import styled from '@emotion/styled';
import React from 'react';

const Triangle = styled.div({
  background: 'black',
  color: 'white',
  border: 'none',
  borderRadius: '3px',
  padding: '0.5em 1em',
  position: 'absolute',
  left: 0,
  top: '100%',
  zIndex: 1,
});

const TriangleTooltip: React.FC<{
  label: string;
  style?: React.CSSProperties;
}> = ({ label, style }) => {
  return <Triangle style={style}>{label}</Triangle>;
};

export default TriangleTooltip;
