import { Button, OutlineButtonDark } from '../../components/button';
import styled from '@emotion/styled';
import { PrimaryNotBlank } from '../../components/text';

export const Resolve = styled(Button)({
  width: '140px',
  minHeight: '32px',
  padding: 0,
});

export const PurpleResolve = styled(Resolve)({
  backgroundColor: '#AE4398',
  ':hover': {
    backgroundColor: '#c262ae',
  },
});

export const CancelButton = styled(OutlineButtonDark)({
  borderRadius: '100px',
  minHeight: '32px',
});

export const Tr = styled.tr({
  borderBottom: '1px solid #E5E8E8',
});

export const getBorderStyle = (selected?: boolean) =>
  selected ? '4px solid #AE4398' : '4px solid transparent';

export const Td = styled.td<{ selected?: boolean }>(({ selected }) => {
  return {
    padding: '12px 22px',
    width: '40%',
    borderLeft: getBorderStyle(selected),
    borderRight: getBorderStyle(selected),
    borderBottom: '1px solid #E5E8E8',
  };
});

export const FirstTD = styled.td({
  width: '20%',
  padding: '12px 22px',
  borderBottom: '1px solid #E5E8E8',
});

export const Label = styled(PrimaryNotBlank)({
  fontWeight: 'bold',
});

export const CGItem = styled.div({
  marginBottom: '8px',
});
