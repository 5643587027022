/**
 * Our default color palette. Try to keep spacing within these options.
 */
export const COLORS = {
  /**
   * #034A46 - Primary brand color
   */
  darkHarbor: '#034A46',
  /**
   * #355E5C - Primary brand color - light variant
   */
  darkHarborLight: '#355E5C',
  /**
   * #11BB9A - Secondary brand color
   */
  teal: '#11BB9A',
  /**
   * #0A8270 - Note: We use a darker teal (#0A8270), optimized for accessibility and legibility, for product and website UI.
   */
  tealDark: '#0A8270',
  /**
   * #A0E4D7 - Secondary brand color - light variant
   */
  tealLight: '#A0E4D7',
  /**
   * #C3EEE5 - Secondary brand color - lighter variant
   */
  tealLighter: '#C3EEE5',
  /**
   * #D9F4EF - Secondary brand color - lightest variant
   */
  tealLightest: '#D9F4EF',
  /**
   * #FF3C00 - Accent color
   */
  orange: '#FF3C00',
  /**
   * #CC3000 - Note: We use a darker orange (#CC3000), optimized for accessibility and legibility, for product and website UI.
   */
  orangeDark: '#CC3000',
  /**
   * #FFBFAC - Accent color - light variant
   */
  orangeLight: '#FFBFAC',
  /**
   * #FDE3D7 - Accent color - lighter variant
   */
  orangeLighter: '#FDE3D7',
  /**
   * #FFF0EC - Accent color - lightest variant
   */
  orangeLightest: '#FFF0EC',
  /**
   * #FFBC00 - Accent color
   */
  butterscotch: '#FFBC00',
  /**
   * #FFE499 - Accent color - light variant
   */
  butterscotchLight: '#FFE499',
  /**
   * #FFEEBF - Accent color - lighter variant
   */
  butterscotchLighter: '#FFEEBF',
  /**
   * #FFF4D6 - Accent color - lightest variant
   */
  butterscotchLightest: '#FFF4D6',
  /**
   * #FF5469 - Accent color
   */
  bubblegum: '#FF5469',
  /**
   * #FFBBC3 - Accent color - light variant
   */
  bubblegumLight: '#FFBBC3',
  /**
   * #FFDDE1 - Accent color - lighter variant
   */
  bubblegumLighter: '#FFDDE1',
  /**
   * #FFEAED - Accent color - lightest variant
   */
  bubblegumLightest: '#FFEAED',
  /**
   * #0253AB - Accent color
   */
  blue: '#0253AB',
  /**
   * #9ABADD - Accent color - light variant
   */
  blueLight: '#9ABADD',
  /**
   * #BFD4EA - Accent color - lighter variant
   */
  blueLighter: '#BFD4EA',
  /**
   * #D2E1F0 - Accent color - lightest variant
   */
  blueLightest: '#D2E1F0',
  /**
   * #695EED - Accent color
   */
  purple: '#695EED', // TODO: update all uses of green and variants when upgrading to latest ui
  /**
   * #B4AEF5 - Accent color - light variant
   */
  purpleLight: '#B4AEF5',
  /**
   * #D9D6FA - Accent color - lighter variant
   */
  purpleLighter: '#D9D6FA',
  /**
   * #E4E2FC - Accent color - lightest variant
   */
  purpleLightest: '#E4E2FC',
  /**
   * #AE4398 - Accent color - formerly purple
   */
  violet: '#AE4398',
  /**
   * #DFB4D6 - Accent color - light variant
   */
  violetLight: '#DFB4D6',
  /**
   * #F2E1EF - Accent color - lighter variant
   */
  violetLighter: '#F2E1EF',
  /**
   * #F6EAF4 - Accent color - lightest variant
   */
  violetLightest: '#F6EAF4',
  /**
   * #4D7236 - Accent color
   */
  green: '#4D7236',
  /**
   * #BFCBB2 - Accent color - light variant
   */
  greenLight: '#BFCBB2',
  /**
   * #D8DFCA - Accent color - lighter variant
   */
  greenLighter: '#D8DFCA',
  /**
   * #E4E9DA - Accent color - lightest variant
   */
  greenLightest: '#E4E9DA',
  /**
   * #9A948C - ???
   */
  beigeDark: '#9A948C',
  /**
   * #F6ECDF - Accent color
   */
  beige: '#F6ECDF',
  /**
   * #F6F3EB - Accent color - light variant
   */
  beigeLight: '#F6F3EB',
  /**
   * #314D4A - Gray color - darkest variant
   */
  grayDarkest: '#314D4A',
  /**
   * #8F9F9D - Gray color - dark variant
   */
  grayDark: '#8F9F9D',
  /**
   * #647977 - Gray color
   */
  gray: '#647977',
  /**
   * #D6DBDB - Gray color - light variant
   */
  grayLight: '#D6DBDB',
  /**
   * #E5E8E8 - Gray color - lighter variant
   */
  grayLighter: '#E5E8E8',
  /**
   * #EFF1F1 - Gray color - lightest variant
   */
  grayLightest: '#EFF1F1',
  /**
   * #F7F8F8 - Gray color - lightester variant
   */
  grayLightester: '#F7F8F8',
  /*
   * 8F9F9D - Low Contrast
   */
  lowContrast: '#8F9F9D',
  /**
   * #FFFFFF - White system UI color
   */
  white: '#FFFFFF',
  /**
   * #010101 - Black system UI color
   */
  black: '#010101',
  /**
   * transparent - No color at all.
   */
  clear: 'transparent',

  // TODO: update all uses of green and variants when upgrading to corresponding teal
  // TODO: update all uses of grey and variants when upgrading to corresponding gray
  // TODO: update all uses of greyDarkest to darkHarbor
  // greyDarkest: '#314D4A',
  // greyDark: '#647977',
  // grey: '#D6DBDB',
  // greyLight: '#E5E8E8',
  // greyLightest: '#F7F8F8',

  /**
   * #D93900 - Red color for errors
   */
  errorRed: '#D93900',
  // TODO: update all uses of warnOrange to orange
  // warnOrange: '#FF3C00',
};

export type GradientColor = keyof typeof GRADIENT_PAIRS;

export const GRADIENT_PAIRS = {
  // NOTE: many of these colors are different from Figma values.
  // They'll be massaged (or maybe eliminated?) when we do the website re-design.
  pink: {
    lighter: '#FFF1F3',
    darker: '#FFE0E5',
  },
  yellow: {
    lighter: '#FFF8E3',
    darker: COLORS.butterscotchLightest,
  },
  green: {
    lighter: '#E5F8F4',
    darker: COLORS.greenLightest,
  },
  blue: {
    lighter: '#EBF2FB',
    darker: '#DBE6F2',
  },
  purple: {
    lighter: '#F2E2EF',
    darker: '#EFD8EA',
  },
  grey: {
    lighter: '#FAFAFA',
    darker: COLORS.grayLight,
  },
  rainbow: {
    lighter: '#FF0000',
    middle: '#FFD600',
    darker: '#60CFFF',
  },
};

/**
 * Our default color palette but without the yelling.
 */
export const Colors = COLORS;
