/** @jsx jsx */
import { ProductFragment } from '@bc/codegen/manager';
import {
  AppointmentFragment,
  ChargeFragment,
  ChargeProductFragment,
  useGetPaymentMethodByChargeIdQuery,
} from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import capitalize from 'lodash/capitalize';
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ICONS } from '../../assets';
import { LOGOS } from '../../assets/payment-method';
import {
  formatMoney,
  numberToCurrency,
  prettifyDate,
  prettifyTimestamp,
} from '../../helpers';
import { ChargeAuditLog } from '../Audit/ChargeAuditLog';
import { PaymentStatusBadge } from '../Badge';
import { HeaderEdit } from '../layout';
import { DefinitionList, DefinitionListItem } from '../List';
import { LinkText, PrimaryText, SmallTitle } from '../text';
import { PaymentInfo } from './PaymentInfo';
import { ProductsTable } from './ProductsTable';
import { Refund } from './Refund';

export interface ChargeProductProps extends ChargeProductFragment {
  product: ProductFragment;
}

interface ChargeProps extends ChargeFragment {
  charge_products?: ChargeProductProps[];
}

const getCardLogo = (brand?: string | null) => {
  // @ts-ignore
  return LOGOS[brand ?? 'Unknown'] ?? LOGOS['Unknown'];
};

const Row = styled.span({
  display: 'flex',
  alignItems: 'center',
});

const FixedWidthColumn = styled(PrimaryText)({
  display: 'flex',
  fontSize: 16,
  fontWeight: 700,
  justifyContent: 'flex-end',
  lineHeight: '19px',
  padding: '8px 24px',
  width: 80,
}).withComponent('div');

const PriceColumn = styled(FixedWidthColumn)({
  width: 104,
});

const ExpandedColumn = styled(FixedWidthColumn)({
  flex: 1,
  justifyContent: 'flex-start',
});

const Icon = styled.img({
  height: 24,
  marginRight: 8,
  width: 24,
});

const Caret = styled(Icon)<{ active?: boolean }>(({ active }) => ({
  marginLeft: 'auto',
  marginRight: 0,
  transform: `rotate(${active ? 0 : 180}deg)`,
  transition: 'all 0.3s ease',
}));

export const Charge: React.FC<{
  appointment?: AppointmentFragment;
  carrier?: string;
  charge: ChargeProps;
  patientName?: string;
  staffName?: string;
}> = ({ appointment, carrier, charge, patientName, staffName }) => {
  const [showProducts, setShowProducts] = useState(false);

  const history = useHistory();

  const { data, refetch } = useGetPaymentMethodByChargeIdQuery({
    variables: { stripeChargeId: charge.stripeChargeId },
    fetchPolicy: 'no-cache',
    skip: !charge.stripeChargeId,
  });

  const stripeCharge = data?.StripePaymentMethodByChargeId;
  const isNativePay =
    stripeCharge?.brand === 'Apple Pay' || stripeCharge?.brand === 'Google Pay';

  const paymentText = isNativePay
    ? stripeCharge?.brand
    : ` ending in ${stripeCharge?.last4}`;

  const totalChargeInCents = charge.amount * 100;

  const refunds = stripeCharge?.refunds?.data ?? [];
  const totalRefunded = refunds
    .map((r: any) => r.amount)
    .reduce((a: number, b: number) => a + b, 0);

  const isFullyRefunded = totalChargeInCents - totalRefunded === 0;
  const isPartiallyRefunded =
    totalChargeInCents - totalRefunded < totalChargeInCents;

  const status = isFullyRefunded
    ? 'Fully Refunded'
    : isPartiallyRefunded
    ? 'Partially Refunded'
    : stripeCharge?.status;

  const hasProducts = !!charge.charge_products?.length;

  return (
    <Fragment>
      <HeaderEdit
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SmallTitle style={{ flex: 'unset', marginRight: 12 }}>
              {prettifyDate(charge.created_at)} Payment
            </SmallTitle>
            <ChargeAuditLog charge={charge} size={24} />
          </div>
        }
        editable={false}
      >
        {!isFullyRefunded && (
          <Refund
            isMore={isPartiallyRefunded}
            stripeChargeId={charge.stripeChargeId}
            onClose={() => refetch()}
          />
        )}
      </HeaderEdit>
      <div css={{ display: 'flex', padding: 32 }}>
        {appointment?.payment && (
          <PaymentInfo label="Payment Type">
            {appointment?.payment === 'INSURANCE'
              ? 'Insurance'
              : 'Out-of-pocket'}
          </PaymentInfo>
        )}
        {appointment?.payment === 'INSURANCE' && (
          <PaymentInfo label="Carrier">{carrier}</PaymentInfo>
        )}
        <PaymentInfo label="Payment Method">
          {capitalize(stripeCharge?.brand ?? undefined)} - {stripeCharge?.last4}
        </PaymentInfo>
      </div>
      <DefinitionList>
        {stripeCharge && status && (
          <DefinitionListItem term="Status">
            <PaymentStatusBadge status={status}>{status}</PaymentStatusBadge>
          </DefinitionListItem>
        )}
        <DefinitionListItem
          term="Amount"
          css={hasProducts && { cursor: 'pointer' }}
          onClick={() => {
            if (hasProducts) {
              setShowProducts(!showProducts);
            }
          }}
        >
          {numberToCurrency(charge.amount)}
          {hasProducts && <Caret src={ICONS.caret} active={showProducts} />}
        </DefinitionListItem>
        {!!charge.charge_products?.length && showProducts && (
          <Fragment>
            <ProductsTable products={charge.charge_products} />
            <Row css={{ padding: '8px 0' }}>
              <ExpandedColumn />
              <FixedWidthColumn>Total</FixedWidthColumn>
              <PriceColumn>{formatMoney(charge.amount)}</PriceColumn>
            </Row>
          </Fragment>
        )}
        {totalRefunded > 0 && (
          <DefinitionListItem term="Amount Refunded">
            -{numberToCurrency(totalRefunded / 100)}
          </DefinitionListItem>
        )}
        <DefinitionListItem term="Date">
          {prettifyTimestamp(charge.created_at, 'ff')}
        </DefinitionListItem>
        {patientName && (
          <DefinitionListItem term="Appointment">
            <LinkText
              onClick={() => {
                history.push(`/appointment/${charge.appointmentId}/visit`);
              }}
            >
              View {patientName}’s appointment
            </LinkText>
          </DefinitionListItem>
        )}
        {staffName && (
          <DefinitionListItem term="Staff Member">
            {staffName}
          </DefinitionListItem>
        )}
        {stripeCharge?.receiptEmail && (
          <DefinitionListItem term="Customer">
            {stripeCharge?.receiptEmail}
          </DefinitionListItem>
        )}
        {/* {stripeCharge?.brand && (
          <DefinitionListItem term="Payment method">
            <Row>
              <Icon
                src={getCardLogo(stripeCharge?.brand)}
                alt={stripeCharge?.brand ?? 'Card'}
              />
              {paymentText}
            </Row>
          </DefinitionListItem>
        )} */}
        {stripeCharge?.receiptUrl && (
          <DefinitionListItem term="Receipt">
            <LinkText
              href={stripeCharge?.receiptUrl}
              target="_blank"
              css={{ fontWeight: 500, textDecoration: 'none' }}
            >
              View Receipt
            </LinkText>
          </DefinitionListItem>
        )}
      </DefinitionList>
    </Fragment>
  );
};
