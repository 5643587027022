import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { LargeTitle, PrimaryText } from '../../components/text';
import { IF_TABLET } from '../../styles';

const commonFlexRules = `
  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  margin-top: 24px;
  ${commonFlexRules};
  cursor: pointer;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const NameWrapper = styled.div`
  ${commonFlexRules};
`;

export const Name = styled(LargeTitle)`
  ${IF_TABLET} {
    font-size: 24px;
  }
  margin: 0 8px 0 0;
`;

export const Age = styled(PrimaryText)`
  color: ${Colors.gray};
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  margin-top: 4px;
`;

export const Wrapper = styled.div<{ hideTopBorder?: boolean }>`
  ${({ hideTopBorder }) =>
    !hideTopBorder && `border-top: 1px solid ${Colors.grayLight};`}
`;
