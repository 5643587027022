/** @jsx jsx */
import {
  useGetAccountsForPatientQuery,
  useGetPatientByPkQuery,
  useLoadPatientInsurancesQuery,
  useUploadKareoDocumentsMutation,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import { upperFirst } from 'lodash';
import React, { Fragment } from 'react';
import {
  InsuranceImageLink,
  PatientAvatarLink,
  PhotoIDLink,
} from '../AppointmentDetails/AvatarDisplay';
import { ConsentDocumentLink } from '../AppointmentDetails/ConsentDocumentLink';
import { Button, TextButton } from '../button';
import { Definition, DefinitionList, Row, Term } from '../List';
import { ErrorMessageWrapper } from '../Payments/Payments.styles';
import { PrimaryText, SmallTitle } from '../text';

const Spacing = styled.div({
  marginBottom: '24px',
});

const SpacedTitle = styled(SmallTitle)({
  marginBottom: '24px',
  display: 'inline-block',
});

export const SpacedDefinitionListItem = ({
  term,
  children,
}: {
  term: string;
  children: React.ReactNode;
}) => {
  return (
    <Row>
      <Term>{term}</Term>
      <Definition style={{ justifyContent: 'flex-end' }}>{children}</Definition>
    </Row>
  );
};

const KareoDocumentReview = ({
  patientId,
  onCancel,
  onSuccess,
}: {
  patientId: string;
  onCancel: () => void;
  onSuccess: () => void;
}) => {
  const [uploadKareoDocuments, { loading: documentLoading, error }] =
    useUploadKareoDocumentsMutation();

  const { data: patientData } = useGetPatientByPkQuery({
    fetchPolicy: 'no-cache',
    variables: {
      patientId,
    },
  });

  const { data: insuranceData } = useLoadPatientInsurancesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      patientId: patientId,
    },
  });

  const { data: accountsData } = useGetAccountsForPatientQuery({
    fetchPolicy: 'no-cache',
    variables: { patientId: patientId },
  });

  const patient = patientData?.patients_by_pk;
  const accounts = accountsData?.accounts ?? [];
  const insurances = insuranceData?.patients_by_pk?.insurances ?? [];
  const hasPatientDocs = patient?.consentSigned || patient?.avatarId;

  const hasAnyAccountDocs = !!accounts.find((account) => {
    return account.photoIdFrontId || account.photoIdBackId;
  });

  const hasAnyInsuranceDocs = !!insurances.find((insurance) => {
    return insurance.frontId || insurance.backId;
  });

  const hasNoDocs =
    !hasPatientDocs && !hasAnyAccountDocs && !hasAnyInsuranceDocs;

  const handleSendDocuments = async () => {
    try {
      await uploadKareoDocuments({
        variables: {
          patientId: patientId,
        },
      });
      onSuccess();
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${Colors.grayLighter}`,
          paddingBottom: '18px',
        }}
      >
        <SmallTitle>Patient Documents</SmallTitle>
        <TextButton type="button" onClick={onCancel}>
          Cancel
        </TextButton>
      </div>
      <div
        style={{
          padding: '24px 0',
        }}
      >
        <PrimaryText>
          {hasNoDocs
            ? 'No document updates available.'
            : 'Document updates available.'}
        </PrimaryText>
      </div>
      {!!error && (
        <div
          css={{
            marginBottom: '16px',
          }}
        >
          <ErrorMessageWrapper>
            <PrimaryText
              style={{
                color: '#FFF',
              }}
            >
              An error occurred while sending documents. Please try again.
            </PrimaryText>
          </ErrorMessageWrapper>
        </div>
      )}
      {!hasNoDocs && (
        <Fragment>
          {hasPatientDocs && (
            <Spacing>
              <SpacedTitle>Patient</SpacedTitle>
              <DefinitionList>
                {patient?.avatarId && (
                  <SpacedDefinitionListItem term="Photo">
                    <PatientAvatarLink patientId={patient.id} />
                  </SpacedDefinitionListItem>
                )}
                {patient?.consentSigned && (
                  <SpacedDefinitionListItem term="Consent to Treat">
                    <ConsentDocumentLink patientId={patient.id}>
                      View
                    </ConsentDocumentLink>
                  </SpacedDefinitionListItem>
                )}
              </DefinitionList>
            </Spacing>
          )}
          {accounts.map((account) => {
            if (!account.photoIdFrontId && !account.photoIdBackId) {
              return null;
            }
            return (
              <Spacing>
                <SpacedTitle>
                  Caregiver {account.firstName} {account.lastName}
                </SpacedTitle>
                <DefinitionList>
                  {account.photoIdFrontId && (
                    <SpacedDefinitionListItem term="Front of ID">
                      <PhotoIDLink accountId={account.id} display="front" />
                    </SpacedDefinitionListItem>
                  )}
                  {account.photoIdBackId && (
                    <SpacedDefinitionListItem term="Back of ID">
                      <PhotoIDLink accountId={account.id} display="back" />
                    </SpacedDefinitionListItem>
                  )}
                </DefinitionList>
              </Spacing>
            );
          })}

          {insurances.map((insurance) => {
            if (!insurance.frontId && !insurance.backId) {
              return null;
            }

            return (
              <Spacing>
                <SpacedTitle>
                  {upperFirst(insurance.type.toLowerCase())} Insurance
                </SpacedTitle>
                <DefinitionList>
                  {insurance.frontId && (
                    <SpacedDefinitionListItem term="Front of Insurance Card">
                      <InsuranceImageLink
                        insuranceId={insurance.id}
                        display="front"
                      />
                    </SpacedDefinitionListItem>
                  )}
                  {insurance.backId && (
                    <SpacedDefinitionListItem term="Back of Insurance Card">
                      <InsuranceImageLink
                        insuranceId={insurance.id}
                        display="back"
                      />
                    </SpacedDefinitionListItem>
                  )}
                </DefinitionList>
              </Spacing>
            );
          })}

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              type="button"
              disabled={documentLoading}
              onClick={handleSendDocuments}
            >
              Send Documents to Kareo
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default KareoDocumentReview;
