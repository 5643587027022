import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import React, { InputHTMLAttributes } from 'react';
import checkmark from '../../assets/checkmark.svg';
import unchecked from '../../assets/unchecked-checkmark.svg';
import { FONT_PRIMARY } from '../../styles';

const StyledLabel = styled.label`
  ${FONT_PRIMARY};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${Colors.darkHarbor};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledInput = styled.input<{ color: string; checked: boolean }>`
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  cursor: pointer;
  content: '';
  position: relative;
  appearance: none;
  -moz-appearance: initial;
  border-radius: 5px;
  background: ${({ checked, color }) =>
    checked && color
      ? `url("${checkmark}"), ${color};`
      : `url("${unchecked}");`};

  ::before {
    content: '';
    width: 24px;
    height: 24px;
    left: 0;
    position: absolute;
    border-radius: 5px;
    background: ${({ checked, color }) =>
      checked && color
        ? `url("${checkmark}"), ${color};`
        : `url("${unchecked}");`};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0px 1px white,
      0 0 0px 2px
        ${({ checked, color }) => (checked && color ? color : Colors.gray)};
    border-radius: 5px;
  }
`;

export const Checkbox: React.FC<
  {
    color?: string;
    groupName?: string;
    label: string | React.ReactNode;
    value: string;
    isChecked: boolean;
    onChange: (value: string) => void;
  } & InputHTMLAttributes<HTMLInputElement>
> = ({
  color = Colors.tealDark,
  groupName,
  label,
  value,
  isChecked,
  onChange,
  ...props
}) => (
  <StyledLabel>
    <StyledInput
      id={value}
      type="checkbox"
      value={value}
      name={groupName}
      color={color}
      checked={isChecked}
      onChange={() => onChange(value)}
      {...props}
    />
    {label}
  </StyledLabel>
);
