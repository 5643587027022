import { Colors } from '@bc/theme';
import { css, Global } from '@emotion/core';
import React from 'react';

const GlobalCss = () => {
  return (
    <Global
      styles={css`
        [data-reach-menu],
        [data-reach-menu-popover] {
          top: 100%;
          z-index: 100000;
        }
        [data-reach-menu-item][data-selected] {
          background: ${Colors.grayLightest};
        }
      `}
    />
  );
};

export default GlobalCss;
