import { PatientFragment, useGetPatientByPkQuery } from '@bc/codegen/medical';
import { capitalize } from 'lodash';
import React, { Fragment } from 'react';
import { HeaderEdit } from './layout';
import { DefinitionList, DefinitionListItem } from './List';

export const HeardAboutUs = ({ patient }: { patient: PatientFragment }) => {
  const { data } = useGetPatientByPkQuery({
    variables: { patientId: patient.id },
    fetchPolicy: 'cache-and-network',
    skip: !patient.id,
  });

  const patientData = data?.patients_by_pk;
  const formatValue = (value: string) =>
    value
      .split('_')
      .map((w) => capitalize(w.toLowerCase()))
      .join(' ') ?? '';

  return (
    <Fragment>
      <HeaderEdit
        title="Heard about us"
        editable={false}
        editing={false}
        onEdit={() => {}}
      />
      <DefinitionList>
        {!patientData?.discovered_froms?.length && (
          <DefinitionListItem term="Referral">
            No answer provided
          </DefinitionListItem>
        )}
        {patientData?.discovered_froms.map((value, index) => (
          <DefinitionListItem key={index} term="Referral">
            {value?.description?.length
              ? value.description
              : formatValue(value.type)}
          </DefinitionListItem>
        ))}
      </DefinitionList>
    </Fragment>
  );
};
