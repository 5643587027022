import React, { Fragment } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth0 } from '../../react-auth0';
import { NetworkConnectivityAlertBanner } from '../Alert';

export const PrivateRoute: React.FC<{
  exact?: boolean;
  path: string;
  component?: any;
  render?: any;
}> = ({ component: Component, path, exact, render }) => {
  const { isAuthenticated, loading } = useAuth0();

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        return isAuthenticated === true ? (
          Component ? (
            <Fragment>
              <NetworkConnectivityAlertBanner />
              <Component {...props} />
            </Fragment>
          ) : (
            render(props)
          )
        ) : loading ? null : (
          <Redirect to="/" />
        );
      }}
    />
  );
};
