import styled from '@emotion/styled';
import { GREY_LIGHT, GREY } from '../../styles';
import { PrimaryText, SmallTitle } from '../text';
import { TextButton } from '../button';
import { ReactComponent as UnselectIcon } from '../../assets/close_reverse.svg';
import { ReactComponent as AddPersonIcon } from '../../assets/add_person.svg';
import { Row } from '../../styles';

export const Wrapper = styled.div`
  width: 100%;
  // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.06),
  //   0px 0px 1px rgba(0, 0, 0, 0.04);
  // padding: 0 24px;
  // border-radius: 16px;
`;

export const AccountWrapper = styled.div();

export const PersonWrapper = styled(Row)({
  borderBottom: `1px solid ${GREY_LIGHT}`,
  padding: '20px 0',
});

export const PersonTextWrapper = styled.div({
  flex: 1,
  marginLeft: 24,
});

export const SelectButton = styled(PrimaryText)<{ isSelected: boolean }>(
  ({ isSelected }) => ({
    alignItems: 'center',
    backgroundColor: '#ffffff',
    border: `1px solid ${isSelected ? GREY : GREY_LIGHT}`,
    borderRadius: 100,
    cursor: 'pointer',
    display: 'flex',
    fontSize: 14,
    padding: isSelected ? '7px 8px 7px 16px' : '11px 16px 10px',
    ':focus': {
      boxShadow: `inset 0 0 0 1px ${isSelected ? GREY : GREY_LIGHT}`,
      outline: 'none',
    },
  })
).withComponent('button');

export const Unselect = styled(UnselectIcon)({
  cursor: 'pointer',
  marginLeft: 8,
});

export const PersonText = styled(SmallTitle)({
  fontSize: 16,
  lineHeight: '22px',
});

export const SecondaryText = styled(PrimaryText)({
  display: 'block',
  fontSize: 14,
  lineHeight: '18px',
  marginTop: 8,
});

export const NormalText = styled(PersonText)({
  fontWeight: 'normal',
}).withComponent('span');

export const CenteredText = styled(PrimaryText)<{ addBottomMargin?: boolean }>(
  ({ addBottomMargin }) => ({
    textAlign: 'center',
    margin: addBottomMargin ? 24 : '24px 24px 0 24px',
    display: 'block',
  })
);

export const Title = styled(SmallTitle)({
  paddingTop: 16,
  display: 'block',
});

export const AddButton = styled(TextButton)({
  fontSize: 16,
  lineHeight: '22px',
  fontWeight: 500,
  paddingLeft: 0,
  ':focus': {
    textDecoration: 'underline',
  },
});

export const AddIcon = styled(AddPersonIcon)({
  cursor: 'pointer',
  marginRight: 8,
});

export const Divider = styled.hr({
  border: `1px solid ${GREY_LIGHT}`,
  appearance: 'none',
  outline: 'none',
  borderStyle: 'inherit',
});
