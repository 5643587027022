/** @jsx jsx */
import { Colors, Spacing } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { PatientAvatarDisplay } from '../../components/AppointmentDetails/AvatarDisplay';
import { Button } from '../../components/button';
import { Header } from '../../components/Header';
import { Content, InnerWrap, MainContent } from '../../components/layout';
import { Sidebar } from '../../components/Nav/Sidebar';
import { LargeTitle, PrimaryText } from '../../components/text';
import { formatDateOfBirth } from '../../helpers';
import { useGetPatientDupes } from '../../lib/patientMerger';

const Resolve = styled(Button)({
  width: '140px',
  height: '32px',
  padding: 0,
});

const Heading = styled.div({
  borderBottom: `1px solid ${Colors.grayLighter}`,
  paddingBottom: Spacing.s,
  marginBottom: Spacing.l,
});

const Subtitle = styled(PrimaryText)({
  display: 'block',
  marginBottom: Spacing.xs,
  marginTop: Spacing.xs,
});

const Row = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: Spacing.xs,
});

const PatientMigrator = () => {
  const { data, duplicates } = useGetPatientDupes();
  const history = useHistory();
  const patients = data?.patient_duplicates ?? [];
  const getPatient = (id: string) => {
    return patients.find((patient) => patient.id === id);
  };

  const handleResolve = (patientIds: string) => {
    history.push(`/patient_merger/${patientIds}`);
  };

  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap>
          <Sidebar />
          <MainContent style={{ padding: Spacing.xl }}>
            <div css={{ maxWidth: '680px' }}>
              <Heading>
                <LargeTitle>Duplicate Patients</LargeTitle>
                <Subtitle>
                  If you don't see the patient listed here, double check that
                  the DOBs match and that the patient record first and last
                  names do not have any extra trailing spaces.
                </Subtitle>
              </Heading>
              {duplicates.map(({ id, matched }) => {
                const dupePatients = matched.map((pid: string) =>
                  getPatient(pid),
                );
                const patientDisplay = getPatient(id);
                const patientAvatar = dupePatients.find(
                  (patient) => !!patient?.avatarId,
                );
                const patientIds = [id, matched[0]].join(',');

                return (
                  <Row key={id}>
                    <PatientAvatarDisplay patientId={patientAvatar?.id!} />
                    <div css={{ flex: 1, paddingLeft: Spacing.s }}>
                      <PrimaryText css={{ fontSize: '24px' }}>
                        {patientDisplay?.firstName} {patientDisplay?.lastName} -{' '}
                        {formatDateOfBirth(patientDisplay?.dateOfBirth!)} (
                        {matched.length})
                      </PrimaryText>
                    </div>
                    <Resolve onClick={() => handleResolve(patientIds)}>
                      Resolve
                    </Resolve>
                  </Row>
                );
              })}
            </div>
          </MainContent>
        </InnerWrap>
      </Content>
    </Fragment>
  );
};

export default PatientMigrator;
