import React, { useState } from 'react';
import Downshift from 'downshift';
import { Input, InputWrap } from '../form';
import { Container, ResultsContainer, SearchResults } from './ui';
import fuzzaldrin from 'fuzzaldrin-plus';

interface Props {
  onChange: (text?: string) => void;
  value?: string;
  error: boolean;
  emails: string[];
}

const EmailSearchInput: React.FC<Props> = ({
  onChange,
  value,
  error,
  emails,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const handleChange = (item: string | null) => {
    onChange(item || '');
  };

  const filtered = fuzzaldrin.filter(emails, inputValue || '');

  return (
    <Downshift
      onChange={handleChange}
      inputValue={inputValue}
      onInputValueChange={(value) => {
        setInputValue(value);
        onChange(value);
      }}
      onSelect={handleChange}
    >
      {({
        getRootProps,
        getInputProps,
        getItemProps,
        isOpen,
        openMenu,
        closeMenu,
        highlightedIndex,
        selectedItem,
      }) => {
        return (
          <Container {...getRootProps()}>
            <ResultsContainer>
              <div>
                <InputWrap style={{ marginBottom: 0 }}>
                  <Input
                    {...getInputProps({
                      onFocus: openMenu,
                      type: 'text',
                      placeholder: 'Enter email address',
                      autoComplete: 'off',
                      onBlur: () => {
                        closeMenu();
                      },
                    })}
                    error={error}
                  />
                </InputWrap>
              </div>
              {isOpen && (
                <SearchResults
                  items={inputValue ? filtered.slice(0, 10) : emails}
                  inputValue={inputValue}
                  getItemProps={getItemProps}
                  highlightedIndex={highlightedIndex}
                  selectedItem={selectedItem}
                />
              )}
            </ResultsContainer>
          </Container>
        );
      }}
    </Downshift>
  );
};

export default EmailSearchInput;
