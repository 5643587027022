/** @jsx jsx */
import {
  useGetClinicsQuery,
  useUpdateClinicMutation,
} from '@bc/codegen/manager';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Fragment, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { hasRole } from '../../auth';
import { Button, ButtonRed, OutlineButton } from '../../components/button';
import { Header } from '../../components/Header';
import { Content, InnerWrap, MainContent } from '../../components/layout';
import { Sidebar } from '../../components/Nav/Sidebar';
import { LargeTitle, PrimaryText, SmallTitle } from '../../components/text';
import { useAuth0 } from '../../react-auth0';
import { IF_TABLET } from '../../styles';

const PageHeader = styled.div`
  align-items: baseline;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding: 14px 12px;
  margin: 20px 0;
  ${IF_TABLET} {
    margin: 40px 0;
    padding: 0;
  }
`;

const SelectWrap = styled.div`
  display: flex;
  position: relative;
`;

const Container = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

const EventsIndex = ({ history }: RouteComponentProps<{}>) => {
  const { accessToken } = useAuth0();
  const isManager = hasRole('manager', accessToken);

  const [updateClinic] = useUpdateClinicMutation({
    context: {
      role: 'manager',
    },
  });
  const { data, loading, refetch } = useGetClinicsQuery({
    fetchPolicy: 'cache-and-network',
    context: { role: 'manager' },
    variables: {
      where: {
        locationType: {
          _eq: 'EVENT',
        },
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [history.location.pathname]);

  const events = data?.clinics ?? [];

  const handleToggleActive = async (clinicId: string) => {
    const event = events.find(({ id }) => {
      return id === clinicId;
    });

    if (!event) return;
    await updateClinic({
      variables: {
        id: clinicId,
        clinic: {
          active: !event.active,
        },
      },
    });
  };

  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap>
          <Sidebar />
          <MainContent>
            <PageHeader>
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <LargeTitle>Events</LargeTitle>
              </div>
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {isManager && (
                  <Fragment>
                    <OutlineButton
                      style={{
                        borderRadius: '100px',
                        marginLeft: 'auto',
                        marginTop: '8px',
                        marginRight: '8px',
                      }}
                      onClick={() => history.push('/events/create')}
                    >
                      Create Event
                    </OutlineButton>
                  </Fragment>
                )}
              </div>
            </PageHeader>
            <div
              css={{
                border: `1px solid #E5E8E8`,
                borderRadius: 12,
                width: '100%',
                minHeight: 'calc(100% - 120px)',
              }}
            >
              {events.map(({ id, name, active, address }, index) => {
                const isLast = index === events.length - 1;

                return (
                  <div
                    key={id}
                    css={[
                      {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '24px',
                        borderTop: '1px solid #E5E8E8',
                      },
                      index === 0 && { borderTop: 'none' },
                      isLast && { borderBottom: '1px solid #E5E8E8' },
                    ]}
                  >
                    <div css={{ display: 'flex', flexDirection: 'column' }}>
                      <SmallTitle>{name}</SmallTitle>
                      <PrimaryText>{address}</PrimaryText>
                    </div>
                    <div>
                      <OutlineButton
                        style={{
                          borderRadius: '100px',
                          marginLeft: 'auto',
                          marginTop: '8px',
                          marginRight: '8px',
                        }}
                        onClick={() => history.push(`/events/edit/${id}`)}
                      >
                        Edit Event
                      </OutlineButton>
                    </div>
                    <SelectWrap>
                      {active && (
                        <ButtonRed
                          onClick={() => {
                            handleToggleActive(id);
                          }}
                        >
                          Set Inactive
                        </ButtonRed>
                      )}
                      {!active && (
                        <Button
                          onClick={() => {
                            handleToggleActive(id);
                          }}
                        >
                          Set Active
                        </Button>
                      )}
                    </SelectWrap>
                  </div>
                );
              })}
              {events.length === 0 && !loading && (
                <Container>
                  <SmallTitle>No events</SmallTitle>
                </Container>
              )}
            </div>
          </MainContent>
        </InnerWrap>
      </Content>
    </Fragment>
  );
};

export default EventsIndex;
