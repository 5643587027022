/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ICONS } from '../../assets';
import { OutlineButtonDark, TransparentButton } from '../../components/button';
import { DueDateTime } from '../../components/Tasks/DueDateTime';
import { PriorityPills } from '../../components/Tasks/TaskPriorityDropdown';
import { LargeStaffImage, SectionTitle } from '../../components/Tasks/ui';
import {
  PrimaryText,
  SectionTitle as SectionPageTitle,
} from '../../components/text';
import { useTrackEvent } from '../../lib/analytics';
import { BasicStaff, useCurrentStaff } from '../../lib/staff';
import {
  getActiveAssignments,
  hasReadTask,
  isResponsible,
  useTasks,
} from '../../lib/task';
import { TaskCheckmark } from './icons/task_check';
import { Task } from './types';

const titleToId = (title: string) => {
  return title?.replace(/ /g, '_')?.toLowerCase();
};

const RotateCaret = styled.img<{ up?: boolean }>(
  {
    width: '8px',
  },
  ({ up }) => {
    return {
      transition: 'transform .25s ease',
      transform: up ? 'rotate(-180deg)' : 'rotate(0deg)',
    };
  },
);

const NoTasks = ({ children }: { children: string }) => {
  return (
    <div
      css={{
        paddingBottom: '16px',
      }}
    >
      <PrimaryText
        css={{
          color: '#647977',
        }}
      >
        {children}
      </PrimaryText>
    </div>
  );
};

const TaskRow = ({
  task,
  isLast,
  onTaskClick,
  currentStaff,
}: {
  task: Task;
  isLast: boolean;
  onTaskClick: (taskId: string) => void;
  currentStaff?: BasicStaff;
}) => {
  const { updateTask, assignTask } = useTasks();
  const isAction = task.type === 'ACTION';
  const isCompleted = task.status === 'COMPLETED';
  const assignemnts = getActiveAssignments(task);
  const assignedStaff = assignemnts?.[0]?.staff;
  const assignedToMe = currentStaff && assignedStaff?.id === currentStaff?.id;
  const canClaim = isResponsible(task, currentStaff?.id);
  const trackEvent = useTrackEvent();

  return (
    <div
      css={{
        opacity: isCompleted ? '0.4' : '1',
        borderTop: '1px solid #E5E8E8',
        borderBottom: isLast ? '1px solid #E5E8E8' : undefined,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '7px 2px',
      }}
      data-testid={titleToId(task.title)}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          flex: 1,
        }}
      >
        {(assignedToMe || isCompleted) && isAction && (
          <TransparentButton
            data-testid="complete_checkmark"
            onClick={() => {
              if (!isCompleted) {
                updateTask(task.id, {
                  status: 'COMPLETED',
                });
                trackEvent({
                  type: 'TASK_COMPLETED',
                  data: {
                    taskId: task.id,
                    currentStaffId: currentStaff?.id,
                  },
                });
              } else {
                updateTask(task.id, {
                  status: 'OPEN',
                });
                trackEvent({
                  type: 'TASK_OPEN',
                  data: {
                    taskId: task.id,
                    currentStaffId: currentStaff?.id,
                  },
                });
              }
            }}
            css={{
              marginRight: '11px',
              [`:hover path, :hover circle`]: {
                stroke: '#11BB9A',
              },
            }}
          >
            {!isCompleted && <TaskCheckmark />}
            {isCompleted && <img src={ICONS.circleCheckGreen} />}
          </TransparentButton>
        )}
        {!isCompleted && canClaim && !assignedStaff && isAction && (
          <OutlineButtonDark
            data-testid="claim_task"
            onClick={() => {
              if (currentStaff) {
                assignTask({
                  taskId: task.id,
                  staffId: currentStaff.id,
                });
                trackEvent({
                  type: 'CLAIM_TASK',
                  data: {
                    taskId: task.id,
                    assignemnts,
                    currentStaffId: currentStaff?.id,
                  },
                });
              }
            }}
            css={{
              borderRadius: '100px',
              padding: '5px 8px',
              minHeight: '32px',
            }}
          >
            Claim
          </OutlineButtonDark>
        )}

        <PrimaryText
          css={{
            flex: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            onTaskClick(task.id);
          }}
        >
          {task.title}
        </PrimaryText>
      </div>

      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        {assignedStaff && (
          <LargeStaffImage
            src={assignedStaff.avatarUrl ?? undefined}
            title={`${assignedStaff.firstName} ${assignedStaff.lastName}`}
          />
        )}
        {task.priority && PriorityPills[task.priority]}
        <DueDateTime
          dueDateTime={task.dueDateTime!}
          isCompleted={isCompleted}
        />
      </div>
    </div>
  );
};

type Section = 'COMPLETED' | 'ACTIVE';

export const BasicTaskView = ({
  tasks,
  title,
  basePath,
}: {
  tasks: Task[];
  title?: React.ReactNode;
  basePath: string;
}) => {
  const history = useHistory();
  const currentStaff = useCurrentStaff();
  const [hidden, setHidden] = useState<Section[]>([]);
  const toggleHidden = (type: Section) => {
    setHidden((hidden) => {
      if (hidden.includes(type)) {
        return hidden.filter((item) => {
          return item !== type;
        });
      } else {
        return [...hidden, type];
      }
    });
  };

  const activeTasks = tasks?.filter((task) => {
    return (
      (task.status !== 'COMPLETED' && task.type === 'ACTION') ||
      (task.type === 'INFORMATION' && !hasReadTask(task, currentStaff?.id))
    );
  });

  const completedTasks = tasks?.filter((task) => {
    return (
      (task.status === 'COMPLETED' && task.type === 'ACTION') ||
      (task.type === 'INFORMATION' && hasReadTask(task, currentStaff?.id))
    );
  });

  return (
    <div
      css={{
        paddingTop: '16px',
      }}
    >
      {title ? (
        title
      ) : (
        <div
          css={{
            marginBottom: '21px',
          }}
        >
          <SectionPageTitle data-testid="appointmentTasks">
            Tasks
          </SectionPageTitle>
        </div>
      )}
      <div
        css={{
          marginBottom: '40px',
        }}
      >
        <div
          css={{
            paddingBottom: '16px',
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            cursor: 'pointer',
          }}
          onClick={() => {
            toggleHidden('ACTIVE');
          }}
        >
          <RotateCaret
            src={ICONS.caretDownPlain}
            up={hidden.includes('ACTIVE')}
          />
          <SectionTitle>Active</SectionTitle>
        </div>
        {!hidden.includes('ACTIVE') && (
          <Fragment>
            {activeTasks.length === 0 && <NoTasks>No active tasks.</NoTasks>}
            {activeTasks.map((task, index) => {
              const isLast = index === activeTasks.length - 1;
              return (
                <TaskRow
                  key={task.id}
                  task={task}
                  isLast={isLast}
                  onTaskClick={(taskId) => {
                    history.push(`${basePath}/tasks/${taskId}`);
                  }}
                  currentStaff={currentStaff}
                />
              );
            })}
          </Fragment>
        )}
      </div>
      <div>
        <div
          css={{
            paddingBottom: '16px',
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            cursor: 'pointer',
          }}
          onClick={() => {
            toggleHidden('COMPLETED');
          }}
        >
          <RotateCaret
            src={ICONS.caretDownPlain}
            up={hidden.includes('COMPLETED')}
          />
          <SectionTitle>Completed</SectionTitle>
        </div>
        {!hidden.includes('COMPLETED') && (
          <Fragment>
            {completedTasks.length === 0 && (
              <NoTasks>No completed tasks.</NoTasks>
            )}
            {completedTasks.map((task, index) => {
              const isLast = index === completedTasks.length - 1;
              return (
                <TaskRow
                  key={task.id}
                  task={task}
                  isLast={isLast}
                  onTaskClick={(taskId) => {
                    history.push(`${basePath}/tasks/${taskId}`);
                  }}
                />
              );
            })}
          </Fragment>
        )}
      </div>
    </div>
  );
};
