/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ICONS } from '../../assets';
import { TextButton } from '../button';
import { PrimaryText } from '../text';

const KareoLinkedBanner = ({
  kareoPatientId,
  onUnlink,
}: {
  kareoPatientId: string;
  onUnlink: () => void;
}) => {
  return (
    <div
      css={{
        marginTop: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img src={ICONS.kareoLinked} />
        <a
          href={`https://app.kareo.com/EhrWebApp/patients/viewFacesheet/${kareoPatientId}`}
          target="_blank"
          style={{
            textDecoration: 'none',
            marginLeft: '6px',
          }}
        >
          <PrimaryText>Patient is linked in Kareo</PrimaryText>
        </a>
      </div>

      <TextButton
        css={{
          color: '#ef3f00',
        }}
        onClick={() => {
          onUnlink();
        }}
      >
        Unlink Patient
      </TextButton>
    </div>
  );
};

export default KareoLinkedBanner;
