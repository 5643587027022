/** @jsx jsx */
import { TaskNotificationFragment } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import Popover, { positionMatchWidth } from '@reach/popover';
import Tooltip from '@reach/tooltip';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { Fragment, useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { ICONS } from '../../assets';
import { getDisplayDate } from '../../pages/Dashboard.helpers';
import { ACCENT_GREEN, IF_DESKTOP } from '../../styles';
import { CalendarSelector } from '../CalendarSelector';
import { InputWrap, Label, NormalField, ResizingTextAreaField } from '../form';
import { InfoSection } from '../layout';
import { PrimaryText, SmallTitle } from '../text';
import UserSearch from '../UserSearch/search';
import LockIcon from './icons/lock.svg';
import { TaskAppointmentDropdown } from './TaskAppointmentDropdown';
import { TaskAssignmentField } from './TaskAssignemnt';
import { TaskAssociationField } from './TaskAssociation';
import { TaskCaregiverDropdown } from './TaskCareGiverDropdown';
import { TaskPatientDropdown } from './TaskPatientDropdown';
import { PriorityDropdown } from './TaskPriorityDropdown';
import { TaskResponsibilityField } from './TaskResponsibility';
import { TaskSubtype } from './TaskSubType';
import { Values } from './types';
import { centeredToolip, ToggleButton } from './ui';

export const TasksForm = ({
  disableAssociations,
}: {
  disableAssociations?: boolean;
}) => {
  const { setFieldValue, values } = useFormikContext<Values>();
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef<HTMLDivElement>(null);
  const calendarSelectRef = useRef<HTMLButtonElement>(null);

  useOnClickOutside(calendarRef, () => {
    setShowCalendar(false);
  });

  const patientAssociation = values?.associations?.find((association) => {
    return association.patientId;
  });

  const appointmentAssociation = values?.associations.find((association) => {
    return association.appointmentId;
  });

  const accountAssociation = values?.associations.find((association) => {
    return association.accountId;
  });

  const groupNotification = values?.notifications.find((notification) => {
    return notification.taskGroupId;
  });

  const handleSelectType = (association: Partial<TaskNotificationFragment>) => {
    setFieldValue('associations', [...values.associations, association]);
  };

  const hideAssociations =
    disableAssociations &&
    [patientAssociation, appointmentAssociation, accountAssociation].filter(
      Boolean,
    ).length === 0;

  const hasSelectedGroupResponsible = !!groupNotification;

  return (
    <Fragment>
      <div
        css={{
          padding: '0 40px',
        }}
      >
        <NormalField
          name="title"
          fullWidth
          placeholder="Add Title"
          autoFocus
          css={{
            [IF_DESKTOP]: {
              fontFamily: 'Tiempos Text',
              fontSize: '22px',
              fontWeight: 700,
            },
          }}
        />
        <InfoSection fullWidth>
          <InputWrap>
            <Label>Description</Label>
            <ResizingTextAreaField
              name="note"
              placeholder="Write a description..."
            />
          </InputWrap>
        </InfoSection>
        <div
          css={{
            display: 'flex',
          }}
        >
          <ToggleButton
            selected={values.type === 'ACTION'}
            disabled={disableAssociations}
            onClick={() => {
              if (!disableAssociations) {
                setFieldValue('type', 'ACTION');
              }
            }}
          >
            To Do
          </ToggleButton>
          <ToggleButton
            selected={values.type === 'INFORMATION'}
            disabled={disableAssociations}
            onClick={() => {
              if (!disableAssociations) {
                setFieldValue('type', 'INFORMATION');
              }
            }}
          >
            Memo
          </ToggleButton>
        </div>
      </div>
      {!hideAssociations && (
        <Fragment>
          <div
            css={{
              marginTop: '16px',
              marginBottom: '32px',
              width: '100%',
              borderBottom: `1px solid ${Colors.grayLighter}`,
            }}
          />
          <div css={{ padding: '0 40px' }}>
            <div
              css={{
                marginBottom: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <PrimaryText
                css={{
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '22px',
                }}
              >
                Associations
              </PrimaryText>{' '}
              <Tooltip
                label={
                  !disableAssociations
                    ? `Associations allow you to link a patient and/or caregiver to this task. To link an appointment, associate a patient first.`
                    : 'Associations cannot be edited.'
                }
                css={{
                  background: Colors.darkHarbor,
                  color: Colors.white,
                  border: 'none',
                  borderRadius: '4px',
                  padding: '8px',
                  fontSize: '16px',
                  fontFamily: "'Greycliff', Helvetica, sans-serif",
                  zIndex: 100000,
                }}
                position={centeredToolip}
              >
                {!disableAssociations ? (
                  <img src={ICONS.question} css={{ marginLeft: '8px' }} />
                ) : (
                  <img src={LockIcon} css={{ marginLeft: '8px' }} />
                )}
              </Tooltip>
            </div>
            {!patientAssociation &&
              !accountAssociation &&
              !disableAssociations && (
                <InputWrap>
                  <Label>Associate Patient or Caregiver (Optional)</Label>
                  <UserSearch
                    onSelect={(selected) => {
                      if (selected.type === 'ACCOUNT') {
                        handleSelectType({
                          accountId: selected.id,
                        });
                      } else if (selected.type === 'PATIENT') {
                        handleSelectType({
                          patientId: selected.id,
                        });
                      }
                    }}
                  />
                </InputWrap>
              )}
            <TaskAssociationField
              name="associations"
              disabled={disableAssociations}
            />
            {!disableAssociations && (
              <Fragment>
                {!!values?.associations?.length && (
                  <div
                    css={{
                      paddingTop: '16px',
                      display: 'flex',
                      gap: '8px', // remove this
                      position: 'relative',
                      '[data-reach-menu-popover]': {
                        width: '100%',
                      },
                    }}
                  >
                    {!patientAssociation?.patientId && accountAssociation && (
                      <TaskPatientDropdown
                        accountId={accountAssociation.accountId!}
                        onSelect={(patientId: string) => {
                          setFieldValue('associations', [
                            ...values.associations,
                            {
                              patientId,
                            },
                          ]);
                        }}
                      />
                    )}
                    {patientAssociation?.patientId && !appointmentAssociation && (
                      <TaskAppointmentDropdown
                        patientId={patientAssociation.patientId}
                        onSelect={(appointmentId: string) => {
                          setFieldValue('associations', [
                            ...values.associations,
                            {
                              appointmentId,
                            },
                          ]);
                        }}
                      />
                    )}
                    {patientAssociation?.patientId && !accountAssociation && (
                      <TaskCaregiverDropdown
                        patientId={patientAssociation.patientId}
                        onSelect={(accountId: string) => {
                          setFieldValue('associations', [
                            ...values.associations,
                            {
                              accountId,
                            },
                          ]);
                        }}
                      />
                    )}
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </Fragment>
      )}
      <div
        css={{
          marginTop: '16px',
          width: '100%',
          borderBottom: `1px solid ${Colors.grayLighter}`,
        }}
      />

      <div
        css={{
          padding: '0 40px',
        }}
      >
        <div css={{ marginBottom: '24px', marginTop: '32px' }}>
          <SmallTitle>To Do Details</SmallTitle>
        </div>
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <Label>Responsible</Label>
          <TaskResponsibilityField
            name="notifications"
            onClear={() => {
              setFieldValue('assignment', undefined);
            }}
          />
        </div>
        {hasSelectedGroupResponsible && (
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '8px',
            }}
          >
            <Label>Assignee (Optional)</Label>
            <TaskAssignmentField
              name="assignment"
              taskGroupId={groupNotification?.taskGroupId!}
            />
          </div>
        )}
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <InfoSection>
            <InputWrap ref={calendarRef}>
              <Label>Due Date</Label>
              <button
                type="button"
                css={{
                  border: `1px solid ${Colors.grayLight}`,
                  borderRadius: '4px',
                  padding: '13px 13px 13px 16px',
                  display: 'flex',
                  cursor: 'pointer',
                  alignItems: 'center',
                  background: 'none',
                  outlineColor: ACCENT_GREEN,
                }}
                onClick={() => {
                  setShowCalendar(true);
                }}
                ref={calendarSelectRef}
              >
                <img src={ICONS.calendarSimple} />
                <PrimaryText
                  css={{ textAlign: 'left', flex: 1, paddingLeft: '16px' }}
                >
                  {getDisplayDate(DateTime.fromJSDate(values.dueDate)).date}
                </PrimaryText>
                <img src={ICONS.caretDown} />
              </button>
              <Popover
                hidden={!showCalendar}
                targetRef={calendarSelectRef}
                position={positionMatchWidth}
                css={{
                  zIndex: 100000,
                }}
              >
                <CalendarSelector
                  value={values.dueDate}
                  onChange={(value) => {
                    setFieldValue('dueDate', value);
                    setShowCalendar(false);
                  }}
                  onMouseDown={(e) => {
                    // Popover puts this in a portal so clicking outside is broken if you try and select stuff
                    e.stopPropagation();
                  }}
                  css={{
                    padding: '24px 16px',
                    zIndex: 16,
                    background: Colors.white,
                    borderRadius: 16,
                    boxShadow:
                      '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
                  }}
                />
              </Popover>
            </InputWrap>
          </InfoSection>
          <InfoSection>
            <InputWrap>
              <Label>Type</Label>
              <TaskSubtype name="subType" />
            </InputWrap>
          </InfoSection>
        </div>
        <div>
          <Label>Add to Task</Label>
          <div css={{ paddingTop: '8px' }}>
            <PriorityDropdown name="priority" />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
