/** @jsx jsx */
import {
  StaffFragment,
  useWatchTaskByIdSubscription,
  WatchTaskByIdSubscription,
} from '@bc/codegen/medical';
import { VISIT_CLASSIFICATIONS, VISIT_TYPES } from '@bc/shared';
import { Colors, Spacing } from '@bc/theme';
import { jsx } from '@emotion/core';
import Tooltip from '@reach/tooltip';
import Linkify from 'linkify-react';
import { DateTime } from 'luxon';
import { Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ICONS } from '../../assets';
import {
  AccountAvatarDisplay,
  PatientAvatarDisplay,
} from '../../components/AppointmentDetails/AvatarDisplay';
import {
  GreenOutlineBorderButton,
  OutlineButtonDark,
  TransparentButton,
} from '../../components/button';
import { DeletedTask } from '../../components/Tasks/DeletedTask';
import { DueDateTime } from '../../components/Tasks/DueDateTime';
import { VIEW_ICONS } from '../../components/Tasks/icons';
import CloseIcon from '../../components/Tasks/icons/close.svg';
import CompleteCheck from '../../components/Tasks/icons/complete_check.svg';
import PencilIcon from '../../components/Tasks/icons/edit_pencil.svg';
import GreenCheck from '../../components/Tasks/icons/green_check.svg';
import RoundClear from '../../components/Tasks/icons/round_clear.svg';
import TrashIcon from '../../components/Tasks/icons/trash.svg';
import Unwatched from '../../components/Tasks/icons/unwatched.svg';
import Watched from '../../components/Tasks/icons/watched.svg';
import { PriorityPills } from '../../components/Tasks/TaskPriorityDropdown';
import TaskStaffSearch from '../../components/Tasks/TaskStaffSearch';
import {
  getStatusColor,
  getStatusDisplay,
  StatusDropdown,
} from '../../components/Tasks/TaskStatus';
import { TaskViewTabs } from '../../components/Tasks/TaskViewTabs';
import { getTypeLabel } from '../../components/Tasks/types';
import {
  DullText,
  LargeStaffImage,
  rightSideText,
  SmallText,
  StaffImage,
  TaskTitle,
  TextDisplay,
} from '../../components/Tasks/ui';
import { PrimaryText } from '../../components/text';
import {
  formatDateOfBirth,
  formatPhoneNumber,
  getDateOfBirth,
} from '../../helpers';
import { useScheduledRegion } from '../../hooks';
import { useTrackEvent } from '../../lib/analytics';
import { getPatientName } from '../../lib/patient';
import { useCurrentStaff } from '../../lib/staff';
import {
  getActiveAssignments,
  getAssociations,
  getIsTaskWatched,
  getResponsible,
  hasReadTask,
  isResponsible,
  useTasks,
} from '../../lib/task';

const NewVisitIcon = () => (
  <div
    css={{
      display: 'flex',
      width: Spacing.xl,
      height: Spacing.xl,
      borderRadius: 20,
      backgroundColor: Colors.grayLighter,
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img
      alt=""
      src={ICONS.newVisit}
      css={{ width: Spacing.m, height: Spacing.m }}
    />
  </div>
);

const Row = ({
  icon,
  title,
  children,
}: {
  icon: string;
  title: string;
  children: React.ReactNode;
}) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      paddingBottom: Spacing.m,
    }}
    data-testid={title?.replace(/ /g, '_')?.toLowerCase()}
  >
    <div
      css={{
        width: '33%',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <img alt="" css={{ width: '18px' }} src={icon} />
      <DullText>{title}</DullText>
    </div>
    <div css={{ flex: 1 }}>{children}</div>
  </div>
);

type TaskNotification = NonNullable<
  NonNullable<WatchTaskByIdSubscription['tasks_by_pk']>['tasks_notifications']
>[0];

const ViewTask = ({
  match,
  history,
  dismissPath,
}: RouteComponentProps<{ taskId: string }> & { dismissPath: string }) => {
  const taskId = match.params.taskId;
  const currentStaff = useCurrentStaff();
  const {
    updateTask,
    assignTask,
    unassignTask,
    markTaskRead,
    markAsUnread,
    unassignTaskPending,
    unreadLoading,
    watchTask,
    unwatchTask,
    watchingPending,
  } = useTasks();
  const scheduledRegion = useScheduledRegion();

  const { data } = useWatchTaskByIdSubscription({
    skip: !currentStaff?.id,
    variables: {
      id: taskId,
      staffId: currentStaff?.id!,
    },
  });

  const trackEvent = useTrackEvent();

  const task = data?.tasks_by_pk ?? undefined;
  const assigned = getActiveAssignments(task)?.[0];
  const isCompleted = task?.status === 'COMPLETED';
  const isCreatedByCurrentStaff = task?.createdBy === currentStaff?.id;
  const claimedForSelf =
    assigned?.staff?.id && assigned?.staff.id === currentStaff?.id;
  const isAction = task?.type === 'ACTION';
  const isMemo = task?.type === 'INFORMATION';
  const responsible = getResponsible(task) as any as TaskNotification;
  const hasRead = hasReadTask(task, currentStaff?.id) && isMemo;
  const canClaim = isResponsible(task, currentStaff?.id);
  const associations = getAssociations(task) as any as TaskNotification[];
  const isTaskWatched = getIsTaskWatched(task);

  let responsibleDisplay: string | undefined = '';
  let readCount = 1;

  if (responsible?.taskGroupId) {
    responsibleDisplay = responsible.tasks_group?.name;
  } else if (responsible?.staffId) {
    responsibleDisplay = `${responsible.staff?.firstName} ${responsible.staff?.lastName}`;
  }

  if (responsible?.taskGroupId) {
    readCount =
      responsible.tasks_group?.tasks_groups_staffs_aggregate.aggregate?.count ??
      1;
  }

  let staffForReading: Partial<StaffFragment>[] = [];
  responsible?.tasks_group?.tasks_groups_staffs?.forEach(({ staff }) => {
    staffForReading.push(staff);
  });
  if (responsible?.staffId && responsible.staff) {
    staffForReading.push(responsible.staff);
  }

  const assignableStaff = responsible?.tasks_group?.tasks_groups_staffs?.map(
    ({ staff }) => staff,
  );

  const canHandleClaims = canClaim && task?.status === 'OPEN' && isAction;

  return (
    <Fragment>
      <div
        css={{
          padding: '0 0 40px 0',
          position: 'relative',
        }}
        data-testid="view-task"
      >
        {task && (
          <Fragment>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                padding: '20px 40px',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <TransparentButton
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  disabled={watchingPending}
                  onClick={() => {
                    try {
                      if (isTaskWatched) {
                        unwatchTask(task.id);
                      } else {
                        watchTask(task.id);
                      }
                    } catch (error) {}
                  }}
                >
                  <img alt="" src={isTaskWatched ? Watched : Unwatched} />
                  <DullText
                    css={{ marginLeft: '7px', textDecoration: 'underline' }}
                  >
                    {isTaskWatched ? 'Unfollow' : 'Follow'}
                  </DullText>
                </TransparentButton>
              </div>
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {task.active &&
                  ((!isCompleted && claimedForSelf) ||
                    isCreatedByCurrentStaff) && (
                    <Link to={`${dismissPath}/${taskId}/edit`}>
                      <img
                        alt=""
                        src={PencilIcon}
                        css={{ width: '24px', height: '24px' }}
                      />
                    </Link>
                  )}
                {task.active && !isCompleted && isCreatedByCurrentStaff && (
                  <TransparentButton
                    css={{
                      margin: '0 16px',
                      display: 'flex',
                    }}
                    onClick={() => {
                      updateTask(task.id, {
                        active: false,
                      });
                      trackEvent({
                        type: 'DELETE_TASK',
                        data: {
                          taskId: task.id,
                          currentStaffId: currentStaff?.id,
                          active: false,
                        },
                      });
                    }}
                  >
                    <img
                      alt=""
                      src={TrashIcon}
                      css={{ width: '23px', height: '23px' }}
                    />
                  </TransparentButton>
                )}
                <Link to={dismissPath} css={{ display: 'flex' }}>
                  <img
                    alt=""
                    src={CloseIcon}
                    css={{ width: '24px', height: '24px' }}
                  />
                </Link>
              </div>
            </div>
            {!task.active && (
              <div
                css={{
                  display: 'flex',
                  padding: '0 40px',
                  marginBottom: '16px',
                }}
              >
                <DeletedTask
                  onUndo={() => {
                    updateTask(task.id, {
                      active: true,
                    });
                    trackEvent({
                      type: 'UNDO_DELETE_TASK',
                      data: {
                        taskId: task.id,
                        currentStaffId: currentStaff?.id,
                        active: true,
                      },
                    });
                  }}
                />
              </div>
            )}
            <div
              css={{
                borderBottom: '1px solid #E5E8E8',
              }}
            />
            <div
              css={{
                padding: '0 40px',
              }}
            >
              <TaskTitle
                css={{
                  marginTop: '16px',
                  marginBottom: '24px',
                }}
              >
                {task.title}
              </TaskTitle>
              {!!task.staff && (
                <Row icon={VIEW_ICONS.view_person} title="Created By">
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <StaffImage
                      src={task.staff.avatarUrl ?? undefined}
                      css={{
                        marginRight: '8px',
                      }}
                    />
                    <DullText>
                      {task.staff.firstName} {task.staff.lastName}
                    </DullText>
                  </div>
                </Row>
              )}
              <Row icon={VIEW_ICONS.view_due} title="Due Date">
                <DueDateTime
                  dueDateTime={task.dueDateTime!}
                  isCompleted={isCompleted || hasRead}
                  css={rightSideText}
                />
              </Row>
              <Row icon={VIEW_ICONS.view_responsible} title="Responsible">
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <DullText css={rightSideText}>{responsibleDisplay}</DullText>
                  {canClaim && isMemo && hasRead && (
                    <GreenOutlineBorderButton
                      css={{
                        display: 'flex',
                        padding: '5px 8px',
                        marginLeft: !!responsibleDisplay ? '8px' : undefined,
                      }}
                      disabled={unreadLoading}
                      onClick={async () => {
                        await markAsUnread(task.id);
                      }}
                    >
                      <img alt="" src={CompleteCheck} />
                      <span css={[{ marginLeft: '8px' }, rightSideText]}>
                        Read
                      </span>
                    </GreenOutlineBorderButton>
                  )}
                  {canClaim && isMemo && !hasRead && (
                    <OutlineButtonDark
                      onClick={async () => {
                        await markTaskRead(taskId);
                      }}
                      disabled={unreadLoading}
                      css={{
                        borderRadius: '100px',
                        marginLeft: !!responsibleDisplay ? '16px' : undefined,
                        padding: '5px 8px',
                        '&:hover img': { filter: 'none' },
                      }}
                    >
                      <img alt="" src={GreenCheck} />
                      <span css={[{ marginLeft: '8px' }, rightSideText]}>
                        Mark Read
                      </span>
                    </OutlineButtonDark>
                  )}
                </div>
              </Row>
              {task.priority && (
                <Row icon={VIEW_ICONS.view_priority} title="Priority">
                  {PriorityPills[task?.priority]}
                </Row>
              )}
              {isAction && (
                <Row icon={VIEW_ICONS.view_claimed} title="Claimed by">
                  {assigned && (
                    <div
                      css={{
                        border: canHandleClaims
                          ? '1px solid #D6DBDB'
                          : undefined,
                        padding: canHandleClaims ? '3px 4px' : undefined,
                        borderRadius: '100px',
                        display: 'inline-block',
                      }}
                    >
                      <div
                        css={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <StaffImage
                          src={assigned.staff.avatarUrl ?? undefined}
                          css={{
                            marginRight: '8px',
                          }}
                        />
                        <DullText>
                          {assigned.staff.firstName} {assigned.staff.lastName}
                        </DullText>
                        {canHandleClaims && (
                          <TransparentButton
                            onClick={async () => {
                              if (currentStaff) {
                                await unassignTask(assigned.id);
                                trackEvent({
                                  type: 'UNCLAIM_TASK',
                                  data: {
                                    taskId: task.id,
                                    currentStaffId: currentStaff?.id,
                                  },
                                });
                              }
                            }}
                            disabled={!!unassignTaskPending}
                            css={{
                              marginLeft: '8px',
                            }}
                            data-testid="view_unclaim_task"
                          >
                            <img alt="" src={RoundClear} />
                          </TransparentButton>
                        )}
                      </div>
                    </div>
                  )}
                  {!assigned && canClaim && (
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        css={{
                          maxWidth: '130px',
                          marginRight: '8px',
                        }}
                      >
                        <TaskStaffSearch
                          options={assignableStaff ?? []}
                          onChange={(staff) => {
                            if (staff) {
                              assignTask({
                                taskId: task.id,
                                staffId: staff.id!,
                              });
                            }
                          }}
                        />
                      </div>
                      <OutlineButtonDark
                        onClick={async () => {
                          if (currentStaff) {
                            await assignTask({
                              taskId: task.id,
                              staffId: currentStaff.id,
                            });
                          }
                        }}
                        css={{
                          borderRadius: '100px',
                          padding: '5px 8px',
                          minHeight: '32px',
                        }}
                      >
                        Claim Task
                      </OutlineButtonDark>
                    </div>
                  )}
                </Row>
              )}

              {isAction && (
                <Row icon={VIEW_ICONS.view_status} title="Status">
                  <div css={{ display: 'flex' }}>
                    {canClaim && claimedForSelf && (
                      <StatusDropdown
                        value={task.status}
                        onChange={async (status) => {
                          await updateTask(taskId, {
                            status,
                          });
                          trackEvent({
                            type: 'UPDATE_TASK_STATUS',
                            data: {
                              taskId: task.id,
                              currentStaffId: currentStaff?.id,
                              status,
                            },
                          });
                        }}
                        css={{
                          border: 'none',
                          paddingLeft: 0,
                        }}
                      />
                    )}
                    {canClaim && claimedForSelf && !isCompleted && (
                      <OutlineButtonDark
                        onClick={async () => {
                          await updateTask(taskId, {
                            status: 'COMPLETED',
                          });
                          trackEvent({
                            type: 'TASK_COMPLETED',
                            data: {
                              taskId: task.id,
                              currentStaffId: currentStaff?.id,
                            },
                          });
                        }}
                        css={{
                          borderRadius: '100px',
                          marginLeft: '16px',
                          padding: '5px 8px',
                          '&:hover img': { filter: 'none' },
                        }}
                      >
                        <img alt="" src={GreenCheck} />
                        <span css={{ marginLeft: '8px', fontSize: '14px' }}>
                          Mark Complete
                        </span>
                      </OutlineButtonDark>
                    )}
                  </div>
                  {!claimedForSelf && (
                    <DullText
                      css={{
                        color: getStatusColor(task.status),
                      }}
                    >
                      {getStatusDisplay(task.status)}
                    </DullText>
                  )}
                </Row>
              )}
              <Row icon={VIEW_ICONS.view_type} title="Type">
                <DullText>{getTypeLabel(task.subType ?? undefined)}</DullText>
              </Row>
            </div>
            {!!associations.length && (
              <div
                css={{
                  borderTop: '1px solid #D6DBDB',
                  borderBottom: '1px solid #D6DBDB',
                  padding: '24px 40px',
                }}
              >
                <div
                  css={{
                    marginBottom: '16px',
                  }}
                >
                  <DullText>Associations</DullText>
                </div>
                {associations.map((association, index) => {
                  const isLast = associations.length - 1 === index;

                  if (association.appointmentId) {
                    const appointment = association.appointment;

                    const date = DateTime.fromISO(appointment?.startTime!);
                    const careTypeTitle = appointment?.visitClassification
                      ? VISIT_CLASSIFICATIONS[appointment.visitClassification]
                      : appointment?.visitType
                      ? VISIT_TYPES[appointment.visitType]
                      : '';

                    return (
                      <div
                        css={{
                          display: 'flex',
                          flex: 1,
                          alignItems: 'center',
                          borderBottom: isLast
                            ? undefined
                            : '1px solid #D6DBDB',
                          cursor: 'pointer',
                          padding: '16px 0',
                          paddingBottom: isLast ? '0px' : undefined,
                        }}
                        onClick={() => {
                          history.push(
                            `/appointment/${association.appointmentId}/visit`,
                          );
                        }}
                        aria-role="button"
                      >
                        <NewVisitIcon />
                        <div
                          css={{
                            flex: 1,
                            flexDirection: 'column',
                            display: 'flex',
                            paddingLeft: '16px',
                          }}
                        >
                          <PrimaryText
                            css={{
                              display: 'inline-block',
                              marginBottom: '4px',
                            }}
                          >
                            {date.toFormat('MM/dd/yyyy')} for {careTypeTitle}
                          </PrimaryText>
                          <SmallText>{appointment?.reason}</SmallText>
                        </div>
                      </div>
                    );
                  } else if (association.patientId && association.patient) {
                    const patient = association.patient;

                    const dob =
                      patient?.dateOfBirth &&
                      `${formatDateOfBirth(
                        patient?.dateOfBirth,
                      )} (${getDateOfBirth({
                        date: patient?.dateOfBirth,
                      })})`;
                    return (
                      <div
                        css={{
                          display: 'flex',
                          flex: 1,
                          alignItems: 'center',
                          borderBottom: isLast
                            ? undefined
                            : '1px solid #D6DBDB',
                          cursor: 'pointer',
                          padding: '16px 0',
                          paddingBottom: isLast ? '0px' : undefined,
                        }}
                        onClick={() => {
                          history.push(
                            `/patient/${association.patientId}/profile`,
                          );
                        }}
                        aria-role="button"
                      >
                        <PatientAvatarDisplay
                          patientId={patient?.id}
                          size={40}
                        />
                        <div
                          css={{
                            flex: 1,
                            flexDirection: 'column',
                            display: 'flex',
                            paddingLeft: '16px',
                          }}
                        >
                          <div
                            css={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <PrimaryText
                              css={{
                                display: 'inline-block',
                                marginBottom: '4px',
                              }}
                            >
                              {patient && getPatientName(patient)}
                            </PrimaryText>

                            {patient?.kareo_patient_id && (
                              <div
                                css={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginLeft: '16px',
                                }}
                              >
                                <img alt="" src={ICONS.kareoLinked} />
                                <a
                                  href={`https://app.kareo.com/notes-ui/#/notes-list/${patient?.kareo_patient_id}`}
                                  target="_blank"
                                  css={{
                                    textDecoration: 'none',
                                    marginLeft: '6px',
                                    cursor: 'pointer',
                                    ':hover': {
                                      textDecoration: 'underline',
                                      textDecorationColor: Colors.green,
                                    },
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <PrimaryText
                                    css={{
                                      ':hover': {
                                        color: Colors.green,
                                      },
                                    }}
                                  >
                                    Kareo Notes{' '}
                                  </PrimaryText>
                                </a>
                              </div>
                            )}
                          </div>

                          <SmallText>{dob}</SmallText>
                        </div>
                      </div>
                    );
                  } else if (association.accountId) {
                    const account = association.account;
                    return (
                      <div
                        css={{
                          display: 'flex',
                          flex: 1,
                          alignItems: 'center',
                          borderBottom: isLast
                            ? undefined
                            : '1px solid #D6DBDB',
                          cursor: 'pointer',
                          padding: `${Spacing.s}px 0`,
                          paddingBottom: isLast ? '0px' : undefined,
                        }}
                        onClick={() => {
                          history.push(
                            `/account/${association.accountId}/profile`,
                          );
                        }}
                        aria-role="button"
                      >
                        <AccountAvatarDisplay
                          accountId={association.accountId}
                          size={40}
                        />
                        <div
                          css={{
                            flex: 1,
                            flexDirection: 'column',
                            display: 'flex',
                            paddingLeft: '16px',
                          }}
                        >
                          <PrimaryText
                            css={{
                              display: 'inline-block',
                              marginBottom: '4px',
                            }}
                          >
                            {account?.firstName} {account?.lastName}
                          </PrimaryText>
                          <SmallText>
                            {formatPhoneNumber(account?.phoneNumber)}
                          </SmallText>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            )}

            {!!task.note && (
              <div css={{ padding: '40px 40px 24px 40px' }}>
                <div css={{ marginBottom: '8px' }}>
                  <DullText>Description</DullText>
                </div>
                <TextDisplay>
                  <Linkify
                    options={{
                      attributes: {
                        target: '_blank',
                        rel: 'noopener noreferrer',
                      },
                      //@ts-ignore
                      ignoreTags: ['script', 'style'],
                    }}
                  >
                    {task.note}
                  </Linkify>
                </TextDisplay>
              </div>
            )}
            {task.type === 'INFORMATION' && (
              <div
                css={{
                  padding: '24px 40px',
                  borderTop: '1px solid #E5E8E8',
                }}
              >
                <div
                  css={{
                    marginBottom: '16px',
                  }}
                >
                  <DullText>
                    Read by {task?.tasks_reads?.length ?? 0} out of {readCount}
                  </DullText>
                </div>

                <div css={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                  {/* If there is a single staff get it. Lets just build an array */}
                  {staffForReading?.map((taskStaff) => {
                    const staffId = taskStaff.id;
                    const personHasRead = task?.tasks_reads?.find(
                      ({ staff }) => {
                        return staff.id === staffId;
                      },
                    );
                    return (
                      <Tooltip
                        label={`${taskStaff.firstName} ${taskStaff.lastName}`}
                        style={{
                          background: 'hsla(0, 0%, 0%, 0.75)',
                          color: 'white',
                          border: 'none',
                          borderRadius: '4px',
                          padding: '0.5em 1em',
                          zIndex: 9999,
                        }}
                      >
                        <LargeStaffImage
                          css={{
                            opacity: personHasRead ? '1' : '.5',
                          }}
                          src={taskStaff.avatarUrl ?? undefined}
                        />
                      </Tooltip>
                    );
                  })}
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>
      <div>
        <TaskViewTabs taskId={taskId} hasFiles={!!task?.tasks_files?.length} />
      </div>
    </Fragment>
  );
};

export default ViewTask;
