/** @jsx jsx */
import {
  StaffFragment,
  TaskAssignmentFragment,
  useLoadTaskGroupStaffsQuery,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import Popover, { positionMatchWidth } from '@reach/popover';
import { useField } from 'formik';
import { matchSorter } from 'match-sorter';
import { useRef, useState } from 'react';
import SearchIcon from '../../assets/search.svg';
import { InputWithIcon } from '../form';
import { SmallPrimaryText } from '../text';

const fuzzyFilter = (
  input: string,
  values?: Partial<StaffFragment>[],
): Partial<StaffFragment>[] => {
  if (!values) return [];

  return matchSorter(values, input, {
    keys: ['firstName', 'lastName'],
  });
};

const RowImage = styled.img({
  width: '40px',
  height: '40px',
  borderRadius: '20px',
});

const Row = styled.div({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  ':hover': {
    background: Colors.grayLightest,
  },
  padding: '24px',
});

const RowContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  paddingLeft: '16px',
});

const RowTitle = styled(SmallPrimaryText)({
  fontWeight: 500,
  marginBottom: '4px',
});

const DropdownContainer = styled.div({
  background: '#FFFFFF',
  border: '1px solid #EFF1F1',
  borderRadius: '16px',
  maxHeight: '460px',
  overflow: 'auto',
});

export const TaskAssignment = ({
  value,
  taskGroupId,
  onChange,
  onClear,
}: {
  value?: string;
  taskGroupId: string;
  onChange: (value: string) => void;
  onClear: () => void;
}) => {
  const [focused, setFocused] = useState(false);
  const [query, setQuery] = useState('');
  const inputRef = useRef<HTMLDivElement>(null);
  const { data } = useLoadTaskGroupStaffsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: taskGroupId,
    },
    skip: !taskGroupId,
  });

  const staffs = data?.tasks_groups_by_pk?.tasks_groups_staffs?.map(
    ({ staff }) => {
      return staff;
    },
  );

  const filteredValues = fuzzyFilter(query, staffs);
  let display: string | undefined = '';

  if (value) {
    const staff = staffs?.find(({ id }) => {
      return id === value;
    });
    if (staff) {
      display = `${staff.firstName} ${staff.lastName}`;
    }
  }

  return (
    <div>
      <InputWithIcon
        icon={SearchIcon}
        value={value ? display : query}
        ref={inputRef}
        name="assign_staff"
        placeholder="Search by staff name, group, or clinic"
        onChange={(e) => {
          if (!value) {
            setQuery(e.target.value);
          }
        }}
        onFocus={() => {
          if (!value) {
            setFocused(true);
          }
        }}
        onBlur={() => {
          setTimeout(() => {
            setFocused(false);
          }, 250);
        }}
        onClear={() => {
          onClear();
          setQuery('');
        }}
      />
      <Popover
        hidden={!focused}
        targetRef={inputRef}
        position={positionMatchWidth}
        css={{
          zIndex: 100000,
        }}
      >
        <DropdownContainer data-testid="assignment_dropdown">
          {filteredValues?.map((value, index) => {
            const { id, firstName, lastName, avatarUrl } = value;
            return (
              <Row
                key={index}
                onClick={() => {
                  onChange(id!);
                  setFocused(false);
                  setQuery('');
                }}
              >
                <RowImage
                  src={
                    avatarUrl ?? 'https://bravecare.imgix.net/staff/empty.png'
                  }
                />
                <RowContent>
                  <RowTitle>
                    {firstName} {lastName}
                  </RowTitle>
                </RowContent>
              </Row>
            );
          })}
        </DropdownContainer>
      </Popover>
    </div>
  );
};

const filterAssignment = (assignemnts: Partial<TaskAssignmentFragment>[]) => {
  return assignemnts?.find((assignment) => {
    return assignment.assignedTo && assignment.active !== false;
  });
};

export const TaskAssignmentField = ({
  name,
  taskGroupId,
}: {
  name: string;
  taskGroupId: string;
}) => {
  const [field, _, helpers] = useField(name);
  const value = filterAssignment(field.value);

  return (
    <TaskAssignment
      value={value?.assignedTo}
      taskGroupId={taskGroupId}
      onClear={() => {
        helpers.setValue([]);
      }}
      onChange={(value: string) => {
        helpers.setValue([
          {
            assignedTo: value,
          },
        ]);
      }}
    />
  );
};
