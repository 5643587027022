import styled from '@emotion/styled';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import React from 'react';
import { ButtonRed } from '../../components/button';
import { ModalText, ModalTitle } from '../../components/text';
import { CancelButton } from './styles';

const SmallModal = styled(DialogContent)`
  width: 328px;
  margin: 30vh auto 0 auto;
  padding: 24px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StaffRemovalConfirmation: React.FC<{
  isOpen: boolean;
  onCancel: any;
  onRemove: () => void;
}> = ({ isOpen, onCancel, onRemove }) => (
  <DialogOverlay
    isOpen={isOpen}
    onDismiss={onCancel}
    style={{
      zIndex: 100,
    }}
  >
    <SmallModal aria-label="Remove Staff">
      <ModalTitle>Are you sure?</ModalTitle>
      <ModalText>
        Once you remove a scheduled shift, you can’t undo it.
      </ModalText>
      <ButtonRed
        tabIndex={0}
        style={{ display: 'inline-block' }}
        onClick={onRemove}
      >
        Remove
      </ButtonRed>
      <CancelButton style={{ display: 'inline-block' }} onClick={onCancel}>
        Cancel
      </CancelButton>
    </SmallModal>
  </DialogOverlay>
);

export default StaffRemovalConfirmation;
