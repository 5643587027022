import {
  InputMaybe,
  InsuranceFragment,
  Insurance_Types_Enum,
  useAddInsuranceMutation,
  useUpdateInsuranceMutation,
} from '@bc/codegen/medical';
import { dateOfBirthYupSchema, getCarrierName } from '@bc/shared';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import React, { Fragment, useState } from 'react';
import { object, string } from 'yup';
import { InsuranceImageDisplay } from '../AppointmentDetails/AvatarDisplay';
import { InsuranceAuditLog } from '../Audit';
import { Button, ButtonRed } from '../button';
import CarrierSearchInput from '../CarrierSearch/search';
import CopyToClip from '../copy';
import {
  CopyToClipField,
  DateOfBirthField,
  ErrorText,
  NormalField,
  SelectSimpleField,
} from '../form';
import { ContentRow, HeaderEdit, InfoSection } from '../layout';
import { DefinitionList, DefinitionListItem } from '../List';
import { SmallTitle } from '../text';
import InsuranceDeleteConfirmation from './InsuranceDeleteConfirmation';

const InsuranceValidation = object().shape({
  accountId: string().required('Required'),
  carrier: string().required('Required'),
  memberId: string().required('Required'),
  type: string().required('Required'),
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  dateOfBirth: dateOfBirthYupSchema(),
});

interface Accounts {
  accountId: string;
  firstName: string;
  lastName: string;
}

export const InsuranceForm: React.FC<{
  title: string;
  insurance?: Partial<InsuranceFragment>;
  type: string;
  accounts: Accounts[];
  patientId: string;
}> = ({ insurance = {}, type, title, accounts, patientId }) => {
  const [addInsurance] = useAddInsuranceMutation();
  const [updateInsurance] = useUpdateInsuranceMutation();
  const [isOpen, setOpen] = useState(false);

  const handleDeleteInsurance = async (id: string) => {
    await updateInsurance({
      variables: {
        insuranceId: id,
        set: {
          active: false,
        },
      },
    });
  };

  const handleUpdateInsurance = async (
    values: Partial<InsuranceFragment>,
    { setStatus }: any,
  ) => {
    const dateOfBirth = DateTime.fromFormat(
      values.dateOfBirth!,
      'MM/dd/yy',
    ).toFormat('yyyy-MM-dd');

    if (values.id) {
      await updateInsurance({
        variables: {
          insuranceId: values.id,
          set: {
            type: type as InputMaybe<Insurance_Types_Enum>,
            carrier: values.carrier,
            memberId: values.memberId,
            firstName: values.firstName,
            lastName: values.lastName,
            dateOfBirth,
            accountId: values.accountId,
            patientId,
          },
        },
      });
    } else {
      await addInsurance({
        variables: {
          insurance: {
            type: type as InputMaybe<Insurance_Types_Enum>,
            carrier: values.carrier,
            memberId: values.memberId,
            firstName: values.firstName,
            lastName: values.lastName,
            dateOfBirth,
            accountId: values.accountId,
            patientId,
          },
        },
      });
    }

    setStatus({ editing: false });
  };

  const getAccountFromId = (value: string) =>
    accounts.find(({ accountId }) => value === accountId);

  return (
    <Fragment>
      <Formik
        initialValues={{
          id: insurance.id,
          type: (insurance.type || type) as 'PRIMARY' | 'SECONDARY',
          carrier: insurance.carrier || '',
          memberId: insurance.memberId || '',
          firstName: insurance.firstName || '',
          lastName: insurance.lastName || '',
          dateOfBirth: insurance.dateOfBirth
            ? DateTime.fromFormat(insurance.dateOfBirth, 'yyyy-MM-dd').toFormat(
                'MM/dd/yyyy',
              )
            : '',
          accountId: insurance.accountId || '',
        }}
        enableReinitialize
        validationSchema={InsuranceValidation}
        initialStatus={{ editing: false }}
        onSubmit={handleUpdateInsurance}
      >
        {({ status, setStatus, setFieldValue, values, resetForm }) => {
          return (
            <Form>
              <HeaderEdit
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SmallTitle style={{ flex: 'unset', marginRight: 12 }}>
                      {title} Insurance
                    </SmallTitle>
                    {insurance?.id && (
                      <InsuranceAuditLog insuranceId={insurance.id} size={24} />
                    )}
                  </div>
                }
                editing={status.editing}
                onEdit={() => setStatus({ editing: true })}
                onCancel={() => {
                  setStatus({ editing: false });
                  resetForm();
                }}
              />
              {!status.editing && (
                <DefinitionList>
                  <DefinitionListItem term="Carrier">
                    <CopyToClipField
                      name="carrier"
                      transform={(value) => getCarrierName(value) || '-'}
                    />
                  </DefinitionListItem>
                  <DefinitionListItem term="Member ID">
                    <CopyToClipField
                      name="memberId"
                      transform={(value) => value || '-'}
                    />
                  </DefinitionListItem>
                  <DefinitionListItem term="Policyholder Name">
                    <CopyToClip text={`${values.firstName} ${values.lastName}`}>
                      {values.firstName && values.lastName
                        ? `${values.firstName} ${values.lastName}`
                        : '-'}
                    </CopyToClip>
                  </DefinitionListItem>
                  <DefinitionListItem term="Caregiver">
                    <CopyToClipField
                      name="accountId"
                      transform={(value) => {
                        const account = getAccountFromId(value);
                        return account
                          ? `${account.firstName} ${account.lastName}`
                          : '-';
                      }}
                    />
                  </DefinitionListItem>
                  <DefinitionListItem term="Policyholder Birth Date">
                    <CopyToClipField
                      name="dateOfBirth"
                      transform={(value) => value || '-'}
                    />
                  </DefinitionListItem>
                </DefinitionList>
              )}
              {status.editing && (
                <Fragment>
                  <ContentRow>
                    <InfoSection title="Carrier">
                      <Field name="carrier">
                        {({ field, meta }: FieldProps<any>) => (
                          <Fragment>
                            <CarrierSearchInput
                              error={meta.touched && !!meta.error}
                              onChange={(carrierId) => {
                                setFieldValue('carrier', carrierId);
                              }}
                              value={
                                field.value ? getCarrierName(field.value) : ''
                              }
                            />
                            <ErrorMessage
                              component={ErrorText}
                              name={field.name}
                            />
                          </Fragment>
                        )}
                      </Field>
                    </InfoSection>
                    <NormalField title="Member ID" name="memberId" />
                  </ContentRow>
                  <ContentRow>
                    <NormalField
                      title="Policyholder First Name"
                      name="firstName"
                    />
                    <NormalField
                      title="Policyholder Last Name"
                      name="lastName"
                    />
                  </ContentRow>
                  <ContentRow>
                    <InfoSection title="Caregiver">
                      <SelectSimpleField name="accountId">
                        <option value="">Select</option>
                        {accounts.map((account) => (
                          <option
                            key={account.accountId}
                            value={account.accountId}
                          >
                            {account.firstName} {account.lastName}
                          </option>
                        ))}
                      </SelectSimpleField>
                      <ErrorMessage component={ErrorText} name="accountId" />
                    </InfoSection>
                    <InfoSection title="Policyholder Birth Date">
                      <DateOfBirthField name="dateOfBirth" />
                    </InfoSection>
                  </ContentRow>
                  <ContentRow>
                    {values.id && (
                      <ButtonRed type="button" onClick={() => setOpen(true)}>
                        Delete
                      </ButtonRed>
                    )}
                    <Button type="submit">Save</Button>
                  </ContentRow>
                </Fragment>
              )}
              <ContentRow>
                <InsuranceImageDisplay insuranceId={insurance.id} />
              </ContentRow>
              <InsuranceDeleteConfirmation
                isOpen={isOpen}
                onCancel={() => setOpen(false)}
                onRemove={async () => {
                  await handleDeleteInsurance(values.id!);
                  setOpen(false);
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};
