/** @jsx jsx */
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { Fragment } from 'react';
import { PrimaryText } from '../text';
import { TaskViewActivity } from './TaskActivity';
import { TaskViewFiles } from './TaskFiles';
import { TaskViewComments } from './TasksComments';

const Title = styled(PrimaryText)({
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '22px',
});

export const TaskViewTabs = ({
  taskId,
  hasFiles,
}: {
  taskId: string;
  hasFiles: boolean;
}) => {
  let tabs = ['Comments', 'Activity'];
  if (hasFiles) {
    tabs = ['Comments', 'Files', 'Activity'];
  }

  return (
    <Tabs
      style={{
        color: Colors.darkHarbor,
      }}
    >
      {({ selectedIndex }) => {
        return (
          <Fragment>
            <TabList
              style={{
                background: 'none',
                display: 'flex',
              }}
            >
              {tabs.map((name, index) => {
                return (
                  <Tab
                    css={{
                      flex: 1,
                      padding: '8px 0',
                      borderBottomWidth: '2px',
                      outline: 'none',
                    }}
                  >
                    <PrimaryText
                      css={{
                        fontWeight: 500,
                        color: selectedIndex === index ? undefined : '#647977',
                      }}
                    >
                      {name}
                    </PrimaryText>
                  </Tab>
                );
              })}
            </TabList>
            <TabPanels
              css={{
                padding: '32px',
                background: '#F3F5F5',
              }}
            >
              <TabPanel>
                <Title>Comments</Title>
                <div css={{ padding: '24px 0' }}>
                  <TaskViewComments taskId={taskId} />
                </div>
              </TabPanel>
              {hasFiles && (
                <TabPanel>
                  <Title>Files</Title>
                  <div css={{ padding: '24px 0' }}>
                    <TaskViewFiles taskId={taskId} />
                  </div>
                </TabPanel>
              )}
              <TabPanel>
                <Title>Activity</Title>
                <div css={{ padding: '24px 0' }}>
                  <TaskViewActivity taskId={taskId} />
                </div>
              </TabPanel>
            </TabPanels>
          </Fragment>
        );
      }}
    </Tabs>
  );
};
