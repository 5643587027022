import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string;
}

const Appointments: React.FC<Props> = ({ color, ...props }) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <circle cx={12} cy={12} r={11} stroke={color} strokeWidth={2} />
    <path
      d="M11.5 7v5.5l4 1.5"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Appointments;
