import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string;
}

const SvgComponent: React.FC<Props> = ({ color, ...props }) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M23 22c0 .5-.5 1-1 1H2c-.5 0-1-.5-1-1V4c0-.5.5-1 1-1h20c.5 0 1 .5 1 1v18zM8 1v4M16 1v4"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 11a1 1 0 102 0 1 1 0 00-2 0zM17 17a1 1 0 102 0 1 1 0 00-2 0zM5 11a1 1 0 102 0 1 1 0 00-2 0zM5 17a1 1 0 102 0 1 1 0 00-2 0zM11 11a1 1 0 102 0 1 1 0 00-2 0zM11 17a1 1 0 102 0 1 1 0 00-2 0z"
      fill={color}
      stroke={color}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
