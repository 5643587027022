/** @jsx jsx */
import { ChatMessageFragment, StaffFragment } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React from 'react';
import enterIcon from '../../assets/enter.svg';
import exitIcon from '../../assets/exit.svg';
import personRequestedIcon from '../../assets/person-requested.svg';
import stopIcon from '../../assets/stop.svg';
import { PrimaryText } from '../../components/text';
import { FONT_PRIMARY } from '../../styles';

const ICON = {
  STAFF_ENDED: stopIcon,
  STAFF_JOINED: enterIcon,
  STAFF_LEFT: exitIcon,
  STAFF_REQUESTED_PROVIDER: personRequestedIcon,
};

export const ChatSystemMessage: React.FC<{
  currentStaffId: string;
  message: ChatMessageFragment & {
    staff?: StaffFragment | null | undefined;
  };
}> = ({ currentStaffId, message }) => {
  const staffName =
    currentStaffId === message.staffId
      ? 'You'
      : `${message.staff?.firstName} ${message.staff?.lastName}`;

  // @ts-ignore
  const icon = ICON[message.data.action];

  let text = message.message;
  if (message.data.action === 'STAFF_JOINED') {
    text = `${staffName} joined the chat.`;
  }
  if (message.data.action === 'STAFF_LEFT') {
    text = `${staffName} left the chat.`;
  }
  if (message.data.action === 'STAFF_REQUESTED_PROVIDER') {
    text = `${staffName} requested a provider to join the chat.`;
  }
  if (message.data.action === 'STAFF_ENDED') {
    text = `${staffName} ended the chat.`;
  }

  return (
    <Container key={message.id}>
      <SystemMessage>
        {icon && <SystemMessageIcon src={icon} />}
        <PrimaryText style={{ padding: '0 10px' }}>{text}</PrimaryText>
      </SystemMessage>
      <Timestamp>
        {DateTime.fromISO(message.createdAt).toFormat('h:mm a')}
      </Timestamp>
    </Container>
  );
};

const Container = styled.div({
  marginBottom: 32,
  alignItems: 'center',
  alignSelf: 'center',
});

export const SystemMessage = styled.div({
  border: `1px solid ${Colors.grayLighter}`,
  alignItems: 'center',
  alignSelf: 'center',
  borderRadius: 100,
  display: 'flex',
  padding: '8px 16px',
});

export const SystemMessageIcon = styled.img({
  height: 16,
  width: 16,
});

const Timestamp = styled.p`
  ${FONT_PRIMARY};
  color: ${Colors.gray};
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
  line-height: 16px;
  margin-top: 8px;
`;
