/** @jsx jsx */
import { useGetAccountByPkQuery } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { CloseButton } from '../components/button';
import { FlagsSidebar } from '../components/Flags';
import Loader from '../components/Loader/loader';
import { AccountHeader } from '../components/ModalHeader';
import { Content, ModalWrap, TaskContent } from '../components/Routing';
import { TaskCreateDropdown } from '../components/Tasks/TaskCreateDropdown';
import { useScheduledRegion } from '../hooks';
import Patients from '../pages/account/Patients';
import Payment from '../pages/account/Payment';
import Profile from '../pages/account/Profile';
import Tasks from '../pages/account/Tasks';
import { Row } from '../styles';
import CreateTask from './tasks/CreateTask';
import EditTask from './tasks/EditTask';
import ViewTask from './tasks/ViewTask';
import {
  ClosePosition,
  InfoPanel,
  MainContent,
  ModalWrapper,
  NavigationWrapper,
  PaddedLink,
} from './ViewAppointment.styles';

const ViewAccount: React.FC<
  { returnPath?: string } & RouteComponentProps<{
    accountId: string;
  }>
> = ({ returnPath, match, history }) => {
  const { accountId } = match.params;
  const scheduledRegion = useScheduledRegion();
  const { data, loading } = useGetAccountByPkQuery({
    variables: {
      accountId,
    },
  });
  const account = data?.accounts_by_pk;

  return loading ? (
    <Loader />
  ) : (
    <ModalWrapper aria-label="Account">
      <CloseButton
        onClick={() => history.push(returnPath || '/dashboard')}
        css={ClosePosition}
      />
      {account && (
        <NavigationWrapper>
          <PaddedLink icon="profile" to={`${match.url}/profile`}>
            Profile
          </PaddedLink>
          <PaddedLink icon="caregiver" to={`${match.url}/patients`}>
            Patients
          </PaddedLink>
          <PaddedLink icon="billing" to={`${match.url}/payment`}>
            Payment
          </PaddedLink>
          <PaddedLink data-testid="tasks" icon="task" to={`${match.url}/tasks`}>
            Tasks
          </PaddedLink>
        </NavigationWrapper>
      )}
      <MainContent>
        <AccountHeader accountId={account?.id}>
          <Row>
            <TaskCreateDropdown
              display={['TASK']}
              onSelect={(type) => {
                if (type === 'TASK') {
                  if (accountId) {
                    history.push(`/account/${accountId}/tasks/create`);
                  }
                }
              }}
            />
          </Row>
        </AccountHeader>
        {!data && loading && <Loader />}
        {data && account && (
          <Switch>
            <Route
              path={`${match.url}/profile`}
              render={() => <Profile data={account} />}
            />
            <Route
              path={`${match.url}/patients`}
              render={() => <Patients data={account} />}
            />
            <Route
              path={`${match.url}/payments`}
              render={() => <Payment data={account} />}
            />
            <Route
              path={`${match.url}/tasks`}
              render={() => <Tasks account={account} basePath={match.url} />}
            />
          </Switch>
        )}
        {data && account && (
          <Switch>
            <Route
              path={`${match.url}/tasks/:taskId/edit`}
              //@ts-ignore
              render={(props: RouteComponentProps<{ taskId: string }>) => {
                return (
                  <ModalWrap
                    {...props}
                    onDismissGoToPath={`${match.url}/tasks`}
                  >
                    <TaskContent>
                      <EditTask {...props} dismissPath={`${match.url}/tasks`} />
                    </TaskContent>
                  </ModalWrap>
                );
              }}
            />
            <Route
              path={`${match.url}/tasks/create`}
              //@ts-ignore
              render={(props: RouteComponentProps<{ taskId: string }>) => {
                return (
                  <ModalWrap
                    {...props}
                    onDismissGoToPath={`${match.url}/tasks`}
                  >
                    <TaskContent>
                      <CreateTask
                        {...props}
                        defaultAssociations={[
                          {
                            accountId: account.id,
                          },
                        ]}
                        dismissPath={`${match.url}/tasks`}
                      />
                    </TaskContent>
                  </ModalWrap>
                );
              }}
            />
            <Route
              path={`${match.url}/tasks/:taskId`}
              //@ts-ignore
              render={(props: RouteComponentProps<{ taskId: string }>) => {
                return (
                  <ModalWrap
                    {...props}
                    onDismissGoToPath={`${match.url}/tasks`}
                  >
                    <Content>
                      <ViewTask {...props} dismissPath={`${match.url}/tasks`} />
                    </Content>
                  </ModalWrap>
                );
              }}
            />
          </Switch>
        )}
      </MainContent>
      <InfoPanel>
        <FlagsSidebar accountId={accountId} />
      </InfoPanel>
    </ModalWrapper>
  );
};

export default ViewAccount;
