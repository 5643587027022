import Amex from './Amex.svg';
import ApplePay from './ApplePay.svg';
import Citi from './Citi.svg';
import Discover from './Discover.svg';
import GooglePay from './GooglePay.svg';
import MasterCard from './Mastercard.svg';
import Visa from './Visa.svg';

export const LOGOS = {
  'American Express': Amex,
  'Apple Pay': ApplePay,
  Citi,
  'Diners Club': Visa, // TODO: update with actual logo
  Discover,
  'Google Pay': GooglePay,
  JCB: Visa, // TODO: update with actual logo
  MasterCard,
  UnionPay: Visa, // TODO: update with actual logo
  Visa,
  Unknown: Visa, // TODO: update with fallback logo
};
