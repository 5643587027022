import styled from '@emotion/styled';
import { IF_TABLET } from '../../styles';

export const TextWrapper = styled.div({
  display: 'flex',
  marginBottom: 16,
  flexWrap: 'wrap',
  [IF_TABLET]: {
    flexWrap: 'nowrap'
  }
});
