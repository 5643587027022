import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import gearIcon from '../../assets/gear.svg';
import { BackButton, Button, OutlineButtonDark } from '../../components/button';
import { AccountAvatar } from '../../components/ChatMessage';
import { ChatNotePrompt } from '../../components/ChatNote';
import { PrimaryText } from '../../components/text';
import { useAsyncChat } from '../../lib/chat';
import { IF_TABLET } from '../../styles';

const PROVIDER_TYPES = [
  'PHYSICIAN',
  'PHYSICIAN_ASSISTANT',
  'NURSE_PRACTITIONER',
];
const isProvider = (staffTypeValue = '') =>
  PROVIDER_TYPES.includes(staffTypeValue);

export const ChatHeader: React.FC<{ chatSessionId: string }> = ({
  chatSessionId,
}) => {
  const [showActions, setShowActions] = useState(false);
  const [showNotePrompt, setShowNotePrompt] = useState(false);

  const {
    chatSession,
    closeChat,
    currentStaffData,
    joinChat,
    leaveChat,
    requestProvider,
    writeChatNote,
  } = useAsyncChat(chatSessionId);

  const history = useHistory();

  const chatSessionAccount = chatSession?.chat_sessions_accounts?.[0];
  const account = chatSessionAccount?.account;
  const accountName = account
    ? `${account?.firstName}${
        account?.goesByName ? ` ‘${account.goesByName}’ ` : ' '
      }${account?.lastName}`
    : '';
  const patient = chatSession?.chat_sessions_patients?.[0]?.patient;
  const patientName = patient
    ? `${patient?.firstName}${
        patient?.goesByName ? ` ‘${patient?.goesByName}’ ` : ' '
      }${patient?.lastName}`
    : '';

  const staffs = chatSession?.chat_sessions_staffs ?? [];
  const hasActiveProvider = !!staffs.find(
    (s) => isProvider(s.staff.staff_type.value) && s.active,
  );
  const isCurrentStaff = staffs.find(
    (s) => s.staffId === currentStaffData.staffId,
  );
  const isCurrentStaffActive = isCurrentStaff?.active;
  const isChatEnded = chatSession?.status === 'CLOSED';
  const lastActivityDiff = DateTime.fromISO(
    chatSessionAccount?.updatedAt!,
  ).diffNow(['days', 'hours', 'minutes']);
  const lastActivityDays = Math.abs(Math.floor(lastActivityDiff.days));
  const lastActivityHours = Math.abs(Math.floor(lastActivityDiff.hours));
  const lastActivityMinutes = Math.abs(Math.floor(lastActivityDiff.minutes));
  const lastActivityAt = lastActivityDays
    ? lastActivityDays + 'd'
    : lastActivityHours
    ? lastActivityHours + 'h'
    : lastActivityMinutes
    ? lastActivityMinutes + 'm'
    : '';

  if (!chatSession) return null;

  return (
    <Fragment>
      <Container>
        <ActionRow>
          <BackButton onClick={() => history.goBack()} />
          <Actions>
            <StaffAvatars style={{ marginRight: isChatEnded ? 0 : 16 }}>
              {staffs
                .filter((s) => s.active)
                .map((s, i) => (
                  <StaffAvatar
                    key={s.staffId}
                    alt={`${s.staff.firstName} ${s.staff.lastName}`}
                    title={`${s.staff.firstName} ${s.staff.lastName}`}
                    style={{
                      left: i * -28 - 32,
                    }}
                    src={
                      s.staff.avatarUrl ??
                      'https://bravecare.imgix.net/staff/empty.png'
                    }
                  />
                ))}
            </StaffAvatars>
            {!isChatEnded && (
              <Fragment>
                {isCurrentStaffActive ? (
                  <LeaveButton onClick={() => setShowNotePrompt(true)}>
                    Leave Chat
                  </LeaveButton>
                ) : (
                  <JoinButton onClick={joinChat}>
                    {isCurrentStaff ? 'Rejoin' : 'Join'} Chat
                  </JoinButton>
                )}
                <ChatActions onClick={() => setShowActions(!showActions)}>
                  <img src={gearIcon} />
                </ChatActions>
                {showActions && (
                  <Fragment>
                    <ActionsTrayBackdrop
                      onClick={() => setShowActions(false)}
                    />
                    <ActionsTray>
                      {chatSession?.status !== 'CLOSED' && !hasActiveProvider && (
                        <ChatAction
                          onClick={() => {
                            requestProvider();
                            setShowActions(false);
                          }}
                        >
                          Request Provider
                        </ChatAction>
                      )}
                      <ChatAction
                        onClick={() => {
                          closeChat();
                          setShowActions(false);
                        }}
                      >
                        End Chat
                      </ChatAction>
                    </ActionsTray>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Actions>
        </ActionRow>
        <TitleWrap>
          {account && (
            <div style={{ marginRight: 16, position: 'relative' }}>
              <AccountAvatar accountId={account.id} size={48} />
              <StatusIndicator active={!!chatSessionAccount?.active} />
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Title>
              Chat with <TitleBold>{accountName}</TitleBold>
              {!!patient && ' about '}
              {!!patient && <TitleBold>{patientName}</TitleBold>}
            </Title>
            {chatSessionAccount?.active ? (
              <StatusText active>Active {lastActivityAt}</StatusText>
            ) : (
              <StatusText>Idle • Last active {lastActivityAt} ago</StatusText>
            )}
          </div>
        </TitleWrap>
      </Container>
      <ChatNotePrompt
        title="Please write an internal note."
        placeholder="Please write a brief summary of the chat and any next steps."
        isOpen={showNotePrompt}
        onDismiss={async () => {
          setShowNotePrompt(false);
        }}
        onSubmit={async (note: string) => {
          await writeChatNote(note, 'INTERNAL');
          await leaveChat();
          setShowNotePrompt(false);
        }}
        staff={currentStaffData.staff}
      />
    </Fragment>
  );
};

const Container = styled.div({
  background: Colors.white,
  borderBottom: `1px solid ${Colors.grayLighter}`,
  padding: '30px 40px 16px',
});

const ActionRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
});

const Actions = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: 'auto',
  position: 'relative',
});

const ChatActions = styled.button({
  alignItems: 'center',
  backgroundColor: Colors.white,
  border: 'none',
  borderRadius: 16,
  display: 'flex',
  height: 32,
  justifyContent: 'center',
  marginLeft: 12,
  padding: 0,
  width: 32,
  ':hover': {
    backgroundColor: Colors.grayLightest,
    cursor: 'pointer',
  },
});

const ActionsTrayBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

const ActionsTray = styled.div`
  position: absolute;
  top: 32px;
  right: -80px;
  min-width: 180px;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: 24px;
  padding-right: 24px;
  border: 1px solid ${Colors.grayLightest};
  border-radius: 13px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
  background: white;
  z-index: 100;
`;

const ChatAction = styled(PrimaryText)({
  cursor: 'pointer',
  display: 'block',
  fontSize: 16,
  fontWeight: 'bold',
  lineHeight: '24px',
  paddingBottom: 8,
  paddingTop: 8,
}).withComponent('a');

const TitleWrap = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  marginTop: 12,
});

const Title = styled(PrimaryText)({
  fontSize: 18,
  lineHeight: '26px',
  [IF_TABLET]: {
    fontSize: 24,
    lineHeight: '32px',
  },
});

const TitleBold = styled(Title)({
  fontWeight: 'bold',
});

const JoinButton = styled(Button)({
  fontSize: 14,
  lineHeight: '18px',
  padding: '6px 12px',
});

const LeaveButton = styled(OutlineButtonDark)({
  borderRadius: 100,
  fontSize: 14,
  lineHeight: '18px',
  padding: '6px 12px',
});

const StaffAvatars = styled.div({
  marginRight: 16,
  position: 'relative',
});

const StaffAvatar = styled.img({
  border: '1px solid white',
  borderRadius: 16,
  height: 32,
  position: 'absolute',
  top: 0,
  width: 32,
});

const StatusText = styled(PrimaryText)<{ active?: boolean }>(({ active }) => ({
  color: active ? '#1EA027' : Colors.gray,
  fontSize: 14,
  lineHeight: '18px',
}));

const StatusIndicator = styled.div<{ active: boolean }>(({ active }) => ({
  backgroundColor: active ? '#1EA027' : Colors.grayLight,
  border: '2px solid white',
  borderRadius: 6,
  height: 12,
  width: 12,
  position: 'absolute',
  bottom: 2,
  right: 0,
}));
