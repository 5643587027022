import { useGetActivePhoneCallsSubscription } from '@bc/codegen/medical';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useAuth0Id } from '../../react-auth0';

export const useGetActivePhoneCalls = () => {
  const userId = useAuth0Id();
  const { data } = useGetActivePhoneCallsSubscription();

  const [, setNow] = useState(DateTime.local());

  useEffect(() => {
    const interval = setInterval(() => setNow(DateTime.local()), 1000);
    return () => clearInterval(interval);
  }, []);

  const onHoldCalls =
    data?.communication_history.filter((ch) => ch.status === 'ON_HOLD') ?? [];

  const longHeldCalls = onHoldCalls.filter(
    (c) => DateTime.fromISO(c.updatedAt).diffNow('minutes').minutes < -2,
  );
  const longHeldCallCount = longHeldCalls.length;
  const longestHeldCallId = longHeldCalls?.[0]?.id;

  const queuedCalls =
    data?.communication_history.filter(
      (ch) => ch.status === 'QUEUED' && !!ch.reasonForCall,
    ) ?? [];
  const queuedCallsCount = queuedCalls.length;
  const longestQueuedCallId: string | undefined = queuedCalls?.[0]?.id;

  const myInProgressCalls =
    data?.communication_history.filter(
      (ch) => ch.status === 'IN_PROGRESS' && ch.staff?.auth0UserId === userId,
    ) ?? [];
  const hasInProgressCall = myInProgressCalls.length > 0;

  return {
    hasInProgressCall,
    longestHeldCallId,
    longestQueuedCallId,
    longHeldCallCount,
    queuedCallsCount,
  };
};
