import { Colors } from '@bc/theme';
import React from 'react';
import { ICONS } from '../../assets';
import { SmallTitle } from '../text';

const KareoSection = ({
  icon,
  title,
  children,
  open,
  success,
  onToggle,
}: {
  icon: string;
  title: React.ReactNode;
  open: boolean;
  success?: boolean;
  onToggle: () => void;
  children: React.ReactNode;
}) => {
  return (
    <div>
      <div
        style={{
          border: `1px solid ${Colors.grayLighter}`,
          borderRadius: '16px',
        }}
      >
        <div
          style={{
            borderBottom: open ? `1px solid ${Colors.grayLighter}` : undefined,
            display: 'flex',
            padding: '24px',
            alignItems: 'center',
            position: 'relative',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (!success) {
              onToggle();
            }
          }}
        >
          <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <img src={icon} />
            <SmallTitle style={{ marginLeft: '21px' }}>{title}</SmallTitle>
          </div>
          {!success ? (
            <img
              src={ICONS.caretDown}
              style={{
                cursor: 'pointer',
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          ) : (
            <img src={ICONS.successCheckmark} />
          )}
        </div>
        {open && <div>{children}</div>}
      </div>
    </div>
  );
};

export default KareoSection;
