/** @jsx jsx */
import {
  AppointmentPartsFragment,
  PatientPartsFragment,
  useGetPatientByPkQuery,
} from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { OutlineButton } from '../../components/button';
import { CaregiverForm } from '../../components/CaregiverForm';
import { SectionContent } from '../../components/layout';
import { SectionTitle } from '../../components/text';

export const Caregivers: React.FC<{
  appointment?: AppointmentPartsFragment;
  data: PatientPartsFragment;
}> = ({ appointment, data: initialPatient }) => {
  const [showNewCaregiverForm, setShowNewCaregiverForm] = useState(false);

  const { data, refetch } = useGetPatientByPkQuery({
    variables: { patientId: initialPatient.id },
  });
  const patient = data?.patients_by_pk;
  const accounts = patient?.accounts ?? [];
  const sortedAccounts = [...accounts].sort(
    (a, b) =>
      DateTime.fromISO(a?.account?.createdAt).toSeconds() -
      DateTime.fromISO(b?.account?.createdAt).toSeconds(),
  );

  return (
    <SectionContent>
      <div
        css={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 16,
        }}
      >
        <SectionTitle data-testid="caregiversTitle">Caregivers</SectionTitle>
        {!showNewCaregiverForm && (
          <OutlineButton
            css={{
              borderRadius: '20px',
              float: 'right',
              fontSize: '14px',
              img: {
                filter:
                  'brightness(0) saturate(100%) invert(12%) sepia(75%) saturate(3263%) hue-rotate(165deg) brightness(100%) contrast(98%)',
              },
              ':hover img': {
                filter:
                  'brightness(0) saturate(100%) invert(12%) sepia(75%) saturate(3263%) hue-rotate(165deg) brightness(100%) contrast(98%)',
              },
            }}
            icon="addPerson"
            onClick={() => setShowNewCaregiverForm(true)}
          >
            Add Caregiver
          </OutlineButton>
        )}
      </div>
      {showNewCaregiverForm && (
        <CaregiverForm
          patient={patient}
          accounts={accounts}
          isNew
          onCancel={() => {
            setShowNewCaregiverForm(false);
            refetch();
          }}
        />
      )}
      {sortedAccounts.map((a, index) => {
        if (!a.account) return null;

        return (
          <CaregiverForm
            accounts={accounts}
            key={a.accountId}
            account={a}
            index={index}
            patient={patient}
            isAccompanying={a.accountId === appointment?.accountId}
          />
        );
      })}
    </SectionContent>
  );
};

export default Caregivers;
