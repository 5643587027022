import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string;
}

const Help: React.FC<Props> = ({ color, ...props }) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 16a4 4 0 100-8 4 4 0 000 8zM4.2 19.8l4.7-4.7M8.9 8.9L4.2 4.2M19.8 4.2l-4.7 4.7M15.1 15.1l4.7 4.7M8.9 15.1l-4.7 4.7M19.8 19.8l-4.7-4.7"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Help;
