/** @jsx jsx */
import {
  AppointmentPartsFragment,
  useGetPaymentInformationQuery,
} from '@bc/codegen/medical';
import { CARRIERS } from '@bc/shared';
import { jsx } from '@emotion/core';
import { DateTime } from 'luxon';
import React, { Fragment, useState } from 'react';
import { Charge, PaymentInfo } from '../../components/Billing';
import { OutlineButton } from '../../components/button';
import { getCopayAmount } from '../../components/CarrierSearch/helpers';
import { HeaderEdit, SectionContent } from '../../components/layout';
import { ChargeCard, InsurancePayment } from '../../components/Payments';
import { SectionTitle } from '../../components/text';
import { IF_TABLET, MobileWrapRow } from '../../styles';

const Payments: React.FC<{ appointment: AppointmentPartsFragment }> = ({
  appointment,
}) => {
  const [visibleForm, setVisibleForm] = useState<
    'charge' | 'insurance' | undefined
  >();

  const { data: cardInfoData } = useGetPaymentInformationQuery({
    variables: {
      stripeCustomerId: appointment?.stripeCustomerId!,
      stripeCardId: appointment?.stripeCardId!,
    },
    skip: !appointment.stripeCustomerId && !appointment.stripeCardId,
  });

  const patient = appointment?.appointment_patients[0]?.patient;
  const insurances = patient?.insurances ?? [];

  const charges = appointment.charges ?? [];
  const sortedCharges = charges.sort((a, b) => {
    return (
      DateTime.fromISO(b?.updated_at).toSeconds() -
      DateTime.fromISO(a?.updated_at).toSeconds()
    );
  });

  const accounts = patient?.accounts ?? [];
  const accountId = appointment.accountId;

  const account = accounts.find((acc) => {
    return accountId === acc.accountId;
  })?.account;

  const defaultEmail = account?.email;

  const allEmails = accounts
    .map(({ account }) => account?.email as string)
    .filter(Boolean);

  const primaryInsurance = insurances.find(({ type }) => type === 'PRIMARY');
  const { payment, stripeCardId } = appointment;

  let copayAmount = 0;

  if (primaryInsurance?.carrier && primaryInsurance?.eligibility) {
    copayAmount =
      parseFloat(
        getCopayAmount(primaryInsurance.carrier, primaryInsurance.eligibility),
      ) || 0;
  }

  const carrier = primaryInsurance?.carrier
    ? // @ts-ignore
      CARRIERS[primaryInsurance?.carrier]?.name
    : undefined;

  return (
    <Fragment>
      <SectionContent>
        <MobileWrapRow>
          <SectionTitle data-testid="billingTitle">Billing</SectionTitle>
          <MobileWrapRow>
            <OutlineButton
              icon="insurancePayment"
              onClick={() => setVisibleForm('insurance')}
              css={{
                margin: '16px 8px 16px 0',
                [IF_TABLET]: { margin: '0 8px 0 0' },
              }}
            >
              Add insurance payment
            </OutlineButton>
            {charges.length > 0 && (
              <OutlineButton
                icon="add"
                css={{
                  img: {
                    filter:
                      'brightness(0) saturate(100%) invert(18%) sepia(97%) saturate(1065%) hue-rotate(147deg) brightness(88%) contrast(104%)',
                  },
                  ':hover img': {
                    filter:
                      'brightness(0) saturate(100%) invert(18%) sepia(97%) saturate(1065%) hue-rotate(147deg) brightness(88%) contrast(104%)',
                  },
                }}
                onClick={() => setVisibleForm('charge')}
              >
                Add charge
              </OutlineButton>
            )}
          </MobileWrapRow>
        </MobileWrapRow>
        {visibleForm === 'insurance' && (
          <InsurancePayment
            appointment={appointment}
            onCancel={() => setVisibleForm(undefined)}
          />
        )}
        {visibleForm === 'charge' && charges.length > 0 && (
          <HeaderEdit
            title={`${DateTime.local().toFormat('D')} Payment`}
            editing={true}
            onCancel={() => setVisibleForm(undefined)}
            css={{ margin: '16px 0' }}
          />
        )}
        {charges.length === 0 && appointment?.payment && (
          <div css={{ display: 'flex', padding: 32 }}>
            <PaymentInfo label="Payment Type">
              {appointment?.payment === 'INSURANCE'
                ? 'Insurance'
                : 'Out-of-pocket'}
            </PaymentInfo>
            {appointment?.payment === 'INSURANCE' && (
              <PaymentInfo label="Carrier">{carrier}</PaymentInfo>
            )}
            <div css={{ display: 'flex', flex: 1 }} />
          </div>
        )}
        {(visibleForm === 'charge' || charges.length === 0) && (
          <ChargeCard
            appointmentId={appointment.id}
            defaultCardId={stripeCardId}
            defaultEmail={defaultEmail}
            defaultCopayAmount={copayAmount}
            emails={allEmails}
            account={account!}
            patient={patient}
          />
        )}
        {sortedCharges.map((charge) => (
          <Charge
            appointment={appointment}
            carrier={carrier}
            charge={charge}
            key={charge.id}
          />
        ))}
      </SectionContent>
    </Fragment>
  );
};

export default Payments;
