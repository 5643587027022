import { useGetPatientInfoByPkQuery } from '@bc/codegen/medical';
import { RELATIONSHIPS } from '@bc/shared';
import { formatDateOfBirth, getDateOfBirth } from '../../helpers';

interface Account {
  name: string;
  accountId: string;
  relationshipLabel?: string;
}

export const useGetPatientInfo = ({ patientId }: { patientId?: string }) => {
  const { data } = useGetPatientInfoByPkQuery({
    variables: { patientId: patientId! },
    skip: !patientId,
  });
  const patient = data?.patients_by_pk;

  const accounts: Account[] =
    patient?.accounts?.map((a) => ({
      name: `${a.account.firstName} ${a.account.lastName}`,
      accountId: a.accountId as string,
      relationshipLabel: RELATIONSHIPS.find((r) => r.value === a.relationship)
        ?.label,
    })) ?? [];

  const address = patient?.address ?? 'None provided';

  const formattedDateofBirth = patient?.dateOfBirth
    ? `${getDateOfBirth({
        date: patient?.dateOfBirth,
      })} • ${formatDateOfBirth(patient?.dateOfBirth)}`
    : '';

  const patientTitle =
    patient?.firstName && patient?.lastName
      ? `${patient.firstName}${
          patient?.goesByName ? ` ${patient.goesByName}’ ` : ' '
        }${patient.lastName}`
      : '';

  return {
    accounts,
    address,
    formattedDateofBirth,
    patientTitle,
    preferredPronoun: patient?.preferredPronoun ?? undefined,
    preferredPronounType: patient?.preferredPronounType ?? undefined,
  };
};
