import React from 'react';
import { ICONS } from '../../assets';
import { Button } from '../button';
import { PrimaryText } from '../text';
import KareoSection from './KareoSection';

const KareoPatientUpdateSection = ({
  onSectionSelect,
  open,
  onToggle,
  success,
}: {
  onSectionSelect: () => void;
  open: boolean;
  success: boolean;
  onToggle: () => void;
}) => {
  return (
    <KareoSection
      icon={ICONS.kareoPatientUpdate}
      title="Update Patient Chart in Kareo"
      open={open}
      onToggle={onToggle}
      success={success}
    >
      <div
        style={{
          padding: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <PrimaryText
          style={{
            marginBottom: '16px',
          }}
        >
          Sync the patient’s information from Brave Care’s intake into Kareo.
        </PrimaryText>
        <Button type="button" onClick={onSectionSelect}>
          Review Patient Chart Updates
        </Button>
      </div>
    </KareoSection>
  );
};

export default KareoPatientUpdateSection;
