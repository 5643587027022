import { useGetLatestAppointmentsQuery } from '@bc/codegen/medical';
import { getCareType } from '@bc/shared';
import { DateTime } from 'luxon';

export const useGetLatestAppointments = ({
  patientId,
}: {
  patientId?: string;
}) => {
  const { data } = useGetLatestAppointmentsQuery({
    variables: {
      where: {
        appointment_patients: {
          patient_id: { _eq: patientId },
        },
      },
      order_by: [{ startTime: 'desc' }],
      limit: 3,
    },
    skip: !patientId,
  });

  const appointments = data?.appointments ?? [];

  const visits = appointments.map((a) => {
    const description = a.notes || a.reason;
    const formattedDate = DateTime.fromISO(a.startTime).toFormat('D');
    const careType = getCareType({
      visitType: a.visitType,
      visitClassification: a.visitClassification,
    });

    return {
      description,
      formattedDate,
      id: a.id,
      title: careType.title,
    };
  });

  return { visits };
};
