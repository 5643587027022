/** @jsx jsx */
import {
  Services_Enum,
  StaffScheduleFragment,
  Staff_Schedule_Insert_Input,
  useDeleteStaffScheduleMutation,
  useGetClinicsQuery,
  useInsertStaffScheduleMutation,
  useLoadStaffAndServicesQuery,
  useLoadStaffScheduleQuery,
} from '@bc/codegen/manager';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import { Field, FieldProps, Form, Formik } from 'formik';
import capitalize from 'lodash/capitalize';
import { DateTime, Interval } from 'luxon';
import React, { useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import CaretDropdownIcon from '../../assets/caret.svg';
import Pin from '../../assets/pin.svg';
import { hasRole } from '../../auth';
import { Button, ButtonRed } from '../../components/button';
import {
  CalendarPicker,
  CalendarSelectorMulti,
} from '../../components/CalendarSelector';
import { Checkbox } from '../../components/CheckboxFilter';
import {
  CheckboxRowWrap,
  Label,
  Select,
  SelectSimpleField,
  SelectWithCaret,
} from '../../components/form';
import {
  ContentRow,
  InfoSection,
  SectionContent,
} from '../../components/layout';
import { isSameDate } from '../../components/Schedule/helper';
import StaffSearch from '../../components/StaffSearch/StaffSearch';
import { PrimaryText } from '../../components/text';
import { useAuth0 } from '../../react-auth0';
import { Close, Content, LargeTitle } from '../ViewAppointment.styles';
import StaffScheduleRemovalConfirmation from './StaffScheduleRemovalConfirmation';
import { CancelButton, Caret, Icon } from './styles';

interface Values extends Partial<StaffScheduleFragment> {
  date: Date;
  startTime: string;
  endTime: string;
  services: Services_Enum[];
  scheduleOn: Date[];
}

const TODAY = new Date();

const ScheduleForm: React.FC<
  RouteComponentProps<
    {
      staffScheduleId?: string;
    },
    {},
    {}
  >
> = ({ history, match }) => {
  const location = useLocation<{ date: Date }>();
  const [selectedClinicCode] = useLocalStorage('selectedClinic');

  const date = location?.state?.date || TODAY;

  const { staffScheduleId } = match.params;
  const { accessToken } = useAuth0();
  const isManager = hasRole('manager', accessToken);
  const context = { role: isManager ? 'manager' : 'assistant' };
  const [isRepeatOpen, setIsRepeatOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [month, setMonth] = useState(new Date());

  const [deleteStaffSchedule] = useDeleteStaffScheduleMutation({ context });
  const [insertStaffSchedule] = useInsertStaffScheduleMutation({ context });
  const { data: clinicData } = useGetClinicsQuery({ context });
  const { data: staffData } = useLoadStaffAndServicesQuery({
    context,
    variables: {
      where: {
        active: {
          _eq: true,
        },
      },
    },
  });
  const { data } = useLoadStaffScheduleQuery({
    context,
    skip: !staffScheduleId,
    variables: { where: { id: { _eq: staffScheduleId } } },
  });

  const clinics = clinicData?.clinics;
  const selectedClinic = clinics?.find(
    (c) => c.clinicCode === selectedClinicCode,
  );
  const staff = staffData?.staff ?? [];
  const staffSchedule = data?.staff_schedule?.[0];
  const isExisting = !!staffSchedule;

  const handleRemoveSchedule = async () => {
    await deleteStaffSchedule({
      variables: { staffScheduleId: staffScheduleId! },
    });
    history.push('/staff/schedule');
  };

  const handleInsertSchedule = async (values: Values) => {
    const clinic = clinics?.find((c) => c.id === values.clinicId);
    const day = DateTime.fromJSDate(values.date);
    const startTime = DateTime.fromFormat(values.startTime, 'HH:mm:ss');
    const endTime = DateTime.fromFormat(values.endTime, 'HH:mm:ss');
    const services = values.services.map((service) => ({ service }));
    const endIsNextDay = startTime.diff(endTime).milliseconds > 0;

    const start = day
      .set({
        hour: startTime.hour,
        minute: startTime.minute,
        second: startTime.second,
      })
      .startOf('second');
    let end = day
      .set({
        hour: endTime.hour,
        minute: endTime.minute,
        second: endTime.second,
      })
      .startOf('second');

    if (endIsNextDay) {
      end = end.plus({ days: 1 });
    }

    let schedules: Staff_Schedule_Insert_Input[] = [];

    const pushSchedule = (start: DateTime, end: DateTime) => {
      schedules.push({
        clinicId: values.clinicId,
        staffId: values.staffId,
        startDateTime: start.toSQL({
          includeOffset: false,
          includeZone: false,
        }),
        endDateTime: end.toSQL({
          includeOffset: false,
          includeZone: false,
        }),
        timeZone: clinic?.timeZone,
        staff_schedule_services: { data: services },
      });
    };

    if (values.scheduleOn.length) {
      for (const day of values.scheduleOn) {
        const startOfDay = DateTime.fromJSDate(day)
          .set({
            hour: startTime.hour,
            minute: startTime.minute,
            second: startTime.second,
          })
          .startOf('second');
        let endOfDay = DateTime.fromJSDate(day)
          .set({
            hour: endTime.hour,
            minute: endTime.minute,
            second: endTime.second,
          })
          .startOf('second');

        if (endIsNextDay) {
          endOfDay = endOfDay.plus({ days: 1 });
        }

        pushSchedule(startOfDay, endOfDay);
      }
    } else {
      // just add one
      pushSchedule(start, end);
    }

    await insertStaffSchedule({ variables: { schedules } });

    history.push('/staff/schedule');
  };

  const handleUpdateSchedule = async (values: Values) => {
    await deleteStaffSchedule({
      variables: { staffScheduleId: staffScheduleId! },
    });
    await handleInsertSchedule(values);
  };

  const staffId = staffSchedule?.staffId ?? '';
  let initialServices: any = [];

  if (staffId) {
    const currentStaff = staff?.find((s) => s.id === staffId);
    initialServices = currentStaff?.staff_services.map((s) => s.service);
  }

  return (
    <Content
      aria-label={isExisting ? 'Edit Staff Schedule' : 'Add Staff Schedule'}
    >
      <div>
        <div style={{ padding: '40px 40px 0 40px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              position: 'relative',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <LargeTitle>Schedule Staff</LargeTitle>
            </div>
            <Close onClick={() => history.push('/staff/schedule')} />
          </div>
        </div>
        <Formik
          initialValues={{
            clinicId:
              staffSchedule?.clinicId ??
              selectedClinic?.id ??
              clinics?.[0]?.id ??
              '',
            date: staffSchedule?.startDateTime
              ? DateTime.fromISO(staffSchedule?.startDateTime).toJSDate()
              : date,
            startTime: staffSchedule?.startDateTime
              ? DateTime.fromISO(staffSchedule?.startDateTime).toFormat(
                  'HH:mm:ss',
                )
              : clinics?.[0]?.open ?? '10:00:00',
            endTime: staffSchedule?.endDateTime
              ? DateTime.fromISO(staffSchedule?.endDateTime).toFormat(
                  'HH:mm:ss',
                )
              : clinics?.[0]?.close ?? '22:00:00',
            staffId: staffSchedule?.staffId ?? '',
            services:
              staffSchedule?.staff_schedule_services.map((s) => s.service) ??
              initialServices,
            scheduleOn: [],
          }}
          enableReinitialize={!isRepeatOpen}
          onSubmit={isExisting ? handleUpdateSchedule : handleInsertSchedule}
        >
          {({ setFieldValue, values, isSubmitting, isValid }) => {
            const currentClinic = clinics?.find(
              (c) => c.id === values.clinicId,
            );
            const isMobileClinic = currentClinic?.locationType === 'MOBILE';
            const openTime = DateTime.fromFormat('00:00:00', 'hh:mm:ss', {
              setZone: true,
              zone: currentClinic?.timeZone || 'America/Los_Angeles',
            });
            const closeTime = DateTime.fromFormat('23:00:00', 'hh:mm:ss', {
              setZone: true,
              zone: currentClinic?.timeZone || 'America/Los_Angeles',
            });

            const currentClinicSlots = Interval.fromDateTimes(
              openTime,
              closeTime.plus({ minutes: isMobileClinic ? 90 : 60 }),
            ).splitBy({ minutes: isMobileClinic ? 90 : 30 });
            const currentStaff = staff?.find((s) => s.id === values.staffId);
            const currentServices = currentStaff?.staff_services;
            const startTime = DateTime.fromFormat(values.startTime, 'HH:mm:ss');
            const endTime = DateTime.fromFormat(values.endTime, 'HH:mm:ss');
            const endIsNextDay = startTime.diff(endTime).milliseconds > 0;

            return (
              <Form>
                <SectionContent style={{ padding: '40px 40px 0' }}>
                  <ContentRow>
                    <InfoSection title="Select location" fullWidth>
                      <Field name="clinicId">
                        {({ field }: FieldProps<any>) => (
                          <SelectWithCaret>
                            <Icon
                              src={Pin}
                              style={{
                                position: 'absolute',
                                top: 16,
                                left: 16,
                              }}
                            />
                            <Select {...field} style={{ paddingLeft: 44 }}>
                              {clinics?.map(({ name, id }) => (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              ))}
                            </Select>
                          </SelectWithCaret>
                        )}
                      </Field>
                    </InfoSection>
                  </ContentRow>
                  <ContentRow>
                    <InfoSection title="Select date" fullWidth>
                      <Field name="date">
                        {({ field }: FieldProps<any>) => (
                          <CalendarPicker
                            value={field.value}
                            onChange={(value) =>
                              setFieldValue(field.name, value)
                            }
                          />
                        )}
                      </Field>
                    </InfoSection>
                  </ContentRow>
                  <ContentRow>
                    <InfoSection title="Start time">
                      <SelectSimpleField name="startTime">
                        {currentClinicSlots?.map(({ start }) => (
                          <option
                            key={start.toMillis()}
                            value={start.toFormat('HH:mm:ss')}
                          >
                            {start.toFormat('h:mm a ZZZZ')}
                          </option>
                        ))}
                      </SelectSimpleField>
                      {endIsNextDay && (
                        <PrimaryText style={{ marginTop: '8px' }}>
                          {startTime.toFormat('dd/MM/yyyy')}
                        </PrimaryText>
                      )}
                    </InfoSection>
                    <div
                      style={{
                        padding: 3,
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 22,
                      }}
                    >
                      <PrimaryText style={{ fontWeight: 'bold' }}>
                        -
                      </PrimaryText>
                    </div>
                    <InfoSection title="End time">
                      <SelectSimpleField name="endTime">
                        {currentClinicSlots?.map(({ start }) => (
                          <option
                            key={start.toMillis()}
                            value={start.toFormat('HH:mm:ss')}
                          >
                            {start.toFormat('h:mm a ZZZZ')}
                          </option>
                        ))}
                      </SelectSimpleField>
                      {endIsNextDay && (
                        <PrimaryText style={{ marginTop: '8px' }}>
                          {startTime.plus({ days: 1 }).toFormat('dd/MM/yyyy')}
                        </PrimaryText>
                      )}
                    </InfoSection>
                  </ContentRow>
                  <ContentRow>
                    <InfoSection title="Select available staff" fullWidth>
                      <Field name="staffId">
                        {({ field, form }: FieldProps<any>) => (
                          <StaffSearch
                            value={field.value}
                            onChange={(member) => {
                              form.setFieldValue(field.name, member?.id);

                              if (member) {
                                const currentStaff = staff?.find(
                                  (s) => s.id === member?.id,
                                );
                                initialServices =
                                  currentStaff?.staff_services.map(
                                    (s) => s.service,
                                  );

                                form.setFieldValue('services', initialServices);
                              }
                            }}
                            filterIds={[]}
                          />
                        )}
                      </Field>
                    </InfoSection>
                  </ContentRow>
                  <ContentRow style={{ marginBottom: '8px' }}>
                    <InfoSection title="Services provided" fullWidth>
                      <CheckboxRowWrap style={{ marginBottom: 0 }}>
                        {currentServices?.map(({ service }) => {
                          const foundIndex = values?.services?.indexOf(service);
                          const hasValue = values?.services?.includes(service);
                          const index = hasValue
                            ? foundIndex
                            : values?.services?.length ?? 0;
                          const field = `services[${index}]`;

                          const toggleValue = () => {
                            if (!hasValue) {
                              setFieldValue(field, service);
                            } else {
                              setFieldValue(
                                'services',
                                values?.services?.filter(
                                  (val) => val !== service,
                                ),
                              );
                            }
                          };

                          return (
                            <Checkbox
                              key={service}
                              color={Colors.teal}
                              groupName={field}
                              label={capitalize(service.replace(/_/g, ' '))}
                              value={service}
                              onChange={toggleValue}
                              isChecked={!!hasValue}
                            />
                          );
                        })}
                      </CheckboxRowWrap>
                    </InfoSection>
                  </ContentRow>
                </SectionContent>
                {!isExisting && (
                  <div
                    style={{
                      borderBottom: `1px solid ${Colors.grayLighter}`,
                      borderTop: `1px solid ${Colors.grayLighter}`,
                      cursor: 'pointer',
                      padding: '0 40px',
                    }}
                  >
                    <ContentRow
                      style={{
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        padding: '17px 0',
                        marginBottom: isRepeatOpen ? 16 : 0,
                        marginTop: 0,
                      }}
                      onClick={() => {
                        if (!values.scheduleOn.length) {
                          setMonth(values.date);
                          setFieldValue('scheduleOn', [values.date]);
                        }
                        setIsRepeatOpen(!isRepeatOpen);
                      }}
                    >
                      <Label
                        style={{ color: Colors.gray, margin: '0 4px 0 0' }}
                      >
                        Repeat On
                      </Label>
                      <Caret src={CaretDropdownIcon} isOpen={isRepeatOpen} />
                    </ContentRow>
                    <div
                      style={{
                        height: isRepeatOpen ? 'auto' : 0,
                        overflow: 'hidden',
                      }}
                    >
                      {isRepeatOpen && (
                        <Field name="scheduleOn">
                          {({ field }: FieldProps<Date[]>) => (
                            <CalendarSelectorMulti
                              month={month}
                              value={field.value}
                              onChange={(day) => {
                                const values = field.value.map((val) =>
                                  DateTime.fromJSDate(val),
                                );

                                let dayValue = DateTime.fromJSDate(day);
                                const isSelected = values.find((value) =>
                                  isSameDate(value, dayValue),
                                );

                                if (isSelected) {
                                  setFieldValue(
                                    field.name,
                                    values
                                      .filter((value) => {
                                        return !isSameDate(value, dayValue);
                                      })
                                      .map((value) => value.toJSDate()),
                                  );
                                } else {
                                  setFieldValue(field.name, [
                                    ...field.value,
                                    day,
                                  ]);
                                }
                              }}
                            />
                          )}
                        </Field>
                      )}
                    </div>
                  </div>
                )}
                {isManager && (
                  <SectionContent css={{ padding: 40 }}>
                    <ContentRow>
                      {isExisting && (
                        <ButtonRed
                          type="button"
                          onClick={() => {
                            setIsDeleteOpen(true);
                          }}
                        >
                          Remove
                        </ButtonRed>
                      )}
                      <CancelButton
                        style={{ marginLeft: 'auto' }}
                        type="button"
                        onClick={() => {
                          history.push('/staff/schedule');
                        }}
                      >
                        Cancel
                      </CancelButton>
                      <Button
                        type="submit"
                        disabled={!isValid || isSubmitting}
                        style={{
                          marginLeft: 24,
                          width: 'auto',
                        }}
                      >
                        {isExisting ? 'Save' : 'Add to Schedule'}
                      </Button>
                    </ContentRow>
                  </SectionContent>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
      <StaffScheduleRemovalConfirmation
        isOpen={isDeleteOpen}
        onCancel={() => {
          setIsDeleteOpen(false);
        }}
        onRemove={() => {
          handleRemoveSchedule();
        }}
      />
    </Content>
  );
};

export default ScheduleForm;
