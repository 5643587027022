/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { PrimaryNotBlank } from './text';
import TriangleTooltip from './TriangleTooltip';

const CopyClip = ({
  children,
  text,
}: {
  children?: React.ReactNode;
  text: string;
}) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    let timeout: number;

    if (copied) {
      timeout = window.setTimeout(() => {
        setCopied(false);
      }, 500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  return (
    <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
      <div
        css={{ position: 'relative', width: '100%', wordBreak: 'break-all' }}
      >
        <PrimaryNotBlank css={{ cursor: 'pointer' }}>
          {children}
        </PrimaryNotBlank>
        {copied && <TriangleTooltip label="Copied" />}
      </div>
    </CopyToClipboard>
  );
};

export default CopyClip;
