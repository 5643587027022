import Downshift from 'downshift';
import React, { Fragment, useEffect, useState } from 'react';
import { Location } from '../../types';
import { Input, InputWrap } from '../form';
import LocationResults, { ClearIcon, Container, ResultsContainer } from './ui';
import { usePlaceSuggestion } from './usePlaceSuggestion';

interface Props {
  onChange: (item?: any) => void;
  value?: Location;
  error: boolean;
}

const FullPlaceSearch: React.FC<Props> = ({ onChange, value, error }) => {
  const [inputValue, setInputValue] = useState(value?.formatted_address ?? '');
  const { predictions, getPlace, place } = usePlaceSuggestion(inputValue || '');

  const itemToString = (item: Location | null) => {
    if (!item) return '';
    return item.formatted_address ?? '';
  };

  const placeId = place?.FindPlace?.place_id;
  const formattedAddress = place?.FindPlace?.formatted_address;
  useEffect(() => {
    if (placeId && formattedAddress) {
      onChange(place?.FindPlace);
      setInputValue(formattedAddress);
    }
  }, [placeId, formattedAddress]);

  const handleLocationChange = async (location: Location | null) => {
    if (location?.place_id) {
      await getPlace({
        variables: {
          placeId: location.place_id,
        },
      });
    }
  };

  return (
    <Fragment>
      <Downshift
        onChange={handleLocationChange}
        itemToString={itemToString}
        inputValue={inputValue}
        onInputValueChange={(value) => setInputValue(value)}
      >
        {({
          getRootProps,
          getInputProps,
          getItemProps,
          highlightedIndex,
          isOpen,
          openMenu,
          closeMenu,
        }) => {
          return (
            <Container {...getRootProps()}>
              <ResultsContainer>
                <div>
                  <InputWrap style={{ marginBottom: 0 }}>
                    <Input
                      {...getInputProps({
                        onFocus: openMenu,
                        type: 'text',
                        placeholder: 'Enter address',
                        autoComplete: 'off',
                        onBlur: () => {
                          closeMenu();
                        },
                      })}
                      error={error}
                    />
                  </InputWrap>
                  {!!value && (
                    <ClearIcon
                      onClick={() => {
                        setInputValue('');
                        onChange(undefined);
                      }}
                    />
                  )}
                </div>
                {isOpen && (
                  <LocationResults
                    inputValue={inputValue}
                    highlightedIndex={highlightedIndex}
                    getItemProps={getItemProps}
                    predictions={predictions}
                  />
                )}
              </ResultsContainer>
            </Container>
          );
        }}
      </Downshift>
    </Fragment>
  );
};

export default FullPlaceSearch;
