/** @jsx jsx */
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { ICONS } from '../assets/';
import LeftArrow from '../assets/left-arrow.svg';
import {
  BUTTON_DARK_HARBOR,
  BUTTON_GREEN,
  BUTTON_OUTLINE,
  BUTTON_OUTLINE_DARK,
  BUTTON_RED,
  BUTTON_TRANSPARENT,
  FONT_PRIMARY,
} from '../styles';

const commonRules = `
  align-items: center;
  justify-content: center;
  padding: 16px 40px;
`;

export const Button = styled.button`
  ${BUTTON_GREEN}
  ${commonRules}
  font-weight: 500;
`;

export const ButtonDarkHarbor = styled.button`
  ${BUTTON_DARK_HARBOR}
  ${commonRules}
  font-weight: 500;
`;

export const ButtonRed = styled.button`
  ${BUTTON_RED}
  ${commonRules}
  font-weight: 500;
`;

export const TextButton = styled.button`
  ${BUTTON_TRANSPARENT}
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  padding: 7px 16px;

  &:hover img {
    filter: brightness(0) saturate(100%) invert(62%) sepia(97%) saturate(590%)
      hue-rotate(115deg) brightness(84%) contrast(101%);
  }
`;

export const GreenOutlineButton = styled(TextButton)`
  ${BUTTON_OUTLINE};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover img {
    filter: brightness(0) saturate(100%) invert(94%) sepia(94%) saturate(0%)
      hue-rotate(232deg) brightness(104%) contrast(108%);
  }
`;

export const OutlineButtonDark = styled(TextButton)`
  ${FONT_PRIMARY};
  ${BUTTON_OUTLINE_DARK};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  :hover img {
    filter: brightness(0) saturate(100%) invert(94%) sepia(94%) saturate(0%)
      hue-rotate(232deg) brightness(104%) contrast(108%);
  }
`;

export const GreenOutlineBorderButton = styled(TextButton)`
  ${FONT_PRIMARY};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d9f4ef;
  border: 1px solid #11bb9a;
  box-sizing: border-box;
  border-radius: 100px;
  :hover img {
    filter: none;
  }

  &:active,
  &:hover {
    border: 1px solid #11bb9a;
  }
`;

export const BackButton = styled.img`
  cursor: pointer;
  z-index: 10;
`;

BackButton.defaultProps = {
  src: LeftArrow,
  alt: 'Back',
};

const CloseIcon = styled.img`
  z-index: 10;
`;

CloseIcon.defaultProps = {
  src: ICONS.close,
  alt: 'Close',
};

const GreyOutlineButton = styled(OutlineButtonDark)`
  background: ${Colors.white};
  border-radius: 100px;
  padding: 8px;
  font-weight: 500;

  :hover img {
    filter: none;
  }
  &:hover {
    background: ${Colors.grayLightest};
  }
  &:focus {
    border: 1px solid ${Colors.darkHarbor};
  }
`;

export const CloseButton: React.FC<
  { className?: string } & React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => (
  <GreyOutlineButton {...props}>
    <CloseIcon />
  </GreyOutlineButton>
);

export const RightButton = styled.img`
  cursor: pointer;
  height: 14px;
  width: 8px;
`;

RightButton.defaultProps = {
  src: ICONS.caretRight,
};

export const LeftButton = styled(RightButton)`
  transform: rotate(180deg);
`;

export type OutlineButtonProps = {
  className?: string;
  icon?: keyof typeof ICONS;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const OutlineButton: React.FC<OutlineButtonProps> = ({
  className,
  children,
  icon,
  onMouseDown,
  ...props
}) => {
  return (
    <GreyOutlineButton
      css={{ padding: '8px 16px', width: 'max-content' }}
      className={className}
      onMouseDown={(e) => {
        e.preventDefault();
        onMouseDown?.(e);
      }}
      {...props}
    >
      {icon && (
        <img
          src={ICONS[icon]}
          alt={icon}
          css={{ marginRight: children ? 6 : 0 }}
        />
      )}
      {children}
    </GreyOutlineButton>
  );
};

export const TransparentButton = styled.button({
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  padding: 0,
  margin: 0,
  display: 'flex',
});
