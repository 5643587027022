import {
  GetDuplicatePatientsQuery,
  useGetDuplicatePatientsQuery,
} from '@bc/codegen/medical';
import { sortBy, uniqBy } from 'lodash';

export const useGetPatientDupes = () => {
  const { data } = useGetDuplicatePatientsQuery({
    fetchPolicy: 'no-cache',
  });

  return {
    data,
    duplicates: getDuplicates(data),
  };
};

export const patientMatches = (p1: any, p2: any) => {
  return patientHash(p1) === patientHash(p2);
};

export const patientHash = (patient: any) => {
  return `${patient?.firstName?.toLowerCase().trim()}_${patient?.lastName
    ?.toLowerCase()
    .trim()}_${patient?.dateOfBirth}`;
};

interface Grouped {
  id: string;
  matched: string[];
}

export const getDuplicates = (data?: GetDuplicatePatientsQuery) => {
  const patients = data?.patient_duplicates ?? [];
  const notDupes = (data?.patient_not_duplicates ?? []).map(
    ({ patient1_id, patient2_id }) => {
      return [patient1_id, patient2_id];
    },
  );

  const checkIfNotDupe = (patientId1: string, patientId2: string) => {
    const hasNotDupe = notDupes.find((pGroup) => {
      return pGroup.includes(patientId1) && pGroup.includes(patientId2);
    });

    return !!hasNotDupe;
  };

  let groupedPatients: Grouped[] = [];

  patients.forEach((patient) => {
    const matched = patients.filter((p) => {
      if (p.id === patient.id) {
        return false;
      }
      if (checkIfNotDupe(p.id!, patient.id!)) {
        return false;
      }

      return patientMatches(patient, p);
    });

    groupedPatients.push({
      id: patient.id!,
      matched: matched.map(({ id }) => id!),
    });
  });

  const filteredMatchDupes = uniqBy(groupedPatients, ({ id, matched }) => {
    return sortBy([...matched, id]).join('_');
  }).filter(({ matched }) => {
    return matched.length !== 0;
  });

  return filteredMatchDupes;
};
