import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import React from 'react';
import { FONT_PRIMARY } from '../../styles';

const StyledBox = styled.div`
  background: ${Colors.grayLighter};
  border-radius: 5px;
  height: 24px;
  width: 24px;
  ${FONT_PRIMARY};
  text-align: center;
  color: ${Colors.gray};
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
`;

export const CountBox: React.FC<{ count: number; className?: string }> = ({
  count,
  className,
}) => <StyledBox className={className}>{count}</StyledBox>;
