import React from 'react';
import styled from '@emotion/styled';

const Wrap = styled.div({
  pointerEvents: 'none',
});

const Oval = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 7px;
  background-color: #ff3c00;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #ffffff;
  box-shadow: 0px 2px 4px rgba(68, 68, 79, 0.15);
`;

const Line = styled.div`
  background-color: #ff3c00;
  position: relative;
  height: 1px;
  width: 100%;
`;

interface Props {
  style?: React.CSSProperties;
}

const CurrentTime = ({ style }: Props) => {
  return (
    <Wrap style={style}>
      <Line>
        <Oval />
      </Line>
    </Wrap>
  );
};

export default CurrentTime;
