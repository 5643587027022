import React from 'react';
import { Loader } from '../Loader';
import { PrimaryText } from '../text';

const KareoActivity = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Loader />
      <PrimaryText style={{ marginTop: '16px' }}>
        A kareo action is in progress
      </PrimaryText>
    </div>
  );
};

export default KareoActivity;
