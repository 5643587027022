/** @jsx jsx */
import {
  AppointmentCountsForDaysQuery,
  ClinicFragment,
  LoadStaffScheduleQuery,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import { sortBy, startCase } from 'lodash';
import { DateTime } from 'luxon';
import { lighten } from 'polished';
import { Fragment, useEffect, useRef } from 'react';
import { PrimaryText } from '../../components/text';
import { normalizedStaffInfo } from '../../pages/staff/helpers';
import { StaffAvatar } from '../../pages/staff/styles';
import { Badge } from '../Badge/Badge';
import { TransparentButton } from '../button';
import { generateTimeSlots, isSameDate } from './helper';

type Schedules = LoadStaffScheduleQuery['staff_schedule'];
type Counts = AppointmentCountsForDaysQuery['appointment_counts'];

interface Props {
  handleScheduleDelete: (scheduleId: string) => void;
  handleScheduleEdit: (scheduleId: string) => void;
  isManager: boolean;
  schedules?: Schedules;
  appointmentCounts: Counts;
  clinics: ClinicFragment[];
  date: DateTime;
}

const getDisplayOffset = ({
  dayStart,
  millPerPix,
  display,
}: {
  dayStart: DateTime;
  millPerPix: number;
  display: { startTime: DateTime; endTime: DateTime };
}) => {
  const displayDiff = display.endTime.diff(display.startTime).toObject();
  const startDiff = display.startTime.diff(dayStart).toObject();

  return {
    width: (displayDiff.milliseconds || 0) / millPerPix,
    offset: (startDiff.milliseconds || 0) / millPerPix,
  };
};

export const DayView = ({
  handleScheduleDelete,
  handleScheduleEdit,
  isManager,
  schedules,
  appointmentCounts,
  clinics,
  date,
}: Props) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const dayOpen = date.startOf('day');
  const dayClose = date.endOf('day');
  const slots = generateTimeSlots(dayOpen, dayClose, 60);
  const totalWidth = slots.length * 125;
  const { milliseconds } = dayClose.diff(dayOpen).toObject();
  const millPerPix = (milliseconds || 0) / totalWidth;

  useEffect(() => {
    if (!scrollRef.current) return;
    scrollRef.current.scrollLeft = 1250;
  }, [schedules?.length]);

  const showTimeBar = isSameDate(date, DateTime.local());

  const { milliseconds: currentOffset } = DateTime.local()
    .diff(dayOpen)
    .toObject();
  const leftOffset = (currentOffset || 0) / millPerPix;

  return (
    <div
      style={{
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        overflow: 'hidden',
        flex: 1,
      }}
    >
      <div
        css={{
          maxWidth: 250,
          minWidth: 250,
          width: '100%',
        }}
      >
        {clinics.map((clinic) => {
          const clinicSchedule = sortBy(
            schedules?.filter(({ clinicId }) => {
              return clinic.id === clinicId;
            }),
            (schedule) => {
              const services = schedule.staff_schedule_services.map(
                ({ service }) => {
                  return service;
                },
              );

              const isOnCall =
                services.includes('ONCALL') || services.includes('XRAY_ONCALL');

              return isOnCall;
            },
          );
          const appointmentCount =
            appointmentCounts?.find(({ clinicId }) => {
              return clinicId === clinic.id;
            })?.count ?? 0;

          return (
            <Fragment>
              <div css={{ height: '72px', paddingTop: '20px' }}>
                <PrimaryText
                  style={{
                    fontWeight: 'bold',
                    fontSize: '16px',
                    marginRight: '8px',
                    display: 'inline-block',
                  }}
                >
                  {clinic?.name}
                </PrimaryText>
                <Badge>
                  <span style={{ fontSize: '12px' }}>
                    {appointmentCount}{' '}
                    {appointmentCount !== 1 ? 'visits' : 'visit'}
                  </span>
                </Badge>
              </div>
              {clinicSchedule?.map((schedule) => {
                const staff = normalizedStaffInfo(schedule.staff!);

                const services = schedule.staff_schedule_services.map(
                  ({ service }) => {
                    return service;
                  },
                );

                const isOnCall =
                  services.includes('ONCALL') ||
                  services.includes('XRAY_ONCALL');

                return (
                  <div key={schedule.id}>
                    <div
                      css={{
                        height: 50,
                        marginBottom: 20,
                        opacity: isOnCall ? 0.5 : 1,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <StaffAvatar
                          src={
                            staff.avatarUrl ||
                            'https://bravecare.imgix.net/staff/empty.png'
                          }
                        />
                        <div>
                          <PrimaryText
                            style={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                              marginBottom: '4px',
                              display: 'inline-block',
                            }}
                          >
                            {staff.informalFullName}
                          </PrimaryText>
                          <div>
                            <PrimaryText
                              style={{
                                fontSize: '14px',
                                color: Colors.gray,
                              }}
                            >
                              {staff.type}
                            </PrimaryText>
                            {staff.lead && (
                              <Badge css={{ marginLeft: '4px' }}>
                                <span style={{ fontSize: '12px' }}>Lead</span>
                              </Badge>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Fragment>
          );
        })}
      </div>

      <div
        style={{
          flex: '1 1 auto',
          overflow: 'auto',
          height: '100%',
          paddingTop: '20px',
        }}
        ref={scrollRef}
      >
        <div
          css={{
            width: 125 * slots.length,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            height: '100%',
          }}
        >
          {showTimeBar && (
            <div
              style={{
                width: '1px',
                position: 'absolute',
                top: '20px',
                bottom: 0,
                left: `${leftOffset}px`,
                backgroundColor: '#ff3c00',
                zIndex: 0,
                pointerEvents: 'none',
              }}
            >
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    height: '14px',
                    width: '14px',
                    borderRadius: '7px',
                    backgroundColor: '#ff3c00',
                    position: 'absolute',
                    top: 0,
                    transform: 'translate(-45%, -45%)',
                    border: '2px solid #ffffff',
                  }}
                />
              </div>
            </div>
          )}
          <div
            style={{
              position: 'absolute',
              top: '20px',
              bottom: 0,
              left: 0,
              right: 0,
              flexDirection: 'row',
              display: 'flex',
            }}
          >
            {slots.map((slot, index) => {
              const isLast = slots.length - 1 === index;

              return (
                <div
                  key={index}
                  css={{
                    width: 125,
                    height: '100%',
                    borderLeft: `1px solid #E5E8E8`,
                    borderRight: isLast ? `1px solid #E5E8E8` : 'none',
                    position: 'relative',
                  }}
                >
                  <div
                    css={{
                      position: 'absolute',
                      top: '-20px',
                      left: '0',
                    }}
                  >
                    <PrimaryText
                      style={{
                        fontSize: '14px',
                        color: Colors.gray,
                      }}
                    >
                      {slot.start.toFormat('h a')}
                    </PrimaryText>
                  </div>
                  {isLast && (
                    <div
                      css={{
                        position: 'absolute',
                        top: '-20px',
                        right: '0',
                        transform: 'translateX(100%)',
                      }}
                    >
                      <PrimaryText
                        style={{
                          fontSize: '14px',
                          color: Colors.gray,
                        }}
                      >
                        {slot.end.plus({ minutes: 1 }).toFormat('h a')}
                      </PrimaryText>
                    </div>
                  )}
                  <div
                    style={{
                      height: '100%',
                      position: 'absolute',
                      width: '1px',
                      backgroundColor: '#E5E8E8',
                      left: '50%',
                      transform: 'translateX(-50%)',
                    }}
                  />
                </div>
              );
            })}
          </div>
          {clinics.map((clinic, index) => {
            const clinicSchedule = sortBy(
              schedules?.filter(({ clinicId }) => {
                return clinic.id === clinicId;
              }),
              (schedule) => {
                const services = schedule.staff_schedule_services.map(
                  ({ service }) => {
                    return service;
                  },
                );

                const isOnCall =
                  services.includes('ONCALL') ||
                  services.includes('XRAY_ONCALL');

                return isOnCall;
              },
            );

            const showLine = clinics.length > 1 && index !== 0;

            return (
              <div
                key={clinic.id}
                css={{
                  paddingTop: '52px',
                  borderTop: showLine
                    ? `1px dashed ${Colors.grayLight}`
                    : undefined,
                }}
              >
                {clinicSchedule?.map((schedule) => {
                  const services = schedule.staff_schedule_services.map(
                    ({ service }) => {
                      return service;
                    },
                  );
                  const isOnCall =
                    services.includes('ONCALL') ||
                    services.includes('XRAY_ONCALL');
                  const displayColor = isOnCall
                    ? Colors.grayLighter
                    : lighten(0.35, schedule?.staff?.color! ?? Colors.teal);

                  let startTime = DateTime.fromISO(schedule.startDateTime);
                  let endTime = DateTime.fromISO(schedule.endDateTime);

                  if (!isSameDate(date, endTime)) {
                    endTime = endTime.startOf('day');
                  }

                  if (!isSameDate(date, startTime)) {
                    startTime = endTime.startOf('day');
                  }

                  const offset = getDisplayOffset({
                    millPerPix,
                    dayStart: dayOpen,
                    display: {
                      startTime,
                      endTime,
                    },
                  });

                  return (
                    <div
                      key={schedule.id}
                      style={{
                        marginBottom: 20,
                        width: offset.width,
                        position: 'relative',
                        left: offset.offset,
                        overflow: 'auto',
                      }}
                    >
                      <div
                        key={schedule.id}
                        css={{
                          borderRadius: '8px',
                          minHeight: 50,
                          maxHeight: 50,
                          padding: '8px 16px',
                          display: 'flex',
                          flexDirection: 'row',
                          backgroundColor: displayColor,
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        <div>
                          <PrimaryText
                            style={{
                              fontWeight: 'bold',
                              fontSize: '14px',
                            }}
                          >
                            {startTime
                              .toFormat('h:mm a ZZZZ')
                              .replace(':00', '')}
                            {' - '}
                            {endTime.toFormat('h:mm a ZZZZ').replace(':00', '')}
                          </PrimaryText>
                        </div>
                        {isManager && (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '4px',
                            }}
                          >
                            <TransparentButton
                              onClick={() => handleScheduleEdit(schedule.id)}
                              style={{
                                border: `1px solid ${Colors.grayDarkest}`,
                                borderRadius: '4px',
                                padding: '2px 8px',
                              }}
                            >
                              <PrimaryText
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '12px',
                                }}
                              >
                                Edit
                              </PrimaryText>
                            </TransparentButton>
                            <TransparentButton
                              onClick={() => handleScheduleDelete(schedule.id)}
                              style={{
                                border: `1px solid ${Colors.grayDarkest}`,
                                borderRadius: '4px',
                                padding: '2px 8px',
                              }}
                            >
                              <PrimaryText
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '12px',
                                }}
                              >
                                Delete
                              </PrimaryText>
                            </TransparentButton>
                          </div>
                        )}

                        {offset.width > 300 && (
                          <PrimaryText
                            style={{
                              fontWeight: 600,
                              fontSize: '12px',
                              marginLeft: '48px',
                            }}
                          >
                            {schedule.staff_schedule_services
                              .map((s: any) =>
                                startCase(s.service.toLowerCase()),
                              )
                              .join(', ')}
                          </PrimaryText>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
