/** @jsx jsx */
import { useLoadStaffQuery } from '@bc/codegen/manager';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { useField, useFormikContext } from 'formik';
import { uniq } from 'lodash';
import { DateTime } from 'luxon';
import { Fragment } from 'react';
import { ICONS } from '../../assets';
import { getTimeSlots } from '../../helpers';
import { IF_TABLET } from '../../styles';
import { InputWrap, NormalField, Select, SelectWithCaret } from '../form';
import { InfoSection } from '../layout';
import StaffSearch from '../StaffSearch/StaffSearch';
import { PrimaryText } from '../text';

const StaffImage = styled.img({
  border: `1px solid ${Colors.white}`,
  borderRadius: '16px',
  height: '32px',
  objectFit: 'contain',
  width: '32px',
});

const SelectCareField = ({
  name,
  children,
}: {
  name: string;
  children: React.ReactNode;
}) => {
  const [field] = useField(name);

  return (
    <SelectWithCaret>
      <Select {...field}>{children}</Select>
    </SelectWithCaret>
  );
};

interface Values {
  reason: string;
  startTime: string;
  endTime: string;
  staffs: string[];
}

const filterSlot = (start: string, end: string) => {
  const startTimeParsed = DateTime.fromFormat(start, 'h:mm a');
  const endTimeParsed = DateTime.fromFormat(end, 'h:mm a');
  return endTimeParsed > startTimeParsed;
};

const useSlots = () => {
  const slots = getTimeSlots({
    open: { hour: 0, minute: 0 },
    close: { hour: 24, minute: 0 },
    interval: 15,
  });

  const { values } = useFormikContext<Values>();
  const startSlots = !values.endTime
    ? slots
    : slots.filter((slot) => filterSlot(slot.display, values.endTime));
  const endSlots = !values.startTime
    ? slots
    : slots.filter((slot) => filterSlot(values.startTime, slot.display));

  return { startSlots, endSlots };
};

const BlockerForm = () => {
  const { startSlots, endSlots } = useSlots();

  const { values, setFieldValue } = useFormikContext<Values>();
  const { data: staffSearch } = useLoadStaffQuery({
    variables: {
      where: {
        active: {
          _eq: true,
        },
      },
    },
  });

  const staffs = staffSearch?.staff ?? [];
  const selectedStaff = staffs.filter(({ id }) => values.staffs.includes(id));

  return (
    <Fragment>
      <div
        css={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '16px',
          flexWrap: 'wrap',
          [IF_TABLET]: {
            flexWrap: 'nowrap',
          },
        }}
      >
        <InfoSection title="Start Time">
          <SelectCareField name="startTime">
            <option value="">Select</option>
            {startSlots.map(({ display }) => {
              return (
                <option key={`start-${display}`} value={display}>
                  {display}
                </option>
              );
            })}
          </SelectCareField>
        </InfoSection>
        <PrimaryText
          css={{
            padding: '18px 16px 0 16px',
          }}
        >
          to
        </PrimaryText>
        <InfoSection title="End Time">
          <SelectCareField name="endTime">
            <option value="">Select</option>
            {endSlots.map(({ display }) => {
              return (
                <option key={`end-${display}`} value={display}>
                  {display}
                </option>
              );
            })}
          </SelectCareField>
        </InfoSection>
      </div>

      <InputWrap>
        <NormalField title="Description (optional)" name="reason" fullWidth />
      </InputWrap>
      <InfoSection title="Block for Staff (optional)" fullWidth>
        <PrimaryText css={{ marginBottom: '16px' }}>
          Leave empty to block scheduling for entire clinic
        </PrimaryText>
        <StaffSearch
          filterIds={values.staffs}
          onChange={(staff) => {
            if (staff) {
              setFieldValue('staffs', uniq([...values.staffs, staff.id]));
            }
          }}
        />
        <div css={{ paddingTop: '4px' }}>
          {selectedStaff.map((staff) => {
            return (
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '4px 0',
                }}
              >
                <StaffImage
                  key={staff.id}
                  title={`${staff.firstName} ${staff.lastName}`}
                  src={
                    staff.avatarUrl ||
                    'https://bravecare.imgix.net/staff/empty.png'
                  }
                  css={{
                    marginRight: '8px',
                  }}
                />
                <PrimaryText>
                  {staff.firstName} {staff.lastName}
                </PrimaryText>
                <img
                  src={ICONS.clear}
                  alt="Remove Staff"
                  css={{ marginLeft: '16px', cursor: 'pointer' }}
                  onClick={() => {
                    const removed = values.staffs.filter((staffId) => {
                      return staffId !== staff.id;
                    });
                    setFieldValue('staffs', removed);
                  }}
                />
              </div>
            );
          })}
        </div>
      </InfoSection>
    </Fragment>
  );
};
export default BlockerForm;
