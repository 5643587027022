import { Staff_Schedule_Bool_Exp } from '@bc/codegen/manager';
import {
  Staff_Type_Enum,
  useLoadStaffScheduleQuery,
} from '@bc/codegen/medical';
import { useField, useFormikContext } from 'formik';
import { Interval } from 'luxon';
import React, { useEffect } from 'react';
import { PrimaryNotBlank } from '../text';
import StaffScheduleSearch from './StaffScheduleSearch';

interface Props {
  name: string;
  display: Staff_Type_Enum[];
  clinicId?: string;
  clinicCode?: string;
  apptTimeSpan: Interval;
  serviceType?: any;
}

const StaffScheduleField = ({
  name,
  display,
  clinicId,
  clinicCode,
  apptTimeSpan,
  serviceType,
}: Props) => {
  const [field, , helpers] = useField(name);
  const form = useFormikContext();
  const editing = form.status.editing;

  let clinicQuery: Staff_Schedule_Bool_Exp = {};
  if (clinicId) {
    clinicQuery = {
      clinicId: {
        _eq: clinicId,
      },
    };
  } else if (clinicCode) {
    clinicQuery = {
      clinic: {
        clinicCode: {
          _eq: clinicCode,
        },
      },
    };
  }

  const { data: staffSearch, loading } = useLoadStaffScheduleQuery({
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        ...clinicQuery,
        startDateTime: {
          _lte: apptTimeSpan.start.toISO(),
        },
        endDateTime: {
          _gte: apptTimeSpan.end.toISO(),
        },
      },
    },
  });

  const staffSchedule = staffSearch?.staff_schedule ?? [];
  const staffs =
    staffSchedule
      .filter(({ staff_schedule_services }) => {
        if (!serviceType) {
          return true;
        }

        const hasServiceVisitType = staff_schedule_services?.find((type) => {
          return type.service === serviceType;
        });

        return hasServiceVisitType;
      })
      .map(({ staff }) => {
        return staff;
      }) ?? [];

  const onlyShow = display;
  const filters = staffs
    .filter((staff) => {
      return !onlyShow.includes(staff?.type);
    })
    .map(({ id }) => id);

  const showStaff = staffs
    .filter(Boolean)
    .filter((staff) => {
      return onlyShow.includes(staff?.type);
    })
    .map(({ id }) => id);

  const staffItems = staffs.filter(Boolean).filter((staff) => {
    return onlyShow.includes(staff?.type);
  });

  const value = field.value.find((value: string) => {
    return showStaff.find((id) => id === value);
  });

  const staff = staffs.find((staff) => {
    return staff?.id === value;
  });

  useEffect(() => {
    // check that staff we have selected exist in the list
    if (!loading && staffItems.length && !value && field.value.length) {
      helpers.setTouched(true);
      // Formik is dumb in this case
      // If you touch and set errors at the same time it clears the error
      setTimeout(() => {
        helpers.setError('Selected staff not available during this time');
      }, 0);
    }
  }, [loading, staffItems.length, value]);

  if (!editing) {
    return (
      <PrimaryNotBlank>
        {staff?.firstName} {staff?.lastName}
      </PrimaryNotBlank>
    );
  }

  const getStaffExists = (id: string) => {
    return !!staffItems.find((staff) => {
      return staff.id === id;
    });
  };

  return (
    <StaffScheduleSearch
      staffItems={staffItems}
      onChange={(staff) => {
        if (!staff) {
          helpers.setValue(
            field.value.filter((id: string) => {
              // remove anyone not in the list aka they aren't on that day
              const staffExists = getStaffExists(id);
              return id !== value && staffExists;
            }),
          );
          return;
        }

        const values = field.value.filter((id: string) => {
          // remove anyone not in the list aka they aren't on that day
          return getStaffExists(id);
        });

        helpers.setValue([...values, staff.id]);
      }}
      filterIds={filters}
      value={value}
    />
  );
};

export default StaffScheduleField;
