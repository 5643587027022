/** @jsx jsx */
import { useGetAccountByPkQuery } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import React from 'react';
import { formatDateOfBirth, getDateOfBirth } from '../../helpers';
import { Column, IF_TABLET, Row } from '../../styles';
import { AccountAvatarDisplay } from '../AppointmentDetails/AvatarDisplay';
import { PronounBadge } from '../Badge';
import { LargeTitle, PrimaryText } from '../text';
import { TextWrapper } from './header.styles';

export const AccountHeader = ({
  accountId,
  children,
  className,
}: {
  accountId?: string;
  avatarSize?: number;
  children?: React.ReactNode;
  className?: string;
}) => {
  const { data } = useGetAccountByPkQuery({
    variables: { accountId: accountId! },
    skip: !accountId,
  });
  const account = data?.accounts_by_pk;

  if (!account?.id) return null;

  const selfAccountPatient = account.account_patients.find(
    (ap) => ap.relationship === 'self',
  );
  const patient = selfAccountPatient?.patient;

  return (
    <Row className={className}>
      <AccountAvatarDisplay
        accountId={account.id}
        css={{
          border: `2px solid ${Colors.grayLighter}`,
          width: 64,
          height: 64,
          [IF_TABLET]: {
            width: 120,
            height: 120,
          },
        }}
      />
      <Column css={{ marginLeft: 16 }}>
        <LargeTitle css={{ margin: '0 8px 8px 0' }}>
          {account?.firstName}
          {account?.goesByName ? ` ‘${account.goesByName}’ ` : ' '}
          {account?.lastName}
        </LargeTitle>
        <TextWrapper>
          {patient && (
            <PrimaryText>
              {getDateOfBirth({
                date: patient?.dateOfBirth,
                addOld: true,
              })}
              {formatDateOfBirth(patient?.dateOfBirth)
                ? ` • ${formatDateOfBirth(patient?.dateOfBirth)}`
                : ''}
            </PrimaryText>
          )}
          {account?.preferredPronounType && (
            <PronounBadge
              preferredPronounType={account?.preferredPronounType}
              preferredPronoun={account?.preferredPronoun ?? ''}
              css={{ marginLeft: 8 }}
            />
          )}
        </TextWrapper>
        {children}
      </Column>
    </Row>
  );
};
