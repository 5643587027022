import {
  useChatSessionsSubscription,
  useLoadChatSessionsQuery,
} from '@bc/codegen/medical';
import { DateTime } from 'luxon';
import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ActiveChatRow } from '../../components/ChatRow';
import { Header } from '../../components/Header';
import { Content, InnerWrap, MainContent } from '../../components/layout';
import { Sidebar } from '../../components/Nav/Sidebar';
import { useMessageWatch } from '../../lib/sound';
import { Title } from './Chats.styles';
import { getChatLabel } from './helpers';

const WaitingRoom: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  const { data: queryData } = useLoadChatSessionsQuery();
  const { data: subscriptionData } = useChatSessionsSubscription();
  const data = subscriptionData ?? queryData;
  useMessageWatch();

  const waitingChats = data?.chat_sessions
    ?.filter(
      (chat) =>
        chat.status !== 'CLOSED' && chat.chat_sessions_staffs.length === 0,
    )
    .map((chat: any) => getChatLabel(chat))
    .sort((a, b) => {
      const chatLabelA = a?.chatLabel;
      const chatLabelB = b?.chatLabel;
      const createdAtA = DateTime.fromISO(a?.createdAt).toSeconds();
      const createdAtB = DateTime.fromISO(b?.createdAt).toSeconds();
      // if chats have the same category, sort by descending chat session createdAt
      if (chatLabelA === chatLabelB) {
        return createdAtB - createdAtA;
      }
      return chatLabelA - chatLabelB;
    });

  const handleChatSelection = (id: string) => {
    history.push(`/chats/${id}`);
  };

  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap>
          <Sidebar />
          <MainContent
            style={{
              paddingTop: '40px',
            }}
          >
            <Title>Waiting Room</Title>
            {waitingChats?.map((chat) => (
              <ActiveChatRow
                key={chat.id}
                chatSession={chat}
                onClick={() => handleChatSelection(chat.id)}
              />
            ))}
          </MainContent>
        </InnerWrap>
      </Content>
    </Fragment>
  );
};

export default WaitingRoom;
