import {
  AppointmentSchedulePartsFragment,
  Appointment_Status_Enum,
  DashboardAppointmentFragment,
  GlobalNoteFragment,
  Maybe,
  StaffFragment,
  Visit_Classifications_Enum,
  Visit_Types_Enum,
  WatchAppointmentBlocksSubscription,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import upperFirst from 'lodash/upperFirst';
import { DateTime } from 'luxon';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';
import { CheckboxOption } from '../components/CheckboxFilter/CheckboxFilter';

type AppointmentBlock = DeepExtractTypeSkipArrays<
  WatchAppointmentBlocksSubscription,
  ['appointment_blocks']
>;

export type IsMatch = (appt: AppointmentSchedulePartsFragment) => boolean;

interface SetTimeOnDate {
  displayDate: DateTime;
  hour: number;
  minute?: number;
}

export const getDateTimeToDisplay = ({
  displayDate,
  hour,
  minute = 0,
}: SetTimeOnDate): string | null => {
  return displayDate
    .set({ hour, minute })
    .startOf('minute')
    .toSQL({ includeZone: false, includeOffset: false });
};

export const getDisplayDate = (date: DateTime) => {
  const today = DateTime.local();
  const hideRelative = Math.abs(date.ordinal - today.ordinal) > 1;
  const relative = date.toRelativeCalendar();

  return {
    date: `${date.toFormat('cccc, LLLL d')}${
      hideRelative ? '' : ` (${upperFirst(relative || '')})`
    }`,
    time: date.toFormat('h:mm a'),
    shortDate: `${date.toFormat('ccc., LLL. d')}${
      hideRelative ? '' : ` (${upperFirst(relative || '')})`
    }`,
  };
};

export interface ScheduleDisplayItem {
  dateOfBirth?: string;
  endTime: DateTime;
  firstName?: string;
  globalNotes?: Partial<GlobalNoteFragment>[];
  goesByName?: string;
  id: string;
  kareoLinked?: boolean;
  lastName?: string;
  pronunciation?: string;
  reason?: string;
  safetyQuestionnaire?: {
    covid19Symptoms?: boolean;
    covid19Exposure?: boolean;
    covid19Test?: boolean;
    covid19TestResult?: 'positive' | 'negative';
  };
  staff?: Partial<StaffFragment>[];
  startTime: DateTime;
  status?: Appointment_Status_Enum;
  type: 'APPOINTMENT' | 'BLOCK';
  visitClassification?: Maybe<Visit_Classifications_Enum>;
  visitType?: Visit_Types_Enum;
}

export const getAppointmentDisplay = (
  appointments: DashboardAppointmentFragment[],
): ScheduleDisplayItem[] => {
  return appointments.map((appointment) => {
    const {
      appointment_patients,
      appointment_staffs,
      endTime,
      id,
      kareo_appointment_id,
      reason,
      safetyQuestionnaire,
      startTime,
      status,
      visitClassification,
      visitType,
    } = appointment;

    const patientNotes = appointment_patients?.flatMap(
      (app) => app.patient.global_notes,
    );
    const globalNotes = [...patientNotes].filter(Boolean);

    const patient = appointment?.appointment_patients[0]?.patient;
    const { firstName, lastName, goesByName, dateOfBirth, pronunciation } =
      patient || {};

    return {
      id,
      type: 'APPOINTMENT',
      startTime: DateTime.fromISO(startTime),
      endTime: DateTime.fromISO(endTime),
      reason,
      firstName: firstName ?? '',
      lastName: lastName ?? '',
      goesByName: goesByName ?? '',
      pronunciation: pronunciation ?? '',
      status: (status || '') as Appointment_Status_Enum,
      visitType: (visitType || 'URGENT_CARE') as Visit_Types_Enum,
      visitClassification,
      dateOfBirth,
      globalNotes,
      kareoLinked: !!kareo_appointment_id,
      safetyQuestionnaire,
      staff: appointment_staffs.filter(Boolean).map(({ staff }) => ({
        id: staff?.id,
        avatarUrl:
          staff?.avatarUrl || 'https://bravecare.imgix.net/staff/empty.png',
      })),
    };
  });
};

export const getBlockDisplay = (
  blocks: AppointmentBlock[],
): ScheduleDisplayItem[] => {
  return blocks.map((block) => {
    const {
      id,
      startDateTime,
      endDateTime,
      reason,
      appointment_blocks_staffs,
    } = block;

    const staffs = appointment_blocks_staffs?.map(({ staff }) => staff);

    return {
      id,
      type: 'BLOCK',
      startTime: DateTime.fromISO(startDateTime),
      endTime: DateTime.fromISO(endDateTime),
      reason: reason ?? '',
      staff: staffs,
    };
  });
};

export interface CheckboxVisitOption extends CheckboxOption {
  groupName: 'visitType' | 'visitClassification';
}

export const DEFAULT_VISIT_TYPES: CheckboxVisitOption[] = [
  {
    color: Colors.blue,
    count: 0,
    groupName: 'visitType',
    label: 'Primary Care',
    value: 'PRIMARY_CARE',
  },
  {
    color: Colors.butterscotch,
    count: 0,
    groupName: 'visitType',
    label: 'Urgent Care',
    value: 'URGENT_CARE',
  },
  {
    color: Colors.violet,
    count: 0,
    groupName: 'visitType',
    label: 'Virtual Care Visit',
    value: 'TELEMEDICINE',
  },
  {
    color: Colors.bubblegum,
    count: 0,
    groupName: 'visitClassification',
    label: 'COVID Test',
    value: 'COVID_19_TEST',
  },
  {
    color: Colors.green,
    count: 0,
    groupName: 'visitType',
    label: 'Vaccination',
    value: 'VACCINATION',
  },
  {
    color: Colors.bubblegum,
    count: 0,
    groupName: 'visitType',
    label: 'Testing',
    value: 'TESTING',
  },
  {
    color: Colors.teal,
    count: 0,
    groupName: 'visitType',
    label: 'Meet and Greet',
    value: 'MEET_AND_GREET',
  },
];

export const DEFAULT_STATUSES: CheckboxOption[] = [
  {
    color: Colors.teal,
    count: 0,
    groupName: 'statuses',
    label: 'Booked',
    value: 'CREATED',
  },
  {
    color: Colors.teal,
    count: 0,
    groupName: 'statuses',
    label: 'Checked In',
    value: 'CHECKED_IN',
  },
  {
    color: Colors.teal,
    count: 0,
    groupName: 'statuses',
    label: 'Ready for Provider',
    value: 'READY_FOR_PROVIDER',
  },
  {
    color: Colors.teal,
    count: 0,
    groupName: 'statuses',
    label: 'With provider',
    value: 'WITH_PROVIDER',
  },
  {
    color: Colors.teal,
    count: 0,
    groupName: 'statuses',
    label: 'Provider Finished',
    value: 'PROVIDER_FINISHED',
  },
  {
    color: Colors.teal,
    count: 0,
    groupName: 'statuses',
    label: 'Completed',
    value: 'COMPLETED',
  },
  // {
  //   color: Colors.teal,
  //   count: 0,
  //   groupName: 'statuses',
  //   label: 'Cancelled',
  //   value: 'CANCELED',
  // },
  // {
  //   color: Colors.teal,
  //   count: 0,
  //   groupName: 'statuses',
  //   label: 'No Show',
  //   value: 'NO_SHOW',
  // },
];
