/** @jsx jsx */
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { PrimaryText } from '../text';

export const Badge = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <Wrap className={className}>
      <Text>{children}</Text>
    </Wrap>
  );
};

const Wrap = styled.div({
  borderRadius: 4,
  backgroundColor: Colors.grayLighter,
  color: Colors.darkHarbor,
  padding: '1px 5px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'max-content',
});

const Text = styled(PrimaryText)({
  fontSize: 13,
  fontWeight: 500,
  letterSpacing: '1px',
  lineHeight: '18px',
});
