/** @jsx jsx */
import { CommunicationHistoryFragment } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import NotFound from '../../assets/notfound.png';
import { formatPhoneNumber } from '../../helpers';
import { Column, IF_TABLET, Row } from '../../styles';
import { LargeTitle, PrimaryText, SmallTitle } from '../text';

const Image = styled.img({
  backgroundColor: '#8e949d',
  border: `2px solid ${Colors.grayLighter}`,
  borderRadius: '50%',
  height: 64,
  width: 64,
  [IF_TABLET]: {
    height: 120,
    width: 120,
  },
});

const CondensedImage = styled(Image)({
  border: 'none',
  height: 64,
  width: 64,
  [IF_TABLET]: {
    height: 64,
    width: 64,
  },
});

export const UnknownHeader = ({
  fullName,
  children,
  className,
  communication,
  condensed,
}: {
  children?: React.ReactNode;
  className?: string;
  communication?: CommunicationHistoryFragment;
  condensed?: boolean;
  fullName?: string;
}) => {
  const isInbound = communication?.direction === 'INBOUND';
  const isSMS = communication?.type === 'SMS';
  const title =
    fullName ||
    formatPhoneNumber(isInbound ? communication?.from : communication?.to);

  return (
    <Row className={className}>
      {condensed ? <CondensedImage src={NotFound} /> : <Image src={NotFound} />}
      <Column css={{ marginLeft: 16 }}>
        {condensed ? (
          <SmallTitle css={{ margin: '0 8px 8px 0' }}>{title}</SmallTitle>
        ) : (
          <LargeTitle css={{ margin: '0 8px 8px 0' }}>{title}</LargeTitle>
        )}
        {communication && (
          <div
            css={{
              alignItems: condensed ? 'flex-start' : 'center',
              display: 'flex',
              flexDirection: condensed ? 'column' : 'row',
              marginBottom: 16,
            }}
          >
            <PrimaryText css={{ display: 'block', marginRight: 16 }}>
              Caller ID:
            </PrimaryText>
            <div>
              <PrimaryText css={{ display: 'block' }}>
                {(isInbound &&
                  communication?.metadata?.[isSMS ? 'sms' : 'call']
                    ?.CallerName) ||
                  'Unknown Name'}
              </PrimaryText>
              <PrimaryText css={{ display: 'block' }}>
                {communication?.metadata?.[isSMS ? 'sms' : 'call']?.[
                  isInbound ? 'FromCity' : 'ToCity'
                ] || 'Unknown City'}
                ,{' '}
                {communication?.metadata?.[isSMS ? 'sms' : 'call']?.[
                  isInbound ? 'FromState' : 'ToState'
                ] || 'Unknown State'}{' '}
                {communication?.metadata?.[isSMS ? 'sms' : 'call']?.[
                  isInbound ? 'FromZip' : 'ToZip'
                ] || 'Unknown Zip'}
              </PrimaryText>
            </div>
          </div>
        )}
        {children}
      </Column>
    </Row>
  );
};
