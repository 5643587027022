import { AppointmentPartsFragment } from '@bc/codegen/medical';
import React from 'react';
import { AdditionalInformation } from '../../components/Patient';

const AppointmentAdditionalInfo: React.FC<{
  appointment: AppointmentPartsFragment;
}> = ({ appointment }) => {
  const patient = appointment?.appointment_patients[0]?.patient;
  return patient ? (
    <AdditionalInformation appointment={appointment} data={patient} />
  ) : null;
};

export default AppointmentAdditionalInfo;
