import { useUpdateAppointmentStatusMutation } from '@bc/codegen/medical';
import { DialogOverlay } from '@reach/dialog';
import * as Sentry from '@sentry/react';
import React from 'react';
import { SmallModal } from '../components/layout';
import { ModalText, ModalTitle } from '../components/text';
import { CancelButton } from '../pages/merge/styles';
import { ButtonRed } from './button';

const CheckInAppointmentModal: React.FC<{
  isOpen: boolean;
  appointmentId: string;
  onCancel: () => void;
}> = ({ isOpen, onCancel, appointmentId }) => {
  const [checkInAppointment] = useUpdateAppointmentStatusMutation();

  const handleCheckIn = async () => {
    try {
      await checkInAppointment({
        variables: {
          id: appointmentId,
          status: 'CHECKED_IN',
          notification: false,
        },
      });
      onCancel();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={onCancel}
      style={{
        zIndex: 100,
      }}
    >
      <SmallModal aria-label="Cancel Appointment">
        <ModalTitle>Warning!</ModalTitle>
        <ModalText>
          Appointments are automatically set to Ready for MA when the patient
          has finished checking in. Only change this status if the patient was
          unable to check in.
        </ModalText>
        <ButtonRed
          tabIndex={0}
          type="button"
          style={{ display: 'inline-block' }}
          onClick={handleCheckIn}
        >
          Change to Ready for MA
        </ButtonRed>
        <CancelButton style={{ display: 'inline-block' }} onClick={onCancel}>
          Close
        </CancelButton>
      </SmallModal>
    </DialogOverlay>
  );
};

export default CheckInAppointmentModal;
