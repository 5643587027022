import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
  bigint: number;
  date: string;
  geography: any;
  geometry: any;
  jsonb: any;
  numeric: number;
  time: string;
  timestamp: string;
  timestamptz: string;
  uuid: string;
};

export type AccountPatientOutput = {
  __typename?: 'AccountPatientOutput';
  accountId?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
};

export type AddAccountOutput = {
  __typename?: 'AddAccountOutput';
  id: Scalars['String'];
};

export type ApplyStaffRolesOutput = {
  __typename?: 'ApplyStaffRolesOutput';
  success: Scalars['Boolean'];
};

export type AttachAccountOutput = {
  __typename?: 'AttachAccountOutput';
  id: Scalars['String'];
};

export type Auth0EmployeeOutput = {
  __typename?: 'Auth0EmployeeOutput';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  userId: Scalars['String'];
};

export type Auth0LinkAccountsOutput = {
  __typename?: 'Auth0LinkAccountsOutput';
  id: Scalars['String'];
};

export type Auth0PayloadOutput = {
  __typename?: 'Auth0PayloadOutput';
  id: Scalars['String'];
};

export type Auth0UserIdOutput = {
  __typename?: 'Auth0UserIdOutput';
  userId?: Maybe<Scalars['String']>;
};

export type BookAppointmentOutput = {
  __typename?: 'BookAppointmentOutput';
  booked: Scalars['Boolean'];
  id?: Maybe<Scalars['String']>;
  nextAvailable?: Maybe<Scalars['String']>;
};

export type BookWalkinAppointmentOutput = {
  __typename?: 'BookWalkinAppointmentOutput';
  id: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type ChargeResponseOutput = {
  __typename?: 'ChargeResponseOutput';
  id: Scalars['String'];
  receiptUrl?: Maybe<Scalars['String']>;
};

export type CommunicationHistoryOutput = {
  __typename?: 'CommunicationHistoryOutput';
  id: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CommunicationInput = {
  body?: InputMaybe<Scalars['String']>;
  call?: InputMaybe<Scalars['jsonb']>;
  direction: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  from: Scalars['String'];
  sms?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['String']>;
  to: Scalars['String'];
  type: Scalars['String'];
};

export type CommunicationOutput = {
  __typename?: 'CommunicationOutput';
  id: Scalars['String'];
  success: Scalars['Boolean'];
};

export type CreateKareoAppointmentOutput = {
  __typename?: 'CreateKareoAppointmentOutput';
  success: Scalars['Boolean'];
};

export type CreateKareoPatientOutput = {
  __typename?: 'CreateKareoPatientOutput';
  success: Scalars['Boolean'];
};

export type CreatePatientOutput = {
  __typename?: 'CreatePatientOutput';
  id: Scalars['String'];
};

export type CreateSupportTicketOutput = {
  __typename?: 'CreateSupportTicketOutput';
  success: Scalars['Boolean'];
};

export type CustomerIoGetCouponOutput = {
  __typename?: 'CustomerIOGetCouponOutput';
  code: Scalars['String'];
};

export type DocumentDetectOutput = {
  __typename?: 'DocumentDetectOutput';
  gcv?: Maybe<Scalars['jsonb']>;
};

export type DocumentPayload = {
  __typename?: 'DocumentPayload';
  account?: Maybe<UserPayload>;
  id: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type FilePayload = {
  __typename?: 'FilePayload';
  gcv?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type GetAllAvailableVisitTypeTimesOutput = {
  __typename?: 'GetAllAvailableVisitTypeTimesOutput';
  availability?: Maybe<Array<TheoreticalAvailabilityOutput>>;
  clinicId: Scalars['String'];
};

export type GetAvailableAppointmentsByStaffInRangeAppointment = {
  __typename?: 'GetAvailableAppointmentsByStaffInRangeAppointment';
  end: Scalars['String'];
  start: Scalars['String'];
};

export type GetAvailableAppointmentsByStaffInRangeClinic = {
  __typename?: 'GetAvailableAppointmentsByStaffInRangeClinic';
  clinicId: Scalars['uuid'];
  staff: Array<GetAvailableAppointmentsByStaffInRangeStaff>;
};

export type GetAvailableAppointmentsByStaffInRangeOutput = {
  __typename?: 'GetAvailableAppointmentsByStaffInRangeOutput';
  clinics: Array<GetAvailableAppointmentsByStaffInRangeClinic>;
};

export type GetAvailableAppointmentsByStaffInRangeStaff = {
  __typename?: 'GetAvailableAppointmentsByStaffInRangeStaff';
  appointmentSlots: Array<GetAvailableAppointmentsByStaffInRangeAppointment>;
  avatarUrl?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  languagesSpoken?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  staffId: Scalars['uuid'];
  staff_type: GetAvailableAppointmentsByStaffInRangeStaffType;
};

export type GetAvailableAppointmentsByStaffInRangeStaffType = {
  __typename?: 'GetAvailableAppointmentsByStaffInRangeStaffType';
  description: Scalars['String'];
  value: Scalars['String'];
};

export type GetAvailableAppointmentsInRangeAppointment = {
  __typename?: 'GetAvailableAppointmentsInRangeAppointment';
  end: Scalars['String'];
  start: Scalars['String'];
};

export type GetAvailableAppointmentsInRangeClinic = {
  __typename?: 'GetAvailableAppointmentsInRangeClinic';
  appointmentSlots: Array<GetAvailableAppointmentsInRangeAppointment>;
  clinicId: Scalars['uuid'];
};

export type GetAvailableAppointmentsInRangeOutput = {
  __typename?: 'GetAvailableAppointmentsInRangeOutput';
  clinics: Array<GetAvailableAppointmentsInRangeClinic>;
};

export type GetFileOutput = {
  __typename?: 'GetFileOutput';
  accountId?: Maybe<Scalars['uuid']>;
  contentType?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  documentType?: Maybe<Scalars['String']>;
  gcv?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  path?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  url: Scalars['String'];
};

export type GetScheduleOutput = {
  __typename?: 'GetScheduleOutput';
  appointments: Array<ScheduleAppointment>;
  clinic: ScheduleClinic;
  staffSchedule: Array<StaffSchedule>;
};

export type GetStaffInfoElement = {
  __typename?: 'GetStaffInfoElement';
  avatar?: Maybe<Scalars['String']>;
  bio: Scalars['String'];
  staffId: Scalars['uuid'];
};

export type GetStaffInfoOutput = {
  __typename?: 'GetStaffInfoOutput';
  staffInfo: Array<GetStaffInfoElement>;
};

export type GetTheoreticalAvailabilityClinicOutput = {
  __typename?: 'GetTheoreticalAvailabilityClinicOutput';
  availability?: Maybe<Array<TheoreticalAvailabilityOutput>>;
  staffId: Scalars['String'];
};

export type GetTheoreticalAvailabilityRangeAvailability = {
  __typename?: 'GetTheoreticalAvailabilityRangeAvailability';
  end: Scalars['String'];
  start: Scalars['String'];
};

export type GetTheoreticalAvailabilityRangeOutput = {
  __typename?: 'GetTheoreticalAvailabilityRangeOutput';
  availability?: Maybe<Array<GetTheoreticalAvailabilityRangeAvailability>>;
  day: Scalars['String'];
};

export type GoogleAddressComponents = {
  __typename?: 'GoogleAddressComponents';
  long_name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GoogleGeoLocation = {
  __typename?: 'GoogleGeoLocation';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type GooglePlace = {
  __typename?: 'GooglePlace';
  formatted_address: Scalars['String'];
  name: Scalars['String'];
  place_id: Scalars['String'];
};

export type GooglePlaceData = {
  __typename?: 'GooglePlaceData';
  address_components?: Maybe<Array<Maybe<GoogleAddressComponents>>>;
  adr_address?: Maybe<Scalars['String']>;
  formatted_address?: Maybe<Scalars['String']>;
  formatted_phone_number?: Maybe<Scalars['String']>;
  geometry?: Maybe<GooglePlaceGeometry>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  place_id?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  url?: Maybe<Scalars['String']>;
  utc_offset?: Maybe<Scalars['Int']>;
  vicinity?: Maybe<Scalars['String']>;
};

export type GooglePlaceGeometry = {
  __typename?: 'GooglePlaceGeometry';
  location?: Maybe<GoogleGeoLocation>;
  viewport?: Maybe<GooglePlaceViewport>;
};

export type GooglePlaceViewport = {
  __typename?: 'GooglePlaceViewport';
  northeast?: Maybe<GoogleGeoLocation>;
  southwest?: Maybe<GoogleGeoLocation>;
};

export type HandleStripeWebhookOutput = {
  __typename?: 'HandleStripeWebhookOutput';
  success: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type KareoFindPatientOutput = {
  __typename?: 'KareoFindPatientOutput';
  dateOfBirth: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  genderId?: Maybe<Scalars['Int']>;
  guid: Scalars['String'];
  lastName: Scalars['String'];
  patientId: Scalars['Int'];
};

export type LoadPrismicBlogPostsOutput = {
  __typename?: 'LoadPrismicBlogPostsOutput';
  posts: Array<PrismicBlogPost>;
};

export type LoadPrismicVaccineFaqsOutput = {
  __typename?: 'LoadPrismicVaccineFaqsOutput';
  vaccineFAQs: Array<PrismicVaccineFaq>;
};

export type LoadPrismicVaccineScheduleOutput = {
  __typename?: 'LoadPrismicVaccineScheduleOutput';
  vaccineAgeRanges: Array<PrismicVaccineSchedule>;
};

export type MergePatientOutput = {
  __typename?: 'MergePatientOutput';
  patientId: Scalars['String'];
};

export type PartialGooglePlace = {
  __typename?: 'PartialGooglePlace';
  formatted_address?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  place_id: Scalars['String'];
};

export type PasswordlessVerifyOutput = {
  __typename?: 'PasswordlessVerifyOutput';
  id: Scalars['String'];
};

export type PatientRelationshipPayload = {
  patientId?: InputMaybe<Scalars['String']>;
  relationship?: InputMaybe<Scalars['String']>;
};

export type PatientTelehealthConsentOutput = {
  __typename?: 'PatientTelehealthConsentOutput';
  id?: Maybe<Scalars['String']>;
  patient?: Maybe<Patients>;
};

export type PaymentType = 'INSURANCE_POS' | 'OUT_OF_POCKET';

export type PrismicAuthor = {
  __typename?: 'PrismicAuthor';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type PrismicBlogPost = {
  __typename?: 'PrismicBlogPost';
  createdAt?: Maybe<Scalars['String']>;
  featureImage?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  ogDescription?: Maybe<Scalars['String']>;
  ogImage?: Maybe<Scalars['String']>;
  ogTitle?: Maybe<Scalars['String']>;
  primaryAuthor?: Maybe<PrismicAuthor>;
  publishedAt?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterDescription?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<Scalars['String']>;
  twitterTitle?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type PrismicVaccine = {
  __typename?: 'PrismicVaccine';
  slug?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PrismicVaccineAnswer = {
  __typename?: 'PrismicVaccineAnswer';
  answer?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
};

export type PrismicVaccineDetail = {
  __typename?: 'PrismicVaccineDetail';
  description?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  items: Array<PrismicVaccineItem>;
  methodOfAdministration?: Maybe<Scalars['String']>;
  numberOfDoses?: Maybe<Scalars['Int']>;
  shortName?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type PrismicVaccineFaq = {
  __typename?: 'PrismicVaccineFaq';
  items: Array<PrismicVaccineFaqItems>;
  title?: Maybe<Scalars['String']>;
};

export type PrismicVaccineFaqItems = {
  __typename?: 'PrismicVaccineFaqItems';
  answer?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
};

export type PrismicVaccineItem = {
  __typename?: 'PrismicVaccineItem';
  answers?: Maybe<PrismicVaccineAnswer>;
  question?: Maybe<Scalars['String']>;
};

export type PrismicVaccineSchedule = {
  __typename?: 'PrismicVaccineSchedule';
  endMonths?: Maybe<Scalars['Int']>;
  icon?: Maybe<Scalars['String']>;
  iconBackgroundColor?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  startMonths?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  vaccines: Array<PrismicVaccine>;
};

export type ProductPayload = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

export type PushNotificationChannel = 'apn' | 'fcm';

export type RegisterDeviceTokenOutput = {
  __typename?: 'RegisterDeviceTokenOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type RequestUploadOutput = {
  __typename?: 'RequestUploadOutput';
  fileId: Scalars['String'];
  mimeType: Scalars['String'];
  url: Scalars['String'];
};

export type ReservationInput = {
  clinicId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  startTime: Scalars['String'];
  timeZone?: InputMaybe<Scalars['String']>;
  visitClassification?: InputMaybe<Scalars['String']>;
  visitType: Scalars['String'];
};

export type ReservationOutput = {
  __typename?: 'ReservationOutput';
  id: Scalars['String'];
};

export type RetrieveVerificationCodeOutput = {
  __typename?: 'RetrieveVerificationCodeOutput';
  code: Scalars['String'];
};

export type ReviewResultOutput = {
  __typename?: 'ReviewResultOutput';
  googlePlaceId?: Maybe<Scalars['String']>;
};

export type SnomedMappingOutput = {
  __typename?: 'SNOMEDMappingOutput';
  results: Array<SnomedMappingResult>;
};

export type SnomedMappingResult = {
  __typename?: 'SNOMEDMappingResult';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type SnomedSearchOutput = {
  __typename?: 'SNOMEDSearchOutput';
  results?: Maybe<Array<SnomedSearchResult>>;
};

export type SnomedSearchResult = {
  __typename?: 'SNOMEDSearchResult';
  code: Scalars['String'];
  name: Scalars['String'];
  synonym?: Maybe<Scalars['String']>;
};

export type SnomedSearchType = 'ALLERGY' | 'MEDICAL' | 'PROBLEM' | 'PROCEDURE';

export type ScheduleAppointment = {
  __typename?: 'ScheduleAppointment';
  careType?: Maybe<Scalars['String']>;
  endTime: Scalars['String'];
  id: Scalars['uuid'];
  isMobileCheckIn?: Maybe<Scalars['Boolean']>;
  kareo_appointment_id?: Maybe<Scalars['String']>;
  patient: ScheduleAppointmentPatient;
  patients: Array<ScheduleAppointmentPatient>;
  primaryStaffId?: Maybe<Scalars['uuid']>;
  reason: Scalars['String'];
  rooms: Array<ScheduleRoom>;
  safetyQuestionnaire?: Maybe<Scalars['String']>;
  staff: Array<ScheduleAppointmentStaffs>;
  startTime: Scalars['String'];
  status?: Maybe<Scalars['String']>;
};

export type ScheduleAppointmentOutput = {
  __typename?: 'ScheduleAppointmentOutput';
  id: Scalars['String'];
};

export type ScheduleAppointmentPatient = {
  __typename?: 'ScheduleAppointmentPatient';
  dateOfBirth: Scalars['String'];
  firstName: Scalars['String'];
  goesByName?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  lastName: Scalars['String'];
  notes: Array<ScheduleNotes>;
  pronunciation?: Maybe<Scalars['String']>;
};

export type ScheduleAppointmentStaffs = {
  __typename?: 'ScheduleAppointmentStaffs';
  avatarUrl?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['uuid'];
  lastName: Scalars['String'];
  staffType: Scalars['String'];
  type: Scalars['String'];
};

export type ScheduleClinic = {
  __typename?: 'ScheduleClinic';
  close: Scalars['String'];
  id: Scalars['uuid'];
  open: Scalars['String'];
  timeZone?: Maybe<Scalars['String']>;
};

export type ScheduleNotes = {
  __typename?: 'ScheduleNotes';
  id: Scalars['uuid'];
  note: Scalars['String'];
};

export type ScheduleRoom = {
  __typename?: 'ScheduleRoom';
  id: Scalars['uuid'];
  name: Scalars['String'];
  status: Scalars['String'];
};

export type ScheduleService = {
  __typename?: 'ScheduleService';
  service: Scalars['String'];
};

export type ScheduleStaff = {
  __typename?: 'ScheduleStaff';
  authProviderId?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['uuid'];
  lastName: Scalars['String'];
  staffType: Scalars['String'];
  type: Scalars['String'];
};

export type SendNotificationOutput = {
  __typename?: 'SendNotificationOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type SendSymptomSummaryOutput = {
  __typename?: 'SendSymptomSummaryOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type SendVCardOutput = {
  __typename?: 'SendVCardOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type SignConsentOutput = {
  __typename?: 'SignConsentOutput';
  id: Scalars['String'];
};

export type StaffBookAppointmentOutput = {
  __typename?: 'StaffBookAppointmentOutput';
  appointmentCollision?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
};

export type StaffOutput = {
  __typename?: 'StaffOutput';
  avatarUrl?: Maybe<Scalars['String']>;
  defaultClinicId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type StaffSchedule = {
  __typename?: 'StaffSchedule';
  endDateTime?: Maybe<Scalars['String']>;
  services: Array<ScheduleService>;
  staff: ScheduleStaff;
  startDateTime: Scalars['String'];
};

export type StaffUpdateAppointmentTimeOutput = {
  __typename?: 'StaffUpdateAppointmentTimeOutput';
  appointmentCollision: Scalars['Boolean'];
  id?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type StripeCard = {
  __typename?: 'StripeCard';
  address_city?: Maybe<Scalars['String']>;
  address_country?: Maybe<Scalars['String']>;
  address_line1?: Maybe<Scalars['String']>;
  address_line1_check?: Maybe<Scalars['String']>;
  address_line2?: Maybe<Scalars['String']>;
  address_state?: Maybe<Scalars['String']>;
  address_zip?: Maybe<Scalars['String']>;
  address_zip_check?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  cvc_check?: Maybe<Scalars['String']>;
  dynamic_last4?: Maybe<Scalars['String']>;
  exp_month: Scalars['Int'];
  exp_year: Scalars['Int'];
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  last4: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  object: Scalars['String'];
  tokenization_method?: Maybe<Scalars['String']>;
};

export type StripeChargeRefunds = {
  __typename?: 'StripeChargeRefunds';
  data: Array<StripeRefund>;
  has_more: Scalars['Boolean'];
  object: Scalars['String'];
  url: Scalars['String'];
};

export type StripeCreateRefundOutput = {
  __typename?: 'StripeCreateRefundOutput';
  id: Scalars['String'];
};

export type StripeCustomerOutput = {
  __typename?: 'StripeCustomerOutput';
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type StripeCustomerPortalSessionResponse = {
  __typename?: 'StripeCustomerPortalSessionResponse';
  url: Scalars['String'];
};

export type StripePaymentMethodByChargeIdOutput = {
  __typename?: 'StripePaymentMethodByChargeIdOutput';
  brand: Scalars['String'];
  created: Scalars['Int'];
  id: Scalars['String'];
  last4: Scalars['String'];
  receiptEmail?: Maybe<Scalars['String']>;
  receiptNumber?: Maybe<Scalars['String']>;
  receiptUrl?: Maybe<Scalars['String']>;
  refunds: StripeChargeRefunds;
  status: Scalars['String'];
};

export type StripeRedeemSubscriptionPromoCodeResponse = {
  __typename?: 'StripeRedeemSubscriptionPromoCodeResponse';
  success: Scalars['Boolean'];
};

export type StripeRefund = {
  __typename?: 'StripeRefund';
  amount: Scalars['Int'];
  balance_transaction?: Maybe<Scalars['String']>;
  charge?: Maybe<Scalars['String']>;
  created: Scalars['Int'];
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  metadata?: Maybe<StripeRefundMetadata>;
  object?: Maybe<Scalars['String']>;
  payment_intent?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  receipt_number?: Maybe<Scalars['String']>;
  source_transfer_reversal?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  transfer_reversal?: Maybe<Scalars['String']>;
};

export type StripeRefundMetadata = {
  __typename?: 'StripeRefundMetadata';
  note?: Maybe<Scalars['String']>;
};

export type StripeRefundReason =
  | 'duplicate'
  | 'fraudulent'
  | 'requested_by_customer';

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  cancel_at_period_end?: Maybe<Scalars['Boolean']>;
  current_period_end?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['Int']>;
  status?: Maybe<StripeSubscriptionStatus>;
  subscribee?: Maybe<Scalars['Boolean']>;
  subscriberName?: Maybe<Scalars['String']>;
};

export type StripeSubscriptionStatus =
  | 'active'
  | 'canceled'
  | 'incomplete'
  | 'incomplete_expired'
  | 'past_due'
  | 'trialing'
  | 'unpaid';

export type SymptomCheckerCreatePayload = {
  __typename?: 'SymptomCheckerCreatePayload';
  id: Scalars['String'];
};

export type TemporaryVerification = {
  __typename?: 'TemporaryVerification';
  code: Scalars['String'];
};

export type TheoreticalAvailabilityOutput = {
  __typename?: 'TheoreticalAvailabilityOutput';
  end: Scalars['String'];
  start: Scalars['String'];
};

export type TimeOutput = {
  __typename?: 'TimeOutput';
  time: Scalars['String'];
};

export type TrackEventInput = {
  data?: InputMaybe<Scalars['jsonb']>;
  type: Scalars['String'];
};

export type TrackEventOutput = {
  __typename?: 'TrackEventOutput';
  success: Scalars['Boolean'];
};

export type UpdateAccountPhoneOutput = {
  __typename?: 'UpdateAccountPhoneOutput';
  success: Scalars['Boolean'];
};

export type UpdateAppointmentStatusOutput = {
  __typename?: 'UpdateAppointmentStatusOutput';
  appointment?: Maybe<Appointments>;
  id: Scalars['String'];
};

export type UpdateAppointmentTimeOutput = {
  __typename?: 'UpdateAppointmentTimeOutput';
  id: Scalars['String'];
};

export type UpdateKareoPatientOutput = {
  __typename?: 'UpdateKareoPatientOutput';
  success: Scalars['Boolean'];
};

export type UpdateSymptomCheckerSessionPayload = {
  __typename?: 'UpdateSymptomCheckerSessionPayload';
  id: Scalars['String'];
};

export type UploadKareoDocumentsOutput = {
  __typename?: 'UploadKareoDocumentsOutput';
  success: Scalars['Boolean'];
};

export type UserMetaDataInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
};

export type UserPayload = {
  __typename?: 'UserPayload';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
};

export type VerifyStaffMemberOutput = {
  __typename?: 'VerifyStaffMemberOutput';
  success: Scalars['Boolean'];
};

export type WiwUserSyncOutput = {
  __typename?: 'WiwUserSyncOutput';
  success: Scalars['Boolean'];
};

/** columns and relationships of "account_patient" */
export type Account_Patient = {
  __typename?: 'account_patient';
  /** An object relationship */
  account: Accounts;
  accountId: Scalars['uuid'];
  /** An object relationship */
  account_limited?: Maybe<Accounts_Limited>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  parentRelationship?: Maybe<Scalars['String']>;
  parentType?: Maybe<Scalars['String']>;
  /** An object relationship */
  patient: Patients;
  patientId: Scalars['uuid'];
  relationship?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "account_patient" */
export type Account_Patient_Aggregate = {
  __typename?: 'account_patient_aggregate';
  aggregate?: Maybe<Account_Patient_Aggregate_Fields>;
  nodes: Array<Account_Patient>;
};

export type Account_Patient_Aggregate_Bool_Exp = {
  count?: InputMaybe<Account_Patient_Aggregate_Bool_Exp_Count>;
};

export type Account_Patient_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Account_Patient_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Account_Patient_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "account_patient" */
export type Account_Patient_Aggregate_Fields = {
  __typename?: 'account_patient_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Account_Patient_Max_Fields>;
  min?: Maybe<Account_Patient_Min_Fields>;
};

/** aggregate fields of "account_patient" */
export type Account_Patient_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Account_Patient_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account_patient" */
export type Account_Patient_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Account_Patient_Max_Order_By>;
  min?: InputMaybe<Account_Patient_Min_Order_By>;
};

/** input type for inserting array relation for remote table "account_patient" */
export type Account_Patient_Arr_Rel_Insert_Input = {
  data: Array<Account_Patient_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Account_Patient_On_Conflict>;
};

/** Boolean expression to filter rows from the table "account_patient". All fields are combined with a logical 'AND'. */
export type Account_Patient_Bool_Exp = {
  _and?: InputMaybe<Array<Account_Patient_Bool_Exp>>;
  _not?: InputMaybe<Account_Patient_Bool_Exp>;
  _or?: InputMaybe<Array<Account_Patient_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  account_limited?: InputMaybe<Accounts_Limited_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  parentRelationship?: InputMaybe<String_Comparison_Exp>;
  parentType?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  relationship?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_patient" */
export type Account_Patient_Constraint =
  /** unique or primary key constraint on columns "accountId", "patientId" */
  | 'account_patient_accountId_patientId_key'
  /** unique or primary key constraint on columns "id" */
  | 'account_patient_pkey';

/** input type for inserting data into table "account_patient" */
export type Account_Patient_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['uuid']>;
  account_limited?: InputMaybe<Accounts_Limited_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  parentRelationship?: InputMaybe<Scalars['String']>;
  parentType?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  relationship?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Account_Patient_Max_Fields = {
  __typename?: 'account_patient_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  parentRelationship?: Maybe<Scalars['String']>;
  parentType?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  relationship?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "account_patient" */
export type Account_Patient_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parentRelationship?: InputMaybe<Order_By>;
  parentType?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  relationship?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Account_Patient_Min_Fields = {
  __typename?: 'account_patient_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  parentRelationship?: Maybe<Scalars['String']>;
  parentType?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  relationship?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "account_patient" */
export type Account_Patient_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parentRelationship?: InputMaybe<Order_By>;
  parentType?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  relationship?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "account_patient" */
export type Account_Patient_Mutation_Response = {
  __typename?: 'account_patient_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Patient>;
};

/** input type for inserting object relation for remote table "account_patient" */
export type Account_Patient_Obj_Rel_Insert_Input = {
  data: Account_Patient_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Account_Patient_On_Conflict>;
};

/** on_conflict condition type for table "account_patient" */
export type Account_Patient_On_Conflict = {
  constraint: Account_Patient_Constraint;
  update_columns?: Array<Account_Patient_Update_Column>;
  where?: InputMaybe<Account_Patient_Bool_Exp>;
};

/** Ordering options when selecting data from "account_patient". */
export type Account_Patient_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  account_limited?: InputMaybe<Accounts_Limited_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parentRelationship?: InputMaybe<Order_By>;
  parentType?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  relationship?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: account_patient */
export type Account_Patient_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "account_patient" */
export type Account_Patient_Select_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'parentRelationship'
  /** column name */
  | 'parentType'
  /** column name */
  | 'patientId'
  /** column name */
  | 'relationship'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "account_patient" */
export type Account_Patient_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  parentRelationship?: InputMaybe<Scalars['String']>;
  parentType?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  relationship?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "account_patient" */
export type Account_Patient_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Patient_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_Patient_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  parentRelationship?: InputMaybe<Scalars['String']>;
  parentType?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  relationship?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "account_patient" */
export type Account_Patient_Update_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'parentRelationship'
  /** column name */
  | 'parentType'
  /** column name */
  | 'patientId'
  /** column name */
  | 'relationship'
  /** column name */
  | 'updatedAt';

export type Account_Patient_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Account_Patient_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_Patient_Bool_Exp;
};

/** Temporary verification codes generated by staff members for caregivers without a phone */
export type Account_Temporary_Verification = {
  __typename?: 'account_temporary_verification';
  /** An object relationship */
  account: Accounts;
  accountId: Scalars['uuid'];
  code: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdByStaffId: Scalars['uuid'];
  expiresAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  staff: Staff;
  updatedAt: Scalars['timestamptz'];
  used: Scalars['Boolean'];
};

/** aggregated selection of "account_temporary_verification" */
export type Account_Temporary_Verification_Aggregate = {
  __typename?: 'account_temporary_verification_aggregate';
  aggregate?: Maybe<Account_Temporary_Verification_Aggregate_Fields>;
  nodes: Array<Account_Temporary_Verification>;
};

/** aggregate fields of "account_temporary_verification" */
export type Account_Temporary_Verification_Aggregate_Fields = {
  __typename?: 'account_temporary_verification_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Account_Temporary_Verification_Max_Fields>;
  min?: Maybe<Account_Temporary_Verification_Min_Fields>;
};

/** aggregate fields of "account_temporary_verification" */
export type Account_Temporary_Verification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Account_Temporary_Verification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "account_temporary_verification". All fields are combined with a logical 'AND'. */
export type Account_Temporary_Verification_Bool_Exp = {
  _and?: InputMaybe<Array<Account_Temporary_Verification_Bool_Exp>>;
  _not?: InputMaybe<Account_Temporary_Verification_Bool_Exp>;
  _or?: InputMaybe<Array<Account_Temporary_Verification_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdByStaffId?: InputMaybe<Uuid_Comparison_Exp>;
  expiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  used?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_temporary_verification" */
export type Account_Temporary_Verification_Constraint =
  /** unique or primary key constraint on columns "id" */
  'account_temporary_verification_pkey';

/** input type for inserting data into table "account_temporary_verification" */
export type Account_Temporary_Verification_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['uuid']>;
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByStaffId?: InputMaybe<Scalars['uuid']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  used?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Account_Temporary_Verification_Max_Fields = {
  __typename?: 'account_temporary_verification_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByStaffId?: Maybe<Scalars['uuid']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Account_Temporary_Verification_Min_Fields = {
  __typename?: 'account_temporary_verification_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByStaffId?: Maybe<Scalars['uuid']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "account_temporary_verification" */
export type Account_Temporary_Verification_Mutation_Response = {
  __typename?: 'account_temporary_verification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Temporary_Verification>;
};

/** on_conflict condition type for table "account_temporary_verification" */
export type Account_Temporary_Verification_On_Conflict = {
  constraint: Account_Temporary_Verification_Constraint;
  update_columns?: Array<Account_Temporary_Verification_Update_Column>;
  where?: InputMaybe<Account_Temporary_Verification_Bool_Exp>;
};

/** Ordering options when selecting data from "account_temporary_verification". */
export type Account_Temporary_Verification_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdByStaffId?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  used?: InputMaybe<Order_By>;
};

/** primary key columns input for table: account_temporary_verification */
export type Account_Temporary_Verification_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "account_temporary_verification" */
export type Account_Temporary_Verification_Select_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'code'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdByStaffId'
  /** column name */
  | 'expiresAt'
  /** column name */
  | 'id'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'used';

/** input type for updating data in table "account_temporary_verification" */
export type Account_Temporary_Verification_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByStaffId?: InputMaybe<Scalars['uuid']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  used?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "account_temporary_verification" */
export type Account_Temporary_Verification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Temporary_Verification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_Temporary_Verification_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByStaffId?: InputMaybe<Scalars['uuid']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  used?: InputMaybe<Scalars['Boolean']>;
};

/** update columns of table "account_temporary_verification" */
export type Account_Temporary_Verification_Update_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'code'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdByStaffId'
  /** column name */
  | 'expiresAt'
  /** column name */
  | 'id'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'used';

export type Account_Temporary_Verification_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Account_Temporary_Verification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_Temporary_Verification_Bool_Exp;
};

/** columns and relationships of "accounts" */
export type Accounts = {
  __typename?: 'accounts';
  /** An object relationship */
  account?: Maybe<Accounts>;
  /** An array relationship */
  account_patients: Array<Account_Patient>;
  /** An aggregate relationship */
  account_patients_aggregate: Account_Patient_Aggregate;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Accounts_Aggregate;
  /** An array relationship */
  appointments: Array<Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Appointments_Aggregate;
  /** An array relationship */
  audit_logs: Array<Audit_Logs>;
  /** An aggregate relationship */
  audit_logs_aggregate: Audit_Logs_Aggregate;
  avatar?: Maybe<FilePayload>;
  avatarId?: Maybe<Scalars['String']>;
  /** An array relationship */
  charges: Array<Charges>;
  /** An aggregate relationship */
  charges_aggregate: Charges_Aggregate;
  /** An array relationship */
  chat_messages: Array<Chat_Messages>;
  /** An aggregate relationship */
  chat_messages_aggregate: Chat_Messages_Aggregate;
  /** An array relationship */
  chat_sessions: Array<Chat_Sessions>;
  /** An array relationship */
  chat_sessions_accounts: Array<Chat_Sessions_Accounts>;
  /** An aggregate relationship */
  chat_sessions_accounts_aggregate: Chat_Sessions_Accounts_Aggregate;
  /** An aggregate relationship */
  chat_sessions_aggregate: Chat_Sessions_Aggregate;
  /** An array relationship */
  chat_sessions_reads: Array<Chat_Sessions_Read>;
  /** An aggregate relationship */
  chat_sessions_reads_aggregate: Chat_Sessions_Read_Aggregate;
  /** An array relationship */
  communication_histories: Array<Communication_History>;
  /** An aggregate relationship */
  communication_histories_aggregate: Communication_History_Aggregate;
  consentToChatTermsTimestamp?: Maybe<Scalars['timestamp']>;
  consentToMedicalTermsTimestamp?: Maybe<Scalars['timestamptz']>;
  consentToNoticeOfPrivacyPracticesTimestamp?: Maybe<Scalars['timestamptz']>;
  consentToTermsAndPrivacyTimestamp?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  createdByAccountId?: Maybe<Scalars['uuid']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  discovered_froms: Array<Discovered_From>;
  /** An aggregate relationship */
  discovered_froms_aggregate: Discovered_From_Aggregate;
  /** An array relationship */
  documents: Array<Documents>;
  /** An aggregate relationship */
  documents_aggregate: Documents_Aggregate;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  /** An array relationship */
  global_notes: Array<Global_Notes>;
  /** An aggregate relationship */
  global_notes_aggregate: Global_Notes_Aggregate;
  goesByName?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  insurances: Array<Insurance>;
  /** An aggregate relationship */
  insurances_aggregate: Insurance_Aggregate;
  lastName?: Maybe<Scalars['String']>;
  migratedFrom?: Maybe<Scalars['String']>;
  /** An array relationship */
  patients: Array<Patients>;
  /** An aggregate relationship */
  patients_aggregate: Patients_Aggregate;
  phoneNumber: Scalars['String'];
  photoIdBack?: Maybe<FilePayload>;
  photoIdBackId?: Maybe<Scalars['String']>;
  photoIdFront?: Maybe<FilePayload>;
  photoIdFrontId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  /** An object relationship */
  preferredClinic?: Maybe<Clinics>;
  preferredClinicId?: Maybe<Scalars['uuid']>;
  preferredLanguage?: Maybe<Scalars['String']>;
  preferredPronoun?: Maybe<Scalars['String']>;
  preferredPronounType?: Maybe<Scalars['String']>;
  primaryAuthProviderId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  /** An array relationship */
  tasks_notifications: Array<Tasks_Notifications>;
  /** An aggregate relationship */
  tasks_notifications_aggregate: Tasks_Notifications_Aggregate;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  userByCreatedByUserId?: Maybe<Users>;
  userId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "accounts" */
export type AccountsAccount_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Account_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Patient_Order_By>>;
  where?: InputMaybe<Account_Patient_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsAccount_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Patient_Order_By>>;
  where?: InputMaybe<Account_Patient_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsAudit_LogsArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Logs_Order_By>>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsAudit_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Logs_Order_By>>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsChargesArgs = {
  distinct_on?: InputMaybe<Array<Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charges_Order_By>>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsCharges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charges_Order_By>>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_Order_By>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_Order_By>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsChat_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsChat_Sessions_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Accounts_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Accounts_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsChat_Sessions_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Accounts_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Accounts_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsChat_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsChat_Sessions_ReadsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Read_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Read_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsChat_Sessions_Reads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Read_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Read_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsCommunication_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Order_By>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsCommunication_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Order_By>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsDiscovered_FromsArgs = {
  distinct_on?: InputMaybe<Array<Discovered_From_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Discovered_From_Order_By>>;
  where?: InputMaybe<Discovered_From_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsDiscovered_Froms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Discovered_From_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Discovered_From_Order_By>>;
  where?: InputMaybe<Discovered_From_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsGlobal_NotesArgs = {
  distinct_on?: InputMaybe<Array<Global_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Notes_Order_By>>;
  where?: InputMaybe<Global_Notes_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsGlobal_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Notes_Order_By>>;
  where?: InputMaybe<Global_Notes_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsInsurancesArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Order_By>>;
  where?: InputMaybe<Insurance_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsInsurances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Order_By>>;
  where?: InputMaybe<Insurance_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsPatients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsTasks_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsTasks_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

/** aggregated selection of "accounts" */
export type Accounts_Aggregate = {
  __typename?: 'accounts_aggregate';
  aggregate?: Maybe<Accounts_Aggregate_Fields>;
  nodes: Array<Accounts>;
};

export type Accounts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Accounts_Aggregate_Bool_Exp_Count>;
};

export type Accounts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Accounts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "accounts" */
export type Accounts_Aggregate_Fields = {
  __typename?: 'accounts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Accounts_Max_Fields>;
  min?: Maybe<Accounts_Min_Fields>;
};

/** aggregate fields of "accounts" */
export type Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "accounts" */
export type Accounts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Accounts_Max_Order_By>;
  min?: InputMaybe<Accounts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "accounts" */
export type Accounts_Arr_Rel_Insert_Input = {
  data: Array<Accounts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "accounts". All fields are combined with a logical 'AND'. */
export type Accounts_Bool_Exp = {
  _and?: InputMaybe<Array<Accounts_Bool_Exp>>;
  _not?: InputMaybe<Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<Accounts_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  account_patients?: InputMaybe<Account_Patient_Bool_Exp>;
  account_patients_aggregate?: InputMaybe<Account_Patient_Aggregate_Bool_Exp>;
  accounts?: InputMaybe<Accounts_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Bool_Exp>;
  appointments?: InputMaybe<Appointments_Bool_Exp>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Bool_Exp>;
  audit_logs?: InputMaybe<Audit_Logs_Bool_Exp>;
  audit_logs_aggregate?: InputMaybe<Audit_Logs_Aggregate_Bool_Exp>;
  avatarId?: InputMaybe<String_Comparison_Exp>;
  charges?: InputMaybe<Charges_Bool_Exp>;
  charges_aggregate?: InputMaybe<Charges_Aggregate_Bool_Exp>;
  chat_messages?: InputMaybe<Chat_Messages_Bool_Exp>;
  chat_messages_aggregate?: InputMaybe<Chat_Messages_Aggregate_Bool_Exp>;
  chat_sessions?: InputMaybe<Chat_Sessions_Bool_Exp>;
  chat_sessions_accounts?: InputMaybe<Chat_Sessions_Accounts_Bool_Exp>;
  chat_sessions_accounts_aggregate?: InputMaybe<Chat_Sessions_Accounts_Aggregate_Bool_Exp>;
  chat_sessions_aggregate?: InputMaybe<Chat_Sessions_Aggregate_Bool_Exp>;
  chat_sessions_reads?: InputMaybe<Chat_Sessions_Read_Bool_Exp>;
  chat_sessions_reads_aggregate?: InputMaybe<Chat_Sessions_Read_Aggregate_Bool_Exp>;
  communication_histories?: InputMaybe<Communication_History_Bool_Exp>;
  communication_histories_aggregate?: InputMaybe<Communication_History_Aggregate_Bool_Exp>;
  consentToChatTermsTimestamp?: InputMaybe<Timestamp_Comparison_Exp>;
  consentToMedicalTermsTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  consentToNoticeOfPrivacyPracticesTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  consentToTermsAndPrivacyTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdByAccountId?: InputMaybe<Uuid_Comparison_Exp>;
  createdByUserId?: InputMaybe<Uuid_Comparison_Exp>;
  discovered_froms?: InputMaybe<Discovered_From_Bool_Exp>;
  discovered_froms_aggregate?: InputMaybe<Discovered_From_Aggregate_Bool_Exp>;
  documents?: InputMaybe<Documents_Bool_Exp>;
  documents_aggregate?: InputMaybe<Documents_Aggregate_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  global_notes?: InputMaybe<Global_Notes_Bool_Exp>;
  global_notes_aggregate?: InputMaybe<Global_Notes_Aggregate_Bool_Exp>;
  goesByName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  insurances?: InputMaybe<Insurance_Bool_Exp>;
  insurances_aggregate?: InputMaybe<Insurance_Aggregate_Bool_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  migratedFrom?: InputMaybe<String_Comparison_Exp>;
  patients?: InputMaybe<Patients_Bool_Exp>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Bool_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  photoIdBackId?: InputMaybe<String_Comparison_Exp>;
  photoIdFrontId?: InputMaybe<String_Comparison_Exp>;
  postalCode?: InputMaybe<String_Comparison_Exp>;
  preferredClinic?: InputMaybe<Clinics_Bool_Exp>;
  preferredClinicId?: InputMaybe<Uuid_Comparison_Exp>;
  preferredLanguage?: InputMaybe<String_Comparison_Exp>;
  preferredPronoun?: InputMaybe<String_Comparison_Exp>;
  preferredPronounType?: InputMaybe<String_Comparison_Exp>;
  primaryAuthProviderId?: InputMaybe<String_Comparison_Exp>;
  stripeCustomerId?: InputMaybe<String_Comparison_Exp>;
  tasks_notifications?: InputMaybe<Tasks_Notifications_Bool_Exp>;
  tasks_notifications_aggregate?: InputMaybe<Tasks_Notifications_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByCreatedByUserId?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "accounts" */
export type Accounts_Constraint =
  /** unique or primary key constraint on columns "phoneNumber" */
  | 'accounts_phoneNumber_key'
  /** unique or primary key constraint on columns "id" */
  | 'accounts_pkey'
  /** unique or primary key constraint on columns "primaryAuthProviderId" */
  | 'accounts_primaryAuthProviderId_key'
  /** unique or primary key constraint on columns "userId" */
  | 'accounts_userId_key';

/** input type for inserting data into table "accounts" */
export type Accounts_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  account_patients?: InputMaybe<Account_Patient_Arr_Rel_Insert_Input>;
  accounts?: InputMaybe<Accounts_Arr_Rel_Insert_Input>;
  appointments?: InputMaybe<Appointments_Arr_Rel_Insert_Input>;
  audit_logs?: InputMaybe<Audit_Logs_Arr_Rel_Insert_Input>;
  avatarId?: InputMaybe<Scalars['String']>;
  charges?: InputMaybe<Charges_Arr_Rel_Insert_Input>;
  chat_messages?: InputMaybe<Chat_Messages_Arr_Rel_Insert_Input>;
  chat_sessions?: InputMaybe<Chat_Sessions_Arr_Rel_Insert_Input>;
  chat_sessions_accounts?: InputMaybe<Chat_Sessions_Accounts_Arr_Rel_Insert_Input>;
  chat_sessions_reads?: InputMaybe<Chat_Sessions_Read_Arr_Rel_Insert_Input>;
  communication_histories?: InputMaybe<Communication_History_Arr_Rel_Insert_Input>;
  consentToChatTermsTimestamp?: InputMaybe<Scalars['timestamp']>;
  consentToMedicalTermsTimestamp?: InputMaybe<Scalars['timestamptz']>;
  consentToNoticeOfPrivacyPracticesTimestamp?: InputMaybe<
    Scalars['timestamptz']
  >;
  consentToTermsAndPrivacyTimestamp?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByAccountId?: InputMaybe<Scalars['uuid']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  discovered_froms?: InputMaybe<Discovered_From_Arr_Rel_Insert_Input>;
  documents?: InputMaybe<Documents_Arr_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  global_notes?: InputMaybe<Global_Notes_Arr_Rel_Insert_Input>;
  goesByName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insurances?: InputMaybe<Insurance_Arr_Rel_Insert_Input>;
  lastName?: InputMaybe<Scalars['String']>;
  migratedFrom?: InputMaybe<Scalars['String']>;
  patients?: InputMaybe<Patients_Arr_Rel_Insert_Input>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  photoIdBackId?: InputMaybe<Scalars['String']>;
  photoIdFrontId?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  preferredClinic?: InputMaybe<Clinics_Obj_Rel_Insert_Input>;
  preferredClinicId?: InputMaybe<Scalars['uuid']>;
  preferredLanguage?: InputMaybe<Scalars['String']>;
  preferredPronoun?: InputMaybe<Scalars['String']>;
  preferredPronounType?: InputMaybe<Scalars['String']>;
  primaryAuthProviderId?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  tasks_notifications?: InputMaybe<Tasks_Notifications_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByCreatedByUserId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "accounts_limited" */
export type Accounts_Limited = {
  __typename?: 'accounts_limited';
  /** An object relationship */
  accounts?: Maybe<Account_Patient>;
  avatar?: Maybe<FilePayload>;
  avatarId?: Maybe<Scalars['String']>;
  consentToMedicalTermsTimestamp?: Maybe<Scalars['timestamptz']>;
  consentToTermsAndPrivacyTimestamp?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  goesByName?: Maybe<Scalars['String']>;
  hasEmail?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

/** aggregated selection of "accounts_limited" */
export type Accounts_Limited_Aggregate = {
  __typename?: 'accounts_limited_aggregate';
  aggregate?: Maybe<Accounts_Limited_Aggregate_Fields>;
  nodes: Array<Accounts_Limited>;
};

/** aggregate fields of "accounts_limited" */
export type Accounts_Limited_Aggregate_Fields = {
  __typename?: 'accounts_limited_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Accounts_Limited_Max_Fields>;
  min?: Maybe<Accounts_Limited_Min_Fields>;
};

/** aggregate fields of "accounts_limited" */
export type Accounts_Limited_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Accounts_Limited_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "accounts_limited". All fields are combined with a logical 'AND'. */
export type Accounts_Limited_Bool_Exp = {
  _and?: InputMaybe<Array<Accounts_Limited_Bool_Exp>>;
  _not?: InputMaybe<Accounts_Limited_Bool_Exp>;
  _or?: InputMaybe<Array<Accounts_Limited_Bool_Exp>>;
  accounts?: InputMaybe<Account_Patient_Bool_Exp>;
  avatarId?: InputMaybe<String_Comparison_Exp>;
  consentToMedicalTermsTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  consentToTermsAndPrivacyTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  goesByName?: InputMaybe<String_Comparison_Exp>;
  hasEmail?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "accounts_limited" */
export type Accounts_Limited_Insert_Input = {
  accounts?: InputMaybe<Account_Patient_Obj_Rel_Insert_Input>;
  avatarId?: InputMaybe<Scalars['String']>;
  consentToMedicalTermsTimestamp?: InputMaybe<Scalars['timestamptz']>;
  consentToTermsAndPrivacyTimestamp?: InputMaybe<Scalars['timestamptz']>;
  firstName?: InputMaybe<Scalars['String']>;
  goesByName?: InputMaybe<Scalars['String']>;
  hasEmail?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Accounts_Limited_Max_Fields = {
  __typename?: 'accounts_limited_max_fields';
  avatarId?: Maybe<Scalars['String']>;
  consentToMedicalTermsTimestamp?: Maybe<Scalars['timestamptz']>;
  consentToTermsAndPrivacyTimestamp?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  goesByName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Accounts_Limited_Min_Fields = {
  __typename?: 'accounts_limited_min_fields';
  avatarId?: Maybe<Scalars['String']>;
  consentToMedicalTermsTimestamp?: Maybe<Scalars['timestamptz']>;
  consentToTermsAndPrivacyTimestamp?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  goesByName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "accounts_limited" */
export type Accounts_Limited_Mutation_Response = {
  __typename?: 'accounts_limited_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounts_Limited>;
};

/** input type for inserting object relation for remote table "accounts_limited" */
export type Accounts_Limited_Obj_Rel_Insert_Input = {
  data: Accounts_Limited_Insert_Input;
};

/** Ordering options when selecting data from "accounts_limited". */
export type Accounts_Limited_Order_By = {
  accounts?: InputMaybe<Account_Patient_Order_By>;
  avatarId?: InputMaybe<Order_By>;
  consentToMedicalTermsTimestamp?: InputMaybe<Order_By>;
  consentToTermsAndPrivacyTimestamp?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  goesByName?: InputMaybe<Order_By>;
  hasEmail?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
};

/** select columns of table "accounts_limited" */
export type Accounts_Limited_Select_Column =
  /** column name */
  | 'avatarId'
  /** column name */
  | 'consentToMedicalTermsTimestamp'
  /** column name */
  | 'consentToTermsAndPrivacyTimestamp'
  /** column name */
  | 'firstName'
  /** column name */
  | 'goesByName'
  /** column name */
  | 'hasEmail'
  /** column name */
  | 'id'
  /** column name */
  | 'lastName'
  /** column name */
  | 'phoneNumber';

/** input type for updating data in table "accounts_limited" */
export type Accounts_Limited_Set_Input = {
  avatarId?: InputMaybe<Scalars['String']>;
  consentToMedicalTermsTimestamp?: InputMaybe<Scalars['timestamptz']>;
  consentToTermsAndPrivacyTimestamp?: InputMaybe<Scalars['timestamptz']>;
  firstName?: InputMaybe<Scalars['String']>;
  goesByName?: InputMaybe<Scalars['String']>;
  hasEmail?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "accounts_limited" */
export type Accounts_Limited_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Accounts_Limited_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Accounts_Limited_Stream_Cursor_Value_Input = {
  avatarId?: InputMaybe<Scalars['String']>;
  consentToMedicalTermsTimestamp?: InputMaybe<Scalars['timestamptz']>;
  consentToTermsAndPrivacyTimestamp?: InputMaybe<Scalars['timestamptz']>;
  firstName?: InputMaybe<Scalars['String']>;
  goesByName?: InputMaybe<Scalars['String']>;
  hasEmail?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type Accounts_Limited_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Accounts_Limited_Set_Input>;
  /** filter the rows which have to be updated */
  where: Accounts_Limited_Bool_Exp;
};

/** aggregate max on columns */
export type Accounts_Max_Fields = {
  __typename?: 'accounts_max_fields';
  avatarId?: Maybe<Scalars['String']>;
  consentToChatTermsTimestamp?: Maybe<Scalars['timestamp']>;
  consentToMedicalTermsTimestamp?: Maybe<Scalars['timestamptz']>;
  consentToNoticeOfPrivacyPracticesTimestamp?: Maybe<Scalars['timestamptz']>;
  consentToTermsAndPrivacyTimestamp?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByAccountId?: Maybe<Scalars['uuid']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  goesByName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  migratedFrom?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photoIdBackId?: Maybe<Scalars['String']>;
  photoIdFrontId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  preferredClinicId?: Maybe<Scalars['uuid']>;
  preferredLanguage?: Maybe<Scalars['String']>;
  preferredPronoun?: Maybe<Scalars['String']>;
  preferredPronounType?: Maybe<Scalars['String']>;
  primaryAuthProviderId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "accounts" */
export type Accounts_Max_Order_By = {
  avatarId?: InputMaybe<Order_By>;
  consentToChatTermsTimestamp?: InputMaybe<Order_By>;
  consentToMedicalTermsTimestamp?: InputMaybe<Order_By>;
  consentToNoticeOfPrivacyPracticesTimestamp?: InputMaybe<Order_By>;
  consentToTermsAndPrivacyTimestamp?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdByAccountId?: InputMaybe<Order_By>;
  createdByUserId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  goesByName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  migratedFrom?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  photoIdBackId?: InputMaybe<Order_By>;
  photoIdFrontId?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
  preferredClinicId?: InputMaybe<Order_By>;
  preferredLanguage?: InputMaybe<Order_By>;
  preferredPronoun?: InputMaybe<Order_By>;
  preferredPronounType?: InputMaybe<Order_By>;
  primaryAuthProviderId?: InputMaybe<Order_By>;
  stripeCustomerId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Accounts_Min_Fields = {
  __typename?: 'accounts_min_fields';
  avatarId?: Maybe<Scalars['String']>;
  consentToChatTermsTimestamp?: Maybe<Scalars['timestamp']>;
  consentToMedicalTermsTimestamp?: Maybe<Scalars['timestamptz']>;
  consentToNoticeOfPrivacyPracticesTimestamp?: Maybe<Scalars['timestamptz']>;
  consentToTermsAndPrivacyTimestamp?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByAccountId?: Maybe<Scalars['uuid']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  goesByName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  migratedFrom?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photoIdBackId?: Maybe<Scalars['String']>;
  photoIdFrontId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  preferredClinicId?: Maybe<Scalars['uuid']>;
  preferredLanguage?: Maybe<Scalars['String']>;
  preferredPronoun?: Maybe<Scalars['String']>;
  preferredPronounType?: Maybe<Scalars['String']>;
  primaryAuthProviderId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "accounts" */
export type Accounts_Min_Order_By = {
  avatarId?: InputMaybe<Order_By>;
  consentToChatTermsTimestamp?: InputMaybe<Order_By>;
  consentToMedicalTermsTimestamp?: InputMaybe<Order_By>;
  consentToNoticeOfPrivacyPracticesTimestamp?: InputMaybe<Order_By>;
  consentToTermsAndPrivacyTimestamp?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdByAccountId?: InputMaybe<Order_By>;
  createdByUserId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  goesByName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  migratedFrom?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  photoIdBackId?: InputMaybe<Order_By>;
  photoIdFrontId?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
  preferredClinicId?: InputMaybe<Order_By>;
  preferredLanguage?: InputMaybe<Order_By>;
  preferredPronoun?: InputMaybe<Order_By>;
  preferredPronounType?: InputMaybe<Order_By>;
  primaryAuthProviderId?: InputMaybe<Order_By>;
  stripeCustomerId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "accounts" */
export type Accounts_Mutation_Response = {
  __typename?: 'accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounts>;
};

/** input type for inserting object relation for remote table "accounts" */
export type Accounts_Obj_Rel_Insert_Input = {
  data: Accounts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};

/** on_conflict condition type for table "accounts" */
export type Accounts_On_Conflict = {
  constraint: Accounts_Constraint;
  update_columns?: Array<Accounts_Update_Column>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "accounts". */
export type Accounts_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  account_patients_aggregate?: InputMaybe<Account_Patient_Aggregate_Order_By>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Order_By>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Order_By>;
  audit_logs_aggregate?: InputMaybe<Audit_Logs_Aggregate_Order_By>;
  avatarId?: InputMaybe<Order_By>;
  charges_aggregate?: InputMaybe<Charges_Aggregate_Order_By>;
  chat_messages_aggregate?: InputMaybe<Chat_Messages_Aggregate_Order_By>;
  chat_sessions_accounts_aggregate?: InputMaybe<Chat_Sessions_Accounts_Aggregate_Order_By>;
  chat_sessions_aggregate?: InputMaybe<Chat_Sessions_Aggregate_Order_By>;
  chat_sessions_reads_aggregate?: InputMaybe<Chat_Sessions_Read_Aggregate_Order_By>;
  communication_histories_aggregate?: InputMaybe<Communication_History_Aggregate_Order_By>;
  consentToChatTermsTimestamp?: InputMaybe<Order_By>;
  consentToMedicalTermsTimestamp?: InputMaybe<Order_By>;
  consentToNoticeOfPrivacyPracticesTimestamp?: InputMaybe<Order_By>;
  consentToTermsAndPrivacyTimestamp?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdByAccountId?: InputMaybe<Order_By>;
  createdByUserId?: InputMaybe<Order_By>;
  discovered_froms_aggregate?: InputMaybe<Discovered_From_Aggregate_Order_By>;
  documents_aggregate?: InputMaybe<Documents_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  global_notes_aggregate?: InputMaybe<Global_Notes_Aggregate_Order_By>;
  goesByName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurances_aggregate?: InputMaybe<Insurance_Aggregate_Order_By>;
  lastName?: InputMaybe<Order_By>;
  migratedFrom?: InputMaybe<Order_By>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  photoIdBackId?: InputMaybe<Order_By>;
  photoIdFrontId?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
  preferredClinic?: InputMaybe<Clinics_Order_By>;
  preferredClinicId?: InputMaybe<Order_By>;
  preferredLanguage?: InputMaybe<Order_By>;
  preferredPronoun?: InputMaybe<Order_By>;
  preferredPronounType?: InputMaybe<Order_By>;
  primaryAuthProviderId?: InputMaybe<Order_By>;
  stripeCustomerId?: InputMaybe<Order_By>;
  tasks_notifications_aggregate?: InputMaybe<Tasks_Notifications_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByCreatedByUserId?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: accounts */
export type Accounts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "accounts" */
export type Accounts_Select_Column =
  /** column name */
  | 'avatarId'
  /** column name */
  | 'consentToChatTermsTimestamp'
  /** column name */
  | 'consentToMedicalTermsTimestamp'
  /** column name */
  | 'consentToNoticeOfPrivacyPracticesTimestamp'
  /** column name */
  | 'consentToTermsAndPrivacyTimestamp'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdByAccountId'
  /** column name */
  | 'createdByUserId'
  /** column name */
  | 'email'
  /** column name */
  | 'firstName'
  /** column name */
  | 'goesByName'
  /** column name */
  | 'id'
  /** column name */
  | 'lastName'
  /** column name */
  | 'migratedFrom'
  /** column name */
  | 'phoneNumber'
  /** column name */
  | 'photoIdBackId'
  /** column name */
  | 'photoIdFrontId'
  /** column name */
  | 'postalCode'
  /** column name */
  | 'preferredClinicId'
  /** column name */
  | 'preferredLanguage'
  /** column name */
  | 'preferredPronoun'
  /** column name */
  | 'preferredPronounType'
  /** column name */
  | 'primaryAuthProviderId'
  /** column name */
  | 'stripeCustomerId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'userId';

/** input type for updating data in table "accounts" */
export type Accounts_Set_Input = {
  avatarId?: InputMaybe<Scalars['String']>;
  consentToChatTermsTimestamp?: InputMaybe<Scalars['timestamp']>;
  consentToMedicalTermsTimestamp?: InputMaybe<Scalars['timestamptz']>;
  consentToNoticeOfPrivacyPracticesTimestamp?: InputMaybe<
    Scalars['timestamptz']
  >;
  consentToTermsAndPrivacyTimestamp?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByAccountId?: InputMaybe<Scalars['uuid']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  goesByName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  migratedFrom?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  photoIdBackId?: InputMaybe<Scalars['String']>;
  photoIdFrontId?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  preferredClinicId?: InputMaybe<Scalars['uuid']>;
  preferredLanguage?: InputMaybe<Scalars['String']>;
  preferredPronoun?: InputMaybe<Scalars['String']>;
  preferredPronounType?: InputMaybe<Scalars['String']>;
  primaryAuthProviderId?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "accounts" */
export type Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Accounts_Stream_Cursor_Value_Input = {
  avatarId?: InputMaybe<Scalars['String']>;
  consentToChatTermsTimestamp?: InputMaybe<Scalars['timestamp']>;
  consentToMedicalTermsTimestamp?: InputMaybe<Scalars['timestamptz']>;
  consentToNoticeOfPrivacyPracticesTimestamp?: InputMaybe<
    Scalars['timestamptz']
  >;
  consentToTermsAndPrivacyTimestamp?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByAccountId?: InputMaybe<Scalars['uuid']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  goesByName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  migratedFrom?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  photoIdBackId?: InputMaybe<Scalars['String']>;
  photoIdFrontId?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  preferredClinicId?: InputMaybe<Scalars['uuid']>;
  preferredLanguage?: InputMaybe<Scalars['String']>;
  preferredPronoun?: InputMaybe<Scalars['String']>;
  preferredPronounType?: InputMaybe<Scalars['String']>;
  primaryAuthProviderId?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "accounts" */
export type Accounts_Update_Column =
  /** column name */
  | 'avatarId'
  /** column name */
  | 'consentToChatTermsTimestamp'
  /** column name */
  | 'consentToMedicalTermsTimestamp'
  /** column name */
  | 'consentToNoticeOfPrivacyPracticesTimestamp'
  /** column name */
  | 'consentToTermsAndPrivacyTimestamp'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdByAccountId'
  /** column name */
  | 'createdByUserId'
  /** column name */
  | 'email'
  /** column name */
  | 'firstName'
  /** column name */
  | 'goesByName'
  /** column name */
  | 'id'
  /** column name */
  | 'lastName'
  /** column name */
  | 'migratedFrom'
  /** column name */
  | 'phoneNumber'
  /** column name */
  | 'photoIdBackId'
  /** column name */
  | 'photoIdFrontId'
  /** column name */
  | 'postalCode'
  /** column name */
  | 'preferredClinicId'
  /** column name */
  | 'preferredLanguage'
  /** column name */
  | 'preferredPronoun'
  /** column name */
  | 'preferredPronounType'
  /** column name */
  | 'primaryAuthProviderId'
  /** column name */
  | 'stripeCustomerId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'userId';

export type Accounts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Accounts_Bool_Exp;
};

/** columns and relationships of "allergies" */
export type Allergies = {
  __typename?: 'allergies';
  /** An object relationship */
  allergies_severity?: Maybe<Allergies_Severity>;
  /** An object relationship */
  allergies_status: Allergies_Status;
  code?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  endedAt?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  patient: Patients;
  patientId: Scalars['uuid'];
  reaction?: Maybe<Scalars['jsonb']>;
  severity?: Maybe<Allergies_Severity_Enum>;
  startedAt?: Maybe<Scalars['date']>;
  status: Allergies_Status_Enum;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "allergies" */
export type AllergiesReactionArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "allergies" */
export type Allergies_Aggregate = {
  __typename?: 'allergies_aggregate';
  aggregate?: Maybe<Allergies_Aggregate_Fields>;
  nodes: Array<Allergies>;
};

export type Allergies_Aggregate_Bool_Exp = {
  count?: InputMaybe<Allergies_Aggregate_Bool_Exp_Count>;
};

export type Allergies_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Allergies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Allergies_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "allergies" */
export type Allergies_Aggregate_Fields = {
  __typename?: 'allergies_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Allergies_Max_Fields>;
  min?: Maybe<Allergies_Min_Fields>;
};

/** aggregate fields of "allergies" */
export type Allergies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Allergies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "allergies" */
export type Allergies_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Allergies_Max_Order_By>;
  min?: InputMaybe<Allergies_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Allergies_Append_Input = {
  reaction?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "allergies" */
export type Allergies_Arr_Rel_Insert_Input = {
  data: Array<Allergies_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Allergies_On_Conflict>;
};

/** Boolean expression to filter rows from the table "allergies". All fields are combined with a logical 'AND'. */
export type Allergies_Bool_Exp = {
  _and?: InputMaybe<Array<Allergies_Bool_Exp>>;
  _not?: InputMaybe<Allergies_Bool_Exp>;
  _or?: InputMaybe<Array<Allergies_Bool_Exp>>;
  allergies_severity?: InputMaybe<Allergies_Severity_Bool_Exp>;
  allergies_status?: InputMaybe<Allergies_Status_Bool_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  endedAt?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  reaction?: InputMaybe<Jsonb_Comparison_Exp>;
  severity?: InputMaybe<Allergies_Severity_Enum_Comparison_Exp>;
  startedAt?: InputMaybe<Date_Comparison_Exp>;
  status?: InputMaybe<Allergies_Status_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "allergies" */
export type Allergies_Constraint =
  /** unique or primary key constraint on columns "id" */
  'allergies_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Allergies_Delete_At_Path_Input = {
  reaction?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Allergies_Delete_Elem_Input = {
  reaction?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Allergies_Delete_Key_Input = {
  reaction?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "allergies" */
export type Allergies_Insert_Input = {
  allergies_severity?: InputMaybe<Allergies_Severity_Obj_Rel_Insert_Input>;
  allergies_status?: InputMaybe<Allergies_Status_Obj_Rel_Insert_Input>;
  code?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  endedAt?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  reaction?: InputMaybe<Scalars['jsonb']>;
  severity?: InputMaybe<Allergies_Severity_Enum>;
  startedAt?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Allergies_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Allergies_Max_Fields = {
  __typename?: 'allergies_max_fields';
  code?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  endedAt?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  startedAt?: Maybe<Scalars['date']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "allergies" */
export type Allergies_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  startedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Allergies_Min_Fields = {
  __typename?: 'allergies_min_fields';
  code?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  endedAt?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  startedAt?: Maybe<Scalars['date']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "allergies" */
export type Allergies_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  startedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "allergies" */
export type Allergies_Mutation_Response = {
  __typename?: 'allergies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Allergies>;
};

/** on_conflict condition type for table "allergies" */
export type Allergies_On_Conflict = {
  constraint: Allergies_Constraint;
  update_columns?: Array<Allergies_Update_Column>;
  where?: InputMaybe<Allergies_Bool_Exp>;
};

/** Ordering options when selecting data from "allergies". */
export type Allergies_Order_By = {
  allergies_severity?: InputMaybe<Allergies_Severity_Order_By>;
  allergies_status?: InputMaybe<Allergies_Status_Order_By>;
  code?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  reaction?: InputMaybe<Order_By>;
  severity?: InputMaybe<Order_By>;
  startedAt?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: allergies */
export type Allergies_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Allergies_Prepend_Input = {
  reaction?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "allergies" */
export type Allergies_Select_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'comments'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'endedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'patientId'
  /** column name */
  | 'reaction'
  /** column name */
  | 'severity'
  /** column name */
  | 'startedAt'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "allergies" */
export type Allergies_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  endedAt?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  reaction?: InputMaybe<Scalars['jsonb']>;
  severity?: InputMaybe<Allergies_Severity_Enum>;
  startedAt?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Allergies_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "allergies_severity" */
export type Allergies_Severity = {
  __typename?: 'allergies_severity';
  /** An array relationship */
  allergies: Array<Allergies>;
  /** An aggregate relationship */
  allergies_aggregate: Allergies_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "allergies_severity" */
export type Allergies_SeverityAllergiesArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Order_By>>;
  where?: InputMaybe<Allergies_Bool_Exp>;
};

/** columns and relationships of "allergies_severity" */
export type Allergies_SeverityAllergies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Order_By>>;
  where?: InputMaybe<Allergies_Bool_Exp>;
};

/** aggregated selection of "allergies_severity" */
export type Allergies_Severity_Aggregate = {
  __typename?: 'allergies_severity_aggregate';
  aggregate?: Maybe<Allergies_Severity_Aggregate_Fields>;
  nodes: Array<Allergies_Severity>;
};

/** aggregate fields of "allergies_severity" */
export type Allergies_Severity_Aggregate_Fields = {
  __typename?: 'allergies_severity_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Allergies_Severity_Max_Fields>;
  min?: Maybe<Allergies_Severity_Min_Fields>;
};

/** aggregate fields of "allergies_severity" */
export type Allergies_Severity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Allergies_Severity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "allergies_severity". All fields are combined with a logical 'AND'. */
export type Allergies_Severity_Bool_Exp = {
  _and?: InputMaybe<Array<Allergies_Severity_Bool_Exp>>;
  _not?: InputMaybe<Allergies_Severity_Bool_Exp>;
  _or?: InputMaybe<Array<Allergies_Severity_Bool_Exp>>;
  allergies?: InputMaybe<Allergies_Bool_Exp>;
  allergies_aggregate?: InputMaybe<Allergies_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "allergies_severity" */
export type Allergies_Severity_Constraint =
  /** unique or primary key constraint on columns "value" */
  'allergies_severity_pkey';

export type Allergies_Severity_Enum =
  /** Fatal */
  | 'FATAL'
  /** Mild */
  | 'MILD'
  /** Moderate */
  | 'MODERATE'
  /** Severe */
  | 'SEVERE';

/** Boolean expression to compare columns of type "allergies_severity_enum". All fields are combined with logical 'AND'. */
export type Allergies_Severity_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Allergies_Severity_Enum>;
  _in?: InputMaybe<Array<Allergies_Severity_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Allergies_Severity_Enum>;
  _nin?: InputMaybe<Array<Allergies_Severity_Enum>>;
};

/** input type for inserting data into table "allergies_severity" */
export type Allergies_Severity_Insert_Input = {
  allergies?: InputMaybe<Allergies_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Allergies_Severity_Max_Fields = {
  __typename?: 'allergies_severity_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Allergies_Severity_Min_Fields = {
  __typename?: 'allergies_severity_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "allergies_severity" */
export type Allergies_Severity_Mutation_Response = {
  __typename?: 'allergies_severity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Allergies_Severity>;
};

/** input type for inserting object relation for remote table "allergies_severity" */
export type Allergies_Severity_Obj_Rel_Insert_Input = {
  data: Allergies_Severity_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Allergies_Severity_On_Conflict>;
};

/** on_conflict condition type for table "allergies_severity" */
export type Allergies_Severity_On_Conflict = {
  constraint: Allergies_Severity_Constraint;
  update_columns?: Array<Allergies_Severity_Update_Column>;
  where?: InputMaybe<Allergies_Severity_Bool_Exp>;
};

/** Ordering options when selecting data from "allergies_severity". */
export type Allergies_Severity_Order_By = {
  allergies_aggregate?: InputMaybe<Allergies_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: allergies_severity */
export type Allergies_Severity_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "allergies_severity" */
export type Allergies_Severity_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "allergies_severity" */
export type Allergies_Severity_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "allergies_severity" */
export type Allergies_Severity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Allergies_Severity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Allergies_Severity_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "allergies_severity" */
export type Allergies_Severity_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Allergies_Severity_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Allergies_Severity_Set_Input>;
  /** filter the rows which have to be updated */
  where: Allergies_Severity_Bool_Exp;
};

/** columns and relationships of "allergies_status" */
export type Allergies_Status = {
  __typename?: 'allergies_status';
  /** An array relationship */
  allergies: Array<Allergies>;
  /** An aggregate relationship */
  allergies_aggregate: Allergies_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "allergies_status" */
export type Allergies_StatusAllergiesArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Order_By>>;
  where?: InputMaybe<Allergies_Bool_Exp>;
};

/** columns and relationships of "allergies_status" */
export type Allergies_StatusAllergies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Order_By>>;
  where?: InputMaybe<Allergies_Bool_Exp>;
};

/** aggregated selection of "allergies_status" */
export type Allergies_Status_Aggregate = {
  __typename?: 'allergies_status_aggregate';
  aggregate?: Maybe<Allergies_Status_Aggregate_Fields>;
  nodes: Array<Allergies_Status>;
};

/** aggregate fields of "allergies_status" */
export type Allergies_Status_Aggregate_Fields = {
  __typename?: 'allergies_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Allergies_Status_Max_Fields>;
  min?: Maybe<Allergies_Status_Min_Fields>;
};

/** aggregate fields of "allergies_status" */
export type Allergies_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Allergies_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "allergies_status". All fields are combined with a logical 'AND'. */
export type Allergies_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Allergies_Status_Bool_Exp>>;
  _not?: InputMaybe<Allergies_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Allergies_Status_Bool_Exp>>;
  allergies?: InputMaybe<Allergies_Bool_Exp>;
  allergies_aggregate?: InputMaybe<Allergies_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "allergies_status" */
export type Allergies_Status_Constraint =
  /** unique or primary key constraint on columns "value" */
  'allergies_status_pkey';

export type Allergies_Status_Enum =
  /** Allergies that are still active */
  | 'ACTIVE'
  /** Allergies that have been marked as error */
  | 'ERROR'
  /** Allergies that have been resolved */
  | 'RESOLVED';

/** Boolean expression to compare columns of type "allergies_status_enum". All fields are combined with logical 'AND'. */
export type Allergies_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Allergies_Status_Enum>;
  _in?: InputMaybe<Array<Allergies_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Allergies_Status_Enum>;
  _nin?: InputMaybe<Array<Allergies_Status_Enum>>;
};

/** input type for inserting data into table "allergies_status" */
export type Allergies_Status_Insert_Input = {
  allergies?: InputMaybe<Allergies_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Allergies_Status_Max_Fields = {
  __typename?: 'allergies_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Allergies_Status_Min_Fields = {
  __typename?: 'allergies_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "allergies_status" */
export type Allergies_Status_Mutation_Response = {
  __typename?: 'allergies_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Allergies_Status>;
};

/** input type for inserting object relation for remote table "allergies_status" */
export type Allergies_Status_Obj_Rel_Insert_Input = {
  data: Allergies_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Allergies_Status_On_Conflict>;
};

/** on_conflict condition type for table "allergies_status" */
export type Allergies_Status_On_Conflict = {
  constraint: Allergies_Status_Constraint;
  update_columns?: Array<Allergies_Status_Update_Column>;
  where?: InputMaybe<Allergies_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "allergies_status". */
export type Allergies_Status_Order_By = {
  allergies_aggregate?: InputMaybe<Allergies_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: allergies_status */
export type Allergies_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "allergies_status" */
export type Allergies_Status_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "allergies_status" */
export type Allergies_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "allergies_status" */
export type Allergies_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Allergies_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Allergies_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "allergies_status" */
export type Allergies_Status_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Allergies_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Allergies_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Allergies_Status_Bool_Exp;
};

/** Streaming cursor of the table "allergies" */
export type Allergies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Allergies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Allergies_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  endedAt?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  reaction?: InputMaybe<Scalars['jsonb']>;
  severity?: InputMaybe<Allergies_Severity_Enum>;
  startedAt?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Allergies_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "allergies" */
export type Allergies_Update_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'comments'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'endedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'patientId'
  /** column name */
  | 'reaction'
  /** column name */
  | 'severity'
  /** column name */
  | 'startedAt'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt';

export type Allergies_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Allergies_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Allergies_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Allergies_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Allergies_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Allergies_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Allergies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Allergies_Bool_Exp;
};

/** columns and relationships of "appointment_blocks" */
export type Appointment_Blocks = {
  __typename?: 'appointment_blocks';
  /** An array relationship */
  appointment_blocks_staffs: Array<Appointment_Blocks_Staff>;
  /** An aggregate relationship */
  appointment_blocks_staffs_aggregate: Appointment_Blocks_Staff_Aggregate;
  /** An object relationship */
  clinic: Clinics;
  clinicId: Scalars['uuid'];
  createdBy?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  endDateTime: Scalars['timestamp'];
  id: Scalars['uuid'];
  reason?: Maybe<Scalars['String']>;
  /** An object relationship */
  staff?: Maybe<Staff>;
  startDateTime: Scalars['timestamp'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "appointment_blocks" */
export type Appointment_BlocksAppointment_Blocks_StaffsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Blocks_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Blocks_Staff_Order_By>>;
  where?: InputMaybe<Appointment_Blocks_Staff_Bool_Exp>;
};

/** columns and relationships of "appointment_blocks" */
export type Appointment_BlocksAppointment_Blocks_Staffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Blocks_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Blocks_Staff_Order_By>>;
  where?: InputMaybe<Appointment_Blocks_Staff_Bool_Exp>;
};

/** aggregated selection of "appointment_blocks" */
export type Appointment_Blocks_Aggregate = {
  __typename?: 'appointment_blocks_aggregate';
  aggregate?: Maybe<Appointment_Blocks_Aggregate_Fields>;
  nodes: Array<Appointment_Blocks>;
};

export type Appointment_Blocks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Appointment_Blocks_Aggregate_Bool_Exp_Count>;
};

export type Appointment_Blocks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Appointment_Blocks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Appointment_Blocks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "appointment_blocks" */
export type Appointment_Blocks_Aggregate_Fields = {
  __typename?: 'appointment_blocks_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Appointment_Blocks_Max_Fields>;
  min?: Maybe<Appointment_Blocks_Min_Fields>;
};

/** aggregate fields of "appointment_blocks" */
export type Appointment_Blocks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Appointment_Blocks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "appointment_blocks" */
export type Appointment_Blocks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Appointment_Blocks_Max_Order_By>;
  min?: InputMaybe<Appointment_Blocks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "appointment_blocks" */
export type Appointment_Blocks_Arr_Rel_Insert_Input = {
  data: Array<Appointment_Blocks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Appointment_Blocks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "appointment_blocks". All fields are combined with a logical 'AND'. */
export type Appointment_Blocks_Bool_Exp = {
  _and?: InputMaybe<Array<Appointment_Blocks_Bool_Exp>>;
  _not?: InputMaybe<Appointment_Blocks_Bool_Exp>;
  _or?: InputMaybe<Array<Appointment_Blocks_Bool_Exp>>;
  appointment_blocks_staffs?: InputMaybe<Appointment_Blocks_Staff_Bool_Exp>;
  appointment_blocks_staffs_aggregate?: InputMaybe<Appointment_Blocks_Staff_Aggregate_Bool_Exp>;
  clinic?: InputMaybe<Clinics_Bool_Exp>;
  clinicId?: InputMaybe<Uuid_Comparison_Exp>;
  createdBy?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  endDateTime?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  startDateTime?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "appointment_blocks" */
export type Appointment_Blocks_Constraint =
  /** unique or primary key constraint on columns "id" */
  'appointment_blocks_pkey';

/** input type for inserting data into table "appointment_blocks" */
export type Appointment_Blocks_Insert_Input = {
  appointment_blocks_staffs?: InputMaybe<Appointment_Blocks_Staff_Arr_Rel_Insert_Input>;
  clinic?: InputMaybe<Clinics_Obj_Rel_Insert_Input>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endDateTime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  reason?: InputMaybe<Scalars['String']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  startDateTime?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Appointment_Blocks_Max_Fields = {
  __typename?: 'appointment_blocks_max_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  createdBy?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  endDateTime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  reason?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "appointment_blocks" */
export type Appointment_Blocks_Max_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  endDateTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  startDateTime?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Appointment_Blocks_Min_Fields = {
  __typename?: 'appointment_blocks_min_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  createdBy?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  endDateTime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  reason?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "appointment_blocks" */
export type Appointment_Blocks_Min_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  endDateTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  startDateTime?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "appointment_blocks" */
export type Appointment_Blocks_Mutation_Response = {
  __typename?: 'appointment_blocks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Appointment_Blocks>;
};

/** input type for inserting object relation for remote table "appointment_blocks" */
export type Appointment_Blocks_Obj_Rel_Insert_Input = {
  data: Appointment_Blocks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Appointment_Blocks_On_Conflict>;
};

/** on_conflict condition type for table "appointment_blocks" */
export type Appointment_Blocks_On_Conflict = {
  constraint: Appointment_Blocks_Constraint;
  update_columns?: Array<Appointment_Blocks_Update_Column>;
  where?: InputMaybe<Appointment_Blocks_Bool_Exp>;
};

/** Ordering options when selecting data from "appointment_blocks". */
export type Appointment_Blocks_Order_By = {
  appointment_blocks_staffs_aggregate?: InputMaybe<Appointment_Blocks_Staff_Aggregate_Order_By>;
  clinic?: InputMaybe<Clinics_Order_By>;
  clinicId?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  endDateTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  startDateTime?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: appointment_blocks */
export type Appointment_Blocks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "appointment_blocks" */
export type Appointment_Blocks_Select_Column =
  /** column name */
  | 'clinicId'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'created_at'
  /** column name */
  | 'endDateTime'
  /** column name */
  | 'id'
  /** column name */
  | 'reason'
  /** column name */
  | 'startDateTime'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "appointment_blocks" */
export type Appointment_Blocks_Set_Input = {
  clinicId?: InputMaybe<Scalars['uuid']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endDateTime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  reason?: InputMaybe<Scalars['String']>;
  startDateTime?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "appointment_blocks_staff" */
export type Appointment_Blocks_Staff = {
  __typename?: 'appointment_blocks_staff';
  appointmentBlockId: Scalars['uuid'];
  /** An object relationship */
  appointment_block: Appointment_Blocks;
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  staff: Staff;
  staffId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Aggregate = {
  __typename?: 'appointment_blocks_staff_aggregate';
  aggregate?: Maybe<Appointment_Blocks_Staff_Aggregate_Fields>;
  nodes: Array<Appointment_Blocks_Staff>;
};

export type Appointment_Blocks_Staff_Aggregate_Bool_Exp = {
  count?: InputMaybe<Appointment_Blocks_Staff_Aggregate_Bool_Exp_Count>;
};

export type Appointment_Blocks_Staff_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Appointment_Blocks_Staff_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Appointment_Blocks_Staff_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Aggregate_Fields = {
  __typename?: 'appointment_blocks_staff_aggregate_fields';
  avg?: Maybe<Appointment_Blocks_Staff_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Appointment_Blocks_Staff_Max_Fields>;
  min?: Maybe<Appointment_Blocks_Staff_Min_Fields>;
  stddev?: Maybe<Appointment_Blocks_Staff_Stddev_Fields>;
  stddev_pop?: Maybe<Appointment_Blocks_Staff_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Appointment_Blocks_Staff_Stddev_Samp_Fields>;
  sum?: Maybe<Appointment_Blocks_Staff_Sum_Fields>;
  var_pop?: Maybe<Appointment_Blocks_Staff_Var_Pop_Fields>;
  var_samp?: Maybe<Appointment_Blocks_Staff_Var_Samp_Fields>;
  variance?: Maybe<Appointment_Blocks_Staff_Variance_Fields>;
};

/** aggregate fields of "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Appointment_Blocks_Staff_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Aggregate_Order_By = {
  avg?: InputMaybe<Appointment_Blocks_Staff_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Appointment_Blocks_Staff_Max_Order_By>;
  min?: InputMaybe<Appointment_Blocks_Staff_Min_Order_By>;
  stddev?: InputMaybe<Appointment_Blocks_Staff_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Appointment_Blocks_Staff_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Appointment_Blocks_Staff_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Appointment_Blocks_Staff_Sum_Order_By>;
  var_pop?: InputMaybe<Appointment_Blocks_Staff_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Appointment_Blocks_Staff_Var_Samp_Order_By>;
  variance?: InputMaybe<Appointment_Blocks_Staff_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Arr_Rel_Insert_Input = {
  data: Array<Appointment_Blocks_Staff_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Appointment_Blocks_Staff_On_Conflict>;
};

/** aggregate avg on columns */
export type Appointment_Blocks_Staff_Avg_Fields = {
  __typename?: 'appointment_blocks_staff_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "appointment_blocks_staff". All fields are combined with a logical 'AND'. */
export type Appointment_Blocks_Staff_Bool_Exp = {
  _and?: InputMaybe<Array<Appointment_Blocks_Staff_Bool_Exp>>;
  _not?: InputMaybe<Appointment_Blocks_Staff_Bool_Exp>;
  _or?: InputMaybe<Array<Appointment_Blocks_Staff_Bool_Exp>>;
  appointmentBlockId?: InputMaybe<Uuid_Comparison_Exp>;
  appointment_block?: InputMaybe<Appointment_Blocks_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'appointment_blocks_staff_pkey'
  /** unique or primary key constraint on columns "staffId", "appointmentBlockId" */
  | 'appointment_blocks_staff_staffId_appointmentBlockId_key';

/** input type for incrementing numeric columns in table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Insert_Input = {
  appointmentBlockId?: InputMaybe<Scalars['uuid']>;
  appointment_block?: InputMaybe<Appointment_Blocks_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Appointment_Blocks_Staff_Max_Fields = {
  __typename?: 'appointment_blocks_staff_max_fields';
  appointmentBlockId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  staffId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Max_Order_By = {
  appointmentBlockId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Appointment_Blocks_Staff_Min_Fields = {
  __typename?: 'appointment_blocks_staff_min_fields';
  appointmentBlockId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  staffId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Min_Order_By = {
  appointmentBlockId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Mutation_Response = {
  __typename?: 'appointment_blocks_staff_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Appointment_Blocks_Staff>;
};

/** on_conflict condition type for table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_On_Conflict = {
  constraint: Appointment_Blocks_Staff_Constraint;
  update_columns?: Array<Appointment_Blocks_Staff_Update_Column>;
  where?: InputMaybe<Appointment_Blocks_Staff_Bool_Exp>;
};

/** Ordering options when selecting data from "appointment_blocks_staff". */
export type Appointment_Blocks_Staff_Order_By = {
  appointmentBlockId?: InputMaybe<Order_By>;
  appointment_block?: InputMaybe<Appointment_Blocks_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: appointment_blocks_staff */
export type Appointment_Blocks_Staff_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Select_Column =
  /** column name */
  | 'appointmentBlockId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'staffId'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Set_Input = {
  appointmentBlockId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Appointment_Blocks_Staff_Stddev_Fields = {
  __typename?: 'appointment_blocks_staff_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Appointment_Blocks_Staff_Stddev_Pop_Fields = {
  __typename?: 'appointment_blocks_staff_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Appointment_Blocks_Staff_Stddev_Samp_Fields = {
  __typename?: 'appointment_blocks_staff_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Appointment_Blocks_Staff_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Appointment_Blocks_Staff_Stream_Cursor_Value_Input = {
  appointmentBlockId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Appointment_Blocks_Staff_Sum_Fields = {
  __typename?: 'appointment_blocks_staff_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Update_Column =
  /** column name */
  | 'appointmentBlockId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'staffId'
  /** column name */
  | 'updatedAt';

export type Appointment_Blocks_Staff_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Appointment_Blocks_Staff_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Appointment_Blocks_Staff_Set_Input>;
  /** filter the rows which have to be updated */
  where: Appointment_Blocks_Staff_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Appointment_Blocks_Staff_Var_Pop_Fields = {
  __typename?: 'appointment_blocks_staff_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Appointment_Blocks_Staff_Var_Samp_Fields = {
  __typename?: 'appointment_blocks_staff_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Appointment_Blocks_Staff_Variance_Fields = {
  __typename?: 'appointment_blocks_staff_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "appointment_blocks_staff" */
export type Appointment_Blocks_Staff_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "appointment_blocks" */
export type Appointment_Blocks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Appointment_Blocks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Appointment_Blocks_Stream_Cursor_Value_Input = {
  clinicId?: InputMaybe<Scalars['uuid']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endDateTime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  reason?: InputMaybe<Scalars['String']>;
  startDateTime?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "appointment_blocks" */
export type Appointment_Blocks_Update_Column =
  /** column name */
  | 'clinicId'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'created_at'
  /** column name */
  | 'endDateTime'
  /** column name */
  | 'id'
  /** column name */
  | 'reason'
  /** column name */
  | 'startDateTime'
  /** column name */
  | 'updated_at';

export type Appointment_Blocks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Appointment_Blocks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Appointment_Blocks_Bool_Exp;
};

/** columns and relationships of "appointment_counts" */
export type Appointment_Counts = {
  __typename?: 'appointment_counts';
  /** An object relationship */
  clinic?: Maybe<Clinics>;
  clinicId?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['date']>;
};

/** aggregated selection of "appointment_counts" */
export type Appointment_Counts_Aggregate = {
  __typename?: 'appointment_counts_aggregate';
  aggregate?: Maybe<Appointment_Counts_Aggregate_Fields>;
  nodes: Array<Appointment_Counts>;
};

/** aggregate fields of "appointment_counts" */
export type Appointment_Counts_Aggregate_Fields = {
  __typename?: 'appointment_counts_aggregate_fields';
  avg?: Maybe<Appointment_Counts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Appointment_Counts_Max_Fields>;
  min?: Maybe<Appointment_Counts_Min_Fields>;
  stddev?: Maybe<Appointment_Counts_Stddev_Fields>;
  stddev_pop?: Maybe<Appointment_Counts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Appointment_Counts_Stddev_Samp_Fields>;
  sum?: Maybe<Appointment_Counts_Sum_Fields>;
  var_pop?: Maybe<Appointment_Counts_Var_Pop_Fields>;
  var_samp?: Maybe<Appointment_Counts_Var_Samp_Fields>;
  variance?: Maybe<Appointment_Counts_Variance_Fields>;
};

/** aggregate fields of "appointment_counts" */
export type Appointment_Counts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Appointment_Counts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Appointment_Counts_Avg_Fields = {
  __typename?: 'appointment_counts_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "appointment_counts". All fields are combined with a logical 'AND'. */
export type Appointment_Counts_Bool_Exp = {
  _and?: InputMaybe<Array<Appointment_Counts_Bool_Exp>>;
  _not?: InputMaybe<Appointment_Counts_Bool_Exp>;
  _or?: InputMaybe<Array<Appointment_Counts_Bool_Exp>>;
  clinic?: InputMaybe<Clinics_Bool_Exp>;
  clinicId?: InputMaybe<Uuid_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
};

/** aggregate max on columns */
export type Appointment_Counts_Max_Fields = {
  __typename?: 'appointment_counts_max_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type Appointment_Counts_Min_Fields = {
  __typename?: 'appointment_counts_min_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['date']>;
};

/** Ordering options when selecting data from "appointment_counts". */
export type Appointment_Counts_Order_By = {
  clinic?: InputMaybe<Clinics_Order_By>;
  clinicId?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
};

/** select columns of table "appointment_counts" */
export type Appointment_Counts_Select_Column =
  /** column name */
  | 'clinicId'
  /** column name */
  | 'count'
  /** column name */
  | 'date';

/** aggregate stddev on columns */
export type Appointment_Counts_Stddev_Fields = {
  __typename?: 'appointment_counts_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Appointment_Counts_Stddev_Pop_Fields = {
  __typename?: 'appointment_counts_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Appointment_Counts_Stddev_Samp_Fields = {
  __typename?: 'appointment_counts_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "appointment_counts" */
export type Appointment_Counts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Appointment_Counts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Appointment_Counts_Stream_Cursor_Value_Input = {
  clinicId?: InputMaybe<Scalars['uuid']>;
  count?: InputMaybe<Scalars['bigint']>;
  date?: InputMaybe<Scalars['date']>;
};

/** aggregate sum on columns */
export type Appointment_Counts_Sum_Fields = {
  __typename?: 'appointment_counts_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Appointment_Counts_Var_Pop_Fields = {
  __typename?: 'appointment_counts_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Appointment_Counts_Var_Samp_Fields = {
  __typename?: 'appointment_counts_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Appointment_Counts_Variance_Fields = {
  __typename?: 'appointment_counts_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "appointment_patient" */
export type Appointment_Patient = {
  __typename?: 'appointment_patient';
  /** An object relationship */
  appointment: Appointments;
  appointment_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  patient: Patients;
  patient_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "appointment_patient" */
export type Appointment_Patient_Aggregate = {
  __typename?: 'appointment_patient_aggregate';
  aggregate?: Maybe<Appointment_Patient_Aggregate_Fields>;
  nodes: Array<Appointment_Patient>;
};

export type Appointment_Patient_Aggregate_Bool_Exp = {
  count?: InputMaybe<Appointment_Patient_Aggregate_Bool_Exp_Count>;
};

export type Appointment_Patient_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Appointment_Patient_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Appointment_Patient_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "appointment_patient" */
export type Appointment_Patient_Aggregate_Fields = {
  __typename?: 'appointment_patient_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Appointment_Patient_Max_Fields>;
  min?: Maybe<Appointment_Patient_Min_Fields>;
};

/** aggregate fields of "appointment_patient" */
export type Appointment_Patient_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Appointment_Patient_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "appointment_patient" */
export type Appointment_Patient_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Appointment_Patient_Max_Order_By>;
  min?: InputMaybe<Appointment_Patient_Min_Order_By>;
};

/** input type for inserting array relation for remote table "appointment_patient" */
export type Appointment_Patient_Arr_Rel_Insert_Input = {
  data: Array<Appointment_Patient_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Appointment_Patient_On_Conflict>;
};

/** Boolean expression to filter rows from the table "appointment_patient". All fields are combined with a logical 'AND'. */
export type Appointment_Patient_Bool_Exp = {
  _and?: InputMaybe<Array<Appointment_Patient_Bool_Exp>>;
  _not?: InputMaybe<Appointment_Patient_Bool_Exp>;
  _or?: InputMaybe<Array<Appointment_Patient_Bool_Exp>>;
  appointment?: InputMaybe<Appointments_Bool_Exp>;
  appointment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patient_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "appointment_patient" */
export type Appointment_Patient_Constraint =
  /** unique or primary key constraint on columns "patient_id", "appointment_id" */
  | 'appointment_patient_appointment_id_patient_id_key'
  /** unique or primary key constraint on columns "id" */
  | 'appointment_patients_pkey';

/** input type for inserting data into table "appointment_patient" */
export type Appointment_Patient_Insert_Input = {
  appointment?: InputMaybe<Appointments_Obj_Rel_Insert_Input>;
  appointment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Appointment_Patient_Max_Fields = {
  __typename?: 'appointment_patient_max_fields';
  appointment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patient_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "appointment_patient" */
export type Appointment_Patient_Max_Order_By = {
  appointment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Appointment_Patient_Min_Fields = {
  __typename?: 'appointment_patient_min_fields';
  appointment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patient_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "appointment_patient" */
export type Appointment_Patient_Min_Order_By = {
  appointment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "appointment_patient" */
export type Appointment_Patient_Mutation_Response = {
  __typename?: 'appointment_patient_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Appointment_Patient>;
};

/** on_conflict condition type for table "appointment_patient" */
export type Appointment_Patient_On_Conflict = {
  constraint: Appointment_Patient_Constraint;
  update_columns?: Array<Appointment_Patient_Update_Column>;
  where?: InputMaybe<Appointment_Patient_Bool_Exp>;
};

/** Ordering options when selecting data from "appointment_patient". */
export type Appointment_Patient_Order_By = {
  appointment?: InputMaybe<Appointments_Order_By>;
  appointment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patient_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: appointment_patient */
export type Appointment_Patient_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "appointment_patient" */
export type Appointment_Patient_Select_Column =
  /** column name */
  | 'appointment_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'patient_id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "appointment_patient" */
export type Appointment_Patient_Set_Input = {
  appointment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "appointment_patient" */
export type Appointment_Patient_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Appointment_Patient_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Appointment_Patient_Stream_Cursor_Value_Input = {
  appointment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "appointment_patient" */
export type Appointment_Patient_Update_Column =
  /** column name */
  | 'appointment_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'patient_id'
  /** column name */
  | 'updated_at';

export type Appointment_Patient_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Appointment_Patient_Set_Input>;
  /** filter the rows which have to be updated */
  where: Appointment_Patient_Bool_Exp;
};

/** columns and relationships of "appointment_payment" */
export type Appointment_Payment = {
  __typename?: 'appointment_payment';
  /** An array relationship */
  appointments: Array<Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Appointments_Aggregate;
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** columns and relationships of "appointment_payment" */
export type Appointment_PaymentAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** columns and relationships of "appointment_payment" */
export type Appointment_PaymentAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** aggregated selection of "appointment_payment" */
export type Appointment_Payment_Aggregate = {
  __typename?: 'appointment_payment_aggregate';
  aggregate?: Maybe<Appointment_Payment_Aggregate_Fields>;
  nodes: Array<Appointment_Payment>;
};

/** aggregate fields of "appointment_payment" */
export type Appointment_Payment_Aggregate_Fields = {
  __typename?: 'appointment_payment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Appointment_Payment_Max_Fields>;
  min?: Maybe<Appointment_Payment_Min_Fields>;
};

/** aggregate fields of "appointment_payment" */
export type Appointment_Payment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Appointment_Payment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "appointment_payment". All fields are combined with a logical 'AND'. */
export type Appointment_Payment_Bool_Exp = {
  _and?: InputMaybe<Array<Appointment_Payment_Bool_Exp>>;
  _not?: InputMaybe<Appointment_Payment_Bool_Exp>;
  _or?: InputMaybe<Array<Appointment_Payment_Bool_Exp>>;
  appointments?: InputMaybe<Appointments_Bool_Exp>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "appointment_payment" */
export type Appointment_Payment_Constraint =
  /** unique or primary key constraint on columns "value" */
  'appointment_payment_pkey';

export type Appointment_Payment_Enum =
  /** Appointment billed to card */
  | 'CARD'
  /** Appointment billed to cash */
  | 'CASH'
  /** Appointment billed to insurance */
  | 'INSURANCE';

/** Boolean expression to compare columns of type "appointment_payment_enum". All fields are combined with logical 'AND'. */
export type Appointment_Payment_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Appointment_Payment_Enum>;
  _in?: InputMaybe<Array<Appointment_Payment_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Appointment_Payment_Enum>;
  _nin?: InputMaybe<Array<Appointment_Payment_Enum>>;
};

/** input type for inserting data into table "appointment_payment" */
export type Appointment_Payment_Insert_Input = {
  appointments?: InputMaybe<Appointments_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Appointment_Payment_Max_Fields = {
  __typename?: 'appointment_payment_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Appointment_Payment_Min_Fields = {
  __typename?: 'appointment_payment_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "appointment_payment" */
export type Appointment_Payment_Mutation_Response = {
  __typename?: 'appointment_payment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Appointment_Payment>;
};

/** input type for inserting object relation for remote table "appointment_payment" */
export type Appointment_Payment_Obj_Rel_Insert_Input = {
  data: Appointment_Payment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Appointment_Payment_On_Conflict>;
};

/** on_conflict condition type for table "appointment_payment" */
export type Appointment_Payment_On_Conflict = {
  constraint: Appointment_Payment_Constraint;
  update_columns?: Array<Appointment_Payment_Update_Column>;
  where?: InputMaybe<Appointment_Payment_Bool_Exp>;
};

/** Ordering options when selecting data from "appointment_payment". */
export type Appointment_Payment_Order_By = {
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: appointment_payment */
export type Appointment_Payment_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "appointment_payment" */
export type Appointment_Payment_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "appointment_payment" */
export type Appointment_Payment_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "appointment_payment" */
export type Appointment_Payment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Appointment_Payment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Appointment_Payment_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "appointment_payment" */
export type Appointment_Payment_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Appointment_Payment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Appointment_Payment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Appointment_Payment_Bool_Exp;
};

/** columns and relationships of "appointment_reservations" */
export type Appointment_Reservations = {
  __typename?: 'appointment_reservations';
  /** An object relationship */
  clinic: Clinics;
  clinicId: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  end_time: Scalars['timestamp'];
  id: Scalars['uuid'];
  start_time: Scalars['timestamp'];
  timezone?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  visitClassification?: Maybe<Visit_Classifications_Enum>;
  visitType: Visit_Types_Enum;
  /** An object relationship */
  visit_classification?: Maybe<Visit_Classifications>;
  /** An object relationship */
  visit_type: Visit_Types;
};

/** aggregated selection of "appointment_reservations" */
export type Appointment_Reservations_Aggregate = {
  __typename?: 'appointment_reservations_aggregate';
  aggregate?: Maybe<Appointment_Reservations_Aggregate_Fields>;
  nodes: Array<Appointment_Reservations>;
};

export type Appointment_Reservations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Appointment_Reservations_Aggregate_Bool_Exp_Count>;
};

export type Appointment_Reservations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Appointment_Reservations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Appointment_Reservations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "appointment_reservations" */
export type Appointment_Reservations_Aggregate_Fields = {
  __typename?: 'appointment_reservations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Appointment_Reservations_Max_Fields>;
  min?: Maybe<Appointment_Reservations_Min_Fields>;
};

/** aggregate fields of "appointment_reservations" */
export type Appointment_Reservations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Appointment_Reservations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "appointment_reservations" */
export type Appointment_Reservations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Appointment_Reservations_Max_Order_By>;
  min?: InputMaybe<Appointment_Reservations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "appointment_reservations" */
export type Appointment_Reservations_Arr_Rel_Insert_Input = {
  data: Array<Appointment_Reservations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Appointment_Reservations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "appointment_reservations". All fields are combined with a logical 'AND'. */
export type Appointment_Reservations_Bool_Exp = {
  _and?: InputMaybe<Array<Appointment_Reservations_Bool_Exp>>;
  _not?: InputMaybe<Appointment_Reservations_Bool_Exp>;
  _or?: InputMaybe<Array<Appointment_Reservations_Bool_Exp>>;
  clinic?: InputMaybe<Clinics_Bool_Exp>;
  clinicId?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  end_time?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  start_time?: InputMaybe<Timestamp_Comparison_Exp>;
  timezone?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  visitClassification?: InputMaybe<Visit_Classifications_Enum_Comparison_Exp>;
  visitType?: InputMaybe<Visit_Types_Enum_Comparison_Exp>;
  visit_classification?: InputMaybe<Visit_Classifications_Bool_Exp>;
  visit_type?: InputMaybe<Visit_Types_Bool_Exp>;
};

/** unique or primary key constraints on table "appointment_reservations" */
export type Appointment_Reservations_Constraint =
  /** unique or primary key constraint on columns "id" */
  'appointment_reservations_pkey';

/** input type for inserting data into table "appointment_reservations" */
export type Appointment_Reservations_Insert_Input = {
  clinic?: InputMaybe<Clinics_Obj_Rel_Insert_Input>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_time?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  start_time?: InputMaybe<Scalars['timestamp']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visitClassification?: InputMaybe<Visit_Classifications_Enum>;
  visitType?: InputMaybe<Visit_Types_Enum>;
  visit_classification?: InputMaybe<Visit_Classifications_Obj_Rel_Insert_Input>;
  visit_type?: InputMaybe<Visit_Types_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Appointment_Reservations_Max_Fields = {
  __typename?: 'appointment_reservations_max_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  start_time?: Maybe<Scalars['timestamp']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "appointment_reservations" */
export type Appointment_Reservations_Max_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Appointment_Reservations_Min_Fields = {
  __typename?: 'appointment_reservations_min_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  start_time?: Maybe<Scalars['timestamp']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "appointment_reservations" */
export type Appointment_Reservations_Min_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "appointment_reservations" */
export type Appointment_Reservations_Mutation_Response = {
  __typename?: 'appointment_reservations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Appointment_Reservations>;
};

/** on_conflict condition type for table "appointment_reservations" */
export type Appointment_Reservations_On_Conflict = {
  constraint: Appointment_Reservations_Constraint;
  update_columns?: Array<Appointment_Reservations_Update_Column>;
  where?: InputMaybe<Appointment_Reservations_Bool_Exp>;
};

/** Ordering options when selecting data from "appointment_reservations". */
export type Appointment_Reservations_Order_By = {
  clinic?: InputMaybe<Clinics_Order_By>;
  clinicId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visitClassification?: InputMaybe<Order_By>;
  visitType?: InputMaybe<Order_By>;
  visit_classification?: InputMaybe<Visit_Classifications_Order_By>;
  visit_type?: InputMaybe<Visit_Types_Order_By>;
};

/** primary key columns input for table: appointment_reservations */
export type Appointment_Reservations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "appointment_reservations" */
export type Appointment_Reservations_Select_Column =
  /** column name */
  | 'clinicId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'end_time'
  /** column name */
  | 'id'
  /** column name */
  | 'start_time'
  /** column name */
  | 'timezone'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'visitClassification'
  /** column name */
  | 'visitType';

/** input type for updating data in table "appointment_reservations" */
export type Appointment_Reservations_Set_Input = {
  clinicId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_time?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  start_time?: InputMaybe<Scalars['timestamp']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visitClassification?: InputMaybe<Visit_Classifications_Enum>;
  visitType?: InputMaybe<Visit_Types_Enum>;
};

/** Streaming cursor of the table "appointment_reservations" */
export type Appointment_Reservations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Appointment_Reservations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Appointment_Reservations_Stream_Cursor_Value_Input = {
  clinicId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_time?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  start_time?: InputMaybe<Scalars['timestamp']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visitClassification?: InputMaybe<Visit_Classifications_Enum>;
  visitType?: InputMaybe<Visit_Types_Enum>;
};

/** update columns of table "appointment_reservations" */
export type Appointment_Reservations_Update_Column =
  /** column name */
  | 'clinicId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'end_time'
  /** column name */
  | 'id'
  /** column name */
  | 'start_time'
  /** column name */
  | 'timezone'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'visitClassification'
  /** column name */
  | 'visitType';

export type Appointment_Reservations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Appointment_Reservations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Appointment_Reservations_Bool_Exp;
};

/** columns and relationships of "appointment_staff" */
export type Appointment_Staff = {
  __typename?: 'appointment_staff';
  /** An object relationship */
  appointment: Appointments;
  appointmentId: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  staff: Staff;
  staffId: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "appointment_staff" */
export type Appointment_Staff_Aggregate = {
  __typename?: 'appointment_staff_aggregate';
  aggregate?: Maybe<Appointment_Staff_Aggregate_Fields>;
  nodes: Array<Appointment_Staff>;
};

export type Appointment_Staff_Aggregate_Bool_Exp = {
  count?: InputMaybe<Appointment_Staff_Aggregate_Bool_Exp_Count>;
};

export type Appointment_Staff_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Appointment_Staff_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Appointment_Staff_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "appointment_staff" */
export type Appointment_Staff_Aggregate_Fields = {
  __typename?: 'appointment_staff_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Appointment_Staff_Max_Fields>;
  min?: Maybe<Appointment_Staff_Min_Fields>;
};

/** aggregate fields of "appointment_staff" */
export type Appointment_Staff_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Appointment_Staff_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "appointment_staff" */
export type Appointment_Staff_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Appointment_Staff_Max_Order_By>;
  min?: InputMaybe<Appointment_Staff_Min_Order_By>;
};

/** input type for inserting array relation for remote table "appointment_staff" */
export type Appointment_Staff_Arr_Rel_Insert_Input = {
  data: Array<Appointment_Staff_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Appointment_Staff_On_Conflict>;
};

/** Boolean expression to filter rows from the table "appointment_staff". All fields are combined with a logical 'AND'. */
export type Appointment_Staff_Bool_Exp = {
  _and?: InputMaybe<Array<Appointment_Staff_Bool_Exp>>;
  _not?: InputMaybe<Appointment_Staff_Bool_Exp>;
  _or?: InputMaybe<Array<Appointment_Staff_Bool_Exp>>;
  appointment?: InputMaybe<Appointments_Bool_Exp>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "appointment_staff" */
export type Appointment_Staff_Constraint =
  /** unique or primary key constraint on columns "appointmentId", "staffId" */
  | 'appointment_staff_appointmentId_staffId_key'
  /** unique or primary key constraint on columns "id" */
  | 'appointment_staff_pkey';

/** input type for inserting data into table "appointment_staff" */
export type Appointment_Staff_Insert_Input = {
  appointment?: InputMaybe<Appointments_Obj_Rel_Insert_Input>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Appointment_Staff_Max_Fields = {
  __typename?: 'appointment_staff_max_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  staffId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "appointment_staff" */
export type Appointment_Staff_Max_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Appointment_Staff_Min_Fields = {
  __typename?: 'appointment_staff_min_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  staffId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "appointment_staff" */
export type Appointment_Staff_Min_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "appointment_staff" */
export type Appointment_Staff_Mutation_Response = {
  __typename?: 'appointment_staff_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Appointment_Staff>;
};

/** on_conflict condition type for table "appointment_staff" */
export type Appointment_Staff_On_Conflict = {
  constraint: Appointment_Staff_Constraint;
  update_columns?: Array<Appointment_Staff_Update_Column>;
  where?: InputMaybe<Appointment_Staff_Bool_Exp>;
};

/** Ordering options when selecting data from "appointment_staff". */
export type Appointment_Staff_Order_By = {
  appointment?: InputMaybe<Appointments_Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: appointment_staff */
export type Appointment_Staff_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "appointment_staff" */
export type Appointment_Staff_Select_Column =
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'staffId'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "appointment_staff" */
export type Appointment_Staff_Set_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "appointment_staff" */
export type Appointment_Staff_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Appointment_Staff_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Appointment_Staff_Stream_Cursor_Value_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "appointment_staff" */
export type Appointment_Staff_Update_Column =
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'staffId'
  /** column name */
  | 'updated_at';

export type Appointment_Staff_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Appointment_Staff_Set_Input>;
  /** filter the rows which have to be updated */
  where: Appointment_Staff_Bool_Exp;
};

/** columns and relationships of "appointment_status" */
export type Appointment_Status = {
  __typename?: 'appointment_status';
  /** An array relationship */
  appointments: Array<Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Appointments_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "appointment_status" */
export type Appointment_StatusAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** columns and relationships of "appointment_status" */
export type Appointment_StatusAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** aggregated selection of "appointment_status" */
export type Appointment_Status_Aggregate = {
  __typename?: 'appointment_status_aggregate';
  aggregate?: Maybe<Appointment_Status_Aggregate_Fields>;
  nodes: Array<Appointment_Status>;
};

/** aggregate fields of "appointment_status" */
export type Appointment_Status_Aggregate_Fields = {
  __typename?: 'appointment_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Appointment_Status_Max_Fields>;
  min?: Maybe<Appointment_Status_Min_Fields>;
};

/** aggregate fields of "appointment_status" */
export type Appointment_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Appointment_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "appointment_status". All fields are combined with a logical 'AND'. */
export type Appointment_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Appointment_Status_Bool_Exp>>;
  _not?: InputMaybe<Appointment_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Appointment_Status_Bool_Exp>>;
  appointments?: InputMaybe<Appointments_Bool_Exp>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "appointment_status" */
export type Appointment_Status_Constraint =
  /** unique or primary key constraint on columns "value" */
  'appointment_status_pkey';

export type Appointment_Status_Enum =
  /** Canceled appointment */
  | 'CANCELED'
  /** Patient has checked in */
  | 'CHECKED_IN'
  /** Completed visit */
  | 'COMPLETED'
  /** Created appointment */
  | 'CREATED'
  /** No show */
  | 'NO_SHOW'
  /** Provider has finished */
  | 'PROVIDER_FINISHED'
  /** MA has finished */
  | 'READY_FOR_PROVIDER'
  /** Patient is with provider */
  | 'WITH_PROVIDER';

/** Boolean expression to compare columns of type "appointment_status_enum". All fields are combined with logical 'AND'. */
export type Appointment_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Appointment_Status_Enum>;
  _in?: InputMaybe<Array<Appointment_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Appointment_Status_Enum>;
  _nin?: InputMaybe<Array<Appointment_Status_Enum>>;
};

/** input type for inserting data into table "appointment_status" */
export type Appointment_Status_Insert_Input = {
  appointments?: InputMaybe<Appointments_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Appointment_Status_Max_Fields = {
  __typename?: 'appointment_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Appointment_Status_Min_Fields = {
  __typename?: 'appointment_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "appointment_status" */
export type Appointment_Status_Mutation_Response = {
  __typename?: 'appointment_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Appointment_Status>;
};

/** input type for inserting object relation for remote table "appointment_status" */
export type Appointment_Status_Obj_Rel_Insert_Input = {
  data: Appointment_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Appointment_Status_On_Conflict>;
};

/** on_conflict condition type for table "appointment_status" */
export type Appointment_Status_On_Conflict = {
  constraint: Appointment_Status_Constraint;
  update_columns?: Array<Appointment_Status_Update_Column>;
  where?: InputMaybe<Appointment_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "appointment_status". */
export type Appointment_Status_Order_By = {
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: appointment_status */
export type Appointment_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "appointment_status" */
export type Appointment_Status_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "appointment_status" */
export type Appointment_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "appointment_status" */
export type Appointment_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Appointment_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Appointment_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "appointment_status" */
export type Appointment_Status_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Appointment_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Appointment_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Appointment_Status_Bool_Exp;
};

/** columns and relationships of "appointment_types" */
export type Appointment_Types = {
  __typename?: 'appointment_types';
  /** An array relationship */
  appointments: Array<Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Appointments_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "appointment_types" */
export type Appointment_TypesAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** columns and relationships of "appointment_types" */
export type Appointment_TypesAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** aggregated selection of "appointment_types" */
export type Appointment_Types_Aggregate = {
  __typename?: 'appointment_types_aggregate';
  aggregate?: Maybe<Appointment_Types_Aggregate_Fields>;
  nodes: Array<Appointment_Types>;
};

/** aggregate fields of "appointment_types" */
export type Appointment_Types_Aggregate_Fields = {
  __typename?: 'appointment_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Appointment_Types_Max_Fields>;
  min?: Maybe<Appointment_Types_Min_Fields>;
};

/** aggregate fields of "appointment_types" */
export type Appointment_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Appointment_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "appointment_types". All fields are combined with a logical 'AND'. */
export type Appointment_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Appointment_Types_Bool_Exp>>;
  _not?: InputMaybe<Appointment_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Appointment_Types_Bool_Exp>>;
  appointments?: InputMaybe<Appointments_Bool_Exp>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "appointment_types" */
export type Appointment_Types_Constraint =
  /** unique or primary key constraint on columns "value" */
  'appointment_types_pkey';

export type Appointment_Types_Enum =
  /** Appointment created by medical assistant */
  | 'CALLIN'
  /** Appointment created from brave care app */
  | 'MOBILE'
  /** Appointment walked in */
  | 'WALKIN'
  /** Appointment created on web */
  | 'WEB';

/** Boolean expression to compare columns of type "appointment_types_enum". All fields are combined with logical 'AND'. */
export type Appointment_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Appointment_Types_Enum>;
  _in?: InputMaybe<Array<Appointment_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Appointment_Types_Enum>;
  _nin?: InputMaybe<Array<Appointment_Types_Enum>>;
};

/** input type for inserting data into table "appointment_types" */
export type Appointment_Types_Insert_Input = {
  appointments?: InputMaybe<Appointments_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Appointment_Types_Max_Fields = {
  __typename?: 'appointment_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Appointment_Types_Min_Fields = {
  __typename?: 'appointment_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "appointment_types" */
export type Appointment_Types_Mutation_Response = {
  __typename?: 'appointment_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Appointment_Types>;
};

/** input type for inserting object relation for remote table "appointment_types" */
export type Appointment_Types_Obj_Rel_Insert_Input = {
  data: Appointment_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Appointment_Types_On_Conflict>;
};

/** on_conflict condition type for table "appointment_types" */
export type Appointment_Types_On_Conflict = {
  constraint: Appointment_Types_Constraint;
  update_columns?: Array<Appointment_Types_Update_Column>;
  where?: InputMaybe<Appointment_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "appointment_types". */
export type Appointment_Types_Order_By = {
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: appointment_types */
export type Appointment_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "appointment_types" */
export type Appointment_Types_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "appointment_types" */
export type Appointment_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "appointment_types" */
export type Appointment_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Appointment_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Appointment_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "appointment_types" */
export type Appointment_Types_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Appointment_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Appointment_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Appointment_Types_Bool_Exp;
};

/** columns and relationships of "appointments" */
export type Appointments = {
  __typename?: 'appointments';
  /** An object relationship */
  account?: Maybe<Account_Patient>;
  accountId?: Maybe<Scalars['uuid']>;
  address?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  appointment_patients: Array<Appointment_Patient>;
  /** An aggregate relationship */
  appointment_patients_aggregate: Appointment_Patient_Aggregate;
  /** An object relationship */
  appointment_payment?: Maybe<Appointment_Payment>;
  /** An array relationship */
  appointment_staffs: Array<Appointment_Staff>;
  /** An aggregate relationship */
  appointment_staffs_aggregate: Appointment_Staff_Aggregate;
  /** An object relationship */
  appointment_status?: Maybe<Appointment_Status>;
  /** An object relationship */
  appointment_type?: Maybe<Appointment_Types>;
  campaign?: Maybe<Scalars['String']>;
  careType?: Maybe<Care_Types_Enum>;
  /** An array relationship */
  charges: Array<Charges>;
  /** An aggregate relationship */
  charges_aggregate: Charges_Aggregate;
  /** An object relationship */
  clinic: Clinics;
  clinicId: Scalars['uuid'];
  /** An array relationship */
  communication_histories: Array<Communication_History>;
  /** An aggregate relationship */
  communication_histories_aggregate: Communication_History_Aggregate;
  createdAt: Scalars['timestamptz'];
  createdByUserId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  discovered_froms: Array<Discovered_From>;
  /** An aggregate relationship */
  discovered_froms_aggregate: Discovered_From_Aggregate;
  /** An array relationship */
  documents: Array<Documents>;
  /** An aggregate relationship */
  documents_aggregate: Documents_Aggregate;
  email?: Maybe<Scalars['String']>;
  endTime: Scalars['timestamp'];
  firstName?: Maybe<Scalars['String']>;
  /** An array relationship */
  global_notes: Array<Global_Notes>;
  /** An aggregate relationship */
  global_notes_aggregate: Global_Notes_Aggregate;
  id: Scalars['uuid'];
  isMobileCheckIn?: Maybe<Scalars['Boolean']>;
  kareo_appointment_id?: Maybe<Scalars['String']>;
  kareo_appointment_uuid?: Maybe<Scalars['String']>;
  languageSelection?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  patient?: Maybe<Patients>;
  patientId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  patients: Array<Account_Patient>;
  /** An aggregate relationship */
  patients_aggregate: Account_Patient_Aggregate;
  payment?: Maybe<Appointment_Payment_Enum>;
  phoneNumber?: Maybe<Scalars['String']>;
  /** An object relationship */
  primaryStaff?: Maybe<Staff>;
  primaryStaffId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  problems: Array<Problems>;
  /** An aggregate relationship */
  problems_aggregate: Problems_Aggregate;
  reason: Scalars['String'];
  /** An array relationship */
  reviews: Array<Reviews>;
  /** An aggregate relationship */
  reviews_aggregate: Reviews_Aggregate;
  /** An array relationship */
  rooms: Array<Rooms>;
  /** An aggregate relationship */
  rooms_aggregate: Rooms_Aggregate;
  safetyQuestionnaire?: Maybe<Scalars['jsonb']>;
  sessionId?: Maybe<Scalars['uuid']>;
  shortCode?: Maybe<Scalars['String']>;
  /** An object relationship */
  staff?: Maybe<Staff>;
  startTime: Scalars['timestamp'];
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Appointment_Status_Enum>;
  stripeCardId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  symptomCheckerId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  symptom_checker?: Maybe<Symptom_Checker>;
  /** An array relationship */
  tasks_notifications: Array<Tasks_Notifications>;
  /** An aggregate relationship */
  tasks_notifications_aggregate: Tasks_Notifications_Aggregate;
  timeZone?: Maybe<Scalars['String']>;
  type?: Maybe<Appointment_Types_Enum>;
  updatedAt: Scalars['timestamptz'];
  updatedByStaffId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<Users>;
  visitClassification?: Maybe<Visit_Classifications_Enum>;
  visitType?: Maybe<Visit_Types_Enum>;
  /** An object relationship */
  visit_classification?: Maybe<Visit_Classifications>;
  /** An object relationship */
  visit_type?: Maybe<Visit_Types>;
  zoomMeetingId?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "appointments" */
export type AppointmentsAddressArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "appointments" */
export type AppointmentsAppointment_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Patient_Order_By>>;
  where?: InputMaybe<Appointment_Patient_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsAppointment_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Patient_Order_By>>;
  where?: InputMaybe<Appointment_Patient_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsAppointment_StaffsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Staff_Order_By>>;
  where?: InputMaybe<Appointment_Staff_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsAppointment_Staffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Staff_Order_By>>;
  where?: InputMaybe<Appointment_Staff_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsChargesArgs = {
  distinct_on?: InputMaybe<Array<Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charges_Order_By>>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsCharges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charges_Order_By>>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsCommunication_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Order_By>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsCommunication_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Order_By>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsDiscovered_FromsArgs = {
  distinct_on?: InputMaybe<Array<Discovered_From_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Discovered_From_Order_By>>;
  where?: InputMaybe<Discovered_From_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsDiscovered_Froms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Discovered_From_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Discovered_From_Order_By>>;
  where?: InputMaybe<Discovered_From_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsGlobal_NotesArgs = {
  distinct_on?: InputMaybe<Array<Global_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Notes_Order_By>>;
  where?: InputMaybe<Global_Notes_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsGlobal_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Notes_Order_By>>;
  where?: InputMaybe<Global_Notes_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsPatientsArgs = {
  distinct_on?: InputMaybe<Array<Account_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Patient_Order_By>>;
  where?: InputMaybe<Account_Patient_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsPatients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Patient_Order_By>>;
  where?: InputMaybe<Account_Patient_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsProblemsArgs = {
  distinct_on?: InputMaybe<Array<Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Problems_Order_By>>;
  where?: InputMaybe<Problems_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsProblems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Problems_Order_By>>;
  where?: InputMaybe<Problems_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsRoomsArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsRooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsSafetyQuestionnaireArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "appointments" */
export type AppointmentsTasks_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

/** columns and relationships of "appointments" */
export type AppointmentsTasks_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

/** aggregated selection of "appointments" */
export type Appointments_Aggregate = {
  __typename?: 'appointments_aggregate';
  aggregate?: Maybe<Appointments_Aggregate_Fields>;
  nodes: Array<Appointments>;
};

export type Appointments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Appointments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Appointments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Appointments_Aggregate_Bool_Exp_Count>;
};

export type Appointments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Appointments_Select_Column_Appointments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Appointments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Appointments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Appointments_Select_Column_Appointments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Appointments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Appointments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Appointments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Appointments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "appointments" */
export type Appointments_Aggregate_Fields = {
  __typename?: 'appointments_aggregate_fields';
  avg?: Maybe<Appointments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Appointments_Max_Fields>;
  min?: Maybe<Appointments_Min_Fields>;
  stddev?: Maybe<Appointments_Stddev_Fields>;
  stddev_pop?: Maybe<Appointments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Appointments_Stddev_Samp_Fields>;
  sum?: Maybe<Appointments_Sum_Fields>;
  var_pop?: Maybe<Appointments_Var_Pop_Fields>;
  var_samp?: Maybe<Appointments_Var_Samp_Fields>;
  variance?: Maybe<Appointments_Variance_Fields>;
};

/** aggregate fields of "appointments" */
export type Appointments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Appointments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "appointments" */
export type Appointments_Aggregate_Order_By = {
  avg?: InputMaybe<Appointments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Appointments_Max_Order_By>;
  min?: InputMaybe<Appointments_Min_Order_By>;
  stddev?: InputMaybe<Appointments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Appointments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Appointments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Appointments_Sum_Order_By>;
  var_pop?: InputMaybe<Appointments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Appointments_Var_Samp_Order_By>;
  variance?: InputMaybe<Appointments_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Appointments_Append_Input = {
  address?: InputMaybe<Scalars['jsonb']>;
  safetyQuestionnaire?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "appointments" */
export type Appointments_Arr_Rel_Insert_Input = {
  data: Array<Appointments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Appointments_On_Conflict>;
};

/** aggregate avg on columns */
export type Appointments_Avg_Fields = {
  __typename?: 'appointments_avg_fields';
  zoomMeetingId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "appointments" */
export type Appointments_Avg_Order_By = {
  zoomMeetingId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "appointments". All fields are combined with a logical 'AND'. */
export type Appointments_Bool_Exp = {
  _and?: InputMaybe<Array<Appointments_Bool_Exp>>;
  _not?: InputMaybe<Appointments_Bool_Exp>;
  _or?: InputMaybe<Array<Appointments_Bool_Exp>>;
  account?: InputMaybe<Account_Patient_Bool_Exp>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  address?: InputMaybe<Jsonb_Comparison_Exp>;
  appointment_patients?: InputMaybe<Appointment_Patient_Bool_Exp>;
  appointment_patients_aggregate?: InputMaybe<Appointment_Patient_Aggregate_Bool_Exp>;
  appointment_payment?: InputMaybe<Appointment_Payment_Bool_Exp>;
  appointment_staffs?: InputMaybe<Appointment_Staff_Bool_Exp>;
  appointment_staffs_aggregate?: InputMaybe<Appointment_Staff_Aggregate_Bool_Exp>;
  appointment_status?: InputMaybe<Appointment_Status_Bool_Exp>;
  appointment_type?: InputMaybe<Appointment_Types_Bool_Exp>;
  campaign?: InputMaybe<String_Comparison_Exp>;
  careType?: InputMaybe<Care_Types_Enum_Comparison_Exp>;
  charges?: InputMaybe<Charges_Bool_Exp>;
  charges_aggregate?: InputMaybe<Charges_Aggregate_Bool_Exp>;
  clinic?: InputMaybe<Clinics_Bool_Exp>;
  clinicId?: InputMaybe<Uuid_Comparison_Exp>;
  communication_histories?: InputMaybe<Communication_History_Bool_Exp>;
  communication_histories_aggregate?: InputMaybe<Communication_History_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdByUserId?: InputMaybe<Uuid_Comparison_Exp>;
  discovered_froms?: InputMaybe<Discovered_From_Bool_Exp>;
  discovered_froms_aggregate?: InputMaybe<Discovered_From_Aggregate_Bool_Exp>;
  documents?: InputMaybe<Documents_Bool_Exp>;
  documents_aggregate?: InputMaybe<Documents_Aggregate_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  endTime?: InputMaybe<Timestamp_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  global_notes?: InputMaybe<Global_Notes_Bool_Exp>;
  global_notes_aggregate?: InputMaybe<Global_Notes_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isMobileCheckIn?: InputMaybe<Boolean_Comparison_Exp>;
  kareo_appointment_id?: InputMaybe<String_Comparison_Exp>;
  kareo_appointment_uuid?: InputMaybe<String_Comparison_Exp>;
  languageSelection?: InputMaybe<String_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  patients?: InputMaybe<Account_Patient_Bool_Exp>;
  patients_aggregate?: InputMaybe<Account_Patient_Aggregate_Bool_Exp>;
  payment?: InputMaybe<Appointment_Payment_Enum_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  primaryStaff?: InputMaybe<Staff_Bool_Exp>;
  primaryStaffId?: InputMaybe<Uuid_Comparison_Exp>;
  problems?: InputMaybe<Problems_Bool_Exp>;
  problems_aggregate?: InputMaybe<Problems_Aggregate_Bool_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  reviews?: InputMaybe<Reviews_Bool_Exp>;
  reviews_aggregate?: InputMaybe<Reviews_Aggregate_Bool_Exp>;
  rooms?: InputMaybe<Rooms_Bool_Exp>;
  rooms_aggregate?: InputMaybe<Rooms_Aggregate_Bool_Exp>;
  safetyQuestionnaire?: InputMaybe<Jsonb_Comparison_Exp>;
  sessionId?: InputMaybe<Uuid_Comparison_Exp>;
  shortCode?: InputMaybe<String_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  startTime?: InputMaybe<Timestamp_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Appointment_Status_Enum_Comparison_Exp>;
  stripeCardId?: InputMaybe<String_Comparison_Exp>;
  stripeCustomerId?: InputMaybe<String_Comparison_Exp>;
  symptomCheckerId?: InputMaybe<Uuid_Comparison_Exp>;
  symptom_checker?: InputMaybe<Symptom_Checker_Bool_Exp>;
  tasks_notifications?: InputMaybe<Tasks_Notifications_Bool_Exp>;
  tasks_notifications_aggregate?: InputMaybe<Tasks_Notifications_Aggregate_Bool_Exp>;
  timeZone?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Appointment_Types_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedByStaffId?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  visitClassification?: InputMaybe<Visit_Classifications_Enum_Comparison_Exp>;
  visitType?: InputMaybe<Visit_Types_Enum_Comparison_Exp>;
  visit_classification?: InputMaybe<Visit_Classifications_Bool_Exp>;
  visit_type?: InputMaybe<Visit_Types_Bool_Exp>;
  zoomMeetingId?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "appointments" */
export type Appointments_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'appointments_pkey'
  /** unique or primary key constraint on columns "shortCode" */
  | 'appointments_shortCode_key';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Appointments_Delete_At_Path_Input = {
  address?: InputMaybe<Array<Scalars['String']>>;
  safetyQuestionnaire?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Appointments_Delete_Elem_Input = {
  address?: InputMaybe<Scalars['Int']>;
  safetyQuestionnaire?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Appointments_Delete_Key_Input = {
  address?: InputMaybe<Scalars['String']>;
  safetyQuestionnaire?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "appointments" */
export type Appointments_Inc_Input = {
  zoomMeetingId?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "appointments" */
export type Appointments_Insert_Input = {
  account?: InputMaybe<Account_Patient_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['uuid']>;
  address?: InputMaybe<Scalars['jsonb']>;
  appointment_patients?: InputMaybe<Appointment_Patient_Arr_Rel_Insert_Input>;
  appointment_payment?: InputMaybe<Appointment_Payment_Obj_Rel_Insert_Input>;
  appointment_staffs?: InputMaybe<Appointment_Staff_Arr_Rel_Insert_Input>;
  appointment_status?: InputMaybe<Appointment_Status_Obj_Rel_Insert_Input>;
  appointment_type?: InputMaybe<Appointment_Types_Obj_Rel_Insert_Input>;
  campaign?: InputMaybe<Scalars['String']>;
  careType?: InputMaybe<Care_Types_Enum>;
  charges?: InputMaybe<Charges_Arr_Rel_Insert_Input>;
  clinic?: InputMaybe<Clinics_Obj_Rel_Insert_Input>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  communication_histories?: InputMaybe<Communication_History_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  discovered_froms?: InputMaybe<Discovered_From_Arr_Rel_Insert_Input>;
  documents?: InputMaybe<Documents_Arr_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['timestamp']>;
  firstName?: InputMaybe<Scalars['String']>;
  global_notes?: InputMaybe<Global_Notes_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  isMobileCheckIn?: InputMaybe<Scalars['Boolean']>;
  kareo_appointment_id?: InputMaybe<Scalars['String']>;
  kareo_appointment_uuid?: InputMaybe<Scalars['String']>;
  languageSelection?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patients?: InputMaybe<Account_Patient_Arr_Rel_Insert_Input>;
  payment?: InputMaybe<Appointment_Payment_Enum>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  primaryStaff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  primaryStaffId?: InputMaybe<Scalars['uuid']>;
  problems?: InputMaybe<Problems_Arr_Rel_Insert_Input>;
  reason?: InputMaybe<Scalars['String']>;
  reviews?: InputMaybe<Reviews_Arr_Rel_Insert_Input>;
  rooms?: InputMaybe<Rooms_Arr_Rel_Insert_Input>;
  safetyQuestionnaire?: InputMaybe<Scalars['jsonb']>;
  sessionId?: InputMaybe<Scalars['uuid']>;
  shortCode?: InputMaybe<Scalars['String']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Appointment_Status_Enum>;
  stripeCardId?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  symptomCheckerId?: InputMaybe<Scalars['uuid']>;
  symptom_checker?: InputMaybe<Symptom_Checker_Obj_Rel_Insert_Input>;
  tasks_notifications?: InputMaybe<Tasks_Notifications_Arr_Rel_Insert_Input>;
  timeZone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Appointment_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedByStaffId?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  visitClassification?: InputMaybe<Visit_Classifications_Enum>;
  visitType?: InputMaybe<Visit_Types_Enum>;
  visit_classification?: InputMaybe<Visit_Classifications_Obj_Rel_Insert_Input>;
  visit_type?: InputMaybe<Visit_Types_Obj_Rel_Insert_Input>;
  zoomMeetingId?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Appointments_Max_Fields = {
  __typename?: 'appointments_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  campaign?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['timestamp']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  kareo_appointment_id?: Maybe<Scalars['String']>;
  kareo_appointment_uuid?: Maybe<Scalars['String']>;
  languageSelection?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  phoneNumber?: Maybe<Scalars['String']>;
  primaryStaffId?: Maybe<Scalars['uuid']>;
  reason?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['uuid']>;
  shortCode?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['timestamp']>;
  state?: Maybe<Scalars['String']>;
  stripeCardId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  symptomCheckerId?: Maybe<Scalars['uuid']>;
  timeZone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedByStaffId?: Maybe<Scalars['uuid']>;
  zoomMeetingId?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "appointments" */
export type Appointments_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  campaign?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdByUserId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  endTime?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kareo_appointment_id?: InputMaybe<Order_By>;
  kareo_appointment_uuid?: InputMaybe<Order_By>;
  languageSelection?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  primaryStaffId?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  sessionId?: InputMaybe<Order_By>;
  shortCode?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  stripeCardId?: InputMaybe<Order_By>;
  stripeCustomerId?: InputMaybe<Order_By>;
  symptomCheckerId?: InputMaybe<Order_By>;
  timeZone?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedByStaffId?: InputMaybe<Order_By>;
  zoomMeetingId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Appointments_Min_Fields = {
  __typename?: 'appointments_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  campaign?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['timestamp']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  kareo_appointment_id?: Maybe<Scalars['String']>;
  kareo_appointment_uuid?: Maybe<Scalars['String']>;
  languageSelection?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  phoneNumber?: Maybe<Scalars['String']>;
  primaryStaffId?: Maybe<Scalars['uuid']>;
  reason?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['uuid']>;
  shortCode?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['timestamp']>;
  state?: Maybe<Scalars['String']>;
  stripeCardId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  symptomCheckerId?: Maybe<Scalars['uuid']>;
  timeZone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedByStaffId?: Maybe<Scalars['uuid']>;
  zoomMeetingId?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "appointments" */
export type Appointments_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  campaign?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdByUserId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  endTime?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kareo_appointment_id?: InputMaybe<Order_By>;
  kareo_appointment_uuid?: InputMaybe<Order_By>;
  languageSelection?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  primaryStaffId?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  sessionId?: InputMaybe<Order_By>;
  shortCode?: InputMaybe<Order_By>;
  startTime?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  stripeCardId?: InputMaybe<Order_By>;
  stripeCustomerId?: InputMaybe<Order_By>;
  symptomCheckerId?: InputMaybe<Order_By>;
  timeZone?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedByStaffId?: InputMaybe<Order_By>;
  zoomMeetingId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "appointments" */
export type Appointments_Mutation_Response = {
  __typename?: 'appointments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Appointments>;
};

/** input type for inserting object relation for remote table "appointments" */
export type Appointments_Obj_Rel_Insert_Input = {
  data: Appointments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Appointments_On_Conflict>;
};

/** on_conflict condition type for table "appointments" */
export type Appointments_On_Conflict = {
  constraint: Appointments_Constraint;
  update_columns?: Array<Appointments_Update_Column>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** Ordering options when selecting data from "appointments". */
export type Appointments_Order_By = {
  account?: InputMaybe<Account_Patient_Order_By>;
  accountId?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  appointment_patients_aggregate?: InputMaybe<Appointment_Patient_Aggregate_Order_By>;
  appointment_payment?: InputMaybe<Appointment_Payment_Order_By>;
  appointment_staffs_aggregate?: InputMaybe<Appointment_Staff_Aggregate_Order_By>;
  appointment_status?: InputMaybe<Appointment_Status_Order_By>;
  appointment_type?: InputMaybe<Appointment_Types_Order_By>;
  campaign?: InputMaybe<Order_By>;
  careType?: InputMaybe<Order_By>;
  charges_aggregate?: InputMaybe<Charges_Aggregate_Order_By>;
  clinic?: InputMaybe<Clinics_Order_By>;
  clinicId?: InputMaybe<Order_By>;
  communication_histories_aggregate?: InputMaybe<Communication_History_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdByUserId?: InputMaybe<Order_By>;
  discovered_froms_aggregate?: InputMaybe<Discovered_From_Aggregate_Order_By>;
  documents_aggregate?: InputMaybe<Documents_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  endTime?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  global_notes_aggregate?: InputMaybe<Global_Notes_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  isMobileCheckIn?: InputMaybe<Order_By>;
  kareo_appointment_id?: InputMaybe<Order_By>;
  kareo_appointment_uuid?: InputMaybe<Order_By>;
  languageSelection?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  patients_aggregate?: InputMaybe<Account_Patient_Aggregate_Order_By>;
  payment?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  primaryStaff?: InputMaybe<Staff_Order_By>;
  primaryStaffId?: InputMaybe<Order_By>;
  problems_aggregate?: InputMaybe<Problems_Aggregate_Order_By>;
  reason?: InputMaybe<Order_By>;
  reviews_aggregate?: InputMaybe<Reviews_Aggregate_Order_By>;
  rooms_aggregate?: InputMaybe<Rooms_Aggregate_Order_By>;
  safetyQuestionnaire?: InputMaybe<Order_By>;
  sessionId?: InputMaybe<Order_By>;
  shortCode?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  startTime?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  stripeCardId?: InputMaybe<Order_By>;
  stripeCustomerId?: InputMaybe<Order_By>;
  symptomCheckerId?: InputMaybe<Order_By>;
  symptom_checker?: InputMaybe<Symptom_Checker_Order_By>;
  tasks_notifications_aggregate?: InputMaybe<Tasks_Notifications_Aggregate_Order_By>;
  timeZone?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedByStaffId?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  visitClassification?: InputMaybe<Order_By>;
  visitType?: InputMaybe<Order_By>;
  visit_classification?: InputMaybe<Visit_Classifications_Order_By>;
  visit_type?: InputMaybe<Visit_Types_Order_By>;
  zoomMeetingId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: appointments */
export type Appointments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Appointments_Prepend_Input = {
  address?: InputMaybe<Scalars['jsonb']>;
  safetyQuestionnaire?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "appointments" */
export type Appointments_Select_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'address'
  /** column name */
  | 'campaign'
  /** column name */
  | 'careType'
  /** column name */
  | 'clinicId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdByUserId'
  /** column name */
  | 'email'
  /** column name */
  | 'endTime'
  /** column name */
  | 'firstName'
  /** column name */
  | 'id'
  /** column name */
  | 'isMobileCheckIn'
  /** column name */
  | 'kareo_appointment_id'
  /** column name */
  | 'kareo_appointment_uuid'
  /** column name */
  | 'languageSelection'
  /** column name */
  | 'lastName'
  /** column name */
  | 'notes'
  /** column name */
  | 'patientId'
  /** column name */
  | 'payment'
  /** column name */
  | 'phoneNumber'
  /** column name */
  | 'primaryStaffId'
  /** column name */
  | 'reason'
  /** column name */
  | 'safetyQuestionnaire'
  /** column name */
  | 'sessionId'
  /** column name */
  | 'shortCode'
  /** column name */
  | 'startTime'
  /** column name */
  | 'state'
  /** column name */
  | 'status'
  /** column name */
  | 'stripeCardId'
  /** column name */
  | 'stripeCustomerId'
  /** column name */
  | 'symptomCheckerId'
  /** column name */
  | 'timeZone'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedByStaffId'
  /** column name */
  | 'visitClassification'
  /** column name */
  | 'visitType'
  /** column name */
  | 'zoomMeetingId';

/** select "appointments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "appointments" */
export type Appointments_Select_Column_Appointments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  'isMobileCheckIn';

/** select "appointments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "appointments" */
export type Appointments_Select_Column_Appointments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  'isMobileCheckIn';

/** input type for updating data in table "appointments" */
export type Appointments_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  address?: InputMaybe<Scalars['jsonb']>;
  campaign?: InputMaybe<Scalars['String']>;
  careType?: InputMaybe<Care_Types_Enum>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['timestamp']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isMobileCheckIn?: InputMaybe<Scalars['Boolean']>;
  kareo_appointment_id?: InputMaybe<Scalars['String']>;
  kareo_appointment_uuid?: InputMaybe<Scalars['String']>;
  languageSelection?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  payment?: InputMaybe<Appointment_Payment_Enum>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  primaryStaffId?: InputMaybe<Scalars['uuid']>;
  reason?: InputMaybe<Scalars['String']>;
  safetyQuestionnaire?: InputMaybe<Scalars['jsonb']>;
  sessionId?: InputMaybe<Scalars['uuid']>;
  shortCode?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Appointment_Status_Enum>;
  stripeCardId?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  symptomCheckerId?: InputMaybe<Scalars['uuid']>;
  timeZone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Appointment_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedByStaffId?: InputMaybe<Scalars['uuid']>;
  visitClassification?: InputMaybe<Visit_Classifications_Enum>;
  visitType?: InputMaybe<Visit_Types_Enum>;
  zoomMeetingId?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Appointments_Stddev_Fields = {
  __typename?: 'appointments_stddev_fields';
  zoomMeetingId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "appointments" */
export type Appointments_Stddev_Order_By = {
  zoomMeetingId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Appointments_Stddev_Pop_Fields = {
  __typename?: 'appointments_stddev_pop_fields';
  zoomMeetingId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "appointments" */
export type Appointments_Stddev_Pop_Order_By = {
  zoomMeetingId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Appointments_Stddev_Samp_Fields = {
  __typename?: 'appointments_stddev_samp_fields';
  zoomMeetingId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "appointments" */
export type Appointments_Stddev_Samp_Order_By = {
  zoomMeetingId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "appointments" */
export type Appointments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Appointments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Appointments_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  address?: InputMaybe<Scalars['jsonb']>;
  campaign?: InputMaybe<Scalars['String']>;
  careType?: InputMaybe<Care_Types_Enum>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['timestamp']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isMobileCheckIn?: InputMaybe<Scalars['Boolean']>;
  kareo_appointment_id?: InputMaybe<Scalars['String']>;
  kareo_appointment_uuid?: InputMaybe<Scalars['String']>;
  languageSelection?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  payment?: InputMaybe<Appointment_Payment_Enum>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  primaryStaffId?: InputMaybe<Scalars['uuid']>;
  reason?: InputMaybe<Scalars['String']>;
  safetyQuestionnaire?: InputMaybe<Scalars['jsonb']>;
  sessionId?: InputMaybe<Scalars['uuid']>;
  shortCode?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Appointment_Status_Enum>;
  stripeCardId?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  symptomCheckerId?: InputMaybe<Scalars['uuid']>;
  timeZone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Appointment_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedByStaffId?: InputMaybe<Scalars['uuid']>;
  visitClassification?: InputMaybe<Visit_Classifications_Enum>;
  visitType?: InputMaybe<Visit_Types_Enum>;
  zoomMeetingId?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Appointments_Sum_Fields = {
  __typename?: 'appointments_sum_fields';
  zoomMeetingId?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "appointments" */
export type Appointments_Sum_Order_By = {
  zoomMeetingId?: InputMaybe<Order_By>;
};

/** update columns of table "appointments" */
export type Appointments_Update_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'address'
  /** column name */
  | 'campaign'
  /** column name */
  | 'careType'
  /** column name */
  | 'clinicId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdByUserId'
  /** column name */
  | 'email'
  /** column name */
  | 'endTime'
  /** column name */
  | 'firstName'
  /** column name */
  | 'id'
  /** column name */
  | 'isMobileCheckIn'
  /** column name */
  | 'kareo_appointment_id'
  /** column name */
  | 'kareo_appointment_uuid'
  /** column name */
  | 'languageSelection'
  /** column name */
  | 'lastName'
  /** column name */
  | 'notes'
  /** column name */
  | 'patientId'
  /** column name */
  | 'payment'
  /** column name */
  | 'phoneNumber'
  /** column name */
  | 'primaryStaffId'
  /** column name */
  | 'reason'
  /** column name */
  | 'safetyQuestionnaire'
  /** column name */
  | 'sessionId'
  /** column name */
  | 'shortCode'
  /** column name */
  | 'startTime'
  /** column name */
  | 'state'
  /** column name */
  | 'status'
  /** column name */
  | 'stripeCardId'
  /** column name */
  | 'stripeCustomerId'
  /** column name */
  | 'symptomCheckerId'
  /** column name */
  | 'timeZone'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedByStaffId'
  /** column name */
  | 'visitClassification'
  /** column name */
  | 'visitType'
  /** column name */
  | 'zoomMeetingId';

export type Appointments_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Appointments_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Appointments_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Appointments_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Appointments_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Appointments_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Appointments_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Appointments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Appointments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Appointments_Var_Pop_Fields = {
  __typename?: 'appointments_var_pop_fields';
  zoomMeetingId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "appointments" */
export type Appointments_Var_Pop_Order_By = {
  zoomMeetingId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Appointments_Var_Samp_Fields = {
  __typename?: 'appointments_var_samp_fields';
  zoomMeetingId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "appointments" */
export type Appointments_Var_Samp_Order_By = {
  zoomMeetingId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Appointments_Variance_Fields = {
  __typename?: 'appointments_variance_fields';
  zoomMeetingId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "appointments" */
export type Appointments_Variance_Order_By = {
  zoomMeetingId?: InputMaybe<Order_By>;
};

/** columns and relationships of "audit_logs" */
export type Audit_Logs = {
  __typename?: 'audit_logs';
  /** An object relationship */
  account?: Maybe<Accounts>;
  accountId?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  difference?: Maybe<Scalars['jsonb']>;
  from?: Maybe<Scalars['jsonb']>;
  id: Scalars['bigint'];
  medicalAssistant?: Maybe<Scalars['String']>;
  operation: Scalars['String'];
  session: Scalars['jsonb'];
  /** An object relationship */
  staff?: Maybe<Staff>;
  staffUserId?: Maybe<Scalars['uuid']>;
  table: Scalars['String'];
  to?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "audit_logs" */
export type Audit_LogsDifferenceArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "audit_logs" */
export type Audit_LogsFromArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "audit_logs" */
export type Audit_LogsSessionArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "audit_logs" */
export type Audit_LogsToArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "audit_logs" */
export type Audit_Logs_Aggregate = {
  __typename?: 'audit_logs_aggregate';
  aggregate?: Maybe<Audit_Logs_Aggregate_Fields>;
  nodes: Array<Audit_Logs>;
};

export type Audit_Logs_Aggregate_Bool_Exp = {
  count?: InputMaybe<Audit_Logs_Aggregate_Bool_Exp_Count>;
};

export type Audit_Logs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Audit_Logs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "audit_logs" */
export type Audit_Logs_Aggregate_Fields = {
  __typename?: 'audit_logs_aggregate_fields';
  avg?: Maybe<Audit_Logs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Audit_Logs_Max_Fields>;
  min?: Maybe<Audit_Logs_Min_Fields>;
  stddev?: Maybe<Audit_Logs_Stddev_Fields>;
  stddev_pop?: Maybe<Audit_Logs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Audit_Logs_Stddev_Samp_Fields>;
  sum?: Maybe<Audit_Logs_Sum_Fields>;
  var_pop?: Maybe<Audit_Logs_Var_Pop_Fields>;
  var_samp?: Maybe<Audit_Logs_Var_Samp_Fields>;
  variance?: Maybe<Audit_Logs_Variance_Fields>;
};

/** aggregate fields of "audit_logs" */
export type Audit_Logs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "audit_logs" */
export type Audit_Logs_Aggregate_Order_By = {
  avg?: InputMaybe<Audit_Logs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Audit_Logs_Max_Order_By>;
  min?: InputMaybe<Audit_Logs_Min_Order_By>;
  stddev?: InputMaybe<Audit_Logs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Audit_Logs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Audit_Logs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Audit_Logs_Sum_Order_By>;
  var_pop?: InputMaybe<Audit_Logs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Audit_Logs_Var_Samp_Order_By>;
  variance?: InputMaybe<Audit_Logs_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logs_Append_Input = {
  difference?: InputMaybe<Scalars['jsonb']>;
  from?: InputMaybe<Scalars['jsonb']>;
  session?: InputMaybe<Scalars['jsonb']>;
  to?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "audit_logs" */
export type Audit_Logs_Arr_Rel_Insert_Input = {
  data: Array<Audit_Logs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Audit_Logs_On_Conflict>;
};

/** aggregate avg on columns */
export type Audit_Logs_Avg_Fields = {
  __typename?: 'audit_logs_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "audit_logs" */
export type Audit_Logs_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "audit_logs". All fields are combined with a logical 'AND'. */
export type Audit_Logs_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Logs_Bool_Exp>>;
  _not?: InputMaybe<Audit_Logs_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Logs_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  difference?: InputMaybe<Jsonb_Comparison_Exp>;
  from?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  medicalAssistant?: InputMaybe<String_Comparison_Exp>;
  operation?: InputMaybe<String_Comparison_Exp>;
  session?: InputMaybe<Jsonb_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffUserId?: InputMaybe<Uuid_Comparison_Exp>;
  table?: InputMaybe<String_Comparison_Exp>;
  to?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "audit_logs" */
export type Audit_Logs_Constraint =
  /** unique or primary key constraint on columns "id" */
  'audit_logs_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Audit_Logs_Delete_At_Path_Input = {
  difference?: InputMaybe<Array<Scalars['String']>>;
  from?: InputMaybe<Array<Scalars['String']>>;
  session?: InputMaybe<Array<Scalars['String']>>;
  to?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Audit_Logs_Delete_Elem_Input = {
  difference?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['Int']>;
  session?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Audit_Logs_Delete_Key_Input = {
  difference?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "audit_logs" */
export type Audit_Logs_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "audit_logs" */
export type Audit_Logs_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  difference?: InputMaybe<Scalars['jsonb']>;
  from?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  medicalAssistant?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<Scalars['jsonb']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffUserId?: InputMaybe<Scalars['uuid']>;
  table?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Audit_Logs_Max_Fields = {
  __typename?: 'audit_logs_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  medicalAssistant?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  staffUserId?: Maybe<Scalars['uuid']>;
  table?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "audit_logs" */
export type Audit_Logs_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medicalAssistant?: InputMaybe<Order_By>;
  operation?: InputMaybe<Order_By>;
  staffUserId?: InputMaybe<Order_By>;
  table?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Audit_Logs_Min_Fields = {
  __typename?: 'audit_logs_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  medicalAssistant?: Maybe<Scalars['String']>;
  operation?: Maybe<Scalars['String']>;
  staffUserId?: Maybe<Scalars['uuid']>;
  table?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "audit_logs" */
export type Audit_Logs_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medicalAssistant?: InputMaybe<Order_By>;
  operation?: InputMaybe<Order_By>;
  staffUserId?: InputMaybe<Order_By>;
  table?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "audit_logs" */
export type Audit_Logs_Mutation_Response = {
  __typename?: 'audit_logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audit_Logs>;
};

/** on_conflict condition type for table "audit_logs" */
export type Audit_Logs_On_Conflict = {
  constraint: Audit_Logs_Constraint;
  update_columns?: Array<Audit_Logs_Update_Column>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

/** Ordering options when selecting data from "audit_logs". */
export type Audit_Logs_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  difference?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medicalAssistant?: InputMaybe<Order_By>;
  operation?: InputMaybe<Order_By>;
  session?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffUserId?: InputMaybe<Order_By>;
  table?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: audit_logs */
export type Audit_Logs_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logs_Prepend_Input = {
  difference?: InputMaybe<Scalars['jsonb']>;
  from?: InputMaybe<Scalars['jsonb']>;
  session?: InputMaybe<Scalars['jsonb']>;
  to?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "audit_logs" */
export type Audit_Logs_Select_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'difference'
  /** column name */
  | 'from'
  /** column name */
  | 'id'
  /** column name */
  | 'medicalAssistant'
  /** column name */
  | 'operation'
  /** column name */
  | 'session'
  /** column name */
  | 'staffUserId'
  /** column name */
  | 'table'
  /** column name */
  | 'to'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "audit_logs" */
export type Audit_Logs_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  difference?: InputMaybe<Scalars['jsonb']>;
  from?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  medicalAssistant?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<Scalars['jsonb']>;
  staffUserId?: InputMaybe<Scalars['uuid']>;
  table?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Audit_Logs_Stddev_Fields = {
  __typename?: 'audit_logs_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "audit_logs" */
export type Audit_Logs_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Audit_Logs_Stddev_Pop_Fields = {
  __typename?: 'audit_logs_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "audit_logs" */
export type Audit_Logs_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Audit_Logs_Stddev_Samp_Fields = {
  __typename?: 'audit_logs_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "audit_logs" */
export type Audit_Logs_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "audit_logs" */
export type Audit_Logs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Logs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Logs_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  difference?: InputMaybe<Scalars['jsonb']>;
  from?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['bigint']>;
  medicalAssistant?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<Scalars['jsonb']>;
  staffUserId?: InputMaybe<Scalars['uuid']>;
  table?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Audit_Logs_Sum_Fields = {
  __typename?: 'audit_logs_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "audit_logs" */
export type Audit_Logs_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "audit_logs" */
export type Audit_Logs_Update_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'difference'
  /** column name */
  | 'from'
  /** column name */
  | 'id'
  /** column name */
  | 'medicalAssistant'
  /** column name */
  | 'operation'
  /** column name */
  | 'session'
  /** column name */
  | 'staffUserId'
  /** column name */
  | 'table'
  /** column name */
  | 'to'
  /** column name */
  | 'updated_at';

export type Audit_Logs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Audit_Logs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Audit_Logs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Audit_Logs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Audit_Logs_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Audit_Logs_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Audit_Logs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Audit_Logs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Audit_Logs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Audit_Logs_Var_Pop_Fields = {
  __typename?: 'audit_logs_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "audit_logs" */
export type Audit_Logs_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Audit_Logs_Var_Samp_Fields = {
  __typename?: 'audit_logs_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "audit_logs" */
export type Audit_Logs_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Audit_Logs_Variance_Fields = {
  __typename?: 'audit_logs_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "audit_logs" */
export type Audit_Logs_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Duration for each care type, setting based on clinics. */
export type Care_Type_Duration = {
  __typename?: 'care_type_duration';
  careType: Care_Types_Enum;
  /** An object relationship */
  care_type: Care_Types;
  /** An object relationship */
  clinic: Clinics;
  clinicId: Scalars['uuid'];
  durationInMinutes: Scalars['Int'];
};

/** aggregated selection of "care_type_duration" */
export type Care_Type_Duration_Aggregate = {
  __typename?: 'care_type_duration_aggregate';
  aggregate?: Maybe<Care_Type_Duration_Aggregate_Fields>;
  nodes: Array<Care_Type_Duration>;
};

export type Care_Type_Duration_Aggregate_Bool_Exp = {
  count?: InputMaybe<Care_Type_Duration_Aggregate_Bool_Exp_Count>;
};

export type Care_Type_Duration_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Care_Type_Duration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Care_Type_Duration_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "care_type_duration" */
export type Care_Type_Duration_Aggregate_Fields = {
  __typename?: 'care_type_duration_aggregate_fields';
  avg?: Maybe<Care_Type_Duration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Care_Type_Duration_Max_Fields>;
  min?: Maybe<Care_Type_Duration_Min_Fields>;
  stddev?: Maybe<Care_Type_Duration_Stddev_Fields>;
  stddev_pop?: Maybe<Care_Type_Duration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Care_Type_Duration_Stddev_Samp_Fields>;
  sum?: Maybe<Care_Type_Duration_Sum_Fields>;
  var_pop?: Maybe<Care_Type_Duration_Var_Pop_Fields>;
  var_samp?: Maybe<Care_Type_Duration_Var_Samp_Fields>;
  variance?: Maybe<Care_Type_Duration_Variance_Fields>;
};

/** aggregate fields of "care_type_duration" */
export type Care_Type_Duration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Care_Type_Duration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "care_type_duration" */
export type Care_Type_Duration_Aggregate_Order_By = {
  avg?: InputMaybe<Care_Type_Duration_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Care_Type_Duration_Max_Order_By>;
  min?: InputMaybe<Care_Type_Duration_Min_Order_By>;
  stddev?: InputMaybe<Care_Type_Duration_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Care_Type_Duration_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Care_Type_Duration_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Care_Type_Duration_Sum_Order_By>;
  var_pop?: InputMaybe<Care_Type_Duration_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Care_Type_Duration_Var_Samp_Order_By>;
  variance?: InputMaybe<Care_Type_Duration_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "care_type_duration" */
export type Care_Type_Duration_Arr_Rel_Insert_Input = {
  data: Array<Care_Type_Duration_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Care_Type_Duration_On_Conflict>;
};

/** aggregate avg on columns */
export type Care_Type_Duration_Avg_Fields = {
  __typename?: 'care_type_duration_avg_fields';
  durationInMinutes?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "care_type_duration" */
export type Care_Type_Duration_Avg_Order_By = {
  durationInMinutes?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "care_type_duration". All fields are combined with a logical 'AND'. */
export type Care_Type_Duration_Bool_Exp = {
  _and?: InputMaybe<Array<Care_Type_Duration_Bool_Exp>>;
  _not?: InputMaybe<Care_Type_Duration_Bool_Exp>;
  _or?: InputMaybe<Array<Care_Type_Duration_Bool_Exp>>;
  careType?: InputMaybe<Care_Types_Enum_Comparison_Exp>;
  care_type?: InputMaybe<Care_Types_Bool_Exp>;
  clinic?: InputMaybe<Clinics_Bool_Exp>;
  clinicId?: InputMaybe<Uuid_Comparison_Exp>;
  durationInMinutes?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "care_type_duration" */
export type Care_Type_Duration_Constraint =
  /** unique or primary key constraint on columns "clinicId", "careType" */
  'care_type_duration_pkey';

/** input type for incrementing numeric columns in table "care_type_duration" */
export type Care_Type_Duration_Inc_Input = {
  durationInMinutes?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "care_type_duration" */
export type Care_Type_Duration_Insert_Input = {
  careType?: InputMaybe<Care_Types_Enum>;
  care_type?: InputMaybe<Care_Types_Obj_Rel_Insert_Input>;
  clinic?: InputMaybe<Clinics_Obj_Rel_Insert_Input>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  durationInMinutes?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Care_Type_Duration_Max_Fields = {
  __typename?: 'care_type_duration_max_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "care_type_duration" */
export type Care_Type_Duration_Max_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  durationInMinutes?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Care_Type_Duration_Min_Fields = {
  __typename?: 'care_type_duration_min_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "care_type_duration" */
export type Care_Type_Duration_Min_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  durationInMinutes?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "care_type_duration" */
export type Care_Type_Duration_Mutation_Response = {
  __typename?: 'care_type_duration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Care_Type_Duration>;
};

/** on_conflict condition type for table "care_type_duration" */
export type Care_Type_Duration_On_Conflict = {
  constraint: Care_Type_Duration_Constraint;
  update_columns?: Array<Care_Type_Duration_Update_Column>;
  where?: InputMaybe<Care_Type_Duration_Bool_Exp>;
};

/** Ordering options when selecting data from "care_type_duration". */
export type Care_Type_Duration_Order_By = {
  careType?: InputMaybe<Order_By>;
  care_type?: InputMaybe<Care_Types_Order_By>;
  clinic?: InputMaybe<Clinics_Order_By>;
  clinicId?: InputMaybe<Order_By>;
  durationInMinutes?: InputMaybe<Order_By>;
};

/** primary key columns input for table: care_type_duration */
export type Care_Type_Duration_Pk_Columns_Input = {
  careType: Care_Types_Enum;
  clinicId: Scalars['uuid'];
};

/** select columns of table "care_type_duration" */
export type Care_Type_Duration_Select_Column =
  /** column name */
  | 'careType'
  /** column name */
  | 'clinicId'
  /** column name */
  | 'durationInMinutes';

/** input type for updating data in table "care_type_duration" */
export type Care_Type_Duration_Set_Input = {
  careType?: InputMaybe<Care_Types_Enum>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  durationInMinutes?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Care_Type_Duration_Stddev_Fields = {
  __typename?: 'care_type_duration_stddev_fields';
  durationInMinutes?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "care_type_duration" */
export type Care_Type_Duration_Stddev_Order_By = {
  durationInMinutes?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Care_Type_Duration_Stddev_Pop_Fields = {
  __typename?: 'care_type_duration_stddev_pop_fields';
  durationInMinutes?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "care_type_duration" */
export type Care_Type_Duration_Stddev_Pop_Order_By = {
  durationInMinutes?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Care_Type_Duration_Stddev_Samp_Fields = {
  __typename?: 'care_type_duration_stddev_samp_fields';
  durationInMinutes?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "care_type_duration" */
export type Care_Type_Duration_Stddev_Samp_Order_By = {
  durationInMinutes?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "care_type_duration" */
export type Care_Type_Duration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Care_Type_Duration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Care_Type_Duration_Stream_Cursor_Value_Input = {
  careType?: InputMaybe<Care_Types_Enum>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  durationInMinutes?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Care_Type_Duration_Sum_Fields = {
  __typename?: 'care_type_duration_sum_fields';
  durationInMinutes?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "care_type_duration" */
export type Care_Type_Duration_Sum_Order_By = {
  durationInMinutes?: InputMaybe<Order_By>;
};

/** update columns of table "care_type_duration" */
export type Care_Type_Duration_Update_Column =
  /** column name */
  | 'careType'
  /** column name */
  | 'clinicId'
  /** column name */
  | 'durationInMinutes';

export type Care_Type_Duration_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Care_Type_Duration_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Care_Type_Duration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Care_Type_Duration_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Care_Type_Duration_Var_Pop_Fields = {
  __typename?: 'care_type_duration_var_pop_fields';
  durationInMinutes?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "care_type_duration" */
export type Care_Type_Duration_Var_Pop_Order_By = {
  durationInMinutes?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Care_Type_Duration_Var_Samp_Fields = {
  __typename?: 'care_type_duration_var_samp_fields';
  durationInMinutes?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "care_type_duration" */
export type Care_Type_Duration_Var_Samp_Order_By = {
  durationInMinutes?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Care_Type_Duration_Variance_Fields = {
  __typename?: 'care_type_duration_variance_fields';
  durationInMinutes?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "care_type_duration" */
export type Care_Type_Duration_Variance_Order_By = {
  durationInMinutes?: InputMaybe<Order_By>;
};

/** columns and relationships of "care_types" */
export type Care_Types = {
  __typename?: 'care_types';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "care_types" */
export type Care_Types_Aggregate = {
  __typename?: 'care_types_aggregate';
  aggregate?: Maybe<Care_Types_Aggregate_Fields>;
  nodes: Array<Care_Types>;
};

/** aggregate fields of "care_types" */
export type Care_Types_Aggregate_Fields = {
  __typename?: 'care_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Care_Types_Max_Fields>;
  min?: Maybe<Care_Types_Min_Fields>;
};

/** aggregate fields of "care_types" */
export type Care_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Care_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "care_types". All fields are combined with a logical 'AND'. */
export type Care_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Care_Types_Bool_Exp>>;
  _not?: InputMaybe<Care_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Care_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "care_types" */
export type Care_Types_Constraint =
  /** unique or primary key constraint on columns "value" */
  'care_types_pkey';

export type Care_Types_Enum =
  /** Check Up */
  | 'CHECK_UP'
  /** COVID-19 Test */
  | 'COVID_19_TEST'
  /** Meet and Greet */
  | 'MEET_AND_GREET'
  /** Office Visit */
  | 'OFFICE_VISIT'
  /** Sports Physical */
  | 'SPORTS_PHYSICAL'
  /** Telemedicine */
  | 'TELEMEDICINE'
  /** Urgent Care */
  | 'URGENT_CARE'
  /** Vaccination */
  | 'VACCINATION';

/** Boolean expression to compare columns of type "care_types_enum". All fields are combined with logical 'AND'. */
export type Care_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Care_Types_Enum>;
  _in?: InputMaybe<Array<Care_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Care_Types_Enum>;
  _nin?: InputMaybe<Array<Care_Types_Enum>>;
};

/** input type for inserting data into table "care_types" */
export type Care_Types_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Care_Types_Max_Fields = {
  __typename?: 'care_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Care_Types_Min_Fields = {
  __typename?: 'care_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "care_types" */
export type Care_Types_Mutation_Response = {
  __typename?: 'care_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Care_Types>;
};

/** input type for inserting object relation for remote table "care_types" */
export type Care_Types_Obj_Rel_Insert_Input = {
  data: Care_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Care_Types_On_Conflict>;
};

/** on_conflict condition type for table "care_types" */
export type Care_Types_On_Conflict = {
  constraint: Care_Types_Constraint;
  update_columns?: Array<Care_Types_Update_Column>;
  where?: InputMaybe<Care_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "care_types". */
export type Care_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: care_types */
export type Care_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "care_types" */
export type Care_Types_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "care_types" */
export type Care_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "care_types" */
export type Care_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Care_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Care_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "care_types" */
export type Care_Types_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Care_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Care_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Care_Types_Bool_Exp;
};

/** columns and relationships of "charge_products" */
export type Charge_Products = {
  __typename?: 'charge_products';
  amount: Scalars['numeric'];
  /** An object relationship */
  charge: Charges;
  chargeId: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  product: Products;
  productId: Scalars['uuid'];
  quantity: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "charge_products" */
export type Charge_Products_Aggregate = {
  __typename?: 'charge_products_aggregate';
  aggregate?: Maybe<Charge_Products_Aggregate_Fields>;
  nodes: Array<Charge_Products>;
};

export type Charge_Products_Aggregate_Bool_Exp = {
  count?: InputMaybe<Charge_Products_Aggregate_Bool_Exp_Count>;
};

export type Charge_Products_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Charge_Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Charge_Products_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "charge_products" */
export type Charge_Products_Aggregate_Fields = {
  __typename?: 'charge_products_aggregate_fields';
  avg?: Maybe<Charge_Products_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Charge_Products_Max_Fields>;
  min?: Maybe<Charge_Products_Min_Fields>;
  stddev?: Maybe<Charge_Products_Stddev_Fields>;
  stddev_pop?: Maybe<Charge_Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Charge_Products_Stddev_Samp_Fields>;
  sum?: Maybe<Charge_Products_Sum_Fields>;
  var_pop?: Maybe<Charge_Products_Var_Pop_Fields>;
  var_samp?: Maybe<Charge_Products_Var_Samp_Fields>;
  variance?: Maybe<Charge_Products_Variance_Fields>;
};

/** aggregate fields of "charge_products" */
export type Charge_Products_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Charge_Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "charge_products" */
export type Charge_Products_Aggregate_Order_By = {
  avg?: InputMaybe<Charge_Products_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Charge_Products_Max_Order_By>;
  min?: InputMaybe<Charge_Products_Min_Order_By>;
  stddev?: InputMaybe<Charge_Products_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Charge_Products_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Charge_Products_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Charge_Products_Sum_Order_By>;
  var_pop?: InputMaybe<Charge_Products_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Charge_Products_Var_Samp_Order_By>;
  variance?: InputMaybe<Charge_Products_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "charge_products" */
export type Charge_Products_Arr_Rel_Insert_Input = {
  data: Array<Charge_Products_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Charge_Products_On_Conflict>;
};

/** aggregate avg on columns */
export type Charge_Products_Avg_Fields = {
  __typename?: 'charge_products_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "charge_products" */
export type Charge_Products_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "charge_products". All fields are combined with a logical 'AND'. */
export type Charge_Products_Bool_Exp = {
  _and?: InputMaybe<Array<Charge_Products_Bool_Exp>>;
  _not?: InputMaybe<Charge_Products_Bool_Exp>;
  _or?: InputMaybe<Array<Charge_Products_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  charge?: InputMaybe<Charges_Bool_Exp>;
  chargeId?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  product?: InputMaybe<Products_Bool_Exp>;
  productId?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "charge_products" */
export type Charge_Products_Constraint =
  /** unique or primary key constraint on columns "id" */
  'charge_products_pkey';

/** input type for incrementing numeric columns in table "charge_products" */
export type Charge_Products_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "charge_products" */
export type Charge_Products_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  charge?: InputMaybe<Charges_Obj_Rel_Insert_Input>;
  chargeId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  product?: InputMaybe<Products_Obj_Rel_Insert_Input>;
  productId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Charge_Products_Max_Fields = {
  __typename?: 'charge_products_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  chargeId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "charge_products" */
export type Charge_Products_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  chargeId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Charge_Products_Min_Fields = {
  __typename?: 'charge_products_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  chargeId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  productId?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "charge_products" */
export type Charge_Products_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  chargeId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  productId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "charge_products" */
export type Charge_Products_Mutation_Response = {
  __typename?: 'charge_products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Charge_Products>;
};

/** on_conflict condition type for table "charge_products" */
export type Charge_Products_On_Conflict = {
  constraint: Charge_Products_Constraint;
  update_columns?: Array<Charge_Products_Update_Column>;
  where?: InputMaybe<Charge_Products_Bool_Exp>;
};

/** Ordering options when selecting data from "charge_products". */
export type Charge_Products_Order_By = {
  amount?: InputMaybe<Order_By>;
  charge?: InputMaybe<Charges_Order_By>;
  chargeId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product?: InputMaybe<Products_Order_By>;
  productId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: charge_products */
export type Charge_Products_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "charge_products" */
export type Charge_Products_Select_Column =
  /** column name */
  | 'amount'
  /** column name */
  | 'chargeId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'productId'
  /** column name */
  | 'quantity'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "charge_products" */
export type Charge_Products_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  chargeId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  productId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Charge_Products_Stddev_Fields = {
  __typename?: 'charge_products_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "charge_products" */
export type Charge_Products_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Charge_Products_Stddev_Pop_Fields = {
  __typename?: 'charge_products_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "charge_products" */
export type Charge_Products_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Charge_Products_Stddev_Samp_Fields = {
  __typename?: 'charge_products_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "charge_products" */
export type Charge_Products_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "charge_products" */
export type Charge_Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Charge_Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Charge_Products_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  chargeId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  productId?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Charge_Products_Sum_Fields = {
  __typename?: 'charge_products_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  quantity?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "charge_products" */
export type Charge_Products_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** update columns of table "charge_products" */
export type Charge_Products_Update_Column =
  /** column name */
  | 'amount'
  /** column name */
  | 'chargeId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'productId'
  /** column name */
  | 'quantity'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at';

export type Charge_Products_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Charge_Products_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Charge_Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Charge_Products_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Charge_Products_Var_Pop_Fields = {
  __typename?: 'charge_products_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "charge_products" */
export type Charge_Products_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Charge_Products_Var_Samp_Fields = {
  __typename?: 'charge_products_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "charge_products" */
export type Charge_Products_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Charge_Products_Variance_Fields = {
  __typename?: 'charge_products_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "charge_products" */
export type Charge_Products_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** columns and relationships of "charges" */
export type Charges = {
  __typename?: 'charges';
  /** An object relationship */
  account?: Maybe<Accounts>;
  accountId?: Maybe<Scalars['uuid']>;
  amount: Scalars['numeric'];
  /** An object relationship */
  appointment?: Maybe<Appointments>;
  appointmentId?: Maybe<Scalars['uuid']>;
  cardId?: Maybe<Scalars['String']>;
  /** An array relationship */
  charge_products: Array<Charge_Products>;
  /** An aggregate relationship */
  charge_products_aggregate: Charge_Products_Aggregate;
  /** An object relationship */
  clinic?: Maybe<Clinics>;
  clinicId?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  medicalAssistantId?: Maybe<Scalars['String']>;
  /** An object relationship */
  patient?: Maybe<Patients>;
  patientId?: Maybe<Scalars['uuid']>;
  paymentType?: Maybe<Payment_Type_Enum>;
  /** An object relationship */
  payment_type?: Maybe<Payment_Type>;
  /** An object relationship */
  staff?: Maybe<Staff>;
  staffId?: Maybe<Scalars['uuid']>;
  stripeChargeId: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "charges" */
export type ChargesCharge_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Charge_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charge_Products_Order_By>>;
  where?: InputMaybe<Charge_Products_Bool_Exp>;
};

/** columns and relationships of "charges" */
export type ChargesCharge_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Charge_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charge_Products_Order_By>>;
  where?: InputMaybe<Charge_Products_Bool_Exp>;
};

/** aggregated selection of "charges" */
export type Charges_Aggregate = {
  __typename?: 'charges_aggregate';
  aggregate?: Maybe<Charges_Aggregate_Fields>;
  nodes: Array<Charges>;
};

export type Charges_Aggregate_Bool_Exp = {
  count?: InputMaybe<Charges_Aggregate_Bool_Exp_Count>;
};

export type Charges_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Charges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Charges_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "charges" */
export type Charges_Aggregate_Fields = {
  __typename?: 'charges_aggregate_fields';
  avg?: Maybe<Charges_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Charges_Max_Fields>;
  min?: Maybe<Charges_Min_Fields>;
  stddev?: Maybe<Charges_Stddev_Fields>;
  stddev_pop?: Maybe<Charges_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Charges_Stddev_Samp_Fields>;
  sum?: Maybe<Charges_Sum_Fields>;
  var_pop?: Maybe<Charges_Var_Pop_Fields>;
  var_samp?: Maybe<Charges_Var_Samp_Fields>;
  variance?: Maybe<Charges_Variance_Fields>;
};

/** aggregate fields of "charges" */
export type Charges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Charges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "charges" */
export type Charges_Aggregate_Order_By = {
  avg?: InputMaybe<Charges_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Charges_Max_Order_By>;
  min?: InputMaybe<Charges_Min_Order_By>;
  stddev?: InputMaybe<Charges_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Charges_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Charges_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Charges_Sum_Order_By>;
  var_pop?: InputMaybe<Charges_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Charges_Var_Samp_Order_By>;
  variance?: InputMaybe<Charges_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "charges" */
export type Charges_Arr_Rel_Insert_Input = {
  data: Array<Charges_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Charges_On_Conflict>;
};

/** aggregate avg on columns */
export type Charges_Avg_Fields = {
  __typename?: 'charges_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "charges" */
export type Charges_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "charges". All fields are combined with a logical 'AND'. */
export type Charges_Bool_Exp = {
  _and?: InputMaybe<Array<Charges_Bool_Exp>>;
  _not?: InputMaybe<Charges_Bool_Exp>;
  _or?: InputMaybe<Array<Charges_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  appointment?: InputMaybe<Appointments_Bool_Exp>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  cardId?: InputMaybe<String_Comparison_Exp>;
  charge_products?: InputMaybe<Charge_Products_Bool_Exp>;
  charge_products_aggregate?: InputMaybe<Charge_Products_Aggregate_Bool_Exp>;
  clinic?: InputMaybe<Clinics_Bool_Exp>;
  clinicId?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  medicalAssistantId?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  paymentType?: InputMaybe<Payment_Type_Enum_Comparison_Exp>;
  payment_type?: InputMaybe<Payment_Type_Bool_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  stripeChargeId?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "charges" */
export type Charges_Constraint =
  /** unique or primary key constraint on columns "id" */
  'charges_pkey';

/** input type for incrementing numeric columns in table "charges" */
export type Charges_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "charges" */
export type Charges_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['uuid']>;
  amount?: InputMaybe<Scalars['numeric']>;
  appointment?: InputMaybe<Appointments_Obj_Rel_Insert_Input>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  cardId?: InputMaybe<Scalars['String']>;
  charge_products?: InputMaybe<Charge_Products_Arr_Rel_Insert_Input>;
  clinic?: InputMaybe<Clinics_Obj_Rel_Insert_Input>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  medicalAssistantId?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  paymentType?: InputMaybe<Payment_Type_Enum>;
  payment_type?: InputMaybe<Payment_Type_Obj_Rel_Insert_Input>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  stripeChargeId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Charges_Max_Fields = {
  __typename?: 'charges_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  amount?: Maybe<Scalars['numeric']>;
  appointmentId?: Maybe<Scalars['uuid']>;
  cardId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  medicalAssistantId?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  staffId?: Maybe<Scalars['uuid']>;
  stripeChargeId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "charges" */
export type Charges_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  cardId?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medicalAssistantId?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  stripeChargeId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Charges_Min_Fields = {
  __typename?: 'charges_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  amount?: Maybe<Scalars['numeric']>;
  appointmentId?: Maybe<Scalars['uuid']>;
  cardId?: Maybe<Scalars['String']>;
  clinicId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  medicalAssistantId?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  staffId?: Maybe<Scalars['uuid']>;
  stripeChargeId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "charges" */
export type Charges_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  cardId?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medicalAssistantId?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  stripeChargeId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "charges" */
export type Charges_Mutation_Response = {
  __typename?: 'charges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Charges>;
};

/** input type for inserting object relation for remote table "charges" */
export type Charges_Obj_Rel_Insert_Input = {
  data: Charges_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Charges_On_Conflict>;
};

/** on_conflict condition type for table "charges" */
export type Charges_On_Conflict = {
  constraint: Charges_Constraint;
  update_columns?: Array<Charges_Update_Column>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

/** Ordering options when selecting data from "charges". */
export type Charges_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  appointment?: InputMaybe<Appointments_Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  cardId?: InputMaybe<Order_By>;
  charge_products_aggregate?: InputMaybe<Charge_Products_Aggregate_Order_By>;
  clinic?: InputMaybe<Clinics_Order_By>;
  clinicId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medicalAssistantId?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  paymentType?: InputMaybe<Order_By>;
  payment_type?: InputMaybe<Payment_Type_Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  stripeChargeId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: charges */
export type Charges_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "charges" */
export type Charges_Select_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'amount'
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'cardId'
  /** column name */
  | 'clinicId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'medicalAssistantId'
  /** column name */
  | 'patientId'
  /** column name */
  | 'paymentType'
  /** column name */
  | 'staffId'
  /** column name */
  | 'stripeChargeId'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "charges" */
export type Charges_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  amount?: InputMaybe<Scalars['numeric']>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  cardId?: InputMaybe<Scalars['String']>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  medicalAssistantId?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  paymentType?: InputMaybe<Payment_Type_Enum>;
  staffId?: InputMaybe<Scalars['uuid']>;
  stripeChargeId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Charges_Stddev_Fields = {
  __typename?: 'charges_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "charges" */
export type Charges_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Charges_Stddev_Pop_Fields = {
  __typename?: 'charges_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "charges" */
export type Charges_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Charges_Stddev_Samp_Fields = {
  __typename?: 'charges_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "charges" */
export type Charges_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "charges" */
export type Charges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Charges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Charges_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  amount?: InputMaybe<Scalars['numeric']>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  cardId?: InputMaybe<Scalars['String']>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  medicalAssistantId?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  paymentType?: InputMaybe<Payment_Type_Enum>;
  staffId?: InputMaybe<Scalars['uuid']>;
  stripeChargeId?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Charges_Sum_Fields = {
  __typename?: 'charges_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "charges" */
export type Charges_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "charges" */
export type Charges_Update_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'amount'
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'cardId'
  /** column name */
  | 'clinicId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'medicalAssistantId'
  /** column name */
  | 'patientId'
  /** column name */
  | 'paymentType'
  /** column name */
  | 'staffId'
  /** column name */
  | 'stripeChargeId'
  /** column name */
  | 'updated_at';

export type Charges_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Charges_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Charges_Set_Input>;
  /** filter the rows which have to be updated */
  where: Charges_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Charges_Var_Pop_Fields = {
  __typename?: 'charges_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "charges" */
export type Charges_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Charges_Var_Samp_Fields = {
  __typename?: 'charges_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "charges" */
export type Charges_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Charges_Variance_Fields = {
  __typename?: 'charges_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "charges" */
export type Charges_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** columns and relationships of "chat_messages" */
export type Chat_Messages = {
  __typename?: 'chat_messages';
  /** An object relationship */
  account?: Maybe<Accounts>;
  accountId?: Maybe<Scalars['uuid']>;
  chatSessionId: Scalars['uuid'];
  /** An array relationship */
  chat_messages_files: Array<Chat_Messages_File>;
  /** An aggregate relationship */
  chat_messages_files_aggregate: Chat_Messages_File_Aggregate;
  /** An object relationship */
  chat_messages_type: Chat_Messages_Type;
  /** An object relationship */
  chat_session: Chat_Sessions;
  createdAt: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  deleted: Scalars['Boolean'];
  id: Scalars['uuid'];
  message: Scalars['String'];
  request?: Maybe<Scalars['jsonb']>;
  response?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  staff?: Maybe<Staff>;
  staffId?: Maybe<Scalars['uuid']>;
  type: Chat_Messages_Type_Enum;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "chat_messages" */
export type Chat_MessagesChat_Messages_FilesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_File_Order_By>>;
  where?: InputMaybe<Chat_Messages_File_Bool_Exp>;
};

/** columns and relationships of "chat_messages" */
export type Chat_MessagesChat_Messages_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_File_Order_By>>;
  where?: InputMaybe<Chat_Messages_File_Bool_Exp>;
};

/** columns and relationships of "chat_messages" */
export type Chat_MessagesDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "chat_messages" */
export type Chat_MessagesRequestArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "chat_messages" */
export type Chat_MessagesResponseArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "chat_messages" */
export type Chat_Messages_Aggregate = {
  __typename?: 'chat_messages_aggregate';
  aggregate?: Maybe<Chat_Messages_Aggregate_Fields>;
  nodes: Array<Chat_Messages>;
};

export type Chat_Messages_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Chat_Messages_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Chat_Messages_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Chat_Messages_Aggregate_Bool_Exp_Count>;
};

export type Chat_Messages_Aggregate_Bool_Exp_Bool_And = {
  arguments: Chat_Messages_Select_Column_Chat_Messages_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Messages_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Chat_Messages_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Chat_Messages_Select_Column_Chat_Messages_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Messages_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Chat_Messages_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Messages_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_messages" */
export type Chat_Messages_Aggregate_Fields = {
  __typename?: 'chat_messages_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Messages_Max_Fields>;
  min?: Maybe<Chat_Messages_Min_Fields>;
};

/** aggregate fields of "chat_messages" */
export type Chat_Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_messages" */
export type Chat_Messages_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chat_Messages_Max_Order_By>;
  min?: InputMaybe<Chat_Messages_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Chat_Messages_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "chat_messages" */
export type Chat_Messages_Arr_Rel_Insert_Input = {
  data: Array<Chat_Messages_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Messages_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chat_messages". All fields are combined with a logical 'AND'. */
export type Chat_Messages_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Messages_Bool_Exp>>;
  _not?: InputMaybe<Chat_Messages_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Messages_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  chatSessionId?: InputMaybe<Uuid_Comparison_Exp>;
  chat_messages_files?: InputMaybe<Chat_Messages_File_Bool_Exp>;
  chat_messages_files_aggregate?: InputMaybe<Chat_Messages_File_Aggregate_Bool_Exp>;
  chat_messages_type?: InputMaybe<Chat_Messages_Type_Bool_Exp>;
  chat_session?: InputMaybe<Chat_Sessions_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  request?: InputMaybe<Jsonb_Comparison_Exp>;
  response?: InputMaybe<Jsonb_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Chat_Messages_Type_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_messages" */
export type Chat_Messages_Constraint =
  /** unique or primary key constraint on columns "id" */
  'chat_messages_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Chat_Messages_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
  request?: InputMaybe<Array<Scalars['String']>>;
  response?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Chat_Messages_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
  request?: InputMaybe<Scalars['Int']>;
  response?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Chat_Messages_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Scalars['String']>;
  response?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "chat_messages_file" */
export type Chat_Messages_File = {
  __typename?: 'chat_messages_file';
  ChatFile?: Maybe<FilePayload>;
  chatMessageId: Scalars['uuid'];
  /** An object relationship */
  chat_message: Chat_Messages;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  file: Files;
  fileId: Scalars['uuid'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "chat_messages_file" */
export type Chat_Messages_File_Aggregate = {
  __typename?: 'chat_messages_file_aggregate';
  aggregate?: Maybe<Chat_Messages_File_Aggregate_Fields>;
  nodes: Array<Chat_Messages_File>;
};

export type Chat_Messages_File_Aggregate_Bool_Exp = {
  count?: InputMaybe<Chat_Messages_File_Aggregate_Bool_Exp_Count>;
};

export type Chat_Messages_File_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chat_Messages_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Messages_File_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_messages_file" */
export type Chat_Messages_File_Aggregate_Fields = {
  __typename?: 'chat_messages_file_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Messages_File_Max_Fields>;
  min?: Maybe<Chat_Messages_File_Min_Fields>;
};

/** aggregate fields of "chat_messages_file" */
export type Chat_Messages_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Messages_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_messages_file" */
export type Chat_Messages_File_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chat_Messages_File_Max_Order_By>;
  min?: InputMaybe<Chat_Messages_File_Min_Order_By>;
};

/** input type for inserting array relation for remote table "chat_messages_file" */
export type Chat_Messages_File_Arr_Rel_Insert_Input = {
  data: Array<Chat_Messages_File_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Messages_File_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chat_messages_file". All fields are combined with a logical 'AND'. */
export type Chat_Messages_File_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Messages_File_Bool_Exp>>;
  _not?: InputMaybe<Chat_Messages_File_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Messages_File_Bool_Exp>>;
  chatMessageId?: InputMaybe<Uuid_Comparison_Exp>;
  chat_message?: InputMaybe<Chat_Messages_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  fileId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_messages_file" */
export type Chat_Messages_File_Constraint =
  /** unique or primary key constraint on columns "id" */
  'chat_messages_file_pkey';

/** input type for inserting data into table "chat_messages_file" */
export type Chat_Messages_File_Insert_Input = {
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  chat_message?: InputMaybe<Chat_Messages_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  fileId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Chat_Messages_File_Max_Fields = {
  __typename?: 'chat_messages_file_max_fields';
  chatMessageId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  fileId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "chat_messages_file" */
export type Chat_Messages_File_Max_Order_By = {
  chatMessageId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chat_Messages_File_Min_Fields = {
  __typename?: 'chat_messages_file_min_fields';
  chatMessageId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  fileId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "chat_messages_file" */
export type Chat_Messages_File_Min_Order_By = {
  chatMessageId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_messages_file" */
export type Chat_Messages_File_Mutation_Response = {
  __typename?: 'chat_messages_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Messages_File>;
};

/** on_conflict condition type for table "chat_messages_file" */
export type Chat_Messages_File_On_Conflict = {
  constraint: Chat_Messages_File_Constraint;
  update_columns?: Array<Chat_Messages_File_Update_Column>;
  where?: InputMaybe<Chat_Messages_File_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_messages_file". */
export type Chat_Messages_File_Order_By = {
  chatMessageId?: InputMaybe<Order_By>;
  chat_message?: InputMaybe<Chat_Messages_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_messages_file */
export type Chat_Messages_File_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "chat_messages_file" */
export type Chat_Messages_File_Select_Column =
  /** column name */
  | 'chatMessageId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'fileId'
  /** column name */
  | 'id'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "chat_messages_file" */
export type Chat_Messages_File_Set_Input = {
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fileId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "chat_messages_file" */
export type Chat_Messages_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Messages_File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Messages_File_Stream_Cursor_Value_Input = {
  chatMessageId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fileId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "chat_messages_file" */
export type Chat_Messages_File_Update_Column =
  /** column name */
  | 'chatMessageId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'fileId'
  /** column name */
  | 'id'
  /** column name */
  | 'updatedAt';

export type Chat_Messages_File_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Messages_File_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Messages_File_Bool_Exp;
};

/** input type for inserting data into table "chat_messages" */
export type Chat_Messages_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['uuid']>;
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  chat_messages_files?: InputMaybe<Chat_Messages_File_Arr_Rel_Insert_Input>;
  chat_messages_type?: InputMaybe<Chat_Messages_Type_Obj_Rel_Insert_Input>;
  chat_session?: InputMaybe<Chat_Sessions_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Chat_Messages_Type_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Chat_Messages_Max_Fields = {
  __typename?: 'chat_messages_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  chatSessionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "chat_messages" */
export type Chat_Messages_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  chatSessionId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chat_Messages_Min_Fields = {
  __typename?: 'chat_messages_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  chatSessionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "chat_messages" */
export type Chat_Messages_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  chatSessionId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_messages" */
export type Chat_Messages_Mutation_Response = {
  __typename?: 'chat_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Messages>;
};

/** input type for inserting object relation for remote table "chat_messages" */
export type Chat_Messages_Obj_Rel_Insert_Input = {
  data: Chat_Messages_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Messages_On_Conflict>;
};

/** on_conflict condition type for table "chat_messages" */
export type Chat_Messages_On_Conflict = {
  constraint: Chat_Messages_Constraint;
  update_columns?: Array<Chat_Messages_Update_Column>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_messages". */
export type Chat_Messages_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  chatSessionId?: InputMaybe<Order_By>;
  chat_messages_files_aggregate?: InputMaybe<Chat_Messages_File_Aggregate_Order_By>;
  chat_messages_type?: InputMaybe<Chat_Messages_Type_Order_By>;
  chat_session?: InputMaybe<Chat_Sessions_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  request?: InputMaybe<Order_By>;
  response?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_messages */
export type Chat_Messages_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Chat_Messages_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "chat_messages" */
export type Chat_Messages_Select_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'chatSessionId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'data'
  /** column name */
  | 'deleted'
  /** column name */
  | 'id'
  /** column name */
  | 'message'
  /** column name */
  | 'request'
  /** column name */
  | 'response'
  /** column name */
  | 'staffId'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt';

/** select "chat_messages_aggregate_bool_exp_bool_and_arguments_columns" columns of table "chat_messages" */
export type Chat_Messages_Select_Column_Chat_Messages_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  'deleted';

/** select "chat_messages_aggregate_bool_exp_bool_or_arguments_columns" columns of table "chat_messages" */
export type Chat_Messages_Select_Column_Chat_Messages_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  'deleted';

/** input type for updating data in table "chat_messages" */
export type Chat_Messages_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Chat_Messages_Type_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "chat_messages" */
export type Chat_Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Messages_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Chat_Messages_Type_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "chat_messages_type" */
export type Chat_Messages_Type = {
  __typename?: 'chat_messages_type';
  /** An array relationship */
  chat_messages: Array<Chat_Messages>;
  /** An aggregate relationship */
  chat_messages_aggregate: Chat_Messages_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "chat_messages_type" */
export type Chat_Messages_TypeChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_Order_By>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};

/** columns and relationships of "chat_messages_type" */
export type Chat_Messages_TypeChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_Order_By>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};

/** aggregated selection of "chat_messages_type" */
export type Chat_Messages_Type_Aggregate = {
  __typename?: 'chat_messages_type_aggregate';
  aggregate?: Maybe<Chat_Messages_Type_Aggregate_Fields>;
  nodes: Array<Chat_Messages_Type>;
};

/** aggregate fields of "chat_messages_type" */
export type Chat_Messages_Type_Aggregate_Fields = {
  __typename?: 'chat_messages_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Messages_Type_Max_Fields>;
  min?: Maybe<Chat_Messages_Type_Min_Fields>;
};

/** aggregate fields of "chat_messages_type" */
export type Chat_Messages_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Messages_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "chat_messages_type". All fields are combined with a logical 'AND'. */
export type Chat_Messages_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Messages_Type_Bool_Exp>>;
  _not?: InputMaybe<Chat_Messages_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Messages_Type_Bool_Exp>>;
  chat_messages?: InputMaybe<Chat_Messages_Bool_Exp>;
  chat_messages_aggregate?: InputMaybe<Chat_Messages_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_messages_type" */
export type Chat_Messages_Type_Constraint =
  /** unique or primary key constraint on columns "value" */
  'chat_messages_type_pkey';

export type Chat_Messages_Type_Enum =
  /** User action type */
  | 'ACTION'
  /** A file message */
  | 'FILE'
  /** An internal message */
  | 'INTERNAL'
  /** A regular message */
  | 'MESSAGE'
  /** A system message */
  | 'SYSTEM';

/** Boolean expression to compare columns of type "chat_messages_type_enum". All fields are combined with logical 'AND'. */
export type Chat_Messages_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Chat_Messages_Type_Enum>;
  _in?: InputMaybe<Array<Chat_Messages_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Chat_Messages_Type_Enum>;
  _nin?: InputMaybe<Array<Chat_Messages_Type_Enum>>;
};

/** input type for inserting data into table "chat_messages_type" */
export type Chat_Messages_Type_Insert_Input = {
  chat_messages?: InputMaybe<Chat_Messages_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Chat_Messages_Type_Max_Fields = {
  __typename?: 'chat_messages_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Chat_Messages_Type_Min_Fields = {
  __typename?: 'chat_messages_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "chat_messages_type" */
export type Chat_Messages_Type_Mutation_Response = {
  __typename?: 'chat_messages_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Messages_Type>;
};

/** input type for inserting object relation for remote table "chat_messages_type" */
export type Chat_Messages_Type_Obj_Rel_Insert_Input = {
  data: Chat_Messages_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Messages_Type_On_Conflict>;
};

/** on_conflict condition type for table "chat_messages_type" */
export type Chat_Messages_Type_On_Conflict = {
  constraint: Chat_Messages_Type_Constraint;
  update_columns?: Array<Chat_Messages_Type_Update_Column>;
  where?: InputMaybe<Chat_Messages_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_messages_type". */
export type Chat_Messages_Type_Order_By = {
  chat_messages_aggregate?: InputMaybe<Chat_Messages_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_messages_type */
export type Chat_Messages_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "chat_messages_type" */
export type Chat_Messages_Type_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "chat_messages_type" */
export type Chat_Messages_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "chat_messages_type" */
export type Chat_Messages_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Messages_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Messages_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "chat_messages_type" */
export type Chat_Messages_Type_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Chat_Messages_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Messages_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Messages_Type_Bool_Exp;
};

/** update columns of table "chat_messages" */
export type Chat_Messages_Update_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'chatSessionId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'data'
  /** column name */
  | 'deleted'
  /** column name */
  | 'id'
  /** column name */
  | 'message'
  /** column name */
  | 'request'
  /** column name */
  | 'response'
  /** column name */
  | 'staffId'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt';

export type Chat_Messages_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Chat_Messages_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Chat_Messages_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Chat_Messages_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Chat_Messages_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Chat_Messages_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Messages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Messages_Bool_Exp;
};

/** columns and relationships of "chat_notes" */
export type Chat_Notes = {
  __typename?: 'chat_notes';
  chatSessionId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  chat_notes_type: Chat_Notes_Types;
  /** An object relationship */
  chat_session?: Maybe<Chat_Sessions>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
  /** An object relationship */
  staff?: Maybe<Staff>;
  staffId?: Maybe<Scalars['uuid']>;
  type: Chat_Notes_Types_Enum;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "chat_notes" */
export type Chat_Notes_Aggregate = {
  __typename?: 'chat_notes_aggregate';
  aggregate?: Maybe<Chat_Notes_Aggregate_Fields>;
  nodes: Array<Chat_Notes>;
};

export type Chat_Notes_Aggregate_Bool_Exp = {
  count?: InputMaybe<Chat_Notes_Aggregate_Bool_Exp_Count>;
};

export type Chat_Notes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chat_Notes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Notes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_notes" */
export type Chat_Notes_Aggregate_Fields = {
  __typename?: 'chat_notes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Notes_Max_Fields>;
  min?: Maybe<Chat_Notes_Min_Fields>;
};

/** aggregate fields of "chat_notes" */
export type Chat_Notes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Notes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_notes" */
export type Chat_Notes_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chat_Notes_Max_Order_By>;
  min?: InputMaybe<Chat_Notes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "chat_notes" */
export type Chat_Notes_Arr_Rel_Insert_Input = {
  data: Array<Chat_Notes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Notes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chat_notes". All fields are combined with a logical 'AND'. */
export type Chat_Notes_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Notes_Bool_Exp>>;
  _not?: InputMaybe<Chat_Notes_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Notes_Bool_Exp>>;
  chatSessionId?: InputMaybe<Uuid_Comparison_Exp>;
  chat_notes_type?: InputMaybe<Chat_Notes_Types_Bool_Exp>;
  chat_session?: InputMaybe<Chat_Sessions_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Chat_Notes_Types_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_notes" */
export type Chat_Notes_Constraint =
  /** unique or primary key constraint on columns "id" */
  'chat_notes_pkey';

/** input type for inserting data into table "chat_notes" */
export type Chat_Notes_Insert_Input = {
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  chat_notes_type?: InputMaybe<Chat_Notes_Types_Obj_Rel_Insert_Input>;
  chat_session?: InputMaybe<Chat_Sessions_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Chat_Notes_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Chat_Notes_Max_Fields = {
  __typename?: 'chat_notes_max_fields';
  chatSessionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "chat_notes" */
export type Chat_Notes_Max_Order_By = {
  chatSessionId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chat_Notes_Min_Fields = {
  __typename?: 'chat_notes_min_fields';
  chatSessionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "chat_notes" */
export type Chat_Notes_Min_Order_By = {
  chatSessionId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_notes" */
export type Chat_Notes_Mutation_Response = {
  __typename?: 'chat_notes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Notes>;
};

/** on_conflict condition type for table "chat_notes" */
export type Chat_Notes_On_Conflict = {
  constraint: Chat_Notes_Constraint;
  update_columns?: Array<Chat_Notes_Update_Column>;
  where?: InputMaybe<Chat_Notes_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_notes". */
export type Chat_Notes_Order_By = {
  chatSessionId?: InputMaybe<Order_By>;
  chat_notes_type?: InputMaybe<Chat_Notes_Types_Order_By>;
  chat_session?: InputMaybe<Chat_Sessions_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_notes */
export type Chat_Notes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "chat_notes" */
export type Chat_Notes_Select_Column =
  /** column name */
  | 'chatSessionId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'note'
  /** column name */
  | 'staffId'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "chat_notes" */
export type Chat_Notes_Set_Input = {
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Chat_Notes_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "chat_notes" */
export type Chat_Notes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Notes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Notes_Stream_Cursor_Value_Input = {
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Chat_Notes_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "chat_notes_types" */
export type Chat_Notes_Types = {
  __typename?: 'chat_notes_types';
  /** An array relationship */
  chat_notes: Array<Chat_Notes>;
  /** An aggregate relationship */
  chat_notes_aggregate: Chat_Notes_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "chat_notes_types" */
export type Chat_Notes_TypesChat_NotesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Notes_Order_By>>;
  where?: InputMaybe<Chat_Notes_Bool_Exp>;
};

/** columns and relationships of "chat_notes_types" */
export type Chat_Notes_TypesChat_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Notes_Order_By>>;
  where?: InputMaybe<Chat_Notes_Bool_Exp>;
};

/** aggregated selection of "chat_notes_types" */
export type Chat_Notes_Types_Aggregate = {
  __typename?: 'chat_notes_types_aggregate';
  aggregate?: Maybe<Chat_Notes_Types_Aggregate_Fields>;
  nodes: Array<Chat_Notes_Types>;
};

/** aggregate fields of "chat_notes_types" */
export type Chat_Notes_Types_Aggregate_Fields = {
  __typename?: 'chat_notes_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Notes_Types_Max_Fields>;
  min?: Maybe<Chat_Notes_Types_Min_Fields>;
};

/** aggregate fields of "chat_notes_types" */
export type Chat_Notes_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Notes_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "chat_notes_types". All fields are combined with a logical 'AND'. */
export type Chat_Notes_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Notes_Types_Bool_Exp>>;
  _not?: InputMaybe<Chat_Notes_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Notes_Types_Bool_Exp>>;
  chat_notes?: InputMaybe<Chat_Notes_Bool_Exp>;
  chat_notes_aggregate?: InputMaybe<Chat_Notes_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_notes_types" */
export type Chat_Notes_Types_Constraint =
  /** unique or primary key constraint on columns "value" */
  'chat_notes_types_pkey';

export type Chat_Notes_Types_Enum =
  /** Internal Notes */
  | 'INTERNAL'
  /** Chat Session Summary */
  | 'SUMMARY';

/** Boolean expression to compare columns of type "chat_notes_types_enum". All fields are combined with logical 'AND'. */
export type Chat_Notes_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Chat_Notes_Types_Enum>;
  _in?: InputMaybe<Array<Chat_Notes_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Chat_Notes_Types_Enum>;
  _nin?: InputMaybe<Array<Chat_Notes_Types_Enum>>;
};

/** input type for inserting data into table "chat_notes_types" */
export type Chat_Notes_Types_Insert_Input = {
  chat_notes?: InputMaybe<Chat_Notes_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Chat_Notes_Types_Max_Fields = {
  __typename?: 'chat_notes_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Chat_Notes_Types_Min_Fields = {
  __typename?: 'chat_notes_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "chat_notes_types" */
export type Chat_Notes_Types_Mutation_Response = {
  __typename?: 'chat_notes_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Notes_Types>;
};

/** input type for inserting object relation for remote table "chat_notes_types" */
export type Chat_Notes_Types_Obj_Rel_Insert_Input = {
  data: Chat_Notes_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Notes_Types_On_Conflict>;
};

/** on_conflict condition type for table "chat_notes_types" */
export type Chat_Notes_Types_On_Conflict = {
  constraint: Chat_Notes_Types_Constraint;
  update_columns?: Array<Chat_Notes_Types_Update_Column>;
  where?: InputMaybe<Chat_Notes_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_notes_types". */
export type Chat_Notes_Types_Order_By = {
  chat_notes_aggregate?: InputMaybe<Chat_Notes_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_notes_types */
export type Chat_Notes_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "chat_notes_types" */
export type Chat_Notes_Types_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "chat_notes_types" */
export type Chat_Notes_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "chat_notes_types" */
export type Chat_Notes_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Notes_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Notes_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "chat_notes_types" */
export type Chat_Notes_Types_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Chat_Notes_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Notes_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Notes_Types_Bool_Exp;
};

/** update columns of table "chat_notes" */
export type Chat_Notes_Update_Column =
  /** column name */
  | 'chatSessionId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'note'
  /** column name */
  | 'staffId'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt';

export type Chat_Notes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Notes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Notes_Bool_Exp;
};

/** columns and relationships of "chat_sessions" */
export type Chat_Sessions = {
  __typename?: 'chat_sessions';
  /** An object relationship */
  account?: Maybe<Accounts>;
  /** An array relationship */
  chat_messages: Array<Chat_Messages>;
  /** An aggregate relationship */
  chat_messages_aggregate: Chat_Messages_Aggregate;
  /** An array relationship */
  chat_notes: Array<Chat_Notes>;
  /** An aggregate relationship */
  chat_notes_aggregate: Chat_Notes_Aggregate;
  /** An array relationship */
  chat_sessions_accounts: Array<Chat_Sessions_Accounts>;
  /** An aggregate relationship */
  chat_sessions_accounts_aggregate: Chat_Sessions_Accounts_Aggregate;
  /** An array relationship */
  chat_sessions_patients: Array<Chat_Sessions_Patients>;
  /** An aggregate relationship */
  chat_sessions_patients_aggregate: Chat_Sessions_Patients_Aggregate;
  /** An array relationship */
  chat_sessions_reads: Array<Chat_Sessions_Read>;
  /** An aggregate relationship */
  chat_sessions_reads_aggregate: Chat_Sessions_Read_Aggregate;
  /** An array relationship */
  chat_sessions_staffs: Array<Chat_Sessions_Staff>;
  /** An aggregate relationship */
  chat_sessions_staffs_aggregate: Chat_Sessions_Staff_Aggregate;
  /** An object relationship */
  chat_sessions_status: Chat_Sessions_Status;
  createdAt: Scalars['timestamptz'];
  createdByAccountId?: Maybe<Scalars['uuid']>;
  deleted: Scalars['Boolean'];
  id: Scalars['uuid'];
  status: Chat_Sessions_Status_Enum;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "chat_sessions" */
export type Chat_SessionsChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_Order_By>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};

/** columns and relationships of "chat_sessions" */
export type Chat_SessionsChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_Order_By>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};

/** columns and relationships of "chat_sessions" */
export type Chat_SessionsChat_NotesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Notes_Order_By>>;
  where?: InputMaybe<Chat_Notes_Bool_Exp>;
};

/** columns and relationships of "chat_sessions" */
export type Chat_SessionsChat_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Notes_Order_By>>;
  where?: InputMaybe<Chat_Notes_Bool_Exp>;
};

/** columns and relationships of "chat_sessions" */
export type Chat_SessionsChat_Sessions_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Accounts_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Accounts_Bool_Exp>;
};

/** columns and relationships of "chat_sessions" */
export type Chat_SessionsChat_Sessions_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Accounts_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Accounts_Bool_Exp>;
};

/** columns and relationships of "chat_sessions" */
export type Chat_SessionsChat_Sessions_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Patients_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Patients_Bool_Exp>;
};

/** columns and relationships of "chat_sessions" */
export type Chat_SessionsChat_Sessions_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Patients_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Patients_Bool_Exp>;
};

/** columns and relationships of "chat_sessions" */
export type Chat_SessionsChat_Sessions_ReadsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Read_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Read_Bool_Exp>;
};

/** columns and relationships of "chat_sessions" */
export type Chat_SessionsChat_Sessions_Reads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Read_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Read_Bool_Exp>;
};

/** columns and relationships of "chat_sessions" */
export type Chat_SessionsChat_Sessions_StaffsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Staff_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Staff_Bool_Exp>;
};

/** columns and relationships of "chat_sessions" */
export type Chat_SessionsChat_Sessions_Staffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Staff_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Staff_Bool_Exp>;
};

/** columns and relationships of "chat_sessions_accounts" */
export type Chat_Sessions_Accounts = {
  __typename?: 'chat_sessions_accounts';
  /** An object relationship */
  account: Accounts;
  accountId: Scalars['uuid'];
  active: Scalars['Boolean'];
  chatSessionId: Scalars['uuid'];
  /** An object relationship */
  chat_session: Chat_Sessions;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "chat_sessions_accounts" */
export type Chat_Sessions_Accounts_Aggregate = {
  __typename?: 'chat_sessions_accounts_aggregate';
  aggregate?: Maybe<Chat_Sessions_Accounts_Aggregate_Fields>;
  nodes: Array<Chat_Sessions_Accounts>;
};

export type Chat_Sessions_Accounts_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Chat_Sessions_Accounts_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Chat_Sessions_Accounts_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Chat_Sessions_Accounts_Aggregate_Bool_Exp_Count>;
};

export type Chat_Sessions_Accounts_Aggregate_Bool_Exp_Bool_And = {
  arguments: Chat_Sessions_Accounts_Select_Column_Chat_Sessions_Accounts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Sessions_Accounts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Chat_Sessions_Accounts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Chat_Sessions_Accounts_Select_Column_Chat_Sessions_Accounts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Sessions_Accounts_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Chat_Sessions_Accounts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chat_Sessions_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Sessions_Accounts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_sessions_accounts" */
export type Chat_Sessions_Accounts_Aggregate_Fields = {
  __typename?: 'chat_sessions_accounts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Sessions_Accounts_Max_Fields>;
  min?: Maybe<Chat_Sessions_Accounts_Min_Fields>;
};

/** aggregate fields of "chat_sessions_accounts" */
export type Chat_Sessions_Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Sessions_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_sessions_accounts" */
export type Chat_Sessions_Accounts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chat_Sessions_Accounts_Max_Order_By>;
  min?: InputMaybe<Chat_Sessions_Accounts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "chat_sessions_accounts" */
export type Chat_Sessions_Accounts_Arr_Rel_Insert_Input = {
  data: Array<Chat_Sessions_Accounts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Sessions_Accounts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chat_sessions_accounts". All fields are combined with a logical 'AND'. */
export type Chat_Sessions_Accounts_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Sessions_Accounts_Bool_Exp>>;
  _not?: InputMaybe<Chat_Sessions_Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Sessions_Accounts_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  chatSessionId?: InputMaybe<Uuid_Comparison_Exp>;
  chat_session?: InputMaybe<Chat_Sessions_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_sessions_accounts" */
export type Chat_Sessions_Accounts_Constraint =
  /** unique or primary key constraint on columns "chatSessionId", "accountId" */
  | 'chat_sessions_accounts_accountId_chatSessionId_key'
  /** unique or primary key constraint on columns "id" */
  | 'chat_sessions_accounts_pkey';

/** input type for inserting data into table "chat_sessions_accounts" */
export type Chat_Sessions_Accounts_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['uuid']>;
  active?: InputMaybe<Scalars['Boolean']>;
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  chat_session?: InputMaybe<Chat_Sessions_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Chat_Sessions_Accounts_Max_Fields = {
  __typename?: 'chat_sessions_accounts_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  chatSessionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "chat_sessions_accounts" */
export type Chat_Sessions_Accounts_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  chatSessionId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chat_Sessions_Accounts_Min_Fields = {
  __typename?: 'chat_sessions_accounts_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  chatSessionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "chat_sessions_accounts" */
export type Chat_Sessions_Accounts_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  chatSessionId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_sessions_accounts" */
export type Chat_Sessions_Accounts_Mutation_Response = {
  __typename?: 'chat_sessions_accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Sessions_Accounts>;
};

/** on_conflict condition type for table "chat_sessions_accounts" */
export type Chat_Sessions_Accounts_On_Conflict = {
  constraint: Chat_Sessions_Accounts_Constraint;
  update_columns?: Array<Chat_Sessions_Accounts_Update_Column>;
  where?: InputMaybe<Chat_Sessions_Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_sessions_accounts". */
export type Chat_Sessions_Accounts_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  active?: InputMaybe<Order_By>;
  chatSessionId?: InputMaybe<Order_By>;
  chat_session?: InputMaybe<Chat_Sessions_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_sessions_accounts */
export type Chat_Sessions_Accounts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "chat_sessions_accounts" */
export type Chat_Sessions_Accounts_Select_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'active'
  /** column name */
  | 'chatSessionId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'updatedAt';

/** select "chat_sessions_accounts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "chat_sessions_accounts" */
export type Chat_Sessions_Accounts_Select_Column_Chat_Sessions_Accounts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  'active';

/** select "chat_sessions_accounts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "chat_sessions_accounts" */
export type Chat_Sessions_Accounts_Select_Column_Chat_Sessions_Accounts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  'active';

/** input type for updating data in table "chat_sessions_accounts" */
export type Chat_Sessions_Accounts_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  active?: InputMaybe<Scalars['Boolean']>;
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "chat_sessions_accounts" */
export type Chat_Sessions_Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Sessions_Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Sessions_Accounts_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  active?: InputMaybe<Scalars['Boolean']>;
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "chat_sessions_accounts" */
export type Chat_Sessions_Accounts_Update_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'active'
  /** column name */
  | 'chatSessionId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'updatedAt';

export type Chat_Sessions_Accounts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Sessions_Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Sessions_Accounts_Bool_Exp;
};

/** aggregated selection of "chat_sessions" */
export type Chat_Sessions_Aggregate = {
  __typename?: 'chat_sessions_aggregate';
  aggregate?: Maybe<Chat_Sessions_Aggregate_Fields>;
  nodes: Array<Chat_Sessions>;
};

export type Chat_Sessions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Chat_Sessions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Chat_Sessions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Chat_Sessions_Aggregate_Bool_Exp_Count>;
};

export type Chat_Sessions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Chat_Sessions_Select_Column_Chat_Sessions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Sessions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Chat_Sessions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Chat_Sessions_Select_Column_Chat_Sessions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Sessions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Chat_Sessions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chat_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Sessions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_sessions" */
export type Chat_Sessions_Aggregate_Fields = {
  __typename?: 'chat_sessions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Sessions_Max_Fields>;
  min?: Maybe<Chat_Sessions_Min_Fields>;
};

/** aggregate fields of "chat_sessions" */
export type Chat_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_sessions" */
export type Chat_Sessions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chat_Sessions_Max_Order_By>;
  min?: InputMaybe<Chat_Sessions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "chat_sessions" */
export type Chat_Sessions_Arr_Rel_Insert_Input = {
  data: Array<Chat_Sessions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Sessions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chat_sessions". All fields are combined with a logical 'AND'. */
export type Chat_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Sessions_Bool_Exp>>;
  _not?: InputMaybe<Chat_Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Sessions_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  chat_messages?: InputMaybe<Chat_Messages_Bool_Exp>;
  chat_messages_aggregate?: InputMaybe<Chat_Messages_Aggregate_Bool_Exp>;
  chat_notes?: InputMaybe<Chat_Notes_Bool_Exp>;
  chat_notes_aggregate?: InputMaybe<Chat_Notes_Aggregate_Bool_Exp>;
  chat_sessions_accounts?: InputMaybe<Chat_Sessions_Accounts_Bool_Exp>;
  chat_sessions_accounts_aggregate?: InputMaybe<Chat_Sessions_Accounts_Aggregate_Bool_Exp>;
  chat_sessions_patients?: InputMaybe<Chat_Sessions_Patients_Bool_Exp>;
  chat_sessions_patients_aggregate?: InputMaybe<Chat_Sessions_Patients_Aggregate_Bool_Exp>;
  chat_sessions_reads?: InputMaybe<Chat_Sessions_Read_Bool_Exp>;
  chat_sessions_reads_aggregate?: InputMaybe<Chat_Sessions_Read_Aggregate_Bool_Exp>;
  chat_sessions_staffs?: InputMaybe<Chat_Sessions_Staff_Bool_Exp>;
  chat_sessions_staffs_aggregate?: InputMaybe<Chat_Sessions_Staff_Aggregate_Bool_Exp>;
  chat_sessions_status?: InputMaybe<Chat_Sessions_Status_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdByAccountId?: InputMaybe<Uuid_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Chat_Sessions_Status_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_sessions" */
export type Chat_Sessions_Constraint =
  /** unique or primary key constraint on columns "id" */
  'chat_sessions_pkey';

/** input type for inserting data into table "chat_sessions" */
export type Chat_Sessions_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  chat_messages?: InputMaybe<Chat_Messages_Arr_Rel_Insert_Input>;
  chat_notes?: InputMaybe<Chat_Notes_Arr_Rel_Insert_Input>;
  chat_sessions_accounts?: InputMaybe<Chat_Sessions_Accounts_Arr_Rel_Insert_Input>;
  chat_sessions_patients?: InputMaybe<Chat_Sessions_Patients_Arr_Rel_Insert_Input>;
  chat_sessions_reads?: InputMaybe<Chat_Sessions_Read_Arr_Rel_Insert_Input>;
  chat_sessions_staffs?: InputMaybe<Chat_Sessions_Staff_Arr_Rel_Insert_Input>;
  chat_sessions_status?: InputMaybe<Chat_Sessions_Status_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByAccountId?: InputMaybe<Scalars['uuid']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Chat_Sessions_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Chat_Sessions_Max_Fields = {
  __typename?: 'chat_sessions_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByAccountId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "chat_sessions" */
export type Chat_Sessions_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  createdByAccountId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chat_Sessions_Min_Fields = {
  __typename?: 'chat_sessions_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByAccountId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "chat_sessions" */
export type Chat_Sessions_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  createdByAccountId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_sessions" */
export type Chat_Sessions_Mutation_Response = {
  __typename?: 'chat_sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Sessions>;
};

/** input type for inserting object relation for remote table "chat_sessions" */
export type Chat_Sessions_Obj_Rel_Insert_Input = {
  data: Chat_Sessions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Sessions_On_Conflict>;
};

/** on_conflict condition type for table "chat_sessions" */
export type Chat_Sessions_On_Conflict = {
  constraint: Chat_Sessions_Constraint;
  update_columns?: Array<Chat_Sessions_Update_Column>;
  where?: InputMaybe<Chat_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_sessions". */
export type Chat_Sessions_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  chat_messages_aggregate?: InputMaybe<Chat_Messages_Aggregate_Order_By>;
  chat_notes_aggregate?: InputMaybe<Chat_Notes_Aggregate_Order_By>;
  chat_sessions_accounts_aggregate?: InputMaybe<Chat_Sessions_Accounts_Aggregate_Order_By>;
  chat_sessions_patients_aggregate?: InputMaybe<Chat_Sessions_Patients_Aggregate_Order_By>;
  chat_sessions_reads_aggregate?: InputMaybe<Chat_Sessions_Read_Aggregate_Order_By>;
  chat_sessions_staffs_aggregate?: InputMaybe<Chat_Sessions_Staff_Aggregate_Order_By>;
  chat_sessions_status?: InputMaybe<Chat_Sessions_Status_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdByAccountId?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** columns and relationships of "chat_sessions_patients" */
export type Chat_Sessions_Patients = {
  __typename?: 'chat_sessions_patients';
  chatSessionId: Scalars['uuid'];
  /** An object relationship */
  chat_session: Chat_Sessions;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  patient: Patients;
  patientId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "chat_sessions_patients" */
export type Chat_Sessions_Patients_Aggregate = {
  __typename?: 'chat_sessions_patients_aggregate';
  aggregate?: Maybe<Chat_Sessions_Patients_Aggregate_Fields>;
  nodes: Array<Chat_Sessions_Patients>;
};

export type Chat_Sessions_Patients_Aggregate_Bool_Exp = {
  count?: InputMaybe<Chat_Sessions_Patients_Aggregate_Bool_Exp_Count>;
};

export type Chat_Sessions_Patients_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chat_Sessions_Patients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Sessions_Patients_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_sessions_patients" */
export type Chat_Sessions_Patients_Aggregate_Fields = {
  __typename?: 'chat_sessions_patients_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Sessions_Patients_Max_Fields>;
  min?: Maybe<Chat_Sessions_Patients_Min_Fields>;
};

/** aggregate fields of "chat_sessions_patients" */
export type Chat_Sessions_Patients_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Sessions_Patients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_sessions_patients" */
export type Chat_Sessions_Patients_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chat_Sessions_Patients_Max_Order_By>;
  min?: InputMaybe<Chat_Sessions_Patients_Min_Order_By>;
};

/** input type for inserting array relation for remote table "chat_sessions_patients" */
export type Chat_Sessions_Patients_Arr_Rel_Insert_Input = {
  data: Array<Chat_Sessions_Patients_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Sessions_Patients_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chat_sessions_patients". All fields are combined with a logical 'AND'. */
export type Chat_Sessions_Patients_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Sessions_Patients_Bool_Exp>>;
  _not?: InputMaybe<Chat_Sessions_Patients_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Sessions_Patients_Bool_Exp>>;
  chatSessionId?: InputMaybe<Uuid_Comparison_Exp>;
  chat_session?: InputMaybe<Chat_Sessions_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_sessions_patients" */
export type Chat_Sessions_Patients_Constraint =
  /** unique or primary key constraint on columns "chatSessionId", "patientId" */
  | 'chat_sessions_patients_patientId_chatSessionId_key'
  /** unique or primary key constraint on columns "id" */
  | 'chat_sessions_patients_pkey';

/** input type for inserting data into table "chat_sessions_patients" */
export type Chat_Sessions_Patients_Insert_Input = {
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  chat_session?: InputMaybe<Chat_Sessions_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Chat_Sessions_Patients_Max_Fields = {
  __typename?: 'chat_sessions_patients_max_fields';
  chatSessionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "chat_sessions_patients" */
export type Chat_Sessions_Patients_Max_Order_By = {
  chatSessionId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chat_Sessions_Patients_Min_Fields = {
  __typename?: 'chat_sessions_patients_min_fields';
  chatSessionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "chat_sessions_patients" */
export type Chat_Sessions_Patients_Min_Order_By = {
  chatSessionId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_sessions_patients" */
export type Chat_Sessions_Patients_Mutation_Response = {
  __typename?: 'chat_sessions_patients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Sessions_Patients>;
};

/** on_conflict condition type for table "chat_sessions_patients" */
export type Chat_Sessions_Patients_On_Conflict = {
  constraint: Chat_Sessions_Patients_Constraint;
  update_columns?: Array<Chat_Sessions_Patients_Update_Column>;
  where?: InputMaybe<Chat_Sessions_Patients_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_sessions_patients". */
export type Chat_Sessions_Patients_Order_By = {
  chatSessionId?: InputMaybe<Order_By>;
  chat_session?: InputMaybe<Chat_Sessions_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_sessions_patients */
export type Chat_Sessions_Patients_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "chat_sessions_patients" */
export type Chat_Sessions_Patients_Select_Column =
  /** column name */
  | 'chatSessionId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'patientId'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "chat_sessions_patients" */
export type Chat_Sessions_Patients_Set_Input = {
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "chat_sessions_patients" */
export type Chat_Sessions_Patients_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Sessions_Patients_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Sessions_Patients_Stream_Cursor_Value_Input = {
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "chat_sessions_patients" */
export type Chat_Sessions_Patients_Update_Column =
  /** column name */
  | 'chatSessionId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'patientId'
  /** column name */
  | 'updatedAt';

export type Chat_Sessions_Patients_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Sessions_Patients_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Sessions_Patients_Bool_Exp;
};

/** primary key columns input for table: chat_sessions */
export type Chat_Sessions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "chat_sessions_read" */
export type Chat_Sessions_Read = {
  __typename?: 'chat_sessions_read';
  /** An object relationship */
  account?: Maybe<Accounts>;
  accountId?: Maybe<Scalars['uuid']>;
  chatSessionId: Scalars['uuid'];
  /** An object relationship */
  chat_session: Chat_Sessions;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  lastViewed: Scalars['timestamptz'];
  /** An object relationship */
  staff?: Maybe<Staff>;
  staffId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "chat_sessions_read" */
export type Chat_Sessions_Read_Aggregate = {
  __typename?: 'chat_sessions_read_aggregate';
  aggregate?: Maybe<Chat_Sessions_Read_Aggregate_Fields>;
  nodes: Array<Chat_Sessions_Read>;
};

export type Chat_Sessions_Read_Aggregate_Bool_Exp = {
  count?: InputMaybe<Chat_Sessions_Read_Aggregate_Bool_Exp_Count>;
};

export type Chat_Sessions_Read_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chat_Sessions_Read_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Sessions_Read_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_sessions_read" */
export type Chat_Sessions_Read_Aggregate_Fields = {
  __typename?: 'chat_sessions_read_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Sessions_Read_Max_Fields>;
  min?: Maybe<Chat_Sessions_Read_Min_Fields>;
};

/** aggregate fields of "chat_sessions_read" */
export type Chat_Sessions_Read_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Sessions_Read_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_sessions_read" */
export type Chat_Sessions_Read_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chat_Sessions_Read_Max_Order_By>;
  min?: InputMaybe<Chat_Sessions_Read_Min_Order_By>;
};

/** input type for inserting array relation for remote table "chat_sessions_read" */
export type Chat_Sessions_Read_Arr_Rel_Insert_Input = {
  data: Array<Chat_Sessions_Read_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Sessions_Read_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chat_sessions_read". All fields are combined with a logical 'AND'. */
export type Chat_Sessions_Read_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Sessions_Read_Bool_Exp>>;
  _not?: InputMaybe<Chat_Sessions_Read_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Sessions_Read_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  chatSessionId?: InputMaybe<Uuid_Comparison_Exp>;
  chat_session?: InputMaybe<Chat_Sessions_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lastViewed?: InputMaybe<Timestamptz_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_sessions_read" */
export type Chat_Sessions_Read_Constraint =
  /** unique or primary key constraint on columns "chatSessionId", "accountId" */
  | 'chat_sessions_read_accountId_chatSessionId_key'
  /** unique or primary key constraint on columns "id" */
  | 'chat_sessions_read_pkey'
  /** unique or primary key constraint on columns "chatSessionId", "staffId" */
  | 'chat_sessions_read_staffId_chatSessionId_key';

/** input type for inserting data into table "chat_sessions_read" */
export type Chat_Sessions_Read_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['uuid']>;
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  chat_session?: InputMaybe<Chat_Sessions_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastViewed?: InputMaybe<Scalars['timestamptz']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Chat_Sessions_Read_Max_Fields = {
  __typename?: 'chat_sessions_read_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  chatSessionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lastViewed?: Maybe<Scalars['timestamptz']>;
  staffId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "chat_sessions_read" */
export type Chat_Sessions_Read_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  chatSessionId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastViewed?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chat_Sessions_Read_Min_Fields = {
  __typename?: 'chat_sessions_read_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  chatSessionId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lastViewed?: Maybe<Scalars['timestamptz']>;
  staffId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "chat_sessions_read" */
export type Chat_Sessions_Read_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  chatSessionId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastViewed?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_sessions_read" */
export type Chat_Sessions_Read_Mutation_Response = {
  __typename?: 'chat_sessions_read_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Sessions_Read>;
};

/** on_conflict condition type for table "chat_sessions_read" */
export type Chat_Sessions_Read_On_Conflict = {
  constraint: Chat_Sessions_Read_Constraint;
  update_columns?: Array<Chat_Sessions_Read_Update_Column>;
  where?: InputMaybe<Chat_Sessions_Read_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_sessions_read". */
export type Chat_Sessions_Read_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  chatSessionId?: InputMaybe<Order_By>;
  chat_session?: InputMaybe<Chat_Sessions_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastViewed?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_sessions_read */
export type Chat_Sessions_Read_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "chat_sessions_read" */
export type Chat_Sessions_Read_Select_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'chatSessionId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'lastViewed'
  /** column name */
  | 'staffId'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "chat_sessions_read" */
export type Chat_Sessions_Read_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastViewed?: InputMaybe<Scalars['timestamptz']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "chat_sessions_read" */
export type Chat_Sessions_Read_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Sessions_Read_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Sessions_Read_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastViewed?: InputMaybe<Scalars['timestamptz']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "chat_sessions_read" */
export type Chat_Sessions_Read_Update_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'chatSessionId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'lastViewed'
  /** column name */
  | 'staffId'
  /** column name */
  | 'updatedAt';

export type Chat_Sessions_Read_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Sessions_Read_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Sessions_Read_Bool_Exp;
};

/** select columns of table "chat_sessions" */
export type Chat_Sessions_Select_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdByAccountId'
  /** column name */
  | 'deleted'
  /** column name */
  | 'id'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt';

/** select "chat_sessions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "chat_sessions" */
export type Chat_Sessions_Select_Column_Chat_Sessions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  'deleted';

/** select "chat_sessions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "chat_sessions" */
export type Chat_Sessions_Select_Column_Chat_Sessions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  'deleted';

/** input type for updating data in table "chat_sessions" */
export type Chat_Sessions_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByAccountId?: InputMaybe<Scalars['uuid']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Chat_Sessions_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "chat_sessions_staff" */
export type Chat_Sessions_Staff = {
  __typename?: 'chat_sessions_staff';
  active: Scalars['Boolean'];
  chatSessionId: Scalars['uuid'];
  /** An object relationship */
  chat_session: Chat_Sessions;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  staff: Staff;
  staffId: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "chat_sessions_staff" */
export type Chat_Sessions_Staff_Aggregate = {
  __typename?: 'chat_sessions_staff_aggregate';
  aggregate?: Maybe<Chat_Sessions_Staff_Aggregate_Fields>;
  nodes: Array<Chat_Sessions_Staff>;
};

export type Chat_Sessions_Staff_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Chat_Sessions_Staff_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Chat_Sessions_Staff_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Chat_Sessions_Staff_Aggregate_Bool_Exp_Count>;
};

export type Chat_Sessions_Staff_Aggregate_Bool_Exp_Bool_And = {
  arguments: Chat_Sessions_Staff_Select_Column_Chat_Sessions_Staff_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Sessions_Staff_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Chat_Sessions_Staff_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Chat_Sessions_Staff_Select_Column_Chat_Sessions_Staff_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Sessions_Staff_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Chat_Sessions_Staff_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Chat_Sessions_Staff_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Chat_Sessions_Staff_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "chat_sessions_staff" */
export type Chat_Sessions_Staff_Aggregate_Fields = {
  __typename?: 'chat_sessions_staff_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Sessions_Staff_Max_Fields>;
  min?: Maybe<Chat_Sessions_Staff_Min_Fields>;
};

/** aggregate fields of "chat_sessions_staff" */
export type Chat_Sessions_Staff_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Sessions_Staff_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "chat_sessions_staff" */
export type Chat_Sessions_Staff_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Chat_Sessions_Staff_Max_Order_By>;
  min?: InputMaybe<Chat_Sessions_Staff_Min_Order_By>;
};

/** input type for inserting array relation for remote table "chat_sessions_staff" */
export type Chat_Sessions_Staff_Arr_Rel_Insert_Input = {
  data: Array<Chat_Sessions_Staff_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Sessions_Staff_On_Conflict>;
};

/** Boolean expression to filter rows from the table "chat_sessions_staff". All fields are combined with a logical 'AND'. */
export type Chat_Sessions_Staff_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Sessions_Staff_Bool_Exp>>;
  _not?: InputMaybe<Chat_Sessions_Staff_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Sessions_Staff_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  chatSessionId?: InputMaybe<Uuid_Comparison_Exp>;
  chat_session?: InputMaybe<Chat_Sessions_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_sessions_staff" */
export type Chat_Sessions_Staff_Constraint =
  /** unique or primary key constraint on columns "chatSessionId", "staffId" */
  | 'chat_sessions_staff_chatSessionId_staffId_key'
  /** unique or primary key constraint on columns "id" */
  | 'chat_sessions_staff_pkey';

/** input type for inserting data into table "chat_sessions_staff" */
export type Chat_Sessions_Staff_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  chat_session?: InputMaybe<Chat_Sessions_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Chat_Sessions_Staff_Max_Fields = {
  __typename?: 'chat_sessions_staff_max_fields';
  chatSessionId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  staffId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "chat_sessions_staff" */
export type Chat_Sessions_Staff_Max_Order_By = {
  chatSessionId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Chat_Sessions_Staff_Min_Fields = {
  __typename?: 'chat_sessions_staff_min_fields';
  chatSessionId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  staffId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "chat_sessions_staff" */
export type Chat_Sessions_Staff_Min_Order_By = {
  chatSessionId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "chat_sessions_staff" */
export type Chat_Sessions_Staff_Mutation_Response = {
  __typename?: 'chat_sessions_staff_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Sessions_Staff>;
};

/** on_conflict condition type for table "chat_sessions_staff" */
export type Chat_Sessions_Staff_On_Conflict = {
  constraint: Chat_Sessions_Staff_Constraint;
  update_columns?: Array<Chat_Sessions_Staff_Update_Column>;
  where?: InputMaybe<Chat_Sessions_Staff_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_sessions_staff". */
export type Chat_Sessions_Staff_Order_By = {
  active?: InputMaybe<Order_By>;
  chatSessionId?: InputMaybe<Order_By>;
  chat_session?: InputMaybe<Chat_Sessions_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_sessions_staff */
export type Chat_Sessions_Staff_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "chat_sessions_staff" */
export type Chat_Sessions_Staff_Select_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'chatSessionId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'staffId'
  /** column name */
  | 'updated_at';

/** select "chat_sessions_staff_aggregate_bool_exp_bool_and_arguments_columns" columns of table "chat_sessions_staff" */
export type Chat_Sessions_Staff_Select_Column_Chat_Sessions_Staff_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  'active';

/** select "chat_sessions_staff_aggregate_bool_exp_bool_or_arguments_columns" columns of table "chat_sessions_staff" */
export type Chat_Sessions_Staff_Select_Column_Chat_Sessions_Staff_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  'active';

/** input type for updating data in table "chat_sessions_staff" */
export type Chat_Sessions_Staff_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "chat_sessions_staff" */
export type Chat_Sessions_Staff_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Sessions_Staff_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Sessions_Staff_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  chatSessionId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "chat_sessions_staff" */
export type Chat_Sessions_Staff_Update_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'chatSessionId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'staffId'
  /** column name */
  | 'updated_at';

export type Chat_Sessions_Staff_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Sessions_Staff_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Sessions_Staff_Bool_Exp;
};

/** columns and relationships of "chat_sessions_status" */
export type Chat_Sessions_Status = {
  __typename?: 'chat_sessions_status';
  /** An array relationship */
  chat_sessions: Array<Chat_Sessions>;
  /** An aggregate relationship */
  chat_sessions_aggregate: Chat_Sessions_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "chat_sessions_status" */
export type Chat_Sessions_StatusChat_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Bool_Exp>;
};

/** columns and relationships of "chat_sessions_status" */
export type Chat_Sessions_StatusChat_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Bool_Exp>;
};

/** aggregated selection of "chat_sessions_status" */
export type Chat_Sessions_Status_Aggregate = {
  __typename?: 'chat_sessions_status_aggregate';
  aggregate?: Maybe<Chat_Sessions_Status_Aggregate_Fields>;
  nodes: Array<Chat_Sessions_Status>;
};

/** aggregate fields of "chat_sessions_status" */
export type Chat_Sessions_Status_Aggregate_Fields = {
  __typename?: 'chat_sessions_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Chat_Sessions_Status_Max_Fields>;
  min?: Maybe<Chat_Sessions_Status_Min_Fields>;
};

/** aggregate fields of "chat_sessions_status" */
export type Chat_Sessions_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chat_Sessions_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "chat_sessions_status". All fields are combined with a logical 'AND'. */
export type Chat_Sessions_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Chat_Sessions_Status_Bool_Exp>>;
  _not?: InputMaybe<Chat_Sessions_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Chat_Sessions_Status_Bool_Exp>>;
  chat_sessions?: InputMaybe<Chat_Sessions_Bool_Exp>;
  chat_sessions_aggregate?: InputMaybe<Chat_Sessions_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_sessions_status" */
export type Chat_Sessions_Status_Constraint =
  /** unique or primary key constraint on columns "value" */
  'chat_sessions_status_pkey';

export type Chat_Sessions_Status_Enum =
  /** A closed chat session */
  | 'CLOSED'
  /** A created chat session */
  | 'CREATED'
  /** Chat session ready for response */
  | 'READY'
  /** Chat session ready for provider */
  | 'READY_FOR_PROVIDER';

/** Boolean expression to compare columns of type "chat_sessions_status_enum". All fields are combined with logical 'AND'. */
export type Chat_Sessions_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Chat_Sessions_Status_Enum>;
  _in?: InputMaybe<Array<Chat_Sessions_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Chat_Sessions_Status_Enum>;
  _nin?: InputMaybe<Array<Chat_Sessions_Status_Enum>>;
};

/** input type for inserting data into table "chat_sessions_status" */
export type Chat_Sessions_Status_Insert_Input = {
  chat_sessions?: InputMaybe<Chat_Sessions_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Chat_Sessions_Status_Max_Fields = {
  __typename?: 'chat_sessions_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Chat_Sessions_Status_Min_Fields = {
  __typename?: 'chat_sessions_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "chat_sessions_status" */
export type Chat_Sessions_Status_Mutation_Response = {
  __typename?: 'chat_sessions_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Sessions_Status>;
};

/** input type for inserting object relation for remote table "chat_sessions_status" */
export type Chat_Sessions_Status_Obj_Rel_Insert_Input = {
  data: Chat_Sessions_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Chat_Sessions_Status_On_Conflict>;
};

/** on_conflict condition type for table "chat_sessions_status" */
export type Chat_Sessions_Status_On_Conflict = {
  constraint: Chat_Sessions_Status_Constraint;
  update_columns?: Array<Chat_Sessions_Status_Update_Column>;
  where?: InputMaybe<Chat_Sessions_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_sessions_status". */
export type Chat_Sessions_Status_Order_By = {
  chat_sessions_aggregate?: InputMaybe<Chat_Sessions_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: chat_sessions_status */
export type Chat_Sessions_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "chat_sessions_status" */
export type Chat_Sessions_Status_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "chat_sessions_status" */
export type Chat_Sessions_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "chat_sessions_status" */
export type Chat_Sessions_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Sessions_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Sessions_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "chat_sessions_status" */
export type Chat_Sessions_Status_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Chat_Sessions_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Sessions_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Sessions_Status_Bool_Exp;
};

/** Streaming cursor of the table "chat_sessions" */
export type Chat_Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Sessions_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByAccountId?: InputMaybe<Scalars['uuid']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Chat_Sessions_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "chat_sessions" */
export type Chat_Sessions_Update_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdByAccountId'
  /** column name */
  | 'deleted'
  /** column name */
  | 'id'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt';

export type Chat_Sessions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chat_Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Chat_Sessions_Bool_Exp;
};

/** columns and relationships of "clinic_location_types" */
export type Clinic_Location_Types = {
  __typename?: 'clinic_location_types';
  /** An array relationship */
  clinics: Array<Clinics>;
  /** An aggregate relationship */
  clinics_aggregate: Clinics_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "clinic_location_types" */
export type Clinic_Location_TypesClinicsArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Order_By>>;
  where?: InputMaybe<Clinics_Bool_Exp>;
};

/** columns and relationships of "clinic_location_types" */
export type Clinic_Location_TypesClinics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Order_By>>;
  where?: InputMaybe<Clinics_Bool_Exp>;
};

/** aggregated selection of "clinic_location_types" */
export type Clinic_Location_Types_Aggregate = {
  __typename?: 'clinic_location_types_aggregate';
  aggregate?: Maybe<Clinic_Location_Types_Aggregate_Fields>;
  nodes: Array<Clinic_Location_Types>;
};

/** aggregate fields of "clinic_location_types" */
export type Clinic_Location_Types_Aggregate_Fields = {
  __typename?: 'clinic_location_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Clinic_Location_Types_Max_Fields>;
  min?: Maybe<Clinic_Location_Types_Min_Fields>;
};

/** aggregate fields of "clinic_location_types" */
export type Clinic_Location_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Clinic_Location_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "clinic_location_types". All fields are combined with a logical 'AND'. */
export type Clinic_Location_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Clinic_Location_Types_Bool_Exp>>;
  _not?: InputMaybe<Clinic_Location_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Clinic_Location_Types_Bool_Exp>>;
  clinics?: InputMaybe<Clinics_Bool_Exp>;
  clinics_aggregate?: InputMaybe<Clinics_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "clinic_location_types" */
export type Clinic_Location_Types_Constraint =
  /** unique or primary key constraint on columns "value" */
  'clinic_location_types_pkey';

export type Clinic_Location_Types_Enum =
  /** Event clinic */
  | 'EVENT'
  /** Mobile location */
  | 'MOBILE'
  /** Physical brick and mortar location */
  | 'PHYSICAL'
  /** Remote Clinic Type */
  | 'REMOTE'
  /** Virtual location */
  | 'VIRTUAL';

/** Boolean expression to compare columns of type "clinic_location_types_enum". All fields are combined with logical 'AND'. */
export type Clinic_Location_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Clinic_Location_Types_Enum>;
  _in?: InputMaybe<Array<Clinic_Location_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Clinic_Location_Types_Enum>;
  _nin?: InputMaybe<Array<Clinic_Location_Types_Enum>>;
};

/** input type for inserting data into table "clinic_location_types" */
export type Clinic_Location_Types_Insert_Input = {
  clinics?: InputMaybe<Clinics_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Clinic_Location_Types_Max_Fields = {
  __typename?: 'clinic_location_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Clinic_Location_Types_Min_Fields = {
  __typename?: 'clinic_location_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "clinic_location_types" */
export type Clinic_Location_Types_Mutation_Response = {
  __typename?: 'clinic_location_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clinic_Location_Types>;
};

/** input type for inserting object relation for remote table "clinic_location_types" */
export type Clinic_Location_Types_Obj_Rel_Insert_Input = {
  data: Clinic_Location_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Clinic_Location_Types_On_Conflict>;
};

/** on_conflict condition type for table "clinic_location_types" */
export type Clinic_Location_Types_On_Conflict = {
  constraint: Clinic_Location_Types_Constraint;
  update_columns?: Array<Clinic_Location_Types_Update_Column>;
  where?: InputMaybe<Clinic_Location_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "clinic_location_types". */
export type Clinic_Location_Types_Order_By = {
  clinics_aggregate?: InputMaybe<Clinics_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clinic_location_types */
export type Clinic_Location_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "clinic_location_types" */
export type Clinic_Location_Types_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "clinic_location_types" */
export type Clinic_Location_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "clinic_location_types" */
export type Clinic_Location_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clinic_Location_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clinic_Location_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "clinic_location_types" */
export type Clinic_Location_Types_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Clinic_Location_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Clinic_Location_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Clinic_Location_Types_Bool_Exp;
};

/** columns and relationships of "clinic_status" */
export type Clinic_Status = {
  __typename?: 'clinic_status';
  /** An array relationship */
  clinics: Array<Clinics>;
  /** An aggregate relationship */
  clinics_aggregate: Clinics_Aggregate;
  description: Scalars['String'];
  name: Scalars['String'];
};

/** columns and relationships of "clinic_status" */
export type Clinic_StatusClinicsArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Order_By>>;
  where?: InputMaybe<Clinics_Bool_Exp>;
};

/** columns and relationships of "clinic_status" */
export type Clinic_StatusClinics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Order_By>>;
  where?: InputMaybe<Clinics_Bool_Exp>;
};

/** aggregated selection of "clinic_status" */
export type Clinic_Status_Aggregate = {
  __typename?: 'clinic_status_aggregate';
  aggregate?: Maybe<Clinic_Status_Aggregate_Fields>;
  nodes: Array<Clinic_Status>;
};

/** aggregate fields of "clinic_status" */
export type Clinic_Status_Aggregate_Fields = {
  __typename?: 'clinic_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Clinic_Status_Max_Fields>;
  min?: Maybe<Clinic_Status_Min_Fields>;
};

/** aggregate fields of "clinic_status" */
export type Clinic_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Clinic_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "clinic_status". All fields are combined with a logical 'AND'. */
export type Clinic_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Clinic_Status_Bool_Exp>>;
  _not?: InputMaybe<Clinic_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Clinic_Status_Bool_Exp>>;
  clinics?: InputMaybe<Clinics_Bool_Exp>;
  clinics_aggregate?: InputMaybe<Clinics_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "clinic_status" */
export type Clinic_Status_Constraint =
  /** unique or primary key constraint on columns "name" */
  'clinic_status_pkey';

export type Clinic_Status_Enum =
  /** Hidden */
  | 'HIDDEN'
  /** OPEN */
  | 'OPEN'
  /** Pending Open */
  | 'PENDING_OPEN'
  /** Permanently Closed */
  | 'PERMANENTLY_CLOSED'
  /** Temporarily Closed */
  | 'TEMPORARILY_CLOSED';

/** Boolean expression to compare columns of type "clinic_status_enum". All fields are combined with logical 'AND'. */
export type Clinic_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Clinic_Status_Enum>;
  _in?: InputMaybe<Array<Clinic_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Clinic_Status_Enum>;
  _nin?: InputMaybe<Array<Clinic_Status_Enum>>;
};

/** input type for inserting data into table "clinic_status" */
export type Clinic_Status_Insert_Input = {
  clinics?: InputMaybe<Clinics_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Clinic_Status_Max_Fields = {
  __typename?: 'clinic_status_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Clinic_Status_Min_Fields = {
  __typename?: 'clinic_status_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "clinic_status" */
export type Clinic_Status_Mutation_Response = {
  __typename?: 'clinic_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clinic_Status>;
};

/** input type for inserting object relation for remote table "clinic_status" */
export type Clinic_Status_Obj_Rel_Insert_Input = {
  data: Clinic_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Clinic_Status_On_Conflict>;
};

/** on_conflict condition type for table "clinic_status" */
export type Clinic_Status_On_Conflict = {
  constraint: Clinic_Status_Constraint;
  update_columns?: Array<Clinic_Status_Update_Column>;
  where?: InputMaybe<Clinic_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "clinic_status". */
export type Clinic_Status_Order_By = {
  clinics_aggregate?: InputMaybe<Clinics_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clinic_status */
export type Clinic_Status_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "clinic_status" */
export type Clinic_Status_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** input type for updating data in table "clinic_status" */
export type Clinic_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "clinic_status" */
export type Clinic_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clinic_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clinic_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "clinic_status" */
export type Clinic_Status_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

export type Clinic_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Clinic_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Clinic_Status_Bool_Exp;
};

/** columns and relationships of "clinics" */
export type Clinics = {
  __typename?: 'clinics';
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Accounts_Aggregate;
  active: Scalars['Boolean'];
  address: Scalars['String'];
  /** An array relationship */
  appointment_blocks: Array<Appointment_Blocks>;
  /** An aggregate relationship */
  appointment_blocks_aggregate: Appointment_Blocks_Aggregate;
  /** An array relationship */
  appointment_reservations: Array<Appointment_Reservations>;
  /** An aggregate relationship */
  appointment_reservations_aggregate: Appointment_Reservations_Aggregate;
  /** An array relationship */
  appointments: Array<Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Appointments_Aggregate;
  /** An array relationship */
  care_type_durations: Array<Care_Type_Duration>;
  /** An aggregate relationship */
  care_type_durations_aggregate: Care_Type_Duration_Aggregate;
  /** An array relationship */
  charges: Array<Charges>;
  /** An aggregate relationship */
  charges_aggregate: Charges_Aggregate;
  /** An object relationship */
  clinic?: Maybe<Clinics>;
  clinicCode: Scalars['String'];
  /** An object relationship */
  clinic_location_type: Clinic_Location_Types;
  /** An object relationship */
  clinic_status: Clinic_Status;
  /** An array relationship */
  clinics: Array<Clinics>;
  /** An aggregate relationship */
  clinics_aggregate: Clinics_Aggregate;
  /** An array relationship */
  clinics_properties: Array<Clinics_Properties>;
  /** An aggregate relationship */
  clinics_properties_aggregate: Clinics_Properties_Aggregate;
  /** An array relationship */
  clinics_regions: Array<Clinics_Regions>;
  /** An aggregate relationship */
  clinics_regions_aggregate: Clinics_Regions_Aggregate;
  close: Scalars['time'];
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  devices: Array<Devices>;
  /** An aggregate relationship */
  devices_aggregate: Devices_Aggregate;
  faxNumber?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  latitude?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['geography']>;
  locationType: Clinic_Location_Types_Enum;
  /** An array relationship */
  locations: Array<Locations>;
  /** An aggregate relationship */
  locations_aggregate: Locations_Aggregate;
  longitude?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  open: Scalars['time'];
  parentClinicId?: Maybe<Scalars['uuid']>;
  phoneNumber: Scalars['String'];
  /** An array relationship */
  phone_numbers: Array<Phone_Numbers>;
  /** An aggregate relationship */
  phone_numbers_aggregate: Phone_Numbers_Aggregate;
  /** An array relationship */
  rooms: Array<Rooms>;
  /** An aggregate relationship */
  rooms_aggregate: Rooms_Aggregate;
  /** An array relationship */
  staff_schedules: Array<Staff_Schedule>;
  /** An aggregate relationship */
  staff_schedules_aggregate: Staff_Schedule_Aggregate;
  /** An array relationship */
  staffs: Array<Staff>;
  /** An aggregate relationship */
  staffs_aggregate: Staff_Aggregate;
  status: Clinic_Status_Enum;
  stripeAccountId?: Maybe<Scalars['String']>;
  /** An array relationship */
  tasks_notifications: Array<Tasks_Notifications>;
  /** An aggregate relationship */
  tasks_notifications_aggregate: Tasks_Notifications_Aggregate;
  timeZone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  wiwAdminUserId?: Maybe<Scalars['Int']>;
  wiwLocationId?: Maybe<Scalars['String']>;
};

/** columns and relationships of "clinics" */
export type ClinicsAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsAppointment_BlocksArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Blocks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Blocks_Order_By>>;
  where?: InputMaybe<Appointment_Blocks_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsAppointment_Blocks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Blocks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Blocks_Order_By>>;
  where?: InputMaybe<Appointment_Blocks_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsAppointment_ReservationsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Reservations_Order_By>>;
  where?: InputMaybe<Appointment_Reservations_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsAppointment_Reservations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Reservations_Order_By>>;
  where?: InputMaybe<Appointment_Reservations_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsCare_Type_DurationsArgs = {
  distinct_on?: InputMaybe<Array<Care_Type_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Care_Type_Duration_Order_By>>;
  where?: InputMaybe<Care_Type_Duration_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsCare_Type_Durations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Care_Type_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Care_Type_Duration_Order_By>>;
  where?: InputMaybe<Care_Type_Duration_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsChargesArgs = {
  distinct_on?: InputMaybe<Array<Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charges_Order_By>>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsCharges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charges_Order_By>>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsClinicsArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Order_By>>;
  where?: InputMaybe<Clinics_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsClinics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Order_By>>;
  where?: InputMaybe<Clinics_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsClinics_PropertiesArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Properties_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Properties_Order_By>>;
  where?: InputMaybe<Clinics_Properties_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsClinics_Properties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Properties_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Properties_Order_By>>;
  where?: InputMaybe<Clinics_Properties_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsClinics_RegionsArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Regions_Order_By>>;
  where?: InputMaybe<Clinics_Regions_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsClinics_Regions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Regions_Order_By>>;
  where?: InputMaybe<Clinics_Regions_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsDevicesArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsLocationsArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsPhone_NumbersArgs = {
  distinct_on?: InputMaybe<Array<Phone_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phone_Numbers_Order_By>>;
  where?: InputMaybe<Phone_Numbers_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsPhone_Numbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Phone_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phone_Numbers_Order_By>>;
  where?: InputMaybe<Phone_Numbers_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsRoomsArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsRooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsStaff_SchedulesArgs = {
  distinct_on?: InputMaybe<Array<Staff_Schedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Schedule_Order_By>>;
  where?: InputMaybe<Staff_Schedule_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsStaff_Schedules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Schedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Schedule_Order_By>>;
  where?: InputMaybe<Staff_Schedule_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsStaffsArgs = {
  distinct_on?: InputMaybe<Array<Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Order_By>>;
  where?: InputMaybe<Staff_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsStaffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Order_By>>;
  where?: InputMaybe<Staff_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsTasks_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

/** columns and relationships of "clinics" */
export type ClinicsTasks_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

/** aggregated selection of "clinics" */
export type Clinics_Aggregate = {
  __typename?: 'clinics_aggregate';
  aggregate?: Maybe<Clinics_Aggregate_Fields>;
  nodes: Array<Clinics>;
};

export type Clinics_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Clinics_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Clinics_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Clinics_Aggregate_Bool_Exp_Count>;
};

export type Clinics_Aggregate_Bool_Exp_Bool_And = {
  arguments: Clinics_Select_Column_Clinics_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Clinics_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Clinics_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Clinics_Select_Column_Clinics_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Clinics_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Clinics_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Clinics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Clinics_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "clinics" */
export type Clinics_Aggregate_Fields = {
  __typename?: 'clinics_aggregate_fields';
  avg?: Maybe<Clinics_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Clinics_Max_Fields>;
  min?: Maybe<Clinics_Min_Fields>;
  stddev?: Maybe<Clinics_Stddev_Fields>;
  stddev_pop?: Maybe<Clinics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Clinics_Stddev_Samp_Fields>;
  sum?: Maybe<Clinics_Sum_Fields>;
  var_pop?: Maybe<Clinics_Var_Pop_Fields>;
  var_samp?: Maybe<Clinics_Var_Samp_Fields>;
  variance?: Maybe<Clinics_Variance_Fields>;
};

/** aggregate fields of "clinics" */
export type Clinics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Clinics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clinics" */
export type Clinics_Aggregate_Order_By = {
  avg?: InputMaybe<Clinics_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Clinics_Max_Order_By>;
  min?: InputMaybe<Clinics_Min_Order_By>;
  stddev?: InputMaybe<Clinics_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Clinics_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Clinics_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Clinics_Sum_Order_By>;
  var_pop?: InputMaybe<Clinics_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Clinics_Var_Samp_Order_By>;
  variance?: InputMaybe<Clinics_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "clinics" */
export type Clinics_Arr_Rel_Insert_Input = {
  data: Array<Clinics_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Clinics_On_Conflict>;
};

/** aggregate avg on columns */
export type Clinics_Avg_Fields = {
  __typename?: 'clinics_avg_fields';
  wiwAdminUserId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "clinics" */
export type Clinics_Avg_Order_By = {
  wiwAdminUserId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "clinics". All fields are combined with a logical 'AND'. */
export type Clinics_Bool_Exp = {
  _and?: InputMaybe<Array<Clinics_Bool_Exp>>;
  _not?: InputMaybe<Clinics_Bool_Exp>;
  _or?: InputMaybe<Array<Clinics_Bool_Exp>>;
  accounts?: InputMaybe<Accounts_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Bool_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  address?: InputMaybe<String_Comparison_Exp>;
  appointment_blocks?: InputMaybe<Appointment_Blocks_Bool_Exp>;
  appointment_blocks_aggregate?: InputMaybe<Appointment_Blocks_Aggregate_Bool_Exp>;
  appointment_reservations?: InputMaybe<Appointment_Reservations_Bool_Exp>;
  appointment_reservations_aggregate?: InputMaybe<Appointment_Reservations_Aggregate_Bool_Exp>;
  appointments?: InputMaybe<Appointments_Bool_Exp>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Bool_Exp>;
  care_type_durations?: InputMaybe<Care_Type_Duration_Bool_Exp>;
  care_type_durations_aggregate?: InputMaybe<Care_Type_Duration_Aggregate_Bool_Exp>;
  charges?: InputMaybe<Charges_Bool_Exp>;
  charges_aggregate?: InputMaybe<Charges_Aggregate_Bool_Exp>;
  clinic?: InputMaybe<Clinics_Bool_Exp>;
  clinicCode?: InputMaybe<String_Comparison_Exp>;
  clinic_location_type?: InputMaybe<Clinic_Location_Types_Bool_Exp>;
  clinic_status?: InputMaybe<Clinic_Status_Bool_Exp>;
  clinics?: InputMaybe<Clinics_Bool_Exp>;
  clinics_aggregate?: InputMaybe<Clinics_Aggregate_Bool_Exp>;
  clinics_properties?: InputMaybe<Clinics_Properties_Bool_Exp>;
  clinics_properties_aggregate?: InputMaybe<Clinics_Properties_Aggregate_Bool_Exp>;
  clinics_regions?: InputMaybe<Clinics_Regions_Bool_Exp>;
  clinics_regions_aggregate?: InputMaybe<Clinics_Regions_Aggregate_Bool_Exp>;
  close?: InputMaybe<Time_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  devices?: InputMaybe<Devices_Bool_Exp>;
  devices_aggregate?: InputMaybe<Devices_Aggregate_Bool_Exp>;
  faxNumber?: InputMaybe<String_Comparison_Exp>;
  googlePlaceId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  latitude?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Geography_Comparison_Exp>;
  locationType?: InputMaybe<Clinic_Location_Types_Enum_Comparison_Exp>;
  locations?: InputMaybe<Locations_Bool_Exp>;
  locations_aggregate?: InputMaybe<Locations_Aggregate_Bool_Exp>;
  longitude?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  open?: InputMaybe<Time_Comparison_Exp>;
  parentClinicId?: InputMaybe<Uuid_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  phone_numbers?: InputMaybe<Phone_Numbers_Bool_Exp>;
  phone_numbers_aggregate?: InputMaybe<Phone_Numbers_Aggregate_Bool_Exp>;
  rooms?: InputMaybe<Rooms_Bool_Exp>;
  rooms_aggregate?: InputMaybe<Rooms_Aggregate_Bool_Exp>;
  staff_schedules?: InputMaybe<Staff_Schedule_Bool_Exp>;
  staff_schedules_aggregate?: InputMaybe<Staff_Schedule_Aggregate_Bool_Exp>;
  staffs?: InputMaybe<Staff_Bool_Exp>;
  staffs_aggregate?: InputMaybe<Staff_Aggregate_Bool_Exp>;
  status?: InputMaybe<Clinic_Status_Enum_Comparison_Exp>;
  stripeAccountId?: InputMaybe<String_Comparison_Exp>;
  tasks_notifications?: InputMaybe<Tasks_Notifications_Bool_Exp>;
  tasks_notifications_aggregate?: InputMaybe<Tasks_Notifications_Aggregate_Bool_Exp>;
  timeZone?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  wiwAdminUserId?: InputMaybe<Int_Comparison_Exp>;
  wiwLocationId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "clinics" */
export type Clinics_Constraint =
  /** unique or primary key constraint on columns "clinicCode" */
  | 'clinics_clinicCode_key'
  /** unique or primary key constraint on columns "id" */
  | 'clinics_pkey'
  /** unique or primary key constraint on columns "wiwLocationId" */
  | 'clinics_wiwLocationId_key';

/** input type for incrementing numeric columns in table "clinics" */
export type Clinics_Inc_Input = {
  wiwAdminUserId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "clinics" */
export type Clinics_Insert_Input = {
  accounts?: InputMaybe<Accounts_Arr_Rel_Insert_Input>;
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<Scalars['String']>;
  appointment_blocks?: InputMaybe<Appointment_Blocks_Arr_Rel_Insert_Input>;
  appointment_reservations?: InputMaybe<Appointment_Reservations_Arr_Rel_Insert_Input>;
  appointments?: InputMaybe<Appointments_Arr_Rel_Insert_Input>;
  care_type_durations?: InputMaybe<Care_Type_Duration_Arr_Rel_Insert_Input>;
  charges?: InputMaybe<Charges_Arr_Rel_Insert_Input>;
  clinic?: InputMaybe<Clinics_Obj_Rel_Insert_Input>;
  clinicCode?: InputMaybe<Scalars['String']>;
  clinic_location_type?: InputMaybe<Clinic_Location_Types_Obj_Rel_Insert_Input>;
  clinic_status?: InputMaybe<Clinic_Status_Obj_Rel_Insert_Input>;
  clinics?: InputMaybe<Clinics_Arr_Rel_Insert_Input>;
  clinics_properties?: InputMaybe<Clinics_Properties_Arr_Rel_Insert_Input>;
  clinics_regions?: InputMaybe<Clinics_Regions_Arr_Rel_Insert_Input>;
  close?: InputMaybe<Scalars['time']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  devices?: InputMaybe<Devices_Arr_Rel_Insert_Input>;
  faxNumber?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  latitude?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['geography']>;
  locationType?: InputMaybe<Clinic_Location_Types_Enum>;
  locations?: InputMaybe<Locations_Arr_Rel_Insert_Input>;
  longitude?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  open?: InputMaybe<Scalars['time']>;
  parentClinicId?: InputMaybe<Scalars['uuid']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phone_numbers?: InputMaybe<Phone_Numbers_Arr_Rel_Insert_Input>;
  rooms?: InputMaybe<Rooms_Arr_Rel_Insert_Input>;
  staff_schedules?: InputMaybe<Staff_Schedule_Arr_Rel_Insert_Input>;
  staffs?: InputMaybe<Staff_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Clinic_Status_Enum>;
  stripeAccountId?: InputMaybe<Scalars['String']>;
  tasks_notifications?: InputMaybe<Tasks_Notifications_Arr_Rel_Insert_Input>;
  timeZone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wiwAdminUserId?: InputMaybe<Scalars['Int']>;
  wiwLocationId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Clinics_Max_Fields = {
  __typename?: 'clinics_max_fields';
  address?: Maybe<Scalars['String']>;
  clinicCode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  faxNumber?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentClinicId?: Maybe<Scalars['uuid']>;
  phoneNumber?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wiwAdminUserId?: Maybe<Scalars['Int']>;
  wiwLocationId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "clinics" */
export type Clinics_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  clinicCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  faxNumber?: InputMaybe<Order_By>;
  googlePlaceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parentClinicId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  stripeAccountId?: InputMaybe<Order_By>;
  timeZone?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wiwAdminUserId?: InputMaybe<Order_By>;
  wiwLocationId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Clinics_Min_Fields = {
  __typename?: 'clinics_min_fields';
  address?: Maybe<Scalars['String']>;
  clinicCode?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  faxNumber?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentClinicId?: Maybe<Scalars['uuid']>;
  phoneNumber?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wiwAdminUserId?: Maybe<Scalars['Int']>;
  wiwLocationId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "clinics" */
export type Clinics_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  clinicCode?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  faxNumber?: InputMaybe<Order_By>;
  googlePlaceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parentClinicId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  stripeAccountId?: InputMaybe<Order_By>;
  timeZone?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wiwAdminUserId?: InputMaybe<Order_By>;
  wiwLocationId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "clinics" */
export type Clinics_Mutation_Response = {
  __typename?: 'clinics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clinics>;
};

/** input type for inserting object relation for remote table "clinics" */
export type Clinics_Obj_Rel_Insert_Input = {
  data: Clinics_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Clinics_On_Conflict>;
};

/** on_conflict condition type for table "clinics" */
export type Clinics_On_Conflict = {
  constraint: Clinics_Constraint;
  update_columns?: Array<Clinics_Update_Column>;
  where?: InputMaybe<Clinics_Bool_Exp>;
};

/** Ordering options when selecting data from "clinics". */
export type Clinics_Order_By = {
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Order_By>;
  active?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  appointment_blocks_aggregate?: InputMaybe<Appointment_Blocks_Aggregate_Order_By>;
  appointment_reservations_aggregate?: InputMaybe<Appointment_Reservations_Aggregate_Order_By>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Order_By>;
  care_type_durations_aggregate?: InputMaybe<Care_Type_Duration_Aggregate_Order_By>;
  charges_aggregate?: InputMaybe<Charges_Aggregate_Order_By>;
  clinic?: InputMaybe<Clinics_Order_By>;
  clinicCode?: InputMaybe<Order_By>;
  clinic_location_type?: InputMaybe<Clinic_Location_Types_Order_By>;
  clinic_status?: InputMaybe<Clinic_Status_Order_By>;
  clinics_aggregate?: InputMaybe<Clinics_Aggregate_Order_By>;
  clinics_properties_aggregate?: InputMaybe<Clinics_Properties_Aggregate_Order_By>;
  clinics_regions_aggregate?: InputMaybe<Clinics_Regions_Aggregate_Order_By>;
  close?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  devices_aggregate?: InputMaybe<Devices_Aggregate_Order_By>;
  faxNumber?: InputMaybe<Order_By>;
  googlePlaceId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  locationType?: InputMaybe<Order_By>;
  locations_aggregate?: InputMaybe<Locations_Aggregate_Order_By>;
  longitude?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  open?: InputMaybe<Order_By>;
  parentClinicId?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  phone_numbers_aggregate?: InputMaybe<Phone_Numbers_Aggregate_Order_By>;
  rooms_aggregate?: InputMaybe<Rooms_Aggregate_Order_By>;
  staff_schedules_aggregate?: InputMaybe<Staff_Schedule_Aggregate_Order_By>;
  staffs_aggregate?: InputMaybe<Staff_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  stripeAccountId?: InputMaybe<Order_By>;
  tasks_notifications_aggregate?: InputMaybe<Tasks_Notifications_Aggregate_Order_By>;
  timeZone?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wiwAdminUserId?: InputMaybe<Order_By>;
  wiwLocationId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clinics */
export type Clinics_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "clinics_properties" */
export type Clinics_Properties = {
  __typename?: 'clinics_properties';
  /** An object relationship */
  clinic: Clinics;
  clinicId: Scalars['uuid'];
  /** An object relationship */
  clinics_properties_type: Clinics_Properties_Types;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  property: Clinics_Properties_Types_Enum;
  updated_at: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "clinics_properties" */
export type Clinics_Properties_Aggregate = {
  __typename?: 'clinics_properties_aggregate';
  aggregate?: Maybe<Clinics_Properties_Aggregate_Fields>;
  nodes: Array<Clinics_Properties>;
};

export type Clinics_Properties_Aggregate_Bool_Exp = {
  count?: InputMaybe<Clinics_Properties_Aggregate_Bool_Exp_Count>;
};

export type Clinics_Properties_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Clinics_Properties_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Clinics_Properties_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "clinics_properties" */
export type Clinics_Properties_Aggregate_Fields = {
  __typename?: 'clinics_properties_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Clinics_Properties_Max_Fields>;
  min?: Maybe<Clinics_Properties_Min_Fields>;
};

/** aggregate fields of "clinics_properties" */
export type Clinics_Properties_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Clinics_Properties_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clinics_properties" */
export type Clinics_Properties_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Clinics_Properties_Max_Order_By>;
  min?: InputMaybe<Clinics_Properties_Min_Order_By>;
};

/** input type for inserting array relation for remote table "clinics_properties" */
export type Clinics_Properties_Arr_Rel_Insert_Input = {
  data: Array<Clinics_Properties_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Clinics_Properties_On_Conflict>;
};

/** Boolean expression to filter rows from the table "clinics_properties". All fields are combined with a logical 'AND'. */
export type Clinics_Properties_Bool_Exp = {
  _and?: InputMaybe<Array<Clinics_Properties_Bool_Exp>>;
  _not?: InputMaybe<Clinics_Properties_Bool_Exp>;
  _or?: InputMaybe<Array<Clinics_Properties_Bool_Exp>>;
  clinic?: InputMaybe<Clinics_Bool_Exp>;
  clinicId?: InputMaybe<Uuid_Comparison_Exp>;
  clinics_properties_type?: InputMaybe<Clinics_Properties_Types_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  property?: InputMaybe<Clinics_Properties_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "clinics_properties" */
export type Clinics_Properties_Constraint =
  /** unique or primary key constraint on columns "id" */
  'clinics_properties_pkey';

/** input type for inserting data into table "clinics_properties" */
export type Clinics_Properties_Insert_Input = {
  clinic?: InputMaybe<Clinics_Obj_Rel_Insert_Input>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  clinics_properties_type?: InputMaybe<Clinics_Properties_Types_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  property?: InputMaybe<Clinics_Properties_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Clinics_Properties_Max_Fields = {
  __typename?: 'clinics_properties_max_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "clinics_properties" */
export type Clinics_Properties_Max_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Clinics_Properties_Min_Fields = {
  __typename?: 'clinics_properties_min_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "clinics_properties" */
export type Clinics_Properties_Min_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "clinics_properties" */
export type Clinics_Properties_Mutation_Response = {
  __typename?: 'clinics_properties_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clinics_Properties>;
};

/** on_conflict condition type for table "clinics_properties" */
export type Clinics_Properties_On_Conflict = {
  constraint: Clinics_Properties_Constraint;
  update_columns?: Array<Clinics_Properties_Update_Column>;
  where?: InputMaybe<Clinics_Properties_Bool_Exp>;
};

/** Ordering options when selecting data from "clinics_properties". */
export type Clinics_Properties_Order_By = {
  clinic?: InputMaybe<Clinics_Order_By>;
  clinicId?: InputMaybe<Order_By>;
  clinics_properties_type?: InputMaybe<Clinics_Properties_Types_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  property?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clinics_properties */
export type Clinics_Properties_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "clinics_properties" */
export type Clinics_Properties_Select_Column =
  /** column name */
  | 'clinicId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'property'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'value';

/** input type for updating data in table "clinics_properties" */
export type Clinics_Properties_Set_Input = {
  clinicId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  property?: InputMaybe<Clinics_Properties_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "clinics_properties" */
export type Clinics_Properties_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clinics_Properties_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clinics_Properties_Stream_Cursor_Value_Input = {
  clinicId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  property?: InputMaybe<Clinics_Properties_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "clinics_properties_types" */
export type Clinics_Properties_Types = {
  __typename?: 'clinics_properties_types';
  /** An array relationship */
  clinics_properties: Array<Clinics_Properties>;
  /** An aggregate relationship */
  clinics_properties_aggregate: Clinics_Properties_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "clinics_properties_types" */
export type Clinics_Properties_TypesClinics_PropertiesArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Properties_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Properties_Order_By>>;
  where?: InputMaybe<Clinics_Properties_Bool_Exp>;
};

/** columns and relationships of "clinics_properties_types" */
export type Clinics_Properties_TypesClinics_Properties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Properties_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Properties_Order_By>>;
  where?: InputMaybe<Clinics_Properties_Bool_Exp>;
};

/** aggregated selection of "clinics_properties_types" */
export type Clinics_Properties_Types_Aggregate = {
  __typename?: 'clinics_properties_types_aggregate';
  aggregate?: Maybe<Clinics_Properties_Types_Aggregate_Fields>;
  nodes: Array<Clinics_Properties_Types>;
};

/** aggregate fields of "clinics_properties_types" */
export type Clinics_Properties_Types_Aggregate_Fields = {
  __typename?: 'clinics_properties_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Clinics_Properties_Types_Max_Fields>;
  min?: Maybe<Clinics_Properties_Types_Min_Fields>;
};

/** aggregate fields of "clinics_properties_types" */
export type Clinics_Properties_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Clinics_Properties_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "clinics_properties_types". All fields are combined with a logical 'AND'. */
export type Clinics_Properties_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Clinics_Properties_Types_Bool_Exp>>;
  _not?: InputMaybe<Clinics_Properties_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Clinics_Properties_Types_Bool_Exp>>;
  clinics_properties?: InputMaybe<Clinics_Properties_Bool_Exp>;
  clinics_properties_aggregate?: InputMaybe<Clinics_Properties_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "clinics_properties_types" */
export type Clinics_Properties_Types_Constraint =
  /** unique or primary key constraint on columns "value" */
  'clinics_properties_types_pkey';

export type Clinics_Properties_Types_Enum =
  /** Offers COVID-19 vaccine */
  | 'COVID_19_VACCINE'
  /** Where parking is located */
  | 'PARKING'
  /** Offers telemedicine */
  | 'TELEMEDICINE';

/** Boolean expression to compare columns of type "clinics_properties_types_enum". All fields are combined with logical 'AND'. */
export type Clinics_Properties_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Clinics_Properties_Types_Enum>;
  _in?: InputMaybe<Array<Clinics_Properties_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Clinics_Properties_Types_Enum>;
  _nin?: InputMaybe<Array<Clinics_Properties_Types_Enum>>;
};

/** input type for inserting data into table "clinics_properties_types" */
export type Clinics_Properties_Types_Insert_Input = {
  clinics_properties?: InputMaybe<Clinics_Properties_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Clinics_Properties_Types_Max_Fields = {
  __typename?: 'clinics_properties_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Clinics_Properties_Types_Min_Fields = {
  __typename?: 'clinics_properties_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "clinics_properties_types" */
export type Clinics_Properties_Types_Mutation_Response = {
  __typename?: 'clinics_properties_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clinics_Properties_Types>;
};

/** input type for inserting object relation for remote table "clinics_properties_types" */
export type Clinics_Properties_Types_Obj_Rel_Insert_Input = {
  data: Clinics_Properties_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Clinics_Properties_Types_On_Conflict>;
};

/** on_conflict condition type for table "clinics_properties_types" */
export type Clinics_Properties_Types_On_Conflict = {
  constraint: Clinics_Properties_Types_Constraint;
  update_columns?: Array<Clinics_Properties_Types_Update_Column>;
  where?: InputMaybe<Clinics_Properties_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "clinics_properties_types". */
export type Clinics_Properties_Types_Order_By = {
  clinics_properties_aggregate?: InputMaybe<Clinics_Properties_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clinics_properties_types */
export type Clinics_Properties_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "clinics_properties_types" */
export type Clinics_Properties_Types_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "clinics_properties_types" */
export type Clinics_Properties_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "clinics_properties_types" */
export type Clinics_Properties_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clinics_Properties_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clinics_Properties_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "clinics_properties_types" */
export type Clinics_Properties_Types_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Clinics_Properties_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Clinics_Properties_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Clinics_Properties_Types_Bool_Exp;
};

/** update columns of table "clinics_properties" */
export type Clinics_Properties_Update_Column =
  /** column name */
  | 'clinicId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'property'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'value';

export type Clinics_Properties_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Clinics_Properties_Set_Input>;
  /** filter the rows which have to be updated */
  where: Clinics_Properties_Bool_Exp;
};

/** columns and relationships of "clinics_regions" */
export type Clinics_Regions = {
  __typename?: 'clinics_regions';
  /** An object relationship */
  clinic: Clinics;
  clinicId: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  region: Regions;
  regionId: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "clinics_regions" */
export type Clinics_Regions_Aggregate = {
  __typename?: 'clinics_regions_aggregate';
  aggregate?: Maybe<Clinics_Regions_Aggregate_Fields>;
  nodes: Array<Clinics_Regions>;
};

export type Clinics_Regions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Clinics_Regions_Aggregate_Bool_Exp_Count>;
};

export type Clinics_Regions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Clinics_Regions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Clinics_Regions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "clinics_regions" */
export type Clinics_Regions_Aggregate_Fields = {
  __typename?: 'clinics_regions_aggregate_fields';
  avg?: Maybe<Clinics_Regions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Clinics_Regions_Max_Fields>;
  min?: Maybe<Clinics_Regions_Min_Fields>;
  stddev?: Maybe<Clinics_Regions_Stddev_Fields>;
  stddev_pop?: Maybe<Clinics_Regions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Clinics_Regions_Stddev_Samp_Fields>;
  sum?: Maybe<Clinics_Regions_Sum_Fields>;
  var_pop?: Maybe<Clinics_Regions_Var_Pop_Fields>;
  var_samp?: Maybe<Clinics_Regions_Var_Samp_Fields>;
  variance?: Maybe<Clinics_Regions_Variance_Fields>;
};

/** aggregate fields of "clinics_regions" */
export type Clinics_Regions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Clinics_Regions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clinics_regions" */
export type Clinics_Regions_Aggregate_Order_By = {
  avg?: InputMaybe<Clinics_Regions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Clinics_Regions_Max_Order_By>;
  min?: InputMaybe<Clinics_Regions_Min_Order_By>;
  stddev?: InputMaybe<Clinics_Regions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Clinics_Regions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Clinics_Regions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Clinics_Regions_Sum_Order_By>;
  var_pop?: InputMaybe<Clinics_Regions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Clinics_Regions_Var_Samp_Order_By>;
  variance?: InputMaybe<Clinics_Regions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "clinics_regions" */
export type Clinics_Regions_Arr_Rel_Insert_Input = {
  data: Array<Clinics_Regions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Clinics_Regions_On_Conflict>;
};

/** aggregate avg on columns */
export type Clinics_Regions_Avg_Fields = {
  __typename?: 'clinics_regions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "clinics_regions" */
export type Clinics_Regions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "clinics_regions". All fields are combined with a logical 'AND'. */
export type Clinics_Regions_Bool_Exp = {
  _and?: InputMaybe<Array<Clinics_Regions_Bool_Exp>>;
  _not?: InputMaybe<Clinics_Regions_Bool_Exp>;
  _or?: InputMaybe<Array<Clinics_Regions_Bool_Exp>>;
  clinic?: InputMaybe<Clinics_Bool_Exp>;
  clinicId?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  region?: InputMaybe<Regions_Bool_Exp>;
  regionId?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "clinics_regions" */
export type Clinics_Regions_Constraint =
  /** unique or primary key constraint on columns "regionId", "clinicId" */
  | 'clinics_regions_clinicId_regionId_key'
  /** unique or primary key constraint on columns "id" */
  | 'clinics_regions_pkey';

/** input type for incrementing numeric columns in table "clinics_regions" */
export type Clinics_Regions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  regionId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "clinics_regions" */
export type Clinics_Regions_Insert_Input = {
  clinic?: InputMaybe<Clinics_Obj_Rel_Insert_Input>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  region?: InputMaybe<Regions_Obj_Rel_Insert_Input>;
  regionId?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Clinics_Regions_Max_Fields = {
  __typename?: 'clinics_regions_max_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  regionId?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "clinics_regions" */
export type Clinics_Regions_Max_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Clinics_Regions_Min_Fields = {
  __typename?: 'clinics_regions_min_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  regionId?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "clinics_regions" */
export type Clinics_Regions_Min_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "clinics_regions" */
export type Clinics_Regions_Mutation_Response = {
  __typename?: 'clinics_regions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clinics_Regions>;
};

/** on_conflict condition type for table "clinics_regions" */
export type Clinics_Regions_On_Conflict = {
  constraint: Clinics_Regions_Constraint;
  update_columns?: Array<Clinics_Regions_Update_Column>;
  where?: InputMaybe<Clinics_Regions_Bool_Exp>;
};

/** Ordering options when selecting data from "clinics_regions". */
export type Clinics_Regions_Order_By = {
  clinic?: InputMaybe<Clinics_Order_By>;
  clinicId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  region?: InputMaybe<Regions_Order_By>;
  regionId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clinics_regions */
export type Clinics_Regions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "clinics_regions" */
export type Clinics_Regions_Select_Column =
  /** column name */
  | 'clinicId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'regionId'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "clinics_regions" */
export type Clinics_Regions_Set_Input = {
  clinicId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  regionId?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Clinics_Regions_Stddev_Fields = {
  __typename?: 'clinics_regions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "clinics_regions" */
export type Clinics_Regions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Clinics_Regions_Stddev_Pop_Fields = {
  __typename?: 'clinics_regions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "clinics_regions" */
export type Clinics_Regions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Clinics_Regions_Stddev_Samp_Fields = {
  __typename?: 'clinics_regions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "clinics_regions" */
export type Clinics_Regions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "clinics_regions" */
export type Clinics_Regions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clinics_Regions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clinics_Regions_Stream_Cursor_Value_Input = {
  clinicId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  regionId?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Clinics_Regions_Sum_Fields = {
  __typename?: 'clinics_regions_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  regionId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "clinics_regions" */
export type Clinics_Regions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
};

/** update columns of table "clinics_regions" */
export type Clinics_Regions_Update_Column =
  /** column name */
  | 'clinicId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'regionId'
  /** column name */
  | 'updated_at';

export type Clinics_Regions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Clinics_Regions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Clinics_Regions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Clinics_Regions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Clinics_Regions_Var_Pop_Fields = {
  __typename?: 'clinics_regions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "clinics_regions" */
export type Clinics_Regions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Clinics_Regions_Var_Samp_Fields = {
  __typename?: 'clinics_regions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "clinics_regions" */
export type Clinics_Regions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Clinics_Regions_Variance_Fields = {
  __typename?: 'clinics_regions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "clinics_regions" */
export type Clinics_Regions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
};

/** select columns of table "clinics" */
export type Clinics_Select_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'address'
  /** column name */
  | 'clinicCode'
  /** column name */
  | 'close'
  /** column name */
  | 'created_at'
  /** column name */
  | 'faxNumber'
  /** column name */
  | 'googlePlaceId'
  /** column name */
  | 'id'
  /** column name */
  | 'latitude'
  /** column name */
  | 'location'
  /** column name */
  | 'locationType'
  /** column name */
  | 'longitude'
  /** column name */
  | 'name'
  /** column name */
  | 'open'
  /** column name */
  | 'parentClinicId'
  /** column name */
  | 'phoneNumber'
  /** column name */
  | 'status'
  /** column name */
  | 'stripeAccountId'
  /** column name */
  | 'timeZone'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'wiwAdminUserId'
  /** column name */
  | 'wiwLocationId';

/** select "clinics_aggregate_bool_exp_bool_and_arguments_columns" columns of table "clinics" */
export type Clinics_Select_Column_Clinics_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  'active';

/** select "clinics_aggregate_bool_exp_bool_or_arguments_columns" columns of table "clinics" */
export type Clinics_Select_Column_Clinics_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  'active';

/** input type for updating data in table "clinics" */
export type Clinics_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<Scalars['String']>;
  clinicCode?: InputMaybe<Scalars['String']>;
  close?: InputMaybe<Scalars['time']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  faxNumber?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  latitude?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['geography']>;
  locationType?: InputMaybe<Clinic_Location_Types_Enum>;
  longitude?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  open?: InputMaybe<Scalars['time']>;
  parentClinicId?: InputMaybe<Scalars['uuid']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Clinic_Status_Enum>;
  stripeAccountId?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wiwAdminUserId?: InputMaybe<Scalars['Int']>;
  wiwLocationId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Clinics_Stddev_Fields = {
  __typename?: 'clinics_stddev_fields';
  wiwAdminUserId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "clinics" */
export type Clinics_Stddev_Order_By = {
  wiwAdminUserId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Clinics_Stddev_Pop_Fields = {
  __typename?: 'clinics_stddev_pop_fields';
  wiwAdminUserId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "clinics" */
export type Clinics_Stddev_Pop_Order_By = {
  wiwAdminUserId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Clinics_Stddev_Samp_Fields = {
  __typename?: 'clinics_stddev_samp_fields';
  wiwAdminUserId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "clinics" */
export type Clinics_Stddev_Samp_Order_By = {
  wiwAdminUserId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "clinics" */
export type Clinics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clinics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clinics_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<Scalars['String']>;
  clinicCode?: InputMaybe<Scalars['String']>;
  close?: InputMaybe<Scalars['time']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  faxNumber?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  latitude?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['geography']>;
  locationType?: InputMaybe<Clinic_Location_Types_Enum>;
  longitude?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  open?: InputMaybe<Scalars['time']>;
  parentClinicId?: InputMaybe<Scalars['uuid']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Clinic_Status_Enum>;
  stripeAccountId?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wiwAdminUserId?: InputMaybe<Scalars['Int']>;
  wiwLocationId?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Clinics_Sum_Fields = {
  __typename?: 'clinics_sum_fields';
  wiwAdminUserId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "clinics" */
export type Clinics_Sum_Order_By = {
  wiwAdminUserId?: InputMaybe<Order_By>;
};

/** update columns of table "clinics" */
export type Clinics_Update_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'address'
  /** column name */
  | 'clinicCode'
  /** column name */
  | 'close'
  /** column name */
  | 'created_at'
  /** column name */
  | 'faxNumber'
  /** column name */
  | 'googlePlaceId'
  /** column name */
  | 'id'
  /** column name */
  | 'latitude'
  /** column name */
  | 'location'
  /** column name */
  | 'locationType'
  /** column name */
  | 'longitude'
  /** column name */
  | 'name'
  /** column name */
  | 'open'
  /** column name */
  | 'parentClinicId'
  /** column name */
  | 'phoneNumber'
  /** column name */
  | 'status'
  /** column name */
  | 'stripeAccountId'
  /** column name */
  | 'timeZone'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'wiwAdminUserId'
  /** column name */
  | 'wiwLocationId';

export type Clinics_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Clinics_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Clinics_Set_Input>;
  /** filter the rows which have to be updated */
  where: Clinics_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Clinics_Var_Pop_Fields = {
  __typename?: 'clinics_var_pop_fields';
  wiwAdminUserId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "clinics" */
export type Clinics_Var_Pop_Order_By = {
  wiwAdminUserId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Clinics_Var_Samp_Fields = {
  __typename?: 'clinics_var_samp_fields';
  wiwAdminUserId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "clinics" */
export type Clinics_Var_Samp_Order_By = {
  wiwAdminUserId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Clinics_Variance_Fields = {
  __typename?: 'clinics_variance_fields';
  wiwAdminUserId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "clinics" */
export type Clinics_Variance_Order_By = {
  wiwAdminUserId?: InputMaybe<Order_By>;
};

/** columns and relationships of "communication_history" */
export type Communication_History = {
  __typename?: 'communication_history';
  /** An object relationship */
  account?: Maybe<Accounts>;
  accountId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  appointment?: Maybe<Appointments>;
  appointmentId?: Maybe<Scalars['uuid']>;
  body?: Maybe<Scalars['String']>;
  /** An object relationship */
  communication_history_direction?: Maybe<Communication_History_Directions>;
  /** An array relationship */
  communication_history_tags: Array<Communication_History_Tags>;
  /** An aggregate relationship */
  communication_history_tags_aggregate: Communication_History_Tags_Aggregate;
  /** An object relationship */
  communication_history_type: Communication_History_Types;
  completedAt?: Maybe<Scalars['timestamptz']>;
  completedCallDuration?: Maybe<Scalars['Int']>;
  connectedToPhoneId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  direction?: Maybe<Communication_History_Directions_Enum>;
  externalId?: Maybe<Scalars['String']>;
  from: Scalars['String'];
  /** An array relationship */
  global_notes: Array<Global_Notes>;
  /** An aggregate relationship */
  global_notes_aggregate: Global_Notes_Aggregate;
  id: Scalars['uuid'];
  metadata?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  patient?: Maybe<Patients>;
  patientId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  phone_number?: Maybe<Phone_Numbers>;
  /** An object relationship */
  staff?: Maybe<Staff>;
  staffId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  to: Scalars['String'];
  type: Communication_History_Types_Enum;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "communication_history" */
export type Communication_HistoryCommunication_History_TagsArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Tags_Order_By>>;
  where?: InputMaybe<Communication_History_Tags_Bool_Exp>;
};

/** columns and relationships of "communication_history" */
export type Communication_HistoryCommunication_History_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Tags_Order_By>>;
  where?: InputMaybe<Communication_History_Tags_Bool_Exp>;
};

/** columns and relationships of "communication_history" */
export type Communication_HistoryGlobal_NotesArgs = {
  distinct_on?: InputMaybe<Array<Global_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Notes_Order_By>>;
  where?: InputMaybe<Global_Notes_Bool_Exp>;
};

/** columns and relationships of "communication_history" */
export type Communication_HistoryGlobal_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Notes_Order_By>>;
  where?: InputMaybe<Global_Notes_Bool_Exp>;
};

/** columns and relationships of "communication_history" */
export type Communication_HistoryMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "communication_history" */
export type Communication_History_Aggregate = {
  __typename?: 'communication_history_aggregate';
  aggregate?: Maybe<Communication_History_Aggregate_Fields>;
  nodes: Array<Communication_History>;
};

export type Communication_History_Aggregate_Bool_Exp = {
  count?: InputMaybe<Communication_History_Aggregate_Bool_Exp_Count>;
};

export type Communication_History_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Communication_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Communication_History_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "communication_history" */
export type Communication_History_Aggregate_Fields = {
  __typename?: 'communication_history_aggregate_fields';
  avg?: Maybe<Communication_History_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Communication_History_Max_Fields>;
  min?: Maybe<Communication_History_Min_Fields>;
  stddev?: Maybe<Communication_History_Stddev_Fields>;
  stddev_pop?: Maybe<Communication_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Communication_History_Stddev_Samp_Fields>;
  sum?: Maybe<Communication_History_Sum_Fields>;
  var_pop?: Maybe<Communication_History_Var_Pop_Fields>;
  var_samp?: Maybe<Communication_History_Var_Samp_Fields>;
  variance?: Maybe<Communication_History_Variance_Fields>;
};

/** aggregate fields of "communication_history" */
export type Communication_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Communication_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "communication_history" */
export type Communication_History_Aggregate_Order_By = {
  avg?: InputMaybe<Communication_History_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Communication_History_Max_Order_By>;
  min?: InputMaybe<Communication_History_Min_Order_By>;
  stddev?: InputMaybe<Communication_History_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Communication_History_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Communication_History_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Communication_History_Sum_Order_By>;
  var_pop?: InputMaybe<Communication_History_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Communication_History_Var_Samp_Order_By>;
  variance?: InputMaybe<Communication_History_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Communication_History_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "communication_history" */
export type Communication_History_Arr_Rel_Insert_Input = {
  data: Array<Communication_History_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Communication_History_On_Conflict>;
};

/** aggregate avg on columns */
export type Communication_History_Avg_Fields = {
  __typename?: 'communication_history_avg_fields';
  completedCallDuration?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "communication_history" */
export type Communication_History_Avg_Order_By = {
  completedCallDuration?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "communication_history". All fields are combined with a logical 'AND'. */
export type Communication_History_Bool_Exp = {
  _and?: InputMaybe<Array<Communication_History_Bool_Exp>>;
  _not?: InputMaybe<Communication_History_Bool_Exp>;
  _or?: InputMaybe<Array<Communication_History_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  appointment?: InputMaybe<Appointments_Bool_Exp>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  body?: InputMaybe<String_Comparison_Exp>;
  communication_history_direction?: InputMaybe<Communication_History_Directions_Bool_Exp>;
  communication_history_tags?: InputMaybe<Communication_History_Tags_Bool_Exp>;
  communication_history_tags_aggregate?: InputMaybe<Communication_History_Tags_Aggregate_Bool_Exp>;
  communication_history_type?: InputMaybe<Communication_History_Types_Bool_Exp>;
  completedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  completedCallDuration?: InputMaybe<Int_Comparison_Exp>;
  connectedToPhoneId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  direction?: InputMaybe<Communication_History_Directions_Enum_Comparison_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  from?: InputMaybe<String_Comparison_Exp>;
  global_notes?: InputMaybe<Global_Notes_Bool_Exp>;
  global_notes_aggregate?: InputMaybe<Global_Notes_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  phone_number?: InputMaybe<Phone_Numbers_Bool_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  to?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Communication_History_Types_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "communication_history" */
export type Communication_History_Constraint =
  /** unique or primary key constraint on columns "id" */
  'communication_history_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Communication_History_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Communication_History_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Communication_History_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "communication_history_directions" */
export type Communication_History_Directions = {
  __typename?: 'communication_history_directions';
  /** An array relationship */
  communication_histories: Array<Communication_History>;
  /** An aggregate relationship */
  communication_histories_aggregate: Communication_History_Aggregate;
  description: Scalars['String'];
  name: Scalars['String'];
};

/** columns and relationships of "communication_history_directions" */
export type Communication_History_DirectionsCommunication_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Order_By>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

/** columns and relationships of "communication_history_directions" */
export type Communication_History_DirectionsCommunication_Histories_AggregateArgs =
  {
    distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Communication_History_Order_By>>;
    where?: InputMaybe<Communication_History_Bool_Exp>;
  };

/** aggregated selection of "communication_history_directions" */
export type Communication_History_Directions_Aggregate = {
  __typename?: 'communication_history_directions_aggregate';
  aggregate?: Maybe<Communication_History_Directions_Aggregate_Fields>;
  nodes: Array<Communication_History_Directions>;
};

/** aggregate fields of "communication_history_directions" */
export type Communication_History_Directions_Aggregate_Fields = {
  __typename?: 'communication_history_directions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Communication_History_Directions_Max_Fields>;
  min?: Maybe<Communication_History_Directions_Min_Fields>;
};

/** aggregate fields of "communication_history_directions" */
export type Communication_History_Directions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Communication_History_Directions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "communication_history_directions". All fields are combined with a logical 'AND'. */
export type Communication_History_Directions_Bool_Exp = {
  _and?: InputMaybe<Array<Communication_History_Directions_Bool_Exp>>;
  _not?: InputMaybe<Communication_History_Directions_Bool_Exp>;
  _or?: InputMaybe<Array<Communication_History_Directions_Bool_Exp>>;
  communication_histories?: InputMaybe<Communication_History_Bool_Exp>;
  communication_histories_aggregate?: InputMaybe<Communication_History_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "communication_history_directions" */
export type Communication_History_Directions_Constraint =
  /** unique or primary key constraint on columns "name" */
  'communication_history_directions_pkey';

export type Communication_History_Directions_Enum =
  /** Inbound communication */
  | 'INBOUND'
  /** Outbound communication */
  | 'OUTBOUND';

/** Boolean expression to compare columns of type "communication_history_directions_enum". All fields are combined with logical 'AND'. */
export type Communication_History_Directions_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Communication_History_Directions_Enum>;
  _in?: InputMaybe<Array<Communication_History_Directions_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Communication_History_Directions_Enum>;
  _nin?: InputMaybe<Array<Communication_History_Directions_Enum>>;
};

/** input type for inserting data into table "communication_history_directions" */
export type Communication_History_Directions_Insert_Input = {
  communication_histories?: InputMaybe<Communication_History_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Communication_History_Directions_Max_Fields = {
  __typename?: 'communication_history_directions_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Communication_History_Directions_Min_Fields = {
  __typename?: 'communication_history_directions_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "communication_history_directions" */
export type Communication_History_Directions_Mutation_Response = {
  __typename?: 'communication_history_directions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Communication_History_Directions>;
};

/** input type for inserting object relation for remote table "communication_history_directions" */
export type Communication_History_Directions_Obj_Rel_Insert_Input = {
  data: Communication_History_Directions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Communication_History_Directions_On_Conflict>;
};

/** on_conflict condition type for table "communication_history_directions" */
export type Communication_History_Directions_On_Conflict = {
  constraint: Communication_History_Directions_Constraint;
  update_columns?: Array<Communication_History_Directions_Update_Column>;
  where?: InputMaybe<Communication_History_Directions_Bool_Exp>;
};

/** Ordering options when selecting data from "communication_history_directions". */
export type Communication_History_Directions_Order_By = {
  communication_histories_aggregate?: InputMaybe<Communication_History_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: communication_history_directions */
export type Communication_History_Directions_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "communication_history_directions" */
export type Communication_History_Directions_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** input type for updating data in table "communication_history_directions" */
export type Communication_History_Directions_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "communication_history_directions" */
export type Communication_History_Directions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Communication_History_Directions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Communication_History_Directions_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "communication_history_directions" */
export type Communication_History_Directions_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

export type Communication_History_Directions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Communication_History_Directions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Communication_History_Directions_Bool_Exp;
};

/** input type for incrementing numeric columns in table "communication_history" */
export type Communication_History_Inc_Input = {
  completedCallDuration?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "communication_history" */
export type Communication_History_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['uuid']>;
  appointment?: InputMaybe<Appointments_Obj_Rel_Insert_Input>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  body?: InputMaybe<Scalars['String']>;
  communication_history_direction?: InputMaybe<Communication_History_Directions_Obj_Rel_Insert_Input>;
  communication_history_tags?: InputMaybe<Communication_History_Tags_Arr_Rel_Insert_Input>;
  communication_history_type?: InputMaybe<Communication_History_Types_Obj_Rel_Insert_Input>;
  completedAt?: InputMaybe<Scalars['timestamptz']>;
  completedCallDuration?: InputMaybe<Scalars['Int']>;
  connectedToPhoneId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  direction?: InputMaybe<Communication_History_Directions_Enum>;
  externalId?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  global_notes?: InputMaybe<Global_Notes_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  phone_number?: InputMaybe<Phone_Numbers_Obj_Rel_Insert_Input>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Communication_History_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Communication_History_Max_Fields = {
  __typename?: 'communication_history_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  appointmentId?: Maybe<Scalars['uuid']>;
  body?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  completedCallDuration?: Maybe<Scalars['Int']>;
  connectedToPhoneId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  staffId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "communication_history" */
export type Communication_History_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  completedAt?: InputMaybe<Order_By>;
  completedCallDuration?: InputMaybe<Order_By>;
  connectedToPhoneId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Communication_History_Min_Fields = {
  __typename?: 'communication_history_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  appointmentId?: Maybe<Scalars['uuid']>;
  body?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  completedCallDuration?: Maybe<Scalars['Int']>;
  connectedToPhoneId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  staffId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "communication_history" */
export type Communication_History_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  completedAt?: InputMaybe<Order_By>;
  completedCallDuration?: InputMaybe<Order_By>;
  connectedToPhoneId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "communication_history" */
export type Communication_History_Mutation_Response = {
  __typename?: 'communication_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Communication_History>;
};

/** input type for inserting object relation for remote table "communication_history" */
export type Communication_History_Obj_Rel_Insert_Input = {
  data: Communication_History_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Communication_History_On_Conflict>;
};

/** on_conflict condition type for table "communication_history" */
export type Communication_History_On_Conflict = {
  constraint: Communication_History_Constraint;
  update_columns?: Array<Communication_History_Update_Column>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

/** Ordering options when selecting data from "communication_history". */
export type Communication_History_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  appointment?: InputMaybe<Appointments_Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  body?: InputMaybe<Order_By>;
  communication_history_direction?: InputMaybe<Communication_History_Directions_Order_By>;
  communication_history_tags_aggregate?: InputMaybe<Communication_History_Tags_Aggregate_Order_By>;
  communication_history_type?: InputMaybe<Communication_History_Types_Order_By>;
  completedAt?: InputMaybe<Order_By>;
  completedCallDuration?: InputMaybe<Order_By>;
  connectedToPhoneId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  direction?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  global_notes_aggregate?: InputMaybe<Global_Notes_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Phone_Numbers_Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: communication_history */
export type Communication_History_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Communication_History_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "communication_history" */
export type Communication_History_Select_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'body'
  /** column name */
  | 'completedAt'
  /** column name */
  | 'completedCallDuration'
  /** column name */
  | 'connectedToPhoneId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'direction'
  /** column name */
  | 'externalId'
  /** column name */
  | 'from'
  /** column name */
  | 'id'
  /** column name */
  | 'metadata'
  /** column name */
  | 'patientId'
  /** column name */
  | 'staffId'
  /** column name */
  | 'status'
  /** column name */
  | 'to'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "communication_history" */
export type Communication_History_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  body?: InputMaybe<Scalars['String']>;
  completedAt?: InputMaybe<Scalars['timestamptz']>;
  completedCallDuration?: InputMaybe<Scalars['Int']>;
  connectedToPhoneId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  direction?: InputMaybe<Communication_History_Directions_Enum>;
  externalId?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Communication_History_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Communication_History_Stddev_Fields = {
  __typename?: 'communication_history_stddev_fields';
  completedCallDuration?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "communication_history" */
export type Communication_History_Stddev_Order_By = {
  completedCallDuration?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Communication_History_Stddev_Pop_Fields = {
  __typename?: 'communication_history_stddev_pop_fields';
  completedCallDuration?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "communication_history" */
export type Communication_History_Stddev_Pop_Order_By = {
  completedCallDuration?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Communication_History_Stddev_Samp_Fields = {
  __typename?: 'communication_history_stddev_samp_fields';
  completedCallDuration?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "communication_history" */
export type Communication_History_Stddev_Samp_Order_By = {
  completedCallDuration?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "communication_history" */
export type Communication_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Communication_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Communication_History_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  body?: InputMaybe<Scalars['String']>;
  completedAt?: InputMaybe<Scalars['timestamptz']>;
  completedCallDuration?: InputMaybe<Scalars['Int']>;
  connectedToPhoneId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  direction?: InputMaybe<Communication_History_Directions_Enum>;
  externalId?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Communication_History_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Communication_History_Sum_Fields = {
  __typename?: 'communication_history_sum_fields';
  completedCallDuration?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "communication_history" */
export type Communication_History_Sum_Order_By = {
  completedCallDuration?: InputMaybe<Order_By>;
};

/** columns and relationships of "communication_history_tag_types" */
export type Communication_History_Tag_Types = {
  __typename?: 'communication_history_tag_types';
  /** An array relationship */
  communication_history_tags: Array<Communication_History_Tags>;
  /** An aggregate relationship */
  communication_history_tags_aggregate: Communication_History_Tags_Aggregate;
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** columns and relationships of "communication_history_tag_types" */
export type Communication_History_Tag_TypesCommunication_History_TagsArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Tags_Order_By>>;
  where?: InputMaybe<Communication_History_Tags_Bool_Exp>;
};

/** columns and relationships of "communication_history_tag_types" */
export type Communication_History_Tag_TypesCommunication_History_Tags_AggregateArgs =
  {
    distinct_on?: InputMaybe<Array<Communication_History_Tags_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order_by?: InputMaybe<Array<Communication_History_Tags_Order_By>>;
    where?: InputMaybe<Communication_History_Tags_Bool_Exp>;
  };

/** aggregated selection of "communication_history_tag_types" */
export type Communication_History_Tag_Types_Aggregate = {
  __typename?: 'communication_history_tag_types_aggregate';
  aggregate?: Maybe<Communication_History_Tag_Types_Aggregate_Fields>;
  nodes: Array<Communication_History_Tag_Types>;
};

/** aggregate fields of "communication_history_tag_types" */
export type Communication_History_Tag_Types_Aggregate_Fields = {
  __typename?: 'communication_history_tag_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Communication_History_Tag_Types_Max_Fields>;
  min?: Maybe<Communication_History_Tag_Types_Min_Fields>;
};

/** aggregate fields of "communication_history_tag_types" */
export type Communication_History_Tag_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Communication_History_Tag_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "communication_history_tag_types". All fields are combined with a logical 'AND'. */
export type Communication_History_Tag_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Communication_History_Tag_Types_Bool_Exp>>;
  _not?: InputMaybe<Communication_History_Tag_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Communication_History_Tag_Types_Bool_Exp>>;
  communication_history_tags?: InputMaybe<Communication_History_Tags_Bool_Exp>;
  communication_history_tags_aggregate?: InputMaybe<Communication_History_Tags_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "communication_history_tag_types" */
export type Communication_History_Tag_Types_Constraint =
  /** unique or primary key constraint on columns "value" */
  'communication_history_tag_types_pkey';

export type Communication_History_Tag_Types_Enum =
  /** Admin - Other */
  | 'ADMIN_OTHER'
  /** At-home care */
  | 'AT_HOME_CARE'
  /** Billing */
  | 'BILLING'
  /** Cancellation */
  | 'CANCELLATION'
  /** Confirmation */
  | 'CONFIRMATION'
  /** COVID testing */
  | 'COVID_TESTING'
  /** General triage */
  | 'GENERAL_TRIAGE'
  /** Insurance */
  | 'INSURANCE'
  /** Lab results */
  | 'LAB_RESULTS'
  /** Letter */
  | 'LETTER'
  /** Marketing Outreach */
  | 'MARKETING_OUTREACH'
  /** Medical advice - Other */
  | 'MEDICAL_ADVICE_OTHER'
  /** Medical Records */
  | 'MEDICAL_RECORDS'
  /** Referrals */
  | 'MISC_REFERRALS'
  /** New Visit */
  | 'NEW_VISIT'
  /** Outside Vendor */
  | 'OUTSIDE_VENDOR'
  /** Pharmacy */
  | 'PHARMACY'
  /** Referrals */
  | 'REFERRALS'
  /** Referral to ER */
  | 'REFERRAL_TO_ER'
  /** Reschedule */
  | 'RESCHEDULE'
  /** Scheduling - Other */
  | 'SCHEDULING_OTHER'
  /** Spam */
  | 'SPAM'
  /** Vaccine Questions */
  | 'VACCINE_QUESTIONS'
  /** Visit follow-up */
  | 'VISIT_FOLLOW_UP';

/** Boolean expression to compare columns of type "communication_history_tag_types_enum". All fields are combined with logical 'AND'. */
export type Communication_History_Tag_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Communication_History_Tag_Types_Enum>;
  _in?: InputMaybe<Array<Communication_History_Tag_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Communication_History_Tag_Types_Enum>;
  _nin?: InputMaybe<Array<Communication_History_Tag_Types_Enum>>;
};

/** input type for inserting data into table "communication_history_tag_types" */
export type Communication_History_Tag_Types_Insert_Input = {
  communication_history_tags?: InputMaybe<Communication_History_Tags_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Communication_History_Tag_Types_Max_Fields = {
  __typename?: 'communication_history_tag_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Communication_History_Tag_Types_Min_Fields = {
  __typename?: 'communication_history_tag_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "communication_history_tag_types" */
export type Communication_History_Tag_Types_Mutation_Response = {
  __typename?: 'communication_history_tag_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Communication_History_Tag_Types>;
};

/** input type for inserting object relation for remote table "communication_history_tag_types" */
export type Communication_History_Tag_Types_Obj_Rel_Insert_Input = {
  data: Communication_History_Tag_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Communication_History_Tag_Types_On_Conflict>;
};

/** on_conflict condition type for table "communication_history_tag_types" */
export type Communication_History_Tag_Types_On_Conflict = {
  constraint: Communication_History_Tag_Types_Constraint;
  update_columns?: Array<Communication_History_Tag_Types_Update_Column>;
  where?: InputMaybe<Communication_History_Tag_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "communication_history_tag_types". */
export type Communication_History_Tag_Types_Order_By = {
  communication_history_tags_aggregate?: InputMaybe<Communication_History_Tags_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: communication_history_tag_types */
export type Communication_History_Tag_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "communication_history_tag_types" */
export type Communication_History_Tag_Types_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "communication_history_tag_types" */
export type Communication_History_Tag_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "communication_history_tag_types" */
export type Communication_History_Tag_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Communication_History_Tag_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Communication_History_Tag_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "communication_history_tag_types" */
export type Communication_History_Tag_Types_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Communication_History_Tag_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Communication_History_Tag_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Communication_History_Tag_Types_Bool_Exp;
};

/** columns and relationships of "communication_history_tags" */
export type Communication_History_Tags = {
  __typename?: 'communication_history_tags';
  communicationHistoryId: Scalars['uuid'];
  /** An object relationship */
  communication_history: Communication_History;
  /** An object relationship */
  communication_history_tag_type: Communication_History_Tag_Types;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  tag: Communication_History_Tag_Types_Enum;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "communication_history_tags" */
export type Communication_History_Tags_Aggregate = {
  __typename?: 'communication_history_tags_aggregate';
  aggregate?: Maybe<Communication_History_Tags_Aggregate_Fields>;
  nodes: Array<Communication_History_Tags>;
};

export type Communication_History_Tags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Communication_History_Tags_Aggregate_Bool_Exp_Count>;
};

export type Communication_History_Tags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Communication_History_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Communication_History_Tags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "communication_history_tags" */
export type Communication_History_Tags_Aggregate_Fields = {
  __typename?: 'communication_history_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Communication_History_Tags_Max_Fields>;
  min?: Maybe<Communication_History_Tags_Min_Fields>;
};

/** aggregate fields of "communication_history_tags" */
export type Communication_History_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Communication_History_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "communication_history_tags" */
export type Communication_History_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Communication_History_Tags_Max_Order_By>;
  min?: InputMaybe<Communication_History_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "communication_history_tags" */
export type Communication_History_Tags_Arr_Rel_Insert_Input = {
  data: Array<Communication_History_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Communication_History_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "communication_history_tags". All fields are combined with a logical 'AND'. */
export type Communication_History_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Communication_History_Tags_Bool_Exp>>;
  _not?: InputMaybe<Communication_History_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Communication_History_Tags_Bool_Exp>>;
  communicationHistoryId?: InputMaybe<Uuid_Comparison_Exp>;
  communication_history?: InputMaybe<Communication_History_Bool_Exp>;
  communication_history_tag_type?: InputMaybe<Communication_History_Tag_Types_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tag?: InputMaybe<Communication_History_Tag_Types_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "communication_history_tags" */
export type Communication_History_Tags_Constraint =
  /** unique or primary key constraint on columns "id" */
  'communication_history_tags_pkey';

/** input type for inserting data into table "communication_history_tags" */
export type Communication_History_Tags_Insert_Input = {
  communicationHistoryId?: InputMaybe<Scalars['uuid']>;
  communication_history?: InputMaybe<Communication_History_Obj_Rel_Insert_Input>;
  communication_history_tag_type?: InputMaybe<Communication_History_Tag_Types_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  tag?: InputMaybe<Communication_History_Tag_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Communication_History_Tags_Max_Fields = {
  __typename?: 'communication_history_tags_max_fields';
  communicationHistoryId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "communication_history_tags" */
export type Communication_History_Tags_Max_Order_By = {
  communicationHistoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Communication_History_Tags_Min_Fields = {
  __typename?: 'communication_history_tags_min_fields';
  communicationHistoryId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "communication_history_tags" */
export type Communication_History_Tags_Min_Order_By = {
  communicationHistoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "communication_history_tags" */
export type Communication_History_Tags_Mutation_Response = {
  __typename?: 'communication_history_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Communication_History_Tags>;
};

/** on_conflict condition type for table "communication_history_tags" */
export type Communication_History_Tags_On_Conflict = {
  constraint: Communication_History_Tags_Constraint;
  update_columns?: Array<Communication_History_Tags_Update_Column>;
  where?: InputMaybe<Communication_History_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "communication_history_tags". */
export type Communication_History_Tags_Order_By = {
  communicationHistoryId?: InputMaybe<Order_By>;
  communication_history?: InputMaybe<Communication_History_Order_By>;
  communication_history_tag_type?: InputMaybe<Communication_History_Tag_Types_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: communication_history_tags */
export type Communication_History_Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "communication_history_tags" */
export type Communication_History_Tags_Select_Column =
  /** column name */
  | 'communicationHistoryId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'tag'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "communication_history_tags" */
export type Communication_History_Tags_Set_Input = {
  communicationHistoryId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  tag?: InputMaybe<Communication_History_Tag_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "communication_history_tags" */
export type Communication_History_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Communication_History_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Communication_History_Tags_Stream_Cursor_Value_Input = {
  communicationHistoryId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  tag?: InputMaybe<Communication_History_Tag_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "communication_history_tags" */
export type Communication_History_Tags_Update_Column =
  /** column name */
  | 'communicationHistoryId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'tag'
  /** column name */
  | 'updatedAt';

export type Communication_History_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Communication_History_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Communication_History_Tags_Bool_Exp;
};

/** columns and relationships of "communication_history_types" */
export type Communication_History_Types = {
  __typename?: 'communication_history_types';
  /** An array relationship */
  communication_histories: Array<Communication_History>;
  /** An aggregate relationship */
  communication_histories_aggregate: Communication_History_Aggregate;
  description: Scalars['String'];
  name: Scalars['String'];
};

/** columns and relationships of "communication_history_types" */
export type Communication_History_TypesCommunication_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Order_By>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

/** columns and relationships of "communication_history_types" */
export type Communication_History_TypesCommunication_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Order_By>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

/** aggregated selection of "communication_history_types" */
export type Communication_History_Types_Aggregate = {
  __typename?: 'communication_history_types_aggregate';
  aggregate?: Maybe<Communication_History_Types_Aggregate_Fields>;
  nodes: Array<Communication_History_Types>;
};

/** aggregate fields of "communication_history_types" */
export type Communication_History_Types_Aggregate_Fields = {
  __typename?: 'communication_history_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Communication_History_Types_Max_Fields>;
  min?: Maybe<Communication_History_Types_Min_Fields>;
};

/** aggregate fields of "communication_history_types" */
export type Communication_History_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Communication_History_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "communication_history_types". All fields are combined with a logical 'AND'. */
export type Communication_History_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Communication_History_Types_Bool_Exp>>;
  _not?: InputMaybe<Communication_History_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Communication_History_Types_Bool_Exp>>;
  communication_histories?: InputMaybe<Communication_History_Bool_Exp>;
  communication_histories_aggregate?: InputMaybe<Communication_History_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "communication_history_types" */
export type Communication_History_Types_Constraint =
  /** unique or primary key constraint on columns "name" */
  'communication_history_types_pkey';

export type Communication_History_Types_Enum =
  /** Email */
  | 'EMAIL'
  /** Phone call */
  | 'PHONE_CALL'
  /** Sms */
  | 'SMS';

/** Boolean expression to compare columns of type "communication_history_types_enum". All fields are combined with logical 'AND'. */
export type Communication_History_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Communication_History_Types_Enum>;
  _in?: InputMaybe<Array<Communication_History_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Communication_History_Types_Enum>;
  _nin?: InputMaybe<Array<Communication_History_Types_Enum>>;
};

/** input type for inserting data into table "communication_history_types" */
export type Communication_History_Types_Insert_Input = {
  communication_histories?: InputMaybe<Communication_History_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Communication_History_Types_Max_Fields = {
  __typename?: 'communication_history_types_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Communication_History_Types_Min_Fields = {
  __typename?: 'communication_history_types_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "communication_history_types" */
export type Communication_History_Types_Mutation_Response = {
  __typename?: 'communication_history_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Communication_History_Types>;
};

/** input type for inserting object relation for remote table "communication_history_types" */
export type Communication_History_Types_Obj_Rel_Insert_Input = {
  data: Communication_History_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Communication_History_Types_On_Conflict>;
};

/** on_conflict condition type for table "communication_history_types" */
export type Communication_History_Types_On_Conflict = {
  constraint: Communication_History_Types_Constraint;
  update_columns?: Array<Communication_History_Types_Update_Column>;
  where?: InputMaybe<Communication_History_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "communication_history_types". */
export type Communication_History_Types_Order_By = {
  communication_histories_aggregate?: InputMaybe<Communication_History_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: communication_history_types */
export type Communication_History_Types_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "communication_history_types" */
export type Communication_History_Types_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** input type for updating data in table "communication_history_types" */
export type Communication_History_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "communication_history_types" */
export type Communication_History_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Communication_History_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Communication_History_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "communication_history_types" */
export type Communication_History_Types_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

export type Communication_History_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Communication_History_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Communication_History_Types_Bool_Exp;
};

/** update columns of table "communication_history" */
export type Communication_History_Update_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'body'
  /** column name */
  | 'completedAt'
  /** column name */
  | 'completedCallDuration'
  /** column name */
  | 'connectedToPhoneId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'direction'
  /** column name */
  | 'externalId'
  /** column name */
  | 'from'
  /** column name */
  | 'id'
  /** column name */
  | 'metadata'
  /** column name */
  | 'patientId'
  /** column name */
  | 'staffId'
  /** column name */
  | 'status'
  /** column name */
  | 'to'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt';

export type Communication_History_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Communication_History_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Communication_History_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Communication_History_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Communication_History_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Communication_History_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Communication_History_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Communication_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Communication_History_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Communication_History_Var_Pop_Fields = {
  __typename?: 'communication_history_var_pop_fields';
  completedCallDuration?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "communication_history" */
export type Communication_History_Var_Pop_Order_By = {
  completedCallDuration?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Communication_History_Var_Samp_Fields = {
  __typename?: 'communication_history_var_samp_fields';
  completedCallDuration?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "communication_history" */
export type Communication_History_Var_Samp_Order_By = {
  completedCallDuration?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Communication_History_Variance_Fields = {
  __typename?: 'communication_history_variance_fields';
  completedCallDuration?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "communication_history" */
export type Communication_History_Variance_Order_By = {
  completedCallDuration?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export type Cursor_Ordering =
  /** ascending ordering of the cursor */
  | 'ASC'
  /** descending ordering of the cursor */
  | 'DESC';

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "devices" */
export type Devices = {
  __typename?: 'devices';
  appVersion?: Maybe<Scalars['String']>;
  batteryLevel?: Maybe<Scalars['numeric']>;
  batteryState?: Maybe<Scalars['String']>;
  /** An object relationship */
  clinic?: Maybe<Clinics>;
  clinicCode?: Maybe<Scalars['String']>;
  codepushVersion?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  location?: Maybe<Locations>;
  locationId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  wifiNetwork?: Maybe<Scalars['String']>;
};

/** aggregated selection of "devices" */
export type Devices_Aggregate = {
  __typename?: 'devices_aggregate';
  aggregate?: Maybe<Devices_Aggregate_Fields>;
  nodes: Array<Devices>;
};

export type Devices_Aggregate_Bool_Exp = {
  count?: InputMaybe<Devices_Aggregate_Bool_Exp_Count>;
};

export type Devices_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Devices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Devices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "devices" */
export type Devices_Aggregate_Fields = {
  __typename?: 'devices_aggregate_fields';
  avg?: Maybe<Devices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Devices_Max_Fields>;
  min?: Maybe<Devices_Min_Fields>;
  stddev?: Maybe<Devices_Stddev_Fields>;
  stddev_pop?: Maybe<Devices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Devices_Stddev_Samp_Fields>;
  sum?: Maybe<Devices_Sum_Fields>;
  var_pop?: Maybe<Devices_Var_Pop_Fields>;
  var_samp?: Maybe<Devices_Var_Samp_Fields>;
  variance?: Maybe<Devices_Variance_Fields>;
};

/** aggregate fields of "devices" */
export type Devices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Devices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "devices" */
export type Devices_Aggregate_Order_By = {
  avg?: InputMaybe<Devices_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Devices_Max_Order_By>;
  min?: InputMaybe<Devices_Min_Order_By>;
  stddev?: InputMaybe<Devices_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Devices_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Devices_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Devices_Sum_Order_By>;
  var_pop?: InputMaybe<Devices_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Devices_Var_Samp_Order_By>;
  variance?: InputMaybe<Devices_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "devices" */
export type Devices_Arr_Rel_Insert_Input = {
  data: Array<Devices_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Devices_On_Conflict>;
};

/** aggregate avg on columns */
export type Devices_Avg_Fields = {
  __typename?: 'devices_avg_fields';
  batteryLevel?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "devices" */
export type Devices_Avg_Order_By = {
  batteryLevel?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "devices". All fields are combined with a logical 'AND'. */
export type Devices_Bool_Exp = {
  _and?: InputMaybe<Array<Devices_Bool_Exp>>;
  _not?: InputMaybe<Devices_Bool_Exp>;
  _or?: InputMaybe<Array<Devices_Bool_Exp>>;
  appVersion?: InputMaybe<String_Comparison_Exp>;
  batteryLevel?: InputMaybe<Numeric_Comparison_Exp>;
  batteryState?: InputMaybe<String_Comparison_Exp>;
  clinic?: InputMaybe<Clinics_Bool_Exp>;
  clinicCode?: InputMaybe<String_Comparison_Exp>;
  codepushVersion?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  location?: InputMaybe<Locations_Bool_Exp>;
  locationId?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  osVersion?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  wifiNetwork?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "devices" */
export type Devices_Constraint =
  /** unique or primary key constraint on columns "id" */
  'devices_pkey';

/** input type for incrementing numeric columns in table "devices" */
export type Devices_Inc_Input = {
  batteryLevel?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "devices" */
export type Devices_Insert_Input = {
  appVersion?: InputMaybe<Scalars['String']>;
  batteryLevel?: InputMaybe<Scalars['numeric']>;
  batteryState?: InputMaybe<Scalars['String']>;
  clinic?: InputMaybe<Clinics_Obj_Rel_Insert_Input>;
  clinicCode?: InputMaybe<Scalars['String']>;
  codepushVersion?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  location?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  osVersion?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  wifiNetwork?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Devices_Max_Fields = {
  __typename?: 'devices_max_fields';
  appVersion?: Maybe<Scalars['String']>;
  batteryLevel?: Maybe<Scalars['numeric']>;
  batteryState?: Maybe<Scalars['String']>;
  clinicCode?: Maybe<Scalars['String']>;
  codepushVersion?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  wifiNetwork?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "devices" */
export type Devices_Max_Order_By = {
  appVersion?: InputMaybe<Order_By>;
  batteryLevel?: InputMaybe<Order_By>;
  batteryState?: InputMaybe<Order_By>;
  clinicCode?: InputMaybe<Order_By>;
  codepushVersion?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  osVersion?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  wifiNetwork?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Devices_Min_Fields = {
  __typename?: 'devices_min_fields';
  appVersion?: Maybe<Scalars['String']>;
  batteryLevel?: Maybe<Scalars['numeric']>;
  batteryState?: Maybe<Scalars['String']>;
  clinicCode?: Maybe<Scalars['String']>;
  codepushVersion?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locationId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  osVersion?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  wifiNetwork?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "devices" */
export type Devices_Min_Order_By = {
  appVersion?: InputMaybe<Order_By>;
  batteryLevel?: InputMaybe<Order_By>;
  batteryState?: InputMaybe<Order_By>;
  clinicCode?: InputMaybe<Order_By>;
  codepushVersion?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  osVersion?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  wifiNetwork?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "devices" */
export type Devices_Mutation_Response = {
  __typename?: 'devices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Devices>;
};

/** on_conflict condition type for table "devices" */
export type Devices_On_Conflict = {
  constraint: Devices_Constraint;
  update_columns?: Array<Devices_Update_Column>;
  where?: InputMaybe<Devices_Bool_Exp>;
};

/** Ordering options when selecting data from "devices". */
export type Devices_Order_By = {
  appVersion?: InputMaybe<Order_By>;
  batteryLevel?: InputMaybe<Order_By>;
  batteryState?: InputMaybe<Order_By>;
  clinic?: InputMaybe<Clinics_Order_By>;
  clinicCode?: InputMaybe<Order_By>;
  codepushVersion?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Locations_Order_By>;
  locationId?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  osVersion?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  wifiNetwork?: InputMaybe<Order_By>;
};

/** primary key columns input for table: devices */
export type Devices_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "devices" */
export type Devices_Select_Column =
  /** column name */
  | 'appVersion'
  /** column name */
  | 'batteryLevel'
  /** column name */
  | 'batteryState'
  /** column name */
  | 'clinicCode'
  /** column name */
  | 'codepushVersion'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'locationId'
  /** column name */
  | 'name'
  /** column name */
  | 'osVersion'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'wifiNetwork';

/** input type for updating data in table "devices" */
export type Devices_Set_Input = {
  appVersion?: InputMaybe<Scalars['String']>;
  batteryLevel?: InputMaybe<Scalars['numeric']>;
  batteryState?: InputMaybe<Scalars['String']>;
  clinicCode?: InputMaybe<Scalars['String']>;
  codepushVersion?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  osVersion?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  wifiNetwork?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Devices_Stddev_Fields = {
  __typename?: 'devices_stddev_fields';
  batteryLevel?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "devices" */
export type Devices_Stddev_Order_By = {
  batteryLevel?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Devices_Stddev_Pop_Fields = {
  __typename?: 'devices_stddev_pop_fields';
  batteryLevel?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "devices" */
export type Devices_Stddev_Pop_Order_By = {
  batteryLevel?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Devices_Stddev_Samp_Fields = {
  __typename?: 'devices_stddev_samp_fields';
  batteryLevel?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "devices" */
export type Devices_Stddev_Samp_Order_By = {
  batteryLevel?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "devices" */
export type Devices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Devices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Devices_Stream_Cursor_Value_Input = {
  appVersion?: InputMaybe<Scalars['String']>;
  batteryLevel?: InputMaybe<Scalars['numeric']>;
  batteryState?: InputMaybe<Scalars['String']>;
  clinicCode?: InputMaybe<Scalars['String']>;
  codepushVersion?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  osVersion?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  wifiNetwork?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Devices_Sum_Fields = {
  __typename?: 'devices_sum_fields';
  batteryLevel?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "devices" */
export type Devices_Sum_Order_By = {
  batteryLevel?: InputMaybe<Order_By>;
};

/** update columns of table "devices" */
export type Devices_Update_Column =
  /** column name */
  | 'appVersion'
  /** column name */
  | 'batteryLevel'
  /** column name */
  | 'batteryState'
  /** column name */
  | 'clinicCode'
  /** column name */
  | 'codepushVersion'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'locationId'
  /** column name */
  | 'name'
  /** column name */
  | 'osVersion'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'wifiNetwork';

export type Devices_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Devices_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Devices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Devices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Devices_Var_Pop_Fields = {
  __typename?: 'devices_var_pop_fields';
  batteryLevel?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "devices" */
export type Devices_Var_Pop_Order_By = {
  batteryLevel?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Devices_Var_Samp_Fields = {
  __typename?: 'devices_var_samp_fields';
  batteryLevel?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "devices" */
export type Devices_Var_Samp_Order_By = {
  batteryLevel?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Devices_Variance_Fields = {
  __typename?: 'devices_variance_fields';
  batteryLevel?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "devices" */
export type Devices_Variance_Order_By = {
  batteryLevel?: InputMaybe<Order_By>;
};

/** columns and relationships of "discovered_from" */
export type Discovered_From = {
  __typename?: 'discovered_from';
  /** An object relationship */
  account: Accounts;
  account_id: Scalars['uuid'];
  /** An object relationship */
  appointment?: Maybe<Appointments>;
  appointment_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  patient?: Maybe<Patients>;
  patient_id?: Maybe<Scalars['uuid']>;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "discovered_from" */
export type Discovered_From_Aggregate = {
  __typename?: 'discovered_from_aggregate';
  aggregate?: Maybe<Discovered_From_Aggregate_Fields>;
  nodes: Array<Discovered_From>;
};

export type Discovered_From_Aggregate_Bool_Exp = {
  count?: InputMaybe<Discovered_From_Aggregate_Bool_Exp_Count>;
};

export type Discovered_From_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Discovered_From_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Discovered_From_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "discovered_from" */
export type Discovered_From_Aggregate_Fields = {
  __typename?: 'discovered_from_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Discovered_From_Max_Fields>;
  min?: Maybe<Discovered_From_Min_Fields>;
};

/** aggregate fields of "discovered_from" */
export type Discovered_From_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Discovered_From_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "discovered_from" */
export type Discovered_From_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Discovered_From_Max_Order_By>;
  min?: InputMaybe<Discovered_From_Min_Order_By>;
};

/** input type for inserting array relation for remote table "discovered_from" */
export type Discovered_From_Arr_Rel_Insert_Input = {
  data: Array<Discovered_From_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Discovered_From_On_Conflict>;
};

/** Boolean expression to filter rows from the table "discovered_from". All fields are combined with a logical 'AND'. */
export type Discovered_From_Bool_Exp = {
  _and?: InputMaybe<Array<Discovered_From_Bool_Exp>>;
  _not?: InputMaybe<Discovered_From_Bool_Exp>;
  _or?: InputMaybe<Array<Discovered_From_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  appointment?: InputMaybe<Appointments_Bool_Exp>;
  appointment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patient_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "discovered_from" */
export type Discovered_From_Constraint =
  /** unique or primary key constraint on columns "id" */
  'discovered_from_pkey';

/** input type for inserting data into table "discovered_from" */
export type Discovered_From_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']>;
  appointment?: InputMaybe<Appointments_Obj_Rel_Insert_Input>;
  appointment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Discovered_From_Max_Fields = {
  __typename?: 'discovered_from_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  appointment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  patient_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "discovered_from" */
export type Discovered_From_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  appointment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Discovered_From_Min_Fields = {
  __typename?: 'discovered_from_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  appointment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  patient_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "discovered_from" */
export type Discovered_From_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  appointment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "discovered_from" */
export type Discovered_From_Mutation_Response = {
  __typename?: 'discovered_from_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Discovered_From>;
};

/** on_conflict condition type for table "discovered_from" */
export type Discovered_From_On_Conflict = {
  constraint: Discovered_From_Constraint;
  update_columns?: Array<Discovered_From_Update_Column>;
  where?: InputMaybe<Discovered_From_Bool_Exp>;
};

/** Ordering options when selecting data from "discovered_from". */
export type Discovered_From_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  appointment?: InputMaybe<Appointments_Order_By>;
  appointment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patient_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: discovered_from */
export type Discovered_From_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "discovered_from" */
export type Discovered_From_Select_Column =
  /** column name */
  | 'account_id'
  /** column name */
  | 'appointment_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'description'
  /** column name */
  | 'id'
  /** column name */
  | 'patient_id'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "discovered_from" */
export type Discovered_From_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  appointment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "discovered_from" */
export type Discovered_From_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Discovered_From_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Discovered_From_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  appointment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "discovered_from" */
export type Discovered_From_Update_Column =
  /** column name */
  | 'account_id'
  /** column name */
  | 'appointment_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'description'
  /** column name */
  | 'id'
  /** column name */
  | 'patient_id'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at';

export type Discovered_From_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Discovered_From_Set_Input>;
  /** filter the rows which have to be updated */
  where: Discovered_From_Bool_Exp;
};

/** columns and relationships of "document_status_types" */
export type Document_Status_Types = {
  __typename?: 'document_status_types';
  description: Scalars['String'];
  /** An array relationship */
  documents: Array<Documents>;
  /** An aggregate relationship */
  documents_aggregate: Documents_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "document_status_types" */
export type Document_Status_TypesDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** columns and relationships of "document_status_types" */
export type Document_Status_TypesDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** aggregated selection of "document_status_types" */
export type Document_Status_Types_Aggregate = {
  __typename?: 'document_status_types_aggregate';
  aggregate?: Maybe<Document_Status_Types_Aggregate_Fields>;
  nodes: Array<Document_Status_Types>;
};

/** aggregate fields of "document_status_types" */
export type Document_Status_Types_Aggregate_Fields = {
  __typename?: 'document_status_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Document_Status_Types_Max_Fields>;
  min?: Maybe<Document_Status_Types_Min_Fields>;
};

/** aggregate fields of "document_status_types" */
export type Document_Status_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Status_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "document_status_types". All fields are combined with a logical 'AND'. */
export type Document_Status_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Document_Status_Types_Bool_Exp>>;
  _not?: InputMaybe<Document_Status_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Status_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  documents?: InputMaybe<Documents_Bool_Exp>;
  documents_aggregate?: InputMaybe<Documents_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "document_status_types" */
export type Document_Status_Types_Constraint =
  /** unique or primary key constraint on columns "value" */
  'document_status_types_pkey';

export type Document_Status_Types_Enum =
  /** Accepted document */
  | 'ACCEPTED'
  /** Archived document */
  | 'ARCHIVED'
  /** Declined document */
  | 'DECLINED';

/** Boolean expression to compare columns of type "document_status_types_enum". All fields are combined with logical 'AND'. */
export type Document_Status_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Document_Status_Types_Enum>;
  _in?: InputMaybe<Array<Document_Status_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Document_Status_Types_Enum>;
  _nin?: InputMaybe<Array<Document_Status_Types_Enum>>;
};

/** input type for inserting data into table "document_status_types" */
export type Document_Status_Types_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<Documents_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Document_Status_Types_Max_Fields = {
  __typename?: 'document_status_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Document_Status_Types_Min_Fields = {
  __typename?: 'document_status_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "document_status_types" */
export type Document_Status_Types_Mutation_Response = {
  __typename?: 'document_status_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Document_Status_Types>;
};

/** input type for inserting object relation for remote table "document_status_types" */
export type Document_Status_Types_Obj_Rel_Insert_Input = {
  data: Document_Status_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_Status_Types_On_Conflict>;
};

/** on_conflict condition type for table "document_status_types" */
export type Document_Status_Types_On_Conflict = {
  constraint: Document_Status_Types_Constraint;
  update_columns?: Array<Document_Status_Types_Update_Column>;
  where?: InputMaybe<Document_Status_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "document_status_types". */
export type Document_Status_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Documents_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: document_status_types */
export type Document_Status_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "document_status_types" */
export type Document_Status_Types_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "document_status_types" */
export type Document_Status_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "document_status_types" */
export type Document_Status_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Status_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Status_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "document_status_types" */
export type Document_Status_Types_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Document_Status_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Document_Status_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Document_Status_Types_Bool_Exp;
};

/** columns and relationships of "document_types" */
export type Document_Types = {
  __typename?: 'document_types';
  description: Scalars['String'];
  /** An array relationship */
  documents: Array<Documents>;
  /** An aggregate relationship */
  documents_aggregate: Documents_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "document_types" */
export type Document_TypesDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** columns and relationships of "document_types" */
export type Document_TypesDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** aggregated selection of "document_types" */
export type Document_Types_Aggregate = {
  __typename?: 'document_types_aggregate';
  aggregate?: Maybe<Document_Types_Aggregate_Fields>;
  nodes: Array<Document_Types>;
};

/** aggregate fields of "document_types" */
export type Document_Types_Aggregate_Fields = {
  __typename?: 'document_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Document_Types_Max_Fields>;
  min?: Maybe<Document_Types_Min_Fields>;
};

/** aggregate fields of "document_types" */
export type Document_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "document_types". All fields are combined with a logical 'AND'. */
export type Document_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Document_Types_Bool_Exp>>;
  _not?: InputMaybe<Document_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  documents?: InputMaybe<Documents_Bool_Exp>;
  documents_aggregate?: InputMaybe<Documents_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "document_types" */
export type Document_Types_Constraint =
  /** unique or primary key constraint on columns "value" */
  'document_types_pkey';

export type Document_Types_Enum =
  /** Code of Conduct */
  | 'CODE_OF_CONDUCT'
  /** Consult note */
  | 'CONSULT_NOTE'
  /** Forms */
  | 'FORM'
  /** Imaging */
  | 'IMAGING'
  /** Immunizations */
  | 'IMMUNIZATIONS'
  /** Labs */
  | 'LABS'
  /** Medical records */
  | 'MEDICAL_RECORD'
  /** Other */
  | 'OTHER'
  /** Patient letters */
  | 'PATIENT_LETTER'
  /** Referrals */
  | 'REFERRALS'
  /** Screenings */
  | 'SCREENINGS'
  /** Studies */
  | 'STUDIES'
  /** Unknown */
  | 'UNKNOWN';

/** Boolean expression to compare columns of type "document_types_enum". All fields are combined with logical 'AND'. */
export type Document_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Document_Types_Enum>;
  _in?: InputMaybe<Array<Document_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Document_Types_Enum>;
  _nin?: InputMaybe<Array<Document_Types_Enum>>;
};

/** input type for inserting data into table "document_types" */
export type Document_Types_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<Documents_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Document_Types_Max_Fields = {
  __typename?: 'document_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Document_Types_Min_Fields = {
  __typename?: 'document_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "document_types" */
export type Document_Types_Mutation_Response = {
  __typename?: 'document_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Document_Types>;
};

/** input type for inserting object relation for remote table "document_types" */
export type Document_Types_Obj_Rel_Insert_Input = {
  data: Document_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_Types_On_Conflict>;
};

/** on_conflict condition type for table "document_types" */
export type Document_Types_On_Conflict = {
  constraint: Document_Types_Constraint;
  update_columns?: Array<Document_Types_Update_Column>;
  where?: InputMaybe<Document_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "document_types". */
export type Document_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Documents_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: document_types */
export type Document_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "document_types" */
export type Document_Types_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "document_types" */
export type Document_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "document_types" */
export type Document_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "document_types" */
export type Document_Types_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Document_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Document_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Document_Types_Bool_Exp;
};

/** columns and relationships of "documents" */
export type Documents = {
  __typename?: 'documents';
  /** An object relationship */
  account: Accounts;
  accountId: Scalars['uuid'];
  /** An object relationship */
  appointment?: Maybe<Appointments>;
  appointmentId?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  document_status_type: Document_Status_Types;
  /** An object relationship */
  document_type: Document_Types;
  /** An object relationship */
  file?: Maybe<Files>;
  fileId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  patient?: Maybe<Patients>;
  patientId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  screenings: Array<Screenings>;
  /** An aggregate relationship */
  screenings_aggregate: Screenings_Aggregate;
  status: Document_Status_Types_Enum;
  type: Document_Types_Enum;
  updated_at: Scalars['timestamptz'];
  version: Scalars['Int'];
};

/** columns and relationships of "documents" */
export type DocumentsScreeningsArgs = {
  distinct_on?: InputMaybe<Array<Screenings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screenings_Order_By>>;
  where?: InputMaybe<Screenings_Bool_Exp>;
};

/** columns and relationships of "documents" */
export type DocumentsScreenings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screenings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screenings_Order_By>>;
  where?: InputMaybe<Screenings_Bool_Exp>;
};

/** aggregated selection of "documents" */
export type Documents_Aggregate = {
  __typename?: 'documents_aggregate';
  aggregate?: Maybe<Documents_Aggregate_Fields>;
  nodes: Array<Documents>;
};

export type Documents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Documents_Aggregate_Bool_Exp_Count>;
};

export type Documents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Documents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "documents" */
export type Documents_Aggregate_Fields = {
  __typename?: 'documents_aggregate_fields';
  avg?: Maybe<Documents_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Documents_Max_Fields>;
  min?: Maybe<Documents_Min_Fields>;
  stddev?: Maybe<Documents_Stddev_Fields>;
  stddev_pop?: Maybe<Documents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Documents_Stddev_Samp_Fields>;
  sum?: Maybe<Documents_Sum_Fields>;
  var_pop?: Maybe<Documents_Var_Pop_Fields>;
  var_samp?: Maybe<Documents_Var_Samp_Fields>;
  variance?: Maybe<Documents_Variance_Fields>;
};

/** aggregate fields of "documents" */
export type Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "documents" */
export type Documents_Aggregate_Order_By = {
  avg?: InputMaybe<Documents_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Documents_Max_Order_By>;
  min?: InputMaybe<Documents_Min_Order_By>;
  stddev?: InputMaybe<Documents_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Documents_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Documents_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Documents_Sum_Order_By>;
  var_pop?: InputMaybe<Documents_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Documents_Var_Samp_Order_By>;
  variance?: InputMaybe<Documents_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "documents" */
export type Documents_Arr_Rel_Insert_Input = {
  data: Array<Documents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Documents_On_Conflict>;
};

/** aggregate avg on columns */
export type Documents_Avg_Fields = {
  __typename?: 'documents_avg_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "documents" */
export type Documents_Avg_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "documents". All fields are combined with a logical 'AND'. */
export type Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Documents_Bool_Exp>>;
  _not?: InputMaybe<Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Documents_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  appointment?: InputMaybe<Appointments_Bool_Exp>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  document_status_type?: InputMaybe<Document_Status_Types_Bool_Exp>;
  document_type?: InputMaybe<Document_Types_Bool_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  fileId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  screenings?: InputMaybe<Screenings_Bool_Exp>;
  screenings_aggregate?: InputMaybe<Screenings_Aggregate_Bool_Exp>;
  status?: InputMaybe<Document_Status_Types_Enum_Comparison_Exp>;
  type?: InputMaybe<Document_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "documents" */
export type Documents_Constraint =
  /** unique or primary key constraint on columns "id" */
  'documents_pkey';

/** input type for incrementing numeric columns in table "documents" */
export type Documents_Inc_Input = {
  version?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "documents" */
export type Documents_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['uuid']>;
  appointment?: InputMaybe<Appointments_Obj_Rel_Insert_Input>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  document_status_type?: InputMaybe<Document_Status_Types_Obj_Rel_Insert_Input>;
  document_type?: InputMaybe<Document_Types_Obj_Rel_Insert_Input>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  fileId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  screenings?: InputMaybe<Screenings_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Document_Status_Types_Enum>;
  type?: InputMaybe<Document_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Documents_Max_Fields = {
  __typename?: 'documents_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  appointmentId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fileId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "documents" */
export type Documents_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Documents_Min_Fields = {
  __typename?: 'documents_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  appointmentId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fileId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "documents" */
export type Documents_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "documents" */
export type Documents_Mutation_Response = {
  __typename?: 'documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Documents>;
};

/** input type for inserting object relation for remote table "documents" */
export type Documents_Obj_Rel_Insert_Input = {
  data: Documents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Documents_On_Conflict>;
};

/** on_conflict condition type for table "documents" */
export type Documents_On_Conflict = {
  constraint: Documents_Constraint;
  update_columns?: Array<Documents_Update_Column>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "documents". */
export type Documents_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  appointment?: InputMaybe<Appointments_Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  document_status_type?: InputMaybe<Document_Status_Types_Order_By>;
  document_type?: InputMaybe<Document_Types_Order_By>;
  file?: InputMaybe<Files_Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  screenings_aggregate?: InputMaybe<Screenings_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: documents */
export type Documents_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "documents" */
export type Documents_Select_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'fileId'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'patientId'
  /** column name */
  | 'status'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'version';

/** input type for updating data in table "documents" */
export type Documents_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fileId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Document_Status_Types_Enum>;
  type?: InputMaybe<Document_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Documents_Stddev_Fields = {
  __typename?: 'documents_stddev_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "documents" */
export type Documents_Stddev_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Documents_Stddev_Pop_Fields = {
  __typename?: 'documents_stddev_pop_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "documents" */
export type Documents_Stddev_Pop_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Documents_Stddev_Samp_Fields = {
  __typename?: 'documents_stddev_samp_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "documents" */
export type Documents_Stddev_Samp_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "documents" */
export type Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Documents_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fileId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Document_Status_Types_Enum>;
  type?: InputMaybe<Document_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Documents_Sum_Fields = {
  __typename?: 'documents_sum_fields';
  version?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "documents" */
export type Documents_Sum_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** update columns of table "documents" */
export type Documents_Update_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'fileId'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'patientId'
  /** column name */
  | 'status'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'version';

export type Documents_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Documents_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Documents_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Documents_Var_Pop_Fields = {
  __typename?: 'documents_var_pop_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "documents" */
export type Documents_Var_Pop_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Documents_Var_Samp_Fields = {
  __typename?: 'documents_var_samp_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "documents" */
export type Documents_Var_Samp_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Documents_Variance_Fields = {
  __typename?: 'documents_variance_fields';
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "documents" */
export type Documents_Variance_Order_By = {
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "fhir_resource_types" */
export type Fhir_Resource_Types = {
  __typename?: 'fhir_resource_types';
  description: Scalars['String'];
  /** An array relationship */
  fhir_resources: Array<Fhir_Resources>;
  /** An aggregate relationship */
  fhir_resources_aggregate: Fhir_Resources_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "fhir_resource_types" */
export type Fhir_Resource_TypesFhir_ResourcesArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_Order_By>>;
  where?: InputMaybe<Fhir_Resources_Bool_Exp>;
};

/** columns and relationships of "fhir_resource_types" */
export type Fhir_Resource_TypesFhir_Resources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_Order_By>>;
  where?: InputMaybe<Fhir_Resources_Bool_Exp>;
};

/** aggregated selection of "fhir_resource_types" */
export type Fhir_Resource_Types_Aggregate = {
  __typename?: 'fhir_resource_types_aggregate';
  aggregate?: Maybe<Fhir_Resource_Types_Aggregate_Fields>;
  nodes: Array<Fhir_Resource_Types>;
};

/** aggregate fields of "fhir_resource_types" */
export type Fhir_Resource_Types_Aggregate_Fields = {
  __typename?: 'fhir_resource_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Fhir_Resource_Types_Max_Fields>;
  min?: Maybe<Fhir_Resource_Types_Min_Fields>;
};

/** aggregate fields of "fhir_resource_types" */
export type Fhir_Resource_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Fhir_Resource_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "fhir_resource_types". All fields are combined with a logical 'AND'. */
export type Fhir_Resource_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Fhir_Resource_Types_Bool_Exp>>;
  _not?: InputMaybe<Fhir_Resource_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Fhir_Resource_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  fhir_resources?: InputMaybe<Fhir_Resources_Bool_Exp>;
  fhir_resources_aggregate?: InputMaybe<Fhir_Resources_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "fhir_resource_types" */
export type Fhir_Resource_Types_Constraint =
  /** unique or primary key constraint on columns "value" */
  'fhir_resource_types_pkey';

export type Fhir_Resource_Types_Enum =
  /** A financial tool for tracking value accrued for a particular purpose.  In the healthcare field, used to track charges for a patient, cost centers, etc. */
  | 'Account'
  /** This resource allows for the definition of some activity to be performed, independent of a particular patient, practitioner, or other performance context. */
  | 'ActivityDefinition'
  /** Actual or  potential/avoided event causing unintended physical injury resulting from or contributed to by medical care, a research study or other healthcare setting factors that requires additional monitoring, treatment, or hospitalization, or that results in death. */
  | 'AdverseEvent'
  /** Risk of harmful or undesirable, physiological response which is unique to an individual and associated with exposure to a substance. */
  | 'AllergyIntolerance'
  /** A booking of a healthcare event among patient(s), practitioner(s), related person(s) and/or device(s) for a specific date/time. This may result in one or more Encounter(s). */
  | 'Appointment'
  /** A reply to an appointment request for a patient and/or practitioner(s), such as a confirmation or rejection. */
  | 'AppointmentResponse'
  /** A record of an event made for purposes of maintaining a security log. Typical uses include detection of intrusion attempts and monitoring for inappropriate usage. */
  | 'AuditEvent'
  /** Basic is used for handling concepts not yet defined in FHIR, narrative-only resources that don't map to an existing resource, and custom resources not appropriate for inclusion in the FHIR specification. */
  | 'Basic'
  /** A binary resource can contain any content, whether text, image, pdf, zip archive, etc. */
  | 'Binary'
  /** A material substance originating from a biological entity intended to be transplanted or infused\ninto another (possibly the same) biological entity. */
  | 'BiologicallyDerivedProduct'
  /** Record details about the anatomical location of a specimen or body part. This resource may be used when a coded concept does not provide the necessary detail needed for the use case. */
  | 'BodySite'
  /** Record details about an anatomical structure.  This resource may be used when a coded concept does not provide the necessary detail needed for the use case. */
  | 'BodyStructure'
  /** A container for a collection of resources. */
  | 'Bundle'
  /** A Capability Statement documents a set of capabilities (behaviors) of a FHIR Server for a particular version of FHIR that may be used as a statement of actual server functionality or a statement of required or desired server implementation. */
  | 'CapabilityStatement'
  /** Describes the intention of how one or more practitioners intend to deliver care for a particular patient for a period of time, possibly limited to care for a specific condition or set of conditions. */
  | 'CarePlan'
  /** The Care Team includes all the people and organizations who plan to participate in the coordination and delivery of care for a patient. */
  | 'CareTeam'
  /** Catalog entries are wrappers that contextualize items included in a catalog. */
  | 'CatalogEntry'
  /** The resource ChargeItem describes the provision of healthcare provider products for a certain patient, therefore referring not only to the product, but containing in addition details of the provision, like date, time, amounts and participating organizations and persons. Main Usage of the ChargeItem is to enable the billing process and internal cost allocation. */
  | 'ChargeItem'
  /** The ChargeItemDefinition resource provides the properties that apply to the (billing) codes necessary to calculate costs and prices. The properties may differ largely depending on type and realm, therefore this resource gives only a rough structure and requires profiling for each type of billing code system. */
  | 'ChargeItemDefinition'
  /** A provider issued list of services and products provided, or to be provided, to a patient which is provided to an insurer for payment recovery. */
  | 'Claim'
  /** This resource provides the adjudication details from the processing of a Claim resource. */
  | 'ClaimResponse'
  /** A record of a clinical assessment performed to determine what problem(s) may affect the patient and before planning the treatments or management strategies that are best to manage a patient's condition. Assessments are often 1:1 with a clinical consultation / encounter, but this varies greatly depending on the clinical workflow. This resource is called 'ClinicalImpression' rather than 'ClinicalAssessment' to avoid confusion with the recording of assessment tools such as Apgar score. */
  | 'ClinicalImpression'
  /** The CodeSystem resource is used to declare the existence of and describe a code system or code system supplement and its key properties, and optionally define a part or all of its content. */
  | 'CodeSystem'
  /** An occurrence of information being transmitted. E.g., an alert that was sent to a responsible provider, a public health agency was notified about a reportable condition. */
  | 'Communication'
  /** A request to convey information. E.g., the CDS system proposes that an alert be sent to a responsible provider, the CDS system proposes that the public health agency be notified about a reportable condition. */
  | 'CommunicationRequest'
  /** A compartment definition that defines how resources are accessed on a server. */
  | 'CompartmentDefinition'
  /** A set of healthcare-related information that is assembled together into a single logical document that provides a single coherent statement of meaning, establishes its own context and that has clinical attestation with regard to who is making the statement. */
  | 'Composition'
  /** A statement of relationships from one set of concepts to one or more other concepts - either code systems or data elements, or classes in class models. */
  | 'ConceptMap'
  /** Use to record detailed information about conditions, problems or diagnoses recognized by a clinician. There are many uses including: recording a Diagnosis during an Encounter; populating a problem List or a Summary Statement, such as a Discharge Summary. */
  | 'Condition'
  /** A conformance statement is a set of requirements for a desired implementation or a description of how a target application fulfills those requirements in a particular implementation. */
  | 'Conformance'
  /** A record of a healthcare consumerâ€™s  choices, which permits or denies identified recipient(s) or recipient role(s) to perform one or more actions within a given policy context, for specific purposes and periods of time. */
  | 'Consent'
  /** A formal agreement between parties regarding the conduct of business, exchange of information or other matters. */
  | 'Contract'
  /** Indicates an actual or potential clinical issue with or between one or more active or proposed clinical actions for a patient. E.g. Drug-drug interaction, Ineffective treatment frequency, Procedure-condition conflict, etc. */
  | 'Contraindication'
  /** Financial instrument which may be used to pay for or reimburse for health care products and services. */
  | 'Coverage'
  /** The CoverageEligibilityRequest provides patient and insurance coverage information to an insurer for them to respond, in the form of an CoverageEligibilityResponse, with information regarding whether the stated coverage is valid and in-force and optionally to provide the insurance details of the policy. */
  | 'CoverageEligibilityRequest'
  /** This resource provides eligibility and plan details from the processing of an CoverageEligibilityRequest resource. */
  | 'CoverageEligibilityResponse'
  /** The formal description of a single piece of information that can be gathered and reported. */
  | 'DataElement'
  /** Indicates an actual or potential clinical issue with or between one or more active or proposed clinical actions for a patient; e.g. Drug-drug interaction, Ineffective treatment frequency, Procedure-condition conflict, etc. */
  | 'DetectedIssue'
  /** This resource identifies an instance of a manufactured thing that is used in the provision of healthcare without being substantially changed through that activity. The device may be a machine, an insert, a computer, an application, etc. This includes durable (reusable) medical equipment as well as disposable equipment used for diagnostic, treatment, and research for healthcare and public health. */
  | 'Device'
  /** Describes the characteristics, operational status and capabilities of a medical-related component of a medical device. */
  | 'DeviceComponent'
  /** The characteristics, operational status and capabilities of a medical-related component of a medical device. */
  | 'DeviceDefinition'
  /** Describes a measurement, calculation or setting capability of a medical device. */
  | 'DeviceMetric'
  /** Represents a request for a patient to employ a medical device. The device may be an implantable device, or an external assistive device, such as a walker. */
  | 'DeviceRequest'
  /** Represents a request for a patient to employ a medical device. The device may be an implantable device, or an external assistive device, such as a walker. */
  | 'DeviceUseRequest'
  /** A record of a device being used by a patient where the record is the result of a report from the patient or another clinician. */
  | 'DeviceUseStatement'
  /** A record of a request for a diagnostic investigation service to be performed. */
  | 'DiagnosticOrder'
  /** The findings and interpretation of diagnostic tests performed on patients, groups of patients, devices, and locations, and/or specimens derived from these. The report includes clinical context such as requesting and provider information, and some mix of atomic results, images, textual and coded interpretation, and formatted representation of diagnostic reports. */
  | 'DiagnosticReport'
  /** A manifest that defines a set of documents. */
  | 'DocumentManifest'
  /** A reference to a document. */
  | 'DocumentReference'
  /** A resource that includes narrative, extensions, and contained resources. */
  | 'DomainResource'
  /** The EffectEvidenceSynthesis resource describes the difference in an outcome between exposures states in a population where the effect estimate is derived from a combination of research studies. */
  | 'EffectEvidenceSynthesis'
  /** This resource provides the insurance eligibility details from the insurer regarding a specified coverage and optionally some class of service. */
  | 'EligibilityRequest'
  /** This resource provides eligibility and plan details from the processing of an Eligibility resource. */
  | 'EligibilityResponse'
  /** An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient. */
  | 'Encounter'
  /** The technical details of an endpoint that can be used for electronic services, such as for web services providing XDS.b or a REST endpoint for another FHIR server. This may include any security context information. */
  | 'Endpoint'
  /** This resource provides the insurance Enrollment details to the insurer regarding a specified coverage. */
  | 'EnrollmentRequest'
  /** This resource provides Enrollment and plan details from the processing of an Enrollment resource. */
  | 'EnrollmentResponse'
  /** An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time. */
  | 'EpisodeOfCare'
  /** The EventDefinition resource provides a reusable description of when a particular event can occur. */
  | 'EventDefinition'
  /** The Evidence resource describes the conditional state (population and any exposures being compared within the population) and outcome (if specified) that the knowledge (evidence, assertion, recommendation) is about. */
  | 'Evidence'
  /** The EvidenceVariable resource describes a "PICO" element that knowledge (evidence, assertion, recommendation) is about. */
  | 'EvidenceVariable'
  /** Example of workflow instance. */
  | 'ExampleScenario'
  /** This resource provides: the claim details; adjudication details from the processing of a Claim; and optionally account balance information, for informing the subscriber of the benefits provided. */
  | 'ExplanationOfBenefit'
  /** Significant health events and conditions for a person related to the patient relevant in the context of care for the patient. */
  | 'FamilyMemberHistory'
  /** Prospective warnings of potential issues when providing care to the patient. */
  | 'Flag'
  /** Describes the intended objective(s) of patient care, for example, weight loss, restoring an activity of daily living, etc. */
  | 'Goal'
  /** A formal computable definition of a graph of resources - that is, a coherent set of resources that form a graph by following references. The Graph Definition resource defines a set and makes rules about the set. */
  | 'GraphDefinition'
  /** Represents a defined collection of entities that may be discussed or acted upon collectively but which are not expected to act collectively and are not formally or legally recognized. I.e. A collection of entities that isn't an Organization. */
  | 'Group'
  /** A guidance response is the formal response to a guidance request, including any output parameters returned by the evaluation, as well as the description of any proposed actions to be taken. */
  | 'GuidanceResponse'
  /** The details of a Healthcare Service available at a location. */
  | 'HealthcareService'
  /** A set of DICOM SOP Instances of a patient, selected for some application purpose, e.g., quality assurance, teaching, conference, consulting, etc. Objects selected can be from different studies, but must be of the same patient. */
  | 'ImagingObjectSelection'
  /** Representation of the content produced in a DICOM imaging study. A study comprises a set of Series, each of which includes a set of Service-Object Pair Instances (SOP Instances - images or other data) acquired or produced in a common context. A Series is of only one modality (e.g., X-ray, CT, MR, ultrasound), but a Study may have multiple Series of different modalities. */
  | 'ImagingStudy'
  /** Immunization event information. */
  | 'Immunization'
  /** Describes a comparison of an immunization event against published recommendations to determine if the administration is "valid" in relation to those  recommendations. */
  | 'ImmunizationEvaluation'
  /** A patient's point-of-time immunization status and recommendation with optional supporting justification. */
  | 'ImmunizationRecommendation'
  /** A set of rules of how a particular interoperability or standards problem is solved - typically through the use of FHIR resources. This resource is used to gather all the parts of an implementation guide into a logical whole and to publish a computable definition of all the parts. */
  | 'ImplementationGuide'
  /** Details of a Health Insurance product/plan provided by an organization. */
  | 'InsurancePlan'
  /** Invoice containing collected ChargeItems from an Account with calculated individual and total price for Billing purpose. */
  | 'Invoice'
  /** The Library resource is a general-purpose container for knowledge asset definitions. It can be used to describe and expose existing knowledge assets such as logic libraries and information model descriptions, as well as to describe a collection of knowledge assets. */
  | 'Library'
  /** Identifies two or more records (resource instances) that refer to the same real-world "occurrence". */
  | 'Linkage'
  /** A set of information summarized from a list of other resources. */
  | 'List'
  /** Details and position information for a physical place where services are provided and resources and participants may be stored, found, contained or accommodated. */
  | 'Location'
  /** The Measure resource provides the definition of a quality measure. */
  | 'Measure'
  /** The MeasureReport resource contains the results of the calculation of a measure; and optionally a reference to the resources involved in that calculation. */
  | 'MeasureReport'
  /** A photo, video, or audio recording acquired or used in healthcare. The actual content may be inline or provided by direct reference. */
  | 'Media'
  /** Primarily used for identification and definition of Medication, but also covers ingredients and packaging. */
  | 'Medication'
  /** Describes the event of a patient consuming or otherwise being administered a medication. This may be as simple as swallowing a tablet or it may be a long running infusion. Related resources tie this event to the authorizing prescription, and the specific encounter between patient and health care practitioner. */
  | 'MedicationAdministration'
  /** Dispensing a medication to a named patient. This includes a description of the supply provided and the instructions for administering the medication. */
  | 'MedicationDispense'
  /** Information about a medication that is used to support knowledge. */
  | 'MedicationKnowledge'
  /** An order for both supply of the medication and the instructions for administration of the medicine to a patient. */
  | 'MedicationPrescription'
  /** An order or request for both supply of the medication and the instructions for administration of the medication to a patient. The resource is called "MedicationRequest" rather than "MedicationPrescription" or "MedicationOrder" to generalize the use across inpatient and outpatient settings, including care plans, etc., and to harmonize with workflow patterns. */
  | 'MedicationRequest'
  /** A record of medication being taken by a patient, or that the medication has been given to a patient where the record is the result of a report from the patient or another clinician. */
  | 'MedicationStatement'
  /** Detailed definition of a medicinal product, typically for uses other than direct patient care (e.g. regulatory use). */
  | 'MedicinalProduct'
  /** The regulatory authorization of a medicinal product. */
  | 'MedicinalProductAuthorization'
  /** The clinical particulars - indications, contraindications etc. of a medicinal product, including for regulatory purposes. */
  | 'MedicinalProductContraindication'
  /** Indication for the Medicinal Product. */
  | 'MedicinalProductIndication'
  /** An ingredient of a manufactured item or pharmaceutical product. */
  | 'MedicinalProductIngredient'
  /** The interactions of the medicinal product with other medicinal products, or other forms of interactions. */
  | 'MedicinalProductInteraction'
  /** The manufactured item as contained in the packaged medicinal product. */
  | 'MedicinalProductManufactured'
  /** A medicinal product in a container or package. */
  | 'MedicinalProductPackaged'
  /** A pharmaceutical product described in terms of its composition and dose form. */
  | 'MedicinalProductPharmaceutical'
  /** Describe the undesirable effects of the medicinal product. */
  | 'MedicinalProductUndesirableEffect'
  /** Defines the characteristics of a message that can be shared between systems, including the type of event that initiates the message, the content to be transmitted and what response(s), if any, are permitted. */
  | 'MessageDefinition'
  /** The header for a message exchange that is either requesting or responding to an action. The Reference(s) that are the subject of the action as well as other Information related to the action are typically transmitted in a bundle in which the MessageHeader resource instance is the first resource in the bundle. */
  | 'MessageHeader'
  /** Raw data describing a biological sequence. */
  | 'MolecularSequence'
  /** A curated namespace that issues unique symbols within that namespace for the identification of concepts, people, devices, etc. Represents a 'System' used within the Identifier and Coding data types. */
  | 'NamingSystem'
  /** A request to supply a diet, formula feeding (enteral) or oral nutritional supplement to a patient/resident. */
  | 'NutritionOrder'
  /** Measurements and simple assertions made about a patient, device or other subject. */
  | 'Observation'
  /** Set of definitional characteristics for a kind of observation or measurement produced or consumed by an orderable health care service. */
  | 'ObservationDefinition'
  /** A formal computable definition of an operation (on the RESTful interface) or a named query (using the search interaction). */
  | 'OperationDefinition'
  /** A collection of error, warning or information messages that result from a system action. */
  | 'OperationOutcome'
  /** A request to perform an action. */
  | 'Order'
  /** A response to an order. */
  | 'OrderResponse'
  /** A formally or informally recognized grouping of people or organizations formed for the purpose of achieving some form of collective action. Includes companies, institutions, corporations, departments, community groups, healthcare practice groups, etc. */
  | 'Organization'
  /** Defines an affiliation/assotiation/relationship between 2 distinct oganizations, that is not a part-of relationship/sub-division relationship. */
  | 'OrganizationAffiliation'
  /** This resource is a non-persisted resource used to pass information into and back from an [operation](operations.html). It has no other use, and there is no RESTful endpoint associated with it. */
  | 'Parameters'
  /** Demographics and other administrative information about an individual or animal receiving care or other health-related services. */
  | 'Patient'
  /** This resource provides the status of the payment for goods and services rendered, and the request and response resource references. */
  | 'PaymentNotice'
  /** This resource provides payment details and claim references supporting a bulk payment. */
  | 'PaymentReconciliation'
  /** Demographics and administrative information about a person independent of a specific health-related context. */
  | 'Person'
  /** This resource allows for the definition of various types of plans as a sharable, consumable, and executable artifact. The resource is general enough to support the description of a broad range of clinical artifacts such as clinical decision support rules, order sets and protocols. */
  | 'PlanDefinition'
  /** A person who is directly or indirectly involved in the provisioning of healthcare. */
  | 'Practitioner'
  /** A specific set of Roles/Locations/specialties/services that a practitioner may perform at an organization for a period of time. */
  | 'PractitionerRole'
  /** An action that is or was performed on a patient. This can be a physical 'thing' like an operation, or less invasive like counseling or hypnotherapy. */
  | 'Procedure'
  /** A request for a procedure to be performed. May be a proposal or an order. */
  | 'ProcedureRequest'
  /** This resource provides the target, request and response, and action details for an action to be performed by the target on or about existing resources. */
  | 'ProcessRequest'
  /** This resource provides processing status, errors and notes from the processing of a resource. */
  | 'ProcessResponse'
  /** Provenance of a resource is a record that describes entities and processes involved in producing and delivering or otherwise influencing that resource. Provenance provides a critical foundation for assessing authenticity, enabling trust, and allowing reproducibility. Provenance assertions are a form of contextual metadata and can themselves become important records with their own provenance. Provenance statement indicates clinical significance in terms of confidence in authenticity, reliability, and trustworthiness, integrity, and stage in lifecycle (e.g., Document Completion - has the artifact been legally authenticated), all of which may impact Security, Privacy, and Trust policies. */
  | 'Provenance'
  /** A structured set of questions intended to guide the collection of answers. The questions are ordered and grouped into coherent subsets, corresponding to the structure of the grouping of the underlying questions. */
  | 'Questionnaire'
  /** A structured set of questions and their answers. The questions are ordered and grouped into coherent subsets, corresponding to the structure of the grouping of the underlying questions. */
  | 'QuestionnaireAnswers'
  /** A structured set of questions and their answers. The questions are ordered and grouped into coherent subsets, corresponding to the structure of the grouping of the questionnaire being responded to. */
  | 'QuestionnaireResponse'
  /** Used to record and send details about a request for referral service or transfer of a patient to the care of another provider or provider organisation. */
  | 'ReferralRequest'
  /** Information about a person that is involved in the care for a patient, but who is not the target of healthcare, nor has a formal responsibility in the care process. */
  | 'RelatedPerson'
  /** A group of related requests that can be used to capture intended activities that have inter-dependencies such as "give this medication after that one". */
  | 'RequestGroup'
  /** The ResearchDefinition resource describes the conditional state (population and any exposures being compared within the population) and outcome (if specified) that the knowledge (evidence, assertion, recommendation) is about. */
  | 'ResearchDefinition'
  /** The ResearchElementDefinition resource describes a "PICO" element that knowledge (evidence, assertion, recommendation) is about. */
  | 'ResearchElementDefinition'
  /** A process where a researcher or organization plans and then executes a series of steps intended to increase the field of healthcare-related knowledge.  This includes studies of safety, efficacy, comparative effectiveness and other information about medications, devices, therapies and other interventional and investigative techniques.  A ResearchStudy involves the gathering of information about human or animal subjects. */
  | 'ResearchStudy'
  /** A physical entity which is the primary unit of operational and/or administrative interest in a study. */
  | 'ResearchSubject'
  /** This is the base resource type for everything. */
  | 'Resource'
  /** An assessment of the likely outcome(s) for a patient or other subject as well as the likelihood of each outcome. */
  | 'RiskAssessment'
  /** The RiskEvidenceSynthesis resource describes the likelihood of an outcome in a population plus exposure state where the risk estimate is derived from a combination of research studies. */
  | 'RiskEvidenceSynthesis'
  /** A container for slot(s) of time that may be available for booking appointments. */
  | 'Schedule'
  /** A Search Parameter that defines a named search item that can be used to search/filter on a resource. */
  | 'SearchParameter'
  /** A record of a request for service such as diagnostic investigations, treatments, or operations to be performed. */
  | 'ServiceRequest'
  /** A slot of time on a schedule that may be available for booking appointments. */
  | 'Slot'
  /** Sample for analysis. */
  | 'Specimen'
  /** A kind of specimen with associated set of requirements. */
  | 'SpecimenDefinition'
  /** A definition of a FHIR structure. This resource is used to describe the underlying resources, data types defined in FHIR, and also for describing extensions, and constraints on resources and data types. */
  | 'StructureDefinition'
  /** A Map of relationships between 2 structures that can be used to transform data. */
  | 'StructureMap'
  /** The subscription resource is used to define a push based subscription from a server to another system. Once a subscription is registered with the server, the server checks every resource that is created or updated, and if the resource matches the given criteria, it sends a message on the defined 'channel' so that another system is able to take an appropriate action. */
  | 'Subscription'
  /** A homogeneous material with a definite composition. */
  | 'Substance'
  /** Nucleic acids are defined by three distinct elements: the base, sugar and linkage. Individual substance/moiety IDs will be created for each of these elements. The nucleotide sequence will be always entered in the 5â€™-3â€™ direction. */
  | 'SubstanceNucleicAcid'
  /** Todo. */
  | 'SubstancePolymer'
  /** A SubstanceProtein is defined as a single unit of a linear amino acid sequence, or a combination of subunits that are either covalently linked or have a defined invariant stoichiometric relationship. This includes all synthetic, recombinant and purified SubstanceProteins of defined sequence, whether the use is therapeutic or prophylactic. This set of elements will be used to describe albumins, coagulation factors, cytokines, growth factors, peptide/SubstanceProtein hormones, enzymes, toxins, toxoids, recombinant vaccines, and immunomodulators. */
  | 'SubstanceProtein'
  /** Todo. */
  | 'SubstanceReferenceInformation'
  /** Source material shall capture information on the taxonomic and anatomical origins as well as the fraction of a material that can result in or can be modified to form a substance. This set of data elements shall be used to define polymer substances isolated from biological matrices. Taxonomic and anatomical origins shall be described using a controlled vocabulary as required. This information is captured for naturally derived polymers ( . starch) and structurally diverse substances. For Organisms belonging to the Kingdom Plantae the Substance level defines the fresh material of a single species or infraspecies, the Herbal Drug and the Herbal preparation. For Herbal preparations, the fraction information will be captured at the Substance information level and additional information for herbal extracts will be captured at the Specified Substance Group 1 information level. See for further explanation the Substance Class: Structurally Diverse and the herbal annex. */
  | 'SubstanceSourceMaterial'
  /** The detailed description of a substance, typically at a level beyond what is used for prescribing. */
  | 'SubstanceSpecification'
  /** A supply - a request for something, and provision of what is supplied. */
  | 'Supply'
  /** Record of delivery of what is supplied. */
  | 'SupplyDelivery'
  /** A record of a request for a medication, substance or device used in the healthcare setting. */
  | 'SupplyRequest'
  /** A task to be performed. */
  | 'Task'
  /** A TerminologyCapabilities resource documents a set of capabilities (behaviors) of a FHIR Terminology Server that may be used as a statement of actual server functionality or a statement of required or desired server implementation. */
  | 'TerminologyCapabilities'
  /** A summary of information based on the results of executing a TestScript. */
  | 'TestReport'
  /** A structured set of tests against a FHIR server or client implementation to determine compliance against the FHIR specification. */
  | 'TestScript'
  /** A value set specifies a set of codes drawn from one or more code systems. */
  | 'ValueSet'
  /** Describes validation requirements, source(s), status and dates for one or more elements. */
  | 'VerificationResult'
  /** An authorization for the supply of glasses and/or contact lenses to a patient. */
  | 'VisionPrescription';

/** Boolean expression to compare columns of type "fhir_resource_types_enum". All fields are combined with logical 'AND'. */
export type Fhir_Resource_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Fhir_Resource_Types_Enum>;
  _in?: InputMaybe<Array<Fhir_Resource_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Fhir_Resource_Types_Enum>;
  _nin?: InputMaybe<Array<Fhir_Resource_Types_Enum>>;
};

/** input type for inserting data into table "fhir_resource_types" */
export type Fhir_Resource_Types_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  fhir_resources?: InputMaybe<Fhir_Resources_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Fhir_Resource_Types_Max_Fields = {
  __typename?: 'fhir_resource_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Fhir_Resource_Types_Min_Fields = {
  __typename?: 'fhir_resource_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "fhir_resource_types" */
export type Fhir_Resource_Types_Mutation_Response = {
  __typename?: 'fhir_resource_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fhir_Resource_Types>;
};

/** input type for inserting object relation for remote table "fhir_resource_types" */
export type Fhir_Resource_Types_Obj_Rel_Insert_Input = {
  data: Fhir_Resource_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Fhir_Resource_Types_On_Conflict>;
};

/** on_conflict condition type for table "fhir_resource_types" */
export type Fhir_Resource_Types_On_Conflict = {
  constraint: Fhir_Resource_Types_Constraint;
  update_columns?: Array<Fhir_Resource_Types_Update_Column>;
  where?: InputMaybe<Fhir_Resource_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "fhir_resource_types". */
export type Fhir_Resource_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  fhir_resources_aggregate?: InputMaybe<Fhir_Resources_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: fhir_resource_types */
export type Fhir_Resource_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "fhir_resource_types" */
export type Fhir_Resource_Types_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "fhir_resource_types" */
export type Fhir_Resource_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "fhir_resource_types" */
export type Fhir_Resource_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fhir_Resource_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fhir_Resource_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "fhir_resource_types" */
export type Fhir_Resource_Types_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Fhir_Resource_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Fhir_Resource_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fhir_Resource_Types_Bool_Exp;
};

/** FHIR resources */
export type Fhir_Resources = {
  __typename?: 'fhir_resources';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  fhir_resource_type: Fhir_Resource_Types;
  id: Scalars['uuid'];
  /** An object relationship */
  patient: Patients;
  patientId: Scalars['uuid'];
  resource: Scalars['jsonb'];
  resourceType: Fhir_Resource_Types_Enum;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** FHIR resources */
export type Fhir_ResourcesResourceArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "fhir_resources" */
export type Fhir_Resources_Aggregate = {
  __typename?: 'fhir_resources_aggregate';
  aggregate?: Maybe<Fhir_Resources_Aggregate_Fields>;
  nodes: Array<Fhir_Resources>;
};

export type Fhir_Resources_Aggregate_Bool_Exp = {
  count?: InputMaybe<Fhir_Resources_Aggregate_Bool_Exp_Count>;
};

export type Fhir_Resources_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Fhir_Resources_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Fhir_Resources_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "fhir_resources" */
export type Fhir_Resources_Aggregate_Fields = {
  __typename?: 'fhir_resources_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Fhir_Resources_Max_Fields>;
  min?: Maybe<Fhir_Resources_Min_Fields>;
};

/** aggregate fields of "fhir_resources" */
export type Fhir_Resources_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Fhir_Resources_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fhir_resources" */
export type Fhir_Resources_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Fhir_Resources_Max_Order_By>;
  min?: InputMaybe<Fhir_Resources_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Fhir_Resources_Append_Input = {
  resource?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "fhir_resources" */
export type Fhir_Resources_Arr_Rel_Insert_Input = {
  data: Array<Fhir_Resources_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Fhir_Resources_On_Conflict>;
};

/** Boolean expression to filter rows from the table "fhir_resources". All fields are combined with a logical 'AND'. */
export type Fhir_Resources_Bool_Exp = {
  _and?: InputMaybe<Array<Fhir_Resources_Bool_Exp>>;
  _not?: InputMaybe<Fhir_Resources_Bool_Exp>;
  _or?: InputMaybe<Array<Fhir_Resources_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  fhir_resource_type?: InputMaybe<Fhir_Resource_Types_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  resource?: InputMaybe<Jsonb_Comparison_Exp>;
  resourceType?: InputMaybe<Fhir_Resource_Types_Enum_Comparison_Exp>;
  transactionId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "fhir_resources_coded" */
export type Fhir_Resources_Coded = {
  __typename?: 'fhir_resources_coded';
  code?: Maybe<Scalars['String']>;
  codesystem?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  effectiveDateTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  resource?: Maybe<Scalars['jsonb']>;
  resourceType?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['jsonb']>;
};

/** columns and relationships of "fhir_resources_coded" */
export type Fhir_Resources_CodedResourceArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "fhir_resources_coded" */
export type Fhir_Resources_CodedValueArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "fhir_resources_coded" */
export type Fhir_Resources_Coded_Aggregate = {
  __typename?: 'fhir_resources_coded_aggregate';
  aggregate?: Maybe<Fhir_Resources_Coded_Aggregate_Fields>;
  nodes: Array<Fhir_Resources_Coded>;
};

export type Fhir_Resources_Coded_Aggregate_Bool_Exp = {
  count?: InputMaybe<Fhir_Resources_Coded_Aggregate_Bool_Exp_Count>;
};

export type Fhir_Resources_Coded_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Fhir_Resources_Coded_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Fhir_Resources_Coded_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "fhir_resources_coded" */
export type Fhir_Resources_Coded_Aggregate_Fields = {
  __typename?: 'fhir_resources_coded_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Fhir_Resources_Coded_Max_Fields>;
  min?: Maybe<Fhir_Resources_Coded_Min_Fields>;
};

/** aggregate fields of "fhir_resources_coded" */
export type Fhir_Resources_Coded_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Fhir_Resources_Coded_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fhir_resources_coded" */
export type Fhir_Resources_Coded_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Fhir_Resources_Coded_Max_Order_By>;
  min?: InputMaybe<Fhir_Resources_Coded_Min_Order_By>;
};

/** input type for inserting array relation for remote table "fhir_resources_coded" */
export type Fhir_Resources_Coded_Arr_Rel_Insert_Input = {
  data: Array<Fhir_Resources_Coded_Insert_Input>;
};

/** Boolean expression to filter rows from the table "fhir_resources_coded". All fields are combined with a logical 'AND'. */
export type Fhir_Resources_Coded_Bool_Exp = {
  _and?: InputMaybe<Array<Fhir_Resources_Coded_Bool_Exp>>;
  _not?: InputMaybe<Fhir_Resources_Coded_Bool_Exp>;
  _or?: InputMaybe<Array<Fhir_Resources_Coded_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  codesystem?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  effectiveDateTime?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  resource?: InputMaybe<Jsonb_Comparison_Exp>;
  resourceType?: InputMaybe<String_Comparison_Exp>;
  transactionId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** input type for inserting data into table "fhir_resources_coded" */
export type Fhir_Resources_Coded_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  codesystem?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  effectiveDateTime?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  resource?: InputMaybe<Scalars['jsonb']>;
  resourceType?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Fhir_Resources_Coded_Max_Fields = {
  __typename?: 'fhir_resources_coded_max_fields';
  code?: Maybe<Scalars['String']>;
  codesystem?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  effectiveDateTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  resourceType?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "fhir_resources_coded" */
export type Fhir_Resources_Coded_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  codesystem?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  effectiveDateTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  resourceType?: InputMaybe<Order_By>;
  transactionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Fhir_Resources_Coded_Min_Fields = {
  __typename?: 'fhir_resources_coded_min_fields';
  code?: Maybe<Scalars['String']>;
  codesystem?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  effectiveDateTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  resourceType?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "fhir_resources_coded" */
export type Fhir_Resources_Coded_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  codesystem?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  effectiveDateTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  resourceType?: InputMaybe<Order_By>;
  transactionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "fhir_resources_coded". */
export type Fhir_Resources_Coded_Order_By = {
  code?: InputMaybe<Order_By>;
  codesystem?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  effectiveDateTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  resource?: InputMaybe<Order_By>;
  resourceType?: InputMaybe<Order_By>;
  transactionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "fhir_resources_coded" */
export type Fhir_Resources_Coded_Select_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'codesystem'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'effectiveDateTime'
  /** column name */
  | 'id'
  /** column name */
  | 'patientId'
  /** column name */
  | 'resource'
  /** column name */
  | 'resourceType'
  /** column name */
  | 'transactionId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'value';

/** Streaming cursor of the table "fhir_resources_coded" */
export type Fhir_Resources_Coded_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fhir_Resources_Coded_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fhir_Resources_Coded_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
  codesystem?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  effectiveDateTime?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  resource?: InputMaybe<Scalars['jsonb']>;
  resourceType?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

/** unique or primary key constraints on table "fhir_resources" */
export type Fhir_Resources_Constraint =
  /** unique or primary key constraint on columns "id" */
  'fhir_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Fhir_Resources_Delete_At_Path_Input = {
  resource?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Fhir_Resources_Delete_Elem_Input = {
  resource?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Fhir_Resources_Delete_Key_Input = {
  resource?: InputMaybe<Scalars['String']>;
};

/** Transaction history of FHIR resource changes */
export type Fhir_Resources_History = {
  __typename?: 'fhir_resources_history';
  createdAt: Scalars['timestamptz'];
  difference?: Maybe<Scalars['jsonb']>;
  from?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  operation: Scalars['String'];
  session: Scalars['jsonb'];
  /** An object relationship */
  staff?: Maybe<Staff>;
  staffId?: Maybe<Scalars['uuid']>;
  to?: Maybe<Scalars['jsonb']>;
};

/** Transaction history of FHIR resource changes */
export type Fhir_Resources_HistoryDifferenceArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Transaction history of FHIR resource changes */
export type Fhir_Resources_HistoryFromArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Transaction history of FHIR resource changes */
export type Fhir_Resources_HistorySessionArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Transaction history of FHIR resource changes */
export type Fhir_Resources_HistoryToArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "fhir_resources_history" */
export type Fhir_Resources_History_Aggregate = {
  __typename?: 'fhir_resources_history_aggregate';
  aggregate?: Maybe<Fhir_Resources_History_Aggregate_Fields>;
  nodes: Array<Fhir_Resources_History>;
};

export type Fhir_Resources_History_Aggregate_Bool_Exp = {
  count?: InputMaybe<Fhir_Resources_History_Aggregate_Bool_Exp_Count>;
};

export type Fhir_Resources_History_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Fhir_Resources_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Fhir_Resources_History_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "fhir_resources_history" */
export type Fhir_Resources_History_Aggregate_Fields = {
  __typename?: 'fhir_resources_history_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Fhir_Resources_History_Max_Fields>;
  min?: Maybe<Fhir_Resources_History_Min_Fields>;
};

/** aggregate fields of "fhir_resources_history" */
export type Fhir_Resources_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Fhir_Resources_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fhir_resources_history" */
export type Fhir_Resources_History_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Fhir_Resources_History_Max_Order_By>;
  min?: InputMaybe<Fhir_Resources_History_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Fhir_Resources_History_Append_Input = {
  difference?: InputMaybe<Scalars['jsonb']>;
  from?: InputMaybe<Scalars['jsonb']>;
  session?: InputMaybe<Scalars['jsonb']>;
  to?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "fhir_resources_history" */
export type Fhir_Resources_History_Arr_Rel_Insert_Input = {
  data: Array<Fhir_Resources_History_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Fhir_Resources_History_On_Conflict>;
};

/** Boolean expression to filter rows from the table "fhir_resources_history". All fields are combined with a logical 'AND'. */
export type Fhir_Resources_History_Bool_Exp = {
  _and?: InputMaybe<Array<Fhir_Resources_History_Bool_Exp>>;
  _not?: InputMaybe<Fhir_Resources_History_Bool_Exp>;
  _or?: InputMaybe<Array<Fhir_Resources_History_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  difference?: InputMaybe<Jsonb_Comparison_Exp>;
  from?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  operation?: InputMaybe<String_Comparison_Exp>;
  session?: InputMaybe<Jsonb_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  to?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "fhir_resources_history" */
export type Fhir_Resources_History_Constraint =
  /** unique or primary key constraint on columns "id" */
  'fhir_resources_history_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Fhir_Resources_History_Delete_At_Path_Input = {
  difference?: InputMaybe<Array<Scalars['String']>>;
  from?: InputMaybe<Array<Scalars['String']>>;
  session?: InputMaybe<Array<Scalars['String']>>;
  to?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Fhir_Resources_History_Delete_Elem_Input = {
  difference?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['Int']>;
  session?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Fhir_Resources_History_Delete_Key_Input = {
  difference?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "fhir_resources_history" */
export type Fhir_Resources_History_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  difference?: InputMaybe<Scalars['jsonb']>;
  from?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  operation?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<Scalars['jsonb']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  to?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Fhir_Resources_History_Max_Fields = {
  __typename?: 'fhir_resources_history_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  operation?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "fhir_resources_history" */
export type Fhir_Resources_History_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operation?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Fhir_Resources_History_Min_Fields = {
  __typename?: 'fhir_resources_history_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  operation?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "fhir_resources_history" */
export type Fhir_Resources_History_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operation?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "fhir_resources_history" */
export type Fhir_Resources_History_Mutation_Response = {
  __typename?: 'fhir_resources_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fhir_Resources_History>;
};

/** on_conflict condition type for table "fhir_resources_history" */
export type Fhir_Resources_History_On_Conflict = {
  constraint: Fhir_Resources_History_Constraint;
  update_columns?: Array<Fhir_Resources_History_Update_Column>;
  where?: InputMaybe<Fhir_Resources_History_Bool_Exp>;
};

/** Ordering options when selecting data from "fhir_resources_history". */
export type Fhir_Resources_History_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  difference?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operation?: InputMaybe<Order_By>;
  session?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
};

/** primary key columns input for table: fhir_resources_history */
export type Fhir_Resources_History_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Fhir_Resources_History_Prepend_Input = {
  difference?: InputMaybe<Scalars['jsonb']>;
  from?: InputMaybe<Scalars['jsonb']>;
  session?: InputMaybe<Scalars['jsonb']>;
  to?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "fhir_resources_history" */
export type Fhir_Resources_History_Select_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'difference'
  /** column name */
  | 'from'
  /** column name */
  | 'id'
  /** column name */
  | 'operation'
  /** column name */
  | 'session'
  /** column name */
  | 'staffId'
  /** column name */
  | 'to';

/** input type for updating data in table "fhir_resources_history" */
export type Fhir_Resources_History_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  difference?: InputMaybe<Scalars['jsonb']>;
  from?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  operation?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<Scalars['jsonb']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  to?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "fhir_resources_history" */
export type Fhir_Resources_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fhir_Resources_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fhir_Resources_History_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  difference?: InputMaybe<Scalars['jsonb']>;
  from?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  operation?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<Scalars['jsonb']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  to?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "fhir_resources_history" */
export type Fhir_Resources_History_Update_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'difference'
  /** column name */
  | 'from'
  /** column name */
  | 'id'
  /** column name */
  | 'operation'
  /** column name */
  | 'session'
  /** column name */
  | 'staffId'
  /** column name */
  | 'to';

export type Fhir_Resources_History_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Fhir_Resources_History_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Fhir_Resources_History_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Fhir_Resources_History_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Fhir_Resources_History_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Fhir_Resources_History_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Fhir_Resources_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fhir_Resources_History_Bool_Exp;
};

/** input type for inserting data into table "fhir_resources" */
export type Fhir_Resources_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fhir_resource_type?: InputMaybe<Fhir_Resource_Types_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  resource?: InputMaybe<Scalars['jsonb']>;
  resourceType?: InputMaybe<Fhir_Resource_Types_Enum>;
  transactionId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Fhir_Resources_Max_Fields = {
  __typename?: 'fhir_resources_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "fhir_resources" */
export type Fhir_Resources_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  transactionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Fhir_Resources_Min_Fields = {
  __typename?: 'fhir_resources_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  transactionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "fhir_resources" */
export type Fhir_Resources_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  transactionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "fhir_resources" */
export type Fhir_Resources_Mutation_Response = {
  __typename?: 'fhir_resources_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fhir_Resources>;
};

/** on_conflict condition type for table "fhir_resources" */
export type Fhir_Resources_On_Conflict = {
  constraint: Fhir_Resources_Constraint;
  update_columns?: Array<Fhir_Resources_Update_Column>;
  where?: InputMaybe<Fhir_Resources_Bool_Exp>;
};

/** Ordering options when selecting data from "fhir_resources". */
export type Fhir_Resources_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  fhir_resource_type?: InputMaybe<Fhir_Resource_Types_Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  resource?: InputMaybe<Order_By>;
  resourceType?: InputMaybe<Order_By>;
  transactionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: fhir_resources */
export type Fhir_Resources_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Fhir_Resources_Prepend_Input = {
  resource?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "fhir_resources" */
export type Fhir_Resources_Select_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'patientId'
  /** column name */
  | 'resource'
  /** column name */
  | 'resourceType'
  /** column name */
  | 'transactionId'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "fhir_resources" */
export type Fhir_Resources_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  resource?: InputMaybe<Scalars['jsonb']>;
  resourceType?: InputMaybe<Fhir_Resource_Types_Enum>;
  transactionId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "fhir_resources" */
export type Fhir_Resources_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fhir_Resources_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fhir_Resources_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  resource?: InputMaybe<Scalars['jsonb']>;
  resourceType?: InputMaybe<Fhir_Resource_Types_Enum>;
  transactionId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "fhir_resources" */
export type Fhir_Resources_Update_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'patientId'
  /** column name */
  | 'resource'
  /** column name */
  | 'resourceType'
  /** column name */
  | 'transactionId'
  /** column name */
  | 'updatedAt';

export type Fhir_Resources_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Fhir_Resources_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Fhir_Resources_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Fhir_Resources_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Fhir_Resources_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Fhir_Resources_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Fhir_Resources_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fhir_Resources_Bool_Exp;
};

/** columns and relationships of "file_document_type" */
export type File_Document_Type = {
  __typename?: 'file_document_type';
  description: Scalars['String'];
  /** An array relationship */
  files: Array<Files>;
  /** An aggregate relationship */
  files_aggregate: Files_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "file_document_type" */
export type File_Document_TypeFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** columns and relationships of "file_document_type" */
export type File_Document_TypeFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** aggregated selection of "file_document_type" */
export type File_Document_Type_Aggregate = {
  __typename?: 'file_document_type_aggregate';
  aggregate?: Maybe<File_Document_Type_Aggregate_Fields>;
  nodes: Array<File_Document_Type>;
};

/** aggregate fields of "file_document_type" */
export type File_Document_Type_Aggregate_Fields = {
  __typename?: 'file_document_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<File_Document_Type_Max_Fields>;
  min?: Maybe<File_Document_Type_Min_Fields>;
};

/** aggregate fields of "file_document_type" */
export type File_Document_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<File_Document_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "file_document_type". All fields are combined with a logical 'AND'. */
export type File_Document_Type_Bool_Exp = {
  _and?: InputMaybe<Array<File_Document_Type_Bool_Exp>>;
  _not?: InputMaybe<File_Document_Type_Bool_Exp>;
  _or?: InputMaybe<Array<File_Document_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  files?: InputMaybe<Files_Bool_Exp>;
  files_aggregate?: InputMaybe<Files_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "file_document_type" */
export type File_Document_Type_Constraint =
  /** unique or primary key constraint on columns "value" */
  'file_document_type_pkey';

export type File_Document_Type_Enum =
  /** An avatar image */
  | 'AVATAR'
  /** Signed consent form */
  | 'CONSENT'
  /** A fax */
  | 'FAX'
  /** Back side of insurance card */
  | 'INSURANCE_CARD_BACK'
  /** Front side of photo identification */
  | 'INSURANCE_CARD_FRONT'
  /** Back side of photo identification */
  | 'PHOTO_ID_BACK'
  /** Front side of photo identification */
  | 'PHOTO_ID_FRONT'
  /** Signed medical records release form */
  | 'RECORDS_RELEASE';

/** Boolean expression to compare columns of type "file_document_type_enum". All fields are combined with logical 'AND'. */
export type File_Document_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<File_Document_Type_Enum>;
  _in?: InputMaybe<Array<File_Document_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<File_Document_Type_Enum>;
  _nin?: InputMaybe<Array<File_Document_Type_Enum>>;
};

/** input type for inserting data into table "file_document_type" */
export type File_Document_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<Files_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type File_Document_Type_Max_Fields = {
  __typename?: 'file_document_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type File_Document_Type_Min_Fields = {
  __typename?: 'file_document_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "file_document_type" */
export type File_Document_Type_Mutation_Response = {
  __typename?: 'file_document_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<File_Document_Type>;
};

/** input type for inserting object relation for remote table "file_document_type" */
export type File_Document_Type_Obj_Rel_Insert_Input = {
  data: File_Document_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<File_Document_Type_On_Conflict>;
};

/** on_conflict condition type for table "file_document_type" */
export type File_Document_Type_On_Conflict = {
  constraint: File_Document_Type_Constraint;
  update_columns?: Array<File_Document_Type_Update_Column>;
  where?: InputMaybe<File_Document_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "file_document_type". */
export type File_Document_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  files_aggregate?: InputMaybe<Files_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: file_document_type */
export type File_Document_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "file_document_type" */
export type File_Document_Type_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "file_document_type" */
export type File_Document_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "file_document_type" */
export type File_Document_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: File_Document_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type File_Document_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "file_document_type" */
export type File_Document_Type_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type File_Document_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<File_Document_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: File_Document_Type_Bool_Exp;
};

/** columns and relationships of "files" */
export type Files = {
  __typename?: 'files';
  /** An object relationship */
  account?: Maybe<Accounts>;
  accountId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  accounts: Array<Account_Patient>;
  /** An aggregate relationship */
  accounts_aggregate: Account_Patient_Aggregate;
  /** An array relationship */
  chat_messages_files: Array<Chat_Messages_File>;
  /** An aggregate relationship */
  chat_messages_files_aggregate: Chat_Messages_File_Aggregate;
  createdAt: Scalars['timestamptz'];
  documentType?: Maybe<File_Document_Type_Enum>;
  /** An array relationship */
  documents: Array<Documents>;
  /** An aggregate relationship */
  documents_aggregate: Documents_Aggregate;
  externalId?: Maybe<Scalars['String']>;
  /** An object relationship */
  file_document_type?: Maybe<File_Document_Type>;
  gcv?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  info?: Maybe<FilePayload>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  /** An object relationship */
  patient?: Maybe<Patients>;
  patientId?: Maybe<Scalars['uuid']>;
  status: Scalars['String'];
  /** An array relationship */
  tasks_files: Array<Tasks_Files>;
  /** An aggregate relationship */
  tasks_files_aggregate: Tasks_Files_Aggregate;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "files" */
export type FilesAccountsArgs = {
  distinct_on?: InputMaybe<Array<Account_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Patient_Order_By>>;
  where?: InputMaybe<Account_Patient_Bool_Exp>;
};

/** columns and relationships of "files" */
export type FilesAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Patient_Order_By>>;
  where?: InputMaybe<Account_Patient_Bool_Exp>;
};

/** columns and relationships of "files" */
export type FilesChat_Messages_FilesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_File_Order_By>>;
  where?: InputMaybe<Chat_Messages_File_Bool_Exp>;
};

/** columns and relationships of "files" */
export type FilesChat_Messages_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_File_Order_By>>;
  where?: InputMaybe<Chat_Messages_File_Bool_Exp>;
};

/** columns and relationships of "files" */
export type FilesDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** columns and relationships of "files" */
export type FilesDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** columns and relationships of "files" */
export type FilesGcvArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "files" */
export type FilesTasks_FilesArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Files_Order_By>>;
  where?: InputMaybe<Tasks_Files_Bool_Exp>;
};

/** columns and relationships of "files" */
export type FilesTasks_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Files_Order_By>>;
  where?: InputMaybe<Tasks_Files_Bool_Exp>;
};

/** aggregated selection of "files" */
export type Files_Aggregate = {
  __typename?: 'files_aggregate';
  aggregate?: Maybe<Files_Aggregate_Fields>;
  nodes: Array<Files>;
};

export type Files_Aggregate_Bool_Exp = {
  count?: InputMaybe<Files_Aggregate_Bool_Exp_Count>;
};

export type Files_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "files" */
export type Files_Aggregate_Fields = {
  __typename?: 'files_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Files_Max_Fields>;
  min?: Maybe<Files_Min_Fields>;
};

/** aggregate fields of "files" */
export type Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "files" */
export type Files_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Files_Max_Order_By>;
  min?: InputMaybe<Files_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Files_Append_Input = {
  gcv?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "files" */
export type Files_Arr_Rel_Insert_Input = {
  data: Array<Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** Boolean expression to filter rows from the table "files". All fields are combined with a logical 'AND'. */
export type Files_Bool_Exp = {
  _and?: InputMaybe<Array<Files_Bool_Exp>>;
  _not?: InputMaybe<Files_Bool_Exp>;
  _or?: InputMaybe<Array<Files_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  accounts?: InputMaybe<Account_Patient_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Account_Patient_Aggregate_Bool_Exp>;
  chat_messages_files?: InputMaybe<Chat_Messages_File_Bool_Exp>;
  chat_messages_files_aggregate?: InputMaybe<Chat_Messages_File_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  documentType?: InputMaybe<File_Document_Type_Enum_Comparison_Exp>;
  documents?: InputMaybe<Documents_Bool_Exp>;
  documents_aggregate?: InputMaybe<Documents_Aggregate_Bool_Exp>;
  externalId?: InputMaybe<String_Comparison_Exp>;
  file_document_type?: InputMaybe<File_Document_Type_Bool_Exp>;
  gcv?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tasks_files?: InputMaybe<Tasks_Files_Bool_Exp>;
  tasks_files_aggregate?: InputMaybe<Tasks_Files_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "files" */
export type Files_Constraint =
  /** unique or primary key constraint on columns "id" */
  'files_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Files_Delete_At_Path_Input = {
  gcv?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Files_Delete_Elem_Input = {
  gcv?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Files_Delete_Key_Input = {
  gcv?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "files" */
export type Files_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['uuid']>;
  accounts?: InputMaybe<Account_Patient_Arr_Rel_Insert_Input>;
  chat_messages_files?: InputMaybe<Chat_Messages_File_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  documentType?: InputMaybe<File_Document_Type_Enum>;
  documents?: InputMaybe<Documents_Arr_Rel_Insert_Input>;
  externalId?: InputMaybe<Scalars['String']>;
  file_document_type?: InputMaybe<File_Document_Type_Obj_Rel_Insert_Input>;
  gcv?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  tasks_files?: InputMaybe<Tasks_Files_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Files_Max_Fields = {
  __typename?: 'files_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "files" */
export type Files_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Files_Min_Fields = {
  __typename?: 'files_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "files" */
export type Files_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  externalId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "files" */
export type Files_Mutation_Response = {
  __typename?: 'files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "files" */
export type Files_Obj_Rel_Insert_Input = {
  data: Files_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** on_conflict condition type for table "files" */
export type Files_On_Conflict = {
  constraint: Files_Constraint;
  update_columns?: Array<Files_Update_Column>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** Ordering options when selecting data from "files". */
export type Files_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  accounts_aggregate?: InputMaybe<Account_Patient_Aggregate_Order_By>;
  chat_messages_files_aggregate?: InputMaybe<Chat_Messages_File_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  documentType?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Documents_Aggregate_Order_By>;
  externalId?: InputMaybe<Order_By>;
  file_document_type?: InputMaybe<File_Document_Type_Order_By>;
  gcv?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tasks_files_aggregate?: InputMaybe<Tasks_Files_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: files */
export type Files_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Files_Prepend_Input = {
  gcv?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "files" */
export type Files_Select_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'documentType'
  /** column name */
  | 'externalId'
  /** column name */
  | 'gcv'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'path'
  /** column name */
  | 'patientId'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "files" */
export type Files_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  documentType?: InputMaybe<File_Document_Type_Enum>;
  externalId?: InputMaybe<Scalars['String']>;
  gcv?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "files" */
export type Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Files_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  documentType?: InputMaybe<File_Document_Type_Enum>;
  externalId?: InputMaybe<Scalars['String']>;
  gcv?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "files" */
export type Files_Update_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'documentType'
  /** column name */
  | 'externalId'
  /** column name */
  | 'gcv'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'path'
  /** column name */
  | 'patientId'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt';

export type Files_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Files_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Files_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Files_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Files_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Files_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Files_Bool_Exp;
};

export type Geography_Cast_Exp = {
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: InputMaybe<Geography_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geography']>;
  _gt?: InputMaybe<Scalars['geography']>;
  _gte?: InputMaybe<Scalars['geography']>;
  _in?: InputMaybe<Array<Scalars['geography']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geography']>;
  _lte?: InputMaybe<Scalars['geography']>;
  _neq?: InputMaybe<Scalars['geography']>;
  _nin?: InputMaybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars['geography']>;
};

export type Geometry_Cast_Exp = {
  geography?: InputMaybe<Geography_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: InputMaybe<Geometry_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geometry']>;
  _gt?: InputMaybe<Scalars['geometry']>;
  _gte?: InputMaybe<Scalars['geometry']>;
  _in?: InputMaybe<Array<Scalars['geometry']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geometry']>;
  _lte?: InputMaybe<Scalars['geometry']>;
  _neq?: InputMaybe<Scalars['geometry']>;
  _nin?: InputMaybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars['geometry']>;
};

/** columns and relationships of "global_notes" */
export type Global_Notes = {
  __typename?: 'global_notes';
  /** An object relationship */
  account?: Maybe<Accounts>;
  accountId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  appointment?: Maybe<Appointments>;
  appointmentId?: Maybe<Scalars['uuid']>;
  communicationHistoryId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  communication_history?: Maybe<Communication_History>;
  createdAt: Scalars['timestamptz'];
  deleted: Scalars['Boolean'];
  id: Scalars['uuid'];
  note: Scalars['String'];
  /** An object relationship */
  patient?: Maybe<Patients>;
  patientId?: Maybe<Scalars['uuid']>;
  reasonDeleted?: Maybe<Scalars['String']>;
  /** An object relationship */
  staff?: Maybe<Staff>;
  staffId?: Maybe<Scalars['uuid']>;
  taskId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "global_notes" */
export type Global_Notes_Aggregate = {
  __typename?: 'global_notes_aggregate';
  aggregate?: Maybe<Global_Notes_Aggregate_Fields>;
  nodes: Array<Global_Notes>;
};

export type Global_Notes_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Global_Notes_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Global_Notes_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Global_Notes_Aggregate_Bool_Exp_Count>;
};

export type Global_Notes_Aggregate_Bool_Exp_Bool_And = {
  arguments: Global_Notes_Select_Column_Global_Notes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Global_Notes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Global_Notes_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Global_Notes_Select_Column_Global_Notes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Global_Notes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Global_Notes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Global_Notes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Global_Notes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "global_notes" */
export type Global_Notes_Aggregate_Fields = {
  __typename?: 'global_notes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Global_Notes_Max_Fields>;
  min?: Maybe<Global_Notes_Min_Fields>;
};

/** aggregate fields of "global_notes" */
export type Global_Notes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Global_Notes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "global_notes" */
export type Global_Notes_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Global_Notes_Max_Order_By>;
  min?: InputMaybe<Global_Notes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "global_notes" */
export type Global_Notes_Arr_Rel_Insert_Input = {
  data: Array<Global_Notes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Global_Notes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "global_notes". All fields are combined with a logical 'AND'. */
export type Global_Notes_Bool_Exp = {
  _and?: InputMaybe<Array<Global_Notes_Bool_Exp>>;
  _not?: InputMaybe<Global_Notes_Bool_Exp>;
  _or?: InputMaybe<Array<Global_Notes_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  appointment?: InputMaybe<Appointments_Bool_Exp>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  communicationHistoryId?: InputMaybe<Uuid_Comparison_Exp>;
  communication_history?: InputMaybe<Communication_History_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  reasonDeleted?: InputMaybe<String_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  taskId?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "global_notes" */
export type Global_Notes_Constraint =
  /** unique or primary key constraint on columns "id" */
  'global_notes_pkey';

/** input type for inserting data into table "global_notes" */
export type Global_Notes_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['uuid']>;
  appointment?: InputMaybe<Appointments_Obj_Rel_Insert_Input>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  communicationHistoryId?: InputMaybe<Scalars['uuid']>;
  communication_history?: InputMaybe<Communication_History_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  reasonDeleted?: InputMaybe<Scalars['String']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  taskId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Global_Notes_Max_Fields = {
  __typename?: 'global_notes_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  appointmentId?: Maybe<Scalars['uuid']>;
  communicationHistoryId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  reasonDeleted?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['uuid']>;
  taskId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "global_notes" */
export type Global_Notes_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  communicationHistoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  reasonDeleted?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  taskId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Global_Notes_Min_Fields = {
  __typename?: 'global_notes_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  appointmentId?: Maybe<Scalars['uuid']>;
  communicationHistoryId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  reasonDeleted?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['uuid']>;
  taskId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "global_notes" */
export type Global_Notes_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  communicationHistoryId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  reasonDeleted?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  taskId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "global_notes" */
export type Global_Notes_Mutation_Response = {
  __typename?: 'global_notes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Global_Notes>;
};

/** on_conflict condition type for table "global_notes" */
export type Global_Notes_On_Conflict = {
  constraint: Global_Notes_Constraint;
  update_columns?: Array<Global_Notes_Update_Column>;
  where?: InputMaybe<Global_Notes_Bool_Exp>;
};

/** Ordering options when selecting data from "global_notes". */
export type Global_Notes_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  appointment?: InputMaybe<Appointments_Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  communicationHistoryId?: InputMaybe<Order_By>;
  communication_history?: InputMaybe<Communication_History_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deleted?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  reasonDeleted?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  taskId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: global_notes */
export type Global_Notes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "global_notes" */
export type Global_Notes_Select_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'communicationHistoryId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'deleted'
  /** column name */
  | 'id'
  /** column name */
  | 'note'
  /** column name */
  | 'patientId'
  /** column name */
  | 'reasonDeleted'
  /** column name */
  | 'staffId'
  /** column name */
  | 'taskId'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt';

/** select "global_notes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "global_notes" */
export type Global_Notes_Select_Column_Global_Notes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  'deleted';

/** select "global_notes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "global_notes" */
export type Global_Notes_Select_Column_Global_Notes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  'deleted';

/** input type for updating data in table "global_notes" */
export type Global_Notes_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  communicationHistoryId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  reasonDeleted?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  taskId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "global_notes" */
export type Global_Notes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Global_Notes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Global_Notes_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  communicationHistoryId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  reasonDeleted?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  taskId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "global_notes" */
export type Global_Notes_Update_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'communicationHistoryId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'deleted'
  /** column name */
  | 'id'
  /** column name */
  | 'note'
  /** column name */
  | 'patientId'
  /** column name */
  | 'reasonDeleted'
  /** column name */
  | 'staffId'
  /** column name */
  | 'taskId'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt';

export type Global_Notes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Global_Notes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Global_Notes_Bool_Exp;
};

/** columns and relationships of "insurance" */
export type Insurance = {
  __typename?: 'insurance';
  /** An object relationship */
  account: Accounts;
  accountId: Scalars['uuid'];
  active: Scalars['Boolean'];
  back?: Maybe<FilePayload>;
  backId?: Maybe<Scalars['String']>;
  carrier?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  dateOfBirth?: Maybe<Scalars['date']>;
  eligibility?: Maybe<Scalars['jsonb']>;
  firstName?: Maybe<Scalars['String']>;
  front?: Maybe<FilePayload>;
  frontId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  insurance_type?: Maybe<Insurance_Types>;
  lastName?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  /** An object relationship */
  patient: Patients;
  patientId: Scalars['uuid'];
  type: Insurance_Types_Enum;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "insurance" */
export type InsuranceEligibilityArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "insurance" */
export type Insurance_Aggregate = {
  __typename?: 'insurance_aggregate';
  aggregate?: Maybe<Insurance_Aggregate_Fields>;
  nodes: Array<Insurance>;
};

export type Insurance_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Insurance_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Insurance_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Insurance_Aggregate_Bool_Exp_Count>;
};

export type Insurance_Aggregate_Bool_Exp_Bool_And = {
  arguments: Insurance_Select_Column_Insurance_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Insurance_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Insurance_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Insurance_Select_Column_Insurance_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Insurance_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Insurance_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Insurance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Insurance_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "insurance" */
export type Insurance_Aggregate_Fields = {
  __typename?: 'insurance_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Insurance_Max_Fields>;
  min?: Maybe<Insurance_Min_Fields>;
};

/** aggregate fields of "insurance" */
export type Insurance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Insurance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "insurance" */
export type Insurance_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Insurance_Max_Order_By>;
  min?: InputMaybe<Insurance_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Insurance_Append_Input = {
  eligibility?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "insurance" */
export type Insurance_Arr_Rel_Insert_Input = {
  data: Array<Insurance_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Insurance_On_Conflict>;
};

/** Boolean expression to filter rows from the table "insurance". All fields are combined with a logical 'AND'. */
export type Insurance_Bool_Exp = {
  _and?: InputMaybe<Array<Insurance_Bool_Exp>>;
  _not?: InputMaybe<Insurance_Bool_Exp>;
  _or?: InputMaybe<Array<Insurance_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  backId?: InputMaybe<String_Comparison_Exp>;
  carrier?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dateOfBirth?: InputMaybe<Date_Comparison_Exp>;
  eligibility?: InputMaybe<Jsonb_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  frontId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  insurance_type?: InputMaybe<Insurance_Types_Bool_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  memberId?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Insurance_Types_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "insurance" */
export type Insurance_Constraint =
  /** unique or primary key constraint on columns "id" */
  'insurance_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Insurance_Delete_At_Path_Input = {
  eligibility?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Insurance_Delete_Elem_Input = {
  eligibility?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Insurance_Delete_Key_Input = {
  eligibility?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "insurance" */
export type Insurance_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['uuid']>;
  active?: InputMaybe<Scalars['Boolean']>;
  backId?: InputMaybe<Scalars['String']>;
  carrier?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dateOfBirth?: InputMaybe<Scalars['date']>;
  eligibility?: InputMaybe<Scalars['jsonb']>;
  firstName?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insurance_type?: InputMaybe<Insurance_Types_Obj_Rel_Insert_Input>;
  lastName?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Insurance_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Insurance_Max_Fields = {
  __typename?: 'insurance_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  backId?: Maybe<Scalars['String']>;
  carrier?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  firstName?: Maybe<Scalars['String']>;
  frontId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "insurance" */
export type Insurance_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  backId?: InputMaybe<Order_By>;
  carrier?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dateOfBirth?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  frontId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  memberId?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Insurance_Min_Fields = {
  __typename?: 'insurance_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  backId?: Maybe<Scalars['String']>;
  carrier?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  firstName?: Maybe<Scalars['String']>;
  frontId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "insurance" */
export type Insurance_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  backId?: InputMaybe<Order_By>;
  carrier?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dateOfBirth?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  frontId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  memberId?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "insurance" */
export type Insurance_Mutation_Response = {
  __typename?: 'insurance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Insurance>;
};

/** on_conflict condition type for table "insurance" */
export type Insurance_On_Conflict = {
  constraint: Insurance_Constraint;
  update_columns?: Array<Insurance_Update_Column>;
  where?: InputMaybe<Insurance_Bool_Exp>;
};

/** Ordering options when selecting data from "insurance". */
export type Insurance_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  active?: InputMaybe<Order_By>;
  backId?: InputMaybe<Order_By>;
  carrier?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dateOfBirth?: InputMaybe<Order_By>;
  eligibility?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  frontId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurance_type?: InputMaybe<Insurance_Types_Order_By>;
  lastName?: InputMaybe<Order_By>;
  memberId?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: insurance */
export type Insurance_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Insurance_Prepend_Input = {
  eligibility?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "insurance_providers" */
export type Insurance_Providers = {
  __typename?: 'insurance_providers';
  active: Scalars['Boolean'];
  apero_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  displayName: Scalars['String'];
  id: Scalars['uuid'];
  payer_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "insurance_providers" */
export type Insurance_Providers_Aggregate = {
  __typename?: 'insurance_providers_aggregate';
  aggregate?: Maybe<Insurance_Providers_Aggregate_Fields>;
  nodes: Array<Insurance_Providers>;
};

/** aggregate fields of "insurance_providers" */
export type Insurance_Providers_Aggregate_Fields = {
  __typename?: 'insurance_providers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Insurance_Providers_Max_Fields>;
  min?: Maybe<Insurance_Providers_Min_Fields>;
};

/** aggregate fields of "insurance_providers" */
export type Insurance_Providers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Insurance_Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "insurance_providers". All fields are combined with a logical 'AND'. */
export type Insurance_Providers_Bool_Exp = {
  _and?: InputMaybe<Array<Insurance_Providers_Bool_Exp>>;
  _not?: InputMaybe<Insurance_Providers_Bool_Exp>;
  _or?: InputMaybe<Array<Insurance_Providers_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  apero_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  displayName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  payer_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "insurance_providers" */
export type Insurance_Providers_Constraint =
  /** unique or primary key constraint on columns "id" */
  'insurance_providers_pkey';

/** input type for inserting data into table "insurance_providers" */
export type Insurance_Providers_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  apero_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  displayName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  payer_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Insurance_Providers_Max_Fields = {
  __typename?: 'insurance_providers_max_fields';
  apero_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  payer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Insurance_Providers_Min_Fields = {
  __typename?: 'insurance_providers_min_fields';
  apero_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  payer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "insurance_providers" */
export type Insurance_Providers_Mutation_Response = {
  __typename?: 'insurance_providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Insurance_Providers>;
};

/** on_conflict condition type for table "insurance_providers" */
export type Insurance_Providers_On_Conflict = {
  constraint: Insurance_Providers_Constraint;
  update_columns?: Array<Insurance_Providers_Update_Column>;
  where?: InputMaybe<Insurance_Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "insurance_providers". */
export type Insurance_Providers_Order_By = {
  active?: InputMaybe<Order_By>;
  apero_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: insurance_providers */
export type Insurance_Providers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "insurance_providers" */
export type Insurance_Providers_Select_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'apero_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'displayName'
  /** column name */
  | 'id'
  /** column name */
  | 'payer_id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "insurance_providers" */
export type Insurance_Providers_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  apero_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  displayName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  payer_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "insurance_providers" */
export type Insurance_Providers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Insurance_Providers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Insurance_Providers_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  apero_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  displayName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  payer_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "insurance_providers" */
export type Insurance_Providers_Update_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'apero_id'
  /** column name */
  | 'created_at'
  /** column name */
  | 'displayName'
  /** column name */
  | 'id'
  /** column name */
  | 'payer_id'
  /** column name */
  | 'updated_at';

export type Insurance_Providers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Insurance_Providers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Insurance_Providers_Bool_Exp;
};

/** select columns of table "insurance" */
export type Insurance_Select_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'active'
  /** column name */
  | 'backId'
  /** column name */
  | 'carrier'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'dateOfBirth'
  /** column name */
  | 'eligibility'
  /** column name */
  | 'firstName'
  /** column name */
  | 'frontId'
  /** column name */
  | 'id'
  /** column name */
  | 'lastName'
  /** column name */
  | 'memberId'
  /** column name */
  | 'patientId'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'userId';

/** select "insurance_aggregate_bool_exp_bool_and_arguments_columns" columns of table "insurance" */
export type Insurance_Select_Column_Insurance_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  'active';

/** select "insurance_aggregate_bool_exp_bool_or_arguments_columns" columns of table "insurance" */
export type Insurance_Select_Column_Insurance_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  'active';

/** input type for updating data in table "insurance" */
export type Insurance_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  active?: InputMaybe<Scalars['Boolean']>;
  backId?: InputMaybe<Scalars['String']>;
  carrier?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dateOfBirth?: InputMaybe<Scalars['date']>;
  eligibility?: InputMaybe<Scalars['jsonb']>;
  firstName?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Insurance_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "insurance" */
export type Insurance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Insurance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Insurance_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  active?: InputMaybe<Scalars['Boolean']>;
  backId?: InputMaybe<Scalars['String']>;
  carrier?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dateOfBirth?: InputMaybe<Scalars['date']>;
  eligibility?: InputMaybe<Scalars['jsonb']>;
  firstName?: InputMaybe<Scalars['String']>;
  frontId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Insurance_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "insurance_types" */
export type Insurance_Types = {
  __typename?: 'insurance_types';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "insurance_types" */
export type Insurance_Types_Aggregate = {
  __typename?: 'insurance_types_aggregate';
  aggregate?: Maybe<Insurance_Types_Aggregate_Fields>;
  nodes: Array<Insurance_Types>;
};

/** aggregate fields of "insurance_types" */
export type Insurance_Types_Aggregate_Fields = {
  __typename?: 'insurance_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Insurance_Types_Max_Fields>;
  min?: Maybe<Insurance_Types_Min_Fields>;
};

/** aggregate fields of "insurance_types" */
export type Insurance_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Insurance_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "insurance_types". All fields are combined with a logical 'AND'. */
export type Insurance_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Insurance_Types_Bool_Exp>>;
  _not?: InputMaybe<Insurance_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Insurance_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "insurance_types" */
export type Insurance_Types_Constraint =
  /** unique or primary key constraint on columns "value" */
  'insurance_types_pkey';

export type Insurance_Types_Enum =
  /** Primary Insurance */
  | 'PRIMARY'
  /** Secondary Insurance */
  | 'SECONDARY';

/** Boolean expression to compare columns of type "insurance_types_enum". All fields are combined with logical 'AND'. */
export type Insurance_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Insurance_Types_Enum>;
  _in?: InputMaybe<Array<Insurance_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Insurance_Types_Enum>;
  _nin?: InputMaybe<Array<Insurance_Types_Enum>>;
};

/** input type for inserting data into table "insurance_types" */
export type Insurance_Types_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Insurance_Types_Max_Fields = {
  __typename?: 'insurance_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Insurance_Types_Min_Fields = {
  __typename?: 'insurance_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "insurance_types" */
export type Insurance_Types_Mutation_Response = {
  __typename?: 'insurance_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Insurance_Types>;
};

/** input type for inserting object relation for remote table "insurance_types" */
export type Insurance_Types_Obj_Rel_Insert_Input = {
  data: Insurance_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Insurance_Types_On_Conflict>;
};

/** on_conflict condition type for table "insurance_types" */
export type Insurance_Types_On_Conflict = {
  constraint: Insurance_Types_Constraint;
  update_columns?: Array<Insurance_Types_Update_Column>;
  where?: InputMaybe<Insurance_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "insurance_types". */
export type Insurance_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: insurance_types */
export type Insurance_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "insurance_types" */
export type Insurance_Types_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "insurance_types" */
export type Insurance_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "insurance_types" */
export type Insurance_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Insurance_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Insurance_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "insurance_types" */
export type Insurance_Types_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Insurance_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Insurance_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Insurance_Types_Bool_Exp;
};

/** update columns of table "insurance" */
export type Insurance_Update_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'active'
  /** column name */
  | 'backId'
  /** column name */
  | 'carrier'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'dateOfBirth'
  /** column name */
  | 'eligibility'
  /** column name */
  | 'firstName'
  /** column name */
  | 'frontId'
  /** column name */
  | 'id'
  /** column name */
  | 'lastName'
  /** column name */
  | 'memberId'
  /** column name */
  | 'patientId'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'userId';

export type Insurance_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Insurance_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Insurance_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Insurance_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Insurance_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Insurance_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Insurance_Set_Input>;
  /** filter the rows which have to be updated */
  where: Insurance_Bool_Exp;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "kareo_logs" */
export type Kareo_Logs = {
  __typename?: 'kareo_logs';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  migration_data?: Maybe<Scalars['jsonb']>;
  patient_id?: Maybe<Scalars['uuid']>;
  request_action: Scalars['String'];
  result_data?: Maybe<Scalars['jsonb']>;
  staff?: Maybe<Scalars['String']>;
  /** An object relationship */
  staffByStaffid?: Maybe<Staff>;
  staffId?: Maybe<Scalars['uuid']>;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "kareo_logs" */
export type Kareo_LogsMigration_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "kareo_logs" */
export type Kareo_LogsResult_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "kareo_logs" */
export type Kareo_Logs_Aggregate = {
  __typename?: 'kareo_logs_aggregate';
  aggregate?: Maybe<Kareo_Logs_Aggregate_Fields>;
  nodes: Array<Kareo_Logs>;
};

export type Kareo_Logs_Aggregate_Bool_Exp = {
  count?: InputMaybe<Kareo_Logs_Aggregate_Bool_Exp_Count>;
};

export type Kareo_Logs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Kareo_Logs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Kareo_Logs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "kareo_logs" */
export type Kareo_Logs_Aggregate_Fields = {
  __typename?: 'kareo_logs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Kareo_Logs_Max_Fields>;
  min?: Maybe<Kareo_Logs_Min_Fields>;
};

/** aggregate fields of "kareo_logs" */
export type Kareo_Logs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Kareo_Logs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "kareo_logs" */
export type Kareo_Logs_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Kareo_Logs_Max_Order_By>;
  min?: InputMaybe<Kareo_Logs_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Kareo_Logs_Append_Input = {
  migration_data?: InputMaybe<Scalars['jsonb']>;
  result_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "kareo_logs" */
export type Kareo_Logs_Arr_Rel_Insert_Input = {
  data: Array<Kareo_Logs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Kareo_Logs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "kareo_logs". All fields are combined with a logical 'AND'. */
export type Kareo_Logs_Bool_Exp = {
  _and?: InputMaybe<Array<Kareo_Logs_Bool_Exp>>;
  _not?: InputMaybe<Kareo_Logs_Bool_Exp>;
  _or?: InputMaybe<Array<Kareo_Logs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  migration_data?: InputMaybe<Jsonb_Comparison_Exp>;
  patient_id?: InputMaybe<Uuid_Comparison_Exp>;
  request_action?: InputMaybe<String_Comparison_Exp>;
  result_data?: InputMaybe<Jsonb_Comparison_Exp>;
  staff?: InputMaybe<String_Comparison_Exp>;
  staffByStaffid?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "kareo_logs" */
export type Kareo_Logs_Constraint =
  /** unique or primary key constraint on columns "id" */
  'kareo_logs_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Kareo_Logs_Delete_At_Path_Input = {
  migration_data?: InputMaybe<Array<Scalars['String']>>;
  result_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Kareo_Logs_Delete_Elem_Input = {
  migration_data?: InputMaybe<Scalars['Int']>;
  result_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Kareo_Logs_Delete_Key_Input = {
  migration_data?: InputMaybe<Scalars['String']>;
  result_data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "kareo_logs" */
export type Kareo_Logs_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  migration_data?: InputMaybe<Scalars['jsonb']>;
  patient_id?: InputMaybe<Scalars['uuid']>;
  request_action?: InputMaybe<Scalars['String']>;
  result_data?: InputMaybe<Scalars['jsonb']>;
  staff?: InputMaybe<Scalars['String']>;
  staffByStaffid?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Kareo_Logs_Max_Fields = {
  __typename?: 'kareo_logs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patient_id?: Maybe<Scalars['uuid']>;
  request_action?: Maybe<Scalars['String']>;
  staff?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "kareo_logs" */
export type Kareo_Logs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  request_action?: InputMaybe<Order_By>;
  staff?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Kareo_Logs_Min_Fields = {
  __typename?: 'kareo_logs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patient_id?: Maybe<Scalars['uuid']>;
  request_action?: Maybe<Scalars['String']>;
  staff?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "kareo_logs" */
export type Kareo_Logs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  request_action?: InputMaybe<Order_By>;
  staff?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "kareo_logs" */
export type Kareo_Logs_Mutation_Response = {
  __typename?: 'kareo_logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kareo_Logs>;
};

/** on_conflict condition type for table "kareo_logs" */
export type Kareo_Logs_On_Conflict = {
  constraint: Kareo_Logs_Constraint;
  update_columns?: Array<Kareo_Logs_Update_Column>;
  where?: InputMaybe<Kareo_Logs_Bool_Exp>;
};

/** Ordering options when selecting data from "kareo_logs". */
export type Kareo_Logs_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  migration_data?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  request_action?: InputMaybe<Order_By>;
  result_data?: InputMaybe<Order_By>;
  staff?: InputMaybe<Order_By>;
  staffByStaffid?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: kareo_logs */
export type Kareo_Logs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Kareo_Logs_Prepend_Input = {
  migration_data?: InputMaybe<Scalars['jsonb']>;
  result_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "kareo_logs" */
export type Kareo_Logs_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'migration_data'
  /** column name */
  | 'patient_id'
  /** column name */
  | 'request_action'
  /** column name */
  | 'result_data'
  /** column name */
  | 'staff'
  /** column name */
  | 'staffId'
  /** column name */
  | 'status'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "kareo_logs" */
export type Kareo_Logs_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  migration_data?: InputMaybe<Scalars['jsonb']>;
  patient_id?: InputMaybe<Scalars['uuid']>;
  request_action?: InputMaybe<Scalars['String']>;
  result_data?: InputMaybe<Scalars['jsonb']>;
  staff?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "kareo_logs" */
export type Kareo_Logs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Kareo_Logs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Kareo_Logs_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  migration_data?: InputMaybe<Scalars['jsonb']>;
  patient_id?: InputMaybe<Scalars['uuid']>;
  request_action?: InputMaybe<Scalars['String']>;
  result_data?: InputMaybe<Scalars['jsonb']>;
  staff?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "kareo_logs" */
export type Kareo_Logs_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'migration_data'
  /** column name */
  | 'patient_id'
  /** column name */
  | 'request_action'
  /** column name */
  | 'result_data'
  /** column name */
  | 'staff'
  /** column name */
  | 'staffId'
  /** column name */
  | 'status'
  /** column name */
  | 'updated_at';

export type Kareo_Logs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Kareo_Logs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Kareo_Logs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Kareo_Logs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Kareo_Logs_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Kareo_Logs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Kareo_Logs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Kareo_Logs_Bool_Exp;
};

/** columns and relationships of "locations" */
export type Locations = {
  __typename?: 'locations';
  /** An object relationship */
  clinic: Clinics;
  clinicCode: Scalars['String'];
  /** An array relationship */
  devices: Array<Devices>;
  /** An aggregate relationship */
  devices_aggregate: Devices_Aggregate;
  id: Scalars['uuid'];
  major: Scalars['Int'];
  minor: Scalars['Int'];
  name: Scalars['String'];
};

/** columns and relationships of "locations" */
export type LocationsDevicesArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};

/** columns and relationships of "locations" */
export type LocationsDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};

/** aggregated selection of "locations" */
export type Locations_Aggregate = {
  __typename?: 'locations_aggregate';
  aggregate?: Maybe<Locations_Aggregate_Fields>;
  nodes: Array<Locations>;
};

export type Locations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Locations_Aggregate_Bool_Exp_Count>;
};

export type Locations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Locations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "locations" */
export type Locations_Aggregate_Fields = {
  __typename?: 'locations_aggregate_fields';
  avg?: Maybe<Locations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Locations_Max_Fields>;
  min?: Maybe<Locations_Min_Fields>;
  stddev?: Maybe<Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Locations_Sum_Fields>;
  var_pop?: Maybe<Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Locations_Var_Samp_Fields>;
  variance?: Maybe<Locations_Variance_Fields>;
};

/** aggregate fields of "locations" */
export type Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "locations" */
export type Locations_Aggregate_Order_By = {
  avg?: InputMaybe<Locations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Locations_Max_Order_By>;
  min?: InputMaybe<Locations_Min_Order_By>;
  stddev?: InputMaybe<Locations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Locations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Locations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Locations_Sum_Order_By>;
  var_pop?: InputMaybe<Locations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Locations_Var_Samp_Order_By>;
  variance?: InputMaybe<Locations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "locations" */
export type Locations_Arr_Rel_Insert_Input = {
  data: Array<Locations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};

/** aggregate avg on columns */
export type Locations_Avg_Fields = {
  __typename?: 'locations_avg_fields';
  major?: Maybe<Scalars['Float']>;
  minor?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "locations" */
export type Locations_Avg_Order_By = {
  major?: InputMaybe<Order_By>;
  minor?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export type Locations_Bool_Exp = {
  _and?: InputMaybe<Array<Locations_Bool_Exp>>;
  _not?: InputMaybe<Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Locations_Bool_Exp>>;
  clinic?: InputMaybe<Clinics_Bool_Exp>;
  clinicCode?: InputMaybe<String_Comparison_Exp>;
  devices?: InputMaybe<Devices_Bool_Exp>;
  devices_aggregate?: InputMaybe<Devices_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  major?: InputMaybe<Int_Comparison_Exp>;
  minor?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "locations" */
export type Locations_Constraint =
  /** unique or primary key constraint on columns "id" */
  'locations_pkey';

/** input type for incrementing numeric columns in table "locations" */
export type Locations_Inc_Input = {
  major?: InputMaybe<Scalars['Int']>;
  minor?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "locations" */
export type Locations_Insert_Input = {
  clinic?: InputMaybe<Clinics_Obj_Rel_Insert_Input>;
  clinicCode?: InputMaybe<Scalars['String']>;
  devices?: InputMaybe<Devices_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  major?: InputMaybe<Scalars['Int']>;
  minor?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Locations_Max_Fields = {
  __typename?: 'locations_max_fields';
  clinicCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  major?: Maybe<Scalars['Int']>;
  minor?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "locations" */
export type Locations_Max_Order_By = {
  clinicCode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  major?: InputMaybe<Order_By>;
  minor?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Locations_Min_Fields = {
  __typename?: 'locations_min_fields';
  clinicCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  major?: Maybe<Scalars['Int']>;
  minor?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "locations" */
export type Locations_Min_Order_By = {
  clinicCode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  major?: InputMaybe<Order_By>;
  minor?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "locations" */
export type Locations_Mutation_Response = {
  __typename?: 'locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Locations>;
};

/** input type for inserting object relation for remote table "locations" */
export type Locations_Obj_Rel_Insert_Input = {
  data: Locations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};

/** on_conflict condition type for table "locations" */
export type Locations_On_Conflict = {
  constraint: Locations_Constraint;
  update_columns?: Array<Locations_Update_Column>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "locations". */
export type Locations_Order_By = {
  clinic?: InputMaybe<Clinics_Order_By>;
  clinicCode?: InputMaybe<Order_By>;
  devices_aggregate?: InputMaybe<Devices_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  major?: InputMaybe<Order_By>;
  minor?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: locations */
export type Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "locations" */
export type Locations_Select_Column =
  /** column name */
  | 'clinicCode'
  /** column name */
  | 'id'
  /** column name */
  | 'major'
  /** column name */
  | 'minor'
  /** column name */
  | 'name';

/** input type for updating data in table "locations" */
export type Locations_Set_Input = {
  clinicCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  major?: InputMaybe<Scalars['Int']>;
  minor?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Locations_Stddev_Fields = {
  __typename?: 'locations_stddev_fields';
  major?: Maybe<Scalars['Float']>;
  minor?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "locations" */
export type Locations_Stddev_Order_By = {
  major?: InputMaybe<Order_By>;
  minor?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Locations_Stddev_Pop_Fields = {
  __typename?: 'locations_stddev_pop_fields';
  major?: Maybe<Scalars['Float']>;
  minor?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "locations" */
export type Locations_Stddev_Pop_Order_By = {
  major?: InputMaybe<Order_By>;
  minor?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Locations_Stddev_Samp_Fields = {
  __typename?: 'locations_stddev_samp_fields';
  major?: Maybe<Scalars['Float']>;
  minor?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "locations" */
export type Locations_Stddev_Samp_Order_By = {
  major?: InputMaybe<Order_By>;
  minor?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "locations" */
export type Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Locations_Stream_Cursor_Value_Input = {
  clinicCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  major?: InputMaybe<Scalars['Int']>;
  minor?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Locations_Sum_Fields = {
  __typename?: 'locations_sum_fields';
  major?: Maybe<Scalars['Int']>;
  minor?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "locations" */
export type Locations_Sum_Order_By = {
  major?: InputMaybe<Order_By>;
  minor?: InputMaybe<Order_By>;
};

/** update columns of table "locations" */
export type Locations_Update_Column =
  /** column name */
  | 'clinicCode'
  /** column name */
  | 'id'
  /** column name */
  | 'major'
  /** column name */
  | 'minor'
  /** column name */
  | 'name';

export type Locations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Locations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Locations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Locations_Var_Pop_Fields = {
  __typename?: 'locations_var_pop_fields';
  major?: Maybe<Scalars['Float']>;
  minor?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "locations" */
export type Locations_Var_Pop_Order_By = {
  major?: InputMaybe<Order_By>;
  minor?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Locations_Var_Samp_Fields = {
  __typename?: 'locations_var_samp_fields';
  major?: Maybe<Scalars['Float']>;
  minor?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "locations" */
export type Locations_Var_Samp_Order_By = {
  major?: InputMaybe<Order_By>;
  minor?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Locations_Variance_Fields = {
  __typename?: 'locations_variance_fields';
  major?: Maybe<Scalars['Float']>;
  minor?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "locations" */
export type Locations_Variance_Order_By = {
  major?: InputMaybe<Order_By>;
  minor?: InputMaybe<Order_By>;
};

/** columns and relationships of "medical_history" */
export type Medical_History = {
  __typename?: 'medical_history';
  codeCPT?: Maybe<Scalars['String']>;
  codeICD10?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  endedAt?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  /** An object relationship */
  medical_history_person_type?: Maybe<Medical_History_Person_Types>;
  /** An object relationship */
  medical_history_type: Medical_History_Types;
  name: Scalars['String'];
  /** An object relationship */
  patient: Patients;
  patientId: Scalars['uuid'];
  /** An object relationship */
  patient_record_status_type?: Maybe<Patient_Record_Status_Types>;
  person?: Maybe<Medical_History_Person_Types_Enum>;
  /** An object relationship */
  staff: Staff;
  startedAt?: Maybe<Scalars['date']>;
  status?: Maybe<Patient_Record_Status_Types_Enum>;
  type: Medical_History_Types_Enum;
  updatedAt: Scalars['timestamptz'];
  updatedByStaffId: Scalars['uuid'];
};

/** aggregated selection of "medical_history" */
export type Medical_History_Aggregate = {
  __typename?: 'medical_history_aggregate';
  aggregate?: Maybe<Medical_History_Aggregate_Fields>;
  nodes: Array<Medical_History>;
};

export type Medical_History_Aggregate_Bool_Exp = {
  count?: InputMaybe<Medical_History_Aggregate_Bool_Exp_Count>;
};

export type Medical_History_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Medical_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Medical_History_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "medical_history" */
export type Medical_History_Aggregate_Fields = {
  __typename?: 'medical_history_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Medical_History_Max_Fields>;
  min?: Maybe<Medical_History_Min_Fields>;
};

/** aggregate fields of "medical_history" */
export type Medical_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Medical_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "medical_history" */
export type Medical_History_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Medical_History_Max_Order_By>;
  min?: InputMaybe<Medical_History_Min_Order_By>;
};

/** Answers for medical history questions */
export type Medical_History_Answers = {
  __typename?: 'medical_history_answers';
  answer?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  medical_history_question: Medical_History_Questions;
  /** An object relationship */
  patient: Patients;
  patientId: Scalars['uuid'];
  questionId: Scalars['uuid'];
  /** An object relationship */
  staff: Staff;
  updatedAt: Scalars['timestamptz'];
  updatedByStaffId: Scalars['uuid'];
};

/** aggregated selection of "medical_history_answers" */
export type Medical_History_Answers_Aggregate = {
  __typename?: 'medical_history_answers_aggregate';
  aggregate?: Maybe<Medical_History_Answers_Aggregate_Fields>;
  nodes: Array<Medical_History_Answers>;
};

export type Medical_History_Answers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Medical_History_Answers_Aggregate_Bool_Exp_Count>;
};

export type Medical_History_Answers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Medical_History_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Medical_History_Answers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "medical_history_answers" */
export type Medical_History_Answers_Aggregate_Fields = {
  __typename?: 'medical_history_answers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Medical_History_Answers_Max_Fields>;
  min?: Maybe<Medical_History_Answers_Min_Fields>;
};

/** aggregate fields of "medical_history_answers" */
export type Medical_History_Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Medical_History_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "medical_history_answers" */
export type Medical_History_Answers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Medical_History_Answers_Max_Order_By>;
  min?: InputMaybe<Medical_History_Answers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "medical_history_answers" */
export type Medical_History_Answers_Arr_Rel_Insert_Input = {
  data: Array<Medical_History_Answers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Medical_History_Answers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "medical_history_answers". All fields are combined with a logical 'AND'. */
export type Medical_History_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Medical_History_Answers_Bool_Exp>>;
  _not?: InputMaybe<Medical_History_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Medical_History_Answers_Bool_Exp>>;
  answer?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  medical_history_question?: InputMaybe<Medical_History_Questions_Bool_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  questionId?: InputMaybe<Uuid_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedByStaffId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "medical_history_answers" */
export type Medical_History_Answers_Constraint =
  /** unique or primary key constraint on columns "id" */
  'medical_history_answers_pkey';

/** input type for inserting data into table "medical_history_answers" */
export type Medical_History_Answers_Insert_Input = {
  answer?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  medical_history_question?: InputMaybe<Medical_History_Questions_Obj_Rel_Insert_Input>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  questionId?: InputMaybe<Scalars['uuid']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedByStaffId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Medical_History_Answers_Max_Fields = {
  __typename?: 'medical_history_answers_max_fields';
  answer?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  questionId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedByStaffId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "medical_history_answers" */
export type Medical_History_Answers_Max_Order_By = {
  answer?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  questionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedByStaffId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Medical_History_Answers_Min_Fields = {
  __typename?: 'medical_history_answers_min_fields';
  answer?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  questionId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedByStaffId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "medical_history_answers" */
export type Medical_History_Answers_Min_Order_By = {
  answer?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  questionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedByStaffId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "medical_history_answers" */
export type Medical_History_Answers_Mutation_Response = {
  __typename?: 'medical_history_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Medical_History_Answers>;
};

/** on_conflict condition type for table "medical_history_answers" */
export type Medical_History_Answers_On_Conflict = {
  constraint: Medical_History_Answers_Constraint;
  update_columns?: Array<Medical_History_Answers_Update_Column>;
  where?: InputMaybe<Medical_History_Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "medical_history_answers". */
export type Medical_History_Answers_Order_By = {
  answer?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medical_history_question?: InputMaybe<Medical_History_Questions_Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  questionId?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedByStaffId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: medical_history_answers */
export type Medical_History_Answers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "medical_history_answers" */
export type Medical_History_Answers_Select_Column =
  /** column name */
  | 'answer'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'patientId'
  /** column name */
  | 'questionId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedByStaffId';

/** input type for updating data in table "medical_history_answers" */
export type Medical_History_Answers_Set_Input = {
  answer?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  questionId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedByStaffId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "medical_history_answers" */
export type Medical_History_Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Medical_History_Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Medical_History_Answers_Stream_Cursor_Value_Input = {
  answer?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  questionId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedByStaffId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "medical_history_answers" */
export type Medical_History_Answers_Update_Column =
  /** column name */
  | 'answer'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'patientId'
  /** column name */
  | 'questionId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedByStaffId';

export type Medical_History_Answers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Medical_History_Answers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Medical_History_Answers_Bool_Exp;
};

/** input type for inserting array relation for remote table "medical_history" */
export type Medical_History_Arr_Rel_Insert_Input = {
  data: Array<Medical_History_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Medical_History_On_Conflict>;
};

/** Boolean expression to filter rows from the table "medical_history". All fields are combined with a logical 'AND'. */
export type Medical_History_Bool_Exp = {
  _and?: InputMaybe<Array<Medical_History_Bool_Exp>>;
  _not?: InputMaybe<Medical_History_Bool_Exp>;
  _or?: InputMaybe<Array<Medical_History_Bool_Exp>>;
  codeCPT?: InputMaybe<String_Comparison_Exp>;
  codeICD10?: InputMaybe<String_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  endedAt?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  medical_history_person_type?: InputMaybe<Medical_History_Person_Types_Bool_Exp>;
  medical_history_type?: InputMaybe<Medical_History_Types_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  patient_record_status_type?: InputMaybe<Patient_Record_Status_Types_Bool_Exp>;
  person?: InputMaybe<Medical_History_Person_Types_Enum_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  startedAt?: InputMaybe<Date_Comparison_Exp>;
  status?: InputMaybe<Patient_Record_Status_Types_Enum_Comparison_Exp>;
  type?: InputMaybe<Medical_History_Types_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedByStaffId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "medical_history" */
export type Medical_History_Constraint =
  /** unique or primary key constraint on columns "id" */
  'medical_history_pkey';

/** input type for inserting data into table "medical_history" */
export type Medical_History_Insert_Input = {
  codeCPT?: InputMaybe<Scalars['String']>;
  codeICD10?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  endedAt?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  medical_history_person_type?: InputMaybe<Medical_History_Person_Types_Obj_Rel_Insert_Input>;
  medical_history_type?: InputMaybe<Medical_History_Types_Obj_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  patient_record_status_type?: InputMaybe<Patient_Record_Status_Types_Obj_Rel_Insert_Input>;
  person?: InputMaybe<Medical_History_Person_Types_Enum>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  startedAt?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Patient_Record_Status_Types_Enum>;
  type?: InputMaybe<Medical_History_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedByStaffId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Medical_History_Max_Fields = {
  __typename?: 'medical_history_max_fields';
  codeCPT?: Maybe<Scalars['String']>;
  codeICD10?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  endedAt?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  startedAt?: Maybe<Scalars['date']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedByStaffId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "medical_history" */
export type Medical_History_Max_Order_By = {
  codeCPT?: InputMaybe<Order_By>;
  codeICD10?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  endedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  startedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedByStaffId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Medical_History_Min_Fields = {
  __typename?: 'medical_history_min_fields';
  codeCPT?: Maybe<Scalars['String']>;
  codeICD10?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  endedAt?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  startedAt?: Maybe<Scalars['date']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedByStaffId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "medical_history" */
export type Medical_History_Min_Order_By = {
  codeCPT?: InputMaybe<Order_By>;
  codeICD10?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  endedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  startedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedByStaffId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "medical_history" */
export type Medical_History_Mutation_Response = {
  __typename?: 'medical_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Medical_History>;
};

/** on_conflict condition type for table "medical_history" */
export type Medical_History_On_Conflict = {
  constraint: Medical_History_Constraint;
  update_columns?: Array<Medical_History_Update_Column>;
  where?: InputMaybe<Medical_History_Bool_Exp>;
};

/** Ordering options when selecting data from "medical_history". */
export type Medical_History_Order_By = {
  codeCPT?: InputMaybe<Order_By>;
  codeICD10?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  endedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medical_history_person_type?: InputMaybe<Medical_History_Person_Types_Order_By>;
  medical_history_type?: InputMaybe<Medical_History_Types_Order_By>;
  name?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  patient_record_status_type?: InputMaybe<Patient_Record_Status_Types_Order_By>;
  person?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  startedAt?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  updatedByStaffId?: InputMaybe<Order_By>;
};

/** columns and relationships of "medical_history_person_types" */
export type Medical_History_Person_Types = {
  __typename?: 'medical_history_person_types';
  description: Scalars['String'];
  /** An array relationship */
  medical_histories: Array<Medical_History>;
  /** An aggregate relationship */
  medical_histories_aggregate: Medical_History_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "medical_history_person_types" */
export type Medical_History_Person_TypesMedical_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Order_By>>;
  where?: InputMaybe<Medical_History_Bool_Exp>;
};

/** columns and relationships of "medical_history_person_types" */
export type Medical_History_Person_TypesMedical_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Order_By>>;
  where?: InputMaybe<Medical_History_Bool_Exp>;
};

/** aggregated selection of "medical_history_person_types" */
export type Medical_History_Person_Types_Aggregate = {
  __typename?: 'medical_history_person_types_aggregate';
  aggregate?: Maybe<Medical_History_Person_Types_Aggregate_Fields>;
  nodes: Array<Medical_History_Person_Types>;
};

/** aggregate fields of "medical_history_person_types" */
export type Medical_History_Person_Types_Aggregate_Fields = {
  __typename?: 'medical_history_person_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Medical_History_Person_Types_Max_Fields>;
  min?: Maybe<Medical_History_Person_Types_Min_Fields>;
};

/** aggregate fields of "medical_history_person_types" */
export type Medical_History_Person_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Medical_History_Person_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "medical_history_person_types". All fields are combined with a logical 'AND'. */
export type Medical_History_Person_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Medical_History_Person_Types_Bool_Exp>>;
  _not?: InputMaybe<Medical_History_Person_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Medical_History_Person_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  medical_histories?: InputMaybe<Medical_History_Bool_Exp>;
  medical_histories_aggregate?: InputMaybe<Medical_History_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "medical_history_person_types" */
export type Medical_History_Person_Types_Constraint =
  /** unique or primary key constraint on columns "value" */
  'medical_history_person_types_pkey';

export type Medical_History_Person_Types_Enum =
  /** family */
  | 'FAMILY'
  /** father */
  | 'FATHER'
  /** mother */
  | 'MOTHER'
  /** other */
  | 'OTHER'
  /** patient */
  | 'PATIENT'
  /** sibling */
  | 'SIBLING';

/** Boolean expression to compare columns of type "medical_history_person_types_enum". All fields are combined with logical 'AND'. */
export type Medical_History_Person_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Medical_History_Person_Types_Enum>;
  _in?: InputMaybe<Array<Medical_History_Person_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Medical_History_Person_Types_Enum>;
  _nin?: InputMaybe<Array<Medical_History_Person_Types_Enum>>;
};

/** input type for inserting data into table "medical_history_person_types" */
export type Medical_History_Person_Types_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  medical_histories?: InputMaybe<Medical_History_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Medical_History_Person_Types_Max_Fields = {
  __typename?: 'medical_history_person_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Medical_History_Person_Types_Min_Fields = {
  __typename?: 'medical_history_person_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "medical_history_person_types" */
export type Medical_History_Person_Types_Mutation_Response = {
  __typename?: 'medical_history_person_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Medical_History_Person_Types>;
};

/** input type for inserting object relation for remote table "medical_history_person_types" */
export type Medical_History_Person_Types_Obj_Rel_Insert_Input = {
  data: Medical_History_Person_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Medical_History_Person_Types_On_Conflict>;
};

/** on_conflict condition type for table "medical_history_person_types" */
export type Medical_History_Person_Types_On_Conflict = {
  constraint: Medical_History_Person_Types_Constraint;
  update_columns?: Array<Medical_History_Person_Types_Update_Column>;
  where?: InputMaybe<Medical_History_Person_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "medical_history_person_types". */
export type Medical_History_Person_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  medical_histories_aggregate?: InputMaybe<Medical_History_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: medical_history_person_types */
export type Medical_History_Person_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "medical_history_person_types" */
export type Medical_History_Person_Types_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "medical_history_person_types" */
export type Medical_History_Person_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "medical_history_person_types" */
export type Medical_History_Person_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Medical_History_Person_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Medical_History_Person_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "medical_history_person_types" */
export type Medical_History_Person_Types_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Medical_History_Person_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Medical_History_Person_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Medical_History_Person_Types_Bool_Exp;
};

/** primary key columns input for table: medical_history */
export type Medical_History_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** Questions for medical history */
export type Medical_History_Questions = {
  __typename?: 'medical_history_questions';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  medical_history_answers: Array<Medical_History_Answers>;
  /** An aggregate relationship */
  medical_history_answers_aggregate: Medical_History_Answers_Aggregate;
  question: Scalars['String'];
  questionKey: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** Questions for medical history */
export type Medical_History_QuestionsMedical_History_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Answers_Order_By>>;
  where?: InputMaybe<Medical_History_Answers_Bool_Exp>;
};

/** Questions for medical history */
export type Medical_History_QuestionsMedical_History_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Answers_Order_By>>;
  where?: InputMaybe<Medical_History_Answers_Bool_Exp>;
};

/** aggregated selection of "medical_history_questions" */
export type Medical_History_Questions_Aggregate = {
  __typename?: 'medical_history_questions_aggregate';
  aggregate?: Maybe<Medical_History_Questions_Aggregate_Fields>;
  nodes: Array<Medical_History_Questions>;
};

/** aggregate fields of "medical_history_questions" */
export type Medical_History_Questions_Aggregate_Fields = {
  __typename?: 'medical_history_questions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Medical_History_Questions_Max_Fields>;
  min?: Maybe<Medical_History_Questions_Min_Fields>;
};

/** aggregate fields of "medical_history_questions" */
export type Medical_History_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Medical_History_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "medical_history_questions". All fields are combined with a logical 'AND'. */
export type Medical_History_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Medical_History_Questions_Bool_Exp>>;
  _not?: InputMaybe<Medical_History_Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Medical_History_Questions_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  medical_history_answers?: InputMaybe<Medical_History_Answers_Bool_Exp>;
  medical_history_answers_aggregate?: InputMaybe<Medical_History_Answers_Aggregate_Bool_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  questionKey?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "medical_history_questions" */
export type Medical_History_Questions_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'medical_history_questions_pkey'
  /** unique or primary key constraint on columns "questionKey" */
  | 'medical_history_questions_questionKey_key';

/** input type for inserting data into table "medical_history_questions" */
export type Medical_History_Questions_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  medical_history_answers?: InputMaybe<Medical_History_Answers_Arr_Rel_Insert_Input>;
  question?: InputMaybe<Scalars['String']>;
  questionKey?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Medical_History_Questions_Max_Fields = {
  __typename?: 'medical_history_questions_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  questionKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Medical_History_Questions_Min_Fields = {
  __typename?: 'medical_history_questions_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  questionKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "medical_history_questions" */
export type Medical_History_Questions_Mutation_Response = {
  __typename?: 'medical_history_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Medical_History_Questions>;
};

/** input type for inserting object relation for remote table "medical_history_questions" */
export type Medical_History_Questions_Obj_Rel_Insert_Input = {
  data: Medical_History_Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Medical_History_Questions_On_Conflict>;
};

/** on_conflict condition type for table "medical_history_questions" */
export type Medical_History_Questions_On_Conflict = {
  constraint: Medical_History_Questions_Constraint;
  update_columns?: Array<Medical_History_Questions_Update_Column>;
  where?: InputMaybe<Medical_History_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "medical_history_questions". */
export type Medical_History_Questions_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medical_history_answers_aggregate?: InputMaybe<Medical_History_Answers_Aggregate_Order_By>;
  question?: InputMaybe<Order_By>;
  questionKey?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: medical_history_questions */
export type Medical_History_Questions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "medical_history_questions" */
export type Medical_History_Questions_Select_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'question'
  /** column name */
  | 'questionKey'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "medical_history_questions" */
export type Medical_History_Questions_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  question?: InputMaybe<Scalars['String']>;
  questionKey?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "medical_history_questions" */
export type Medical_History_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Medical_History_Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Medical_History_Questions_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  question?: InputMaybe<Scalars['String']>;
  questionKey?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "medical_history_questions" */
export type Medical_History_Questions_Update_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'question'
  /** column name */
  | 'questionKey'
  /** column name */
  | 'updatedAt';

export type Medical_History_Questions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Medical_History_Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Medical_History_Questions_Bool_Exp;
};

/** select columns of table "medical_history" */
export type Medical_History_Select_Column =
  /** column name */
  | 'codeCPT'
  /** column name */
  | 'codeICD10'
  /** column name */
  | 'comments'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'description'
  /** column name */
  | 'endedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'patientId'
  /** column name */
  | 'person'
  /** column name */
  | 'startedAt'
  /** column name */
  | 'status'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedByStaffId';

/** input type for updating data in table "medical_history" */
export type Medical_History_Set_Input = {
  codeCPT?: InputMaybe<Scalars['String']>;
  codeICD10?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  endedAt?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  person?: InputMaybe<Medical_History_Person_Types_Enum>;
  startedAt?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Patient_Record_Status_Types_Enum>;
  type?: InputMaybe<Medical_History_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedByStaffId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "medical_history" */
export type Medical_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Medical_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Medical_History_Stream_Cursor_Value_Input = {
  codeCPT?: InputMaybe<Scalars['String']>;
  codeICD10?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  endedAt?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  person?: InputMaybe<Medical_History_Person_Types_Enum>;
  startedAt?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Patient_Record_Status_Types_Enum>;
  type?: InputMaybe<Medical_History_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedByStaffId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "medical_history_types" */
export type Medical_History_Types = {
  __typename?: 'medical_history_types';
  description: Scalars['String'];
  /** An array relationship */
  medical_histories: Array<Medical_History>;
  /** An aggregate relationship */
  medical_histories_aggregate: Medical_History_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "medical_history_types" */
export type Medical_History_TypesMedical_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Order_By>>;
  where?: InputMaybe<Medical_History_Bool_Exp>;
};

/** columns and relationships of "medical_history_types" */
export type Medical_History_TypesMedical_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Order_By>>;
  where?: InputMaybe<Medical_History_Bool_Exp>;
};

/** aggregated selection of "medical_history_types" */
export type Medical_History_Types_Aggregate = {
  __typename?: 'medical_history_types_aggregate';
  aggregate?: Maybe<Medical_History_Types_Aggregate_Fields>;
  nodes: Array<Medical_History_Types>;
};

/** aggregate fields of "medical_history_types" */
export type Medical_History_Types_Aggregate_Fields = {
  __typename?: 'medical_history_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Medical_History_Types_Max_Fields>;
  min?: Maybe<Medical_History_Types_Min_Fields>;
};

/** aggregate fields of "medical_history_types" */
export type Medical_History_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Medical_History_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "medical_history_types". All fields are combined with a logical 'AND'. */
export type Medical_History_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Medical_History_Types_Bool_Exp>>;
  _not?: InputMaybe<Medical_History_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Medical_History_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  medical_histories?: InputMaybe<Medical_History_Bool_Exp>;
  medical_histories_aggregate?: InputMaybe<Medical_History_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "medical_history_types" */
export type Medical_History_Types_Constraint =
  /** unique or primary key constraint on columns "value" */
  'medical_history_types_pkey';

export type Medical_History_Types_Enum =
  /** diagnosis */
  | 'DIAGNOSIS'
  /** hospitalization */
  | 'HOSPITALIZATION'
  /** procedure */
  | 'PROCEDURE';

/** Boolean expression to compare columns of type "medical_history_types_enum". All fields are combined with logical 'AND'. */
export type Medical_History_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Medical_History_Types_Enum>;
  _in?: InputMaybe<Array<Medical_History_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Medical_History_Types_Enum>;
  _nin?: InputMaybe<Array<Medical_History_Types_Enum>>;
};

/** input type for inserting data into table "medical_history_types" */
export type Medical_History_Types_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  medical_histories?: InputMaybe<Medical_History_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Medical_History_Types_Max_Fields = {
  __typename?: 'medical_history_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Medical_History_Types_Min_Fields = {
  __typename?: 'medical_history_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "medical_history_types" */
export type Medical_History_Types_Mutation_Response = {
  __typename?: 'medical_history_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Medical_History_Types>;
};

/** input type for inserting object relation for remote table "medical_history_types" */
export type Medical_History_Types_Obj_Rel_Insert_Input = {
  data: Medical_History_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Medical_History_Types_On_Conflict>;
};

/** on_conflict condition type for table "medical_history_types" */
export type Medical_History_Types_On_Conflict = {
  constraint: Medical_History_Types_Constraint;
  update_columns?: Array<Medical_History_Types_Update_Column>;
  where?: InputMaybe<Medical_History_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "medical_history_types". */
export type Medical_History_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  medical_histories_aggregate?: InputMaybe<Medical_History_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: medical_history_types */
export type Medical_History_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "medical_history_types" */
export type Medical_History_Types_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "medical_history_types" */
export type Medical_History_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "medical_history_types" */
export type Medical_History_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Medical_History_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Medical_History_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "medical_history_types" */
export type Medical_History_Types_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Medical_History_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Medical_History_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Medical_History_Types_Bool_Exp;
};

/** update columns of table "medical_history" */
export type Medical_History_Update_Column =
  /** column name */
  | 'codeCPT'
  /** column name */
  | 'codeICD10'
  /** column name */
  | 'comments'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'description'
  /** column name */
  | 'endedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'patientId'
  /** column name */
  | 'person'
  /** column name */
  | 'startedAt'
  /** column name */
  | 'status'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedByStaffId';

export type Medical_History_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Medical_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Medical_History_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  AddAccount?: Maybe<AddAccountOutput>;
  AddPatientTelehealthConsent?: Maybe<PatientTelehealthConsentOutput>;
  AttachAccountToPatient?: Maybe<AttachAccountOutput>;
  Auth0CreateEmailAccount?: Maybe<Auth0UserIdOutput>;
  Auth0LinkAccounts?: Maybe<Auth0LinkAccountsOutput>;
  Auth0LoadEmployee?: Maybe<Auth0EmployeeOutput>;
  Auth0PasswordlessVerify?: Maybe<PasswordlessVerifyOutput>;
  Auth0ResetPassword?: Maybe<Auth0PayloadOutput>;
  BookAppointment?: Maybe<BookAppointmentOutput>;
  BookWalkinAppointment?: Maybe<BookWalkinAppointmentOutput>;
  CreateAccountPatient?: Maybe<AccountPatientOutput>;
  CreateKareoAppointment?: Maybe<CreateKareoAppointmentOutput>;
  CreateKareoPatient?: Maybe<CreateKareoPatientOutput>;
  CreatePatient?: Maybe<CreatePatientOutput>;
  CreatePaymentMethod?: Maybe<StripeCustomerOutput>;
  CreateReview?: Maybe<ReviewResultOutput>;
  CreateSupportTicket?: Maybe<CreateSupportTicketOutput>;
  CreateSymptomCheckerSession?: Maybe<SymptomCheckerCreatePayload>;
  /** Endpoint for CustomerIO to call to get data. */
  CustomerIOGetCoupon?: Maybe<CustomerIoGetCouponOutput>;
  DeletePaymentMethod?: Maybe<StripeCustomerOutput>;
  FindKareoPatient?: Maybe<Array<Maybe<KareoFindPatientOutput>>>;
  /** Generates a new temporary verification code for accounts that cannot retrieve the SMS code from Twilio Verify */
  GenerateTemporaryVerificationCode?: Maybe<TemporaryVerification>;
  HandleStripeWebhook: HandleStripeWebhookOutput;
  InsertCommunicationHistory?: Maybe<CommunicationOutput>;
  MergePatients?: Maybe<MergePatientOutput>;
  NormalizeStaffRoles?: Maybe<ApplyStaffRolesOutput>;
  OneTimeCharge?: Maybe<ChargeResponseOutput>;
  RegisterDeviceToken?: Maybe<RegisterDeviceTokenOutput>;
  RequestUpload?: Maybe<RequestUploadOutput>;
  ScheduleAppointment?: Maybe<ScheduleAppointmentOutput>;
  SendSymptomSummary?: Maybe<SendSymptomSummaryOutput>;
  SendTextNotification?: Maybe<SendNotificationOutput>;
  SendVCard?: Maybe<SendVCardOutput>;
  SignConsent?: Maybe<SignConsentOutput>;
  /** Allows a staff member to book an appointment without restrictions around availability. */
  StaffBookAppointment?: Maybe<StaffBookAppointmentOutput>;
  StaffUpdateAppointmentTime?: Maybe<StaffUpdateAppointmentTimeOutput>;
  StripeCreateRefund: StripeCreateRefundOutput;
  StripeRedeemSubscriptionPromoCode: StripeRedeemSubscriptionPromoCodeResponse;
  TrackAction?: Maybe<TrackEventOutput>;
  UpdateAccountPhone?: Maybe<UpdateAccountPhoneOutput>;
  UpdateAppointmentStatus?: Maybe<UpdateAppointmentStatusOutput>;
  UpdateAppointmentTime?: Maybe<UpdateAppointmentTimeOutput>;
  UpdateCommunicationHistory?: Maybe<CommunicationHistoryOutput>;
  UpdateKareoPatient?: Maybe<UpdateKareoPatientOutput>;
  UpdatePaymentMethod?: Maybe<StripeCustomerOutput>;
  UpdateSymptomCheckerSession?: Maybe<UpdateSymptomCheckerSessionPayload>;
  UploadKareoDocuments?: Maybe<UploadKareoDocumentsOutput>;
  UpsertReservation?: Maybe<ReservationOutput>;
  VerifyStaffMember?: Maybe<VerifyStaffMemberOutput>;
  WiwUserSync?: Maybe<WiwUserSyncOutput>;
  /** delete data from the table: "account_patient" */
  delete_account_patient?: Maybe<Account_Patient_Mutation_Response>;
  /** delete single row from the table: "account_patient" */
  delete_account_patient_by_pk?: Maybe<Account_Patient>;
  /** delete data from the table: "account_temporary_verification" */
  delete_account_temporary_verification?: Maybe<Account_Temporary_Verification_Mutation_Response>;
  /** delete single row from the table: "account_temporary_verification" */
  delete_account_temporary_verification_by_pk?: Maybe<Account_Temporary_Verification>;
  /** delete data from the table: "accounts" */
  delete_accounts?: Maybe<Accounts_Mutation_Response>;
  /** delete single row from the table: "accounts" */
  delete_accounts_by_pk?: Maybe<Accounts>;
  /** delete data from the table: "accounts_limited" */
  delete_accounts_limited?: Maybe<Accounts_Limited_Mutation_Response>;
  /** delete data from the table: "allergies" */
  delete_allergies?: Maybe<Allergies_Mutation_Response>;
  /** delete single row from the table: "allergies" */
  delete_allergies_by_pk?: Maybe<Allergies>;
  /** delete data from the table: "allergies_severity" */
  delete_allergies_severity?: Maybe<Allergies_Severity_Mutation_Response>;
  /** delete single row from the table: "allergies_severity" */
  delete_allergies_severity_by_pk?: Maybe<Allergies_Severity>;
  /** delete data from the table: "allergies_status" */
  delete_allergies_status?: Maybe<Allergies_Status_Mutation_Response>;
  /** delete single row from the table: "allergies_status" */
  delete_allergies_status_by_pk?: Maybe<Allergies_Status>;
  /** delete data from the table: "appointment_blocks" */
  delete_appointment_blocks?: Maybe<Appointment_Blocks_Mutation_Response>;
  /** delete single row from the table: "appointment_blocks" */
  delete_appointment_blocks_by_pk?: Maybe<Appointment_Blocks>;
  /** delete data from the table: "appointment_blocks_staff" */
  delete_appointment_blocks_staff?: Maybe<Appointment_Blocks_Staff_Mutation_Response>;
  /** delete single row from the table: "appointment_blocks_staff" */
  delete_appointment_blocks_staff_by_pk?: Maybe<Appointment_Blocks_Staff>;
  /** delete data from the table: "appointment_patient" */
  delete_appointment_patient?: Maybe<Appointment_Patient_Mutation_Response>;
  /** delete single row from the table: "appointment_patient" */
  delete_appointment_patient_by_pk?: Maybe<Appointment_Patient>;
  /** delete data from the table: "appointment_payment" */
  delete_appointment_payment?: Maybe<Appointment_Payment_Mutation_Response>;
  /** delete single row from the table: "appointment_payment" */
  delete_appointment_payment_by_pk?: Maybe<Appointment_Payment>;
  /** delete data from the table: "appointment_reservations" */
  delete_appointment_reservations?: Maybe<Appointment_Reservations_Mutation_Response>;
  /** delete single row from the table: "appointment_reservations" */
  delete_appointment_reservations_by_pk?: Maybe<Appointment_Reservations>;
  /** delete data from the table: "appointment_staff" */
  delete_appointment_staff?: Maybe<Appointment_Staff_Mutation_Response>;
  /** delete single row from the table: "appointment_staff" */
  delete_appointment_staff_by_pk?: Maybe<Appointment_Staff>;
  /** delete data from the table: "appointment_status" */
  delete_appointment_status?: Maybe<Appointment_Status_Mutation_Response>;
  /** delete single row from the table: "appointment_status" */
  delete_appointment_status_by_pk?: Maybe<Appointment_Status>;
  /** delete data from the table: "appointment_types" */
  delete_appointment_types?: Maybe<Appointment_Types_Mutation_Response>;
  /** delete single row from the table: "appointment_types" */
  delete_appointment_types_by_pk?: Maybe<Appointment_Types>;
  /** delete data from the table: "appointments" */
  delete_appointments?: Maybe<Appointments_Mutation_Response>;
  /** delete single row from the table: "appointments" */
  delete_appointments_by_pk?: Maybe<Appointments>;
  /** delete data from the table: "audit_logs" */
  delete_audit_logs?: Maybe<Audit_Logs_Mutation_Response>;
  /** delete single row from the table: "audit_logs" */
  delete_audit_logs_by_pk?: Maybe<Audit_Logs>;
  /** delete data from the table: "care_type_duration" */
  delete_care_type_duration?: Maybe<Care_Type_Duration_Mutation_Response>;
  /** delete single row from the table: "care_type_duration" */
  delete_care_type_duration_by_pk?: Maybe<Care_Type_Duration>;
  /** delete data from the table: "care_types" */
  delete_care_types?: Maybe<Care_Types_Mutation_Response>;
  /** delete single row from the table: "care_types" */
  delete_care_types_by_pk?: Maybe<Care_Types>;
  /** delete data from the table: "charge_products" */
  delete_charge_products?: Maybe<Charge_Products_Mutation_Response>;
  /** delete single row from the table: "charge_products" */
  delete_charge_products_by_pk?: Maybe<Charge_Products>;
  /** delete data from the table: "charges" */
  delete_charges?: Maybe<Charges_Mutation_Response>;
  /** delete single row from the table: "charges" */
  delete_charges_by_pk?: Maybe<Charges>;
  /** delete data from the table: "chat_messages" */
  delete_chat_messages?: Maybe<Chat_Messages_Mutation_Response>;
  /** delete single row from the table: "chat_messages" */
  delete_chat_messages_by_pk?: Maybe<Chat_Messages>;
  /** delete data from the table: "chat_messages_file" */
  delete_chat_messages_file?: Maybe<Chat_Messages_File_Mutation_Response>;
  /** delete single row from the table: "chat_messages_file" */
  delete_chat_messages_file_by_pk?: Maybe<Chat_Messages_File>;
  /** delete data from the table: "chat_messages_type" */
  delete_chat_messages_type?: Maybe<Chat_Messages_Type_Mutation_Response>;
  /** delete single row from the table: "chat_messages_type" */
  delete_chat_messages_type_by_pk?: Maybe<Chat_Messages_Type>;
  /** delete data from the table: "chat_notes" */
  delete_chat_notes?: Maybe<Chat_Notes_Mutation_Response>;
  /** delete single row from the table: "chat_notes" */
  delete_chat_notes_by_pk?: Maybe<Chat_Notes>;
  /** delete data from the table: "chat_notes_types" */
  delete_chat_notes_types?: Maybe<Chat_Notes_Types_Mutation_Response>;
  /** delete single row from the table: "chat_notes_types" */
  delete_chat_notes_types_by_pk?: Maybe<Chat_Notes_Types>;
  /** delete data from the table: "chat_sessions" */
  delete_chat_sessions?: Maybe<Chat_Sessions_Mutation_Response>;
  /** delete data from the table: "chat_sessions_accounts" */
  delete_chat_sessions_accounts?: Maybe<Chat_Sessions_Accounts_Mutation_Response>;
  /** delete single row from the table: "chat_sessions_accounts" */
  delete_chat_sessions_accounts_by_pk?: Maybe<Chat_Sessions_Accounts>;
  /** delete single row from the table: "chat_sessions" */
  delete_chat_sessions_by_pk?: Maybe<Chat_Sessions>;
  /** delete data from the table: "chat_sessions_patients" */
  delete_chat_sessions_patients?: Maybe<Chat_Sessions_Patients_Mutation_Response>;
  /** delete single row from the table: "chat_sessions_patients" */
  delete_chat_sessions_patients_by_pk?: Maybe<Chat_Sessions_Patients>;
  /** delete data from the table: "chat_sessions_read" */
  delete_chat_sessions_read?: Maybe<Chat_Sessions_Read_Mutation_Response>;
  /** delete single row from the table: "chat_sessions_read" */
  delete_chat_sessions_read_by_pk?: Maybe<Chat_Sessions_Read>;
  /** delete data from the table: "chat_sessions_staff" */
  delete_chat_sessions_staff?: Maybe<Chat_Sessions_Staff_Mutation_Response>;
  /** delete single row from the table: "chat_sessions_staff" */
  delete_chat_sessions_staff_by_pk?: Maybe<Chat_Sessions_Staff>;
  /** delete data from the table: "chat_sessions_status" */
  delete_chat_sessions_status?: Maybe<Chat_Sessions_Status_Mutation_Response>;
  /** delete single row from the table: "chat_sessions_status" */
  delete_chat_sessions_status_by_pk?: Maybe<Chat_Sessions_Status>;
  /** delete data from the table: "clinic_location_types" */
  delete_clinic_location_types?: Maybe<Clinic_Location_Types_Mutation_Response>;
  /** delete single row from the table: "clinic_location_types" */
  delete_clinic_location_types_by_pk?: Maybe<Clinic_Location_Types>;
  /** delete data from the table: "clinic_status" */
  delete_clinic_status?: Maybe<Clinic_Status_Mutation_Response>;
  /** delete single row from the table: "clinic_status" */
  delete_clinic_status_by_pk?: Maybe<Clinic_Status>;
  /** delete data from the table: "clinics" */
  delete_clinics?: Maybe<Clinics_Mutation_Response>;
  /** delete single row from the table: "clinics" */
  delete_clinics_by_pk?: Maybe<Clinics>;
  /** delete data from the table: "clinics_properties" */
  delete_clinics_properties?: Maybe<Clinics_Properties_Mutation_Response>;
  /** delete single row from the table: "clinics_properties" */
  delete_clinics_properties_by_pk?: Maybe<Clinics_Properties>;
  /** delete data from the table: "clinics_properties_types" */
  delete_clinics_properties_types?: Maybe<Clinics_Properties_Types_Mutation_Response>;
  /** delete single row from the table: "clinics_properties_types" */
  delete_clinics_properties_types_by_pk?: Maybe<Clinics_Properties_Types>;
  /** delete data from the table: "clinics_regions" */
  delete_clinics_regions?: Maybe<Clinics_Regions_Mutation_Response>;
  /** delete single row from the table: "clinics_regions" */
  delete_clinics_regions_by_pk?: Maybe<Clinics_Regions>;
  /** delete data from the table: "communication_history" */
  delete_communication_history?: Maybe<Communication_History_Mutation_Response>;
  /** delete single row from the table: "communication_history" */
  delete_communication_history_by_pk?: Maybe<Communication_History>;
  /** delete data from the table: "communication_history_directions" */
  delete_communication_history_directions?: Maybe<Communication_History_Directions_Mutation_Response>;
  /** delete single row from the table: "communication_history_directions" */
  delete_communication_history_directions_by_pk?: Maybe<Communication_History_Directions>;
  /** delete data from the table: "communication_history_tag_types" */
  delete_communication_history_tag_types?: Maybe<Communication_History_Tag_Types_Mutation_Response>;
  /** delete single row from the table: "communication_history_tag_types" */
  delete_communication_history_tag_types_by_pk?: Maybe<Communication_History_Tag_Types>;
  /** delete data from the table: "communication_history_tags" */
  delete_communication_history_tags?: Maybe<Communication_History_Tags_Mutation_Response>;
  /** delete single row from the table: "communication_history_tags" */
  delete_communication_history_tags_by_pk?: Maybe<Communication_History_Tags>;
  /** delete data from the table: "communication_history_types" */
  delete_communication_history_types?: Maybe<Communication_History_Types_Mutation_Response>;
  /** delete single row from the table: "communication_history_types" */
  delete_communication_history_types_by_pk?: Maybe<Communication_History_Types>;
  /** delete data from the table: "devices" */
  delete_devices?: Maybe<Devices_Mutation_Response>;
  /** delete single row from the table: "devices" */
  delete_devices_by_pk?: Maybe<Devices>;
  /** delete data from the table: "discovered_from" */
  delete_discovered_from?: Maybe<Discovered_From_Mutation_Response>;
  /** delete single row from the table: "discovered_from" */
  delete_discovered_from_by_pk?: Maybe<Discovered_From>;
  /** delete data from the table: "document_status_types" */
  delete_document_status_types?: Maybe<Document_Status_Types_Mutation_Response>;
  /** delete single row from the table: "document_status_types" */
  delete_document_status_types_by_pk?: Maybe<Document_Status_Types>;
  /** delete data from the table: "document_types" */
  delete_document_types?: Maybe<Document_Types_Mutation_Response>;
  /** delete single row from the table: "document_types" */
  delete_document_types_by_pk?: Maybe<Document_Types>;
  /** delete data from the table: "documents" */
  delete_documents?: Maybe<Documents_Mutation_Response>;
  /** delete single row from the table: "documents" */
  delete_documents_by_pk?: Maybe<Documents>;
  /** delete data from the table: "fhir_resource_types" */
  delete_fhir_resource_types?: Maybe<Fhir_Resource_Types_Mutation_Response>;
  /** delete single row from the table: "fhir_resource_types" */
  delete_fhir_resource_types_by_pk?: Maybe<Fhir_Resource_Types>;
  /** delete data from the table: "fhir_resources" */
  delete_fhir_resources?: Maybe<Fhir_Resources_Mutation_Response>;
  /** delete single row from the table: "fhir_resources" */
  delete_fhir_resources_by_pk?: Maybe<Fhir_Resources>;
  /** delete data from the table: "fhir_resources_history" */
  delete_fhir_resources_history?: Maybe<Fhir_Resources_History_Mutation_Response>;
  /** delete single row from the table: "fhir_resources_history" */
  delete_fhir_resources_history_by_pk?: Maybe<Fhir_Resources_History>;
  /** delete data from the table: "file_document_type" */
  delete_file_document_type?: Maybe<File_Document_Type_Mutation_Response>;
  /** delete single row from the table: "file_document_type" */
  delete_file_document_type_by_pk?: Maybe<File_Document_Type>;
  /** delete data from the table: "files" */
  delete_files?: Maybe<Files_Mutation_Response>;
  /** delete single row from the table: "files" */
  delete_files_by_pk?: Maybe<Files>;
  /** delete data from the table: "global_notes" */
  delete_global_notes?: Maybe<Global_Notes_Mutation_Response>;
  /** delete single row from the table: "global_notes" */
  delete_global_notes_by_pk?: Maybe<Global_Notes>;
  /** delete data from the table: "insurance" */
  delete_insurance?: Maybe<Insurance_Mutation_Response>;
  /** delete single row from the table: "insurance" */
  delete_insurance_by_pk?: Maybe<Insurance>;
  /** delete data from the table: "insurance_providers" */
  delete_insurance_providers?: Maybe<Insurance_Providers_Mutation_Response>;
  /** delete single row from the table: "insurance_providers" */
  delete_insurance_providers_by_pk?: Maybe<Insurance_Providers>;
  /** delete data from the table: "insurance_types" */
  delete_insurance_types?: Maybe<Insurance_Types_Mutation_Response>;
  /** delete single row from the table: "insurance_types" */
  delete_insurance_types_by_pk?: Maybe<Insurance_Types>;
  /** delete data from the table: "kareo_logs" */
  delete_kareo_logs?: Maybe<Kareo_Logs_Mutation_Response>;
  /** delete single row from the table: "kareo_logs" */
  delete_kareo_logs_by_pk?: Maybe<Kareo_Logs>;
  /** delete data from the table: "locations" */
  delete_locations?: Maybe<Locations_Mutation_Response>;
  /** delete single row from the table: "locations" */
  delete_locations_by_pk?: Maybe<Locations>;
  /** delete data from the table: "medical_history" */
  delete_medical_history?: Maybe<Medical_History_Mutation_Response>;
  /** delete data from the table: "medical_history_answers" */
  delete_medical_history_answers?: Maybe<Medical_History_Answers_Mutation_Response>;
  /** delete single row from the table: "medical_history_answers" */
  delete_medical_history_answers_by_pk?: Maybe<Medical_History_Answers>;
  /** delete single row from the table: "medical_history" */
  delete_medical_history_by_pk?: Maybe<Medical_History>;
  /** delete data from the table: "medical_history_person_types" */
  delete_medical_history_person_types?: Maybe<Medical_History_Person_Types_Mutation_Response>;
  /** delete single row from the table: "medical_history_person_types" */
  delete_medical_history_person_types_by_pk?: Maybe<Medical_History_Person_Types>;
  /** delete data from the table: "medical_history_questions" */
  delete_medical_history_questions?: Maybe<Medical_History_Questions_Mutation_Response>;
  /** delete single row from the table: "medical_history_questions" */
  delete_medical_history_questions_by_pk?: Maybe<Medical_History_Questions>;
  /** delete data from the table: "medical_history_types" */
  delete_medical_history_types?: Maybe<Medical_History_Types_Mutation_Response>;
  /** delete single row from the table: "medical_history_types" */
  delete_medical_history_types_by_pk?: Maybe<Medical_History_Types>;
  /** delete data from the table: "patient_migrations" */
  delete_patient_migrations?: Maybe<Patient_Migrations_Mutation_Response>;
  /** delete single row from the table: "patient_migrations" */
  delete_patient_migrations_by_pk?: Maybe<Patient_Migrations>;
  /** delete data from the table: "patient_not_duplicates" */
  delete_patient_not_duplicates?: Maybe<Patient_Not_Duplicates_Mutation_Response>;
  /** delete single row from the table: "patient_not_duplicates" */
  delete_patient_not_duplicates_by_pk?: Maybe<Patient_Not_Duplicates>;
  /** delete data from the table: "patient_record_status_types" */
  delete_patient_record_status_types?: Maybe<Patient_Record_Status_Types_Mutation_Response>;
  /** delete single row from the table: "patient_record_status_types" */
  delete_patient_record_status_types_by_pk?: Maybe<Patient_Record_Status_Types>;
  /** delete data from the table: "patients" */
  delete_patients?: Maybe<Patients_Mutation_Response>;
  /** delete single row from the table: "patients" */
  delete_patients_by_pk?: Maybe<Patients>;
  /** delete data from the table: "payment_type" */
  delete_payment_type?: Maybe<Payment_Type_Mutation_Response>;
  /** delete single row from the table: "payment_type" */
  delete_payment_type_by_pk?: Maybe<Payment_Type>;
  /** delete data from the table: "phone_numbers" */
  delete_phone_numbers?: Maybe<Phone_Numbers_Mutation_Response>;
  /** delete single row from the table: "phone_numbers" */
  delete_phone_numbers_by_pk?: Maybe<Phone_Numbers>;
  /** delete data from the table: "problem_status_types" */
  delete_problem_status_types?: Maybe<Problem_Status_Types_Mutation_Response>;
  /** delete single row from the table: "problem_status_types" */
  delete_problem_status_types_by_pk?: Maybe<Problem_Status_Types>;
  /** delete data from the table: "problems" */
  delete_problems?: Maybe<Problems_Mutation_Response>;
  /** delete single row from the table: "problems" */
  delete_problems_by_pk?: Maybe<Problems>;
  /** delete data from the table: "products" */
  delete_products?: Maybe<Products_Mutation_Response>;
  /** delete single row from the table: "products" */
  delete_products_by_pk?: Maybe<Products>;
  /** delete data from the table: "reconciliation_type" */
  delete_reconciliation_type?: Maybe<Reconciliation_Type_Mutation_Response>;
  /** delete single row from the table: "reconciliation_type" */
  delete_reconciliation_type_by_pk?: Maybe<Reconciliation_Type>;
  /** delete data from the table: "reconciliations" */
  delete_reconciliations?: Maybe<Reconciliations_Mutation_Response>;
  /** delete single row from the table: "reconciliations" */
  delete_reconciliations_by_pk?: Maybe<Reconciliations>;
  /** delete data from the table: "regions" */
  delete_regions?: Maybe<Regions_Mutation_Response>;
  /** delete single row from the table: "regions" */
  delete_regions_by_pk?: Maybe<Regions>;
  /** delete data from the table: "reservations" */
  delete_reservations?: Maybe<Reservations_Mutation_Response>;
  /** delete data from the table: "reviews" */
  delete_reviews?: Maybe<Reviews_Mutation_Response>;
  /** delete single row from the table: "reviews" */
  delete_reviews_by_pk?: Maybe<Reviews>;
  /** delete data from the table: "rooms" */
  delete_rooms?: Maybe<Rooms_Mutation_Response>;
  /** delete single row from the table: "rooms" */
  delete_rooms_by_pk?: Maybe<Rooms>;
  /** delete data from the table: "screening_category_types" */
  delete_screening_category_types?: Maybe<Screening_Category_Types_Mutation_Response>;
  /** delete single row from the table: "screening_category_types" */
  delete_screening_category_types_by_pk?: Maybe<Screening_Category_Types>;
  /** delete data from the table: "screening_types" */
  delete_screening_types?: Maybe<Screening_Types_Mutation_Response>;
  /** delete single row from the table: "screening_types" */
  delete_screening_types_by_pk?: Maybe<Screening_Types>;
  /** delete data from the table: "screenings" */
  delete_screenings?: Maybe<Screenings_Mutation_Response>;
  /** delete single row from the table: "screenings" */
  delete_screenings_by_pk?: Maybe<Screenings>;
  /** delete data from the table: "services" */
  delete_services?: Maybe<Services_Mutation_Response>;
  /** delete single row from the table: "services" */
  delete_services_by_pk?: Maybe<Services>;
  /** delete data from the table: "snomed_synonyms" */
  delete_snomed_synonyms?: Maybe<Snomed_Synonyms_Mutation_Response>;
  /** delete single row from the table: "snomed_synonyms" */
  delete_snomed_synonyms_by_pk?: Maybe<Snomed_Synonyms>;
  /** delete data from the table: "staff" */
  delete_staff?: Maybe<Staff_Mutation_Response>;
  /** delete single row from the table: "staff" */
  delete_staff_by_pk?: Maybe<Staff>;
  /** delete data from the table: "staff_regions" */
  delete_staff_regions?: Maybe<Staff_Regions_Mutation_Response>;
  /** delete single row from the table: "staff_regions" */
  delete_staff_regions_by_pk?: Maybe<Staff_Regions>;
  /** delete data from the table: "staff_schedule" */
  delete_staff_schedule?: Maybe<Staff_Schedule_Mutation_Response>;
  /** delete single row from the table: "staff_schedule" */
  delete_staff_schedule_by_pk?: Maybe<Staff_Schedule>;
  /** delete data from the table: "staff_schedule_service" */
  delete_staff_schedule_service?: Maybe<Staff_Schedule_Service_Mutation_Response>;
  /** delete single row from the table: "staff_schedule_service" */
  delete_staff_schedule_service_by_pk?: Maybe<Staff_Schedule_Service>;
  /** delete data from the table: "staff_services" */
  delete_staff_services?: Maybe<Staff_Services_Mutation_Response>;
  /** delete single row from the table: "staff_services" */
  delete_staff_services_by_pk?: Maybe<Staff_Services>;
  /** delete data from the table: "staff_type" */
  delete_staff_type?: Maybe<Staff_Type_Mutation_Response>;
  /** delete single row from the table: "staff_type" */
  delete_staff_type_by_pk?: Maybe<Staff_Type>;
  /** delete data from the table: "symptom_checker" */
  delete_symptom_checker?: Maybe<Symptom_Checker_Mutation_Response>;
  /** delete single row from the table: "symptom_checker" */
  delete_symptom_checker_by_pk?: Maybe<Symptom_Checker>;
  /** delete data from the table: "task_priority" */
  delete_task_priority?: Maybe<Task_Priority_Mutation_Response>;
  /** delete single row from the table: "task_priority" */
  delete_task_priority_by_pk?: Maybe<Task_Priority>;
  /** delete data from the table: "task_status" */
  delete_task_status?: Maybe<Task_Status_Mutation_Response>;
  /** delete single row from the table: "task_status" */
  delete_task_status_by_pk?: Maybe<Task_Status>;
  /** delete data from the table: "task_sub_type" */
  delete_task_sub_type?: Maybe<Task_Sub_Type_Mutation_Response>;
  /** delete single row from the table: "task_sub_type" */
  delete_task_sub_type_by_pk?: Maybe<Task_Sub_Type>;
  /** delete data from the table: "task_type" */
  delete_task_type?: Maybe<Task_Type_Mutation_Response>;
  /** delete single row from the table: "task_type" */
  delete_task_type_by_pk?: Maybe<Task_Type>;
  /** delete data from the table: "tasks" */
  delete_tasks?: Maybe<Tasks_Mutation_Response>;
  /** delete data from the table: "tasks_assignments" */
  delete_tasks_assignments?: Maybe<Tasks_Assignments_Mutation_Response>;
  /** delete single row from the table: "tasks_assignments" */
  delete_tasks_assignments_by_pk?: Maybe<Tasks_Assignments>;
  /** delete single row from the table: "tasks" */
  delete_tasks_by_pk?: Maybe<Tasks>;
  /** delete data from the table: "tasks_files" */
  delete_tasks_files?: Maybe<Tasks_Files_Mutation_Response>;
  /** delete single row from the table: "tasks_files" */
  delete_tasks_files_by_pk?: Maybe<Tasks_Files>;
  /** delete data from the table: "tasks_groups" */
  delete_tasks_groups?: Maybe<Tasks_Groups_Mutation_Response>;
  /** delete single row from the table: "tasks_groups" */
  delete_tasks_groups_by_pk?: Maybe<Tasks_Groups>;
  /** delete data from the table: "tasks_groups_staff" */
  delete_tasks_groups_staff?: Maybe<Tasks_Groups_Staff_Mutation_Response>;
  /** delete single row from the table: "tasks_groups_staff" */
  delete_tasks_groups_staff_by_pk?: Maybe<Tasks_Groups_Staff>;
  /** delete data from the table: "tasks_notifications" */
  delete_tasks_notifications?: Maybe<Tasks_Notifications_Mutation_Response>;
  /** delete single row from the table: "tasks_notifications" */
  delete_tasks_notifications_by_pk?: Maybe<Tasks_Notifications>;
  /** delete data from the table: "tasks_read" */
  delete_tasks_read?: Maybe<Tasks_Read_Mutation_Response>;
  /** delete single row from the table: "tasks_read" */
  delete_tasks_read_by_pk?: Maybe<Tasks_Read>;
  /** delete data from the table: "tasks_watch" */
  delete_tasks_watch?: Maybe<Tasks_Watch_Mutation_Response>;
  /** delete single row from the table: "tasks_watch" */
  delete_tasks_watch_by_pk?: Maybe<Tasks_Watch>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "visit_classifications" */
  delete_visit_classifications?: Maybe<Visit_Classifications_Mutation_Response>;
  /** delete single row from the table: "visit_classifications" */
  delete_visit_classifications_by_pk?: Maybe<Visit_Classifications>;
  /** delete data from the table: "visit_types" */
  delete_visit_types?: Maybe<Visit_Types_Mutation_Response>;
  /** delete single row from the table: "visit_types" */
  delete_visit_types_by_pk?: Maybe<Visit_Types>;
  /** insert data into the table: "account_patient" */
  insert_account_patient?: Maybe<Account_Patient_Mutation_Response>;
  /** insert a single row into the table: "account_patient" */
  insert_account_patient_one?: Maybe<Account_Patient>;
  /** insert data into the table: "account_temporary_verification" */
  insert_account_temporary_verification?: Maybe<Account_Temporary_Verification_Mutation_Response>;
  /** insert a single row into the table: "account_temporary_verification" */
  insert_account_temporary_verification_one?: Maybe<Account_Temporary_Verification>;
  /** insert data into the table: "accounts" */
  insert_accounts?: Maybe<Accounts_Mutation_Response>;
  /** insert data into the table: "accounts_limited" */
  insert_accounts_limited?: Maybe<Accounts_Limited_Mutation_Response>;
  /** insert a single row into the table: "accounts_limited" */
  insert_accounts_limited_one?: Maybe<Accounts_Limited>;
  /** insert a single row into the table: "accounts" */
  insert_accounts_one?: Maybe<Accounts>;
  /** insert data into the table: "allergies" */
  insert_allergies?: Maybe<Allergies_Mutation_Response>;
  /** insert a single row into the table: "allergies" */
  insert_allergies_one?: Maybe<Allergies>;
  /** insert data into the table: "allergies_severity" */
  insert_allergies_severity?: Maybe<Allergies_Severity_Mutation_Response>;
  /** insert a single row into the table: "allergies_severity" */
  insert_allergies_severity_one?: Maybe<Allergies_Severity>;
  /** insert data into the table: "allergies_status" */
  insert_allergies_status?: Maybe<Allergies_Status_Mutation_Response>;
  /** insert a single row into the table: "allergies_status" */
  insert_allergies_status_one?: Maybe<Allergies_Status>;
  /** insert data into the table: "appointment_blocks" */
  insert_appointment_blocks?: Maybe<Appointment_Blocks_Mutation_Response>;
  /** insert a single row into the table: "appointment_blocks" */
  insert_appointment_blocks_one?: Maybe<Appointment_Blocks>;
  /** insert data into the table: "appointment_blocks_staff" */
  insert_appointment_blocks_staff?: Maybe<Appointment_Blocks_Staff_Mutation_Response>;
  /** insert a single row into the table: "appointment_blocks_staff" */
  insert_appointment_blocks_staff_one?: Maybe<Appointment_Blocks_Staff>;
  /** insert data into the table: "appointment_patient" */
  insert_appointment_patient?: Maybe<Appointment_Patient_Mutation_Response>;
  /** insert a single row into the table: "appointment_patient" */
  insert_appointment_patient_one?: Maybe<Appointment_Patient>;
  /** insert data into the table: "appointment_payment" */
  insert_appointment_payment?: Maybe<Appointment_Payment_Mutation_Response>;
  /** insert a single row into the table: "appointment_payment" */
  insert_appointment_payment_one?: Maybe<Appointment_Payment>;
  /** insert data into the table: "appointment_reservations" */
  insert_appointment_reservations?: Maybe<Appointment_Reservations_Mutation_Response>;
  /** insert a single row into the table: "appointment_reservations" */
  insert_appointment_reservations_one?: Maybe<Appointment_Reservations>;
  /** insert data into the table: "appointment_staff" */
  insert_appointment_staff?: Maybe<Appointment_Staff_Mutation_Response>;
  /** insert a single row into the table: "appointment_staff" */
  insert_appointment_staff_one?: Maybe<Appointment_Staff>;
  /** insert data into the table: "appointment_status" */
  insert_appointment_status?: Maybe<Appointment_Status_Mutation_Response>;
  /** insert a single row into the table: "appointment_status" */
  insert_appointment_status_one?: Maybe<Appointment_Status>;
  /** insert data into the table: "appointment_types" */
  insert_appointment_types?: Maybe<Appointment_Types_Mutation_Response>;
  /** insert a single row into the table: "appointment_types" */
  insert_appointment_types_one?: Maybe<Appointment_Types>;
  /** insert data into the table: "appointments" */
  insert_appointments?: Maybe<Appointments_Mutation_Response>;
  /** insert a single row into the table: "appointments" */
  insert_appointments_one?: Maybe<Appointments>;
  /** insert data into the table: "audit_logs" */
  insert_audit_logs?: Maybe<Audit_Logs_Mutation_Response>;
  /** insert a single row into the table: "audit_logs" */
  insert_audit_logs_one?: Maybe<Audit_Logs>;
  /** insert data into the table: "care_type_duration" */
  insert_care_type_duration?: Maybe<Care_Type_Duration_Mutation_Response>;
  /** insert a single row into the table: "care_type_duration" */
  insert_care_type_duration_one?: Maybe<Care_Type_Duration>;
  /** insert data into the table: "care_types" */
  insert_care_types?: Maybe<Care_Types_Mutation_Response>;
  /** insert a single row into the table: "care_types" */
  insert_care_types_one?: Maybe<Care_Types>;
  /** insert data into the table: "charge_products" */
  insert_charge_products?: Maybe<Charge_Products_Mutation_Response>;
  /** insert a single row into the table: "charge_products" */
  insert_charge_products_one?: Maybe<Charge_Products>;
  /** insert data into the table: "charges" */
  insert_charges?: Maybe<Charges_Mutation_Response>;
  /** insert a single row into the table: "charges" */
  insert_charges_one?: Maybe<Charges>;
  /** insert data into the table: "chat_messages" */
  insert_chat_messages?: Maybe<Chat_Messages_Mutation_Response>;
  /** insert data into the table: "chat_messages_file" */
  insert_chat_messages_file?: Maybe<Chat_Messages_File_Mutation_Response>;
  /** insert a single row into the table: "chat_messages_file" */
  insert_chat_messages_file_one?: Maybe<Chat_Messages_File>;
  /** insert a single row into the table: "chat_messages" */
  insert_chat_messages_one?: Maybe<Chat_Messages>;
  /** insert data into the table: "chat_messages_type" */
  insert_chat_messages_type?: Maybe<Chat_Messages_Type_Mutation_Response>;
  /** insert a single row into the table: "chat_messages_type" */
  insert_chat_messages_type_one?: Maybe<Chat_Messages_Type>;
  /** insert data into the table: "chat_notes" */
  insert_chat_notes?: Maybe<Chat_Notes_Mutation_Response>;
  /** insert a single row into the table: "chat_notes" */
  insert_chat_notes_one?: Maybe<Chat_Notes>;
  /** insert data into the table: "chat_notes_types" */
  insert_chat_notes_types?: Maybe<Chat_Notes_Types_Mutation_Response>;
  /** insert a single row into the table: "chat_notes_types" */
  insert_chat_notes_types_one?: Maybe<Chat_Notes_Types>;
  /** insert data into the table: "chat_sessions" */
  insert_chat_sessions?: Maybe<Chat_Sessions_Mutation_Response>;
  /** insert data into the table: "chat_sessions_accounts" */
  insert_chat_sessions_accounts?: Maybe<Chat_Sessions_Accounts_Mutation_Response>;
  /** insert a single row into the table: "chat_sessions_accounts" */
  insert_chat_sessions_accounts_one?: Maybe<Chat_Sessions_Accounts>;
  /** insert a single row into the table: "chat_sessions" */
  insert_chat_sessions_one?: Maybe<Chat_Sessions>;
  /** insert data into the table: "chat_sessions_patients" */
  insert_chat_sessions_patients?: Maybe<Chat_Sessions_Patients_Mutation_Response>;
  /** insert a single row into the table: "chat_sessions_patients" */
  insert_chat_sessions_patients_one?: Maybe<Chat_Sessions_Patients>;
  /** insert data into the table: "chat_sessions_read" */
  insert_chat_sessions_read?: Maybe<Chat_Sessions_Read_Mutation_Response>;
  /** insert a single row into the table: "chat_sessions_read" */
  insert_chat_sessions_read_one?: Maybe<Chat_Sessions_Read>;
  /** insert data into the table: "chat_sessions_staff" */
  insert_chat_sessions_staff?: Maybe<Chat_Sessions_Staff_Mutation_Response>;
  /** insert a single row into the table: "chat_sessions_staff" */
  insert_chat_sessions_staff_one?: Maybe<Chat_Sessions_Staff>;
  /** insert data into the table: "chat_sessions_status" */
  insert_chat_sessions_status?: Maybe<Chat_Sessions_Status_Mutation_Response>;
  /** insert a single row into the table: "chat_sessions_status" */
  insert_chat_sessions_status_one?: Maybe<Chat_Sessions_Status>;
  /** insert data into the table: "clinic_location_types" */
  insert_clinic_location_types?: Maybe<Clinic_Location_Types_Mutation_Response>;
  /** insert a single row into the table: "clinic_location_types" */
  insert_clinic_location_types_one?: Maybe<Clinic_Location_Types>;
  /** insert data into the table: "clinic_status" */
  insert_clinic_status?: Maybe<Clinic_Status_Mutation_Response>;
  /** insert a single row into the table: "clinic_status" */
  insert_clinic_status_one?: Maybe<Clinic_Status>;
  /** insert data into the table: "clinics" */
  insert_clinics?: Maybe<Clinics_Mutation_Response>;
  /** insert a single row into the table: "clinics" */
  insert_clinics_one?: Maybe<Clinics>;
  /** insert data into the table: "clinics_properties" */
  insert_clinics_properties?: Maybe<Clinics_Properties_Mutation_Response>;
  /** insert a single row into the table: "clinics_properties" */
  insert_clinics_properties_one?: Maybe<Clinics_Properties>;
  /** insert data into the table: "clinics_properties_types" */
  insert_clinics_properties_types?: Maybe<Clinics_Properties_Types_Mutation_Response>;
  /** insert a single row into the table: "clinics_properties_types" */
  insert_clinics_properties_types_one?: Maybe<Clinics_Properties_Types>;
  /** insert data into the table: "clinics_regions" */
  insert_clinics_regions?: Maybe<Clinics_Regions_Mutation_Response>;
  /** insert a single row into the table: "clinics_regions" */
  insert_clinics_regions_one?: Maybe<Clinics_Regions>;
  /** insert data into the table: "communication_history" */
  insert_communication_history?: Maybe<Communication_History_Mutation_Response>;
  /** insert data into the table: "communication_history_directions" */
  insert_communication_history_directions?: Maybe<Communication_History_Directions_Mutation_Response>;
  /** insert a single row into the table: "communication_history_directions" */
  insert_communication_history_directions_one?: Maybe<Communication_History_Directions>;
  /** insert a single row into the table: "communication_history" */
  insert_communication_history_one?: Maybe<Communication_History>;
  /** insert data into the table: "communication_history_tag_types" */
  insert_communication_history_tag_types?: Maybe<Communication_History_Tag_Types_Mutation_Response>;
  /** insert a single row into the table: "communication_history_tag_types" */
  insert_communication_history_tag_types_one?: Maybe<Communication_History_Tag_Types>;
  /** insert data into the table: "communication_history_tags" */
  insert_communication_history_tags?: Maybe<Communication_History_Tags_Mutation_Response>;
  /** insert a single row into the table: "communication_history_tags" */
  insert_communication_history_tags_one?: Maybe<Communication_History_Tags>;
  /** insert data into the table: "communication_history_types" */
  insert_communication_history_types?: Maybe<Communication_History_Types_Mutation_Response>;
  /** insert a single row into the table: "communication_history_types" */
  insert_communication_history_types_one?: Maybe<Communication_History_Types>;
  /** insert data into the table: "devices" */
  insert_devices?: Maybe<Devices_Mutation_Response>;
  /** insert a single row into the table: "devices" */
  insert_devices_one?: Maybe<Devices>;
  /** insert data into the table: "discovered_from" */
  insert_discovered_from?: Maybe<Discovered_From_Mutation_Response>;
  /** insert a single row into the table: "discovered_from" */
  insert_discovered_from_one?: Maybe<Discovered_From>;
  /** insert data into the table: "document_status_types" */
  insert_document_status_types?: Maybe<Document_Status_Types_Mutation_Response>;
  /** insert a single row into the table: "document_status_types" */
  insert_document_status_types_one?: Maybe<Document_Status_Types>;
  /** insert data into the table: "document_types" */
  insert_document_types?: Maybe<Document_Types_Mutation_Response>;
  /** insert a single row into the table: "document_types" */
  insert_document_types_one?: Maybe<Document_Types>;
  /** insert data into the table: "documents" */
  insert_documents?: Maybe<Documents_Mutation_Response>;
  /** insert a single row into the table: "documents" */
  insert_documents_one?: Maybe<Documents>;
  /** insert data into the table: "fhir_resource_types" */
  insert_fhir_resource_types?: Maybe<Fhir_Resource_Types_Mutation_Response>;
  /** insert a single row into the table: "fhir_resource_types" */
  insert_fhir_resource_types_one?: Maybe<Fhir_Resource_Types>;
  /** insert data into the table: "fhir_resources" */
  insert_fhir_resources?: Maybe<Fhir_Resources_Mutation_Response>;
  /** insert data into the table: "fhir_resources_history" */
  insert_fhir_resources_history?: Maybe<Fhir_Resources_History_Mutation_Response>;
  /** insert a single row into the table: "fhir_resources_history" */
  insert_fhir_resources_history_one?: Maybe<Fhir_Resources_History>;
  /** insert a single row into the table: "fhir_resources" */
  insert_fhir_resources_one?: Maybe<Fhir_Resources>;
  /** insert data into the table: "file_document_type" */
  insert_file_document_type?: Maybe<File_Document_Type_Mutation_Response>;
  /** insert a single row into the table: "file_document_type" */
  insert_file_document_type_one?: Maybe<File_Document_Type>;
  /** insert data into the table: "files" */
  insert_files?: Maybe<Files_Mutation_Response>;
  /** insert a single row into the table: "files" */
  insert_files_one?: Maybe<Files>;
  /** insert data into the table: "global_notes" */
  insert_global_notes?: Maybe<Global_Notes_Mutation_Response>;
  /** insert a single row into the table: "global_notes" */
  insert_global_notes_one?: Maybe<Global_Notes>;
  /** insert data into the table: "insurance" */
  insert_insurance?: Maybe<Insurance_Mutation_Response>;
  /** insert a single row into the table: "insurance" */
  insert_insurance_one?: Maybe<Insurance>;
  /** insert data into the table: "insurance_providers" */
  insert_insurance_providers?: Maybe<Insurance_Providers_Mutation_Response>;
  /** insert a single row into the table: "insurance_providers" */
  insert_insurance_providers_one?: Maybe<Insurance_Providers>;
  /** insert data into the table: "insurance_types" */
  insert_insurance_types?: Maybe<Insurance_Types_Mutation_Response>;
  /** insert a single row into the table: "insurance_types" */
  insert_insurance_types_one?: Maybe<Insurance_Types>;
  /** insert data into the table: "kareo_logs" */
  insert_kareo_logs?: Maybe<Kareo_Logs_Mutation_Response>;
  /** insert a single row into the table: "kareo_logs" */
  insert_kareo_logs_one?: Maybe<Kareo_Logs>;
  /** insert data into the table: "locations" */
  insert_locations?: Maybe<Locations_Mutation_Response>;
  /** insert a single row into the table: "locations" */
  insert_locations_one?: Maybe<Locations>;
  /** insert data into the table: "medical_history" */
  insert_medical_history?: Maybe<Medical_History_Mutation_Response>;
  /** insert data into the table: "medical_history_answers" */
  insert_medical_history_answers?: Maybe<Medical_History_Answers_Mutation_Response>;
  /** insert a single row into the table: "medical_history_answers" */
  insert_medical_history_answers_one?: Maybe<Medical_History_Answers>;
  /** insert a single row into the table: "medical_history" */
  insert_medical_history_one?: Maybe<Medical_History>;
  /** insert data into the table: "medical_history_person_types" */
  insert_medical_history_person_types?: Maybe<Medical_History_Person_Types_Mutation_Response>;
  /** insert a single row into the table: "medical_history_person_types" */
  insert_medical_history_person_types_one?: Maybe<Medical_History_Person_Types>;
  /** insert data into the table: "medical_history_questions" */
  insert_medical_history_questions?: Maybe<Medical_History_Questions_Mutation_Response>;
  /** insert a single row into the table: "medical_history_questions" */
  insert_medical_history_questions_one?: Maybe<Medical_History_Questions>;
  /** insert data into the table: "medical_history_types" */
  insert_medical_history_types?: Maybe<Medical_History_Types_Mutation_Response>;
  /** insert a single row into the table: "medical_history_types" */
  insert_medical_history_types_one?: Maybe<Medical_History_Types>;
  /** insert data into the table: "patient_migrations" */
  insert_patient_migrations?: Maybe<Patient_Migrations_Mutation_Response>;
  /** insert a single row into the table: "patient_migrations" */
  insert_patient_migrations_one?: Maybe<Patient_Migrations>;
  /** insert data into the table: "patient_not_duplicates" */
  insert_patient_not_duplicates?: Maybe<Patient_Not_Duplicates_Mutation_Response>;
  /** insert a single row into the table: "patient_not_duplicates" */
  insert_patient_not_duplicates_one?: Maybe<Patient_Not_Duplicates>;
  /** insert data into the table: "patient_record_status_types" */
  insert_patient_record_status_types?: Maybe<Patient_Record_Status_Types_Mutation_Response>;
  /** insert a single row into the table: "patient_record_status_types" */
  insert_patient_record_status_types_one?: Maybe<Patient_Record_Status_Types>;
  /** insert data into the table: "patients" */
  insert_patients?: Maybe<Patients_Mutation_Response>;
  /** insert a single row into the table: "patients" */
  insert_patients_one?: Maybe<Patients>;
  /** insert data into the table: "payment_type" */
  insert_payment_type?: Maybe<Payment_Type_Mutation_Response>;
  /** insert a single row into the table: "payment_type" */
  insert_payment_type_one?: Maybe<Payment_Type>;
  /** insert data into the table: "phone_numbers" */
  insert_phone_numbers?: Maybe<Phone_Numbers_Mutation_Response>;
  /** insert a single row into the table: "phone_numbers" */
  insert_phone_numbers_one?: Maybe<Phone_Numbers>;
  /** insert data into the table: "problem_status_types" */
  insert_problem_status_types?: Maybe<Problem_Status_Types_Mutation_Response>;
  /** insert a single row into the table: "problem_status_types" */
  insert_problem_status_types_one?: Maybe<Problem_Status_Types>;
  /** insert data into the table: "problems" */
  insert_problems?: Maybe<Problems_Mutation_Response>;
  /** insert a single row into the table: "problems" */
  insert_problems_one?: Maybe<Problems>;
  /** insert data into the table: "products" */
  insert_products?: Maybe<Products_Mutation_Response>;
  /** insert a single row into the table: "products" */
  insert_products_one?: Maybe<Products>;
  /** insert data into the table: "reconciliation_type" */
  insert_reconciliation_type?: Maybe<Reconciliation_Type_Mutation_Response>;
  /** insert a single row into the table: "reconciliation_type" */
  insert_reconciliation_type_one?: Maybe<Reconciliation_Type>;
  /** insert data into the table: "reconciliations" */
  insert_reconciliations?: Maybe<Reconciliations_Mutation_Response>;
  /** insert a single row into the table: "reconciliations" */
  insert_reconciliations_one?: Maybe<Reconciliations>;
  /** insert data into the table: "regions" */
  insert_regions?: Maybe<Regions_Mutation_Response>;
  /** insert a single row into the table: "regions" */
  insert_regions_one?: Maybe<Regions>;
  /** insert data into the table: "reservations" */
  insert_reservations?: Maybe<Reservations_Mutation_Response>;
  /** insert a single row into the table: "reservations" */
  insert_reservations_one?: Maybe<Reservations>;
  /** insert data into the table: "reviews" */
  insert_reviews?: Maybe<Reviews_Mutation_Response>;
  /** insert a single row into the table: "reviews" */
  insert_reviews_one?: Maybe<Reviews>;
  /** insert data into the table: "rooms" */
  insert_rooms?: Maybe<Rooms_Mutation_Response>;
  /** insert a single row into the table: "rooms" */
  insert_rooms_one?: Maybe<Rooms>;
  /** insert data into the table: "screening_category_types" */
  insert_screening_category_types?: Maybe<Screening_Category_Types_Mutation_Response>;
  /** insert a single row into the table: "screening_category_types" */
  insert_screening_category_types_one?: Maybe<Screening_Category_Types>;
  /** insert data into the table: "screening_types" */
  insert_screening_types?: Maybe<Screening_Types_Mutation_Response>;
  /** insert a single row into the table: "screening_types" */
  insert_screening_types_one?: Maybe<Screening_Types>;
  /** insert data into the table: "screenings" */
  insert_screenings?: Maybe<Screenings_Mutation_Response>;
  /** insert a single row into the table: "screenings" */
  insert_screenings_one?: Maybe<Screenings>;
  /** insert data into the table: "services" */
  insert_services?: Maybe<Services_Mutation_Response>;
  /** insert a single row into the table: "services" */
  insert_services_one?: Maybe<Services>;
  /** insert data into the table: "snomed_synonyms" */
  insert_snomed_synonyms?: Maybe<Snomed_Synonyms_Mutation_Response>;
  /** insert a single row into the table: "snomed_synonyms" */
  insert_snomed_synonyms_one?: Maybe<Snomed_Synonyms>;
  /** insert data into the table: "staff" */
  insert_staff?: Maybe<Staff_Mutation_Response>;
  /** insert a single row into the table: "staff" */
  insert_staff_one?: Maybe<Staff>;
  /** insert data into the table: "staff_regions" */
  insert_staff_regions?: Maybe<Staff_Regions_Mutation_Response>;
  /** insert a single row into the table: "staff_regions" */
  insert_staff_regions_one?: Maybe<Staff_Regions>;
  /** insert data into the table: "staff_schedule" */
  insert_staff_schedule?: Maybe<Staff_Schedule_Mutation_Response>;
  /** insert a single row into the table: "staff_schedule" */
  insert_staff_schedule_one?: Maybe<Staff_Schedule>;
  /** insert data into the table: "staff_schedule_service" */
  insert_staff_schedule_service?: Maybe<Staff_Schedule_Service_Mutation_Response>;
  /** insert a single row into the table: "staff_schedule_service" */
  insert_staff_schedule_service_one?: Maybe<Staff_Schedule_Service>;
  /** insert data into the table: "staff_services" */
  insert_staff_services?: Maybe<Staff_Services_Mutation_Response>;
  /** insert a single row into the table: "staff_services" */
  insert_staff_services_one?: Maybe<Staff_Services>;
  /** insert data into the table: "staff_type" */
  insert_staff_type?: Maybe<Staff_Type_Mutation_Response>;
  /** insert a single row into the table: "staff_type" */
  insert_staff_type_one?: Maybe<Staff_Type>;
  /** insert data into the table: "symptom_checker" */
  insert_symptom_checker?: Maybe<Symptom_Checker_Mutation_Response>;
  /** insert a single row into the table: "symptom_checker" */
  insert_symptom_checker_one?: Maybe<Symptom_Checker>;
  /** insert data into the table: "task_priority" */
  insert_task_priority?: Maybe<Task_Priority_Mutation_Response>;
  /** insert a single row into the table: "task_priority" */
  insert_task_priority_one?: Maybe<Task_Priority>;
  /** insert data into the table: "task_status" */
  insert_task_status?: Maybe<Task_Status_Mutation_Response>;
  /** insert a single row into the table: "task_status" */
  insert_task_status_one?: Maybe<Task_Status>;
  /** insert data into the table: "task_sub_type" */
  insert_task_sub_type?: Maybe<Task_Sub_Type_Mutation_Response>;
  /** insert a single row into the table: "task_sub_type" */
  insert_task_sub_type_one?: Maybe<Task_Sub_Type>;
  /** insert data into the table: "task_type" */
  insert_task_type?: Maybe<Task_Type_Mutation_Response>;
  /** insert a single row into the table: "task_type" */
  insert_task_type_one?: Maybe<Task_Type>;
  /** insert data into the table: "tasks" */
  insert_tasks?: Maybe<Tasks_Mutation_Response>;
  /** insert data into the table: "tasks_assignments" */
  insert_tasks_assignments?: Maybe<Tasks_Assignments_Mutation_Response>;
  /** insert a single row into the table: "tasks_assignments" */
  insert_tasks_assignments_one?: Maybe<Tasks_Assignments>;
  /** insert data into the table: "tasks_files" */
  insert_tasks_files?: Maybe<Tasks_Files_Mutation_Response>;
  /** insert a single row into the table: "tasks_files" */
  insert_tasks_files_one?: Maybe<Tasks_Files>;
  /** insert data into the table: "tasks_groups" */
  insert_tasks_groups?: Maybe<Tasks_Groups_Mutation_Response>;
  /** insert a single row into the table: "tasks_groups" */
  insert_tasks_groups_one?: Maybe<Tasks_Groups>;
  /** insert data into the table: "tasks_groups_staff" */
  insert_tasks_groups_staff?: Maybe<Tasks_Groups_Staff_Mutation_Response>;
  /** insert a single row into the table: "tasks_groups_staff" */
  insert_tasks_groups_staff_one?: Maybe<Tasks_Groups_Staff>;
  /** insert data into the table: "tasks_notifications" */
  insert_tasks_notifications?: Maybe<Tasks_Notifications_Mutation_Response>;
  /** insert a single row into the table: "tasks_notifications" */
  insert_tasks_notifications_one?: Maybe<Tasks_Notifications>;
  /** insert a single row into the table: "tasks" */
  insert_tasks_one?: Maybe<Tasks>;
  /** insert data into the table: "tasks_read" */
  insert_tasks_read?: Maybe<Tasks_Read_Mutation_Response>;
  /** insert a single row into the table: "tasks_read" */
  insert_tasks_read_one?: Maybe<Tasks_Read>;
  /** insert data into the table: "tasks_watch" */
  insert_tasks_watch?: Maybe<Tasks_Watch_Mutation_Response>;
  /** insert a single row into the table: "tasks_watch" */
  insert_tasks_watch_one?: Maybe<Tasks_Watch>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "visit_classifications" */
  insert_visit_classifications?: Maybe<Visit_Classifications_Mutation_Response>;
  /** insert a single row into the table: "visit_classifications" */
  insert_visit_classifications_one?: Maybe<Visit_Classifications>;
  /** insert data into the table: "visit_types" */
  insert_visit_types?: Maybe<Visit_Types_Mutation_Response>;
  /** insert a single row into the table: "visit_types" */
  insert_visit_types_one?: Maybe<Visit_Types>;
  /** update data of the table: "account_patient" */
  update_account_patient?: Maybe<Account_Patient_Mutation_Response>;
  /** update single row of the table: "account_patient" */
  update_account_patient_by_pk?: Maybe<Account_Patient>;
  /** update multiples rows of table: "account_patient" */
  update_account_patient_many?: Maybe<
    Array<Maybe<Account_Patient_Mutation_Response>>
  >;
  /** update data of the table: "account_temporary_verification" */
  update_account_temporary_verification?: Maybe<Account_Temporary_Verification_Mutation_Response>;
  /** update single row of the table: "account_temporary_verification" */
  update_account_temporary_verification_by_pk?: Maybe<Account_Temporary_Verification>;
  /** update multiples rows of table: "account_temporary_verification" */
  update_account_temporary_verification_many?: Maybe<
    Array<Maybe<Account_Temporary_Verification_Mutation_Response>>
  >;
  /** update data of the table: "accounts" */
  update_accounts?: Maybe<Accounts_Mutation_Response>;
  /** update single row of the table: "accounts" */
  update_accounts_by_pk?: Maybe<Accounts>;
  /** update data of the table: "accounts_limited" */
  update_accounts_limited?: Maybe<Accounts_Limited_Mutation_Response>;
  /** update multiples rows of table: "accounts_limited" */
  update_accounts_limited_many?: Maybe<
    Array<Maybe<Accounts_Limited_Mutation_Response>>
  >;
  /** update multiples rows of table: "accounts" */
  update_accounts_many?: Maybe<Array<Maybe<Accounts_Mutation_Response>>>;
  /** update data of the table: "allergies" */
  update_allergies?: Maybe<Allergies_Mutation_Response>;
  /** update single row of the table: "allergies" */
  update_allergies_by_pk?: Maybe<Allergies>;
  /** update multiples rows of table: "allergies" */
  update_allergies_many?: Maybe<Array<Maybe<Allergies_Mutation_Response>>>;
  /** update data of the table: "allergies_severity" */
  update_allergies_severity?: Maybe<Allergies_Severity_Mutation_Response>;
  /** update single row of the table: "allergies_severity" */
  update_allergies_severity_by_pk?: Maybe<Allergies_Severity>;
  /** update multiples rows of table: "allergies_severity" */
  update_allergies_severity_many?: Maybe<
    Array<Maybe<Allergies_Severity_Mutation_Response>>
  >;
  /** update data of the table: "allergies_status" */
  update_allergies_status?: Maybe<Allergies_Status_Mutation_Response>;
  /** update single row of the table: "allergies_status" */
  update_allergies_status_by_pk?: Maybe<Allergies_Status>;
  /** update multiples rows of table: "allergies_status" */
  update_allergies_status_many?: Maybe<
    Array<Maybe<Allergies_Status_Mutation_Response>>
  >;
  /** update data of the table: "appointment_blocks" */
  update_appointment_blocks?: Maybe<Appointment_Blocks_Mutation_Response>;
  /** update single row of the table: "appointment_blocks" */
  update_appointment_blocks_by_pk?: Maybe<Appointment_Blocks>;
  /** update multiples rows of table: "appointment_blocks" */
  update_appointment_blocks_many?: Maybe<
    Array<Maybe<Appointment_Blocks_Mutation_Response>>
  >;
  /** update data of the table: "appointment_blocks_staff" */
  update_appointment_blocks_staff?: Maybe<Appointment_Blocks_Staff_Mutation_Response>;
  /** update single row of the table: "appointment_blocks_staff" */
  update_appointment_blocks_staff_by_pk?: Maybe<Appointment_Blocks_Staff>;
  /** update multiples rows of table: "appointment_blocks_staff" */
  update_appointment_blocks_staff_many?: Maybe<
    Array<Maybe<Appointment_Blocks_Staff_Mutation_Response>>
  >;
  /** update data of the table: "appointment_patient" */
  update_appointment_patient?: Maybe<Appointment_Patient_Mutation_Response>;
  /** update single row of the table: "appointment_patient" */
  update_appointment_patient_by_pk?: Maybe<Appointment_Patient>;
  /** update multiples rows of table: "appointment_patient" */
  update_appointment_patient_many?: Maybe<
    Array<Maybe<Appointment_Patient_Mutation_Response>>
  >;
  /** update data of the table: "appointment_payment" */
  update_appointment_payment?: Maybe<Appointment_Payment_Mutation_Response>;
  /** update single row of the table: "appointment_payment" */
  update_appointment_payment_by_pk?: Maybe<Appointment_Payment>;
  /** update multiples rows of table: "appointment_payment" */
  update_appointment_payment_many?: Maybe<
    Array<Maybe<Appointment_Payment_Mutation_Response>>
  >;
  /** update data of the table: "appointment_reservations" */
  update_appointment_reservations?: Maybe<Appointment_Reservations_Mutation_Response>;
  /** update single row of the table: "appointment_reservations" */
  update_appointment_reservations_by_pk?: Maybe<Appointment_Reservations>;
  /** update multiples rows of table: "appointment_reservations" */
  update_appointment_reservations_many?: Maybe<
    Array<Maybe<Appointment_Reservations_Mutation_Response>>
  >;
  /** update data of the table: "appointment_staff" */
  update_appointment_staff?: Maybe<Appointment_Staff_Mutation_Response>;
  /** update single row of the table: "appointment_staff" */
  update_appointment_staff_by_pk?: Maybe<Appointment_Staff>;
  /** update multiples rows of table: "appointment_staff" */
  update_appointment_staff_many?: Maybe<
    Array<Maybe<Appointment_Staff_Mutation_Response>>
  >;
  /** update data of the table: "appointment_status" */
  update_appointment_status?: Maybe<Appointment_Status_Mutation_Response>;
  /** update single row of the table: "appointment_status" */
  update_appointment_status_by_pk?: Maybe<Appointment_Status>;
  /** update multiples rows of table: "appointment_status" */
  update_appointment_status_many?: Maybe<
    Array<Maybe<Appointment_Status_Mutation_Response>>
  >;
  /** update data of the table: "appointment_types" */
  update_appointment_types?: Maybe<Appointment_Types_Mutation_Response>;
  /** update single row of the table: "appointment_types" */
  update_appointment_types_by_pk?: Maybe<Appointment_Types>;
  /** update multiples rows of table: "appointment_types" */
  update_appointment_types_many?: Maybe<
    Array<Maybe<Appointment_Types_Mutation_Response>>
  >;
  /** update data of the table: "appointments" */
  update_appointments?: Maybe<Appointments_Mutation_Response>;
  /** update single row of the table: "appointments" */
  update_appointments_by_pk?: Maybe<Appointments>;
  /** update multiples rows of table: "appointments" */
  update_appointments_many?: Maybe<
    Array<Maybe<Appointments_Mutation_Response>>
  >;
  /** update data of the table: "audit_logs" */
  update_audit_logs?: Maybe<Audit_Logs_Mutation_Response>;
  /** update single row of the table: "audit_logs" */
  update_audit_logs_by_pk?: Maybe<Audit_Logs>;
  /** update multiples rows of table: "audit_logs" */
  update_audit_logs_many?: Maybe<Array<Maybe<Audit_Logs_Mutation_Response>>>;
  /** update data of the table: "care_type_duration" */
  update_care_type_duration?: Maybe<Care_Type_Duration_Mutation_Response>;
  /** update single row of the table: "care_type_duration" */
  update_care_type_duration_by_pk?: Maybe<Care_Type_Duration>;
  /** update multiples rows of table: "care_type_duration" */
  update_care_type_duration_many?: Maybe<
    Array<Maybe<Care_Type_Duration_Mutation_Response>>
  >;
  /** update data of the table: "care_types" */
  update_care_types?: Maybe<Care_Types_Mutation_Response>;
  /** update single row of the table: "care_types" */
  update_care_types_by_pk?: Maybe<Care_Types>;
  /** update multiples rows of table: "care_types" */
  update_care_types_many?: Maybe<Array<Maybe<Care_Types_Mutation_Response>>>;
  /** update data of the table: "charge_products" */
  update_charge_products?: Maybe<Charge_Products_Mutation_Response>;
  /** update single row of the table: "charge_products" */
  update_charge_products_by_pk?: Maybe<Charge_Products>;
  /** update multiples rows of table: "charge_products" */
  update_charge_products_many?: Maybe<
    Array<Maybe<Charge_Products_Mutation_Response>>
  >;
  /** update data of the table: "charges" */
  update_charges?: Maybe<Charges_Mutation_Response>;
  /** update single row of the table: "charges" */
  update_charges_by_pk?: Maybe<Charges>;
  /** update multiples rows of table: "charges" */
  update_charges_many?: Maybe<Array<Maybe<Charges_Mutation_Response>>>;
  /** update data of the table: "chat_messages" */
  update_chat_messages?: Maybe<Chat_Messages_Mutation_Response>;
  /** update single row of the table: "chat_messages" */
  update_chat_messages_by_pk?: Maybe<Chat_Messages>;
  /** update data of the table: "chat_messages_file" */
  update_chat_messages_file?: Maybe<Chat_Messages_File_Mutation_Response>;
  /** update single row of the table: "chat_messages_file" */
  update_chat_messages_file_by_pk?: Maybe<Chat_Messages_File>;
  /** update multiples rows of table: "chat_messages_file" */
  update_chat_messages_file_many?: Maybe<
    Array<Maybe<Chat_Messages_File_Mutation_Response>>
  >;
  /** update multiples rows of table: "chat_messages" */
  update_chat_messages_many?: Maybe<
    Array<Maybe<Chat_Messages_Mutation_Response>>
  >;
  /** update data of the table: "chat_messages_type" */
  update_chat_messages_type?: Maybe<Chat_Messages_Type_Mutation_Response>;
  /** update single row of the table: "chat_messages_type" */
  update_chat_messages_type_by_pk?: Maybe<Chat_Messages_Type>;
  /** update multiples rows of table: "chat_messages_type" */
  update_chat_messages_type_many?: Maybe<
    Array<Maybe<Chat_Messages_Type_Mutation_Response>>
  >;
  /** update data of the table: "chat_notes" */
  update_chat_notes?: Maybe<Chat_Notes_Mutation_Response>;
  /** update single row of the table: "chat_notes" */
  update_chat_notes_by_pk?: Maybe<Chat_Notes>;
  /** update multiples rows of table: "chat_notes" */
  update_chat_notes_many?: Maybe<Array<Maybe<Chat_Notes_Mutation_Response>>>;
  /** update data of the table: "chat_notes_types" */
  update_chat_notes_types?: Maybe<Chat_Notes_Types_Mutation_Response>;
  /** update single row of the table: "chat_notes_types" */
  update_chat_notes_types_by_pk?: Maybe<Chat_Notes_Types>;
  /** update multiples rows of table: "chat_notes_types" */
  update_chat_notes_types_many?: Maybe<
    Array<Maybe<Chat_Notes_Types_Mutation_Response>>
  >;
  /** update data of the table: "chat_sessions" */
  update_chat_sessions?: Maybe<Chat_Sessions_Mutation_Response>;
  /** update data of the table: "chat_sessions_accounts" */
  update_chat_sessions_accounts?: Maybe<Chat_Sessions_Accounts_Mutation_Response>;
  /** update single row of the table: "chat_sessions_accounts" */
  update_chat_sessions_accounts_by_pk?: Maybe<Chat_Sessions_Accounts>;
  /** update multiples rows of table: "chat_sessions_accounts" */
  update_chat_sessions_accounts_many?: Maybe<
    Array<Maybe<Chat_Sessions_Accounts_Mutation_Response>>
  >;
  /** update single row of the table: "chat_sessions" */
  update_chat_sessions_by_pk?: Maybe<Chat_Sessions>;
  /** update multiples rows of table: "chat_sessions" */
  update_chat_sessions_many?: Maybe<
    Array<Maybe<Chat_Sessions_Mutation_Response>>
  >;
  /** update data of the table: "chat_sessions_patients" */
  update_chat_sessions_patients?: Maybe<Chat_Sessions_Patients_Mutation_Response>;
  /** update single row of the table: "chat_sessions_patients" */
  update_chat_sessions_patients_by_pk?: Maybe<Chat_Sessions_Patients>;
  /** update multiples rows of table: "chat_sessions_patients" */
  update_chat_sessions_patients_many?: Maybe<
    Array<Maybe<Chat_Sessions_Patients_Mutation_Response>>
  >;
  /** update data of the table: "chat_sessions_read" */
  update_chat_sessions_read?: Maybe<Chat_Sessions_Read_Mutation_Response>;
  /** update single row of the table: "chat_sessions_read" */
  update_chat_sessions_read_by_pk?: Maybe<Chat_Sessions_Read>;
  /** update multiples rows of table: "chat_sessions_read" */
  update_chat_sessions_read_many?: Maybe<
    Array<Maybe<Chat_Sessions_Read_Mutation_Response>>
  >;
  /** update data of the table: "chat_sessions_staff" */
  update_chat_sessions_staff?: Maybe<Chat_Sessions_Staff_Mutation_Response>;
  /** update single row of the table: "chat_sessions_staff" */
  update_chat_sessions_staff_by_pk?: Maybe<Chat_Sessions_Staff>;
  /** update multiples rows of table: "chat_sessions_staff" */
  update_chat_sessions_staff_many?: Maybe<
    Array<Maybe<Chat_Sessions_Staff_Mutation_Response>>
  >;
  /** update data of the table: "chat_sessions_status" */
  update_chat_sessions_status?: Maybe<Chat_Sessions_Status_Mutation_Response>;
  /** update single row of the table: "chat_sessions_status" */
  update_chat_sessions_status_by_pk?: Maybe<Chat_Sessions_Status>;
  /** update multiples rows of table: "chat_sessions_status" */
  update_chat_sessions_status_many?: Maybe<
    Array<Maybe<Chat_Sessions_Status_Mutation_Response>>
  >;
  /** update data of the table: "clinic_location_types" */
  update_clinic_location_types?: Maybe<Clinic_Location_Types_Mutation_Response>;
  /** update single row of the table: "clinic_location_types" */
  update_clinic_location_types_by_pk?: Maybe<Clinic_Location_Types>;
  /** update multiples rows of table: "clinic_location_types" */
  update_clinic_location_types_many?: Maybe<
    Array<Maybe<Clinic_Location_Types_Mutation_Response>>
  >;
  /** update data of the table: "clinic_status" */
  update_clinic_status?: Maybe<Clinic_Status_Mutation_Response>;
  /** update single row of the table: "clinic_status" */
  update_clinic_status_by_pk?: Maybe<Clinic_Status>;
  /** update multiples rows of table: "clinic_status" */
  update_clinic_status_many?: Maybe<
    Array<Maybe<Clinic_Status_Mutation_Response>>
  >;
  /** update data of the table: "clinics" */
  update_clinics?: Maybe<Clinics_Mutation_Response>;
  /** update single row of the table: "clinics" */
  update_clinics_by_pk?: Maybe<Clinics>;
  /** update multiples rows of table: "clinics" */
  update_clinics_many?: Maybe<Array<Maybe<Clinics_Mutation_Response>>>;
  /** update data of the table: "clinics_properties" */
  update_clinics_properties?: Maybe<Clinics_Properties_Mutation_Response>;
  /** update single row of the table: "clinics_properties" */
  update_clinics_properties_by_pk?: Maybe<Clinics_Properties>;
  /** update multiples rows of table: "clinics_properties" */
  update_clinics_properties_many?: Maybe<
    Array<Maybe<Clinics_Properties_Mutation_Response>>
  >;
  /** update data of the table: "clinics_properties_types" */
  update_clinics_properties_types?: Maybe<Clinics_Properties_Types_Mutation_Response>;
  /** update single row of the table: "clinics_properties_types" */
  update_clinics_properties_types_by_pk?: Maybe<Clinics_Properties_Types>;
  /** update multiples rows of table: "clinics_properties_types" */
  update_clinics_properties_types_many?: Maybe<
    Array<Maybe<Clinics_Properties_Types_Mutation_Response>>
  >;
  /** update data of the table: "clinics_regions" */
  update_clinics_regions?: Maybe<Clinics_Regions_Mutation_Response>;
  /** update single row of the table: "clinics_regions" */
  update_clinics_regions_by_pk?: Maybe<Clinics_Regions>;
  /** update multiples rows of table: "clinics_regions" */
  update_clinics_regions_many?: Maybe<
    Array<Maybe<Clinics_Regions_Mutation_Response>>
  >;
  /** update data of the table: "communication_history" */
  update_communication_history?: Maybe<Communication_History_Mutation_Response>;
  /** update single row of the table: "communication_history" */
  update_communication_history_by_pk?: Maybe<Communication_History>;
  /** update data of the table: "communication_history_directions" */
  update_communication_history_directions?: Maybe<Communication_History_Directions_Mutation_Response>;
  /** update single row of the table: "communication_history_directions" */
  update_communication_history_directions_by_pk?: Maybe<Communication_History_Directions>;
  /** update multiples rows of table: "communication_history_directions" */
  update_communication_history_directions_many?: Maybe<
    Array<Maybe<Communication_History_Directions_Mutation_Response>>
  >;
  /** update multiples rows of table: "communication_history" */
  update_communication_history_many?: Maybe<
    Array<Maybe<Communication_History_Mutation_Response>>
  >;
  /** update data of the table: "communication_history_tag_types" */
  update_communication_history_tag_types?: Maybe<Communication_History_Tag_Types_Mutation_Response>;
  /** update single row of the table: "communication_history_tag_types" */
  update_communication_history_tag_types_by_pk?: Maybe<Communication_History_Tag_Types>;
  /** update multiples rows of table: "communication_history_tag_types" */
  update_communication_history_tag_types_many?: Maybe<
    Array<Maybe<Communication_History_Tag_Types_Mutation_Response>>
  >;
  /** update data of the table: "communication_history_tags" */
  update_communication_history_tags?: Maybe<Communication_History_Tags_Mutation_Response>;
  /** update single row of the table: "communication_history_tags" */
  update_communication_history_tags_by_pk?: Maybe<Communication_History_Tags>;
  /** update multiples rows of table: "communication_history_tags" */
  update_communication_history_tags_many?: Maybe<
    Array<Maybe<Communication_History_Tags_Mutation_Response>>
  >;
  /** update data of the table: "communication_history_types" */
  update_communication_history_types?: Maybe<Communication_History_Types_Mutation_Response>;
  /** update single row of the table: "communication_history_types" */
  update_communication_history_types_by_pk?: Maybe<Communication_History_Types>;
  /** update multiples rows of table: "communication_history_types" */
  update_communication_history_types_many?: Maybe<
    Array<Maybe<Communication_History_Types_Mutation_Response>>
  >;
  /** update data of the table: "devices" */
  update_devices?: Maybe<Devices_Mutation_Response>;
  /** update single row of the table: "devices" */
  update_devices_by_pk?: Maybe<Devices>;
  /** update multiples rows of table: "devices" */
  update_devices_many?: Maybe<Array<Maybe<Devices_Mutation_Response>>>;
  /** update data of the table: "discovered_from" */
  update_discovered_from?: Maybe<Discovered_From_Mutation_Response>;
  /** update single row of the table: "discovered_from" */
  update_discovered_from_by_pk?: Maybe<Discovered_From>;
  /** update multiples rows of table: "discovered_from" */
  update_discovered_from_many?: Maybe<
    Array<Maybe<Discovered_From_Mutation_Response>>
  >;
  /** update data of the table: "document_status_types" */
  update_document_status_types?: Maybe<Document_Status_Types_Mutation_Response>;
  /** update single row of the table: "document_status_types" */
  update_document_status_types_by_pk?: Maybe<Document_Status_Types>;
  /** update multiples rows of table: "document_status_types" */
  update_document_status_types_many?: Maybe<
    Array<Maybe<Document_Status_Types_Mutation_Response>>
  >;
  /** update data of the table: "document_types" */
  update_document_types?: Maybe<Document_Types_Mutation_Response>;
  /** update single row of the table: "document_types" */
  update_document_types_by_pk?: Maybe<Document_Types>;
  /** update multiples rows of table: "document_types" */
  update_document_types_many?: Maybe<
    Array<Maybe<Document_Types_Mutation_Response>>
  >;
  /** update data of the table: "documents" */
  update_documents?: Maybe<Documents_Mutation_Response>;
  /** update single row of the table: "documents" */
  update_documents_by_pk?: Maybe<Documents>;
  /** update multiples rows of table: "documents" */
  update_documents_many?: Maybe<Array<Maybe<Documents_Mutation_Response>>>;
  /** update data of the table: "fhir_resource_types" */
  update_fhir_resource_types?: Maybe<Fhir_Resource_Types_Mutation_Response>;
  /** update single row of the table: "fhir_resource_types" */
  update_fhir_resource_types_by_pk?: Maybe<Fhir_Resource_Types>;
  /** update multiples rows of table: "fhir_resource_types" */
  update_fhir_resource_types_many?: Maybe<
    Array<Maybe<Fhir_Resource_Types_Mutation_Response>>
  >;
  /** update data of the table: "fhir_resources" */
  update_fhir_resources?: Maybe<Fhir_Resources_Mutation_Response>;
  /** update single row of the table: "fhir_resources" */
  update_fhir_resources_by_pk?: Maybe<Fhir_Resources>;
  /** update data of the table: "fhir_resources_history" */
  update_fhir_resources_history?: Maybe<Fhir_Resources_History_Mutation_Response>;
  /** update single row of the table: "fhir_resources_history" */
  update_fhir_resources_history_by_pk?: Maybe<Fhir_Resources_History>;
  /** update multiples rows of table: "fhir_resources_history" */
  update_fhir_resources_history_many?: Maybe<
    Array<Maybe<Fhir_Resources_History_Mutation_Response>>
  >;
  /** update multiples rows of table: "fhir_resources" */
  update_fhir_resources_many?: Maybe<
    Array<Maybe<Fhir_Resources_Mutation_Response>>
  >;
  /** update data of the table: "file_document_type" */
  update_file_document_type?: Maybe<File_Document_Type_Mutation_Response>;
  /** update single row of the table: "file_document_type" */
  update_file_document_type_by_pk?: Maybe<File_Document_Type>;
  /** update multiples rows of table: "file_document_type" */
  update_file_document_type_many?: Maybe<
    Array<Maybe<File_Document_Type_Mutation_Response>>
  >;
  /** update data of the table: "files" */
  update_files?: Maybe<Files_Mutation_Response>;
  /** update single row of the table: "files" */
  update_files_by_pk?: Maybe<Files>;
  /** update multiples rows of table: "files" */
  update_files_many?: Maybe<Array<Maybe<Files_Mutation_Response>>>;
  /** update data of the table: "global_notes" */
  update_global_notes?: Maybe<Global_Notes_Mutation_Response>;
  /** update single row of the table: "global_notes" */
  update_global_notes_by_pk?: Maybe<Global_Notes>;
  /** update multiples rows of table: "global_notes" */
  update_global_notes_many?: Maybe<
    Array<Maybe<Global_Notes_Mutation_Response>>
  >;
  /** update data of the table: "insurance" */
  update_insurance?: Maybe<Insurance_Mutation_Response>;
  /** update single row of the table: "insurance" */
  update_insurance_by_pk?: Maybe<Insurance>;
  /** update multiples rows of table: "insurance" */
  update_insurance_many?: Maybe<Array<Maybe<Insurance_Mutation_Response>>>;
  /** update data of the table: "insurance_providers" */
  update_insurance_providers?: Maybe<Insurance_Providers_Mutation_Response>;
  /** update single row of the table: "insurance_providers" */
  update_insurance_providers_by_pk?: Maybe<Insurance_Providers>;
  /** update multiples rows of table: "insurance_providers" */
  update_insurance_providers_many?: Maybe<
    Array<Maybe<Insurance_Providers_Mutation_Response>>
  >;
  /** update data of the table: "insurance_types" */
  update_insurance_types?: Maybe<Insurance_Types_Mutation_Response>;
  /** update single row of the table: "insurance_types" */
  update_insurance_types_by_pk?: Maybe<Insurance_Types>;
  /** update multiples rows of table: "insurance_types" */
  update_insurance_types_many?: Maybe<
    Array<Maybe<Insurance_Types_Mutation_Response>>
  >;
  /** update data of the table: "kareo_logs" */
  update_kareo_logs?: Maybe<Kareo_Logs_Mutation_Response>;
  /** update single row of the table: "kareo_logs" */
  update_kareo_logs_by_pk?: Maybe<Kareo_Logs>;
  /** update multiples rows of table: "kareo_logs" */
  update_kareo_logs_many?: Maybe<Array<Maybe<Kareo_Logs_Mutation_Response>>>;
  /** update data of the table: "locations" */
  update_locations?: Maybe<Locations_Mutation_Response>;
  /** update single row of the table: "locations" */
  update_locations_by_pk?: Maybe<Locations>;
  /** update multiples rows of table: "locations" */
  update_locations_many?: Maybe<Array<Maybe<Locations_Mutation_Response>>>;
  /** update data of the table: "medical_history" */
  update_medical_history?: Maybe<Medical_History_Mutation_Response>;
  /** update data of the table: "medical_history_answers" */
  update_medical_history_answers?: Maybe<Medical_History_Answers_Mutation_Response>;
  /** update single row of the table: "medical_history_answers" */
  update_medical_history_answers_by_pk?: Maybe<Medical_History_Answers>;
  /** update multiples rows of table: "medical_history_answers" */
  update_medical_history_answers_many?: Maybe<
    Array<Maybe<Medical_History_Answers_Mutation_Response>>
  >;
  /** update single row of the table: "medical_history" */
  update_medical_history_by_pk?: Maybe<Medical_History>;
  /** update multiples rows of table: "medical_history" */
  update_medical_history_many?: Maybe<
    Array<Maybe<Medical_History_Mutation_Response>>
  >;
  /** update data of the table: "medical_history_person_types" */
  update_medical_history_person_types?: Maybe<Medical_History_Person_Types_Mutation_Response>;
  /** update single row of the table: "medical_history_person_types" */
  update_medical_history_person_types_by_pk?: Maybe<Medical_History_Person_Types>;
  /** update multiples rows of table: "medical_history_person_types" */
  update_medical_history_person_types_many?: Maybe<
    Array<Maybe<Medical_History_Person_Types_Mutation_Response>>
  >;
  /** update data of the table: "medical_history_questions" */
  update_medical_history_questions?: Maybe<Medical_History_Questions_Mutation_Response>;
  /** update single row of the table: "medical_history_questions" */
  update_medical_history_questions_by_pk?: Maybe<Medical_History_Questions>;
  /** update multiples rows of table: "medical_history_questions" */
  update_medical_history_questions_many?: Maybe<
    Array<Maybe<Medical_History_Questions_Mutation_Response>>
  >;
  /** update data of the table: "medical_history_types" */
  update_medical_history_types?: Maybe<Medical_History_Types_Mutation_Response>;
  /** update single row of the table: "medical_history_types" */
  update_medical_history_types_by_pk?: Maybe<Medical_History_Types>;
  /** update multiples rows of table: "medical_history_types" */
  update_medical_history_types_many?: Maybe<
    Array<Maybe<Medical_History_Types_Mutation_Response>>
  >;
  /** update data of the table: "patient_migrations" */
  update_patient_migrations?: Maybe<Patient_Migrations_Mutation_Response>;
  /** update single row of the table: "patient_migrations" */
  update_patient_migrations_by_pk?: Maybe<Patient_Migrations>;
  /** update multiples rows of table: "patient_migrations" */
  update_patient_migrations_many?: Maybe<
    Array<Maybe<Patient_Migrations_Mutation_Response>>
  >;
  /** update data of the table: "patient_not_duplicates" */
  update_patient_not_duplicates?: Maybe<Patient_Not_Duplicates_Mutation_Response>;
  /** update single row of the table: "patient_not_duplicates" */
  update_patient_not_duplicates_by_pk?: Maybe<Patient_Not_Duplicates>;
  /** update multiples rows of table: "patient_not_duplicates" */
  update_patient_not_duplicates_many?: Maybe<
    Array<Maybe<Patient_Not_Duplicates_Mutation_Response>>
  >;
  /** update data of the table: "patient_record_status_types" */
  update_patient_record_status_types?: Maybe<Patient_Record_Status_Types_Mutation_Response>;
  /** update single row of the table: "patient_record_status_types" */
  update_patient_record_status_types_by_pk?: Maybe<Patient_Record_Status_Types>;
  /** update multiples rows of table: "patient_record_status_types" */
  update_patient_record_status_types_many?: Maybe<
    Array<Maybe<Patient_Record_Status_Types_Mutation_Response>>
  >;
  /** update data of the table: "patients" */
  update_patients?: Maybe<Patients_Mutation_Response>;
  /** update single row of the table: "patients" */
  update_patients_by_pk?: Maybe<Patients>;
  /** update multiples rows of table: "patients" */
  update_patients_many?: Maybe<Array<Maybe<Patients_Mutation_Response>>>;
  /** update data of the table: "payment_type" */
  update_payment_type?: Maybe<Payment_Type_Mutation_Response>;
  /** update single row of the table: "payment_type" */
  update_payment_type_by_pk?: Maybe<Payment_Type>;
  /** update multiples rows of table: "payment_type" */
  update_payment_type_many?: Maybe<
    Array<Maybe<Payment_Type_Mutation_Response>>
  >;
  /** update data of the table: "phone_numbers" */
  update_phone_numbers?: Maybe<Phone_Numbers_Mutation_Response>;
  /** update single row of the table: "phone_numbers" */
  update_phone_numbers_by_pk?: Maybe<Phone_Numbers>;
  /** update multiples rows of table: "phone_numbers" */
  update_phone_numbers_many?: Maybe<
    Array<Maybe<Phone_Numbers_Mutation_Response>>
  >;
  /** update data of the table: "problem_status_types" */
  update_problem_status_types?: Maybe<Problem_Status_Types_Mutation_Response>;
  /** update single row of the table: "problem_status_types" */
  update_problem_status_types_by_pk?: Maybe<Problem_Status_Types>;
  /** update multiples rows of table: "problem_status_types" */
  update_problem_status_types_many?: Maybe<
    Array<Maybe<Problem_Status_Types_Mutation_Response>>
  >;
  /** update data of the table: "problems" */
  update_problems?: Maybe<Problems_Mutation_Response>;
  /** update single row of the table: "problems" */
  update_problems_by_pk?: Maybe<Problems>;
  /** update multiples rows of table: "problems" */
  update_problems_many?: Maybe<Array<Maybe<Problems_Mutation_Response>>>;
  /** update data of the table: "products" */
  update_products?: Maybe<Products_Mutation_Response>;
  /** update single row of the table: "products" */
  update_products_by_pk?: Maybe<Products>;
  /** update multiples rows of table: "products" */
  update_products_many?: Maybe<Array<Maybe<Products_Mutation_Response>>>;
  /** update data of the table: "reconciliation_type" */
  update_reconciliation_type?: Maybe<Reconciliation_Type_Mutation_Response>;
  /** update single row of the table: "reconciliation_type" */
  update_reconciliation_type_by_pk?: Maybe<Reconciliation_Type>;
  /** update multiples rows of table: "reconciliation_type" */
  update_reconciliation_type_many?: Maybe<
    Array<Maybe<Reconciliation_Type_Mutation_Response>>
  >;
  /** update data of the table: "reconciliations" */
  update_reconciliations?: Maybe<Reconciliations_Mutation_Response>;
  /** update single row of the table: "reconciliations" */
  update_reconciliations_by_pk?: Maybe<Reconciliations>;
  /** update multiples rows of table: "reconciliations" */
  update_reconciliations_many?: Maybe<
    Array<Maybe<Reconciliations_Mutation_Response>>
  >;
  /** update data of the table: "regions" */
  update_regions?: Maybe<Regions_Mutation_Response>;
  /** update single row of the table: "regions" */
  update_regions_by_pk?: Maybe<Regions>;
  /** update multiples rows of table: "regions" */
  update_regions_many?: Maybe<Array<Maybe<Regions_Mutation_Response>>>;
  /** update data of the table: "reservations" */
  update_reservations?: Maybe<Reservations_Mutation_Response>;
  /** update multiples rows of table: "reservations" */
  update_reservations_many?: Maybe<
    Array<Maybe<Reservations_Mutation_Response>>
  >;
  /** update data of the table: "reviews" */
  update_reviews?: Maybe<Reviews_Mutation_Response>;
  /** update single row of the table: "reviews" */
  update_reviews_by_pk?: Maybe<Reviews>;
  /** update multiples rows of table: "reviews" */
  update_reviews_many?: Maybe<Array<Maybe<Reviews_Mutation_Response>>>;
  /** update data of the table: "rooms" */
  update_rooms?: Maybe<Rooms_Mutation_Response>;
  /** update single row of the table: "rooms" */
  update_rooms_by_pk?: Maybe<Rooms>;
  /** update multiples rows of table: "rooms" */
  update_rooms_many?: Maybe<Array<Maybe<Rooms_Mutation_Response>>>;
  /** update data of the table: "screening_category_types" */
  update_screening_category_types?: Maybe<Screening_Category_Types_Mutation_Response>;
  /** update single row of the table: "screening_category_types" */
  update_screening_category_types_by_pk?: Maybe<Screening_Category_Types>;
  /** update multiples rows of table: "screening_category_types" */
  update_screening_category_types_many?: Maybe<
    Array<Maybe<Screening_Category_Types_Mutation_Response>>
  >;
  /** update data of the table: "screening_types" */
  update_screening_types?: Maybe<Screening_Types_Mutation_Response>;
  /** update single row of the table: "screening_types" */
  update_screening_types_by_pk?: Maybe<Screening_Types>;
  /** update multiples rows of table: "screening_types" */
  update_screening_types_many?: Maybe<
    Array<Maybe<Screening_Types_Mutation_Response>>
  >;
  /** update data of the table: "screenings" */
  update_screenings?: Maybe<Screenings_Mutation_Response>;
  /** update single row of the table: "screenings" */
  update_screenings_by_pk?: Maybe<Screenings>;
  /** update multiples rows of table: "screenings" */
  update_screenings_many?: Maybe<Array<Maybe<Screenings_Mutation_Response>>>;
  /** update data of the table: "services" */
  update_services?: Maybe<Services_Mutation_Response>;
  /** update single row of the table: "services" */
  update_services_by_pk?: Maybe<Services>;
  /** update multiples rows of table: "services" */
  update_services_many?: Maybe<Array<Maybe<Services_Mutation_Response>>>;
  /** update data of the table: "snomed_synonyms" */
  update_snomed_synonyms?: Maybe<Snomed_Synonyms_Mutation_Response>;
  /** update single row of the table: "snomed_synonyms" */
  update_snomed_synonyms_by_pk?: Maybe<Snomed_Synonyms>;
  /** update multiples rows of table: "snomed_synonyms" */
  update_snomed_synonyms_many?: Maybe<
    Array<Maybe<Snomed_Synonyms_Mutation_Response>>
  >;
  /** update data of the table: "staff" */
  update_staff?: Maybe<Staff_Mutation_Response>;
  /** update single row of the table: "staff" */
  update_staff_by_pk?: Maybe<Staff>;
  /** update multiples rows of table: "staff" */
  update_staff_many?: Maybe<Array<Maybe<Staff_Mutation_Response>>>;
  /** update data of the table: "staff_regions" */
  update_staff_regions?: Maybe<Staff_Regions_Mutation_Response>;
  /** update single row of the table: "staff_regions" */
  update_staff_regions_by_pk?: Maybe<Staff_Regions>;
  /** update multiples rows of table: "staff_regions" */
  update_staff_regions_many?: Maybe<
    Array<Maybe<Staff_Regions_Mutation_Response>>
  >;
  /** update data of the table: "staff_schedule" */
  update_staff_schedule?: Maybe<Staff_Schedule_Mutation_Response>;
  /** update single row of the table: "staff_schedule" */
  update_staff_schedule_by_pk?: Maybe<Staff_Schedule>;
  /** update multiples rows of table: "staff_schedule" */
  update_staff_schedule_many?: Maybe<
    Array<Maybe<Staff_Schedule_Mutation_Response>>
  >;
  /** update data of the table: "staff_schedule_service" */
  update_staff_schedule_service?: Maybe<Staff_Schedule_Service_Mutation_Response>;
  /** update single row of the table: "staff_schedule_service" */
  update_staff_schedule_service_by_pk?: Maybe<Staff_Schedule_Service>;
  /** update multiples rows of table: "staff_schedule_service" */
  update_staff_schedule_service_many?: Maybe<
    Array<Maybe<Staff_Schedule_Service_Mutation_Response>>
  >;
  /** update data of the table: "staff_services" */
  update_staff_services?: Maybe<Staff_Services_Mutation_Response>;
  /** update single row of the table: "staff_services" */
  update_staff_services_by_pk?: Maybe<Staff_Services>;
  /** update multiples rows of table: "staff_services" */
  update_staff_services_many?: Maybe<
    Array<Maybe<Staff_Services_Mutation_Response>>
  >;
  /** update data of the table: "staff_type" */
  update_staff_type?: Maybe<Staff_Type_Mutation_Response>;
  /** update single row of the table: "staff_type" */
  update_staff_type_by_pk?: Maybe<Staff_Type>;
  /** update multiples rows of table: "staff_type" */
  update_staff_type_many?: Maybe<Array<Maybe<Staff_Type_Mutation_Response>>>;
  /** update data of the table: "symptom_checker" */
  update_symptom_checker?: Maybe<Symptom_Checker_Mutation_Response>;
  /** update single row of the table: "symptom_checker" */
  update_symptom_checker_by_pk?: Maybe<Symptom_Checker>;
  /** update multiples rows of table: "symptom_checker" */
  update_symptom_checker_many?: Maybe<
    Array<Maybe<Symptom_Checker_Mutation_Response>>
  >;
  /** update data of the table: "task_priority" */
  update_task_priority?: Maybe<Task_Priority_Mutation_Response>;
  /** update single row of the table: "task_priority" */
  update_task_priority_by_pk?: Maybe<Task_Priority>;
  /** update multiples rows of table: "task_priority" */
  update_task_priority_many?: Maybe<
    Array<Maybe<Task_Priority_Mutation_Response>>
  >;
  /** update data of the table: "task_status" */
  update_task_status?: Maybe<Task_Status_Mutation_Response>;
  /** update single row of the table: "task_status" */
  update_task_status_by_pk?: Maybe<Task_Status>;
  /** update multiples rows of table: "task_status" */
  update_task_status_many?: Maybe<Array<Maybe<Task_Status_Mutation_Response>>>;
  /** update data of the table: "task_sub_type" */
  update_task_sub_type?: Maybe<Task_Sub_Type_Mutation_Response>;
  /** update single row of the table: "task_sub_type" */
  update_task_sub_type_by_pk?: Maybe<Task_Sub_Type>;
  /** update multiples rows of table: "task_sub_type" */
  update_task_sub_type_many?: Maybe<
    Array<Maybe<Task_Sub_Type_Mutation_Response>>
  >;
  /** update data of the table: "task_type" */
  update_task_type?: Maybe<Task_Type_Mutation_Response>;
  /** update single row of the table: "task_type" */
  update_task_type_by_pk?: Maybe<Task_Type>;
  /** update multiples rows of table: "task_type" */
  update_task_type_many?: Maybe<Array<Maybe<Task_Type_Mutation_Response>>>;
  /** update data of the table: "tasks" */
  update_tasks?: Maybe<Tasks_Mutation_Response>;
  /** update data of the table: "tasks_assignments" */
  update_tasks_assignments?: Maybe<Tasks_Assignments_Mutation_Response>;
  /** update single row of the table: "tasks_assignments" */
  update_tasks_assignments_by_pk?: Maybe<Tasks_Assignments>;
  /** update multiples rows of table: "tasks_assignments" */
  update_tasks_assignments_many?: Maybe<
    Array<Maybe<Tasks_Assignments_Mutation_Response>>
  >;
  /** update single row of the table: "tasks" */
  update_tasks_by_pk?: Maybe<Tasks>;
  /** update data of the table: "tasks_files" */
  update_tasks_files?: Maybe<Tasks_Files_Mutation_Response>;
  /** update single row of the table: "tasks_files" */
  update_tasks_files_by_pk?: Maybe<Tasks_Files>;
  /** update multiples rows of table: "tasks_files" */
  update_tasks_files_many?: Maybe<Array<Maybe<Tasks_Files_Mutation_Response>>>;
  /** update data of the table: "tasks_groups" */
  update_tasks_groups?: Maybe<Tasks_Groups_Mutation_Response>;
  /** update single row of the table: "tasks_groups" */
  update_tasks_groups_by_pk?: Maybe<Tasks_Groups>;
  /** update multiples rows of table: "tasks_groups" */
  update_tasks_groups_many?: Maybe<
    Array<Maybe<Tasks_Groups_Mutation_Response>>
  >;
  /** update data of the table: "tasks_groups_staff" */
  update_tasks_groups_staff?: Maybe<Tasks_Groups_Staff_Mutation_Response>;
  /** update single row of the table: "tasks_groups_staff" */
  update_tasks_groups_staff_by_pk?: Maybe<Tasks_Groups_Staff>;
  /** update multiples rows of table: "tasks_groups_staff" */
  update_tasks_groups_staff_many?: Maybe<
    Array<Maybe<Tasks_Groups_Staff_Mutation_Response>>
  >;
  /** update multiples rows of table: "tasks" */
  update_tasks_many?: Maybe<Array<Maybe<Tasks_Mutation_Response>>>;
  /** update data of the table: "tasks_notifications" */
  update_tasks_notifications?: Maybe<Tasks_Notifications_Mutation_Response>;
  /** update single row of the table: "tasks_notifications" */
  update_tasks_notifications_by_pk?: Maybe<Tasks_Notifications>;
  /** update multiples rows of table: "tasks_notifications" */
  update_tasks_notifications_many?: Maybe<
    Array<Maybe<Tasks_Notifications_Mutation_Response>>
  >;
  /** update data of the table: "tasks_read" */
  update_tasks_read?: Maybe<Tasks_Read_Mutation_Response>;
  /** update single row of the table: "tasks_read" */
  update_tasks_read_by_pk?: Maybe<Tasks_Read>;
  /** update multiples rows of table: "tasks_read" */
  update_tasks_read_many?: Maybe<Array<Maybe<Tasks_Read_Mutation_Response>>>;
  /** update data of the table: "tasks_watch" */
  update_tasks_watch?: Maybe<Tasks_Watch_Mutation_Response>;
  /** update single row of the table: "tasks_watch" */
  update_tasks_watch_by_pk?: Maybe<Tasks_Watch>;
  /** update multiples rows of table: "tasks_watch" */
  update_tasks_watch_many?: Maybe<Array<Maybe<Tasks_Watch_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "visit_classifications" */
  update_visit_classifications?: Maybe<Visit_Classifications_Mutation_Response>;
  /** update single row of the table: "visit_classifications" */
  update_visit_classifications_by_pk?: Maybe<Visit_Classifications>;
  /** update multiples rows of table: "visit_classifications" */
  update_visit_classifications_many?: Maybe<
    Array<Maybe<Visit_Classifications_Mutation_Response>>
  >;
  /** update data of the table: "visit_types" */
  update_visit_types?: Maybe<Visit_Types_Mutation_Response>;
  /** update single row of the table: "visit_types" */
  update_visit_types_by_pk?: Maybe<Visit_Types>;
  /** update multiples rows of table: "visit_types" */
  update_visit_types_many?: Maybe<Array<Maybe<Visit_Types_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootAddAccountArgs = {
  avatarId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  goesByName?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  parentRelationship?: InputMaybe<Scalars['String']>;
  parentType?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['String']>;
  patientIds?: InputMaybe<Array<InputMaybe<PatientRelationshipPayload>>>;
  phoneNumber: Scalars['String'];
  preferredLanguage?: InputMaybe<Scalars['String']>;
  preferredPronoun?: InputMaybe<Scalars['String']>;
  preferredPronounType?: InputMaybe<Scalars['String']>;
  relationship?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootAddPatientTelehealthConsentArgs = {
  accompanyingAccountId: Scalars['String'];
  appointmentId?: InputMaybe<Scalars['String']>;
  patientId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootAttachAccountToPatientArgs = {
  accountId: Scalars['String'];
  patientId: Scalars['String'];
  relationship: Scalars['String'];
};

/** mutation root */
export type Mutation_RootAuth0CreateEmailAccountArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootAuth0LinkAccountsArgs = {
  emailToken: Scalars['String'];
  phoneToken: Scalars['String'];
  userMetaData?: InputMaybe<UserMetaDataInput>;
};

/** mutation root */
export type Mutation_RootAuth0LoadEmployeeArgs = {
  email: Scalars['String'];
};

/** mutation root */
export type Mutation_RootAuth0PasswordlessVerifyArgs = {
  phoneToken?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootAuth0ResetPasswordArgs = {
  password: Scalars['String'];
  phoneToken: Scalars['String'];
};

/** mutation root */
export type Mutation_RootBookAppointmentArgs = {
  accountId: Scalars['String'];
  careType: Scalars['String'];
  clinicCode: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  patientId: Scalars['String'];
  reason: Scalars['String'];
  safetyQuestionnaire?: InputMaybe<Scalars['jsonb']>;
  staffId?: InputMaybe<Scalars['String']>;
  symptomCheckerId?: InputMaybe<Scalars['String']>;
  time: Scalars['String'];
  type: Scalars['String'];
};

/** mutation root */
export type Mutation_RootBookWalkinAppointmentArgs = {
  accountId: Scalars['String'];
  careType: Scalars['String'];
  clinicCode: Scalars['String'];
  patientId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreateAccountPatientArgs = {
  accountFirstName: Scalars['String'];
  accountId?: InputMaybe<Scalars['String']>;
  accountLastName: Scalars['String'];
  dateOfBirth: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  patientFirstName: Scalars['String'];
  patientId?: InputMaybe<Scalars['String']>;
  patientLastName: Scalars['String'];
  phone_number: Scalars['String'];
  relationship: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreateKareoAppointmentArgs = {
  appointmentId: Scalars['String'];
  kareoClinic: Scalars['String'];
  kareoProvider: Scalars['String'];
  kareoType: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreateKareoPatientArgs = {
  patientId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreatePatientArgs = {
  dateOfBirth: Scalars['String'];
  firstName: Scalars['String'];
  genderAtBirth?: InputMaybe<Scalars['String']>;
  goesByName?: InputMaybe<Scalars['String']>;
  hasPCP?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  lastWellVisitStatus?: InputMaybe<Scalars['String']>;
  preferredPronoun?: InputMaybe<Scalars['String']>;
  preferredPronounType?: InputMaybe<Scalars['String']>;
  relationship: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreatePaymentMethodArgs = {
  accountId?: InputMaybe<Scalars['String']>;
  source: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreateReviewArgs = {
  nps: Scalars['Int'];
  review: Scalars['String'];
  shortCode: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreateSupportTicketArgs = {
  body: Scalars['String'];
  subject: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreateSymptomCheckerSessionArgs = {
  phoneNumber?: InputMaybe<Scalars['String']>;
  session: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCustomerIoGetCouponArgs = {
  checkoutSession: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  priceId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeletePaymentMethodArgs = {
  accountId?: InputMaybe<Scalars['String']>;
  stripeCardId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootFindKareoPatientArgs = {
  dateOfBirth?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootGenerateTemporaryVerificationCodeArgs = {
  accountId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootHandleStripeWebhookArgs = {
  body: Scalars['String'];
};

/** mutation root */
export type Mutation_RootInsertCommunicationHistoryArgs = {
  communicationData: CommunicationInput;
};

/** mutation root */
export type Mutation_RootMergePatientsArgs = {
  copyFields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fromPatientId: Scalars['String'];
  toPatientId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootNormalizeStaffRolesArgs = {
  id: Scalars['String'];
  roles: Array<InputMaybe<Scalars['String']>>;
};

/** mutation root */
export type Mutation_RootOneTimeChargeArgs = {
  amount: Scalars['String'];
  appointmentId?: InputMaybe<Scalars['String']>;
  cardToken: Scalars['String'];
  clinicId?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<PaymentType>;
  products?: InputMaybe<Array<InputMaybe<ProductPayload>>>;
  reason?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootRegisterDeviceTokenArgs = {
  channel: PushNotificationChannel;
  deviceToken: Scalars['String'];
};

/** mutation root */
export type Mutation_RootRequestUploadArgs = {
  accountId?: InputMaybe<Scalars['String']>;
  contentMD5: Scalars['String'];
  documentType?: InputMaybe<Scalars['String']>;
  fileName: Scalars['String'];
  patientId?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootScheduleAppointmentArgs = {
  accountId: Scalars['String'];
  address?: InputMaybe<Scalars['String']>;
  campaign?: InputMaybe<Scalars['String']>;
  clinicId: Scalars['String'];
  communicationHistoryId?: InputMaybe<Scalars['String']>;
  consentToMedicalTerms?: InputMaybe<Scalars['Boolean']>;
  consentToTelemedicineTerms?: InputMaybe<Scalars['Boolean']>;
  consentToTermsAndPrivacy?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  patientId: Scalars['String'];
  reason: Scalars['String'];
  reservationId?: InputMaybe<Scalars['String']>;
  safetyQuestionnaire?: InputMaybe<Scalars['jsonb']>;
  sqlTime: Scalars['String'];
  staffId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  symptomCheckerId?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  visitClassification?: InputMaybe<Scalars['String']>;
  visitType?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootSendSymptomSummaryArgs = {
  number: Scalars['String'];
  summaryUrl: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSendTextNotificationArgs = {
  message: Scalars['String'];
  to: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSendVCardArgs = {
  number: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSignConsentArgs = {
  documentId: Scalars['String'];
  patientId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootStaffBookAppointmentArgs = {
  accountId: Scalars['uuid'];
  careType: Scalars['String'];
  clinicCode: Scalars['String'];
  doubleBook: Scalars['Boolean'];
  email?: InputMaybe<Scalars['String']>;
  endTime: Scalars['String'];
  patientId: Scalars['uuid'];
  primaryStaffId: Scalars['uuid'];
  reason: Scalars['String'];
  startTime: Scalars['String'];
  type: Scalars['String'];
};

/** mutation root */
export type Mutation_RootStaffUpdateAppointmentTimeArgs = {
  accountId: Scalars['String'];
  careType?: InputMaybe<Scalars['String']>;
  clinicCode: Scalars['String'];
  doubleBook: Scalars['Boolean'];
  endTime: Scalars['String'];
  id: Scalars['uuid'];
  notes?: InputMaybe<Scalars['String']>;
  notification: Scalars['Boolean'];
  primaryStaffId: Scalars['uuid'];
  reason?: InputMaybe<Scalars['String']>;
  startTime: Scalars['String'];
};

/** mutation root */
export type Mutation_RootStripeCreateRefundArgs = {
  note?: InputMaybe<Scalars['String']>;
  reason: StripeRefundReason;
  refundAmount: Scalars['Float'];
  stripeChargeId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootStripeRedeemSubscriptionPromoCodeArgs = {
  promoCode: Scalars['String'];
};

/** mutation root */
export type Mutation_RootTrackActionArgs = {
  event: TrackEventInput;
};

/** mutation root */
export type Mutation_RootUpdateAccountPhoneArgs = {
  accountId: Scalars['String'];
  phoneNumber: Scalars['String'];
};

/** mutation root */
export type Mutation_RootUpdateAppointmentStatusArgs = {
  id: Scalars['uuid'];
  notification: Scalars['Boolean'];
  status: Scalars['String'];
};

/** mutation root */
export type Mutation_RootUpdateAppointmentTimeArgs = {
  clinicId?: InputMaybe<Scalars['String']>;
  endTime: Scalars['String'];
  id: Scalars['uuid'];
  notes?: InputMaybe<Scalars['String']>;
  notification: Scalars['Boolean'];
  reason?: InputMaybe<Scalars['String']>;
  startTime: Scalars['String'];
  visitClassification?: InputMaybe<Scalars['String']>;
  visitType?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootUpdateCommunicationHistoryArgs = {
  externalId: Scalars['String'];
  newExternalId?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootUpdateKareoPatientArgs = {
  patientId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootUpdatePaymentMethodArgs = {
  accountId?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  stripeCardId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootUpdateSymptomCheckerSessionArgs = {
  id: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  session: Scalars['String'];
};

/** mutation root */
export type Mutation_RootUploadKareoDocumentsArgs = {
  patientId: Scalars['String'];
};

/** mutation root */
export type Mutation_RootUpsertReservationArgs = {
  reservation?: InputMaybe<ReservationInput>;
};

/** mutation root */
export type Mutation_RootVerifyStaffMemberArgs = {
  token: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Account_PatientArgs = {
  where: Account_Patient_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Account_Patient_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Account_Temporary_VerificationArgs = {
  where: Account_Temporary_Verification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Account_Temporary_Verification_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_AccountsArgs = {
  where: Accounts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Accounts_LimitedArgs = {
  where: Accounts_Limited_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_AllergiesArgs = {
  where: Allergies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Allergies_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Allergies_SeverityArgs = {
  where: Allergies_Severity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Allergies_Severity_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Allergies_StatusArgs = {
  where: Allergies_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Allergies_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Appointment_BlocksArgs = {
  where: Appointment_Blocks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Appointment_Blocks_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Appointment_Blocks_StaffArgs = {
  where: Appointment_Blocks_Staff_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Appointment_Blocks_Staff_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Appointment_PatientArgs = {
  where: Appointment_Patient_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Appointment_Patient_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Appointment_PaymentArgs = {
  where: Appointment_Payment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Appointment_Payment_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Appointment_ReservationsArgs = {
  where: Appointment_Reservations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Appointment_Reservations_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Appointment_StaffArgs = {
  where: Appointment_Staff_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Appointment_Staff_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Appointment_StatusArgs = {
  where: Appointment_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Appointment_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Appointment_TypesArgs = {
  where: Appointment_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Appointment_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_AppointmentsArgs = {
  where: Appointments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Appointments_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Audit_LogsArgs = {
  where: Audit_Logs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Audit_Logs_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Care_Type_DurationArgs = {
  where: Care_Type_Duration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Care_Type_Duration_By_PkArgs = {
  careType: Care_Types_Enum;
  clinicId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Care_TypesArgs = {
  where: Care_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Care_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Charge_ProductsArgs = {
  where: Charge_Products_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Charge_Products_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ChargesArgs = {
  where: Charges_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Charges_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_MessagesArgs = {
  where: Chat_Messages_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Messages_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_Messages_FileArgs = {
  where: Chat_Messages_File_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Messages_File_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_Messages_TypeArgs = {
  where: Chat_Messages_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Messages_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_NotesArgs = {
  where: Chat_Notes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Notes_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_Notes_TypesArgs = {
  where: Chat_Notes_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Notes_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_SessionsArgs = {
  where: Chat_Sessions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Sessions_AccountsArgs = {
  where: Chat_Sessions_Accounts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Sessions_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_Sessions_PatientsArgs = {
  where: Chat_Sessions_Patients_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Sessions_Patients_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_Sessions_ReadArgs = {
  where: Chat_Sessions_Read_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Sessions_Read_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_Sessions_StaffArgs = {
  where: Chat_Sessions_Staff_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Sessions_Staff_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Chat_Sessions_StatusArgs = {
  where: Chat_Sessions_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Chat_Sessions_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Clinic_Location_TypesArgs = {
  where: Clinic_Location_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Clinic_Location_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Clinic_StatusArgs = {
  where: Clinic_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Clinic_Status_By_PkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_ClinicsArgs = {
  where: Clinics_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Clinics_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Clinics_PropertiesArgs = {
  where: Clinics_Properties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Clinics_Properties_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Clinics_Properties_TypesArgs = {
  where: Clinics_Properties_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Clinics_Properties_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Clinics_RegionsArgs = {
  where: Clinics_Regions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Clinics_Regions_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Communication_HistoryArgs = {
  where: Communication_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Communication_History_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Communication_History_DirectionsArgs = {
  where: Communication_History_Directions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Communication_History_Directions_By_PkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Communication_History_Tag_TypesArgs = {
  where: Communication_History_Tag_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Communication_History_Tag_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Communication_History_TagsArgs = {
  where: Communication_History_Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Communication_History_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Communication_History_TypesArgs = {
  where: Communication_History_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Communication_History_Types_By_PkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_DevicesArgs = {
  where: Devices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Devices_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Discovered_FromArgs = {
  where: Discovered_From_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Discovered_From_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Document_Status_TypesArgs = {
  where: Document_Status_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Document_Status_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Document_TypesArgs = {
  where: Document_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Document_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_DocumentsArgs = {
  where: Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Documents_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Fhir_Resource_TypesArgs = {
  where: Fhir_Resource_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Fhir_Resource_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Fhir_ResourcesArgs = {
  where: Fhir_Resources_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Fhir_Resources_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Fhir_Resources_HistoryArgs = {
  where: Fhir_Resources_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Fhir_Resources_History_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_File_Document_TypeArgs = {
  where: File_Document_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_File_Document_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_FilesArgs = {
  where: Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Files_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Global_NotesArgs = {
  where: Global_Notes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Global_Notes_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_InsuranceArgs = {
  where: Insurance_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Insurance_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Insurance_ProvidersArgs = {
  where: Insurance_Providers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Insurance_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Insurance_TypesArgs = {
  where: Insurance_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Insurance_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Kareo_LogsArgs = {
  where: Kareo_Logs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Kareo_Logs_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_LocationsArgs = {
  where: Locations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Medical_HistoryArgs = {
  where: Medical_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Medical_History_AnswersArgs = {
  where: Medical_History_Answers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Medical_History_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Medical_History_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Medical_History_Person_TypesArgs = {
  where: Medical_History_Person_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Medical_History_Person_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Medical_History_QuestionsArgs = {
  where: Medical_History_Questions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Medical_History_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Medical_History_TypesArgs = {
  where: Medical_History_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Medical_History_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Patient_MigrationsArgs = {
  where: Patient_Migrations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Patient_Migrations_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Patient_Not_DuplicatesArgs = {
  where: Patient_Not_Duplicates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Patient_Not_Duplicates_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Patient_Record_Status_TypesArgs = {
  where: Patient_Record_Status_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Patient_Record_Status_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_PatientsArgs = {
  where: Patients_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Patients_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Payment_TypeArgs = {
  where: Payment_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Payment_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Phone_NumbersArgs = {
  where: Phone_Numbers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Phone_Numbers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Problem_Status_TypesArgs = {
  where: Problem_Status_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Problem_Status_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_ProblemsArgs = {
  where: Problems_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Problems_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ProductsArgs = {
  where: Products_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Products_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Reconciliation_TypeArgs = {
  where: Reconciliation_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Reconciliation_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_ReconciliationsArgs = {
  where: Reconciliations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Reconciliations_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_RegionsArgs = {
  where: Regions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Regions_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_ReservationsArgs = {
  where: Reservations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ReviewsArgs = {
  where: Reviews_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Reviews_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_RoomsArgs = {
  where: Rooms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rooms_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Screening_Category_TypesArgs = {
  where: Screening_Category_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Screening_Category_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Screening_TypesArgs = {
  where: Screening_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Screening_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_ScreeningsArgs = {
  where: Screenings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Screenings_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ServicesArgs = {
  where: Services_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Services_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Snomed_SynonymsArgs = {
  where: Snomed_Synonyms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Snomed_Synonyms_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_StaffArgs = {
  where: Staff_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Staff_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Staff_RegionsArgs = {
  where: Staff_Regions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Staff_Regions_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Staff_ScheduleArgs = {
  where: Staff_Schedule_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Staff_Schedule_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Staff_Schedule_ServiceArgs = {
  where: Staff_Schedule_Service_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Staff_Schedule_Service_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Staff_ServicesArgs = {
  where: Staff_Services_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Staff_Services_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Staff_TypeArgs = {
  where: Staff_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Staff_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Symptom_CheckerArgs = {
  where: Symptom_Checker_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Symptom_Checker_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Task_PriorityArgs = {
  where: Task_Priority_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Priority_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Task_StatusArgs = {
  where: Task_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Status_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Task_Sub_TypeArgs = {
  where: Task_Sub_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Sub_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Task_TypeArgs = {
  where: Task_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_TasksArgs = {
  where: Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tasks_AssignmentsArgs = {
  where: Tasks_Assignments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tasks_Assignments_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Tasks_FilesArgs = {
  where: Tasks_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tasks_Files_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Tasks_GroupsArgs = {
  where: Tasks_Groups_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tasks_Groups_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Tasks_Groups_StaffArgs = {
  where: Tasks_Groups_Staff_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tasks_Groups_Staff_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Tasks_NotificationsArgs = {
  where: Tasks_Notifications_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tasks_Notifications_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Tasks_ReadArgs = {
  where: Tasks_Read_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tasks_Read_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_Tasks_WatchArgs = {
  where: Tasks_Watch_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tasks_Watch_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Visit_ClassificationsArgs = {
  where: Visit_Classifications_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Visit_Classifications_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Visit_TypesArgs = {
  where: Visit_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Visit_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootInsert_Account_PatientArgs = {
  objects: Array<Account_Patient_Insert_Input>;
  on_conflict?: InputMaybe<Account_Patient_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Account_Patient_OneArgs = {
  object: Account_Patient_Insert_Input;
  on_conflict?: InputMaybe<Account_Patient_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Account_Temporary_VerificationArgs = {
  objects: Array<Account_Temporary_Verification_Insert_Input>;
  on_conflict?: InputMaybe<Account_Temporary_Verification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Account_Temporary_Verification_OneArgs = {
  object: Account_Temporary_Verification_Insert_Input;
  on_conflict?: InputMaybe<Account_Temporary_Verification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_AccountsArgs = {
  objects: Array<Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Accounts_LimitedArgs = {
  objects: Array<Accounts_Limited_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Accounts_Limited_OneArgs = {
  object: Accounts_Limited_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Accounts_OneArgs = {
  object: Accounts_Insert_Input;
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_AllergiesArgs = {
  objects: Array<Allergies_Insert_Input>;
  on_conflict?: InputMaybe<Allergies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Allergies_OneArgs = {
  object: Allergies_Insert_Input;
  on_conflict?: InputMaybe<Allergies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Allergies_SeverityArgs = {
  objects: Array<Allergies_Severity_Insert_Input>;
  on_conflict?: InputMaybe<Allergies_Severity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Allergies_Severity_OneArgs = {
  object: Allergies_Severity_Insert_Input;
  on_conflict?: InputMaybe<Allergies_Severity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Allergies_StatusArgs = {
  objects: Array<Allergies_Status_Insert_Input>;
  on_conflict?: InputMaybe<Allergies_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Allergies_Status_OneArgs = {
  object: Allergies_Status_Insert_Input;
  on_conflict?: InputMaybe<Allergies_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Appointment_BlocksArgs = {
  objects: Array<Appointment_Blocks_Insert_Input>;
  on_conflict?: InputMaybe<Appointment_Blocks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Appointment_Blocks_OneArgs = {
  object: Appointment_Blocks_Insert_Input;
  on_conflict?: InputMaybe<Appointment_Blocks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Appointment_Blocks_StaffArgs = {
  objects: Array<Appointment_Blocks_Staff_Insert_Input>;
  on_conflict?: InputMaybe<Appointment_Blocks_Staff_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Appointment_Blocks_Staff_OneArgs = {
  object: Appointment_Blocks_Staff_Insert_Input;
  on_conflict?: InputMaybe<Appointment_Blocks_Staff_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Appointment_PatientArgs = {
  objects: Array<Appointment_Patient_Insert_Input>;
  on_conflict?: InputMaybe<Appointment_Patient_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Appointment_Patient_OneArgs = {
  object: Appointment_Patient_Insert_Input;
  on_conflict?: InputMaybe<Appointment_Patient_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Appointment_PaymentArgs = {
  objects: Array<Appointment_Payment_Insert_Input>;
  on_conflict?: InputMaybe<Appointment_Payment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Appointment_Payment_OneArgs = {
  object: Appointment_Payment_Insert_Input;
  on_conflict?: InputMaybe<Appointment_Payment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Appointment_ReservationsArgs = {
  objects: Array<Appointment_Reservations_Insert_Input>;
  on_conflict?: InputMaybe<Appointment_Reservations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Appointment_Reservations_OneArgs = {
  object: Appointment_Reservations_Insert_Input;
  on_conflict?: InputMaybe<Appointment_Reservations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Appointment_StaffArgs = {
  objects: Array<Appointment_Staff_Insert_Input>;
  on_conflict?: InputMaybe<Appointment_Staff_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Appointment_Staff_OneArgs = {
  object: Appointment_Staff_Insert_Input;
  on_conflict?: InputMaybe<Appointment_Staff_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Appointment_StatusArgs = {
  objects: Array<Appointment_Status_Insert_Input>;
  on_conflict?: InputMaybe<Appointment_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Appointment_Status_OneArgs = {
  object: Appointment_Status_Insert_Input;
  on_conflict?: InputMaybe<Appointment_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Appointment_TypesArgs = {
  objects: Array<Appointment_Types_Insert_Input>;
  on_conflict?: InputMaybe<Appointment_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Appointment_Types_OneArgs = {
  object: Appointment_Types_Insert_Input;
  on_conflict?: InputMaybe<Appointment_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_AppointmentsArgs = {
  objects: Array<Appointments_Insert_Input>;
  on_conflict?: InputMaybe<Appointments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Appointments_OneArgs = {
  object: Appointments_Insert_Input;
  on_conflict?: InputMaybe<Appointments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Audit_LogsArgs = {
  objects: Array<Audit_Logs_Insert_Input>;
  on_conflict?: InputMaybe<Audit_Logs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Audit_Logs_OneArgs = {
  object: Audit_Logs_Insert_Input;
  on_conflict?: InputMaybe<Audit_Logs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Care_Type_DurationArgs = {
  objects: Array<Care_Type_Duration_Insert_Input>;
  on_conflict?: InputMaybe<Care_Type_Duration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Care_Type_Duration_OneArgs = {
  object: Care_Type_Duration_Insert_Input;
  on_conflict?: InputMaybe<Care_Type_Duration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Care_TypesArgs = {
  objects: Array<Care_Types_Insert_Input>;
  on_conflict?: InputMaybe<Care_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Care_Types_OneArgs = {
  object: Care_Types_Insert_Input;
  on_conflict?: InputMaybe<Care_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Charge_ProductsArgs = {
  objects: Array<Charge_Products_Insert_Input>;
  on_conflict?: InputMaybe<Charge_Products_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Charge_Products_OneArgs = {
  object: Charge_Products_Insert_Input;
  on_conflict?: InputMaybe<Charge_Products_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ChargesArgs = {
  objects: Array<Charges_Insert_Input>;
  on_conflict?: InputMaybe<Charges_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Charges_OneArgs = {
  object: Charges_Insert_Input;
  on_conflict?: InputMaybe<Charges_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_MessagesArgs = {
  objects: Array<Chat_Messages_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Messages_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Messages_FileArgs = {
  objects: Array<Chat_Messages_File_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Messages_File_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Messages_File_OneArgs = {
  object: Chat_Messages_File_Insert_Input;
  on_conflict?: InputMaybe<Chat_Messages_File_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Messages_OneArgs = {
  object: Chat_Messages_Insert_Input;
  on_conflict?: InputMaybe<Chat_Messages_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Messages_TypeArgs = {
  objects: Array<Chat_Messages_Type_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Messages_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Messages_Type_OneArgs = {
  object: Chat_Messages_Type_Insert_Input;
  on_conflict?: InputMaybe<Chat_Messages_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_NotesArgs = {
  objects: Array<Chat_Notes_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Notes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Notes_OneArgs = {
  object: Chat_Notes_Insert_Input;
  on_conflict?: InputMaybe<Chat_Notes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Notes_TypesArgs = {
  objects: Array<Chat_Notes_Types_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Notes_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Notes_Types_OneArgs = {
  object: Chat_Notes_Types_Insert_Input;
  on_conflict?: InputMaybe<Chat_Notes_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_SessionsArgs = {
  objects: Array<Chat_Sessions_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Sessions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Sessions_AccountsArgs = {
  objects: Array<Chat_Sessions_Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Sessions_Accounts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Sessions_Accounts_OneArgs = {
  object: Chat_Sessions_Accounts_Insert_Input;
  on_conflict?: InputMaybe<Chat_Sessions_Accounts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Sessions_OneArgs = {
  object: Chat_Sessions_Insert_Input;
  on_conflict?: InputMaybe<Chat_Sessions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Sessions_PatientsArgs = {
  objects: Array<Chat_Sessions_Patients_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Sessions_Patients_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Sessions_Patients_OneArgs = {
  object: Chat_Sessions_Patients_Insert_Input;
  on_conflict?: InputMaybe<Chat_Sessions_Patients_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Sessions_ReadArgs = {
  objects: Array<Chat_Sessions_Read_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Sessions_Read_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Sessions_Read_OneArgs = {
  object: Chat_Sessions_Read_Insert_Input;
  on_conflict?: InputMaybe<Chat_Sessions_Read_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Sessions_StaffArgs = {
  objects: Array<Chat_Sessions_Staff_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Sessions_Staff_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Sessions_Staff_OneArgs = {
  object: Chat_Sessions_Staff_Insert_Input;
  on_conflict?: InputMaybe<Chat_Sessions_Staff_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Sessions_StatusArgs = {
  objects: Array<Chat_Sessions_Status_Insert_Input>;
  on_conflict?: InputMaybe<Chat_Sessions_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Chat_Sessions_Status_OneArgs = {
  object: Chat_Sessions_Status_Insert_Input;
  on_conflict?: InputMaybe<Chat_Sessions_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Clinic_Location_TypesArgs = {
  objects: Array<Clinic_Location_Types_Insert_Input>;
  on_conflict?: InputMaybe<Clinic_Location_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Clinic_Location_Types_OneArgs = {
  object: Clinic_Location_Types_Insert_Input;
  on_conflict?: InputMaybe<Clinic_Location_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Clinic_StatusArgs = {
  objects: Array<Clinic_Status_Insert_Input>;
  on_conflict?: InputMaybe<Clinic_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Clinic_Status_OneArgs = {
  object: Clinic_Status_Insert_Input;
  on_conflict?: InputMaybe<Clinic_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClinicsArgs = {
  objects: Array<Clinics_Insert_Input>;
  on_conflict?: InputMaybe<Clinics_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Clinics_OneArgs = {
  object: Clinics_Insert_Input;
  on_conflict?: InputMaybe<Clinics_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Clinics_PropertiesArgs = {
  objects: Array<Clinics_Properties_Insert_Input>;
  on_conflict?: InputMaybe<Clinics_Properties_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Clinics_Properties_OneArgs = {
  object: Clinics_Properties_Insert_Input;
  on_conflict?: InputMaybe<Clinics_Properties_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Clinics_Properties_TypesArgs = {
  objects: Array<Clinics_Properties_Types_Insert_Input>;
  on_conflict?: InputMaybe<Clinics_Properties_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Clinics_Properties_Types_OneArgs = {
  object: Clinics_Properties_Types_Insert_Input;
  on_conflict?: InputMaybe<Clinics_Properties_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Clinics_RegionsArgs = {
  objects: Array<Clinics_Regions_Insert_Input>;
  on_conflict?: InputMaybe<Clinics_Regions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Clinics_Regions_OneArgs = {
  object: Clinics_Regions_Insert_Input;
  on_conflict?: InputMaybe<Clinics_Regions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Communication_HistoryArgs = {
  objects: Array<Communication_History_Insert_Input>;
  on_conflict?: InputMaybe<Communication_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Communication_History_DirectionsArgs = {
  objects: Array<Communication_History_Directions_Insert_Input>;
  on_conflict?: InputMaybe<Communication_History_Directions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Communication_History_Directions_OneArgs = {
  object: Communication_History_Directions_Insert_Input;
  on_conflict?: InputMaybe<Communication_History_Directions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Communication_History_OneArgs = {
  object: Communication_History_Insert_Input;
  on_conflict?: InputMaybe<Communication_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Communication_History_Tag_TypesArgs = {
  objects: Array<Communication_History_Tag_Types_Insert_Input>;
  on_conflict?: InputMaybe<Communication_History_Tag_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Communication_History_Tag_Types_OneArgs = {
  object: Communication_History_Tag_Types_Insert_Input;
  on_conflict?: InputMaybe<Communication_History_Tag_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Communication_History_TagsArgs = {
  objects: Array<Communication_History_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Communication_History_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Communication_History_Tags_OneArgs = {
  object: Communication_History_Tags_Insert_Input;
  on_conflict?: InputMaybe<Communication_History_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Communication_History_TypesArgs = {
  objects: Array<Communication_History_Types_Insert_Input>;
  on_conflict?: InputMaybe<Communication_History_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Communication_History_Types_OneArgs = {
  object: Communication_History_Types_Insert_Input;
  on_conflict?: InputMaybe<Communication_History_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DevicesArgs = {
  objects: Array<Devices_Insert_Input>;
  on_conflict?: InputMaybe<Devices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Devices_OneArgs = {
  object: Devices_Insert_Input;
  on_conflict?: InputMaybe<Devices_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Discovered_FromArgs = {
  objects: Array<Discovered_From_Insert_Input>;
  on_conflict?: InputMaybe<Discovered_From_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Discovered_From_OneArgs = {
  object: Discovered_From_Insert_Input;
  on_conflict?: InputMaybe<Discovered_From_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Document_Status_TypesArgs = {
  objects: Array<Document_Status_Types_Insert_Input>;
  on_conflict?: InputMaybe<Document_Status_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Document_Status_Types_OneArgs = {
  object: Document_Status_Types_Insert_Input;
  on_conflict?: InputMaybe<Document_Status_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Document_TypesArgs = {
  objects: Array<Document_Types_Insert_Input>;
  on_conflict?: InputMaybe<Document_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Document_Types_OneArgs = {
  object: Document_Types_Insert_Input;
  on_conflict?: InputMaybe<Document_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DocumentsArgs = {
  objects: Array<Documents_Insert_Input>;
  on_conflict?: InputMaybe<Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Documents_OneArgs = {
  object: Documents_Insert_Input;
  on_conflict?: InputMaybe<Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Fhir_Resource_TypesArgs = {
  objects: Array<Fhir_Resource_Types_Insert_Input>;
  on_conflict?: InputMaybe<Fhir_Resource_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Fhir_Resource_Types_OneArgs = {
  object: Fhir_Resource_Types_Insert_Input;
  on_conflict?: InputMaybe<Fhir_Resource_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Fhir_ResourcesArgs = {
  objects: Array<Fhir_Resources_Insert_Input>;
  on_conflict?: InputMaybe<Fhir_Resources_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Fhir_Resources_HistoryArgs = {
  objects: Array<Fhir_Resources_History_Insert_Input>;
  on_conflict?: InputMaybe<Fhir_Resources_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Fhir_Resources_History_OneArgs = {
  object: Fhir_Resources_History_Insert_Input;
  on_conflict?: InputMaybe<Fhir_Resources_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Fhir_Resources_OneArgs = {
  object: Fhir_Resources_Insert_Input;
  on_conflict?: InputMaybe<Fhir_Resources_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_File_Document_TypeArgs = {
  objects: Array<File_Document_Type_Insert_Input>;
  on_conflict?: InputMaybe<File_Document_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_File_Document_Type_OneArgs = {
  object: File_Document_Type_Insert_Input;
  on_conflict?: InputMaybe<File_Document_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_FilesArgs = {
  objects: Array<Files_Insert_Input>;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Files_OneArgs = {
  object: Files_Insert_Input;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Global_NotesArgs = {
  objects: Array<Global_Notes_Insert_Input>;
  on_conflict?: InputMaybe<Global_Notes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Global_Notes_OneArgs = {
  object: Global_Notes_Insert_Input;
  on_conflict?: InputMaybe<Global_Notes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_InsuranceArgs = {
  objects: Array<Insurance_Insert_Input>;
  on_conflict?: InputMaybe<Insurance_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Insurance_OneArgs = {
  object: Insurance_Insert_Input;
  on_conflict?: InputMaybe<Insurance_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Insurance_ProvidersArgs = {
  objects: Array<Insurance_Providers_Insert_Input>;
  on_conflict?: InputMaybe<Insurance_Providers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Insurance_Providers_OneArgs = {
  object: Insurance_Providers_Insert_Input;
  on_conflict?: InputMaybe<Insurance_Providers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Insurance_TypesArgs = {
  objects: Array<Insurance_Types_Insert_Input>;
  on_conflict?: InputMaybe<Insurance_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Insurance_Types_OneArgs = {
  object: Insurance_Types_Insert_Input;
  on_conflict?: InputMaybe<Insurance_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Kareo_LogsArgs = {
  objects: Array<Kareo_Logs_Insert_Input>;
  on_conflict?: InputMaybe<Kareo_Logs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Kareo_Logs_OneArgs = {
  object: Kareo_Logs_Insert_Input;
  on_conflict?: InputMaybe<Kareo_Logs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LocationsArgs = {
  objects: Array<Locations_Insert_Input>;
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Locations_OneArgs = {
  object: Locations_Insert_Input;
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Medical_HistoryArgs = {
  objects: Array<Medical_History_Insert_Input>;
  on_conflict?: InputMaybe<Medical_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Medical_History_AnswersArgs = {
  objects: Array<Medical_History_Answers_Insert_Input>;
  on_conflict?: InputMaybe<Medical_History_Answers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Medical_History_Answers_OneArgs = {
  object: Medical_History_Answers_Insert_Input;
  on_conflict?: InputMaybe<Medical_History_Answers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Medical_History_OneArgs = {
  object: Medical_History_Insert_Input;
  on_conflict?: InputMaybe<Medical_History_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Medical_History_Person_TypesArgs = {
  objects: Array<Medical_History_Person_Types_Insert_Input>;
  on_conflict?: InputMaybe<Medical_History_Person_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Medical_History_Person_Types_OneArgs = {
  object: Medical_History_Person_Types_Insert_Input;
  on_conflict?: InputMaybe<Medical_History_Person_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Medical_History_QuestionsArgs = {
  objects: Array<Medical_History_Questions_Insert_Input>;
  on_conflict?: InputMaybe<Medical_History_Questions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Medical_History_Questions_OneArgs = {
  object: Medical_History_Questions_Insert_Input;
  on_conflict?: InputMaybe<Medical_History_Questions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Medical_History_TypesArgs = {
  objects: Array<Medical_History_Types_Insert_Input>;
  on_conflict?: InputMaybe<Medical_History_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Medical_History_Types_OneArgs = {
  object: Medical_History_Types_Insert_Input;
  on_conflict?: InputMaybe<Medical_History_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Patient_MigrationsArgs = {
  objects: Array<Patient_Migrations_Insert_Input>;
  on_conflict?: InputMaybe<Patient_Migrations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Patient_Migrations_OneArgs = {
  object: Patient_Migrations_Insert_Input;
  on_conflict?: InputMaybe<Patient_Migrations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Patient_Not_DuplicatesArgs = {
  objects: Array<Patient_Not_Duplicates_Insert_Input>;
  on_conflict?: InputMaybe<Patient_Not_Duplicates_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Patient_Not_Duplicates_OneArgs = {
  object: Patient_Not_Duplicates_Insert_Input;
  on_conflict?: InputMaybe<Patient_Not_Duplicates_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Patient_Record_Status_TypesArgs = {
  objects: Array<Patient_Record_Status_Types_Insert_Input>;
  on_conflict?: InputMaybe<Patient_Record_Status_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Patient_Record_Status_Types_OneArgs = {
  object: Patient_Record_Status_Types_Insert_Input;
  on_conflict?: InputMaybe<Patient_Record_Status_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PatientsArgs = {
  objects: Array<Patients_Insert_Input>;
  on_conflict?: InputMaybe<Patients_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Patients_OneArgs = {
  object: Patients_Insert_Input;
  on_conflict?: InputMaybe<Patients_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Payment_TypeArgs = {
  objects: Array<Payment_Type_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Payment_Type_OneArgs = {
  object: Payment_Type_Insert_Input;
  on_conflict?: InputMaybe<Payment_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Phone_NumbersArgs = {
  objects: Array<Phone_Numbers_Insert_Input>;
  on_conflict?: InputMaybe<Phone_Numbers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Phone_Numbers_OneArgs = {
  object: Phone_Numbers_Insert_Input;
  on_conflict?: InputMaybe<Phone_Numbers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Problem_Status_TypesArgs = {
  objects: Array<Problem_Status_Types_Insert_Input>;
  on_conflict?: InputMaybe<Problem_Status_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Problem_Status_Types_OneArgs = {
  object: Problem_Status_Types_Insert_Input;
  on_conflict?: InputMaybe<Problem_Status_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProblemsArgs = {
  objects: Array<Problems_Insert_Input>;
  on_conflict?: InputMaybe<Problems_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Problems_OneArgs = {
  object: Problems_Insert_Input;
  on_conflict?: InputMaybe<Problems_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProductsArgs = {
  objects: Array<Products_Insert_Input>;
  on_conflict?: InputMaybe<Products_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Products_OneArgs = {
  object: Products_Insert_Input;
  on_conflict?: InputMaybe<Products_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Reconciliation_TypeArgs = {
  objects: Array<Reconciliation_Type_Insert_Input>;
  on_conflict?: InputMaybe<Reconciliation_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Reconciliation_Type_OneArgs = {
  object: Reconciliation_Type_Insert_Input;
  on_conflict?: InputMaybe<Reconciliation_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ReconciliationsArgs = {
  objects: Array<Reconciliations_Insert_Input>;
  on_conflict?: InputMaybe<Reconciliations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Reconciliations_OneArgs = {
  object: Reconciliations_Insert_Input;
  on_conflict?: InputMaybe<Reconciliations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RegionsArgs = {
  objects: Array<Regions_Insert_Input>;
  on_conflict?: InputMaybe<Regions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Regions_OneArgs = {
  object: Regions_Insert_Input;
  on_conflict?: InputMaybe<Regions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ReservationsArgs = {
  objects: Array<Reservations_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Reservations_OneArgs = {
  object: Reservations_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_ReviewsArgs = {
  objects: Array<Reviews_Insert_Input>;
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Reviews_OneArgs = {
  object: Reviews_Insert_Input;
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RoomsArgs = {
  objects: Array<Rooms_Insert_Input>;
  on_conflict?: InputMaybe<Rooms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Rooms_OneArgs = {
  object: Rooms_Insert_Input;
  on_conflict?: InputMaybe<Rooms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Screening_Category_TypesArgs = {
  objects: Array<Screening_Category_Types_Insert_Input>;
  on_conflict?: InputMaybe<Screening_Category_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Screening_Category_Types_OneArgs = {
  object: Screening_Category_Types_Insert_Input;
  on_conflict?: InputMaybe<Screening_Category_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Screening_TypesArgs = {
  objects: Array<Screening_Types_Insert_Input>;
  on_conflict?: InputMaybe<Screening_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Screening_Types_OneArgs = {
  object: Screening_Types_Insert_Input;
  on_conflict?: InputMaybe<Screening_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ScreeningsArgs = {
  objects: Array<Screenings_Insert_Input>;
  on_conflict?: InputMaybe<Screenings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Screenings_OneArgs = {
  object: Screenings_Insert_Input;
  on_conflict?: InputMaybe<Screenings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ServicesArgs = {
  objects: Array<Services_Insert_Input>;
  on_conflict?: InputMaybe<Services_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Services_OneArgs = {
  object: Services_Insert_Input;
  on_conflict?: InputMaybe<Services_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Snomed_SynonymsArgs = {
  objects: Array<Snomed_Synonyms_Insert_Input>;
  on_conflict?: InputMaybe<Snomed_Synonyms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Snomed_Synonyms_OneArgs = {
  object: Snomed_Synonyms_Insert_Input;
  on_conflict?: InputMaybe<Snomed_Synonyms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_StaffArgs = {
  objects: Array<Staff_Insert_Input>;
  on_conflict?: InputMaybe<Staff_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Staff_OneArgs = {
  object: Staff_Insert_Input;
  on_conflict?: InputMaybe<Staff_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Staff_RegionsArgs = {
  objects: Array<Staff_Regions_Insert_Input>;
  on_conflict?: InputMaybe<Staff_Regions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Staff_Regions_OneArgs = {
  object: Staff_Regions_Insert_Input;
  on_conflict?: InputMaybe<Staff_Regions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Staff_ScheduleArgs = {
  objects: Array<Staff_Schedule_Insert_Input>;
  on_conflict?: InputMaybe<Staff_Schedule_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Staff_Schedule_OneArgs = {
  object: Staff_Schedule_Insert_Input;
  on_conflict?: InputMaybe<Staff_Schedule_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Staff_Schedule_ServiceArgs = {
  objects: Array<Staff_Schedule_Service_Insert_Input>;
  on_conflict?: InputMaybe<Staff_Schedule_Service_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Staff_Schedule_Service_OneArgs = {
  object: Staff_Schedule_Service_Insert_Input;
  on_conflict?: InputMaybe<Staff_Schedule_Service_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Staff_ServicesArgs = {
  objects: Array<Staff_Services_Insert_Input>;
  on_conflict?: InputMaybe<Staff_Services_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Staff_Services_OneArgs = {
  object: Staff_Services_Insert_Input;
  on_conflict?: InputMaybe<Staff_Services_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Staff_TypeArgs = {
  objects: Array<Staff_Type_Insert_Input>;
  on_conflict?: InputMaybe<Staff_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Staff_Type_OneArgs = {
  object: Staff_Type_Insert_Input;
  on_conflict?: InputMaybe<Staff_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Symptom_CheckerArgs = {
  objects: Array<Symptom_Checker_Insert_Input>;
  on_conflict?: InputMaybe<Symptom_Checker_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Symptom_Checker_OneArgs = {
  object: Symptom_Checker_Insert_Input;
  on_conflict?: InputMaybe<Symptom_Checker_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_PriorityArgs = {
  objects: Array<Task_Priority_Insert_Input>;
  on_conflict?: InputMaybe<Task_Priority_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Priority_OneArgs = {
  object: Task_Priority_Insert_Input;
  on_conflict?: InputMaybe<Task_Priority_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_StatusArgs = {
  objects: Array<Task_Status_Insert_Input>;
  on_conflict?: InputMaybe<Task_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Status_OneArgs = {
  object: Task_Status_Insert_Input;
  on_conflict?: InputMaybe<Task_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Sub_TypeArgs = {
  objects: Array<Task_Sub_Type_Insert_Input>;
  on_conflict?: InputMaybe<Task_Sub_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Sub_Type_OneArgs = {
  object: Task_Sub_Type_Insert_Input;
  on_conflict?: InputMaybe<Task_Sub_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_TypeArgs = {
  objects: Array<Task_Type_Insert_Input>;
  on_conflict?: InputMaybe<Task_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Type_OneArgs = {
  object: Task_Type_Insert_Input;
  on_conflict?: InputMaybe<Task_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TasksArgs = {
  objects: Array<Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_AssignmentsArgs = {
  objects: Array<Tasks_Assignments_Insert_Input>;
  on_conflict?: InputMaybe<Tasks_Assignments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_Assignments_OneArgs = {
  object: Tasks_Assignments_Insert_Input;
  on_conflict?: InputMaybe<Tasks_Assignments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_FilesArgs = {
  objects: Array<Tasks_Files_Insert_Input>;
  on_conflict?: InputMaybe<Tasks_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_Files_OneArgs = {
  object: Tasks_Files_Insert_Input;
  on_conflict?: InputMaybe<Tasks_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_GroupsArgs = {
  objects: Array<Tasks_Groups_Insert_Input>;
  on_conflict?: InputMaybe<Tasks_Groups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_Groups_OneArgs = {
  object: Tasks_Groups_Insert_Input;
  on_conflict?: InputMaybe<Tasks_Groups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_Groups_StaffArgs = {
  objects: Array<Tasks_Groups_Staff_Insert_Input>;
  on_conflict?: InputMaybe<Tasks_Groups_Staff_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_Groups_Staff_OneArgs = {
  object: Tasks_Groups_Staff_Insert_Input;
  on_conflict?: InputMaybe<Tasks_Groups_Staff_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_NotificationsArgs = {
  objects: Array<Tasks_Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Tasks_Notifications_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_Notifications_OneArgs = {
  object: Tasks_Notifications_Insert_Input;
  on_conflict?: InputMaybe<Tasks_Notifications_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_OneArgs = {
  object: Tasks_Insert_Input;
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_ReadArgs = {
  objects: Array<Tasks_Read_Insert_Input>;
  on_conflict?: InputMaybe<Tasks_Read_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_Read_OneArgs = {
  object: Tasks_Read_Insert_Input;
  on_conflict?: InputMaybe<Tasks_Read_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_WatchArgs = {
  objects: Array<Tasks_Watch_Insert_Input>;
  on_conflict?: InputMaybe<Tasks_Watch_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_Watch_OneArgs = {
  object: Tasks_Watch_Insert_Input;
  on_conflict?: InputMaybe<Tasks_Watch_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Visit_ClassificationsArgs = {
  objects: Array<Visit_Classifications_Insert_Input>;
  on_conflict?: InputMaybe<Visit_Classifications_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Visit_Classifications_OneArgs = {
  object: Visit_Classifications_Insert_Input;
  on_conflict?: InputMaybe<Visit_Classifications_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Visit_TypesArgs = {
  objects: Array<Visit_Types_Insert_Input>;
  on_conflict?: InputMaybe<Visit_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Visit_Types_OneArgs = {
  object: Visit_Types_Insert_Input;
  on_conflict?: InputMaybe<Visit_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_Account_PatientArgs = {
  _set?: InputMaybe<Account_Patient_Set_Input>;
  where: Account_Patient_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Account_Patient_By_PkArgs = {
  _set?: InputMaybe<Account_Patient_Set_Input>;
  pk_columns: Account_Patient_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Account_Patient_ManyArgs = {
  updates: Array<Account_Patient_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Account_Temporary_VerificationArgs = {
  _set?: InputMaybe<Account_Temporary_Verification_Set_Input>;
  where: Account_Temporary_Verification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Account_Temporary_Verification_By_PkArgs = {
  _set?: InputMaybe<Account_Temporary_Verification_Set_Input>;
  pk_columns: Account_Temporary_Verification_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Account_Temporary_Verification_ManyArgs = {
  updates: Array<Account_Temporary_Verification_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AccountsArgs = {
  _set?: InputMaybe<Accounts_Set_Input>;
  where: Accounts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Accounts_By_PkArgs = {
  _set?: InputMaybe<Accounts_Set_Input>;
  pk_columns: Accounts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Accounts_LimitedArgs = {
  _set?: InputMaybe<Accounts_Limited_Set_Input>;
  where: Accounts_Limited_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Accounts_Limited_ManyArgs = {
  updates: Array<Accounts_Limited_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Accounts_ManyArgs = {
  updates: Array<Accounts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AllergiesArgs = {
  _append?: InputMaybe<Allergies_Append_Input>;
  _delete_at_path?: InputMaybe<Allergies_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Allergies_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Allergies_Delete_Key_Input>;
  _prepend?: InputMaybe<Allergies_Prepend_Input>;
  _set?: InputMaybe<Allergies_Set_Input>;
  where: Allergies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Allergies_By_PkArgs = {
  _append?: InputMaybe<Allergies_Append_Input>;
  _delete_at_path?: InputMaybe<Allergies_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Allergies_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Allergies_Delete_Key_Input>;
  _prepend?: InputMaybe<Allergies_Prepend_Input>;
  _set?: InputMaybe<Allergies_Set_Input>;
  pk_columns: Allergies_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Allergies_ManyArgs = {
  updates: Array<Allergies_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Allergies_SeverityArgs = {
  _set?: InputMaybe<Allergies_Severity_Set_Input>;
  where: Allergies_Severity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Allergies_Severity_By_PkArgs = {
  _set?: InputMaybe<Allergies_Severity_Set_Input>;
  pk_columns: Allergies_Severity_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Allergies_Severity_ManyArgs = {
  updates: Array<Allergies_Severity_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Allergies_StatusArgs = {
  _set?: InputMaybe<Allergies_Status_Set_Input>;
  where: Allergies_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Allergies_Status_By_PkArgs = {
  _set?: InputMaybe<Allergies_Status_Set_Input>;
  pk_columns: Allergies_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Allergies_Status_ManyArgs = {
  updates: Array<Allergies_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_BlocksArgs = {
  _set?: InputMaybe<Appointment_Blocks_Set_Input>;
  where: Appointment_Blocks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_Blocks_By_PkArgs = {
  _set?: InputMaybe<Appointment_Blocks_Set_Input>;
  pk_columns: Appointment_Blocks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_Blocks_ManyArgs = {
  updates: Array<Appointment_Blocks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_Blocks_StaffArgs = {
  _inc?: InputMaybe<Appointment_Blocks_Staff_Inc_Input>;
  _set?: InputMaybe<Appointment_Blocks_Staff_Set_Input>;
  where: Appointment_Blocks_Staff_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_Blocks_Staff_By_PkArgs = {
  _inc?: InputMaybe<Appointment_Blocks_Staff_Inc_Input>;
  _set?: InputMaybe<Appointment_Blocks_Staff_Set_Input>;
  pk_columns: Appointment_Blocks_Staff_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_Blocks_Staff_ManyArgs = {
  updates: Array<Appointment_Blocks_Staff_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_PatientArgs = {
  _set?: InputMaybe<Appointment_Patient_Set_Input>;
  where: Appointment_Patient_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_Patient_By_PkArgs = {
  _set?: InputMaybe<Appointment_Patient_Set_Input>;
  pk_columns: Appointment_Patient_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_Patient_ManyArgs = {
  updates: Array<Appointment_Patient_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_PaymentArgs = {
  _set?: InputMaybe<Appointment_Payment_Set_Input>;
  where: Appointment_Payment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_Payment_By_PkArgs = {
  _set?: InputMaybe<Appointment_Payment_Set_Input>;
  pk_columns: Appointment_Payment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_Payment_ManyArgs = {
  updates: Array<Appointment_Payment_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_ReservationsArgs = {
  _set?: InputMaybe<Appointment_Reservations_Set_Input>;
  where: Appointment_Reservations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_Reservations_By_PkArgs = {
  _set?: InputMaybe<Appointment_Reservations_Set_Input>;
  pk_columns: Appointment_Reservations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_Reservations_ManyArgs = {
  updates: Array<Appointment_Reservations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_StaffArgs = {
  _set?: InputMaybe<Appointment_Staff_Set_Input>;
  where: Appointment_Staff_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_Staff_By_PkArgs = {
  _set?: InputMaybe<Appointment_Staff_Set_Input>;
  pk_columns: Appointment_Staff_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_Staff_ManyArgs = {
  updates: Array<Appointment_Staff_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_StatusArgs = {
  _set?: InputMaybe<Appointment_Status_Set_Input>;
  where: Appointment_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_Status_By_PkArgs = {
  _set?: InputMaybe<Appointment_Status_Set_Input>;
  pk_columns: Appointment_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_Status_ManyArgs = {
  updates: Array<Appointment_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_TypesArgs = {
  _set?: InputMaybe<Appointment_Types_Set_Input>;
  where: Appointment_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_Types_By_PkArgs = {
  _set?: InputMaybe<Appointment_Types_Set_Input>;
  pk_columns: Appointment_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Appointment_Types_ManyArgs = {
  updates: Array<Appointment_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AppointmentsArgs = {
  _append?: InputMaybe<Appointments_Append_Input>;
  _delete_at_path?: InputMaybe<Appointments_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Appointments_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Appointments_Delete_Key_Input>;
  _inc?: InputMaybe<Appointments_Inc_Input>;
  _prepend?: InputMaybe<Appointments_Prepend_Input>;
  _set?: InputMaybe<Appointments_Set_Input>;
  where: Appointments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Appointments_By_PkArgs = {
  _append?: InputMaybe<Appointments_Append_Input>;
  _delete_at_path?: InputMaybe<Appointments_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Appointments_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Appointments_Delete_Key_Input>;
  _inc?: InputMaybe<Appointments_Inc_Input>;
  _prepend?: InputMaybe<Appointments_Prepend_Input>;
  _set?: InputMaybe<Appointments_Set_Input>;
  pk_columns: Appointments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Appointments_ManyArgs = {
  updates: Array<Appointments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_LogsArgs = {
  _append?: InputMaybe<Audit_Logs_Append_Input>;
  _delete_at_path?: InputMaybe<Audit_Logs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audit_Logs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audit_Logs_Delete_Key_Input>;
  _inc?: InputMaybe<Audit_Logs_Inc_Input>;
  _prepend?: InputMaybe<Audit_Logs_Prepend_Input>;
  _set?: InputMaybe<Audit_Logs_Set_Input>;
  where: Audit_Logs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_Logs_By_PkArgs = {
  _append?: InputMaybe<Audit_Logs_Append_Input>;
  _delete_at_path?: InputMaybe<Audit_Logs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audit_Logs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audit_Logs_Delete_Key_Input>;
  _inc?: InputMaybe<Audit_Logs_Inc_Input>;
  _prepend?: InputMaybe<Audit_Logs_Prepend_Input>;
  _set?: InputMaybe<Audit_Logs_Set_Input>;
  pk_columns: Audit_Logs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_Logs_ManyArgs = {
  updates: Array<Audit_Logs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Care_Type_DurationArgs = {
  _inc?: InputMaybe<Care_Type_Duration_Inc_Input>;
  _set?: InputMaybe<Care_Type_Duration_Set_Input>;
  where: Care_Type_Duration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Care_Type_Duration_By_PkArgs = {
  _inc?: InputMaybe<Care_Type_Duration_Inc_Input>;
  _set?: InputMaybe<Care_Type_Duration_Set_Input>;
  pk_columns: Care_Type_Duration_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Care_Type_Duration_ManyArgs = {
  updates: Array<Care_Type_Duration_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Care_TypesArgs = {
  _set?: InputMaybe<Care_Types_Set_Input>;
  where: Care_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Care_Types_By_PkArgs = {
  _set?: InputMaybe<Care_Types_Set_Input>;
  pk_columns: Care_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Care_Types_ManyArgs = {
  updates: Array<Care_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Charge_ProductsArgs = {
  _inc?: InputMaybe<Charge_Products_Inc_Input>;
  _set?: InputMaybe<Charge_Products_Set_Input>;
  where: Charge_Products_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Charge_Products_By_PkArgs = {
  _inc?: InputMaybe<Charge_Products_Inc_Input>;
  _set?: InputMaybe<Charge_Products_Set_Input>;
  pk_columns: Charge_Products_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Charge_Products_ManyArgs = {
  updates: Array<Charge_Products_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ChargesArgs = {
  _inc?: InputMaybe<Charges_Inc_Input>;
  _set?: InputMaybe<Charges_Set_Input>;
  where: Charges_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Charges_By_PkArgs = {
  _inc?: InputMaybe<Charges_Inc_Input>;
  _set?: InputMaybe<Charges_Set_Input>;
  pk_columns: Charges_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Charges_ManyArgs = {
  updates: Array<Charges_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_MessagesArgs = {
  _append?: InputMaybe<Chat_Messages_Append_Input>;
  _delete_at_path?: InputMaybe<Chat_Messages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Chat_Messages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Chat_Messages_Delete_Key_Input>;
  _prepend?: InputMaybe<Chat_Messages_Prepend_Input>;
  _set?: InputMaybe<Chat_Messages_Set_Input>;
  where: Chat_Messages_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Messages_By_PkArgs = {
  _append?: InputMaybe<Chat_Messages_Append_Input>;
  _delete_at_path?: InputMaybe<Chat_Messages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Chat_Messages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Chat_Messages_Delete_Key_Input>;
  _prepend?: InputMaybe<Chat_Messages_Prepend_Input>;
  _set?: InputMaybe<Chat_Messages_Set_Input>;
  pk_columns: Chat_Messages_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Messages_FileArgs = {
  _set?: InputMaybe<Chat_Messages_File_Set_Input>;
  where: Chat_Messages_File_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Messages_File_By_PkArgs = {
  _set?: InputMaybe<Chat_Messages_File_Set_Input>;
  pk_columns: Chat_Messages_File_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Messages_File_ManyArgs = {
  updates: Array<Chat_Messages_File_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Messages_ManyArgs = {
  updates: Array<Chat_Messages_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Messages_TypeArgs = {
  _set?: InputMaybe<Chat_Messages_Type_Set_Input>;
  where: Chat_Messages_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Messages_Type_By_PkArgs = {
  _set?: InputMaybe<Chat_Messages_Type_Set_Input>;
  pk_columns: Chat_Messages_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Messages_Type_ManyArgs = {
  updates: Array<Chat_Messages_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_NotesArgs = {
  _set?: InputMaybe<Chat_Notes_Set_Input>;
  where: Chat_Notes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Notes_By_PkArgs = {
  _set?: InputMaybe<Chat_Notes_Set_Input>;
  pk_columns: Chat_Notes_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Notes_ManyArgs = {
  updates: Array<Chat_Notes_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Notes_TypesArgs = {
  _set?: InputMaybe<Chat_Notes_Types_Set_Input>;
  where: Chat_Notes_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Notes_Types_By_PkArgs = {
  _set?: InputMaybe<Chat_Notes_Types_Set_Input>;
  pk_columns: Chat_Notes_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Notes_Types_ManyArgs = {
  updates: Array<Chat_Notes_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_SessionsArgs = {
  _set?: InputMaybe<Chat_Sessions_Set_Input>;
  where: Chat_Sessions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Sessions_AccountsArgs = {
  _set?: InputMaybe<Chat_Sessions_Accounts_Set_Input>;
  where: Chat_Sessions_Accounts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Sessions_Accounts_By_PkArgs = {
  _set?: InputMaybe<Chat_Sessions_Accounts_Set_Input>;
  pk_columns: Chat_Sessions_Accounts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Sessions_Accounts_ManyArgs = {
  updates: Array<Chat_Sessions_Accounts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Sessions_By_PkArgs = {
  _set?: InputMaybe<Chat_Sessions_Set_Input>;
  pk_columns: Chat_Sessions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Sessions_ManyArgs = {
  updates: Array<Chat_Sessions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Sessions_PatientsArgs = {
  _set?: InputMaybe<Chat_Sessions_Patients_Set_Input>;
  where: Chat_Sessions_Patients_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Sessions_Patients_By_PkArgs = {
  _set?: InputMaybe<Chat_Sessions_Patients_Set_Input>;
  pk_columns: Chat_Sessions_Patients_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Sessions_Patients_ManyArgs = {
  updates: Array<Chat_Sessions_Patients_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Sessions_ReadArgs = {
  _set?: InputMaybe<Chat_Sessions_Read_Set_Input>;
  where: Chat_Sessions_Read_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Sessions_Read_By_PkArgs = {
  _set?: InputMaybe<Chat_Sessions_Read_Set_Input>;
  pk_columns: Chat_Sessions_Read_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Sessions_Read_ManyArgs = {
  updates: Array<Chat_Sessions_Read_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Sessions_StaffArgs = {
  _set?: InputMaybe<Chat_Sessions_Staff_Set_Input>;
  where: Chat_Sessions_Staff_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Sessions_Staff_By_PkArgs = {
  _set?: InputMaybe<Chat_Sessions_Staff_Set_Input>;
  pk_columns: Chat_Sessions_Staff_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Sessions_Staff_ManyArgs = {
  updates: Array<Chat_Sessions_Staff_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Sessions_StatusArgs = {
  _set?: InputMaybe<Chat_Sessions_Status_Set_Input>;
  where: Chat_Sessions_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Sessions_Status_By_PkArgs = {
  _set?: InputMaybe<Chat_Sessions_Status_Set_Input>;
  pk_columns: Chat_Sessions_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Chat_Sessions_Status_ManyArgs = {
  updates: Array<Chat_Sessions_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Clinic_Location_TypesArgs = {
  _set?: InputMaybe<Clinic_Location_Types_Set_Input>;
  where: Clinic_Location_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Clinic_Location_Types_By_PkArgs = {
  _set?: InputMaybe<Clinic_Location_Types_Set_Input>;
  pk_columns: Clinic_Location_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Clinic_Location_Types_ManyArgs = {
  updates: Array<Clinic_Location_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Clinic_StatusArgs = {
  _set?: InputMaybe<Clinic_Status_Set_Input>;
  where: Clinic_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Clinic_Status_By_PkArgs = {
  _set?: InputMaybe<Clinic_Status_Set_Input>;
  pk_columns: Clinic_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Clinic_Status_ManyArgs = {
  updates: Array<Clinic_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ClinicsArgs = {
  _inc?: InputMaybe<Clinics_Inc_Input>;
  _set?: InputMaybe<Clinics_Set_Input>;
  where: Clinics_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Clinics_By_PkArgs = {
  _inc?: InputMaybe<Clinics_Inc_Input>;
  _set?: InputMaybe<Clinics_Set_Input>;
  pk_columns: Clinics_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Clinics_ManyArgs = {
  updates: Array<Clinics_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Clinics_PropertiesArgs = {
  _set?: InputMaybe<Clinics_Properties_Set_Input>;
  where: Clinics_Properties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Clinics_Properties_By_PkArgs = {
  _set?: InputMaybe<Clinics_Properties_Set_Input>;
  pk_columns: Clinics_Properties_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Clinics_Properties_ManyArgs = {
  updates: Array<Clinics_Properties_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Clinics_Properties_TypesArgs = {
  _set?: InputMaybe<Clinics_Properties_Types_Set_Input>;
  where: Clinics_Properties_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Clinics_Properties_Types_By_PkArgs = {
  _set?: InputMaybe<Clinics_Properties_Types_Set_Input>;
  pk_columns: Clinics_Properties_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Clinics_Properties_Types_ManyArgs = {
  updates: Array<Clinics_Properties_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Clinics_RegionsArgs = {
  _inc?: InputMaybe<Clinics_Regions_Inc_Input>;
  _set?: InputMaybe<Clinics_Regions_Set_Input>;
  where: Clinics_Regions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Clinics_Regions_By_PkArgs = {
  _inc?: InputMaybe<Clinics_Regions_Inc_Input>;
  _set?: InputMaybe<Clinics_Regions_Set_Input>;
  pk_columns: Clinics_Regions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Clinics_Regions_ManyArgs = {
  updates: Array<Clinics_Regions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Communication_HistoryArgs = {
  _append?: InputMaybe<Communication_History_Append_Input>;
  _delete_at_path?: InputMaybe<Communication_History_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Communication_History_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Communication_History_Delete_Key_Input>;
  _inc?: InputMaybe<Communication_History_Inc_Input>;
  _prepend?: InputMaybe<Communication_History_Prepend_Input>;
  _set?: InputMaybe<Communication_History_Set_Input>;
  where: Communication_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Communication_History_By_PkArgs = {
  _append?: InputMaybe<Communication_History_Append_Input>;
  _delete_at_path?: InputMaybe<Communication_History_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Communication_History_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Communication_History_Delete_Key_Input>;
  _inc?: InputMaybe<Communication_History_Inc_Input>;
  _prepend?: InputMaybe<Communication_History_Prepend_Input>;
  _set?: InputMaybe<Communication_History_Set_Input>;
  pk_columns: Communication_History_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Communication_History_DirectionsArgs = {
  _set?: InputMaybe<Communication_History_Directions_Set_Input>;
  where: Communication_History_Directions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Communication_History_Directions_By_PkArgs = {
  _set?: InputMaybe<Communication_History_Directions_Set_Input>;
  pk_columns: Communication_History_Directions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Communication_History_Directions_ManyArgs = {
  updates: Array<Communication_History_Directions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Communication_History_ManyArgs = {
  updates: Array<Communication_History_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Communication_History_Tag_TypesArgs = {
  _set?: InputMaybe<Communication_History_Tag_Types_Set_Input>;
  where: Communication_History_Tag_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Communication_History_Tag_Types_By_PkArgs = {
  _set?: InputMaybe<Communication_History_Tag_Types_Set_Input>;
  pk_columns: Communication_History_Tag_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Communication_History_Tag_Types_ManyArgs = {
  updates: Array<Communication_History_Tag_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Communication_History_TagsArgs = {
  _set?: InputMaybe<Communication_History_Tags_Set_Input>;
  where: Communication_History_Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Communication_History_Tags_By_PkArgs = {
  _set?: InputMaybe<Communication_History_Tags_Set_Input>;
  pk_columns: Communication_History_Tags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Communication_History_Tags_ManyArgs = {
  updates: Array<Communication_History_Tags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Communication_History_TypesArgs = {
  _set?: InputMaybe<Communication_History_Types_Set_Input>;
  where: Communication_History_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Communication_History_Types_By_PkArgs = {
  _set?: InputMaybe<Communication_History_Types_Set_Input>;
  pk_columns: Communication_History_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Communication_History_Types_ManyArgs = {
  updates: Array<Communication_History_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DevicesArgs = {
  _inc?: InputMaybe<Devices_Inc_Input>;
  _set?: InputMaybe<Devices_Set_Input>;
  where: Devices_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Devices_By_PkArgs = {
  _inc?: InputMaybe<Devices_Inc_Input>;
  _set?: InputMaybe<Devices_Set_Input>;
  pk_columns: Devices_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Devices_ManyArgs = {
  updates: Array<Devices_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Discovered_FromArgs = {
  _set?: InputMaybe<Discovered_From_Set_Input>;
  where: Discovered_From_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Discovered_From_By_PkArgs = {
  _set?: InputMaybe<Discovered_From_Set_Input>;
  pk_columns: Discovered_From_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Discovered_From_ManyArgs = {
  updates: Array<Discovered_From_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Document_Status_TypesArgs = {
  _set?: InputMaybe<Document_Status_Types_Set_Input>;
  where: Document_Status_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Document_Status_Types_By_PkArgs = {
  _set?: InputMaybe<Document_Status_Types_Set_Input>;
  pk_columns: Document_Status_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Document_Status_Types_ManyArgs = {
  updates: Array<Document_Status_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Document_TypesArgs = {
  _set?: InputMaybe<Document_Types_Set_Input>;
  where: Document_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Document_Types_By_PkArgs = {
  _set?: InputMaybe<Document_Types_Set_Input>;
  pk_columns: Document_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Document_Types_ManyArgs = {
  updates: Array<Document_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DocumentsArgs = {
  _inc?: InputMaybe<Documents_Inc_Input>;
  _set?: InputMaybe<Documents_Set_Input>;
  where: Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Documents_By_PkArgs = {
  _inc?: InputMaybe<Documents_Inc_Input>;
  _set?: InputMaybe<Documents_Set_Input>;
  pk_columns: Documents_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Documents_ManyArgs = {
  updates: Array<Documents_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Fhir_Resource_TypesArgs = {
  _set?: InputMaybe<Fhir_Resource_Types_Set_Input>;
  where: Fhir_Resource_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Fhir_Resource_Types_By_PkArgs = {
  _set?: InputMaybe<Fhir_Resource_Types_Set_Input>;
  pk_columns: Fhir_Resource_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Fhir_Resource_Types_ManyArgs = {
  updates: Array<Fhir_Resource_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Fhir_ResourcesArgs = {
  _append?: InputMaybe<Fhir_Resources_Append_Input>;
  _delete_at_path?: InputMaybe<Fhir_Resources_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Fhir_Resources_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Fhir_Resources_Delete_Key_Input>;
  _prepend?: InputMaybe<Fhir_Resources_Prepend_Input>;
  _set?: InputMaybe<Fhir_Resources_Set_Input>;
  where: Fhir_Resources_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Fhir_Resources_By_PkArgs = {
  _append?: InputMaybe<Fhir_Resources_Append_Input>;
  _delete_at_path?: InputMaybe<Fhir_Resources_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Fhir_Resources_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Fhir_Resources_Delete_Key_Input>;
  _prepend?: InputMaybe<Fhir_Resources_Prepend_Input>;
  _set?: InputMaybe<Fhir_Resources_Set_Input>;
  pk_columns: Fhir_Resources_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Fhir_Resources_HistoryArgs = {
  _append?: InputMaybe<Fhir_Resources_History_Append_Input>;
  _delete_at_path?: InputMaybe<Fhir_Resources_History_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Fhir_Resources_History_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Fhir_Resources_History_Delete_Key_Input>;
  _prepend?: InputMaybe<Fhir_Resources_History_Prepend_Input>;
  _set?: InputMaybe<Fhir_Resources_History_Set_Input>;
  where: Fhir_Resources_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Fhir_Resources_History_By_PkArgs = {
  _append?: InputMaybe<Fhir_Resources_History_Append_Input>;
  _delete_at_path?: InputMaybe<Fhir_Resources_History_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Fhir_Resources_History_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Fhir_Resources_History_Delete_Key_Input>;
  _prepend?: InputMaybe<Fhir_Resources_History_Prepend_Input>;
  _set?: InputMaybe<Fhir_Resources_History_Set_Input>;
  pk_columns: Fhir_Resources_History_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Fhir_Resources_History_ManyArgs = {
  updates: Array<Fhir_Resources_History_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Fhir_Resources_ManyArgs = {
  updates: Array<Fhir_Resources_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_File_Document_TypeArgs = {
  _set?: InputMaybe<File_Document_Type_Set_Input>;
  where: File_Document_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_File_Document_Type_By_PkArgs = {
  _set?: InputMaybe<File_Document_Type_Set_Input>;
  pk_columns: File_Document_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_File_Document_Type_ManyArgs = {
  updates: Array<File_Document_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_FilesArgs = {
  _append?: InputMaybe<Files_Append_Input>;
  _delete_at_path?: InputMaybe<Files_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Files_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Files_Delete_Key_Input>;
  _prepend?: InputMaybe<Files_Prepend_Input>;
  _set?: InputMaybe<Files_Set_Input>;
  where: Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Files_By_PkArgs = {
  _append?: InputMaybe<Files_Append_Input>;
  _delete_at_path?: InputMaybe<Files_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Files_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Files_Delete_Key_Input>;
  _prepend?: InputMaybe<Files_Prepend_Input>;
  _set?: InputMaybe<Files_Set_Input>;
  pk_columns: Files_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Files_ManyArgs = {
  updates: Array<Files_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Global_NotesArgs = {
  _set?: InputMaybe<Global_Notes_Set_Input>;
  where: Global_Notes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Global_Notes_By_PkArgs = {
  _set?: InputMaybe<Global_Notes_Set_Input>;
  pk_columns: Global_Notes_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Global_Notes_ManyArgs = {
  updates: Array<Global_Notes_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_InsuranceArgs = {
  _append?: InputMaybe<Insurance_Append_Input>;
  _delete_at_path?: InputMaybe<Insurance_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Insurance_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Insurance_Delete_Key_Input>;
  _prepend?: InputMaybe<Insurance_Prepend_Input>;
  _set?: InputMaybe<Insurance_Set_Input>;
  where: Insurance_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Insurance_By_PkArgs = {
  _append?: InputMaybe<Insurance_Append_Input>;
  _delete_at_path?: InputMaybe<Insurance_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Insurance_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Insurance_Delete_Key_Input>;
  _prepend?: InputMaybe<Insurance_Prepend_Input>;
  _set?: InputMaybe<Insurance_Set_Input>;
  pk_columns: Insurance_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Insurance_ManyArgs = {
  updates: Array<Insurance_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Insurance_ProvidersArgs = {
  _set?: InputMaybe<Insurance_Providers_Set_Input>;
  where: Insurance_Providers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Insurance_Providers_By_PkArgs = {
  _set?: InputMaybe<Insurance_Providers_Set_Input>;
  pk_columns: Insurance_Providers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Insurance_Providers_ManyArgs = {
  updates: Array<Insurance_Providers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Insurance_TypesArgs = {
  _set?: InputMaybe<Insurance_Types_Set_Input>;
  where: Insurance_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Insurance_Types_By_PkArgs = {
  _set?: InputMaybe<Insurance_Types_Set_Input>;
  pk_columns: Insurance_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Insurance_Types_ManyArgs = {
  updates: Array<Insurance_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Kareo_LogsArgs = {
  _append?: InputMaybe<Kareo_Logs_Append_Input>;
  _delete_at_path?: InputMaybe<Kareo_Logs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Kareo_Logs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Kareo_Logs_Delete_Key_Input>;
  _prepend?: InputMaybe<Kareo_Logs_Prepend_Input>;
  _set?: InputMaybe<Kareo_Logs_Set_Input>;
  where: Kareo_Logs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Kareo_Logs_By_PkArgs = {
  _append?: InputMaybe<Kareo_Logs_Append_Input>;
  _delete_at_path?: InputMaybe<Kareo_Logs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Kareo_Logs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Kareo_Logs_Delete_Key_Input>;
  _prepend?: InputMaybe<Kareo_Logs_Prepend_Input>;
  _set?: InputMaybe<Kareo_Logs_Set_Input>;
  pk_columns: Kareo_Logs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Kareo_Logs_ManyArgs = {
  updates: Array<Kareo_Logs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_LocationsArgs = {
  _inc?: InputMaybe<Locations_Inc_Input>;
  _set?: InputMaybe<Locations_Set_Input>;
  where: Locations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Locations_By_PkArgs = {
  _inc?: InputMaybe<Locations_Inc_Input>;
  _set?: InputMaybe<Locations_Set_Input>;
  pk_columns: Locations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Locations_ManyArgs = {
  updates: Array<Locations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Medical_HistoryArgs = {
  _set?: InputMaybe<Medical_History_Set_Input>;
  where: Medical_History_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Medical_History_AnswersArgs = {
  _set?: InputMaybe<Medical_History_Answers_Set_Input>;
  where: Medical_History_Answers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Medical_History_Answers_By_PkArgs = {
  _set?: InputMaybe<Medical_History_Answers_Set_Input>;
  pk_columns: Medical_History_Answers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Medical_History_Answers_ManyArgs = {
  updates: Array<Medical_History_Answers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Medical_History_By_PkArgs = {
  _set?: InputMaybe<Medical_History_Set_Input>;
  pk_columns: Medical_History_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Medical_History_ManyArgs = {
  updates: Array<Medical_History_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Medical_History_Person_TypesArgs = {
  _set?: InputMaybe<Medical_History_Person_Types_Set_Input>;
  where: Medical_History_Person_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Medical_History_Person_Types_By_PkArgs = {
  _set?: InputMaybe<Medical_History_Person_Types_Set_Input>;
  pk_columns: Medical_History_Person_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Medical_History_Person_Types_ManyArgs = {
  updates: Array<Medical_History_Person_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Medical_History_QuestionsArgs = {
  _set?: InputMaybe<Medical_History_Questions_Set_Input>;
  where: Medical_History_Questions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Medical_History_Questions_By_PkArgs = {
  _set?: InputMaybe<Medical_History_Questions_Set_Input>;
  pk_columns: Medical_History_Questions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Medical_History_Questions_ManyArgs = {
  updates: Array<Medical_History_Questions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Medical_History_TypesArgs = {
  _set?: InputMaybe<Medical_History_Types_Set_Input>;
  where: Medical_History_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Medical_History_Types_By_PkArgs = {
  _set?: InputMaybe<Medical_History_Types_Set_Input>;
  pk_columns: Medical_History_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Medical_History_Types_ManyArgs = {
  updates: Array<Medical_History_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Patient_MigrationsArgs = {
  _append?: InputMaybe<Patient_Migrations_Append_Input>;
  _delete_at_path?: InputMaybe<Patient_Migrations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Patient_Migrations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Patient_Migrations_Delete_Key_Input>;
  _inc?: InputMaybe<Patient_Migrations_Inc_Input>;
  _prepend?: InputMaybe<Patient_Migrations_Prepend_Input>;
  _set?: InputMaybe<Patient_Migrations_Set_Input>;
  where: Patient_Migrations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Patient_Migrations_By_PkArgs = {
  _append?: InputMaybe<Patient_Migrations_Append_Input>;
  _delete_at_path?: InputMaybe<Patient_Migrations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Patient_Migrations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Patient_Migrations_Delete_Key_Input>;
  _inc?: InputMaybe<Patient_Migrations_Inc_Input>;
  _prepend?: InputMaybe<Patient_Migrations_Prepend_Input>;
  _set?: InputMaybe<Patient_Migrations_Set_Input>;
  pk_columns: Patient_Migrations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Patient_Migrations_ManyArgs = {
  updates: Array<Patient_Migrations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Patient_Not_DuplicatesArgs = {
  _set?: InputMaybe<Patient_Not_Duplicates_Set_Input>;
  where: Patient_Not_Duplicates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Patient_Not_Duplicates_By_PkArgs = {
  _set?: InputMaybe<Patient_Not_Duplicates_Set_Input>;
  pk_columns: Patient_Not_Duplicates_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Patient_Not_Duplicates_ManyArgs = {
  updates: Array<Patient_Not_Duplicates_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Patient_Record_Status_TypesArgs = {
  _set?: InputMaybe<Patient_Record_Status_Types_Set_Input>;
  where: Patient_Record_Status_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Patient_Record_Status_Types_By_PkArgs = {
  _set?: InputMaybe<Patient_Record_Status_Types_Set_Input>;
  pk_columns: Patient_Record_Status_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Patient_Record_Status_Types_ManyArgs = {
  updates: Array<Patient_Record_Status_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PatientsArgs = {
  _append?: InputMaybe<Patients_Append_Input>;
  _delete_at_path?: InputMaybe<Patients_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Patients_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Patients_Delete_Key_Input>;
  _prepend?: InputMaybe<Patients_Prepend_Input>;
  _set?: InputMaybe<Patients_Set_Input>;
  where: Patients_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Patients_By_PkArgs = {
  _append?: InputMaybe<Patients_Append_Input>;
  _delete_at_path?: InputMaybe<Patients_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Patients_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Patients_Delete_Key_Input>;
  _prepend?: InputMaybe<Patients_Prepend_Input>;
  _set?: InputMaybe<Patients_Set_Input>;
  pk_columns: Patients_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Patients_ManyArgs = {
  updates: Array<Patients_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Payment_TypeArgs = {
  _set?: InputMaybe<Payment_Type_Set_Input>;
  where: Payment_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Payment_Type_By_PkArgs = {
  _set?: InputMaybe<Payment_Type_Set_Input>;
  pk_columns: Payment_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Payment_Type_ManyArgs = {
  updates: Array<Payment_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Phone_NumbersArgs = {
  _set?: InputMaybe<Phone_Numbers_Set_Input>;
  where: Phone_Numbers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Phone_Numbers_By_PkArgs = {
  _set?: InputMaybe<Phone_Numbers_Set_Input>;
  pk_columns: Phone_Numbers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Phone_Numbers_ManyArgs = {
  updates: Array<Phone_Numbers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Problem_Status_TypesArgs = {
  _set?: InputMaybe<Problem_Status_Types_Set_Input>;
  where: Problem_Status_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Problem_Status_Types_By_PkArgs = {
  _set?: InputMaybe<Problem_Status_Types_Set_Input>;
  pk_columns: Problem_Status_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Problem_Status_Types_ManyArgs = {
  updates: Array<Problem_Status_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ProblemsArgs = {
  _set?: InputMaybe<Problems_Set_Input>;
  where: Problems_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Problems_By_PkArgs = {
  _set?: InputMaybe<Problems_Set_Input>;
  pk_columns: Problems_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Problems_ManyArgs = {
  updates: Array<Problems_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ProductsArgs = {
  _inc?: InputMaybe<Products_Inc_Input>;
  _set?: InputMaybe<Products_Set_Input>;
  where: Products_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Products_By_PkArgs = {
  _inc?: InputMaybe<Products_Inc_Input>;
  _set?: InputMaybe<Products_Set_Input>;
  pk_columns: Products_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Products_ManyArgs = {
  updates: Array<Products_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Reconciliation_TypeArgs = {
  _set?: InputMaybe<Reconciliation_Type_Set_Input>;
  where: Reconciliation_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Reconciliation_Type_By_PkArgs = {
  _set?: InputMaybe<Reconciliation_Type_Set_Input>;
  pk_columns: Reconciliation_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Reconciliation_Type_ManyArgs = {
  updates: Array<Reconciliation_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ReconciliationsArgs = {
  _append?: InputMaybe<Reconciliations_Append_Input>;
  _delete_at_path?: InputMaybe<Reconciliations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Reconciliations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Reconciliations_Delete_Key_Input>;
  _prepend?: InputMaybe<Reconciliations_Prepend_Input>;
  _set?: InputMaybe<Reconciliations_Set_Input>;
  where: Reconciliations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Reconciliations_By_PkArgs = {
  _append?: InputMaybe<Reconciliations_Append_Input>;
  _delete_at_path?: InputMaybe<Reconciliations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Reconciliations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Reconciliations_Delete_Key_Input>;
  _prepend?: InputMaybe<Reconciliations_Prepend_Input>;
  _set?: InputMaybe<Reconciliations_Set_Input>;
  pk_columns: Reconciliations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Reconciliations_ManyArgs = {
  updates: Array<Reconciliations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RegionsArgs = {
  _inc?: InputMaybe<Regions_Inc_Input>;
  _set?: InputMaybe<Regions_Set_Input>;
  where: Regions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Regions_By_PkArgs = {
  _inc?: InputMaybe<Regions_Inc_Input>;
  _set?: InputMaybe<Regions_Set_Input>;
  pk_columns: Regions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Regions_ManyArgs = {
  updates: Array<Regions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ReservationsArgs = {
  _set?: InputMaybe<Reservations_Set_Input>;
  where: Reservations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Reservations_ManyArgs = {
  updates: Array<Reservations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ReviewsArgs = {
  _inc?: InputMaybe<Reviews_Inc_Input>;
  _set?: InputMaybe<Reviews_Set_Input>;
  where: Reviews_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Reviews_By_PkArgs = {
  _inc?: InputMaybe<Reviews_Inc_Input>;
  _set?: InputMaybe<Reviews_Set_Input>;
  pk_columns: Reviews_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Reviews_ManyArgs = {
  updates: Array<Reviews_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RoomsArgs = {
  _set?: InputMaybe<Rooms_Set_Input>;
  where: Rooms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rooms_By_PkArgs = {
  _set?: InputMaybe<Rooms_Set_Input>;
  pk_columns: Rooms_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Rooms_ManyArgs = {
  updates: Array<Rooms_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Screening_Category_TypesArgs = {
  _set?: InputMaybe<Screening_Category_Types_Set_Input>;
  where: Screening_Category_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Screening_Category_Types_By_PkArgs = {
  _set?: InputMaybe<Screening_Category_Types_Set_Input>;
  pk_columns: Screening_Category_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Screening_Category_Types_ManyArgs = {
  updates: Array<Screening_Category_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Screening_TypesArgs = {
  _set?: InputMaybe<Screening_Types_Set_Input>;
  where: Screening_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Screening_Types_By_PkArgs = {
  _set?: InputMaybe<Screening_Types_Set_Input>;
  pk_columns: Screening_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Screening_Types_ManyArgs = {
  updates: Array<Screening_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ScreeningsArgs = {
  _inc?: InputMaybe<Screenings_Inc_Input>;
  _set?: InputMaybe<Screenings_Set_Input>;
  where: Screenings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Screenings_By_PkArgs = {
  _inc?: InputMaybe<Screenings_Inc_Input>;
  _set?: InputMaybe<Screenings_Set_Input>;
  pk_columns: Screenings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Screenings_ManyArgs = {
  updates: Array<Screenings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ServicesArgs = {
  _set?: InputMaybe<Services_Set_Input>;
  where: Services_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Services_By_PkArgs = {
  _set?: InputMaybe<Services_Set_Input>;
  pk_columns: Services_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Services_ManyArgs = {
  updates: Array<Services_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Snomed_SynonymsArgs = {
  _set?: InputMaybe<Snomed_Synonyms_Set_Input>;
  where: Snomed_Synonyms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Snomed_Synonyms_By_PkArgs = {
  _set?: InputMaybe<Snomed_Synonyms_Set_Input>;
  pk_columns: Snomed_Synonyms_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Snomed_Synonyms_ManyArgs = {
  updates: Array<Snomed_Synonyms_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_StaffArgs = {
  _append?: InputMaybe<Staff_Append_Input>;
  _delete_at_path?: InputMaybe<Staff_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Staff_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Staff_Delete_Key_Input>;
  _prepend?: InputMaybe<Staff_Prepend_Input>;
  _set?: InputMaybe<Staff_Set_Input>;
  where: Staff_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Staff_By_PkArgs = {
  _append?: InputMaybe<Staff_Append_Input>;
  _delete_at_path?: InputMaybe<Staff_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Staff_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Staff_Delete_Key_Input>;
  _prepend?: InputMaybe<Staff_Prepend_Input>;
  _set?: InputMaybe<Staff_Set_Input>;
  pk_columns: Staff_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Staff_ManyArgs = {
  updates: Array<Staff_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Staff_RegionsArgs = {
  _inc?: InputMaybe<Staff_Regions_Inc_Input>;
  _set?: InputMaybe<Staff_Regions_Set_Input>;
  where: Staff_Regions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Staff_Regions_By_PkArgs = {
  _inc?: InputMaybe<Staff_Regions_Inc_Input>;
  _set?: InputMaybe<Staff_Regions_Set_Input>;
  pk_columns: Staff_Regions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Staff_Regions_ManyArgs = {
  updates: Array<Staff_Regions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Staff_ScheduleArgs = {
  _set?: InputMaybe<Staff_Schedule_Set_Input>;
  where: Staff_Schedule_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Staff_Schedule_By_PkArgs = {
  _set?: InputMaybe<Staff_Schedule_Set_Input>;
  pk_columns: Staff_Schedule_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Staff_Schedule_ManyArgs = {
  updates: Array<Staff_Schedule_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Staff_Schedule_ServiceArgs = {
  _set?: InputMaybe<Staff_Schedule_Service_Set_Input>;
  where: Staff_Schedule_Service_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Staff_Schedule_Service_By_PkArgs = {
  _set?: InputMaybe<Staff_Schedule_Service_Set_Input>;
  pk_columns: Staff_Schedule_Service_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Staff_Schedule_Service_ManyArgs = {
  updates: Array<Staff_Schedule_Service_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Staff_ServicesArgs = {
  _set?: InputMaybe<Staff_Services_Set_Input>;
  where: Staff_Services_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Staff_Services_By_PkArgs = {
  _set?: InputMaybe<Staff_Services_Set_Input>;
  pk_columns: Staff_Services_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Staff_Services_ManyArgs = {
  updates: Array<Staff_Services_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Staff_TypeArgs = {
  _set?: InputMaybe<Staff_Type_Set_Input>;
  where: Staff_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Staff_Type_By_PkArgs = {
  _set?: InputMaybe<Staff_Type_Set_Input>;
  pk_columns: Staff_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Staff_Type_ManyArgs = {
  updates: Array<Staff_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Symptom_CheckerArgs = {
  _append?: InputMaybe<Symptom_Checker_Append_Input>;
  _delete_at_path?: InputMaybe<Symptom_Checker_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Symptom_Checker_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Symptom_Checker_Delete_Key_Input>;
  _prepend?: InputMaybe<Symptom_Checker_Prepend_Input>;
  _set?: InputMaybe<Symptom_Checker_Set_Input>;
  where: Symptom_Checker_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Symptom_Checker_By_PkArgs = {
  _append?: InputMaybe<Symptom_Checker_Append_Input>;
  _delete_at_path?: InputMaybe<Symptom_Checker_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Symptom_Checker_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Symptom_Checker_Delete_Key_Input>;
  _prepend?: InputMaybe<Symptom_Checker_Prepend_Input>;
  _set?: InputMaybe<Symptom_Checker_Set_Input>;
  pk_columns: Symptom_Checker_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Symptom_Checker_ManyArgs = {
  updates: Array<Symptom_Checker_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_PriorityArgs = {
  _set?: InputMaybe<Task_Priority_Set_Input>;
  where: Task_Priority_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Priority_By_PkArgs = {
  _set?: InputMaybe<Task_Priority_Set_Input>;
  pk_columns: Task_Priority_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Priority_ManyArgs = {
  updates: Array<Task_Priority_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_StatusArgs = {
  _set?: InputMaybe<Task_Status_Set_Input>;
  where: Task_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Status_By_PkArgs = {
  _set?: InputMaybe<Task_Status_Set_Input>;
  pk_columns: Task_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Status_ManyArgs = {
  updates: Array<Task_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Sub_TypeArgs = {
  _set?: InputMaybe<Task_Sub_Type_Set_Input>;
  where: Task_Sub_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Sub_Type_By_PkArgs = {
  _set?: InputMaybe<Task_Sub_Type_Set_Input>;
  pk_columns: Task_Sub_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Sub_Type_ManyArgs = {
  updates: Array<Task_Sub_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_TypeArgs = {
  _set?: InputMaybe<Task_Type_Set_Input>;
  where: Task_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Type_By_PkArgs = {
  _set?: InputMaybe<Task_Type_Set_Input>;
  pk_columns: Task_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Type_ManyArgs = {
  updates: Array<Task_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TasksArgs = {
  _set?: InputMaybe<Tasks_Set_Input>;
  where: Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_AssignmentsArgs = {
  _inc?: InputMaybe<Tasks_Assignments_Inc_Input>;
  _set?: InputMaybe<Tasks_Assignments_Set_Input>;
  where: Tasks_Assignments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Assignments_By_PkArgs = {
  _inc?: InputMaybe<Tasks_Assignments_Inc_Input>;
  _set?: InputMaybe<Tasks_Assignments_Set_Input>;
  pk_columns: Tasks_Assignments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Assignments_ManyArgs = {
  updates: Array<Tasks_Assignments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_By_PkArgs = {
  _set?: InputMaybe<Tasks_Set_Input>;
  pk_columns: Tasks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_FilesArgs = {
  _inc?: InputMaybe<Tasks_Files_Inc_Input>;
  _set?: InputMaybe<Tasks_Files_Set_Input>;
  where: Tasks_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Files_By_PkArgs = {
  _inc?: InputMaybe<Tasks_Files_Inc_Input>;
  _set?: InputMaybe<Tasks_Files_Set_Input>;
  pk_columns: Tasks_Files_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Files_ManyArgs = {
  updates: Array<Tasks_Files_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_GroupsArgs = {
  _set?: InputMaybe<Tasks_Groups_Set_Input>;
  where: Tasks_Groups_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Groups_By_PkArgs = {
  _set?: InputMaybe<Tasks_Groups_Set_Input>;
  pk_columns: Tasks_Groups_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Groups_ManyArgs = {
  updates: Array<Tasks_Groups_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Groups_StaffArgs = {
  _inc?: InputMaybe<Tasks_Groups_Staff_Inc_Input>;
  _set?: InputMaybe<Tasks_Groups_Staff_Set_Input>;
  where: Tasks_Groups_Staff_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Groups_Staff_By_PkArgs = {
  _inc?: InputMaybe<Tasks_Groups_Staff_Inc_Input>;
  _set?: InputMaybe<Tasks_Groups_Staff_Set_Input>;
  pk_columns: Tasks_Groups_Staff_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Groups_Staff_ManyArgs = {
  updates: Array<Tasks_Groups_Staff_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_ManyArgs = {
  updates: Array<Tasks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_NotificationsArgs = {
  _inc?: InputMaybe<Tasks_Notifications_Inc_Input>;
  _set?: InputMaybe<Tasks_Notifications_Set_Input>;
  where: Tasks_Notifications_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Notifications_By_PkArgs = {
  _inc?: InputMaybe<Tasks_Notifications_Inc_Input>;
  _set?: InputMaybe<Tasks_Notifications_Set_Input>;
  pk_columns: Tasks_Notifications_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Notifications_ManyArgs = {
  updates: Array<Tasks_Notifications_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_ReadArgs = {
  _inc?: InputMaybe<Tasks_Read_Inc_Input>;
  _set?: InputMaybe<Tasks_Read_Set_Input>;
  where: Tasks_Read_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Read_By_PkArgs = {
  _inc?: InputMaybe<Tasks_Read_Inc_Input>;
  _set?: InputMaybe<Tasks_Read_Set_Input>;
  pk_columns: Tasks_Read_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Read_ManyArgs = {
  updates: Array<Tasks_Read_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_WatchArgs = {
  _inc?: InputMaybe<Tasks_Watch_Inc_Input>;
  _set?: InputMaybe<Tasks_Watch_Set_Input>;
  where: Tasks_Watch_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Watch_By_PkArgs = {
  _inc?: InputMaybe<Tasks_Watch_Inc_Input>;
  _set?: InputMaybe<Tasks_Watch_Set_Input>;
  pk_columns: Tasks_Watch_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Watch_ManyArgs = {
  updates: Array<Tasks_Watch_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Visit_ClassificationsArgs = {
  _set?: InputMaybe<Visit_Classifications_Set_Input>;
  where: Visit_Classifications_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Visit_Classifications_By_PkArgs = {
  _set?: InputMaybe<Visit_Classifications_Set_Input>;
  pk_columns: Visit_Classifications_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Visit_Classifications_ManyArgs = {
  updates: Array<Visit_Classifications_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Visit_TypesArgs = {
  _set?: InputMaybe<Visit_Types_Set_Input>;
  where: Visit_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Visit_Types_By_PkArgs = {
  _set?: InputMaybe<Visit_Types_Set_Input>;
  pk_columns: Visit_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Visit_Types_ManyArgs = {
  updates: Array<Visit_Types_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export type Order_By =
  /** in ascending order, nulls last */
  | 'asc'
  /** in ascending order, nulls first */
  | 'asc_nulls_first'
  /** in ascending order, nulls last */
  | 'asc_nulls_last'
  /** in descending order, nulls first */
  | 'desc'
  /** in descending order, nulls first */
  | 'desc_nulls_first'
  /** in descending order, nulls last */
  | 'desc_nulls_last';

/** columns and relationships of "patient_address" */
export type Patient_Address = {
  __typename?: 'patient_address';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  num?: Maybe<Scalars['String']>;
  /** An object relationship */
  patient?: Maybe<Patients>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  zipplus?: Maybe<Scalars['String']>;
};

/** aggregated selection of "patient_address" */
export type Patient_Address_Aggregate = {
  __typename?: 'patient_address_aggregate';
  aggregate?: Maybe<Patient_Address_Aggregate_Fields>;
  nodes: Array<Patient_Address>;
};

/** aggregate fields of "patient_address" */
export type Patient_Address_Aggregate_Fields = {
  __typename?: 'patient_address_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Patient_Address_Max_Fields>;
  min?: Maybe<Patient_Address_Min_Fields>;
};

/** aggregate fields of "patient_address" */
export type Patient_Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patient_Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "patient_address". All fields are combined with a logical 'AND'. */
export type Patient_Address_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Address_Bool_Exp>>;
  _not?: InputMaybe<Patient_Address_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Address_Bool_Exp>>;
  address1?: InputMaybe<String_Comparison_Exp>;
  address2?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  fullAddress?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  num?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  street?: InputMaybe<String_Comparison_Exp>;
  street2?: InputMaybe<String_Comparison_Exp>;
  zip?: InputMaybe<String_Comparison_Exp>;
  zipplus?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "patient_address" */
export type Patient_Address_Insert_Input = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  fullAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  num?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
  zipplus?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Patient_Address_Max_Fields = {
  __typename?: 'patient_address_max_fields';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  num?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  zipplus?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Patient_Address_Min_Fields = {
  __typename?: 'patient_address_min_fields';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  num?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  zipplus?: Maybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "patient_address" */
export type Patient_Address_Obj_Rel_Insert_Input = {
  data: Patient_Address_Insert_Input;
};

/** Ordering options when selecting data from "patient_address". */
export type Patient_Address_Order_By = {
  address1?: InputMaybe<Order_By>;
  address2?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  fullAddress?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  num?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  state?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  zip?: InputMaybe<Order_By>;
  zipplus?: InputMaybe<Order_By>;
};

/** select columns of table "patient_address" */
export type Patient_Address_Select_Column =
  /** column name */
  | 'address1'
  /** column name */
  | 'address2'
  /** column name */
  | 'city'
  /** column name */
  | 'country'
  /** column name */
  | 'fullAddress'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'state'
  /** column name */
  | 'street'
  /** column name */
  | 'street2'
  /** column name */
  | 'zip'
  /** column name */
  | 'zipplus';

/** Streaming cursor of the table "patient_address" */
export type Patient_Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Address_Stream_Cursor_Value_Input = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  fullAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  num?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
  zipplus?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "patient_duplicates" */
export type Patient_Duplicates = {
  __typename?: 'patient_duplicates';
  additionalInfo?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['jsonb']>;
  avatarId?: Maybe<Scalars['String']>;
  consentDocumentId?: Maybe<Scalars['String']>;
  consentSigned?: Maybe<Scalars['Boolean']>;
  consentSignedAt?: Maybe<Scalars['timestamptz']>;
  consentSignedByUserId?: Maybe<Scalars['uuid']>;
  consentToTelemedicineTermsByAccountId?: Maybe<Scalars['uuid']>;
  consentToTelemedicineTermsTimestamp?: Maybe<Scalars['timestamptz']>;
  count?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  firstName?: Maybe<Scalars['String']>;
  genderAtBirth?: Maybe<Scalars['String']>;
  goesByName?: Maybe<Scalars['String']>;
  hasPCP?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  lastWellVisitStatus?: Maybe<Scalars['String']>;
  migratedToPatientId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  patient?: Maybe<Patients>;
  physicianName?: Maybe<Scalars['String']>;
  preferredPharmacy?: Maybe<Scalars['jsonb']>;
  preferredPronoun?: Maybe<Scalars['String']>;
  preferredPronounType?: Maybe<Scalars['String']>;
  primaryCareStaffId?: Maybe<Scalars['uuid']>;
  primaryClinicAddress?: Maybe<Scalars['jsonb']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vaccinationStatus?: Maybe<Scalars['String']>;
};

/** columns and relationships of "patient_duplicates" */
export type Patient_DuplicatesAddressArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "patient_duplicates" */
export type Patient_DuplicatesPreferredPharmacyArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "patient_duplicates" */
export type Patient_DuplicatesPrimaryClinicAddressArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "patient_duplicates" */
export type Patient_Duplicates_Aggregate = {
  __typename?: 'patient_duplicates_aggregate';
  aggregate?: Maybe<Patient_Duplicates_Aggregate_Fields>;
  nodes: Array<Patient_Duplicates>;
};

/** aggregate fields of "patient_duplicates" */
export type Patient_Duplicates_Aggregate_Fields = {
  __typename?: 'patient_duplicates_aggregate_fields';
  avg?: Maybe<Patient_Duplicates_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Patient_Duplicates_Max_Fields>;
  min?: Maybe<Patient_Duplicates_Min_Fields>;
  stddev?: Maybe<Patient_Duplicates_Stddev_Fields>;
  stddev_pop?: Maybe<Patient_Duplicates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Patient_Duplicates_Stddev_Samp_Fields>;
  sum?: Maybe<Patient_Duplicates_Sum_Fields>;
  var_pop?: Maybe<Patient_Duplicates_Var_Pop_Fields>;
  var_samp?: Maybe<Patient_Duplicates_Var_Samp_Fields>;
  variance?: Maybe<Patient_Duplicates_Variance_Fields>;
};

/** aggregate fields of "patient_duplicates" */
export type Patient_Duplicates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patient_Duplicates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Patient_Duplicates_Avg_Fields = {
  __typename?: 'patient_duplicates_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "patient_duplicates". All fields are combined with a logical 'AND'. */
export type Patient_Duplicates_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Duplicates_Bool_Exp>>;
  _not?: InputMaybe<Patient_Duplicates_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Duplicates_Bool_Exp>>;
  additionalInfo?: InputMaybe<String_Comparison_Exp>;
  address?: InputMaybe<Jsonb_Comparison_Exp>;
  avatarId?: InputMaybe<String_Comparison_Exp>;
  consentDocumentId?: InputMaybe<String_Comparison_Exp>;
  consentSigned?: InputMaybe<Boolean_Comparison_Exp>;
  consentSignedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  consentSignedByUserId?: InputMaybe<Uuid_Comparison_Exp>;
  consentToTelemedicineTermsByAccountId?: InputMaybe<Uuid_Comparison_Exp>;
  consentToTelemedicineTermsTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdByUserId?: InputMaybe<Uuid_Comparison_Exp>;
  dateOfBirth?: InputMaybe<Date_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  genderAtBirth?: InputMaybe<String_Comparison_Exp>;
  goesByName?: InputMaybe<String_Comparison_Exp>;
  hasPCP?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  lastWellVisitStatus?: InputMaybe<String_Comparison_Exp>;
  migratedToPatientId?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  physicianName?: InputMaybe<String_Comparison_Exp>;
  preferredPharmacy?: InputMaybe<Jsonb_Comparison_Exp>;
  preferredPronoun?: InputMaybe<String_Comparison_Exp>;
  preferredPronounType?: InputMaybe<String_Comparison_Exp>;
  primaryCareStaffId?: InputMaybe<Uuid_Comparison_Exp>;
  primaryClinicAddress?: InputMaybe<Jsonb_Comparison_Exp>;
  stripeSubscriptionId?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vaccinationStatus?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Patient_Duplicates_Max_Fields = {
  __typename?: 'patient_duplicates_max_fields';
  additionalInfo?: Maybe<Scalars['String']>;
  avatarId?: Maybe<Scalars['String']>;
  consentDocumentId?: Maybe<Scalars['String']>;
  consentSignedAt?: Maybe<Scalars['timestamptz']>;
  consentSignedByUserId?: Maybe<Scalars['uuid']>;
  consentToTelemedicineTermsByAccountId?: Maybe<Scalars['uuid']>;
  consentToTelemedicineTermsTimestamp?: Maybe<Scalars['timestamptz']>;
  count?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  firstName?: Maybe<Scalars['String']>;
  genderAtBirth?: Maybe<Scalars['String']>;
  goesByName?: Maybe<Scalars['String']>;
  hasPCP?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  lastWellVisitStatus?: Maybe<Scalars['String']>;
  migratedToPatientId?: Maybe<Scalars['uuid']>;
  physicianName?: Maybe<Scalars['String']>;
  preferredPronoun?: Maybe<Scalars['String']>;
  preferredPronounType?: Maybe<Scalars['String']>;
  primaryCareStaffId?: Maybe<Scalars['uuid']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vaccinationStatus?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Patient_Duplicates_Min_Fields = {
  __typename?: 'patient_duplicates_min_fields';
  additionalInfo?: Maybe<Scalars['String']>;
  avatarId?: Maybe<Scalars['String']>;
  consentDocumentId?: Maybe<Scalars['String']>;
  consentSignedAt?: Maybe<Scalars['timestamptz']>;
  consentSignedByUserId?: Maybe<Scalars['uuid']>;
  consentToTelemedicineTermsByAccountId?: Maybe<Scalars['uuid']>;
  consentToTelemedicineTermsTimestamp?: Maybe<Scalars['timestamptz']>;
  count?: Maybe<Scalars['bigint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  firstName?: Maybe<Scalars['String']>;
  genderAtBirth?: Maybe<Scalars['String']>;
  goesByName?: Maybe<Scalars['String']>;
  hasPCP?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  lastWellVisitStatus?: Maybe<Scalars['String']>;
  migratedToPatientId?: Maybe<Scalars['uuid']>;
  physicianName?: Maybe<Scalars['String']>;
  preferredPronoun?: Maybe<Scalars['String']>;
  preferredPronounType?: Maybe<Scalars['String']>;
  primaryCareStaffId?: Maybe<Scalars['uuid']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vaccinationStatus?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "patient_duplicates". */
export type Patient_Duplicates_Order_By = {
  additionalInfo?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  avatarId?: InputMaybe<Order_By>;
  consentDocumentId?: InputMaybe<Order_By>;
  consentSigned?: InputMaybe<Order_By>;
  consentSignedAt?: InputMaybe<Order_By>;
  consentSignedByUserId?: InputMaybe<Order_By>;
  consentToTelemedicineTermsByAccountId?: InputMaybe<Order_By>;
  consentToTelemedicineTermsTimestamp?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdByUserId?: InputMaybe<Order_By>;
  dateOfBirth?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  genderAtBirth?: InputMaybe<Order_By>;
  goesByName?: InputMaybe<Order_By>;
  hasPCP?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  lastWellVisitStatus?: InputMaybe<Order_By>;
  migratedToPatientId?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  physicianName?: InputMaybe<Order_By>;
  preferredPharmacy?: InputMaybe<Order_By>;
  preferredPronoun?: InputMaybe<Order_By>;
  preferredPronounType?: InputMaybe<Order_By>;
  primaryCareStaffId?: InputMaybe<Order_By>;
  primaryClinicAddress?: InputMaybe<Order_By>;
  stripeSubscriptionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vaccinationStatus?: InputMaybe<Order_By>;
};

/** select columns of table "patient_duplicates" */
export type Patient_Duplicates_Select_Column =
  /** column name */
  | 'additionalInfo'
  /** column name */
  | 'address'
  /** column name */
  | 'avatarId'
  /** column name */
  | 'consentDocumentId'
  /** column name */
  | 'consentSigned'
  /** column name */
  | 'consentSignedAt'
  /** column name */
  | 'consentSignedByUserId'
  /** column name */
  | 'consentToTelemedicineTermsByAccountId'
  /** column name */
  | 'consentToTelemedicineTermsTimestamp'
  /** column name */
  | 'count'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdByUserId'
  /** column name */
  | 'dateOfBirth'
  /** column name */
  | 'firstName'
  /** column name */
  | 'genderAtBirth'
  /** column name */
  | 'goesByName'
  /** column name */
  | 'hasPCP'
  /** column name */
  | 'id'
  /** column name */
  | 'lastName'
  /** column name */
  | 'lastWellVisitStatus'
  /** column name */
  | 'migratedToPatientId'
  /** column name */
  | 'physicianName'
  /** column name */
  | 'preferredPharmacy'
  /** column name */
  | 'preferredPronoun'
  /** column name */
  | 'preferredPronounType'
  /** column name */
  | 'primaryCareStaffId'
  /** column name */
  | 'primaryClinicAddress'
  /** column name */
  | 'stripeSubscriptionId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'vaccinationStatus';

/** aggregate stddev on columns */
export type Patient_Duplicates_Stddev_Fields = {
  __typename?: 'patient_duplicates_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Patient_Duplicates_Stddev_Pop_Fields = {
  __typename?: 'patient_duplicates_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Patient_Duplicates_Stddev_Samp_Fields = {
  __typename?: 'patient_duplicates_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "patient_duplicates" */
export type Patient_Duplicates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Duplicates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Duplicates_Stream_Cursor_Value_Input = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['jsonb']>;
  avatarId?: InputMaybe<Scalars['String']>;
  consentDocumentId?: InputMaybe<Scalars['String']>;
  consentSigned?: InputMaybe<Scalars['Boolean']>;
  consentSignedAt?: InputMaybe<Scalars['timestamptz']>;
  consentSignedByUserId?: InputMaybe<Scalars['uuid']>;
  consentToTelemedicineTermsByAccountId?: InputMaybe<Scalars['uuid']>;
  consentToTelemedicineTermsTimestamp?: InputMaybe<Scalars['timestamptz']>;
  count?: InputMaybe<Scalars['bigint']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  dateOfBirth?: InputMaybe<Scalars['date']>;
  firstName?: InputMaybe<Scalars['String']>;
  genderAtBirth?: InputMaybe<Scalars['String']>;
  goesByName?: InputMaybe<Scalars['String']>;
  hasPCP?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastWellVisitStatus?: InputMaybe<Scalars['String']>;
  migratedToPatientId?: InputMaybe<Scalars['uuid']>;
  physicianName?: InputMaybe<Scalars['String']>;
  preferredPharmacy?: InputMaybe<Scalars['jsonb']>;
  preferredPronoun?: InputMaybe<Scalars['String']>;
  preferredPronounType?: InputMaybe<Scalars['String']>;
  primaryCareStaffId?: InputMaybe<Scalars['uuid']>;
  primaryClinicAddress?: InputMaybe<Scalars['jsonb']>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vaccinationStatus?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Patient_Duplicates_Sum_Fields = {
  __typename?: 'patient_duplicates_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Patient_Duplicates_Var_Pop_Fields = {
  __typename?: 'patient_duplicates_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Patient_Duplicates_Var_Samp_Fields = {
  __typename?: 'patient_duplicates_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Patient_Duplicates_Variance_Fields = {
  __typename?: 'patient_duplicates_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "patient_migrations" */
export type Patient_Migrations = {
  __typename?: 'patient_migrations';
  created_at: Scalars['timestamptz'];
  data: Scalars['jsonb'];
  from_patient_id: Scalars['uuid'];
  id: Scalars['bigint'];
  migratedBy: Scalars['String'];
  /** An object relationship */
  staff?: Maybe<Staff>;
  staffId?: Maybe<Scalars['uuid']>;
  to_patient_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "patient_migrations" */
export type Patient_MigrationsDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "patient_migrations" */
export type Patient_Migrations_Aggregate = {
  __typename?: 'patient_migrations_aggregate';
  aggregate?: Maybe<Patient_Migrations_Aggregate_Fields>;
  nodes: Array<Patient_Migrations>;
};

export type Patient_Migrations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Patient_Migrations_Aggregate_Bool_Exp_Count>;
};

export type Patient_Migrations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Patient_Migrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patient_Migrations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "patient_migrations" */
export type Patient_Migrations_Aggregate_Fields = {
  __typename?: 'patient_migrations_aggregate_fields';
  avg?: Maybe<Patient_Migrations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Patient_Migrations_Max_Fields>;
  min?: Maybe<Patient_Migrations_Min_Fields>;
  stddev?: Maybe<Patient_Migrations_Stddev_Fields>;
  stddev_pop?: Maybe<Patient_Migrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Patient_Migrations_Stddev_Samp_Fields>;
  sum?: Maybe<Patient_Migrations_Sum_Fields>;
  var_pop?: Maybe<Patient_Migrations_Var_Pop_Fields>;
  var_samp?: Maybe<Patient_Migrations_Var_Samp_Fields>;
  variance?: Maybe<Patient_Migrations_Variance_Fields>;
};

/** aggregate fields of "patient_migrations" */
export type Patient_Migrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patient_Migrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patient_migrations" */
export type Patient_Migrations_Aggregate_Order_By = {
  avg?: InputMaybe<Patient_Migrations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patient_Migrations_Max_Order_By>;
  min?: InputMaybe<Patient_Migrations_Min_Order_By>;
  stddev?: InputMaybe<Patient_Migrations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Patient_Migrations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Patient_Migrations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Patient_Migrations_Sum_Order_By>;
  var_pop?: InputMaybe<Patient_Migrations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Patient_Migrations_Var_Samp_Order_By>;
  variance?: InputMaybe<Patient_Migrations_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Patient_Migrations_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "patient_migrations" */
export type Patient_Migrations_Arr_Rel_Insert_Input = {
  data: Array<Patient_Migrations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Migrations_On_Conflict>;
};

/** aggregate avg on columns */
export type Patient_Migrations_Avg_Fields = {
  __typename?: 'patient_migrations_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "patient_migrations" */
export type Patient_Migrations_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "patient_migrations". All fields are combined with a logical 'AND'. */
export type Patient_Migrations_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Migrations_Bool_Exp>>;
  _not?: InputMaybe<Patient_Migrations_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Migrations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  from_patient_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  migratedBy?: InputMaybe<String_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  to_patient_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_migrations" */
export type Patient_Migrations_Constraint =
  /** unique or primary key constraint on columns "id" */
  'patient_migrations_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Patient_Migrations_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Patient_Migrations_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Patient_Migrations_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "patient_migrations" */
export type Patient_Migrations_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "patient_migrations" */
export type Patient_Migrations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  from_patient_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['bigint']>;
  migratedBy?: InputMaybe<Scalars['String']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  to_patient_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Patient_Migrations_Max_Fields = {
  __typename?: 'patient_migrations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  from_patient_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  migratedBy?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['uuid']>;
  to_patient_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "patient_migrations" */
export type Patient_Migrations_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  from_patient_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  migratedBy?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  to_patient_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Patient_Migrations_Min_Fields = {
  __typename?: 'patient_migrations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  from_patient_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  migratedBy?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['uuid']>;
  to_patient_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "patient_migrations" */
export type Patient_Migrations_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  from_patient_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  migratedBy?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  to_patient_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patient_migrations" */
export type Patient_Migrations_Mutation_Response = {
  __typename?: 'patient_migrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patient_Migrations>;
};

/** on_conflict condition type for table "patient_migrations" */
export type Patient_Migrations_On_Conflict = {
  constraint: Patient_Migrations_Constraint;
  update_columns?: Array<Patient_Migrations_Update_Column>;
  where?: InputMaybe<Patient_Migrations_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_migrations". */
export type Patient_Migrations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  from_patient_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  migratedBy?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  to_patient_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient_migrations */
export type Patient_Migrations_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Patient_Migrations_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "patient_migrations" */
export type Patient_Migrations_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'data'
  /** column name */
  | 'from_patient_id'
  /** column name */
  | 'id'
  /** column name */
  | 'migratedBy'
  /** column name */
  | 'staffId'
  /** column name */
  | 'to_patient_id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "patient_migrations" */
export type Patient_Migrations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  from_patient_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['bigint']>;
  migratedBy?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  to_patient_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Patient_Migrations_Stddev_Fields = {
  __typename?: 'patient_migrations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "patient_migrations" */
export type Patient_Migrations_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Patient_Migrations_Stddev_Pop_Fields = {
  __typename?: 'patient_migrations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "patient_migrations" */
export type Patient_Migrations_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Patient_Migrations_Stddev_Samp_Fields = {
  __typename?: 'patient_migrations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "patient_migrations" */
export type Patient_Migrations_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "patient_migrations" */
export type Patient_Migrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Migrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Migrations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  from_patient_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['bigint']>;
  migratedBy?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  to_patient_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Patient_Migrations_Sum_Fields = {
  __typename?: 'patient_migrations_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "patient_migrations" */
export type Patient_Migrations_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "patient_migrations" */
export type Patient_Migrations_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'data'
  /** column name */
  | 'from_patient_id'
  /** column name */
  | 'id'
  /** column name */
  | 'migratedBy'
  /** column name */
  | 'staffId'
  /** column name */
  | 'to_patient_id'
  /** column name */
  | 'updated_at';

export type Patient_Migrations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Patient_Migrations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Patient_Migrations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Patient_Migrations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Patient_Migrations_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Patient_Migrations_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Patient_Migrations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Migrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Migrations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Patient_Migrations_Var_Pop_Fields = {
  __typename?: 'patient_migrations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "patient_migrations" */
export type Patient_Migrations_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Patient_Migrations_Var_Samp_Fields = {
  __typename?: 'patient_migrations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "patient_migrations" */
export type Patient_Migrations_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Patient_Migrations_Variance_Fields = {
  __typename?: 'patient_migrations_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "patient_migrations" */
export type Patient_Migrations_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "patient_not_duplicates" */
export type Patient_Not_Duplicates = {
  __typename?: 'patient_not_duplicates';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  patient: Patients;
  patient1_id: Scalars['uuid'];
  patient2_id: Scalars['uuid'];
  /** An object relationship */
  patientByPatient1Id: Patients;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "patient_not_duplicates" */
export type Patient_Not_Duplicates_Aggregate = {
  __typename?: 'patient_not_duplicates_aggregate';
  aggregate?: Maybe<Patient_Not_Duplicates_Aggregate_Fields>;
  nodes: Array<Patient_Not_Duplicates>;
};

export type Patient_Not_Duplicates_Aggregate_Bool_Exp = {
  count?: InputMaybe<Patient_Not_Duplicates_Aggregate_Bool_Exp_Count>;
};

export type Patient_Not_Duplicates_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Patient_Not_Duplicates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patient_Not_Duplicates_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "patient_not_duplicates" */
export type Patient_Not_Duplicates_Aggregate_Fields = {
  __typename?: 'patient_not_duplicates_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Patient_Not_Duplicates_Max_Fields>;
  min?: Maybe<Patient_Not_Duplicates_Min_Fields>;
};

/** aggregate fields of "patient_not_duplicates" */
export type Patient_Not_Duplicates_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patient_Not_Duplicates_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patient_not_duplicates" */
export type Patient_Not_Duplicates_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patient_Not_Duplicates_Max_Order_By>;
  min?: InputMaybe<Patient_Not_Duplicates_Min_Order_By>;
};

/** input type for inserting array relation for remote table "patient_not_duplicates" */
export type Patient_Not_Duplicates_Arr_Rel_Insert_Input = {
  data: Array<Patient_Not_Duplicates_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Not_Duplicates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "patient_not_duplicates". All fields are combined with a logical 'AND'. */
export type Patient_Not_Duplicates_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Not_Duplicates_Bool_Exp>>;
  _not?: InputMaybe<Patient_Not_Duplicates_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Not_Duplicates_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patient1_id?: InputMaybe<Uuid_Comparison_Exp>;
  patient2_id?: InputMaybe<Uuid_Comparison_Exp>;
  patientByPatient1Id?: InputMaybe<Patients_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_not_duplicates" */
export type Patient_Not_Duplicates_Constraint =
  /** unique or primary key constraint on columns "id" */
  'patient_not_duplicates_pkey';

/** input type for inserting data into table "patient_not_duplicates" */
export type Patient_Not_Duplicates_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patient1_id?: InputMaybe<Scalars['uuid']>;
  patient2_id?: InputMaybe<Scalars['uuid']>;
  patientByPatient1Id?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Patient_Not_Duplicates_Max_Fields = {
  __typename?: 'patient_not_duplicates_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patient1_id?: Maybe<Scalars['uuid']>;
  patient2_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "patient_not_duplicates" */
export type Patient_Not_Duplicates_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient1_id?: InputMaybe<Order_By>;
  patient2_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Patient_Not_Duplicates_Min_Fields = {
  __typename?: 'patient_not_duplicates_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patient1_id?: Maybe<Scalars['uuid']>;
  patient2_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "patient_not_duplicates" */
export type Patient_Not_Duplicates_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient1_id?: InputMaybe<Order_By>;
  patient2_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patient_not_duplicates" */
export type Patient_Not_Duplicates_Mutation_Response = {
  __typename?: 'patient_not_duplicates_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patient_Not_Duplicates>;
};

/** on_conflict condition type for table "patient_not_duplicates" */
export type Patient_Not_Duplicates_On_Conflict = {
  constraint: Patient_Not_Duplicates_Constraint;
  update_columns?: Array<Patient_Not_Duplicates_Update_Column>;
  where?: InputMaybe<Patient_Not_Duplicates_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_not_duplicates". */
export type Patient_Not_Duplicates_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patient1_id?: InputMaybe<Order_By>;
  patient2_id?: InputMaybe<Order_By>;
  patientByPatient1Id?: InputMaybe<Patients_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient_not_duplicates */
export type Patient_Not_Duplicates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "patient_not_duplicates" */
export type Patient_Not_Duplicates_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'patient1_id'
  /** column name */
  | 'patient2_id'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "patient_not_duplicates" */
export type Patient_Not_Duplicates_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient1_id?: InputMaybe<Scalars['uuid']>;
  patient2_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "patient_not_duplicates" */
export type Patient_Not_Duplicates_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Not_Duplicates_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Not_Duplicates_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient1_id?: InputMaybe<Scalars['uuid']>;
  patient2_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "patient_not_duplicates" */
export type Patient_Not_Duplicates_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'patient1_id'
  /** column name */
  | 'patient2_id'
  /** column name */
  | 'updated_at';

export type Patient_Not_Duplicates_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Not_Duplicates_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Not_Duplicates_Bool_Exp;
};

/** Used as a status table for records whose only status type is marked as error */
export type Patient_Record_Status_Types = {
  __typename?: 'patient_record_status_types';
  description: Scalars['String'];
  /** An array relationship */
  medical_histories: Array<Medical_History>;
  /** An aggregate relationship */
  medical_histories_aggregate: Medical_History_Aggregate;
  /** An array relationship */
  screenings: Array<Screenings>;
  /** An aggregate relationship */
  screenings_aggregate: Screenings_Aggregate;
  value: Scalars['String'];
};

/** Used as a status table for records whose only status type is marked as error */
export type Patient_Record_Status_TypesMedical_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Order_By>>;
  where?: InputMaybe<Medical_History_Bool_Exp>;
};

/** Used as a status table for records whose only status type is marked as error */
export type Patient_Record_Status_TypesMedical_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Order_By>>;
  where?: InputMaybe<Medical_History_Bool_Exp>;
};

/** Used as a status table for records whose only status type is marked as error */
export type Patient_Record_Status_TypesScreeningsArgs = {
  distinct_on?: InputMaybe<Array<Screenings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screenings_Order_By>>;
  where?: InputMaybe<Screenings_Bool_Exp>;
};

/** Used as a status table for records whose only status type is marked as error */
export type Patient_Record_Status_TypesScreenings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screenings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screenings_Order_By>>;
  where?: InputMaybe<Screenings_Bool_Exp>;
};

/** aggregated selection of "patient_record_status_types" */
export type Patient_Record_Status_Types_Aggregate = {
  __typename?: 'patient_record_status_types_aggregate';
  aggregate?: Maybe<Patient_Record_Status_Types_Aggregate_Fields>;
  nodes: Array<Patient_Record_Status_Types>;
};

/** aggregate fields of "patient_record_status_types" */
export type Patient_Record_Status_Types_Aggregate_Fields = {
  __typename?: 'patient_record_status_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Patient_Record_Status_Types_Max_Fields>;
  min?: Maybe<Patient_Record_Status_Types_Min_Fields>;
};

/** aggregate fields of "patient_record_status_types" */
export type Patient_Record_Status_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patient_Record_Status_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "patient_record_status_types". All fields are combined with a logical 'AND'. */
export type Patient_Record_Status_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Record_Status_Types_Bool_Exp>>;
  _not?: InputMaybe<Patient_Record_Status_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Record_Status_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  medical_histories?: InputMaybe<Medical_History_Bool_Exp>;
  medical_histories_aggregate?: InputMaybe<Medical_History_Aggregate_Bool_Exp>;
  screenings?: InputMaybe<Screenings_Bool_Exp>;
  screenings_aggregate?: InputMaybe<Screenings_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_record_status_types" */
export type Patient_Record_Status_Types_Constraint =
  /** unique or primary key constraint on columns "value" */
  'screening_status_types_pkey';

export type Patient_Record_Status_Types_Enum =
  /** Entered as error */
  'ERROR';

/** Boolean expression to compare columns of type "patient_record_status_types_enum". All fields are combined with logical 'AND'. */
export type Patient_Record_Status_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Patient_Record_Status_Types_Enum>;
  _in?: InputMaybe<Array<Patient_Record_Status_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Patient_Record_Status_Types_Enum>;
  _nin?: InputMaybe<Array<Patient_Record_Status_Types_Enum>>;
};

/** input type for inserting data into table "patient_record_status_types" */
export type Patient_Record_Status_Types_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  medical_histories?: InputMaybe<Medical_History_Arr_Rel_Insert_Input>;
  screenings?: InputMaybe<Screenings_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Patient_Record_Status_Types_Max_Fields = {
  __typename?: 'patient_record_status_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Patient_Record_Status_Types_Min_Fields = {
  __typename?: 'patient_record_status_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "patient_record_status_types" */
export type Patient_Record_Status_Types_Mutation_Response = {
  __typename?: 'patient_record_status_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patient_Record_Status_Types>;
};

/** input type for inserting object relation for remote table "patient_record_status_types" */
export type Patient_Record_Status_Types_Obj_Rel_Insert_Input = {
  data: Patient_Record_Status_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Record_Status_Types_On_Conflict>;
};

/** on_conflict condition type for table "patient_record_status_types" */
export type Patient_Record_Status_Types_On_Conflict = {
  constraint: Patient_Record_Status_Types_Constraint;
  update_columns?: Array<Patient_Record_Status_Types_Update_Column>;
  where?: InputMaybe<Patient_Record_Status_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_record_status_types". */
export type Patient_Record_Status_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  medical_histories_aggregate?: InputMaybe<Medical_History_Aggregate_Order_By>;
  screenings_aggregate?: InputMaybe<Screenings_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient_record_status_types */
export type Patient_Record_Status_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "patient_record_status_types" */
export type Patient_Record_Status_Types_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "patient_record_status_types" */
export type Patient_Record_Status_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "patient_record_status_types" */
export type Patient_Record_Status_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Record_Status_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Record_Status_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "patient_record_status_types" */
export type Patient_Record_Status_Types_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Patient_Record_Status_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Record_Status_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Record_Status_Types_Bool_Exp;
};

/** columns and relationships of "patients" */
export type Patients = {
  __typename?: 'patients';
  /** An array relationship */
  accounts: Array<Account_Patient>;
  /** An aggregate relationship */
  accounts_aggregate: Account_Patient_Aggregate;
  additionalInfo?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  allergies: Array<Allergies>;
  /** An aggregate relationship */
  allergies_aggregate: Allergies_Aggregate;
  /** An array relationship */
  appointment_patients: Array<Appointment_Patient>;
  /** An aggregate relationship */
  appointment_patients_aggregate: Appointment_Patient_Aggregate;
  /** An array relationship */
  appointments: Array<Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Appointments_Aggregate;
  avatar?: Maybe<FilePayload>;
  avatarId?: Maybe<Scalars['String']>;
  /** An array relationship */
  charges: Array<Charges>;
  /** An aggregate relationship */
  charges_aggregate: Charges_Aggregate;
  /** An array relationship */
  chat_sessions_patients: Array<Chat_Sessions_Patients>;
  /** An aggregate relationship */
  chat_sessions_patients_aggregate: Chat_Sessions_Patients_Aggregate;
  /** An array relationship */
  communication_histories: Array<Communication_History>;
  /** An aggregate relationship */
  communication_histories_aggregate: Communication_History_Aggregate;
  consentDocument?: Maybe<FilePayload>;
  consentDocumentId?: Maybe<Scalars['String']>;
  consentSigned?: Maybe<Scalars['Boolean']>;
  consentSignedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  consentSignedByUser?: Maybe<Users>;
  consentSignedByUserId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  consentToTelemedicineTermsByAccount?: Maybe<Accounts>;
  consentToTelemedicineTermsByAccountId?: Maybe<Scalars['uuid']>;
  consentToTelemedicineTermsTimestamp?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  createdByUserId?: Maybe<Scalars['uuid']>;
  dateOfBirth: Scalars['date'];
  deceasedAt?: Maybe<Scalars['date']>;
  /** An array relationship */
  discovered_froms: Array<Discovered_From>;
  /** An aggregate relationship */
  discovered_froms_aggregate: Discovered_From_Aggregate;
  /** An array relationship */
  documents: Array<Documents>;
  /** An aggregate relationship */
  documents_aggregate: Documents_Aggregate;
  /** An array relationship */
  fhir_resources: Array<Fhir_Resources>;
  /** An aggregate relationship */
  fhir_resources_aggregate: Fhir_Resources_Aggregate;
  /** An array relationship */
  fhir_resources_coded: Array<Fhir_Resources_Coded>;
  /** An aggregate relationship */
  fhir_resources_coded_aggregate: Fhir_Resources_Coded_Aggregate;
  firstName: Scalars['String'];
  genderAtBirth?: Maybe<Scalars['String']>;
  /** An array relationship */
  global_notes: Array<Global_Notes>;
  /** An aggregate relationship */
  global_notes_aggregate: Global_Notes_Aggregate;
  goesByName?: Maybe<Scalars['String']>;
  hasPCP?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  insurances: Array<Insurance>;
  /** An aggregate relationship */
  insurances_aggregate: Insurance_Aggregate;
  kareo_guid?: Maybe<Scalars['String']>;
  kareo_patient_id?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  lastWellVisitStatus?: Maybe<Scalars['String']>;
  /** An array relationship */
  medical_histories: Array<Medical_History>;
  /** An aggregate relationship */
  medical_histories_aggregate: Medical_History_Aggregate;
  /** An array relationship */
  medical_history_answers: Array<Medical_History_Answers>;
  /** An aggregate relationship */
  medical_history_answers_aggregate: Medical_History_Answers_Aggregate;
  migratedToPatientId?: Maybe<Scalars['uuid']>;
  mrn: Scalars['String'];
  /** An object relationship */
  parsed_address?: Maybe<Patient_Address>;
  /** An array relationship */
  patientNotDuplicatesByPatient1Id: Array<Patient_Not_Duplicates>;
  /** An aggregate relationship */
  patientNotDuplicatesByPatient1Id_aggregate: Patient_Not_Duplicates_Aggregate;
  /** An array relationship */
  patient_not_duplicates: Array<Patient_Not_Duplicates>;
  /** An aggregate relationship */
  patient_not_duplicates_aggregate: Patient_Not_Duplicates_Aggregate;
  physicianName?: Maybe<Scalars['String']>;
  preferredPharmacy?: Maybe<Scalars['jsonb']>;
  preferredPronoun?: Maybe<Scalars['String']>;
  preferredPronounType?: Maybe<Scalars['String']>;
  primaryCareStaffId?: Maybe<Scalars['uuid']>;
  primaryClinicAddress?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  problems: Array<Problems>;
  /** An aggregate relationship */
  problems_aggregate: Problems_Aggregate;
  pronunciation?: Maybe<Scalars['String']>;
  /** An array relationship */
  screenings: Array<Screenings>;
  /** An aggregate relationship */
  screenings_aggregate: Screenings_Aggregate;
  /** An object relationship */
  staff?: Maybe<Staff>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  /** An array relationship */
  tasks_notifications: Array<Tasks_Notifications>;
  /** An aggregate relationship */
  tasks_notifications_aggregate: Tasks_Notifications_Aggregate;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  userByCreatedbyuserid?: Maybe<Users>;
  vaccinationStatus?: Maybe<Scalars['String']>;
};

/** columns and relationships of "patients" */
export type PatientsAccountsArgs = {
  distinct_on?: InputMaybe<Array<Account_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Patient_Order_By>>;
  where?: InputMaybe<Account_Patient_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Patient_Order_By>>;
  where?: InputMaybe<Account_Patient_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsAddressArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "patients" */
export type PatientsAllergiesArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Order_By>>;
  where?: InputMaybe<Allergies_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsAllergies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Order_By>>;
  where?: InputMaybe<Allergies_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsAppointment_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Patient_Order_By>>;
  where?: InputMaybe<Appointment_Patient_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsAppointment_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Patient_Order_By>>;
  where?: InputMaybe<Appointment_Patient_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsChargesArgs = {
  distinct_on?: InputMaybe<Array<Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charges_Order_By>>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsCharges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charges_Order_By>>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsChat_Sessions_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Patients_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Patients_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsChat_Sessions_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Patients_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Patients_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsCommunication_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Order_By>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsCommunication_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Order_By>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsDiscovered_FromsArgs = {
  distinct_on?: InputMaybe<Array<Discovered_From_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Discovered_From_Order_By>>;
  where?: InputMaybe<Discovered_From_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsDiscovered_Froms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Discovered_From_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Discovered_From_Order_By>>;
  where?: InputMaybe<Discovered_From_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsFhir_ResourcesArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_Order_By>>;
  where?: InputMaybe<Fhir_Resources_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsFhir_Resources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_Order_By>>;
  where?: InputMaybe<Fhir_Resources_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsFhir_Resources_CodedArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_Coded_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_Coded_Order_By>>;
  where?: InputMaybe<Fhir_Resources_Coded_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsFhir_Resources_Coded_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_Coded_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_Coded_Order_By>>;
  where?: InputMaybe<Fhir_Resources_Coded_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsGlobal_NotesArgs = {
  distinct_on?: InputMaybe<Array<Global_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Notes_Order_By>>;
  where?: InputMaybe<Global_Notes_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsGlobal_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Notes_Order_By>>;
  where?: InputMaybe<Global_Notes_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsInsurancesArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Order_By>>;
  where?: InputMaybe<Insurance_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsInsurances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Order_By>>;
  where?: InputMaybe<Insurance_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsMedical_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Order_By>>;
  where?: InputMaybe<Medical_History_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsMedical_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Order_By>>;
  where?: InputMaybe<Medical_History_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsMedical_History_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Answers_Order_By>>;
  where?: InputMaybe<Medical_History_Answers_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsMedical_History_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Answers_Order_By>>;
  where?: InputMaybe<Medical_History_Answers_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsPatientNotDuplicatesByPatient1IdArgs = {
  distinct_on?: InputMaybe<Array<Patient_Not_Duplicates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Not_Duplicates_Order_By>>;
  where?: InputMaybe<Patient_Not_Duplicates_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsPatientNotDuplicatesByPatient1Id_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Not_Duplicates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Not_Duplicates_Order_By>>;
  where?: InputMaybe<Patient_Not_Duplicates_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsPatient_Not_DuplicatesArgs = {
  distinct_on?: InputMaybe<Array<Patient_Not_Duplicates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Not_Duplicates_Order_By>>;
  where?: InputMaybe<Patient_Not_Duplicates_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsPatient_Not_Duplicates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Not_Duplicates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Not_Duplicates_Order_By>>;
  where?: InputMaybe<Patient_Not_Duplicates_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsPreferredPharmacyArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "patients" */
export type PatientsPrimaryClinicAddressArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "patients" */
export type PatientsProblemsArgs = {
  distinct_on?: InputMaybe<Array<Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Problems_Order_By>>;
  where?: InputMaybe<Problems_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsProblems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Problems_Order_By>>;
  where?: InputMaybe<Problems_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsScreeningsArgs = {
  distinct_on?: InputMaybe<Array<Screenings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screenings_Order_By>>;
  where?: InputMaybe<Screenings_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsScreenings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screenings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screenings_Order_By>>;
  where?: InputMaybe<Screenings_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsTasks_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsTasks_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

/** aggregated selection of "patients" */
export type Patients_Aggregate = {
  __typename?: 'patients_aggregate';
  aggregate?: Maybe<Patients_Aggregate_Fields>;
  nodes: Array<Patients>;
};

export type Patients_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Patients_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Patients_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Patients_Aggregate_Bool_Exp_Count>;
};

export type Patients_Aggregate_Bool_Exp_Bool_And = {
  arguments: Patients_Select_Column_Patients_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patients_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Patients_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Patients_Select_Column_Patients_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patients_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Patients_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Patients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patients_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "patients" */
export type Patients_Aggregate_Fields = {
  __typename?: 'patients_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Patients_Max_Fields>;
  min?: Maybe<Patients_Min_Fields>;
};

/** aggregate fields of "patients" */
export type Patients_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patients" */
export type Patients_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patients_Max_Order_By>;
  min?: InputMaybe<Patients_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Patients_Append_Input = {
  address?: InputMaybe<Scalars['jsonb']>;
  preferredPharmacy?: InputMaybe<Scalars['jsonb']>;
  primaryClinicAddress?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "patients" */
export type Patients_Arr_Rel_Insert_Input = {
  data: Array<Patients_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Patients_On_Conflict>;
};

/** Boolean expression to filter rows from the table "patients". All fields are combined with a logical 'AND'. */
export type Patients_Bool_Exp = {
  _and?: InputMaybe<Array<Patients_Bool_Exp>>;
  _not?: InputMaybe<Patients_Bool_Exp>;
  _or?: InputMaybe<Array<Patients_Bool_Exp>>;
  accounts?: InputMaybe<Account_Patient_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Account_Patient_Aggregate_Bool_Exp>;
  additionalInfo?: InputMaybe<String_Comparison_Exp>;
  address?: InputMaybe<Jsonb_Comparison_Exp>;
  allergies?: InputMaybe<Allergies_Bool_Exp>;
  allergies_aggregate?: InputMaybe<Allergies_Aggregate_Bool_Exp>;
  appointment_patients?: InputMaybe<Appointment_Patient_Bool_Exp>;
  appointment_patients_aggregate?: InputMaybe<Appointment_Patient_Aggregate_Bool_Exp>;
  appointments?: InputMaybe<Appointments_Bool_Exp>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Bool_Exp>;
  avatarId?: InputMaybe<String_Comparison_Exp>;
  charges?: InputMaybe<Charges_Bool_Exp>;
  charges_aggregate?: InputMaybe<Charges_Aggregate_Bool_Exp>;
  chat_sessions_patients?: InputMaybe<Chat_Sessions_Patients_Bool_Exp>;
  chat_sessions_patients_aggregate?: InputMaybe<Chat_Sessions_Patients_Aggregate_Bool_Exp>;
  communication_histories?: InputMaybe<Communication_History_Bool_Exp>;
  communication_histories_aggregate?: InputMaybe<Communication_History_Aggregate_Bool_Exp>;
  consentDocumentId?: InputMaybe<String_Comparison_Exp>;
  consentSigned?: InputMaybe<Boolean_Comparison_Exp>;
  consentSignedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  consentSignedByUser?: InputMaybe<Users_Bool_Exp>;
  consentSignedByUserId?: InputMaybe<Uuid_Comparison_Exp>;
  consentToTelemedicineTermsByAccount?: InputMaybe<Accounts_Bool_Exp>;
  consentToTelemedicineTermsByAccountId?: InputMaybe<Uuid_Comparison_Exp>;
  consentToTelemedicineTermsTimestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdByUserId?: InputMaybe<Uuid_Comparison_Exp>;
  dateOfBirth?: InputMaybe<Date_Comparison_Exp>;
  deceasedAt?: InputMaybe<Date_Comparison_Exp>;
  discovered_froms?: InputMaybe<Discovered_From_Bool_Exp>;
  discovered_froms_aggregate?: InputMaybe<Discovered_From_Aggregate_Bool_Exp>;
  documents?: InputMaybe<Documents_Bool_Exp>;
  documents_aggregate?: InputMaybe<Documents_Aggregate_Bool_Exp>;
  fhir_resources?: InputMaybe<Fhir_Resources_Bool_Exp>;
  fhir_resources_aggregate?: InputMaybe<Fhir_Resources_Aggregate_Bool_Exp>;
  fhir_resources_coded?: InputMaybe<Fhir_Resources_Coded_Bool_Exp>;
  fhir_resources_coded_aggregate?: InputMaybe<Fhir_Resources_Coded_Aggregate_Bool_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  genderAtBirth?: InputMaybe<String_Comparison_Exp>;
  global_notes?: InputMaybe<Global_Notes_Bool_Exp>;
  global_notes_aggregate?: InputMaybe<Global_Notes_Aggregate_Bool_Exp>;
  goesByName?: InputMaybe<String_Comparison_Exp>;
  hasPCP?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  insurances?: InputMaybe<Insurance_Bool_Exp>;
  insurances_aggregate?: InputMaybe<Insurance_Aggregate_Bool_Exp>;
  kareo_guid?: InputMaybe<String_Comparison_Exp>;
  kareo_patient_id?: InputMaybe<String_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  lastWellVisitStatus?: InputMaybe<String_Comparison_Exp>;
  medical_histories?: InputMaybe<Medical_History_Bool_Exp>;
  medical_histories_aggregate?: InputMaybe<Medical_History_Aggregate_Bool_Exp>;
  medical_history_answers?: InputMaybe<Medical_History_Answers_Bool_Exp>;
  medical_history_answers_aggregate?: InputMaybe<Medical_History_Answers_Aggregate_Bool_Exp>;
  migratedToPatientId?: InputMaybe<Uuid_Comparison_Exp>;
  mrn?: InputMaybe<String_Comparison_Exp>;
  parsed_address?: InputMaybe<Patient_Address_Bool_Exp>;
  patientNotDuplicatesByPatient1Id?: InputMaybe<Patient_Not_Duplicates_Bool_Exp>;
  patientNotDuplicatesByPatient1Id_aggregate?: InputMaybe<Patient_Not_Duplicates_Aggregate_Bool_Exp>;
  patient_not_duplicates?: InputMaybe<Patient_Not_Duplicates_Bool_Exp>;
  patient_not_duplicates_aggregate?: InputMaybe<Patient_Not_Duplicates_Aggregate_Bool_Exp>;
  physicianName?: InputMaybe<String_Comparison_Exp>;
  preferredPharmacy?: InputMaybe<Jsonb_Comparison_Exp>;
  preferredPronoun?: InputMaybe<String_Comparison_Exp>;
  preferredPronounType?: InputMaybe<String_Comparison_Exp>;
  primaryCareStaffId?: InputMaybe<Uuid_Comparison_Exp>;
  primaryClinicAddress?: InputMaybe<Jsonb_Comparison_Exp>;
  problems?: InputMaybe<Problems_Bool_Exp>;
  problems_aggregate?: InputMaybe<Problems_Aggregate_Bool_Exp>;
  pronunciation?: InputMaybe<String_Comparison_Exp>;
  screenings?: InputMaybe<Screenings_Bool_Exp>;
  screenings_aggregate?: InputMaybe<Screenings_Aggregate_Bool_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  stripeSubscriptionId?: InputMaybe<String_Comparison_Exp>;
  tasks_notifications?: InputMaybe<Tasks_Notifications_Bool_Exp>;
  tasks_notifications_aggregate?: InputMaybe<Tasks_Notifications_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByCreatedbyuserid?: InputMaybe<Users_Bool_Exp>;
  vaccinationStatus?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "patients" */
export type Patients_Constraint =
  /** unique or primary key constraint on columns "mrn" */
  | 'patients_mrn_key'
  /** unique or primary key constraint on columns "id" */
  | 'patients_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Patients_Delete_At_Path_Input = {
  address?: InputMaybe<Array<Scalars['String']>>;
  preferredPharmacy?: InputMaybe<Array<Scalars['String']>>;
  primaryClinicAddress?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Patients_Delete_Elem_Input = {
  address?: InputMaybe<Scalars['Int']>;
  preferredPharmacy?: InputMaybe<Scalars['Int']>;
  primaryClinicAddress?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Patients_Delete_Key_Input = {
  address?: InputMaybe<Scalars['String']>;
  preferredPharmacy?: InputMaybe<Scalars['String']>;
  primaryClinicAddress?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "patients" */
export type Patients_Insert_Input = {
  accounts?: InputMaybe<Account_Patient_Arr_Rel_Insert_Input>;
  additionalInfo?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['jsonb']>;
  allergies?: InputMaybe<Allergies_Arr_Rel_Insert_Input>;
  appointment_patients?: InputMaybe<Appointment_Patient_Arr_Rel_Insert_Input>;
  appointments?: InputMaybe<Appointments_Arr_Rel_Insert_Input>;
  avatarId?: InputMaybe<Scalars['String']>;
  charges?: InputMaybe<Charges_Arr_Rel_Insert_Input>;
  chat_sessions_patients?: InputMaybe<Chat_Sessions_Patients_Arr_Rel_Insert_Input>;
  communication_histories?: InputMaybe<Communication_History_Arr_Rel_Insert_Input>;
  consentDocumentId?: InputMaybe<Scalars['String']>;
  consentSigned?: InputMaybe<Scalars['Boolean']>;
  consentSignedAt?: InputMaybe<Scalars['timestamptz']>;
  consentSignedByUser?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  consentSignedByUserId?: InputMaybe<Scalars['uuid']>;
  consentToTelemedicineTermsByAccount?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  consentToTelemedicineTermsByAccountId?: InputMaybe<Scalars['uuid']>;
  consentToTelemedicineTermsTimestamp?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  dateOfBirth?: InputMaybe<Scalars['date']>;
  deceasedAt?: InputMaybe<Scalars['date']>;
  discovered_froms?: InputMaybe<Discovered_From_Arr_Rel_Insert_Input>;
  documents?: InputMaybe<Documents_Arr_Rel_Insert_Input>;
  fhir_resources?: InputMaybe<Fhir_Resources_Arr_Rel_Insert_Input>;
  fhir_resources_coded?: InputMaybe<Fhir_Resources_Coded_Arr_Rel_Insert_Input>;
  firstName?: InputMaybe<Scalars['String']>;
  genderAtBirth?: InputMaybe<Scalars['String']>;
  global_notes?: InputMaybe<Global_Notes_Arr_Rel_Insert_Input>;
  goesByName?: InputMaybe<Scalars['String']>;
  hasPCP?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insurances?: InputMaybe<Insurance_Arr_Rel_Insert_Input>;
  kareo_guid?: InputMaybe<Scalars['String']>;
  kareo_patient_id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastWellVisitStatus?: InputMaybe<Scalars['String']>;
  medical_histories?: InputMaybe<Medical_History_Arr_Rel_Insert_Input>;
  medical_history_answers?: InputMaybe<Medical_History_Answers_Arr_Rel_Insert_Input>;
  migratedToPatientId?: InputMaybe<Scalars['uuid']>;
  mrn?: InputMaybe<Scalars['String']>;
  parsed_address?: InputMaybe<Patient_Address_Obj_Rel_Insert_Input>;
  patientNotDuplicatesByPatient1Id?: InputMaybe<Patient_Not_Duplicates_Arr_Rel_Insert_Input>;
  patient_not_duplicates?: InputMaybe<Patient_Not_Duplicates_Arr_Rel_Insert_Input>;
  physicianName?: InputMaybe<Scalars['String']>;
  preferredPharmacy?: InputMaybe<Scalars['jsonb']>;
  preferredPronoun?: InputMaybe<Scalars['String']>;
  preferredPronounType?: InputMaybe<Scalars['String']>;
  primaryCareStaffId?: InputMaybe<Scalars['uuid']>;
  primaryClinicAddress?: InputMaybe<Scalars['jsonb']>;
  problems?: InputMaybe<Problems_Arr_Rel_Insert_Input>;
  pronunciation?: InputMaybe<Scalars['String']>;
  screenings?: InputMaybe<Screenings_Arr_Rel_Insert_Input>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']>;
  tasks_notifications?: InputMaybe<Tasks_Notifications_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByCreatedbyuserid?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  vaccinationStatus?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Patients_Max_Fields = {
  __typename?: 'patients_max_fields';
  additionalInfo?: Maybe<Scalars['String']>;
  avatarId?: Maybe<Scalars['String']>;
  consentDocumentId?: Maybe<Scalars['String']>;
  consentSignedAt?: Maybe<Scalars['timestamptz']>;
  consentSignedByUserId?: Maybe<Scalars['uuid']>;
  consentToTelemedicineTermsByAccountId?: Maybe<Scalars['uuid']>;
  consentToTelemedicineTermsTimestamp?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  deceasedAt?: Maybe<Scalars['date']>;
  firstName?: Maybe<Scalars['String']>;
  genderAtBirth?: Maybe<Scalars['String']>;
  goesByName?: Maybe<Scalars['String']>;
  hasPCP?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  kareo_guid?: Maybe<Scalars['String']>;
  kareo_patient_id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastWellVisitStatus?: Maybe<Scalars['String']>;
  migratedToPatientId?: Maybe<Scalars['uuid']>;
  mrn?: Maybe<Scalars['String']>;
  physicianName?: Maybe<Scalars['String']>;
  preferredPronoun?: Maybe<Scalars['String']>;
  preferredPronounType?: Maybe<Scalars['String']>;
  primaryCareStaffId?: Maybe<Scalars['uuid']>;
  pronunciation?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vaccinationStatus?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "patients" */
export type Patients_Max_Order_By = {
  additionalInfo?: InputMaybe<Order_By>;
  avatarId?: InputMaybe<Order_By>;
  consentDocumentId?: InputMaybe<Order_By>;
  consentSignedAt?: InputMaybe<Order_By>;
  consentSignedByUserId?: InputMaybe<Order_By>;
  consentToTelemedicineTermsByAccountId?: InputMaybe<Order_By>;
  consentToTelemedicineTermsTimestamp?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdByUserId?: InputMaybe<Order_By>;
  dateOfBirth?: InputMaybe<Order_By>;
  deceasedAt?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  genderAtBirth?: InputMaybe<Order_By>;
  goesByName?: InputMaybe<Order_By>;
  hasPCP?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kareo_guid?: InputMaybe<Order_By>;
  kareo_patient_id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  lastWellVisitStatus?: InputMaybe<Order_By>;
  migratedToPatientId?: InputMaybe<Order_By>;
  mrn?: InputMaybe<Order_By>;
  physicianName?: InputMaybe<Order_By>;
  preferredPronoun?: InputMaybe<Order_By>;
  preferredPronounType?: InputMaybe<Order_By>;
  primaryCareStaffId?: InputMaybe<Order_By>;
  pronunciation?: InputMaybe<Order_By>;
  stripeSubscriptionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vaccinationStatus?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Patients_Min_Fields = {
  __typename?: 'patients_min_fields';
  additionalInfo?: Maybe<Scalars['String']>;
  avatarId?: Maybe<Scalars['String']>;
  consentDocumentId?: Maybe<Scalars['String']>;
  consentSignedAt?: Maybe<Scalars['timestamptz']>;
  consentSignedByUserId?: Maybe<Scalars['uuid']>;
  consentToTelemedicineTermsByAccountId?: Maybe<Scalars['uuid']>;
  consentToTelemedicineTermsTimestamp?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  deceasedAt?: Maybe<Scalars['date']>;
  firstName?: Maybe<Scalars['String']>;
  genderAtBirth?: Maybe<Scalars['String']>;
  goesByName?: Maybe<Scalars['String']>;
  hasPCP?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  kareo_guid?: Maybe<Scalars['String']>;
  kareo_patient_id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastWellVisitStatus?: Maybe<Scalars['String']>;
  migratedToPatientId?: Maybe<Scalars['uuid']>;
  mrn?: Maybe<Scalars['String']>;
  physicianName?: Maybe<Scalars['String']>;
  preferredPronoun?: Maybe<Scalars['String']>;
  preferredPronounType?: Maybe<Scalars['String']>;
  primaryCareStaffId?: Maybe<Scalars['uuid']>;
  pronunciation?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vaccinationStatus?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "patients" */
export type Patients_Min_Order_By = {
  additionalInfo?: InputMaybe<Order_By>;
  avatarId?: InputMaybe<Order_By>;
  consentDocumentId?: InputMaybe<Order_By>;
  consentSignedAt?: InputMaybe<Order_By>;
  consentSignedByUserId?: InputMaybe<Order_By>;
  consentToTelemedicineTermsByAccountId?: InputMaybe<Order_By>;
  consentToTelemedicineTermsTimestamp?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdByUserId?: InputMaybe<Order_By>;
  dateOfBirth?: InputMaybe<Order_By>;
  deceasedAt?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  genderAtBirth?: InputMaybe<Order_By>;
  goesByName?: InputMaybe<Order_By>;
  hasPCP?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kareo_guid?: InputMaybe<Order_By>;
  kareo_patient_id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  lastWellVisitStatus?: InputMaybe<Order_By>;
  migratedToPatientId?: InputMaybe<Order_By>;
  mrn?: InputMaybe<Order_By>;
  physicianName?: InputMaybe<Order_By>;
  preferredPronoun?: InputMaybe<Order_By>;
  preferredPronounType?: InputMaybe<Order_By>;
  primaryCareStaffId?: InputMaybe<Order_By>;
  pronunciation?: InputMaybe<Order_By>;
  stripeSubscriptionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vaccinationStatus?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patients" */
export type Patients_Mutation_Response = {
  __typename?: 'patients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patients>;
};

/** input type for inserting object relation for remote table "patients" */
export type Patients_Obj_Rel_Insert_Input = {
  data: Patients_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Patients_On_Conflict>;
};

/** on_conflict condition type for table "patients" */
export type Patients_On_Conflict = {
  constraint: Patients_Constraint;
  update_columns?: Array<Patients_Update_Column>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

/** Ordering options when selecting data from "patients". */
export type Patients_Order_By = {
  accounts_aggregate?: InputMaybe<Account_Patient_Aggregate_Order_By>;
  additionalInfo?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  allergies_aggregate?: InputMaybe<Allergies_Aggregate_Order_By>;
  appointment_patients_aggregate?: InputMaybe<Appointment_Patient_Aggregate_Order_By>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Order_By>;
  avatarId?: InputMaybe<Order_By>;
  charges_aggregate?: InputMaybe<Charges_Aggregate_Order_By>;
  chat_sessions_patients_aggregate?: InputMaybe<Chat_Sessions_Patients_Aggregate_Order_By>;
  communication_histories_aggregate?: InputMaybe<Communication_History_Aggregate_Order_By>;
  consentDocumentId?: InputMaybe<Order_By>;
  consentSigned?: InputMaybe<Order_By>;
  consentSignedAt?: InputMaybe<Order_By>;
  consentSignedByUser?: InputMaybe<Users_Order_By>;
  consentSignedByUserId?: InputMaybe<Order_By>;
  consentToTelemedicineTermsByAccount?: InputMaybe<Accounts_Order_By>;
  consentToTelemedicineTermsByAccountId?: InputMaybe<Order_By>;
  consentToTelemedicineTermsTimestamp?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdByUserId?: InputMaybe<Order_By>;
  dateOfBirth?: InputMaybe<Order_By>;
  deceasedAt?: InputMaybe<Order_By>;
  discovered_froms_aggregate?: InputMaybe<Discovered_From_Aggregate_Order_By>;
  documents_aggregate?: InputMaybe<Documents_Aggregate_Order_By>;
  fhir_resources_aggregate?: InputMaybe<Fhir_Resources_Aggregate_Order_By>;
  fhir_resources_coded_aggregate?: InputMaybe<Fhir_Resources_Coded_Aggregate_Order_By>;
  firstName?: InputMaybe<Order_By>;
  genderAtBirth?: InputMaybe<Order_By>;
  global_notes_aggregate?: InputMaybe<Global_Notes_Aggregate_Order_By>;
  goesByName?: InputMaybe<Order_By>;
  hasPCP?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurances_aggregate?: InputMaybe<Insurance_Aggregate_Order_By>;
  kareo_guid?: InputMaybe<Order_By>;
  kareo_patient_id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  lastWellVisitStatus?: InputMaybe<Order_By>;
  medical_histories_aggregate?: InputMaybe<Medical_History_Aggregate_Order_By>;
  medical_history_answers_aggregate?: InputMaybe<Medical_History_Answers_Aggregate_Order_By>;
  migratedToPatientId?: InputMaybe<Order_By>;
  mrn?: InputMaybe<Order_By>;
  parsed_address?: InputMaybe<Patient_Address_Order_By>;
  patientNotDuplicatesByPatient1Id_aggregate?: InputMaybe<Patient_Not_Duplicates_Aggregate_Order_By>;
  patient_not_duplicates_aggregate?: InputMaybe<Patient_Not_Duplicates_Aggregate_Order_By>;
  physicianName?: InputMaybe<Order_By>;
  preferredPharmacy?: InputMaybe<Order_By>;
  preferredPronoun?: InputMaybe<Order_By>;
  preferredPronounType?: InputMaybe<Order_By>;
  primaryCareStaffId?: InputMaybe<Order_By>;
  primaryClinicAddress?: InputMaybe<Order_By>;
  problems_aggregate?: InputMaybe<Problems_Aggregate_Order_By>;
  pronunciation?: InputMaybe<Order_By>;
  screenings_aggregate?: InputMaybe<Screenings_Aggregate_Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  stripeSubscriptionId?: InputMaybe<Order_By>;
  tasks_notifications_aggregate?: InputMaybe<Tasks_Notifications_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByCreatedbyuserid?: InputMaybe<Users_Order_By>;
  vaccinationStatus?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patients */
export type Patients_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Patients_Prepend_Input = {
  address?: InputMaybe<Scalars['jsonb']>;
  preferredPharmacy?: InputMaybe<Scalars['jsonb']>;
  primaryClinicAddress?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "patients" */
export type Patients_Select_Column =
  /** column name */
  | 'additionalInfo'
  /** column name */
  | 'address'
  /** column name */
  | 'avatarId'
  /** column name */
  | 'consentDocumentId'
  /** column name */
  | 'consentSigned'
  /** column name */
  | 'consentSignedAt'
  /** column name */
  | 'consentSignedByUserId'
  /** column name */
  | 'consentToTelemedicineTermsByAccountId'
  /** column name */
  | 'consentToTelemedicineTermsTimestamp'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdByUserId'
  /** column name */
  | 'dateOfBirth'
  /** column name */
  | 'deceasedAt'
  /** column name */
  | 'firstName'
  /** column name */
  | 'genderAtBirth'
  /** column name */
  | 'goesByName'
  /** column name */
  | 'hasPCP'
  /** column name */
  | 'id'
  /** column name */
  | 'kareo_guid'
  /** column name */
  | 'kareo_patient_id'
  /** column name */
  | 'lastName'
  /** column name */
  | 'lastWellVisitStatus'
  /** column name */
  | 'migratedToPatientId'
  /** column name */
  | 'mrn'
  /** column name */
  | 'physicianName'
  /** column name */
  | 'preferredPharmacy'
  /** column name */
  | 'preferredPronoun'
  /** column name */
  | 'preferredPronounType'
  /** column name */
  | 'primaryCareStaffId'
  /** column name */
  | 'primaryClinicAddress'
  /** column name */
  | 'pronunciation'
  /** column name */
  | 'stripeSubscriptionId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'vaccinationStatus';

/** select "patients_aggregate_bool_exp_bool_and_arguments_columns" columns of table "patients" */
export type Patients_Select_Column_Patients_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  'consentSigned';

/** select "patients_aggregate_bool_exp_bool_or_arguments_columns" columns of table "patients" */
export type Patients_Select_Column_Patients_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  'consentSigned';

/** input type for updating data in table "patients" */
export type Patients_Set_Input = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['jsonb']>;
  avatarId?: InputMaybe<Scalars['String']>;
  consentDocumentId?: InputMaybe<Scalars['String']>;
  consentSigned?: InputMaybe<Scalars['Boolean']>;
  consentSignedAt?: InputMaybe<Scalars['timestamptz']>;
  consentSignedByUserId?: InputMaybe<Scalars['uuid']>;
  consentToTelemedicineTermsByAccountId?: InputMaybe<Scalars['uuid']>;
  consentToTelemedicineTermsTimestamp?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  dateOfBirth?: InputMaybe<Scalars['date']>;
  deceasedAt?: InputMaybe<Scalars['date']>;
  firstName?: InputMaybe<Scalars['String']>;
  genderAtBirth?: InputMaybe<Scalars['String']>;
  goesByName?: InputMaybe<Scalars['String']>;
  hasPCP?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  kareo_guid?: InputMaybe<Scalars['String']>;
  kareo_patient_id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastWellVisitStatus?: InputMaybe<Scalars['String']>;
  migratedToPatientId?: InputMaybe<Scalars['uuid']>;
  mrn?: InputMaybe<Scalars['String']>;
  physicianName?: InputMaybe<Scalars['String']>;
  preferredPharmacy?: InputMaybe<Scalars['jsonb']>;
  preferredPronoun?: InputMaybe<Scalars['String']>;
  preferredPronounType?: InputMaybe<Scalars['String']>;
  primaryCareStaffId?: InputMaybe<Scalars['uuid']>;
  primaryClinicAddress?: InputMaybe<Scalars['jsonb']>;
  pronunciation?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vaccinationStatus?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "patients" */
export type Patients_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patients_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patients_Stream_Cursor_Value_Input = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['jsonb']>;
  avatarId?: InputMaybe<Scalars['String']>;
  consentDocumentId?: InputMaybe<Scalars['String']>;
  consentSigned?: InputMaybe<Scalars['Boolean']>;
  consentSignedAt?: InputMaybe<Scalars['timestamptz']>;
  consentSignedByUserId?: InputMaybe<Scalars['uuid']>;
  consentToTelemedicineTermsByAccountId?: InputMaybe<Scalars['uuid']>;
  consentToTelemedicineTermsTimestamp?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdByUserId?: InputMaybe<Scalars['uuid']>;
  dateOfBirth?: InputMaybe<Scalars['date']>;
  deceasedAt?: InputMaybe<Scalars['date']>;
  firstName?: InputMaybe<Scalars['String']>;
  genderAtBirth?: InputMaybe<Scalars['String']>;
  goesByName?: InputMaybe<Scalars['String']>;
  hasPCP?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  kareo_guid?: InputMaybe<Scalars['String']>;
  kareo_patient_id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastWellVisitStatus?: InputMaybe<Scalars['String']>;
  migratedToPatientId?: InputMaybe<Scalars['uuid']>;
  mrn?: InputMaybe<Scalars['String']>;
  physicianName?: InputMaybe<Scalars['String']>;
  preferredPharmacy?: InputMaybe<Scalars['jsonb']>;
  preferredPronoun?: InputMaybe<Scalars['String']>;
  preferredPronounType?: InputMaybe<Scalars['String']>;
  primaryCareStaffId?: InputMaybe<Scalars['uuid']>;
  primaryClinicAddress?: InputMaybe<Scalars['jsonb']>;
  pronunciation?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vaccinationStatus?: InputMaybe<Scalars['String']>;
};

/** update columns of table "patients" */
export type Patients_Update_Column =
  /** column name */
  | 'additionalInfo'
  /** column name */
  | 'address'
  /** column name */
  | 'avatarId'
  /** column name */
  | 'consentDocumentId'
  /** column name */
  | 'consentSigned'
  /** column name */
  | 'consentSignedAt'
  /** column name */
  | 'consentSignedByUserId'
  /** column name */
  | 'consentToTelemedicineTermsByAccountId'
  /** column name */
  | 'consentToTelemedicineTermsTimestamp'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdByUserId'
  /** column name */
  | 'dateOfBirth'
  /** column name */
  | 'deceasedAt'
  /** column name */
  | 'firstName'
  /** column name */
  | 'genderAtBirth'
  /** column name */
  | 'goesByName'
  /** column name */
  | 'hasPCP'
  /** column name */
  | 'id'
  /** column name */
  | 'kareo_guid'
  /** column name */
  | 'kareo_patient_id'
  /** column name */
  | 'lastName'
  /** column name */
  | 'lastWellVisitStatus'
  /** column name */
  | 'migratedToPatientId'
  /** column name */
  | 'mrn'
  /** column name */
  | 'physicianName'
  /** column name */
  | 'preferredPharmacy'
  /** column name */
  | 'preferredPronoun'
  /** column name */
  | 'preferredPronounType'
  /** column name */
  | 'primaryCareStaffId'
  /** column name */
  | 'primaryClinicAddress'
  /** column name */
  | 'pronunciation'
  /** column name */
  | 'stripeSubscriptionId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'vaccinationStatus';

export type Patients_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Patients_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Patients_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Patients_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Patients_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Patients_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patients_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patients_Bool_Exp;
};

/** columns and relationships of "payment_type" */
export type Payment_Type = {
  __typename?: 'payment_type';
  /** An array relationship */
  charges: Array<Charges>;
  /** An aggregate relationship */
  charges_aggregate: Charges_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "payment_type" */
export type Payment_TypeChargesArgs = {
  distinct_on?: InputMaybe<Array<Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charges_Order_By>>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

/** columns and relationships of "payment_type" */
export type Payment_TypeCharges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charges_Order_By>>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

/** aggregated selection of "payment_type" */
export type Payment_Type_Aggregate = {
  __typename?: 'payment_type_aggregate';
  aggregate?: Maybe<Payment_Type_Aggregate_Fields>;
  nodes: Array<Payment_Type>;
};

/** aggregate fields of "payment_type" */
export type Payment_Type_Aggregate_Fields = {
  __typename?: 'payment_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Payment_Type_Max_Fields>;
  min?: Maybe<Payment_Type_Min_Fields>;
};

/** aggregate fields of "payment_type" */
export type Payment_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "payment_type". All fields are combined with a logical 'AND'. */
export type Payment_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Type_Bool_Exp>>;
  _not?: InputMaybe<Payment_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Type_Bool_Exp>>;
  charges?: InputMaybe<Charges_Bool_Exp>;
  charges_aggregate?: InputMaybe<Charges_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_type" */
export type Payment_Type_Constraint =
  /** unique or primary key constraint on columns "value" */
  'payment_type_pkey';

export type Payment_Type_Enum =
  /**  Point-of-sale payment made with CC provided by insurance */
  | 'INSURANCE_POS'
  /** Point-of-sale payment made with CC provided by caregiver */
  | 'OUT_OF_POCKET';

/** Boolean expression to compare columns of type "payment_type_enum". All fields are combined with logical 'AND'. */
export type Payment_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Payment_Type_Enum>;
  _in?: InputMaybe<Array<Payment_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Payment_Type_Enum>;
  _nin?: InputMaybe<Array<Payment_Type_Enum>>;
};

/** input type for inserting data into table "payment_type" */
export type Payment_Type_Insert_Input = {
  charges?: InputMaybe<Charges_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payment_Type_Max_Fields = {
  __typename?: 'payment_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_Type_Min_Fields = {
  __typename?: 'payment_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_type" */
export type Payment_Type_Mutation_Response = {
  __typename?: 'payment_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Type>;
};

/** input type for inserting object relation for remote table "payment_type" */
export type Payment_Type_Obj_Rel_Insert_Input = {
  data: Payment_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_Type_On_Conflict>;
};

/** on_conflict condition type for table "payment_type" */
export type Payment_Type_On_Conflict = {
  constraint: Payment_Type_Constraint;
  update_columns?: Array<Payment_Type_Update_Column>;
  where?: InputMaybe<Payment_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_type". */
export type Payment_Type_Order_By = {
  charges_aggregate?: InputMaybe<Charges_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_type */
export type Payment_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "payment_type" */
export type Payment_Type_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "payment_type" */
export type Payment_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "payment_type" */
export type Payment_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "payment_type" */
export type Payment_Type_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Payment_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Type_Bool_Exp;
};

/** columns and relationships of "phone_numbers" */
export type Phone_Numbers = {
  __typename?: 'phone_numbers';
  /** An object relationship */
  clinic?: Maybe<Clinics>;
  clinicId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  communication_histories: Array<Communication_History>;
  /** An aggregate relationship */
  communication_histories_aggregate: Communication_History_Aggregate;
  description?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  /** An object relationship */
  staff?: Maybe<Staff>;
  staffId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "phone_numbers" */
export type Phone_NumbersCommunication_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Order_By>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

/** columns and relationships of "phone_numbers" */
export type Phone_NumbersCommunication_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Order_By>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

/** aggregated selection of "phone_numbers" */
export type Phone_Numbers_Aggregate = {
  __typename?: 'phone_numbers_aggregate';
  aggregate?: Maybe<Phone_Numbers_Aggregate_Fields>;
  nodes: Array<Phone_Numbers>;
};

export type Phone_Numbers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Phone_Numbers_Aggregate_Bool_Exp_Count>;
};

export type Phone_Numbers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Phone_Numbers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Phone_Numbers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "phone_numbers" */
export type Phone_Numbers_Aggregate_Fields = {
  __typename?: 'phone_numbers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Phone_Numbers_Max_Fields>;
  min?: Maybe<Phone_Numbers_Min_Fields>;
};

/** aggregate fields of "phone_numbers" */
export type Phone_Numbers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Phone_Numbers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "phone_numbers" */
export type Phone_Numbers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Phone_Numbers_Max_Order_By>;
  min?: InputMaybe<Phone_Numbers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "phone_numbers" */
export type Phone_Numbers_Arr_Rel_Insert_Input = {
  data: Array<Phone_Numbers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Phone_Numbers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "phone_numbers". All fields are combined with a logical 'AND'. */
export type Phone_Numbers_Bool_Exp = {
  _and?: InputMaybe<Array<Phone_Numbers_Bool_Exp>>;
  _not?: InputMaybe<Phone_Numbers_Bool_Exp>;
  _or?: InputMaybe<Array<Phone_Numbers_Bool_Exp>>;
  clinic?: InputMaybe<Clinics_Bool_Exp>;
  clinicId?: InputMaybe<Uuid_Comparison_Exp>;
  communication_histories?: InputMaybe<Communication_History_Bool_Exp>;
  communication_histories_aggregate?: InputMaybe<Communication_History_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  extension?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "phone_numbers" */
export type Phone_Numbers_Constraint =
  /** unique or primary key constraint on columns "id" */
  'phone_numbers_pkey';

/** input type for inserting data into table "phone_numbers" */
export type Phone_Numbers_Insert_Input = {
  clinic?: InputMaybe<Clinics_Obj_Rel_Insert_Input>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  communication_histories?: InputMaybe<Communication_History_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  extension?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Phone_Numbers_Max_Fields = {
  __typename?: 'phone_numbers_max_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "phone_numbers" */
export type Phone_Numbers_Max_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  extension?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Phone_Numbers_Min_Fields = {
  __typename?: 'phone_numbers_min_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "phone_numbers" */
export type Phone_Numbers_Min_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  extension?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "phone_numbers" */
export type Phone_Numbers_Mutation_Response = {
  __typename?: 'phone_numbers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Phone_Numbers>;
};

/** input type for inserting object relation for remote table "phone_numbers" */
export type Phone_Numbers_Obj_Rel_Insert_Input = {
  data: Phone_Numbers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Phone_Numbers_On_Conflict>;
};

/** on_conflict condition type for table "phone_numbers" */
export type Phone_Numbers_On_Conflict = {
  constraint: Phone_Numbers_Constraint;
  update_columns?: Array<Phone_Numbers_Update_Column>;
  where?: InputMaybe<Phone_Numbers_Bool_Exp>;
};

/** Ordering options when selecting data from "phone_numbers". */
export type Phone_Numbers_Order_By = {
  clinic?: InputMaybe<Clinics_Order_By>;
  clinicId?: InputMaybe<Order_By>;
  communication_histories_aggregate?: InputMaybe<Communication_History_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  extension?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: phone_numbers */
export type Phone_Numbers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "phone_numbers" */
export type Phone_Numbers_Select_Column =
  /** column name */
  | 'clinicId'
  /** column name */
  | 'description'
  /** column name */
  | 'extension'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'phoneNumber'
  /** column name */
  | 'staffId';

/** input type for updating data in table "phone_numbers" */
export type Phone_Numbers_Set_Input = {
  clinicId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  extension?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "phone_numbers" */
export type Phone_Numbers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Phone_Numbers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Phone_Numbers_Stream_Cursor_Value_Input = {
  clinicId?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  extension?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "phone_numbers" */
export type Phone_Numbers_Update_Column =
  /** column name */
  | 'clinicId'
  /** column name */
  | 'description'
  /** column name */
  | 'extension'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'phoneNumber'
  /** column name */
  | 'staffId';

export type Phone_Numbers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Phone_Numbers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Phone_Numbers_Bool_Exp;
};

/** columns and relationships of "problem_status_types" */
export type Problem_Status_Types = {
  __typename?: 'problem_status_types';
  description: Scalars['String'];
  /** An array relationship */
  problems: Array<Problems>;
  /** An aggregate relationship */
  problems_aggregate: Problems_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "problem_status_types" */
export type Problem_Status_TypesProblemsArgs = {
  distinct_on?: InputMaybe<Array<Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Problems_Order_By>>;
  where?: InputMaybe<Problems_Bool_Exp>;
};

/** columns and relationships of "problem_status_types" */
export type Problem_Status_TypesProblems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Problems_Order_By>>;
  where?: InputMaybe<Problems_Bool_Exp>;
};

/** aggregated selection of "problem_status_types" */
export type Problem_Status_Types_Aggregate = {
  __typename?: 'problem_status_types_aggregate';
  aggregate?: Maybe<Problem_Status_Types_Aggregate_Fields>;
  nodes: Array<Problem_Status_Types>;
};

/** aggregate fields of "problem_status_types" */
export type Problem_Status_Types_Aggregate_Fields = {
  __typename?: 'problem_status_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Problem_Status_Types_Max_Fields>;
  min?: Maybe<Problem_Status_Types_Min_Fields>;
};

/** aggregate fields of "problem_status_types" */
export type Problem_Status_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Problem_Status_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "problem_status_types". All fields are combined with a logical 'AND'. */
export type Problem_Status_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Problem_Status_Types_Bool_Exp>>;
  _not?: InputMaybe<Problem_Status_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Problem_Status_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  problems?: InputMaybe<Problems_Bool_Exp>;
  problems_aggregate?: InputMaybe<Problems_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "problem_status_types" */
export type Problem_Status_Types_Constraint =
  /** unique or primary key constraint on columns "value" */
  'problem_status_types_pkey';

export type Problem_Status_Types_Enum =
  /** Ongoing problems shown in patient header. */
  | 'ACTIVE'
  /** Entered as error */
  | 'ERROR'
  /** Relevant problems not shown in patient header */
  | 'PERTINENT'
  /** Resolved problems that are no longer active */
  | 'RESOLVED';

/** Boolean expression to compare columns of type "problem_status_types_enum". All fields are combined with logical 'AND'. */
export type Problem_Status_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Problem_Status_Types_Enum>;
  _in?: InputMaybe<Array<Problem_Status_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Problem_Status_Types_Enum>;
  _nin?: InputMaybe<Array<Problem_Status_Types_Enum>>;
};

/** input type for inserting data into table "problem_status_types" */
export type Problem_Status_Types_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  problems?: InputMaybe<Problems_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Problem_Status_Types_Max_Fields = {
  __typename?: 'problem_status_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Problem_Status_Types_Min_Fields = {
  __typename?: 'problem_status_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "problem_status_types" */
export type Problem_Status_Types_Mutation_Response = {
  __typename?: 'problem_status_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Problem_Status_Types>;
};

/** input type for inserting object relation for remote table "problem_status_types" */
export type Problem_Status_Types_Obj_Rel_Insert_Input = {
  data: Problem_Status_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Problem_Status_Types_On_Conflict>;
};

/** on_conflict condition type for table "problem_status_types" */
export type Problem_Status_Types_On_Conflict = {
  constraint: Problem_Status_Types_Constraint;
  update_columns?: Array<Problem_Status_Types_Update_Column>;
  where?: InputMaybe<Problem_Status_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "problem_status_types". */
export type Problem_Status_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  problems_aggregate?: InputMaybe<Problems_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: problem_status_types */
export type Problem_Status_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "problem_status_types" */
export type Problem_Status_Types_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "problem_status_types" */
export type Problem_Status_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "problem_status_types" */
export type Problem_Status_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Problem_Status_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Problem_Status_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "problem_status_types" */
export type Problem_Status_Types_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Problem_Status_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Problem_Status_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Problem_Status_Types_Bool_Exp;
};

/** columns and relationships of "problems" */
export type Problems = {
  __typename?: 'problems';
  /** An object relationship */
  appointment?: Maybe<Appointments>;
  appointmentId?: Maybe<Scalars['uuid']>;
  codeICD10: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  endedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  patient: Patients;
  patientId: Scalars['uuid'];
  /** An object relationship */
  problem_status_type: Problem_Status_Types;
  startedAt?: Maybe<Scalars['timestamptz']>;
  status: Problem_Status_Types_Enum;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "problems" */
export type Problems_Aggregate = {
  __typename?: 'problems_aggregate';
  aggregate?: Maybe<Problems_Aggregate_Fields>;
  nodes: Array<Problems>;
};

export type Problems_Aggregate_Bool_Exp = {
  count?: InputMaybe<Problems_Aggregate_Bool_Exp_Count>;
};

export type Problems_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Problems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Problems_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "problems" */
export type Problems_Aggregate_Fields = {
  __typename?: 'problems_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Problems_Max_Fields>;
  min?: Maybe<Problems_Min_Fields>;
};

/** aggregate fields of "problems" */
export type Problems_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Problems_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "problems" */
export type Problems_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Problems_Max_Order_By>;
  min?: InputMaybe<Problems_Min_Order_By>;
};

/** input type for inserting array relation for remote table "problems" */
export type Problems_Arr_Rel_Insert_Input = {
  data: Array<Problems_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Problems_On_Conflict>;
};

/** Boolean expression to filter rows from the table "problems". All fields are combined with a logical 'AND'. */
export type Problems_Bool_Exp = {
  _and?: InputMaybe<Array<Problems_Bool_Exp>>;
  _not?: InputMaybe<Problems_Bool_Exp>;
  _or?: InputMaybe<Array<Problems_Bool_Exp>>;
  appointment?: InputMaybe<Appointments_Bool_Exp>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  codeICD10?: InputMaybe<String_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  endedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  problem_status_type?: InputMaybe<Problem_Status_Types_Bool_Exp>;
  startedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<Problem_Status_Types_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "problems" */
export type Problems_Constraint =
  /** unique or primary key constraint on columns "id" */
  'problems_pkey';

/** input type for inserting data into table "problems" */
export type Problems_Insert_Input = {
  appointment?: InputMaybe<Appointments_Obj_Rel_Insert_Input>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  codeICD10?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  endedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  problem_status_type?: InputMaybe<Problem_Status_Types_Obj_Rel_Insert_Input>;
  startedAt?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Problem_Status_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Problems_Max_Fields = {
  __typename?: 'problems_max_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  codeICD10?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  endedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  startedAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "problems" */
export type Problems_Max_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  codeICD10?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  endedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  startedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Problems_Min_Fields = {
  __typename?: 'problems_min_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  codeICD10?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  endedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  startedAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "problems" */
export type Problems_Min_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  codeICD10?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  endedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  startedAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "problems" */
export type Problems_Mutation_Response = {
  __typename?: 'problems_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Problems>;
};

/** on_conflict condition type for table "problems" */
export type Problems_On_Conflict = {
  constraint: Problems_Constraint;
  update_columns?: Array<Problems_Update_Column>;
  where?: InputMaybe<Problems_Bool_Exp>;
};

/** Ordering options when selecting data from "problems". */
export type Problems_Order_By = {
  appointment?: InputMaybe<Appointments_Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  codeICD10?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  endedAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  problem_status_type?: InputMaybe<Problem_Status_Types_Order_By>;
  startedAt?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: problems */
export type Problems_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "problems" */
export type Problems_Select_Column =
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'codeICD10'
  /** column name */
  | 'comments'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'description'
  /** column name */
  | 'endedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'patientId'
  /** column name */
  | 'startedAt'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "problems" */
export type Problems_Set_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  codeICD10?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  endedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  startedAt?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Problem_Status_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "problems" */
export type Problems_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Problems_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Problems_Stream_Cursor_Value_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  codeICD10?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  endedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  startedAt?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Problem_Status_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "problems" */
export type Problems_Update_Column =
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'codeICD10'
  /** column name */
  | 'comments'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'description'
  /** column name */
  | 'endedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'patientId'
  /** column name */
  | 'startedAt'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt';

export type Problems_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Problems_Set_Input>;
  /** filter the rows which have to be updated */
  where: Problems_Bool_Exp;
};

/** columns and relationships of "products" */
export type Products = {
  __typename?: 'products';
  /** An array relationship */
  charge_products: Array<Charge_Products>;
  /** An aggregate relationship */
  charge_products_aggregate: Charge_Products_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  price: Scalars['numeric'];
  type: Scalars['String'];
};

/** columns and relationships of "products" */
export type ProductsCharge_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Charge_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charge_Products_Order_By>>;
  where?: InputMaybe<Charge_Products_Bool_Exp>;
};

/** columns and relationships of "products" */
export type ProductsCharge_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Charge_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charge_Products_Order_By>>;
  where?: InputMaybe<Charge_Products_Bool_Exp>;
};

/** aggregated selection of "products" */
export type Products_Aggregate = {
  __typename?: 'products_aggregate';
  aggregate?: Maybe<Products_Aggregate_Fields>;
  nodes: Array<Products>;
};

/** aggregate fields of "products" */
export type Products_Aggregate_Fields = {
  __typename?: 'products_aggregate_fields';
  avg?: Maybe<Products_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Products_Max_Fields>;
  min?: Maybe<Products_Min_Fields>;
  stddev?: Maybe<Products_Stddev_Fields>;
  stddev_pop?: Maybe<Products_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Products_Stddev_Samp_Fields>;
  sum?: Maybe<Products_Sum_Fields>;
  var_pop?: Maybe<Products_Var_Pop_Fields>;
  var_samp?: Maybe<Products_Var_Samp_Fields>;
  variance?: Maybe<Products_Variance_Fields>;
};

/** aggregate fields of "products" */
export type Products_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Products_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Products_Avg_Fields = {
  __typename?: 'products_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
export type Products_Bool_Exp = {
  _and?: InputMaybe<Array<Products_Bool_Exp>>;
  _not?: InputMaybe<Products_Bool_Exp>;
  _or?: InputMaybe<Array<Products_Bool_Exp>>;
  charge_products?: InputMaybe<Charge_Products_Bool_Exp>;
  charge_products_aggregate?: InputMaybe<Charge_Products_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "products" */
export type Products_Constraint =
  /** unique or primary key constraint on columns "id" */
  'products_pkey';

/** input type for incrementing numeric columns in table "products" */
export type Products_Inc_Input = {
  price?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "products" */
export type Products_Insert_Input = {
  charge_products?: InputMaybe<Charge_Products_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Products_Max_Fields = {
  __typename?: 'products_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Products_Min_Fields = {
  __typename?: 'products_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "products" */
export type Products_Mutation_Response = {
  __typename?: 'products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Products>;
};

/** input type for inserting object relation for remote table "products" */
export type Products_Obj_Rel_Insert_Input = {
  data: Products_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Products_On_Conflict>;
};

/** on_conflict condition type for table "products" */
export type Products_On_Conflict = {
  constraint: Products_Constraint;
  update_columns?: Array<Products_Update_Column>;
  where?: InputMaybe<Products_Bool_Exp>;
};

/** Ordering options when selecting data from "products". */
export type Products_Order_By = {
  charge_products_aggregate?: InputMaybe<Charge_Products_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: products */
export type Products_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "products" */
export type Products_Select_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'price'
  /** column name */
  | 'type';

/** input type for updating data in table "products" */
export type Products_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Products_Stddev_Fields = {
  __typename?: 'products_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Products_Stddev_Pop_Fields = {
  __typename?: 'products_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Products_Stddev_Samp_Fields = {
  __typename?: 'products_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "products" */
export type Products_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Products_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Products_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Products_Sum_Fields = {
  __typename?: 'products_sum_fields';
  price?: Maybe<Scalars['numeric']>;
};

/** update columns of table "products" */
export type Products_Update_Column =
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'price'
  /** column name */
  | 'type';

export type Products_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Products_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Products_Set_Input>;
  /** filter the rows which have to be updated */
  where: Products_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Products_Var_Pop_Fields = {
  __typename?: 'products_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Products_Var_Samp_Fields = {
  __typename?: 'products_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Products_Variance_Fields = {
  __typename?: 'products_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  AutocompleteLocations: Array<GooglePlace>;
  DetectDocumentText?: Maybe<DocumentDetectOutput>;
  FindPlace: GooglePlaceData;
  GetAllAvailableVisitTypeTimes?: Maybe<
    Array<GetAllAvailableVisitTypeTimesOutput>
  >;
  GetAvailableAppointmentsByStaffInRange: GetAvailableAppointmentsByStaffInRangeOutput;
  GetAvailableAppointmentsInRange: GetAvailableAppointmentsInRangeOutput;
  /** Get the currently logged in staff member's info */
  GetCurrentStaffInfo?: Maybe<StaffOutput>;
  GetDocument?: Maybe<DocumentPayload>;
  GetFile?: Maybe<GetFileOutput>;
  GetNextTimeSlot?: Maybe<TimeOutput>;
  /** GetSchedule */
  GetSchedule: GetScheduleOutput;
  GetStaffInfo?: Maybe<GetStaffInfoOutput>;
  GetTheoreticalAvailability?: Maybe<
    Array<Maybe<TheoreticalAvailabilityOutput>>
  >;
  GetTheoreticalAvailabilityClinic?: Maybe<
    Array<GetTheoreticalAvailabilityClinicOutput>
  >;
  GetTheoreticalAvailabilityRange?: Maybe<
    Array<GetTheoreticalAvailabilityRangeOutput>
  >;
  LoadPrismicBlogPosts: LoadPrismicBlogPostsOutput;
  LoadPrismicVaccineFaqs?: Maybe<LoadPrismicVaccineFaqsOutput>;
  LoadPrismicVaccineSchedule?: Maybe<LoadPrismicVaccineScheduleOutput>;
  LoadPrismicVaccines: Array<PrismicVaccineDetail>;
  NextTheoreticalAvailability?: Maybe<TheoreticalAvailabilityOutput>;
  NextTheoreticalStaffAvailability?: Maybe<TheoreticalAvailabilityOutput>;
  RetrieveVerificationCode?: Maybe<RetrieveVerificationCodeOutput>;
  SNOMEDMapping?: Maybe<SnomedMappingOutput>;
  SNOMEDSearch?: Maybe<SnomedSearchOutput>;
  SearchLocations: Array<PartialGooglePlace>;
  StripeCustomerPortalSession: StripeCustomerPortalSessionResponse;
  StripePaymentMethod: StripeCard;
  StripePaymentMethodByChargeId: StripePaymentMethodByChargeIdOutput;
  StripePaymentMethods: Array<StripeCard>;
  StripeSubscription?: Maybe<StripeSubscription>;
  /** fetch data from the table: "account_patient" */
  account_patient: Array<Account_Patient>;
  /** fetch aggregated fields from the table: "account_patient" */
  account_patient_aggregate: Account_Patient_Aggregate;
  /** fetch data from the table: "account_patient" using primary key columns */
  account_patient_by_pk?: Maybe<Account_Patient>;
  /** fetch data from the table: "account_temporary_verification" */
  account_temporary_verification: Array<Account_Temporary_Verification>;
  /** fetch aggregated fields from the table: "account_temporary_verification" */
  account_temporary_verification_aggregate: Account_Temporary_Verification_Aggregate;
  /** fetch data from the table: "account_temporary_verification" using primary key columns */
  account_temporary_verification_by_pk?: Maybe<Account_Temporary_Verification>;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Accounts_Aggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table: "accounts_limited" */
  accounts_limited: Array<Accounts_Limited>;
  /** fetch aggregated fields from the table: "accounts_limited" */
  accounts_limited_aggregate: Accounts_Limited_Aggregate;
  /** An array relationship */
  allergies: Array<Allergies>;
  /** An aggregate relationship */
  allergies_aggregate: Allergies_Aggregate;
  /** fetch data from the table: "allergies" using primary key columns */
  allergies_by_pk?: Maybe<Allergies>;
  /** fetch data from the table: "allergies_severity" */
  allergies_severity: Array<Allergies_Severity>;
  /** fetch aggregated fields from the table: "allergies_severity" */
  allergies_severity_aggregate: Allergies_Severity_Aggregate;
  /** fetch data from the table: "allergies_severity" using primary key columns */
  allergies_severity_by_pk?: Maybe<Allergies_Severity>;
  /** fetch data from the table: "allergies_status" */
  allergies_status: Array<Allergies_Status>;
  /** fetch aggregated fields from the table: "allergies_status" */
  allergies_status_aggregate: Allergies_Status_Aggregate;
  /** fetch data from the table: "allergies_status" using primary key columns */
  allergies_status_by_pk?: Maybe<Allergies_Status>;
  /** An array relationship */
  appointment_blocks: Array<Appointment_Blocks>;
  /** An aggregate relationship */
  appointment_blocks_aggregate: Appointment_Blocks_Aggregate;
  /** fetch data from the table: "appointment_blocks" using primary key columns */
  appointment_blocks_by_pk?: Maybe<Appointment_Blocks>;
  /** fetch data from the table: "appointment_blocks_staff" */
  appointment_blocks_staff: Array<Appointment_Blocks_Staff>;
  /** fetch aggregated fields from the table: "appointment_blocks_staff" */
  appointment_blocks_staff_aggregate: Appointment_Blocks_Staff_Aggregate;
  /** fetch data from the table: "appointment_blocks_staff" using primary key columns */
  appointment_blocks_staff_by_pk?: Maybe<Appointment_Blocks_Staff>;
  /** fetch data from the table: "appointment_counts" */
  appointment_counts: Array<Appointment_Counts>;
  /** fetch aggregated fields from the table: "appointment_counts" */
  appointment_counts_aggregate: Appointment_Counts_Aggregate;
  /** fetch data from the table: "appointment_patient" */
  appointment_patient: Array<Appointment_Patient>;
  /** fetch aggregated fields from the table: "appointment_patient" */
  appointment_patient_aggregate: Appointment_Patient_Aggregate;
  /** fetch data from the table: "appointment_patient" using primary key columns */
  appointment_patient_by_pk?: Maybe<Appointment_Patient>;
  /** fetch data from the table: "appointment_payment" */
  appointment_payment: Array<Appointment_Payment>;
  /** fetch aggregated fields from the table: "appointment_payment" */
  appointment_payment_aggregate: Appointment_Payment_Aggregate;
  /** fetch data from the table: "appointment_payment" using primary key columns */
  appointment_payment_by_pk?: Maybe<Appointment_Payment>;
  /** An array relationship */
  appointment_reservations: Array<Appointment_Reservations>;
  /** An aggregate relationship */
  appointment_reservations_aggregate: Appointment_Reservations_Aggregate;
  /** fetch data from the table: "appointment_reservations" using primary key columns */
  appointment_reservations_by_pk?: Maybe<Appointment_Reservations>;
  /** fetch data from the table: "appointment_staff" */
  appointment_staff: Array<Appointment_Staff>;
  /** fetch aggregated fields from the table: "appointment_staff" */
  appointment_staff_aggregate: Appointment_Staff_Aggregate;
  /** fetch data from the table: "appointment_staff" using primary key columns */
  appointment_staff_by_pk?: Maybe<Appointment_Staff>;
  /** fetch data from the table: "appointment_status" */
  appointment_status: Array<Appointment_Status>;
  /** fetch aggregated fields from the table: "appointment_status" */
  appointment_status_aggregate: Appointment_Status_Aggregate;
  /** fetch data from the table: "appointment_status" using primary key columns */
  appointment_status_by_pk?: Maybe<Appointment_Status>;
  /** fetch data from the table: "appointment_types" */
  appointment_types: Array<Appointment_Types>;
  /** fetch aggregated fields from the table: "appointment_types" */
  appointment_types_aggregate: Appointment_Types_Aggregate;
  /** fetch data from the table: "appointment_types" using primary key columns */
  appointment_types_by_pk?: Maybe<Appointment_Types>;
  /** An array relationship */
  appointments: Array<Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Appointments_Aggregate;
  /** fetch data from the table: "appointments" using primary key columns */
  appointments_by_pk?: Maybe<Appointments>;
  /** An array relationship */
  audit_logs: Array<Audit_Logs>;
  /** An aggregate relationship */
  audit_logs_aggregate: Audit_Logs_Aggregate;
  /** fetch data from the table: "audit_logs" using primary key columns */
  audit_logs_by_pk?: Maybe<Audit_Logs>;
  authRoles: Array<Maybe<Scalars['String']>>;
  /** fetch data from the table: "care_type_duration" */
  care_type_duration: Array<Care_Type_Duration>;
  /** fetch aggregated fields from the table: "care_type_duration" */
  care_type_duration_aggregate: Care_Type_Duration_Aggregate;
  /** fetch data from the table: "care_type_duration" using primary key columns */
  care_type_duration_by_pk?: Maybe<Care_Type_Duration>;
  /** fetch data from the table: "care_types" */
  care_types: Array<Care_Types>;
  /** fetch aggregated fields from the table: "care_types" */
  care_types_aggregate: Care_Types_Aggregate;
  /** fetch data from the table: "care_types" using primary key columns */
  care_types_by_pk?: Maybe<Care_Types>;
  /** An array relationship */
  charge_products: Array<Charge_Products>;
  /** An aggregate relationship */
  charge_products_aggregate: Charge_Products_Aggregate;
  /** fetch data from the table: "charge_products" using primary key columns */
  charge_products_by_pk?: Maybe<Charge_Products>;
  /** An array relationship */
  charges: Array<Charges>;
  /** An aggregate relationship */
  charges_aggregate: Charges_Aggregate;
  /** fetch data from the table: "charges" using primary key columns */
  charges_by_pk?: Maybe<Charges>;
  /** An array relationship */
  chat_messages: Array<Chat_Messages>;
  /** An aggregate relationship */
  chat_messages_aggregate: Chat_Messages_Aggregate;
  /** fetch data from the table: "chat_messages" using primary key columns */
  chat_messages_by_pk?: Maybe<Chat_Messages>;
  /** fetch data from the table: "chat_messages_file" */
  chat_messages_file: Array<Chat_Messages_File>;
  /** fetch aggregated fields from the table: "chat_messages_file" */
  chat_messages_file_aggregate: Chat_Messages_File_Aggregate;
  /** fetch data from the table: "chat_messages_file" using primary key columns */
  chat_messages_file_by_pk?: Maybe<Chat_Messages_File>;
  /** fetch data from the table: "chat_messages_type" */
  chat_messages_type: Array<Chat_Messages_Type>;
  /** fetch aggregated fields from the table: "chat_messages_type" */
  chat_messages_type_aggregate: Chat_Messages_Type_Aggregate;
  /** fetch data from the table: "chat_messages_type" using primary key columns */
  chat_messages_type_by_pk?: Maybe<Chat_Messages_Type>;
  /** An array relationship */
  chat_notes: Array<Chat_Notes>;
  /** An aggregate relationship */
  chat_notes_aggregate: Chat_Notes_Aggregate;
  /** fetch data from the table: "chat_notes" using primary key columns */
  chat_notes_by_pk?: Maybe<Chat_Notes>;
  /** fetch data from the table: "chat_notes_types" */
  chat_notes_types: Array<Chat_Notes_Types>;
  /** fetch aggregated fields from the table: "chat_notes_types" */
  chat_notes_types_aggregate: Chat_Notes_Types_Aggregate;
  /** fetch data from the table: "chat_notes_types" using primary key columns */
  chat_notes_types_by_pk?: Maybe<Chat_Notes_Types>;
  /** An array relationship */
  chat_sessions: Array<Chat_Sessions>;
  /** An array relationship */
  chat_sessions_accounts: Array<Chat_Sessions_Accounts>;
  /** An aggregate relationship */
  chat_sessions_accounts_aggregate: Chat_Sessions_Accounts_Aggregate;
  /** fetch data from the table: "chat_sessions_accounts" using primary key columns */
  chat_sessions_accounts_by_pk?: Maybe<Chat_Sessions_Accounts>;
  /** An aggregate relationship */
  chat_sessions_aggregate: Chat_Sessions_Aggregate;
  /** fetch data from the table: "chat_sessions" using primary key columns */
  chat_sessions_by_pk?: Maybe<Chat_Sessions>;
  /** An array relationship */
  chat_sessions_patients: Array<Chat_Sessions_Patients>;
  /** An aggregate relationship */
  chat_sessions_patients_aggregate: Chat_Sessions_Patients_Aggregate;
  /** fetch data from the table: "chat_sessions_patients" using primary key columns */
  chat_sessions_patients_by_pk?: Maybe<Chat_Sessions_Patients>;
  /** fetch data from the table: "chat_sessions_read" */
  chat_sessions_read: Array<Chat_Sessions_Read>;
  /** fetch aggregated fields from the table: "chat_sessions_read" */
  chat_sessions_read_aggregate: Chat_Sessions_Read_Aggregate;
  /** fetch data from the table: "chat_sessions_read" using primary key columns */
  chat_sessions_read_by_pk?: Maybe<Chat_Sessions_Read>;
  /** fetch data from the table: "chat_sessions_staff" */
  chat_sessions_staff: Array<Chat_Sessions_Staff>;
  /** fetch aggregated fields from the table: "chat_sessions_staff" */
  chat_sessions_staff_aggregate: Chat_Sessions_Staff_Aggregate;
  /** fetch data from the table: "chat_sessions_staff" using primary key columns */
  chat_sessions_staff_by_pk?: Maybe<Chat_Sessions_Staff>;
  /** fetch data from the table: "chat_sessions_status" */
  chat_sessions_status: Array<Chat_Sessions_Status>;
  /** fetch aggregated fields from the table: "chat_sessions_status" */
  chat_sessions_status_aggregate: Chat_Sessions_Status_Aggregate;
  /** fetch data from the table: "chat_sessions_status" using primary key columns */
  chat_sessions_status_by_pk?: Maybe<Chat_Sessions_Status>;
  /** fetch data from the table: "clinic_location_types" */
  clinic_location_types: Array<Clinic_Location_Types>;
  /** fetch aggregated fields from the table: "clinic_location_types" */
  clinic_location_types_aggregate: Clinic_Location_Types_Aggregate;
  /** fetch data from the table: "clinic_location_types" using primary key columns */
  clinic_location_types_by_pk?: Maybe<Clinic_Location_Types>;
  /** fetch data from the table: "clinic_status" */
  clinic_status: Array<Clinic_Status>;
  /** fetch aggregated fields from the table: "clinic_status" */
  clinic_status_aggregate: Clinic_Status_Aggregate;
  /** fetch data from the table: "clinic_status" using primary key columns */
  clinic_status_by_pk?: Maybe<Clinic_Status>;
  /** An array relationship */
  clinics: Array<Clinics>;
  /** An aggregate relationship */
  clinics_aggregate: Clinics_Aggregate;
  /** fetch data from the table: "clinics" using primary key columns */
  clinics_by_pk?: Maybe<Clinics>;
  /** An array relationship */
  clinics_properties: Array<Clinics_Properties>;
  /** An aggregate relationship */
  clinics_properties_aggregate: Clinics_Properties_Aggregate;
  /** fetch data from the table: "clinics_properties" using primary key columns */
  clinics_properties_by_pk?: Maybe<Clinics_Properties>;
  /** fetch data from the table: "clinics_properties_types" */
  clinics_properties_types: Array<Clinics_Properties_Types>;
  /** fetch aggregated fields from the table: "clinics_properties_types" */
  clinics_properties_types_aggregate: Clinics_Properties_Types_Aggregate;
  /** fetch data from the table: "clinics_properties_types" using primary key columns */
  clinics_properties_types_by_pk?: Maybe<Clinics_Properties_Types>;
  /** An array relationship */
  clinics_regions: Array<Clinics_Regions>;
  /** An aggregate relationship */
  clinics_regions_aggregate: Clinics_Regions_Aggregate;
  /** fetch data from the table: "clinics_regions" using primary key columns */
  clinics_regions_by_pk?: Maybe<Clinics_Regions>;
  /** fetch data from the table: "communication_history" */
  communication_history: Array<Communication_History>;
  /** fetch aggregated fields from the table: "communication_history" */
  communication_history_aggregate: Communication_History_Aggregate;
  /** fetch data from the table: "communication_history" using primary key columns */
  communication_history_by_pk?: Maybe<Communication_History>;
  /** fetch data from the table: "communication_history_directions" */
  communication_history_directions: Array<Communication_History_Directions>;
  /** fetch aggregated fields from the table: "communication_history_directions" */
  communication_history_directions_aggregate: Communication_History_Directions_Aggregate;
  /** fetch data from the table: "communication_history_directions" using primary key columns */
  communication_history_directions_by_pk?: Maybe<Communication_History_Directions>;
  /** fetch data from the table: "communication_history_tag_types" */
  communication_history_tag_types: Array<Communication_History_Tag_Types>;
  /** fetch aggregated fields from the table: "communication_history_tag_types" */
  communication_history_tag_types_aggregate: Communication_History_Tag_Types_Aggregate;
  /** fetch data from the table: "communication_history_tag_types" using primary key columns */
  communication_history_tag_types_by_pk?: Maybe<Communication_History_Tag_Types>;
  /** An array relationship */
  communication_history_tags: Array<Communication_History_Tags>;
  /** An aggregate relationship */
  communication_history_tags_aggregate: Communication_History_Tags_Aggregate;
  /** fetch data from the table: "communication_history_tags" using primary key columns */
  communication_history_tags_by_pk?: Maybe<Communication_History_Tags>;
  /** fetch data from the table: "communication_history_types" */
  communication_history_types: Array<Communication_History_Types>;
  /** fetch aggregated fields from the table: "communication_history_types" */
  communication_history_types_aggregate: Communication_History_Types_Aggregate;
  /** fetch data from the table: "communication_history_types" using primary key columns */
  communication_history_types_by_pk?: Maybe<Communication_History_Types>;
  /** An array relationship */
  devices: Array<Devices>;
  /** An aggregate relationship */
  devices_aggregate: Devices_Aggregate;
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk?: Maybe<Devices>;
  /** fetch data from the table: "discovered_from" */
  discovered_from: Array<Discovered_From>;
  /** fetch aggregated fields from the table: "discovered_from" */
  discovered_from_aggregate: Discovered_From_Aggregate;
  /** fetch data from the table: "discovered_from" using primary key columns */
  discovered_from_by_pk?: Maybe<Discovered_From>;
  /** fetch data from the table: "document_status_types" */
  document_status_types: Array<Document_Status_Types>;
  /** fetch aggregated fields from the table: "document_status_types" */
  document_status_types_aggregate: Document_Status_Types_Aggregate;
  /** fetch data from the table: "document_status_types" using primary key columns */
  document_status_types_by_pk?: Maybe<Document_Status_Types>;
  /** fetch data from the table: "document_types" */
  document_types: Array<Document_Types>;
  /** fetch aggregated fields from the table: "document_types" */
  document_types_aggregate: Document_Types_Aggregate;
  /** fetch data from the table: "document_types" using primary key columns */
  document_types_by_pk?: Maybe<Document_Types>;
  /** An array relationship */
  documents: Array<Documents>;
  /** An aggregate relationship */
  documents_aggregate: Documents_Aggregate;
  /** fetch data from the table: "documents" using primary key columns */
  documents_by_pk?: Maybe<Documents>;
  /** fetch data from the table: "fhir_resource_types" */
  fhir_resource_types: Array<Fhir_Resource_Types>;
  /** fetch aggregated fields from the table: "fhir_resource_types" */
  fhir_resource_types_aggregate: Fhir_Resource_Types_Aggregate;
  /** fetch data from the table: "fhir_resource_types" using primary key columns */
  fhir_resource_types_by_pk?: Maybe<Fhir_Resource_Types>;
  /** An array relationship */
  fhir_resources: Array<Fhir_Resources>;
  /** An aggregate relationship */
  fhir_resources_aggregate: Fhir_Resources_Aggregate;
  /** fetch data from the table: "fhir_resources" using primary key columns */
  fhir_resources_by_pk?: Maybe<Fhir_Resources>;
  /** An array relationship */
  fhir_resources_coded: Array<Fhir_Resources_Coded>;
  /** An aggregate relationship */
  fhir_resources_coded_aggregate: Fhir_Resources_Coded_Aggregate;
  /** fetch data from the table: "fhir_resources_history" */
  fhir_resources_history: Array<Fhir_Resources_History>;
  /** fetch aggregated fields from the table: "fhir_resources_history" */
  fhir_resources_history_aggregate: Fhir_Resources_History_Aggregate;
  /** fetch data from the table: "fhir_resources_history" using primary key columns */
  fhir_resources_history_by_pk?: Maybe<Fhir_Resources_History>;
  /** fetch data from the table: "file_document_type" */
  file_document_type: Array<File_Document_Type>;
  /** fetch aggregated fields from the table: "file_document_type" */
  file_document_type_aggregate: File_Document_Type_Aggregate;
  /** fetch data from the table: "file_document_type" using primary key columns */
  file_document_type_by_pk?: Maybe<File_Document_Type>;
  /** An array relationship */
  files: Array<Files>;
  /** An aggregate relationship */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  getFileByUUID?: Maybe<FilePayload>;
  /** An array relationship */
  global_notes: Array<Global_Notes>;
  /** An aggregate relationship */
  global_notes_aggregate: Global_Notes_Aggregate;
  /** fetch data from the table: "global_notes" using primary key columns */
  global_notes_by_pk?: Maybe<Global_Notes>;
  /** fetch data from the table: "insurance" */
  insurance: Array<Insurance>;
  /** fetch aggregated fields from the table: "insurance" */
  insurance_aggregate: Insurance_Aggregate;
  /** fetch data from the table: "insurance" using primary key columns */
  insurance_by_pk?: Maybe<Insurance>;
  /** fetch data from the table: "insurance_providers" */
  insurance_providers: Array<Insurance_Providers>;
  /** fetch aggregated fields from the table: "insurance_providers" */
  insurance_providers_aggregate: Insurance_Providers_Aggregate;
  /** fetch data from the table: "insurance_providers" using primary key columns */
  insurance_providers_by_pk?: Maybe<Insurance_Providers>;
  /** fetch data from the table: "insurance_types" */
  insurance_types: Array<Insurance_Types>;
  /** fetch aggregated fields from the table: "insurance_types" */
  insurance_types_aggregate: Insurance_Types_Aggregate;
  /** fetch data from the table: "insurance_types" using primary key columns */
  insurance_types_by_pk?: Maybe<Insurance_Types>;
  /** An array relationship */
  kareo_logs: Array<Kareo_Logs>;
  /** An aggregate relationship */
  kareo_logs_aggregate: Kareo_Logs_Aggregate;
  /** fetch data from the table: "kareo_logs" using primary key columns */
  kareo_logs_by_pk?: Maybe<Kareo_Logs>;
  /** An array relationship */
  locations: Array<Locations>;
  /** An aggregate relationship */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table: "medical_history" */
  medical_history: Array<Medical_History>;
  /** fetch aggregated fields from the table: "medical_history" */
  medical_history_aggregate: Medical_History_Aggregate;
  /** An array relationship */
  medical_history_answers: Array<Medical_History_Answers>;
  /** An aggregate relationship */
  medical_history_answers_aggregate: Medical_History_Answers_Aggregate;
  /** fetch data from the table: "medical_history_answers" using primary key columns */
  medical_history_answers_by_pk?: Maybe<Medical_History_Answers>;
  /** fetch data from the table: "medical_history" using primary key columns */
  medical_history_by_pk?: Maybe<Medical_History>;
  /** fetch data from the table: "medical_history_person_types" */
  medical_history_person_types: Array<Medical_History_Person_Types>;
  /** fetch aggregated fields from the table: "medical_history_person_types" */
  medical_history_person_types_aggregate: Medical_History_Person_Types_Aggregate;
  /** fetch data from the table: "medical_history_person_types" using primary key columns */
  medical_history_person_types_by_pk?: Maybe<Medical_History_Person_Types>;
  /** fetch data from the table: "medical_history_questions" */
  medical_history_questions: Array<Medical_History_Questions>;
  /** fetch aggregated fields from the table: "medical_history_questions" */
  medical_history_questions_aggregate: Medical_History_Questions_Aggregate;
  /** fetch data from the table: "medical_history_questions" using primary key columns */
  medical_history_questions_by_pk?: Maybe<Medical_History_Questions>;
  /** fetch data from the table: "medical_history_types" */
  medical_history_types: Array<Medical_History_Types>;
  /** fetch aggregated fields from the table: "medical_history_types" */
  medical_history_types_aggregate: Medical_History_Types_Aggregate;
  /** fetch data from the table: "medical_history_types" using primary key columns */
  medical_history_types_by_pk?: Maybe<Medical_History_Types>;
  /** fetch data from the table: "patient_address" */
  patient_address: Array<Patient_Address>;
  /** fetch aggregated fields from the table: "patient_address" */
  patient_address_aggregate: Patient_Address_Aggregate;
  /** fetch data from the table: "patient_duplicates" */
  patient_duplicates: Array<Patient_Duplicates>;
  /** fetch aggregated fields from the table: "patient_duplicates" */
  patient_duplicates_aggregate: Patient_Duplicates_Aggregate;
  /** An array relationship */
  patient_migrations: Array<Patient_Migrations>;
  /** An aggregate relationship */
  patient_migrations_aggregate: Patient_Migrations_Aggregate;
  /** fetch data from the table: "patient_migrations" using primary key columns */
  patient_migrations_by_pk?: Maybe<Patient_Migrations>;
  /** An array relationship */
  patient_not_duplicates: Array<Patient_Not_Duplicates>;
  /** An aggregate relationship */
  patient_not_duplicates_aggregate: Patient_Not_Duplicates_Aggregate;
  /** fetch data from the table: "patient_not_duplicates" using primary key columns */
  patient_not_duplicates_by_pk?: Maybe<Patient_Not_Duplicates>;
  /** fetch data from the table: "patient_record_status_types" */
  patient_record_status_types: Array<Patient_Record_Status_Types>;
  /** fetch aggregated fields from the table: "patient_record_status_types" */
  patient_record_status_types_aggregate: Patient_Record_Status_Types_Aggregate;
  /** fetch data from the table: "patient_record_status_types" using primary key columns */
  patient_record_status_types_by_pk?: Maybe<Patient_Record_Status_Types>;
  /** An array relationship */
  patients: Array<Patients>;
  /** An aggregate relationship */
  patients_aggregate: Patients_Aggregate;
  /** fetch data from the table: "patients" using primary key columns */
  patients_by_pk?: Maybe<Patients>;
  /** fetch data from the table: "payment_type" */
  payment_type: Array<Payment_Type>;
  /** fetch aggregated fields from the table: "payment_type" */
  payment_type_aggregate: Payment_Type_Aggregate;
  /** fetch data from the table: "payment_type" using primary key columns */
  payment_type_by_pk?: Maybe<Payment_Type>;
  /** An array relationship */
  phone_numbers: Array<Phone_Numbers>;
  /** An aggregate relationship */
  phone_numbers_aggregate: Phone_Numbers_Aggregate;
  /** fetch data from the table: "phone_numbers" using primary key columns */
  phone_numbers_by_pk?: Maybe<Phone_Numbers>;
  /** fetch data from the table: "problem_status_types" */
  problem_status_types: Array<Problem_Status_Types>;
  /** fetch aggregated fields from the table: "problem_status_types" */
  problem_status_types_aggregate: Problem_Status_Types_Aggregate;
  /** fetch data from the table: "problem_status_types" using primary key columns */
  problem_status_types_by_pk?: Maybe<Problem_Status_Types>;
  /** An array relationship */
  problems: Array<Problems>;
  /** An aggregate relationship */
  problems_aggregate: Problems_Aggregate;
  /** fetch data from the table: "problems" using primary key columns */
  problems_by_pk?: Maybe<Problems>;
  /** fetch data from the table: "products" */
  products: Array<Products>;
  /** fetch aggregated fields from the table: "products" */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table: "reconciliation_type" */
  reconciliation_type: Array<Reconciliation_Type>;
  /** fetch aggregated fields from the table: "reconciliation_type" */
  reconciliation_type_aggregate: Reconciliation_Type_Aggregate;
  /** fetch data from the table: "reconciliation_type" using primary key columns */
  reconciliation_type_by_pk?: Maybe<Reconciliation_Type>;
  /** An array relationship */
  reconciliations: Array<Reconciliations>;
  /** An aggregate relationship */
  reconciliations_aggregate: Reconciliations_Aggregate;
  /** fetch data from the table: "reconciliations" using primary key columns */
  reconciliations_by_pk?: Maybe<Reconciliations>;
  /** fetch data from the table: "regions" */
  regions: Array<Regions>;
  /** fetch aggregated fields from the table: "regions" */
  regions_aggregate: Regions_Aggregate;
  /** fetch data from the table: "regions" using primary key columns */
  regions_by_pk?: Maybe<Regions>;
  /** fetch data from the table: "reservations" */
  reservations: Array<Reservations>;
  /** fetch aggregated fields from the table: "reservations" */
  reservations_aggregate: Reservations_Aggregate;
  /** An array relationship */
  reviews: Array<Reviews>;
  /** An aggregate relationship */
  reviews_aggregate: Reviews_Aggregate;
  /** fetch data from the table: "reviews" using primary key columns */
  reviews_by_pk?: Maybe<Reviews>;
  /** An array relationship */
  rooms: Array<Rooms>;
  /** An aggregate relationship */
  rooms_aggregate: Rooms_Aggregate;
  /** fetch data from the table: "rooms" using primary key columns */
  rooms_by_pk?: Maybe<Rooms>;
  /** fetch data from the table: "screening_category_types" */
  screening_category_types: Array<Screening_Category_Types>;
  /** fetch aggregated fields from the table: "screening_category_types" */
  screening_category_types_aggregate: Screening_Category_Types_Aggregate;
  /** fetch data from the table: "screening_category_types" using primary key columns */
  screening_category_types_by_pk?: Maybe<Screening_Category_Types>;
  /** An array relationship */
  screening_types: Array<Screening_Types>;
  /** An aggregate relationship */
  screening_types_aggregate: Screening_Types_Aggregate;
  /** fetch data from the table: "screening_types" using primary key columns */
  screening_types_by_pk?: Maybe<Screening_Types>;
  /** An array relationship */
  screenings: Array<Screenings>;
  /** An aggregate relationship */
  screenings_aggregate: Screenings_Aggregate;
  /** fetch data from the table: "screenings" using primary key columns */
  screenings_by_pk?: Maybe<Screenings>;
  /** execute function "search_accounts" which returns "accounts" */
  search_accounts: Array<Accounts>;
  /** execute function "search_accounts" and query aggregates on result of table type "accounts" */
  search_accounts_aggregate: Accounts_Aggregate;
  /** execute function "search_patients" which returns "patients" */
  search_patients: Array<Patients>;
  /** execute function "search_patients" and query aggregates on result of table type "patients" */
  search_patients_aggregate: Patients_Aggregate;
  /** execute function "search_snomed_synonyms" which returns "snomed_synonyms" */
  search_snomed_synonyms: Array<Snomed_Synonyms>;
  /** execute function "search_snomed_synonyms" and query aggregates on result of table type "snomed_synonyms" */
  search_snomed_synonyms_aggregate: Snomed_Synonyms_Aggregate;
  /** execute function "search_staff" which returns "staff" */
  search_staff: Array<Staff>;
  /** execute function "search_staff" and query aggregates on result of table type "staff" */
  search_staff_aggregate: Staff_Aggregate;
  /** execute function "search_tasks" which returns "tasks" */
  search_tasks: Array<Tasks>;
  /** execute function "search_tasks" and query aggregates on result of table type "tasks" */
  search_tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "services" */
  services: Array<Services>;
  /** fetch aggregated fields from the table: "services" */
  services_aggregate: Services_Aggregate;
  /** fetch data from the table: "services" using primary key columns */
  services_by_pk?: Maybe<Services>;
  /** fetch data from the table: "snomed_synonyms" */
  snomed_synonyms: Array<Snomed_Synonyms>;
  /** fetch aggregated fields from the table: "snomed_synonyms" */
  snomed_synonyms_aggregate: Snomed_Synonyms_Aggregate;
  /** fetch data from the table: "snomed_synonyms" using primary key columns */
  snomed_synonyms_by_pk?: Maybe<Snomed_Synonyms>;
  /** fetch data from the table: "staff" */
  staff: Array<Staff>;
  /** fetch aggregated fields from the table: "staff" */
  staff_aggregate: Staff_Aggregate;
  /** fetch data from the table: "staff" using primary key columns */
  staff_by_pk?: Maybe<Staff>;
  /** An array relationship */
  staff_regions: Array<Staff_Regions>;
  /** An aggregate relationship */
  staff_regions_aggregate: Staff_Regions_Aggregate;
  /** fetch data from the table: "staff_regions" using primary key columns */
  staff_regions_by_pk?: Maybe<Staff_Regions>;
  /** fetch data from the table: "staff_schedule" */
  staff_schedule: Array<Staff_Schedule>;
  /** fetch aggregated fields from the table: "staff_schedule" */
  staff_schedule_aggregate: Staff_Schedule_Aggregate;
  /** fetch data from the table: "staff_schedule" using primary key columns */
  staff_schedule_by_pk?: Maybe<Staff_Schedule>;
  /** fetch data from the table: "staff_schedule_service" */
  staff_schedule_service: Array<Staff_Schedule_Service>;
  /** fetch aggregated fields from the table: "staff_schedule_service" */
  staff_schedule_service_aggregate: Staff_Schedule_Service_Aggregate;
  /** fetch data from the table: "staff_schedule_service" using primary key columns */
  staff_schedule_service_by_pk?: Maybe<Staff_Schedule_Service>;
  /** An array relationship */
  staff_services: Array<Staff_Services>;
  /** An aggregate relationship */
  staff_services_aggregate: Staff_Services_Aggregate;
  /** fetch data from the table: "staff_services" using primary key columns */
  staff_services_by_pk?: Maybe<Staff_Services>;
  /** fetch data from the table: "staff_type" */
  staff_type: Array<Staff_Type>;
  /** fetch aggregated fields from the table: "staff_type" */
  staff_type_aggregate: Staff_Type_Aggregate;
  /** fetch data from the table: "staff_type" using primary key columns */
  staff_type_by_pk?: Maybe<Staff_Type>;
  /** fetch data from the table: "symptom_checker" */
  symptom_checker: Array<Symptom_Checker>;
  /** fetch aggregated fields from the table: "symptom_checker" */
  symptom_checker_aggregate: Symptom_Checker_Aggregate;
  /** fetch data from the table: "symptom_checker" using primary key columns */
  symptom_checker_by_pk?: Maybe<Symptom_Checker>;
  /** fetch data from the table: "task_priority" */
  task_priority: Array<Task_Priority>;
  /** fetch aggregated fields from the table: "task_priority" */
  task_priority_aggregate: Task_Priority_Aggregate;
  /** fetch data from the table: "task_priority" using primary key columns */
  task_priority_by_pk?: Maybe<Task_Priority>;
  /** fetch data from the table: "task_status" */
  task_status: Array<Task_Status>;
  /** fetch aggregated fields from the table: "task_status" */
  task_status_aggregate: Task_Status_Aggregate;
  /** fetch data from the table: "task_status" using primary key columns */
  task_status_by_pk?: Maybe<Task_Status>;
  /** fetch data from the table: "task_sub_type" */
  task_sub_type: Array<Task_Sub_Type>;
  /** fetch aggregated fields from the table: "task_sub_type" */
  task_sub_type_aggregate: Task_Sub_Type_Aggregate;
  /** fetch data from the table: "task_sub_type" using primary key columns */
  task_sub_type_by_pk?: Maybe<Task_Sub_Type>;
  /** fetch data from the table: "task_type" */
  task_type: Array<Task_Type>;
  /** fetch aggregated fields from the table: "task_type" */
  task_type_aggregate: Task_Type_Aggregate;
  /** fetch data from the table: "task_type" using primary key columns */
  task_type_by_pk?: Maybe<Task_Type>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** An array relationship */
  tasks_assignments: Array<Tasks_Assignments>;
  /** An aggregate relationship */
  tasks_assignments_aggregate: Tasks_Assignments_Aggregate;
  /** fetch data from the table: "tasks_assignments" using primary key columns */
  tasks_assignments_by_pk?: Maybe<Tasks_Assignments>;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** An array relationship */
  tasks_files: Array<Tasks_Files>;
  /** An aggregate relationship */
  tasks_files_aggregate: Tasks_Files_Aggregate;
  /** fetch data from the table: "tasks_files" using primary key columns */
  tasks_files_by_pk?: Maybe<Tasks_Files>;
  /** fetch data from the table: "tasks_groups" */
  tasks_groups: Array<Tasks_Groups>;
  /** fetch aggregated fields from the table: "tasks_groups" */
  tasks_groups_aggregate: Tasks_Groups_Aggregate;
  /** fetch data from the table: "tasks_groups" using primary key columns */
  tasks_groups_by_pk?: Maybe<Tasks_Groups>;
  /** fetch data from the table: "tasks_groups_staff" */
  tasks_groups_staff: Array<Tasks_Groups_Staff>;
  /** fetch aggregated fields from the table: "tasks_groups_staff" */
  tasks_groups_staff_aggregate: Tasks_Groups_Staff_Aggregate;
  /** fetch data from the table: "tasks_groups_staff" using primary key columns */
  tasks_groups_staff_by_pk?: Maybe<Tasks_Groups_Staff>;
  /** An array relationship */
  tasks_notifications: Array<Tasks_Notifications>;
  /** An aggregate relationship */
  tasks_notifications_aggregate: Tasks_Notifications_Aggregate;
  /** fetch data from the table: "tasks_notifications" using primary key columns */
  tasks_notifications_by_pk?: Maybe<Tasks_Notifications>;
  /** fetch data from the table: "tasks_read" */
  tasks_read: Array<Tasks_Read>;
  /** fetch aggregated fields from the table: "tasks_read" */
  tasks_read_aggregate: Tasks_Read_Aggregate;
  /** fetch data from the table: "tasks_read" using primary key columns */
  tasks_read_by_pk?: Maybe<Tasks_Read>;
  /** fetch data from the table: "tasks_responsible" */
  tasks_responsible: Array<Tasks_Responsible>;
  /** fetch aggregated fields from the table: "tasks_responsible" */
  tasks_responsible_aggregate: Tasks_Responsible_Aggregate;
  /** fetch data from the table: "tasks_watch" */
  tasks_watch: Array<Tasks_Watch>;
  /** fetch aggregated fields from the table: "tasks_watch" */
  tasks_watch_aggregate: Tasks_Watch_Aggregate;
  /** fetch data from the table: "tasks_watch" using primary key columns */
  tasks_watch_by_pk?: Maybe<Tasks_Watch>;
  /** fetch data from the table: "triage_schedule" */
  triage_schedule: Array<Triage_Schedule>;
  /** fetch aggregated fields from the table: "triage_schedule" */
  triage_schedule_aggregate: Triage_Schedule_Aggregate;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "visit_classifications" */
  visit_classifications: Array<Visit_Classifications>;
  /** fetch aggregated fields from the table: "visit_classifications" */
  visit_classifications_aggregate: Visit_Classifications_Aggregate;
  /** fetch data from the table: "visit_classifications" using primary key columns */
  visit_classifications_by_pk?: Maybe<Visit_Classifications>;
  /** fetch data from the table: "visit_types" */
  visit_types: Array<Visit_Types>;
  /** fetch aggregated fields from the table: "visit_types" */
  visit_types_aggregate: Visit_Types_Aggregate;
  /** fetch data from the table: "visit_types" using primary key columns */
  visit_types_by_pk?: Maybe<Visit_Types>;
};

export type Query_RootAutocompleteLocationsArgs = {
  location?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type Query_RootDetectDocumentTextArgs = {
  fileId: Scalars['String'];
};

export type Query_RootFindPlaceArgs = {
  placeId: Scalars['String'];
};

export type Query_RootGetAllAvailableVisitTypeTimesArgs = {
  fromDate: Scalars['String'];
  reservationId?: InputMaybe<Scalars['String']>;
  toDate: Scalars['String'];
  visitType: Scalars['String'];
};

export type Query_RootGetAvailableAppointmentsByStaffInRangeArgs = {
  careType: Scalars['String'];
  clinicCodes: Array<Scalars['String']>;
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
};

export type Query_RootGetAvailableAppointmentsInRangeArgs = {
  careType: Scalars['String'];
  clinicCodes?: InputMaybe<Array<Scalars['String']>>;
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
};

export type Query_RootGetDocumentArgs = {
  documentType: Scalars['String'];
  patientId: Scalars['String'];
};

export type Query_RootGetFileArgs = {
  id: Scalars['String'];
};

export type Query_RootGetNextTimeSlotArgs = {
  clinicId: Scalars['String'];
  time: Scalars['String'];
};

export type Query_RootGetScheduleArgs = {
  clinicCode?: InputMaybe<Scalars['String']>;
  clinicId?: InputMaybe<Scalars['String']>;
  date: Scalars['String'];
};

export type Query_RootGetStaffInfoArgs = {
  staffIds: Array<Scalars['uuid']>;
};

export type Query_RootGetTheoreticalAvailabilityArgs = {
  clinicId: Scalars['String'];
  date: Scalars['String'];
  reservationId?: InputMaybe<Scalars['String']>;
  visitType: Scalars['String'];
};

export type Query_RootGetTheoreticalAvailabilityClinicArgs = {
  clinicId: Scalars['String'];
  date: Scalars['String'];
  reservationId?: InputMaybe<Scalars['String']>;
  visitType: Scalars['String'];
};

export type Query_RootGetTheoreticalAvailabilityRangeArgs = {
  clinicId: Scalars['String'];
  fromDate: Scalars['String'];
  reservationId?: InputMaybe<Scalars['String']>;
  toDate: Scalars['String'];
  visitType: Scalars['String'];
};

export type Query_RootLoadPrismicBlogPostsArgs = {
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

export type Query_RootLoadPrismicVaccinesArgs = {
  vaccineSlugs: Array<Scalars['String']>;
};

export type Query_RootNextTheoreticalAvailabilityArgs = {
  clinicId: Scalars['String'];
  date: Scalars['String'];
  reservationId?: InputMaybe<Scalars['String']>;
  visitClassification?: InputMaybe<Scalars['String']>;
  visitType: Scalars['String'];
};

export type Query_RootNextTheoreticalStaffAvailabilityArgs = {
  date: Scalars['String'];
  reservationId?: InputMaybe<Scalars['String']>;
  staffId: Scalars['String'];
  visitClassification?: InputMaybe<Scalars['String']>;
  visitType: Scalars['String'];
};

export type Query_RootRetrieveVerificationCodeArgs = {
  phoneNumber: Scalars['String'];
};

export type Query_RootSnomedMappingArgs = {
  code: Scalars['String'];
};

export type Query_RootSnomedSearchArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
  searchType: SnomedSearchType;
};

export type Query_RootSearchLocationsArgs = {
  location?: InputMaybe<Scalars['String']>;
  nearAddress?: InputMaybe<Scalars['String']>;
  placeTypes: Array<Scalars['String']>;
  value: Scalars['String'];
};

export type Query_RootStripePaymentMethodArgs = {
  stripeCardId: Scalars['String'];
  stripeCustomerId: Scalars['String'];
};

export type Query_RootStripePaymentMethodByChargeIdArgs = {
  stripeChargeId: Scalars['String'];
};

export type Query_RootStripePaymentMethodsArgs = {
  accountId?: InputMaybe<Scalars['String']>;
};

export type Query_RootStripeSubscriptionArgs = {
  accountId?: InputMaybe<Scalars['String']>;
};

export type Query_RootAccount_PatientArgs = {
  distinct_on?: InputMaybe<Array<Account_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Patient_Order_By>>;
  where?: InputMaybe<Account_Patient_Bool_Exp>;
};

export type Query_RootAccount_Patient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Patient_Order_By>>;
  where?: InputMaybe<Account_Patient_Bool_Exp>;
};

export type Query_RootAccount_Patient_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAccount_Temporary_VerificationArgs = {
  distinct_on?: InputMaybe<Array<Account_Temporary_Verification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Temporary_Verification_Order_By>>;
  where?: InputMaybe<Account_Temporary_Verification_Bool_Exp>;
};

export type Query_RootAccount_Temporary_Verification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Temporary_Verification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Temporary_Verification_Order_By>>;
  where?: InputMaybe<Account_Temporary_Verification_Bool_Exp>;
};

export type Query_RootAccount_Temporary_Verification_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

export type Query_RootAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

export type Query_RootAccounts_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAccounts_LimitedArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Limited_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Limited_Order_By>>;
  where?: InputMaybe<Accounts_Limited_Bool_Exp>;
};

export type Query_RootAccounts_Limited_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Limited_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Limited_Order_By>>;
  where?: InputMaybe<Accounts_Limited_Bool_Exp>;
};

export type Query_RootAllergiesArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Order_By>>;
  where?: InputMaybe<Allergies_Bool_Exp>;
};

export type Query_RootAllergies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Order_By>>;
  where?: InputMaybe<Allergies_Bool_Exp>;
};

export type Query_RootAllergies_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAllergies_SeverityArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Severity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Severity_Order_By>>;
  where?: InputMaybe<Allergies_Severity_Bool_Exp>;
};

export type Query_RootAllergies_Severity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Severity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Severity_Order_By>>;
  where?: InputMaybe<Allergies_Severity_Bool_Exp>;
};

export type Query_RootAllergies_Severity_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootAllergies_StatusArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Status_Order_By>>;
  where?: InputMaybe<Allergies_Status_Bool_Exp>;
};

export type Query_RootAllergies_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Status_Order_By>>;
  where?: InputMaybe<Allergies_Status_Bool_Exp>;
};

export type Query_RootAllergies_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootAppointment_BlocksArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Blocks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Blocks_Order_By>>;
  where?: InputMaybe<Appointment_Blocks_Bool_Exp>;
};

export type Query_RootAppointment_Blocks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Blocks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Blocks_Order_By>>;
  where?: InputMaybe<Appointment_Blocks_Bool_Exp>;
};

export type Query_RootAppointment_Blocks_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAppointment_Blocks_StaffArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Blocks_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Blocks_Staff_Order_By>>;
  where?: InputMaybe<Appointment_Blocks_Staff_Bool_Exp>;
};

export type Query_RootAppointment_Blocks_Staff_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Blocks_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Blocks_Staff_Order_By>>;
  where?: InputMaybe<Appointment_Blocks_Staff_Bool_Exp>;
};

export type Query_RootAppointment_Blocks_Staff_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootAppointment_CountsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Counts_Order_By>>;
  where?: InputMaybe<Appointment_Counts_Bool_Exp>;
};

export type Query_RootAppointment_Counts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Counts_Order_By>>;
  where?: InputMaybe<Appointment_Counts_Bool_Exp>;
};

export type Query_RootAppointment_PatientArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Patient_Order_By>>;
  where?: InputMaybe<Appointment_Patient_Bool_Exp>;
};

export type Query_RootAppointment_Patient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Patient_Order_By>>;
  where?: InputMaybe<Appointment_Patient_Bool_Exp>;
};

export type Query_RootAppointment_Patient_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAppointment_PaymentArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Payment_Order_By>>;
  where?: InputMaybe<Appointment_Payment_Bool_Exp>;
};

export type Query_RootAppointment_Payment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Payment_Order_By>>;
  where?: InputMaybe<Appointment_Payment_Bool_Exp>;
};

export type Query_RootAppointment_Payment_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootAppointment_ReservationsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Reservations_Order_By>>;
  where?: InputMaybe<Appointment_Reservations_Bool_Exp>;
};

export type Query_RootAppointment_Reservations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Reservations_Order_By>>;
  where?: InputMaybe<Appointment_Reservations_Bool_Exp>;
};

export type Query_RootAppointment_Reservations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAppointment_StaffArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Staff_Order_By>>;
  where?: InputMaybe<Appointment_Staff_Bool_Exp>;
};

export type Query_RootAppointment_Staff_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Staff_Order_By>>;
  where?: InputMaybe<Appointment_Staff_Bool_Exp>;
};

export type Query_RootAppointment_Staff_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAppointment_StatusArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Status_Order_By>>;
  where?: InputMaybe<Appointment_Status_Bool_Exp>;
};

export type Query_RootAppointment_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Status_Order_By>>;
  where?: InputMaybe<Appointment_Status_Bool_Exp>;
};

export type Query_RootAppointment_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootAppointment_TypesArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Types_Order_By>>;
  where?: InputMaybe<Appointment_Types_Bool_Exp>;
};

export type Query_RootAppointment_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Types_Order_By>>;
  where?: InputMaybe<Appointment_Types_Bool_Exp>;
};

export type Query_RootAppointment_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

export type Query_RootAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

export type Query_RootAppointments_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAudit_LogsArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Logs_Order_By>>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

export type Query_RootAudit_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Logs_Order_By>>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

export type Query_RootAudit_Logs_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootAuthRolesArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCare_Type_DurationArgs = {
  distinct_on?: InputMaybe<Array<Care_Type_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Care_Type_Duration_Order_By>>;
  where?: InputMaybe<Care_Type_Duration_Bool_Exp>;
};

export type Query_RootCare_Type_Duration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Care_Type_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Care_Type_Duration_Order_By>>;
  where?: InputMaybe<Care_Type_Duration_Bool_Exp>;
};

export type Query_RootCare_Type_Duration_By_PkArgs = {
  careType: Care_Types_Enum;
  clinicId: Scalars['uuid'];
};

export type Query_RootCare_TypesArgs = {
  distinct_on?: InputMaybe<Array<Care_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Care_Types_Order_By>>;
  where?: InputMaybe<Care_Types_Bool_Exp>;
};

export type Query_RootCare_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Care_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Care_Types_Order_By>>;
  where?: InputMaybe<Care_Types_Bool_Exp>;
};

export type Query_RootCare_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCharge_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Charge_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charge_Products_Order_By>>;
  where?: InputMaybe<Charge_Products_Bool_Exp>;
};

export type Query_RootCharge_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Charge_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charge_Products_Order_By>>;
  where?: InputMaybe<Charge_Products_Bool_Exp>;
};

export type Query_RootCharge_Products_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootChargesArgs = {
  distinct_on?: InputMaybe<Array<Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charges_Order_By>>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

export type Query_RootCharges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charges_Order_By>>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

export type Query_RootCharges_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_Order_By>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};

export type Query_RootChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_Order_By>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};

export type Query_RootChat_Messages_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootChat_Messages_FileArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_File_Order_By>>;
  where?: InputMaybe<Chat_Messages_File_Bool_Exp>;
};

export type Query_RootChat_Messages_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_File_Order_By>>;
  where?: InputMaybe<Chat_Messages_File_Bool_Exp>;
};

export type Query_RootChat_Messages_File_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootChat_Messages_TypeArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_Type_Order_By>>;
  where?: InputMaybe<Chat_Messages_Type_Bool_Exp>;
};

export type Query_RootChat_Messages_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_Type_Order_By>>;
  where?: InputMaybe<Chat_Messages_Type_Bool_Exp>;
};

export type Query_RootChat_Messages_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootChat_NotesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Notes_Order_By>>;
  where?: InputMaybe<Chat_Notes_Bool_Exp>;
};

export type Query_RootChat_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Notes_Order_By>>;
  where?: InputMaybe<Chat_Notes_Bool_Exp>;
};

export type Query_RootChat_Notes_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootChat_Notes_TypesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Notes_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Notes_Types_Order_By>>;
  where?: InputMaybe<Chat_Notes_Types_Bool_Exp>;
};

export type Query_RootChat_Notes_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Notes_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Notes_Types_Order_By>>;
  where?: InputMaybe<Chat_Notes_Types_Bool_Exp>;
};

export type Query_RootChat_Notes_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootChat_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Bool_Exp>;
};

export type Query_RootChat_Sessions_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Accounts_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Accounts_Bool_Exp>;
};

export type Query_RootChat_Sessions_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Accounts_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Accounts_Bool_Exp>;
};

export type Query_RootChat_Sessions_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootChat_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Bool_Exp>;
};

export type Query_RootChat_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootChat_Sessions_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Patients_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Patients_Bool_Exp>;
};

export type Query_RootChat_Sessions_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Patients_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Patients_Bool_Exp>;
};

export type Query_RootChat_Sessions_Patients_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootChat_Sessions_ReadArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Read_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Read_Bool_Exp>;
};

export type Query_RootChat_Sessions_Read_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Read_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Read_Bool_Exp>;
};

export type Query_RootChat_Sessions_Read_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootChat_Sessions_StaffArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Staff_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Staff_Bool_Exp>;
};

export type Query_RootChat_Sessions_Staff_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Staff_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Staff_Bool_Exp>;
};

export type Query_RootChat_Sessions_Staff_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootChat_Sessions_StatusArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Status_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Status_Bool_Exp>;
};

export type Query_RootChat_Sessions_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Status_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Status_Bool_Exp>;
};

export type Query_RootChat_Sessions_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootClinic_Location_TypesArgs = {
  distinct_on?: InputMaybe<Array<Clinic_Location_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinic_Location_Types_Order_By>>;
  where?: InputMaybe<Clinic_Location_Types_Bool_Exp>;
};

export type Query_RootClinic_Location_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinic_Location_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinic_Location_Types_Order_By>>;
  where?: InputMaybe<Clinic_Location_Types_Bool_Exp>;
};

export type Query_RootClinic_Location_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootClinic_StatusArgs = {
  distinct_on?: InputMaybe<Array<Clinic_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinic_Status_Order_By>>;
  where?: InputMaybe<Clinic_Status_Bool_Exp>;
};

export type Query_RootClinic_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinic_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinic_Status_Order_By>>;
  where?: InputMaybe<Clinic_Status_Bool_Exp>;
};

export type Query_RootClinic_Status_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootClinicsArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Order_By>>;
  where?: InputMaybe<Clinics_Bool_Exp>;
};

export type Query_RootClinics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Order_By>>;
  where?: InputMaybe<Clinics_Bool_Exp>;
};

export type Query_RootClinics_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClinics_PropertiesArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Properties_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Properties_Order_By>>;
  where?: InputMaybe<Clinics_Properties_Bool_Exp>;
};

export type Query_RootClinics_Properties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Properties_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Properties_Order_By>>;
  where?: InputMaybe<Clinics_Properties_Bool_Exp>;
};

export type Query_RootClinics_Properties_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClinics_Properties_TypesArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Properties_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Properties_Types_Order_By>>;
  where?: InputMaybe<Clinics_Properties_Types_Bool_Exp>;
};

export type Query_RootClinics_Properties_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Properties_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Properties_Types_Order_By>>;
  where?: InputMaybe<Clinics_Properties_Types_Bool_Exp>;
};

export type Query_RootClinics_Properties_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootClinics_RegionsArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Regions_Order_By>>;
  where?: InputMaybe<Clinics_Regions_Bool_Exp>;
};

export type Query_RootClinics_Regions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Regions_Order_By>>;
  where?: InputMaybe<Clinics_Regions_Bool_Exp>;
};

export type Query_RootClinics_Regions_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootCommunication_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Order_By>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

export type Query_RootCommunication_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Order_By>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

export type Query_RootCommunication_History_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCommunication_History_DirectionsArgs = {
  distinct_on?: InputMaybe<
    Array<Communication_History_Directions_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Directions_Order_By>>;
  where?: InputMaybe<Communication_History_Directions_Bool_Exp>;
};

export type Query_RootCommunication_History_Directions_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Communication_History_Directions_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Directions_Order_By>>;
  where?: InputMaybe<Communication_History_Directions_Bool_Exp>;
};

export type Query_RootCommunication_History_Directions_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootCommunication_History_Tag_TypesArgs = {
  distinct_on?: InputMaybe<
    Array<Communication_History_Tag_Types_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Tag_Types_Order_By>>;
  where?: InputMaybe<Communication_History_Tag_Types_Bool_Exp>;
};

export type Query_RootCommunication_History_Tag_Types_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Communication_History_Tag_Types_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Tag_Types_Order_By>>;
  where?: InputMaybe<Communication_History_Tag_Types_Bool_Exp>;
};

export type Query_RootCommunication_History_Tag_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootCommunication_History_TagsArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Tags_Order_By>>;
  where?: InputMaybe<Communication_History_Tags_Bool_Exp>;
};

export type Query_RootCommunication_History_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Tags_Order_By>>;
  where?: InputMaybe<Communication_History_Tags_Bool_Exp>;
};

export type Query_RootCommunication_History_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCommunication_History_TypesArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Types_Order_By>>;
  where?: InputMaybe<Communication_History_Types_Bool_Exp>;
};

export type Query_RootCommunication_History_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Types_Order_By>>;
  where?: InputMaybe<Communication_History_Types_Bool_Exp>;
};

export type Query_RootCommunication_History_Types_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootDevicesArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};

export type Query_RootDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};

export type Query_RootDevices_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDiscovered_FromArgs = {
  distinct_on?: InputMaybe<Array<Discovered_From_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Discovered_From_Order_By>>;
  where?: InputMaybe<Discovered_From_Bool_Exp>;
};

export type Query_RootDiscovered_From_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Discovered_From_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Discovered_From_Order_By>>;
  where?: InputMaybe<Discovered_From_Bool_Exp>;
};

export type Query_RootDiscovered_From_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDocument_Status_TypesArgs = {
  distinct_on?: InputMaybe<Array<Document_Status_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Status_Types_Order_By>>;
  where?: InputMaybe<Document_Status_Types_Bool_Exp>;
};

export type Query_RootDocument_Status_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Status_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Status_Types_Order_By>>;
  where?: InputMaybe<Document_Status_Types_Bool_Exp>;
};

export type Query_RootDocument_Status_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootDocument_TypesArgs = {
  distinct_on?: InputMaybe<Array<Document_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Types_Order_By>>;
  where?: InputMaybe<Document_Types_Bool_Exp>;
};

export type Query_RootDocument_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Types_Order_By>>;
  where?: InputMaybe<Document_Types_Bool_Exp>;
};

export type Query_RootDocument_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

export type Query_RootDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

export type Query_RootDocuments_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootFhir_Resource_TypesArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resource_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resource_Types_Order_By>>;
  where?: InputMaybe<Fhir_Resource_Types_Bool_Exp>;
};

export type Query_RootFhir_Resource_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resource_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resource_Types_Order_By>>;
  where?: InputMaybe<Fhir_Resource_Types_Bool_Exp>;
};

export type Query_RootFhir_Resource_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootFhir_ResourcesArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_Order_By>>;
  where?: InputMaybe<Fhir_Resources_Bool_Exp>;
};

export type Query_RootFhir_Resources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_Order_By>>;
  where?: InputMaybe<Fhir_Resources_Bool_Exp>;
};

export type Query_RootFhir_Resources_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootFhir_Resources_CodedArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_Coded_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_Coded_Order_By>>;
  where?: InputMaybe<Fhir_Resources_Coded_Bool_Exp>;
};

export type Query_RootFhir_Resources_Coded_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_Coded_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_Coded_Order_By>>;
  where?: InputMaybe<Fhir_Resources_Coded_Bool_Exp>;
};

export type Query_RootFhir_Resources_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_History_Order_By>>;
  where?: InputMaybe<Fhir_Resources_History_Bool_Exp>;
};

export type Query_RootFhir_Resources_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_History_Order_By>>;
  where?: InputMaybe<Fhir_Resources_History_Bool_Exp>;
};

export type Query_RootFhir_Resources_History_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootFile_Document_TypeArgs = {
  distinct_on?: InputMaybe<Array<File_Document_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Document_Type_Order_By>>;
  where?: InputMaybe<File_Document_Type_Bool_Exp>;
};

export type Query_RootFile_Document_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Document_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Document_Type_Order_By>>;
  where?: InputMaybe<File_Document_Type_Bool_Exp>;
};

export type Query_RootFile_Document_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

export type Query_RootFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

export type Query_RootFiles_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGetFileByUuidArgs = {
  id?: InputMaybe<Scalars['uuid']>;
};

export type Query_RootGlobal_NotesArgs = {
  distinct_on?: InputMaybe<Array<Global_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Notes_Order_By>>;
  where?: InputMaybe<Global_Notes_Bool_Exp>;
};

export type Query_RootGlobal_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Notes_Order_By>>;
  where?: InputMaybe<Global_Notes_Bool_Exp>;
};

export type Query_RootGlobal_Notes_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootInsuranceArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Order_By>>;
  where?: InputMaybe<Insurance_Bool_Exp>;
};

export type Query_RootInsurance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Order_By>>;
  where?: InputMaybe<Insurance_Bool_Exp>;
};

export type Query_RootInsurance_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootInsurance_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Providers_Order_By>>;
  where?: InputMaybe<Insurance_Providers_Bool_Exp>;
};

export type Query_RootInsurance_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Providers_Order_By>>;
  where?: InputMaybe<Insurance_Providers_Bool_Exp>;
};

export type Query_RootInsurance_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootInsurance_TypesArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Types_Order_By>>;
  where?: InputMaybe<Insurance_Types_Bool_Exp>;
};

export type Query_RootInsurance_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Types_Order_By>>;
  where?: InputMaybe<Insurance_Types_Bool_Exp>;
};

export type Query_RootInsurance_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootKareo_LogsArgs = {
  distinct_on?: InputMaybe<Array<Kareo_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kareo_Logs_Order_By>>;
  where?: InputMaybe<Kareo_Logs_Bool_Exp>;
};

export type Query_RootKareo_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kareo_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kareo_Logs_Order_By>>;
  where?: InputMaybe<Kareo_Logs_Bool_Exp>;
};

export type Query_RootKareo_Logs_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootLocationsArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

export type Query_RootLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

export type Query_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootMedical_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Order_By>>;
  where?: InputMaybe<Medical_History_Bool_Exp>;
};

export type Query_RootMedical_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Order_By>>;
  where?: InputMaybe<Medical_History_Bool_Exp>;
};

export type Query_RootMedical_History_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Answers_Order_By>>;
  where?: InputMaybe<Medical_History_Answers_Bool_Exp>;
};

export type Query_RootMedical_History_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Answers_Order_By>>;
  where?: InputMaybe<Medical_History_Answers_Bool_Exp>;
};

export type Query_RootMedical_History_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootMedical_History_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootMedical_History_Person_TypesArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Person_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Person_Types_Order_By>>;
  where?: InputMaybe<Medical_History_Person_Types_Bool_Exp>;
};

export type Query_RootMedical_History_Person_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Person_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Person_Types_Order_By>>;
  where?: InputMaybe<Medical_History_Person_Types_Bool_Exp>;
};

export type Query_RootMedical_History_Person_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootMedical_History_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Questions_Order_By>>;
  where?: InputMaybe<Medical_History_Questions_Bool_Exp>;
};

export type Query_RootMedical_History_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Questions_Order_By>>;
  where?: InputMaybe<Medical_History_Questions_Bool_Exp>;
};

export type Query_RootMedical_History_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootMedical_History_TypesArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Types_Order_By>>;
  where?: InputMaybe<Medical_History_Types_Bool_Exp>;
};

export type Query_RootMedical_History_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Types_Order_By>>;
  where?: InputMaybe<Medical_History_Types_Bool_Exp>;
};

export type Query_RootMedical_History_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootPatient_AddressArgs = {
  distinct_on?: InputMaybe<Array<Patient_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Address_Order_By>>;
  where?: InputMaybe<Patient_Address_Bool_Exp>;
};

export type Query_RootPatient_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Address_Order_By>>;
  where?: InputMaybe<Patient_Address_Bool_Exp>;
};

export type Query_RootPatient_DuplicatesArgs = {
  distinct_on?: InputMaybe<Array<Patient_Duplicates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Duplicates_Order_By>>;
  where?: InputMaybe<Patient_Duplicates_Bool_Exp>;
};

export type Query_RootPatient_Duplicates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Duplicates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Duplicates_Order_By>>;
  where?: InputMaybe<Patient_Duplicates_Bool_Exp>;
};

export type Query_RootPatient_MigrationsArgs = {
  distinct_on?: InputMaybe<Array<Patient_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Migrations_Order_By>>;
  where?: InputMaybe<Patient_Migrations_Bool_Exp>;
};

export type Query_RootPatient_Migrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Migrations_Order_By>>;
  where?: InputMaybe<Patient_Migrations_Bool_Exp>;
};

export type Query_RootPatient_Migrations_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootPatient_Not_DuplicatesArgs = {
  distinct_on?: InputMaybe<Array<Patient_Not_Duplicates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Not_Duplicates_Order_By>>;
  where?: InputMaybe<Patient_Not_Duplicates_Bool_Exp>;
};

export type Query_RootPatient_Not_Duplicates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Not_Duplicates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Not_Duplicates_Order_By>>;
  where?: InputMaybe<Patient_Not_Duplicates_Bool_Exp>;
};

export type Query_RootPatient_Not_Duplicates_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPatient_Record_Status_TypesArgs = {
  distinct_on?: InputMaybe<Array<Patient_Record_Status_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Record_Status_Types_Order_By>>;
  where?: InputMaybe<Patient_Record_Status_Types_Bool_Exp>;
};

export type Query_RootPatient_Record_Status_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Record_Status_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Record_Status_Types_Order_By>>;
  where?: InputMaybe<Patient_Record_Status_Types_Bool_Exp>;
};

export type Query_RootPatient_Record_Status_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

export type Query_RootPatients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

export type Query_RootPatients_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPayment_TypeArgs = {
  distinct_on?: InputMaybe<Array<Payment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Type_Order_By>>;
  where?: InputMaybe<Payment_Type_Bool_Exp>;
};

export type Query_RootPayment_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Type_Order_By>>;
  where?: InputMaybe<Payment_Type_Bool_Exp>;
};

export type Query_RootPayment_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootPhone_NumbersArgs = {
  distinct_on?: InputMaybe<Array<Phone_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phone_Numbers_Order_By>>;
  where?: InputMaybe<Phone_Numbers_Bool_Exp>;
};

export type Query_RootPhone_Numbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Phone_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phone_Numbers_Order_By>>;
  where?: InputMaybe<Phone_Numbers_Bool_Exp>;
};

export type Query_RootPhone_Numbers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProblem_Status_TypesArgs = {
  distinct_on?: InputMaybe<Array<Problem_Status_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Problem_Status_Types_Order_By>>;
  where?: InputMaybe<Problem_Status_Types_Bool_Exp>;
};

export type Query_RootProblem_Status_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Problem_Status_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Problem_Status_Types_Order_By>>;
  where?: InputMaybe<Problem_Status_Types_Bool_Exp>;
};

export type Query_RootProblem_Status_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootProblemsArgs = {
  distinct_on?: InputMaybe<Array<Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Problems_Order_By>>;
  where?: InputMaybe<Problems_Bool_Exp>;
};

export type Query_RootProblems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Problems_Order_By>>;
  where?: InputMaybe<Problems_Bool_Exp>;
};

export type Query_RootProblems_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};

export type Query_RootProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};

export type Query_RootProducts_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootReconciliation_TypeArgs = {
  distinct_on?: InputMaybe<Array<Reconciliation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reconciliation_Type_Order_By>>;
  where?: InputMaybe<Reconciliation_Type_Bool_Exp>;
};

export type Query_RootReconciliation_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reconciliation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reconciliation_Type_Order_By>>;
  where?: InputMaybe<Reconciliation_Type_Bool_Exp>;
};

export type Query_RootReconciliation_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootReconciliationsArgs = {
  distinct_on?: InputMaybe<Array<Reconciliations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reconciliations_Order_By>>;
  where?: InputMaybe<Reconciliations_Bool_Exp>;
};

export type Query_RootReconciliations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reconciliations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reconciliations_Order_By>>;
  where?: InputMaybe<Reconciliations_Bool_Exp>;
};

export type Query_RootReconciliations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootRegionsArgs = {
  distinct_on?: InputMaybe<Array<Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regions_Order_By>>;
  where?: InputMaybe<Regions_Bool_Exp>;
};

export type Query_RootRegions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regions_Order_By>>;
  where?: InputMaybe<Regions_Bool_Exp>;
};

export type Query_RootRegions_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootReservationsArgs = {
  distinct_on?: InputMaybe<Array<Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservations_Order_By>>;
  where?: InputMaybe<Reservations_Bool_Exp>;
};

export type Query_RootReservations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservations_Order_By>>;
  where?: InputMaybe<Reservations_Bool_Exp>;
};

export type Query_RootReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

export type Query_RootReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

export type Query_RootReviews_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootRoomsArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};

export type Query_RootRooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};

export type Query_RootRooms_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootScreening_Category_TypesArgs = {
  distinct_on?: InputMaybe<Array<Screening_Category_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screening_Category_Types_Order_By>>;
  where?: InputMaybe<Screening_Category_Types_Bool_Exp>;
};

export type Query_RootScreening_Category_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screening_Category_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screening_Category_Types_Order_By>>;
  where?: InputMaybe<Screening_Category_Types_Bool_Exp>;
};

export type Query_RootScreening_Category_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootScreening_TypesArgs = {
  distinct_on?: InputMaybe<Array<Screening_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screening_Types_Order_By>>;
  where?: InputMaybe<Screening_Types_Bool_Exp>;
};

export type Query_RootScreening_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screening_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screening_Types_Order_By>>;
  where?: InputMaybe<Screening_Types_Bool_Exp>;
};

export type Query_RootScreening_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootScreeningsArgs = {
  distinct_on?: InputMaybe<Array<Screenings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screenings_Order_By>>;
  where?: InputMaybe<Screenings_Bool_Exp>;
};

export type Query_RootScreenings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screenings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screenings_Order_By>>;
  where?: InputMaybe<Screenings_Bool_Exp>;
};

export type Query_RootScreenings_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSearch_AccountsArgs = {
  args: Search_Accounts_Args;
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

export type Query_RootSearch_Accounts_AggregateArgs = {
  args: Search_Accounts_Args;
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

export type Query_RootSearch_PatientsArgs = {
  args: Search_Patients_Args;
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

export type Query_RootSearch_Patients_AggregateArgs = {
  args: Search_Patients_Args;
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

export type Query_RootSearch_Snomed_SynonymsArgs = {
  args: Search_Snomed_Synonyms_Args;
  distinct_on?: InputMaybe<Array<Snomed_Synonyms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Snomed_Synonyms_Order_By>>;
  where?: InputMaybe<Snomed_Synonyms_Bool_Exp>;
};

export type Query_RootSearch_Snomed_Synonyms_AggregateArgs = {
  args: Search_Snomed_Synonyms_Args;
  distinct_on?: InputMaybe<Array<Snomed_Synonyms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Snomed_Synonyms_Order_By>>;
  where?: InputMaybe<Snomed_Synonyms_Bool_Exp>;
};

export type Query_RootSearch_StaffArgs = {
  args: Search_Staff_Args;
  distinct_on?: InputMaybe<Array<Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Order_By>>;
  where?: InputMaybe<Staff_Bool_Exp>;
};

export type Query_RootSearch_Staff_AggregateArgs = {
  args: Search_Staff_Args;
  distinct_on?: InputMaybe<Array<Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Order_By>>;
  where?: InputMaybe<Staff_Bool_Exp>;
};

export type Query_RootSearch_TasksArgs = {
  args: Search_Tasks_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootSearch_Tasks_AggregateArgs = {
  args: Search_Tasks_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootServicesArgs = {
  distinct_on?: InputMaybe<Array<Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Order_By>>;
  where?: InputMaybe<Services_Bool_Exp>;
};

export type Query_RootServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Order_By>>;
  where?: InputMaybe<Services_Bool_Exp>;
};

export type Query_RootServices_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootSnomed_SynonymsArgs = {
  distinct_on?: InputMaybe<Array<Snomed_Synonyms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Snomed_Synonyms_Order_By>>;
  where?: InputMaybe<Snomed_Synonyms_Bool_Exp>;
};

export type Query_RootSnomed_Synonyms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Snomed_Synonyms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Snomed_Synonyms_Order_By>>;
  where?: InputMaybe<Snomed_Synonyms_Bool_Exp>;
};

export type Query_RootSnomed_Synonyms_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootStaffArgs = {
  distinct_on?: InputMaybe<Array<Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Order_By>>;
  where?: InputMaybe<Staff_Bool_Exp>;
};

export type Query_RootStaff_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Order_By>>;
  where?: InputMaybe<Staff_Bool_Exp>;
};

export type Query_RootStaff_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootStaff_RegionsArgs = {
  distinct_on?: InputMaybe<Array<Staff_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Regions_Order_By>>;
  where?: InputMaybe<Staff_Regions_Bool_Exp>;
};

export type Query_RootStaff_Regions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Regions_Order_By>>;
  where?: InputMaybe<Staff_Regions_Bool_Exp>;
};

export type Query_RootStaff_Regions_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootStaff_ScheduleArgs = {
  distinct_on?: InputMaybe<Array<Staff_Schedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Schedule_Order_By>>;
  where?: InputMaybe<Staff_Schedule_Bool_Exp>;
};

export type Query_RootStaff_Schedule_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Schedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Schedule_Order_By>>;
  where?: InputMaybe<Staff_Schedule_Bool_Exp>;
};

export type Query_RootStaff_Schedule_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootStaff_Schedule_ServiceArgs = {
  distinct_on?: InputMaybe<Array<Staff_Schedule_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Schedule_Service_Order_By>>;
  where?: InputMaybe<Staff_Schedule_Service_Bool_Exp>;
};

export type Query_RootStaff_Schedule_Service_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Schedule_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Schedule_Service_Order_By>>;
  where?: InputMaybe<Staff_Schedule_Service_Bool_Exp>;
};

export type Query_RootStaff_Schedule_Service_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootStaff_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Staff_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Services_Order_By>>;
  where?: InputMaybe<Staff_Services_Bool_Exp>;
};

export type Query_RootStaff_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Services_Order_By>>;
  where?: InputMaybe<Staff_Services_Bool_Exp>;
};

export type Query_RootStaff_Services_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootStaff_TypeArgs = {
  distinct_on?: InputMaybe<Array<Staff_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Type_Order_By>>;
  where?: InputMaybe<Staff_Type_Bool_Exp>;
};

export type Query_RootStaff_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Type_Order_By>>;
  where?: InputMaybe<Staff_Type_Bool_Exp>;
};

export type Query_RootStaff_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootSymptom_CheckerArgs = {
  distinct_on?: InputMaybe<Array<Symptom_Checker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Symptom_Checker_Order_By>>;
  where?: InputMaybe<Symptom_Checker_Bool_Exp>;
};

export type Query_RootSymptom_Checker_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Symptom_Checker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Symptom_Checker_Order_By>>;
  where?: InputMaybe<Symptom_Checker_Bool_Exp>;
};

export type Query_RootSymptom_Checker_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTask_PriorityArgs = {
  distinct_on?: InputMaybe<Array<Task_Priority_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Priority_Order_By>>;
  where?: InputMaybe<Task_Priority_Bool_Exp>;
};

export type Query_RootTask_Priority_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Priority_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Priority_Order_By>>;
  where?: InputMaybe<Task_Priority_Bool_Exp>;
};

export type Query_RootTask_Priority_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootTask_StatusArgs = {
  distinct_on?: InputMaybe<Array<Task_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Status_Order_By>>;
  where?: InputMaybe<Task_Status_Bool_Exp>;
};

export type Query_RootTask_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Status_Order_By>>;
  where?: InputMaybe<Task_Status_Bool_Exp>;
};

export type Query_RootTask_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootTask_Sub_TypeArgs = {
  distinct_on?: InputMaybe<Array<Task_Sub_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Sub_Type_Order_By>>;
  where?: InputMaybe<Task_Sub_Type_Bool_Exp>;
};

export type Query_RootTask_Sub_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Sub_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Sub_Type_Order_By>>;
  where?: InputMaybe<Task_Sub_Type_Bool_Exp>;
};

export type Query_RootTask_Sub_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootTask_TypeArgs = {
  distinct_on?: InputMaybe<Array<Task_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Type_Order_By>>;
  where?: InputMaybe<Task_Type_Bool_Exp>;
};

export type Query_RootTask_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Type_Order_By>>;
  where?: InputMaybe<Task_Type_Bool_Exp>;
};

export type Query_RootTask_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootTasks_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Assignments_Order_By>>;
  where?: InputMaybe<Tasks_Assignments_Bool_Exp>;
};

export type Query_RootTasks_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Assignments_Order_By>>;
  where?: InputMaybe<Tasks_Assignments_Bool_Exp>;
};

export type Query_RootTasks_Assignments_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootTasks_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTasks_FilesArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Files_Order_By>>;
  where?: InputMaybe<Tasks_Files_Bool_Exp>;
};

export type Query_RootTasks_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Files_Order_By>>;
  where?: InputMaybe<Tasks_Files_Bool_Exp>;
};

export type Query_RootTasks_Files_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootTasks_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Groups_Order_By>>;
  where?: InputMaybe<Tasks_Groups_Bool_Exp>;
};

export type Query_RootTasks_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Groups_Order_By>>;
  where?: InputMaybe<Tasks_Groups_Bool_Exp>;
};

export type Query_RootTasks_Groups_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTasks_Groups_StaffArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Groups_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Groups_Staff_Order_By>>;
  where?: InputMaybe<Tasks_Groups_Staff_Bool_Exp>;
};

export type Query_RootTasks_Groups_Staff_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Groups_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Groups_Staff_Order_By>>;
  where?: InputMaybe<Tasks_Groups_Staff_Bool_Exp>;
};

export type Query_RootTasks_Groups_Staff_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootTasks_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

export type Query_RootTasks_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

export type Query_RootTasks_Notifications_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootTasks_ReadArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Read_Order_By>>;
  where?: InputMaybe<Tasks_Read_Bool_Exp>;
};

export type Query_RootTasks_Read_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Read_Order_By>>;
  where?: InputMaybe<Tasks_Read_Bool_Exp>;
};

export type Query_RootTasks_Read_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootTasks_ResponsibleArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Responsible_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Responsible_Order_By>>;
  where?: InputMaybe<Tasks_Responsible_Bool_Exp>;
};

export type Query_RootTasks_Responsible_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Responsible_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Responsible_Order_By>>;
  where?: InputMaybe<Tasks_Responsible_Bool_Exp>;
};

export type Query_RootTasks_WatchArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Watch_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Watch_Order_By>>;
  where?: InputMaybe<Tasks_Watch_Bool_Exp>;
};

export type Query_RootTasks_Watch_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Watch_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Watch_Order_By>>;
  where?: InputMaybe<Tasks_Watch_Bool_Exp>;
};

export type Query_RootTasks_Watch_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootTriage_ScheduleArgs = {
  distinct_on?: InputMaybe<Array<Triage_Schedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Triage_Schedule_Order_By>>;
  where?: InputMaybe<Triage_Schedule_Bool_Exp>;
};

export type Query_RootTriage_Schedule_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Triage_Schedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Triage_Schedule_Order_By>>;
  where?: InputMaybe<Triage_Schedule_Bool_Exp>;
};

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootVisit_ClassificationsArgs = {
  distinct_on?: InputMaybe<Array<Visit_Classifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Visit_Classifications_Order_By>>;
  where?: InputMaybe<Visit_Classifications_Bool_Exp>;
};

export type Query_RootVisit_Classifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Visit_Classifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Visit_Classifications_Order_By>>;
  where?: InputMaybe<Visit_Classifications_Bool_Exp>;
};

export type Query_RootVisit_Classifications_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootVisit_TypesArgs = {
  distinct_on?: InputMaybe<Array<Visit_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Visit_Types_Order_By>>;
  where?: InputMaybe<Visit_Types_Bool_Exp>;
};

export type Query_RootVisit_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Visit_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Visit_Types_Order_By>>;
  where?: InputMaybe<Visit_Types_Bool_Exp>;
};

export type Query_RootVisit_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** columns and relationships of "reconciliation_type" */
export type Reconciliation_Type = {
  __typename?: 'reconciliation_type';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "reconciliation_type" */
export type Reconciliation_Type_Aggregate = {
  __typename?: 'reconciliation_type_aggregate';
  aggregate?: Maybe<Reconciliation_Type_Aggregate_Fields>;
  nodes: Array<Reconciliation_Type>;
};

/** aggregate fields of "reconciliation_type" */
export type Reconciliation_Type_Aggregate_Fields = {
  __typename?: 'reconciliation_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Reconciliation_Type_Max_Fields>;
  min?: Maybe<Reconciliation_Type_Min_Fields>;
};

/** aggregate fields of "reconciliation_type" */
export type Reconciliation_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reconciliation_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "reconciliation_type". All fields are combined with a logical 'AND'. */
export type Reconciliation_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Reconciliation_Type_Bool_Exp>>;
  _not?: InputMaybe<Reconciliation_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Reconciliation_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "reconciliation_type" */
export type Reconciliation_Type_Constraint =
  /** unique or primary key constraint on columns "value" */
  'reconciliation_type_pkey';

export type Reconciliation_Type_Enum =
  /** Patient allergy data was reconciled */
  | 'ALLERGIES'
  /** Patient medical history data was reconciled */
  | 'MEDICAL_HISTORY'
  /** Patient medication data was reconciled */
  | 'MEDICATIONS'
  /** Patient problem data was reconciled */
  | 'PROBLEMS';

/** Boolean expression to compare columns of type "reconciliation_type_enum". All fields are combined with logical 'AND'. */
export type Reconciliation_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Reconciliation_Type_Enum>;
  _in?: InputMaybe<Array<Reconciliation_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Reconciliation_Type_Enum>;
  _nin?: InputMaybe<Array<Reconciliation_Type_Enum>>;
};

/** input type for inserting data into table "reconciliation_type" */
export type Reconciliation_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Reconciliation_Type_Max_Fields = {
  __typename?: 'reconciliation_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Reconciliation_Type_Min_Fields = {
  __typename?: 'reconciliation_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "reconciliation_type" */
export type Reconciliation_Type_Mutation_Response = {
  __typename?: 'reconciliation_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reconciliation_Type>;
};

/** on_conflict condition type for table "reconciliation_type" */
export type Reconciliation_Type_On_Conflict = {
  constraint: Reconciliation_Type_Constraint;
  update_columns?: Array<Reconciliation_Type_Update_Column>;
  where?: InputMaybe<Reconciliation_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "reconciliation_type". */
export type Reconciliation_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reconciliation_type */
export type Reconciliation_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "reconciliation_type" */
export type Reconciliation_Type_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "reconciliation_type" */
export type Reconciliation_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "reconciliation_type" */
export type Reconciliation_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reconciliation_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reconciliation_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "reconciliation_type" */
export type Reconciliation_Type_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Reconciliation_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reconciliation_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reconciliation_Type_Bool_Exp;
};

/** columns and relationships of "reconciliations" */
export type Reconciliations = {
  __typename?: 'reconciliations';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  patient: Patients;
  patientId: Scalars['uuid'];
  reconciledData: Scalars['jsonb'];
  /** An object relationship */
  staff: Staff;
  staffId: Scalars['uuid'];
  type: Reconciliation_Type_Enum;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "reconciliations" */
export type ReconciliationsReconciledDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "reconciliations" */
export type Reconciliations_Aggregate = {
  __typename?: 'reconciliations_aggregate';
  aggregate?: Maybe<Reconciliations_Aggregate_Fields>;
  nodes: Array<Reconciliations>;
};

export type Reconciliations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reconciliations_Aggregate_Bool_Exp_Count>;
};

export type Reconciliations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reconciliations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reconciliations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reconciliations" */
export type Reconciliations_Aggregate_Fields = {
  __typename?: 'reconciliations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Reconciliations_Max_Fields>;
  min?: Maybe<Reconciliations_Min_Fields>;
};

/** aggregate fields of "reconciliations" */
export type Reconciliations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reconciliations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reconciliations" */
export type Reconciliations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reconciliations_Max_Order_By>;
  min?: InputMaybe<Reconciliations_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Reconciliations_Append_Input = {
  reconciledData?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "reconciliations" */
export type Reconciliations_Arr_Rel_Insert_Input = {
  data: Array<Reconciliations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reconciliations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reconciliations". All fields are combined with a logical 'AND'. */
export type Reconciliations_Bool_Exp = {
  _and?: InputMaybe<Array<Reconciliations_Bool_Exp>>;
  _not?: InputMaybe<Reconciliations_Bool_Exp>;
  _or?: InputMaybe<Array<Reconciliations_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  reconciledData?: InputMaybe<Jsonb_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Reconciliation_Type_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "reconciliations" */
export type Reconciliations_Constraint =
  /** unique or primary key constraint on columns "id" */
  'reconciliations_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Reconciliations_Delete_At_Path_Input = {
  reconciledData?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Reconciliations_Delete_Elem_Input = {
  reconciledData?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Reconciliations_Delete_Key_Input = {
  reconciledData?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "reconciliations" */
export type Reconciliations_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  reconciledData?: InputMaybe<Scalars['jsonb']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Reconciliation_Type_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Reconciliations_Max_Fields = {
  __typename?: 'reconciliations_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  staffId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "reconciliations" */
export type Reconciliations_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reconciliations_Min_Fields = {
  __typename?: 'reconciliations_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  patientId?: Maybe<Scalars['uuid']>;
  staffId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "reconciliations" */
export type Reconciliations_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reconciliations" */
export type Reconciliations_Mutation_Response = {
  __typename?: 'reconciliations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reconciliations>;
};

/** on_conflict condition type for table "reconciliations" */
export type Reconciliations_On_Conflict = {
  constraint: Reconciliations_Constraint;
  update_columns?: Array<Reconciliations_Update_Column>;
  where?: InputMaybe<Reconciliations_Bool_Exp>;
};

/** Ordering options when selecting data from "reconciliations". */
export type Reconciliations_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  reconciledData?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reconciliations */
export type Reconciliations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Reconciliations_Prepend_Input = {
  reconciledData?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "reconciliations" */
export type Reconciliations_Select_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'patientId'
  /** column name */
  | 'reconciledData'
  /** column name */
  | 'staffId'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "reconciliations" */
export type Reconciliations_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  reconciledData?: InputMaybe<Scalars['jsonb']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Reconciliation_Type_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "reconciliations" */
export type Reconciliations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reconciliations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reconciliations_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  reconciledData?: InputMaybe<Scalars['jsonb']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Reconciliation_Type_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "reconciliations" */
export type Reconciliations_Update_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'patientId'
  /** column name */
  | 'reconciledData'
  /** column name */
  | 'staffId'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt';

export type Reconciliations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Reconciliations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Reconciliations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Reconciliations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Reconciliations_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Reconciliations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reconciliations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reconciliations_Bool_Exp;
};

/** columns and relationships of "regions" */
export type Regions = {
  __typename?: 'regions';
  /** An array relationship */
  clinics_regions: Array<Clinics_Regions>;
  /** An aggregate relationship */
  clinics_regions_aggregate: Clinics_Regions_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  location?: Maybe<Scalars['geography']>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  regionCode: Scalars['String'];
  /** An array relationship */
  staff_regions: Array<Staff_Regions>;
  /** An aggregate relationship */
  staff_regions_aggregate: Staff_Regions_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  virtualZoomRegionEmail?: Maybe<Scalars['String']>;
  virtualZoomRegionUserId?: Maybe<Scalars['String']>;
};

/** columns and relationships of "regions" */
export type RegionsClinics_RegionsArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Regions_Order_By>>;
  where?: InputMaybe<Clinics_Regions_Bool_Exp>;
};

/** columns and relationships of "regions" */
export type RegionsClinics_Regions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Regions_Order_By>>;
  where?: InputMaybe<Clinics_Regions_Bool_Exp>;
};

/** columns and relationships of "regions" */
export type RegionsStaff_RegionsArgs = {
  distinct_on?: InputMaybe<Array<Staff_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Regions_Order_By>>;
  where?: InputMaybe<Staff_Regions_Bool_Exp>;
};

/** columns and relationships of "regions" */
export type RegionsStaff_Regions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Regions_Order_By>>;
  where?: InputMaybe<Staff_Regions_Bool_Exp>;
};

/** aggregated selection of "regions" */
export type Regions_Aggregate = {
  __typename?: 'regions_aggregate';
  aggregate?: Maybe<Regions_Aggregate_Fields>;
  nodes: Array<Regions>;
};

/** aggregate fields of "regions" */
export type Regions_Aggregate_Fields = {
  __typename?: 'regions_aggregate_fields';
  avg?: Maybe<Regions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Regions_Max_Fields>;
  min?: Maybe<Regions_Min_Fields>;
  stddev?: Maybe<Regions_Stddev_Fields>;
  stddev_pop?: Maybe<Regions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Regions_Stddev_Samp_Fields>;
  sum?: Maybe<Regions_Sum_Fields>;
  var_pop?: Maybe<Regions_Var_Pop_Fields>;
  var_samp?: Maybe<Regions_Var_Samp_Fields>;
  variance?: Maybe<Regions_Variance_Fields>;
};

/** aggregate fields of "regions" */
export type Regions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Regions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Regions_Avg_Fields = {
  __typename?: 'regions_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "regions". All fields are combined with a logical 'AND'. */
export type Regions_Bool_Exp = {
  _and?: InputMaybe<Array<Regions_Bool_Exp>>;
  _not?: InputMaybe<Regions_Bool_Exp>;
  _or?: InputMaybe<Array<Regions_Bool_Exp>>;
  clinics_regions?: InputMaybe<Clinics_Regions_Bool_Exp>;
  clinics_regions_aggregate?: InputMaybe<Clinics_Regions_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  location?: InputMaybe<Geography_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  regionCode?: InputMaybe<String_Comparison_Exp>;
  staff_regions?: InputMaybe<Staff_Regions_Bool_Exp>;
  staff_regions_aggregate?: InputMaybe<Staff_Regions_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  virtualZoomRegionEmail?: InputMaybe<String_Comparison_Exp>;
  virtualZoomRegionUserId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "regions" */
export type Regions_Constraint =
  /** unique or primary key constraint on columns "id" */
  'regions_pkey';

/** input type for incrementing numeric columns in table "regions" */
export type Regions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "regions" */
export type Regions_Insert_Input = {
  clinics_regions?: InputMaybe<Clinics_Regions_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  location?: InputMaybe<Scalars['geography']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  regionCode?: InputMaybe<Scalars['String']>;
  staff_regions?: InputMaybe<Staff_Regions_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  virtualZoomRegionEmail?: InputMaybe<Scalars['String']>;
  virtualZoomRegionUserId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Regions_Max_Fields = {
  __typename?: 'regions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  virtualZoomRegionEmail?: Maybe<Scalars['String']>;
  virtualZoomRegionUserId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Regions_Min_Fields = {
  __typename?: 'regions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  virtualZoomRegionEmail?: Maybe<Scalars['String']>;
  virtualZoomRegionUserId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "regions" */
export type Regions_Mutation_Response = {
  __typename?: 'regions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Regions>;
};

/** input type for inserting object relation for remote table "regions" */
export type Regions_Obj_Rel_Insert_Input = {
  data: Regions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Regions_On_Conflict>;
};

/** on_conflict condition type for table "regions" */
export type Regions_On_Conflict = {
  constraint: Regions_Constraint;
  update_columns?: Array<Regions_Update_Column>;
  where?: InputMaybe<Regions_Bool_Exp>;
};

/** Ordering options when selecting data from "regions". */
export type Regions_Order_By = {
  clinics_regions_aggregate?: InputMaybe<Clinics_Regions_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  regionCode?: InputMaybe<Order_By>;
  staff_regions_aggregate?: InputMaybe<Staff_Regions_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  virtualZoomRegionEmail?: InputMaybe<Order_By>;
  virtualZoomRegionUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: regions */
export type Regions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "regions" */
export type Regions_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'location'
  /** column name */
  | 'name'
  /** column name */
  | 'phoneNumber'
  /** column name */
  | 'regionCode'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'virtualZoomRegionEmail'
  /** column name */
  | 'virtualZoomRegionUserId';

/** input type for updating data in table "regions" */
export type Regions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  location?: InputMaybe<Scalars['geography']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  regionCode?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  virtualZoomRegionEmail?: InputMaybe<Scalars['String']>;
  virtualZoomRegionUserId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Regions_Stddev_Fields = {
  __typename?: 'regions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Regions_Stddev_Pop_Fields = {
  __typename?: 'regions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Regions_Stddev_Samp_Fields = {
  __typename?: 'regions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "regions" */
export type Regions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Regions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Regions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  location?: InputMaybe<Scalars['geography']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  regionCode?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  virtualZoomRegionEmail?: InputMaybe<Scalars['String']>;
  virtualZoomRegionUserId?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Regions_Sum_Fields = {
  __typename?: 'regions_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "regions" */
export type Regions_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'location'
  /** column name */
  | 'name'
  /** column name */
  | 'phoneNumber'
  /** column name */
  | 'regionCode'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'virtualZoomRegionEmail'
  /** column name */
  | 'virtualZoomRegionUserId';

export type Regions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Regions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Regions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Regions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Regions_Var_Pop_Fields = {
  __typename?: 'regions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Regions_Var_Samp_Fields = {
  __typename?: 'regions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Regions_Variance_Fields = {
  __typename?: 'regions_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "reservations" */
export type Reservations = {
  __typename?: 'reservations';
  clinicId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  start_time?: Maybe<Scalars['timestamp']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visitClassification?: Maybe<Scalars['String']>;
  visitType?: Maybe<Scalars['String']>;
};

/** aggregated selection of "reservations" */
export type Reservations_Aggregate = {
  __typename?: 'reservations_aggregate';
  aggregate?: Maybe<Reservations_Aggregate_Fields>;
  nodes: Array<Reservations>;
};

/** aggregate fields of "reservations" */
export type Reservations_Aggregate_Fields = {
  __typename?: 'reservations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Reservations_Max_Fields>;
  min?: Maybe<Reservations_Min_Fields>;
};

/** aggregate fields of "reservations" */
export type Reservations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reservations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "reservations". All fields are combined with a logical 'AND'. */
export type Reservations_Bool_Exp = {
  _and?: InputMaybe<Array<Reservations_Bool_Exp>>;
  _not?: InputMaybe<Reservations_Bool_Exp>;
  _or?: InputMaybe<Array<Reservations_Bool_Exp>>;
  clinicId?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  end_time?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  start_time?: InputMaybe<Timestamp_Comparison_Exp>;
  timezone?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  visitClassification?: InputMaybe<String_Comparison_Exp>;
  visitType?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "reservations" */
export type Reservations_Insert_Input = {
  clinicId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_time?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  start_time?: InputMaybe<Scalars['timestamp']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visitClassification?: InputMaybe<Scalars['String']>;
  visitType?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Reservations_Max_Fields = {
  __typename?: 'reservations_max_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  start_time?: Maybe<Scalars['timestamp']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visitClassification?: Maybe<Scalars['String']>;
  visitType?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Reservations_Min_Fields = {
  __typename?: 'reservations_min_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_time?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  start_time?: Maybe<Scalars['timestamp']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visitClassification?: Maybe<Scalars['String']>;
  visitType?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "reservations" */
export type Reservations_Mutation_Response = {
  __typename?: 'reservations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reservations>;
};

/** Ordering options when selecting data from "reservations". */
export type Reservations_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visitClassification?: InputMaybe<Order_By>;
  visitType?: InputMaybe<Order_By>;
};

/** select columns of table "reservations" */
export type Reservations_Select_Column =
  /** column name */
  | 'clinicId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'end_time'
  /** column name */
  | 'id'
  /** column name */
  | 'start_time'
  /** column name */
  | 'timezone'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'visitClassification'
  /** column name */
  | 'visitType';

/** input type for updating data in table "reservations" */
export type Reservations_Set_Input = {
  clinicId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_time?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  start_time?: InputMaybe<Scalars['timestamp']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visitClassification?: InputMaybe<Scalars['String']>;
  visitType?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "reservations" */
export type Reservations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reservations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reservations_Stream_Cursor_Value_Input = {
  clinicId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_time?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  start_time?: InputMaybe<Scalars['timestamp']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visitClassification?: InputMaybe<Scalars['String']>;
  visitType?: InputMaybe<Scalars['String']>;
};

export type Reservations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reservations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reservations_Bool_Exp;
};

/** columns and relationships of "reviews" */
export type Reviews = {
  __typename?: 'reviews';
  /** An object relationship */
  appointment?: Maybe<Appointments>;
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  nps?: Maybe<Scalars['Int']>;
  review?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "reviews" */
export type Reviews_Aggregate = {
  __typename?: 'reviews_aggregate';
  aggregate?: Maybe<Reviews_Aggregate_Fields>;
  nodes: Array<Reviews>;
};

export type Reviews_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reviews_Aggregate_Bool_Exp_Count>;
};

export type Reviews_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reviews_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reviews_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reviews" */
export type Reviews_Aggregate_Fields = {
  __typename?: 'reviews_aggregate_fields';
  avg?: Maybe<Reviews_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Reviews_Max_Fields>;
  min?: Maybe<Reviews_Min_Fields>;
  stddev?: Maybe<Reviews_Stddev_Fields>;
  stddev_pop?: Maybe<Reviews_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reviews_Stddev_Samp_Fields>;
  sum?: Maybe<Reviews_Sum_Fields>;
  var_pop?: Maybe<Reviews_Var_Pop_Fields>;
  var_samp?: Maybe<Reviews_Var_Samp_Fields>;
  variance?: Maybe<Reviews_Variance_Fields>;
};

/** aggregate fields of "reviews" */
export type Reviews_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reviews_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reviews" */
export type Reviews_Aggregate_Order_By = {
  avg?: InputMaybe<Reviews_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reviews_Max_Order_By>;
  min?: InputMaybe<Reviews_Min_Order_By>;
  stddev?: InputMaybe<Reviews_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reviews_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reviews_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reviews_Sum_Order_By>;
  var_pop?: InputMaybe<Reviews_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reviews_Var_Samp_Order_By>;
  variance?: InputMaybe<Reviews_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reviews" */
export type Reviews_Arr_Rel_Insert_Input = {
  data: Array<Reviews_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};

/** aggregate avg on columns */
export type Reviews_Avg_Fields = {
  __typename?: 'reviews_avg_fields';
  nps?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "reviews" */
export type Reviews_Avg_Order_By = {
  nps?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reviews". All fields are combined with a logical 'AND'. */
export type Reviews_Bool_Exp = {
  _and?: InputMaybe<Array<Reviews_Bool_Exp>>;
  _not?: InputMaybe<Reviews_Bool_Exp>;
  _or?: InputMaybe<Array<Reviews_Bool_Exp>>;
  appointment?: InputMaybe<Appointments_Bool_Exp>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nps?: InputMaybe<Int_Comparison_Exp>;
  review?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "reviews" */
export type Reviews_Constraint =
  /** unique or primary key constraint on columns "id" */
  'reviews_pkey';

/** input type for incrementing numeric columns in table "reviews" */
export type Reviews_Inc_Input = {
  nps?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "reviews" */
export type Reviews_Insert_Input = {
  appointment?: InputMaybe<Appointments_Obj_Rel_Insert_Input>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nps?: InputMaybe<Scalars['Int']>;
  review?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Reviews_Max_Fields = {
  __typename?: 'reviews_max_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  nps?: Maybe<Scalars['Int']>;
  review?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "reviews" */
export type Reviews_Max_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nps?: InputMaybe<Order_By>;
  review?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reviews_Min_Fields = {
  __typename?: 'reviews_min_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  nps?: Maybe<Scalars['Int']>;
  review?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "reviews" */
export type Reviews_Min_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nps?: InputMaybe<Order_By>;
  review?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reviews" */
export type Reviews_Mutation_Response = {
  __typename?: 'reviews_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reviews>;
};

/** on_conflict condition type for table "reviews" */
export type Reviews_On_Conflict = {
  constraint: Reviews_Constraint;
  update_columns?: Array<Reviews_Update_Column>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

/** Ordering options when selecting data from "reviews". */
export type Reviews_Order_By = {
  appointment?: InputMaybe<Appointments_Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nps?: InputMaybe<Order_By>;
  review?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reviews */
export type Reviews_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "reviews" */
export type Reviews_Select_Column =
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'nps'
  /** column name */
  | 'review'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "reviews" */
export type Reviews_Set_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nps?: InputMaybe<Scalars['Int']>;
  review?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Reviews_Stddev_Fields = {
  __typename?: 'reviews_stddev_fields';
  nps?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "reviews" */
export type Reviews_Stddev_Order_By = {
  nps?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reviews_Stddev_Pop_Fields = {
  __typename?: 'reviews_stddev_pop_fields';
  nps?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "reviews" */
export type Reviews_Stddev_Pop_Order_By = {
  nps?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reviews_Stddev_Samp_Fields = {
  __typename?: 'reviews_stddev_samp_fields';
  nps?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "reviews" */
export type Reviews_Stddev_Samp_Order_By = {
  nps?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "reviews" */
export type Reviews_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reviews_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reviews_Stream_Cursor_Value_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nps?: InputMaybe<Scalars['Int']>;
  review?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Reviews_Sum_Fields = {
  __typename?: 'reviews_sum_fields';
  nps?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "reviews" */
export type Reviews_Sum_Order_By = {
  nps?: InputMaybe<Order_By>;
};

/** update columns of table "reviews" */
export type Reviews_Update_Column =
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'nps'
  /** column name */
  | 'review'
  /** column name */
  | 'updatedAt';

export type Reviews_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reviews_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reviews_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reviews_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reviews_Var_Pop_Fields = {
  __typename?: 'reviews_var_pop_fields';
  nps?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "reviews" */
export type Reviews_Var_Pop_Order_By = {
  nps?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reviews_Var_Samp_Fields = {
  __typename?: 'reviews_var_samp_fields';
  nps?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "reviews" */
export type Reviews_Var_Samp_Order_By = {
  nps?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Reviews_Variance_Fields = {
  __typename?: 'reviews_variance_fields';
  nps?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "reviews" */
export type Reviews_Variance_Order_By = {
  nps?: InputMaybe<Order_By>;
};

/** columns and relationships of "rooms" */
export type Rooms = {
  __typename?: 'rooms';
  /** An object relationship */
  appointment?: Maybe<Appointments>;
  appointmentId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  clinic: Clinics;
  clinicId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  signId: Scalars['String'];
  /** An object relationship */
  staff?: Maybe<Staff>;
  staffId?: Maybe<Scalars['uuid']>;
  status: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "rooms" */
export type Rooms_Aggregate = {
  __typename?: 'rooms_aggregate';
  aggregate?: Maybe<Rooms_Aggregate_Fields>;
  nodes: Array<Rooms>;
};

export type Rooms_Aggregate_Bool_Exp = {
  count?: InputMaybe<Rooms_Aggregate_Bool_Exp_Count>;
};

export type Rooms_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rooms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rooms_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rooms" */
export type Rooms_Aggregate_Fields = {
  __typename?: 'rooms_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Rooms_Max_Fields>;
  min?: Maybe<Rooms_Min_Fields>;
};

/** aggregate fields of "rooms" */
export type Rooms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rooms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rooms" */
export type Rooms_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rooms_Max_Order_By>;
  min?: InputMaybe<Rooms_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rooms" */
export type Rooms_Arr_Rel_Insert_Input = {
  data: Array<Rooms_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rooms_On_Conflict>;
};

/** Boolean expression to filter rows from the table "rooms". All fields are combined with a logical 'AND'. */
export type Rooms_Bool_Exp = {
  _and?: InputMaybe<Array<Rooms_Bool_Exp>>;
  _not?: InputMaybe<Rooms_Bool_Exp>;
  _or?: InputMaybe<Array<Rooms_Bool_Exp>>;
  appointment?: InputMaybe<Appointments_Bool_Exp>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  clinic?: InputMaybe<Clinics_Bool_Exp>;
  clinicId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  signId?: InputMaybe<String_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "rooms" */
export type Rooms_Constraint =
  /** unique or primary key constraint on columns "id" */
  'rooms_pkey';

/** input type for inserting data into table "rooms" */
export type Rooms_Insert_Input = {
  appointment?: InputMaybe<Appointments_Obj_Rel_Insert_Input>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  clinic?: InputMaybe<Clinics_Obj_Rel_Insert_Input>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  signId?: InputMaybe<Scalars['String']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Rooms_Max_Fields = {
  __typename?: 'rooms_max_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  clinicId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  signId?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "rooms" */
export type Rooms_Max_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  signId?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rooms_Min_Fields = {
  __typename?: 'rooms_min_fields';
  appointmentId?: Maybe<Scalars['uuid']>;
  clinicId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  signId?: Maybe<Scalars['String']>;
  staffId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "rooms" */
export type Rooms_Min_Order_By = {
  appointmentId?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  signId?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rooms" */
export type Rooms_Mutation_Response = {
  __typename?: 'rooms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rooms>;
};

/** on_conflict condition type for table "rooms" */
export type Rooms_On_Conflict = {
  constraint: Rooms_Constraint;
  update_columns?: Array<Rooms_Update_Column>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};

/** Ordering options when selecting data from "rooms". */
export type Rooms_Order_By = {
  appointment?: InputMaybe<Appointments_Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  clinic?: InputMaybe<Clinics_Order_By>;
  clinicId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  signId?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rooms */
export type Rooms_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "rooms" */
export type Rooms_Select_Column =
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'clinicId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'signId'
  /** column name */
  | 'staffId'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "rooms" */
export type Rooms_Set_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  signId?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "rooms" */
export type Rooms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rooms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rooms_Stream_Cursor_Value_Input = {
  appointmentId?: InputMaybe<Scalars['uuid']>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  signId?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "rooms" */
export type Rooms_Update_Column =
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'clinicId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'signId'
  /** column name */
  | 'staffId'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt';

export type Rooms_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rooms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rooms_Bool_Exp;
};

/** columns and relationships of "screening_category_types" */
export type Screening_Category_Types = {
  __typename?: 'screening_category_types';
  description: Scalars['String'];
  /** An array relationship */
  screening_types: Array<Screening_Types>;
  /** An aggregate relationship */
  screening_types_aggregate: Screening_Types_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "screening_category_types" */
export type Screening_Category_TypesScreening_TypesArgs = {
  distinct_on?: InputMaybe<Array<Screening_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screening_Types_Order_By>>;
  where?: InputMaybe<Screening_Types_Bool_Exp>;
};

/** columns and relationships of "screening_category_types" */
export type Screening_Category_TypesScreening_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screening_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screening_Types_Order_By>>;
  where?: InputMaybe<Screening_Types_Bool_Exp>;
};

/** aggregated selection of "screening_category_types" */
export type Screening_Category_Types_Aggregate = {
  __typename?: 'screening_category_types_aggregate';
  aggregate?: Maybe<Screening_Category_Types_Aggregate_Fields>;
  nodes: Array<Screening_Category_Types>;
};

/** aggregate fields of "screening_category_types" */
export type Screening_Category_Types_Aggregate_Fields = {
  __typename?: 'screening_category_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Screening_Category_Types_Max_Fields>;
  min?: Maybe<Screening_Category_Types_Min_Fields>;
};

/** aggregate fields of "screening_category_types" */
export type Screening_Category_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Screening_Category_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "screening_category_types". All fields are combined with a logical 'AND'. */
export type Screening_Category_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Screening_Category_Types_Bool_Exp>>;
  _not?: InputMaybe<Screening_Category_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Screening_Category_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  screening_types?: InputMaybe<Screening_Types_Bool_Exp>;
  screening_types_aggregate?: InputMaybe<Screening_Types_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "screening_category_types" */
export type Screening_Category_Types_Constraint =
  /** unique or primary key constraint on columns "value" */
  'screening_types_pkey';

export type Screening_Category_Types_Enum =
  /** Developmental */
  | 'DEVELOPMENTAL'
  /** Health */
  | 'HEALTH'
  /** Mental health */
  | 'MENTAL_HEALTH'
  /** Social */
  | 'SOCIAL';

/** Boolean expression to compare columns of type "screening_category_types_enum". All fields are combined with logical 'AND'. */
export type Screening_Category_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Screening_Category_Types_Enum>;
  _in?: InputMaybe<Array<Screening_Category_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Screening_Category_Types_Enum>;
  _nin?: InputMaybe<Array<Screening_Category_Types_Enum>>;
};

/** input type for inserting data into table "screening_category_types" */
export type Screening_Category_Types_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  screening_types?: InputMaybe<Screening_Types_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Screening_Category_Types_Max_Fields = {
  __typename?: 'screening_category_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Screening_Category_Types_Min_Fields = {
  __typename?: 'screening_category_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "screening_category_types" */
export type Screening_Category_Types_Mutation_Response = {
  __typename?: 'screening_category_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Screening_Category_Types>;
};

/** input type for inserting object relation for remote table "screening_category_types" */
export type Screening_Category_Types_Obj_Rel_Insert_Input = {
  data: Screening_Category_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Screening_Category_Types_On_Conflict>;
};

/** on_conflict condition type for table "screening_category_types" */
export type Screening_Category_Types_On_Conflict = {
  constraint: Screening_Category_Types_Constraint;
  update_columns?: Array<Screening_Category_Types_Update_Column>;
  where?: InputMaybe<Screening_Category_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "screening_category_types". */
export type Screening_Category_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  screening_types_aggregate?: InputMaybe<Screening_Types_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: screening_category_types */
export type Screening_Category_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "screening_category_types" */
export type Screening_Category_Types_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "screening_category_types" */
export type Screening_Category_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "screening_category_types" */
export type Screening_Category_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Screening_Category_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Screening_Category_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "screening_category_types" */
export type Screening_Category_Types_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Screening_Category_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Screening_Category_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Screening_Category_Types_Bool_Exp;
};

/** columns and relationships of "screening_types" */
export type Screening_Types = {
  __typename?: 'screening_types';
  category: Screening_Category_Types_Enum;
  description: Scalars['String'];
  /** An object relationship */
  screening_category_type: Screening_Category_Types;
  /** An array relationship */
  screenings: Array<Screenings>;
  /** An aggregate relationship */
  screenings_aggregate: Screenings_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "screening_types" */
export type Screening_TypesScreeningsArgs = {
  distinct_on?: InputMaybe<Array<Screenings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screenings_Order_By>>;
  where?: InputMaybe<Screenings_Bool_Exp>;
};

/** columns and relationships of "screening_types" */
export type Screening_TypesScreenings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screenings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screenings_Order_By>>;
  where?: InputMaybe<Screenings_Bool_Exp>;
};

/** aggregated selection of "screening_types" */
export type Screening_Types_Aggregate = {
  __typename?: 'screening_types_aggregate';
  aggregate?: Maybe<Screening_Types_Aggregate_Fields>;
  nodes: Array<Screening_Types>;
};

export type Screening_Types_Aggregate_Bool_Exp = {
  count?: InputMaybe<Screening_Types_Aggregate_Bool_Exp_Count>;
};

export type Screening_Types_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Screening_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Screening_Types_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "screening_types" */
export type Screening_Types_Aggregate_Fields = {
  __typename?: 'screening_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Screening_Types_Max_Fields>;
  min?: Maybe<Screening_Types_Min_Fields>;
};

/** aggregate fields of "screening_types" */
export type Screening_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Screening_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "screening_types" */
export type Screening_Types_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Screening_Types_Max_Order_By>;
  min?: InputMaybe<Screening_Types_Min_Order_By>;
};

/** input type for inserting array relation for remote table "screening_types" */
export type Screening_Types_Arr_Rel_Insert_Input = {
  data: Array<Screening_Types_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Screening_Types_On_Conflict>;
};

/** Boolean expression to filter rows from the table "screening_types". All fields are combined with a logical 'AND'. */
export type Screening_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Screening_Types_Bool_Exp>>;
  _not?: InputMaybe<Screening_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Screening_Types_Bool_Exp>>;
  category?: InputMaybe<Screening_Category_Types_Enum_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  screening_category_type?: InputMaybe<Screening_Category_Types_Bool_Exp>;
  screenings?: InputMaybe<Screenings_Bool_Exp>;
  screenings_aggregate?: InputMaybe<Screenings_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "screening_types" */
export type Screening_Types_Constraint =
  /** unique or primary key constraint on columns "value" */
  'screening_types_pkey1';

/** input type for inserting data into table "screening_types" */
export type Screening_Types_Insert_Input = {
  category?: InputMaybe<Screening_Category_Types_Enum>;
  description?: InputMaybe<Scalars['String']>;
  screening_category_type?: InputMaybe<Screening_Category_Types_Obj_Rel_Insert_Input>;
  screenings?: InputMaybe<Screenings_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Screening_Types_Max_Fields = {
  __typename?: 'screening_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "screening_types" */
export type Screening_Types_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Screening_Types_Min_Fields = {
  __typename?: 'screening_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "screening_types" */
export type Screening_Types_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "screening_types" */
export type Screening_Types_Mutation_Response = {
  __typename?: 'screening_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Screening_Types>;
};

/** input type for inserting object relation for remote table "screening_types" */
export type Screening_Types_Obj_Rel_Insert_Input = {
  data: Screening_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Screening_Types_On_Conflict>;
};

/** on_conflict condition type for table "screening_types" */
export type Screening_Types_On_Conflict = {
  constraint: Screening_Types_Constraint;
  update_columns?: Array<Screening_Types_Update_Column>;
  where?: InputMaybe<Screening_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "screening_types". */
export type Screening_Types_Order_By = {
  category?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  screening_category_type?: InputMaybe<Screening_Category_Types_Order_By>;
  screenings_aggregate?: InputMaybe<Screenings_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: screening_types */
export type Screening_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "screening_types" */
export type Screening_Types_Select_Column =
  /** column name */
  | 'category'
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "screening_types" */
export type Screening_Types_Set_Input = {
  category?: InputMaybe<Screening_Category_Types_Enum>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "screening_types" */
export type Screening_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Screening_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Screening_Types_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Screening_Category_Types_Enum>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "screening_types" */
export type Screening_Types_Update_Column =
  /** column name */
  | 'category'
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Screening_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Screening_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Screening_Types_Bool_Exp;
};

/** columns and relationships of "screenings" */
export type Screenings = {
  __typename?: 'screenings';
  comments?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  document?: Maybe<Documents>;
  documentId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  patient: Patients;
  patientId: Scalars['uuid'];
  result?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  /** An object relationship */
  screening_status_type?: Maybe<Patient_Record_Status_Types>;
  /** An object relationship */
  screening_type: Screening_Types;
  /** An object relationship */
  staff?: Maybe<Staff>;
  staffId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Patient_Record_Status_Types_Enum>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "screenings" */
export type Screenings_Aggregate = {
  __typename?: 'screenings_aggregate';
  aggregate?: Maybe<Screenings_Aggregate_Fields>;
  nodes: Array<Screenings>;
};

export type Screenings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Screenings_Aggregate_Bool_Exp_Count>;
};

export type Screenings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Screenings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Screenings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "screenings" */
export type Screenings_Aggregate_Fields = {
  __typename?: 'screenings_aggregate_fields';
  avg?: Maybe<Screenings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Screenings_Max_Fields>;
  min?: Maybe<Screenings_Min_Fields>;
  stddev?: Maybe<Screenings_Stddev_Fields>;
  stddev_pop?: Maybe<Screenings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Screenings_Stddev_Samp_Fields>;
  sum?: Maybe<Screenings_Sum_Fields>;
  var_pop?: Maybe<Screenings_Var_Pop_Fields>;
  var_samp?: Maybe<Screenings_Var_Samp_Fields>;
  variance?: Maybe<Screenings_Variance_Fields>;
};

/** aggregate fields of "screenings" */
export type Screenings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Screenings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "screenings" */
export type Screenings_Aggregate_Order_By = {
  avg?: InputMaybe<Screenings_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Screenings_Max_Order_By>;
  min?: InputMaybe<Screenings_Min_Order_By>;
  stddev?: InputMaybe<Screenings_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Screenings_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Screenings_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Screenings_Sum_Order_By>;
  var_pop?: InputMaybe<Screenings_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Screenings_Var_Samp_Order_By>;
  variance?: InputMaybe<Screenings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "screenings" */
export type Screenings_Arr_Rel_Insert_Input = {
  data: Array<Screenings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Screenings_On_Conflict>;
};

/** aggregate avg on columns */
export type Screenings_Avg_Fields = {
  __typename?: 'screenings_avg_fields';
  score?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "screenings" */
export type Screenings_Avg_Order_By = {
  score?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "screenings". All fields are combined with a logical 'AND'. */
export type Screenings_Bool_Exp = {
  _and?: InputMaybe<Array<Screenings_Bool_Exp>>;
  _not?: InputMaybe<Screenings_Bool_Exp>;
  _or?: InputMaybe<Array<Screenings_Bool_Exp>>;
  comments?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  document?: InputMaybe<Documents_Bool_Exp>;
  documentId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  result?: InputMaybe<String_Comparison_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
  screening_status_type?: InputMaybe<Patient_Record_Status_Types_Bool_Exp>;
  screening_type?: InputMaybe<Screening_Types_Bool_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Patient_Record_Status_Types_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "screenings" */
export type Screenings_Constraint =
  /** unique or primary key constraint on columns "id" */
  'screenings_pkey';

/** input type for incrementing numeric columns in table "screenings" */
export type Screenings_Inc_Input = {
  score?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "screenings" */
export type Screenings_Insert_Input = {
  comments?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  document?: InputMaybe<Documents_Obj_Rel_Insert_Input>;
  documentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  result?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Int']>;
  screening_status_type?: InputMaybe<Patient_Record_Status_Types_Obj_Rel_Insert_Input>;
  screening_type?: InputMaybe<Screening_Types_Obj_Rel_Insert_Input>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Patient_Record_Status_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Screenings_Max_Fields = {
  __typename?: 'screenings_max_fields';
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  documentId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  result?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  staffId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "screenings" */
export type Screenings_Max_Order_By = {
  comments?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  documentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Screenings_Min_Fields = {
  __typename?: 'screenings_min_fields';
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  documentId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['uuid']>;
  result?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  staffId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "screenings" */
export type Screenings_Min_Order_By = {
  comments?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  documentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "screenings" */
export type Screenings_Mutation_Response = {
  __typename?: 'screenings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Screenings>;
};

/** on_conflict condition type for table "screenings" */
export type Screenings_On_Conflict = {
  constraint: Screenings_Constraint;
  update_columns?: Array<Screenings_Update_Column>;
  where?: InputMaybe<Screenings_Bool_Exp>;
};

/** Ordering options when selecting data from "screenings". */
export type Screenings_Order_By = {
  comments?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  document?: InputMaybe<Documents_Order_By>;
  documentId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  screening_status_type?: InputMaybe<Patient_Record_Status_Types_Order_By>;
  screening_type?: InputMaybe<Screening_Types_Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: screenings */
export type Screenings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "screenings" */
export type Screenings_Select_Column =
  /** column name */
  | 'comments'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'documentId'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'patientId'
  /** column name */
  | 'result'
  /** column name */
  | 'score'
  /** column name */
  | 'staffId'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "screenings" */
export type Screenings_Set_Input = {
  comments?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  documentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  result?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Int']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Patient_Record_Status_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Screenings_Stddev_Fields = {
  __typename?: 'screenings_stddev_fields';
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "screenings" */
export type Screenings_Stddev_Order_By = {
  score?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Screenings_Stddev_Pop_Fields = {
  __typename?: 'screenings_stddev_pop_fields';
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "screenings" */
export type Screenings_Stddev_Pop_Order_By = {
  score?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Screenings_Stddev_Samp_Fields = {
  __typename?: 'screenings_stddev_samp_fields';
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "screenings" */
export type Screenings_Stddev_Samp_Order_By = {
  score?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "screenings" */
export type Screenings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Screenings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Screenings_Stream_Cursor_Value_Input = {
  comments?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  documentId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  result?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Int']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Patient_Record_Status_Types_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Screenings_Sum_Fields = {
  __typename?: 'screenings_sum_fields';
  score?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "screenings" */
export type Screenings_Sum_Order_By = {
  score?: InputMaybe<Order_By>;
};

/** update columns of table "screenings" */
export type Screenings_Update_Column =
  /** column name */
  | 'comments'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'documentId'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'patientId'
  /** column name */
  | 'result'
  /** column name */
  | 'score'
  /** column name */
  | 'staffId'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt';

export type Screenings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Screenings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Screenings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Screenings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Screenings_Var_Pop_Fields = {
  __typename?: 'screenings_var_pop_fields';
  score?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "screenings" */
export type Screenings_Var_Pop_Order_By = {
  score?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Screenings_Var_Samp_Fields = {
  __typename?: 'screenings_var_samp_fields';
  score?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "screenings" */
export type Screenings_Var_Samp_Order_By = {
  score?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Screenings_Variance_Fields = {
  __typename?: 'screenings_variance_fields';
  score?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "screenings" */
export type Screenings_Variance_Order_By = {
  score?: InputMaybe<Order_By>;
};

export type Search_Accounts_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Patients_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Snomed_Synonyms_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Staff_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Tasks_Args = {
  search?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "services" */
export type Services = {
  __typename?: 'services';
  description: Scalars['String'];
  /** An array relationship */
  staff_schedule_services: Array<Staff_Schedule_Service>;
  /** An aggregate relationship */
  staff_schedule_services_aggregate: Staff_Schedule_Service_Aggregate;
  /** An array relationship */
  staff_services: Array<Staff_Services>;
  /** An aggregate relationship */
  staff_services_aggregate: Staff_Services_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "services" */
export type ServicesStaff_Schedule_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Staff_Schedule_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Schedule_Service_Order_By>>;
  where?: InputMaybe<Staff_Schedule_Service_Bool_Exp>;
};

/** columns and relationships of "services" */
export type ServicesStaff_Schedule_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Schedule_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Schedule_Service_Order_By>>;
  where?: InputMaybe<Staff_Schedule_Service_Bool_Exp>;
};

/** columns and relationships of "services" */
export type ServicesStaff_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Staff_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Services_Order_By>>;
  where?: InputMaybe<Staff_Services_Bool_Exp>;
};

/** columns and relationships of "services" */
export type ServicesStaff_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Services_Order_By>>;
  where?: InputMaybe<Staff_Services_Bool_Exp>;
};

/** aggregated selection of "services" */
export type Services_Aggregate = {
  __typename?: 'services_aggregate';
  aggregate?: Maybe<Services_Aggregate_Fields>;
  nodes: Array<Services>;
};

/** aggregate fields of "services" */
export type Services_Aggregate_Fields = {
  __typename?: 'services_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Services_Max_Fields>;
  min?: Maybe<Services_Min_Fields>;
};

/** aggregate fields of "services" */
export type Services_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Services_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "services". All fields are combined with a logical 'AND'. */
export type Services_Bool_Exp = {
  _and?: InputMaybe<Array<Services_Bool_Exp>>;
  _not?: InputMaybe<Services_Bool_Exp>;
  _or?: InputMaybe<Array<Services_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  staff_schedule_services?: InputMaybe<Staff_Schedule_Service_Bool_Exp>;
  staff_schedule_services_aggregate?: InputMaybe<Staff_Schedule_Service_Aggregate_Bool_Exp>;
  staff_services?: InputMaybe<Staff_Services_Bool_Exp>;
  staff_services_aggregate?: InputMaybe<Staff_Services_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "services" */
export type Services_Constraint =
  /** unique or primary key constraint on columns "value" */
  'services_pkey';

export type Services_Enum =
  /** Meet and Greet */
  | 'MEET_AND_GREET'
  /** On Call */
  | 'ONCALL'
  /** Primary Care */
  | 'PRIMARY_CARE'
  /** Telemedicine */
  | 'TELEMEDICINE'
  /** Testing */
  | 'TESTING'
  /** Triage Line */
  | 'TRIAGE'
  /** Urgent Care */
  | 'URGENT_CARE'
  /** Vaccination */
  | 'VACCINATION'
  /** X-Ray */
  | 'XRAY'
  /** X-Ray On Call */
  | 'XRAY_ONCALL';

/** Boolean expression to compare columns of type "services_enum". All fields are combined with logical 'AND'. */
export type Services_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Services_Enum>;
  _in?: InputMaybe<Array<Services_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Services_Enum>;
  _nin?: InputMaybe<Array<Services_Enum>>;
};

/** input type for inserting data into table "services" */
export type Services_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  staff_schedule_services?: InputMaybe<Staff_Schedule_Service_Arr_Rel_Insert_Input>;
  staff_services?: InputMaybe<Staff_Services_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Services_Max_Fields = {
  __typename?: 'services_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Services_Min_Fields = {
  __typename?: 'services_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "services" */
export type Services_Mutation_Response = {
  __typename?: 'services_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Services>;
};

/** input type for inserting object relation for remote table "services" */
export type Services_Obj_Rel_Insert_Input = {
  data: Services_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Services_On_Conflict>;
};

/** on_conflict condition type for table "services" */
export type Services_On_Conflict = {
  constraint: Services_Constraint;
  update_columns?: Array<Services_Update_Column>;
  where?: InputMaybe<Services_Bool_Exp>;
};

/** Ordering options when selecting data from "services". */
export type Services_Order_By = {
  description?: InputMaybe<Order_By>;
  staff_schedule_services_aggregate?: InputMaybe<Staff_Schedule_Service_Aggregate_Order_By>;
  staff_services_aggregate?: InputMaybe<Staff_Services_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: services */
export type Services_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "services" */
export type Services_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "services" */
export type Services_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "services" */
export type Services_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Services_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Services_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "services" */
export type Services_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Services_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Services_Set_Input>;
  /** filter the rows which have to be updated */
  where: Services_Bool_Exp;
};

/** Our own custom synonyms for when SNOMED doesn't give us what we want */
export type Snomed_Synonyms = {
  __typename?: 'snomed_synonyms';
  code: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  synonym: Scalars['String'];
};

export type Snomed_Synonyms_Aggregate = {
  __typename?: 'snomed_synonyms_aggregate';
  aggregate?: Maybe<Snomed_Synonyms_Aggregate_Fields>;
  nodes: Array<Snomed_Synonyms>;
};

/** aggregate fields of "snomed_synonyms" */
export type Snomed_Synonyms_Aggregate_Fields = {
  __typename?: 'snomed_synonyms_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Snomed_Synonyms_Max_Fields>;
  min?: Maybe<Snomed_Synonyms_Min_Fields>;
};

/** aggregate fields of "snomed_synonyms" */
export type Snomed_Synonyms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Snomed_Synonyms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "snomed_synonyms". All fields are combined with a logical 'AND'. */
export type Snomed_Synonyms_Bool_Exp = {
  _and?: InputMaybe<Array<Snomed_Synonyms_Bool_Exp>>;
  _not?: InputMaybe<Snomed_Synonyms_Bool_Exp>;
  _or?: InputMaybe<Array<Snomed_Synonyms_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  synonym?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "snomed_synonyms" */
export type Snomed_Synonyms_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'snomed_synonyms_pkey'
  /** unique or primary key constraint on columns "synonym" */
  | 'snomed_synonyms_synonym_key';

/** input type for inserting data into table "snomed_synonyms" */
export type Snomed_Synonyms_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  synonym?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Snomed_Synonyms_Max_Fields = {
  __typename?: 'snomed_synonyms_max_fields';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  synonym?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Snomed_Synonyms_Min_Fields = {
  __typename?: 'snomed_synonyms_min_fields';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  synonym?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "snomed_synonyms" */
export type Snomed_Synonyms_Mutation_Response = {
  __typename?: 'snomed_synonyms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Snomed_Synonyms>;
};

/** on_conflict condition type for table "snomed_synonyms" */
export type Snomed_Synonyms_On_Conflict = {
  constraint: Snomed_Synonyms_Constraint;
  update_columns?: Array<Snomed_Synonyms_Update_Column>;
  where?: InputMaybe<Snomed_Synonyms_Bool_Exp>;
};

/** Ordering options when selecting data from "snomed_synonyms". */
export type Snomed_Synonyms_Order_By = {
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  synonym?: InputMaybe<Order_By>;
};

/** primary key columns input for table: snomed_synonyms */
export type Snomed_Synonyms_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "snomed_synonyms" */
export type Snomed_Synonyms_Select_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'synonym';

/** input type for updating data in table "snomed_synonyms" */
export type Snomed_Synonyms_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  synonym?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "snomed_synonyms" */
export type Snomed_Synonyms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Snomed_Synonyms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Snomed_Synonyms_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  synonym?: InputMaybe<Scalars['String']>;
};

/** update columns of table "snomed_synonyms" */
export type Snomed_Synonyms_Update_Column =
  /** column name */
  | 'code'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'synonym';

export type Snomed_Synonyms_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Snomed_Synonyms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Snomed_Synonyms_Bool_Exp;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: InputMaybe<Scalars['Boolean']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

/** columns and relationships of "staff" */
export type Staff = {
  __typename?: 'staff';
  active: Scalars['Boolean'];
  /** An array relationship */
  appointment_blocks: Array<Appointment_Blocks>;
  /** An aggregate relationship */
  appointment_blocks_aggregate: Appointment_Blocks_Aggregate;
  /** An array relationship */
  appointment_blocks_staffs: Array<Appointment_Blocks_Staff>;
  /** An aggregate relationship */
  appointment_blocks_staffs_aggregate: Appointment_Blocks_Staff_Aggregate;
  /** An array relationship */
  appointment_staffs: Array<Appointment_Staff>;
  /** An aggregate relationship */
  appointment_staffs_aggregate: Appointment_Staff_Aggregate;
  /** An array relationship */
  appointments: Array<Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Appointments_Aggregate;
  /** An array relationship */
  audit_logs: Array<Audit_Logs>;
  /** An aggregate relationship */
  audit_logs_aggregate: Audit_Logs_Aggregate;
  auth0UserId?: Maybe<Scalars['String']>;
  authProviderId?: Maybe<Scalars['String']>;
  auth_roles: Array<Maybe<Scalars['String']>>;
  avatarUrl?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  /** An array relationship */
  charges: Array<Charges>;
  /** An aggregate relationship */
  charges_aggregate: Charges_Aggregate;
  /** An array relationship */
  chat_messages: Array<Chat_Messages>;
  /** An aggregate relationship */
  chat_messages_aggregate: Chat_Messages_Aggregate;
  /** An array relationship */
  chat_notes: Array<Chat_Notes>;
  /** An aggregate relationship */
  chat_notes_aggregate: Chat_Notes_Aggregate;
  /** An array relationship */
  chat_sessions_reads: Array<Chat_Sessions_Read>;
  /** An aggregate relationship */
  chat_sessions_reads_aggregate: Chat_Sessions_Read_Aggregate;
  /** An array relationship */
  chat_sessions_staffs: Array<Chat_Sessions_Staff>;
  /** An aggregate relationship */
  chat_sessions_staffs_aggregate: Chat_Sessions_Staff_Aggregate;
  /** An object relationship */
  clinic?: Maybe<Clinics>;
  color?: Maybe<Scalars['String']>;
  /** An array relationship */
  communication_histories: Array<Communication_History>;
  /** An aggregate relationship */
  communication_histories_aggregate: Communication_History_Aggregate;
  created_at: Scalars['timestamptz'];
  defaultClinicId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  fhir_resources_histories: Array<Fhir_Resources_History>;
  /** An aggregate relationship */
  fhir_resources_histories_aggregate: Fhir_Resources_History_Aggregate;
  firstName: Scalars['String'];
  /** An array relationship */
  global_notes: Array<Global_Notes>;
  /** An aggregate relationship */
  global_notes_aggregate: Global_Notes_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  kareo_logs: Array<Kareo_Logs>;
  /** An aggregate relationship */
  kareo_logs_aggregate: Kareo_Logs_Aggregate;
  languagesSpoken?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  lead: Scalars['Boolean'];
  /** An array relationship */
  medical_histories: Array<Medical_History>;
  /** An aggregate relationship */
  medical_histories_aggregate: Medical_History_Aggregate;
  /** An array relationship */
  medical_history_answers: Array<Medical_History_Answers>;
  /** An aggregate relationship */
  medical_history_answers_aggregate: Medical_History_Answers_Aggregate;
  /** An array relationship */
  patient_migrations: Array<Patient_Migrations>;
  /** An aggregate relationship */
  patient_migrations_aggregate: Patient_Migrations_Aggregate;
  /** An array relationship */
  patients: Array<Patients>;
  /** An aggregate relationship */
  patients_aggregate: Patients_Aggregate;
  phoneNumber?: Maybe<Scalars['String']>;
  /** An array relationship */
  phone_numbers: Array<Phone_Numbers>;
  /** An aggregate relationship */
  phone_numbers_aggregate: Phone_Numbers_Aggregate;
  preferredPronoun?: Maybe<Scalars['String']>;
  preferredPronounType?: Maybe<Scalars['String']>;
  /** An array relationship */
  reconciliations: Array<Reconciliations>;
  /** An aggregate relationship */
  reconciliations_aggregate: Reconciliations_Aggregate;
  roles?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  rooms: Array<Rooms>;
  /** An aggregate relationship */
  rooms_aggregate: Rooms_Aggregate;
  /** An array relationship */
  screenings: Array<Screenings>;
  /** An aggregate relationship */
  screenings_aggregate: Screenings_Aggregate;
  /** An object relationship */
  staff?: Maybe<Staff>;
  staffManagerId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  staff_regions: Array<Staff_Regions>;
  /** An aggregate relationship */
  staff_regions_aggregate: Staff_Regions_Aggregate;
  /** An array relationship */
  staff_schedules: Array<Staff_Schedule>;
  /** An aggregate relationship */
  staff_schedules_aggregate: Staff_Schedule_Aggregate;
  /** An array relationship */
  staff_services: Array<Staff_Services>;
  /** An aggregate relationship */
  staff_services_aggregate: Staff_Services_Aggregate;
  /** An object relationship */
  staff_type: Staff_Type;
  /** An array relationship */
  staffs: Array<Staff>;
  /** An aggregate relationship */
  staffs_aggregate: Staff_Aggregate;
  state?: Maybe<Scalars['String']>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An array relationship */
  tasksAssignmentsByAssignedby: Array<Tasks_Assignments>;
  /** An aggregate relationship */
  tasksAssignmentsByAssignedby_aggregate: Tasks_Assignments_Aggregate;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** An array relationship */
  tasks_assignments: Array<Tasks_Assignments>;
  /** An aggregate relationship */
  tasks_assignments_aggregate: Tasks_Assignments_Aggregate;
  /** An array relationship */
  tasks_files: Array<Tasks_Files>;
  /** An aggregate relationship */
  tasks_files_aggregate: Tasks_Files_Aggregate;
  /** An array relationship */
  tasks_groups_staffs: Array<Tasks_Groups_Staff>;
  /** An aggregate relationship */
  tasks_groups_staffs_aggregate: Tasks_Groups_Staff_Aggregate;
  /** An array relationship */
  tasks_notifications: Array<Tasks_Notifications>;
  /** An aggregate relationship */
  tasks_notifications_aggregate: Tasks_Notifications_Aggregate;
  /** An array relationship */
  tasks_reads: Array<Tasks_Read>;
  /** An aggregate relationship */
  tasks_reads_aggregate: Tasks_Read_Aggregate;
  /** An array relationship */
  tasks_watches: Array<Tasks_Watch>;
  /** An aggregate relationship */
  tasks_watches_aggregate: Tasks_Watch_Aggregate;
  timeZone: Scalars['String'];
  type: Staff_Type_Enum;
  updated_at: Scalars['timestamptz'];
  wiwUserId?: Maybe<Scalars['String']>;
};

/** columns and relationships of "staff" */
export type StaffAppointment_BlocksArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Blocks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Blocks_Order_By>>;
  where?: InputMaybe<Appointment_Blocks_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffAppointment_Blocks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Blocks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Blocks_Order_By>>;
  where?: InputMaybe<Appointment_Blocks_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffAppointment_Blocks_StaffsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Blocks_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Blocks_Staff_Order_By>>;
  where?: InputMaybe<Appointment_Blocks_Staff_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffAppointment_Blocks_Staffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Blocks_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Blocks_Staff_Order_By>>;
  where?: InputMaybe<Appointment_Blocks_Staff_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffAppointment_StaffsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Staff_Order_By>>;
  where?: InputMaybe<Appointment_Staff_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffAppointment_Staffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Staff_Order_By>>;
  where?: InputMaybe<Appointment_Staff_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffAudit_LogsArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Logs_Order_By>>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffAudit_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Logs_Order_By>>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffChargesArgs = {
  distinct_on?: InputMaybe<Array<Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charges_Order_By>>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffCharges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charges_Order_By>>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_Order_By>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_Order_By>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffChat_NotesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Notes_Order_By>>;
  where?: InputMaybe<Chat_Notes_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffChat_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Notes_Order_By>>;
  where?: InputMaybe<Chat_Notes_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffChat_Sessions_ReadsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Read_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Read_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffChat_Sessions_Reads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Read_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Read_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffChat_Sessions_StaffsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Staff_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Staff_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffChat_Sessions_Staffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Staff_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Staff_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffCommunication_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Order_By>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffCommunication_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Order_By>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffFhir_Resources_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_History_Order_By>>;
  where?: InputMaybe<Fhir_Resources_History_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffFhir_Resources_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_History_Order_By>>;
  where?: InputMaybe<Fhir_Resources_History_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffGlobal_NotesArgs = {
  distinct_on?: InputMaybe<Array<Global_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Notes_Order_By>>;
  where?: InputMaybe<Global_Notes_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffGlobal_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Notes_Order_By>>;
  where?: InputMaybe<Global_Notes_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffKareo_LogsArgs = {
  distinct_on?: InputMaybe<Array<Kareo_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kareo_Logs_Order_By>>;
  where?: InputMaybe<Kareo_Logs_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffKareo_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kareo_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kareo_Logs_Order_By>>;
  where?: InputMaybe<Kareo_Logs_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffMedical_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Order_By>>;
  where?: InputMaybe<Medical_History_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffMedical_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Order_By>>;
  where?: InputMaybe<Medical_History_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffMedical_History_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Answers_Order_By>>;
  where?: InputMaybe<Medical_History_Answers_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffMedical_History_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Answers_Order_By>>;
  where?: InputMaybe<Medical_History_Answers_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffPatient_MigrationsArgs = {
  distinct_on?: InputMaybe<Array<Patient_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Migrations_Order_By>>;
  where?: InputMaybe<Patient_Migrations_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffPatient_Migrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Migrations_Order_By>>;
  where?: InputMaybe<Patient_Migrations_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffPatients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffPhone_NumbersArgs = {
  distinct_on?: InputMaybe<Array<Phone_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phone_Numbers_Order_By>>;
  where?: InputMaybe<Phone_Numbers_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffPhone_Numbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Phone_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phone_Numbers_Order_By>>;
  where?: InputMaybe<Phone_Numbers_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffReconciliationsArgs = {
  distinct_on?: InputMaybe<Array<Reconciliations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reconciliations_Order_By>>;
  where?: InputMaybe<Reconciliations_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffReconciliations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reconciliations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reconciliations_Order_By>>;
  where?: InputMaybe<Reconciliations_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffRolesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "staff" */
export type StaffRoomsArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffRooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffScreeningsArgs = {
  distinct_on?: InputMaybe<Array<Screenings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screenings_Order_By>>;
  where?: InputMaybe<Screenings_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffScreenings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screenings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screenings_Order_By>>;
  where?: InputMaybe<Screenings_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffStaff_RegionsArgs = {
  distinct_on?: InputMaybe<Array<Staff_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Regions_Order_By>>;
  where?: InputMaybe<Staff_Regions_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffStaff_Regions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Regions_Order_By>>;
  where?: InputMaybe<Staff_Regions_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffStaff_SchedulesArgs = {
  distinct_on?: InputMaybe<Array<Staff_Schedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Schedule_Order_By>>;
  where?: InputMaybe<Staff_Schedule_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffStaff_Schedules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Schedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Schedule_Order_By>>;
  where?: InputMaybe<Staff_Schedule_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffStaff_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Staff_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Services_Order_By>>;
  where?: InputMaybe<Staff_Services_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffStaff_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Services_Order_By>>;
  where?: InputMaybe<Staff_Services_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffStaffsArgs = {
  distinct_on?: InputMaybe<Array<Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Order_By>>;
  where?: InputMaybe<Staff_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffStaffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Order_By>>;
  where?: InputMaybe<Staff_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffTasksAssignmentsByAssignedbyArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Assignments_Order_By>>;
  where?: InputMaybe<Tasks_Assignments_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffTasksAssignmentsByAssignedby_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Assignments_Order_By>>;
  where?: InputMaybe<Tasks_Assignments_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffTasks_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Assignments_Order_By>>;
  where?: InputMaybe<Tasks_Assignments_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffTasks_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Assignments_Order_By>>;
  where?: InputMaybe<Tasks_Assignments_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffTasks_FilesArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Files_Order_By>>;
  where?: InputMaybe<Tasks_Files_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffTasks_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Files_Order_By>>;
  where?: InputMaybe<Tasks_Files_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffTasks_Groups_StaffsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Groups_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Groups_Staff_Order_By>>;
  where?: InputMaybe<Tasks_Groups_Staff_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffTasks_Groups_Staffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Groups_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Groups_Staff_Order_By>>;
  where?: InputMaybe<Tasks_Groups_Staff_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffTasks_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffTasks_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffTasks_ReadsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Read_Order_By>>;
  where?: InputMaybe<Tasks_Read_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffTasks_Reads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Read_Order_By>>;
  where?: InputMaybe<Tasks_Read_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffTasks_WatchesArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Watch_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Watch_Order_By>>;
  where?: InputMaybe<Tasks_Watch_Bool_Exp>;
};

/** columns and relationships of "staff" */
export type StaffTasks_Watches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Watch_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Watch_Order_By>>;
  where?: InputMaybe<Tasks_Watch_Bool_Exp>;
};

/** aggregated selection of "staff" */
export type Staff_Aggregate = {
  __typename?: 'staff_aggregate';
  aggregate?: Maybe<Staff_Aggregate_Fields>;
  nodes: Array<Staff>;
};

export type Staff_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Staff_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Staff_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Staff_Aggregate_Bool_Exp_Count>;
};

export type Staff_Aggregate_Bool_Exp_Bool_And = {
  arguments: Staff_Select_Column_Staff_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Staff_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Staff_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Staff_Select_Column_Staff_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Staff_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Staff_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Staff_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Staff_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "staff" */
export type Staff_Aggregate_Fields = {
  __typename?: 'staff_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Staff_Max_Fields>;
  min?: Maybe<Staff_Min_Fields>;
};

/** aggregate fields of "staff" */
export type Staff_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Staff_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "staff" */
export type Staff_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Staff_Max_Order_By>;
  min?: InputMaybe<Staff_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Staff_Append_Input = {
  roles?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "staff" */
export type Staff_Arr_Rel_Insert_Input = {
  data: Array<Staff_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Staff_On_Conflict>;
};

/** Boolean expression to filter rows from the table "staff". All fields are combined with a logical 'AND'. */
export type Staff_Bool_Exp = {
  _and?: InputMaybe<Array<Staff_Bool_Exp>>;
  _not?: InputMaybe<Staff_Bool_Exp>;
  _or?: InputMaybe<Array<Staff_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  appointment_blocks?: InputMaybe<Appointment_Blocks_Bool_Exp>;
  appointment_blocks_aggregate?: InputMaybe<Appointment_Blocks_Aggregate_Bool_Exp>;
  appointment_blocks_staffs?: InputMaybe<Appointment_Blocks_Staff_Bool_Exp>;
  appointment_blocks_staffs_aggregate?: InputMaybe<Appointment_Blocks_Staff_Aggregate_Bool_Exp>;
  appointment_staffs?: InputMaybe<Appointment_Staff_Bool_Exp>;
  appointment_staffs_aggregate?: InputMaybe<Appointment_Staff_Aggregate_Bool_Exp>;
  appointments?: InputMaybe<Appointments_Bool_Exp>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Bool_Exp>;
  audit_logs?: InputMaybe<Audit_Logs_Bool_Exp>;
  audit_logs_aggregate?: InputMaybe<Audit_Logs_Aggregate_Bool_Exp>;
  auth0UserId?: InputMaybe<String_Comparison_Exp>;
  authProviderId?: InputMaybe<String_Comparison_Exp>;
  avatarUrl?: InputMaybe<String_Comparison_Exp>;
  bio?: InputMaybe<String_Comparison_Exp>;
  charges?: InputMaybe<Charges_Bool_Exp>;
  charges_aggregate?: InputMaybe<Charges_Aggregate_Bool_Exp>;
  chat_messages?: InputMaybe<Chat_Messages_Bool_Exp>;
  chat_messages_aggregate?: InputMaybe<Chat_Messages_Aggregate_Bool_Exp>;
  chat_notes?: InputMaybe<Chat_Notes_Bool_Exp>;
  chat_notes_aggregate?: InputMaybe<Chat_Notes_Aggregate_Bool_Exp>;
  chat_sessions_reads?: InputMaybe<Chat_Sessions_Read_Bool_Exp>;
  chat_sessions_reads_aggregate?: InputMaybe<Chat_Sessions_Read_Aggregate_Bool_Exp>;
  chat_sessions_staffs?: InputMaybe<Chat_Sessions_Staff_Bool_Exp>;
  chat_sessions_staffs_aggregate?: InputMaybe<Chat_Sessions_Staff_Aggregate_Bool_Exp>;
  clinic?: InputMaybe<Clinics_Bool_Exp>;
  color?: InputMaybe<String_Comparison_Exp>;
  communication_histories?: InputMaybe<Communication_History_Bool_Exp>;
  communication_histories_aggregate?: InputMaybe<Communication_History_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  defaultClinicId?: InputMaybe<Uuid_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  fhir_resources_histories?: InputMaybe<Fhir_Resources_History_Bool_Exp>;
  fhir_resources_histories_aggregate?: InputMaybe<Fhir_Resources_History_Aggregate_Bool_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  global_notes?: InputMaybe<Global_Notes_Bool_Exp>;
  global_notes_aggregate?: InputMaybe<Global_Notes_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kareo_logs?: InputMaybe<Kareo_Logs_Bool_Exp>;
  kareo_logs_aggregate?: InputMaybe<Kareo_Logs_Aggregate_Bool_Exp>;
  languagesSpoken?: InputMaybe<String_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  lead?: InputMaybe<Boolean_Comparison_Exp>;
  medical_histories?: InputMaybe<Medical_History_Bool_Exp>;
  medical_histories_aggregate?: InputMaybe<Medical_History_Aggregate_Bool_Exp>;
  medical_history_answers?: InputMaybe<Medical_History_Answers_Bool_Exp>;
  medical_history_answers_aggregate?: InputMaybe<Medical_History_Answers_Aggregate_Bool_Exp>;
  patient_migrations?: InputMaybe<Patient_Migrations_Bool_Exp>;
  patient_migrations_aggregate?: InputMaybe<Patient_Migrations_Aggregate_Bool_Exp>;
  patients?: InputMaybe<Patients_Bool_Exp>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Bool_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  phone_numbers?: InputMaybe<Phone_Numbers_Bool_Exp>;
  phone_numbers_aggregate?: InputMaybe<Phone_Numbers_Aggregate_Bool_Exp>;
  preferredPronoun?: InputMaybe<String_Comparison_Exp>;
  preferredPronounType?: InputMaybe<String_Comparison_Exp>;
  reconciliations?: InputMaybe<Reconciliations_Bool_Exp>;
  reconciliations_aggregate?: InputMaybe<Reconciliations_Aggregate_Bool_Exp>;
  roles?: InputMaybe<Jsonb_Comparison_Exp>;
  rooms?: InputMaybe<Rooms_Bool_Exp>;
  rooms_aggregate?: InputMaybe<Rooms_Aggregate_Bool_Exp>;
  screenings?: InputMaybe<Screenings_Bool_Exp>;
  screenings_aggregate?: InputMaybe<Screenings_Aggregate_Bool_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffManagerId?: InputMaybe<Uuid_Comparison_Exp>;
  staff_regions?: InputMaybe<Staff_Regions_Bool_Exp>;
  staff_regions_aggregate?: InputMaybe<Staff_Regions_Aggregate_Bool_Exp>;
  staff_schedules?: InputMaybe<Staff_Schedule_Bool_Exp>;
  staff_schedules_aggregate?: InputMaybe<Staff_Schedule_Aggregate_Bool_Exp>;
  staff_services?: InputMaybe<Staff_Services_Bool_Exp>;
  staff_services_aggregate?: InputMaybe<Staff_Services_Aggregate_Bool_Exp>;
  staff_type?: InputMaybe<Staff_Type_Bool_Exp>;
  staffs?: InputMaybe<Staff_Bool_Exp>;
  staffs_aggregate?: InputMaybe<Staff_Aggregate_Bool_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  tasks?: InputMaybe<Tasks_Bool_Exp>;
  tasksAssignmentsByAssignedby?: InputMaybe<Tasks_Assignments_Bool_Exp>;
  tasksAssignmentsByAssignedby_aggregate?: InputMaybe<Tasks_Assignments_Aggregate_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Bool_Exp>;
  tasks_assignments?: InputMaybe<Tasks_Assignments_Bool_Exp>;
  tasks_assignments_aggregate?: InputMaybe<Tasks_Assignments_Aggregate_Bool_Exp>;
  tasks_files?: InputMaybe<Tasks_Files_Bool_Exp>;
  tasks_files_aggregate?: InputMaybe<Tasks_Files_Aggregate_Bool_Exp>;
  tasks_groups_staffs?: InputMaybe<Tasks_Groups_Staff_Bool_Exp>;
  tasks_groups_staffs_aggregate?: InputMaybe<Tasks_Groups_Staff_Aggregate_Bool_Exp>;
  tasks_notifications?: InputMaybe<Tasks_Notifications_Bool_Exp>;
  tasks_notifications_aggregate?: InputMaybe<Tasks_Notifications_Aggregate_Bool_Exp>;
  tasks_reads?: InputMaybe<Tasks_Read_Bool_Exp>;
  tasks_reads_aggregate?: InputMaybe<Tasks_Read_Aggregate_Bool_Exp>;
  tasks_watches?: InputMaybe<Tasks_Watch_Bool_Exp>;
  tasks_watches_aggregate?: InputMaybe<Tasks_Watch_Aggregate_Bool_Exp>;
  timeZone?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Staff_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  wiwUserId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "staff" */
export type Staff_Constraint =
  /** unique or primary key constraint on columns "authProviderId" */
  | 'staff_authProviderId_key'
  /** unique or primary key constraint on columns "email" */
  | 'staff_email_uidx'
  /** unique or primary key constraint on columns "id" */
  | 'staff_pkey'
  /** unique or primary key constraint on columns "wiwUserId" */
  | 'staff_wiwUserId_key';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Staff_Delete_At_Path_Input = {
  roles?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Staff_Delete_Elem_Input = {
  roles?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Staff_Delete_Key_Input = {
  roles?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "staff" */
export type Staff_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  appointment_blocks?: InputMaybe<Appointment_Blocks_Arr_Rel_Insert_Input>;
  appointment_blocks_staffs?: InputMaybe<Appointment_Blocks_Staff_Arr_Rel_Insert_Input>;
  appointment_staffs?: InputMaybe<Appointment_Staff_Arr_Rel_Insert_Input>;
  appointments?: InputMaybe<Appointments_Arr_Rel_Insert_Input>;
  audit_logs?: InputMaybe<Audit_Logs_Arr_Rel_Insert_Input>;
  auth0UserId?: InputMaybe<Scalars['String']>;
  authProviderId?: InputMaybe<Scalars['String']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  charges?: InputMaybe<Charges_Arr_Rel_Insert_Input>;
  chat_messages?: InputMaybe<Chat_Messages_Arr_Rel_Insert_Input>;
  chat_notes?: InputMaybe<Chat_Notes_Arr_Rel_Insert_Input>;
  chat_sessions_reads?: InputMaybe<Chat_Sessions_Read_Arr_Rel_Insert_Input>;
  chat_sessions_staffs?: InputMaybe<Chat_Sessions_Staff_Arr_Rel_Insert_Input>;
  clinic?: InputMaybe<Clinics_Obj_Rel_Insert_Input>;
  color?: InputMaybe<Scalars['String']>;
  communication_histories?: InputMaybe<Communication_History_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  defaultClinicId?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  fhir_resources_histories?: InputMaybe<Fhir_Resources_History_Arr_Rel_Insert_Input>;
  firstName?: InputMaybe<Scalars['String']>;
  global_notes?: InputMaybe<Global_Notes_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  kareo_logs?: InputMaybe<Kareo_Logs_Arr_Rel_Insert_Input>;
  languagesSpoken?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lead?: InputMaybe<Scalars['Boolean']>;
  medical_histories?: InputMaybe<Medical_History_Arr_Rel_Insert_Input>;
  medical_history_answers?: InputMaybe<Medical_History_Answers_Arr_Rel_Insert_Input>;
  patient_migrations?: InputMaybe<Patient_Migrations_Arr_Rel_Insert_Input>;
  patients?: InputMaybe<Patients_Arr_Rel_Insert_Input>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phone_numbers?: InputMaybe<Phone_Numbers_Arr_Rel_Insert_Input>;
  preferredPronoun?: InputMaybe<Scalars['String']>;
  preferredPronounType?: InputMaybe<Scalars['String']>;
  reconciliations?: InputMaybe<Reconciliations_Arr_Rel_Insert_Input>;
  roles?: InputMaybe<Scalars['jsonb']>;
  rooms?: InputMaybe<Rooms_Arr_Rel_Insert_Input>;
  screenings?: InputMaybe<Screenings_Arr_Rel_Insert_Input>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffManagerId?: InputMaybe<Scalars['uuid']>;
  staff_regions?: InputMaybe<Staff_Regions_Arr_Rel_Insert_Input>;
  staff_schedules?: InputMaybe<Staff_Schedule_Arr_Rel_Insert_Input>;
  staff_services?: InputMaybe<Staff_Services_Arr_Rel_Insert_Input>;
  staff_type?: InputMaybe<Staff_Type_Obj_Rel_Insert_Input>;
  staffs?: InputMaybe<Staff_Arr_Rel_Insert_Input>;
  state?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<Tasks_Arr_Rel_Insert_Input>;
  tasksAssignmentsByAssignedby?: InputMaybe<Tasks_Assignments_Arr_Rel_Insert_Input>;
  tasks_assignments?: InputMaybe<Tasks_Assignments_Arr_Rel_Insert_Input>;
  tasks_files?: InputMaybe<Tasks_Files_Arr_Rel_Insert_Input>;
  tasks_groups_staffs?: InputMaybe<Tasks_Groups_Staff_Arr_Rel_Insert_Input>;
  tasks_notifications?: InputMaybe<Tasks_Notifications_Arr_Rel_Insert_Input>;
  tasks_reads?: InputMaybe<Tasks_Read_Arr_Rel_Insert_Input>;
  tasks_watches?: InputMaybe<Tasks_Watch_Arr_Rel_Insert_Input>;
  timeZone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Staff_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wiwUserId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Staff_Max_Fields = {
  __typename?: 'staff_max_fields';
  auth0UserId?: Maybe<Scalars['String']>;
  authProviderId?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  defaultClinicId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  languagesSpoken?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  preferredPronoun?: Maybe<Scalars['String']>;
  preferredPronounType?: Maybe<Scalars['String']>;
  staffManagerId?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wiwUserId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "staff" */
export type Staff_Max_Order_By = {
  auth0UserId?: InputMaybe<Order_By>;
  authProviderId?: InputMaybe<Order_By>;
  avatarUrl?: InputMaybe<Order_By>;
  bio?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  defaultClinicId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  languagesSpoken?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  preferredPronoun?: InputMaybe<Order_By>;
  preferredPronounType?: InputMaybe<Order_By>;
  staffManagerId?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  timeZone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wiwUserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Staff_Min_Fields = {
  __typename?: 'staff_min_fields';
  auth0UserId?: Maybe<Scalars['String']>;
  authProviderId?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  defaultClinicId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  languagesSpoken?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  preferredPronoun?: Maybe<Scalars['String']>;
  preferredPronounType?: Maybe<Scalars['String']>;
  staffManagerId?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wiwUserId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "staff" */
export type Staff_Min_Order_By = {
  auth0UserId?: InputMaybe<Order_By>;
  authProviderId?: InputMaybe<Order_By>;
  avatarUrl?: InputMaybe<Order_By>;
  bio?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  defaultClinicId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  languagesSpoken?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  preferredPronoun?: InputMaybe<Order_By>;
  preferredPronounType?: InputMaybe<Order_By>;
  staffManagerId?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  timeZone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wiwUserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "staff" */
export type Staff_Mutation_Response = {
  __typename?: 'staff_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Staff>;
};

/** input type for inserting object relation for remote table "staff" */
export type Staff_Obj_Rel_Insert_Input = {
  data: Staff_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Staff_On_Conflict>;
};

/** on_conflict condition type for table "staff" */
export type Staff_On_Conflict = {
  constraint: Staff_Constraint;
  update_columns?: Array<Staff_Update_Column>;
  where?: InputMaybe<Staff_Bool_Exp>;
};

/** Ordering options when selecting data from "staff". */
export type Staff_Order_By = {
  active?: InputMaybe<Order_By>;
  appointment_blocks_aggregate?: InputMaybe<Appointment_Blocks_Aggregate_Order_By>;
  appointment_blocks_staffs_aggregate?: InputMaybe<Appointment_Blocks_Staff_Aggregate_Order_By>;
  appointment_staffs_aggregate?: InputMaybe<Appointment_Staff_Aggregate_Order_By>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Order_By>;
  audit_logs_aggregate?: InputMaybe<Audit_Logs_Aggregate_Order_By>;
  auth0UserId?: InputMaybe<Order_By>;
  authProviderId?: InputMaybe<Order_By>;
  avatarUrl?: InputMaybe<Order_By>;
  bio?: InputMaybe<Order_By>;
  charges_aggregate?: InputMaybe<Charges_Aggregate_Order_By>;
  chat_messages_aggregate?: InputMaybe<Chat_Messages_Aggregate_Order_By>;
  chat_notes_aggregate?: InputMaybe<Chat_Notes_Aggregate_Order_By>;
  chat_sessions_reads_aggregate?: InputMaybe<Chat_Sessions_Read_Aggregate_Order_By>;
  chat_sessions_staffs_aggregate?: InputMaybe<Chat_Sessions_Staff_Aggregate_Order_By>;
  clinic?: InputMaybe<Clinics_Order_By>;
  color?: InputMaybe<Order_By>;
  communication_histories_aggregate?: InputMaybe<Communication_History_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  defaultClinicId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  fhir_resources_histories_aggregate?: InputMaybe<Fhir_Resources_History_Aggregate_Order_By>;
  firstName?: InputMaybe<Order_By>;
  global_notes_aggregate?: InputMaybe<Global_Notes_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  kareo_logs_aggregate?: InputMaybe<Kareo_Logs_Aggregate_Order_By>;
  languagesSpoken?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  lead?: InputMaybe<Order_By>;
  medical_histories_aggregate?: InputMaybe<Medical_History_Aggregate_Order_By>;
  medical_history_answers_aggregate?: InputMaybe<Medical_History_Answers_Aggregate_Order_By>;
  patient_migrations_aggregate?: InputMaybe<Patient_Migrations_Aggregate_Order_By>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  phone_numbers_aggregate?: InputMaybe<Phone_Numbers_Aggregate_Order_By>;
  preferredPronoun?: InputMaybe<Order_By>;
  preferredPronounType?: InputMaybe<Order_By>;
  reconciliations_aggregate?: InputMaybe<Reconciliations_Aggregate_Order_By>;
  roles?: InputMaybe<Order_By>;
  rooms_aggregate?: InputMaybe<Rooms_Aggregate_Order_By>;
  screenings_aggregate?: InputMaybe<Screenings_Aggregate_Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffManagerId?: InputMaybe<Order_By>;
  staff_regions_aggregate?: InputMaybe<Staff_Regions_Aggregate_Order_By>;
  staff_schedules_aggregate?: InputMaybe<Staff_Schedule_Aggregate_Order_By>;
  staff_services_aggregate?: InputMaybe<Staff_Services_Aggregate_Order_By>;
  staff_type?: InputMaybe<Staff_Type_Order_By>;
  staffs_aggregate?: InputMaybe<Staff_Aggregate_Order_By>;
  state?: InputMaybe<Order_By>;
  tasksAssignmentsByAssignedby_aggregate?: InputMaybe<Tasks_Assignments_Aggregate_Order_By>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Order_By>;
  tasks_assignments_aggregate?: InputMaybe<Tasks_Assignments_Aggregate_Order_By>;
  tasks_files_aggregate?: InputMaybe<Tasks_Files_Aggregate_Order_By>;
  tasks_groups_staffs_aggregate?: InputMaybe<Tasks_Groups_Staff_Aggregate_Order_By>;
  tasks_notifications_aggregate?: InputMaybe<Tasks_Notifications_Aggregate_Order_By>;
  tasks_reads_aggregate?: InputMaybe<Tasks_Read_Aggregate_Order_By>;
  tasks_watches_aggregate?: InputMaybe<Tasks_Watch_Aggregate_Order_By>;
  timeZone?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wiwUserId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: staff */
export type Staff_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Staff_Prepend_Input = {
  roles?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "staff_regions" */
export type Staff_Regions = {
  __typename?: 'staff_regions';
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  region: Regions;
  regionId: Scalars['bigint'];
  /** An object relationship */
  staff: Staff;
  staffId: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "staff_regions" */
export type Staff_Regions_Aggregate = {
  __typename?: 'staff_regions_aggregate';
  aggregate?: Maybe<Staff_Regions_Aggregate_Fields>;
  nodes: Array<Staff_Regions>;
};

export type Staff_Regions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Staff_Regions_Aggregate_Bool_Exp_Count>;
};

export type Staff_Regions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Staff_Regions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Staff_Regions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "staff_regions" */
export type Staff_Regions_Aggregate_Fields = {
  __typename?: 'staff_regions_aggregate_fields';
  avg?: Maybe<Staff_Regions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Staff_Regions_Max_Fields>;
  min?: Maybe<Staff_Regions_Min_Fields>;
  stddev?: Maybe<Staff_Regions_Stddev_Fields>;
  stddev_pop?: Maybe<Staff_Regions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Staff_Regions_Stddev_Samp_Fields>;
  sum?: Maybe<Staff_Regions_Sum_Fields>;
  var_pop?: Maybe<Staff_Regions_Var_Pop_Fields>;
  var_samp?: Maybe<Staff_Regions_Var_Samp_Fields>;
  variance?: Maybe<Staff_Regions_Variance_Fields>;
};

/** aggregate fields of "staff_regions" */
export type Staff_Regions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Staff_Regions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "staff_regions" */
export type Staff_Regions_Aggregate_Order_By = {
  avg?: InputMaybe<Staff_Regions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Staff_Regions_Max_Order_By>;
  min?: InputMaybe<Staff_Regions_Min_Order_By>;
  stddev?: InputMaybe<Staff_Regions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Staff_Regions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Staff_Regions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Staff_Regions_Sum_Order_By>;
  var_pop?: InputMaybe<Staff_Regions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Staff_Regions_Var_Samp_Order_By>;
  variance?: InputMaybe<Staff_Regions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "staff_regions" */
export type Staff_Regions_Arr_Rel_Insert_Input = {
  data: Array<Staff_Regions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Staff_Regions_On_Conflict>;
};

/** aggregate avg on columns */
export type Staff_Regions_Avg_Fields = {
  __typename?: 'staff_regions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "staff_regions" */
export type Staff_Regions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "staff_regions". All fields are combined with a logical 'AND'. */
export type Staff_Regions_Bool_Exp = {
  _and?: InputMaybe<Array<Staff_Regions_Bool_Exp>>;
  _not?: InputMaybe<Staff_Regions_Bool_Exp>;
  _or?: InputMaybe<Array<Staff_Regions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  region?: InputMaybe<Regions_Bool_Exp>;
  regionId?: InputMaybe<Bigint_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "staff_regions" */
export type Staff_Regions_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'staff_regions_pkey'
  /** unique or primary key constraint on columns "regionId", "staffId" */
  | 'staff_regions_staffId_regionId_key';

/** input type for incrementing numeric columns in table "staff_regions" */
export type Staff_Regions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  regionId?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "staff_regions" */
export type Staff_Regions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  region?: InputMaybe<Regions_Obj_Rel_Insert_Input>;
  regionId?: InputMaybe<Scalars['bigint']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Staff_Regions_Max_Fields = {
  __typename?: 'staff_regions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  regionId?: Maybe<Scalars['bigint']>;
  staffId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "staff_regions" */
export type Staff_Regions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Staff_Regions_Min_Fields = {
  __typename?: 'staff_regions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  regionId?: Maybe<Scalars['bigint']>;
  staffId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "staff_regions" */
export type Staff_Regions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "staff_regions" */
export type Staff_Regions_Mutation_Response = {
  __typename?: 'staff_regions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Staff_Regions>;
};

/** on_conflict condition type for table "staff_regions" */
export type Staff_Regions_On_Conflict = {
  constraint: Staff_Regions_Constraint;
  update_columns?: Array<Staff_Regions_Update_Column>;
  where?: InputMaybe<Staff_Regions_Bool_Exp>;
};

/** Ordering options when selecting data from "staff_regions". */
export type Staff_Regions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  region?: InputMaybe<Regions_Order_By>;
  regionId?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: staff_regions */
export type Staff_Regions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "staff_regions" */
export type Staff_Regions_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'regionId'
  /** column name */
  | 'staffId'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "staff_regions" */
export type Staff_Regions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  regionId?: InputMaybe<Scalars['bigint']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Staff_Regions_Stddev_Fields = {
  __typename?: 'staff_regions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "staff_regions" */
export type Staff_Regions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Staff_Regions_Stddev_Pop_Fields = {
  __typename?: 'staff_regions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "staff_regions" */
export type Staff_Regions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Staff_Regions_Stddev_Samp_Fields = {
  __typename?: 'staff_regions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "staff_regions" */
export type Staff_Regions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "staff_regions" */
export type Staff_Regions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Staff_Regions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staff_Regions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  regionId?: InputMaybe<Scalars['bigint']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Staff_Regions_Sum_Fields = {
  __typename?: 'staff_regions_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  regionId?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "staff_regions" */
export type Staff_Regions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
};

/** update columns of table "staff_regions" */
export type Staff_Regions_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'regionId'
  /** column name */
  | 'staffId'
  /** column name */
  | 'updated_at';

export type Staff_Regions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Staff_Regions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Staff_Regions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Staff_Regions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Staff_Regions_Var_Pop_Fields = {
  __typename?: 'staff_regions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "staff_regions" */
export type Staff_Regions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Staff_Regions_Var_Samp_Fields = {
  __typename?: 'staff_regions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "staff_regions" */
export type Staff_Regions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Staff_Regions_Variance_Fields = {
  __typename?: 'staff_regions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  regionId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "staff_regions" */
export type Staff_Regions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  regionId?: InputMaybe<Order_By>;
};

/** columns and relationships of "staff_schedule" */
export type Staff_Schedule = {
  __typename?: 'staff_schedule';
  /** An object relationship */
  clinic?: Maybe<Clinics>;
  clinicId?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  endDateTime: Scalars['timestamp'];
  id: Scalars['uuid'];
  /** An object relationship */
  staff: Staff;
  staffId: Scalars['uuid'];
  /** An array relationship */
  staff_schedule_services: Array<Staff_Schedule_Service>;
  /** An aggregate relationship */
  staff_schedule_services_aggregate: Staff_Schedule_Service_Aggregate;
  startDateTime: Scalars['timestamp'];
  timeZone: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "staff_schedule" */
export type Staff_ScheduleStaff_Schedule_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Staff_Schedule_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Schedule_Service_Order_By>>;
  where?: InputMaybe<Staff_Schedule_Service_Bool_Exp>;
};

/** columns and relationships of "staff_schedule" */
export type Staff_ScheduleStaff_Schedule_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Schedule_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Schedule_Service_Order_By>>;
  where?: InputMaybe<Staff_Schedule_Service_Bool_Exp>;
};

/** aggregated selection of "staff_schedule" */
export type Staff_Schedule_Aggregate = {
  __typename?: 'staff_schedule_aggregate';
  aggregate?: Maybe<Staff_Schedule_Aggregate_Fields>;
  nodes: Array<Staff_Schedule>;
};

export type Staff_Schedule_Aggregate_Bool_Exp = {
  count?: InputMaybe<Staff_Schedule_Aggregate_Bool_Exp_Count>;
};

export type Staff_Schedule_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Staff_Schedule_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Staff_Schedule_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "staff_schedule" */
export type Staff_Schedule_Aggregate_Fields = {
  __typename?: 'staff_schedule_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Staff_Schedule_Max_Fields>;
  min?: Maybe<Staff_Schedule_Min_Fields>;
};

/** aggregate fields of "staff_schedule" */
export type Staff_Schedule_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Staff_Schedule_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "staff_schedule" */
export type Staff_Schedule_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Staff_Schedule_Max_Order_By>;
  min?: InputMaybe<Staff_Schedule_Min_Order_By>;
};

/** input type for inserting array relation for remote table "staff_schedule" */
export type Staff_Schedule_Arr_Rel_Insert_Input = {
  data: Array<Staff_Schedule_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Staff_Schedule_On_Conflict>;
};

/** Boolean expression to filter rows from the table "staff_schedule". All fields are combined with a logical 'AND'. */
export type Staff_Schedule_Bool_Exp = {
  _and?: InputMaybe<Array<Staff_Schedule_Bool_Exp>>;
  _not?: InputMaybe<Staff_Schedule_Bool_Exp>;
  _or?: InputMaybe<Array<Staff_Schedule_Bool_Exp>>;
  clinic?: InputMaybe<Clinics_Bool_Exp>;
  clinicId?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  endDateTime?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  staff_schedule_services?: InputMaybe<Staff_Schedule_Service_Bool_Exp>;
  staff_schedule_services_aggregate?: InputMaybe<Staff_Schedule_Service_Aggregate_Bool_Exp>;
  startDateTime?: InputMaybe<Timestamp_Comparison_Exp>;
  timeZone?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "staff_schedule" */
export type Staff_Schedule_Constraint =
  /** unique or primary key constraint on columns "id" */
  'staff_schedule_pkey';

/** input type for inserting data into table "staff_schedule" */
export type Staff_Schedule_Insert_Input = {
  clinic?: InputMaybe<Clinics_Obj_Rel_Insert_Input>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endDateTime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  staff_schedule_services?: InputMaybe<Staff_Schedule_Service_Arr_Rel_Insert_Input>;
  startDateTime?: InputMaybe<Scalars['timestamp']>;
  timeZone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Staff_Schedule_Max_Fields = {
  __typename?: 'staff_schedule_max_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  endDateTime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  staffId?: Maybe<Scalars['uuid']>;
  startDateTime?: Maybe<Scalars['timestamp']>;
  timeZone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "staff_schedule" */
export type Staff_Schedule_Max_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  endDateTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  startDateTime?: InputMaybe<Order_By>;
  timeZone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Staff_Schedule_Min_Fields = {
  __typename?: 'staff_schedule_min_fields';
  clinicId?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  endDateTime?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  staffId?: Maybe<Scalars['uuid']>;
  startDateTime?: Maybe<Scalars['timestamp']>;
  timeZone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "staff_schedule" */
export type Staff_Schedule_Min_Order_By = {
  clinicId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  endDateTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  startDateTime?: InputMaybe<Order_By>;
  timeZone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "staff_schedule" */
export type Staff_Schedule_Mutation_Response = {
  __typename?: 'staff_schedule_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Staff_Schedule>;
};

/** input type for inserting object relation for remote table "staff_schedule" */
export type Staff_Schedule_Obj_Rel_Insert_Input = {
  data: Staff_Schedule_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Staff_Schedule_On_Conflict>;
};

/** on_conflict condition type for table "staff_schedule" */
export type Staff_Schedule_On_Conflict = {
  constraint: Staff_Schedule_Constraint;
  update_columns?: Array<Staff_Schedule_Update_Column>;
  where?: InputMaybe<Staff_Schedule_Bool_Exp>;
};

/** Ordering options when selecting data from "staff_schedule". */
export type Staff_Schedule_Order_By = {
  clinic?: InputMaybe<Clinics_Order_By>;
  clinicId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  endDateTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  staff_schedule_services_aggregate?: InputMaybe<Staff_Schedule_Service_Aggregate_Order_By>;
  startDateTime?: InputMaybe<Order_By>;
  timeZone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: staff_schedule */
export type Staff_Schedule_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "staff_schedule" */
export type Staff_Schedule_Select_Column =
  /** column name */
  | 'clinicId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'endDateTime'
  /** column name */
  | 'id'
  /** column name */
  | 'staffId'
  /** column name */
  | 'startDateTime'
  /** column name */
  | 'timeZone'
  /** column name */
  | 'updated_at';

/** columns and relationships of "staff_schedule_service" */
export type Staff_Schedule_Service = {
  __typename?: 'staff_schedule_service';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  service: Services_Enum;
  /** An object relationship */
  serviceByService: Services;
  staffScheduleId: Scalars['uuid'];
  /** An object relationship */
  staff_schedule: Staff_Schedule;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "staff_schedule_service" */
export type Staff_Schedule_Service_Aggregate = {
  __typename?: 'staff_schedule_service_aggregate';
  aggregate?: Maybe<Staff_Schedule_Service_Aggregate_Fields>;
  nodes: Array<Staff_Schedule_Service>;
};

export type Staff_Schedule_Service_Aggregate_Bool_Exp = {
  count?: InputMaybe<Staff_Schedule_Service_Aggregate_Bool_Exp_Count>;
};

export type Staff_Schedule_Service_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Staff_Schedule_Service_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Staff_Schedule_Service_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "staff_schedule_service" */
export type Staff_Schedule_Service_Aggregate_Fields = {
  __typename?: 'staff_schedule_service_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Staff_Schedule_Service_Max_Fields>;
  min?: Maybe<Staff_Schedule_Service_Min_Fields>;
};

/** aggregate fields of "staff_schedule_service" */
export type Staff_Schedule_Service_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Staff_Schedule_Service_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "staff_schedule_service" */
export type Staff_Schedule_Service_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Staff_Schedule_Service_Max_Order_By>;
  min?: InputMaybe<Staff_Schedule_Service_Min_Order_By>;
};

/** input type for inserting array relation for remote table "staff_schedule_service" */
export type Staff_Schedule_Service_Arr_Rel_Insert_Input = {
  data: Array<Staff_Schedule_Service_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Staff_Schedule_Service_On_Conflict>;
};

/** Boolean expression to filter rows from the table "staff_schedule_service". All fields are combined with a logical 'AND'. */
export type Staff_Schedule_Service_Bool_Exp = {
  _and?: InputMaybe<Array<Staff_Schedule_Service_Bool_Exp>>;
  _not?: InputMaybe<Staff_Schedule_Service_Bool_Exp>;
  _or?: InputMaybe<Array<Staff_Schedule_Service_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  service?: InputMaybe<Services_Enum_Comparison_Exp>;
  serviceByService?: InputMaybe<Services_Bool_Exp>;
  staffScheduleId?: InputMaybe<Uuid_Comparison_Exp>;
  staff_schedule?: InputMaybe<Staff_Schedule_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "staff_schedule_service" */
export type Staff_Schedule_Service_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'staff_schedule_service_pkey'
  /** unique or primary key constraint on columns "staffScheduleId", "service" */
  | 'staff_schedule_service_staffScheduleId_service_key';

/** input type for inserting data into table "staff_schedule_service" */
export type Staff_Schedule_Service_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Services_Enum>;
  serviceByService?: InputMaybe<Services_Obj_Rel_Insert_Input>;
  staffScheduleId?: InputMaybe<Scalars['uuid']>;
  staff_schedule?: InputMaybe<Staff_Schedule_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Staff_Schedule_Service_Max_Fields = {
  __typename?: 'staff_schedule_service_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  staffScheduleId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "staff_schedule_service" */
export type Staff_Schedule_Service_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffScheduleId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Staff_Schedule_Service_Min_Fields = {
  __typename?: 'staff_schedule_service_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  staffScheduleId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "staff_schedule_service" */
export type Staff_Schedule_Service_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffScheduleId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "staff_schedule_service" */
export type Staff_Schedule_Service_Mutation_Response = {
  __typename?: 'staff_schedule_service_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Staff_Schedule_Service>;
};

/** on_conflict condition type for table "staff_schedule_service" */
export type Staff_Schedule_Service_On_Conflict = {
  constraint: Staff_Schedule_Service_Constraint;
  update_columns?: Array<Staff_Schedule_Service_Update_Column>;
  where?: InputMaybe<Staff_Schedule_Service_Bool_Exp>;
};

/** Ordering options when selecting data from "staff_schedule_service". */
export type Staff_Schedule_Service_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service?: InputMaybe<Order_By>;
  serviceByService?: InputMaybe<Services_Order_By>;
  staffScheduleId?: InputMaybe<Order_By>;
  staff_schedule?: InputMaybe<Staff_Schedule_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: staff_schedule_service */
export type Staff_Schedule_Service_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "staff_schedule_service" */
export type Staff_Schedule_Service_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'service'
  /** column name */
  | 'staffScheduleId'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "staff_schedule_service" */
export type Staff_Schedule_Service_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Services_Enum>;
  staffScheduleId?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "staff_schedule_service" */
export type Staff_Schedule_Service_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Staff_Schedule_Service_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staff_Schedule_Service_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Services_Enum>;
  staffScheduleId?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "staff_schedule_service" */
export type Staff_Schedule_Service_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'service'
  /** column name */
  | 'staffScheduleId'
  /** column name */
  | 'updated_at';

export type Staff_Schedule_Service_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Staff_Schedule_Service_Set_Input>;
  /** filter the rows which have to be updated */
  where: Staff_Schedule_Service_Bool_Exp;
};

/** input type for updating data in table "staff_schedule" */
export type Staff_Schedule_Set_Input = {
  clinicId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endDateTime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  startDateTime?: InputMaybe<Scalars['timestamp']>;
  timeZone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "staff_schedule" */
export type Staff_Schedule_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Staff_Schedule_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staff_Schedule_Stream_Cursor_Value_Input = {
  clinicId?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endDateTime?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  startDateTime?: InputMaybe<Scalars['timestamp']>;
  timeZone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "staff_schedule" */
export type Staff_Schedule_Update_Column =
  /** column name */
  | 'clinicId'
  /** column name */
  | 'created_at'
  /** column name */
  | 'endDateTime'
  /** column name */
  | 'id'
  /** column name */
  | 'staffId'
  /** column name */
  | 'startDateTime'
  /** column name */
  | 'timeZone'
  /** column name */
  | 'updated_at';

export type Staff_Schedule_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Staff_Schedule_Set_Input>;
  /** filter the rows which have to be updated */
  where: Staff_Schedule_Bool_Exp;
};

/** select columns of table "staff" */
export type Staff_Select_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'auth0UserId'
  /** column name */
  | 'authProviderId'
  /** column name */
  | 'avatarUrl'
  /** column name */
  | 'bio'
  /** column name */
  | 'color'
  /** column name */
  | 'created_at'
  /** column name */
  | 'defaultClinicId'
  /** column name */
  | 'email'
  /** column name */
  | 'firstName'
  /** column name */
  | 'id'
  /** column name */
  | 'languagesSpoken'
  /** column name */
  | 'lastName'
  /** column name */
  | 'lead'
  /** column name */
  | 'phoneNumber'
  /** column name */
  | 'preferredPronoun'
  /** column name */
  | 'preferredPronounType'
  /** column name */
  | 'roles'
  /** column name */
  | 'staffManagerId'
  /** column name */
  | 'state'
  /** column name */
  | 'timeZone'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'wiwUserId';

/** select "staff_aggregate_bool_exp_bool_and_arguments_columns" columns of table "staff" */
export type Staff_Select_Column_Staff_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  | 'active'
  /** column name */
  | 'lead';

/** select "staff_aggregate_bool_exp_bool_or_arguments_columns" columns of table "staff" */
export type Staff_Select_Column_Staff_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  | 'active'
  /** column name */
  | 'lead';

/** columns and relationships of "staff_services" */
export type Staff_Services = {
  __typename?: 'staff_services';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  service: Services_Enum;
  /** An object relationship */
  serviceByService: Services;
  /** An object relationship */
  staff: Staff;
  staffId: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "staff_services" */
export type Staff_Services_Aggregate = {
  __typename?: 'staff_services_aggregate';
  aggregate?: Maybe<Staff_Services_Aggregate_Fields>;
  nodes: Array<Staff_Services>;
};

export type Staff_Services_Aggregate_Bool_Exp = {
  count?: InputMaybe<Staff_Services_Aggregate_Bool_Exp_Count>;
};

export type Staff_Services_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Staff_Services_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Staff_Services_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "staff_services" */
export type Staff_Services_Aggregate_Fields = {
  __typename?: 'staff_services_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Staff_Services_Max_Fields>;
  min?: Maybe<Staff_Services_Min_Fields>;
};

/** aggregate fields of "staff_services" */
export type Staff_Services_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Staff_Services_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "staff_services" */
export type Staff_Services_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Staff_Services_Max_Order_By>;
  min?: InputMaybe<Staff_Services_Min_Order_By>;
};

/** input type for inserting array relation for remote table "staff_services" */
export type Staff_Services_Arr_Rel_Insert_Input = {
  data: Array<Staff_Services_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Staff_Services_On_Conflict>;
};

/** Boolean expression to filter rows from the table "staff_services". All fields are combined with a logical 'AND'. */
export type Staff_Services_Bool_Exp = {
  _and?: InputMaybe<Array<Staff_Services_Bool_Exp>>;
  _not?: InputMaybe<Staff_Services_Bool_Exp>;
  _or?: InputMaybe<Array<Staff_Services_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  service?: InputMaybe<Services_Enum_Comparison_Exp>;
  serviceByService?: InputMaybe<Services_Bool_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "staff_services" */
export type Staff_Services_Constraint =
  /** unique or primary key constraint on columns "id" */
  'staff_services_pkey';

/** input type for inserting data into table "staff_services" */
export type Staff_Services_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Services_Enum>;
  serviceByService?: InputMaybe<Services_Obj_Rel_Insert_Input>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Staff_Services_Max_Fields = {
  __typename?: 'staff_services_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  staffId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "staff_services" */
export type Staff_Services_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Staff_Services_Min_Fields = {
  __typename?: 'staff_services_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  staffId?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "staff_services" */
export type Staff_Services_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "staff_services" */
export type Staff_Services_Mutation_Response = {
  __typename?: 'staff_services_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Staff_Services>;
};

/** on_conflict condition type for table "staff_services" */
export type Staff_Services_On_Conflict = {
  constraint: Staff_Services_Constraint;
  update_columns?: Array<Staff_Services_Update_Column>;
  where?: InputMaybe<Staff_Services_Bool_Exp>;
};

/** Ordering options when selecting data from "staff_services". */
export type Staff_Services_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service?: InputMaybe<Order_By>;
  serviceByService?: InputMaybe<Services_Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: staff_services */
export type Staff_Services_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "staff_services" */
export type Staff_Services_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'service'
  /** column name */
  | 'staffId'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "staff_services" */
export type Staff_Services_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Services_Enum>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "staff_services" */
export type Staff_Services_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Staff_Services_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staff_Services_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Services_Enum>;
  staffId?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "staff_services" */
export type Staff_Services_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'service'
  /** column name */
  | 'staffId'
  /** column name */
  | 'updated_at';

export type Staff_Services_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Staff_Services_Set_Input>;
  /** filter the rows which have to be updated */
  where: Staff_Services_Bool_Exp;
};

/** input type for updating data in table "staff" */
export type Staff_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  auth0UserId?: InputMaybe<Scalars['String']>;
  authProviderId?: InputMaybe<Scalars['String']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  defaultClinicId?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  languagesSpoken?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lead?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  preferredPronoun?: InputMaybe<Scalars['String']>;
  preferredPronounType?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Scalars['jsonb']>;
  staffManagerId?: InputMaybe<Scalars['uuid']>;
  state?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Staff_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wiwUserId?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "staff" */
export type Staff_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Staff_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staff_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  auth0UserId?: InputMaybe<Scalars['String']>;
  authProviderId?: InputMaybe<Scalars['String']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  defaultClinicId?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  languagesSpoken?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  lead?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  preferredPronoun?: InputMaybe<Scalars['String']>;
  preferredPronounType?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Scalars['jsonb']>;
  staffManagerId?: InputMaybe<Scalars['uuid']>;
  state?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Staff_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wiwUserId?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "staff_type" */
export type Staff_Type = {
  __typename?: 'staff_type';
  description: Scalars['String'];
  /** An array relationship */
  staffs: Array<Staff>;
  /** An aggregate relationship */
  staffs_aggregate: Staff_Aggregate;
  /** An array relationship */
  tasks_notifications: Array<Tasks_Notifications>;
  /** An aggregate relationship */
  tasks_notifications_aggregate: Tasks_Notifications_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "staff_type" */
export type Staff_TypeStaffsArgs = {
  distinct_on?: InputMaybe<Array<Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Order_By>>;
  where?: InputMaybe<Staff_Bool_Exp>;
};

/** columns and relationships of "staff_type" */
export type Staff_TypeStaffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Order_By>>;
  where?: InputMaybe<Staff_Bool_Exp>;
};

/** columns and relationships of "staff_type" */
export type Staff_TypeTasks_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

/** columns and relationships of "staff_type" */
export type Staff_TypeTasks_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

/** aggregated selection of "staff_type" */
export type Staff_Type_Aggregate = {
  __typename?: 'staff_type_aggregate';
  aggregate?: Maybe<Staff_Type_Aggregate_Fields>;
  nodes: Array<Staff_Type>;
};

/** aggregate fields of "staff_type" */
export type Staff_Type_Aggregate_Fields = {
  __typename?: 'staff_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Staff_Type_Max_Fields>;
  min?: Maybe<Staff_Type_Min_Fields>;
};

/** aggregate fields of "staff_type" */
export type Staff_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Staff_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "staff_type". All fields are combined with a logical 'AND'. */
export type Staff_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Staff_Type_Bool_Exp>>;
  _not?: InputMaybe<Staff_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Staff_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  staffs?: InputMaybe<Staff_Bool_Exp>;
  staffs_aggregate?: InputMaybe<Staff_Aggregate_Bool_Exp>;
  tasks_notifications?: InputMaybe<Tasks_Notifications_Bool_Exp>;
  tasks_notifications_aggregate?: InputMaybe<Tasks_Notifications_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "staff_type" */
export type Staff_Type_Constraint =
  /** unique or primary key constraint on columns "value" */
  'staff_type_pkey';

export type Staff_Type_Enum =
  /** Medical Assistant */
  | 'MEDICAL_ASSISTANT'
  /** Nurse Practitioner */
  | 'NURSE_PRACTITIONER'
  /** Operation member */
  | 'OPERATION'
  /** Physician */
  | 'PHYSICIAN'
  /** Physician Assistant */
  | 'PHYSICIAN_ASSISTANT'
  /** Triage Nurse */
  | 'TRIAGE_NURSE'
  /** Unknown */
  | 'UNKNOWN';

/** Boolean expression to compare columns of type "staff_type_enum". All fields are combined with logical 'AND'. */
export type Staff_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Staff_Type_Enum>;
  _in?: InputMaybe<Array<Staff_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Staff_Type_Enum>;
  _nin?: InputMaybe<Array<Staff_Type_Enum>>;
};

/** input type for inserting data into table "staff_type" */
export type Staff_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  staffs?: InputMaybe<Staff_Arr_Rel_Insert_Input>;
  tasks_notifications?: InputMaybe<Tasks_Notifications_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Staff_Type_Max_Fields = {
  __typename?: 'staff_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Staff_Type_Min_Fields = {
  __typename?: 'staff_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "staff_type" */
export type Staff_Type_Mutation_Response = {
  __typename?: 'staff_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Staff_Type>;
};

/** input type for inserting object relation for remote table "staff_type" */
export type Staff_Type_Obj_Rel_Insert_Input = {
  data: Staff_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Staff_Type_On_Conflict>;
};

/** on_conflict condition type for table "staff_type" */
export type Staff_Type_On_Conflict = {
  constraint: Staff_Type_Constraint;
  update_columns?: Array<Staff_Type_Update_Column>;
  where?: InputMaybe<Staff_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "staff_type". */
export type Staff_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  staffs_aggregate?: InputMaybe<Staff_Aggregate_Order_By>;
  tasks_notifications_aggregate?: InputMaybe<Tasks_Notifications_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: staff_type */
export type Staff_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "staff_type" */
export type Staff_Type_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "staff_type" */
export type Staff_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "staff_type" */
export type Staff_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Staff_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Staff_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "staff_type" */
export type Staff_Type_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Staff_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Staff_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Staff_Type_Bool_Exp;
};

/** update columns of table "staff" */
export type Staff_Update_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'auth0UserId'
  /** column name */
  | 'authProviderId'
  /** column name */
  | 'avatarUrl'
  /** column name */
  | 'bio'
  /** column name */
  | 'color'
  /** column name */
  | 'created_at'
  /** column name */
  | 'defaultClinicId'
  /** column name */
  | 'email'
  /** column name */
  | 'firstName'
  /** column name */
  | 'id'
  /** column name */
  | 'languagesSpoken'
  /** column name */
  | 'lastName'
  /** column name */
  | 'lead'
  /** column name */
  | 'phoneNumber'
  /** column name */
  | 'preferredPronoun'
  /** column name */
  | 'preferredPronounType'
  /** column name */
  | 'roles'
  /** column name */
  | 'staffManagerId'
  /** column name */
  | 'state'
  /** column name */
  | 'timeZone'
  /** column name */
  | 'type'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'wiwUserId';

export type Staff_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Staff_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Staff_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Staff_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Staff_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Staff_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Staff_Set_Input>;
  /** filter the rows which have to be updated */
  where: Staff_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "account_patient" */
  account_patient: Array<Account_Patient>;
  /** fetch aggregated fields from the table: "account_patient" */
  account_patient_aggregate: Account_Patient_Aggregate;
  /** fetch data from the table: "account_patient" using primary key columns */
  account_patient_by_pk?: Maybe<Account_Patient>;
  /** fetch data from the table in a streaming manner: "account_patient" */
  account_patient_stream: Array<Account_Patient>;
  /** fetch data from the table: "account_temporary_verification" */
  account_temporary_verification: Array<Account_Temporary_Verification>;
  /** fetch aggregated fields from the table: "account_temporary_verification" */
  account_temporary_verification_aggregate: Account_Temporary_Verification_Aggregate;
  /** fetch data from the table: "account_temporary_verification" using primary key columns */
  account_temporary_verification_by_pk?: Maybe<Account_Temporary_Verification>;
  /** fetch data from the table in a streaming manner: "account_temporary_verification" */
  account_temporary_verification_stream: Array<Account_Temporary_Verification>;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Accounts_Aggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table: "accounts_limited" */
  accounts_limited: Array<Accounts_Limited>;
  /** fetch aggregated fields from the table: "accounts_limited" */
  accounts_limited_aggregate: Accounts_Limited_Aggregate;
  /** fetch data from the table in a streaming manner: "accounts_limited" */
  accounts_limited_stream: Array<Accounts_Limited>;
  /** fetch data from the table in a streaming manner: "accounts" */
  accounts_stream: Array<Accounts>;
  /** An array relationship */
  allergies: Array<Allergies>;
  /** An aggregate relationship */
  allergies_aggregate: Allergies_Aggregate;
  /** fetch data from the table: "allergies" using primary key columns */
  allergies_by_pk?: Maybe<Allergies>;
  /** fetch data from the table: "allergies_severity" */
  allergies_severity: Array<Allergies_Severity>;
  /** fetch aggregated fields from the table: "allergies_severity" */
  allergies_severity_aggregate: Allergies_Severity_Aggregate;
  /** fetch data from the table: "allergies_severity" using primary key columns */
  allergies_severity_by_pk?: Maybe<Allergies_Severity>;
  /** fetch data from the table in a streaming manner: "allergies_severity" */
  allergies_severity_stream: Array<Allergies_Severity>;
  /** fetch data from the table: "allergies_status" */
  allergies_status: Array<Allergies_Status>;
  /** fetch aggregated fields from the table: "allergies_status" */
  allergies_status_aggregate: Allergies_Status_Aggregate;
  /** fetch data from the table: "allergies_status" using primary key columns */
  allergies_status_by_pk?: Maybe<Allergies_Status>;
  /** fetch data from the table in a streaming manner: "allergies_status" */
  allergies_status_stream: Array<Allergies_Status>;
  /** fetch data from the table in a streaming manner: "allergies" */
  allergies_stream: Array<Allergies>;
  /** An array relationship */
  appointment_blocks: Array<Appointment_Blocks>;
  /** An aggregate relationship */
  appointment_blocks_aggregate: Appointment_Blocks_Aggregate;
  /** fetch data from the table: "appointment_blocks" using primary key columns */
  appointment_blocks_by_pk?: Maybe<Appointment_Blocks>;
  /** fetch data from the table: "appointment_blocks_staff" */
  appointment_blocks_staff: Array<Appointment_Blocks_Staff>;
  /** fetch aggregated fields from the table: "appointment_blocks_staff" */
  appointment_blocks_staff_aggregate: Appointment_Blocks_Staff_Aggregate;
  /** fetch data from the table: "appointment_blocks_staff" using primary key columns */
  appointment_blocks_staff_by_pk?: Maybe<Appointment_Blocks_Staff>;
  /** fetch data from the table in a streaming manner: "appointment_blocks_staff" */
  appointment_blocks_staff_stream: Array<Appointment_Blocks_Staff>;
  /** fetch data from the table in a streaming manner: "appointment_blocks" */
  appointment_blocks_stream: Array<Appointment_Blocks>;
  /** fetch data from the table: "appointment_counts" */
  appointment_counts: Array<Appointment_Counts>;
  /** fetch aggregated fields from the table: "appointment_counts" */
  appointment_counts_aggregate: Appointment_Counts_Aggregate;
  /** fetch data from the table in a streaming manner: "appointment_counts" */
  appointment_counts_stream: Array<Appointment_Counts>;
  /** fetch data from the table: "appointment_patient" */
  appointment_patient: Array<Appointment_Patient>;
  /** fetch aggregated fields from the table: "appointment_patient" */
  appointment_patient_aggregate: Appointment_Patient_Aggregate;
  /** fetch data from the table: "appointment_patient" using primary key columns */
  appointment_patient_by_pk?: Maybe<Appointment_Patient>;
  /** fetch data from the table in a streaming manner: "appointment_patient" */
  appointment_patient_stream: Array<Appointment_Patient>;
  /** fetch data from the table: "appointment_payment" */
  appointment_payment: Array<Appointment_Payment>;
  /** fetch aggregated fields from the table: "appointment_payment" */
  appointment_payment_aggregate: Appointment_Payment_Aggregate;
  /** fetch data from the table: "appointment_payment" using primary key columns */
  appointment_payment_by_pk?: Maybe<Appointment_Payment>;
  /** fetch data from the table in a streaming manner: "appointment_payment" */
  appointment_payment_stream: Array<Appointment_Payment>;
  /** An array relationship */
  appointment_reservations: Array<Appointment_Reservations>;
  /** An aggregate relationship */
  appointment_reservations_aggregate: Appointment_Reservations_Aggregate;
  /** fetch data from the table: "appointment_reservations" using primary key columns */
  appointment_reservations_by_pk?: Maybe<Appointment_Reservations>;
  /** fetch data from the table in a streaming manner: "appointment_reservations" */
  appointment_reservations_stream: Array<Appointment_Reservations>;
  /** fetch data from the table: "appointment_staff" */
  appointment_staff: Array<Appointment_Staff>;
  /** fetch aggregated fields from the table: "appointment_staff" */
  appointment_staff_aggregate: Appointment_Staff_Aggregate;
  /** fetch data from the table: "appointment_staff" using primary key columns */
  appointment_staff_by_pk?: Maybe<Appointment_Staff>;
  /** fetch data from the table in a streaming manner: "appointment_staff" */
  appointment_staff_stream: Array<Appointment_Staff>;
  /** fetch data from the table: "appointment_status" */
  appointment_status: Array<Appointment_Status>;
  /** fetch aggregated fields from the table: "appointment_status" */
  appointment_status_aggregate: Appointment_Status_Aggregate;
  /** fetch data from the table: "appointment_status" using primary key columns */
  appointment_status_by_pk?: Maybe<Appointment_Status>;
  /** fetch data from the table in a streaming manner: "appointment_status" */
  appointment_status_stream: Array<Appointment_Status>;
  /** fetch data from the table: "appointment_types" */
  appointment_types: Array<Appointment_Types>;
  /** fetch aggregated fields from the table: "appointment_types" */
  appointment_types_aggregate: Appointment_Types_Aggregate;
  /** fetch data from the table: "appointment_types" using primary key columns */
  appointment_types_by_pk?: Maybe<Appointment_Types>;
  /** fetch data from the table in a streaming manner: "appointment_types" */
  appointment_types_stream: Array<Appointment_Types>;
  /** An array relationship */
  appointments: Array<Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Appointments_Aggregate;
  /** fetch data from the table: "appointments" using primary key columns */
  appointments_by_pk?: Maybe<Appointments>;
  /** fetch data from the table in a streaming manner: "appointments" */
  appointments_stream: Array<Appointments>;
  /** An array relationship */
  audit_logs: Array<Audit_Logs>;
  /** An aggregate relationship */
  audit_logs_aggregate: Audit_Logs_Aggregate;
  /** fetch data from the table: "audit_logs" using primary key columns */
  audit_logs_by_pk?: Maybe<Audit_Logs>;
  /** fetch data from the table in a streaming manner: "audit_logs" */
  audit_logs_stream: Array<Audit_Logs>;
  /** fetch data from the table: "care_type_duration" */
  care_type_duration: Array<Care_Type_Duration>;
  /** fetch aggregated fields from the table: "care_type_duration" */
  care_type_duration_aggregate: Care_Type_Duration_Aggregate;
  /** fetch data from the table: "care_type_duration" using primary key columns */
  care_type_duration_by_pk?: Maybe<Care_Type_Duration>;
  /** fetch data from the table in a streaming manner: "care_type_duration" */
  care_type_duration_stream: Array<Care_Type_Duration>;
  /** fetch data from the table: "care_types" */
  care_types: Array<Care_Types>;
  /** fetch aggregated fields from the table: "care_types" */
  care_types_aggregate: Care_Types_Aggregate;
  /** fetch data from the table: "care_types" using primary key columns */
  care_types_by_pk?: Maybe<Care_Types>;
  /** fetch data from the table in a streaming manner: "care_types" */
  care_types_stream: Array<Care_Types>;
  /** An array relationship */
  charge_products: Array<Charge_Products>;
  /** An aggregate relationship */
  charge_products_aggregate: Charge_Products_Aggregate;
  /** fetch data from the table: "charge_products" using primary key columns */
  charge_products_by_pk?: Maybe<Charge_Products>;
  /** fetch data from the table in a streaming manner: "charge_products" */
  charge_products_stream: Array<Charge_Products>;
  /** An array relationship */
  charges: Array<Charges>;
  /** An aggregate relationship */
  charges_aggregate: Charges_Aggregate;
  /** fetch data from the table: "charges" using primary key columns */
  charges_by_pk?: Maybe<Charges>;
  /** fetch data from the table in a streaming manner: "charges" */
  charges_stream: Array<Charges>;
  /** An array relationship */
  chat_messages: Array<Chat_Messages>;
  /** An aggregate relationship */
  chat_messages_aggregate: Chat_Messages_Aggregate;
  /** fetch data from the table: "chat_messages" using primary key columns */
  chat_messages_by_pk?: Maybe<Chat_Messages>;
  /** fetch data from the table: "chat_messages_file" */
  chat_messages_file: Array<Chat_Messages_File>;
  /** fetch aggregated fields from the table: "chat_messages_file" */
  chat_messages_file_aggregate: Chat_Messages_File_Aggregate;
  /** fetch data from the table: "chat_messages_file" using primary key columns */
  chat_messages_file_by_pk?: Maybe<Chat_Messages_File>;
  /** fetch data from the table in a streaming manner: "chat_messages_file" */
  chat_messages_file_stream: Array<Chat_Messages_File>;
  /** fetch data from the table in a streaming manner: "chat_messages" */
  chat_messages_stream: Array<Chat_Messages>;
  /** fetch data from the table: "chat_messages_type" */
  chat_messages_type: Array<Chat_Messages_Type>;
  /** fetch aggregated fields from the table: "chat_messages_type" */
  chat_messages_type_aggregate: Chat_Messages_Type_Aggregate;
  /** fetch data from the table: "chat_messages_type" using primary key columns */
  chat_messages_type_by_pk?: Maybe<Chat_Messages_Type>;
  /** fetch data from the table in a streaming manner: "chat_messages_type" */
  chat_messages_type_stream: Array<Chat_Messages_Type>;
  /** An array relationship */
  chat_notes: Array<Chat_Notes>;
  /** An aggregate relationship */
  chat_notes_aggregate: Chat_Notes_Aggregate;
  /** fetch data from the table: "chat_notes" using primary key columns */
  chat_notes_by_pk?: Maybe<Chat_Notes>;
  /** fetch data from the table in a streaming manner: "chat_notes" */
  chat_notes_stream: Array<Chat_Notes>;
  /** fetch data from the table: "chat_notes_types" */
  chat_notes_types: Array<Chat_Notes_Types>;
  /** fetch aggregated fields from the table: "chat_notes_types" */
  chat_notes_types_aggregate: Chat_Notes_Types_Aggregate;
  /** fetch data from the table: "chat_notes_types" using primary key columns */
  chat_notes_types_by_pk?: Maybe<Chat_Notes_Types>;
  /** fetch data from the table in a streaming manner: "chat_notes_types" */
  chat_notes_types_stream: Array<Chat_Notes_Types>;
  /** An array relationship */
  chat_sessions: Array<Chat_Sessions>;
  /** An array relationship */
  chat_sessions_accounts: Array<Chat_Sessions_Accounts>;
  /** An aggregate relationship */
  chat_sessions_accounts_aggregate: Chat_Sessions_Accounts_Aggregate;
  /** fetch data from the table: "chat_sessions_accounts" using primary key columns */
  chat_sessions_accounts_by_pk?: Maybe<Chat_Sessions_Accounts>;
  /** fetch data from the table in a streaming manner: "chat_sessions_accounts" */
  chat_sessions_accounts_stream: Array<Chat_Sessions_Accounts>;
  /** An aggregate relationship */
  chat_sessions_aggregate: Chat_Sessions_Aggregate;
  /** fetch data from the table: "chat_sessions" using primary key columns */
  chat_sessions_by_pk?: Maybe<Chat_Sessions>;
  /** An array relationship */
  chat_sessions_patients: Array<Chat_Sessions_Patients>;
  /** An aggregate relationship */
  chat_sessions_patients_aggregate: Chat_Sessions_Patients_Aggregate;
  /** fetch data from the table: "chat_sessions_patients" using primary key columns */
  chat_sessions_patients_by_pk?: Maybe<Chat_Sessions_Patients>;
  /** fetch data from the table in a streaming manner: "chat_sessions_patients" */
  chat_sessions_patients_stream: Array<Chat_Sessions_Patients>;
  /** fetch data from the table: "chat_sessions_read" */
  chat_sessions_read: Array<Chat_Sessions_Read>;
  /** fetch aggregated fields from the table: "chat_sessions_read" */
  chat_sessions_read_aggregate: Chat_Sessions_Read_Aggregate;
  /** fetch data from the table: "chat_sessions_read" using primary key columns */
  chat_sessions_read_by_pk?: Maybe<Chat_Sessions_Read>;
  /** fetch data from the table in a streaming manner: "chat_sessions_read" */
  chat_sessions_read_stream: Array<Chat_Sessions_Read>;
  /** fetch data from the table: "chat_sessions_staff" */
  chat_sessions_staff: Array<Chat_Sessions_Staff>;
  /** fetch aggregated fields from the table: "chat_sessions_staff" */
  chat_sessions_staff_aggregate: Chat_Sessions_Staff_Aggregate;
  /** fetch data from the table: "chat_sessions_staff" using primary key columns */
  chat_sessions_staff_by_pk?: Maybe<Chat_Sessions_Staff>;
  /** fetch data from the table in a streaming manner: "chat_sessions_staff" */
  chat_sessions_staff_stream: Array<Chat_Sessions_Staff>;
  /** fetch data from the table: "chat_sessions_status" */
  chat_sessions_status: Array<Chat_Sessions_Status>;
  /** fetch aggregated fields from the table: "chat_sessions_status" */
  chat_sessions_status_aggregate: Chat_Sessions_Status_Aggregate;
  /** fetch data from the table: "chat_sessions_status" using primary key columns */
  chat_sessions_status_by_pk?: Maybe<Chat_Sessions_Status>;
  /** fetch data from the table in a streaming manner: "chat_sessions_status" */
  chat_sessions_status_stream: Array<Chat_Sessions_Status>;
  /** fetch data from the table in a streaming manner: "chat_sessions" */
  chat_sessions_stream: Array<Chat_Sessions>;
  /** fetch data from the table: "clinic_location_types" */
  clinic_location_types: Array<Clinic_Location_Types>;
  /** fetch aggregated fields from the table: "clinic_location_types" */
  clinic_location_types_aggregate: Clinic_Location_Types_Aggregate;
  /** fetch data from the table: "clinic_location_types" using primary key columns */
  clinic_location_types_by_pk?: Maybe<Clinic_Location_Types>;
  /** fetch data from the table in a streaming manner: "clinic_location_types" */
  clinic_location_types_stream: Array<Clinic_Location_Types>;
  /** fetch data from the table: "clinic_status" */
  clinic_status: Array<Clinic_Status>;
  /** fetch aggregated fields from the table: "clinic_status" */
  clinic_status_aggregate: Clinic_Status_Aggregate;
  /** fetch data from the table: "clinic_status" using primary key columns */
  clinic_status_by_pk?: Maybe<Clinic_Status>;
  /** fetch data from the table in a streaming manner: "clinic_status" */
  clinic_status_stream: Array<Clinic_Status>;
  /** An array relationship */
  clinics: Array<Clinics>;
  /** An aggregate relationship */
  clinics_aggregate: Clinics_Aggregate;
  /** fetch data from the table: "clinics" using primary key columns */
  clinics_by_pk?: Maybe<Clinics>;
  /** An array relationship */
  clinics_properties: Array<Clinics_Properties>;
  /** An aggregate relationship */
  clinics_properties_aggregate: Clinics_Properties_Aggregate;
  /** fetch data from the table: "clinics_properties" using primary key columns */
  clinics_properties_by_pk?: Maybe<Clinics_Properties>;
  /** fetch data from the table in a streaming manner: "clinics_properties" */
  clinics_properties_stream: Array<Clinics_Properties>;
  /** fetch data from the table: "clinics_properties_types" */
  clinics_properties_types: Array<Clinics_Properties_Types>;
  /** fetch aggregated fields from the table: "clinics_properties_types" */
  clinics_properties_types_aggregate: Clinics_Properties_Types_Aggregate;
  /** fetch data from the table: "clinics_properties_types" using primary key columns */
  clinics_properties_types_by_pk?: Maybe<Clinics_Properties_Types>;
  /** fetch data from the table in a streaming manner: "clinics_properties_types" */
  clinics_properties_types_stream: Array<Clinics_Properties_Types>;
  /** An array relationship */
  clinics_regions: Array<Clinics_Regions>;
  /** An aggregate relationship */
  clinics_regions_aggregate: Clinics_Regions_Aggregate;
  /** fetch data from the table: "clinics_regions" using primary key columns */
  clinics_regions_by_pk?: Maybe<Clinics_Regions>;
  /** fetch data from the table in a streaming manner: "clinics_regions" */
  clinics_regions_stream: Array<Clinics_Regions>;
  /** fetch data from the table in a streaming manner: "clinics" */
  clinics_stream: Array<Clinics>;
  /** fetch data from the table: "communication_history" */
  communication_history: Array<Communication_History>;
  /** fetch aggregated fields from the table: "communication_history" */
  communication_history_aggregate: Communication_History_Aggregate;
  /** fetch data from the table: "communication_history" using primary key columns */
  communication_history_by_pk?: Maybe<Communication_History>;
  /** fetch data from the table: "communication_history_directions" */
  communication_history_directions: Array<Communication_History_Directions>;
  /** fetch aggregated fields from the table: "communication_history_directions" */
  communication_history_directions_aggregate: Communication_History_Directions_Aggregate;
  /** fetch data from the table: "communication_history_directions" using primary key columns */
  communication_history_directions_by_pk?: Maybe<Communication_History_Directions>;
  /** fetch data from the table in a streaming manner: "communication_history_directions" */
  communication_history_directions_stream: Array<Communication_History_Directions>;
  /** fetch data from the table in a streaming manner: "communication_history" */
  communication_history_stream: Array<Communication_History>;
  /** fetch data from the table: "communication_history_tag_types" */
  communication_history_tag_types: Array<Communication_History_Tag_Types>;
  /** fetch aggregated fields from the table: "communication_history_tag_types" */
  communication_history_tag_types_aggregate: Communication_History_Tag_Types_Aggregate;
  /** fetch data from the table: "communication_history_tag_types" using primary key columns */
  communication_history_tag_types_by_pk?: Maybe<Communication_History_Tag_Types>;
  /** fetch data from the table in a streaming manner: "communication_history_tag_types" */
  communication_history_tag_types_stream: Array<Communication_History_Tag_Types>;
  /** An array relationship */
  communication_history_tags: Array<Communication_History_Tags>;
  /** An aggregate relationship */
  communication_history_tags_aggregate: Communication_History_Tags_Aggregate;
  /** fetch data from the table: "communication_history_tags" using primary key columns */
  communication_history_tags_by_pk?: Maybe<Communication_History_Tags>;
  /** fetch data from the table in a streaming manner: "communication_history_tags" */
  communication_history_tags_stream: Array<Communication_History_Tags>;
  /** fetch data from the table: "communication_history_types" */
  communication_history_types: Array<Communication_History_Types>;
  /** fetch aggregated fields from the table: "communication_history_types" */
  communication_history_types_aggregate: Communication_History_Types_Aggregate;
  /** fetch data from the table: "communication_history_types" using primary key columns */
  communication_history_types_by_pk?: Maybe<Communication_History_Types>;
  /** fetch data from the table in a streaming manner: "communication_history_types" */
  communication_history_types_stream: Array<Communication_History_Types>;
  /** An array relationship */
  devices: Array<Devices>;
  /** An aggregate relationship */
  devices_aggregate: Devices_Aggregate;
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk?: Maybe<Devices>;
  /** fetch data from the table in a streaming manner: "devices" */
  devices_stream: Array<Devices>;
  /** fetch data from the table: "discovered_from" */
  discovered_from: Array<Discovered_From>;
  /** fetch aggregated fields from the table: "discovered_from" */
  discovered_from_aggregate: Discovered_From_Aggregate;
  /** fetch data from the table: "discovered_from" using primary key columns */
  discovered_from_by_pk?: Maybe<Discovered_From>;
  /** fetch data from the table in a streaming manner: "discovered_from" */
  discovered_from_stream: Array<Discovered_From>;
  /** fetch data from the table: "document_status_types" */
  document_status_types: Array<Document_Status_Types>;
  /** fetch aggregated fields from the table: "document_status_types" */
  document_status_types_aggregate: Document_Status_Types_Aggregate;
  /** fetch data from the table: "document_status_types" using primary key columns */
  document_status_types_by_pk?: Maybe<Document_Status_Types>;
  /** fetch data from the table in a streaming manner: "document_status_types" */
  document_status_types_stream: Array<Document_Status_Types>;
  /** fetch data from the table: "document_types" */
  document_types: Array<Document_Types>;
  /** fetch aggregated fields from the table: "document_types" */
  document_types_aggregate: Document_Types_Aggregate;
  /** fetch data from the table: "document_types" using primary key columns */
  document_types_by_pk?: Maybe<Document_Types>;
  /** fetch data from the table in a streaming manner: "document_types" */
  document_types_stream: Array<Document_Types>;
  /** An array relationship */
  documents: Array<Documents>;
  /** An aggregate relationship */
  documents_aggregate: Documents_Aggregate;
  /** fetch data from the table: "documents" using primary key columns */
  documents_by_pk?: Maybe<Documents>;
  /** fetch data from the table in a streaming manner: "documents" */
  documents_stream: Array<Documents>;
  /** fetch data from the table: "fhir_resource_types" */
  fhir_resource_types: Array<Fhir_Resource_Types>;
  /** fetch aggregated fields from the table: "fhir_resource_types" */
  fhir_resource_types_aggregate: Fhir_Resource_Types_Aggregate;
  /** fetch data from the table: "fhir_resource_types" using primary key columns */
  fhir_resource_types_by_pk?: Maybe<Fhir_Resource_Types>;
  /** fetch data from the table in a streaming manner: "fhir_resource_types" */
  fhir_resource_types_stream: Array<Fhir_Resource_Types>;
  /** An array relationship */
  fhir_resources: Array<Fhir_Resources>;
  /** An aggregate relationship */
  fhir_resources_aggregate: Fhir_Resources_Aggregate;
  /** fetch data from the table: "fhir_resources" using primary key columns */
  fhir_resources_by_pk?: Maybe<Fhir_Resources>;
  /** An array relationship */
  fhir_resources_coded: Array<Fhir_Resources_Coded>;
  /** An aggregate relationship */
  fhir_resources_coded_aggregate: Fhir_Resources_Coded_Aggregate;
  /** fetch data from the table in a streaming manner: "fhir_resources_coded" */
  fhir_resources_coded_stream: Array<Fhir_Resources_Coded>;
  /** fetch data from the table: "fhir_resources_history" */
  fhir_resources_history: Array<Fhir_Resources_History>;
  /** fetch aggregated fields from the table: "fhir_resources_history" */
  fhir_resources_history_aggregate: Fhir_Resources_History_Aggregate;
  /** fetch data from the table: "fhir_resources_history" using primary key columns */
  fhir_resources_history_by_pk?: Maybe<Fhir_Resources_History>;
  /** fetch data from the table in a streaming manner: "fhir_resources_history" */
  fhir_resources_history_stream: Array<Fhir_Resources_History>;
  /** fetch data from the table in a streaming manner: "fhir_resources" */
  fhir_resources_stream: Array<Fhir_Resources>;
  /** fetch data from the table: "file_document_type" */
  file_document_type: Array<File_Document_Type>;
  /** fetch aggregated fields from the table: "file_document_type" */
  file_document_type_aggregate: File_Document_Type_Aggregate;
  /** fetch data from the table: "file_document_type" using primary key columns */
  file_document_type_by_pk?: Maybe<File_Document_Type>;
  /** fetch data from the table in a streaming manner: "file_document_type" */
  file_document_type_stream: Array<File_Document_Type>;
  /** An array relationship */
  files: Array<Files>;
  /** An aggregate relationship */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** fetch data from the table in a streaming manner: "files" */
  files_stream: Array<Files>;
  /** An array relationship */
  global_notes: Array<Global_Notes>;
  /** An aggregate relationship */
  global_notes_aggregate: Global_Notes_Aggregate;
  /** fetch data from the table: "global_notes" using primary key columns */
  global_notes_by_pk?: Maybe<Global_Notes>;
  /** fetch data from the table in a streaming manner: "global_notes" */
  global_notes_stream: Array<Global_Notes>;
  /** fetch data from the table: "insurance" */
  insurance: Array<Insurance>;
  /** fetch aggregated fields from the table: "insurance" */
  insurance_aggregate: Insurance_Aggregate;
  /** fetch data from the table: "insurance" using primary key columns */
  insurance_by_pk?: Maybe<Insurance>;
  /** fetch data from the table: "insurance_providers" */
  insurance_providers: Array<Insurance_Providers>;
  /** fetch aggregated fields from the table: "insurance_providers" */
  insurance_providers_aggregate: Insurance_Providers_Aggregate;
  /** fetch data from the table: "insurance_providers" using primary key columns */
  insurance_providers_by_pk?: Maybe<Insurance_Providers>;
  /** fetch data from the table in a streaming manner: "insurance_providers" */
  insurance_providers_stream: Array<Insurance_Providers>;
  /** fetch data from the table in a streaming manner: "insurance" */
  insurance_stream: Array<Insurance>;
  /** fetch data from the table: "insurance_types" */
  insurance_types: Array<Insurance_Types>;
  /** fetch aggregated fields from the table: "insurance_types" */
  insurance_types_aggregate: Insurance_Types_Aggregate;
  /** fetch data from the table: "insurance_types" using primary key columns */
  insurance_types_by_pk?: Maybe<Insurance_Types>;
  /** fetch data from the table in a streaming manner: "insurance_types" */
  insurance_types_stream: Array<Insurance_Types>;
  /** An array relationship */
  kareo_logs: Array<Kareo_Logs>;
  /** An aggregate relationship */
  kareo_logs_aggregate: Kareo_Logs_Aggregate;
  /** fetch data from the table: "kareo_logs" using primary key columns */
  kareo_logs_by_pk?: Maybe<Kareo_Logs>;
  /** fetch data from the table in a streaming manner: "kareo_logs" */
  kareo_logs_stream: Array<Kareo_Logs>;
  /** An array relationship */
  locations: Array<Locations>;
  /** An aggregate relationship */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table in a streaming manner: "locations" */
  locations_stream: Array<Locations>;
  /** fetch data from the table: "medical_history" */
  medical_history: Array<Medical_History>;
  /** fetch aggregated fields from the table: "medical_history" */
  medical_history_aggregate: Medical_History_Aggregate;
  /** An array relationship */
  medical_history_answers: Array<Medical_History_Answers>;
  /** An aggregate relationship */
  medical_history_answers_aggregate: Medical_History_Answers_Aggregate;
  /** fetch data from the table: "medical_history_answers" using primary key columns */
  medical_history_answers_by_pk?: Maybe<Medical_History_Answers>;
  /** fetch data from the table in a streaming manner: "medical_history_answers" */
  medical_history_answers_stream: Array<Medical_History_Answers>;
  /** fetch data from the table: "medical_history" using primary key columns */
  medical_history_by_pk?: Maybe<Medical_History>;
  /** fetch data from the table: "medical_history_person_types" */
  medical_history_person_types: Array<Medical_History_Person_Types>;
  /** fetch aggregated fields from the table: "medical_history_person_types" */
  medical_history_person_types_aggregate: Medical_History_Person_Types_Aggregate;
  /** fetch data from the table: "medical_history_person_types" using primary key columns */
  medical_history_person_types_by_pk?: Maybe<Medical_History_Person_Types>;
  /** fetch data from the table in a streaming manner: "medical_history_person_types" */
  medical_history_person_types_stream: Array<Medical_History_Person_Types>;
  /** fetch data from the table: "medical_history_questions" */
  medical_history_questions: Array<Medical_History_Questions>;
  /** fetch aggregated fields from the table: "medical_history_questions" */
  medical_history_questions_aggregate: Medical_History_Questions_Aggregate;
  /** fetch data from the table: "medical_history_questions" using primary key columns */
  medical_history_questions_by_pk?: Maybe<Medical_History_Questions>;
  /** fetch data from the table in a streaming manner: "medical_history_questions" */
  medical_history_questions_stream: Array<Medical_History_Questions>;
  /** fetch data from the table in a streaming manner: "medical_history" */
  medical_history_stream: Array<Medical_History>;
  /** fetch data from the table: "medical_history_types" */
  medical_history_types: Array<Medical_History_Types>;
  /** fetch aggregated fields from the table: "medical_history_types" */
  medical_history_types_aggregate: Medical_History_Types_Aggregate;
  /** fetch data from the table: "medical_history_types" using primary key columns */
  medical_history_types_by_pk?: Maybe<Medical_History_Types>;
  /** fetch data from the table in a streaming manner: "medical_history_types" */
  medical_history_types_stream: Array<Medical_History_Types>;
  /** fetch data from the table: "patient_address" */
  patient_address: Array<Patient_Address>;
  /** fetch aggregated fields from the table: "patient_address" */
  patient_address_aggregate: Patient_Address_Aggregate;
  /** fetch data from the table in a streaming manner: "patient_address" */
  patient_address_stream: Array<Patient_Address>;
  /** fetch data from the table: "patient_duplicates" */
  patient_duplicates: Array<Patient_Duplicates>;
  /** fetch aggregated fields from the table: "patient_duplicates" */
  patient_duplicates_aggregate: Patient_Duplicates_Aggregate;
  /** fetch data from the table in a streaming manner: "patient_duplicates" */
  patient_duplicates_stream: Array<Patient_Duplicates>;
  /** An array relationship */
  patient_migrations: Array<Patient_Migrations>;
  /** An aggregate relationship */
  patient_migrations_aggregate: Patient_Migrations_Aggregate;
  /** fetch data from the table: "patient_migrations" using primary key columns */
  patient_migrations_by_pk?: Maybe<Patient_Migrations>;
  /** fetch data from the table in a streaming manner: "patient_migrations" */
  patient_migrations_stream: Array<Patient_Migrations>;
  /** An array relationship */
  patient_not_duplicates: Array<Patient_Not_Duplicates>;
  /** An aggregate relationship */
  patient_not_duplicates_aggregate: Patient_Not_Duplicates_Aggregate;
  /** fetch data from the table: "patient_not_duplicates" using primary key columns */
  patient_not_duplicates_by_pk?: Maybe<Patient_Not_Duplicates>;
  /** fetch data from the table in a streaming manner: "patient_not_duplicates" */
  patient_not_duplicates_stream: Array<Patient_Not_Duplicates>;
  /** fetch data from the table: "patient_record_status_types" */
  patient_record_status_types: Array<Patient_Record_Status_Types>;
  /** fetch aggregated fields from the table: "patient_record_status_types" */
  patient_record_status_types_aggregate: Patient_Record_Status_Types_Aggregate;
  /** fetch data from the table: "patient_record_status_types" using primary key columns */
  patient_record_status_types_by_pk?: Maybe<Patient_Record_Status_Types>;
  /** fetch data from the table in a streaming manner: "patient_record_status_types" */
  patient_record_status_types_stream: Array<Patient_Record_Status_Types>;
  /** An array relationship */
  patients: Array<Patients>;
  /** An aggregate relationship */
  patients_aggregate: Patients_Aggregate;
  /** fetch data from the table: "patients" using primary key columns */
  patients_by_pk?: Maybe<Patients>;
  /** fetch data from the table in a streaming manner: "patients" */
  patients_stream: Array<Patients>;
  /** fetch data from the table: "payment_type" */
  payment_type: Array<Payment_Type>;
  /** fetch aggregated fields from the table: "payment_type" */
  payment_type_aggregate: Payment_Type_Aggregate;
  /** fetch data from the table: "payment_type" using primary key columns */
  payment_type_by_pk?: Maybe<Payment_Type>;
  /** fetch data from the table in a streaming manner: "payment_type" */
  payment_type_stream: Array<Payment_Type>;
  /** An array relationship */
  phone_numbers: Array<Phone_Numbers>;
  /** An aggregate relationship */
  phone_numbers_aggregate: Phone_Numbers_Aggregate;
  /** fetch data from the table: "phone_numbers" using primary key columns */
  phone_numbers_by_pk?: Maybe<Phone_Numbers>;
  /** fetch data from the table in a streaming manner: "phone_numbers" */
  phone_numbers_stream: Array<Phone_Numbers>;
  /** fetch data from the table: "problem_status_types" */
  problem_status_types: Array<Problem_Status_Types>;
  /** fetch aggregated fields from the table: "problem_status_types" */
  problem_status_types_aggregate: Problem_Status_Types_Aggregate;
  /** fetch data from the table: "problem_status_types" using primary key columns */
  problem_status_types_by_pk?: Maybe<Problem_Status_Types>;
  /** fetch data from the table in a streaming manner: "problem_status_types" */
  problem_status_types_stream: Array<Problem_Status_Types>;
  /** An array relationship */
  problems: Array<Problems>;
  /** An aggregate relationship */
  problems_aggregate: Problems_Aggregate;
  /** fetch data from the table: "problems" using primary key columns */
  problems_by_pk?: Maybe<Problems>;
  /** fetch data from the table in a streaming manner: "problems" */
  problems_stream: Array<Problems>;
  /** fetch data from the table: "products" */
  products: Array<Products>;
  /** fetch aggregated fields from the table: "products" */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table in a streaming manner: "products" */
  products_stream: Array<Products>;
  /** fetch data from the table: "reconciliation_type" */
  reconciliation_type: Array<Reconciliation_Type>;
  /** fetch aggregated fields from the table: "reconciliation_type" */
  reconciliation_type_aggregate: Reconciliation_Type_Aggregate;
  /** fetch data from the table: "reconciliation_type" using primary key columns */
  reconciliation_type_by_pk?: Maybe<Reconciliation_Type>;
  /** fetch data from the table in a streaming manner: "reconciliation_type" */
  reconciliation_type_stream: Array<Reconciliation_Type>;
  /** An array relationship */
  reconciliations: Array<Reconciliations>;
  /** An aggregate relationship */
  reconciliations_aggregate: Reconciliations_Aggregate;
  /** fetch data from the table: "reconciliations" using primary key columns */
  reconciliations_by_pk?: Maybe<Reconciliations>;
  /** fetch data from the table in a streaming manner: "reconciliations" */
  reconciliations_stream: Array<Reconciliations>;
  /** fetch data from the table: "regions" */
  regions: Array<Regions>;
  /** fetch aggregated fields from the table: "regions" */
  regions_aggregate: Regions_Aggregate;
  /** fetch data from the table: "regions" using primary key columns */
  regions_by_pk?: Maybe<Regions>;
  /** fetch data from the table in a streaming manner: "regions" */
  regions_stream: Array<Regions>;
  /** fetch data from the table: "reservations" */
  reservations: Array<Reservations>;
  /** fetch aggregated fields from the table: "reservations" */
  reservations_aggregate: Reservations_Aggregate;
  /** fetch data from the table in a streaming manner: "reservations" */
  reservations_stream: Array<Reservations>;
  /** An array relationship */
  reviews: Array<Reviews>;
  /** An aggregate relationship */
  reviews_aggregate: Reviews_Aggregate;
  /** fetch data from the table: "reviews" using primary key columns */
  reviews_by_pk?: Maybe<Reviews>;
  /** fetch data from the table in a streaming manner: "reviews" */
  reviews_stream: Array<Reviews>;
  /** An array relationship */
  rooms: Array<Rooms>;
  /** An aggregate relationship */
  rooms_aggregate: Rooms_Aggregate;
  /** fetch data from the table: "rooms" using primary key columns */
  rooms_by_pk?: Maybe<Rooms>;
  /** fetch data from the table in a streaming manner: "rooms" */
  rooms_stream: Array<Rooms>;
  /** fetch data from the table: "screening_category_types" */
  screening_category_types: Array<Screening_Category_Types>;
  /** fetch aggregated fields from the table: "screening_category_types" */
  screening_category_types_aggregate: Screening_Category_Types_Aggregate;
  /** fetch data from the table: "screening_category_types" using primary key columns */
  screening_category_types_by_pk?: Maybe<Screening_Category_Types>;
  /** fetch data from the table in a streaming manner: "screening_category_types" */
  screening_category_types_stream: Array<Screening_Category_Types>;
  /** An array relationship */
  screening_types: Array<Screening_Types>;
  /** An aggregate relationship */
  screening_types_aggregate: Screening_Types_Aggregate;
  /** fetch data from the table: "screening_types" using primary key columns */
  screening_types_by_pk?: Maybe<Screening_Types>;
  /** fetch data from the table in a streaming manner: "screening_types" */
  screening_types_stream: Array<Screening_Types>;
  /** An array relationship */
  screenings: Array<Screenings>;
  /** An aggregate relationship */
  screenings_aggregate: Screenings_Aggregate;
  /** fetch data from the table: "screenings" using primary key columns */
  screenings_by_pk?: Maybe<Screenings>;
  /** fetch data from the table in a streaming manner: "screenings" */
  screenings_stream: Array<Screenings>;
  /** execute function "search_accounts" which returns "accounts" */
  search_accounts: Array<Accounts>;
  /** execute function "search_accounts" and query aggregates on result of table type "accounts" */
  search_accounts_aggregate: Accounts_Aggregate;
  /** execute function "search_patients" which returns "patients" */
  search_patients: Array<Patients>;
  /** execute function "search_patients" and query aggregates on result of table type "patients" */
  search_patients_aggregate: Patients_Aggregate;
  /** execute function "search_snomed_synonyms" which returns "snomed_synonyms" */
  search_snomed_synonyms: Array<Snomed_Synonyms>;
  /** execute function "search_snomed_synonyms" and query aggregates on result of table type "snomed_synonyms" */
  search_snomed_synonyms_aggregate: Snomed_Synonyms_Aggregate;
  /** execute function "search_staff" which returns "staff" */
  search_staff: Array<Staff>;
  /** execute function "search_staff" and query aggregates on result of table type "staff" */
  search_staff_aggregate: Staff_Aggregate;
  /** execute function "search_tasks" which returns "tasks" */
  search_tasks: Array<Tasks>;
  /** execute function "search_tasks" and query aggregates on result of table type "tasks" */
  search_tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "services" */
  services: Array<Services>;
  /** fetch aggregated fields from the table: "services" */
  services_aggregate: Services_Aggregate;
  /** fetch data from the table: "services" using primary key columns */
  services_by_pk?: Maybe<Services>;
  /** fetch data from the table in a streaming manner: "services" */
  services_stream: Array<Services>;
  /** fetch data from the table: "snomed_synonyms" */
  snomed_synonyms: Array<Snomed_Synonyms>;
  /** fetch aggregated fields from the table: "snomed_synonyms" */
  snomed_synonyms_aggregate: Snomed_Synonyms_Aggregate;
  /** fetch data from the table: "snomed_synonyms" using primary key columns */
  snomed_synonyms_by_pk?: Maybe<Snomed_Synonyms>;
  /** fetch data from the table in a streaming manner: "snomed_synonyms" */
  snomed_synonyms_stream: Array<Snomed_Synonyms>;
  /** fetch data from the table: "staff" */
  staff: Array<Staff>;
  /** fetch aggregated fields from the table: "staff" */
  staff_aggregate: Staff_Aggregate;
  /** fetch data from the table: "staff" using primary key columns */
  staff_by_pk?: Maybe<Staff>;
  /** An array relationship */
  staff_regions: Array<Staff_Regions>;
  /** An aggregate relationship */
  staff_regions_aggregate: Staff_Regions_Aggregate;
  /** fetch data from the table: "staff_regions" using primary key columns */
  staff_regions_by_pk?: Maybe<Staff_Regions>;
  /** fetch data from the table in a streaming manner: "staff_regions" */
  staff_regions_stream: Array<Staff_Regions>;
  /** fetch data from the table: "staff_schedule" */
  staff_schedule: Array<Staff_Schedule>;
  /** fetch aggregated fields from the table: "staff_schedule" */
  staff_schedule_aggregate: Staff_Schedule_Aggregate;
  /** fetch data from the table: "staff_schedule" using primary key columns */
  staff_schedule_by_pk?: Maybe<Staff_Schedule>;
  /** fetch data from the table: "staff_schedule_service" */
  staff_schedule_service: Array<Staff_Schedule_Service>;
  /** fetch aggregated fields from the table: "staff_schedule_service" */
  staff_schedule_service_aggregate: Staff_Schedule_Service_Aggregate;
  /** fetch data from the table: "staff_schedule_service" using primary key columns */
  staff_schedule_service_by_pk?: Maybe<Staff_Schedule_Service>;
  /** fetch data from the table in a streaming manner: "staff_schedule_service" */
  staff_schedule_service_stream: Array<Staff_Schedule_Service>;
  /** fetch data from the table in a streaming manner: "staff_schedule" */
  staff_schedule_stream: Array<Staff_Schedule>;
  /** An array relationship */
  staff_services: Array<Staff_Services>;
  /** An aggregate relationship */
  staff_services_aggregate: Staff_Services_Aggregate;
  /** fetch data from the table: "staff_services" using primary key columns */
  staff_services_by_pk?: Maybe<Staff_Services>;
  /** fetch data from the table in a streaming manner: "staff_services" */
  staff_services_stream: Array<Staff_Services>;
  /** fetch data from the table in a streaming manner: "staff" */
  staff_stream: Array<Staff>;
  /** fetch data from the table: "staff_type" */
  staff_type: Array<Staff_Type>;
  /** fetch aggregated fields from the table: "staff_type" */
  staff_type_aggregate: Staff_Type_Aggregate;
  /** fetch data from the table: "staff_type" using primary key columns */
  staff_type_by_pk?: Maybe<Staff_Type>;
  /** fetch data from the table in a streaming manner: "staff_type" */
  staff_type_stream: Array<Staff_Type>;
  /** fetch data from the table: "symptom_checker" */
  symptom_checker: Array<Symptom_Checker>;
  /** fetch aggregated fields from the table: "symptom_checker" */
  symptom_checker_aggregate: Symptom_Checker_Aggregate;
  /** fetch data from the table: "symptom_checker" using primary key columns */
  symptom_checker_by_pk?: Maybe<Symptom_Checker>;
  /** fetch data from the table in a streaming manner: "symptom_checker" */
  symptom_checker_stream: Array<Symptom_Checker>;
  /** fetch data from the table: "task_priority" */
  task_priority: Array<Task_Priority>;
  /** fetch aggregated fields from the table: "task_priority" */
  task_priority_aggregate: Task_Priority_Aggregate;
  /** fetch data from the table: "task_priority" using primary key columns */
  task_priority_by_pk?: Maybe<Task_Priority>;
  /** fetch data from the table in a streaming manner: "task_priority" */
  task_priority_stream: Array<Task_Priority>;
  /** fetch data from the table: "task_status" */
  task_status: Array<Task_Status>;
  /** fetch aggregated fields from the table: "task_status" */
  task_status_aggregate: Task_Status_Aggregate;
  /** fetch data from the table: "task_status" using primary key columns */
  task_status_by_pk?: Maybe<Task_Status>;
  /** fetch data from the table in a streaming manner: "task_status" */
  task_status_stream: Array<Task_Status>;
  /** fetch data from the table: "task_sub_type" */
  task_sub_type: Array<Task_Sub_Type>;
  /** fetch aggregated fields from the table: "task_sub_type" */
  task_sub_type_aggregate: Task_Sub_Type_Aggregate;
  /** fetch data from the table: "task_sub_type" using primary key columns */
  task_sub_type_by_pk?: Maybe<Task_Sub_Type>;
  /** fetch data from the table in a streaming manner: "task_sub_type" */
  task_sub_type_stream: Array<Task_Sub_Type>;
  /** fetch data from the table: "task_type" */
  task_type: Array<Task_Type>;
  /** fetch aggregated fields from the table: "task_type" */
  task_type_aggregate: Task_Type_Aggregate;
  /** fetch data from the table: "task_type" using primary key columns */
  task_type_by_pk?: Maybe<Task_Type>;
  /** fetch data from the table in a streaming manner: "task_type" */
  task_type_stream: Array<Task_Type>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** An array relationship */
  tasks_assignments: Array<Tasks_Assignments>;
  /** An aggregate relationship */
  tasks_assignments_aggregate: Tasks_Assignments_Aggregate;
  /** fetch data from the table: "tasks_assignments" using primary key columns */
  tasks_assignments_by_pk?: Maybe<Tasks_Assignments>;
  /** fetch data from the table in a streaming manner: "tasks_assignments" */
  tasks_assignments_stream: Array<Tasks_Assignments>;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** An array relationship */
  tasks_files: Array<Tasks_Files>;
  /** An aggregate relationship */
  tasks_files_aggregate: Tasks_Files_Aggregate;
  /** fetch data from the table: "tasks_files" using primary key columns */
  tasks_files_by_pk?: Maybe<Tasks_Files>;
  /** fetch data from the table in a streaming manner: "tasks_files" */
  tasks_files_stream: Array<Tasks_Files>;
  /** fetch data from the table: "tasks_groups" */
  tasks_groups: Array<Tasks_Groups>;
  /** fetch aggregated fields from the table: "tasks_groups" */
  tasks_groups_aggregate: Tasks_Groups_Aggregate;
  /** fetch data from the table: "tasks_groups" using primary key columns */
  tasks_groups_by_pk?: Maybe<Tasks_Groups>;
  /** fetch data from the table: "tasks_groups_staff" */
  tasks_groups_staff: Array<Tasks_Groups_Staff>;
  /** fetch aggregated fields from the table: "tasks_groups_staff" */
  tasks_groups_staff_aggregate: Tasks_Groups_Staff_Aggregate;
  /** fetch data from the table: "tasks_groups_staff" using primary key columns */
  tasks_groups_staff_by_pk?: Maybe<Tasks_Groups_Staff>;
  /** fetch data from the table in a streaming manner: "tasks_groups_staff" */
  tasks_groups_staff_stream: Array<Tasks_Groups_Staff>;
  /** fetch data from the table in a streaming manner: "tasks_groups" */
  tasks_groups_stream: Array<Tasks_Groups>;
  /** An array relationship */
  tasks_notifications: Array<Tasks_Notifications>;
  /** An aggregate relationship */
  tasks_notifications_aggregate: Tasks_Notifications_Aggregate;
  /** fetch data from the table: "tasks_notifications" using primary key columns */
  tasks_notifications_by_pk?: Maybe<Tasks_Notifications>;
  /** fetch data from the table in a streaming manner: "tasks_notifications" */
  tasks_notifications_stream: Array<Tasks_Notifications>;
  /** fetch data from the table: "tasks_read" */
  tasks_read: Array<Tasks_Read>;
  /** fetch aggregated fields from the table: "tasks_read" */
  tasks_read_aggregate: Tasks_Read_Aggregate;
  /** fetch data from the table: "tasks_read" using primary key columns */
  tasks_read_by_pk?: Maybe<Tasks_Read>;
  /** fetch data from the table in a streaming manner: "tasks_read" */
  tasks_read_stream: Array<Tasks_Read>;
  /** fetch data from the table: "tasks_responsible" */
  tasks_responsible: Array<Tasks_Responsible>;
  /** fetch aggregated fields from the table: "tasks_responsible" */
  tasks_responsible_aggregate: Tasks_Responsible_Aggregate;
  /** fetch data from the table in a streaming manner: "tasks_responsible" */
  tasks_responsible_stream: Array<Tasks_Responsible>;
  /** fetch data from the table in a streaming manner: "tasks" */
  tasks_stream: Array<Tasks>;
  /** fetch data from the table: "tasks_watch" */
  tasks_watch: Array<Tasks_Watch>;
  /** fetch aggregated fields from the table: "tasks_watch" */
  tasks_watch_aggregate: Tasks_Watch_Aggregate;
  /** fetch data from the table: "tasks_watch" using primary key columns */
  tasks_watch_by_pk?: Maybe<Tasks_Watch>;
  /** fetch data from the table in a streaming manner: "tasks_watch" */
  tasks_watch_stream: Array<Tasks_Watch>;
  /** fetch data from the table: "triage_schedule" */
  triage_schedule: Array<Triage_Schedule>;
  /** fetch aggregated fields from the table: "triage_schedule" */
  triage_schedule_aggregate: Triage_Schedule_Aggregate;
  /** fetch data from the table in a streaming manner: "triage_schedule" */
  triage_schedule_stream: Array<Triage_Schedule>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "visit_classifications" */
  visit_classifications: Array<Visit_Classifications>;
  /** fetch aggregated fields from the table: "visit_classifications" */
  visit_classifications_aggregate: Visit_Classifications_Aggregate;
  /** fetch data from the table: "visit_classifications" using primary key columns */
  visit_classifications_by_pk?: Maybe<Visit_Classifications>;
  /** fetch data from the table in a streaming manner: "visit_classifications" */
  visit_classifications_stream: Array<Visit_Classifications>;
  /** fetch data from the table: "visit_types" */
  visit_types: Array<Visit_Types>;
  /** fetch aggregated fields from the table: "visit_types" */
  visit_types_aggregate: Visit_Types_Aggregate;
  /** fetch data from the table: "visit_types" using primary key columns */
  visit_types_by_pk?: Maybe<Visit_Types>;
  /** fetch data from the table in a streaming manner: "visit_types" */
  visit_types_stream: Array<Visit_Types>;
};

export type Subscription_RootAccount_PatientArgs = {
  distinct_on?: InputMaybe<Array<Account_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Patient_Order_By>>;
  where?: InputMaybe<Account_Patient_Bool_Exp>;
};

export type Subscription_RootAccount_Patient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Patient_Order_By>>;
  where?: InputMaybe<Account_Patient_Bool_Exp>;
};

export type Subscription_RootAccount_Patient_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAccount_Patient_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Account_Patient_Stream_Cursor_Input>>;
  where?: InputMaybe<Account_Patient_Bool_Exp>;
};

export type Subscription_RootAccount_Temporary_VerificationArgs = {
  distinct_on?: InputMaybe<Array<Account_Temporary_Verification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Temporary_Verification_Order_By>>;
  where?: InputMaybe<Account_Temporary_Verification_Bool_Exp>;
};

export type Subscription_RootAccount_Temporary_Verification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Temporary_Verification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Temporary_Verification_Order_By>>;
  where?: InputMaybe<Account_Temporary_Verification_Bool_Exp>;
};

export type Subscription_RootAccount_Temporary_Verification_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAccount_Temporary_Verification_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Account_Temporary_Verification_Stream_Cursor_Input>>;
  where?: InputMaybe<Account_Temporary_Verification_Bool_Exp>;
};

export type Subscription_RootAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

export type Subscription_RootAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

export type Subscription_RootAccounts_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAccounts_LimitedArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Limited_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Limited_Order_By>>;
  where?: InputMaybe<Accounts_Limited_Bool_Exp>;
};

export type Subscription_RootAccounts_Limited_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Limited_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Limited_Order_By>>;
  where?: InputMaybe<Accounts_Limited_Bool_Exp>;
};

export type Subscription_RootAccounts_Limited_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Accounts_Limited_Stream_Cursor_Input>>;
  where?: InputMaybe<Accounts_Limited_Bool_Exp>;
};

export type Subscription_RootAccounts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Accounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

export type Subscription_RootAllergiesArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Order_By>>;
  where?: InputMaybe<Allergies_Bool_Exp>;
};

export type Subscription_RootAllergies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Order_By>>;
  where?: InputMaybe<Allergies_Bool_Exp>;
};

export type Subscription_RootAllergies_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAllergies_SeverityArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Severity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Severity_Order_By>>;
  where?: InputMaybe<Allergies_Severity_Bool_Exp>;
};

export type Subscription_RootAllergies_Severity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Severity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Severity_Order_By>>;
  where?: InputMaybe<Allergies_Severity_Bool_Exp>;
};

export type Subscription_RootAllergies_Severity_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootAllergies_Severity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Allergies_Severity_Stream_Cursor_Input>>;
  where?: InputMaybe<Allergies_Severity_Bool_Exp>;
};

export type Subscription_RootAllergies_StatusArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Status_Order_By>>;
  where?: InputMaybe<Allergies_Status_Bool_Exp>;
};

export type Subscription_RootAllergies_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Allergies_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allergies_Status_Order_By>>;
  where?: InputMaybe<Allergies_Status_Bool_Exp>;
};

export type Subscription_RootAllergies_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootAllergies_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Allergies_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Allergies_Status_Bool_Exp>;
};

export type Subscription_RootAllergies_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Allergies_Stream_Cursor_Input>>;
  where?: InputMaybe<Allergies_Bool_Exp>;
};

export type Subscription_RootAppointment_BlocksArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Blocks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Blocks_Order_By>>;
  where?: InputMaybe<Appointment_Blocks_Bool_Exp>;
};

export type Subscription_RootAppointment_Blocks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Blocks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Blocks_Order_By>>;
  where?: InputMaybe<Appointment_Blocks_Bool_Exp>;
};

export type Subscription_RootAppointment_Blocks_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAppointment_Blocks_StaffArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Blocks_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Blocks_Staff_Order_By>>;
  where?: InputMaybe<Appointment_Blocks_Staff_Bool_Exp>;
};

export type Subscription_RootAppointment_Blocks_Staff_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Blocks_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Blocks_Staff_Order_By>>;
  where?: InputMaybe<Appointment_Blocks_Staff_Bool_Exp>;
};

export type Subscription_RootAppointment_Blocks_Staff_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootAppointment_Blocks_Staff_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Appointment_Blocks_Staff_Stream_Cursor_Input>>;
  where?: InputMaybe<Appointment_Blocks_Staff_Bool_Exp>;
};

export type Subscription_RootAppointment_Blocks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Appointment_Blocks_Stream_Cursor_Input>>;
  where?: InputMaybe<Appointment_Blocks_Bool_Exp>;
};

export type Subscription_RootAppointment_CountsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Counts_Order_By>>;
  where?: InputMaybe<Appointment_Counts_Bool_Exp>;
};

export type Subscription_RootAppointment_Counts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Counts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Counts_Order_By>>;
  where?: InputMaybe<Appointment_Counts_Bool_Exp>;
};

export type Subscription_RootAppointment_Counts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Appointment_Counts_Stream_Cursor_Input>>;
  where?: InputMaybe<Appointment_Counts_Bool_Exp>;
};

export type Subscription_RootAppointment_PatientArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Patient_Order_By>>;
  where?: InputMaybe<Appointment_Patient_Bool_Exp>;
};

export type Subscription_RootAppointment_Patient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Patient_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Patient_Order_By>>;
  where?: InputMaybe<Appointment_Patient_Bool_Exp>;
};

export type Subscription_RootAppointment_Patient_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAppointment_Patient_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Appointment_Patient_Stream_Cursor_Input>>;
  where?: InputMaybe<Appointment_Patient_Bool_Exp>;
};

export type Subscription_RootAppointment_PaymentArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Payment_Order_By>>;
  where?: InputMaybe<Appointment_Payment_Bool_Exp>;
};

export type Subscription_RootAppointment_Payment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Payment_Order_By>>;
  where?: InputMaybe<Appointment_Payment_Bool_Exp>;
};

export type Subscription_RootAppointment_Payment_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootAppointment_Payment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Appointment_Payment_Stream_Cursor_Input>>;
  where?: InputMaybe<Appointment_Payment_Bool_Exp>;
};

export type Subscription_RootAppointment_ReservationsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Reservations_Order_By>>;
  where?: InputMaybe<Appointment_Reservations_Bool_Exp>;
};

export type Subscription_RootAppointment_Reservations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Reservations_Order_By>>;
  where?: InputMaybe<Appointment_Reservations_Bool_Exp>;
};

export type Subscription_RootAppointment_Reservations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAppointment_Reservations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Appointment_Reservations_Stream_Cursor_Input>>;
  where?: InputMaybe<Appointment_Reservations_Bool_Exp>;
};

export type Subscription_RootAppointment_StaffArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Staff_Order_By>>;
  where?: InputMaybe<Appointment_Staff_Bool_Exp>;
};

export type Subscription_RootAppointment_Staff_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Staff_Order_By>>;
  where?: InputMaybe<Appointment_Staff_Bool_Exp>;
};

export type Subscription_RootAppointment_Staff_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAppointment_Staff_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Appointment_Staff_Stream_Cursor_Input>>;
  where?: InputMaybe<Appointment_Staff_Bool_Exp>;
};

export type Subscription_RootAppointment_StatusArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Status_Order_By>>;
  where?: InputMaybe<Appointment_Status_Bool_Exp>;
};

export type Subscription_RootAppointment_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Status_Order_By>>;
  where?: InputMaybe<Appointment_Status_Bool_Exp>;
};

export type Subscription_RootAppointment_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootAppointment_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Appointment_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Appointment_Status_Bool_Exp>;
};

export type Subscription_RootAppointment_TypesArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Types_Order_By>>;
  where?: InputMaybe<Appointment_Types_Bool_Exp>;
};

export type Subscription_RootAppointment_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Types_Order_By>>;
  where?: InputMaybe<Appointment_Types_Bool_Exp>;
};

export type Subscription_RootAppointment_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootAppointment_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Appointment_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Appointment_Types_Bool_Exp>;
};

export type Subscription_RootAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

export type Subscription_RootAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

export type Subscription_RootAppointments_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAppointments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Appointments_Stream_Cursor_Input>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

export type Subscription_RootAudit_LogsArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Logs_Order_By>>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

export type Subscription_RootAudit_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Logs_Order_By>>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

export type Subscription_RootAudit_Logs_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootAudit_Logs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Audit_Logs_Stream_Cursor_Input>>;
  where?: InputMaybe<Audit_Logs_Bool_Exp>;
};

export type Subscription_RootCare_Type_DurationArgs = {
  distinct_on?: InputMaybe<Array<Care_Type_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Care_Type_Duration_Order_By>>;
  where?: InputMaybe<Care_Type_Duration_Bool_Exp>;
};

export type Subscription_RootCare_Type_Duration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Care_Type_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Care_Type_Duration_Order_By>>;
  where?: InputMaybe<Care_Type_Duration_Bool_Exp>;
};

export type Subscription_RootCare_Type_Duration_By_PkArgs = {
  careType: Care_Types_Enum;
  clinicId: Scalars['uuid'];
};

export type Subscription_RootCare_Type_Duration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Care_Type_Duration_Stream_Cursor_Input>>;
  where?: InputMaybe<Care_Type_Duration_Bool_Exp>;
};

export type Subscription_RootCare_TypesArgs = {
  distinct_on?: InputMaybe<Array<Care_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Care_Types_Order_By>>;
  where?: InputMaybe<Care_Types_Bool_Exp>;
};

export type Subscription_RootCare_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Care_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Care_Types_Order_By>>;
  where?: InputMaybe<Care_Types_Bool_Exp>;
};

export type Subscription_RootCare_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCare_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Care_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Care_Types_Bool_Exp>;
};

export type Subscription_RootCharge_ProductsArgs = {
  distinct_on?: InputMaybe<Array<Charge_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charge_Products_Order_By>>;
  where?: InputMaybe<Charge_Products_Bool_Exp>;
};

export type Subscription_RootCharge_Products_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Charge_Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charge_Products_Order_By>>;
  where?: InputMaybe<Charge_Products_Bool_Exp>;
};

export type Subscription_RootCharge_Products_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCharge_Products_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Charge_Products_Stream_Cursor_Input>>;
  where?: InputMaybe<Charge_Products_Bool_Exp>;
};

export type Subscription_RootChargesArgs = {
  distinct_on?: InputMaybe<Array<Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charges_Order_By>>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

export type Subscription_RootCharges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Charges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Charges_Order_By>>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

export type Subscription_RootCharges_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCharges_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Charges_Stream_Cursor_Input>>;
  where?: InputMaybe<Charges_Bool_Exp>;
};

export type Subscription_RootChat_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_Order_By>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};

export type Subscription_RootChat_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_Order_By>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};

export type Subscription_RootChat_Messages_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootChat_Messages_FileArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_File_Order_By>>;
  where?: InputMaybe<Chat_Messages_File_Bool_Exp>;
};

export type Subscription_RootChat_Messages_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_File_Order_By>>;
  where?: InputMaybe<Chat_Messages_File_Bool_Exp>;
};

export type Subscription_RootChat_Messages_File_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootChat_Messages_File_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Messages_File_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Messages_File_Bool_Exp>;
};

export type Subscription_RootChat_Messages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Messages_Bool_Exp>;
};

export type Subscription_RootChat_Messages_TypeArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_Type_Order_By>>;
  where?: InputMaybe<Chat_Messages_Type_Bool_Exp>;
};

export type Subscription_RootChat_Messages_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Messages_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Messages_Type_Order_By>>;
  where?: InputMaybe<Chat_Messages_Type_Bool_Exp>;
};

export type Subscription_RootChat_Messages_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootChat_Messages_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Messages_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Messages_Type_Bool_Exp>;
};

export type Subscription_RootChat_NotesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Notes_Order_By>>;
  where?: InputMaybe<Chat_Notes_Bool_Exp>;
};

export type Subscription_RootChat_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Notes_Order_By>>;
  where?: InputMaybe<Chat_Notes_Bool_Exp>;
};

export type Subscription_RootChat_Notes_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootChat_Notes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Notes_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Notes_Bool_Exp>;
};

export type Subscription_RootChat_Notes_TypesArgs = {
  distinct_on?: InputMaybe<Array<Chat_Notes_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Notes_Types_Order_By>>;
  where?: InputMaybe<Chat_Notes_Types_Bool_Exp>;
};

export type Subscription_RootChat_Notes_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Notes_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Notes_Types_Order_By>>;
  where?: InputMaybe<Chat_Notes_Types_Bool_Exp>;
};

export type Subscription_RootChat_Notes_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootChat_Notes_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Notes_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Notes_Types_Bool_Exp>;
};

export type Subscription_RootChat_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Bool_Exp>;
};

export type Subscription_RootChat_Sessions_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Accounts_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Accounts_Bool_Exp>;
};

export type Subscription_RootChat_Sessions_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Accounts_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Accounts_Bool_Exp>;
};

export type Subscription_RootChat_Sessions_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootChat_Sessions_Accounts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Sessions_Accounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Sessions_Accounts_Bool_Exp>;
};

export type Subscription_RootChat_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Bool_Exp>;
};

export type Subscription_RootChat_Sessions_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootChat_Sessions_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Patients_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Patients_Bool_Exp>;
};

export type Subscription_RootChat_Sessions_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Patients_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Patients_Bool_Exp>;
};

export type Subscription_RootChat_Sessions_Patients_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootChat_Sessions_Patients_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Sessions_Patients_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Sessions_Patients_Bool_Exp>;
};

export type Subscription_RootChat_Sessions_ReadArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Read_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Read_Bool_Exp>;
};

export type Subscription_RootChat_Sessions_Read_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Read_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Read_Bool_Exp>;
};

export type Subscription_RootChat_Sessions_Read_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootChat_Sessions_Read_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Sessions_Read_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Sessions_Read_Bool_Exp>;
};

export type Subscription_RootChat_Sessions_StaffArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Staff_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Staff_Bool_Exp>;
};

export type Subscription_RootChat_Sessions_Staff_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Staff_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Staff_Bool_Exp>;
};

export type Subscription_RootChat_Sessions_Staff_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootChat_Sessions_Staff_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Sessions_Staff_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Sessions_Staff_Bool_Exp>;
};

export type Subscription_RootChat_Sessions_StatusArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Status_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Status_Bool_Exp>;
};

export type Subscription_RootChat_Sessions_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chat_Sessions_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Chat_Sessions_Status_Order_By>>;
  where?: InputMaybe<Chat_Sessions_Status_Bool_Exp>;
};

export type Subscription_RootChat_Sessions_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootChat_Sessions_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Sessions_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Sessions_Status_Bool_Exp>;
};

export type Subscription_RootChat_Sessions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Chat_Sessions_Stream_Cursor_Input>>;
  where?: InputMaybe<Chat_Sessions_Bool_Exp>;
};

export type Subscription_RootClinic_Location_TypesArgs = {
  distinct_on?: InputMaybe<Array<Clinic_Location_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinic_Location_Types_Order_By>>;
  where?: InputMaybe<Clinic_Location_Types_Bool_Exp>;
};

export type Subscription_RootClinic_Location_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinic_Location_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinic_Location_Types_Order_By>>;
  where?: InputMaybe<Clinic_Location_Types_Bool_Exp>;
};

export type Subscription_RootClinic_Location_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootClinic_Location_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Clinic_Location_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Clinic_Location_Types_Bool_Exp>;
};

export type Subscription_RootClinic_StatusArgs = {
  distinct_on?: InputMaybe<Array<Clinic_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinic_Status_Order_By>>;
  where?: InputMaybe<Clinic_Status_Bool_Exp>;
};

export type Subscription_RootClinic_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinic_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinic_Status_Order_By>>;
  where?: InputMaybe<Clinic_Status_Bool_Exp>;
};

export type Subscription_RootClinic_Status_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootClinic_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Clinic_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Clinic_Status_Bool_Exp>;
};

export type Subscription_RootClinicsArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Order_By>>;
  where?: InputMaybe<Clinics_Bool_Exp>;
};

export type Subscription_RootClinics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Order_By>>;
  where?: InputMaybe<Clinics_Bool_Exp>;
};

export type Subscription_RootClinics_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClinics_PropertiesArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Properties_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Properties_Order_By>>;
  where?: InputMaybe<Clinics_Properties_Bool_Exp>;
};

export type Subscription_RootClinics_Properties_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Properties_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Properties_Order_By>>;
  where?: InputMaybe<Clinics_Properties_Bool_Exp>;
};

export type Subscription_RootClinics_Properties_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClinics_Properties_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Clinics_Properties_Stream_Cursor_Input>>;
  where?: InputMaybe<Clinics_Properties_Bool_Exp>;
};

export type Subscription_RootClinics_Properties_TypesArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Properties_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Properties_Types_Order_By>>;
  where?: InputMaybe<Clinics_Properties_Types_Bool_Exp>;
};

export type Subscription_RootClinics_Properties_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Properties_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Properties_Types_Order_By>>;
  where?: InputMaybe<Clinics_Properties_Types_Bool_Exp>;
};

export type Subscription_RootClinics_Properties_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootClinics_Properties_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Clinics_Properties_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Clinics_Properties_Types_Bool_Exp>;
};

export type Subscription_RootClinics_RegionsArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Regions_Order_By>>;
  where?: InputMaybe<Clinics_Regions_Bool_Exp>;
};

export type Subscription_RootClinics_Regions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinics_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinics_Regions_Order_By>>;
  where?: InputMaybe<Clinics_Regions_Bool_Exp>;
};

export type Subscription_RootClinics_Regions_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootClinics_Regions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Clinics_Regions_Stream_Cursor_Input>>;
  where?: InputMaybe<Clinics_Regions_Bool_Exp>;
};

export type Subscription_RootClinics_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Clinics_Stream_Cursor_Input>>;
  where?: InputMaybe<Clinics_Bool_Exp>;
};

export type Subscription_RootCommunication_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Order_By>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

export type Subscription_RootCommunication_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Order_By>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

export type Subscription_RootCommunication_History_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCommunication_History_DirectionsArgs = {
  distinct_on?: InputMaybe<
    Array<Communication_History_Directions_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Directions_Order_By>>;
  where?: InputMaybe<Communication_History_Directions_Bool_Exp>;
};

export type Subscription_RootCommunication_History_Directions_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Communication_History_Directions_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Directions_Order_By>>;
  where?: InputMaybe<Communication_History_Directions_Bool_Exp>;
};

export type Subscription_RootCommunication_History_Directions_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootCommunication_History_Directions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Communication_History_Directions_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Communication_History_Directions_Bool_Exp>;
};

export type Subscription_RootCommunication_History_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Communication_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
};

export type Subscription_RootCommunication_History_Tag_TypesArgs = {
  distinct_on?: InputMaybe<
    Array<Communication_History_Tag_Types_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Tag_Types_Order_By>>;
  where?: InputMaybe<Communication_History_Tag_Types_Bool_Exp>;
};

export type Subscription_RootCommunication_History_Tag_Types_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Communication_History_Tag_Types_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Tag_Types_Order_By>>;
  where?: InputMaybe<Communication_History_Tag_Types_Bool_Exp>;
};

export type Subscription_RootCommunication_History_Tag_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootCommunication_History_Tag_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Communication_History_Tag_Types_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Communication_History_Tag_Types_Bool_Exp>;
};

export type Subscription_RootCommunication_History_TagsArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Tags_Order_By>>;
  where?: InputMaybe<Communication_History_Tags_Bool_Exp>;
};

export type Subscription_RootCommunication_History_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Tags_Order_By>>;
  where?: InputMaybe<Communication_History_Tags_Bool_Exp>;
};

export type Subscription_RootCommunication_History_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCommunication_History_Tags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Communication_History_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Communication_History_Tags_Bool_Exp>;
};

export type Subscription_RootCommunication_History_TypesArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Types_Order_By>>;
  where?: InputMaybe<Communication_History_Types_Bool_Exp>;
};

export type Subscription_RootCommunication_History_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Communication_History_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Communication_History_Types_Order_By>>;
  where?: InputMaybe<Communication_History_Types_Bool_Exp>;
};

export type Subscription_RootCommunication_History_Types_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootCommunication_History_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Communication_History_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Communication_History_Types_Bool_Exp>;
};

export type Subscription_RootDevicesArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};

export type Subscription_RootDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};

export type Subscription_RootDevices_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDevices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Devices_Stream_Cursor_Input>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};

export type Subscription_RootDiscovered_FromArgs = {
  distinct_on?: InputMaybe<Array<Discovered_From_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Discovered_From_Order_By>>;
  where?: InputMaybe<Discovered_From_Bool_Exp>;
};

export type Subscription_RootDiscovered_From_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Discovered_From_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Discovered_From_Order_By>>;
  where?: InputMaybe<Discovered_From_Bool_Exp>;
};

export type Subscription_RootDiscovered_From_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDiscovered_From_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Discovered_From_Stream_Cursor_Input>>;
  where?: InputMaybe<Discovered_From_Bool_Exp>;
};

export type Subscription_RootDocument_Status_TypesArgs = {
  distinct_on?: InputMaybe<Array<Document_Status_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Status_Types_Order_By>>;
  where?: InputMaybe<Document_Status_Types_Bool_Exp>;
};

export type Subscription_RootDocument_Status_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Status_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Status_Types_Order_By>>;
  where?: InputMaybe<Document_Status_Types_Bool_Exp>;
};

export type Subscription_RootDocument_Status_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootDocument_Status_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Document_Status_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_Status_Types_Bool_Exp>;
};

export type Subscription_RootDocument_TypesArgs = {
  distinct_on?: InputMaybe<Array<Document_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Types_Order_By>>;
  where?: InputMaybe<Document_Types_Bool_Exp>;
};

export type Subscription_RootDocument_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Types_Order_By>>;
  where?: InputMaybe<Document_Types_Bool_Exp>;
};

export type Subscription_RootDocument_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootDocument_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Document_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_Types_Bool_Exp>;
};

export type Subscription_RootDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

export type Subscription_RootDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

export type Subscription_RootDocuments_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDocuments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

export type Subscription_RootFhir_Resource_TypesArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resource_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resource_Types_Order_By>>;
  where?: InputMaybe<Fhir_Resource_Types_Bool_Exp>;
};

export type Subscription_RootFhir_Resource_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resource_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resource_Types_Order_By>>;
  where?: InputMaybe<Fhir_Resource_Types_Bool_Exp>;
};

export type Subscription_RootFhir_Resource_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootFhir_Resource_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Fhir_Resource_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Fhir_Resource_Types_Bool_Exp>;
};

export type Subscription_RootFhir_ResourcesArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_Order_By>>;
  where?: InputMaybe<Fhir_Resources_Bool_Exp>;
};

export type Subscription_RootFhir_Resources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_Order_By>>;
  where?: InputMaybe<Fhir_Resources_Bool_Exp>;
};

export type Subscription_RootFhir_Resources_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootFhir_Resources_CodedArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_Coded_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_Coded_Order_By>>;
  where?: InputMaybe<Fhir_Resources_Coded_Bool_Exp>;
};

export type Subscription_RootFhir_Resources_Coded_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_Coded_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_Coded_Order_By>>;
  where?: InputMaybe<Fhir_Resources_Coded_Bool_Exp>;
};

export type Subscription_RootFhir_Resources_Coded_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Fhir_Resources_Coded_Stream_Cursor_Input>>;
  where?: InputMaybe<Fhir_Resources_Coded_Bool_Exp>;
};

export type Subscription_RootFhir_Resources_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_History_Order_By>>;
  where?: InputMaybe<Fhir_Resources_History_Bool_Exp>;
};

export type Subscription_RootFhir_Resources_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Fhir_Resources_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Fhir_Resources_History_Order_By>>;
  where?: InputMaybe<Fhir_Resources_History_Bool_Exp>;
};

export type Subscription_RootFhir_Resources_History_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootFhir_Resources_History_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Fhir_Resources_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Fhir_Resources_History_Bool_Exp>;
};

export type Subscription_RootFhir_Resources_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Fhir_Resources_Stream_Cursor_Input>>;
  where?: InputMaybe<Fhir_Resources_Bool_Exp>;
};

export type Subscription_RootFile_Document_TypeArgs = {
  distinct_on?: InputMaybe<Array<File_Document_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Document_Type_Order_By>>;
  where?: InputMaybe<File_Document_Type_Bool_Exp>;
};

export type Subscription_RootFile_Document_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Document_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Document_Type_Order_By>>;
  where?: InputMaybe<File_Document_Type_Bool_Exp>;
};

export type Subscription_RootFile_Document_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootFile_Document_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<File_Document_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<File_Document_Type_Bool_Exp>;
};

export type Subscription_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

export type Subscription_RootFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

export type Subscription_RootFiles_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootFiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

export type Subscription_RootGlobal_NotesArgs = {
  distinct_on?: InputMaybe<Array<Global_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Notes_Order_By>>;
  where?: InputMaybe<Global_Notes_Bool_Exp>;
};

export type Subscription_RootGlobal_Notes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Global_Notes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Global_Notes_Order_By>>;
  where?: InputMaybe<Global_Notes_Bool_Exp>;
};

export type Subscription_RootGlobal_Notes_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootGlobal_Notes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Global_Notes_Stream_Cursor_Input>>;
  where?: InputMaybe<Global_Notes_Bool_Exp>;
};

export type Subscription_RootInsuranceArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Order_By>>;
  where?: InputMaybe<Insurance_Bool_Exp>;
};

export type Subscription_RootInsurance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Order_By>>;
  where?: InputMaybe<Insurance_Bool_Exp>;
};

export type Subscription_RootInsurance_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootInsurance_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Providers_Order_By>>;
  where?: InputMaybe<Insurance_Providers_Bool_Exp>;
};

export type Subscription_RootInsurance_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Providers_Order_By>>;
  where?: InputMaybe<Insurance_Providers_Bool_Exp>;
};

export type Subscription_RootInsurance_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootInsurance_Providers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Insurance_Providers_Stream_Cursor_Input>>;
  where?: InputMaybe<Insurance_Providers_Bool_Exp>;
};

export type Subscription_RootInsurance_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Insurance_Stream_Cursor_Input>>;
  where?: InputMaybe<Insurance_Bool_Exp>;
};

export type Subscription_RootInsurance_TypesArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Types_Order_By>>;
  where?: InputMaybe<Insurance_Types_Bool_Exp>;
};

export type Subscription_RootInsurance_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Types_Order_By>>;
  where?: InputMaybe<Insurance_Types_Bool_Exp>;
};

export type Subscription_RootInsurance_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootInsurance_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Insurance_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Insurance_Types_Bool_Exp>;
};

export type Subscription_RootKareo_LogsArgs = {
  distinct_on?: InputMaybe<Array<Kareo_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kareo_Logs_Order_By>>;
  where?: InputMaybe<Kareo_Logs_Bool_Exp>;
};

export type Subscription_RootKareo_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Kareo_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kareo_Logs_Order_By>>;
  where?: InputMaybe<Kareo_Logs_Bool_Exp>;
};

export type Subscription_RootKareo_Logs_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootKareo_Logs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Kareo_Logs_Stream_Cursor_Input>>;
  where?: InputMaybe<Kareo_Logs_Bool_Exp>;
};

export type Subscription_RootLocationsArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

export type Subscription_RootLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

export type Subscription_RootLocations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootLocations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Locations_Stream_Cursor_Input>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

export type Subscription_RootMedical_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Order_By>>;
  where?: InputMaybe<Medical_History_Bool_Exp>;
};

export type Subscription_RootMedical_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Order_By>>;
  where?: InputMaybe<Medical_History_Bool_Exp>;
};

export type Subscription_RootMedical_History_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Answers_Order_By>>;
  where?: InputMaybe<Medical_History_Answers_Bool_Exp>;
};

export type Subscription_RootMedical_History_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Answers_Order_By>>;
  where?: InputMaybe<Medical_History_Answers_Bool_Exp>;
};

export type Subscription_RootMedical_History_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootMedical_History_Answers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Medical_History_Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Medical_History_Answers_Bool_Exp>;
};

export type Subscription_RootMedical_History_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootMedical_History_Person_TypesArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Person_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Person_Types_Order_By>>;
  where?: InputMaybe<Medical_History_Person_Types_Bool_Exp>;
};

export type Subscription_RootMedical_History_Person_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Person_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Person_Types_Order_By>>;
  where?: InputMaybe<Medical_History_Person_Types_Bool_Exp>;
};

export type Subscription_RootMedical_History_Person_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootMedical_History_Person_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Medical_History_Person_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Medical_History_Person_Types_Bool_Exp>;
};

export type Subscription_RootMedical_History_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Questions_Order_By>>;
  where?: InputMaybe<Medical_History_Questions_Bool_Exp>;
};

export type Subscription_RootMedical_History_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Questions_Order_By>>;
  where?: InputMaybe<Medical_History_Questions_Bool_Exp>;
};

export type Subscription_RootMedical_History_Questions_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootMedical_History_Questions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Medical_History_Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Medical_History_Questions_Bool_Exp>;
};

export type Subscription_RootMedical_History_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Medical_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Medical_History_Bool_Exp>;
};

export type Subscription_RootMedical_History_TypesArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Types_Order_By>>;
  where?: InputMaybe<Medical_History_Types_Bool_Exp>;
};

export type Subscription_RootMedical_History_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Medical_History_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Medical_History_Types_Order_By>>;
  where?: InputMaybe<Medical_History_Types_Bool_Exp>;
};

export type Subscription_RootMedical_History_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootMedical_History_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Medical_History_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Medical_History_Types_Bool_Exp>;
};

export type Subscription_RootPatient_AddressArgs = {
  distinct_on?: InputMaybe<Array<Patient_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Address_Order_By>>;
  where?: InputMaybe<Patient_Address_Bool_Exp>;
};

export type Subscription_RootPatient_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Address_Order_By>>;
  where?: InputMaybe<Patient_Address_Bool_Exp>;
};

export type Subscription_RootPatient_Address_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patient_Address_Stream_Cursor_Input>>;
  where?: InputMaybe<Patient_Address_Bool_Exp>;
};

export type Subscription_RootPatient_DuplicatesArgs = {
  distinct_on?: InputMaybe<Array<Patient_Duplicates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Duplicates_Order_By>>;
  where?: InputMaybe<Patient_Duplicates_Bool_Exp>;
};

export type Subscription_RootPatient_Duplicates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Duplicates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Duplicates_Order_By>>;
  where?: InputMaybe<Patient_Duplicates_Bool_Exp>;
};

export type Subscription_RootPatient_Duplicates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patient_Duplicates_Stream_Cursor_Input>>;
  where?: InputMaybe<Patient_Duplicates_Bool_Exp>;
};

export type Subscription_RootPatient_MigrationsArgs = {
  distinct_on?: InputMaybe<Array<Patient_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Migrations_Order_By>>;
  where?: InputMaybe<Patient_Migrations_Bool_Exp>;
};

export type Subscription_RootPatient_Migrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Migrations_Order_By>>;
  where?: InputMaybe<Patient_Migrations_Bool_Exp>;
};

export type Subscription_RootPatient_Migrations_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootPatient_Migrations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patient_Migrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Patient_Migrations_Bool_Exp>;
};

export type Subscription_RootPatient_Not_DuplicatesArgs = {
  distinct_on?: InputMaybe<Array<Patient_Not_Duplicates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Not_Duplicates_Order_By>>;
  where?: InputMaybe<Patient_Not_Duplicates_Bool_Exp>;
};

export type Subscription_RootPatient_Not_Duplicates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Not_Duplicates_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Not_Duplicates_Order_By>>;
  where?: InputMaybe<Patient_Not_Duplicates_Bool_Exp>;
};

export type Subscription_RootPatient_Not_Duplicates_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPatient_Not_Duplicates_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patient_Not_Duplicates_Stream_Cursor_Input>>;
  where?: InputMaybe<Patient_Not_Duplicates_Bool_Exp>;
};

export type Subscription_RootPatient_Record_Status_TypesArgs = {
  distinct_on?: InputMaybe<Array<Patient_Record_Status_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Record_Status_Types_Order_By>>;
  where?: InputMaybe<Patient_Record_Status_Types_Bool_Exp>;
};

export type Subscription_RootPatient_Record_Status_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Record_Status_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Record_Status_Types_Order_By>>;
  where?: InputMaybe<Patient_Record_Status_Types_Bool_Exp>;
};

export type Subscription_RootPatient_Record_Status_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootPatient_Record_Status_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patient_Record_Status_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Patient_Record_Status_Types_Bool_Exp>;
};

export type Subscription_RootPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

export type Subscription_RootPatients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

export type Subscription_RootPatients_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPatients_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patients_Stream_Cursor_Input>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

export type Subscription_RootPayment_TypeArgs = {
  distinct_on?: InputMaybe<Array<Payment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Type_Order_By>>;
  where?: InputMaybe<Payment_Type_Bool_Exp>;
};

export type Subscription_RootPayment_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Type_Order_By>>;
  where?: InputMaybe<Payment_Type_Bool_Exp>;
};

export type Subscription_RootPayment_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootPayment_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Type_Bool_Exp>;
};

export type Subscription_RootPhone_NumbersArgs = {
  distinct_on?: InputMaybe<Array<Phone_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phone_Numbers_Order_By>>;
  where?: InputMaybe<Phone_Numbers_Bool_Exp>;
};

export type Subscription_RootPhone_Numbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Phone_Numbers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Phone_Numbers_Order_By>>;
  where?: InputMaybe<Phone_Numbers_Bool_Exp>;
};

export type Subscription_RootPhone_Numbers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPhone_Numbers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Phone_Numbers_Stream_Cursor_Input>>;
  where?: InputMaybe<Phone_Numbers_Bool_Exp>;
};

export type Subscription_RootProblem_Status_TypesArgs = {
  distinct_on?: InputMaybe<Array<Problem_Status_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Problem_Status_Types_Order_By>>;
  where?: InputMaybe<Problem_Status_Types_Bool_Exp>;
};

export type Subscription_RootProblem_Status_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Problem_Status_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Problem_Status_Types_Order_By>>;
  where?: InputMaybe<Problem_Status_Types_Bool_Exp>;
};

export type Subscription_RootProblem_Status_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootProblem_Status_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Problem_Status_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Problem_Status_Types_Bool_Exp>;
};

export type Subscription_RootProblemsArgs = {
  distinct_on?: InputMaybe<Array<Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Problems_Order_By>>;
  where?: InputMaybe<Problems_Bool_Exp>;
};

export type Subscription_RootProblems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Problems_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Problems_Order_By>>;
  where?: InputMaybe<Problems_Bool_Exp>;
};

export type Subscription_RootProblems_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProblems_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Problems_Stream_Cursor_Input>>;
  where?: InputMaybe<Problems_Bool_Exp>;
};

export type Subscription_RootProductsArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};

export type Subscription_RootProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Products_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Products_Order_By>>;
  where?: InputMaybe<Products_Bool_Exp>;
};

export type Subscription_RootProducts_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProducts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Products_Stream_Cursor_Input>>;
  where?: InputMaybe<Products_Bool_Exp>;
};

export type Subscription_RootReconciliation_TypeArgs = {
  distinct_on?: InputMaybe<Array<Reconciliation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reconciliation_Type_Order_By>>;
  where?: InputMaybe<Reconciliation_Type_Bool_Exp>;
};

export type Subscription_RootReconciliation_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reconciliation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reconciliation_Type_Order_By>>;
  where?: InputMaybe<Reconciliation_Type_Bool_Exp>;
};

export type Subscription_RootReconciliation_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootReconciliation_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reconciliation_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Reconciliation_Type_Bool_Exp>;
};

export type Subscription_RootReconciliationsArgs = {
  distinct_on?: InputMaybe<Array<Reconciliations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reconciliations_Order_By>>;
  where?: InputMaybe<Reconciliations_Bool_Exp>;
};

export type Subscription_RootReconciliations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reconciliations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reconciliations_Order_By>>;
  where?: InputMaybe<Reconciliations_Bool_Exp>;
};

export type Subscription_RootReconciliations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootReconciliations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reconciliations_Stream_Cursor_Input>>;
  where?: InputMaybe<Reconciliations_Bool_Exp>;
};

export type Subscription_RootRegionsArgs = {
  distinct_on?: InputMaybe<Array<Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regions_Order_By>>;
  where?: InputMaybe<Regions_Bool_Exp>;
};

export type Subscription_RootRegions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regions_Order_By>>;
  where?: InputMaybe<Regions_Bool_Exp>;
};

export type Subscription_RootRegions_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootRegions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Regions_Stream_Cursor_Input>>;
  where?: InputMaybe<Regions_Bool_Exp>;
};

export type Subscription_RootReservationsArgs = {
  distinct_on?: InputMaybe<Array<Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservations_Order_By>>;
  where?: InputMaybe<Reservations_Bool_Exp>;
};

export type Subscription_RootReservations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reservations_Order_By>>;
  where?: InputMaybe<Reservations_Bool_Exp>;
};

export type Subscription_RootReservations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reservations_Stream_Cursor_Input>>;
  where?: InputMaybe<Reservations_Bool_Exp>;
};

export type Subscription_RootReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

export type Subscription_RootReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

export type Subscription_RootReviews_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootReviews_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reviews_Stream_Cursor_Input>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

export type Subscription_RootRoomsArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};

export type Subscription_RootRooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};

export type Subscription_RootRooms_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootRooms_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rooms_Stream_Cursor_Input>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};

export type Subscription_RootScreening_Category_TypesArgs = {
  distinct_on?: InputMaybe<Array<Screening_Category_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screening_Category_Types_Order_By>>;
  where?: InputMaybe<Screening_Category_Types_Bool_Exp>;
};

export type Subscription_RootScreening_Category_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screening_Category_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screening_Category_Types_Order_By>>;
  where?: InputMaybe<Screening_Category_Types_Bool_Exp>;
};

export type Subscription_RootScreening_Category_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootScreening_Category_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Screening_Category_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Screening_Category_Types_Bool_Exp>;
};

export type Subscription_RootScreening_TypesArgs = {
  distinct_on?: InputMaybe<Array<Screening_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screening_Types_Order_By>>;
  where?: InputMaybe<Screening_Types_Bool_Exp>;
};

export type Subscription_RootScreening_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screening_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screening_Types_Order_By>>;
  where?: InputMaybe<Screening_Types_Bool_Exp>;
};

export type Subscription_RootScreening_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootScreening_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Screening_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Screening_Types_Bool_Exp>;
};

export type Subscription_RootScreeningsArgs = {
  distinct_on?: InputMaybe<Array<Screenings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screenings_Order_By>>;
  where?: InputMaybe<Screenings_Bool_Exp>;
};

export type Subscription_RootScreenings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screenings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screenings_Order_By>>;
  where?: InputMaybe<Screenings_Bool_Exp>;
};

export type Subscription_RootScreenings_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootScreenings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Screenings_Stream_Cursor_Input>>;
  where?: InputMaybe<Screenings_Bool_Exp>;
};

export type Subscription_RootSearch_AccountsArgs = {
  args: Search_Accounts_Args;
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

export type Subscription_RootSearch_Accounts_AggregateArgs = {
  args: Search_Accounts_Args;
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

export type Subscription_RootSearch_PatientsArgs = {
  args: Search_Patients_Args;
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

export type Subscription_RootSearch_Patients_AggregateArgs = {
  args: Search_Patients_Args;
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

export type Subscription_RootSearch_Snomed_SynonymsArgs = {
  args: Search_Snomed_Synonyms_Args;
  distinct_on?: InputMaybe<Array<Snomed_Synonyms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Snomed_Synonyms_Order_By>>;
  where?: InputMaybe<Snomed_Synonyms_Bool_Exp>;
};

export type Subscription_RootSearch_Snomed_Synonyms_AggregateArgs = {
  args: Search_Snomed_Synonyms_Args;
  distinct_on?: InputMaybe<Array<Snomed_Synonyms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Snomed_Synonyms_Order_By>>;
  where?: InputMaybe<Snomed_Synonyms_Bool_Exp>;
};

export type Subscription_RootSearch_StaffArgs = {
  args: Search_Staff_Args;
  distinct_on?: InputMaybe<Array<Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Order_By>>;
  where?: InputMaybe<Staff_Bool_Exp>;
};

export type Subscription_RootSearch_Staff_AggregateArgs = {
  args: Search_Staff_Args;
  distinct_on?: InputMaybe<Array<Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Order_By>>;
  where?: InputMaybe<Staff_Bool_Exp>;
};

export type Subscription_RootSearch_TasksArgs = {
  args: Search_Tasks_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootSearch_Tasks_AggregateArgs = {
  args: Search_Tasks_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootServicesArgs = {
  distinct_on?: InputMaybe<Array<Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Order_By>>;
  where?: InputMaybe<Services_Bool_Exp>;
};

export type Subscription_RootServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Order_By>>;
  where?: InputMaybe<Services_Bool_Exp>;
};

export type Subscription_RootServices_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootServices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Services_Stream_Cursor_Input>>;
  where?: InputMaybe<Services_Bool_Exp>;
};

export type Subscription_RootSnomed_SynonymsArgs = {
  distinct_on?: InputMaybe<Array<Snomed_Synonyms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Snomed_Synonyms_Order_By>>;
  where?: InputMaybe<Snomed_Synonyms_Bool_Exp>;
};

export type Subscription_RootSnomed_Synonyms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Snomed_Synonyms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Snomed_Synonyms_Order_By>>;
  where?: InputMaybe<Snomed_Synonyms_Bool_Exp>;
};

export type Subscription_RootSnomed_Synonyms_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSnomed_Synonyms_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Snomed_Synonyms_Stream_Cursor_Input>>;
  where?: InputMaybe<Snomed_Synonyms_Bool_Exp>;
};

export type Subscription_RootStaffArgs = {
  distinct_on?: InputMaybe<Array<Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Order_By>>;
  where?: InputMaybe<Staff_Bool_Exp>;
};

export type Subscription_RootStaff_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Order_By>>;
  where?: InputMaybe<Staff_Bool_Exp>;
};

export type Subscription_RootStaff_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootStaff_RegionsArgs = {
  distinct_on?: InputMaybe<Array<Staff_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Regions_Order_By>>;
  where?: InputMaybe<Staff_Regions_Bool_Exp>;
};

export type Subscription_RootStaff_Regions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Regions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Regions_Order_By>>;
  where?: InputMaybe<Staff_Regions_Bool_Exp>;
};

export type Subscription_RootStaff_Regions_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootStaff_Regions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Staff_Regions_Stream_Cursor_Input>>;
  where?: InputMaybe<Staff_Regions_Bool_Exp>;
};

export type Subscription_RootStaff_ScheduleArgs = {
  distinct_on?: InputMaybe<Array<Staff_Schedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Schedule_Order_By>>;
  where?: InputMaybe<Staff_Schedule_Bool_Exp>;
};

export type Subscription_RootStaff_Schedule_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Schedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Schedule_Order_By>>;
  where?: InputMaybe<Staff_Schedule_Bool_Exp>;
};

export type Subscription_RootStaff_Schedule_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootStaff_Schedule_ServiceArgs = {
  distinct_on?: InputMaybe<Array<Staff_Schedule_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Schedule_Service_Order_By>>;
  where?: InputMaybe<Staff_Schedule_Service_Bool_Exp>;
};

export type Subscription_RootStaff_Schedule_Service_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Schedule_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Schedule_Service_Order_By>>;
  where?: InputMaybe<Staff_Schedule_Service_Bool_Exp>;
};

export type Subscription_RootStaff_Schedule_Service_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootStaff_Schedule_Service_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Staff_Schedule_Service_Stream_Cursor_Input>>;
  where?: InputMaybe<Staff_Schedule_Service_Bool_Exp>;
};

export type Subscription_RootStaff_Schedule_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Staff_Schedule_Stream_Cursor_Input>>;
  where?: InputMaybe<Staff_Schedule_Bool_Exp>;
};

export type Subscription_RootStaff_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Staff_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Services_Order_By>>;
  where?: InputMaybe<Staff_Services_Bool_Exp>;
};

export type Subscription_RootStaff_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Services_Order_By>>;
  where?: InputMaybe<Staff_Services_Bool_Exp>;
};

export type Subscription_RootStaff_Services_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootStaff_Services_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Staff_Services_Stream_Cursor_Input>>;
  where?: InputMaybe<Staff_Services_Bool_Exp>;
};

export type Subscription_RootStaff_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Staff_Stream_Cursor_Input>>;
  where?: InputMaybe<Staff_Bool_Exp>;
};

export type Subscription_RootStaff_TypeArgs = {
  distinct_on?: InputMaybe<Array<Staff_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Type_Order_By>>;
  where?: InputMaybe<Staff_Type_Bool_Exp>;
};

export type Subscription_RootStaff_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Staff_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Staff_Type_Order_By>>;
  where?: InputMaybe<Staff_Type_Bool_Exp>;
};

export type Subscription_RootStaff_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootStaff_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Staff_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Staff_Type_Bool_Exp>;
};

export type Subscription_RootSymptom_CheckerArgs = {
  distinct_on?: InputMaybe<Array<Symptom_Checker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Symptom_Checker_Order_By>>;
  where?: InputMaybe<Symptom_Checker_Bool_Exp>;
};

export type Subscription_RootSymptom_Checker_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Symptom_Checker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Symptom_Checker_Order_By>>;
  where?: InputMaybe<Symptom_Checker_Bool_Exp>;
};

export type Subscription_RootSymptom_Checker_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSymptom_Checker_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Symptom_Checker_Stream_Cursor_Input>>;
  where?: InputMaybe<Symptom_Checker_Bool_Exp>;
};

export type Subscription_RootTask_PriorityArgs = {
  distinct_on?: InputMaybe<Array<Task_Priority_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Priority_Order_By>>;
  where?: InputMaybe<Task_Priority_Bool_Exp>;
};

export type Subscription_RootTask_Priority_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Priority_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Priority_Order_By>>;
  where?: InputMaybe<Task_Priority_Bool_Exp>;
};

export type Subscription_RootTask_Priority_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootTask_Priority_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Priority_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Priority_Bool_Exp>;
};

export type Subscription_RootTask_StatusArgs = {
  distinct_on?: InputMaybe<Array<Task_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Status_Order_By>>;
  where?: InputMaybe<Task_Status_Bool_Exp>;
};

export type Subscription_RootTask_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Status_Order_By>>;
  where?: InputMaybe<Task_Status_Bool_Exp>;
};

export type Subscription_RootTask_Status_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootTask_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Status_Bool_Exp>;
};

export type Subscription_RootTask_Sub_TypeArgs = {
  distinct_on?: InputMaybe<Array<Task_Sub_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Sub_Type_Order_By>>;
  where?: InputMaybe<Task_Sub_Type_Bool_Exp>;
};

export type Subscription_RootTask_Sub_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Sub_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Sub_Type_Order_By>>;
  where?: InputMaybe<Task_Sub_Type_Bool_Exp>;
};

export type Subscription_RootTask_Sub_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootTask_Sub_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Sub_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Sub_Type_Bool_Exp>;
};

export type Subscription_RootTask_TypeArgs = {
  distinct_on?: InputMaybe<Array<Task_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Type_Order_By>>;
  where?: InputMaybe<Task_Type_Bool_Exp>;
};

export type Subscription_RootTask_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Type_Order_By>>;
  where?: InputMaybe<Task_Type_Bool_Exp>;
};

export type Subscription_RootTask_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootTask_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Type_Bool_Exp>;
};

export type Subscription_RootTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootTasks_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Assignments_Order_By>>;
  where?: InputMaybe<Tasks_Assignments_Bool_Exp>;
};

export type Subscription_RootTasks_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Assignments_Order_By>>;
  where?: InputMaybe<Tasks_Assignments_Bool_Exp>;
};

export type Subscription_RootTasks_Assignments_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootTasks_Assignments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tasks_Assignments_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Assignments_Bool_Exp>;
};

export type Subscription_RootTasks_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTasks_FilesArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Files_Order_By>>;
  where?: InputMaybe<Tasks_Files_Bool_Exp>;
};

export type Subscription_RootTasks_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Files_Order_By>>;
  where?: InputMaybe<Tasks_Files_Bool_Exp>;
};

export type Subscription_RootTasks_Files_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootTasks_Files_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tasks_Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Files_Bool_Exp>;
};

export type Subscription_RootTasks_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Groups_Order_By>>;
  where?: InputMaybe<Tasks_Groups_Bool_Exp>;
};

export type Subscription_RootTasks_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Groups_Order_By>>;
  where?: InputMaybe<Tasks_Groups_Bool_Exp>;
};

export type Subscription_RootTasks_Groups_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTasks_Groups_StaffArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Groups_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Groups_Staff_Order_By>>;
  where?: InputMaybe<Tasks_Groups_Staff_Bool_Exp>;
};

export type Subscription_RootTasks_Groups_Staff_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Groups_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Groups_Staff_Order_By>>;
  where?: InputMaybe<Tasks_Groups_Staff_Bool_Exp>;
};

export type Subscription_RootTasks_Groups_Staff_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootTasks_Groups_Staff_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tasks_Groups_Staff_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Groups_Staff_Bool_Exp>;
};

export type Subscription_RootTasks_Groups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tasks_Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Groups_Bool_Exp>;
};

export type Subscription_RootTasks_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

export type Subscription_RootTasks_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

export type Subscription_RootTasks_Notifications_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootTasks_Notifications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tasks_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

export type Subscription_RootTasks_ReadArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Read_Order_By>>;
  where?: InputMaybe<Tasks_Read_Bool_Exp>;
};

export type Subscription_RootTasks_Read_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Read_Order_By>>;
  where?: InputMaybe<Tasks_Read_Bool_Exp>;
};

export type Subscription_RootTasks_Read_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootTasks_Read_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tasks_Read_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Read_Bool_Exp>;
};

export type Subscription_RootTasks_ResponsibleArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Responsible_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Responsible_Order_By>>;
  where?: InputMaybe<Tasks_Responsible_Bool_Exp>;
};

export type Subscription_RootTasks_Responsible_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Responsible_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Responsible_Order_By>>;
  where?: InputMaybe<Tasks_Responsible_Bool_Exp>;
};

export type Subscription_RootTasks_Responsible_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tasks_Responsible_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Responsible_Bool_Exp>;
};

export type Subscription_RootTasks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootTasks_WatchArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Watch_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Watch_Order_By>>;
  where?: InputMaybe<Tasks_Watch_Bool_Exp>;
};

export type Subscription_RootTasks_Watch_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Watch_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Watch_Order_By>>;
  where?: InputMaybe<Tasks_Watch_Bool_Exp>;
};

export type Subscription_RootTasks_Watch_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootTasks_Watch_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tasks_Watch_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Watch_Bool_Exp>;
};

export type Subscription_RootTriage_ScheduleArgs = {
  distinct_on?: InputMaybe<Array<Triage_Schedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Triage_Schedule_Order_By>>;
  where?: InputMaybe<Triage_Schedule_Bool_Exp>;
};

export type Subscription_RootTriage_Schedule_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Triage_Schedule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Triage_Schedule_Order_By>>;
  where?: InputMaybe<Triage_Schedule_Bool_Exp>;
};

export type Subscription_RootTriage_Schedule_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Triage_Schedule_Stream_Cursor_Input>>;
  where?: InputMaybe<Triage_Schedule_Bool_Exp>;
};

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootVisit_ClassificationsArgs = {
  distinct_on?: InputMaybe<Array<Visit_Classifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Visit_Classifications_Order_By>>;
  where?: InputMaybe<Visit_Classifications_Bool_Exp>;
};

export type Subscription_RootVisit_Classifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Visit_Classifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Visit_Classifications_Order_By>>;
  where?: InputMaybe<Visit_Classifications_Bool_Exp>;
};

export type Subscription_RootVisit_Classifications_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootVisit_Classifications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Visit_Classifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Visit_Classifications_Bool_Exp>;
};

export type Subscription_RootVisit_TypesArgs = {
  distinct_on?: InputMaybe<Array<Visit_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Visit_Types_Order_By>>;
  where?: InputMaybe<Visit_Types_Bool_Exp>;
};

export type Subscription_RootVisit_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Visit_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Visit_Types_Order_By>>;
  where?: InputMaybe<Visit_Types_Bool_Exp>;
};

export type Subscription_RootVisit_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootVisit_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Visit_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Visit_Types_Bool_Exp>;
};

/** columns and relationships of "symptom_checker" */
export type Symptom_Checker = {
  __typename?: 'symptom_checker';
  /** An array relationship */
  appointments: Array<Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Appointments_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  phoneNumber?: Maybe<Scalars['String']>;
  session: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "symptom_checker" */
export type Symptom_CheckerAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** columns and relationships of "symptom_checker" */
export type Symptom_CheckerAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** columns and relationships of "symptom_checker" */
export type Symptom_CheckerSessionArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "symptom_checker" */
export type Symptom_Checker_Aggregate = {
  __typename?: 'symptom_checker_aggregate';
  aggregate?: Maybe<Symptom_Checker_Aggregate_Fields>;
  nodes: Array<Symptom_Checker>;
};

export type Symptom_Checker_Aggregate_Bool_Exp = {
  count?: InputMaybe<Symptom_Checker_Aggregate_Bool_Exp_Count>;
};

export type Symptom_Checker_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Symptom_Checker_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Symptom_Checker_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "symptom_checker" */
export type Symptom_Checker_Aggregate_Fields = {
  __typename?: 'symptom_checker_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Symptom_Checker_Max_Fields>;
  min?: Maybe<Symptom_Checker_Min_Fields>;
};

/** aggregate fields of "symptom_checker" */
export type Symptom_Checker_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Symptom_Checker_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "symptom_checker" */
export type Symptom_Checker_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Symptom_Checker_Max_Order_By>;
  min?: InputMaybe<Symptom_Checker_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Symptom_Checker_Append_Input = {
  session?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "symptom_checker" */
export type Symptom_Checker_Arr_Rel_Insert_Input = {
  data: Array<Symptom_Checker_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Symptom_Checker_On_Conflict>;
};

/** Boolean expression to filter rows from the table "symptom_checker". All fields are combined with a logical 'AND'. */
export type Symptom_Checker_Bool_Exp = {
  _and?: InputMaybe<Array<Symptom_Checker_Bool_Exp>>;
  _not?: InputMaybe<Symptom_Checker_Bool_Exp>;
  _or?: InputMaybe<Array<Symptom_Checker_Bool_Exp>>;
  appointments?: InputMaybe<Appointments_Bool_Exp>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  session?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "symptom_checker" */
export type Symptom_Checker_Constraint =
  /** unique or primary key constraint on columns "id" */
  'symptom_checker_pkey';

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Symptom_Checker_Delete_At_Path_Input = {
  session?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Symptom_Checker_Delete_Elem_Input = {
  session?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Symptom_Checker_Delete_Key_Input = {
  session?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "symptom_checker" */
export type Symptom_Checker_Insert_Input = {
  appointments?: InputMaybe<Appointments_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Symptom_Checker_Max_Fields = {
  __typename?: 'symptom_checker_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "symptom_checker" */
export type Symptom_Checker_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Symptom_Checker_Min_Fields = {
  __typename?: 'symptom_checker_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "symptom_checker" */
export type Symptom_Checker_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "symptom_checker" */
export type Symptom_Checker_Mutation_Response = {
  __typename?: 'symptom_checker_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Symptom_Checker>;
};

/** input type for inserting object relation for remote table "symptom_checker" */
export type Symptom_Checker_Obj_Rel_Insert_Input = {
  data: Symptom_Checker_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Symptom_Checker_On_Conflict>;
};

/** on_conflict condition type for table "symptom_checker" */
export type Symptom_Checker_On_Conflict = {
  constraint: Symptom_Checker_Constraint;
  update_columns?: Array<Symptom_Checker_Update_Column>;
  where?: InputMaybe<Symptom_Checker_Bool_Exp>;
};

/** Ordering options when selecting data from "symptom_checker". */
export type Symptom_Checker_Order_By = {
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  session?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: symptom_checker */
export type Symptom_Checker_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Symptom_Checker_Prepend_Input = {
  session?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "symptom_checker" */
export type Symptom_Checker_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'phoneNumber'
  /** column name */
  | 'session'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId';

/** input type for updating data in table "symptom_checker" */
export type Symptom_Checker_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "symptom_checker" */
export type Symptom_Checker_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Symptom_Checker_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Symptom_Checker_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "symptom_checker" */
export type Symptom_Checker_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'phoneNumber'
  /** column name */
  | 'session'
  /** column name */
  | 'updated_at'
  /** column name */
  | 'userId';

export type Symptom_Checker_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Symptom_Checker_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Symptom_Checker_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Symptom_Checker_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Symptom_Checker_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Symptom_Checker_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Symptom_Checker_Set_Input>;
  /** filter the rows which have to be updated */
  where: Symptom_Checker_Bool_Exp;
};

/** columns and relationships of "task_priority" */
export type Task_Priority = {
  __typename?: 'task_priority';
  description: Scalars['String'];
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "task_priority" */
export type Task_PriorityTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** columns and relationships of "task_priority" */
export type Task_PriorityTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** aggregated selection of "task_priority" */
export type Task_Priority_Aggregate = {
  __typename?: 'task_priority_aggregate';
  aggregate?: Maybe<Task_Priority_Aggregate_Fields>;
  nodes: Array<Task_Priority>;
};

/** aggregate fields of "task_priority" */
export type Task_Priority_Aggregate_Fields = {
  __typename?: 'task_priority_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Priority_Max_Fields>;
  min?: Maybe<Task_Priority_Min_Fields>;
};

/** aggregate fields of "task_priority" */
export type Task_Priority_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Priority_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "task_priority". All fields are combined with a logical 'AND'. */
export type Task_Priority_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Priority_Bool_Exp>>;
  _not?: InputMaybe<Task_Priority_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Priority_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  tasks?: InputMaybe<Tasks_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_priority" */
export type Task_Priority_Constraint =
  /** unique or primary key constraint on columns "value" */
  'task_priority_pkey';

export type Task_Priority_Enum =
  /** High priority */
  | 'HIGH'
  /** Low priority */
  | 'LOW'
  /** Medium priority */
  | 'MEDIUM';

/** Boolean expression to compare columns of type "task_priority_enum". All fields are combined with logical 'AND'. */
export type Task_Priority_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Task_Priority_Enum>;
  _in?: InputMaybe<Array<Task_Priority_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Task_Priority_Enum>;
  _nin?: InputMaybe<Array<Task_Priority_Enum>>;
};

/** input type for inserting data into table "task_priority" */
export type Task_Priority_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<Tasks_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Task_Priority_Max_Fields = {
  __typename?: 'task_priority_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Task_Priority_Min_Fields = {
  __typename?: 'task_priority_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "task_priority" */
export type Task_Priority_Mutation_Response = {
  __typename?: 'task_priority_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Priority>;
};

/** input type for inserting object relation for remote table "task_priority" */
export type Task_Priority_Obj_Rel_Insert_Input = {
  data: Task_Priority_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Priority_On_Conflict>;
};

/** on_conflict condition type for table "task_priority" */
export type Task_Priority_On_Conflict = {
  constraint: Task_Priority_Constraint;
  update_columns?: Array<Task_Priority_Update_Column>;
  where?: InputMaybe<Task_Priority_Bool_Exp>;
};

/** Ordering options when selecting data from "task_priority". */
export type Task_Priority_Order_By = {
  description?: InputMaybe<Order_By>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_priority */
export type Task_Priority_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "task_priority" */
export type Task_Priority_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "task_priority" */
export type Task_Priority_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "task_priority" */
export type Task_Priority_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Priority_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Priority_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "task_priority" */
export type Task_Priority_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Task_Priority_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Priority_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Priority_Bool_Exp;
};

/** columns and relationships of "task_status" */
export type Task_Status = {
  __typename?: 'task_status';
  description: Scalars['String'];
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "task_status" */
export type Task_StatusTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** columns and relationships of "task_status" */
export type Task_StatusTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** aggregated selection of "task_status" */
export type Task_Status_Aggregate = {
  __typename?: 'task_status_aggregate';
  aggregate?: Maybe<Task_Status_Aggregate_Fields>;
  nodes: Array<Task_Status>;
};

/** aggregate fields of "task_status" */
export type Task_Status_Aggregate_Fields = {
  __typename?: 'task_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Status_Max_Fields>;
  min?: Maybe<Task_Status_Min_Fields>;
};

/** aggregate fields of "task_status" */
export type Task_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "task_status". All fields are combined with a logical 'AND'. */
export type Task_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Status_Bool_Exp>>;
  _not?: InputMaybe<Task_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  tasks?: InputMaybe<Tasks_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_status" */
export type Task_Status_Constraint =
  /** unique or primary key constraint on columns "value" */
  'task_status_pkey';

export type Task_Status_Enum =
  /** Blocked status */
  | 'BLOCKED'
  /** Completed status */
  | 'COMPLETED'
  /** In progress status */
  | 'IN_PROGRESS'
  /** Open status */
  | 'OPEN';

/** Boolean expression to compare columns of type "task_status_enum". All fields are combined with logical 'AND'. */
export type Task_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Task_Status_Enum>;
  _in?: InputMaybe<Array<Task_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Task_Status_Enum>;
  _nin?: InputMaybe<Array<Task_Status_Enum>>;
};

/** input type for inserting data into table "task_status" */
export type Task_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<Tasks_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Task_Status_Max_Fields = {
  __typename?: 'task_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Task_Status_Min_Fields = {
  __typename?: 'task_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "task_status" */
export type Task_Status_Mutation_Response = {
  __typename?: 'task_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Status>;
};

/** input type for inserting object relation for remote table "task_status" */
export type Task_Status_Obj_Rel_Insert_Input = {
  data: Task_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Status_On_Conflict>;
};

/** on_conflict condition type for table "task_status" */
export type Task_Status_On_Conflict = {
  constraint: Task_Status_Constraint;
  update_columns?: Array<Task_Status_Update_Column>;
  where?: InputMaybe<Task_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "task_status". */
export type Task_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_status */
export type Task_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "task_status" */
export type Task_Status_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "task_status" */
export type Task_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "task_status" */
export type Task_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "task_status" */
export type Task_Status_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Task_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Status_Bool_Exp;
};

/** columns and relationships of "task_sub_type" */
export type Task_Sub_Type = {
  __typename?: 'task_sub_type';
  description: Scalars['String'];
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "task_sub_type" */
export type Task_Sub_TypeTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** columns and relationships of "task_sub_type" */
export type Task_Sub_TypeTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** aggregated selection of "task_sub_type" */
export type Task_Sub_Type_Aggregate = {
  __typename?: 'task_sub_type_aggregate';
  aggregate?: Maybe<Task_Sub_Type_Aggregate_Fields>;
  nodes: Array<Task_Sub_Type>;
};

/** aggregate fields of "task_sub_type" */
export type Task_Sub_Type_Aggregate_Fields = {
  __typename?: 'task_sub_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Sub_Type_Max_Fields>;
  min?: Maybe<Task_Sub_Type_Min_Fields>;
};

/** aggregate fields of "task_sub_type" */
export type Task_Sub_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Sub_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "task_sub_type". All fields are combined with a logical 'AND'. */
export type Task_Sub_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Sub_Type_Bool_Exp>>;
  _not?: InputMaybe<Task_Sub_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Sub_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  tasks?: InputMaybe<Tasks_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_sub_type" */
export type Task_Sub_Type_Constraint =
  /** unique or primary key constraint on columns "value" */
  'task_sub_type_pkey';

export type Task_Sub_Type_Enum =
  /** Announcement */
  | 'ANNOUNCEMENT'
  /** Appointment Prep */
  | 'APPOINTMENT_PREP'
  /** Best Practice */
  | 'BEST_PRACTICE'
  /** Document Migration */
  | 'DOCUMENT_MIGRATION'
  /** Patient Follow Up */
  | 'FOLLOW_UP'
  /** Medical Records Request */
  | 'MEDICAL_RECORD_REQUEST'
  /** Patient Request */
  | 'PATIENT_REQUEST'
  /** Referral */
  | 'REFERRAL'
  /** Schedule */
  | 'SCHEDULE'
  /** Sign out */
  | 'SIGN_OUT'
  /** Verification */
  | 'VERIFICATION';

/** Boolean expression to compare columns of type "task_sub_type_enum". All fields are combined with logical 'AND'. */
export type Task_Sub_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Task_Sub_Type_Enum>;
  _in?: InputMaybe<Array<Task_Sub_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Task_Sub_Type_Enum>;
  _nin?: InputMaybe<Array<Task_Sub_Type_Enum>>;
};

/** input type for inserting data into table "task_sub_type" */
export type Task_Sub_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<Tasks_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Task_Sub_Type_Max_Fields = {
  __typename?: 'task_sub_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Task_Sub_Type_Min_Fields = {
  __typename?: 'task_sub_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "task_sub_type" */
export type Task_Sub_Type_Mutation_Response = {
  __typename?: 'task_sub_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Sub_Type>;
};

/** input type for inserting object relation for remote table "task_sub_type" */
export type Task_Sub_Type_Obj_Rel_Insert_Input = {
  data: Task_Sub_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Sub_Type_On_Conflict>;
};

/** on_conflict condition type for table "task_sub_type" */
export type Task_Sub_Type_On_Conflict = {
  constraint: Task_Sub_Type_Constraint;
  update_columns?: Array<Task_Sub_Type_Update_Column>;
  where?: InputMaybe<Task_Sub_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "task_sub_type". */
export type Task_Sub_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_sub_type */
export type Task_Sub_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "task_sub_type" */
export type Task_Sub_Type_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "task_sub_type" */
export type Task_Sub_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "task_sub_type" */
export type Task_Sub_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Sub_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Sub_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "task_sub_type" */
export type Task_Sub_Type_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Task_Sub_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Sub_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Sub_Type_Bool_Exp;
};

/** columns and relationships of "task_type" */
export type Task_Type = {
  __typename?: 'task_type';
  description: Scalars['String'];
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "task_type" */
export type Task_TypeTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** columns and relationships of "task_type" */
export type Task_TypeTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** aggregated selection of "task_type" */
export type Task_Type_Aggregate = {
  __typename?: 'task_type_aggregate';
  aggregate?: Maybe<Task_Type_Aggregate_Fields>;
  nodes: Array<Task_Type>;
};

/** aggregate fields of "task_type" */
export type Task_Type_Aggregate_Fields = {
  __typename?: 'task_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Type_Max_Fields>;
  min?: Maybe<Task_Type_Min_Fields>;
};

/** aggregate fields of "task_type" */
export type Task_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "task_type". All fields are combined with a logical 'AND'. */
export type Task_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Type_Bool_Exp>>;
  _not?: InputMaybe<Task_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  tasks?: InputMaybe<Tasks_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_type" */
export type Task_Type_Constraint =
  /** unique or primary key constraint on columns "value" */
  'task_type_pkey';

export type Task_Type_Enum =
  /** Action task */
  | 'ACTION'
  /** Information task */
  | 'INFORMATION';

/** Boolean expression to compare columns of type "task_type_enum". All fields are combined with logical 'AND'. */
export type Task_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Task_Type_Enum>;
  _in?: InputMaybe<Array<Task_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Task_Type_Enum>;
  _nin?: InputMaybe<Array<Task_Type_Enum>>;
};

/** input type for inserting data into table "task_type" */
export type Task_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<Tasks_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Task_Type_Max_Fields = {
  __typename?: 'task_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Task_Type_Min_Fields = {
  __typename?: 'task_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "task_type" */
export type Task_Type_Mutation_Response = {
  __typename?: 'task_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Type>;
};

/** input type for inserting object relation for remote table "task_type" */
export type Task_Type_Obj_Rel_Insert_Input = {
  data: Task_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Type_On_Conflict>;
};

/** on_conflict condition type for table "task_type" */
export type Task_Type_On_Conflict = {
  constraint: Task_Type_Constraint;
  update_columns?: Array<Task_Type_Update_Column>;
  where?: InputMaybe<Task_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "task_type". */
export type Task_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_type */
export type Task_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "task_type" */
export type Task_Type_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "task_type" */
export type Task_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "task_type" */
export type Task_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "task_type" */
export type Task_Type_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Task_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Type_Bool_Exp;
};

/** columns and relationships of "tasks" */
export type Tasks = {
  __typename?: 'tasks';
  active: Scalars['Boolean'];
  /** Task memo completed */
  completed?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['timestamptz'];
  createdBy?: Maybe<Scalars['uuid']>;
  dueDateTime?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
  priority?: Maybe<Task_Priority_Enum>;
  /** Tasks read count */
  readCount?: Maybe<Scalars['bigint']>;
  /** Task Responsible Count */
  responsibleCount?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  staff?: Maybe<Staff>;
  status: Task_Status_Enum;
  subType?: Maybe<Task_Sub_Type_Enum>;
  /** An object relationship */
  task_priority?: Maybe<Task_Priority>;
  /** An object relationship */
  task_status: Task_Status;
  /** An object relationship */
  task_sub_type?: Maybe<Task_Sub_Type>;
  /** An object relationship */
  task_type: Task_Type;
  /** An array relationship */
  tasks_assignments: Array<Tasks_Assignments>;
  /** An aggregate relationship */
  tasks_assignments_aggregate: Tasks_Assignments_Aggregate;
  /** An array relationship */
  tasks_files: Array<Tasks_Files>;
  /** An aggregate relationship */
  tasks_files_aggregate: Tasks_Files_Aggregate;
  /** An array relationship */
  tasks_notifications: Array<Tasks_Notifications>;
  /** An aggregate relationship */
  tasks_notifications_aggregate: Tasks_Notifications_Aggregate;
  /** An array relationship */
  tasks_reads: Array<Tasks_Read>;
  /** An aggregate relationship */
  tasks_reads_aggregate: Tasks_Read_Aggregate;
  /** An object relationship */
  tasks_responsible_count?: Maybe<Tasks_Responsible>;
  /** An array relationship */
  tasks_watches: Array<Tasks_Watch>;
  /** An aggregate relationship */
  tasks_watches_aggregate: Tasks_Watch_Aggregate;
  title: Scalars['String'];
  type: Task_Type_Enum;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "tasks" */
export type TasksTasks_AssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Assignments_Order_By>>;
  where?: InputMaybe<Tasks_Assignments_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTasks_Assignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Assignments_Order_By>>;
  where?: InputMaybe<Tasks_Assignments_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTasks_FilesArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Files_Order_By>>;
  where?: InputMaybe<Tasks_Files_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTasks_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Files_Order_By>>;
  where?: InputMaybe<Tasks_Files_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTasks_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTasks_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTasks_ReadsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Read_Order_By>>;
  where?: InputMaybe<Tasks_Read_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTasks_Reads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Read_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Read_Order_By>>;
  where?: InputMaybe<Tasks_Read_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTasks_WatchesArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Watch_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Watch_Order_By>>;
  where?: InputMaybe<Tasks_Watch_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTasks_Watches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Watch_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Watch_Order_By>>;
  where?: InputMaybe<Tasks_Watch_Bool_Exp>;
};

/** aggregated selection of "tasks" */
export type Tasks_Aggregate = {
  __typename?: 'tasks_aggregate';
  aggregate?: Maybe<Tasks_Aggregate_Fields>;
  nodes: Array<Tasks>;
};

export type Tasks_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tasks_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tasks_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tasks_Aggregate_Bool_Exp_Count>;
};

export type Tasks_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tasks" */
export type Tasks_Aggregate_Fields = {
  __typename?: 'tasks_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tasks_Max_Fields>;
  min?: Maybe<Tasks_Min_Fields>;
};

/** aggregate fields of "tasks" */
export type Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tasks" */
export type Tasks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Max_Order_By>;
  min?: InputMaybe<Tasks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tasks" */
export type Tasks_Arr_Rel_Insert_Input = {
  data: Array<Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** columns and relationships of "tasks_assignments" */
export type Tasks_Assignments = {
  __typename?: 'tasks_assignments';
  active: Scalars['Boolean'];
  assignedBy?: Maybe<Scalars['uuid']>;
  assignedTo: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  staff: Staff;
  /** An object relationship */
  staffByAssignedby?: Maybe<Staff>;
  /** An object relationship */
  task: Tasks;
  taskId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "tasks_assignments" */
export type Tasks_Assignments_Aggregate = {
  __typename?: 'tasks_assignments_aggregate';
  aggregate?: Maybe<Tasks_Assignments_Aggregate_Fields>;
  nodes: Array<Tasks_Assignments>;
};

export type Tasks_Assignments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tasks_Assignments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tasks_Assignments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tasks_Assignments_Aggregate_Bool_Exp_Count>;
};

export type Tasks_Assignments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tasks_Assignments_Select_Column_Tasks_Assignments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Assignments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tasks_Assignments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tasks_Assignments_Select_Column_Tasks_Assignments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Assignments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tasks_Assignments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tasks_Assignments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Assignments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tasks_assignments" */
export type Tasks_Assignments_Aggregate_Fields = {
  __typename?: 'tasks_assignments_aggregate_fields';
  avg?: Maybe<Tasks_Assignments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tasks_Assignments_Max_Fields>;
  min?: Maybe<Tasks_Assignments_Min_Fields>;
  stddev?: Maybe<Tasks_Assignments_Stddev_Fields>;
  stddev_pop?: Maybe<Tasks_Assignments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tasks_Assignments_Stddev_Samp_Fields>;
  sum?: Maybe<Tasks_Assignments_Sum_Fields>;
  var_pop?: Maybe<Tasks_Assignments_Var_Pop_Fields>;
  var_samp?: Maybe<Tasks_Assignments_Var_Samp_Fields>;
  variance?: Maybe<Tasks_Assignments_Variance_Fields>;
};

/** aggregate fields of "tasks_assignments" */
export type Tasks_Assignments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tasks_Assignments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tasks_assignments" */
export type Tasks_Assignments_Aggregate_Order_By = {
  avg?: InputMaybe<Tasks_Assignments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Assignments_Max_Order_By>;
  min?: InputMaybe<Tasks_Assignments_Min_Order_By>;
  stddev?: InputMaybe<Tasks_Assignments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tasks_Assignments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tasks_Assignments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tasks_Assignments_Sum_Order_By>;
  var_pop?: InputMaybe<Tasks_Assignments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tasks_Assignments_Var_Samp_Order_By>;
  variance?: InputMaybe<Tasks_Assignments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tasks_assignments" */
export type Tasks_Assignments_Arr_Rel_Insert_Input = {
  data: Array<Tasks_Assignments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_Assignments_On_Conflict>;
};

/** aggregate avg on columns */
export type Tasks_Assignments_Avg_Fields = {
  __typename?: 'tasks_assignments_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tasks_assignments" */
export type Tasks_Assignments_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks_assignments". All fields are combined with a logical 'AND'. */
export type Tasks_Assignments_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Assignments_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Assignments_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Assignments_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  assignedBy?: InputMaybe<Uuid_Comparison_Exp>;
  assignedTo?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffByAssignedby?: InputMaybe<Staff_Bool_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  taskId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tasks_assignments" */
export type Tasks_Assignments_Constraint =
  /** unique or primary key constraint on columns "id" */
  'tasks_assignments_pkey';

/** input type for incrementing numeric columns in table "tasks_assignments" */
export type Tasks_Assignments_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "tasks_assignments" */
export type Tasks_Assignments_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  assignedTo?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffByAssignedby?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  taskId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tasks_Assignments_Max_Fields = {
  __typename?: 'tasks_assignments_max_fields';
  assignedBy?: Maybe<Scalars['uuid']>;
  assignedTo?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  taskId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tasks_assignments" */
export type Tasks_Assignments_Max_Order_By = {
  assignedBy?: InputMaybe<Order_By>;
  assignedTo?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  taskId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tasks_Assignments_Min_Fields = {
  __typename?: 'tasks_assignments_min_fields';
  assignedBy?: Maybe<Scalars['uuid']>;
  assignedTo?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  taskId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tasks_assignments" */
export type Tasks_Assignments_Min_Order_By = {
  assignedBy?: InputMaybe<Order_By>;
  assignedTo?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  taskId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tasks_assignments" */
export type Tasks_Assignments_Mutation_Response = {
  __typename?: 'tasks_assignments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks_Assignments>;
};

/** on_conflict condition type for table "tasks_assignments" */
export type Tasks_Assignments_On_Conflict = {
  constraint: Tasks_Assignments_Constraint;
  update_columns?: Array<Tasks_Assignments_Update_Column>;
  where?: InputMaybe<Tasks_Assignments_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks_assignments". */
export type Tasks_Assignments_Order_By = {
  active?: InputMaybe<Order_By>;
  assignedBy?: InputMaybe<Order_By>;
  assignedTo?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffByAssignedby?: InputMaybe<Staff_Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  taskId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tasks_assignments */
export type Tasks_Assignments_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "tasks_assignments" */
export type Tasks_Assignments_Select_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'assignedBy'
  /** column name */
  | 'assignedTo'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'taskId'
  /** column name */
  | 'updatedAt';

/** select "tasks_assignments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tasks_assignments" */
export type Tasks_Assignments_Select_Column_Tasks_Assignments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  'active';

/** select "tasks_assignments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tasks_assignments" */
export type Tasks_Assignments_Select_Column_Tasks_Assignments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  'active';

/** input type for updating data in table "tasks_assignments" */
export type Tasks_Assignments_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  assignedTo?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  taskId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tasks_Assignments_Stddev_Fields = {
  __typename?: 'tasks_assignments_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tasks_assignments" */
export type Tasks_Assignments_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tasks_Assignments_Stddev_Pop_Fields = {
  __typename?: 'tasks_assignments_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tasks_assignments" */
export type Tasks_Assignments_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tasks_Assignments_Stddev_Samp_Fields = {
  __typename?: 'tasks_assignments_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tasks_assignments" */
export type Tasks_Assignments_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tasks_assignments" */
export type Tasks_Assignments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Assignments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Assignments_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  assignedBy?: InputMaybe<Scalars['uuid']>;
  assignedTo?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  taskId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tasks_Assignments_Sum_Fields = {
  __typename?: 'tasks_assignments_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "tasks_assignments" */
export type Tasks_Assignments_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "tasks_assignments" */
export type Tasks_Assignments_Update_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'assignedBy'
  /** column name */
  | 'assignedTo'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'taskId'
  /** column name */
  | 'updatedAt';

export type Tasks_Assignments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tasks_Assignments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tasks_Assignments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Assignments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tasks_Assignments_Var_Pop_Fields = {
  __typename?: 'tasks_assignments_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tasks_assignments" */
export type Tasks_Assignments_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tasks_Assignments_Var_Samp_Fields = {
  __typename?: 'tasks_assignments_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tasks_assignments" */
export type Tasks_Assignments_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tasks_Assignments_Variance_Fields = {
  __typename?: 'tasks_assignments_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tasks_assignments" */
export type Tasks_Assignments_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks". All fields are combined with a logical 'AND'. */
export type Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  completed?: InputMaybe<Boolean_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdBy?: InputMaybe<Uuid_Comparison_Exp>;
  dueDateTime?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  priority?: InputMaybe<Task_Priority_Enum_Comparison_Exp>;
  readCount?: InputMaybe<Bigint_Comparison_Exp>;
  responsibleCount?: InputMaybe<Bigint_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  status?: InputMaybe<Task_Status_Enum_Comparison_Exp>;
  subType?: InputMaybe<Task_Sub_Type_Enum_Comparison_Exp>;
  task_priority?: InputMaybe<Task_Priority_Bool_Exp>;
  task_status?: InputMaybe<Task_Status_Bool_Exp>;
  task_sub_type?: InputMaybe<Task_Sub_Type_Bool_Exp>;
  task_type?: InputMaybe<Task_Type_Bool_Exp>;
  tasks_assignments?: InputMaybe<Tasks_Assignments_Bool_Exp>;
  tasks_assignments_aggregate?: InputMaybe<Tasks_Assignments_Aggregate_Bool_Exp>;
  tasks_files?: InputMaybe<Tasks_Files_Bool_Exp>;
  tasks_files_aggregate?: InputMaybe<Tasks_Files_Aggregate_Bool_Exp>;
  tasks_notifications?: InputMaybe<Tasks_Notifications_Bool_Exp>;
  tasks_notifications_aggregate?: InputMaybe<Tasks_Notifications_Aggregate_Bool_Exp>;
  tasks_reads?: InputMaybe<Tasks_Read_Bool_Exp>;
  tasks_reads_aggregate?: InputMaybe<Tasks_Read_Aggregate_Bool_Exp>;
  tasks_responsible_count?: InputMaybe<Tasks_Responsible_Bool_Exp>;
  tasks_watches?: InputMaybe<Tasks_Watch_Bool_Exp>;
  tasks_watches_aggregate?: InputMaybe<Tasks_Watch_Aggregate_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Task_Type_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tasks" */
export type Tasks_Constraint =
  /** unique or primary key constraint on columns "id" */
  'tasks_pkey';

/** columns and relationships of "tasks_files" */
export type Tasks_Files = {
  __typename?: 'tasks_files';
  active: Scalars['Boolean'];
  createdAt: Scalars['timestamptz'];
  createdBy?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  file: Files;
  fileId: Scalars['uuid'];
  id: Scalars['bigint'];
  /** An object relationship */
  staff?: Maybe<Staff>;
  /** An object relationship */
  task: Tasks;
  taskId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "tasks_files" */
export type Tasks_Files_Aggregate = {
  __typename?: 'tasks_files_aggregate';
  aggregate?: Maybe<Tasks_Files_Aggregate_Fields>;
  nodes: Array<Tasks_Files>;
};

export type Tasks_Files_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tasks_Files_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tasks_Files_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tasks_Files_Aggregate_Bool_Exp_Count>;
};

export type Tasks_Files_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tasks_Files_Select_Column_Tasks_Files_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Files_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tasks_Files_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tasks_Files_Select_Column_Tasks_Files_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Files_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tasks_Files_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tasks_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tasks_files" */
export type Tasks_Files_Aggregate_Fields = {
  __typename?: 'tasks_files_aggregate_fields';
  avg?: Maybe<Tasks_Files_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tasks_Files_Max_Fields>;
  min?: Maybe<Tasks_Files_Min_Fields>;
  stddev?: Maybe<Tasks_Files_Stddev_Fields>;
  stddev_pop?: Maybe<Tasks_Files_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tasks_Files_Stddev_Samp_Fields>;
  sum?: Maybe<Tasks_Files_Sum_Fields>;
  var_pop?: Maybe<Tasks_Files_Var_Pop_Fields>;
  var_samp?: Maybe<Tasks_Files_Var_Samp_Fields>;
  variance?: Maybe<Tasks_Files_Variance_Fields>;
};

/** aggregate fields of "tasks_files" */
export type Tasks_Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tasks_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tasks_files" */
export type Tasks_Files_Aggregate_Order_By = {
  avg?: InputMaybe<Tasks_Files_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Files_Max_Order_By>;
  min?: InputMaybe<Tasks_Files_Min_Order_By>;
  stddev?: InputMaybe<Tasks_Files_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tasks_Files_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tasks_Files_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tasks_Files_Sum_Order_By>;
  var_pop?: InputMaybe<Tasks_Files_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tasks_Files_Var_Samp_Order_By>;
  variance?: InputMaybe<Tasks_Files_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tasks_files" */
export type Tasks_Files_Arr_Rel_Insert_Input = {
  data: Array<Tasks_Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_Files_On_Conflict>;
};

/** aggregate avg on columns */
export type Tasks_Files_Avg_Fields = {
  __typename?: 'tasks_files_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tasks_files" */
export type Tasks_Files_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks_files". All fields are combined with a logical 'AND'. */
export type Tasks_Files_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Files_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Files_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Files_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdBy?: InputMaybe<Uuid_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  fileId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  taskId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tasks_files" */
export type Tasks_Files_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'tasks_files_pkey'
  /** unique or primary key constraint on columns "fileId", "taskId" */
  | 'tasks_files_taskId_fileId_key';

/** input type for incrementing numeric columns in table "tasks_files" */
export type Tasks_Files_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "tasks_files" */
export type Tasks_Files_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  fileId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['bigint']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  taskId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tasks_Files_Max_Fields = {
  __typename?: 'tasks_files_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['uuid']>;
  fileId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  taskId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tasks_files" */
export type Tasks_Files_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  taskId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tasks_Files_Min_Fields = {
  __typename?: 'tasks_files_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['uuid']>;
  fileId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  taskId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tasks_files" */
export type Tasks_Files_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  taskId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tasks_files" */
export type Tasks_Files_Mutation_Response = {
  __typename?: 'tasks_files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks_Files>;
};

/** on_conflict condition type for table "tasks_files" */
export type Tasks_Files_On_Conflict = {
  constraint: Tasks_Files_Constraint;
  update_columns?: Array<Tasks_Files_Update_Column>;
  where?: InputMaybe<Tasks_Files_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks_files". */
export type Tasks_Files_Order_By = {
  active?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  taskId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tasks_files */
export type Tasks_Files_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "tasks_files" */
export type Tasks_Files_Select_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'fileId'
  /** column name */
  | 'id'
  /** column name */
  | 'taskId'
  /** column name */
  | 'updatedAt';

/** select "tasks_files_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tasks_files" */
export type Tasks_Files_Select_Column_Tasks_Files_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  'active';

/** select "tasks_files_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tasks_files" */
export type Tasks_Files_Select_Column_Tasks_Files_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  'active';

/** input type for updating data in table "tasks_files" */
export type Tasks_Files_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  fileId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['bigint']>;
  taskId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tasks_Files_Stddev_Fields = {
  __typename?: 'tasks_files_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tasks_files" */
export type Tasks_Files_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tasks_Files_Stddev_Pop_Fields = {
  __typename?: 'tasks_files_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tasks_files" */
export type Tasks_Files_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tasks_Files_Stddev_Samp_Fields = {
  __typename?: 'tasks_files_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tasks_files" */
export type Tasks_Files_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tasks_files" */
export type Tasks_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Files_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  fileId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['bigint']>;
  taskId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tasks_Files_Sum_Fields = {
  __typename?: 'tasks_files_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "tasks_files" */
export type Tasks_Files_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "tasks_files" */
export type Tasks_Files_Update_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'fileId'
  /** column name */
  | 'id'
  /** column name */
  | 'taskId'
  /** column name */
  | 'updatedAt';

export type Tasks_Files_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tasks_Files_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tasks_Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Files_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tasks_Files_Var_Pop_Fields = {
  __typename?: 'tasks_files_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tasks_files" */
export type Tasks_Files_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tasks_Files_Var_Samp_Fields = {
  __typename?: 'tasks_files_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tasks_files" */
export type Tasks_Files_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tasks_Files_Variance_Fields = {
  __typename?: 'tasks_files_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tasks_files" */
export type Tasks_Files_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tasks_groups" */
export type Tasks_Groups = {
  __typename?: 'tasks_groups';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  tasks_groups_staffs: Array<Tasks_Groups_Staff>;
  /** An aggregate relationship */
  tasks_groups_staffs_aggregate: Tasks_Groups_Staff_Aggregate;
  /** An array relationship */
  tasks_notifications: Array<Tasks_Notifications>;
  /** An aggregate relationship */
  tasks_notifications_aggregate: Tasks_Notifications_Aggregate;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "tasks_groups" */
export type Tasks_GroupsTasks_Groups_StaffsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Groups_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Groups_Staff_Order_By>>;
  where?: InputMaybe<Tasks_Groups_Staff_Bool_Exp>;
};

/** columns and relationships of "tasks_groups" */
export type Tasks_GroupsTasks_Groups_Staffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Groups_Staff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Groups_Staff_Order_By>>;
  where?: InputMaybe<Tasks_Groups_Staff_Bool_Exp>;
};

/** columns and relationships of "tasks_groups" */
export type Tasks_GroupsTasks_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

/** columns and relationships of "tasks_groups" */
export type Tasks_GroupsTasks_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Notifications_Order_By>>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

/** aggregated selection of "tasks_groups" */
export type Tasks_Groups_Aggregate = {
  __typename?: 'tasks_groups_aggregate';
  aggregate?: Maybe<Tasks_Groups_Aggregate_Fields>;
  nodes: Array<Tasks_Groups>;
};

/** aggregate fields of "tasks_groups" */
export type Tasks_Groups_Aggregate_Fields = {
  __typename?: 'tasks_groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tasks_Groups_Max_Fields>;
  min?: Maybe<Tasks_Groups_Min_Fields>;
};

/** aggregate fields of "tasks_groups" */
export type Tasks_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tasks_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tasks_groups". All fields are combined with a logical 'AND'. */
export type Tasks_Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Groups_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Groups_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tasks_groups_staffs?: InputMaybe<Tasks_Groups_Staff_Bool_Exp>;
  tasks_groups_staffs_aggregate?: InputMaybe<Tasks_Groups_Staff_Aggregate_Bool_Exp>;
  tasks_notifications?: InputMaybe<Tasks_Notifications_Bool_Exp>;
  tasks_notifications_aggregate?: InputMaybe<Tasks_Notifications_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tasks_groups" */
export type Tasks_Groups_Constraint =
  /** unique or primary key constraint on columns "id" */
  'tasks_groups_pkey';

/** input type for inserting data into table "tasks_groups" */
export type Tasks_Groups_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  tasks_groups_staffs?: InputMaybe<Tasks_Groups_Staff_Arr_Rel_Insert_Input>;
  tasks_notifications?: InputMaybe<Tasks_Notifications_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tasks_Groups_Max_Fields = {
  __typename?: 'tasks_groups_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Tasks_Groups_Min_Fields = {
  __typename?: 'tasks_groups_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "tasks_groups" */
export type Tasks_Groups_Mutation_Response = {
  __typename?: 'tasks_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks_Groups>;
};

/** input type for inserting object relation for remote table "tasks_groups" */
export type Tasks_Groups_Obj_Rel_Insert_Input = {
  data: Tasks_Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_Groups_On_Conflict>;
};

/** on_conflict condition type for table "tasks_groups" */
export type Tasks_Groups_On_Conflict = {
  constraint: Tasks_Groups_Constraint;
  update_columns?: Array<Tasks_Groups_Update_Column>;
  where?: InputMaybe<Tasks_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks_groups". */
export type Tasks_Groups_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tasks_groups_staffs_aggregate?: InputMaybe<Tasks_Groups_Staff_Aggregate_Order_By>;
  tasks_notifications_aggregate?: InputMaybe<Tasks_Notifications_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tasks_groups */
export type Tasks_Groups_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tasks_groups" */
export type Tasks_Groups_Select_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'updated_at';

/** input type for updating data in table "tasks_groups" */
export type Tasks_Groups_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "tasks_groups_staff" */
export type Tasks_Groups_Staff = {
  __typename?: 'tasks_groups_staff';
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  staff: Staff;
  staffId: Scalars['uuid'];
  taskGroupId: Scalars['uuid'];
  /** An object relationship */
  tasks_group: Tasks_Groups;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "tasks_groups_staff" */
export type Tasks_Groups_Staff_Aggregate = {
  __typename?: 'tasks_groups_staff_aggregate';
  aggregate?: Maybe<Tasks_Groups_Staff_Aggregate_Fields>;
  nodes: Array<Tasks_Groups_Staff>;
};

export type Tasks_Groups_Staff_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tasks_Groups_Staff_Aggregate_Bool_Exp_Count>;
};

export type Tasks_Groups_Staff_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tasks_Groups_Staff_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Groups_Staff_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tasks_groups_staff" */
export type Tasks_Groups_Staff_Aggregate_Fields = {
  __typename?: 'tasks_groups_staff_aggregate_fields';
  avg?: Maybe<Tasks_Groups_Staff_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tasks_Groups_Staff_Max_Fields>;
  min?: Maybe<Tasks_Groups_Staff_Min_Fields>;
  stddev?: Maybe<Tasks_Groups_Staff_Stddev_Fields>;
  stddev_pop?: Maybe<Tasks_Groups_Staff_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tasks_Groups_Staff_Stddev_Samp_Fields>;
  sum?: Maybe<Tasks_Groups_Staff_Sum_Fields>;
  var_pop?: Maybe<Tasks_Groups_Staff_Var_Pop_Fields>;
  var_samp?: Maybe<Tasks_Groups_Staff_Var_Samp_Fields>;
  variance?: Maybe<Tasks_Groups_Staff_Variance_Fields>;
};

/** aggregate fields of "tasks_groups_staff" */
export type Tasks_Groups_Staff_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tasks_Groups_Staff_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Aggregate_Order_By = {
  avg?: InputMaybe<Tasks_Groups_Staff_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Groups_Staff_Max_Order_By>;
  min?: InputMaybe<Tasks_Groups_Staff_Min_Order_By>;
  stddev?: InputMaybe<Tasks_Groups_Staff_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tasks_Groups_Staff_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tasks_Groups_Staff_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tasks_Groups_Staff_Sum_Order_By>;
  var_pop?: InputMaybe<Tasks_Groups_Staff_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tasks_Groups_Staff_Var_Samp_Order_By>;
  variance?: InputMaybe<Tasks_Groups_Staff_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Arr_Rel_Insert_Input = {
  data: Array<Tasks_Groups_Staff_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_Groups_Staff_On_Conflict>;
};

/** aggregate avg on columns */
export type Tasks_Groups_Staff_Avg_Fields = {
  __typename?: 'tasks_groups_staff_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks_groups_staff". All fields are combined with a logical 'AND'. */
export type Tasks_Groups_Staff_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Groups_Staff_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Groups_Staff_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Groups_Staff_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  taskGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  tasks_group?: InputMaybe<Tasks_Groups_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'tasks_groups_staff_pkey'
  /** unique or primary key constraint on columns "staffId", "taskGroupId" */
  | 'tasks_groups_staff_taskGroupId_staffId_key';

/** input type for incrementing numeric columns in table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  taskGroupId?: InputMaybe<Scalars['uuid']>;
  tasks_group?: InputMaybe<Tasks_Groups_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tasks_Groups_Staff_Max_Fields = {
  __typename?: 'tasks_groups_staff_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  staffId?: Maybe<Scalars['uuid']>;
  taskGroupId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  taskGroupId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tasks_Groups_Staff_Min_Fields = {
  __typename?: 'tasks_groups_staff_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  staffId?: Maybe<Scalars['uuid']>;
  taskGroupId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  taskGroupId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Mutation_Response = {
  __typename?: 'tasks_groups_staff_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks_Groups_Staff>;
};

/** on_conflict condition type for table "tasks_groups_staff" */
export type Tasks_Groups_Staff_On_Conflict = {
  constraint: Tasks_Groups_Staff_Constraint;
  update_columns?: Array<Tasks_Groups_Staff_Update_Column>;
  where?: InputMaybe<Tasks_Groups_Staff_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks_groups_staff". */
export type Tasks_Groups_Staff_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  taskGroupId?: InputMaybe<Order_By>;
  tasks_group?: InputMaybe<Tasks_Groups_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tasks_groups_staff */
export type Tasks_Groups_Staff_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Select_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'staffId'
  /** column name */
  | 'taskGroupId'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  taskGroupId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tasks_Groups_Staff_Stddev_Fields = {
  __typename?: 'tasks_groups_staff_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tasks_Groups_Staff_Stddev_Pop_Fields = {
  __typename?: 'tasks_groups_staff_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tasks_Groups_Staff_Stddev_Samp_Fields = {
  __typename?: 'tasks_groups_staff_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Groups_Staff_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Groups_Staff_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  taskGroupId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tasks_Groups_Staff_Sum_Fields = {
  __typename?: 'tasks_groups_staff_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Update_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'staffId'
  /** column name */
  | 'taskGroupId'
  /** column name */
  | 'updatedAt';

export type Tasks_Groups_Staff_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tasks_Groups_Staff_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tasks_Groups_Staff_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Groups_Staff_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tasks_Groups_Staff_Var_Pop_Fields = {
  __typename?: 'tasks_groups_staff_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tasks_Groups_Staff_Var_Samp_Fields = {
  __typename?: 'tasks_groups_staff_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tasks_Groups_Staff_Variance_Fields = {
  __typename?: 'tasks_groups_staff_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tasks_groups_staff" */
export type Tasks_Groups_Staff_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tasks_groups" */
export type Tasks_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Groups_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "tasks_groups" */
export type Tasks_Groups_Update_Column =
  /** column name */
  | 'created_at'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'updated_at';

export type Tasks_Groups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tasks_Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Groups_Bool_Exp;
};

/** input type for inserting data into table "tasks" */
export type Tasks_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  dueDateTime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Task_Priority_Enum>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Task_Status_Enum>;
  subType?: InputMaybe<Task_Sub_Type_Enum>;
  task_priority?: InputMaybe<Task_Priority_Obj_Rel_Insert_Input>;
  task_status?: InputMaybe<Task_Status_Obj_Rel_Insert_Input>;
  task_sub_type?: InputMaybe<Task_Sub_Type_Obj_Rel_Insert_Input>;
  task_type?: InputMaybe<Task_Type_Obj_Rel_Insert_Input>;
  tasks_assignments?: InputMaybe<Tasks_Assignments_Arr_Rel_Insert_Input>;
  tasks_files?: InputMaybe<Tasks_Files_Arr_Rel_Insert_Input>;
  tasks_notifications?: InputMaybe<Tasks_Notifications_Arr_Rel_Insert_Input>;
  tasks_reads?: InputMaybe<Tasks_Read_Arr_Rel_Insert_Input>;
  tasks_responsible_count?: InputMaybe<Tasks_Responsible_Obj_Rel_Insert_Input>;
  tasks_watches?: InputMaybe<Tasks_Watch_Arr_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Task_Type_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tasks_Max_Fields = {
  __typename?: 'tasks_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['uuid']>;
  dueDateTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tasks" */
export type Tasks_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  dueDateTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tasks_Min_Fields = {
  __typename?: 'tasks_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['uuid']>;
  dueDateTime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tasks" */
export type Tasks_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  dueDateTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tasks" */
export type Tasks_Mutation_Response = {
  __typename?: 'tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks>;
};

/** columns and relationships of "tasks_notifications" */
export type Tasks_Notifications = {
  __typename?: 'tasks_notifications';
  /** An object relationship */
  account?: Maybe<Accounts>;
  accountId?: Maybe<Scalars['uuid']>;
  active: Scalars['Boolean'];
  /** An object relationship */
  appointment?: Maybe<Appointments>;
  appointmentId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  clinic?: Maybe<Clinics>;
  clinicId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  patient?: Maybe<Patients>;
  patientId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  staff?: Maybe<Staff>;
  staffId?: Maybe<Scalars['uuid']>;
  staffType?: Maybe<Staff_Type_Enum>;
  /** An object relationship */
  staff_type?: Maybe<Staff_Type>;
  /** An object relationship */
  task: Tasks;
  taskGroupId?: Maybe<Scalars['uuid']>;
  taskId: Scalars['uuid'];
  /** An object relationship */
  tasks_group?: Maybe<Tasks_Groups>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "tasks_notifications" */
export type Tasks_Notifications_Aggregate = {
  __typename?: 'tasks_notifications_aggregate';
  aggregate?: Maybe<Tasks_Notifications_Aggregate_Fields>;
  nodes: Array<Tasks_Notifications>;
};

export type Tasks_Notifications_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tasks_Notifications_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tasks_Notifications_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tasks_Notifications_Aggregate_Bool_Exp_Count>;
};

export type Tasks_Notifications_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tasks_Notifications_Select_Column_Tasks_Notifications_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Notifications_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tasks_Notifications_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tasks_Notifications_Select_Column_Tasks_Notifications_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Notifications_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tasks_Notifications_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Notifications_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tasks_notifications" */
export type Tasks_Notifications_Aggregate_Fields = {
  __typename?: 'tasks_notifications_aggregate_fields';
  avg?: Maybe<Tasks_Notifications_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tasks_Notifications_Max_Fields>;
  min?: Maybe<Tasks_Notifications_Min_Fields>;
  stddev?: Maybe<Tasks_Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<Tasks_Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tasks_Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<Tasks_Notifications_Sum_Fields>;
  var_pop?: Maybe<Tasks_Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<Tasks_Notifications_Var_Samp_Fields>;
  variance?: Maybe<Tasks_Notifications_Variance_Fields>;
};

/** aggregate fields of "tasks_notifications" */
export type Tasks_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tasks_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tasks_notifications" */
export type Tasks_Notifications_Aggregate_Order_By = {
  avg?: InputMaybe<Tasks_Notifications_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Notifications_Max_Order_By>;
  min?: InputMaybe<Tasks_Notifications_Min_Order_By>;
  stddev?: InputMaybe<Tasks_Notifications_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tasks_Notifications_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tasks_Notifications_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tasks_Notifications_Sum_Order_By>;
  var_pop?: InputMaybe<Tasks_Notifications_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tasks_Notifications_Var_Samp_Order_By>;
  variance?: InputMaybe<Tasks_Notifications_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tasks_notifications" */
export type Tasks_Notifications_Arr_Rel_Insert_Input = {
  data: Array<Tasks_Notifications_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_Notifications_On_Conflict>;
};

/** aggregate avg on columns */
export type Tasks_Notifications_Avg_Fields = {
  __typename?: 'tasks_notifications_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tasks_notifications" */
export type Tasks_Notifications_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks_notifications". All fields are combined with a logical 'AND'. */
export type Tasks_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Notifications_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Notifications_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  appointment?: InputMaybe<Appointments_Bool_Exp>;
  appointmentId?: InputMaybe<Uuid_Comparison_Exp>;
  clinic?: InputMaybe<Clinics_Bool_Exp>;
  clinicId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Uuid_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  staffType?: InputMaybe<Staff_Type_Enum_Comparison_Exp>;
  staff_type?: InputMaybe<Staff_Type_Bool_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  taskGroupId?: InputMaybe<Uuid_Comparison_Exp>;
  taskId?: InputMaybe<Uuid_Comparison_Exp>;
  tasks_group?: InputMaybe<Tasks_Groups_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tasks_notifications" */
export type Tasks_Notifications_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'tasks_notifications_pkey'
  /** unique or primary key constraint on columns "taskId", "accountId" */
  | 'tasks_notifications_taskId_accountId_key'
  /** unique or primary key constraint on columns "appointmentId", "taskId" */
  | 'tasks_notifications_taskId_appointmentId_key'
  /** unique or primary key constraint on columns "taskId", "patientId" */
  | 'tasks_notifications_taskId_patientId_key';

/** input type for incrementing numeric columns in table "tasks_notifications" */
export type Tasks_Notifications_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "tasks_notifications" */
export type Tasks_Notifications_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accountId?: InputMaybe<Scalars['uuid']>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointment?: InputMaybe<Appointments_Obj_Rel_Insert_Input>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  clinic?: InputMaybe<Clinics_Obj_Rel_Insert_Input>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['uuid']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  staffType?: InputMaybe<Staff_Type_Enum>;
  staff_type?: InputMaybe<Staff_Type_Obj_Rel_Insert_Input>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  taskGroupId?: InputMaybe<Scalars['uuid']>;
  taskId?: InputMaybe<Scalars['uuid']>;
  tasks_group?: InputMaybe<Tasks_Groups_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tasks_Notifications_Max_Fields = {
  __typename?: 'tasks_notifications_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  appointmentId?: Maybe<Scalars['uuid']>;
  clinicId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  patientId?: Maybe<Scalars['uuid']>;
  staffId?: Maybe<Scalars['uuid']>;
  taskGroupId?: Maybe<Scalars['uuid']>;
  taskId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tasks_notifications" */
export type Tasks_Notifications_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  taskGroupId?: InputMaybe<Order_By>;
  taskId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tasks_Notifications_Min_Fields = {
  __typename?: 'tasks_notifications_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  appointmentId?: Maybe<Scalars['uuid']>;
  clinicId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  patientId?: Maybe<Scalars['uuid']>;
  staffId?: Maybe<Scalars['uuid']>;
  taskGroupId?: Maybe<Scalars['uuid']>;
  taskId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tasks_notifications" */
export type Tasks_Notifications_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  taskGroupId?: InputMaybe<Order_By>;
  taskId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tasks_notifications" */
export type Tasks_Notifications_Mutation_Response = {
  __typename?: 'tasks_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks_Notifications>;
};

/** on_conflict condition type for table "tasks_notifications" */
export type Tasks_Notifications_On_Conflict = {
  constraint: Tasks_Notifications_Constraint;
  update_columns?: Array<Tasks_Notifications_Update_Column>;
  where?: InputMaybe<Tasks_Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks_notifications". */
export type Tasks_Notifications_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accountId?: InputMaybe<Order_By>;
  active?: InputMaybe<Order_By>;
  appointment?: InputMaybe<Appointments_Order_By>;
  appointmentId?: InputMaybe<Order_By>;
  clinic?: InputMaybe<Clinics_Order_By>;
  clinicId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  staffType?: InputMaybe<Order_By>;
  staff_type?: InputMaybe<Staff_Type_Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  taskGroupId?: InputMaybe<Order_By>;
  taskId?: InputMaybe<Order_By>;
  tasks_group?: InputMaybe<Tasks_Groups_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tasks_notifications */
export type Tasks_Notifications_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "tasks_notifications" */
export type Tasks_Notifications_Select_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'active'
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'clinicId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'patientId'
  /** column name */
  | 'staffId'
  /** column name */
  | 'staffType'
  /** column name */
  | 'taskGroupId'
  /** column name */
  | 'taskId'
  /** column name */
  | 'updatedAt';

/** select "tasks_notifications_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tasks_notifications" */
export type Tasks_Notifications_Select_Column_Tasks_Notifications_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  'active';

/** select "tasks_notifications_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tasks_notifications" */
export type Tasks_Notifications_Select_Column_Tasks_Notifications_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  'active';

/** input type for updating data in table "tasks_notifications" */
export type Tasks_Notifications_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  staffType?: InputMaybe<Staff_Type_Enum>;
  taskGroupId?: InputMaybe<Scalars['uuid']>;
  taskId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tasks_Notifications_Stddev_Fields = {
  __typename?: 'tasks_notifications_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tasks_notifications" */
export type Tasks_Notifications_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tasks_Notifications_Stddev_Pop_Fields = {
  __typename?: 'tasks_notifications_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tasks_notifications" */
export type Tasks_Notifications_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tasks_Notifications_Stddev_Samp_Fields = {
  __typename?: 'tasks_notifications_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tasks_notifications" */
export type Tasks_Notifications_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tasks_notifications" */
export type Tasks_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Notifications_Stream_Cursor_Value_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  active?: InputMaybe<Scalars['Boolean']>;
  appointmentId?: InputMaybe<Scalars['uuid']>;
  clinicId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  patientId?: InputMaybe<Scalars['uuid']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  staffType?: InputMaybe<Staff_Type_Enum>;
  taskGroupId?: InputMaybe<Scalars['uuid']>;
  taskId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tasks_Notifications_Sum_Fields = {
  __typename?: 'tasks_notifications_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "tasks_notifications" */
export type Tasks_Notifications_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "tasks_notifications" */
export type Tasks_Notifications_Update_Column =
  /** column name */
  | 'accountId'
  /** column name */
  | 'active'
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'clinicId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'patientId'
  /** column name */
  | 'staffId'
  /** column name */
  | 'staffType'
  /** column name */
  | 'taskGroupId'
  /** column name */
  | 'taskId'
  /** column name */
  | 'updatedAt';

export type Tasks_Notifications_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tasks_Notifications_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tasks_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Notifications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tasks_Notifications_Var_Pop_Fields = {
  __typename?: 'tasks_notifications_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tasks_notifications" */
export type Tasks_Notifications_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tasks_Notifications_Var_Samp_Fields = {
  __typename?: 'tasks_notifications_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tasks_notifications" */
export type Tasks_Notifications_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tasks_Notifications_Variance_Fields = {
  __typename?: 'tasks_notifications_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tasks_notifications" */
export type Tasks_Notifications_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "tasks" */
export type Tasks_Obj_Rel_Insert_Input = {
  data: Tasks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** on_conflict condition type for table "tasks" */
export type Tasks_On_Conflict = {
  constraint: Tasks_Constraint;
  update_columns?: Array<Tasks_Update_Column>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks". */
export type Tasks_Order_By = {
  active?: InputMaybe<Order_By>;
  completed?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  dueDateTime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  readCount?: InputMaybe<Order_By>;
  responsibleCount?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  status?: InputMaybe<Order_By>;
  subType?: InputMaybe<Order_By>;
  task_priority?: InputMaybe<Task_Priority_Order_By>;
  task_status?: InputMaybe<Task_Status_Order_By>;
  task_sub_type?: InputMaybe<Task_Sub_Type_Order_By>;
  task_type?: InputMaybe<Task_Type_Order_By>;
  tasks_assignments_aggregate?: InputMaybe<Tasks_Assignments_Aggregate_Order_By>;
  tasks_files_aggregate?: InputMaybe<Tasks_Files_Aggregate_Order_By>;
  tasks_notifications_aggregate?: InputMaybe<Tasks_Notifications_Aggregate_Order_By>;
  tasks_reads_aggregate?: InputMaybe<Tasks_Read_Aggregate_Order_By>;
  tasks_responsible_count?: InputMaybe<Tasks_Responsible_Order_By>;
  tasks_watches_aggregate?: InputMaybe<Tasks_Watch_Aggregate_Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tasks */
export type Tasks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "tasks_read" */
export type Tasks_Read = {
  __typename?: 'tasks_read';
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  staff: Staff;
  staffId: Scalars['uuid'];
  /** An object relationship */
  task: Tasks;
  taskId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "tasks_read" */
export type Tasks_Read_Aggregate = {
  __typename?: 'tasks_read_aggregate';
  aggregate?: Maybe<Tasks_Read_Aggregate_Fields>;
  nodes: Array<Tasks_Read>;
};

export type Tasks_Read_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tasks_Read_Aggregate_Bool_Exp_Count>;
};

export type Tasks_Read_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tasks_Read_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Read_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tasks_read" */
export type Tasks_Read_Aggregate_Fields = {
  __typename?: 'tasks_read_aggregate_fields';
  avg?: Maybe<Tasks_Read_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tasks_Read_Max_Fields>;
  min?: Maybe<Tasks_Read_Min_Fields>;
  stddev?: Maybe<Tasks_Read_Stddev_Fields>;
  stddev_pop?: Maybe<Tasks_Read_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tasks_Read_Stddev_Samp_Fields>;
  sum?: Maybe<Tasks_Read_Sum_Fields>;
  var_pop?: Maybe<Tasks_Read_Var_Pop_Fields>;
  var_samp?: Maybe<Tasks_Read_Var_Samp_Fields>;
  variance?: Maybe<Tasks_Read_Variance_Fields>;
};

/** aggregate fields of "tasks_read" */
export type Tasks_Read_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tasks_Read_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tasks_read" */
export type Tasks_Read_Aggregate_Order_By = {
  avg?: InputMaybe<Tasks_Read_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Read_Max_Order_By>;
  min?: InputMaybe<Tasks_Read_Min_Order_By>;
  stddev?: InputMaybe<Tasks_Read_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tasks_Read_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tasks_Read_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tasks_Read_Sum_Order_By>;
  var_pop?: InputMaybe<Tasks_Read_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tasks_Read_Var_Samp_Order_By>;
  variance?: InputMaybe<Tasks_Read_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tasks_read" */
export type Tasks_Read_Arr_Rel_Insert_Input = {
  data: Array<Tasks_Read_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_Read_On_Conflict>;
};

/** aggregate avg on columns */
export type Tasks_Read_Avg_Fields = {
  __typename?: 'tasks_read_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tasks_read" */
export type Tasks_Read_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks_read". All fields are combined with a logical 'AND'. */
export type Tasks_Read_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Read_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Read_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Read_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  taskId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tasks_read" */
export type Tasks_Read_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'tasks_read_pkey'
  /** unique or primary key constraint on columns "taskId", "staffId" */
  | 'tasks_read_taskId_staffId_key';

/** input type for incrementing numeric columns in table "tasks_read" */
export type Tasks_Read_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "tasks_read" */
export type Tasks_Read_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  taskId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tasks_Read_Max_Fields = {
  __typename?: 'tasks_read_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  staffId?: Maybe<Scalars['uuid']>;
  taskId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tasks_read" */
export type Tasks_Read_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  taskId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tasks_Read_Min_Fields = {
  __typename?: 'tasks_read_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  staffId?: Maybe<Scalars['uuid']>;
  taskId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tasks_read" */
export type Tasks_Read_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  taskId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tasks_read" */
export type Tasks_Read_Mutation_Response = {
  __typename?: 'tasks_read_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks_Read>;
};

/** on_conflict condition type for table "tasks_read" */
export type Tasks_Read_On_Conflict = {
  constraint: Tasks_Read_Constraint;
  update_columns?: Array<Tasks_Read_Update_Column>;
  where?: InputMaybe<Tasks_Read_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks_read". */
export type Tasks_Read_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  taskId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tasks_read */
export type Tasks_Read_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "tasks_read" */
export type Tasks_Read_Select_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'staffId'
  /** column name */
  | 'taskId'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "tasks_read" */
export type Tasks_Read_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  taskId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tasks_Read_Stddev_Fields = {
  __typename?: 'tasks_read_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tasks_read" */
export type Tasks_Read_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tasks_Read_Stddev_Pop_Fields = {
  __typename?: 'tasks_read_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tasks_read" */
export type Tasks_Read_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tasks_Read_Stddev_Samp_Fields = {
  __typename?: 'tasks_read_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tasks_read" */
export type Tasks_Read_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tasks_read" */
export type Tasks_Read_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Read_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Read_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  taskId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tasks_Read_Sum_Fields = {
  __typename?: 'tasks_read_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "tasks_read" */
export type Tasks_Read_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "tasks_read" */
export type Tasks_Read_Update_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'staffId'
  /** column name */
  | 'taskId'
  /** column name */
  | 'updatedAt';

export type Tasks_Read_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tasks_Read_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tasks_Read_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Read_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tasks_Read_Var_Pop_Fields = {
  __typename?: 'tasks_read_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tasks_read" */
export type Tasks_Read_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tasks_Read_Var_Samp_Fields = {
  __typename?: 'tasks_read_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tasks_read" */
export type Tasks_Read_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tasks_Read_Variance_Fields = {
  __typename?: 'tasks_read_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tasks_read" */
export type Tasks_Read_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tasks_responsible" */
export type Tasks_Responsible = {
  __typename?: 'tasks_responsible';
  completed?: Maybe<Scalars['Boolean']>;
  readcount?: Maybe<Scalars['bigint']>;
  responsibleCount?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  task?: Maybe<Tasks>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "tasks_responsible" */
export type Tasks_Responsible_Aggregate = {
  __typename?: 'tasks_responsible_aggregate';
  aggregate?: Maybe<Tasks_Responsible_Aggregate_Fields>;
  nodes: Array<Tasks_Responsible>;
};

/** aggregate fields of "tasks_responsible" */
export type Tasks_Responsible_Aggregate_Fields = {
  __typename?: 'tasks_responsible_aggregate_fields';
  avg?: Maybe<Tasks_Responsible_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tasks_Responsible_Max_Fields>;
  min?: Maybe<Tasks_Responsible_Min_Fields>;
  stddev?: Maybe<Tasks_Responsible_Stddev_Fields>;
  stddev_pop?: Maybe<Tasks_Responsible_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tasks_Responsible_Stddev_Samp_Fields>;
  sum?: Maybe<Tasks_Responsible_Sum_Fields>;
  var_pop?: Maybe<Tasks_Responsible_Var_Pop_Fields>;
  var_samp?: Maybe<Tasks_Responsible_Var_Samp_Fields>;
  variance?: Maybe<Tasks_Responsible_Variance_Fields>;
};

/** aggregate fields of "tasks_responsible" */
export type Tasks_Responsible_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tasks_Responsible_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tasks_Responsible_Avg_Fields = {
  __typename?: 'tasks_responsible_avg_fields';
  readcount?: Maybe<Scalars['Float']>;
  responsibleCount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tasks_responsible". All fields are combined with a logical 'AND'. */
export type Tasks_Responsible_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Responsible_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Responsible_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Responsible_Bool_Exp>>;
  completed?: InputMaybe<Boolean_Comparison_Exp>;
  readcount?: InputMaybe<Bigint_Comparison_Exp>;
  responsibleCount?: InputMaybe<Bigint_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  taskId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "tasks_responsible" */
export type Tasks_Responsible_Insert_Input = {
  completed?: InputMaybe<Scalars['Boolean']>;
  readcount?: InputMaybe<Scalars['bigint']>;
  responsibleCount?: InputMaybe<Scalars['bigint']>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  taskId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Tasks_Responsible_Max_Fields = {
  __typename?: 'tasks_responsible_max_fields';
  readcount?: Maybe<Scalars['bigint']>;
  responsibleCount?: Maybe<Scalars['bigint']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Tasks_Responsible_Min_Fields = {
  __typename?: 'tasks_responsible_min_fields';
  readcount?: Maybe<Scalars['bigint']>;
  responsibleCount?: Maybe<Scalars['bigint']>;
  taskId?: Maybe<Scalars['uuid']>;
};

/** input type for inserting object relation for remote table "tasks_responsible" */
export type Tasks_Responsible_Obj_Rel_Insert_Input = {
  data: Tasks_Responsible_Insert_Input;
};

/** Ordering options when selecting data from "tasks_responsible". */
export type Tasks_Responsible_Order_By = {
  completed?: InputMaybe<Order_By>;
  readcount?: InputMaybe<Order_By>;
  responsibleCount?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  taskId?: InputMaybe<Order_By>;
};

/** select columns of table "tasks_responsible" */
export type Tasks_Responsible_Select_Column =
  /** column name */
  | 'completed'
  /** column name */
  | 'readcount'
  /** column name */
  | 'responsibleCount'
  /** column name */
  | 'taskId';

/** aggregate stddev on columns */
export type Tasks_Responsible_Stddev_Fields = {
  __typename?: 'tasks_responsible_stddev_fields';
  readcount?: Maybe<Scalars['Float']>;
  responsibleCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tasks_Responsible_Stddev_Pop_Fields = {
  __typename?: 'tasks_responsible_stddev_pop_fields';
  readcount?: Maybe<Scalars['Float']>;
  responsibleCount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tasks_Responsible_Stddev_Samp_Fields = {
  __typename?: 'tasks_responsible_stddev_samp_fields';
  readcount?: Maybe<Scalars['Float']>;
  responsibleCount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "tasks_responsible" */
export type Tasks_Responsible_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Responsible_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Responsible_Stream_Cursor_Value_Input = {
  completed?: InputMaybe<Scalars['Boolean']>;
  readcount?: InputMaybe<Scalars['bigint']>;
  responsibleCount?: InputMaybe<Scalars['bigint']>;
  taskId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Tasks_Responsible_Sum_Fields = {
  __typename?: 'tasks_responsible_sum_fields';
  readcount?: Maybe<Scalars['bigint']>;
  responsibleCount?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Tasks_Responsible_Var_Pop_Fields = {
  __typename?: 'tasks_responsible_var_pop_fields';
  readcount?: Maybe<Scalars['Float']>;
  responsibleCount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tasks_Responsible_Var_Samp_Fields = {
  __typename?: 'tasks_responsible_var_samp_fields';
  readcount?: Maybe<Scalars['Float']>;
  responsibleCount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tasks_Responsible_Variance_Fields = {
  __typename?: 'tasks_responsible_variance_fields';
  readcount?: Maybe<Scalars['Float']>;
  responsibleCount?: Maybe<Scalars['Float']>;
};

/** select columns of table "tasks" */
export type Tasks_Select_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'dueDateTime'
  /** column name */
  | 'id'
  /** column name */
  | 'note'
  /** column name */
  | 'priority'
  /** column name */
  | 'status'
  /** column name */
  | 'subType'
  /** column name */
  | 'title'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt';

/** select "tasks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tasks" */
export type Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns =
  /** column name */
  'active';

/** select "tasks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tasks" */
export type Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns =
  /** column name */
  'active';

/** input type for updating data in table "tasks" */
export type Tasks_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  dueDateTime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Task_Priority_Enum>;
  status?: InputMaybe<Task_Status_Enum>;
  subType?: InputMaybe<Task_Sub_Type_Enum>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Task_Type_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "tasks" */
export type Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['uuid']>;
  dueDateTime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Task_Priority_Enum>;
  status?: InputMaybe<Task_Status_Enum>;
  subType?: InputMaybe<Task_Sub_Type_Enum>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Task_Type_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "tasks" */
export type Tasks_Update_Column =
  /** column name */
  | 'active'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'dueDateTime'
  /** column name */
  | 'id'
  /** column name */
  | 'note'
  /** column name */
  | 'priority'
  /** column name */
  | 'status'
  /** column name */
  | 'subType'
  /** column name */
  | 'title'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt';

export type Tasks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Bool_Exp;
};

/** columns and relationships of "tasks_watch" */
export type Tasks_Watch = {
  __typename?: 'tasks_watch';
  createdAt: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  staff: Staff;
  staffId: Scalars['uuid'];
  /** An object relationship */
  task: Tasks;
  taskId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "tasks_watch" */
export type Tasks_Watch_Aggregate = {
  __typename?: 'tasks_watch_aggregate';
  aggregate?: Maybe<Tasks_Watch_Aggregate_Fields>;
  nodes: Array<Tasks_Watch>;
};

export type Tasks_Watch_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tasks_Watch_Aggregate_Bool_Exp_Count>;
};

export type Tasks_Watch_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tasks_Watch_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Watch_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tasks_watch" */
export type Tasks_Watch_Aggregate_Fields = {
  __typename?: 'tasks_watch_aggregate_fields';
  avg?: Maybe<Tasks_Watch_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tasks_Watch_Max_Fields>;
  min?: Maybe<Tasks_Watch_Min_Fields>;
  stddev?: Maybe<Tasks_Watch_Stddev_Fields>;
  stddev_pop?: Maybe<Tasks_Watch_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tasks_Watch_Stddev_Samp_Fields>;
  sum?: Maybe<Tasks_Watch_Sum_Fields>;
  var_pop?: Maybe<Tasks_Watch_Var_Pop_Fields>;
  var_samp?: Maybe<Tasks_Watch_Var_Samp_Fields>;
  variance?: Maybe<Tasks_Watch_Variance_Fields>;
};

/** aggregate fields of "tasks_watch" */
export type Tasks_Watch_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tasks_Watch_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tasks_watch" */
export type Tasks_Watch_Aggregate_Order_By = {
  avg?: InputMaybe<Tasks_Watch_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Watch_Max_Order_By>;
  min?: InputMaybe<Tasks_Watch_Min_Order_By>;
  stddev?: InputMaybe<Tasks_Watch_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tasks_Watch_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tasks_Watch_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tasks_Watch_Sum_Order_By>;
  var_pop?: InputMaybe<Tasks_Watch_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tasks_Watch_Var_Samp_Order_By>;
  variance?: InputMaybe<Tasks_Watch_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tasks_watch" */
export type Tasks_Watch_Arr_Rel_Insert_Input = {
  data: Array<Tasks_Watch_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_Watch_On_Conflict>;
};

/** aggregate avg on columns */
export type Tasks_Watch_Avg_Fields = {
  __typename?: 'tasks_watch_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tasks_watch" */
export type Tasks_Watch_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks_watch". All fields are combined with a logical 'AND'. */
export type Tasks_Watch_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Watch_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Watch_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Watch_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  staff?: InputMaybe<Staff_Bool_Exp>;
  staffId?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  taskId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tasks_watch" */
export type Tasks_Watch_Constraint =
  /** unique or primary key constraint on columns "id" */
  | 'tasks_watch_pkey'
  /** unique or primary key constraint on columns "taskId", "staffId" */
  | 'tasks_watch_staffId_taskId_key';

/** input type for incrementing numeric columns in table "tasks_watch" */
export type Tasks_Watch_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "tasks_watch" */
export type Tasks_Watch_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  staff?: InputMaybe<Staff_Obj_Rel_Insert_Input>;
  staffId?: InputMaybe<Scalars['uuid']>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  taskId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tasks_Watch_Max_Fields = {
  __typename?: 'tasks_watch_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  staffId?: Maybe<Scalars['uuid']>;
  taskId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tasks_watch" */
export type Tasks_Watch_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  taskId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tasks_Watch_Min_Fields = {
  __typename?: 'tasks_watch_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  staffId?: Maybe<Scalars['uuid']>;
  taskId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tasks_watch" */
export type Tasks_Watch_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staffId?: InputMaybe<Order_By>;
  taskId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tasks_watch" */
export type Tasks_Watch_Mutation_Response = {
  __typename?: 'tasks_watch_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks_Watch>;
};

/** on_conflict condition type for table "tasks_watch" */
export type Tasks_Watch_On_Conflict = {
  constraint: Tasks_Watch_Constraint;
  update_columns?: Array<Tasks_Watch_Update_Column>;
  where?: InputMaybe<Tasks_Watch_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks_watch". */
export type Tasks_Watch_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  staff?: InputMaybe<Staff_Order_By>;
  staffId?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  taskId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tasks_watch */
export type Tasks_Watch_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "tasks_watch" */
export type Tasks_Watch_Select_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'staffId'
  /** column name */
  | 'taskId'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "tasks_watch" */
export type Tasks_Watch_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  taskId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tasks_Watch_Stddev_Fields = {
  __typename?: 'tasks_watch_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tasks_watch" */
export type Tasks_Watch_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tasks_Watch_Stddev_Pop_Fields = {
  __typename?: 'tasks_watch_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tasks_watch" */
export type Tasks_Watch_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tasks_Watch_Stddev_Samp_Fields = {
  __typename?: 'tasks_watch_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tasks_watch" */
export type Tasks_Watch_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tasks_watch" */
export type Tasks_Watch_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Watch_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Watch_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  staffId?: InputMaybe<Scalars['uuid']>;
  taskId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tasks_Watch_Sum_Fields = {
  __typename?: 'tasks_watch_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "tasks_watch" */
export type Tasks_Watch_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "tasks_watch" */
export type Tasks_Watch_Update_Column =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'staffId'
  /** column name */
  | 'taskId'
  /** column name */
  | 'updatedAt';

export type Tasks_Watch_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tasks_Watch_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tasks_Watch_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Watch_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tasks_Watch_Var_Pop_Fields = {
  __typename?: 'tasks_watch_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tasks_watch" */
export type Tasks_Watch_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tasks_Watch_Var_Samp_Fields = {
  __typename?: 'tasks_watch_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tasks_watch" */
export type Tasks_Watch_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tasks_Watch_Variance_Fields = {
  __typename?: 'tasks_watch_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tasks_watch" */
export type Tasks_Watch_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type Time_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['time']>;
  _gt?: InputMaybe<Scalars['time']>;
  _gte?: InputMaybe<Scalars['time']>;
  _in?: InputMaybe<Array<Scalars['time']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['time']>;
  _lte?: InputMaybe<Scalars['time']>;
  _neq?: InputMaybe<Scalars['time']>;
  _nin?: InputMaybe<Array<Scalars['time']>>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "triage_schedule" */
export type Triage_Schedule = {
  __typename?: 'triage_schedule';
  endDateTime?: Maybe<Scalars['timestamp']>;
  firstName?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregated selection of "triage_schedule" */
export type Triage_Schedule_Aggregate = {
  __typename?: 'triage_schedule_aggregate';
  aggregate?: Maybe<Triage_Schedule_Aggregate_Fields>;
  nodes: Array<Triage_Schedule>;
};

/** aggregate fields of "triage_schedule" */
export type Triage_Schedule_Aggregate_Fields = {
  __typename?: 'triage_schedule_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Triage_Schedule_Max_Fields>;
  min?: Maybe<Triage_Schedule_Min_Fields>;
};

/** aggregate fields of "triage_schedule" */
export type Triage_Schedule_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Triage_Schedule_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "triage_schedule". All fields are combined with a logical 'AND'. */
export type Triage_Schedule_Bool_Exp = {
  _and?: InputMaybe<Array<Triage_Schedule_Bool_Exp>>;
  _not?: InputMaybe<Triage_Schedule_Bool_Exp>;
  _or?: InputMaybe<Array<Triage_Schedule_Bool_Exp>>;
  endDateTime?: InputMaybe<Timestamp_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  startDateTime?: InputMaybe<Timestamp_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Triage_Schedule_Max_Fields = {
  __typename?: 'triage_schedule_max_fields';
  endDateTime?: Maybe<Scalars['timestamp']>;
  firstName?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Triage_Schedule_Min_Fields = {
  __typename?: 'triage_schedule_min_fields';
  endDateTime?: Maybe<Scalars['timestamp']>;
  firstName?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['timestamp']>;
  username?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "triage_schedule". */
export type Triage_Schedule_Order_By = {
  endDateTime?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  startDateTime?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** select columns of table "triage_schedule" */
export type Triage_Schedule_Select_Column =
  /** column name */
  | 'endDateTime'
  /** column name */
  | 'firstName'
  /** column name */
  | 'startDateTime'
  /** column name */
  | 'username';

/** Streaming cursor of the table "triage_schedule" */
export type Triage_Schedule_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Triage_Schedule_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Triage_Schedule_Stream_Cursor_Value_Input = {
  endDateTime?: InputMaybe<Scalars['timestamp']>;
  firstName?: InputMaybe<Scalars['String']>;
  startDateTime?: InputMaybe<Scalars['timestamp']>;
  username?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An object relationship */
  account?: Maybe<Accounts>;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Accounts_Aggregate;
  /** An array relationship */
  appointments: Array<Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Appointments_Aggregate;
  auth0Email?: Maybe<Scalars['String']>;
  auth0PhoneNumber?: Maybe<Scalars['String']>;
  auth0UserId?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  insurances: Array<Insurance>;
  /** An aggregate relationship */
  insurances_aggregate: Insurance_Aggregate;
  lastName?: Maybe<Scalars['String']>;
  /** An array relationship */
  patients: Array<Patients>;
  /** An array relationship */
  patientsByConsentSignedByUserId: Array<Patients>;
  /** An aggregate relationship */
  patientsByConsentSignedByUserId_aggregate: Patients_Aggregate;
  /** An aggregate relationship */
  patients_aggregate: Patients_Aggregate;
  phoneNumber: Scalars['String'];
  /** An array relationship */
  symptom_checkers: Array<Symptom_Checker>;
  /** An aggregate relationship */
  symptom_checkers_aggregate: Symptom_Checker_Aggregate;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "users" */
export type UsersAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersInsurancesArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Order_By>>;
  where?: InputMaybe<Insurance_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersInsurances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Insurance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insurance_Order_By>>;
  where?: InputMaybe<Insurance_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPatientsByConsentSignedByUserIdArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPatientsByConsentSignedByUserId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPatients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersSymptom_CheckersArgs = {
  distinct_on?: InputMaybe<Array<Symptom_Checker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Symptom_Checker_Order_By>>;
  where?: InputMaybe<Symptom_Checker_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersSymptom_Checkers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Symptom_Checker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Symptom_Checker_Order_By>>;
  where?: InputMaybe<Symptom_Checker_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  accounts?: InputMaybe<Accounts_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Bool_Exp>;
  appointments?: InputMaybe<Appointments_Bool_Exp>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Bool_Exp>;
  auth0Email?: InputMaybe<String_Comparison_Exp>;
  auth0PhoneNumber?: InputMaybe<String_Comparison_Exp>;
  auth0UserId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  insurances?: InputMaybe<Insurance_Bool_Exp>;
  insurances_aggregate?: InputMaybe<Insurance_Aggregate_Bool_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  patients?: InputMaybe<Patients_Bool_Exp>;
  patientsByConsentSignedByUserId?: InputMaybe<Patients_Bool_Exp>;
  patientsByConsentSignedByUserId_aggregate?: InputMaybe<Patients_Aggregate_Bool_Exp>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Bool_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  symptom_checkers?: InputMaybe<Symptom_Checker_Bool_Exp>;
  symptom_checkers_aggregate?: InputMaybe<Symptom_Checker_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export type Users_Constraint =
  /** unique or primary key constraint on columns "phoneNumber" */
  | 'users_phoneNumber_key'
  /** unique or primary key constraint on columns "id" */
  | 'users_pkey';

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  accounts?: InputMaybe<Accounts_Arr_Rel_Insert_Input>;
  appointments?: InputMaybe<Appointments_Arr_Rel_Insert_Input>;
  auth0Email?: InputMaybe<Scalars['String']>;
  auth0PhoneNumber?: InputMaybe<Scalars['String']>;
  auth0UserId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  insurances?: InputMaybe<Insurance_Arr_Rel_Insert_Input>;
  lastName?: InputMaybe<Scalars['String']>;
  patients?: InputMaybe<Patients_Arr_Rel_Insert_Input>;
  patientsByConsentSignedByUserId?: InputMaybe<Patients_Arr_Rel_Insert_Input>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  symptom_checkers?: InputMaybe<Symptom_Checker_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  auth0Email?: Maybe<Scalars['String']>;
  auth0PhoneNumber?: Maybe<Scalars['String']>;
  auth0UserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  auth0Email?: Maybe<Scalars['String']>;
  auth0PhoneNumber?: Maybe<Scalars['String']>;
  auth0UserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Order_By>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Order_By>;
  auth0Email?: InputMaybe<Order_By>;
  auth0PhoneNumber?: InputMaybe<Order_By>;
  auth0UserId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurances_aggregate?: InputMaybe<Insurance_Aggregate_Order_By>;
  lastName?: InputMaybe<Order_By>;
  patientsByConsentSignedByUserId_aggregate?: InputMaybe<Patients_Aggregate_Order_By>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  symptom_checkers_aggregate?: InputMaybe<Symptom_Checker_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export type Users_Select_Column =
  /** column name */
  | 'auth0Email'
  /** column name */
  | 'auth0PhoneNumber'
  /** column name */
  | 'auth0UserId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'firstName'
  /** column name */
  | 'id'
  /** column name */
  | 'lastName'
  /** column name */
  | 'phoneNumber'
  /** column name */
  | 'updatedAt';

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  auth0Email?: InputMaybe<Scalars['String']>;
  auth0PhoneNumber?: InputMaybe<Scalars['String']>;
  auth0UserId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  auth0Email?: InputMaybe<Scalars['String']>;
  auth0PhoneNumber?: InputMaybe<Scalars['String']>;
  auth0UserId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "users" */
export type Users_Update_Column =
  /** column name */
  | 'auth0Email'
  /** column name */
  | 'auth0PhoneNumber'
  /** column name */
  | 'auth0UserId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'firstName'
  /** column name */
  | 'id'
  /** column name */
  | 'lastName'
  /** column name */
  | 'phoneNumber'
  /** column name */
  | 'updatedAt';

export type Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "visit_classifications" */
export type Visit_Classifications = {
  __typename?: 'visit_classifications';
  /** An array relationship */
  appointment_reservations: Array<Appointment_Reservations>;
  /** An aggregate relationship */
  appointment_reservations_aggregate: Appointment_Reservations_Aggregate;
  /** An array relationship */
  appointments: Array<Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Appointments_Aggregate;
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** columns and relationships of "visit_classifications" */
export type Visit_ClassificationsAppointment_ReservationsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Reservations_Order_By>>;
  where?: InputMaybe<Appointment_Reservations_Bool_Exp>;
};

/** columns and relationships of "visit_classifications" */
export type Visit_ClassificationsAppointment_Reservations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Reservations_Order_By>>;
  where?: InputMaybe<Appointment_Reservations_Bool_Exp>;
};

/** columns and relationships of "visit_classifications" */
export type Visit_ClassificationsAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** columns and relationships of "visit_classifications" */
export type Visit_ClassificationsAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** aggregated selection of "visit_classifications" */
export type Visit_Classifications_Aggregate = {
  __typename?: 'visit_classifications_aggregate';
  aggregate?: Maybe<Visit_Classifications_Aggregate_Fields>;
  nodes: Array<Visit_Classifications>;
};

/** aggregate fields of "visit_classifications" */
export type Visit_Classifications_Aggregate_Fields = {
  __typename?: 'visit_classifications_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Visit_Classifications_Max_Fields>;
  min?: Maybe<Visit_Classifications_Min_Fields>;
};

/** aggregate fields of "visit_classifications" */
export type Visit_Classifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Visit_Classifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "visit_classifications". All fields are combined with a logical 'AND'. */
export type Visit_Classifications_Bool_Exp = {
  _and?: InputMaybe<Array<Visit_Classifications_Bool_Exp>>;
  _not?: InputMaybe<Visit_Classifications_Bool_Exp>;
  _or?: InputMaybe<Array<Visit_Classifications_Bool_Exp>>;
  appointment_reservations?: InputMaybe<Appointment_Reservations_Bool_Exp>;
  appointment_reservations_aggregate?: InputMaybe<Appointment_Reservations_Aggregate_Bool_Exp>;
  appointments?: InputMaybe<Appointments_Bool_Exp>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "visit_classifications" */
export type Visit_Classifications_Constraint =
  /** unique or primary key constraint on columns "value" */
  'visit_classifications_pkey';

export type Visit_Classifications_Enum =
  /** COVID-19 Test */
  | 'COVID_19_TEST'
  /** COVID-19 Vaccination */
  | 'COVID_19_VACCINE'
  /** Flu Shot Vaccination */
  | 'FLU_VACCINE'
  /** Mental Health */
  | 'MENTAL_HEALTH'
  /** Sports Physical */
  | 'SPORTS_PHYSICAL';

/** Boolean expression to compare columns of type "visit_classifications_enum". All fields are combined with logical 'AND'. */
export type Visit_Classifications_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Visit_Classifications_Enum>;
  _in?: InputMaybe<Array<Visit_Classifications_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Visit_Classifications_Enum>;
  _nin?: InputMaybe<Array<Visit_Classifications_Enum>>;
};

/** input type for inserting data into table "visit_classifications" */
export type Visit_Classifications_Insert_Input = {
  appointment_reservations?: InputMaybe<Appointment_Reservations_Arr_Rel_Insert_Input>;
  appointments?: InputMaybe<Appointments_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Visit_Classifications_Max_Fields = {
  __typename?: 'visit_classifications_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Visit_Classifications_Min_Fields = {
  __typename?: 'visit_classifications_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "visit_classifications" */
export type Visit_Classifications_Mutation_Response = {
  __typename?: 'visit_classifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Visit_Classifications>;
};

/** input type for inserting object relation for remote table "visit_classifications" */
export type Visit_Classifications_Obj_Rel_Insert_Input = {
  data: Visit_Classifications_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Visit_Classifications_On_Conflict>;
};

/** on_conflict condition type for table "visit_classifications" */
export type Visit_Classifications_On_Conflict = {
  constraint: Visit_Classifications_Constraint;
  update_columns?: Array<Visit_Classifications_Update_Column>;
  where?: InputMaybe<Visit_Classifications_Bool_Exp>;
};

/** Ordering options when selecting data from "visit_classifications". */
export type Visit_Classifications_Order_By = {
  appointment_reservations_aggregate?: InputMaybe<Appointment_Reservations_Aggregate_Order_By>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: visit_classifications */
export type Visit_Classifications_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "visit_classifications" */
export type Visit_Classifications_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "visit_classifications" */
export type Visit_Classifications_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "visit_classifications" */
export type Visit_Classifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Visit_Classifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Visit_Classifications_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "visit_classifications" */
export type Visit_Classifications_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Visit_Classifications_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Visit_Classifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Visit_Classifications_Bool_Exp;
};

/** columns and relationships of "visit_types" */
export type Visit_Types = {
  __typename?: 'visit_types';
  /** An array relationship */
  appointment_reservations: Array<Appointment_Reservations>;
  /** An aggregate relationship */
  appointment_reservations_aggregate: Appointment_Reservations_Aggregate;
  /** An array relationship */
  appointments: Array<Appointments>;
  /** An aggregate relationship */
  appointments_aggregate: Appointments_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "visit_types" */
export type Visit_TypesAppointment_ReservationsArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Reservations_Order_By>>;
  where?: InputMaybe<Appointment_Reservations_Bool_Exp>;
};

/** columns and relationships of "visit_types" */
export type Visit_TypesAppointment_Reservations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointment_Reservations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointment_Reservations_Order_By>>;
  where?: InputMaybe<Appointment_Reservations_Bool_Exp>;
};

/** columns and relationships of "visit_types" */
export type Visit_TypesAppointmentsArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** columns and relationships of "visit_types" */
export type Visit_TypesAppointments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Appointments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Appointments_Order_By>>;
  where?: InputMaybe<Appointments_Bool_Exp>;
};

/** aggregated selection of "visit_types" */
export type Visit_Types_Aggregate = {
  __typename?: 'visit_types_aggregate';
  aggregate?: Maybe<Visit_Types_Aggregate_Fields>;
  nodes: Array<Visit_Types>;
};

/** aggregate fields of "visit_types" */
export type Visit_Types_Aggregate_Fields = {
  __typename?: 'visit_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Visit_Types_Max_Fields>;
  min?: Maybe<Visit_Types_Min_Fields>;
};

/** aggregate fields of "visit_types" */
export type Visit_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Visit_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "visit_types". All fields are combined with a logical 'AND'. */
export type Visit_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Visit_Types_Bool_Exp>>;
  _not?: InputMaybe<Visit_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Visit_Types_Bool_Exp>>;
  appointment_reservations?: InputMaybe<Appointment_Reservations_Bool_Exp>;
  appointment_reservations_aggregate?: InputMaybe<Appointment_Reservations_Aggregate_Bool_Exp>;
  appointments?: InputMaybe<Appointments_Bool_Exp>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "visit_types" */
export type Visit_Types_Constraint =
  /** unique or primary key constraint on columns "value" */
  'visit_types_pkey';

export type Visit_Types_Enum =
  /** Meet and Greet */
  | 'MEET_AND_GREET'
  /** Primary Care */
  | 'PRIMARY_CARE'
  /** Telemedicine */
  | 'TELEMEDICINE'
  /** Testing */
  | 'TESTING'
  /** Urgent Care */
  | 'URGENT_CARE'
  /** Vaccination */
  | 'VACCINATION';

/** Boolean expression to compare columns of type "visit_types_enum". All fields are combined with logical 'AND'. */
export type Visit_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Visit_Types_Enum>;
  _in?: InputMaybe<Array<Visit_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Visit_Types_Enum>;
  _nin?: InputMaybe<Array<Visit_Types_Enum>>;
};

/** input type for inserting data into table "visit_types" */
export type Visit_Types_Insert_Input = {
  appointment_reservations?: InputMaybe<Appointment_Reservations_Arr_Rel_Insert_Input>;
  appointments?: InputMaybe<Appointments_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Visit_Types_Max_Fields = {
  __typename?: 'visit_types_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Visit_Types_Min_Fields = {
  __typename?: 'visit_types_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "visit_types" */
export type Visit_Types_Mutation_Response = {
  __typename?: 'visit_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Visit_Types>;
};

/** input type for inserting object relation for remote table "visit_types" */
export type Visit_Types_Obj_Rel_Insert_Input = {
  data: Visit_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Visit_Types_On_Conflict>;
};

/** on_conflict condition type for table "visit_types" */
export type Visit_Types_On_Conflict = {
  constraint: Visit_Types_Constraint;
  update_columns?: Array<Visit_Types_Update_Column>;
  where?: InputMaybe<Visit_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "visit_types". */
export type Visit_Types_Order_By = {
  appointment_reservations_aggregate?: InputMaybe<Appointment_Reservations_Aggregate_Order_By>;
  appointments_aggregate?: InputMaybe<Appointments_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: visit_types */
export type Visit_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "visit_types" */
export type Visit_Types_Select_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

/** input type for updating data in table "visit_types" */
export type Visit_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "visit_types" */
export type Visit_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Visit_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Visit_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "visit_types" */
export type Visit_Types_Update_Column =
  /** column name */
  | 'description'
  /** column name */
  | 'value';

export type Visit_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Visit_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Visit_Types_Bool_Exp;
};

export type AddAccountMutationVariables = Exact<{
  patientId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  relationship?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  preferredPronounType?: InputMaybe<Scalars['String']>;
  preferredPronoun?: InputMaybe<Scalars['String']>;
}>;

export type AddAccountMutation = {
  __typename?: 'mutation_root';
  AddAccount?: { __typename?: 'AddAccountOutput'; id: string } | null;
};

export type UpdateAccountMutationVariables = Exact<{
  set: Accounts_Set_Input;
  where: Accounts_Bool_Exp;
}>;

export type UpdateAccountMutation = {
  __typename?: 'mutation_root';
  update_accounts?: {
    __typename?: 'accounts_mutation_response';
    returning: Array<{
      __typename?: 'accounts';
      id: string;
      createdAt: string;
      consentToMedicalTermsTimestamp?: string | null;
      consentToTermsAndPrivacyTimestamp?: string | null;
      email?: string | null;
      firstName?: string | null;
      goesByName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      postalCode?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
    }>;
  } | null;
};

export type UpdateAccountByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  set: Accounts_Set_Input;
}>;

export type UpdateAccountByPkMutation = {
  __typename?: 'mutation_root';
  update_accounts_by_pk?: {
    __typename?: 'accounts';
    id: string;
    createdAt: string;
    consentToMedicalTermsTimestamp?: string | null;
    consentToTermsAndPrivacyTimestamp?: string | null;
    email?: string | null;
    firstName?: string | null;
    goesByName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    postalCode?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
  } | null;
};

export type LoadAccountQueryVariables = Exact<{
  accountId: Scalars['uuid'];
}>;

export type LoadAccountQuery = {
  __typename?: 'query_root';
  accounts_by_pk?: {
    __typename?: 'accounts';
    id: string;
    createdAt: string;
    consentToMedicalTermsTimestamp?: string | null;
    consentToTermsAndPrivacyTimestamp?: string | null;
    email?: string | null;
    firstName?: string | null;
    goesByName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    postalCode?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
  } | null;
};

export type FindAccountQueryVariables = Exact<{
  where: Accounts_Bool_Exp;
}>;

export type FindAccountQuery = {
  __typename?: 'query_root';
  accounts: Array<{
    __typename?: 'accounts';
    id: string;
    createdAt: string;
    consentToMedicalTermsTimestamp?: string | null;
    consentToTermsAndPrivacyTimestamp?: string | null;
    email?: string | null;
    firstName?: string | null;
    goesByName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    postalCode?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
  }>;
};

export type GetAccountByPkQueryVariables = Exact<{
  accountId: Scalars['uuid'];
}>;

export type GetAccountByPkQuery = {
  __typename?: 'query_root';
  accounts_by_pk?: {
    __typename?: 'accounts';
    stripeCustomerId?: string | null;
    id: string;
    createdAt: string;
    consentToMedicalTermsTimestamp?: string | null;
    consentToTermsAndPrivacyTimestamp?: string | null;
    email?: string | null;
    firstName?: string | null;
    goesByName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    postalCode?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
    account_patients: Array<{
      __typename?: 'account_patient';
      id: string;
      accountId: string;
      patientId: string;
      relationship?: string | null;
      patient: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
        goesByName?: string | null;
        pronunciation?: string | null;
        dateOfBirth: string;
        deceasedAt?: string | null;
        consentSignedByUserId?: string | null;
        consentToTelemedicineTermsTimestamp?: string | null;
        additionalInfo?: string | null;
        consentSignedAt?: string | null;
        consentSigned?: boolean | null;
        avatarId?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
        genderAtBirth?: string | null;
        primaryCareStaffId?: string | null;
        primaryClinicAddress?: any | null;
        physicianName?: string | null;
        preferredPharmacy?: any | null;
        address?: any | null;
        hasPCP?: string | null;
        lastWellVisitStatus?: string | null;
        vaccinationStatus?: string | null;
        kareo_patient_id?: string | null;
        kareo_guid?: string | null;
        consentSignedByUser?: {
          __typename?: 'users';
          account?: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          } | null;
        } | null;
        consentToTelemedicineTermsByAccount?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
        parsed_address?: {
          __typename?: 'patient_address';
          fullAddress?: string | null;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          zip?: string | null;
          state?: string | null;
        } | null;
        discovered_froms: Array<{
          __typename?: 'discovered_from';
          description?: string | null;
          type: string;
          account: {
            __typename?: 'accounts';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
          };
        }>;
      };
    }>;
  } | null;
};

export type GetAccountByPkWithRelatedQueryVariables = Exact<{
  accountId: Scalars['uuid'];
}>;

export type GetAccountByPkWithRelatedQuery = {
  __typename?: 'query_root';
  accounts_by_pk?: {
    __typename?: 'accounts';
    stripeCustomerId?: string | null;
    id: string;
    createdAt: string;
    consentToMedicalTermsTimestamp?: string | null;
    consentToTermsAndPrivacyTimestamp?: string | null;
    email?: string | null;
    firstName?: string | null;
    goesByName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    postalCode?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
    account_patients: Array<{
      __typename?: 'account_patient';
      id: string;
      accountId: string;
      patientId: string;
      relationship?: string | null;
      patient: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
        goesByName?: string | null;
        pronunciation?: string | null;
        dateOfBirth: string;
        deceasedAt?: string | null;
        consentSignedByUserId?: string | null;
        consentToTelemedicineTermsTimestamp?: string | null;
        additionalInfo?: string | null;
        consentSignedAt?: string | null;
        consentSigned?: boolean | null;
        avatarId?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
        genderAtBirth?: string | null;
        primaryCareStaffId?: string | null;
        primaryClinicAddress?: any | null;
        physicianName?: string | null;
        preferredPharmacy?: any | null;
        address?: any | null;
        hasPCP?: string | null;
        lastWellVisitStatus?: string | null;
        vaccinationStatus?: string | null;
        kareo_patient_id?: string | null;
        kareo_guid?: string | null;
        accounts: Array<{
          __typename?: 'account_patient';
          id: string;
          accountId: string;
          relationship?: string | null;
          account_limited?: {
            __typename?: 'accounts_limited';
            id?: string | null;
            firstName?: string | null;
            lastName?: string | null;
            goesByName?: string | null;
          } | null;
        }>;
        consentSignedByUser?: {
          __typename?: 'users';
          account?: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          } | null;
        } | null;
        consentToTelemedicineTermsByAccount?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
        parsed_address?: {
          __typename?: 'patient_address';
          fullAddress?: string | null;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          zip?: string | null;
          state?: string | null;
        } | null;
      };
    }>;
  } | null;
};

export type GetAccountChargesQueryVariables = Exact<{
  where: Charges_Bool_Exp;
}>;

export type GetAccountChargesQuery = {
  __typename?: 'query_root';
  charges: Array<{
    __typename?: 'charges';
    id: string;
    medicalAssistantId?: string | null;
    amount: number;
    accountId?: string | null;
    appointmentId?: string | null;
    cardId?: string | null;
    clinicId?: string | null;
    created_at: string;
    patientId?: string | null;
    stripeChargeId: string;
    updated_at: string;
    charge_products: Array<{
      __typename?: 'charge_products';
      id: string;
      amount: number;
      type?: string | null;
      quantity: number;
      chargeId: string;
      created_at: string;
      updated_at: string;
      productId: string;
      product: {
        __typename?: 'products';
        id: string;
        name: string;
        price: number;
        type: string;
      };
    }>;
  }>;
};

export type UpdateAccountPhoneMutationVariables = Exact<{
  accountId: Scalars['String'];
  phoneNumber: Scalars['String'];
}>;

export type UpdateAccountPhoneMutation = {
  __typename?: 'mutation_root';
  UpdateAccountPhone?: {
    __typename?: 'UpdateAccountPhoneOutput';
    success: boolean;
  } | null;
};

export type LoadGlobalNotesQueryVariables = Exact<{
  where?: InputMaybe<Global_Notes_Bool_Exp>;
  orderBy?: InputMaybe<Array<Global_Notes_Order_By> | Global_Notes_Order_By>;
}>;

export type LoadGlobalNotesQuery = {
  __typename?: 'query_root';
  global_notes: Array<{
    __typename?: 'global_notes';
    id: string;
    appointmentId?: string | null;
    deleted: boolean;
    patientId?: string | null;
    accountId?: string | null;
    updatedAt: string;
    createdAt: string;
    note: string;
    type?: string | null;
    staffId?: string | null;
    staff?: {
      __typename?: 'staff';
      id: string;
      active: boolean;
      firstName: string;
      lastName: string;
      auth0UserId?: string | null;
      created_at: string;
      defaultClinicId?: string | null;
      timeZone: string;
      type: Staff_Type_Enum;
      updated_at: string;
      email?: string | null;
      avatarUrl?: string | null;
      color?: string | null;
      lead: boolean;
      staffManagerId?: string | null;
      staff_type: {
        __typename?: 'staff_type';
        description: string;
        value: string;
      };
    } | null;
  }>;
};

export type WatchGlobalNotesSubscriptionVariables = Exact<{
  where?: InputMaybe<Global_Notes_Bool_Exp>;
  orderBy?: InputMaybe<Array<Global_Notes_Order_By> | Global_Notes_Order_By>;
}>;

export type WatchGlobalNotesSubscription = {
  __typename?: 'subscription_root';
  global_notes: Array<{
    __typename?: 'global_notes';
    id: string;
    appointmentId?: string | null;
    deleted: boolean;
    patientId?: string | null;
    accountId?: string | null;
    updatedAt: string;
    createdAt: string;
    note: string;
    type?: string | null;
    staffId?: string | null;
    staff?: {
      __typename?: 'staff';
      id: string;
      active: boolean;
      firstName: string;
      lastName: string;
      auth0UserId?: string | null;
      created_at: string;
      defaultClinicId?: string | null;
      timeZone: string;
      type: Staff_Type_Enum;
      updated_at: string;
      email?: string | null;
      avatarUrl?: string | null;
      color?: string | null;
      lead: boolean;
      staffManagerId?: string | null;
      staff_type: {
        __typename?: 'staff_type';
        description: string;
        value: string;
      };
    } | null;
  }>;
};

export type AddGlobalNoteMutationVariables = Exact<{
  globalNote: Global_Notes_Insert_Input;
}>;

export type AddGlobalNoteMutation = {
  __typename?: 'mutation_root';
  insert_global_notes_one?: {
    __typename?: 'global_notes';
    id: string;
    appointmentId?: string | null;
    deleted: boolean;
    patientId?: string | null;
    accountId?: string | null;
    updatedAt: string;
    createdAt: string;
    note: string;
    type?: string | null;
    staffId?: string | null;
    staff?: {
      __typename?: 'staff';
      id: string;
      active: boolean;
      firstName: string;
      lastName: string;
      auth0UserId?: string | null;
      created_at: string;
      defaultClinicId?: string | null;
      timeZone: string;
      type: Staff_Type_Enum;
      updated_at: string;
      email?: string | null;
      avatarUrl?: string | null;
      color?: string | null;
      lead: boolean;
      staffManagerId?: string | null;
      staff_type: {
        __typename?: 'staff_type';
        description: string;
        value: string;
      };
    } | null;
  } | null;
};

export type DeleteGlobalNoteMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteGlobalNoteMutation = {
  __typename?: 'mutation_root';
  update_global_notes_by_pk?: {
    __typename?: 'global_notes';
    id: string;
  } | null;
};

export type AppointmentScheduleQueryVariables = Exact<{
  where: Appointments_Bool_Exp;
}>;

export type AppointmentScheduleQuery = {
  __typename?: 'query_root';
  appointments: Array<{
    __typename?: 'appointments';
    id: string;
    accountId?: string | null;
    address?: any | null;
    campaign?: string | null;
    clinicId: string;
    createdAt: string;
    email?: string | null;
    endTime: string;
    firstName?: string | null;
    kareo_appointment_id?: string | null;
    kareo_appointment_uuid?: string | null;
    languageSelection?: string | null;
    lastName?: string | null;
    notes?: string | null;
    patientId?: string | null;
    payment?: Appointment_Payment_Enum | null;
    phoneNumber?: string | null;
    reason: string;
    safetyQuestionnaire?: any | null;
    startTime: string;
    state?: string | null;
    status?: Appointment_Status_Enum | null;
    stripeCardId?: string | null;
    stripeCustomerId?: string | null;
    timeZone?: string | null;
    type?: Appointment_Types_Enum | null;
    visitClassification?: Visit_Classifications_Enum | null;
    visitType?: Visit_Types_Enum | null;
    reviews: Array<{
      __typename?: 'reviews';
      id: string;
      review?: string | null;
      createdAt: string;
      updatedAt: string;
      nps?: number | null;
      appointmentId?: string | null;
    }>;
    clinic: {
      __typename?: 'clinics';
      id: string;
      address: string;
      clinicCode: string;
      close: string;
      created_at: string;
      latitude?: string | null;
      locationType: Clinic_Location_Types_Enum;
      longitude?: string | null;
      name: string;
      open: string;
      parentClinicId?: string | null;
      phoneNumber: string;
      status: Clinic_Status_Enum;
      timeZone?: string | null;
      updated_at: string;
    };
    appointment_staffs: Array<{
      __typename?: 'appointment_staff';
      id: string;
      appointmentId: string;
      staffId: string;
      created_at: string;
      updated_at: string;
      staff: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      };
    }>;
    appointment_patients: Array<{
      __typename?: 'appointment_patient';
      id: string;
      appointment_id: string;
      created_at: string;
      updated_at: string;
      patient_id: string;
      patient: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
        goesByName?: string | null;
        pronunciation?: string | null;
        dateOfBirth: string;
        deceasedAt?: string | null;
        consentSignedByUserId?: string | null;
        consentToTelemedicineTermsTimestamp?: string | null;
        additionalInfo?: string | null;
        consentSignedAt?: string | null;
        consentSigned?: boolean | null;
        avatarId?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
        genderAtBirth?: string | null;
        primaryCareStaffId?: string | null;
        primaryClinicAddress?: any | null;
        physicianName?: string | null;
        preferredPharmacy?: any | null;
        address?: any | null;
        hasPCP?: string | null;
        lastWellVisitStatus?: string | null;
        vaccinationStatus?: string | null;
        kareo_patient_id?: string | null;
        kareo_guid?: string | null;
        global_notes: Array<{
          __typename?: 'global_notes';
          id: string;
          appointmentId?: string | null;
          deleted: boolean;
          patientId?: string | null;
          accountId?: string | null;
          updatedAt: string;
          createdAt: string;
          note: string;
          type?: string | null;
          staffId?: string | null;
          staff?: {
            __typename?: 'staff';
            id: string;
            active: boolean;
            firstName: string;
            lastName: string;
            auth0UserId?: string | null;
            created_at: string;
            defaultClinicId?: string | null;
            timeZone: string;
            type: Staff_Type_Enum;
            updated_at: string;
            email?: string | null;
            avatarUrl?: string | null;
            color?: string | null;
            lead: boolean;
            staffManagerId?: string | null;
            staff_type: {
              __typename?: 'staff_type';
              description: string;
              value: string;
            };
          } | null;
        }>;
        insurances: Array<{
          __typename?: 'insurance';
          id: string;
          accountId: string;
          backId?: string | null;
          carrier?: string | null;
          dateOfBirth?: string | null;
          eligibility?: any | null;
          firstName?: string | null;
          frontId?: string | null;
          lastName?: string | null;
          memberId?: string | null;
          type: Insurance_Types_Enum;
          updatedAt: string;
        }>;
        accounts: Array<{
          __typename?: 'account_patient';
          id: string;
          accountId: string;
          patientId: string;
          relationship?: string | null;
          account: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
            global_notes: Array<{
              __typename?: 'global_notes';
              id: string;
              appointmentId?: string | null;
              deleted: boolean;
              patientId?: string | null;
              accountId?: string | null;
              updatedAt: string;
              createdAt: string;
              note: string;
              type?: string | null;
              staffId?: string | null;
              staff?: {
                __typename?: 'staff';
                id: string;
                active: boolean;
                firstName: string;
                lastName: string;
                auth0UserId?: string | null;
                created_at: string;
                defaultClinicId?: string | null;
                timeZone: string;
                type: Staff_Type_Enum;
                updated_at: string;
                email?: string | null;
                avatarUrl?: string | null;
                color?: string | null;
                lead: boolean;
                staffManagerId?: string | null;
                staff_type: {
                  __typename?: 'staff_type';
                  description: string;
                  value: string;
                };
              } | null;
            }>;
          };
        }>;
        consentSignedByUser?: {
          __typename?: 'users';
          account?: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          } | null;
        } | null;
        consentToTelemedicineTermsByAccount?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
        parsed_address?: {
          __typename?: 'patient_address';
          fullAddress?: string | null;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          zip?: string | null;
          state?: string | null;
        } | null;
      };
    }>;
  }>;
};

export type ScheduleSubscriptionVariables = Exact<{
  where: Appointments_Bool_Exp;
}>;

export type ScheduleSubscription = {
  __typename?: 'subscription_root';
  appointments: Array<{
    __typename?: 'appointments';
    id: string;
    accountId?: string | null;
    address?: any | null;
    campaign?: string | null;
    clinicId: string;
    createdAt: string;
    email?: string | null;
    endTime: string;
    firstName?: string | null;
    kareo_appointment_id?: string | null;
    kareo_appointment_uuid?: string | null;
    languageSelection?: string | null;
    lastName?: string | null;
    notes?: string | null;
    patientId?: string | null;
    payment?: Appointment_Payment_Enum | null;
    phoneNumber?: string | null;
    reason: string;
    safetyQuestionnaire?: any | null;
    startTime: string;
    state?: string | null;
    status?: Appointment_Status_Enum | null;
    stripeCardId?: string | null;
    stripeCustomerId?: string | null;
    timeZone?: string | null;
    type?: Appointment_Types_Enum | null;
    visitClassification?: Visit_Classifications_Enum | null;
    visitType?: Visit_Types_Enum | null;
    reviews: Array<{
      __typename?: 'reviews';
      id: string;
      review?: string | null;
      createdAt: string;
      updatedAt: string;
      nps?: number | null;
      appointmentId?: string | null;
    }>;
    clinic: {
      __typename?: 'clinics';
      id: string;
      address: string;
      clinicCode: string;
      close: string;
      created_at: string;
      latitude?: string | null;
      locationType: Clinic_Location_Types_Enum;
      longitude?: string | null;
      name: string;
      open: string;
      parentClinicId?: string | null;
      phoneNumber: string;
      status: Clinic_Status_Enum;
      timeZone?: string | null;
      updated_at: string;
    };
    appointment_staffs: Array<{
      __typename?: 'appointment_staff';
      id: string;
      appointmentId: string;
      staffId: string;
      created_at: string;
      updated_at: string;
      staff: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      };
    }>;
    appointment_patients: Array<{
      __typename?: 'appointment_patient';
      id: string;
      appointment_id: string;
      created_at: string;
      updated_at: string;
      patient_id: string;
      patient: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
        goesByName?: string | null;
        pronunciation?: string | null;
        dateOfBirth: string;
        deceasedAt?: string | null;
        consentSignedByUserId?: string | null;
        consentToTelemedicineTermsTimestamp?: string | null;
        additionalInfo?: string | null;
        consentSignedAt?: string | null;
        consentSigned?: boolean | null;
        avatarId?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
        genderAtBirth?: string | null;
        primaryCareStaffId?: string | null;
        primaryClinicAddress?: any | null;
        physicianName?: string | null;
        preferredPharmacy?: any | null;
        address?: any | null;
        hasPCP?: string | null;
        lastWellVisitStatus?: string | null;
        vaccinationStatus?: string | null;
        kareo_patient_id?: string | null;
        kareo_guid?: string | null;
        global_notes: Array<{
          __typename?: 'global_notes';
          id: string;
          appointmentId?: string | null;
          deleted: boolean;
          patientId?: string | null;
          accountId?: string | null;
          updatedAt: string;
          createdAt: string;
          note: string;
          type?: string | null;
          staffId?: string | null;
          staff?: {
            __typename?: 'staff';
            id: string;
            active: boolean;
            firstName: string;
            lastName: string;
            auth0UserId?: string | null;
            created_at: string;
            defaultClinicId?: string | null;
            timeZone: string;
            type: Staff_Type_Enum;
            updated_at: string;
            email?: string | null;
            avatarUrl?: string | null;
            color?: string | null;
            lead: boolean;
            staffManagerId?: string | null;
            staff_type: {
              __typename?: 'staff_type';
              description: string;
              value: string;
            };
          } | null;
        }>;
        insurances: Array<{
          __typename?: 'insurance';
          id: string;
          accountId: string;
          backId?: string | null;
          carrier?: string | null;
          dateOfBirth?: string | null;
          eligibility?: any | null;
          firstName?: string | null;
          frontId?: string | null;
          lastName?: string | null;
          memberId?: string | null;
          type: Insurance_Types_Enum;
          updatedAt: string;
        }>;
        accounts: Array<{
          __typename?: 'account_patient';
          id: string;
          accountId: string;
          patientId: string;
          relationship?: string | null;
          account: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
            global_notes: Array<{
              __typename?: 'global_notes';
              id: string;
              appointmentId?: string | null;
              deleted: boolean;
              patientId?: string | null;
              accountId?: string | null;
              updatedAt: string;
              createdAt: string;
              note: string;
              type?: string | null;
              staffId?: string | null;
              staff?: {
                __typename?: 'staff';
                id: string;
                active: boolean;
                firstName: string;
                lastName: string;
                auth0UserId?: string | null;
                created_at: string;
                defaultClinicId?: string | null;
                timeZone: string;
                type: Staff_Type_Enum;
                updated_at: string;
                email?: string | null;
                avatarUrl?: string | null;
                color?: string | null;
                lead: boolean;
                staffManagerId?: string | null;
                staff_type: {
                  __typename?: 'staff_type';
                  description: string;
                  value: string;
                };
              } | null;
            }>;
          };
        }>;
        consentSignedByUser?: {
          __typename?: 'users';
          account?: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          } | null;
        } | null;
        consentToTelemedicineTermsByAccount?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
        parsed_address?: {
          __typename?: 'patient_address';
          fullAddress?: string | null;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          zip?: string | null;
          state?: string | null;
        } | null;
      };
    }>;
  }>;
};

export type NewScheduleSubscriptionVariables = Exact<{
  clinicCode: Scalars['String'];
  startTime: Scalars['timestamp'];
  endTime: Scalars['timestamp'];
}>;

export type NewScheduleSubscription = {
  __typename?: 'subscription_root';
  appointments: Array<{
    __typename?: 'appointments';
    id: string;
    endTime: string;
    kareo_appointment_id?: string | null;
    reason: string;
    safetyQuestionnaire?: any | null;
    startTime: string;
    status?: Appointment_Status_Enum | null;
    visitClassification?: Visit_Classifications_Enum | null;
    visitType?: Visit_Types_Enum | null;
    appointment_staffs: Array<{
      __typename?: 'appointment_staff';
      staff: { __typename?: 'staff'; id: string; avatarUrl?: string | null };
    }>;
    appointment_patients: Array<{
      __typename?: 'appointment_patient';
      patient: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
        goesByName?: string | null;
        dateOfBirth: string;
        pronunciation?: string | null;
        global_notes: Array<{
          __typename?: 'global_notes';
          id: string;
          note: string;
        }>;
      };
    }>;
  }>;
};

export type AppointmentCountsQueryVariables = Exact<{
  where: Appointments_Bool_Exp;
}>;

export type AppointmentCountsQuery = {
  __typename?: 'query_root';
  appointments_aggregate: {
    __typename?: 'appointments_aggregate';
    aggregate?: {
      __typename?: 'appointments_aggregate_fields';
      count: number;
    } | null;
  };
};

export type AppointmentCountSubscriptionVariables = Exact<{
  where: Appointments_Bool_Exp;
}>;

export type AppointmentCountSubscription = {
  __typename?: 'subscription_root';
  appointments_aggregate: {
    __typename?: 'appointments_aggregate';
    aggregate?: {
      __typename?: 'appointments_aggregate_fields';
      count: number;
    } | null;
  };
};

export type AppointmentCountsForDaysQueryVariables = Exact<{
  where: Appointment_Counts_Bool_Exp;
}>;

export type AppointmentCountsForDaysQuery = {
  __typename?: 'query_root';
  appointment_counts: Array<{
    __typename?: 'appointment_counts';
    count?: number | null;
    date?: string | null;
    clinicId?: string | null;
  }>;
};

export type CreateQuickAppointmentMutationVariables = Exact<{
  appointment: Appointments_Insert_Input;
}>;

export type CreateQuickAppointmentMutation = {
  __typename?: 'mutation_root';
  insert_appointments?: {
    __typename?: 'appointments_mutation_response';
    returning: Array<{
      __typename?: 'appointments';
      id: string;
      accountId?: string | null;
      address?: any | null;
      campaign?: string | null;
      clinicId: string;
      createdAt: string;
      email?: string | null;
      endTime: string;
      firstName?: string | null;
      kareo_appointment_id?: string | null;
      kareo_appointment_uuid?: string | null;
      languageSelection?: string | null;
      lastName?: string | null;
      notes?: string | null;
      patientId?: string | null;
      payment?: Appointment_Payment_Enum | null;
      phoneNumber?: string | null;
      reason: string;
      safetyQuestionnaire?: any | null;
      startTime: string;
      state?: string | null;
      status?: Appointment_Status_Enum | null;
      stripeCardId?: string | null;
      stripeCustomerId?: string | null;
      timeZone?: string | null;
      type?: Appointment_Types_Enum | null;
      visitClassification?: Visit_Classifications_Enum | null;
      visitType?: Visit_Types_Enum | null;
      symptom_checker?: {
        __typename?: 'symptom_checker';
        id: string;
        created_at: string;
        phoneNumber?: string | null;
        session: any;
        updated_at: string;
        userId?: string | null;
      } | null;
      reviews: Array<{
        __typename?: 'reviews';
        id: string;
        review?: string | null;
        createdAt: string;
        updatedAt: string;
        nps?: number | null;
        appointmentId?: string | null;
      }>;
      clinic: {
        __typename?: 'clinics';
        id: string;
        address: string;
        clinicCode: string;
        close: string;
        created_at: string;
        latitude?: string | null;
        locationType: Clinic_Location_Types_Enum;
        longitude?: string | null;
        name: string;
        open: string;
        parentClinicId?: string | null;
        phoneNumber: string;
        status: Clinic_Status_Enum;
        timeZone?: string | null;
        updated_at: string;
      };
      appointment_staffs: Array<{
        __typename?: 'appointment_staff';
        id: string;
        appointmentId: string;
        staffId: string;
        created_at: string;
        updated_at: string;
        staff: {
          __typename?: 'staff';
          id: string;
          active: boolean;
          firstName: string;
          lastName: string;
          auth0UserId?: string | null;
          created_at: string;
          defaultClinicId?: string | null;
          timeZone: string;
          type: Staff_Type_Enum;
          updated_at: string;
          email?: string | null;
          avatarUrl?: string | null;
          color?: string | null;
          lead: boolean;
          staffManagerId?: string | null;
          staff_type: {
            __typename?: 'staff_type';
            description: string;
            value: string;
          };
        };
      }>;
      appointment_patients: Array<{
        __typename?: 'appointment_patient';
        id: string;
        appointment_id: string;
        created_at: string;
        updated_at: string;
        patient_id: string;
        patient: {
          __typename?: 'patients';
          id: string;
          firstName: string;
          lastName: string;
          goesByName?: string | null;
          pronunciation?: string | null;
          dateOfBirth: string;
          deceasedAt?: string | null;
          consentSignedByUserId?: string | null;
          consentToTelemedicineTermsTimestamp?: string | null;
          additionalInfo?: string | null;
          consentSignedAt?: string | null;
          consentSigned?: boolean | null;
          avatarId?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
          genderAtBirth?: string | null;
          primaryCareStaffId?: string | null;
          primaryClinicAddress?: any | null;
          physicianName?: string | null;
          preferredPharmacy?: any | null;
          address?: any | null;
          hasPCP?: string | null;
          lastWellVisitStatus?: string | null;
          vaccinationStatus?: string | null;
          kareo_patient_id?: string | null;
          kareo_guid?: string | null;
          insurances: Array<{
            __typename?: 'insurance';
            id: string;
            accountId: string;
            backId?: string | null;
            carrier?: string | null;
            dateOfBirth?: string | null;
            eligibility?: any | null;
            firstName?: string | null;
            frontId?: string | null;
            lastName?: string | null;
            memberId?: string | null;
            type: Insurance_Types_Enum;
            updatedAt: string;
          }>;
          accounts: Array<{
            __typename?: 'account_patient';
            id: string;
            accountId: string;
            patientId: string;
            relationship?: string | null;
            account: {
              __typename?: 'accounts';
              id: string;
              createdAt: string;
              consentToMedicalTermsTimestamp?: string | null;
              consentToTermsAndPrivacyTimestamp?: string | null;
              email?: string | null;
              firstName?: string | null;
              goesByName?: string | null;
              lastName?: string | null;
              phoneNumber: string;
              postalCode?: string | null;
              preferredPronoun?: string | null;
              preferredPronounType?: string | null;
            };
          }>;
          consentSignedByUser?: {
            __typename?: 'users';
            account?: {
              __typename?: 'accounts';
              id: string;
              createdAt: string;
              consentToMedicalTermsTimestamp?: string | null;
              consentToTermsAndPrivacyTimestamp?: string | null;
              email?: string | null;
              firstName?: string | null;
              goesByName?: string | null;
              lastName?: string | null;
              phoneNumber: string;
              postalCode?: string | null;
              preferredPronoun?: string | null;
              preferredPronounType?: string | null;
            } | null;
          } | null;
          consentToTelemedicineTermsByAccount?: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          } | null;
          parsed_address?: {
            __typename?: 'patient_address';
            fullAddress?: string | null;
            address1?: string | null;
            address2?: string | null;
            city?: string | null;
            zip?: string | null;
            state?: string | null;
          } | null;
          discovered_froms: Array<{
            __typename?: 'discovered_from';
            description?: string | null;
            type: string;
            account: {
              __typename?: 'accounts';
              id: string;
              firstName?: string | null;
              lastName?: string | null;
            };
          }>;
        };
      }>;
    }>;
  } | null;
};

export type LoadAppointmentDataQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type LoadAppointmentDataQuery = {
  __typename?: 'query_root';
  appointments_by_pk?: {
    __typename?: 'appointments';
    id: string;
    accountId?: string | null;
    address?: any | null;
    campaign?: string | null;
    clinicId: string;
    createdAt: string;
    email?: string | null;
    endTime: string;
    firstName?: string | null;
    kareo_appointment_id?: string | null;
    kareo_appointment_uuid?: string | null;
    languageSelection?: string | null;
    lastName?: string | null;
    notes?: string | null;
    patientId?: string | null;
    payment?: Appointment_Payment_Enum | null;
    phoneNumber?: string | null;
    reason: string;
    safetyQuestionnaire?: any | null;
    startTime: string;
    state?: string | null;
    status?: Appointment_Status_Enum | null;
    stripeCardId?: string | null;
    stripeCustomerId?: string | null;
    timeZone?: string | null;
    type?: Appointment_Types_Enum | null;
    visitClassification?: Visit_Classifications_Enum | null;
    visitType?: Visit_Types_Enum | null;
    symptom_checker?: {
      __typename?: 'symptom_checker';
      id: string;
      created_at: string;
      phoneNumber?: string | null;
      session: any;
      updated_at: string;
      userId?: string | null;
    } | null;
    reviews: Array<{
      __typename?: 'reviews';
      id: string;
      review?: string | null;
      createdAt: string;
      updatedAt: string;
      nps?: number | null;
      appointmentId?: string | null;
    }>;
    clinic: {
      __typename?: 'clinics';
      id: string;
      address: string;
      clinicCode: string;
      close: string;
      created_at: string;
      latitude?: string | null;
      locationType: Clinic_Location_Types_Enum;
      longitude?: string | null;
      name: string;
      open: string;
      parentClinicId?: string | null;
      phoneNumber: string;
      status: Clinic_Status_Enum;
      timeZone?: string | null;
      updated_at: string;
    };
    appointment_staffs: Array<{
      __typename?: 'appointment_staff';
      id: string;
      appointmentId: string;
      staffId: string;
      created_at: string;
      updated_at: string;
      staff: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      };
    }>;
    appointment_patients: Array<{
      __typename?: 'appointment_patient';
      id: string;
      appointment_id: string;
      created_at: string;
      updated_at: string;
      patient_id: string;
      patient: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
        goesByName?: string | null;
        pronunciation?: string | null;
        dateOfBirth: string;
        deceasedAt?: string | null;
        consentSignedByUserId?: string | null;
        consentToTelemedicineTermsTimestamp?: string | null;
        additionalInfo?: string | null;
        consentSignedAt?: string | null;
        consentSigned?: boolean | null;
        avatarId?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
        genderAtBirth?: string | null;
        primaryCareStaffId?: string | null;
        primaryClinicAddress?: any | null;
        physicianName?: string | null;
        preferredPharmacy?: any | null;
        address?: any | null;
        hasPCP?: string | null;
        lastWellVisitStatus?: string | null;
        vaccinationStatus?: string | null;
        kareo_patient_id?: string | null;
        kareo_guid?: string | null;
        insurances: Array<{
          __typename?: 'insurance';
          id: string;
          accountId: string;
          backId?: string | null;
          carrier?: string | null;
          dateOfBirth?: string | null;
          eligibility?: any | null;
          firstName?: string | null;
          frontId?: string | null;
          lastName?: string | null;
          memberId?: string | null;
          type: Insurance_Types_Enum;
          updatedAt: string;
        }>;
        accounts: Array<{
          __typename?: 'account_patient';
          id: string;
          accountId: string;
          patientId: string;
          relationship?: string | null;
          account: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          };
        }>;
        consentSignedByUser?: {
          __typename?: 'users';
          account?: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          } | null;
        } | null;
        consentToTelemedicineTermsByAccount?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
        parsed_address?: {
          __typename?: 'patient_address';
          fullAddress?: string | null;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          zip?: string | null;
          state?: string | null;
        } | null;
        discovered_froms: Array<{
          __typename?: 'discovered_from';
          description?: string | null;
          type: string;
          account: {
            __typename?: 'accounts';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
          };
        }>;
      };
    }>;
  } | null;
};

export type AppointmentDataSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type AppointmentDataSubscription = {
  __typename?: 'subscription_root';
  appointments_by_pk?: {
    __typename?: 'appointments';
    id: string;
    accountId?: string | null;
    address?: any | null;
    campaign?: string | null;
    clinicId: string;
    createdAt: string;
    email?: string | null;
    endTime: string;
    firstName?: string | null;
    kareo_appointment_id?: string | null;
    kareo_appointment_uuid?: string | null;
    languageSelection?: string | null;
    lastName?: string | null;
    notes?: string | null;
    patientId?: string | null;
    payment?: Appointment_Payment_Enum | null;
    phoneNumber?: string | null;
    reason: string;
    safetyQuestionnaire?: any | null;
    startTime: string;
    state?: string | null;
    status?: Appointment_Status_Enum | null;
    stripeCardId?: string | null;
    stripeCustomerId?: string | null;
    timeZone?: string | null;
    type?: Appointment_Types_Enum | null;
    visitClassification?: Visit_Classifications_Enum | null;
    visitType?: Visit_Types_Enum | null;
    appointment_patients: Array<{
      __typename?: 'appointment_patient';
      id: string;
      appointment_id: string;
      created_at: string;
      updated_at: string;
      patient_id: string;
      patient: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
        goesByName?: string | null;
        pronunciation?: string | null;
        dateOfBirth: string;
        deceasedAt?: string | null;
        consentSignedByUserId?: string | null;
        consentToTelemedicineTermsTimestamp?: string | null;
        additionalInfo?: string | null;
        consentSignedAt?: string | null;
        consentSigned?: boolean | null;
        avatarId?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
        genderAtBirth?: string | null;
        primaryCareStaffId?: string | null;
        primaryClinicAddress?: any | null;
        physicianName?: string | null;
        preferredPharmacy?: any | null;
        address?: any | null;
        hasPCP?: string | null;
        lastWellVisitStatus?: string | null;
        vaccinationStatus?: string | null;
        kareo_patient_id?: string | null;
        kareo_guid?: string | null;
        insurances: Array<{
          __typename?: 'insurance';
          id: string;
          accountId: string;
          backId?: string | null;
          carrier?: string | null;
          dateOfBirth?: string | null;
          eligibility?: any | null;
          firstName?: string | null;
          frontId?: string | null;
          lastName?: string | null;
          memberId?: string | null;
          type: Insurance_Types_Enum;
          updatedAt: string;
        }>;
        accounts: Array<{
          __typename?: 'account_patient';
          id: string;
          accountId: string;
          patientId: string;
          relationship?: string | null;
          account: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          };
        }>;
        consentSignedByUser?: {
          __typename?: 'users';
          account?: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          } | null;
        } | null;
        consentToTelemedicineTermsByAccount?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
        parsed_address?: {
          __typename?: 'patient_address';
          fullAddress?: string | null;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          zip?: string | null;
          state?: string | null;
        } | null;
        discovered_froms: Array<{
          __typename?: 'discovered_from';
          description?: string | null;
          type: string;
          account: {
            __typename?: 'accounts';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
          };
        }>;
      };
    }>;
    appointment_staffs: Array<{
      __typename?: 'appointment_staff';
      id: string;
      appointmentId: string;
      staffId: string;
      created_at: string;
      updated_at: string;
      staff: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      };
    }>;
    charges: Array<{
      __typename?: 'charges';
      id: string;
      medicalAssistantId?: string | null;
      amount: number;
      accountId?: string | null;
      appointmentId?: string | null;
      cardId?: string | null;
      clinicId?: string | null;
      created_at: string;
      patientId?: string | null;
      stripeChargeId: string;
      updated_at: string;
      charge_products: Array<{
        __typename?: 'charge_products';
        id: string;
        amount: number;
        type?: string | null;
        quantity: number;
        chargeId: string;
        created_at: string;
        updated_at: string;
        productId: string;
        product: {
          __typename?: 'products';
          id: string;
          name: string;
          price: number;
          type: string;
        };
      }>;
    }>;
    clinic: {
      __typename?: 'clinics';
      id: string;
      address: string;
      clinicCode: string;
      close: string;
      created_at: string;
      latitude?: string | null;
      locationType: Clinic_Location_Types_Enum;
      longitude?: string | null;
      name: string;
      open: string;
      parentClinicId?: string | null;
      phoneNumber: string;
      status: Clinic_Status_Enum;
      timeZone?: string | null;
      updated_at: string;
    };
    reviews: Array<{
      __typename?: 'reviews';
      id: string;
      review?: string | null;
      createdAt: string;
      updatedAt: string;
      nps?: number | null;
      appointmentId?: string | null;
    }>;
    symptom_checker?: {
      __typename?: 'symptom_checker';
      id: string;
      created_at: string;
      phoneNumber?: string | null;
      session: any;
      updated_at: string;
      userId?: string | null;
    } | null;
  } | null;
};

export type UpdateAppointmentMutationVariables = Exact<{
  id: Scalars['uuid'];
  set?: InputMaybe<Appointments_Set_Input>;
}>;

export type UpdateAppointmentMutation = {
  __typename?: 'mutation_root';
  update_appointments?: {
    __typename?: 'appointments_mutation_response';
    returning: Array<{
      __typename?: 'appointments';
      id: string;
      accountId?: string | null;
      address?: any | null;
      campaign?: string | null;
      clinicId: string;
      createdAt: string;
      email?: string | null;
      endTime: string;
      firstName?: string | null;
      kareo_appointment_id?: string | null;
      kareo_appointment_uuid?: string | null;
      languageSelection?: string | null;
      lastName?: string | null;
      notes?: string | null;
      patientId?: string | null;
      payment?: Appointment_Payment_Enum | null;
      phoneNumber?: string | null;
      reason: string;
      safetyQuestionnaire?: any | null;
      startTime: string;
      state?: string | null;
      status?: Appointment_Status_Enum | null;
      stripeCardId?: string | null;
      stripeCustomerId?: string | null;
      timeZone?: string | null;
      type?: Appointment_Types_Enum | null;
      visitClassification?: Visit_Classifications_Enum | null;
      visitType?: Visit_Types_Enum | null;
      symptom_checker?: {
        __typename?: 'symptom_checker';
        id: string;
        created_at: string;
        phoneNumber?: string | null;
        session: any;
        updated_at: string;
        userId?: string | null;
      } | null;
      reviews: Array<{
        __typename?: 'reviews';
        id: string;
        review?: string | null;
        createdAt: string;
        updatedAt: string;
        nps?: number | null;
        appointmentId?: string | null;
      }>;
      clinic: {
        __typename?: 'clinics';
        id: string;
        address: string;
        clinicCode: string;
        close: string;
        created_at: string;
        latitude?: string | null;
        locationType: Clinic_Location_Types_Enum;
        longitude?: string | null;
        name: string;
        open: string;
        parentClinicId?: string | null;
        phoneNumber: string;
        status: Clinic_Status_Enum;
        timeZone?: string | null;
        updated_at: string;
      };
      appointment_staffs: Array<{
        __typename?: 'appointment_staff';
        id: string;
        appointmentId: string;
        staffId: string;
        created_at: string;
        updated_at: string;
        staff: {
          __typename?: 'staff';
          id: string;
          active: boolean;
          firstName: string;
          lastName: string;
          auth0UserId?: string | null;
          created_at: string;
          defaultClinicId?: string | null;
          timeZone: string;
          type: Staff_Type_Enum;
          updated_at: string;
          email?: string | null;
          avatarUrl?: string | null;
          color?: string | null;
          lead: boolean;
          staffManagerId?: string | null;
          staff_type: {
            __typename?: 'staff_type';
            description: string;
            value: string;
          };
        };
      }>;
      appointment_patients: Array<{
        __typename?: 'appointment_patient';
        id: string;
        appointment_id: string;
        created_at: string;
        updated_at: string;
        patient_id: string;
        patient: {
          __typename?: 'patients';
          id: string;
          firstName: string;
          lastName: string;
          goesByName?: string | null;
          pronunciation?: string | null;
          dateOfBirth: string;
          deceasedAt?: string | null;
          consentSignedByUserId?: string | null;
          consentToTelemedicineTermsTimestamp?: string | null;
          additionalInfo?: string | null;
          consentSignedAt?: string | null;
          consentSigned?: boolean | null;
          avatarId?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
          genderAtBirth?: string | null;
          primaryCareStaffId?: string | null;
          primaryClinicAddress?: any | null;
          physicianName?: string | null;
          preferredPharmacy?: any | null;
          address?: any | null;
          hasPCP?: string | null;
          lastWellVisitStatus?: string | null;
          vaccinationStatus?: string | null;
          kareo_patient_id?: string | null;
          kareo_guid?: string | null;
          insurances: Array<{
            __typename?: 'insurance';
            id: string;
            accountId: string;
            backId?: string | null;
            carrier?: string | null;
            dateOfBirth?: string | null;
            eligibility?: any | null;
            firstName?: string | null;
            frontId?: string | null;
            lastName?: string | null;
            memberId?: string | null;
            type: Insurance_Types_Enum;
            updatedAt: string;
          }>;
          accounts: Array<{
            __typename?: 'account_patient';
            id: string;
            accountId: string;
            patientId: string;
            relationship?: string | null;
            account: {
              __typename?: 'accounts';
              id: string;
              createdAt: string;
              consentToMedicalTermsTimestamp?: string | null;
              consentToTermsAndPrivacyTimestamp?: string | null;
              email?: string | null;
              firstName?: string | null;
              goesByName?: string | null;
              lastName?: string | null;
              phoneNumber: string;
              postalCode?: string | null;
              preferredPronoun?: string | null;
              preferredPronounType?: string | null;
            };
          }>;
          consentSignedByUser?: {
            __typename?: 'users';
            account?: {
              __typename?: 'accounts';
              id: string;
              createdAt: string;
              consentToMedicalTermsTimestamp?: string | null;
              consentToTermsAndPrivacyTimestamp?: string | null;
              email?: string | null;
              firstName?: string | null;
              goesByName?: string | null;
              lastName?: string | null;
              phoneNumber: string;
              postalCode?: string | null;
              preferredPronoun?: string | null;
              preferredPronounType?: string | null;
            } | null;
          } | null;
          consentToTelemedicineTermsByAccount?: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          } | null;
          parsed_address?: {
            __typename?: 'patient_address';
            fullAddress?: string | null;
            address1?: string | null;
            address2?: string | null;
            city?: string | null;
            zip?: string | null;
            state?: string | null;
          } | null;
          discovered_froms: Array<{
            __typename?: 'discovered_from';
            description?: string | null;
            type: string;
            account: {
              __typename?: 'accounts';
              id: string;
              firstName?: string | null;
              lastName?: string | null;
            };
          }>;
        };
      }>;
    }>;
  } | null;
};

export type UpdateAppointmentStatusMutationVariables = Exact<{
  id: Scalars['uuid'];
  status: Scalars['String'];
  notification: Scalars['Boolean'];
}>;

export type UpdateAppointmentStatusMutation = {
  __typename?: 'mutation_root';
  UpdateAppointmentStatus?: {
    __typename?: 'UpdateAppointmentStatusOutput';
    id: string;
  } | null;
};

export type UpdateAppointmentTimeMutationVariables = Exact<{
  id: Scalars['uuid'];
  clinicId?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  visitType?: InputMaybe<Scalars['String']>;
  visitClassification?: InputMaybe<Scalars['String']>;
  notification: Scalars['Boolean'];
}>;

export type UpdateAppointmentTimeMutation = {
  __typename?: 'mutation_root';
  UpdateAppointmentTime?: {
    __typename?: 'UpdateAppointmentTimeOutput';
    id: string;
  } | null;
};

export type CancelAppointmentMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type CancelAppointmentMutation = {
  __typename?: 'mutation_root';
  update_appointments_by_pk?: {
    __typename?: 'appointments';
    id: string;
    status?: Appointment_Status_Enum | null;
  } | null;
};

export type GetAppointmentPhoneNumberQueryVariables = Exact<{
  appointmentId: Scalars['uuid'];
}>;

export type GetAppointmentPhoneNumberQuery = {
  __typename?: 'query_root';
  appointments_by_pk?: {
    __typename?: 'appointments';
    account?: {
      __typename?: 'account_patient';
      account: { __typename?: 'accounts'; id: string; phoneNumber: string };
    } | null;
  } | null;
};

export type LoadAllVisitClassificationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LoadAllVisitClassificationsQuery = {
  __typename?: 'query_root';
  visit_classifications: Array<{
    __typename?: 'visit_classifications';
    value: string;
    description?: string | null;
  }>;
};

export type LoadVisitTypesAndClassificationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LoadVisitTypesAndClassificationsQuery = {
  __typename?: 'query_root';
  visit_classifications: Array<{
    __typename?: 'visit_classifications';
    value: string;
    description?: string | null;
  }>;
  visit_types: Array<{
    __typename?: 'visit_types';
    value: string;
    description: string;
  }>;
};

export type CreateAccountPatientMutationVariables = Exact<{
  accountFirstName: Scalars['String'];
  accountId?: InputMaybe<Scalars['String']>;
  accountLastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  relationship: Scalars['String'];
  patientFirstName: Scalars['String'];
  patientId?: InputMaybe<Scalars['String']>;
  patientLastName: Scalars['String'];
  dateOfBirth: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
}>;

export type CreateAccountPatientMutation = {
  __typename?: 'mutation_root';
  CreateAccountPatient?: {
    __typename?: 'AccountPatientOutput';
    accountId?: string | null;
    patientId?: string | null;
  } | null;
};

export type ScheduleAppointmentMutationVariables = Exact<{
  accountId: Scalars['String'];
  clinicId: Scalars['String'];
  patientId: Scalars['String'];
  reason: Scalars['String'];
  sqlTime: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  visitType?: InputMaybe<Scalars['String']>;
  visitClassification?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['String']>;
  communicationHistoryId?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  campaign?: InputMaybe<Scalars['String']>;
}>;

export type ScheduleAppointmentMutation = {
  __typename?: 'mutation_root';
  ScheduleAppointment?: {
    __typename?: 'ScheduleAppointmentOutput';
    id: string;
  } | null;
};

export type LoadAppointmentsQueryVariables = Exact<{
  where: Appointments_Bool_Exp;
  order_by?: InputMaybe<Array<Appointments_Order_By> | Appointments_Order_By>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type LoadAppointmentsQuery = {
  __typename?: 'query_root';
  appointments: Array<{
    __typename?: 'appointments';
    id: string;
    accountId?: string | null;
    address?: any | null;
    campaign?: string | null;
    clinicId: string;
    createdAt: string;
    email?: string | null;
    endTime: string;
    firstName?: string | null;
    kareo_appointment_id?: string | null;
    kareo_appointment_uuid?: string | null;
    languageSelection?: string | null;
    lastName?: string | null;
    notes?: string | null;
    patientId?: string | null;
    payment?: Appointment_Payment_Enum | null;
    phoneNumber?: string | null;
    reason: string;
    safetyQuestionnaire?: any | null;
    startTime: string;
    state?: string | null;
    status?: Appointment_Status_Enum | null;
    stripeCardId?: string | null;
    stripeCustomerId?: string | null;
    timeZone?: string | null;
    type?: Appointment_Types_Enum | null;
    visitClassification?: Visit_Classifications_Enum | null;
    visitType?: Visit_Types_Enum | null;
    appointment_patients: Array<{
      __typename?: 'appointment_patient';
      id: string;
      appointment_id: string;
      created_at: string;
      updated_at: string;
      patient_id: string;
      patient: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
        goesByName?: string | null;
        pronunciation?: string | null;
        dateOfBirth: string;
        deceasedAt?: string | null;
        consentSignedByUserId?: string | null;
        consentToTelemedicineTermsTimestamp?: string | null;
        additionalInfo?: string | null;
        consentSignedAt?: string | null;
        consentSigned?: boolean | null;
        avatarId?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
        genderAtBirth?: string | null;
        primaryCareStaffId?: string | null;
        primaryClinicAddress?: any | null;
        physicianName?: string | null;
        preferredPharmacy?: any | null;
        address?: any | null;
        hasPCP?: string | null;
        lastWellVisitStatus?: string | null;
        vaccinationStatus?: string | null;
        kareo_patient_id?: string | null;
        kareo_guid?: string | null;
        insurances: Array<{
          __typename?: 'insurance';
          id: string;
          accountId: string;
          backId?: string | null;
          carrier?: string | null;
          dateOfBirth?: string | null;
          eligibility?: any | null;
          firstName?: string | null;
          frontId?: string | null;
          lastName?: string | null;
          memberId?: string | null;
          type: Insurance_Types_Enum;
          updatedAt: string;
        }>;
        accounts: Array<{
          __typename?: 'account_patient';
          id: string;
          accountId: string;
          patientId: string;
          relationship?: string | null;
          account: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          };
        }>;
        consentSignedByUser?: {
          __typename?: 'users';
          account?: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          } | null;
        } | null;
        consentToTelemedicineTermsByAccount?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
        parsed_address?: {
          __typename?: 'patient_address';
          fullAddress?: string | null;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          zip?: string | null;
          state?: string | null;
        } | null;
        discovered_froms: Array<{
          __typename?: 'discovered_from';
          description?: string | null;
          type: string;
          account: {
            __typename?: 'accounts';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
          };
        }>;
      };
    }>;
    appointment_staffs: Array<{
      __typename?: 'appointment_staff';
      id: string;
      appointmentId: string;
      staffId: string;
      created_at: string;
      updated_at: string;
      staff: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      };
    }>;
    charges: Array<{
      __typename?: 'charges';
      id: string;
      medicalAssistantId?: string | null;
      amount: number;
      accountId?: string | null;
      appointmentId?: string | null;
      cardId?: string | null;
      clinicId?: string | null;
      created_at: string;
      patientId?: string | null;
      stripeChargeId: string;
      updated_at: string;
      charge_products: Array<{
        __typename?: 'charge_products';
        id: string;
        amount: number;
        type?: string | null;
        quantity: number;
        chargeId: string;
        created_at: string;
        updated_at: string;
        productId: string;
        product: {
          __typename?: 'products';
          id: string;
          name: string;
          price: number;
          type: string;
        };
      }>;
    }>;
    clinic: {
      __typename?: 'clinics';
      id: string;
      address: string;
      clinicCode: string;
      close: string;
      created_at: string;
      latitude?: string | null;
      locationType: Clinic_Location_Types_Enum;
      longitude?: string | null;
      name: string;
      open: string;
      parentClinicId?: string | null;
      phoneNumber: string;
      status: Clinic_Status_Enum;
      timeZone?: string | null;
      updated_at: string;
    };
    reviews: Array<{
      __typename?: 'reviews';
      id: string;
      review?: string | null;
      createdAt: string;
      updatedAt: string;
      nps?: number | null;
      appointmentId?: string | null;
    }>;
    symptom_checker?: {
      __typename?: 'symptom_checker';
      id: string;
      created_at: string;
      phoneNumber?: string | null;
      session: any;
      updated_at: string;
      userId?: string | null;
    } | null;
  }>;
};

export type LoadAppointmentBlockByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type LoadAppointmentBlockByPkQuery = {
  __typename?: 'query_root';
  appointment_blocks_by_pk?: {
    __typename?: 'appointment_blocks';
    startDateTime: string;
    reason?: string | null;
    id: string;
    endDateTime: string;
    staff?: {
      __typename?: 'staff';
      firstName: string;
      lastName: string;
      id: string;
    } | null;
    appointment_blocks_staffs: Array<{
      __typename?: 'appointment_blocks_staff';
      id: number;
      staff: {
        __typename?: 'staff';
        id: string;
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
      };
    }>;
  } | null;
};

export type GetLatestAppointmentsQueryVariables = Exact<{
  where: Appointments_Bool_Exp;
  order_by?: InputMaybe<Array<Appointments_Order_By> | Appointments_Order_By>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetLatestAppointmentsQuery = {
  __typename?: 'query_root';
  appointments: Array<{
    __typename?: 'appointments';
    id: string;
    notes?: string | null;
    reason: string;
    startTime: string;
    visitType?: Visit_Types_Enum | null;
    visitClassification?: Visit_Classifications_Enum | null;
  }>;
};

export type AuditLogQueryVariables = Exact<{
  where: Audit_Logs_Bool_Exp;
  order_by: Array<Audit_Logs_Order_By> | Audit_Logs_Order_By;
}>;

export type AuditLogQuery = {
  __typename?: 'query_root';
  audit_logs: Array<{
    __typename?: 'audit_logs';
    accountId?: string | null;
    created_at: string;
    difference?: any | null;
    from?: any | null;
    id: number;
    operation: string;
    session: any;
    staffUserId?: string | null;
    table: string;
    to?: any | null;
    updated_at: string;
    staff?: {
      __typename?: 'staff';
      avatarUrl?: string | null;
      firstName: string;
      lastName: string;
      id: string;
    } | null;
    account?: {
      __typename?: 'accounts';
      firstName?: string | null;
      lastName?: string | null;
      id: string;
      avatar?: {
        __typename?: 'FilePayload';
        id?: string | null;
        url?: string | null;
      } | null;
    } | null;
  }>;
};

export type AuditFragment = {
  __typename?: 'audit_logs';
  accountId?: string | null;
  created_at: string;
  difference?: any | null;
  from?: any | null;
  id: number;
  operation: string;
  session: any;
  staffUserId?: string | null;
  table: string;
  to?: any | null;
  updated_at: string;
  staff?: {
    __typename?: 'staff';
    avatarUrl?: string | null;
    firstName: string;
    lastName: string;
    id: string;
  } | null;
  account?: {
    __typename?: 'accounts';
    firstName?: string | null;
    lastName?: string | null;
    id: string;
    avatar?: {
      __typename?: 'FilePayload';
      id?: string | null;
      url?: string | null;
    } | null;
  } | null;
};

export type RetrieveVerificationCodeQueryVariables = Exact<{
  phoneNumber: Scalars['String'];
}>;

export type RetrieveVerificationCodeQuery = {
  __typename?: 'query_root';
  RetrieveVerificationCode?: {
    __typename?: 'RetrieveVerificationCodeOutput';
    code: string;
  } | null;
};

export type GenerateTemporaryVerificationCodeMutationVariables = Exact<{
  accountId: Scalars['uuid'];
}>;

export type GenerateTemporaryVerificationCodeMutation = {
  __typename?: 'mutation_root';
  GenerateTemporaryVerificationCode?: {
    __typename?: 'TemporaryVerification';
    code: string;
  } | null;
};

export type LoadChatSessionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Chat_Sessions_Order_By> | Chat_Sessions_Order_By>;
  where?: Chat_Sessions_Bool_Exp;
}>;

export type LoadChatSessionsQuery = {
  __typename?: 'query_root';
  chat_sessions: Array<{
    __typename?: 'chat_sessions';
    id: string;
    status: Chat_Sessions_Status_Enum;
    updatedAt: string;
    createdByAccountId?: string | null;
    createdAt: string;
    chat_sessions_accounts: Array<{
      __typename?: 'chat_sessions_accounts';
      id: string;
      accountId: string;
      active: boolean;
      chatSessionId: string;
      createdAt: string;
      updatedAt: string;
      account: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      };
    }>;
    chat_sessions_patients: Array<{
      __typename?: 'chat_sessions_patients';
      id: string;
      createdAt: string;
      chatSessionId: string;
      updatedAt: string;
      patientId: string;
      patient: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
        goesByName?: string | null;
        pronunciation?: string | null;
        dateOfBirth: string;
        deceasedAt?: string | null;
        consentSignedByUserId?: string | null;
        consentToTelemedicineTermsTimestamp?: string | null;
        additionalInfo?: string | null;
        consentSignedAt?: string | null;
        consentSigned?: boolean | null;
        avatarId?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
        genderAtBirth?: string | null;
        primaryCareStaffId?: string | null;
        primaryClinicAddress?: any | null;
        physicianName?: string | null;
        preferredPharmacy?: any | null;
        address?: any | null;
        hasPCP?: string | null;
        lastWellVisitStatus?: string | null;
        vaccinationStatus?: string | null;
        kareo_patient_id?: string | null;
        kareo_guid?: string | null;
        consentSignedByUser?: {
          __typename?: 'users';
          account?: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          } | null;
        } | null;
        consentToTelemedicineTermsByAccount?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
        parsed_address?: {
          __typename?: 'patient_address';
          fullAddress?: string | null;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          zip?: string | null;
          state?: string | null;
        } | null;
      };
    }>;
    chat_sessions_staffs: Array<{
      __typename?: 'chat_sessions_staff';
      id: string;
      active: boolean;
      chatSessionId: string;
      created_at: string;
      staffId: string;
      updated_at: string;
      staff: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      };
    }>;
    chat_sessions_reads: Array<{
      __typename?: 'chat_sessions_read';
      id: string;
      accountId?: string | null;
      staffId?: string | null;
      chatSessionId: string;
      createdAt: string;
      lastViewed: string;
      updatedAt: string;
    }>;
    chat_messages: Array<{
      __typename?: 'chat_messages';
      id: string;
      chatSessionId: string;
      accountId?: string | null;
      staffId?: string | null;
      data?: any | null;
      message: string;
      type: Chat_Messages_Type_Enum;
      createdAt: string;
      updatedAt: string;
      request?: any | null;
      response?: any | null;
    }>;
    chat_notes: Array<{
      __typename?: 'chat_notes';
      id: string;
      chatSessionId?: string | null;
      createdAt: string;
      note?: string | null;
      staffId?: string | null;
      type: Chat_Notes_Types_Enum;
      updatedAt?: string | null;
      staff?: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      } | null;
    }>;
  }>;
};

export type NewUserChatMessagesSubscriptionVariables = Exact<{
  chatSession?: InputMaybe<Uuid_Comparison_Exp>;
}>;

export type NewUserChatMessagesSubscription = {
  __typename?: 'subscription_root';
  chat_messages: Array<{
    __typename?: 'chat_messages';
    id: string;
    chatSessionId: string;
  }>;
};

export type ChatSessionsSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Chat_Sessions_Order_By> | Chat_Sessions_Order_By>;
  where?: Chat_Sessions_Bool_Exp;
}>;

export type ChatSessionsSubscription = {
  __typename?: 'subscription_root';
  chat_sessions: Array<{
    __typename?: 'chat_sessions';
    id: string;
    status: Chat_Sessions_Status_Enum;
    updatedAt: string;
    createdByAccountId?: string | null;
    createdAt: string;
    chat_sessions_accounts: Array<{
      __typename?: 'chat_sessions_accounts';
      id: string;
      accountId: string;
      active: boolean;
      chatSessionId: string;
      createdAt: string;
      updatedAt: string;
      account: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      };
    }>;
    chat_sessions_patients: Array<{
      __typename?: 'chat_sessions_patients';
      id: string;
      createdAt: string;
      chatSessionId: string;
      updatedAt: string;
      patientId: string;
      patient: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
        goesByName?: string | null;
        pronunciation?: string | null;
        dateOfBirth: string;
        deceasedAt?: string | null;
        consentSignedByUserId?: string | null;
        consentToTelemedicineTermsTimestamp?: string | null;
        additionalInfo?: string | null;
        consentSignedAt?: string | null;
        consentSigned?: boolean | null;
        avatarId?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
        genderAtBirth?: string | null;
        primaryCareStaffId?: string | null;
        primaryClinicAddress?: any | null;
        physicianName?: string | null;
        preferredPharmacy?: any | null;
        address?: any | null;
        hasPCP?: string | null;
        lastWellVisitStatus?: string | null;
        vaccinationStatus?: string | null;
        kareo_patient_id?: string | null;
        kareo_guid?: string | null;
        consentSignedByUser?: {
          __typename?: 'users';
          account?: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          } | null;
        } | null;
        consentToTelemedicineTermsByAccount?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
        parsed_address?: {
          __typename?: 'patient_address';
          fullAddress?: string | null;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          zip?: string | null;
          state?: string | null;
        } | null;
      };
    }>;
    chat_sessions_staffs: Array<{
      __typename?: 'chat_sessions_staff';
      id: string;
      active: boolean;
      chatSessionId: string;
      created_at: string;
      staffId: string;
      updated_at: string;
      staff: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      };
    }>;
    chat_sessions_reads: Array<{
      __typename?: 'chat_sessions_read';
      id: string;
      accountId?: string | null;
      staffId?: string | null;
      chatSessionId: string;
      createdAt: string;
      lastViewed: string;
      updatedAt: string;
    }>;
    chat_messages: Array<{
      __typename?: 'chat_messages';
      id: string;
      chatSessionId: string;
      accountId?: string | null;
      staffId?: string | null;
      data?: any | null;
      message: string;
      type: Chat_Messages_Type_Enum;
      createdAt: string;
      updatedAt: string;
      request?: any | null;
      response?: any | null;
    }>;
    chat_notes: Array<{
      __typename?: 'chat_notes';
      id: string;
      chatSessionId?: string | null;
      createdAt: string;
      note?: string | null;
      staffId?: string | null;
      type: Chat_Notes_Types_Enum;
      updatedAt?: string | null;
      staff?: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      } | null;
    }>;
  }>;
};

export type RecentChatsDataFragment = {
  __typename?: 'chat_sessions';
  id: string;
  createdAt: string;
  chat_sessions_accounts: Array<{
    __typename?: 'chat_sessions_accounts';
    account: {
      __typename?: 'accounts';
      goesByName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    };
  }>;
  chat_sessions_staffs: Array<{
    __typename?: 'chat_sessions_staff';
    staff: { __typename?: 'staff'; firstName: string; lastName: string };
  }>;
};

export type RecentChatsSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Chat_Sessions_Order_By> | Chat_Sessions_Order_By>;
  where?: Chat_Sessions_Bool_Exp;
}>;

export type RecentChatsSubscription = {
  __typename?: 'subscription_root';
  chat_sessions: Array<{
    __typename?: 'chat_sessions';
    id: string;
    createdAt: string;
    chat_sessions_accounts: Array<{
      __typename?: 'chat_sessions_accounts';
      account: {
        __typename?: 'accounts';
        goesByName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      };
    }>;
    chat_sessions_staffs: Array<{
      __typename?: 'chat_sessions_staff';
      staff: { __typename?: 'staff'; firstName: string; lastName: string };
    }>;
  }>;
};

export type LoadChatSessionQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type LoadChatSessionQuery = {
  __typename?: 'query_root';
  chat_sessions_by_pk?: {
    __typename?: 'chat_sessions';
    id: string;
    status: Chat_Sessions_Status_Enum;
    updatedAt: string;
    createdByAccountId?: string | null;
    createdAt: string;
    chat_sessions_accounts: Array<{
      __typename?: 'chat_sessions_accounts';
      id: string;
      accountId: string;
      active: boolean;
      chatSessionId: string;
      createdAt: string;
      updatedAt: string;
      account: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      };
    }>;
    chat_sessions_patients: Array<{
      __typename?: 'chat_sessions_patients';
      id: string;
      createdAt: string;
      chatSessionId: string;
      updatedAt: string;
      patientId: string;
      patient: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
        goesByName?: string | null;
        pronunciation?: string | null;
        dateOfBirth: string;
        deceasedAt?: string | null;
        consentSignedByUserId?: string | null;
        consentToTelemedicineTermsTimestamp?: string | null;
        additionalInfo?: string | null;
        consentSignedAt?: string | null;
        consentSigned?: boolean | null;
        avatarId?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
        genderAtBirth?: string | null;
        primaryCareStaffId?: string | null;
        primaryClinicAddress?: any | null;
        physicianName?: string | null;
        preferredPharmacy?: any | null;
        address?: any | null;
        hasPCP?: string | null;
        lastWellVisitStatus?: string | null;
        vaccinationStatus?: string | null;
        kareo_patient_id?: string | null;
        kareo_guid?: string | null;
        consentSignedByUser?: {
          __typename?: 'users';
          account?: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          } | null;
        } | null;
        consentToTelemedicineTermsByAccount?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
        parsed_address?: {
          __typename?: 'patient_address';
          fullAddress?: string | null;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          zip?: string | null;
          state?: string | null;
        } | null;
      };
    }>;
    chat_sessions_staffs: Array<{
      __typename?: 'chat_sessions_staff';
      id: string;
      active: boolean;
      chatSessionId: string;
      created_at: string;
      staffId: string;
      updated_at: string;
      staff: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      };
    }>;
    chat_notes: Array<{
      __typename?: 'chat_notes';
      id: string;
      chatSessionId?: string | null;
      createdAt: string;
      note?: string | null;
      staffId?: string | null;
      type: Chat_Notes_Types_Enum;
      updatedAt?: string | null;
      staff?: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      } | null;
    }>;
  } | null;
};

export type ChatSessionSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type ChatSessionSubscription = {
  __typename?: 'subscription_root';
  chat_sessions_by_pk?: {
    __typename?: 'chat_sessions';
    id: string;
    status: Chat_Sessions_Status_Enum;
    updatedAt: string;
    createdByAccountId?: string | null;
    createdAt: string;
    chat_sessions_accounts: Array<{
      __typename?: 'chat_sessions_accounts';
      id: string;
      accountId: string;
      active: boolean;
      chatSessionId: string;
      createdAt: string;
      updatedAt: string;
      account: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      };
    }>;
    chat_sessions_patients: Array<{
      __typename?: 'chat_sessions_patients';
      id: string;
      createdAt: string;
      chatSessionId: string;
      updatedAt: string;
      patientId: string;
      patient: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
        goesByName?: string | null;
        pronunciation?: string | null;
        dateOfBirth: string;
        deceasedAt?: string | null;
        consentSignedByUserId?: string | null;
        consentToTelemedicineTermsTimestamp?: string | null;
        additionalInfo?: string | null;
        consentSignedAt?: string | null;
        consentSigned?: boolean | null;
        avatarId?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
        genderAtBirth?: string | null;
        primaryCareStaffId?: string | null;
        primaryClinicAddress?: any | null;
        physicianName?: string | null;
        preferredPharmacy?: any | null;
        address?: any | null;
        hasPCP?: string | null;
        lastWellVisitStatus?: string | null;
        vaccinationStatus?: string | null;
        kareo_patient_id?: string | null;
        kareo_guid?: string | null;
        consentSignedByUser?: {
          __typename?: 'users';
          account?: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          } | null;
        } | null;
        consentToTelemedicineTermsByAccount?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
        parsed_address?: {
          __typename?: 'patient_address';
          fullAddress?: string | null;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          zip?: string | null;
          state?: string | null;
        } | null;
      };
    }>;
    chat_sessions_staffs: Array<{
      __typename?: 'chat_sessions_staff';
      id: string;
      active: boolean;
      chatSessionId: string;
      created_at: string;
      staffId: string;
      updated_at: string;
      staff: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      };
    }>;
    chat_notes: Array<{
      __typename?: 'chat_notes';
      id: string;
      chatSessionId?: string | null;
      createdAt: string;
      note?: string | null;
      staffId?: string | null;
      type: Chat_Notes_Types_Enum;
      updatedAt?: string | null;
      staff?: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      } | null;
    }>;
  } | null;
};

export type ChatMessagesSubscriptionVariables = Exact<{
  where: Chat_Messages_Bool_Exp;
}>;

export type ChatMessagesSubscription = {
  __typename?: 'subscription_root';
  chat_messages: Array<{
    __typename?: 'chat_messages';
    id: string;
    chatSessionId: string;
    accountId?: string | null;
    staffId?: string | null;
    data?: any | null;
    message: string;
    type: Chat_Messages_Type_Enum;
    createdAt: string;
    updatedAt: string;
    request?: any | null;
    response?: any | null;
    staff?: {
      __typename?: 'staff';
      id: string;
      active: boolean;
      firstName: string;
      lastName: string;
      auth0UserId?: string | null;
      created_at: string;
      defaultClinicId?: string | null;
      timeZone: string;
      type: Staff_Type_Enum;
      updated_at: string;
      email?: string | null;
      avatarUrl?: string | null;
      color?: string | null;
      lead: boolean;
      staffManagerId?: string | null;
      staff_type: {
        __typename?: 'staff_type';
        description: string;
        value: string;
      };
    } | null;
    chat_messages_files: Array<{
      __typename?: 'chat_messages_file';
      id: string;
      chatMessageId: string;
      createdAt: string;
      fileId: string;
      updatedAt: string;
    }>;
  }>;
};

export type LoadChatFileQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type LoadChatFileQuery = {
  __typename?: 'query_root';
  GetFile?: { __typename?: 'GetFileOutput'; id: string; url: string } | null;
};

export type SendChatMessageMutationVariables = Exact<{
  message: Chat_Messages_Insert_Input;
}>;

export type SendChatMessageMutation = {
  __typename?: 'mutation_root';
  insert_chat_messages?: {
    __typename?: 'chat_messages_mutation_response';
    returning: Array<{
      __typename?: 'chat_messages';
      id: string;
      chatSessionId: string;
      accountId?: string | null;
      staffId?: string | null;
      data?: any | null;
      message: string;
      type: Chat_Messages_Type_Enum;
      createdAt: string;
      updatedAt: string;
      request?: any | null;
      response?: any | null;
    }>;
  } | null;
};

export type CreateChatSessionMutationVariables = Exact<{
  chatSession: Chat_Sessions_Insert_Input;
}>;

export type CreateChatSessionMutation = {
  __typename?: 'mutation_root';
  insert_chat_sessions?: {
    __typename?: 'chat_sessions_mutation_response';
    returning: Array<{
      __typename?: 'chat_sessions';
      id: string;
      status: Chat_Sessions_Status_Enum;
      updatedAt: string;
      createdByAccountId?: string | null;
      createdAt: string;
    }>;
  } | null;
};

export type UpdateChatSessionStatusMutationVariables = Exact<{
  id: Scalars['uuid'];
  status: Chat_Sessions_Status_Enum;
}>;

export type UpdateChatSessionStatusMutation = {
  __typename?: 'mutation_root';
  update_chat_sessions?: {
    __typename?: 'chat_sessions_mutation_response';
    returning: Array<{
      __typename?: 'chat_sessions';
      id: string;
      status: Chat_Sessions_Status_Enum;
      updatedAt: string;
      createdByAccountId?: string | null;
      createdAt: string;
    }>;
  } | null;
};

export type InsertChatSessionAccountMutationVariables = Exact<{
  chatSessionAccount: Chat_Sessions_Accounts_Insert_Input;
}>;

export type InsertChatSessionAccountMutation = {
  __typename?: 'mutation_root';
  insert_chat_sessions_accounts?: {
    __typename?: 'chat_sessions_accounts_mutation_response';
    returning: Array<{
      __typename?: 'chat_sessions_accounts';
      id: string;
      accountId: string;
      active: boolean;
      chatSessionId: string;
      createdAt: string;
      updatedAt: string;
      account: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      };
    }>;
  } | null;
};

export type InsertChatSessionPatientMutationVariables = Exact<{
  chatSessionPatient: Chat_Sessions_Patients_Insert_Input;
}>;

export type InsertChatSessionPatientMutation = {
  __typename?: 'mutation_root';
  insert_chat_sessions_patients?: {
    __typename?: 'chat_sessions_patients_mutation_response';
    returning: Array<{
      __typename?: 'chat_sessions_patients';
      id: string;
      createdAt: string;
      chatSessionId: string;
      updatedAt: string;
      patientId: string;
      patient: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
        goesByName?: string | null;
        pronunciation?: string | null;
        dateOfBirth: string;
        deceasedAt?: string | null;
        consentSignedByUserId?: string | null;
        consentToTelemedicineTermsTimestamp?: string | null;
        additionalInfo?: string | null;
        consentSignedAt?: string | null;
        consentSigned?: boolean | null;
        avatarId?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
        genderAtBirth?: string | null;
        primaryCareStaffId?: string | null;
        primaryClinicAddress?: any | null;
        physicianName?: string | null;
        preferredPharmacy?: any | null;
        address?: any | null;
        hasPCP?: string | null;
        lastWellVisitStatus?: string | null;
        vaccinationStatus?: string | null;
        kareo_patient_id?: string | null;
        kareo_guid?: string | null;
        consentSignedByUser?: {
          __typename?: 'users';
          account?: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          } | null;
        } | null;
        consentToTelemedicineTermsByAccount?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
        parsed_address?: {
          __typename?: 'patient_address';
          fullAddress?: string | null;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          zip?: string | null;
          state?: string | null;
        } | null;
      };
    }>;
  } | null;
};

export type InsertChatSessionStaffMutationVariables = Exact<{
  chatSessionStaff: Chat_Sessions_Staff_Insert_Input;
}>;

export type InsertChatSessionStaffMutation = {
  __typename?: 'mutation_root';
  insert_chat_sessions_staff?: {
    __typename?: 'chat_sessions_staff_mutation_response';
    returning: Array<{
      __typename?: 'chat_sessions_staff';
      id: string;
      active: boolean;
      chatSessionId: string;
      created_at: string;
      staffId: string;
      updated_at: string;
      staff: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      };
    }>;
  } | null;
};

export type LeaveChatMutationVariables = Exact<{
  chatSessionId: Scalars['uuid'];
  staffId: Scalars['uuid'];
}>;

export type LeaveChatMutation = {
  __typename?: 'mutation_root';
  update_chat_sessions_staff?: {
    __typename?: 'chat_sessions_staff_mutation_response';
    returning: Array<{
      __typename?: 'chat_sessions_staff';
      id: string;
      active: boolean;
      chatSessionId: string;
      created_at: string;
      staffId: string;
      updated_at: string;
      staff: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      };
    }>;
  } | null;
};

export type RejoinChatMutationVariables = Exact<{
  chatSessionId: Scalars['uuid'];
  staffId: Scalars['uuid'];
}>;

export type RejoinChatMutation = {
  __typename?: 'mutation_root';
  update_chat_sessions_staff?: {
    __typename?: 'chat_sessions_staff_mutation_response';
    returning: Array<{
      __typename?: 'chat_sessions_staff';
      id: string;
      active: boolean;
      chatSessionId: string;
      created_at: string;
      staffId: string;
      updated_at: string;
      staff: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      };
    }>;
  } | null;
};

export type UpdateReadReceiptMutationVariables = Exact<{
  read: Chat_Sessions_Read_Insert_Input;
}>;

export type UpdateReadReceiptMutation = {
  __typename?: 'mutation_root';
  insert_chat_sessions_read?: {
    __typename?: 'chat_sessions_read_mutation_response';
    returning: Array<{ __typename?: 'chat_sessions_read'; id: string }>;
  } | null;
};

export type CreateChatNoteMutationVariables = Exact<{
  chatNote: Chat_Notes_Insert_Input;
}>;

export type CreateChatNoteMutation = {
  __typename?: 'mutation_root';
  insert_chat_notes?: {
    __typename?: 'chat_notes_mutation_response';
    returning: Array<{
      __typename?: 'chat_notes';
      id: string;
      chatSessionId?: string | null;
      createdAt: string;
      note?: string | null;
      staffId?: string | null;
      type: Chat_Notes_Types_Enum;
      updatedAt?: string | null;
      staff?: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      } | null;
    }>;
  } | null;
};

export type TotalChatSessionsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type TotalChatSessionsSubscription = {
  __typename?: 'subscription_root';
  chat_sessions_aggregate: {
    __typename?: 'chat_sessions_aggregate';
    aggregate?: {
      __typename?: 'chat_sessions_aggregate_fields';
      count: number;
    } | null;
  };
};

export type GetClinicByCodeQueryVariables = Exact<{
  clinicCode: Scalars['String'];
}>;

export type GetClinicByCodeQuery = {
  __typename?: 'query_root';
  clinics: Array<{
    __typename?: 'clinics';
    id: string;
    address: string;
    clinicCode: string;
    close: string;
    created_at: string;
    latitude?: string | null;
    locationType: Clinic_Location_Types_Enum;
    longitude?: string | null;
    name: string;
    open: string;
    parentClinicId?: string | null;
    phoneNumber: string;
    status: Clinic_Status_Enum;
    timeZone?: string | null;
    updated_at: string;
  }>;
};

export type LoadAllClinicsQueryVariables = Exact<{
  where?: InputMaybe<Clinics_Bool_Exp>;
}>;

export type LoadAllClinicsQuery = {
  __typename?: 'query_root';
  clinics: Array<{
    __typename?: 'clinics';
    id: string;
    address: string;
    clinicCode: string;
    close: string;
    created_at: string;
    latitude?: string | null;
    locationType: Clinic_Location_Types_Enum;
    longitude?: string | null;
    name: string;
    open: string;
    parentClinicId?: string | null;
    phoneNumber: string;
    status: Clinic_Status_Enum;
    timeZone?: string | null;
    updated_at: string;
  }>;
};

export type DevicesSubscriptionVariables = Exact<{
  where?: InputMaybe<Devices_Bool_Exp>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Devices_Order_By> | Devices_Order_By>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type DevicesSubscription = {
  __typename?: 'subscription_root';
  devices: Array<{
    __typename?: 'devices';
    appVersion?: string | null;
    batteryLevel?: number | null;
    batteryState?: string | null;
    clinicCode?: string | null;
    codepushVersion?: string | null;
    createdAt?: string | null;
    id: string;
    locationId?: string | null;
    name?: string | null;
    osVersion?: string | null;
    updatedAt?: string | null;
    wifiNetwork?: string | null;
    clinic?: {
      __typename?: 'clinics';
      id: string;
      name: string;
      clinicCode: string;
    } | null;
    location?: {
      __typename?: 'locations';
      id: string;
      name: string;
      clinicCode: string;
    } | null;
  }>;
};

export type DevicesByClinicCodeSubscriptionVariables = Exact<{
  clinicCode: Scalars['String'];
}>;

export type DevicesByClinicCodeSubscription = {
  __typename?: 'subscription_root';
  devices: Array<{
    __typename?: 'devices';
    id: string;
    name?: string | null;
    updatedAt?: string | null;
    location?: { __typename?: 'locations'; id: string; name: string } | null;
  }>;
};

export type LocationsByClinicCodeSubscriptionVariables = Exact<{
  clinicCode: Scalars['String'];
}>;

export type LocationsByClinicCodeSubscription = {
  __typename?: 'subscription_root';
  locations: Array<{
    __typename?: 'locations';
    id: string;
    name: string;
    devices: Array<{
      __typename?: 'devices';
      id: string;
      name?: string | null;
      updatedAt?: string | null;
    }>;
  }>;
};

export type CommunicationHistoryFragment = {
  __typename?: 'communication_history';
  id: string;
  body?: string | null;
  createdAt: string;
  updatedAt: string;
  direction?: Communication_History_Directions_Enum | null;
  from: string;
  metadata?: any | null;
  status?: string | null;
  to: string;
  type: Communication_History_Types_Enum;
  account?: {
    __typename?: 'accounts';
    id: string;
    createdAt: string;
    consentToMedicalTermsTimestamp?: string | null;
    consentToTermsAndPrivacyTimestamp?: string | null;
    email?: string | null;
    firstName?: string | null;
    goesByName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    postalCode?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
  } | null;
  staff?: {
    __typename?: 'staff';
    id: string;
    active: boolean;
    firstName: string;
    lastName: string;
    auth0UserId?: string | null;
    created_at: string;
    defaultClinicId?: string | null;
    timeZone: string;
    type: Staff_Type_Enum;
    updated_at: string;
    email?: string | null;
    avatarUrl?: string | null;
    color?: string | null;
    lead: boolean;
    staffManagerId?: string | null;
    staff_type: {
      __typename?: 'staff_type';
      description: string;
      value: string;
    };
  } | null;
};

export type CommunicationHistoryDataFragment = {
  __typename?: 'communication_history';
  id: string;
  body?: string | null;
  createdAt: string;
  updatedAt: string;
  direction?: Communication_History_Directions_Enum | null;
  from: string;
  metadata?: any | null;
  status?: string | null;
  to: string;
  type: Communication_History_Types_Enum;
  appointment?: {
    __typename?: 'appointments';
    id: string;
    accountId?: string | null;
    address?: any | null;
    campaign?: string | null;
    clinicId: string;
    createdAt: string;
    email?: string | null;
    endTime: string;
    firstName?: string | null;
    kareo_appointment_id?: string | null;
    kareo_appointment_uuid?: string | null;
    languageSelection?: string | null;
    lastName?: string | null;
    notes?: string | null;
    patientId?: string | null;
    payment?: Appointment_Payment_Enum | null;
    phoneNumber?: string | null;
    reason: string;
    safetyQuestionnaire?: any | null;
    startTime: string;
    state?: string | null;
    status?: Appointment_Status_Enum | null;
    stripeCardId?: string | null;
    stripeCustomerId?: string | null;
    timeZone?: string | null;
    type?: Appointment_Types_Enum | null;
    visitClassification?: Visit_Classifications_Enum | null;
    visitType?: Visit_Types_Enum | null;
  } | null;
  global_notes: Array<{
    __typename?: 'global_notes';
    id: string;
    appointmentId?: string | null;
    deleted: boolean;
    patientId?: string | null;
    accountId?: string | null;
    updatedAt: string;
    createdAt: string;
    note: string;
    type?: string | null;
    staffId?: string | null;
    staff?: {
      __typename?: 'staff';
      id: string;
      active: boolean;
      firstName: string;
      lastName: string;
      auth0UserId?: string | null;
      created_at: string;
      defaultClinicId?: string | null;
      timeZone: string;
      type: Staff_Type_Enum;
      updated_at: string;
      email?: string | null;
      avatarUrl?: string | null;
      color?: string | null;
      lead: boolean;
      staffManagerId?: string | null;
      staff_type: {
        __typename?: 'staff_type';
        description: string;
        value: string;
      };
    } | null;
  }>;
  patient?: {
    __typename?: 'patients';
    id: string;
    firstName: string;
    lastName: string;
    goesByName?: string | null;
    pronunciation?: string | null;
    dateOfBirth: string;
    deceasedAt?: string | null;
    consentSignedByUserId?: string | null;
    consentToTelemedicineTermsTimestamp?: string | null;
    additionalInfo?: string | null;
    consentSignedAt?: string | null;
    consentSigned?: boolean | null;
    avatarId?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
    genderAtBirth?: string | null;
    primaryCareStaffId?: string | null;
    primaryClinicAddress?: any | null;
    physicianName?: string | null;
    preferredPharmacy?: any | null;
    address?: any | null;
    hasPCP?: string | null;
    lastWellVisitStatus?: string | null;
    vaccinationStatus?: string | null;
    kareo_patient_id?: string | null;
    kareo_guid?: string | null;
    consentSignedByUser?: {
      __typename?: 'users';
      account?: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      } | null;
    } | null;
    consentToTelemedicineTermsByAccount?: {
      __typename?: 'accounts';
      id: string;
      createdAt: string;
      consentToMedicalTermsTimestamp?: string | null;
      consentToTermsAndPrivacyTimestamp?: string | null;
      email?: string | null;
      firstName?: string | null;
      goesByName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      postalCode?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
    } | null;
    parsed_address?: {
      __typename?: 'patient_address';
      fullAddress?: string | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      zip?: string | null;
      state?: string | null;
    } | null;
  } | null;
  account?: {
    __typename?: 'accounts';
    id: string;
    createdAt: string;
    consentToMedicalTermsTimestamp?: string | null;
    consentToTermsAndPrivacyTimestamp?: string | null;
    email?: string | null;
    firstName?: string | null;
    goesByName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    postalCode?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
  } | null;
  staff?: {
    __typename?: 'staff';
    id: string;
    active: boolean;
    firstName: string;
    lastName: string;
    auth0UserId?: string | null;
    created_at: string;
    defaultClinicId?: string | null;
    timeZone: string;
    type: Staff_Type_Enum;
    updated_at: string;
    email?: string | null;
    avatarUrl?: string | null;
    color?: string | null;
    lead: boolean;
    staffManagerId?: string | null;
    staff_type: {
      __typename?: 'staff_type';
      description: string;
      value: string;
    };
  } | null;
};

export type CommunicationHistorySubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
}>;

export type CommunicationHistorySubscription = {
  __typename?: 'subscription_root';
  communication_history: Array<{
    __typename?: 'communication_history';
    id: string;
    body?: string | null;
    createdAt: string;
    updatedAt: string;
    direction?: Communication_History_Directions_Enum | null;
    from: string;
    metadata?: any | null;
    status?: string | null;
    to: string;
    type: Communication_History_Types_Enum;
    account?: {
      __typename?: 'accounts';
      id: string;
      createdAt: string;
      consentToMedicalTermsTimestamp?: string | null;
      consentToTermsAndPrivacyTimestamp?: string | null;
      email?: string | null;
      firstName?: string | null;
      goesByName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      postalCode?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
    } | null;
    staff?: {
      __typename?: 'staff';
      id: string;
      active: boolean;
      firstName: string;
      lastName: string;
      auth0UserId?: string | null;
      created_at: string;
      defaultClinicId?: string | null;
      timeZone: string;
      type: Staff_Type_Enum;
      updated_at: string;
      email?: string | null;
      avatarUrl?: string | null;
      color?: string | null;
      lead: boolean;
      staffManagerId?: string | null;
      staff_type: {
        __typename?: 'staff_type';
        description: string;
        value: string;
      };
    } | null;
  }>;
};

export type LoadCommunicationHistoryQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type LoadCommunicationHistoryQuery = {
  __typename?: 'query_root';
  communication_history_by_pk?: {
    __typename?: 'communication_history';
    id: string;
    body?: string | null;
    createdAt: string;
    updatedAt: string;
    direction?: Communication_History_Directions_Enum | null;
    from: string;
    metadata?: any | null;
    status?: string | null;
    to: string;
    type: Communication_History_Types_Enum;
    appointment?: {
      __typename?: 'appointments';
      id: string;
      accountId?: string | null;
      address?: any | null;
      campaign?: string | null;
      clinicId: string;
      createdAt: string;
      email?: string | null;
      endTime: string;
      firstName?: string | null;
      kareo_appointment_id?: string | null;
      kareo_appointment_uuid?: string | null;
      languageSelection?: string | null;
      lastName?: string | null;
      notes?: string | null;
      patientId?: string | null;
      payment?: Appointment_Payment_Enum | null;
      phoneNumber?: string | null;
      reason: string;
      safetyQuestionnaire?: any | null;
      startTime: string;
      state?: string | null;
      status?: Appointment_Status_Enum | null;
      stripeCardId?: string | null;
      stripeCustomerId?: string | null;
      timeZone?: string | null;
      type?: Appointment_Types_Enum | null;
      visitClassification?: Visit_Classifications_Enum | null;
      visitType?: Visit_Types_Enum | null;
    } | null;
    global_notes: Array<{
      __typename?: 'global_notes';
      id: string;
      appointmentId?: string | null;
      deleted: boolean;
      patientId?: string | null;
      accountId?: string | null;
      updatedAt: string;
      createdAt: string;
      note: string;
      type?: string | null;
      staffId?: string | null;
      staff?: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      } | null;
    }>;
    patient?: {
      __typename?: 'patients';
      id: string;
      firstName: string;
      lastName: string;
      goesByName?: string | null;
      pronunciation?: string | null;
      dateOfBirth: string;
      deceasedAt?: string | null;
      consentSignedByUserId?: string | null;
      consentToTelemedicineTermsTimestamp?: string | null;
      additionalInfo?: string | null;
      consentSignedAt?: string | null;
      consentSigned?: boolean | null;
      avatarId?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
      genderAtBirth?: string | null;
      primaryCareStaffId?: string | null;
      primaryClinicAddress?: any | null;
      physicianName?: string | null;
      preferredPharmacy?: any | null;
      address?: any | null;
      hasPCP?: string | null;
      lastWellVisitStatus?: string | null;
      vaccinationStatus?: string | null;
      kareo_patient_id?: string | null;
      kareo_guid?: string | null;
      consentSignedByUser?: {
        __typename?: 'users';
        account?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
      } | null;
      consentToTelemedicineTermsByAccount?: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      } | null;
      parsed_address?: {
        __typename?: 'patient_address';
        fullAddress?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        zip?: string | null;
        state?: string | null;
      } | null;
    } | null;
    account?: {
      __typename?: 'accounts';
      id: string;
      createdAt: string;
      consentToMedicalTermsTimestamp?: string | null;
      consentToTermsAndPrivacyTimestamp?: string | null;
      email?: string | null;
      firstName?: string | null;
      goesByName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      postalCode?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
    } | null;
    staff?: {
      __typename?: 'staff';
      id: string;
      active: boolean;
      firstName: string;
      lastName: string;
      auth0UserId?: string | null;
      created_at: string;
      defaultClinicId?: string | null;
      timeZone: string;
      type: Staff_Type_Enum;
      updated_at: string;
      email?: string | null;
      avatarUrl?: string | null;
      color?: string | null;
      lead: boolean;
      staffManagerId?: string | null;
      staff_type: {
        __typename?: 'staff_type';
        description: string;
        value: string;
      };
    } | null;
  } | null;
};

export type CommunicationHistoryDataSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type CommunicationHistoryDataSubscription = {
  __typename?: 'subscription_root';
  communication_history_by_pk?: {
    __typename?: 'communication_history';
    id: string;
    body?: string | null;
    createdAt: string;
    updatedAt: string;
    direction?: Communication_History_Directions_Enum | null;
    from: string;
    metadata?: any | null;
    status?: string | null;
    to: string;
    type: Communication_History_Types_Enum;
    appointment?: {
      __typename?: 'appointments';
      id: string;
      accountId?: string | null;
      address?: any | null;
      campaign?: string | null;
      clinicId: string;
      createdAt: string;
      email?: string | null;
      endTime: string;
      firstName?: string | null;
      kareo_appointment_id?: string | null;
      kareo_appointment_uuid?: string | null;
      languageSelection?: string | null;
      lastName?: string | null;
      notes?: string | null;
      patientId?: string | null;
      payment?: Appointment_Payment_Enum | null;
      phoneNumber?: string | null;
      reason: string;
      safetyQuestionnaire?: any | null;
      startTime: string;
      state?: string | null;
      status?: Appointment_Status_Enum | null;
      stripeCardId?: string | null;
      stripeCustomerId?: string | null;
      timeZone?: string | null;
      type?: Appointment_Types_Enum | null;
      visitClassification?: Visit_Classifications_Enum | null;
      visitType?: Visit_Types_Enum | null;
    } | null;
    global_notes: Array<{
      __typename?: 'global_notes';
      id: string;
      appointmentId?: string | null;
      deleted: boolean;
      patientId?: string | null;
      accountId?: string | null;
      updatedAt: string;
      createdAt: string;
      note: string;
      type?: string | null;
      staffId?: string | null;
      staff?: {
        __typename?: 'staff';
        id: string;
        active: boolean;
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        avatarUrl?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      } | null;
    }>;
    patient?: {
      __typename?: 'patients';
      id: string;
      firstName: string;
      lastName: string;
      goesByName?: string | null;
      pronunciation?: string | null;
      dateOfBirth: string;
      deceasedAt?: string | null;
      consentSignedByUserId?: string | null;
      consentToTelemedicineTermsTimestamp?: string | null;
      additionalInfo?: string | null;
      consentSignedAt?: string | null;
      consentSigned?: boolean | null;
      avatarId?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
      genderAtBirth?: string | null;
      primaryCareStaffId?: string | null;
      primaryClinicAddress?: any | null;
      physicianName?: string | null;
      preferredPharmacy?: any | null;
      address?: any | null;
      hasPCP?: string | null;
      lastWellVisitStatus?: string | null;
      vaccinationStatus?: string | null;
      kareo_patient_id?: string | null;
      kareo_guid?: string | null;
      consentSignedByUser?: {
        __typename?: 'users';
        account?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
      } | null;
      consentToTelemedicineTermsByAccount?: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      } | null;
      parsed_address?: {
        __typename?: 'patient_address';
        fullAddress?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        zip?: string | null;
        state?: string | null;
      } | null;
    } | null;
    account?: {
      __typename?: 'accounts';
      id: string;
      createdAt: string;
      consentToMedicalTermsTimestamp?: string | null;
      consentToTermsAndPrivacyTimestamp?: string | null;
      email?: string | null;
      firstName?: string | null;
      goesByName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      postalCode?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
    } | null;
    staff?: {
      __typename?: 'staff';
      id: string;
      active: boolean;
      firstName: string;
      lastName: string;
      auth0UserId?: string | null;
      created_at: string;
      defaultClinicId?: string | null;
      timeZone: string;
      type: Staff_Type_Enum;
      updated_at: string;
      email?: string | null;
      avatarUrl?: string | null;
      color?: string | null;
      lead: boolean;
      staffManagerId?: string | null;
      staff_type: {
        __typename?: 'staff_type';
        description: string;
        value: string;
      };
    } | null;
  } | null;
};

export type LoadPhoneNumbersQueryVariables = Exact<{
  where?: InputMaybe<Phone_Numbers_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type LoadPhoneNumbersQuery = {
  __typename?: 'query_root';
  phone_numbers: Array<{
    __typename?: 'phone_numbers';
    id: string;
    clinicId?: string | null;
    description?: string | null;
    extension?: string | null;
    name: string;
    phoneNumber: string;
    staffId?: string | null;
    clinic?: { __typename?: 'clinics'; id: string; name: string } | null;
    staff?: {
      __typename?: 'staff';
      id: string;
      lastName: string;
      firstName: string;
    } | null;
  }>;
};

export type LoadCommunicationHistoryTagTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LoadCommunicationHistoryTagTypesQuery = {
  __typename?: 'query_root';
  communication_history_tag_types: Array<{
    __typename?: 'communication_history_tag_types';
    value: string;
    description?: string | null;
  }>;
};

export type LoadCommunicationHistoryTagsQueryVariables = Exact<{
  communicationHistoryId: Scalars['uuid'];
}>;

export type LoadCommunicationHistoryTagsQuery = {
  __typename?: 'query_root';
  communication_history_tags: Array<{
    __typename?: 'communication_history_tags';
    id: string;
    tag: Communication_History_Tag_Types_Enum;
  }>;
};

export type InsertCommunicationHistoryTagMutationVariables = Exact<{
  communicationHistoryId: Scalars['uuid'];
  tag: Communication_History_Tag_Types_Enum;
}>;

export type InsertCommunicationHistoryTagMutation = {
  __typename?: 'mutation_root';
  insert_communication_history_tags_one?: {
    __typename?: 'communication_history_tags';
    id: string;
  } | null;
};

export type DeleteCommunicationHistoryTagsMutationVariables = Exact<{
  where: Communication_History_Tags_Bool_Exp;
}>;

export type DeleteCommunicationHistoryTagsMutation = {
  __typename?: 'mutation_root';
  delete_communication_history_tags?: {
    __typename?: 'communication_history_tags_mutation_response';
    affected_rows: number;
  } | null;
};

export type UpdateCommunicationHistoryMutationVariables = Exact<{
  id: Scalars['uuid'];
  set: Communication_History_Set_Input;
}>;

export type UpdateCommunicationHistoryMutation = {
  __typename?: 'mutation_root';
  update_communication_history_by_pk?: {
    __typename?: 'communication_history';
    id: string;
  } | null;
};

export type GetActivePhoneCallsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetActivePhoneCallsSubscription = {
  __typename?: 'subscription_root';
  communication_history: Array<{
    __typename?: 'communication_history';
    id: string;
    updatedAt: string;
    status?: string | null;
    reasonForCall?: any | null;
    staff?: { __typename?: 'staff'; auth0UserId?: string | null } | null;
  }>;
};

export type CommunicationsPageDataFragment = {
  __typename?: 'communication_history';
  id: string;
  createdAt: string;
  updatedAt: string;
  direction?: Communication_History_Directions_Enum | null;
  status?: string | null;
  from: string;
  to: string;
  reasonForCall?: any | null;
  callerHungUp?: any | null;
  account?: {
    __typename?: 'accounts';
    id: string;
    goesByName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  staff?: {
    __typename?: 'staff';
    avatarUrl?: string | null;
    firstName: string;
    lastName: string;
    staff_type: { __typename?: 'staff_type'; value: string };
  } | null;
};

export type CommunicationsPageSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type CommunicationsPageSubscription = {
  __typename?: 'subscription_root';
  communication_history: Array<{
    __typename?: 'communication_history';
    id: string;
    createdAt: string;
    updatedAt: string;
    direction?: Communication_History_Directions_Enum | null;
    status?: string | null;
    from: string;
    to: string;
    reasonForCall?: any | null;
    callerHungUp?: any | null;
    account?: {
      __typename?: 'accounts';
      id: string;
      goesByName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    staff?: {
      __typename?: 'staff';
      avatarUrl?: string | null;
      firstName: string;
      lastName: string;
      staff_type: { __typename?: 'staff_type'; value: string };
    } | null;
  }>;
};

export type RecentCommunicationsPageDataFragment = {
  __typename?: 'communication_history';
  id: string;
  body?: string | null;
  createdAt: string;
  direction?: Communication_History_Directions_Enum | null;
  from: string;
  to: string;
  reasonForCall?: any | null;
  account?: {
    __typename?: 'accounts';
    goesByName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  staff?: { __typename?: 'staff'; firstName: string; lastName: string } | null;
};

export type RecentCommunicationsPageSubscriptionVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Communication_History_Bool_Exp>;
}>;

export type RecentCommunicationsPageSubscription = {
  __typename?: 'subscription_root';
  communication_history: Array<{
    __typename?: 'communication_history';
    id: string;
    body?: string | null;
    createdAt: string;
    direction?: Communication_History_Directions_Enum | null;
    from: string;
    to: string;
    reasonForCall?: any | null;
    account?: {
      __typename?: 'accounts';
      goesByName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    staff?: {
      __typename?: 'staff';
      firstName: string;
      lastName: string;
    } | null;
  }>;
};

export type LoadDocumentsQueryVariables = Exact<{
  where?: InputMaybe<Documents_Bool_Exp>;
  order_by?: InputMaybe<Array<Documents_Order_By> | Documents_Order_By>;
}>;

export type LoadDocumentsQuery = {
  __typename?: 'query_root';
  documents: Array<{
    __typename?: 'documents';
    id: string;
    status: Document_Status_Types_Enum;
    type: Document_Types_Enum;
    version: number;
    accountId: string;
    appointmentId?: string | null;
    created_at: string;
    fileId?: string | null;
    patientId?: string | null;
  }>;
};

export type RequestUploadMutationVariables = Exact<{
  fileName: Scalars['String'];
  contentMD5: Scalars['String'];
  accountId?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['String']>;
  documentType?: InputMaybe<Scalars['String']>;
}>;

export type RequestUploadMutation = {
  __typename?: 'mutation_root';
  RequestUpload?: {
    __typename?: 'RequestUploadOutput';
    fileId: string;
    url: string;
    mimeType: string;
  } | null;
};

export type UpdateFileMutationVariables = Exact<{
  id: Scalars['uuid'];
  file: Files_Set_Input;
}>;

export type UpdateFileMutation = {
  __typename?: 'mutation_root';
  update_files_by_pk?: {
    __typename?: 'files';
    id: string;
    createdAt: string;
    updatedAt: string;
    accountId?: string | null;
    patientId?: string | null;
    documentType?: File_Document_Type_Enum | null;
    gcv?: any | null;
    path?: string | null;
    status: string;
  } | null;
};

export type AppointmentSchedulePartsFragment = {
  __typename?: 'appointments';
  id: string;
  accountId?: string | null;
  address?: any | null;
  campaign?: string | null;
  clinicId: string;
  createdAt: string;
  email?: string | null;
  endTime: string;
  firstName?: string | null;
  kareo_appointment_id?: string | null;
  kareo_appointment_uuid?: string | null;
  languageSelection?: string | null;
  lastName?: string | null;
  notes?: string | null;
  patientId?: string | null;
  payment?: Appointment_Payment_Enum | null;
  phoneNumber?: string | null;
  reason: string;
  safetyQuestionnaire?: any | null;
  startTime: string;
  state?: string | null;
  status?: Appointment_Status_Enum | null;
  stripeCardId?: string | null;
  stripeCustomerId?: string | null;
  timeZone?: string | null;
  type?: Appointment_Types_Enum | null;
  visitClassification?: Visit_Classifications_Enum | null;
  visitType?: Visit_Types_Enum | null;
  reviews: Array<{
    __typename?: 'reviews';
    id: string;
    review?: string | null;
    createdAt: string;
    updatedAt: string;
    nps?: number | null;
    appointmentId?: string | null;
  }>;
  clinic: {
    __typename?: 'clinics';
    id: string;
    address: string;
    clinicCode: string;
    close: string;
    created_at: string;
    latitude?: string | null;
    locationType: Clinic_Location_Types_Enum;
    longitude?: string | null;
    name: string;
    open: string;
    parentClinicId?: string | null;
    phoneNumber: string;
    status: Clinic_Status_Enum;
    timeZone?: string | null;
    updated_at: string;
  };
  appointment_staffs: Array<{
    __typename?: 'appointment_staff';
    id: string;
    appointmentId: string;
    staffId: string;
    created_at: string;
    updated_at: string;
    staff: {
      __typename?: 'staff';
      id: string;
      active: boolean;
      firstName: string;
      lastName: string;
      auth0UserId?: string | null;
      created_at: string;
      defaultClinicId?: string | null;
      timeZone: string;
      type: Staff_Type_Enum;
      updated_at: string;
      email?: string | null;
      avatarUrl?: string | null;
      color?: string | null;
      lead: boolean;
      staffManagerId?: string | null;
      staff_type: {
        __typename?: 'staff_type';
        description: string;
        value: string;
      };
    };
  }>;
  appointment_patients: Array<{
    __typename?: 'appointment_patient';
    id: string;
    appointment_id: string;
    created_at: string;
    updated_at: string;
    patient_id: string;
    patient: {
      __typename?: 'patients';
      id: string;
      firstName: string;
      lastName: string;
      goesByName?: string | null;
      pronunciation?: string | null;
      dateOfBirth: string;
      deceasedAt?: string | null;
      consentSignedByUserId?: string | null;
      consentToTelemedicineTermsTimestamp?: string | null;
      additionalInfo?: string | null;
      consentSignedAt?: string | null;
      consentSigned?: boolean | null;
      avatarId?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
      genderAtBirth?: string | null;
      primaryCareStaffId?: string | null;
      primaryClinicAddress?: any | null;
      physicianName?: string | null;
      preferredPharmacy?: any | null;
      address?: any | null;
      hasPCP?: string | null;
      lastWellVisitStatus?: string | null;
      vaccinationStatus?: string | null;
      kareo_patient_id?: string | null;
      kareo_guid?: string | null;
      global_notes: Array<{
        __typename?: 'global_notes';
        id: string;
        appointmentId?: string | null;
        deleted: boolean;
        patientId?: string | null;
        accountId?: string | null;
        updatedAt: string;
        createdAt: string;
        note: string;
        type?: string | null;
        staffId?: string | null;
        staff?: {
          __typename?: 'staff';
          id: string;
          active: boolean;
          firstName: string;
          lastName: string;
          auth0UserId?: string | null;
          created_at: string;
          defaultClinicId?: string | null;
          timeZone: string;
          type: Staff_Type_Enum;
          updated_at: string;
          email?: string | null;
          avatarUrl?: string | null;
          color?: string | null;
          lead: boolean;
          staffManagerId?: string | null;
          staff_type: {
            __typename?: 'staff_type';
            description: string;
            value: string;
          };
        } | null;
      }>;
      insurances: Array<{
        __typename?: 'insurance';
        id: string;
        accountId: string;
        backId?: string | null;
        carrier?: string | null;
        dateOfBirth?: string | null;
        eligibility?: any | null;
        firstName?: string | null;
        frontId?: string | null;
        lastName?: string | null;
        memberId?: string | null;
        type: Insurance_Types_Enum;
        updatedAt: string;
      }>;
      accounts: Array<{
        __typename?: 'account_patient';
        id: string;
        accountId: string;
        patientId: string;
        relationship?: string | null;
        account: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
          global_notes: Array<{
            __typename?: 'global_notes';
            id: string;
            appointmentId?: string | null;
            deleted: boolean;
            patientId?: string | null;
            accountId?: string | null;
            updatedAt: string;
            createdAt: string;
            note: string;
            type?: string | null;
            staffId?: string | null;
            staff?: {
              __typename?: 'staff';
              id: string;
              active: boolean;
              firstName: string;
              lastName: string;
              auth0UserId?: string | null;
              created_at: string;
              defaultClinicId?: string | null;
              timeZone: string;
              type: Staff_Type_Enum;
              updated_at: string;
              email?: string | null;
              avatarUrl?: string | null;
              color?: string | null;
              lead: boolean;
              staffManagerId?: string | null;
              staff_type: {
                __typename?: 'staff_type';
                description: string;
                value: string;
              };
            } | null;
          }>;
        };
      }>;
      consentSignedByUser?: {
        __typename?: 'users';
        account?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
      } | null;
      consentToTelemedicineTermsByAccount?: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      } | null;
      parsed_address?: {
        __typename?: 'patient_address';
        fullAddress?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        zip?: string | null;
        state?: string | null;
      } | null;
    };
  }>;
};

export type DashboardAppointmentFragment = {
  __typename?: 'appointments';
  id: string;
  endTime: string;
  kareo_appointment_id?: string | null;
  reason: string;
  safetyQuestionnaire?: any | null;
  startTime: string;
  status?: Appointment_Status_Enum | null;
  visitClassification?: Visit_Classifications_Enum | null;
  visitType?: Visit_Types_Enum | null;
  appointment_staffs: Array<{
    __typename?: 'appointment_staff';
    staff: { __typename?: 'staff'; id: string; avatarUrl?: string | null };
  }>;
  appointment_patients: Array<{
    __typename?: 'appointment_patient';
    patient: {
      __typename?: 'patients';
      id: string;
      firstName: string;
      lastName: string;
      goesByName?: string | null;
      dateOfBirth: string;
      pronunciation?: string | null;
      global_notes: Array<{
        __typename?: 'global_notes';
        id: string;
        note: string;
      }>;
    };
  }>;
};

export type AppointmentPartsFragment = {
  __typename?: 'appointments';
  id: string;
  accountId?: string | null;
  address?: any | null;
  campaign?: string | null;
  clinicId: string;
  createdAt: string;
  email?: string | null;
  endTime: string;
  firstName?: string | null;
  kareo_appointment_id?: string | null;
  kareo_appointment_uuid?: string | null;
  languageSelection?: string | null;
  lastName?: string | null;
  notes?: string | null;
  patientId?: string | null;
  payment?: Appointment_Payment_Enum | null;
  phoneNumber?: string | null;
  reason: string;
  safetyQuestionnaire?: any | null;
  startTime: string;
  state?: string | null;
  status?: Appointment_Status_Enum | null;
  stripeCardId?: string | null;
  stripeCustomerId?: string | null;
  timeZone?: string | null;
  type?: Appointment_Types_Enum | null;
  visitClassification?: Visit_Classifications_Enum | null;
  visitType?: Visit_Types_Enum | null;
  appointment_patients: Array<{
    __typename?: 'appointment_patient';
    id: string;
    appointment_id: string;
    created_at: string;
    updated_at: string;
    patient_id: string;
    patient: {
      __typename?: 'patients';
      id: string;
      firstName: string;
      lastName: string;
      goesByName?: string | null;
      pronunciation?: string | null;
      dateOfBirth: string;
      deceasedAt?: string | null;
      consentSignedByUserId?: string | null;
      consentToTelemedicineTermsTimestamp?: string | null;
      additionalInfo?: string | null;
      consentSignedAt?: string | null;
      consentSigned?: boolean | null;
      avatarId?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
      genderAtBirth?: string | null;
      primaryCareStaffId?: string | null;
      primaryClinicAddress?: any | null;
      physicianName?: string | null;
      preferredPharmacy?: any | null;
      address?: any | null;
      hasPCP?: string | null;
      lastWellVisitStatus?: string | null;
      vaccinationStatus?: string | null;
      kareo_patient_id?: string | null;
      kareo_guid?: string | null;
      insurances: Array<{
        __typename?: 'insurance';
        id: string;
        accountId: string;
        backId?: string | null;
        carrier?: string | null;
        dateOfBirth?: string | null;
        eligibility?: any | null;
        firstName?: string | null;
        frontId?: string | null;
        lastName?: string | null;
        memberId?: string | null;
        type: Insurance_Types_Enum;
        updatedAt: string;
      }>;
      accounts: Array<{
        __typename?: 'account_patient';
        id: string;
        accountId: string;
        patientId: string;
        relationship?: string | null;
        account: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        };
      }>;
      consentSignedByUser?: {
        __typename?: 'users';
        account?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
      } | null;
      consentToTelemedicineTermsByAccount?: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      } | null;
      parsed_address?: {
        __typename?: 'patient_address';
        fullAddress?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        zip?: string | null;
        state?: string | null;
      } | null;
      discovered_froms: Array<{
        __typename?: 'discovered_from';
        description?: string | null;
        type: string;
        account: {
          __typename?: 'accounts';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
        };
      }>;
    };
  }>;
  appointment_staffs: Array<{
    __typename?: 'appointment_staff';
    id: string;
    appointmentId: string;
    staffId: string;
    created_at: string;
    updated_at: string;
    staff: {
      __typename?: 'staff';
      id: string;
      active: boolean;
      firstName: string;
      lastName: string;
      auth0UserId?: string | null;
      created_at: string;
      defaultClinicId?: string | null;
      timeZone: string;
      type: Staff_Type_Enum;
      updated_at: string;
      email?: string | null;
      avatarUrl?: string | null;
      color?: string | null;
      lead: boolean;
      staffManagerId?: string | null;
      staff_type: {
        __typename?: 'staff_type';
        description: string;
        value: string;
      };
    };
  }>;
  charges: Array<{
    __typename?: 'charges';
    id: string;
    medicalAssistantId?: string | null;
    amount: number;
    accountId?: string | null;
    appointmentId?: string | null;
    cardId?: string | null;
    clinicId?: string | null;
    created_at: string;
    patientId?: string | null;
    stripeChargeId: string;
    updated_at: string;
    charge_products: Array<{
      __typename?: 'charge_products';
      id: string;
      amount: number;
      type?: string | null;
      quantity: number;
      chargeId: string;
      created_at: string;
      updated_at: string;
      productId: string;
      product: {
        __typename?: 'products';
        id: string;
        name: string;
        price: number;
        type: string;
      };
    }>;
  }>;
  clinic: {
    __typename?: 'clinics';
    id: string;
    address: string;
    clinicCode: string;
    close: string;
    created_at: string;
    latitude?: string | null;
    locationType: Clinic_Location_Types_Enum;
    longitude?: string | null;
    name: string;
    open: string;
    parentClinicId?: string | null;
    phoneNumber: string;
    status: Clinic_Status_Enum;
    timeZone?: string | null;
    updated_at: string;
  };
  reviews: Array<{
    __typename?: 'reviews';
    id: string;
    review?: string | null;
    createdAt: string;
    updatedAt: string;
    nps?: number | null;
    appointmentId?: string | null;
  }>;
  symptom_checker?: {
    __typename?: 'symptom_checker';
    id: string;
    created_at: string;
    phoneNumber?: string | null;
    session: any;
    updated_at: string;
    userId?: string | null;
  } | null;
};

export type AppointmentPartsWithImagesFragment = {
  __typename?: 'appointments';
  id: string;
  accountId?: string | null;
  address?: any | null;
  campaign?: string | null;
  clinicId: string;
  createdAt: string;
  email?: string | null;
  endTime: string;
  firstName?: string | null;
  kareo_appointment_id?: string | null;
  kareo_appointment_uuid?: string | null;
  languageSelection?: string | null;
  lastName?: string | null;
  notes?: string | null;
  patientId?: string | null;
  payment?: Appointment_Payment_Enum | null;
  phoneNumber?: string | null;
  reason: string;
  safetyQuestionnaire?: any | null;
  startTime: string;
  state?: string | null;
  status?: Appointment_Status_Enum | null;
  stripeCardId?: string | null;
  stripeCustomerId?: string | null;
  timeZone?: string | null;
  type?: Appointment_Types_Enum | null;
  visitClassification?: Visit_Classifications_Enum | null;
  visitType?: Visit_Types_Enum | null;
  symptom_checker?: {
    __typename?: 'symptom_checker';
    id: string;
    created_at: string;
    phoneNumber?: string | null;
    session: any;
    updated_at: string;
    userId?: string | null;
  } | null;
  reviews: Array<{
    __typename?: 'reviews';
    id: string;
    review?: string | null;
    createdAt: string;
    updatedAt: string;
    nps?: number | null;
    appointmentId?: string | null;
  }>;
  clinic: {
    __typename?: 'clinics';
    id: string;
    address: string;
    clinicCode: string;
    close: string;
    created_at: string;
    latitude?: string | null;
    locationType: Clinic_Location_Types_Enum;
    longitude?: string | null;
    name: string;
    open: string;
    parentClinicId?: string | null;
    phoneNumber: string;
    status: Clinic_Status_Enum;
    timeZone?: string | null;
    updated_at: string;
  };
  appointment_staffs: Array<{
    __typename?: 'appointment_staff';
    id: string;
    appointmentId: string;
    staffId: string;
    created_at: string;
    updated_at: string;
    staff: {
      __typename?: 'staff';
      id: string;
      active: boolean;
      firstName: string;
      lastName: string;
      auth0UserId?: string | null;
      created_at: string;
      defaultClinicId?: string | null;
      timeZone: string;
      type: Staff_Type_Enum;
      updated_at: string;
      email?: string | null;
      avatarUrl?: string | null;
      color?: string | null;
      lead: boolean;
      staffManagerId?: string | null;
      staff_type: {
        __typename?: 'staff_type';
        description: string;
        value: string;
      };
    };
  }>;
  appointment_patients: Array<{
    __typename?: 'appointment_patient';
    id: string;
    appointment_id: string;
    created_at: string;
    updated_at: string;
    patient_id: string;
    patient: {
      __typename?: 'patients';
      id: string;
      firstName: string;
      lastName: string;
      goesByName?: string | null;
      pronunciation?: string | null;
      dateOfBirth: string;
      deceasedAt?: string | null;
      consentSignedByUserId?: string | null;
      consentToTelemedicineTermsTimestamp?: string | null;
      additionalInfo?: string | null;
      consentSignedAt?: string | null;
      consentSigned?: boolean | null;
      avatarId?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
      genderAtBirth?: string | null;
      primaryCareStaffId?: string | null;
      primaryClinicAddress?: any | null;
      physicianName?: string | null;
      preferredPharmacy?: any | null;
      address?: any | null;
      hasPCP?: string | null;
      lastWellVisitStatus?: string | null;
      vaccinationStatus?: string | null;
      kareo_patient_id?: string | null;
      kareo_guid?: string | null;
      insurances: Array<{
        __typename?: 'insurance';
        id: string;
        accountId: string;
        backId?: string | null;
        carrier?: string | null;
        dateOfBirth?: string | null;
        eligibility?: any | null;
        firstName?: string | null;
        frontId?: string | null;
        lastName?: string | null;
        memberId?: string | null;
        type: Insurance_Types_Enum;
        updatedAt: string;
      }>;
      accounts: Array<{
        __typename?: 'account_patient';
        id: string;
        accountId: string;
        patientId: string;
        relationship?: string | null;
        account: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        };
      }>;
      consentSignedByUser?: {
        __typename?: 'users';
        account?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
      } | null;
      consentToTelemedicineTermsByAccount?: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      } | null;
      parsed_address?: {
        __typename?: 'patient_address';
        fullAddress?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        zip?: string | null;
        state?: string | null;
      } | null;
      discovered_froms: Array<{
        __typename?: 'discovered_from';
        description?: string | null;
        type: string;
        account: {
          __typename?: 'accounts';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
        };
      }>;
    };
  }>;
};

export type PatientPartsFragment = {
  __typename?: 'patients';
  id: string;
  firstName: string;
  lastName: string;
  goesByName?: string | null;
  pronunciation?: string | null;
  dateOfBirth: string;
  deceasedAt?: string | null;
  consentSignedByUserId?: string | null;
  consentToTelemedicineTermsTimestamp?: string | null;
  additionalInfo?: string | null;
  consentSignedAt?: string | null;
  consentSigned?: boolean | null;
  avatarId?: string | null;
  preferredPronoun?: string | null;
  preferredPronounType?: string | null;
  genderAtBirth?: string | null;
  primaryCareStaffId?: string | null;
  primaryClinicAddress?: any | null;
  physicianName?: string | null;
  preferredPharmacy?: any | null;
  address?: any | null;
  hasPCP?: string | null;
  lastWellVisitStatus?: string | null;
  vaccinationStatus?: string | null;
  kareo_patient_id?: string | null;
  kareo_guid?: string | null;
  accounts: Array<{
    __typename?: 'account_patient';
    id: string;
    accountId: string;
    patientId: string;
    relationship?: string | null;
    account: {
      __typename?: 'accounts';
      id: string;
      createdAt: string;
      consentToMedicalTermsTimestamp?: string | null;
      consentToTermsAndPrivacyTimestamp?: string | null;
      email?: string | null;
      firstName?: string | null;
      goesByName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      postalCode?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
      user?: {
        __typename?: 'users';
        id: string;
        auth0UserId?: string | null;
      } | null;
    };
  }>;
  consentSignedByUser?: {
    __typename?: 'users';
    account?: {
      __typename?: 'accounts';
      id: string;
      createdAt: string;
      consentToMedicalTermsTimestamp?: string | null;
      consentToTermsAndPrivacyTimestamp?: string | null;
      email?: string | null;
      firstName?: string | null;
      goesByName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      postalCode?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
    } | null;
  } | null;
  consentToTelemedicineTermsByAccount?: {
    __typename?: 'accounts';
    id: string;
    createdAt: string;
    consentToMedicalTermsTimestamp?: string | null;
    consentToTermsAndPrivacyTimestamp?: string | null;
    email?: string | null;
    firstName?: string | null;
    goesByName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    postalCode?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
  } | null;
  parsed_address?: {
    __typename?: 'patient_address';
    fullAddress?: string | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    zip?: string | null;
    state?: string | null;
  } | null;
  discovered_froms: Array<{
    __typename?: 'discovered_from';
    description?: string | null;
    type: string;
    account: {
      __typename?: 'accounts';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    };
  }>;
};

export type AccountPartsFragment = {
  __typename?: 'accounts';
  stripeCustomerId?: string | null;
  id: string;
  createdAt: string;
  consentToMedicalTermsTimestamp?: string | null;
  consentToTermsAndPrivacyTimestamp?: string | null;
  email?: string | null;
  firstName?: string | null;
  goesByName?: string | null;
  lastName?: string | null;
  phoneNumber: string;
  postalCode?: string | null;
  preferredPronoun?: string | null;
  preferredPronounType?: string | null;
  account_patients: Array<{
    __typename?: 'account_patient';
    id: string;
    accountId: string;
    patientId: string;
    relationship?: string | null;
    patient: {
      __typename?: 'patients';
      id: string;
      firstName: string;
      lastName: string;
      goesByName?: string | null;
      pronunciation?: string | null;
      dateOfBirth: string;
      deceasedAt?: string | null;
      consentSignedByUserId?: string | null;
      consentToTelemedicineTermsTimestamp?: string | null;
      additionalInfo?: string | null;
      consentSignedAt?: string | null;
      consentSigned?: boolean | null;
      avatarId?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
      genderAtBirth?: string | null;
      primaryCareStaffId?: string | null;
      primaryClinicAddress?: any | null;
      physicianName?: string | null;
      preferredPharmacy?: any | null;
      address?: any | null;
      hasPCP?: string | null;
      lastWellVisitStatus?: string | null;
      vaccinationStatus?: string | null;
      kareo_patient_id?: string | null;
      kareo_guid?: string | null;
      consentSignedByUser?: {
        __typename?: 'users';
        account?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
      } | null;
      consentToTelemedicineTermsByAccount?: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      } | null;
      parsed_address?: {
        __typename?: 'patient_address';
        fullAddress?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        zip?: string | null;
        state?: string | null;
      } | null;
      discovered_froms: Array<{
        __typename?: 'discovered_from';
        description?: string | null;
        type: string;
        account: {
          __typename?: 'accounts';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
        };
      }>;
    };
  }>;
};

export type AccountPartsWithRelatedAccountPartsFragment = {
  __typename?: 'accounts';
  stripeCustomerId?: string | null;
  id: string;
  createdAt: string;
  consentToMedicalTermsTimestamp?: string | null;
  consentToTermsAndPrivacyTimestamp?: string | null;
  email?: string | null;
  firstName?: string | null;
  goesByName?: string | null;
  lastName?: string | null;
  phoneNumber: string;
  postalCode?: string | null;
  preferredPronoun?: string | null;
  preferredPronounType?: string | null;
  account_patients: Array<{
    __typename?: 'account_patient';
    id: string;
    accountId: string;
    patientId: string;
    relationship?: string | null;
    patient: {
      __typename?: 'patients';
      id: string;
      firstName: string;
      lastName: string;
      goesByName?: string | null;
      pronunciation?: string | null;
      dateOfBirth: string;
      deceasedAt?: string | null;
      consentSignedByUserId?: string | null;
      consentToTelemedicineTermsTimestamp?: string | null;
      additionalInfo?: string | null;
      consentSignedAt?: string | null;
      consentSigned?: boolean | null;
      avatarId?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
      genderAtBirth?: string | null;
      primaryCareStaffId?: string | null;
      primaryClinicAddress?: any | null;
      physicianName?: string | null;
      preferredPharmacy?: any | null;
      address?: any | null;
      hasPCP?: string | null;
      lastWellVisitStatus?: string | null;
      vaccinationStatus?: string | null;
      kareo_patient_id?: string | null;
      kareo_guid?: string | null;
      accounts: Array<{
        __typename?: 'account_patient';
        id: string;
        accountId: string;
        relationship?: string | null;
        account_limited?: {
          __typename?: 'accounts_limited';
          id?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          goesByName?: string | null;
        } | null;
      }>;
      consentSignedByUser?: {
        __typename?: 'users';
        account?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
      } | null;
      consentToTelemedicineTermsByAccount?: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      } | null;
      parsed_address?: {
        __typename?: 'patient_address';
        fullAddress?: string | null;
        address1?: string | null;
        address2?: string | null;
        city?: string | null;
        zip?: string | null;
        state?: string | null;
      } | null;
    };
  }>;
};

export type InsuranceFragment = {
  __typename?: 'insurance';
  id: string;
  accountId: string;
  backId?: string | null;
  carrier?: string | null;
  dateOfBirth?: string | null;
  eligibility?: any | null;
  firstName?: string | null;
  frontId?: string | null;
  lastName?: string | null;
  memberId?: string | null;
  type: Insurance_Types_Enum;
  updatedAt: string;
};

export type InsuranceImagesFragment = {
  __typename?: 'insurance';
  back?: {
    __typename?: 'FilePayload';
    id?: string | null;
    url?: string | null;
  } | null;
  front?: {
    __typename?: 'FilePayload';
    id?: string | null;
    url?: string | null;
  } | null;
};

export type AccountPatientFragment = {
  __typename?: 'account_patient';
  id: string;
  accountId: string;
  patientId: string;
  relationship?: string | null;
};

export type AccountFragment = {
  __typename?: 'accounts';
  id: string;
  createdAt: string;
  consentToMedicalTermsTimestamp?: string | null;
  consentToTermsAndPrivacyTimestamp?: string | null;
  email?: string | null;
  firstName?: string | null;
  goesByName?: string | null;
  lastName?: string | null;
  phoneNumber: string;
  postalCode?: string | null;
  preferredPronoun?: string | null;
  preferredPronounType?: string | null;
};

export type FullAccountFragment = {
  __typename?: 'accounts';
  id: string;
  consentToMedicalTermsTimestamp?: string | null;
  consentToTermsAndPrivacyTimestamp?: string | null;
  email?: string | null;
  firstName?: string | null;
  goesByName?: string | null;
  lastName?: string | null;
  phoneNumber: string;
  postalCode?: string | null;
  preferredPronoun?: string | null;
  preferredPronounType?: string | null;
  photoIdBackId?: string | null;
  photoIdFrontId?: string | null;
};

export type AccountPhotoIdsFragment = {
  __typename?: 'accounts';
  photoIdBack?: {
    __typename?: 'FilePayload';
    id?: string | null;
    url?: string | null;
  } | null;
  photoIdFront?: {
    __typename?: 'FilePayload';
    id?: string | null;
    url?: string | null;
  } | null;
};

export type AccountAvatarFragment = {
  __typename?: 'accounts';
  avatar?: {
    __typename?: 'FilePayload';
    id?: string | null;
    url?: string | null;
  } | null;
};

export type PatientFragment = {
  __typename?: 'patients';
  id: string;
  firstName: string;
  lastName: string;
  goesByName?: string | null;
  pronunciation?: string | null;
  dateOfBirth: string;
  deceasedAt?: string | null;
  consentSignedByUserId?: string | null;
  consentToTelemedicineTermsTimestamp?: string | null;
  additionalInfo?: string | null;
  consentSignedAt?: string | null;
  consentSigned?: boolean | null;
  avatarId?: string | null;
  preferredPronoun?: string | null;
  preferredPronounType?: string | null;
  genderAtBirth?: string | null;
  primaryCareStaffId?: string | null;
  primaryClinicAddress?: any | null;
  physicianName?: string | null;
  preferredPharmacy?: any | null;
  address?: any | null;
  hasPCP?: string | null;
  lastWellVisitStatus?: string | null;
  vaccinationStatus?: string | null;
  kareo_patient_id?: string | null;
  kareo_guid?: string | null;
  consentSignedByUser?: {
    __typename?: 'users';
    account?: {
      __typename?: 'accounts';
      id: string;
      createdAt: string;
      consentToMedicalTermsTimestamp?: string | null;
      consentToTermsAndPrivacyTimestamp?: string | null;
      email?: string | null;
      firstName?: string | null;
      goesByName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      postalCode?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
    } | null;
  } | null;
  consentToTelemedicineTermsByAccount?: {
    __typename?: 'accounts';
    id: string;
    createdAt: string;
    consentToMedicalTermsTimestamp?: string | null;
    consentToTermsAndPrivacyTimestamp?: string | null;
    email?: string | null;
    firstName?: string | null;
    goesByName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    postalCode?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
  } | null;
  parsed_address?: {
    __typename?: 'patient_address';
    fullAddress?: string | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    zip?: string | null;
    state?: string | null;
  } | null;
};

export type PatientAvatarFragment = {
  __typename?: 'patients';
  avatar?: {
    __typename?: 'FilePayload';
    id?: string | null;
    url?: string | null;
  } | null;
};

export type PatientConsentDocumentFragment = {
  __typename?: 'patients';
  consentDocument?: {
    __typename?: 'FilePayload';
    url?: string | null;
    id?: string | null;
  } | null;
};

export type PatientDiscoveredFromFragment = {
  __typename?: 'patients';
  discovered_froms: Array<{
    __typename?: 'discovered_from';
    description?: string | null;
    type: string;
    account: {
      __typename?: 'accounts';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    };
  }>;
};

export type AppointmentFragment = {
  __typename?: 'appointments';
  id: string;
  accountId?: string | null;
  address?: any | null;
  campaign?: string | null;
  clinicId: string;
  createdAt: string;
  email?: string | null;
  endTime: string;
  firstName?: string | null;
  kareo_appointment_id?: string | null;
  kareo_appointment_uuid?: string | null;
  languageSelection?: string | null;
  lastName?: string | null;
  notes?: string | null;
  patientId?: string | null;
  payment?: Appointment_Payment_Enum | null;
  phoneNumber?: string | null;
  reason: string;
  safetyQuestionnaire?: any | null;
  startTime: string;
  state?: string | null;
  status?: Appointment_Status_Enum | null;
  stripeCardId?: string | null;
  stripeCustomerId?: string | null;
  timeZone?: string | null;
  type?: Appointment_Types_Enum | null;
  visitClassification?: Visit_Classifications_Enum | null;
  visitType?: Visit_Types_Enum | null;
};

export type ReviewFragment = {
  __typename?: 'reviews';
  id: string;
  review?: string | null;
  createdAt: string;
  updatedAt: string;
  nps?: number | null;
  appointmentId?: string | null;
};

export type ClinicFragment = {
  __typename?: 'clinics';
  id: string;
  address: string;
  clinicCode: string;
  close: string;
  created_at: string;
  latitude?: string | null;
  locationType: Clinic_Location_Types_Enum;
  longitude?: string | null;
  name: string;
  open: string;
  parentClinicId?: string | null;
  phoneNumber: string;
  status: Clinic_Status_Enum;
  timeZone?: string | null;
  updated_at: string;
};

export type AppointmentPatientFragment = {
  __typename?: 'appointment_patient';
  id: string;
  appointment_id: string;
  created_at: string;
  updated_at: string;
  patient_id: string;
};

export type SymptomCheckerFragment = {
  __typename?: 'symptom_checker';
  id: string;
  created_at: string;
  phoneNumber?: string | null;
  session: any;
  updated_at: string;
  userId?: string | null;
};

export type ProductFragment = {
  __typename?: 'products';
  id: string;
  name: string;
  price: number;
  type: string;
};

export type StaffFragment = {
  __typename?: 'staff';
  id: string;
  active: boolean;
  firstName: string;
  lastName: string;
  auth0UserId?: string | null;
  created_at: string;
  defaultClinicId?: string | null;
  timeZone: string;
  type: Staff_Type_Enum;
  updated_at: string;
  email?: string | null;
  avatarUrl?: string | null;
  color?: string | null;
  lead: boolean;
  staffManagerId?: string | null;
  staff_type: { __typename?: 'staff_type'; description: string; value: string };
};

export type StaffScheduleFragment = {
  __typename?: 'staff_schedule';
  id: string;
  startDateTime: string;
  endDateTime: string;
  clinicId?: string | null;
  created_at: string;
  staffId: string;
  updated_at: string;
  timeZone: string;
};

export type StaffScheduleServiceFragment = {
  __typename?: 'staff_schedule_service';
  id: string;
  service: Services_Enum;
  staffScheduleId: string;
  updated_at: string;
  created_at: string;
};

export type StaffServiceFragment = {
  __typename?: 'staff_services';
  id: string;
  service: Services_Enum;
  staffId: string;
  updated_at: string;
  created_at: string;
};

export type AppointmentBlockFragment = {
  __typename?: 'appointment_blocks';
  id: string;
  clinicId: string;
  endDateTime: string;
  startDateTime: string;
  reason?: string | null;
};

export type ChargeFragment = {
  __typename?: 'charges';
  id: string;
  medicalAssistantId?: string | null;
  amount: number;
  accountId?: string | null;
  appointmentId?: string | null;
  cardId?: string | null;
  clinicId?: string | null;
  created_at: string;
  patientId?: string | null;
  stripeChargeId: string;
  updated_at: string;
};

export type ChargeProductFragment = {
  __typename?: 'charge_products';
  id: string;
  amount: number;
  type?: string | null;
  quantity: number;
  chargeId: string;
  created_at: string;
  updated_at: string;
  productId: string;
};

export type AppointmentStaffFragment = {
  __typename?: 'appointment_staff';
  id: string;
  appointmentId: string;
  staffId: string;
  created_at: string;
  updated_at: string;
};

export type ChatSessionFragment = {
  __typename?: 'chat_sessions';
  id: string;
  status: Chat_Sessions_Status_Enum;
  updatedAt: string;
  createdByAccountId?: string | null;
  createdAt: string;
};

export type ChatMessageFragment = {
  __typename?: 'chat_messages';
  id: string;
  chatSessionId: string;
  accountId?: string | null;
  staffId?: string | null;
  data?: any | null;
  message: string;
  type: Chat_Messages_Type_Enum;
  createdAt: string;
  updatedAt: string;
  request?: any | null;
  response?: any | null;
};

export type ChatMessageFileFragment = {
  __typename?: 'chat_messages_file';
  id: string;
  chatMessageId: string;
  createdAt: string;
  fileId: string;
  updatedAt: string;
};

export type ChatNoteFragment = {
  __typename?: 'chat_notes';
  id: string;
  chatSessionId?: string | null;
  createdAt: string;
  note?: string | null;
  staffId?: string | null;
  type: Chat_Notes_Types_Enum;
  updatedAt?: string | null;
  staff?: {
    __typename?: 'staff';
    id: string;
    active: boolean;
    firstName: string;
    lastName: string;
    auth0UserId?: string | null;
    created_at: string;
    defaultClinicId?: string | null;
    timeZone: string;
    type: Staff_Type_Enum;
    updated_at: string;
    email?: string | null;
    avatarUrl?: string | null;
    color?: string | null;
    lead: boolean;
    staffManagerId?: string | null;
    staff_type: {
      __typename?: 'staff_type';
      description: string;
      value: string;
    };
  } | null;
};

export type ChatSessionReadFragment = {
  __typename?: 'chat_sessions_read';
  id: string;
  accountId?: string | null;
  staffId?: string | null;
  chatSessionId: string;
  createdAt: string;
  lastViewed: string;
  updatedAt: string;
};

export type ChatSessionAccountFragment = {
  __typename?: 'chat_sessions_accounts';
  id: string;
  accountId: string;
  active: boolean;
  chatSessionId: string;
  createdAt: string;
  updatedAt: string;
  account: {
    __typename?: 'accounts';
    id: string;
    createdAt: string;
    consentToMedicalTermsTimestamp?: string | null;
    consentToTermsAndPrivacyTimestamp?: string | null;
    email?: string | null;
    firstName?: string | null;
    goesByName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    postalCode?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
  };
};

export type ChatSessionPatientFragment = {
  __typename?: 'chat_sessions_patients';
  id: string;
  createdAt: string;
  chatSessionId: string;
  updatedAt: string;
  patientId: string;
  patient: {
    __typename?: 'patients';
    id: string;
    firstName: string;
    lastName: string;
    goesByName?: string | null;
    pronunciation?: string | null;
    dateOfBirth: string;
    deceasedAt?: string | null;
    consentSignedByUserId?: string | null;
    consentToTelemedicineTermsTimestamp?: string | null;
    additionalInfo?: string | null;
    consentSignedAt?: string | null;
    consentSigned?: boolean | null;
    avatarId?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
    genderAtBirth?: string | null;
    primaryCareStaffId?: string | null;
    primaryClinicAddress?: any | null;
    physicianName?: string | null;
    preferredPharmacy?: any | null;
    address?: any | null;
    hasPCP?: string | null;
    lastWellVisitStatus?: string | null;
    vaccinationStatus?: string | null;
    kareo_patient_id?: string | null;
    kareo_guid?: string | null;
    consentSignedByUser?: {
      __typename?: 'users';
      account?: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      } | null;
    } | null;
    consentToTelemedicineTermsByAccount?: {
      __typename?: 'accounts';
      id: string;
      createdAt: string;
      consentToMedicalTermsTimestamp?: string | null;
      consentToTermsAndPrivacyTimestamp?: string | null;
      email?: string | null;
      firstName?: string | null;
      goesByName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      postalCode?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
    } | null;
    parsed_address?: {
      __typename?: 'patient_address';
      fullAddress?: string | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      zip?: string | null;
      state?: string | null;
    } | null;
  };
};

export type ChatSessionStaffFragment = {
  __typename?: 'chat_sessions_staff';
  id: string;
  active: boolean;
  chatSessionId: string;
  created_at: string;
  staffId: string;
  updated_at: string;
  staff: {
    __typename?: 'staff';
    id: string;
    active: boolean;
    firstName: string;
    lastName: string;
    auth0UserId?: string | null;
    created_at: string;
    defaultClinicId?: string | null;
    timeZone: string;
    type: Staff_Type_Enum;
    updated_at: string;
    email?: string | null;
    avatarUrl?: string | null;
    color?: string | null;
    lead: boolean;
    staffManagerId?: string | null;
    staff_type: {
      __typename?: 'staff_type';
      description: string;
      value: string;
    };
  };
};

export type RoomFragment = {
  __typename?: 'rooms';
  appointmentId?: string | null;
  createdAt: string;
  clinicId: string;
  id: string;
  signId: string;
  name: string;
  staffId?: string | null;
  status: string;
  updatedAt: string;
};

export type FileFragment = {
  __typename?: 'files';
  id: string;
  createdAt: string;
  updatedAt: string;
  accountId?: string | null;
  patientId?: string | null;
  documentType?: File_Document_Type_Enum | null;
  gcv?: any | null;
  path?: string | null;
  status: string;
};

export type GlobalNoteFragment = {
  __typename?: 'global_notes';
  id: string;
  appointmentId?: string | null;
  deleted: boolean;
  patientId?: string | null;
  accountId?: string | null;
  updatedAt: string;
  createdAt: string;
  note: string;
  type?: string | null;
  staffId?: string | null;
  staff?: {
    __typename?: 'staff';
    id: string;
    active: boolean;
    firstName: string;
    lastName: string;
    auth0UserId?: string | null;
    created_at: string;
    defaultClinicId?: string | null;
    timeZone: string;
    type: Staff_Type_Enum;
    updated_at: string;
    email?: string | null;
    avatarUrl?: string | null;
    color?: string | null;
    lead: boolean;
    staffManagerId?: string | null;
    staff_type: {
      __typename?: 'staff_type';
      description: string;
      value: string;
    };
  } | null;
};

export type DocumentFragment = {
  __typename?: 'documents';
  id: string;
  status: Document_Status_Types_Enum;
  type: Document_Types_Enum;
  version: number;
  accountId: string;
  appointmentId?: string | null;
  created_at: string;
  fileId?: string | null;
  patientId?: string | null;
};

export type AppointmentBlockStaffFragment = {
  __typename?: 'appointment_blocks_staff';
  id: number;
  staff: {
    __typename?: 'staff';
    id: string;
    avatarUrl?: string | null;
    firstName: string;
    lastName: string;
  };
};

export type PhoneNumberFragment = {
  __typename?: 'phone_numbers';
  id: string;
  clinicId?: string | null;
  description?: string | null;
  extension?: string | null;
  name: string;
  phoneNumber: string;
  staffId?: string | null;
};

export type AddInsuranceMutationVariables = Exact<{
  insurance: Insurance_Insert_Input;
}>;

export type AddInsuranceMutation = {
  __typename?: 'mutation_root';
  insert_insurance?: {
    __typename?: 'insurance_mutation_response';
    returning: Array<{ __typename?: 'insurance'; id: string }>;
  } | null;
};

export type UpdateInsuranceMutationVariables = Exact<{
  insuranceId: Scalars['uuid'];
  set: Insurance_Set_Input;
}>;

export type UpdateInsuranceMutation = {
  __typename?: 'mutation_root';
  update_insurance?: {
    __typename?: 'insurance_mutation_response';
    returning: Array<{ __typename?: 'insurance'; id: string }>;
  } | null;
};

export type LoadPatientInsurancesQueryVariables = Exact<{
  patientId: Scalars['uuid'];
  where?: InputMaybe<Insurance_Bool_Exp>;
}>;

export type LoadPatientInsurancesQuery = {
  __typename?: 'query_root';
  patients_by_pk?: {
    __typename?: 'patients';
    insurances: Array<{
      __typename?: 'insurance';
      id: string;
      accountId: string;
      backId?: string | null;
      carrier?: string | null;
      dateOfBirth?: string | null;
      eligibility?: any | null;
      firstName?: string | null;
      frontId?: string | null;
      lastName?: string | null;
      memberId?: string | null;
      type: Insurance_Types_Enum;
      updatedAt: string;
    }>;
  } | null;
};

export type PatientInsurancesSubscriptionVariables = Exact<{
  patientId: Scalars['uuid'];
  where?: InputMaybe<Insurance_Bool_Exp>;
}>;

export type PatientInsurancesSubscription = {
  __typename?: 'subscription_root';
  patients_by_pk?: {
    __typename?: 'patients';
    insurances: Array<{
      __typename?: 'insurance';
      id: string;
      accountId: string;
      backId?: string | null;
      carrier?: string | null;
      dateOfBirth?: string | null;
      eligibility?: any | null;
      firstName?: string | null;
      frontId?: string | null;
      lastName?: string | null;
      memberId?: string | null;
      type: Insurance_Types_Enum;
      updatedAt: string;
    }>;
  } | null;
};

export type FindKareoPatientMutationVariables = Exact<{
  dateOfBirth?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
}>;

export type FindKareoPatientMutation = {
  __typename?: 'mutation_root';
  FindKareoPatient?: Array<{
    __typename?: 'KareoFindPatientOutput';
    dateOfBirth: number;
    email?: string | null;
    firstName: string;
    guid: string;
    lastName: string;
    patientId: number;
    genderId?: number | null;
  } | null> | null;
};

export type CreateKareoPatientMutationVariables = Exact<{
  patientId: Scalars['String'];
}>;

export type CreateKareoPatientMutation = {
  __typename?: 'mutation_root';
  CreateKareoPatient?: {
    __typename?: 'CreateKareoPatientOutput';
    success: boolean;
  } | null;
};

export type UploadKareoDocumentsMutationVariables = Exact<{
  patientId: Scalars['String'];
}>;

export type UploadKareoDocumentsMutation = {
  __typename?: 'mutation_root';
  UploadKareoDocuments?: {
    __typename?: 'UploadKareoDocumentsOutput';
    success: boolean;
  } | null;
};

export type CreateKareoAppointmentMutationVariables = Exact<{
  appointmentId: Scalars['String'];
  kareoClinic: Scalars['String'];
  kareoProvider: Scalars['String'];
  kareoType: Scalars['String'];
}>;

export type CreateKareoAppointmentMutation = {
  __typename?: 'mutation_root';
  CreateKareoAppointment?: {
    __typename?: 'CreateKareoAppointmentOutput';
    success: boolean;
  } | null;
};

export type UpdateKareoPatientMutationVariables = Exact<{
  patientId: Scalars['String'];
}>;

export type UpdateKareoPatientMutation = {
  __typename?: 'mutation_root';
  UpdateKareoPatient?: {
    __typename?: 'UpdateKareoPatientOutput';
    success: boolean;
  } | null;
};

export type WatchForKareoActionsSubscriptionVariables = Exact<{
  query_start: Scalars['timestamptz'];
}>;

export type WatchForKareoActionsSubscription = {
  __typename?: 'subscription_root';
  kareo_logs: Array<{ __typename?: 'kareo_logs'; id: string }>;
};

export type GetLogsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Kareo_Logs_Order_By> | Kareo_Logs_Order_By>;
}>;

export type GetLogsQuery = {
  __typename?: 'query_root';
  kareo_logs: Array<{
    __typename?: 'kareo_logs';
    created_at: string;
    id: string;
    patient_id?: string | null;
    request_action: string;
    staff?: string | null;
    status: string;
  }>;
};

export type FindLocationsQueryVariables = Exact<{
  placeTypes: Array<Scalars['String']> | Scalars['String'];
  value: Scalars['String'];
  location?: InputMaybe<Scalars['String']>;
}>;

export type FindLocationsQuery = {
  __typename?: 'query_root';
  SearchLocations: Array<{
    __typename?: 'PartialGooglePlace';
    formatted_address?: string | null;
    name: string;
    place_id: string;
  }>;
};

export type GetPlaceQueryVariables = Exact<{
  placeId: Scalars['String'];
}>;

export type GetPlaceQuery = {
  __typename?: 'query_root';
  FindPlace: {
    __typename?: 'GooglePlaceData';
    formatted_address?: string | null;
    name?: string | null;
    place_id?: string | null;
    address_components?: Array<{
      __typename?: 'GoogleAddressComponents';
      short_name?: string | null;
      types?: Array<string | null> | null;
    } | null> | null;
    geometry?: {
      __typename?: 'GooglePlaceGeometry';
      location?: {
        __typename?: 'GoogleGeoLocation';
        lat?: number | null;
        lng?: number | null;
      } | null;
    } | null;
  };
};

export type GetFullPlaceQueryVariables = Exact<{
  placeId: Scalars['String'];
}>;

export type GetFullPlaceQuery = {
  __typename?: 'query_root';
  FindPlace: {
    __typename?: 'GooglePlaceData';
    place_id?: string | null;
    adr_address?: string | null;
    formatted_address?: string | null;
    formatted_phone_number?: string | null;
    icon?: string | null;
    id?: string | null;
    name?: string | null;
    scope?: string | null;
    reference?: string | null;
    types?: Array<string | null> | null;
    url?: string | null;
    vicinity?: string | null;
    utc_offset?: number | null;
    geometry?: {
      __typename?: 'GooglePlaceGeometry';
      location?: {
        __typename?: 'GoogleGeoLocation';
        lng?: number | null;
        lat?: number | null;
      } | null;
      viewport?: {
        __typename?: 'GooglePlaceViewport';
        northeast?: {
          __typename?: 'GoogleGeoLocation';
          lat?: number | null;
          lng?: number | null;
        } | null;
        southwest?: {
          __typename?: 'GoogleGeoLocation';
          lat?: number | null;
          lng?: number | null;
        } | null;
      } | null;
    } | null;
    address_components?: Array<{
      __typename?: 'GoogleAddressComponents';
      long_name?: string | null;
      short_name?: string | null;
      types?: Array<string | null> | null;
    } | null> | null;
  };
};

export type SearchAccountsAndPatientsQueryVariables = Exact<{
  search: Scalars['String'];
  limit: Scalars['Int'];
}>;

export type SearchAccountsAndPatientsQuery = {
  __typename?: 'query_root';
  search_accounts: Array<{
    __typename?: 'accounts';
    id: string;
    createdAt: string;
    consentToMedicalTermsTimestamp?: string | null;
    consentToTermsAndPrivacyTimestamp?: string | null;
    email?: string | null;
    firstName?: string | null;
    goesByName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    postalCode?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
    account_patients: Array<{
      __typename?: 'account_patient';
      id: string;
      accountId: string;
      patientId: string;
      relationship?: string | null;
      patient: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
        goesByName?: string | null;
        pronunciation?: string | null;
        dateOfBirth: string;
        deceasedAt?: string | null;
        consentSignedByUserId?: string | null;
        consentToTelemedicineTermsTimestamp?: string | null;
        additionalInfo?: string | null;
        consentSignedAt?: string | null;
        consentSigned?: boolean | null;
        avatarId?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
        genderAtBirth?: string | null;
        primaryCareStaffId?: string | null;
        primaryClinicAddress?: any | null;
        physicianName?: string | null;
        preferredPharmacy?: any | null;
        address?: any | null;
        hasPCP?: string | null;
        lastWellVisitStatus?: string | null;
        vaccinationStatus?: string | null;
        kareo_patient_id?: string | null;
        kareo_guid?: string | null;
        consentSignedByUser?: {
          __typename?: 'users';
          account?: {
            __typename?: 'accounts';
            id: string;
            createdAt: string;
            consentToMedicalTermsTimestamp?: string | null;
            consentToTermsAndPrivacyTimestamp?: string | null;
            email?: string | null;
            firstName?: string | null;
            goesByName?: string | null;
            lastName?: string | null;
            phoneNumber: string;
            postalCode?: string | null;
            preferredPronoun?: string | null;
            preferredPronounType?: string | null;
          } | null;
        } | null;
        consentToTelemedicineTermsByAccount?: {
          __typename?: 'accounts';
          id: string;
          createdAt: string;
          consentToMedicalTermsTimestamp?: string | null;
          consentToTermsAndPrivacyTimestamp?: string | null;
          email?: string | null;
          firstName?: string | null;
          goesByName?: string | null;
          lastName?: string | null;
          phoneNumber: string;
          postalCode?: string | null;
          preferredPronoun?: string | null;
          preferredPronounType?: string | null;
        } | null;
        parsed_address?: {
          __typename?: 'patient_address';
          fullAddress?: string | null;
          address1?: string | null;
          address2?: string | null;
          city?: string | null;
          zip?: string | null;
          state?: string | null;
        } | null;
      };
    }>;
  }>;
  search_patients: Array<{
    __typename?: 'patients';
    id: string;
    firstName: string;
    lastName: string;
    goesByName?: string | null;
    pronunciation?: string | null;
    dateOfBirth: string;
    deceasedAt?: string | null;
    consentSignedByUserId?: string | null;
    consentToTelemedicineTermsTimestamp?: string | null;
    additionalInfo?: string | null;
    consentSignedAt?: string | null;
    consentSigned?: boolean | null;
    avatarId?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
    genderAtBirth?: string | null;
    primaryCareStaffId?: string | null;
    primaryClinicAddress?: any | null;
    physicianName?: string | null;
    preferredPharmacy?: any | null;
    address?: any | null;
    hasPCP?: string | null;
    lastWellVisitStatus?: string | null;
    vaccinationStatus?: string | null;
    kareo_patient_id?: string | null;
    kareo_guid?: string | null;
    appointments: Array<{
      __typename?: 'appointments';
      id: string;
      accountId?: string | null;
      address?: any | null;
      campaign?: string | null;
      clinicId: string;
      createdAt: string;
      email?: string | null;
      endTime: string;
      firstName?: string | null;
      kareo_appointment_id?: string | null;
      kareo_appointment_uuid?: string | null;
      languageSelection?: string | null;
      lastName?: string | null;
      notes?: string | null;
      patientId?: string | null;
      payment?: Appointment_Payment_Enum | null;
      phoneNumber?: string | null;
      reason: string;
      safetyQuestionnaire?: any | null;
      startTime: string;
      state?: string | null;
      status?: Appointment_Status_Enum | null;
      stripeCardId?: string | null;
      stripeCustomerId?: string | null;
      timeZone?: string | null;
      type?: Appointment_Types_Enum | null;
      visitClassification?: Visit_Classifications_Enum | null;
      visitType?: Visit_Types_Enum | null;
    }>;
    accounts: Array<{
      __typename?: 'account_patient';
      id: string;
      accountId: string;
      patientId: string;
      relationship?: string | null;
      account: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      };
    }>;
    consentSignedByUser?: {
      __typename?: 'users';
      account?: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      } | null;
    } | null;
    consentToTelemedicineTermsByAccount?: {
      __typename?: 'accounts';
      id: string;
      createdAt: string;
      consentToMedicalTermsTimestamp?: string | null;
      consentToTermsAndPrivacyTimestamp?: string | null;
      email?: string | null;
      firstName?: string | null;
      goesByName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      postalCode?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
    } | null;
    parsed_address?: {
      __typename?: 'patient_address';
      fullAddress?: string | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      zip?: string | null;
      state?: string | null;
    } | null;
  }>;
};

export type GetDuplicatePatientsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetDuplicatePatientsQuery = {
  __typename?: 'query_root';
  patient_duplicates: Array<{
    __typename?: 'patient_duplicates';
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    dateOfBirth?: string | null;
    avatarId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  }>;
  patient_not_duplicates: Array<{
    __typename?: 'patient_not_duplicates';
    patient1_id: string;
    patient2_id: string;
    id: string;
    created_at: string;
    updated_at: string;
  }>;
};

export type InsertNotDuplicateMutationVariables = Exact<{
  patient1_id: Scalars['uuid'];
  patient2_id: Scalars['uuid'];
}>;

export type InsertNotDuplicateMutation = {
  __typename?: 'mutation_root';
  insert_patient_not_duplicates_one?: {
    __typename?: 'patient_not_duplicates';
    updated_at: string;
    patient2_id: string;
    patient1_id: string;
    id: string;
    created_at: string;
  } | null;
};

export type GetPatientByPkAndNotesQueryVariables = Exact<{
  patientId: Scalars['uuid'];
}>;

export type GetPatientByPkAndNotesQuery = {
  __typename?: 'query_root';
  patients_by_pk?: {
    __typename?: 'patients';
    id: string;
    firstName: string;
    lastName: string;
    goesByName?: string | null;
    pronunciation?: string | null;
    dateOfBirth: string;
    deceasedAt?: string | null;
    consentSignedByUserId?: string | null;
    consentToTelemedicineTermsTimestamp?: string | null;
    additionalInfo?: string | null;
    consentSignedAt?: string | null;
    consentSigned?: boolean | null;
    avatarId?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
    genderAtBirth?: string | null;
    primaryCareStaffId?: string | null;
    primaryClinicAddress?: any | null;
    physicianName?: string | null;
    preferredPharmacy?: any | null;
    address?: any | null;
    hasPCP?: string | null;
    lastWellVisitStatus?: string | null;
    vaccinationStatus?: string | null;
    kareo_patient_id?: string | null;
    kareo_guid?: string | null;
    accounts: Array<{
      __typename?: 'account_patient';
      id: string;
      accountId: string;
      patientId: string;
      relationship?: string | null;
      account: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
        user?: {
          __typename?: 'users';
          id: string;
          auth0UserId?: string | null;
        } | null;
      };
    }>;
    consentSignedByUser?: {
      __typename?: 'users';
      account?: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      } | null;
    } | null;
    consentToTelemedicineTermsByAccount?: {
      __typename?: 'accounts';
      id: string;
      createdAt: string;
      consentToMedicalTermsTimestamp?: string | null;
      consentToTermsAndPrivacyTimestamp?: string | null;
      email?: string | null;
      firstName?: string | null;
      goesByName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      postalCode?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
    } | null;
    parsed_address?: {
      __typename?: 'patient_address';
      fullAddress?: string | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      zip?: string | null;
      state?: string | null;
    } | null;
    discovered_froms: Array<{
      __typename?: 'discovered_from';
      description?: string | null;
      type: string;
      account: {
        __typename?: 'accounts';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      };
    }>;
  } | null;
  global_notes: Array<{
    __typename?: 'global_notes';
    note: string;
    createdAt: string;
    staff?: {
      __typename?: 'staff';
      firstName: string;
      lastName: string;
    } | null;
  }>;
};

export type SendTextNotificationMutationVariables = Exact<{
  to: Scalars['String'];
  message: Scalars['String'];
}>;

export type SendTextNotificationMutation = {
  __typename?: 'mutation_root';
  SendTextNotification?: {
    __typename?: 'SendNotificationOutput';
    success?: boolean | null;
  } | null;
};

export type TrackActionMutationVariables = Exact<{
  type: Scalars['String'];
  data: Scalars['jsonb'];
}>;

export type TrackActionMutation = {
  __typename?: 'mutation_root';
  TrackAction?: { __typename?: 'TrackEventOutput'; success: boolean } | null;
};

export type GetPatientByPkQueryVariables = Exact<{
  patientId: Scalars['uuid'];
}>;

export type GetPatientByPkQuery = {
  __typename?: 'query_root';
  patients_by_pk?: {
    __typename?: 'patients';
    id: string;
    firstName: string;
    lastName: string;
    goesByName?: string | null;
    pronunciation?: string | null;
    dateOfBirth: string;
    deceasedAt?: string | null;
    consentSignedByUserId?: string | null;
    consentToTelemedicineTermsTimestamp?: string | null;
    additionalInfo?: string | null;
    consentSignedAt?: string | null;
    consentSigned?: boolean | null;
    avatarId?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
    genderAtBirth?: string | null;
    primaryCareStaffId?: string | null;
    primaryClinicAddress?: any | null;
    physicianName?: string | null;
    preferredPharmacy?: any | null;
    address?: any | null;
    hasPCP?: string | null;
    lastWellVisitStatus?: string | null;
    vaccinationStatus?: string | null;
    kareo_patient_id?: string | null;
    kareo_guid?: string | null;
    accounts: Array<{
      __typename?: 'account_patient';
      id: string;
      accountId: string;
      patientId: string;
      relationship?: string | null;
      account: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
        user?: {
          __typename?: 'users';
          id: string;
          auth0UserId?: string | null;
        } | null;
      };
    }>;
    consentSignedByUser?: {
      __typename?: 'users';
      account?: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      } | null;
    } | null;
    consentToTelemedicineTermsByAccount?: {
      __typename?: 'accounts';
      id: string;
      createdAt: string;
      consentToMedicalTermsTimestamp?: string | null;
      consentToTermsAndPrivacyTimestamp?: string | null;
      email?: string | null;
      firstName?: string | null;
      goesByName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      postalCode?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
    } | null;
    parsed_address?: {
      __typename?: 'patient_address';
      fullAddress?: string | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      zip?: string | null;
      state?: string | null;
    } | null;
    discovered_froms: Array<{
      __typename?: 'discovered_from';
      description?: string | null;
      type: string;
      account: {
        __typename?: 'accounts';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      };
    }>;
  } | null;
};

export type GetPatientInfoByPkQueryVariables = Exact<{
  patientId: Scalars['uuid'];
}>;

export type GetPatientInfoByPkQuery = {
  __typename?: 'query_root';
  patients_by_pk?: {
    __typename?: 'patients';
    address?: any | null;
    dateOfBirth: string;
    firstName: string;
    goesByName?: string | null;
    lastName: string;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
    accounts: Array<{
      __typename?: 'account_patient';
      accountId: string;
      relationship?: string | null;
      account: {
        __typename?: 'accounts';
        firstName?: string | null;
        lastName?: string | null;
      };
    }>;
  } | null;
};

export type UpdatePatientByPkMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set: Patients_Set_Input;
}>;

export type UpdatePatientByPkMutation = {
  __typename?: 'mutation_root';
  update_patients_by_pk?: {
    __typename?: 'patients';
    id: string;
    firstName: string;
    lastName: string;
    goesByName?: string | null;
    pronunciation?: string | null;
    dateOfBirth: string;
    deceasedAt?: string | null;
    consentSignedByUserId?: string | null;
    consentToTelemedicineTermsTimestamp?: string | null;
    additionalInfo?: string | null;
    consentSignedAt?: string | null;
    consentSigned?: boolean | null;
    avatarId?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
    genderAtBirth?: string | null;
    primaryCareStaffId?: string | null;
    primaryClinicAddress?: any | null;
    physicianName?: string | null;
    preferredPharmacy?: any | null;
    address?: any | null;
    hasPCP?: string | null;
    lastWellVisitStatus?: string | null;
    vaccinationStatus?: string | null;
    kareo_patient_id?: string | null;
    kareo_guid?: string | null;
    accounts: Array<{
      __typename?: 'account_patient';
      id: string;
      accountId: string;
      patientId: string;
      relationship?: string | null;
      account: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
        user?: {
          __typename?: 'users';
          id: string;
          auth0UserId?: string | null;
        } | null;
      };
    }>;
    consentSignedByUser?: {
      __typename?: 'users';
      account?: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      } | null;
    } | null;
    consentToTelemedicineTermsByAccount?: {
      __typename?: 'accounts';
      id: string;
      createdAt: string;
      consentToMedicalTermsTimestamp?: string | null;
      consentToTermsAndPrivacyTimestamp?: string | null;
      email?: string | null;
      firstName?: string | null;
      goesByName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      postalCode?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
    } | null;
    parsed_address?: {
      __typename?: 'patient_address';
      fullAddress?: string | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      zip?: string | null;
      state?: string | null;
    } | null;
    discovered_froms: Array<{
      __typename?: 'discovered_from';
      description?: string | null;
      type: string;
      account: {
        __typename?: 'accounts';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      };
    }>;
  } | null;
};

export type UpdatePatientMutationVariables = Exact<{
  set: Patients_Set_Input;
  where: Patients_Bool_Exp;
}>;

export type UpdatePatientMutation = {
  __typename?: 'mutation_root';
  update_patients?: {
    __typename?: 'patients_mutation_response';
    returning: Array<{
      __typename?: 'patients';
      id: string;
      firstName: string;
      lastName: string;
      goesByName?: string | null;
      dateOfBirth: string;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
      genderAtBirth?: string | null;
      primaryClinicAddress?: any | null;
      physicianName?: string | null;
      preferredPharmacy?: any | null;
      address?: any | null;
    }>;
  } | null;
};

export type UpdateAccountPatientRelationshipMutationVariables = Exact<{
  accountId: Scalars['uuid'];
  patientId: Scalars['uuid'];
  relationship: Scalars['String'];
}>;

export type UpdateAccountPatientRelationshipMutation = {
  __typename?: 'mutation_root';
  update_account_patient?: {
    __typename?: 'account_patient_mutation_response';
    returning: Array<{
      __typename?: 'account_patient';
      id: string;
      relationship?: string | null;
      patientId: string;
      accountId: string;
    }>;
  } | null;
};

export type GetMedicalRecordsReleaseFormQueryVariables = Exact<{
  patientId: Scalars['String'];
}>;

export type GetMedicalRecordsReleaseFormQuery = {
  __typename?: 'query_root';
  GetDocument?: {
    __typename?: 'DocumentPayload';
    id: string;
    updatedAt?: string | null;
    url?: string | null;
    account?: {
      __typename?: 'UserPayload';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null;
};

export type MergePatientsMutationVariables = Exact<{
  fromPatientId: Scalars['String'];
  toPatientId: Scalars['String'];
  copyFields?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type MergePatientsMutation = {
  __typename?: 'mutation_root';
  MergePatients?: {
    __typename?: 'MergePatientOutput';
    patientId: string;
  } | null;
};

export type GetPatientChargesQueryVariables = Exact<{
  where: Charges_Bool_Exp;
}>;

export type GetPatientChargesQuery = {
  __typename?: 'query_root';
  charges: Array<{
    __typename?: 'charges';
    id: string;
    medicalAssistantId?: string | null;
    amount: number;
    accountId?: string | null;
    appointmentId?: string | null;
    cardId?: string | null;
    clinicId?: string | null;
    created_at: string;
    patientId?: string | null;
    stripeChargeId: string;
    updated_at: string;
    charge_products: Array<{
      __typename?: 'charge_products';
      id: string;
      amount: number;
      type?: string | null;
      quantity: number;
      chargeId: string;
      created_at: string;
      updated_at: string;
      productId: string;
      product: {
        __typename?: 'products';
        id: string;
        name: string;
        price: number;
        type: string;
      };
    }>;
  }>;
};

export type AddPatientTelehealthConsentMutationVariables = Exact<{
  accompanyingAccountId: Scalars['String'];
  patientId: Scalars['String'];
  appointmentId?: InputMaybe<Scalars['String']>;
}>;

export type AddPatientTelehealthConsentMutation = {
  __typename?: 'mutation_root';
  AddPatientTelehealthConsent?: {
    __typename?: 'PatientTelehealthConsentOutput';
    id?: string | null;
  } | null;
};

export type RemoveAccountFromPatientMutationVariables = Exact<{
  accountId: Scalars['uuid'];
  patientId: Scalars['uuid'];
}>;

export type RemoveAccountFromPatientMutation = {
  __typename?: 'mutation_root';
  delete_account_patient?: {
    __typename?: 'account_patient_mutation_response';
    affected_rows: number;
  } | null;
};

export type GetAccountsForPatientQueryVariables = Exact<{
  patientId: Scalars['uuid'];
}>;

export type GetAccountsForPatientQuery = {
  __typename?: 'query_root';
  accounts: Array<{
    __typename?: 'accounts';
    id: string;
    consentToMedicalTermsTimestamp?: string | null;
    consentToTermsAndPrivacyTimestamp?: string | null;
    email?: string | null;
    firstName?: string | null;
    goesByName?: string | null;
    lastName?: string | null;
    phoneNumber: string;
    postalCode?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
    photoIdBackId?: string | null;
    photoIdFrontId?: string | null;
  }>;
};

export type FindPatientsQueryVariables = Exact<{
  where: Patients_Bool_Exp;
}>;

export type FindPatientsQuery = {
  __typename?: 'query_root';
  patients: Array<{
    __typename?: 'patients';
    id: string;
    firstName: string;
    lastName: string;
    goesByName?: string | null;
    pronunciation?: string | null;
    dateOfBirth: string;
    deceasedAt?: string | null;
    consentSignedByUserId?: string | null;
    consentToTelemedicineTermsTimestamp?: string | null;
    additionalInfo?: string | null;
    consentSignedAt?: string | null;
    consentSigned?: boolean | null;
    avatarId?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
    genderAtBirth?: string | null;
    primaryCareStaffId?: string | null;
    primaryClinicAddress?: any | null;
    physicianName?: string | null;
    preferredPharmacy?: any | null;
    address?: any | null;
    hasPCP?: string | null;
    lastWellVisitStatus?: string | null;
    vaccinationStatus?: string | null;
    kareo_patient_id?: string | null;
    kareo_guid?: string | null;
    consentSignedByUser?: {
      __typename?: 'users';
      account?: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      } | null;
    } | null;
    consentToTelemedicineTermsByAccount?: {
      __typename?: 'accounts';
      id: string;
      createdAt: string;
      consentToMedicalTermsTimestamp?: string | null;
      consentToTermsAndPrivacyTimestamp?: string | null;
      email?: string | null;
      firstName?: string | null;
      goesByName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      postalCode?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
    } | null;
    parsed_address?: {
      __typename?: 'patient_address';
      fullAddress?: string | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      zip?: string | null;
      state?: string | null;
    } | null;
  }>;
};

export type PatientHeaderQueryVariables = Exact<{
  patientId: Scalars['uuid'];
}>;

export type PatientHeaderQuery = {
  __typename?: 'query_root';
  patients_by_pk?: {
    __typename?: 'patients';
    id: string;
    firstName: string;
    lastName: string;
    goesByName?: string | null;
    pronunciation?: string | null;
    dateOfBirth: string;
    deceasedAt?: string | null;
    consentSignedByUserId?: string | null;
    consentToTelemedicineTermsTimestamp?: string | null;
    additionalInfo?: string | null;
    consentSignedAt?: string | null;
    consentSigned?: boolean | null;
    avatarId?: string | null;
    preferredPronoun?: string | null;
    preferredPronounType?: string | null;
    genderAtBirth?: string | null;
    primaryCareStaffId?: string | null;
    primaryClinicAddress?: any | null;
    physicianName?: string | null;
    preferredPharmacy?: any | null;
    address?: any | null;
    hasPCP?: string | null;
    lastWellVisitStatus?: string | null;
    vaccinationStatus?: string | null;
    kareo_patient_id?: string | null;
    kareo_guid?: string | null;
    accounts: Array<{
      __typename?: 'account_patient';
      id: string;
      accountId: string;
      patientId: string;
      relationship?: string | null;
      account: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      };
    }>;
    insurances: Array<{
      __typename?: 'insurance';
      id: string;
      accountId: string;
      backId?: string | null;
      carrier?: string | null;
      dateOfBirth?: string | null;
      eligibility?: any | null;
      firstName?: string | null;
      frontId?: string | null;
      lastName?: string | null;
      memberId?: string | null;
      type: Insurance_Types_Enum;
      updatedAt: string;
    }>;
    allergies: Array<{
      __typename?: 'fhir_resources';
      id: string;
      resource: any;
    }>;
    medications: Array<{
      __typename?: 'fhir_resources';
      id: string;
      resource: any;
    }>;
    observations: Array<{
      __typename?: 'fhir_resources';
      id: string;
      resource: any;
    }>;
    problems: Array<{
      __typename?: 'fhir_resources';
      id: string;
      resource: any;
    }>;
    global_notes: Array<{
      __typename?: 'global_notes';
      id: string;
      appointmentId?: string | null;
      deleted: boolean;
      patientId?: string | null;
      accountId?: string | null;
      updatedAt: string;
      createdAt: string;
      note: string;
      type?: string | null;
      staffId?: string | null;
      staff?: {
        __typename?: 'staff';
        firstName: string;
        lastName: string;
        auth0UserId?: string | null;
        avatarUrl?: string | null;
        id: string;
        active: boolean;
        created_at: string;
        defaultClinicId?: string | null;
        timeZone: string;
        type: Staff_Type_Enum;
        updated_at: string;
        email?: string | null;
        color?: string | null;
        lead: boolean;
        staffManagerId?: string | null;
        staff_type: {
          __typename?: 'staff_type';
          description: string;
          value: string;
        };
      } | null;
    }>;
    consentSignedByUser?: {
      __typename?: 'users';
      account?: {
        __typename?: 'accounts';
        id: string;
        createdAt: string;
        consentToMedicalTermsTimestamp?: string | null;
        consentToTermsAndPrivacyTimestamp?: string | null;
        email?: string | null;
        firstName?: string | null;
        goesByName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
        postalCode?: string | null;
        preferredPronoun?: string | null;
        preferredPronounType?: string | null;
      } | null;
    } | null;
    consentToTelemedicineTermsByAccount?: {
      __typename?: 'accounts';
      id: string;
      createdAt: string;
      consentToMedicalTermsTimestamp?: string | null;
      consentToTermsAndPrivacyTimestamp?: string | null;
      email?: string | null;
      firstName?: string | null;
      goesByName?: string | null;
      lastName?: string | null;
      phoneNumber: string;
      postalCode?: string | null;
      preferredPronoun?: string | null;
      preferredPronounType?: string | null;
    } | null;
    parsed_address?: {
      __typename?: 'patient_address';
      fullAddress?: string | null;
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      zip?: string | null;
      state?: string | null;
    } | null;
    avatar?: {
      __typename?: 'FilePayload';
      id?: string | null;
      url?: string | null;
    } | null;
  } | null;
};

export type GetPaymentInformationQueryVariables = Exact<{
  stripeCardId: Scalars['String'];
  stripeCustomerId: Scalars['String'];
}>;

export type GetPaymentInformationQuery = {
  __typename?: 'query_root';
  StripePaymentMethod: {
    __typename?: 'StripeCard';
    id: string;
    brand: string;
    last4: string;
  };
};

export type GetPaymentMethodsByAccountQueryVariables = Exact<{
  accountId: Scalars['String'];
}>;

export type GetPaymentMethodsByAccountQuery = {
  __typename?: 'query_root';
  StripePaymentMethods: Array<{
    __typename?: 'StripeCard';
    id: string;
    brand: string;
    country?: string | null;
    customer?: string | null;
    exp_month: number;
    exp_year: number;
    fingerprint?: string | null;
    funding?: string | null;
    last4: string;
  }>;
};

export type OneTimeChargeMutationVariables = Exact<{
  appointmentId?: InputMaybe<Scalars['String']>;
  cardToken: Scalars['String'];
  amount: Scalars['String'];
  reason?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<
    Array<InputMaybe<ProductPayload>> | InputMaybe<ProductPayload>
  >;
}>;

export type OneTimeChargeMutation = {
  __typename?: 'mutation_root';
  OneTimeCharge?: {
    __typename?: 'ChargeResponseOutput';
    id: string;
    receiptUrl?: string | null;
  } | null;
};

export type CreatePaymentMethodMutationVariables = Exact<{
  source: Scalars['String'];
  accountId: Scalars['String'];
}>;

export type CreatePaymentMethodMutation = {
  __typename?: 'mutation_root';
  CreatePaymentMethod?: {
    __typename?: 'StripeCustomerOutput';
    id?: string | null;
    error?: string | null;
  } | null;
};

export type GetPaymentMethodByChargeIdQueryVariables = Exact<{
  stripeChargeId: Scalars['String'];
}>;

export type GetPaymentMethodByChargeIdQuery = {
  __typename?: 'query_root';
  StripePaymentMethodByChargeId: {
    __typename?: 'StripePaymentMethodByChargeIdOutput';
    id: string;
    brand: string;
    last4: string;
    receiptEmail?: string | null;
    receiptUrl?: string | null;
    status: string;
    created: number;
    refunds: {
      __typename?: 'StripeChargeRefunds';
      data: Array<{
        __typename?: 'StripeRefund';
        id?: string | null;
        amount: number;
        created: number;
        reason?: string | null;
        metadata?: {
          __typename?: 'StripeRefundMetadata';
          note?: string | null;
        } | null;
      }>;
    };
  };
};

export type CreateRefundMutationVariables = Exact<{
  stripeChargeId: Scalars['String'];
  refundAmount: Scalars['Float'];
  reason: StripeRefundReason;
  note?: InputMaybe<Scalars['String']>;
}>;

export type CreateRefundMutation = {
  __typename?: 'mutation_root';
  StripeCreateRefund: { __typename?: 'StripeCreateRefundOutput'; id: string };
};

export type GetProductsQueryVariables = Exact<{
  where?: InputMaybe<Products_Bool_Exp>;
  order_by?: InputMaybe<Array<Products_Order_By> | Products_Order_By>;
}>;

export type GetProductsQuery = {
  __typename?: 'query_root';
  products: Array<{
    __typename?: 'products';
    id: string;
    name: string;
    price: number;
    type: string;
  }>;
};

export type GetProductQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetProductQuery = {
  __typename?: 'query_root';
  products_by_pk?: {
    __typename?: 'products';
    id: string;
    name: string;
    price: number;
    type: string;
  } | null;
};

export type LoadRegionsQueryVariables = Exact<{
  where?: InputMaybe<Regions_Bool_Exp>;
}>;

export type LoadRegionsQuery = {
  __typename?: 'query_root';
  regions: Array<{
    __typename?: 'regions';
    id: number;
    location?: any | null;
    name: string;
    phoneNumber?: string | null;
    regionCode: string;
    virtualZoomRegionEmail?: string | null;
  }>;
};

export type LoadRegionsClinicsQueryVariables = Exact<{
  where?: InputMaybe<Regions_Bool_Exp>;
  clinicWhere?: InputMaybe<Clinics_Regions_Bool_Exp>;
}>;

export type LoadRegionsClinicsQuery = {
  __typename?: 'query_root';
  regions: Array<{
    __typename?: 'regions';
    id: number;
    name: string;
    regionCode: string;
    clinics_regions: Array<{
      __typename?: 'clinics_regions';
      clinic: {
        __typename?: 'clinics';
        id: string;
        name: string;
        clinicCode: string;
      };
    }>;
  }>;
};

export type GetPatientAvatarQueryVariables = Exact<{
  patientId: Scalars['uuid'];
}>;

export type GetPatientAvatarQuery = {
  __typename?: 'query_root';
  patients_by_pk?: {
    __typename?: 'patients';
    avatar?: {
      __typename?: 'FilePayload';
      id?: string | null;
      url?: string | null;
    } | null;
  } | null;
};

export type GetPatientDocumentsQueryVariables = Exact<{
  patientId: Scalars['uuid'];
}>;

export type GetPatientDocumentsQuery = {
  __typename?: 'query_root';
  patients_by_pk?: {
    __typename?: 'patients';
    consentDocument?: {
      __typename?: 'FilePayload';
      url?: string | null;
      id?: string | null;
    } | null;
  } | null;
};

export type GetAccountAvatarQueryVariables = Exact<{
  accountId: Scalars['uuid'];
}>;

export type GetAccountAvatarQuery = {
  __typename?: 'query_root';
  accounts_by_pk?: {
    __typename?: 'accounts';
    firstName?: string | null;
    lastName?: string | null;
    avatar?: {
      __typename?: 'FilePayload';
      id?: string | null;
      url?: string | null;
    } | null;
  } | null;
};

export type GetInsuranceImageQueryVariables = Exact<{
  insuranceId: Scalars['uuid'];
}>;

export type GetInsuranceImageQuery = {
  __typename?: 'query_root';
  insurance_by_pk?: {
    __typename?: 'insurance';
    id: string;
    accountId: string;
    backId?: string | null;
    carrier?: string | null;
    dateOfBirth?: string | null;
    eligibility?: any | null;
    firstName?: string | null;
    frontId?: string | null;
    lastName?: string | null;
    memberId?: string | null;
    type: Insurance_Types_Enum;
    updatedAt: string;
    back?: {
      __typename?: 'FilePayload';
      id?: string | null;
      url?: string | null;
    } | null;
    front?: {
      __typename?: 'FilePayload';
      id?: string | null;
      url?: string | null;
    } | null;
  } | null;
};

export type GetAccountPhotoIdsQueryVariables = Exact<{
  accountId: Scalars['uuid'];
}>;

export type GetAccountPhotoIdsQuery = {
  __typename?: 'query_root';
  accounts_by_pk?: {
    __typename?: 'accounts';
    id: string;
    photoIdBack?: {
      __typename?: 'FilePayload';
      id?: string | null;
      url?: string | null;
    } | null;
    photoIdFront?: {
      __typename?: 'FilePayload';
      id?: string | null;
      url?: string | null;
    } | null;
  } | null;
};

export type UpdateRoomMutationVariables = Exact<{
  set?: InputMaybe<Rooms_Set_Input>;
  id: Scalars['uuid'];
}>;

export type UpdateRoomMutation = {
  __typename?: 'mutation_root';
  update_rooms_by_pk?: { __typename?: 'rooms'; id: string } | null;
};

export type GetRoomsByClinicQueryVariables = Exact<{
  clinicId: Scalars['uuid'];
}>;

export type GetRoomsByClinicQuery = {
  __typename?: 'query_root';
  rooms: Array<{
    __typename?: 'rooms';
    appointmentId?: string | null;
    createdAt: string;
    clinicId: string;
    id: string;
    signId: string;
    name: string;
    staffId?: string | null;
    status: string;
    updatedAt: string;
  }>;
};

export type RoomsByClinicSubscriptionVariables = Exact<{
  clinicId: Scalars['uuid'];
}>;

export type RoomsByClinicSubscription = {
  __typename?: 'subscription_root';
  rooms: Array<{
    __typename?: 'rooms';
    appointmentId?: string | null;
    createdAt: string;
    clinicId: string;
    id: string;
    signId: string;
    name: string;
    staffId?: string | null;
    status: string;
    updatedAt: string;
  }>;
};

export type RoomsByClinicCodeSubscriptionVariables = Exact<{
  clinicCode: Scalars['String'];
}>;

export type RoomsByClinicCodeSubscription = {
  __typename?: 'subscription_root';
  rooms: Array<{
    __typename?: 'rooms';
    id: string;
    name: string;
    status: string;
    appointment?: {
      __typename?: 'appointments';
      id: string;
      appointment_patients: Array<{
        __typename?: 'appointment_patient';
        patient: { __typename?: 'patients'; id: string };
      }>;
    } | null;
  }>;
};

export type InsertAppointmentBlockMutationVariables = Exact<{
  block: Appointment_Blocks_Insert_Input;
}>;

export type InsertAppointmentBlockMutation = {
  __typename?: 'mutation_root';
  insert_appointment_blocks_one?: {
    __typename?: 'appointment_blocks';
    id: string;
    clinicId: string;
    endDateTime: string;
    startDateTime: string;
    reason?: string | null;
    appointment_blocks_staffs: Array<{
      __typename?: 'appointment_blocks_staff';
      id: number;
      staff: {
        __typename?: 'staff';
        id: string;
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
      };
    }>;
  } | null;
};

export type UpsertAppointmentBlockMutationVariables = Exact<{
  id: Scalars['uuid'];
  block: Appointment_Blocks_Set_Input;
  staff:
    | Array<Appointment_Blocks_Staff_Insert_Input>
    | Appointment_Blocks_Staff_Insert_Input;
}>;

export type UpsertAppointmentBlockMutation = {
  __typename?: 'mutation_root';
  delete_appointment_blocks_staff?: {
    __typename?: 'appointment_blocks_staff_mutation_response';
    affected_rows: number;
  } | null;
  insert_appointment_blocks_staff?: {
    __typename?: 'appointment_blocks_staff_mutation_response';
    affected_rows: number;
  } | null;
  update_appointment_blocks_by_pk?: {
    __typename?: 'appointment_blocks';
    id: string;
    clinicId: string;
    endDateTime: string;
    startDateTime: string;
    reason?: string | null;
    appointment_blocks_staffs: Array<{
      __typename?: 'appointment_blocks_staff';
      id: number;
      staff: {
        __typename?: 'staff';
        id: string;
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
      };
    }>;
  } | null;
};

export type UpdateAppointmentBlockMutationVariables = Exact<{
  id: Scalars['uuid'];
  block: Appointment_Blocks_Set_Input;
}>;

export type UpdateAppointmentBlockMutation = {
  __typename?: 'mutation_root';
  update_appointment_blocks_by_pk?: {
    __typename?: 'appointment_blocks';
    id: string;
    clinicId: string;
    endDateTime: string;
    startDateTime: string;
    reason?: string | null;
    appointment_blocks_staffs: Array<{
      __typename?: 'appointment_blocks_staff';
      id: number;
      staff: {
        __typename?: 'staff';
        id: string;
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
      };
    }>;
  } | null;
};

export type DeleteAppointmentBlockMutationVariables = Exact<{
  blockId: Scalars['uuid'];
}>;

export type DeleteAppointmentBlockMutation = {
  __typename?: 'mutation_root';
  delete_appointment_blocks_staff?: {
    __typename?: 'appointment_blocks_staff_mutation_response';
    affected_rows: number;
  } | null;
  delete_appointment_blocks_by_pk?: {
    __typename?: 'appointment_blocks';
    id: string;
  } | null;
};

export type DeleteAppointmentStaffBlockMutationVariables = Exact<{
  appointmentStaffBlockId: Scalars['bigint'];
}>;

export type DeleteAppointmentStaffBlockMutation = {
  __typename?: 'mutation_root';
  delete_appointment_blocks_staff_by_pk?: {
    __typename?: 'appointment_blocks_staff';
    id: number;
  } | null;
};

export type WatchAppointmentBlocksSubscriptionVariables = Exact<{
  where?: InputMaybe<Appointment_Blocks_Bool_Exp>;
}>;

export type WatchAppointmentBlocksSubscription = {
  __typename?: 'subscription_root';
  appointment_blocks: Array<{
    __typename?: 'appointment_blocks';
    id: string;
    clinicId: string;
    endDateTime: string;
    startDateTime: string;
    reason?: string | null;
    appointment_blocks_staffs: Array<{
      __typename?: 'appointment_blocks_staff';
      id: number;
      staff: {
        __typename?: 'staff';
        id: string;
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
      };
    }>;
  }>;
};

export type LoadStaffScheduleQueryVariables = Exact<{
  where: Staff_Schedule_Bool_Exp;
}>;

export type LoadStaffScheduleQuery = {
  __typename?: 'query_root';
  staff_schedule: Array<{
    __typename?: 'staff_schedule';
    id: string;
    startDateTime: string;
    endDateTime: string;
    clinicId?: string | null;
    created_at: string;
    staffId: string;
    updated_at: string;
    timeZone: string;
    staff: {
      __typename?: 'staff';
      id: string;
      active: boolean;
      firstName: string;
      lastName: string;
      auth0UserId?: string | null;
      created_at: string;
      defaultClinicId?: string | null;
      timeZone: string;
      type: Staff_Type_Enum;
      updated_at: string;
      email?: string | null;
      avatarUrl?: string | null;
      color?: string | null;
      lead: boolean;
      staffManagerId?: string | null;
      staff_type: {
        __typename?: 'staff_type';
        description: string;
        value: string;
      };
    };
    clinic?: {
      __typename?: 'clinics';
      id: string;
      timeZone?: string | null;
    } | null;
    staff_schedule_services: Array<{
      __typename?: 'staff_schedule_service';
      id: string;
      service: Services_Enum;
      staffScheduleId: string;
      updated_at: string;
      created_at: string;
    }>;
  }>;
};

export type VerifyStaffMemberMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type VerifyStaffMemberMutation = {
  __typename?: 'mutation_root';
  VerifyStaffMember?: {
    __typename?: 'VerifyStaffMemberOutput';
    success: boolean;
  } | null;
};

export type LoadBasicStaffDetailsQueryVariables = Exact<{
  where: Staff_Bool_Exp;
}>;

export type LoadBasicStaffDetailsQuery = {
  __typename?: 'query_root';
  staff: Array<{
    __typename?: 'staff';
    id: string;
    firstName: string;
    lastName: string;
    defaultClinicId?: string | null;
    auth0UserId?: string | null;
    avatarUrl?: string | null;
    type: Staff_Type_Enum;
    clinic?: { __typename?: 'clinics'; clinicCode: string } | null;
  }>;
};

export type BootstrapClinicsQueryVariables = Exact<{
  auth0UserId: Scalars['String'];
}>;

export type BootstrapClinicsQuery = {
  __typename?: 'query_root';
  staff: Array<{
    __typename?: 'staff';
    id: string;
    defaultClinicId?: string | null;
    clinic?: { __typename?: 'clinics'; clinicCode: string } | null;
  }>;
  clinics_regions: Array<{
    __typename?: 'clinics_regions';
    clinicId: string;
    regionId: number;
    clinic: { __typename?: 'clinics'; clinicCode: string };
  }>;
};

export type LoadEditTaskByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type LoadEditTaskByIdQuery = {
  __typename?: 'query_root';
  tasks_by_pk?: {
    __typename?: 'tasks';
    id: string;
    createdAt: string;
    updatedAt: string;
    title: string;
    note?: string | null;
    priority?: Task_Priority_Enum | null;
    dueDateTime?: string | null;
    createdBy?: string | null;
    active: boolean;
    status: Task_Status_Enum;
    subType?: Task_Sub_Type_Enum | null;
    type: Task_Type_Enum;
    completed?: boolean | null;
    readCount?: number | null;
    responsibleCount?: number | null;
    tasks_assignments: Array<{
      __typename?: 'tasks_assignments';
      id: number;
      createdAt: string;
      updatedAt: string;
      assignedTo: string;
      assignedBy?: string | null;
      taskId: string;
      active: boolean;
    }>;
    tasks_notifications: Array<{
      __typename?: 'tasks_notifications';
      id: number;
      createdAt: string;
      updatedAt: string;
      accountId?: string | null;
      active: boolean;
      appointmentId?: string | null;
      clinicId?: string | null;
      patientId?: string | null;
      staffId?: string | null;
      staffType?: Staff_Type_Enum | null;
      taskId: string;
      taskGroupId?: string | null;
    }>;
  } | null;
};

export type LoadTaskByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type LoadTaskByIdQuery = {
  __typename?: 'query_root';
  tasks_by_pk?: {
    __typename?: 'tasks';
    id: string;
    createdAt: string;
    updatedAt: string;
    title: string;
    note?: string | null;
    priority?: Task_Priority_Enum | null;
    dueDateTime?: string | null;
    createdBy?: string | null;
    active: boolean;
    status: Task_Status_Enum;
    subType?: Task_Sub_Type_Enum | null;
    type: Task_Type_Enum;
    completed?: boolean | null;
    readCount?: number | null;
    responsibleCount?: number | null;
    staff?: {
      __typename?: 'staff';
      id: string;
      avatarUrl?: string | null;
      firstName: string;
      lastName: string;
    } | null;
    tasks_assignments: Array<{
      __typename?: 'tasks_assignments';
      id: number;
      createdAt: string;
      updatedAt: string;
      assignedTo: string;
      assignedBy?: string | null;
      taskId: string;
      active: boolean;
      staff: {
        __typename?: 'staff';
        id: string;
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
      };
    }>;
    tasks_watches: Array<{
      __typename?: 'tasks_watch';
      id: number;
      staffId: string;
      taskId: string;
    }>;
    tasks_reads: Array<{
      __typename?: 'tasks_read';
      staff: {
        __typename?: 'staff';
        id: string;
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
      };
    }>;
    tasks_files: Array<{
      __typename?: 'tasks_files';
      id: number;
      fileId: string;
    }>;
    tasks_notifications: Array<{
      __typename?: 'tasks_notifications';
      id: number;
      createdAt: string;
      updatedAt: string;
      accountId?: string | null;
      active: boolean;
      appointmentId?: string | null;
      clinicId?: string | null;
      patientId?: string | null;
      staffId?: string | null;
      staffType?: Staff_Type_Enum | null;
      taskId: string;
      taskGroupId?: string | null;
      account?: {
        __typename?: 'accounts';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
      } | null;
      clinic?: { __typename?: 'clinics'; id: string; name: string } | null;
      patient?: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
        dateOfBirth: string;
        goesByName?: string | null;
        pronunciation?: string | null;
        kareo_patient_id?: string | null;
      } | null;
      staff?: {
        __typename?: 'staff';
        id: string;
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
      } | null;
      tasks_group?: {
        __typename?: 'tasks_groups';
        id: string;
        name: string;
        tasks_groups_staffs: Array<{
          __typename?: 'tasks_groups_staff';
          staff: {
            __typename?: 'staff';
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl?: string | null;
          };
        }>;
        tasks_groups_staffs_aggregate: {
          __typename?: 'tasks_groups_staff_aggregate';
          aggregate?: {
            __typename?: 'tasks_groups_staff_aggregate_fields';
            count: number;
          } | null;
        };
      } | null;
      appointment?: {
        __typename?: 'appointments';
        visitType?: Visit_Types_Enum | null;
        visitClassification?: Visit_Classifications_Enum | null;
        reason: string;
        startTime: string;
        endTime: string;
      } | null;
    }>;
  } | null;
};

export type LoadTaskAssignmentsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type LoadTaskAssignmentsQuery = {
  __typename?: 'query_root';
  tasks_assignments: Array<{
    __typename?: 'tasks_assignments';
    id: number;
    createdAt: string;
    updatedAt: string;
    assignedTo: string;
    assignedBy?: string | null;
    taskId: string;
    active: boolean;
    staff: {
      __typename?: 'staff';
      id: string;
      avatarUrl?: string | null;
      firstName: string;
      lastName: string;
    };
  }>;
};

export type LoadTaskActivityQueryVariables = Exact<{
  taskId: Scalars['uuid'];
  where: Audit_Logs_Bool_Exp;
  order_by: Array<Audit_Logs_Order_By> | Audit_Logs_Order_By;
}>;

export type LoadTaskActivityQuery = {
  __typename?: 'query_root';
  tasks_assignments: Array<{
    __typename?: 'tasks_assignments';
    createdAt: string;
    updatedAt: string;
    staff: {
      __typename?: 'staff';
      id: string;
      avatarUrl?: string | null;
      firstName: string;
      lastName: string;
    };
  }>;
  tasks_read: Array<{
    __typename?: 'tasks_read';
    createdAt: string;
    staff: {
      __typename?: 'staff';
      id: string;
      avatarUrl?: string | null;
      firstName: string;
      lastName: string;
    };
  }>;
  audit_logs: Array<{
    __typename?: 'audit_logs';
    accountId?: string | null;
    created_at: string;
    difference?: any | null;
    from?: any | null;
    id: number;
    operation: string;
    session: any;
    staffUserId?: string | null;
    table: string;
    to?: any | null;
    updated_at: string;
    staff?: {
      __typename?: 'staff';
      avatarUrl?: string | null;
      firstName: string;
      lastName: string;
      id: string;
    } | null;
    account?: {
      __typename?: 'accounts';
      firstName?: string | null;
      lastName?: string | null;
      id: string;
      avatar?: {
        __typename?: 'FilePayload';
        id?: string | null;
        url?: string | null;
      } | null;
    } | null;
  }>;
};

export type WatchTaskByIdSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
  staffId: Scalars['uuid'];
}>;

export type WatchTaskByIdSubscription = {
  __typename?: 'subscription_root';
  tasks_by_pk?: {
    __typename?: 'tasks';
    id: string;
    createdAt: string;
    updatedAt: string;
    title: string;
    note?: string | null;
    priority?: Task_Priority_Enum | null;
    dueDateTime?: string | null;
    createdBy?: string | null;
    active: boolean;
    status: Task_Status_Enum;
    subType?: Task_Sub_Type_Enum | null;
    type: Task_Type_Enum;
    completed?: boolean | null;
    readCount?: number | null;
    responsibleCount?: number | null;
    staff?: {
      __typename?: 'staff';
      id: string;
      avatarUrl?: string | null;
      firstName: string;
      lastName: string;
    } | null;
    tasks_files: Array<{
      __typename?: 'tasks_files';
      id: number;
      fileId: string;
    }>;
    tasks_assignments: Array<{
      __typename?: 'tasks_assignments';
      id: number;
      createdAt: string;
      updatedAt: string;
      assignedTo: string;
      assignedBy?: string | null;
      taskId: string;
      active: boolean;
      staff: {
        __typename?: 'staff';
        id: string;
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
      };
    }>;
    tasks_watches: Array<{
      __typename?: 'tasks_watch';
      id: number;
      staffId: string;
      taskId: string;
    }>;
    tasks_reads: Array<{
      __typename?: 'tasks_read';
      id: number;
      staffId: string;
      staff: {
        __typename?: 'staff';
        id: string;
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
      };
    }>;
    tasks_notifications: Array<{
      __typename?: 'tasks_notifications';
      id: number;
      createdAt: string;
      updatedAt: string;
      accountId?: string | null;
      active: boolean;
      appointmentId?: string | null;
      clinicId?: string | null;
      patientId?: string | null;
      staffId?: string | null;
      staffType?: Staff_Type_Enum | null;
      taskId: string;
      taskGroupId?: string | null;
      account?: {
        __typename?: 'accounts';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber: string;
      } | null;
      clinic?: { __typename?: 'clinics'; id: string; name: string } | null;
      patient?: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
        dateOfBirth: string;
        goesByName?: string | null;
        pronunciation?: string | null;
        kareo_patient_id?: string | null;
      } | null;
      staff?: {
        __typename?: 'staff';
        id: string;
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
      } | null;
      responsible?: {
        __typename?: 'tasks_groups';
        tasks_groups_staffs_aggregate: {
          __typename?: 'tasks_groups_staff_aggregate';
          aggregate?: {
            __typename?: 'tasks_groups_staff_aggregate_fields';
            count: number;
          } | null;
        };
      } | null;
      tasks_group?: {
        __typename?: 'tasks_groups';
        id: string;
        name: string;
        tasks_groups_staffs: Array<{
          __typename?: 'tasks_groups_staff';
          staffId: string;
          staff: {
            __typename?: 'staff';
            id: string;
            firstName: string;
            lastName: string;
            avatarUrl?: string | null;
          };
        }>;
        tasks_groups_staffs_aggregate: {
          __typename?: 'tasks_groups_staff_aggregate';
          aggregate?: {
            __typename?: 'tasks_groups_staff_aggregate_fields';
            count: number;
          } | null;
        };
      } | null;
      appointment?: {
        __typename?: 'appointments';
        visitType?: Visit_Types_Enum | null;
        visitClassification?: Visit_Classifications_Enum | null;
        reason: string;
        startTime: string;
        endTime: string;
      } | null;
    }>;
  } | null;
};

export type LoadTasksQueryVariables = Exact<{
  where: Tasks_Bool_Exp;
  order_by?: InputMaybe<Array<Tasks_Order_By> | Tasks_Order_By>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  staffId: Scalars['uuid'];
}>;

export type LoadTasksQuery = {
  __typename?: 'query_root';
  tasks: Array<{
    __typename?: 'tasks';
    id: string;
    title: string;
    priority?: Task_Priority_Enum | null;
    dueDateTime?: string | null;
    status: Task_Status_Enum;
    subType?: Task_Sub_Type_Enum | null;
    type: Task_Type_Enum;
    completed?: boolean | null;
    readCount?: number | null;
    responsibleCount?: number | null;
    staff?: {
      __typename?: 'staff';
      id: string;
      avatarUrl?: string | null;
      firstName: string;
      lastName: string;
    } | null;
    tasks_reads: Array<{
      __typename?: 'tasks_read';
      id: number;
      staffId: string;
    }>;
    tasks_watches: Array<{
      __typename?: 'tasks_watch';
      id: number;
      staffId: string;
    }>;
    tasks_assignments: Array<{
      __typename?: 'tasks_assignments';
      id: number;
      assignedTo: string;
      staff: {
        __typename?: 'staff';
        id: string;
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
      };
    }>;
    tasks_notifications: Array<{
      __typename?: 'tasks_notifications';
      patientId?: string | null;
      appointmentId?: string | null;
      accountId?: string | null;
      staffId?: string | null;
      taskGroupId?: string | null;
      patient?: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      staff?: {
        __typename?: 'staff';
        id: string;
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
      } | null;
      responsible?: {
        __typename?: 'tasks_groups';
        tasks_groups_staffs_aggregate: {
          __typename?: 'tasks_groups_staff_aggregate';
          aggregate?: {
            __typename?: 'tasks_groups_staff_aggregate_fields';
            count: number;
          } | null;
        };
      } | null;
      tasks_group?: {
        __typename?: 'tasks_groups';
        id: string;
        name: string;
        tasks_groups_staffs: Array<{
          __typename?: 'tasks_groups_staff';
          id: number;
          staffId: string;
        }>;
        tasks_groups_staffs_aggregate: {
          __typename?: 'tasks_groups_staff_aggregate';
          aggregate?: {
            __typename?: 'tasks_groups_staff_aggregate_fields';
            count: number;
          } | null;
        };
      } | null;
    }>;
  }>;
};

export type WatchTasksSubscriptionVariables = Exact<{
  where: Tasks_Bool_Exp;
  order_by?: InputMaybe<Array<Tasks_Order_By> | Tasks_Order_By>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  staffId: Scalars['uuid'];
}>;

export type WatchTasksSubscription = {
  __typename?: 'subscription_root';
  tasks: Array<{
    __typename?: 'tasks';
    id: string;
    title: string;
    priority?: Task_Priority_Enum | null;
    dueDateTime?: string | null;
    status: Task_Status_Enum;
    subType?: Task_Sub_Type_Enum | null;
    type: Task_Type_Enum;
    completed?: boolean | null;
    readCount?: number | null;
    responsibleCount?: number | null;
    staff?: {
      __typename?: 'staff';
      id: string;
      avatarUrl?: string | null;
      firstName: string;
      lastName: string;
    } | null;
    tasks_reads: Array<{
      __typename?: 'tasks_read';
      id: number;
      staffId: string;
    }>;
    tasks_watches: Array<{ __typename?: 'tasks_watch'; staffId: string }>;
    tasks_assignments: Array<{
      __typename?: 'tasks_assignments';
      id: number;
      assignedTo: string;
      staff: {
        __typename?: 'staff';
        id: string;
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
      };
    }>;
    tasks_notifications: Array<{
      __typename?: 'tasks_notifications';
      patientId?: string | null;
      appointmentId?: string | null;
      accountId?: string | null;
      staffId?: string | null;
      taskGroupId?: string | null;
      patient?: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      staff?: {
        __typename?: 'staff';
        id: string;
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
      } | null;
      responsible?: {
        __typename?: 'tasks_groups';
        tasks_groups_staffs_aggregate: {
          __typename?: 'tasks_groups_staff_aggregate';
          aggregate?: {
            __typename?: 'tasks_groups_staff_aggregate_fields';
            count: number;
          } | null;
        };
      } | null;
      tasks_group?: {
        __typename?: 'tasks_groups';
        id: string;
        name: string;
        tasks_groups_staffs: Array<{
          __typename?: 'tasks_groups_staff';
          staffId: string;
        }>;
        tasks_groups_staffs_aggregate: {
          __typename?: 'tasks_groups_staff_aggregate';
          aggregate?: {
            __typename?: 'tasks_groups_staff_aggregate_fields';
            count: number;
          } | null;
        };
      } | null;
    }>;
  }>;
};

export type LoadTaskIndexQueryVariables = Exact<{
  where: Tasks_Bool_Exp;
  order_by?: InputMaybe<Array<Tasks_Order_By> | Tasks_Order_By>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  staffId: Scalars['uuid'];
}>;

export type LoadTaskIndexQuery = {
  __typename?: 'query_root';
  tasks: Array<{
    __typename?: 'tasks';
    id: string;
    title: string;
    priority?: Task_Priority_Enum | null;
    dueDateTime?: string | null;
    status: Task_Status_Enum;
    subType?: Task_Sub_Type_Enum | null;
    type: Task_Type_Enum;
    staff?: {
      __typename?: 'staff';
      id: string;
      avatarUrl?: string | null;
      firstName: string;
      lastName: string;
    } | null;
    tasks_reads: Array<{
      __typename?: 'tasks_read';
      id: number;
      staffId: string;
    }>;
    tasks_watches: Array<{ __typename?: 'tasks_watch'; staffId: string }>;
    tasks_assignments: Array<{
      __typename?: 'tasks_assignments';
      id: number;
      assignedTo: string;
      staff: {
        __typename?: 'staff';
        id: string;
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
      };
    }>;
    tasks_notifications: Array<{
      __typename?: 'tasks_notifications';
      patientId?: string | null;
      appointmentId?: string | null;
      accountId?: string | null;
      staffId?: string | null;
      taskGroupId?: string | null;
      patient?: {
        __typename?: 'patients';
        id: string;
        firstName: string;
        lastName: string;
      } | null;
      staff?: {
        __typename?: 'staff';
        id: string;
        avatarUrl?: string | null;
        firstName: string;
        lastName: string;
      } | null;
      responsible?: {
        __typename?: 'tasks_groups';
        tasks_groups_staffs_aggregate: {
          __typename?: 'tasks_groups_staff_aggregate';
          aggregate?: {
            __typename?: 'tasks_groups_staff_aggregate_fields';
            count: number;
          } | null;
        };
      } | null;
      tasks_group?: {
        __typename?: 'tasks_groups';
        id: string;
        name: string;
        tasks_groups_staffs: Array<{
          __typename?: 'tasks_groups_staff';
          staffId: string;
        }>;
        tasks_groups_staffs_aggregate: {
          __typename?: 'tasks_groups_staff_aggregate';
          aggregate?: {
            __typename?: 'tasks_groups_staff_aggregate_fields';
            count: number;
          } | null;
        };
      } | null;
    }>;
  }>;
};

export type WatchTasksCountSubscriptionVariables = Exact<{
  where: Tasks_Bool_Exp;
}>;

export type WatchTasksCountSubscription = {
  __typename?: 'subscription_root';
  tasks_aggregate: {
    __typename?: 'tasks_aggregate';
    aggregate?: { __typename?: 'tasks_aggregate_fields'; count: number } | null;
  };
};

export type LoadTasksCountQueryVariables = Exact<{
  start: Scalars['timestamptz'];
  end: Scalars['timestamptz'];
  staffAuthId: Scalars['String'];
}>;

export type LoadTasksCountQuery = {
  __typename?: 'query_root';
  tasks_aggregate: {
    __typename?: 'tasks_aggregate';
    aggregate?: { __typename?: 'tasks_aggregate_fields'; count: number } | null;
  };
};

export type InsertTaskMutationVariables = Exact<{
  task: Tasks_Insert_Input;
}>;

export type InsertTaskMutation = {
  __typename?: 'mutation_root';
  insert_tasks_one?: { __typename?: 'tasks'; id: string } | null;
};

export type DeleteTaskMutationVariables = Exact<{
  taskId: Scalars['uuid'];
}>;

export type DeleteTaskMutation = {
  __typename?: 'mutation_root';
  update_tasks_by_pk?: { __typename?: 'tasks'; id: string } | null;
};

export type UpdateTaskMutationVariables = Exact<{
  taskId: Scalars['uuid'];
  task: Tasks_Set_Input;
}>;

export type UpdateTaskMutation = {
  __typename?: 'mutation_root';
  update_tasks_by_pk?: { __typename?: 'tasks'; id: string } | null;
};

export type UpdateTaskNotificationsMutationVariables = Exact<{
  where: Tasks_Notifications_Bool_Exp;
  notifications: Tasks_Notifications_Set_Input;
}>;

export type UpdateTaskNotificationsMutation = {
  __typename?: 'mutation_root';
  update_tasks_notifications?: {
    __typename?: 'tasks_notifications_mutation_response';
    affected_rows: number;
  } | null;
};

export type InsertTaskNotificationsMutationVariables = Exact<{
  notifications:
    | Array<Tasks_Notifications_Insert_Input>
    | Tasks_Notifications_Insert_Input;
}>;

export type InsertTaskNotificationsMutation = {
  __typename?: 'mutation_root';
  insert_tasks_notifications?: {
    __typename?: 'tasks_notifications_mutation_response';
    affected_rows: number;
  } | null;
};

export type RemoveNotificationMutationVariables = Exact<{
  taskNotificationId: Scalars['bigint'];
}>;

export type RemoveNotificationMutation = {
  __typename?: 'mutation_root';
  update_tasks_notifications_by_pk?: {
    __typename?: 'tasks_notifications';
    id: number;
  } | null;
};

export type InsertTaskAssignmentMutationVariables = Exact<{
  taskAssignment: Tasks_Assignments_Insert_Input;
}>;

export type InsertTaskAssignmentMutation = {
  __typename?: 'mutation_root';
  insert_tasks_assignments_one?: {
    __typename?: 'tasks_assignments';
    id: number;
  } | null;
};

export type UnassignTaskMutationVariables = Exact<{
  taskAssignmentId: Scalars['bigint'];
}>;

export type UnassignTaskMutation = {
  __typename?: 'mutation_root';
  update_tasks_assignments_by_pk?: {
    __typename?: 'tasks_assignments';
    id: number;
    active: boolean;
  } | null;
};

export type MarkAsReadMutationVariables = Exact<{
  taskId: Scalars['uuid'];
}>;

export type MarkAsReadMutation = {
  __typename?: 'mutation_root';
  insert_tasks_read_one?: { __typename?: 'tasks_read'; id: number } | null;
};

export type DeleteUnreadMutationVariables = Exact<{
  where: Tasks_Read_Bool_Exp;
}>;

export type DeleteUnreadMutation = {
  __typename?: 'mutation_root';
  delete_tasks_read?: {
    __typename?: 'tasks_read_mutation_response';
    affected_rows: number;
  } | null;
};

export type LoadTaskGroupsQueryVariables = Exact<{
  where?: InputMaybe<Tasks_Groups_Bool_Exp>;
  order_by?: InputMaybe<Array<Tasks_Groups_Order_By> | Tasks_Groups_Order_By>;
}>;

export type LoadTaskGroupsQuery = {
  __typename?: 'query_root';
  tasks_groups: Array<{
    __typename?: 'tasks_groups';
    id: string;
    name: string;
  }>;
};

export type LoadTaskGroupStaffsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type LoadTaskGroupStaffsQuery = {
  __typename?: 'query_root';
  tasks_groups_by_pk?: {
    __typename?: 'tasks_groups';
    id: string;
    name: string;
    tasks_groups_staffs: Array<{
      __typename?: 'tasks_groups_staff';
      id: number;
      staffId: string;
      staff: {
        __typename?: 'staff';
        id: string;
        lastName: string;
        firstName: string;
        avatarUrl?: string | null;
      };
    }>;
  } | null;
};

export type LoadTaskFilesQueryVariables = Exact<{
  where: Tasks_Files_Bool_Exp;
  order_by?: InputMaybe<Array<Tasks_Files_Order_By> | Tasks_Files_Order_By>;
}>;

export type LoadTaskFilesQuery = {
  __typename?: 'query_root';
  tasks_files: Array<{
    __typename?: 'tasks_files';
    file: {
      __typename?: 'files';
      id: string;
      info?: {
        __typename?: 'FilePayload';
        url?: string | null;
        id?: string | null;
      } | null;
    };
  }>;
};

export type UnwatchTaskMutationVariables = Exact<{
  taskId: Scalars['uuid'];
}>;

export type UnwatchTaskMutation = {
  __typename?: 'mutation_root';
  delete_tasks_watch?: {
    __typename?: 'tasks_watch_mutation_response';
    affected_rows: number;
  } | null;
};

export type WatchTaskMutationVariables = Exact<{
  taskId: Scalars['uuid'];
}>;

export type WatchTaskMutation = {
  __typename?: 'mutation_root';
  insert_tasks_watch_one?: {
    __typename?: 'tasks_watch';
    id: number;
    staffId: string;
    taskId: string;
  } | null;
};

export type TaskWatchFragment = {
  __typename?: 'tasks_watch';
  id: number;
  staffId: string;
  taskId: string;
};

export type TaskFragment = {
  __typename?: 'tasks';
  id: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  note?: string | null;
  priority?: Task_Priority_Enum | null;
  dueDateTime?: string | null;
  createdBy?: string | null;
  active: boolean;
  status: Task_Status_Enum;
  subType?: Task_Sub_Type_Enum | null;
  type: Task_Type_Enum;
  completed?: boolean | null;
  readCount?: number | null;
  responsibleCount?: number | null;
};

export type TaskReadFragment = {
  __typename?: 'tasks_read';
  id: number;
  createdAt: string;
  updatedAt: string;
  taskId: string;
  staffId: string;
};

export type TaskNotificationFragment = {
  __typename?: 'tasks_notifications';
  id: number;
  createdAt: string;
  updatedAt: string;
  accountId?: string | null;
  active: boolean;
  appointmentId?: string | null;
  clinicId?: string | null;
  patientId?: string | null;
  staffId?: string | null;
  staffType?: Staff_Type_Enum | null;
  taskId: string;
  taskGroupId?: string | null;
};

export type TaskAssignmentFragment = {
  __typename?: 'tasks_assignments';
  id: number;
  createdAt: string;
  updatedAt: string;
  assignedTo: string;
  assignedBy?: string | null;
  taskId: string;
  active: boolean;
};

export const AccountAvatarFragmentDoc = gql`
  fragment AccountAvatar on accounts {
    avatar {
      id
      url
    }
  }
`;
export const AuditFragmentDoc = gql`
  fragment Audit on audit_logs {
    accountId
    created_at
    difference
    from
    id
    operation
    session
    staffUserId
    table
    to
    updated_at
    staff {
      avatarUrl
      firstName
      lastName
      id
    }
    account {
      ...AccountAvatar
      firstName
      lastName
      id
    }
  }
  ${AccountAvatarFragmentDoc}
`;
export const RecentChatsDataFragmentDoc = gql`
  fragment RecentChatsData on chat_sessions {
    id
    createdAt
    chat_sessions_accounts {
      account {
        goesByName
        firstName
        lastName
      }
    }
    chat_sessions_staffs {
      staff {
        firstName
        lastName
      }
    }
  }
`;
export const AccountFragmentDoc = gql`
  fragment Account on accounts {
    id
    createdAt
    consentToMedicalTermsTimestamp
    consentToTermsAndPrivacyTimestamp
    email
    firstName
    goesByName
    lastName
    phoneNumber
    postalCode
    preferredPronoun
    preferredPronounType
  }
`;
export const StaffFragmentDoc = gql`
  fragment Staff on staff {
    id
    active
    firstName
    lastName
    auth0UserId
    created_at
    defaultClinicId
    timeZone
    type
    updated_at
    email
    avatarUrl
    color
    lead
    staffManagerId
    staff_type {
      description
      value
    }
  }
`;
export const CommunicationHistoryFragmentDoc = gql`
  fragment CommunicationHistory on communication_history {
    id
    body
    createdAt
    updatedAt
    direction
    from
    metadata
    status
    to
    type
    account {
      ...Account
    }
    staff {
      ...Staff
    }
  }
  ${AccountFragmentDoc}
  ${StaffFragmentDoc}
`;
export const AppointmentFragmentDoc = gql`
  fragment Appointment on appointments {
    id
    accountId
    address
    campaign
    clinicId
    createdAt
    email
    endTime
    firstName
    kareo_appointment_id
    kareo_appointment_uuid
    languageSelection
    lastName
    notes
    patientId
    payment
    phoneNumber
    reason
    safetyQuestionnaire
    startTime
    state
    status
    stripeCardId
    stripeCustomerId
    timeZone
    type
    visitClassification
    visitType
  }
`;
export const GlobalNoteFragmentDoc = gql`
  fragment GlobalNote on global_notes {
    id
    appointmentId
    deleted
    patientId
    accountId
    updatedAt
    createdAt
    note
    type
    staffId
    staff {
      ...Staff
    }
  }
  ${StaffFragmentDoc}
`;
export const PatientFragmentDoc = gql`
  fragment Patient on patients {
    id
    firstName
    lastName
    goesByName
    pronunciation
    dateOfBirth
    deceasedAt
    consentSignedByUserId
    consentSignedByUser {
      account {
        ...Account
      }
    }
    consentToTelemedicineTermsTimestamp
    consentToTelemedicineTermsByAccount {
      ...Account
    }
    parsed_address {
      fullAddress
      address1
      address2
      city
      zip
      state
    }
    additionalInfo
    consentSignedAt
    consentSigned
    avatarId
    preferredPronoun
    preferredPronounType
    genderAtBirth
    primaryCareStaffId
    primaryClinicAddress
    physicianName
    preferredPharmacy
    address
    hasPCP
    lastWellVisitStatus
    vaccinationStatus
    kareo_patient_id
    kareo_guid
  }
  ${AccountFragmentDoc}
`;
export const CommunicationHistoryDataFragmentDoc = gql`
  fragment CommunicationHistoryData on communication_history {
    ...CommunicationHistory
    appointment {
      ...Appointment
    }
    global_notes {
      ...GlobalNote
    }
    patient {
      ...Patient
    }
  }
  ${CommunicationHistoryFragmentDoc}
  ${AppointmentFragmentDoc}
  ${GlobalNoteFragmentDoc}
  ${PatientFragmentDoc}
`;
export const CommunicationsPageDataFragmentDoc = gql`
  fragment CommunicationsPageData on communication_history {
    id
    createdAt
    updatedAt
    direction
    status
    from
    to
    reasonForCall: metadata(path: "reasonForCall")
    callerHungUp: metadata(path: "callerHungUp")
    account {
      id
      goesByName
      firstName
      lastName
    }
    staff {
      avatarUrl
      firstName
      lastName
      staff_type {
        value
      }
    }
  }
`;
export const RecentCommunicationsPageDataFragmentDoc = gql`
  fragment RecentCommunicationsPageData on communication_history {
    id
    body
    createdAt
    direction
    from
    to
    reasonForCall: metadata(path: "reasonForCall")
    account {
      goesByName
      firstName
      lastName
    }
    staff {
      firstName
      lastName
    }
  }
`;
export const ReviewFragmentDoc = gql`
  fragment Review on reviews {
    id
    review
    createdAt
    updatedAt
    nps
    appointmentId
  }
`;
export const ClinicFragmentDoc = gql`
  fragment Clinic on clinics {
    id
    address
    clinicCode
    close
    created_at
    latitude
    locationType
    longitude
    name
    open
    parentClinicId
    phoneNumber
    status
    timeZone
    updated_at
  }
`;
export const AppointmentStaffFragmentDoc = gql`
  fragment AppointmentStaff on appointment_staff {
    id
    appointmentId
    staffId
    created_at
    updated_at
  }
`;
export const AppointmentPatientFragmentDoc = gql`
  fragment AppointmentPatient on appointment_patient {
    id
    appointment_id
    created_at
    updated_at
    patient_id
  }
`;
export const InsuranceFragmentDoc = gql`
  fragment Insurance on insurance {
    id
    accountId
    backId
    carrier
    dateOfBirth
    eligibility
    firstName
    frontId
    lastName
    memberId
    type
    updatedAt
  }
`;
export const AccountPatientFragmentDoc = gql`
  fragment AccountPatient on account_patient {
    id
    accountId
    patientId
    relationship
  }
`;
export const AppointmentSchedulePartsFragmentDoc = gql`
  fragment AppointmentScheduleParts on appointments {
    ...Appointment
    reviews {
      ...Review
    }
    clinic {
      ...Clinic
    }
    appointment_staffs {
      ...AppointmentStaff
      staff {
        ...Staff
      }
    }
    appointment_patients {
      ...AppointmentPatient
      patient {
        ...Patient
        global_notes {
          ...GlobalNote
        }
        insurances {
          ...Insurance
        }
        accounts {
          ...AccountPatient
          account {
            ...Account
            global_notes {
              ...GlobalNote
            }
          }
        }
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${ReviewFragmentDoc}
  ${ClinicFragmentDoc}
  ${AppointmentStaffFragmentDoc}
  ${StaffFragmentDoc}
  ${AppointmentPatientFragmentDoc}
  ${PatientFragmentDoc}
  ${GlobalNoteFragmentDoc}
  ${InsuranceFragmentDoc}
  ${AccountPatientFragmentDoc}
  ${AccountFragmentDoc}
`;
export const DashboardAppointmentFragmentDoc = gql`
  fragment DashboardAppointment on appointments {
    id
    endTime
    kareo_appointment_id
    reason
    safetyQuestionnaire
    startTime
    status
    visitClassification
    visitType
    appointment_staffs {
      staff {
        id
        avatarUrl
      }
    }
    appointment_patients {
      patient {
        id
        firstName
        lastName
        goesByName
        dateOfBirth
        pronunciation
        global_notes(where: { deleted: { _eq: false } }) {
          id
          note
        }
      }
    }
  }
`;
export const PatientDiscoveredFromFragmentDoc = gql`
  fragment PatientDiscoveredFrom on patients {
    discovered_froms {
      description
      type
      account {
        id
        firstName
        lastName
      }
    }
  }
`;
export const ChargeFragmentDoc = gql`
  fragment Charge on charges {
    id
    medicalAssistantId
    amount
    accountId
    appointmentId
    cardId
    clinicId
    created_at
    patientId
    stripeChargeId
    updated_at
  }
`;
export const ChargeProductFragmentDoc = gql`
  fragment ChargeProduct on charge_products {
    id
    amount
    type
    quantity
    chargeId
    created_at
    updated_at
    productId
  }
`;
export const ProductFragmentDoc = gql`
  fragment Product on products {
    id
    name
    price
    type
  }
`;
export const SymptomCheckerFragmentDoc = gql`
  fragment SymptomChecker on symptom_checker {
    id
    created_at
    phoneNumber
    session
    updated_at
    userId
  }
`;
export const AppointmentPartsFragmentDoc = gql`
  fragment AppointmentParts on appointments {
    ...Appointment
    appointment_patients {
      ...AppointmentPatient
      patient {
        ...Patient
        ...PatientDiscoveredFrom
        insurances {
          ...Insurance
        }
        accounts(order_by: { createdAt: asc }) {
          ...AccountPatient
          account {
            ...Account
          }
        }
      }
    }
    appointment_staffs {
      ...AppointmentStaff
      staff {
        ...Staff
      }
    }
    charges {
      ...Charge
      charge_products {
        ...ChargeProduct
        product {
          ...Product
        }
      }
    }
    clinic {
      ...Clinic
    }
    reviews {
      ...Review
    }
    symptom_checker {
      ...SymptomChecker
    }
  }
  ${AppointmentFragmentDoc}
  ${AppointmentPatientFragmentDoc}
  ${PatientFragmentDoc}
  ${PatientDiscoveredFromFragmentDoc}
  ${InsuranceFragmentDoc}
  ${AccountPatientFragmentDoc}
  ${AccountFragmentDoc}
  ${AppointmentStaffFragmentDoc}
  ${StaffFragmentDoc}
  ${ChargeFragmentDoc}
  ${ChargeProductFragmentDoc}
  ${ProductFragmentDoc}
  ${ClinicFragmentDoc}
  ${ReviewFragmentDoc}
  ${SymptomCheckerFragmentDoc}
`;
export const AppointmentPartsWithImagesFragmentDoc = gql`
  fragment AppointmentPartsWithImages on appointments {
    ...Appointment
    symptom_checker {
      ...SymptomChecker
    }
    reviews {
      ...Review
    }
    clinic {
      ...Clinic
    }
    appointment_staffs {
      ...AppointmentStaff
      staff {
        ...Staff
      }
    }
    appointment_patients {
      ...AppointmentPatient
      patient {
        ...Patient
        ...PatientDiscoveredFrom
        insurances {
          ...Insurance
        }
        accounts {
          ...AccountPatient
          account {
            ...Account
          }
        }
      }
    }
  }
  ${AppointmentFragmentDoc}
  ${SymptomCheckerFragmentDoc}
  ${ReviewFragmentDoc}
  ${ClinicFragmentDoc}
  ${AppointmentStaffFragmentDoc}
  ${StaffFragmentDoc}
  ${AppointmentPatientFragmentDoc}
  ${PatientFragmentDoc}
  ${PatientDiscoveredFromFragmentDoc}
  ${InsuranceFragmentDoc}
  ${AccountPatientFragmentDoc}
  ${AccountFragmentDoc}
`;
export const PatientPartsFragmentDoc = gql`
  fragment PatientParts on patients {
    ...Patient
    ...PatientDiscoveredFrom
    accounts {
      ...AccountPatient
      account {
        ...Account
        user {
          id
          auth0UserId
        }
      }
    }
  }
  ${PatientFragmentDoc}
  ${PatientDiscoveredFromFragmentDoc}
  ${AccountPatientFragmentDoc}
  ${AccountFragmentDoc}
`;
export const AccountPartsFragmentDoc = gql`
  fragment AccountParts on accounts {
    ...Account
    stripeCustomerId
    account_patients {
      ...AccountPatient
      patient {
        ...Patient
        ...PatientDiscoveredFrom
      }
    }
  }
  ${AccountFragmentDoc}
  ${AccountPatientFragmentDoc}
  ${PatientFragmentDoc}
  ${PatientDiscoveredFromFragmentDoc}
`;
export const AccountPartsWithRelatedAccountPartsFragmentDoc = gql`
  fragment AccountPartsWithRelatedAccountParts on accounts {
    ...Account
    stripeCustomerId
    account_patients {
      ...AccountPatient
      patient {
        ...Patient
        accounts {
          id
          accountId
          relationship
          account_limited {
            id
            firstName
            lastName
            goesByName
          }
        }
      }
    }
  }
  ${AccountFragmentDoc}
  ${AccountPatientFragmentDoc}
  ${PatientFragmentDoc}
`;
export const InsuranceImagesFragmentDoc = gql`
  fragment InsuranceImages on insurance {
    back {
      id
      url
    }
    front {
      id
      url
    }
  }
`;
export const FullAccountFragmentDoc = gql`
  fragment FullAccount on accounts {
    id
    consentToMedicalTermsTimestamp
    consentToTermsAndPrivacyTimestamp
    email
    firstName
    goesByName
    lastName
    phoneNumber
    postalCode
    preferredPronoun
    preferredPronounType
    photoIdBackId
    photoIdFrontId
  }
`;
export const AccountPhotoIdsFragmentDoc = gql`
  fragment AccountPhotoIds on accounts {
    photoIdBack {
      id
      url
    }
    photoIdFront {
      id
      url
    }
  }
`;
export const PatientAvatarFragmentDoc = gql`
  fragment PatientAvatar on patients {
    avatar {
      id
      url
    }
  }
`;
export const PatientConsentDocumentFragmentDoc = gql`
  fragment PatientConsentDocument on patients {
    consentDocument {
      url
      id
    }
  }
`;
export const StaffScheduleFragmentDoc = gql`
  fragment StaffSchedule on staff_schedule {
    id
    startDateTime
    endDateTime
    clinicId
    created_at
    staffId
    updated_at
    timeZone
  }
`;
export const StaffScheduleServiceFragmentDoc = gql`
  fragment StaffScheduleService on staff_schedule_service {
    id
    service
    staffScheduleId
    updated_at
    created_at
  }
`;
export const StaffServiceFragmentDoc = gql`
  fragment StaffService on staff_services {
    id
    service
    staffId
    updated_at
    created_at
  }
`;
export const AppointmentBlockFragmentDoc = gql`
  fragment AppointmentBlock on appointment_blocks {
    id
    clinicId
    endDateTime
    startDateTime
    reason
  }
`;
export const ChatSessionFragmentDoc = gql`
  fragment ChatSession on chat_sessions {
    id
    status
    updatedAt
    createdByAccountId
    createdAt
  }
`;
export const ChatMessageFragmentDoc = gql`
  fragment ChatMessage on chat_messages {
    id
    chatSessionId
    accountId
    staffId
    data
    message
    type
    createdAt
    updatedAt
    request
    response
  }
`;
export const ChatMessageFileFragmentDoc = gql`
  fragment ChatMessageFile on chat_messages_file {
    id
    chatMessageId
    createdAt
    fileId
    updatedAt
  }
`;
export const ChatNoteFragmentDoc = gql`
  fragment ChatNote on chat_notes {
    id
    chatSessionId
    createdAt
    note
    staffId
    staff {
      ...Staff
    }
    type
    updatedAt
  }
  ${StaffFragmentDoc}
`;
export const ChatSessionReadFragmentDoc = gql`
  fragment ChatSessionRead on chat_sessions_read {
    id
    accountId
    staffId
    chatSessionId
    createdAt
    lastViewed
    updatedAt
  }
`;
export const ChatSessionAccountFragmentDoc = gql`
  fragment ChatSessionAccount on chat_sessions_accounts {
    id
    accountId
    account {
      ...Account
    }
    active
    chatSessionId
    createdAt
    updatedAt
  }
  ${AccountFragmentDoc}
`;
export const ChatSessionPatientFragmentDoc = gql`
  fragment ChatSessionPatient on chat_sessions_patients {
    id
    createdAt
    chatSessionId
    updatedAt
    patientId
    patient {
      ...Patient
    }
  }
  ${PatientFragmentDoc}
`;
export const ChatSessionStaffFragmentDoc = gql`
  fragment ChatSessionStaff on chat_sessions_staff {
    id
    active
    chatSessionId
    created_at
    staffId
    updated_at
    staff {
      ...Staff
    }
  }
  ${StaffFragmentDoc}
`;
export const RoomFragmentDoc = gql`
  fragment Room on rooms {
    appointmentId
    createdAt
    clinicId
    id
    signId
    name
    staffId
    status
    updatedAt
  }
`;
export const FileFragmentDoc = gql`
  fragment File on files {
    id
    createdAt
    updatedAt
    accountId
    patientId
    documentType
    gcv
    path
    status
  }
`;
export const DocumentFragmentDoc = gql`
  fragment Document on documents {
    id
    status
    type
    version
    accountId
    appointmentId
    created_at
    fileId
    patientId
  }
`;
export const AppointmentBlockStaffFragmentDoc = gql`
  fragment AppointmentBlockStaff on appointment_blocks_staff {
    id
    staff {
      id
      avatarUrl
      firstName
      lastName
    }
  }
`;
export const PhoneNumberFragmentDoc = gql`
  fragment PhoneNumber on phone_numbers {
    id
    clinicId
    description
    extension
    name
    phoneNumber
    staffId
  }
`;
export const TaskWatchFragmentDoc = gql`
  fragment TaskWatch on tasks_watch {
    id
    staffId
    taskId
  }
`;
export const TaskFragmentDoc = gql`
  fragment Task on tasks {
    id
    createdAt
    updatedAt
    title
    note
    priority
    dueDateTime
    createdBy
    active
    status
    subType
    type
    completed
    readCount
    responsibleCount
  }
`;
export const TaskReadFragmentDoc = gql`
  fragment TaskRead on tasks_read {
    id
    createdAt
    updatedAt
    taskId
    staffId
  }
`;
export const TaskNotificationFragmentDoc = gql`
  fragment TaskNotification on tasks_notifications {
    id
    createdAt
    updatedAt
    accountId
    active
    appointmentId
    clinicId
    patientId
    staffId
    staffType
    taskId
    taskGroupId
  }
`;
export const TaskAssignmentFragmentDoc = gql`
  fragment TaskAssignment on tasks_assignments {
    id
    createdAt
    updatedAt
    assignedTo
    assignedBy
    taskId
    active
  }
`;
export const AddAccountDocument = gql`
  mutation AddAccount(
    $patientId: String
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $relationship: String
    $email: String
    $preferredPronounType: String
    $preferredPronoun: String
  ) {
    AddAccount(
      patientId: $patientId
      relationship: $relationship
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      email: $email
      preferredPronounType: $preferredPronounType
      preferredPronoun: $preferredPronoun
    ) {
      id
    }
  }
`;
export type AddAccountMutationFn = Apollo.MutationFunction<
  AddAccountMutation,
  AddAccountMutationVariables
>;

/**
 * __useAddAccountMutation__
 *
 * To run a mutation, you first call `useAddAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountMutation, { data, loading, error }] = useAddAccountMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *      relationship: // value for 'relationship'
 *      email: // value for 'email'
 *      preferredPronounType: // value for 'preferredPronounType'
 *      preferredPronoun: // value for 'preferredPronoun'
 *   },
 * });
 */
export function useAddAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAccountMutation,
    AddAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAccountMutation, AddAccountMutationVariables>(
    AddAccountDocument,
    options,
  );
}
export type AddAccountMutationHookResult = ReturnType<
  typeof useAddAccountMutation
>;
export type AddAccountMutationResult =
  Apollo.MutationResult<AddAccountMutation>;
export type AddAccountMutationOptions = Apollo.BaseMutationOptions<
  AddAccountMutation,
  AddAccountMutationVariables
>;
export const UpdateAccountDocument = gql`
  mutation UpdateAccount(
    $set: accounts_set_input!
    $where: accounts_bool_exp!
  ) {
    update_accounts(_set: $set, where: $where) {
      returning {
        ...Account
      }
    }
  }
  ${AccountFragmentDoc}
`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
  >(UpdateAccountDocument, options);
}
export type UpdateAccountMutationHookResult = ReturnType<
  typeof useUpdateAccountMutation
>;
export type UpdateAccountMutationResult =
  Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export const UpdateAccountByPkDocument = gql`
  mutation UpdateAccountByPk($id: uuid!, $set: accounts_set_input!) {
    update_accounts_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...Account
    }
  }
  ${AccountFragmentDoc}
`;
export type UpdateAccountByPkMutationFn = Apollo.MutationFunction<
  UpdateAccountByPkMutation,
  UpdateAccountByPkMutationVariables
>;

/**
 * __useUpdateAccountByPkMutation__
 *
 * To run a mutation, you first call `useUpdateAccountByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountByPkMutation, { data, loading, error }] = useUpdateAccountByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateAccountByPkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountByPkMutation,
    UpdateAccountByPkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccountByPkMutation,
    UpdateAccountByPkMutationVariables
  >(UpdateAccountByPkDocument, options);
}
export type UpdateAccountByPkMutationHookResult = ReturnType<
  typeof useUpdateAccountByPkMutation
>;
export type UpdateAccountByPkMutationResult =
  Apollo.MutationResult<UpdateAccountByPkMutation>;
export type UpdateAccountByPkMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountByPkMutation,
  UpdateAccountByPkMutationVariables
>;
export const LoadAccountDocument = gql`
  query LoadAccount($accountId: uuid!) {
    accounts_by_pk(id: $accountId) {
      ...Account
    }
  }
  ${AccountFragmentDoc}
`;

/**
 * __useLoadAccountQuery__
 *
 * To run a query within a React component, call `useLoadAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useLoadAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAccountQuery,
    LoadAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadAccountQuery, LoadAccountQueryVariables>(
    LoadAccountDocument,
    options,
  );
}
export function useLoadAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAccountQuery,
    LoadAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadAccountQuery, LoadAccountQueryVariables>(
    LoadAccountDocument,
    options,
  );
}
export type LoadAccountQueryHookResult = ReturnType<typeof useLoadAccountQuery>;
export type LoadAccountLazyQueryHookResult = ReturnType<
  typeof useLoadAccountLazyQuery
>;
export type LoadAccountQueryResult = Apollo.QueryResult<
  LoadAccountQuery,
  LoadAccountQueryVariables
>;
export const FindAccountDocument = gql`
  query FindAccount($where: accounts_bool_exp!) {
    accounts(where: $where) {
      ...Account
    }
  }
  ${AccountFragmentDoc}
`;

/**
 * __useFindAccountQuery__
 *
 * To run a query within a React component, call `useFindAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAccountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAccountQuery,
    FindAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindAccountQuery, FindAccountQueryVariables>(
    FindAccountDocument,
    options,
  );
}
export function useFindAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAccountQuery,
    FindAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindAccountQuery, FindAccountQueryVariables>(
    FindAccountDocument,
    options,
  );
}
export type FindAccountQueryHookResult = ReturnType<typeof useFindAccountQuery>;
export type FindAccountLazyQueryHookResult = ReturnType<
  typeof useFindAccountLazyQuery
>;
export type FindAccountQueryResult = Apollo.QueryResult<
  FindAccountQuery,
  FindAccountQueryVariables
>;
export const GetAccountByPkDocument = gql`
  query GetAccountByPk($accountId: uuid!) {
    accounts_by_pk(id: $accountId) {
      ...AccountParts
    }
  }
  ${AccountPartsFragmentDoc}
`;

/**
 * __useGetAccountByPkQuery__
 *
 * To run a query within a React component, call `useGetAccountByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountByPkQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountByPkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAccountByPkQuery,
    GetAccountByPkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountByPkQuery, GetAccountByPkQueryVariables>(
    GetAccountByPkDocument,
    options,
  );
}
export function useGetAccountByPkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountByPkQuery,
    GetAccountByPkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountByPkQuery, GetAccountByPkQueryVariables>(
    GetAccountByPkDocument,
    options,
  );
}
export type GetAccountByPkQueryHookResult = ReturnType<
  typeof useGetAccountByPkQuery
>;
export type GetAccountByPkLazyQueryHookResult = ReturnType<
  typeof useGetAccountByPkLazyQuery
>;
export type GetAccountByPkQueryResult = Apollo.QueryResult<
  GetAccountByPkQuery,
  GetAccountByPkQueryVariables
>;
export const GetAccountByPkWithRelatedDocument = gql`
  query GetAccountByPkWithRelated($accountId: uuid!) {
    accounts_by_pk(id: $accountId) {
      ...AccountPartsWithRelatedAccountParts
    }
  }
  ${AccountPartsWithRelatedAccountPartsFragmentDoc}
`;

/**
 * __useGetAccountByPkWithRelatedQuery__
 *
 * To run a query within a React component, call `useGetAccountByPkWithRelatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountByPkWithRelatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountByPkWithRelatedQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountByPkWithRelatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAccountByPkWithRelatedQuery,
    GetAccountByPkWithRelatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountByPkWithRelatedQuery,
    GetAccountByPkWithRelatedQueryVariables
  >(GetAccountByPkWithRelatedDocument, options);
}
export function useGetAccountByPkWithRelatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountByPkWithRelatedQuery,
    GetAccountByPkWithRelatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountByPkWithRelatedQuery,
    GetAccountByPkWithRelatedQueryVariables
  >(GetAccountByPkWithRelatedDocument, options);
}
export type GetAccountByPkWithRelatedQueryHookResult = ReturnType<
  typeof useGetAccountByPkWithRelatedQuery
>;
export type GetAccountByPkWithRelatedLazyQueryHookResult = ReturnType<
  typeof useGetAccountByPkWithRelatedLazyQuery
>;
export type GetAccountByPkWithRelatedQueryResult = Apollo.QueryResult<
  GetAccountByPkWithRelatedQuery,
  GetAccountByPkWithRelatedQueryVariables
>;
export const GetAccountChargesDocument = gql`
  query GetAccountCharges($where: charges_bool_exp!) {
    charges(where: $where) {
      ...Charge
      charge_products {
        ...ChargeProduct
        product {
          ...Product
        }
      }
    }
  }
  ${ChargeFragmentDoc}
  ${ChargeProductFragmentDoc}
  ${ProductFragmentDoc}
`;

/**
 * __useGetAccountChargesQuery__
 *
 * To run a query within a React component, call `useGetAccountChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountChargesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAccountChargesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAccountChargesQuery,
    GetAccountChargesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountChargesQuery,
    GetAccountChargesQueryVariables
  >(GetAccountChargesDocument, options);
}
export function useGetAccountChargesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountChargesQuery,
    GetAccountChargesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountChargesQuery,
    GetAccountChargesQueryVariables
  >(GetAccountChargesDocument, options);
}
export type GetAccountChargesQueryHookResult = ReturnType<
  typeof useGetAccountChargesQuery
>;
export type GetAccountChargesLazyQueryHookResult = ReturnType<
  typeof useGetAccountChargesLazyQuery
>;
export type GetAccountChargesQueryResult = Apollo.QueryResult<
  GetAccountChargesQuery,
  GetAccountChargesQueryVariables
>;
export const UpdateAccountPhoneDocument = gql`
  mutation UpdateAccountPhone($accountId: String!, $phoneNumber: String!) {
    UpdateAccountPhone(accountId: $accountId, phoneNumber: $phoneNumber) {
      success
    }
  }
`;
export type UpdateAccountPhoneMutationFn = Apollo.MutationFunction<
  UpdateAccountPhoneMutation,
  UpdateAccountPhoneMutationVariables
>;

/**
 * __useUpdateAccountPhoneMutation__
 *
 * To run a mutation, you first call `useUpdateAccountPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPhoneMutation, { data, loading, error }] = useUpdateAccountPhoneMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdateAccountPhoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountPhoneMutation,
    UpdateAccountPhoneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccountPhoneMutation,
    UpdateAccountPhoneMutationVariables
  >(UpdateAccountPhoneDocument, options);
}
export type UpdateAccountPhoneMutationHookResult = ReturnType<
  typeof useUpdateAccountPhoneMutation
>;
export type UpdateAccountPhoneMutationResult =
  Apollo.MutationResult<UpdateAccountPhoneMutation>;
export type UpdateAccountPhoneMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountPhoneMutation,
  UpdateAccountPhoneMutationVariables
>;
export const LoadGlobalNotesDocument = gql`
  query LoadGlobalNotes(
    $where: global_notes_bool_exp = {}
    $orderBy: [global_notes_order_by!]
  ) {
    global_notes(where: $where, order_by: $orderBy) {
      ...GlobalNote
    }
  }
  ${GlobalNoteFragmentDoc}
`;

/**
 * __useLoadGlobalNotesQuery__
 *
 * To run a query within a React component, call `useLoadGlobalNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadGlobalNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadGlobalNotesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useLoadGlobalNotesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadGlobalNotesQuery,
    LoadGlobalNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadGlobalNotesQuery, LoadGlobalNotesQueryVariables>(
    LoadGlobalNotesDocument,
    options,
  );
}
export function useLoadGlobalNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadGlobalNotesQuery,
    LoadGlobalNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadGlobalNotesQuery,
    LoadGlobalNotesQueryVariables
  >(LoadGlobalNotesDocument, options);
}
export type LoadGlobalNotesQueryHookResult = ReturnType<
  typeof useLoadGlobalNotesQuery
>;
export type LoadGlobalNotesLazyQueryHookResult = ReturnType<
  typeof useLoadGlobalNotesLazyQuery
>;
export type LoadGlobalNotesQueryResult = Apollo.QueryResult<
  LoadGlobalNotesQuery,
  LoadGlobalNotesQueryVariables
>;
export const WatchGlobalNotesDocument = gql`
  subscription WatchGlobalNotes(
    $where: global_notes_bool_exp = {}
    $orderBy: [global_notes_order_by!]
  ) {
    global_notes(where: $where, order_by: $orderBy) {
      ...GlobalNote
    }
  }
  ${GlobalNoteFragmentDoc}
`;

/**
 * __useWatchGlobalNotesSubscription__
 *
 * To run a query within a React component, call `useWatchGlobalNotesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchGlobalNotesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchGlobalNotesSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useWatchGlobalNotesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    WatchGlobalNotesSubscription,
    WatchGlobalNotesSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchGlobalNotesSubscription,
    WatchGlobalNotesSubscriptionVariables
  >(WatchGlobalNotesDocument, options);
}
export type WatchGlobalNotesSubscriptionHookResult = ReturnType<
  typeof useWatchGlobalNotesSubscription
>;
export type WatchGlobalNotesSubscriptionResult =
  Apollo.SubscriptionResult<WatchGlobalNotesSubscription>;
export const AddGlobalNoteDocument = gql`
  mutation AddGlobalNote($globalNote: global_notes_insert_input!) {
    insert_global_notes_one(object: $globalNote) {
      ...GlobalNote
    }
  }
  ${GlobalNoteFragmentDoc}
`;
export type AddGlobalNoteMutationFn = Apollo.MutationFunction<
  AddGlobalNoteMutation,
  AddGlobalNoteMutationVariables
>;

/**
 * __useAddGlobalNoteMutation__
 *
 * To run a mutation, you first call `useAddGlobalNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGlobalNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGlobalNoteMutation, { data, loading, error }] = useAddGlobalNoteMutation({
 *   variables: {
 *      globalNote: // value for 'globalNote'
 *   },
 * });
 */
export function useAddGlobalNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGlobalNoteMutation,
    AddGlobalNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGlobalNoteMutation,
    AddGlobalNoteMutationVariables
  >(AddGlobalNoteDocument, options);
}
export type AddGlobalNoteMutationHookResult = ReturnType<
  typeof useAddGlobalNoteMutation
>;
export type AddGlobalNoteMutationResult =
  Apollo.MutationResult<AddGlobalNoteMutation>;
export type AddGlobalNoteMutationOptions = Apollo.BaseMutationOptions<
  AddGlobalNoteMutation,
  AddGlobalNoteMutationVariables
>;
export const DeleteGlobalNoteDocument = gql`
  mutation DeleteGlobalNote($id: uuid!) {
    update_global_notes_by_pk(
      pk_columns: { id: $id }
      _set: { deleted: true }
    ) {
      id
    }
  }
`;
export type DeleteGlobalNoteMutationFn = Apollo.MutationFunction<
  DeleteGlobalNoteMutation,
  DeleteGlobalNoteMutationVariables
>;

/**
 * __useDeleteGlobalNoteMutation__
 *
 * To run a mutation, you first call `useDeleteGlobalNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGlobalNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGlobalNoteMutation, { data, loading, error }] = useDeleteGlobalNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGlobalNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGlobalNoteMutation,
    DeleteGlobalNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGlobalNoteMutation,
    DeleteGlobalNoteMutationVariables
  >(DeleteGlobalNoteDocument, options);
}
export type DeleteGlobalNoteMutationHookResult = ReturnType<
  typeof useDeleteGlobalNoteMutation
>;
export type DeleteGlobalNoteMutationResult =
  Apollo.MutationResult<DeleteGlobalNoteMutation>;
export type DeleteGlobalNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteGlobalNoteMutation,
  DeleteGlobalNoteMutationVariables
>;
export const AppointmentScheduleDocument = gql`
  query AppointmentSchedule($where: appointments_bool_exp!) {
    appointments(where: $where, distinct_on: id) {
      ...AppointmentScheduleParts
    }
  }
  ${AppointmentSchedulePartsFragmentDoc}
`;

/**
 * __useAppointmentScheduleQuery__
 *
 * To run a query within a React component, call `useAppointmentScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentScheduleQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAppointmentScheduleQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentScheduleQuery,
    AppointmentScheduleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AppointmentScheduleQuery,
    AppointmentScheduleQueryVariables
  >(AppointmentScheduleDocument, options);
}
export function useAppointmentScheduleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentScheduleQuery,
    AppointmentScheduleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppointmentScheduleQuery,
    AppointmentScheduleQueryVariables
  >(AppointmentScheduleDocument, options);
}
export type AppointmentScheduleQueryHookResult = ReturnType<
  typeof useAppointmentScheduleQuery
>;
export type AppointmentScheduleLazyQueryHookResult = ReturnType<
  typeof useAppointmentScheduleLazyQuery
>;
export type AppointmentScheduleQueryResult = Apollo.QueryResult<
  AppointmentScheduleQuery,
  AppointmentScheduleQueryVariables
>;
export const ScheduleDocument = gql`
  subscription Schedule($where: appointments_bool_exp!) {
    appointments(where: $where) {
      ...AppointmentScheduleParts
    }
  }
  ${AppointmentSchedulePartsFragmentDoc}
`;

/**
 * __useScheduleSubscription__
 *
 * To run a query within a React component, call `useScheduleSubscription` and pass it any options that fit your needs.
 * When your component renders, `useScheduleSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useScheduleSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ScheduleSubscription,
    ScheduleSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ScheduleSubscription,
    ScheduleSubscriptionVariables
  >(ScheduleDocument, options);
}
export type ScheduleSubscriptionHookResult = ReturnType<
  typeof useScheduleSubscription
>;
export type ScheduleSubscriptionResult =
  Apollo.SubscriptionResult<ScheduleSubscription>;
export const NewScheduleDocument = gql`
  subscription NewSchedule(
    $clinicCode: String!
    $startTime: timestamp!
    $endTime: timestamp!
  ) {
    appointments(
      where: {
        clinic: { clinicCode: { _eq: $clinicCode } }
        _and: [
          { startTime: { _gte: $startTime } }
          { startTime: { _lte: $endTime } }
        ]
      }
      distinct_on: id
    ) {
      ...DashboardAppointment
    }
  }
  ${DashboardAppointmentFragmentDoc}
`;

/**
 * __useNewScheduleSubscription__
 *
 * To run a query within a React component, call `useNewScheduleSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewScheduleSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewScheduleSubscription({
 *   variables: {
 *      clinicCode: // value for 'clinicCode'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useNewScheduleSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NewScheduleSubscription,
    NewScheduleSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewScheduleSubscription,
    NewScheduleSubscriptionVariables
  >(NewScheduleDocument, options);
}
export type NewScheduleSubscriptionHookResult = ReturnType<
  typeof useNewScheduleSubscription
>;
export type NewScheduleSubscriptionResult =
  Apollo.SubscriptionResult<NewScheduleSubscription>;
export const AppointmentCountsDocument = gql`
  query AppointmentCounts($where: appointments_bool_exp!) {
    appointments_aggregate(where: $where) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;

/**
 * __useAppointmentCountsQuery__
 *
 * To run a query within a React component, call `useAppointmentCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentCountsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAppointmentCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentCountsQuery,
    AppointmentCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AppointmentCountsQuery,
    AppointmentCountsQueryVariables
  >(AppointmentCountsDocument, options);
}
export function useAppointmentCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentCountsQuery,
    AppointmentCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppointmentCountsQuery,
    AppointmentCountsQueryVariables
  >(AppointmentCountsDocument, options);
}
export type AppointmentCountsQueryHookResult = ReturnType<
  typeof useAppointmentCountsQuery
>;
export type AppointmentCountsLazyQueryHookResult = ReturnType<
  typeof useAppointmentCountsLazyQuery
>;
export type AppointmentCountsQueryResult = Apollo.QueryResult<
  AppointmentCountsQuery,
  AppointmentCountsQueryVariables
>;
export const AppointmentCountDocument = gql`
  subscription AppointmentCount($where: appointments_bool_exp!) {
    appointments_aggregate(where: $where) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;

/**
 * __useAppointmentCountSubscription__
 *
 * To run a query within a React component, call `useAppointmentCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAppointmentCountSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    AppointmentCountSubscription,
    AppointmentCountSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    AppointmentCountSubscription,
    AppointmentCountSubscriptionVariables
  >(AppointmentCountDocument, options);
}
export type AppointmentCountSubscriptionHookResult = ReturnType<
  typeof useAppointmentCountSubscription
>;
export type AppointmentCountSubscriptionResult =
  Apollo.SubscriptionResult<AppointmentCountSubscription>;
export const AppointmentCountsForDaysDocument = gql`
  query AppointmentCountsForDays($where: appointment_counts_bool_exp!) {
    appointment_counts(where: $where) {
      count
      date
      clinicId
    }
  }
`;

/**
 * __useAppointmentCountsForDaysQuery__
 *
 * To run a query within a React component, call `useAppointmentCountsForDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentCountsForDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentCountsForDaysQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAppointmentCountsForDaysQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentCountsForDaysQuery,
    AppointmentCountsForDaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AppointmentCountsForDaysQuery,
    AppointmentCountsForDaysQueryVariables
  >(AppointmentCountsForDaysDocument, options);
}
export function useAppointmentCountsForDaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentCountsForDaysQuery,
    AppointmentCountsForDaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppointmentCountsForDaysQuery,
    AppointmentCountsForDaysQueryVariables
  >(AppointmentCountsForDaysDocument, options);
}
export type AppointmentCountsForDaysQueryHookResult = ReturnType<
  typeof useAppointmentCountsForDaysQuery
>;
export type AppointmentCountsForDaysLazyQueryHookResult = ReturnType<
  typeof useAppointmentCountsForDaysLazyQuery
>;
export type AppointmentCountsForDaysQueryResult = Apollo.QueryResult<
  AppointmentCountsForDaysQuery,
  AppointmentCountsForDaysQueryVariables
>;
export const CreateQuickAppointmentDocument = gql`
  mutation CreateQuickAppointment($appointment: appointments_insert_input!) {
    insert_appointments(objects: [$appointment]) {
      returning {
        ...AppointmentPartsWithImages
      }
    }
  }
  ${AppointmentPartsWithImagesFragmentDoc}
`;
export type CreateQuickAppointmentMutationFn = Apollo.MutationFunction<
  CreateQuickAppointmentMutation,
  CreateQuickAppointmentMutationVariables
>;

/**
 * __useCreateQuickAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateQuickAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuickAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuickAppointmentMutation, { data, loading, error }] = useCreateQuickAppointmentMutation({
 *   variables: {
 *      appointment: // value for 'appointment'
 *   },
 * });
 */
export function useCreateQuickAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQuickAppointmentMutation,
    CreateQuickAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateQuickAppointmentMutation,
    CreateQuickAppointmentMutationVariables
  >(CreateQuickAppointmentDocument, options);
}
export type CreateQuickAppointmentMutationHookResult = ReturnType<
  typeof useCreateQuickAppointmentMutation
>;
export type CreateQuickAppointmentMutationResult =
  Apollo.MutationResult<CreateQuickAppointmentMutation>;
export type CreateQuickAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CreateQuickAppointmentMutation,
  CreateQuickAppointmentMutationVariables
>;
export const LoadAppointmentDataDocument = gql`
  query LoadAppointmentData($id: uuid!) {
    appointments_by_pk(id: $id) {
      ...AppointmentPartsWithImages
    }
  }
  ${AppointmentPartsWithImagesFragmentDoc}
`;

/**
 * __useLoadAppointmentDataQuery__
 *
 * To run a query within a React component, call `useLoadAppointmentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAppointmentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAppointmentDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadAppointmentDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAppointmentDataQuery,
    LoadAppointmentDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAppointmentDataQuery,
    LoadAppointmentDataQueryVariables
  >(LoadAppointmentDataDocument, options);
}
export function useLoadAppointmentDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAppointmentDataQuery,
    LoadAppointmentDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAppointmentDataQuery,
    LoadAppointmentDataQueryVariables
  >(LoadAppointmentDataDocument, options);
}
export type LoadAppointmentDataQueryHookResult = ReturnType<
  typeof useLoadAppointmentDataQuery
>;
export type LoadAppointmentDataLazyQueryHookResult = ReturnType<
  typeof useLoadAppointmentDataLazyQuery
>;
export type LoadAppointmentDataQueryResult = Apollo.QueryResult<
  LoadAppointmentDataQuery,
  LoadAppointmentDataQueryVariables
>;
export const AppointmentDataDocument = gql`
  subscription AppointmentData($id: uuid!) {
    appointments_by_pk(id: $id) {
      ...AppointmentParts
    }
  }
  ${AppointmentPartsFragmentDoc}
`;

/**
 * __useAppointmentDataSubscription__
 *
 * To run a query within a React component, call `useAppointmentDataSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentDataSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentDataSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppointmentDataSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    AppointmentDataSubscription,
    AppointmentDataSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    AppointmentDataSubscription,
    AppointmentDataSubscriptionVariables
  >(AppointmentDataDocument, options);
}
export type AppointmentDataSubscriptionHookResult = ReturnType<
  typeof useAppointmentDataSubscription
>;
export type AppointmentDataSubscriptionResult =
  Apollo.SubscriptionResult<AppointmentDataSubscription>;
export const UpdateAppointmentDocument = gql`
  mutation UpdateAppointment($id: uuid!, $set: appointments_set_input) {
    update_appointments(_set: $set, where: { id: { _eq: $id } }) {
      returning {
        ...AppointmentPartsWithImages
      }
    }
  }
  ${AppointmentPartsWithImagesFragmentDoc}
`;
export type UpdateAppointmentMutationFn = Apollo.MutationFunction<
  UpdateAppointmentMutation,
  UpdateAppointmentMutationVariables
>;

/**
 * __useUpdateAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppointmentMutation,
    UpdateAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppointmentMutation,
    UpdateAppointmentMutationVariables
  >(UpdateAppointmentDocument, options);
}
export type UpdateAppointmentMutationHookResult = ReturnType<
  typeof useUpdateAppointmentMutation
>;
export type UpdateAppointmentMutationResult =
  Apollo.MutationResult<UpdateAppointmentMutation>;
export type UpdateAppointmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppointmentMutation,
  UpdateAppointmentMutationVariables
>;
export const UpdateAppointmentStatusDocument = gql`
  mutation UpdateAppointmentStatus(
    $id: uuid!
    $status: String!
    $notification: Boolean!
  ) {
    UpdateAppointmentStatus(
      id: $id
      status: $status
      notification: $notification
    ) {
      id
    }
  }
`;
export type UpdateAppointmentStatusMutationFn = Apollo.MutationFunction<
  UpdateAppointmentStatusMutation,
  UpdateAppointmentStatusMutationVariables
>;

/**
 * __useUpdateAppointmentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentStatusMutation, { data, loading, error }] = useUpdateAppointmentStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      notification: // value for 'notification'
 *   },
 * });
 */
export function useUpdateAppointmentStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppointmentStatusMutation,
    UpdateAppointmentStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppointmentStatusMutation,
    UpdateAppointmentStatusMutationVariables
  >(UpdateAppointmentStatusDocument, options);
}
export type UpdateAppointmentStatusMutationHookResult = ReturnType<
  typeof useUpdateAppointmentStatusMutation
>;
export type UpdateAppointmentStatusMutationResult =
  Apollo.MutationResult<UpdateAppointmentStatusMutation>;
export type UpdateAppointmentStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppointmentStatusMutation,
  UpdateAppointmentStatusMutationVariables
>;
export const UpdateAppointmentTimeDocument = gql`
  mutation UpdateAppointmentTime(
    $id: uuid!
    $clinicId: String
    $reason: String
    $startTime: String!
    $endTime: String!
    $notes: String
    $visitType: String
    $visitClassification: String
    $notification: Boolean!
  ) {
    UpdateAppointmentTime(
      id: $id
      clinicId: $clinicId
      reason: $reason
      startTime: $startTime
      endTime: $endTime
      notes: $notes
      visitType: $visitType
      visitClassification: $visitClassification
      notification: $notification
    ) {
      id
    }
  }
`;
export type UpdateAppointmentTimeMutationFn = Apollo.MutationFunction<
  UpdateAppointmentTimeMutation,
  UpdateAppointmentTimeMutationVariables
>;

/**
 * __useUpdateAppointmentTimeMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentTimeMutation, { data, loading, error }] = useUpdateAppointmentTimeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clinicId: // value for 'clinicId'
 *      reason: // value for 'reason'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      notes: // value for 'notes'
 *      visitType: // value for 'visitType'
 *      visitClassification: // value for 'visitClassification'
 *      notification: // value for 'notification'
 *   },
 * });
 */
export function useUpdateAppointmentTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppointmentTimeMutation,
    UpdateAppointmentTimeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppointmentTimeMutation,
    UpdateAppointmentTimeMutationVariables
  >(UpdateAppointmentTimeDocument, options);
}
export type UpdateAppointmentTimeMutationHookResult = ReturnType<
  typeof useUpdateAppointmentTimeMutation
>;
export type UpdateAppointmentTimeMutationResult =
  Apollo.MutationResult<UpdateAppointmentTimeMutation>;
export type UpdateAppointmentTimeMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppointmentTimeMutation,
  UpdateAppointmentTimeMutationVariables
>;
export const CancelAppointmentDocument = gql`
  mutation CancelAppointment($id: uuid!) {
    update_appointments_by_pk(
      _set: { status: CANCELED }
      pk_columns: { id: $id }
    ) {
      id
      status
    }
  }
`;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >(CancelAppointmentDocument, options);
}
export type CancelAppointmentMutationHookResult = ReturnType<
  typeof useCancelAppointmentMutation
>;
export type CancelAppointmentMutationResult =
  Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;
export const GetAppointmentPhoneNumberDocument = gql`
  query GetAppointmentPhoneNumber($appointmentId: uuid!) {
    appointments_by_pk(id: $appointmentId) {
      account {
        account {
          id
          phoneNumber
        }
      }
    }
  }
`;

/**
 * __useGetAppointmentPhoneNumberQuery__
 *
 * To run a query within a React component, call `useGetAppointmentPhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentPhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentPhoneNumberQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useGetAppointmentPhoneNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAppointmentPhoneNumberQuery,
    GetAppointmentPhoneNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAppointmentPhoneNumberQuery,
    GetAppointmentPhoneNumberQueryVariables
  >(GetAppointmentPhoneNumberDocument, options);
}
export function useGetAppointmentPhoneNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentPhoneNumberQuery,
    GetAppointmentPhoneNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAppointmentPhoneNumberQuery,
    GetAppointmentPhoneNumberQueryVariables
  >(GetAppointmentPhoneNumberDocument, options);
}
export type GetAppointmentPhoneNumberQueryHookResult = ReturnType<
  typeof useGetAppointmentPhoneNumberQuery
>;
export type GetAppointmentPhoneNumberLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentPhoneNumberLazyQuery
>;
export type GetAppointmentPhoneNumberQueryResult = Apollo.QueryResult<
  GetAppointmentPhoneNumberQuery,
  GetAppointmentPhoneNumberQueryVariables
>;
export const LoadAllVisitClassificationsDocument = gql`
  query LoadAllVisitClassifications {
    visit_classifications {
      value
      description
    }
  }
`;

/**
 * __useLoadAllVisitClassificationsQuery__
 *
 * To run a query within a React component, call `useLoadAllVisitClassificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAllVisitClassificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAllVisitClassificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadAllVisitClassificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadAllVisitClassificationsQuery,
    LoadAllVisitClassificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAllVisitClassificationsQuery,
    LoadAllVisitClassificationsQueryVariables
  >(LoadAllVisitClassificationsDocument, options);
}
export function useLoadAllVisitClassificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAllVisitClassificationsQuery,
    LoadAllVisitClassificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAllVisitClassificationsQuery,
    LoadAllVisitClassificationsQueryVariables
  >(LoadAllVisitClassificationsDocument, options);
}
export type LoadAllVisitClassificationsQueryHookResult = ReturnType<
  typeof useLoadAllVisitClassificationsQuery
>;
export type LoadAllVisitClassificationsLazyQueryHookResult = ReturnType<
  typeof useLoadAllVisitClassificationsLazyQuery
>;
export type LoadAllVisitClassificationsQueryResult = Apollo.QueryResult<
  LoadAllVisitClassificationsQuery,
  LoadAllVisitClassificationsQueryVariables
>;
export const LoadVisitTypesAndClassificationsDocument = gql`
  query LoadVisitTypesAndClassifications {
    visit_classifications {
      value
      description
    }
    visit_types {
      value
      description
    }
  }
`;

/**
 * __useLoadVisitTypesAndClassificationsQuery__
 *
 * To run a query within a React component, call `useLoadVisitTypesAndClassificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadVisitTypesAndClassificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadVisitTypesAndClassificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadVisitTypesAndClassificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadVisitTypesAndClassificationsQuery,
    LoadVisitTypesAndClassificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadVisitTypesAndClassificationsQuery,
    LoadVisitTypesAndClassificationsQueryVariables
  >(LoadVisitTypesAndClassificationsDocument, options);
}
export function useLoadVisitTypesAndClassificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadVisitTypesAndClassificationsQuery,
    LoadVisitTypesAndClassificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadVisitTypesAndClassificationsQuery,
    LoadVisitTypesAndClassificationsQueryVariables
  >(LoadVisitTypesAndClassificationsDocument, options);
}
export type LoadVisitTypesAndClassificationsQueryHookResult = ReturnType<
  typeof useLoadVisitTypesAndClassificationsQuery
>;
export type LoadVisitTypesAndClassificationsLazyQueryHookResult = ReturnType<
  typeof useLoadVisitTypesAndClassificationsLazyQuery
>;
export type LoadVisitTypesAndClassificationsQueryResult = Apollo.QueryResult<
  LoadVisitTypesAndClassificationsQuery,
  LoadVisitTypesAndClassificationsQueryVariables
>;
export const CreateAccountPatientDocument = gql`
  mutation CreateAccountPatient(
    $accountFirstName: String!
    $accountId: String
    $accountLastName: String!
    $phoneNumber: String!
    $relationship: String!
    $patientFirstName: String!
    $patientId: String
    $patientLastName: String!
    $dateOfBirth: String!
    $email: String
  ) {
    CreateAccountPatient(
      accountFirstName: $accountFirstName
      accountId: $accountId
      accountLastName: $accountLastName
      dateOfBirth: $dateOfBirth
      email: $email
      patientFirstName: $patientFirstName
      patientId: $patientId
      patientLastName: $patientLastName
      phone_number: $phoneNumber
      relationship: $relationship
    ) {
      accountId
      patientId
    }
  }
`;
export type CreateAccountPatientMutationFn = Apollo.MutationFunction<
  CreateAccountPatientMutation,
  CreateAccountPatientMutationVariables
>;

/**
 * __useCreateAccountPatientMutation__
 *
 * To run a mutation, you first call `useCreateAccountPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountPatientMutation, { data, loading, error }] = useCreateAccountPatientMutation({
 *   variables: {
 *      accountFirstName: // value for 'accountFirstName'
 *      accountId: // value for 'accountId'
 *      accountLastName: // value for 'accountLastName'
 *      phoneNumber: // value for 'phoneNumber'
 *      relationship: // value for 'relationship'
 *      patientFirstName: // value for 'patientFirstName'
 *      patientId: // value for 'patientId'
 *      patientLastName: // value for 'patientLastName'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateAccountPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountPatientMutation,
    CreateAccountPatientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAccountPatientMutation,
    CreateAccountPatientMutationVariables
  >(CreateAccountPatientDocument, options);
}
export type CreateAccountPatientMutationHookResult = ReturnType<
  typeof useCreateAccountPatientMutation
>;
export type CreateAccountPatientMutationResult =
  Apollo.MutationResult<CreateAccountPatientMutation>;
export type CreateAccountPatientMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountPatientMutation,
  CreateAccountPatientMutationVariables
>;
export const ScheduleAppointmentDocument = gql`
  mutation ScheduleAppointment(
    $accountId: String!
    $clinicId: String!
    $patientId: String!
    $reason: String!
    $sqlTime: String!
    $type: String
    $visitType: String
    $visitClassification: String
    $staffId: String
    $communicationHistoryId: String
    $address: String
    $timeZone: String
    $email: String
    $notes: String
    $campaign: String
  ) {
    ScheduleAppointment(
      accountId: $accountId
      clinicId: $clinicId
      patientId: $patientId
      reason: $reason
      sqlTime: $sqlTime
      type: $type
      visitType: $visitType
      visitClassification: $visitClassification
      staffId: $staffId
      communicationHistoryId: $communicationHistoryId
      address: $address
      email: $email
      timeZone: $timeZone
      notes: $notes
      campaign: $campaign
    ) {
      id
    }
  }
`;
export type ScheduleAppointmentMutationFn = Apollo.MutationFunction<
  ScheduleAppointmentMutation,
  ScheduleAppointmentMutationVariables
>;

/**
 * __useScheduleAppointmentMutation__
 *
 * To run a mutation, you first call `useScheduleAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleAppointmentMutation, { data, loading, error }] = useScheduleAppointmentMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      clinicId: // value for 'clinicId'
 *      patientId: // value for 'patientId'
 *      reason: // value for 'reason'
 *      sqlTime: // value for 'sqlTime'
 *      type: // value for 'type'
 *      visitType: // value for 'visitType'
 *      visitClassification: // value for 'visitClassification'
 *      staffId: // value for 'staffId'
 *      communicationHistoryId: // value for 'communicationHistoryId'
 *      address: // value for 'address'
 *      timeZone: // value for 'timeZone'
 *      email: // value for 'email'
 *      notes: // value for 'notes'
 *      campaign: // value for 'campaign'
 *   },
 * });
 */
export function useScheduleAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ScheduleAppointmentMutation,
    ScheduleAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ScheduleAppointmentMutation,
    ScheduleAppointmentMutationVariables
  >(ScheduleAppointmentDocument, options);
}
export type ScheduleAppointmentMutationHookResult = ReturnType<
  typeof useScheduleAppointmentMutation
>;
export type ScheduleAppointmentMutationResult =
  Apollo.MutationResult<ScheduleAppointmentMutation>;
export type ScheduleAppointmentMutationOptions = Apollo.BaseMutationOptions<
  ScheduleAppointmentMutation,
  ScheduleAppointmentMutationVariables
>;
export const LoadAppointmentsDocument = gql`
  query LoadAppointments(
    $where: appointments_bool_exp!
    $order_by: [appointments_order_by!] = {}
    $limit: Int = 10
  ) {
    appointments(where: $where, order_by: $order_by, limit: $limit) {
      ...AppointmentParts
    }
  }
  ${AppointmentPartsFragmentDoc}
`;

/**
 * __useLoadAppointmentsQuery__
 *
 * To run a query within a React component, call `useLoadAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAppointmentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useLoadAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAppointmentsQuery,
    LoadAppointmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadAppointmentsQuery, LoadAppointmentsQueryVariables>(
    LoadAppointmentsDocument,
    options,
  );
}
export function useLoadAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAppointmentsQuery,
    LoadAppointmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAppointmentsQuery,
    LoadAppointmentsQueryVariables
  >(LoadAppointmentsDocument, options);
}
export type LoadAppointmentsQueryHookResult = ReturnType<
  typeof useLoadAppointmentsQuery
>;
export type LoadAppointmentsLazyQueryHookResult = ReturnType<
  typeof useLoadAppointmentsLazyQuery
>;
export type LoadAppointmentsQueryResult = Apollo.QueryResult<
  LoadAppointmentsQuery,
  LoadAppointmentsQueryVariables
>;
export const LoadAppointmentBlockByPkDocument = gql`
  query LoadAppointmentBlockByPk($id: uuid!) {
    appointment_blocks_by_pk(id: $id) {
      startDateTime
      reason
      id
      endDateTime
      staff {
        firstName
        lastName
        id
      }
      appointment_blocks_staffs {
        ...AppointmentBlockStaff
      }
    }
  }
  ${AppointmentBlockStaffFragmentDoc}
`;

/**
 * __useLoadAppointmentBlockByPkQuery__
 *
 * To run a query within a React component, call `useLoadAppointmentBlockByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAppointmentBlockByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAppointmentBlockByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadAppointmentBlockByPkQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAppointmentBlockByPkQuery,
    LoadAppointmentBlockByPkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAppointmentBlockByPkQuery,
    LoadAppointmentBlockByPkQueryVariables
  >(LoadAppointmentBlockByPkDocument, options);
}
export function useLoadAppointmentBlockByPkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAppointmentBlockByPkQuery,
    LoadAppointmentBlockByPkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAppointmentBlockByPkQuery,
    LoadAppointmentBlockByPkQueryVariables
  >(LoadAppointmentBlockByPkDocument, options);
}
export type LoadAppointmentBlockByPkQueryHookResult = ReturnType<
  typeof useLoadAppointmentBlockByPkQuery
>;
export type LoadAppointmentBlockByPkLazyQueryHookResult = ReturnType<
  typeof useLoadAppointmentBlockByPkLazyQuery
>;
export type LoadAppointmentBlockByPkQueryResult = Apollo.QueryResult<
  LoadAppointmentBlockByPkQuery,
  LoadAppointmentBlockByPkQueryVariables
>;
export const GetLatestAppointmentsDocument = gql`
  query GetLatestAppointments(
    $where: appointments_bool_exp!
    $order_by: [appointments_order_by!] = {}
    $limit: Int = 10
  ) {
    appointments(where: $where, order_by: $order_by, limit: $limit) {
      id
      notes
      reason
      startTime
      visitType
      visitClassification
    }
  }
`;

/**
 * __useGetLatestAppointmentsQuery__
 *
 * To run a query within a React component, call `useGetLatestAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestAppointmentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLatestAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLatestAppointmentsQuery,
    GetLatestAppointmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestAppointmentsQuery,
    GetLatestAppointmentsQueryVariables
  >(GetLatestAppointmentsDocument, options);
}
export function useGetLatestAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestAppointmentsQuery,
    GetLatestAppointmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestAppointmentsQuery,
    GetLatestAppointmentsQueryVariables
  >(GetLatestAppointmentsDocument, options);
}
export type GetLatestAppointmentsQueryHookResult = ReturnType<
  typeof useGetLatestAppointmentsQuery
>;
export type GetLatestAppointmentsLazyQueryHookResult = ReturnType<
  typeof useGetLatestAppointmentsLazyQuery
>;
export type GetLatestAppointmentsQueryResult = Apollo.QueryResult<
  GetLatestAppointmentsQuery,
  GetLatestAppointmentsQueryVariables
>;
export const AuditLogDocument = gql`
  query AuditLog(
    $where: audit_logs_bool_exp!
    $order_by: [audit_logs_order_by!]!
  ) {
    audit_logs(where: $where, order_by: $order_by) {
      ...Audit
    }
  }
  ${AuditFragmentDoc}
`;

/**
 * __useAuditLogQuery__
 *
 * To run a query within a React component, call `useAuditLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditLogQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAuditLogQuery(
  baseOptions: Apollo.QueryHookOptions<AuditLogQuery, AuditLogQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuditLogQuery, AuditLogQueryVariables>(
    AuditLogDocument,
    options,
  );
}
export function useAuditLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuditLogQuery,
    AuditLogQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuditLogQuery, AuditLogQueryVariables>(
    AuditLogDocument,
    options,
  );
}
export type AuditLogQueryHookResult = ReturnType<typeof useAuditLogQuery>;
export type AuditLogLazyQueryHookResult = ReturnType<
  typeof useAuditLogLazyQuery
>;
export type AuditLogQueryResult = Apollo.QueryResult<
  AuditLogQuery,
  AuditLogQueryVariables
>;
export const RetrieveVerificationCodeDocument = gql`
  query RetrieveVerificationCode($phoneNumber: String!) {
    RetrieveVerificationCode(phoneNumber: $phoneNumber) {
      code
    }
  }
`;

/**
 * __useRetrieveVerificationCodeQuery__
 *
 * To run a query within a React component, call `useRetrieveVerificationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveVerificationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveVerificationCodeQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useRetrieveVerificationCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    RetrieveVerificationCodeQuery,
    RetrieveVerificationCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RetrieveVerificationCodeQuery,
    RetrieveVerificationCodeQueryVariables
  >(RetrieveVerificationCodeDocument, options);
}
export function useRetrieveVerificationCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RetrieveVerificationCodeQuery,
    RetrieveVerificationCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RetrieveVerificationCodeQuery,
    RetrieveVerificationCodeQueryVariables
  >(RetrieveVerificationCodeDocument, options);
}
export type RetrieveVerificationCodeQueryHookResult = ReturnType<
  typeof useRetrieveVerificationCodeQuery
>;
export type RetrieveVerificationCodeLazyQueryHookResult = ReturnType<
  typeof useRetrieveVerificationCodeLazyQuery
>;
export type RetrieveVerificationCodeQueryResult = Apollo.QueryResult<
  RetrieveVerificationCodeQuery,
  RetrieveVerificationCodeQueryVariables
>;
export const GenerateTemporaryVerificationCodeDocument = gql`
  mutation GenerateTemporaryVerificationCode($accountId: uuid!) {
    GenerateTemporaryVerificationCode(accountId: $accountId) {
      code
    }
  }
`;
export type GenerateTemporaryVerificationCodeMutationFn =
  Apollo.MutationFunction<
    GenerateTemporaryVerificationCodeMutation,
    GenerateTemporaryVerificationCodeMutationVariables
  >;

/**
 * __useGenerateTemporaryVerificationCodeMutation__
 *
 * To run a mutation, you first call `useGenerateTemporaryVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateTemporaryVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateTemporaryVerificationCodeMutation, { data, loading, error }] = useGenerateTemporaryVerificationCodeMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGenerateTemporaryVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateTemporaryVerificationCodeMutation,
    GenerateTemporaryVerificationCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateTemporaryVerificationCodeMutation,
    GenerateTemporaryVerificationCodeMutationVariables
  >(GenerateTemporaryVerificationCodeDocument, options);
}
export type GenerateTemporaryVerificationCodeMutationHookResult = ReturnType<
  typeof useGenerateTemporaryVerificationCodeMutation
>;
export type GenerateTemporaryVerificationCodeMutationResult =
  Apollo.MutationResult<GenerateTemporaryVerificationCodeMutation>;
export type GenerateTemporaryVerificationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateTemporaryVerificationCodeMutation,
    GenerateTemporaryVerificationCodeMutationVariables
  >;
export const LoadChatSessionsDocument = gql`
  query LoadChatSessions(
    $limit: Int = 30
    $orderBy: [chat_sessions_order_by!] = [{ createdAt: desc }]
    $where: chat_sessions_bool_exp! = {}
  ) {
    chat_sessions(limit: $limit, order_by: $orderBy, where: $where) {
      ...ChatSession
      chat_sessions_accounts {
        ...ChatSessionAccount
      }
      chat_sessions_patients {
        ...ChatSessionPatient
      }
      chat_sessions_staffs {
        ...ChatSessionStaff
      }
      chat_sessions_reads {
        ...ChatSessionRead
      }
      chat_messages(
        limit: 1
        order_by: { updatedAt: desc }
        where: { type: { _in: [MESSAGE, ACTION] } }
      ) {
        ...ChatMessage
      }
      chat_notes {
        ...ChatNote
      }
    }
  }
  ${ChatSessionFragmentDoc}
  ${ChatSessionAccountFragmentDoc}
  ${ChatSessionPatientFragmentDoc}
  ${ChatSessionStaffFragmentDoc}
  ${ChatSessionReadFragmentDoc}
  ${ChatMessageFragmentDoc}
  ${ChatNoteFragmentDoc}
`;

/**
 * __useLoadChatSessionsQuery__
 *
 * To run a query within a React component, call `useLoadChatSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadChatSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadChatSessionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLoadChatSessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadChatSessionsQuery,
    LoadChatSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadChatSessionsQuery, LoadChatSessionsQueryVariables>(
    LoadChatSessionsDocument,
    options,
  );
}
export function useLoadChatSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadChatSessionsQuery,
    LoadChatSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadChatSessionsQuery,
    LoadChatSessionsQueryVariables
  >(LoadChatSessionsDocument, options);
}
export type LoadChatSessionsQueryHookResult = ReturnType<
  typeof useLoadChatSessionsQuery
>;
export type LoadChatSessionsLazyQueryHookResult = ReturnType<
  typeof useLoadChatSessionsLazyQuery
>;
export type LoadChatSessionsQueryResult = Apollo.QueryResult<
  LoadChatSessionsQuery,
  LoadChatSessionsQueryVariables
>;
export const NewUserChatMessagesDocument = gql`
  subscription NewUserChatMessages($chatSession: uuid_comparison_exp = {}) {
    chat_messages(
      limit: 1
      order_by: { createdAt: desc }
      where: {
        staffId: { _is_null: true }
        accountId: { _is_null: false }
        chatSessionId: $chatSession
      }
    ) {
      id
      chatSessionId
    }
  }
`;

/**
 * __useNewUserChatMessagesSubscription__
 *
 * To run a query within a React component, call `useNewUserChatMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewUserChatMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewUserChatMessagesSubscription({
 *   variables: {
 *      chatSession: // value for 'chatSession'
 *   },
 * });
 */
export function useNewUserChatMessagesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewUserChatMessagesSubscription,
    NewUserChatMessagesSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewUserChatMessagesSubscription,
    NewUserChatMessagesSubscriptionVariables
  >(NewUserChatMessagesDocument, options);
}
export type NewUserChatMessagesSubscriptionHookResult = ReturnType<
  typeof useNewUserChatMessagesSubscription
>;
export type NewUserChatMessagesSubscriptionResult =
  Apollo.SubscriptionResult<NewUserChatMessagesSubscription>;
export const ChatSessionsDocument = gql`
  subscription ChatSessions(
    $limit: Int = 30
    $orderBy: [chat_sessions_order_by!] = [{ createdAt: desc }]
    $where: chat_sessions_bool_exp! = {}
  ) {
    chat_sessions(limit: $limit, order_by: $orderBy, where: $where) {
      ...ChatSession
      chat_sessions_accounts {
        ...ChatSessionAccount
      }
      chat_sessions_patients {
        ...ChatSessionPatient
      }
      chat_sessions_staffs {
        ...ChatSessionStaff
      }
      chat_sessions_reads {
        ...ChatSessionRead
      }
      chat_messages(
        limit: 1
        order_by: { updatedAt: desc }
        where: { type: { _in: [MESSAGE, ACTION] } }
      ) {
        ...ChatMessage
      }
      chat_notes {
        ...ChatNote
      }
    }
  }
  ${ChatSessionFragmentDoc}
  ${ChatSessionAccountFragmentDoc}
  ${ChatSessionPatientFragmentDoc}
  ${ChatSessionStaffFragmentDoc}
  ${ChatSessionReadFragmentDoc}
  ${ChatMessageFragmentDoc}
  ${ChatNoteFragmentDoc}
`;

/**
 * __useChatSessionsSubscription__
 *
 * To run a query within a React component, call `useChatSessionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatSessionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatSessionsSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useChatSessionsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ChatSessionsSubscription,
    ChatSessionsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ChatSessionsSubscription,
    ChatSessionsSubscriptionVariables
  >(ChatSessionsDocument, options);
}
export type ChatSessionsSubscriptionHookResult = ReturnType<
  typeof useChatSessionsSubscription
>;
export type ChatSessionsSubscriptionResult =
  Apollo.SubscriptionResult<ChatSessionsSubscription>;
export const RecentChatsDocument = gql`
  subscription RecentChats(
    $limit: Int = 30
    $orderBy: [chat_sessions_order_by!] = [{ createdAt: desc }]
    $where: chat_sessions_bool_exp! = {}
  ) {
    chat_sessions(limit: $limit, order_by: $orderBy, where: $where) {
      ...RecentChatsData
    }
  }
  ${RecentChatsDataFragmentDoc}
`;

/**
 * __useRecentChatsSubscription__
 *
 * To run a query within a React component, call `useRecentChatsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRecentChatsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentChatsSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRecentChatsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    RecentChatsSubscription,
    RecentChatsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    RecentChatsSubscription,
    RecentChatsSubscriptionVariables
  >(RecentChatsDocument, options);
}
export type RecentChatsSubscriptionHookResult = ReturnType<
  typeof useRecentChatsSubscription
>;
export type RecentChatsSubscriptionResult =
  Apollo.SubscriptionResult<RecentChatsSubscription>;
export const LoadChatSessionDocument = gql`
  query LoadChatSession($id: uuid!) {
    chat_sessions_by_pk(id: $id) {
      ...ChatSession
      chat_sessions_accounts {
        ...ChatSessionAccount
      }
      chat_sessions_patients {
        ...ChatSessionPatient
      }
      chat_sessions_staffs {
        ...ChatSessionStaff
      }
      chat_notes {
        ...ChatNote
      }
    }
  }
  ${ChatSessionFragmentDoc}
  ${ChatSessionAccountFragmentDoc}
  ${ChatSessionPatientFragmentDoc}
  ${ChatSessionStaffFragmentDoc}
  ${ChatNoteFragmentDoc}
`;

/**
 * __useLoadChatSessionQuery__
 *
 * To run a query within a React component, call `useLoadChatSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadChatSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadChatSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadChatSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadChatSessionQuery,
    LoadChatSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadChatSessionQuery, LoadChatSessionQueryVariables>(
    LoadChatSessionDocument,
    options,
  );
}
export function useLoadChatSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadChatSessionQuery,
    LoadChatSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadChatSessionQuery,
    LoadChatSessionQueryVariables
  >(LoadChatSessionDocument, options);
}
export type LoadChatSessionQueryHookResult = ReturnType<
  typeof useLoadChatSessionQuery
>;
export type LoadChatSessionLazyQueryHookResult = ReturnType<
  typeof useLoadChatSessionLazyQuery
>;
export type LoadChatSessionQueryResult = Apollo.QueryResult<
  LoadChatSessionQuery,
  LoadChatSessionQueryVariables
>;
export const ChatSessionDocument = gql`
  subscription ChatSession($id: uuid!) {
    chat_sessions_by_pk(id: $id) {
      ...ChatSession
      chat_sessions_accounts {
        ...ChatSessionAccount
      }
      chat_sessions_patients {
        ...ChatSessionPatient
      }
      chat_sessions_staffs {
        ...ChatSessionStaff
      }
      chat_notes {
        ...ChatNote
      }
    }
  }
  ${ChatSessionFragmentDoc}
  ${ChatSessionAccountFragmentDoc}
  ${ChatSessionPatientFragmentDoc}
  ${ChatSessionStaffFragmentDoc}
  ${ChatNoteFragmentDoc}
`;

/**
 * __useChatSessionSubscription__
 *
 * To run a query within a React component, call `useChatSessionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatSessionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatSessionSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChatSessionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ChatSessionSubscription,
    ChatSessionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ChatSessionSubscription,
    ChatSessionSubscriptionVariables
  >(ChatSessionDocument, options);
}
export type ChatSessionSubscriptionHookResult = ReturnType<
  typeof useChatSessionSubscription
>;
export type ChatSessionSubscriptionResult =
  Apollo.SubscriptionResult<ChatSessionSubscription>;
export const ChatMessagesDocument = gql`
  subscription ChatMessages($where: chat_messages_bool_exp!) {
    chat_messages(where: $where) {
      ...ChatMessage
      staff {
        ...Staff
      }
      chat_messages_files {
        ...ChatMessageFile
      }
    }
  }
  ${ChatMessageFragmentDoc}
  ${StaffFragmentDoc}
  ${ChatMessageFileFragmentDoc}
`;

/**
 * __useChatMessagesSubscription__
 *
 * To run a query within a React component, call `useChatMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMessagesSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useChatMessagesSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ChatMessagesSubscription,
    ChatMessagesSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ChatMessagesSubscription,
    ChatMessagesSubscriptionVariables
  >(ChatMessagesDocument, options);
}
export type ChatMessagesSubscriptionHookResult = ReturnType<
  typeof useChatMessagesSubscription
>;
export type ChatMessagesSubscriptionResult =
  Apollo.SubscriptionResult<ChatMessagesSubscription>;
export const LoadChatFileDocument = gql`
  query LoadChatFile($id: String!) {
    GetFile(id: $id) {
      id
      url
    }
  }
`;

/**
 * __useLoadChatFileQuery__
 *
 * To run a query within a React component, call `useLoadChatFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadChatFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadChatFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadChatFileQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadChatFileQuery,
    LoadChatFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadChatFileQuery, LoadChatFileQueryVariables>(
    LoadChatFileDocument,
    options,
  );
}
export function useLoadChatFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadChatFileQuery,
    LoadChatFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadChatFileQuery, LoadChatFileQueryVariables>(
    LoadChatFileDocument,
    options,
  );
}
export type LoadChatFileQueryHookResult = ReturnType<
  typeof useLoadChatFileQuery
>;
export type LoadChatFileLazyQueryHookResult = ReturnType<
  typeof useLoadChatFileLazyQuery
>;
export type LoadChatFileQueryResult = Apollo.QueryResult<
  LoadChatFileQuery,
  LoadChatFileQueryVariables
>;
export const SendChatMessageDocument = gql`
  mutation SendChatMessage($message: chat_messages_insert_input!) {
    insert_chat_messages(objects: [$message]) {
      returning {
        ...ChatMessage
      }
    }
  }
  ${ChatMessageFragmentDoc}
`;
export type SendChatMessageMutationFn = Apollo.MutationFunction<
  SendChatMessageMutation,
  SendChatMessageMutationVariables
>;

/**
 * __useSendChatMessageMutation__
 *
 * To run a mutation, you first call `useSendChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatMessageMutation, { data, loading, error }] = useSendChatMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendChatMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendChatMessageMutation,
    SendChatMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendChatMessageMutation,
    SendChatMessageMutationVariables
  >(SendChatMessageDocument, options);
}
export type SendChatMessageMutationHookResult = ReturnType<
  typeof useSendChatMessageMutation
>;
export type SendChatMessageMutationResult =
  Apollo.MutationResult<SendChatMessageMutation>;
export type SendChatMessageMutationOptions = Apollo.BaseMutationOptions<
  SendChatMessageMutation,
  SendChatMessageMutationVariables
>;
export const CreateChatSessionDocument = gql`
  mutation CreateChatSession($chatSession: chat_sessions_insert_input!) {
    insert_chat_sessions(objects: [$chatSession]) {
      returning {
        ...ChatSession
      }
    }
  }
  ${ChatSessionFragmentDoc}
`;
export type CreateChatSessionMutationFn = Apollo.MutationFunction<
  CreateChatSessionMutation,
  CreateChatSessionMutationVariables
>;

/**
 * __useCreateChatSessionMutation__
 *
 * To run a mutation, you first call `useCreateChatSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatSessionMutation, { data, loading, error }] = useCreateChatSessionMutation({
 *   variables: {
 *      chatSession: // value for 'chatSession'
 *   },
 * });
 */
export function useCreateChatSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChatSessionMutation,
    CreateChatSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateChatSessionMutation,
    CreateChatSessionMutationVariables
  >(CreateChatSessionDocument, options);
}
export type CreateChatSessionMutationHookResult = ReturnType<
  typeof useCreateChatSessionMutation
>;
export type CreateChatSessionMutationResult =
  Apollo.MutationResult<CreateChatSessionMutation>;
export type CreateChatSessionMutationOptions = Apollo.BaseMutationOptions<
  CreateChatSessionMutation,
  CreateChatSessionMutationVariables
>;
export const UpdateChatSessionStatusDocument = gql`
  mutation UpdateChatSessionStatus(
    $id: uuid!
    $status: chat_sessions_status_enum!
  ) {
    update_chat_sessions(
      where: { id: { _eq: $id } }
      _set: { status: $status }
    ) {
      returning {
        ...ChatSession
      }
    }
  }
  ${ChatSessionFragmentDoc}
`;
export type UpdateChatSessionStatusMutationFn = Apollo.MutationFunction<
  UpdateChatSessionStatusMutation,
  UpdateChatSessionStatusMutationVariables
>;

/**
 * __useUpdateChatSessionStatusMutation__
 *
 * To run a mutation, you first call `useUpdateChatSessionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatSessionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatSessionStatusMutation, { data, loading, error }] = useUpdateChatSessionStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateChatSessionStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChatSessionStatusMutation,
    UpdateChatSessionStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChatSessionStatusMutation,
    UpdateChatSessionStatusMutationVariables
  >(UpdateChatSessionStatusDocument, options);
}
export type UpdateChatSessionStatusMutationHookResult = ReturnType<
  typeof useUpdateChatSessionStatusMutation
>;
export type UpdateChatSessionStatusMutationResult =
  Apollo.MutationResult<UpdateChatSessionStatusMutation>;
export type UpdateChatSessionStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateChatSessionStatusMutation,
  UpdateChatSessionStatusMutationVariables
>;
export const InsertChatSessionAccountDocument = gql`
  mutation InsertChatSessionAccount(
    $chatSessionAccount: chat_sessions_accounts_insert_input!
  ) {
    insert_chat_sessions_accounts(objects: [$chatSessionAccount]) {
      returning {
        ...ChatSessionAccount
      }
    }
  }
  ${ChatSessionAccountFragmentDoc}
`;
export type InsertChatSessionAccountMutationFn = Apollo.MutationFunction<
  InsertChatSessionAccountMutation,
  InsertChatSessionAccountMutationVariables
>;

/**
 * __useInsertChatSessionAccountMutation__
 *
 * To run a mutation, you first call `useInsertChatSessionAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertChatSessionAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertChatSessionAccountMutation, { data, loading, error }] = useInsertChatSessionAccountMutation({
 *   variables: {
 *      chatSessionAccount: // value for 'chatSessionAccount'
 *   },
 * });
 */
export function useInsertChatSessionAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertChatSessionAccountMutation,
    InsertChatSessionAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertChatSessionAccountMutation,
    InsertChatSessionAccountMutationVariables
  >(InsertChatSessionAccountDocument, options);
}
export type InsertChatSessionAccountMutationHookResult = ReturnType<
  typeof useInsertChatSessionAccountMutation
>;
export type InsertChatSessionAccountMutationResult =
  Apollo.MutationResult<InsertChatSessionAccountMutation>;
export type InsertChatSessionAccountMutationOptions =
  Apollo.BaseMutationOptions<
    InsertChatSessionAccountMutation,
    InsertChatSessionAccountMutationVariables
  >;
export const InsertChatSessionPatientDocument = gql`
  mutation InsertChatSessionPatient(
    $chatSessionPatient: chat_sessions_patients_insert_input!
  ) {
    insert_chat_sessions_patients(objects: [$chatSessionPatient]) {
      returning {
        ...ChatSessionPatient
      }
    }
  }
  ${ChatSessionPatientFragmentDoc}
`;
export type InsertChatSessionPatientMutationFn = Apollo.MutationFunction<
  InsertChatSessionPatientMutation,
  InsertChatSessionPatientMutationVariables
>;

/**
 * __useInsertChatSessionPatientMutation__
 *
 * To run a mutation, you first call `useInsertChatSessionPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertChatSessionPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertChatSessionPatientMutation, { data, loading, error }] = useInsertChatSessionPatientMutation({
 *   variables: {
 *      chatSessionPatient: // value for 'chatSessionPatient'
 *   },
 * });
 */
export function useInsertChatSessionPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertChatSessionPatientMutation,
    InsertChatSessionPatientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertChatSessionPatientMutation,
    InsertChatSessionPatientMutationVariables
  >(InsertChatSessionPatientDocument, options);
}
export type InsertChatSessionPatientMutationHookResult = ReturnType<
  typeof useInsertChatSessionPatientMutation
>;
export type InsertChatSessionPatientMutationResult =
  Apollo.MutationResult<InsertChatSessionPatientMutation>;
export type InsertChatSessionPatientMutationOptions =
  Apollo.BaseMutationOptions<
    InsertChatSessionPatientMutation,
    InsertChatSessionPatientMutationVariables
  >;
export const InsertChatSessionStaffDocument = gql`
  mutation InsertChatSessionStaff(
    $chatSessionStaff: chat_sessions_staff_insert_input!
  ) {
    insert_chat_sessions_staff(objects: [$chatSessionStaff]) {
      returning {
        ...ChatSessionStaff
      }
    }
  }
  ${ChatSessionStaffFragmentDoc}
`;
export type InsertChatSessionStaffMutationFn = Apollo.MutationFunction<
  InsertChatSessionStaffMutation,
  InsertChatSessionStaffMutationVariables
>;

/**
 * __useInsertChatSessionStaffMutation__
 *
 * To run a mutation, you first call `useInsertChatSessionStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertChatSessionStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertChatSessionStaffMutation, { data, loading, error }] = useInsertChatSessionStaffMutation({
 *   variables: {
 *      chatSessionStaff: // value for 'chatSessionStaff'
 *   },
 * });
 */
export function useInsertChatSessionStaffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertChatSessionStaffMutation,
    InsertChatSessionStaffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertChatSessionStaffMutation,
    InsertChatSessionStaffMutationVariables
  >(InsertChatSessionStaffDocument, options);
}
export type InsertChatSessionStaffMutationHookResult = ReturnType<
  typeof useInsertChatSessionStaffMutation
>;
export type InsertChatSessionStaffMutationResult =
  Apollo.MutationResult<InsertChatSessionStaffMutation>;
export type InsertChatSessionStaffMutationOptions = Apollo.BaseMutationOptions<
  InsertChatSessionStaffMutation,
  InsertChatSessionStaffMutationVariables
>;
export const LeaveChatDocument = gql`
  mutation LeaveChat($chatSessionId: uuid!, $staffId: uuid!) {
    update_chat_sessions_staff(
      where: {
        chatSessionId: { _eq: $chatSessionId }
        staffId: { _eq: $staffId }
      }
      _set: { active: false }
    ) {
      returning {
        ...ChatSessionStaff
      }
    }
  }
  ${ChatSessionStaffFragmentDoc}
`;
export type LeaveChatMutationFn = Apollo.MutationFunction<
  LeaveChatMutation,
  LeaveChatMutationVariables
>;

/**
 * __useLeaveChatMutation__
 *
 * To run a mutation, you first call `useLeaveChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveChatMutation, { data, loading, error }] = useLeaveChatMutation({
 *   variables: {
 *      chatSessionId: // value for 'chatSessionId'
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useLeaveChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveChatMutation,
    LeaveChatMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LeaveChatMutation, LeaveChatMutationVariables>(
    LeaveChatDocument,
    options,
  );
}
export type LeaveChatMutationHookResult = ReturnType<
  typeof useLeaveChatMutation
>;
export type LeaveChatMutationResult = Apollo.MutationResult<LeaveChatMutation>;
export type LeaveChatMutationOptions = Apollo.BaseMutationOptions<
  LeaveChatMutation,
  LeaveChatMutationVariables
>;
export const RejoinChatDocument = gql`
  mutation RejoinChat($chatSessionId: uuid!, $staffId: uuid!) {
    update_chat_sessions_staff(
      where: {
        chatSessionId: { _eq: $chatSessionId }
        staffId: { _eq: $staffId }
      }
      _set: { active: true }
    ) {
      returning {
        ...ChatSessionStaff
      }
    }
  }
  ${ChatSessionStaffFragmentDoc}
`;
export type RejoinChatMutationFn = Apollo.MutationFunction<
  RejoinChatMutation,
  RejoinChatMutationVariables
>;

/**
 * __useRejoinChatMutation__
 *
 * To run a mutation, you first call `useRejoinChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejoinChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejoinChatMutation, { data, loading, error }] = useRejoinChatMutation({
 *   variables: {
 *      chatSessionId: // value for 'chatSessionId'
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useRejoinChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejoinChatMutation,
    RejoinChatMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RejoinChatMutation, RejoinChatMutationVariables>(
    RejoinChatDocument,
    options,
  );
}
export type RejoinChatMutationHookResult = ReturnType<
  typeof useRejoinChatMutation
>;
export type RejoinChatMutationResult =
  Apollo.MutationResult<RejoinChatMutation>;
export type RejoinChatMutationOptions = Apollo.BaseMutationOptions<
  RejoinChatMutation,
  RejoinChatMutationVariables
>;
export const UpdateReadReceiptDocument = gql`
  mutation UpdateReadReceipt($read: chat_sessions_read_insert_input!) {
    insert_chat_sessions_read(
      objects: [$read]
      on_conflict: {
        constraint: chat_sessions_read_staffId_chatSessionId_key
        update_columns: lastViewed
      }
    ) {
      returning {
        id
      }
    }
  }
`;
export type UpdateReadReceiptMutationFn = Apollo.MutationFunction<
  UpdateReadReceiptMutation,
  UpdateReadReceiptMutationVariables
>;

/**
 * __useUpdateReadReceiptMutation__
 *
 * To run a mutation, you first call `useUpdateReadReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReadReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReadReceiptMutation, { data, loading, error }] = useUpdateReadReceiptMutation({
 *   variables: {
 *      read: // value for 'read'
 *   },
 * });
 */
export function useUpdateReadReceiptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReadReceiptMutation,
    UpdateReadReceiptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateReadReceiptMutation,
    UpdateReadReceiptMutationVariables
  >(UpdateReadReceiptDocument, options);
}
export type UpdateReadReceiptMutationHookResult = ReturnType<
  typeof useUpdateReadReceiptMutation
>;
export type UpdateReadReceiptMutationResult =
  Apollo.MutationResult<UpdateReadReceiptMutation>;
export type UpdateReadReceiptMutationOptions = Apollo.BaseMutationOptions<
  UpdateReadReceiptMutation,
  UpdateReadReceiptMutationVariables
>;
export const CreateChatNoteDocument = gql`
  mutation CreateChatNote($chatNote: chat_notes_insert_input!) {
    insert_chat_notes(objects: [$chatNote]) {
      returning {
        ...ChatNote
      }
    }
  }
  ${ChatNoteFragmentDoc}
`;
export type CreateChatNoteMutationFn = Apollo.MutationFunction<
  CreateChatNoteMutation,
  CreateChatNoteMutationVariables
>;

/**
 * __useCreateChatNoteMutation__
 *
 * To run a mutation, you first call `useCreateChatNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatNoteMutation, { data, loading, error }] = useCreateChatNoteMutation({
 *   variables: {
 *      chatNote: // value for 'chatNote'
 *   },
 * });
 */
export function useCreateChatNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChatNoteMutation,
    CreateChatNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateChatNoteMutation,
    CreateChatNoteMutationVariables
  >(CreateChatNoteDocument, options);
}
export type CreateChatNoteMutationHookResult = ReturnType<
  typeof useCreateChatNoteMutation
>;
export type CreateChatNoteMutationResult =
  Apollo.MutationResult<CreateChatNoteMutation>;
export type CreateChatNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateChatNoteMutation,
  CreateChatNoteMutationVariables
>;
export const TotalChatSessionsDocument = gql`
  subscription TotalChatSessions {
    chat_sessions_aggregate {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useTotalChatSessionsSubscription__
 *
 * To run a query within a React component, call `useTotalChatSessionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTotalChatSessionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalChatSessionsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTotalChatSessionsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TotalChatSessionsSubscription,
    TotalChatSessionsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TotalChatSessionsSubscription,
    TotalChatSessionsSubscriptionVariables
  >(TotalChatSessionsDocument, options);
}
export type TotalChatSessionsSubscriptionHookResult = ReturnType<
  typeof useTotalChatSessionsSubscription
>;
export type TotalChatSessionsSubscriptionResult =
  Apollo.SubscriptionResult<TotalChatSessionsSubscription>;
export const GetClinicByCodeDocument = gql`
  query GetClinicByCode($clinicCode: String!) {
    clinics(where: { clinicCode: { _eq: $clinicCode } }, limit: 1) {
      ...Clinic
    }
  }
  ${ClinicFragmentDoc}
`;

/**
 * __useGetClinicByCodeQuery__
 *
 * To run a query within a React component, call `useGetClinicByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicByCodeQuery({
 *   variables: {
 *      clinicCode: // value for 'clinicCode'
 *   },
 * });
 */
export function useGetClinicByCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClinicByCodeQuery,
    GetClinicByCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClinicByCodeQuery, GetClinicByCodeQueryVariables>(
    GetClinicByCodeDocument,
    options,
  );
}
export function useGetClinicByCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClinicByCodeQuery,
    GetClinicByCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClinicByCodeQuery,
    GetClinicByCodeQueryVariables
  >(GetClinicByCodeDocument, options);
}
export type GetClinicByCodeQueryHookResult = ReturnType<
  typeof useGetClinicByCodeQuery
>;
export type GetClinicByCodeLazyQueryHookResult = ReturnType<
  typeof useGetClinicByCodeLazyQuery
>;
export type GetClinicByCodeQueryResult = Apollo.QueryResult<
  GetClinicByCodeQuery,
  GetClinicByCodeQueryVariables
>;
export const LoadAllClinicsDocument = gql`
  query LoadAllClinics($where: clinics_bool_exp) {
    clinics(where: $where) {
      ...Clinic
    }
  }
  ${ClinicFragmentDoc}
`;

/**
 * __useLoadAllClinicsQuery__
 *
 * To run a query within a React component, call `useLoadAllClinicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAllClinicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAllClinicsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLoadAllClinicsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadAllClinicsQuery,
    LoadAllClinicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadAllClinicsQuery, LoadAllClinicsQueryVariables>(
    LoadAllClinicsDocument,
    options,
  );
}
export function useLoadAllClinicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAllClinicsQuery,
    LoadAllClinicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadAllClinicsQuery, LoadAllClinicsQueryVariables>(
    LoadAllClinicsDocument,
    options,
  );
}
export type LoadAllClinicsQueryHookResult = ReturnType<
  typeof useLoadAllClinicsQuery
>;
export type LoadAllClinicsLazyQueryHookResult = ReturnType<
  typeof useLoadAllClinicsLazyQuery
>;
export type LoadAllClinicsQueryResult = Apollo.QueryResult<
  LoadAllClinicsQuery,
  LoadAllClinicsQueryVariables
>;
export const DevicesDocument = gql`
  subscription Devices(
    $where: devices_bool_exp
    $offset: Int
    $orderBy: [devices_order_by!]
    $limit: Int
  ) {
    devices(where: $where, offset: $offset, order_by: $orderBy, limit: $limit) {
      appVersion
      batteryLevel
      batteryState
      clinic {
        id
        name
        clinicCode
      }
      clinicCode
      codepushVersion
      createdAt
      id
      location {
        id
        name
        clinicCode
      }
      locationId
      name
      osVersion
      updatedAt
      wifiNetwork
    }
  }
`;

/**
 * __useDevicesSubscription__
 *
 * To run a query within a React component, call `useDevicesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDevicesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDevicesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    DevicesSubscription,
    DevicesSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    DevicesSubscription,
    DevicesSubscriptionVariables
  >(DevicesDocument, options);
}
export type DevicesSubscriptionHookResult = ReturnType<
  typeof useDevicesSubscription
>;
export type DevicesSubscriptionResult =
  Apollo.SubscriptionResult<DevicesSubscription>;
export const DevicesByClinicCodeDocument = gql`
  subscription DevicesByClinicCode($clinicCode: String!) {
    devices(where: { location: { clinicCode: { _eq: $clinicCode } } }) {
      id
      name
      location {
        id
        name
      }
      updatedAt
    }
  }
`;

/**
 * __useDevicesByClinicCodeSubscription__
 *
 * To run a query within a React component, call `useDevicesByClinicCodeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDevicesByClinicCodeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesByClinicCodeSubscription({
 *   variables: {
 *      clinicCode: // value for 'clinicCode'
 *   },
 * });
 */
export function useDevicesByClinicCodeSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    DevicesByClinicCodeSubscription,
    DevicesByClinicCodeSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    DevicesByClinicCodeSubscription,
    DevicesByClinicCodeSubscriptionVariables
  >(DevicesByClinicCodeDocument, options);
}
export type DevicesByClinicCodeSubscriptionHookResult = ReturnType<
  typeof useDevicesByClinicCodeSubscription
>;
export type DevicesByClinicCodeSubscriptionResult =
  Apollo.SubscriptionResult<DevicesByClinicCodeSubscription>;
export const LocationsByClinicCodeDocument = gql`
  subscription LocationsByClinicCode($clinicCode: String!) {
    locations(where: { clinic: { clinicCode: { _eq: $clinicCode } } }) {
      id
      name
      devices {
        id
        name
        updatedAt
      }
    }
  }
`;

/**
 * __useLocationsByClinicCodeSubscription__
 *
 * To run a query within a React component, call `useLocationsByClinicCodeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLocationsByClinicCodeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsByClinicCodeSubscription({
 *   variables: {
 *      clinicCode: // value for 'clinicCode'
 *   },
 * });
 */
export function useLocationsByClinicCodeSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    LocationsByClinicCodeSubscription,
    LocationsByClinicCodeSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    LocationsByClinicCodeSubscription,
    LocationsByClinicCodeSubscriptionVariables
  >(LocationsByClinicCodeDocument, options);
}
export type LocationsByClinicCodeSubscriptionHookResult = ReturnType<
  typeof useLocationsByClinicCodeSubscription
>;
export type LocationsByClinicCodeSubscriptionResult =
  Apollo.SubscriptionResult<LocationsByClinicCodeSubscription>;
export const CommunicationHistoryDocument = gql`
  subscription CommunicationHistory(
    $limit: Int = 10
    $where: communication_history_bool_exp = {}
  ) {
    communication_history(
      order_by: { createdAt: desc }
      limit: $limit
      where: $where
    ) {
      ...CommunicationHistory
    }
  }
  ${CommunicationHistoryFragmentDoc}
`;

/**
 * __useCommunicationHistorySubscription__
 *
 * To run a query within a React component, call `useCommunicationHistorySubscription` and pass it any options that fit your needs.
 * When your component renders, `useCommunicationHistorySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunicationHistorySubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCommunicationHistorySubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CommunicationHistorySubscription,
    CommunicationHistorySubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CommunicationHistorySubscription,
    CommunicationHistorySubscriptionVariables
  >(CommunicationHistoryDocument, options);
}
export type CommunicationHistorySubscriptionHookResult = ReturnType<
  typeof useCommunicationHistorySubscription
>;
export type CommunicationHistorySubscriptionResult =
  Apollo.SubscriptionResult<CommunicationHistorySubscription>;
export const LoadCommunicationHistoryDocument = gql`
  query LoadCommunicationHistory($id: uuid!) {
    communication_history_by_pk(id: $id) {
      ...CommunicationHistoryData
    }
  }
  ${CommunicationHistoryDataFragmentDoc}
`;

/**
 * __useLoadCommunicationHistoryQuery__
 *
 * To run a query within a React component, call `useLoadCommunicationHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCommunicationHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCommunicationHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadCommunicationHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadCommunicationHistoryQuery,
    LoadCommunicationHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadCommunicationHistoryQuery,
    LoadCommunicationHistoryQueryVariables
  >(LoadCommunicationHistoryDocument, options);
}
export function useLoadCommunicationHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCommunicationHistoryQuery,
    LoadCommunicationHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadCommunicationHistoryQuery,
    LoadCommunicationHistoryQueryVariables
  >(LoadCommunicationHistoryDocument, options);
}
export type LoadCommunicationHistoryQueryHookResult = ReturnType<
  typeof useLoadCommunicationHistoryQuery
>;
export type LoadCommunicationHistoryLazyQueryHookResult = ReturnType<
  typeof useLoadCommunicationHistoryLazyQuery
>;
export type LoadCommunicationHistoryQueryResult = Apollo.QueryResult<
  LoadCommunicationHistoryQuery,
  LoadCommunicationHistoryQueryVariables
>;
export const CommunicationHistoryDataDocument = gql`
  subscription CommunicationHistoryData($id: uuid!) {
    communication_history_by_pk(id: $id) {
      ...CommunicationHistoryData
    }
  }
  ${CommunicationHistoryDataFragmentDoc}
`;

/**
 * __useCommunicationHistoryDataSubscription__
 *
 * To run a query within a React component, call `useCommunicationHistoryDataSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCommunicationHistoryDataSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunicationHistoryDataSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommunicationHistoryDataSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CommunicationHistoryDataSubscription,
    CommunicationHistoryDataSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CommunicationHistoryDataSubscription,
    CommunicationHistoryDataSubscriptionVariables
  >(CommunicationHistoryDataDocument, options);
}
export type CommunicationHistoryDataSubscriptionHookResult = ReturnType<
  typeof useCommunicationHistoryDataSubscription
>;
export type CommunicationHistoryDataSubscriptionResult =
  Apollo.SubscriptionResult<CommunicationHistoryDataSubscription>;
export const LoadPhoneNumbersDocument = gql`
  query LoadPhoneNumbers(
    $where: phone_numbers_bool_exp = {}
    $limit: Int = 20
  ) {
    phone_numbers(where: $where, limit: $limit) {
      ...PhoneNumber
      clinic {
        id
        name
      }
      staff {
        id
        lastName
        firstName
      }
    }
  }
  ${PhoneNumberFragmentDoc}
`;

/**
 * __useLoadPhoneNumbersQuery__
 *
 * To run a query within a React component, call `useLoadPhoneNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadPhoneNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadPhoneNumbersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useLoadPhoneNumbersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadPhoneNumbersQuery,
    LoadPhoneNumbersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadPhoneNumbersQuery, LoadPhoneNumbersQueryVariables>(
    LoadPhoneNumbersDocument,
    options,
  );
}
export function useLoadPhoneNumbersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadPhoneNumbersQuery,
    LoadPhoneNumbersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadPhoneNumbersQuery,
    LoadPhoneNumbersQueryVariables
  >(LoadPhoneNumbersDocument, options);
}
export type LoadPhoneNumbersQueryHookResult = ReturnType<
  typeof useLoadPhoneNumbersQuery
>;
export type LoadPhoneNumbersLazyQueryHookResult = ReturnType<
  typeof useLoadPhoneNumbersLazyQuery
>;
export type LoadPhoneNumbersQueryResult = Apollo.QueryResult<
  LoadPhoneNumbersQuery,
  LoadPhoneNumbersQueryVariables
>;
export const LoadCommunicationHistoryTagTypesDocument = gql`
  query LoadCommunicationHistoryTagTypes {
    communication_history_tag_types {
      value
      description
    }
  }
`;

/**
 * __useLoadCommunicationHistoryTagTypesQuery__
 *
 * To run a query within a React component, call `useLoadCommunicationHistoryTagTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCommunicationHistoryTagTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCommunicationHistoryTagTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadCommunicationHistoryTagTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadCommunicationHistoryTagTypesQuery,
    LoadCommunicationHistoryTagTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadCommunicationHistoryTagTypesQuery,
    LoadCommunicationHistoryTagTypesQueryVariables
  >(LoadCommunicationHistoryTagTypesDocument, options);
}
export function useLoadCommunicationHistoryTagTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCommunicationHistoryTagTypesQuery,
    LoadCommunicationHistoryTagTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadCommunicationHistoryTagTypesQuery,
    LoadCommunicationHistoryTagTypesQueryVariables
  >(LoadCommunicationHistoryTagTypesDocument, options);
}
export type LoadCommunicationHistoryTagTypesQueryHookResult = ReturnType<
  typeof useLoadCommunicationHistoryTagTypesQuery
>;
export type LoadCommunicationHistoryTagTypesLazyQueryHookResult = ReturnType<
  typeof useLoadCommunicationHistoryTagTypesLazyQuery
>;
export type LoadCommunicationHistoryTagTypesQueryResult = Apollo.QueryResult<
  LoadCommunicationHistoryTagTypesQuery,
  LoadCommunicationHistoryTagTypesQueryVariables
>;
export const LoadCommunicationHistoryTagsDocument = gql`
  query LoadCommunicationHistoryTags($communicationHistoryId: uuid!) {
    communication_history_tags(
      where: { communicationHistoryId: { _eq: $communicationHistoryId } }
    ) {
      id
      tag
    }
  }
`;

/**
 * __useLoadCommunicationHistoryTagsQuery__
 *
 * To run a query within a React component, call `useLoadCommunicationHistoryTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCommunicationHistoryTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCommunicationHistoryTagsQuery({
 *   variables: {
 *      communicationHistoryId: // value for 'communicationHistoryId'
 *   },
 * });
 */
export function useLoadCommunicationHistoryTagsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadCommunicationHistoryTagsQuery,
    LoadCommunicationHistoryTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadCommunicationHistoryTagsQuery,
    LoadCommunicationHistoryTagsQueryVariables
  >(LoadCommunicationHistoryTagsDocument, options);
}
export function useLoadCommunicationHistoryTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCommunicationHistoryTagsQuery,
    LoadCommunicationHistoryTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadCommunicationHistoryTagsQuery,
    LoadCommunicationHistoryTagsQueryVariables
  >(LoadCommunicationHistoryTagsDocument, options);
}
export type LoadCommunicationHistoryTagsQueryHookResult = ReturnType<
  typeof useLoadCommunicationHistoryTagsQuery
>;
export type LoadCommunicationHistoryTagsLazyQueryHookResult = ReturnType<
  typeof useLoadCommunicationHistoryTagsLazyQuery
>;
export type LoadCommunicationHistoryTagsQueryResult = Apollo.QueryResult<
  LoadCommunicationHistoryTagsQuery,
  LoadCommunicationHistoryTagsQueryVariables
>;
export const InsertCommunicationHistoryTagDocument = gql`
  mutation InsertCommunicationHistoryTag(
    $communicationHistoryId: uuid!
    $tag: communication_history_tag_types_enum!
  ) {
    insert_communication_history_tags_one(
      object: { communicationHistoryId: $communicationHistoryId, tag: $tag }
    ) {
      id
    }
  }
`;
export type InsertCommunicationHistoryTagMutationFn = Apollo.MutationFunction<
  InsertCommunicationHistoryTagMutation,
  InsertCommunicationHistoryTagMutationVariables
>;

/**
 * __useInsertCommunicationHistoryTagMutation__
 *
 * To run a mutation, you first call `useInsertCommunicationHistoryTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCommunicationHistoryTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCommunicationHistoryTagMutation, { data, loading, error }] = useInsertCommunicationHistoryTagMutation({
 *   variables: {
 *      communicationHistoryId: // value for 'communicationHistoryId'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useInsertCommunicationHistoryTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertCommunicationHistoryTagMutation,
    InsertCommunicationHistoryTagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertCommunicationHistoryTagMutation,
    InsertCommunicationHistoryTagMutationVariables
  >(InsertCommunicationHistoryTagDocument, options);
}
export type InsertCommunicationHistoryTagMutationHookResult = ReturnType<
  typeof useInsertCommunicationHistoryTagMutation
>;
export type InsertCommunicationHistoryTagMutationResult =
  Apollo.MutationResult<InsertCommunicationHistoryTagMutation>;
export type InsertCommunicationHistoryTagMutationOptions =
  Apollo.BaseMutationOptions<
    InsertCommunicationHistoryTagMutation,
    InsertCommunicationHistoryTagMutationVariables
  >;
export const DeleteCommunicationHistoryTagsDocument = gql`
  mutation DeleteCommunicationHistoryTags(
    $where: communication_history_tags_bool_exp!
  ) {
    delete_communication_history_tags(where: $where) {
      affected_rows
    }
  }
`;
export type DeleteCommunicationHistoryTagsMutationFn = Apollo.MutationFunction<
  DeleteCommunicationHistoryTagsMutation,
  DeleteCommunicationHistoryTagsMutationVariables
>;

/**
 * __useDeleteCommunicationHistoryTagsMutation__
 *
 * To run a mutation, you first call `useDeleteCommunicationHistoryTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunicationHistoryTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunicationHistoryTagsMutation, { data, loading, error }] = useDeleteCommunicationHistoryTagsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteCommunicationHistoryTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCommunicationHistoryTagsMutation,
    DeleteCommunicationHistoryTagsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCommunicationHistoryTagsMutation,
    DeleteCommunicationHistoryTagsMutationVariables
  >(DeleteCommunicationHistoryTagsDocument, options);
}
export type DeleteCommunicationHistoryTagsMutationHookResult = ReturnType<
  typeof useDeleteCommunicationHistoryTagsMutation
>;
export type DeleteCommunicationHistoryTagsMutationResult =
  Apollo.MutationResult<DeleteCommunicationHistoryTagsMutation>;
export type DeleteCommunicationHistoryTagsMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteCommunicationHistoryTagsMutation,
    DeleteCommunicationHistoryTagsMutationVariables
  >;
export const UpdateCommunicationHistoryDocument = gql`
  mutation UpdateCommunicationHistory(
    $id: uuid!
    $set: communication_history_set_input!
  ) {
    update_communication_history_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
    }
  }
`;
export type UpdateCommunicationHistoryMutationFn = Apollo.MutationFunction<
  UpdateCommunicationHistoryMutation,
  UpdateCommunicationHistoryMutationVariables
>;

/**
 * __useUpdateCommunicationHistoryMutation__
 *
 * To run a mutation, you first call `useUpdateCommunicationHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunicationHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunicationHistoryMutation, { data, loading, error }] = useUpdateCommunicationHistoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateCommunicationHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCommunicationHistoryMutation,
    UpdateCommunicationHistoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCommunicationHistoryMutation,
    UpdateCommunicationHistoryMutationVariables
  >(UpdateCommunicationHistoryDocument, options);
}
export type UpdateCommunicationHistoryMutationHookResult = ReturnType<
  typeof useUpdateCommunicationHistoryMutation
>;
export type UpdateCommunicationHistoryMutationResult =
  Apollo.MutationResult<UpdateCommunicationHistoryMutation>;
export type UpdateCommunicationHistoryMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCommunicationHistoryMutation,
    UpdateCommunicationHistoryMutationVariables
  >;
export const GetActivePhoneCallsDocument = gql`
  subscription GetActivePhoneCalls {
    communication_history(
      limit: 20
      order_by: { createdAt: asc }
      where: { status: { _in: ["ON_HOLD", "QUEUED", "IN_PROGRESS"] } }
    ) {
      id
      updatedAt
      status
      reasonForCall: metadata(path: "reasonForCall")
      staff {
        auth0UserId
      }
    }
  }
`;

/**
 * __useGetActivePhoneCallsSubscription__
 *
 * To run a query within a React component, call `useGetActivePhoneCallsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetActivePhoneCallsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivePhoneCallsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetActivePhoneCallsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetActivePhoneCallsSubscription,
    GetActivePhoneCallsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetActivePhoneCallsSubscription,
    GetActivePhoneCallsSubscriptionVariables
  >(GetActivePhoneCallsDocument, options);
}
export type GetActivePhoneCallsSubscriptionHookResult = ReturnType<
  typeof useGetActivePhoneCallsSubscription
>;
export type GetActivePhoneCallsSubscriptionResult =
  Apollo.SubscriptionResult<GetActivePhoneCallsSubscription>;
export const CommunicationsPageDocument = gql`
  subscription CommunicationsPage($limit: Int = 10) {
    communication_history(
      order_by: { createdAt: desc }
      limit: $limit
      where: { type: { _eq: PHONE_CALL } }
    ) {
      ...CommunicationsPageData
    }
  }
  ${CommunicationsPageDataFragmentDoc}
`;

/**
 * __useCommunicationsPageSubscription__
 *
 * To run a query within a React component, call `useCommunicationsPageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCommunicationsPageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunicationsPageSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCommunicationsPageSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CommunicationsPageSubscription,
    CommunicationsPageSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CommunicationsPageSubscription,
    CommunicationsPageSubscriptionVariables
  >(CommunicationsPageDocument, options);
}
export type CommunicationsPageSubscriptionHookResult = ReturnType<
  typeof useCommunicationsPageSubscription
>;
export type CommunicationsPageSubscriptionResult =
  Apollo.SubscriptionResult<CommunicationsPageSubscription>;
export const RecentCommunicationsPageDocument = gql`
  subscription RecentCommunicationsPage(
    $limit: Int = 10
    $where: communication_history_bool_exp = {}
  ) {
    communication_history(
      order_by: { createdAt: desc }
      limit: $limit
      where: $where
    ) {
      ...RecentCommunicationsPageData
    }
  }
  ${RecentCommunicationsPageDataFragmentDoc}
`;

/**
 * __useRecentCommunicationsPageSubscription__
 *
 * To run a query within a React component, call `useRecentCommunicationsPageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRecentCommunicationsPageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentCommunicationsPageSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRecentCommunicationsPageSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    RecentCommunicationsPageSubscription,
    RecentCommunicationsPageSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    RecentCommunicationsPageSubscription,
    RecentCommunicationsPageSubscriptionVariables
  >(RecentCommunicationsPageDocument, options);
}
export type RecentCommunicationsPageSubscriptionHookResult = ReturnType<
  typeof useRecentCommunicationsPageSubscription
>;
export type RecentCommunicationsPageSubscriptionResult =
  Apollo.SubscriptionResult<RecentCommunicationsPageSubscription>;
export const LoadDocumentsDocument = gql`
  query LoadDocuments(
    $where: documents_bool_exp = {}
    $order_by: [documents_order_by!] = { created_at: desc }
  ) {
    documents(where: $where, order_by: $order_by) {
      ...Document
    }
  }
  ${DocumentFragmentDoc}
`;

/**
 * __useLoadDocumentsQuery__
 *
 * To run a query within a React component, call `useLoadDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadDocumentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useLoadDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadDocumentsQuery,
    LoadDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadDocumentsQuery, LoadDocumentsQueryVariables>(
    LoadDocumentsDocument,
    options,
  );
}
export function useLoadDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadDocumentsQuery,
    LoadDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadDocumentsQuery, LoadDocumentsQueryVariables>(
    LoadDocumentsDocument,
    options,
  );
}
export type LoadDocumentsQueryHookResult = ReturnType<
  typeof useLoadDocumentsQuery
>;
export type LoadDocumentsLazyQueryHookResult = ReturnType<
  typeof useLoadDocumentsLazyQuery
>;
export type LoadDocumentsQueryResult = Apollo.QueryResult<
  LoadDocumentsQuery,
  LoadDocumentsQueryVariables
>;
export const RequestUploadDocument = gql`
  mutation RequestUpload(
    $fileName: String!
    $contentMD5: String!
    $accountId: String
    $patientId: String
    $documentType: String
  ) {
    RequestUpload(
      fileName: $fileName
      contentMD5: $contentMD5
      accountId: $accountId
      patientId: $patientId
      documentType: $documentType
    ) {
      fileId
      url
      mimeType
    }
  }
`;
export type RequestUploadMutationFn = Apollo.MutationFunction<
  RequestUploadMutation,
  RequestUploadMutationVariables
>;

/**
 * __useRequestUploadMutation__
 *
 * To run a mutation, you first call `useRequestUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestUploadMutation, { data, loading, error }] = useRequestUploadMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      contentMD5: // value for 'contentMD5'
 *      accountId: // value for 'accountId'
 *      patientId: // value for 'patientId'
 *      documentType: // value for 'documentType'
 *   },
 * });
 */
export function useRequestUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestUploadMutation,
    RequestUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestUploadMutation,
    RequestUploadMutationVariables
  >(RequestUploadDocument, options);
}
export type RequestUploadMutationHookResult = ReturnType<
  typeof useRequestUploadMutation
>;
export type RequestUploadMutationResult =
  Apollo.MutationResult<RequestUploadMutation>;
export type RequestUploadMutationOptions = Apollo.BaseMutationOptions<
  RequestUploadMutation,
  RequestUploadMutationVariables
>;
export const UpdateFileDocument = gql`
  mutation UpdateFile($id: uuid!, $file: files_set_input!) {
    update_files_by_pk(pk_columns: { id: $id }, _set: $file) {
      id
      createdAt
      updatedAt
      accountId
      patientId
      documentType
      gcv
      path
      status
    }
  }
`;
export type UpdateFileMutationFn = Apollo.MutationFunction<
  UpdateFileMutation,
  UpdateFileMutationVariables
>;

/**
 * __useUpdateFileMutation__
 *
 * To run a mutation, you first call `useUpdateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileMutation, { data, loading, error }] = useUpdateFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFileMutation,
    UpdateFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFileMutation, UpdateFileMutationVariables>(
    UpdateFileDocument,
    options,
  );
}
export type UpdateFileMutationHookResult = ReturnType<
  typeof useUpdateFileMutation
>;
export type UpdateFileMutationResult =
  Apollo.MutationResult<UpdateFileMutation>;
export type UpdateFileMutationOptions = Apollo.BaseMutationOptions<
  UpdateFileMutation,
  UpdateFileMutationVariables
>;
export const AddInsuranceDocument = gql`
  mutation AddInsurance($insurance: insurance_insert_input!) {
    insert_insurance(objects: [$insurance]) {
      returning {
        id
      }
    }
  }
`;
export type AddInsuranceMutationFn = Apollo.MutationFunction<
  AddInsuranceMutation,
  AddInsuranceMutationVariables
>;

/**
 * __useAddInsuranceMutation__
 *
 * To run a mutation, you first call `useAddInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInsuranceMutation, { data, loading, error }] = useAddInsuranceMutation({
 *   variables: {
 *      insurance: // value for 'insurance'
 *   },
 * });
 */
export function useAddInsuranceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddInsuranceMutation,
    AddInsuranceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddInsuranceMutation,
    AddInsuranceMutationVariables
  >(AddInsuranceDocument, options);
}
export type AddInsuranceMutationHookResult = ReturnType<
  typeof useAddInsuranceMutation
>;
export type AddInsuranceMutationResult =
  Apollo.MutationResult<AddInsuranceMutation>;
export type AddInsuranceMutationOptions = Apollo.BaseMutationOptions<
  AddInsuranceMutation,
  AddInsuranceMutationVariables
>;
export const UpdateInsuranceDocument = gql`
  mutation UpdateInsurance($insuranceId: uuid!, $set: insurance_set_input!) {
    update_insurance(where: { id: { _eq: $insuranceId } }, _set: $set) {
      returning {
        id
      }
    }
  }
`;
export type UpdateInsuranceMutationFn = Apollo.MutationFunction<
  UpdateInsuranceMutation,
  UpdateInsuranceMutationVariables
>;

/**
 * __useUpdateInsuranceMutation__
 *
 * To run a mutation, you first call `useUpdateInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInsuranceMutation, { data, loading, error }] = useUpdateInsuranceMutation({
 *   variables: {
 *      insuranceId: // value for 'insuranceId'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateInsuranceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInsuranceMutation,
    UpdateInsuranceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInsuranceMutation,
    UpdateInsuranceMutationVariables
  >(UpdateInsuranceDocument, options);
}
export type UpdateInsuranceMutationHookResult = ReturnType<
  typeof useUpdateInsuranceMutation
>;
export type UpdateInsuranceMutationResult =
  Apollo.MutationResult<UpdateInsuranceMutation>;
export type UpdateInsuranceMutationOptions = Apollo.BaseMutationOptions<
  UpdateInsuranceMutation,
  UpdateInsuranceMutationVariables
>;
export const LoadPatientInsurancesDocument = gql`
  query LoadPatientInsurances(
    $patientId: uuid!
    $where: insurance_bool_exp = {}
  ) {
    patients_by_pk(id: $patientId) {
      insurances(where: $where) {
        ...Insurance
      }
    }
  }
  ${InsuranceFragmentDoc}
`;

/**
 * __useLoadPatientInsurancesQuery__
 *
 * To run a query within a React component, call `useLoadPatientInsurancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadPatientInsurancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadPatientInsurancesQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLoadPatientInsurancesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadPatientInsurancesQuery,
    LoadPatientInsurancesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadPatientInsurancesQuery,
    LoadPatientInsurancesQueryVariables
  >(LoadPatientInsurancesDocument, options);
}
export function useLoadPatientInsurancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadPatientInsurancesQuery,
    LoadPatientInsurancesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadPatientInsurancesQuery,
    LoadPatientInsurancesQueryVariables
  >(LoadPatientInsurancesDocument, options);
}
export type LoadPatientInsurancesQueryHookResult = ReturnType<
  typeof useLoadPatientInsurancesQuery
>;
export type LoadPatientInsurancesLazyQueryHookResult = ReturnType<
  typeof useLoadPatientInsurancesLazyQuery
>;
export type LoadPatientInsurancesQueryResult = Apollo.QueryResult<
  LoadPatientInsurancesQuery,
  LoadPatientInsurancesQueryVariables
>;
export const PatientInsurancesDocument = gql`
  subscription PatientInsurances(
    $patientId: uuid!
    $where: insurance_bool_exp = {}
  ) {
    patients_by_pk(id: $patientId) {
      insurances(where: $where) {
        ...Insurance
      }
    }
  }
  ${InsuranceFragmentDoc}
`;

/**
 * __usePatientInsurancesSubscription__
 *
 * To run a query within a React component, call `usePatientInsurancesSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePatientInsurancesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientInsurancesSubscription({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePatientInsurancesSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    PatientInsurancesSubscription,
    PatientInsurancesSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    PatientInsurancesSubscription,
    PatientInsurancesSubscriptionVariables
  >(PatientInsurancesDocument, options);
}
export type PatientInsurancesSubscriptionHookResult = ReturnType<
  typeof usePatientInsurancesSubscription
>;
export type PatientInsurancesSubscriptionResult =
  Apollo.SubscriptionResult<PatientInsurancesSubscription>;
export const FindKareoPatientDocument = gql`
  mutation FindKareoPatient($dateOfBirth: String, $name: String) {
    FindKareoPatient(dateOfBirth: $dateOfBirth, name: $name) {
      dateOfBirth
      email
      firstName
      guid
      lastName
      patientId
      genderId
    }
  }
`;
export type FindKareoPatientMutationFn = Apollo.MutationFunction<
  FindKareoPatientMutation,
  FindKareoPatientMutationVariables
>;

/**
 * __useFindKareoPatientMutation__
 *
 * To run a mutation, you first call `useFindKareoPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFindKareoPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [findKareoPatientMutation, { data, loading, error }] = useFindKareoPatientMutation({
 *   variables: {
 *      dateOfBirth: // value for 'dateOfBirth'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFindKareoPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FindKareoPatientMutation,
    FindKareoPatientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FindKareoPatientMutation,
    FindKareoPatientMutationVariables
  >(FindKareoPatientDocument, options);
}
export type FindKareoPatientMutationHookResult = ReturnType<
  typeof useFindKareoPatientMutation
>;
export type FindKareoPatientMutationResult =
  Apollo.MutationResult<FindKareoPatientMutation>;
export type FindKareoPatientMutationOptions = Apollo.BaseMutationOptions<
  FindKareoPatientMutation,
  FindKareoPatientMutationVariables
>;
export const CreateKareoPatientDocument = gql`
  mutation CreateKareoPatient($patientId: String!) {
    CreateKareoPatient(patientId: $patientId) {
      success
    }
  }
`;
export type CreateKareoPatientMutationFn = Apollo.MutationFunction<
  CreateKareoPatientMutation,
  CreateKareoPatientMutationVariables
>;

/**
 * __useCreateKareoPatientMutation__
 *
 * To run a mutation, you first call `useCreateKareoPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKareoPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKareoPatientMutation, { data, loading, error }] = useCreateKareoPatientMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useCreateKareoPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateKareoPatientMutation,
    CreateKareoPatientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateKareoPatientMutation,
    CreateKareoPatientMutationVariables
  >(CreateKareoPatientDocument, options);
}
export type CreateKareoPatientMutationHookResult = ReturnType<
  typeof useCreateKareoPatientMutation
>;
export type CreateKareoPatientMutationResult =
  Apollo.MutationResult<CreateKareoPatientMutation>;
export type CreateKareoPatientMutationOptions = Apollo.BaseMutationOptions<
  CreateKareoPatientMutation,
  CreateKareoPatientMutationVariables
>;
export const UploadKareoDocumentsDocument = gql`
  mutation UploadKareoDocuments($patientId: String!) {
    UploadKareoDocuments(patientId: $patientId) {
      success
    }
  }
`;
export type UploadKareoDocumentsMutationFn = Apollo.MutationFunction<
  UploadKareoDocumentsMutation,
  UploadKareoDocumentsMutationVariables
>;

/**
 * __useUploadKareoDocumentsMutation__
 *
 * To run a mutation, you first call `useUploadKareoDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadKareoDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadKareoDocumentsMutation, { data, loading, error }] = useUploadKareoDocumentsMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useUploadKareoDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadKareoDocumentsMutation,
    UploadKareoDocumentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadKareoDocumentsMutation,
    UploadKareoDocumentsMutationVariables
  >(UploadKareoDocumentsDocument, options);
}
export type UploadKareoDocumentsMutationHookResult = ReturnType<
  typeof useUploadKareoDocumentsMutation
>;
export type UploadKareoDocumentsMutationResult =
  Apollo.MutationResult<UploadKareoDocumentsMutation>;
export type UploadKareoDocumentsMutationOptions = Apollo.BaseMutationOptions<
  UploadKareoDocumentsMutation,
  UploadKareoDocumentsMutationVariables
>;
export const CreateKareoAppointmentDocument = gql`
  mutation CreateKareoAppointment(
    $appointmentId: String!
    $kareoClinic: String!
    $kareoProvider: String!
    $kareoType: String!
  ) {
    CreateKareoAppointment(
      appointmentId: $appointmentId
      kareoProvider: $kareoProvider
      kareoType: $kareoType
      kareoClinic: $kareoClinic
    ) {
      success
    }
  }
`;
export type CreateKareoAppointmentMutationFn = Apollo.MutationFunction<
  CreateKareoAppointmentMutation,
  CreateKareoAppointmentMutationVariables
>;

/**
 * __useCreateKareoAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateKareoAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKareoAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKareoAppointmentMutation, { data, loading, error }] = useCreateKareoAppointmentMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      kareoClinic: // value for 'kareoClinic'
 *      kareoProvider: // value for 'kareoProvider'
 *      kareoType: // value for 'kareoType'
 *   },
 * });
 */
export function useCreateKareoAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateKareoAppointmentMutation,
    CreateKareoAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateKareoAppointmentMutation,
    CreateKareoAppointmentMutationVariables
  >(CreateKareoAppointmentDocument, options);
}
export type CreateKareoAppointmentMutationHookResult = ReturnType<
  typeof useCreateKareoAppointmentMutation
>;
export type CreateKareoAppointmentMutationResult =
  Apollo.MutationResult<CreateKareoAppointmentMutation>;
export type CreateKareoAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CreateKareoAppointmentMutation,
  CreateKareoAppointmentMutationVariables
>;
export const UpdateKareoPatientDocument = gql`
  mutation UpdateKareoPatient($patientId: String!) {
    UpdateKareoPatient(patientId: $patientId) {
      success
    }
  }
`;
export type UpdateKareoPatientMutationFn = Apollo.MutationFunction<
  UpdateKareoPatientMutation,
  UpdateKareoPatientMutationVariables
>;

/**
 * __useUpdateKareoPatientMutation__
 *
 * To run a mutation, you first call `useUpdateKareoPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKareoPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKareoPatientMutation, { data, loading, error }] = useUpdateKareoPatientMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useUpdateKareoPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateKareoPatientMutation,
    UpdateKareoPatientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateKareoPatientMutation,
    UpdateKareoPatientMutationVariables
  >(UpdateKareoPatientDocument, options);
}
export type UpdateKareoPatientMutationHookResult = ReturnType<
  typeof useUpdateKareoPatientMutation
>;
export type UpdateKareoPatientMutationResult =
  Apollo.MutationResult<UpdateKareoPatientMutation>;
export type UpdateKareoPatientMutationOptions = Apollo.BaseMutationOptions<
  UpdateKareoPatientMutation,
  UpdateKareoPatientMutationVariables
>;
export const WatchForKareoActionsDocument = gql`
  subscription WatchForKareoActions($query_start: timestamptz!) {
    kareo_logs(
      where: { status: { _eq: "RUNNING" }, created_at: { _gte: $query_start } }
    ) {
      id
    }
  }
`;

/**
 * __useWatchForKareoActionsSubscription__
 *
 * To run a query within a React component, call `useWatchForKareoActionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchForKareoActionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchForKareoActionsSubscription({
 *   variables: {
 *      query_start: // value for 'query_start'
 *   },
 * });
 */
export function useWatchForKareoActionsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    WatchForKareoActionsSubscription,
    WatchForKareoActionsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchForKareoActionsSubscription,
    WatchForKareoActionsSubscriptionVariables
  >(WatchForKareoActionsDocument, options);
}
export type WatchForKareoActionsSubscriptionHookResult = ReturnType<
  typeof useWatchForKareoActionsSubscription
>;
export type WatchForKareoActionsSubscriptionResult =
  Apollo.SubscriptionResult<WatchForKareoActionsSubscription>;
export const GetLogsDocument = gql`
  query GetLogs(
    $limit: Int = 10
    $offset: Int = 10
    $order_by: [kareo_logs_order_by!] = { created_at: asc }
  ) {
    kareo_logs(limit: $limit, offset: $offset, order_by: $order_by) {
      created_at
      id
      patient_id
      request_action
      staff
      status
    }
  }
`;

/**
 * __useGetLogsQuery__
 *
 * To run a query within a React component, call `useGetLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLogsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetLogsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLogsQuery, GetLogsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLogsQuery, GetLogsQueryVariables>(
    GetLogsDocument,
    options,
  );
}
export function useGetLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLogsQuery,
    GetLogsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLogsQuery, GetLogsQueryVariables>(
    GetLogsDocument,
    options,
  );
}
export type GetLogsQueryHookResult = ReturnType<typeof useGetLogsQuery>;
export type GetLogsLazyQueryHookResult = ReturnType<typeof useGetLogsLazyQuery>;
export type GetLogsQueryResult = Apollo.QueryResult<
  GetLogsQuery,
  GetLogsQueryVariables
>;
export const FindLocationsDocument = gql`
  query FindLocations(
    $placeTypes: [String!]!
    $value: String!
    $location: String
  ) {
    SearchLocations(
      placeTypes: $placeTypes
      value: $value
      location: $location
    ) {
      formatted_address
      name
      place_id
    }
  }
`;

/**
 * __useFindLocationsQuery__
 *
 * To run a query within a React component, call `useFindLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindLocationsQuery({
 *   variables: {
 *      placeTypes: // value for 'placeTypes'
 *      value: // value for 'value'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useFindLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindLocationsQuery,
    FindLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindLocationsQuery, FindLocationsQueryVariables>(
    FindLocationsDocument,
    options,
  );
}
export function useFindLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindLocationsQuery,
    FindLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindLocationsQuery, FindLocationsQueryVariables>(
    FindLocationsDocument,
    options,
  );
}
export type FindLocationsQueryHookResult = ReturnType<
  typeof useFindLocationsQuery
>;
export type FindLocationsLazyQueryHookResult = ReturnType<
  typeof useFindLocationsLazyQuery
>;
export type FindLocationsQueryResult = Apollo.QueryResult<
  FindLocationsQuery,
  FindLocationsQueryVariables
>;
export const GetPlaceDocument = gql`
  query GetPlace($placeId: String!) {
    FindPlace(placeId: $placeId) {
      address_components {
        short_name
        types
      }
      geometry {
        location {
          lat
          lng
        }
      }
      formatted_address
      name
      place_id
    }
  }
`;

/**
 * __useGetPlaceQuery__
 *
 * To run a query within a React component, call `useGetPlaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useGetPlaceQuery(
  baseOptions: Apollo.QueryHookOptions<GetPlaceQuery, GetPlaceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlaceQuery, GetPlaceQueryVariables>(
    GetPlaceDocument,
    options,
  );
}
export function useGetPlaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlaceQuery,
    GetPlaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlaceQuery, GetPlaceQueryVariables>(
    GetPlaceDocument,
    options,
  );
}
export type GetPlaceQueryHookResult = ReturnType<typeof useGetPlaceQuery>;
export type GetPlaceLazyQueryHookResult = ReturnType<
  typeof useGetPlaceLazyQuery
>;
export type GetPlaceQueryResult = Apollo.QueryResult<
  GetPlaceQuery,
  GetPlaceQueryVariables
>;
export const GetFullPlaceDocument = gql`
  query GetFullPlace($placeId: String!) {
    FindPlace(placeId: $placeId) {
      place_id
      adr_address
      formatted_address
      formatted_phone_number
      icon
      id
      name
      scope
      reference
      types
      url
      vicinity
      utc_offset
      geometry {
        location {
          lng
          lat
        }
        viewport {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
      address_components {
        long_name
        short_name
        types
      }
    }
  }
`;

/**
 * __useGetFullPlaceQuery__
 *
 * To run a query within a React component, call `useGetFullPlaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullPlaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullPlaceQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useGetFullPlaceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFullPlaceQuery,
    GetFullPlaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFullPlaceQuery, GetFullPlaceQueryVariables>(
    GetFullPlaceDocument,
    options,
  );
}
export function useGetFullPlaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFullPlaceQuery,
    GetFullPlaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFullPlaceQuery, GetFullPlaceQueryVariables>(
    GetFullPlaceDocument,
    options,
  );
}
export type GetFullPlaceQueryHookResult = ReturnType<
  typeof useGetFullPlaceQuery
>;
export type GetFullPlaceLazyQueryHookResult = ReturnType<
  typeof useGetFullPlaceLazyQuery
>;
export type GetFullPlaceQueryResult = Apollo.QueryResult<
  GetFullPlaceQuery,
  GetFullPlaceQueryVariables
>;
export const SearchAccountsAndPatientsDocument = gql`
  query SearchAccountsAndPatients($search: String!, $limit: Int!) {
    search_accounts(args: { search: $search }, limit: $limit) {
      ...Account
      account_patients {
        ...AccountPatient
        patient {
          ...Patient
        }
      }
    }
    search_patients(
      args: { search: $search }
      limit: $limit
      where: { migratedToPatientId: { _is_null: true } }
    ) {
      ...Patient
      appointments(limit: 3, order_by: { startTime: desc }) {
        ...Appointment
      }
      accounts {
        ...AccountPatient
        account {
          ...Account
        }
      }
    }
  }
  ${AccountFragmentDoc}
  ${AccountPatientFragmentDoc}
  ${PatientFragmentDoc}
  ${AppointmentFragmentDoc}
`;

/**
 * __useSearchAccountsAndPatientsQuery__
 *
 * To run a query within a React component, call `useSearchAccountsAndPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAccountsAndPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAccountsAndPatientsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchAccountsAndPatientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAccountsAndPatientsQuery,
    SearchAccountsAndPatientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchAccountsAndPatientsQuery,
    SearchAccountsAndPatientsQueryVariables
  >(SearchAccountsAndPatientsDocument, options);
}
export function useSearchAccountsAndPatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAccountsAndPatientsQuery,
    SearchAccountsAndPatientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchAccountsAndPatientsQuery,
    SearchAccountsAndPatientsQueryVariables
  >(SearchAccountsAndPatientsDocument, options);
}
export type SearchAccountsAndPatientsQueryHookResult = ReturnType<
  typeof useSearchAccountsAndPatientsQuery
>;
export type SearchAccountsAndPatientsLazyQueryHookResult = ReturnType<
  typeof useSearchAccountsAndPatientsLazyQuery
>;
export type SearchAccountsAndPatientsQueryResult = Apollo.QueryResult<
  SearchAccountsAndPatientsQuery,
  SearchAccountsAndPatientsQueryVariables
>;
export const GetDuplicatePatientsDocument = gql`
  query GetDuplicatePatients {
    patient_duplicates {
      id
      firstName
      lastName
      dateOfBirth
      avatarId
      createdAt
      updatedAt
    }
    patient_not_duplicates {
      patient1_id
      patient2_id
      id
      created_at
      updated_at
    }
  }
`;

/**
 * __useGetDuplicatePatientsQuery__
 *
 * To run a query within a React component, call `useGetDuplicatePatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDuplicatePatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDuplicatePatientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDuplicatePatientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDuplicatePatientsQuery,
    GetDuplicatePatientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDuplicatePatientsQuery,
    GetDuplicatePatientsQueryVariables
  >(GetDuplicatePatientsDocument, options);
}
export function useGetDuplicatePatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDuplicatePatientsQuery,
    GetDuplicatePatientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDuplicatePatientsQuery,
    GetDuplicatePatientsQueryVariables
  >(GetDuplicatePatientsDocument, options);
}
export type GetDuplicatePatientsQueryHookResult = ReturnType<
  typeof useGetDuplicatePatientsQuery
>;
export type GetDuplicatePatientsLazyQueryHookResult = ReturnType<
  typeof useGetDuplicatePatientsLazyQuery
>;
export type GetDuplicatePatientsQueryResult = Apollo.QueryResult<
  GetDuplicatePatientsQuery,
  GetDuplicatePatientsQueryVariables
>;
export const InsertNotDuplicateDocument = gql`
  mutation InsertNotDuplicate($patient1_id: uuid!, $patient2_id: uuid!) {
    insert_patient_not_duplicates_one(
      object: { patient1_id: $patient1_id, patient2_id: $patient2_id }
    ) {
      updated_at
      patient2_id
      patient1_id
      id
      created_at
    }
  }
`;
export type InsertNotDuplicateMutationFn = Apollo.MutationFunction<
  InsertNotDuplicateMutation,
  InsertNotDuplicateMutationVariables
>;

/**
 * __useInsertNotDuplicateMutation__
 *
 * To run a mutation, you first call `useInsertNotDuplicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertNotDuplicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNotDuplicateMutation, { data, loading, error }] = useInsertNotDuplicateMutation({
 *   variables: {
 *      patient1_id: // value for 'patient1_id'
 *      patient2_id: // value for 'patient2_id'
 *   },
 * });
 */
export function useInsertNotDuplicateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertNotDuplicateMutation,
    InsertNotDuplicateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertNotDuplicateMutation,
    InsertNotDuplicateMutationVariables
  >(InsertNotDuplicateDocument, options);
}
export type InsertNotDuplicateMutationHookResult = ReturnType<
  typeof useInsertNotDuplicateMutation
>;
export type InsertNotDuplicateMutationResult =
  Apollo.MutationResult<InsertNotDuplicateMutation>;
export type InsertNotDuplicateMutationOptions = Apollo.BaseMutationOptions<
  InsertNotDuplicateMutation,
  InsertNotDuplicateMutationVariables
>;
export const GetPatientByPkAndNotesDocument = gql`
  query GetPatientByPkAndNotes($patientId: uuid!) {
    patients_by_pk(id: $patientId) {
      ...PatientParts
    }
    global_notes(where: { patientId: { _eq: $patientId } }) {
      note
      staff {
        firstName
        lastName
      }
      createdAt
    }
  }
  ${PatientPartsFragmentDoc}
`;

/**
 * __useGetPatientByPkAndNotesQuery__
 *
 * To run a query within a React component, call `useGetPatientByPkAndNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientByPkAndNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientByPkAndNotesQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPatientByPkAndNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPatientByPkAndNotesQuery,
    GetPatientByPkAndNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPatientByPkAndNotesQuery,
    GetPatientByPkAndNotesQueryVariables
  >(GetPatientByPkAndNotesDocument, options);
}
export function useGetPatientByPkAndNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientByPkAndNotesQuery,
    GetPatientByPkAndNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPatientByPkAndNotesQuery,
    GetPatientByPkAndNotesQueryVariables
  >(GetPatientByPkAndNotesDocument, options);
}
export type GetPatientByPkAndNotesQueryHookResult = ReturnType<
  typeof useGetPatientByPkAndNotesQuery
>;
export type GetPatientByPkAndNotesLazyQueryHookResult = ReturnType<
  typeof useGetPatientByPkAndNotesLazyQuery
>;
export type GetPatientByPkAndNotesQueryResult = Apollo.QueryResult<
  GetPatientByPkAndNotesQuery,
  GetPatientByPkAndNotesQueryVariables
>;
export const SendTextNotificationDocument = gql`
  mutation SendTextNotification($to: String!, $message: String!) {
    SendTextNotification(to: $to, message: $message) {
      success
    }
  }
`;
export type SendTextNotificationMutationFn = Apollo.MutationFunction<
  SendTextNotificationMutation,
  SendTextNotificationMutationVariables
>;

/**
 * __useSendTextNotificationMutation__
 *
 * To run a mutation, you first call `useSendTextNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTextNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTextNotificationMutation, { data, loading, error }] = useSendTextNotificationMutation({
 *   variables: {
 *      to: // value for 'to'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendTextNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendTextNotificationMutation,
    SendTextNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendTextNotificationMutation,
    SendTextNotificationMutationVariables
  >(SendTextNotificationDocument, options);
}
export type SendTextNotificationMutationHookResult = ReturnType<
  typeof useSendTextNotificationMutation
>;
export type SendTextNotificationMutationResult =
  Apollo.MutationResult<SendTextNotificationMutation>;
export type SendTextNotificationMutationOptions = Apollo.BaseMutationOptions<
  SendTextNotificationMutation,
  SendTextNotificationMutationVariables
>;
export const TrackActionDocument = gql`
  mutation TrackAction($type: String!, $data: jsonb!) {
    TrackAction(event: { type: $type, data: $data }) {
      success
    }
  }
`;
export type TrackActionMutationFn = Apollo.MutationFunction<
  TrackActionMutation,
  TrackActionMutationVariables
>;

/**
 * __useTrackActionMutation__
 *
 * To run a mutation, you first call `useTrackActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackActionMutation, { data, loading, error }] = useTrackActionMutation({
 *   variables: {
 *      type: // value for 'type'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTrackActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackActionMutation,
    TrackActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TrackActionMutation, TrackActionMutationVariables>(
    TrackActionDocument,
    options,
  );
}
export type TrackActionMutationHookResult = ReturnType<
  typeof useTrackActionMutation
>;
export type TrackActionMutationResult =
  Apollo.MutationResult<TrackActionMutation>;
export type TrackActionMutationOptions = Apollo.BaseMutationOptions<
  TrackActionMutation,
  TrackActionMutationVariables
>;
export const GetPatientByPkDocument = gql`
  query GetPatientByPk($patientId: uuid!) {
    patients_by_pk(id: $patientId) {
      ...PatientParts
    }
  }
  ${PatientPartsFragmentDoc}
`;

/**
 * __useGetPatientByPkQuery__
 *
 * To run a query within a React component, call `useGetPatientByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientByPkQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPatientByPkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPatientByPkQuery,
    GetPatientByPkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPatientByPkQuery, GetPatientByPkQueryVariables>(
    GetPatientByPkDocument,
    options,
  );
}
export function useGetPatientByPkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientByPkQuery,
    GetPatientByPkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPatientByPkQuery, GetPatientByPkQueryVariables>(
    GetPatientByPkDocument,
    options,
  );
}
export type GetPatientByPkQueryHookResult = ReturnType<
  typeof useGetPatientByPkQuery
>;
export type GetPatientByPkLazyQueryHookResult = ReturnType<
  typeof useGetPatientByPkLazyQuery
>;
export type GetPatientByPkQueryResult = Apollo.QueryResult<
  GetPatientByPkQuery,
  GetPatientByPkQueryVariables
>;
export const GetPatientInfoByPkDocument = gql`
  query GetPatientInfoByPk($patientId: uuid!) {
    patients_by_pk(id: $patientId) {
      address(path: "formatted_address")
      dateOfBirth
      firstName
      goesByName
      lastName
      preferredPronoun
      preferredPronounType
      accounts {
        accountId
        relationship
        account {
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useGetPatientInfoByPkQuery__
 *
 * To run a query within a React component, call `useGetPatientInfoByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientInfoByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientInfoByPkQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPatientInfoByPkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPatientInfoByPkQuery,
    GetPatientInfoByPkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPatientInfoByPkQuery,
    GetPatientInfoByPkQueryVariables
  >(GetPatientInfoByPkDocument, options);
}
export function useGetPatientInfoByPkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientInfoByPkQuery,
    GetPatientInfoByPkQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPatientInfoByPkQuery,
    GetPatientInfoByPkQueryVariables
  >(GetPatientInfoByPkDocument, options);
}
export type GetPatientInfoByPkQueryHookResult = ReturnType<
  typeof useGetPatientInfoByPkQuery
>;
export type GetPatientInfoByPkLazyQueryHookResult = ReturnType<
  typeof useGetPatientInfoByPkLazyQuery
>;
export type GetPatientInfoByPkQueryResult = Apollo.QueryResult<
  GetPatientInfoByPkQuery,
  GetPatientInfoByPkQueryVariables
>;
export const UpdatePatientByPkDocument = gql`
  mutation UpdatePatientByPk($id: uuid!, $_set: patients_set_input!) {
    update_patients_by_pk(pk_columns: { id: $id }, _set: $_set) {
      ...PatientParts
    }
  }
  ${PatientPartsFragmentDoc}
`;
export type UpdatePatientByPkMutationFn = Apollo.MutationFunction<
  UpdatePatientByPkMutation,
  UpdatePatientByPkMutationVariables
>;

/**
 * __useUpdatePatientByPkMutation__
 *
 * To run a mutation, you first call `useUpdatePatientByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientByPkMutation, { data, loading, error }] = useUpdatePatientByPkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdatePatientByPkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatientByPkMutation,
    UpdatePatientByPkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePatientByPkMutation,
    UpdatePatientByPkMutationVariables
  >(UpdatePatientByPkDocument, options);
}
export type UpdatePatientByPkMutationHookResult = ReturnType<
  typeof useUpdatePatientByPkMutation
>;
export type UpdatePatientByPkMutationResult =
  Apollo.MutationResult<UpdatePatientByPkMutation>;
export type UpdatePatientByPkMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatientByPkMutation,
  UpdatePatientByPkMutationVariables
>;
export const UpdatePatientDocument = gql`
  mutation UpdatePatient(
    $set: patients_set_input!
    $where: patients_bool_exp!
  ) {
    update_patients(_set: $set, where: $where) {
      returning {
        id
        firstName
        lastName
        goesByName
        dateOfBirth
        preferredPronoun
        preferredPronounType
        genderAtBirth
        primaryClinicAddress
        physicianName
        preferredPharmacy
        address
      }
    }
  }
`;
export type UpdatePatientMutationFn = Apollo.MutationFunction<
  UpdatePatientMutation,
  UpdatePatientMutationVariables
>;

/**
 * __useUpdatePatientMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMutation, { data, loading, error }] = useUpdatePatientMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdatePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatientMutation,
    UpdatePatientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePatientMutation,
    UpdatePatientMutationVariables
  >(UpdatePatientDocument, options);
}
export type UpdatePatientMutationHookResult = ReturnType<
  typeof useUpdatePatientMutation
>;
export type UpdatePatientMutationResult =
  Apollo.MutationResult<UpdatePatientMutation>;
export type UpdatePatientMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatientMutation,
  UpdatePatientMutationVariables
>;
export const UpdateAccountPatientRelationshipDocument = gql`
  mutation UpdateAccountPatientRelationship(
    $accountId: uuid!
    $patientId: uuid!
    $relationship: String!
  ) {
    update_account_patient(
      where: { accountId: { _eq: $accountId }, patientId: { _eq: $patientId } }
      _set: { relationship: $relationship }
    ) {
      returning {
        id
        relationship
        patientId
        accountId
      }
    }
  }
`;
export type UpdateAccountPatientRelationshipMutationFn =
  Apollo.MutationFunction<
    UpdateAccountPatientRelationshipMutation,
    UpdateAccountPatientRelationshipMutationVariables
  >;

/**
 * __useUpdateAccountPatientRelationshipMutation__
 *
 * To run a mutation, you first call `useUpdateAccountPatientRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPatientRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPatientRelationshipMutation, { data, loading, error }] = useUpdateAccountPatientRelationshipMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      patientId: // value for 'patientId'
 *      relationship: // value for 'relationship'
 *   },
 * });
 */
export function useUpdateAccountPatientRelationshipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountPatientRelationshipMutation,
    UpdateAccountPatientRelationshipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccountPatientRelationshipMutation,
    UpdateAccountPatientRelationshipMutationVariables
  >(UpdateAccountPatientRelationshipDocument, options);
}
export type UpdateAccountPatientRelationshipMutationHookResult = ReturnType<
  typeof useUpdateAccountPatientRelationshipMutation
>;
export type UpdateAccountPatientRelationshipMutationResult =
  Apollo.MutationResult<UpdateAccountPatientRelationshipMutation>;
export type UpdateAccountPatientRelationshipMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAccountPatientRelationshipMutation,
    UpdateAccountPatientRelationshipMutationVariables
  >;
export const GetMedicalRecordsReleaseFormDocument = gql`
  query GetMedicalRecordsReleaseForm($patientId: String!) {
    GetDocument(documentType: "RECORDS_RELEASE", patientId: $patientId) {
      id
      updatedAt
      url
      account {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useGetMedicalRecordsReleaseFormQuery__
 *
 * To run a query within a React component, call `useGetMedicalRecordsReleaseFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMedicalRecordsReleaseFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMedicalRecordsReleaseFormQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetMedicalRecordsReleaseFormQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMedicalRecordsReleaseFormQuery,
    GetMedicalRecordsReleaseFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMedicalRecordsReleaseFormQuery,
    GetMedicalRecordsReleaseFormQueryVariables
  >(GetMedicalRecordsReleaseFormDocument, options);
}
export function useGetMedicalRecordsReleaseFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMedicalRecordsReleaseFormQuery,
    GetMedicalRecordsReleaseFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMedicalRecordsReleaseFormQuery,
    GetMedicalRecordsReleaseFormQueryVariables
  >(GetMedicalRecordsReleaseFormDocument, options);
}
export type GetMedicalRecordsReleaseFormQueryHookResult = ReturnType<
  typeof useGetMedicalRecordsReleaseFormQuery
>;
export type GetMedicalRecordsReleaseFormLazyQueryHookResult = ReturnType<
  typeof useGetMedicalRecordsReleaseFormLazyQuery
>;
export type GetMedicalRecordsReleaseFormQueryResult = Apollo.QueryResult<
  GetMedicalRecordsReleaseFormQuery,
  GetMedicalRecordsReleaseFormQueryVariables
>;
export const MergePatientsDocument = gql`
  mutation MergePatients(
    $fromPatientId: String!
    $toPatientId: String!
    $copyFields: [String]
  ) {
    MergePatients(
      fromPatientId: $fromPatientId
      toPatientId: $toPatientId
      copyFields: $copyFields
    ) {
      patientId
    }
  }
`;
export type MergePatientsMutationFn = Apollo.MutationFunction<
  MergePatientsMutation,
  MergePatientsMutationVariables
>;

/**
 * __useMergePatientsMutation__
 *
 * To run a mutation, you first call `useMergePatientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergePatientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergePatientsMutation, { data, loading, error }] = useMergePatientsMutation({
 *   variables: {
 *      fromPatientId: // value for 'fromPatientId'
 *      toPatientId: // value for 'toPatientId'
 *      copyFields: // value for 'copyFields'
 *   },
 * });
 */
export function useMergePatientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MergePatientsMutation,
    MergePatientsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MergePatientsMutation,
    MergePatientsMutationVariables
  >(MergePatientsDocument, options);
}
export type MergePatientsMutationHookResult = ReturnType<
  typeof useMergePatientsMutation
>;
export type MergePatientsMutationResult =
  Apollo.MutationResult<MergePatientsMutation>;
export type MergePatientsMutationOptions = Apollo.BaseMutationOptions<
  MergePatientsMutation,
  MergePatientsMutationVariables
>;
export const GetPatientChargesDocument = gql`
  query GetPatientCharges($where: charges_bool_exp!) {
    charges(where: $where) {
      ...Charge
      charge_products {
        ...ChargeProduct
        product {
          ...Product
        }
      }
    }
  }
  ${ChargeFragmentDoc}
  ${ChargeProductFragmentDoc}
  ${ProductFragmentDoc}
`;

/**
 * __useGetPatientChargesQuery__
 *
 * To run a query within a React component, call `useGetPatientChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientChargesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPatientChargesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPatientChargesQuery,
    GetPatientChargesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPatientChargesQuery,
    GetPatientChargesQueryVariables
  >(GetPatientChargesDocument, options);
}
export function useGetPatientChargesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientChargesQuery,
    GetPatientChargesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPatientChargesQuery,
    GetPatientChargesQueryVariables
  >(GetPatientChargesDocument, options);
}
export type GetPatientChargesQueryHookResult = ReturnType<
  typeof useGetPatientChargesQuery
>;
export type GetPatientChargesLazyQueryHookResult = ReturnType<
  typeof useGetPatientChargesLazyQuery
>;
export type GetPatientChargesQueryResult = Apollo.QueryResult<
  GetPatientChargesQuery,
  GetPatientChargesQueryVariables
>;
export const AddPatientTelehealthConsentDocument = gql`
  mutation AddPatientTelehealthConsent(
    $accompanyingAccountId: String!
    $patientId: String!
    $appointmentId: String
  ) {
    AddPatientTelehealthConsent(
      accompanyingAccountId: $accompanyingAccountId
      patientId: $patientId
      appointmentId: $appointmentId
    ) {
      id
    }
  }
`;
export type AddPatientTelehealthConsentMutationFn = Apollo.MutationFunction<
  AddPatientTelehealthConsentMutation,
  AddPatientTelehealthConsentMutationVariables
>;

/**
 * __useAddPatientTelehealthConsentMutation__
 *
 * To run a mutation, you first call `useAddPatientTelehealthConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPatientTelehealthConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPatientTelehealthConsentMutation, { data, loading, error }] = useAddPatientTelehealthConsentMutation({
 *   variables: {
 *      accompanyingAccountId: // value for 'accompanyingAccountId'
 *      patientId: // value for 'patientId'
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useAddPatientTelehealthConsentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPatientTelehealthConsentMutation,
    AddPatientTelehealthConsentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPatientTelehealthConsentMutation,
    AddPatientTelehealthConsentMutationVariables
  >(AddPatientTelehealthConsentDocument, options);
}
export type AddPatientTelehealthConsentMutationHookResult = ReturnType<
  typeof useAddPatientTelehealthConsentMutation
>;
export type AddPatientTelehealthConsentMutationResult =
  Apollo.MutationResult<AddPatientTelehealthConsentMutation>;
export type AddPatientTelehealthConsentMutationOptions =
  Apollo.BaseMutationOptions<
    AddPatientTelehealthConsentMutation,
    AddPatientTelehealthConsentMutationVariables
  >;
export const RemoveAccountFromPatientDocument = gql`
  mutation RemoveAccountFromPatient($accountId: uuid!, $patientId: uuid!) {
    delete_account_patient(
      where: { accountId: { _eq: $accountId }, patientId: { _eq: $patientId } }
    ) {
      affected_rows
    }
  }
`;
export type RemoveAccountFromPatientMutationFn = Apollo.MutationFunction<
  RemoveAccountFromPatientMutation,
  RemoveAccountFromPatientMutationVariables
>;

/**
 * __useRemoveAccountFromPatientMutation__
 *
 * To run a mutation, you first call `useRemoveAccountFromPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountFromPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountFromPatientMutation, { data, loading, error }] = useRemoveAccountFromPatientMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useRemoveAccountFromPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAccountFromPatientMutation,
    RemoveAccountFromPatientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAccountFromPatientMutation,
    RemoveAccountFromPatientMutationVariables
  >(RemoveAccountFromPatientDocument, options);
}
export type RemoveAccountFromPatientMutationHookResult = ReturnType<
  typeof useRemoveAccountFromPatientMutation
>;
export type RemoveAccountFromPatientMutationResult =
  Apollo.MutationResult<RemoveAccountFromPatientMutation>;
export type RemoveAccountFromPatientMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveAccountFromPatientMutation,
    RemoveAccountFromPatientMutationVariables
  >;
export const GetAccountsForPatientDocument = gql`
  query GetAccountsForPatient($patientId: uuid!) {
    accounts(where: { account_patients: { patientId: { _eq: $patientId } } }) {
      ...FullAccount
    }
  }
  ${FullAccountFragmentDoc}
`;

/**
 * __useGetAccountsForPatientQuery__
 *
 * To run a query within a React component, call `useGetAccountsForPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsForPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsForPatientQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetAccountsForPatientQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAccountsForPatientQuery,
    GetAccountsForPatientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountsForPatientQuery,
    GetAccountsForPatientQueryVariables
  >(GetAccountsForPatientDocument, options);
}
export function useGetAccountsForPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountsForPatientQuery,
    GetAccountsForPatientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountsForPatientQuery,
    GetAccountsForPatientQueryVariables
  >(GetAccountsForPatientDocument, options);
}
export type GetAccountsForPatientQueryHookResult = ReturnType<
  typeof useGetAccountsForPatientQuery
>;
export type GetAccountsForPatientLazyQueryHookResult = ReturnType<
  typeof useGetAccountsForPatientLazyQuery
>;
export type GetAccountsForPatientQueryResult = Apollo.QueryResult<
  GetAccountsForPatientQuery,
  GetAccountsForPatientQueryVariables
>;
export const FindPatientsDocument = gql`
  query FindPatients($where: patients_bool_exp!) {
    patients(where: $where) {
      ...Patient
    }
  }
  ${PatientFragmentDoc}
`;

/**
 * __useFindPatientsQuery__
 *
 * To run a query within a React component, call `useFindPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPatientsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindPatientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindPatientsQuery,
    FindPatientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindPatientsQuery, FindPatientsQueryVariables>(
    FindPatientsDocument,
    options,
  );
}
export function useFindPatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindPatientsQuery,
    FindPatientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindPatientsQuery, FindPatientsQueryVariables>(
    FindPatientsDocument,
    options,
  );
}
export type FindPatientsQueryHookResult = ReturnType<
  typeof useFindPatientsQuery
>;
export type FindPatientsLazyQueryHookResult = ReturnType<
  typeof useFindPatientsLazyQuery
>;
export type FindPatientsQueryResult = Apollo.QueryResult<
  FindPatientsQuery,
  FindPatientsQueryVariables
>;
export const PatientHeaderDocument = gql`
  query PatientHeader($patientId: uuid!) {
    patients_by_pk(id: $patientId) {
      ...Patient
      ...PatientAvatar
      accounts {
        ...AccountPatient
        account {
          ...Account
        }
      }
      insurances {
        ...Insurance
      }
      allergies: fhir_resources(
        where: { resourceType: { _eq: AllergyIntolerance } }
      ) {
        id
        resource
      }
      medications: fhir_resources(
        where: { resourceType: { _eq: Medication } }
      ) {
        id
        resource
      }
      observations: fhir_resources(
        where: { resourceType: { _eq: Observation } }
      ) {
        id
        resource
      }
      problems: fhir_resources(where: { resourceType: { _eq: Condition } }) {
        id
        resource
      }
      global_notes {
        ...GlobalNote
        staff {
          firstName
          lastName
          auth0UserId
          avatarUrl
        }
      }
    }
  }
  ${PatientFragmentDoc}
  ${PatientAvatarFragmentDoc}
  ${AccountPatientFragmentDoc}
  ${AccountFragmentDoc}
  ${InsuranceFragmentDoc}
  ${GlobalNoteFragmentDoc}
`;

/**
 * __usePatientHeaderQuery__
 *
 * To run a query within a React component, call `usePatientHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientHeaderQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientHeaderQuery(
  baseOptions: Apollo.QueryHookOptions<
    PatientHeaderQuery,
    PatientHeaderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientHeaderQuery, PatientHeaderQueryVariables>(
    PatientHeaderDocument,
    options,
  );
}
export function usePatientHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientHeaderQuery,
    PatientHeaderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientHeaderQuery, PatientHeaderQueryVariables>(
    PatientHeaderDocument,
    options,
  );
}
export type PatientHeaderQueryHookResult = ReturnType<
  typeof usePatientHeaderQuery
>;
export type PatientHeaderLazyQueryHookResult = ReturnType<
  typeof usePatientHeaderLazyQuery
>;
export type PatientHeaderQueryResult = Apollo.QueryResult<
  PatientHeaderQuery,
  PatientHeaderQueryVariables
>;
export const GetPaymentInformationDocument = gql`
  query GetPaymentInformation(
    $stripeCardId: String!
    $stripeCustomerId: String!
  ) {
    StripePaymentMethod(
      stripeCardId: $stripeCardId
      stripeCustomerId: $stripeCustomerId
    ) {
      id
      brand
      last4
    }
  }
`;

/**
 * __useGetPaymentInformationQuery__
 *
 * To run a query within a React component, call `useGetPaymentInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentInformationQuery({
 *   variables: {
 *      stripeCardId: // value for 'stripeCardId'
 *      stripeCustomerId: // value for 'stripeCustomerId'
 *   },
 * });
 */
export function useGetPaymentInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentInformationQuery,
    GetPaymentInformationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaymentInformationQuery,
    GetPaymentInformationQueryVariables
  >(GetPaymentInformationDocument, options);
}
export function useGetPaymentInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentInformationQuery,
    GetPaymentInformationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaymentInformationQuery,
    GetPaymentInformationQueryVariables
  >(GetPaymentInformationDocument, options);
}
export type GetPaymentInformationQueryHookResult = ReturnType<
  typeof useGetPaymentInformationQuery
>;
export type GetPaymentInformationLazyQueryHookResult = ReturnType<
  typeof useGetPaymentInformationLazyQuery
>;
export type GetPaymentInformationQueryResult = Apollo.QueryResult<
  GetPaymentInformationQuery,
  GetPaymentInformationQueryVariables
>;
export const GetPaymentMethodsByAccountDocument = gql`
  query GetPaymentMethodsByAccount($accountId: String!) {
    StripePaymentMethods(accountId: $accountId) {
      id
      brand
      country
      customer
      exp_month
      exp_year
      fingerprint
      funding
      last4
    }
  }
`;

/**
 * __useGetPaymentMethodsByAccountQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsByAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetPaymentMethodsByAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentMethodsByAccountQuery,
    GetPaymentMethodsByAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaymentMethodsByAccountQuery,
    GetPaymentMethodsByAccountQueryVariables
  >(GetPaymentMethodsByAccountDocument, options);
}
export function useGetPaymentMethodsByAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentMethodsByAccountQuery,
    GetPaymentMethodsByAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaymentMethodsByAccountQuery,
    GetPaymentMethodsByAccountQueryVariables
  >(GetPaymentMethodsByAccountDocument, options);
}
export type GetPaymentMethodsByAccountQueryHookResult = ReturnType<
  typeof useGetPaymentMethodsByAccountQuery
>;
export type GetPaymentMethodsByAccountLazyQueryHookResult = ReturnType<
  typeof useGetPaymentMethodsByAccountLazyQuery
>;
export type GetPaymentMethodsByAccountQueryResult = Apollo.QueryResult<
  GetPaymentMethodsByAccountQuery,
  GetPaymentMethodsByAccountQueryVariables
>;
export const OneTimeChargeDocument = gql`
  mutation OneTimeCharge(
    $appointmentId: String
    $cardToken: String!
    $amount: String!
    $reason: String
    $email: String
    $firstName: String
    $lastName: String
    $dateOfBirth: String
    $patientId: String
    $products: [ProductPayload]
  ) {
    OneTimeCharge(
      cardToken: $cardToken
      appointmentId: $appointmentId
      amount: $amount
      reason: $reason
      email: $email
      firstName: $firstName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      patientId: $patientId
      products: $products
    ) {
      id
      receiptUrl
    }
  }
`;
export type OneTimeChargeMutationFn = Apollo.MutationFunction<
  OneTimeChargeMutation,
  OneTimeChargeMutationVariables
>;

/**
 * __useOneTimeChargeMutation__
 *
 * To run a mutation, you first call `useOneTimeChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOneTimeChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oneTimeChargeMutation, { data, loading, error }] = useOneTimeChargeMutation({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *      cardToken: // value for 'cardToken'
 *      amount: // value for 'amount'
 *      reason: // value for 'reason'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      patientId: // value for 'patientId'
 *      products: // value for 'products'
 *   },
 * });
 */
export function useOneTimeChargeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OneTimeChargeMutation,
    OneTimeChargeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OneTimeChargeMutation,
    OneTimeChargeMutationVariables
  >(OneTimeChargeDocument, options);
}
export type OneTimeChargeMutationHookResult = ReturnType<
  typeof useOneTimeChargeMutation
>;
export type OneTimeChargeMutationResult =
  Apollo.MutationResult<OneTimeChargeMutation>;
export type OneTimeChargeMutationOptions = Apollo.BaseMutationOptions<
  OneTimeChargeMutation,
  OneTimeChargeMutationVariables
>;
export const CreatePaymentMethodDocument = gql`
  mutation CreatePaymentMethod($source: String!, $accountId: String!) {
    CreatePaymentMethod(source: $source, accountId: $accountId) {
      id
      error
    }
  }
`;
export type CreatePaymentMethodMutationFn = Apollo.MutationFunction<
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables
>;

/**
 * __useCreatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodMutation, { data, loading, error }] = useCreatePaymentMethodMutation({
 *   variables: {
 *      source: // value for 'source'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useCreatePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentMethodMutation,
    CreatePaymentMethodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePaymentMethodMutation,
    CreatePaymentMethodMutationVariables
  >(CreatePaymentMethodDocument, options);
}
export type CreatePaymentMethodMutationHookResult = ReturnType<
  typeof useCreatePaymentMethodMutation
>;
export type CreatePaymentMethodMutationResult =
  Apollo.MutationResult<CreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables
>;
export const GetPaymentMethodByChargeIdDocument = gql`
  query GetPaymentMethodByChargeId($stripeChargeId: String!) {
    StripePaymentMethodByChargeId(stripeChargeId: $stripeChargeId) {
      id
      brand
      last4
      receiptEmail
      receiptUrl
      refunds {
        data {
          id
          amount
          created
          metadata {
            note
          }
          reason
        }
      }
      status
      created
    }
  }
`;

/**
 * __useGetPaymentMethodByChargeIdQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodByChargeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodByChargeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodByChargeIdQuery({
 *   variables: {
 *      stripeChargeId: // value for 'stripeChargeId'
 *   },
 * });
 */
export function useGetPaymentMethodByChargeIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentMethodByChargeIdQuery,
    GetPaymentMethodByChargeIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPaymentMethodByChargeIdQuery,
    GetPaymentMethodByChargeIdQueryVariables
  >(GetPaymentMethodByChargeIdDocument, options);
}
export function useGetPaymentMethodByChargeIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentMethodByChargeIdQuery,
    GetPaymentMethodByChargeIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPaymentMethodByChargeIdQuery,
    GetPaymentMethodByChargeIdQueryVariables
  >(GetPaymentMethodByChargeIdDocument, options);
}
export type GetPaymentMethodByChargeIdQueryHookResult = ReturnType<
  typeof useGetPaymentMethodByChargeIdQuery
>;
export type GetPaymentMethodByChargeIdLazyQueryHookResult = ReturnType<
  typeof useGetPaymentMethodByChargeIdLazyQuery
>;
export type GetPaymentMethodByChargeIdQueryResult = Apollo.QueryResult<
  GetPaymentMethodByChargeIdQuery,
  GetPaymentMethodByChargeIdQueryVariables
>;
export const CreateRefundDocument = gql`
  mutation CreateRefund(
    $stripeChargeId: String!
    $refundAmount: Float!
    $reason: StripeRefundReason!
    $note: String
  ) {
    StripeCreateRefund(
      stripeChargeId: $stripeChargeId
      refundAmount: $refundAmount
      reason: $reason
      note: $note
    ) {
      id
    }
  }
`;
export type CreateRefundMutationFn = Apollo.MutationFunction<
  CreateRefundMutation,
  CreateRefundMutationVariables
>;

/**
 * __useCreateRefundMutation__
 *
 * To run a mutation, you first call `useCreateRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRefundMutation, { data, loading, error }] = useCreateRefundMutation({
 *   variables: {
 *      stripeChargeId: // value for 'stripeChargeId'
 *      refundAmount: // value for 'refundAmount'
 *      reason: // value for 'reason'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useCreateRefundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRefundMutation,
    CreateRefundMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRefundMutation,
    CreateRefundMutationVariables
  >(CreateRefundDocument, options);
}
export type CreateRefundMutationHookResult = ReturnType<
  typeof useCreateRefundMutation
>;
export type CreateRefundMutationResult =
  Apollo.MutationResult<CreateRefundMutation>;
export type CreateRefundMutationOptions = Apollo.BaseMutationOptions<
  CreateRefundMutation,
  CreateRefundMutationVariables
>;
export const GetProductsDocument = gql`
  query GetProducts(
    $where: products_bool_exp
    $order_by: [products_order_by!] = {}
  ) {
    products(where: $where, order_by: $order_by) {
      ...Product
    }
  }
  ${ProductFragmentDoc}
`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductsQuery,
    GetProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(
    GetProductsDocument,
    options,
  );
}
export function useGetProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsQuery,
    GetProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(
    GetProductsDocument,
    options,
  );
}
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<
  typeof useGetProductsLazyQuery
>;
export type GetProductsQueryResult = Apollo.QueryResult<
  GetProductsQuery,
  GetProductsQueryVariables
>;
export const GetProductDocument = gql`
  query GetProduct($id: uuid!) {
    products_by_pk(id: $id) {
      ...Product
    }
  }
  ${ProductFragmentDoc}
`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductQuery,
    GetProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(
    GetProductDocument,
    options,
  );
}
export function useGetProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductQuery,
    GetProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(
    GetProductDocument,
    options,
  );
}
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<
  typeof useGetProductLazyQuery
>;
export type GetProductQueryResult = Apollo.QueryResult<
  GetProductQuery,
  GetProductQueryVariables
>;
export const LoadRegionsDocument = gql`
  query LoadRegions($where: regions_bool_exp = {}) {
    regions(where: $where) {
      id
      location
      name
      phoneNumber
      regionCode
      virtualZoomRegionEmail
    }
  }
`;

/**
 * __useLoadRegionsQuery__
 *
 * To run a query within a React component, call `useLoadRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadRegionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLoadRegionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadRegionsQuery,
    LoadRegionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadRegionsQuery, LoadRegionsQueryVariables>(
    LoadRegionsDocument,
    options,
  );
}
export function useLoadRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadRegionsQuery,
    LoadRegionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadRegionsQuery, LoadRegionsQueryVariables>(
    LoadRegionsDocument,
    options,
  );
}
export type LoadRegionsQueryHookResult = ReturnType<typeof useLoadRegionsQuery>;
export type LoadRegionsLazyQueryHookResult = ReturnType<
  typeof useLoadRegionsLazyQuery
>;
export type LoadRegionsQueryResult = Apollo.QueryResult<
  LoadRegionsQuery,
  LoadRegionsQueryVariables
>;
export const LoadRegionsClinicsDocument = gql`
  query LoadRegionsClinics(
    $where: regions_bool_exp = {}
    $clinicWhere: clinics_regions_bool_exp = {
      clinic: { active: { _eq: true } }
    }
  ) {
    regions(where: $where) {
      id
      name
      regionCode
      clinics_regions(where: $clinicWhere) {
        clinic {
          id
          name
          clinicCode
        }
      }
    }
  }
`;

/**
 * __useLoadRegionsClinicsQuery__
 *
 * To run a query within a React component, call `useLoadRegionsClinicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadRegionsClinicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadRegionsClinicsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      clinicWhere: // value for 'clinicWhere'
 *   },
 * });
 */
export function useLoadRegionsClinicsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadRegionsClinicsQuery,
    LoadRegionsClinicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadRegionsClinicsQuery,
    LoadRegionsClinicsQueryVariables
  >(LoadRegionsClinicsDocument, options);
}
export function useLoadRegionsClinicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadRegionsClinicsQuery,
    LoadRegionsClinicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadRegionsClinicsQuery,
    LoadRegionsClinicsQueryVariables
  >(LoadRegionsClinicsDocument, options);
}
export type LoadRegionsClinicsQueryHookResult = ReturnType<
  typeof useLoadRegionsClinicsQuery
>;
export type LoadRegionsClinicsLazyQueryHookResult = ReturnType<
  typeof useLoadRegionsClinicsLazyQuery
>;
export type LoadRegionsClinicsQueryResult = Apollo.QueryResult<
  LoadRegionsClinicsQuery,
  LoadRegionsClinicsQueryVariables
>;
export const GetPatientAvatarDocument = gql`
  query GetPatientAvatar($patientId: uuid!) {
    patients_by_pk(id: $patientId) {
      ...PatientAvatar
    }
  }
  ${PatientAvatarFragmentDoc}
`;

/**
 * __useGetPatientAvatarQuery__
 *
 * To run a query within a React component, call `useGetPatientAvatarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientAvatarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientAvatarQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPatientAvatarQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPatientAvatarQuery,
    GetPatientAvatarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPatientAvatarQuery, GetPatientAvatarQueryVariables>(
    GetPatientAvatarDocument,
    options,
  );
}
export function useGetPatientAvatarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientAvatarQuery,
    GetPatientAvatarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPatientAvatarQuery,
    GetPatientAvatarQueryVariables
  >(GetPatientAvatarDocument, options);
}
export type GetPatientAvatarQueryHookResult = ReturnType<
  typeof useGetPatientAvatarQuery
>;
export type GetPatientAvatarLazyQueryHookResult = ReturnType<
  typeof useGetPatientAvatarLazyQuery
>;
export type GetPatientAvatarQueryResult = Apollo.QueryResult<
  GetPatientAvatarQuery,
  GetPatientAvatarQueryVariables
>;
export const GetPatientDocumentsDocument = gql`
  query GetPatientDocuments($patientId: uuid!) {
    patients_by_pk(id: $patientId) {
      ...PatientConsentDocument
    }
  }
  ${PatientConsentDocumentFragmentDoc}
`;

/**
 * __useGetPatientDocumentsQuery__
 *
 * To run a query within a React component, call `useGetPatientDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientDocumentsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPatientDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPatientDocumentsQuery,
    GetPatientDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPatientDocumentsQuery,
    GetPatientDocumentsQueryVariables
  >(GetPatientDocumentsDocument, options);
}
export function useGetPatientDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientDocumentsQuery,
    GetPatientDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPatientDocumentsQuery,
    GetPatientDocumentsQueryVariables
  >(GetPatientDocumentsDocument, options);
}
export type GetPatientDocumentsQueryHookResult = ReturnType<
  typeof useGetPatientDocumentsQuery
>;
export type GetPatientDocumentsLazyQueryHookResult = ReturnType<
  typeof useGetPatientDocumentsLazyQuery
>;
export type GetPatientDocumentsQueryResult = Apollo.QueryResult<
  GetPatientDocumentsQuery,
  GetPatientDocumentsQueryVariables
>;
export const GetAccountAvatarDocument = gql`
  query GetAccountAvatar($accountId: uuid!) {
    accounts_by_pk(id: $accountId) {
      ...AccountAvatar
      firstName
      lastName
    }
  }
  ${AccountAvatarFragmentDoc}
`;

/**
 * __useGetAccountAvatarQuery__
 *
 * To run a query within a React component, call `useGetAccountAvatarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountAvatarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountAvatarQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountAvatarQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAccountAvatarQuery,
    GetAccountAvatarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountAvatarQuery, GetAccountAvatarQueryVariables>(
    GetAccountAvatarDocument,
    options,
  );
}
export function useGetAccountAvatarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountAvatarQuery,
    GetAccountAvatarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountAvatarQuery,
    GetAccountAvatarQueryVariables
  >(GetAccountAvatarDocument, options);
}
export type GetAccountAvatarQueryHookResult = ReturnType<
  typeof useGetAccountAvatarQuery
>;
export type GetAccountAvatarLazyQueryHookResult = ReturnType<
  typeof useGetAccountAvatarLazyQuery
>;
export type GetAccountAvatarQueryResult = Apollo.QueryResult<
  GetAccountAvatarQuery,
  GetAccountAvatarQueryVariables
>;
export const GetInsuranceImageDocument = gql`
  query GetInsuranceImage($insuranceId: uuid!) {
    insurance_by_pk(id: $insuranceId) {
      ...InsuranceImages
      ...Insurance
    }
  }
  ${InsuranceImagesFragmentDoc}
  ${InsuranceFragmentDoc}
`;

/**
 * __useGetInsuranceImageQuery__
 *
 * To run a query within a React component, call `useGetInsuranceImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInsuranceImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsuranceImageQuery({
 *   variables: {
 *      insuranceId: // value for 'insuranceId'
 *   },
 * });
 */
export function useGetInsuranceImageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInsuranceImageQuery,
    GetInsuranceImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInsuranceImageQuery,
    GetInsuranceImageQueryVariables
  >(GetInsuranceImageDocument, options);
}
export function useGetInsuranceImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInsuranceImageQuery,
    GetInsuranceImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInsuranceImageQuery,
    GetInsuranceImageQueryVariables
  >(GetInsuranceImageDocument, options);
}
export type GetInsuranceImageQueryHookResult = ReturnType<
  typeof useGetInsuranceImageQuery
>;
export type GetInsuranceImageLazyQueryHookResult = ReturnType<
  typeof useGetInsuranceImageLazyQuery
>;
export type GetInsuranceImageQueryResult = Apollo.QueryResult<
  GetInsuranceImageQuery,
  GetInsuranceImageQueryVariables
>;
export const GetAccountPhotoIdsDocument = gql`
  query GetAccountPhotoIds($accountId: uuid!) {
    accounts_by_pk(id: $accountId) {
      id
      ...AccountPhotoIds
    }
  }
  ${AccountPhotoIdsFragmentDoc}
`;

/**
 * __useGetAccountPhotoIdsQuery__
 *
 * To run a query within a React component, call `useGetAccountPhotoIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPhotoIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPhotoIdsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountPhotoIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAccountPhotoIdsQuery,
    GetAccountPhotoIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountPhotoIdsQuery,
    GetAccountPhotoIdsQueryVariables
  >(GetAccountPhotoIdsDocument, options);
}
export function useGetAccountPhotoIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountPhotoIdsQuery,
    GetAccountPhotoIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountPhotoIdsQuery,
    GetAccountPhotoIdsQueryVariables
  >(GetAccountPhotoIdsDocument, options);
}
export type GetAccountPhotoIdsQueryHookResult = ReturnType<
  typeof useGetAccountPhotoIdsQuery
>;
export type GetAccountPhotoIdsLazyQueryHookResult = ReturnType<
  typeof useGetAccountPhotoIdsLazyQuery
>;
export type GetAccountPhotoIdsQueryResult = Apollo.QueryResult<
  GetAccountPhotoIdsQuery,
  GetAccountPhotoIdsQueryVariables
>;
export const UpdateRoomDocument = gql`
  mutation UpdateRoom($set: rooms_set_input = {}, $id: uuid!) {
    update_rooms_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
    }
  }
`;
export type UpdateRoomMutationFn = Apollo.MutationFunction<
  UpdateRoomMutation,
  UpdateRoomMutationVariables
>;

/**
 * __useUpdateRoomMutation__
 *
 * To run a mutation, you first call `useUpdateRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomMutation, { data, loading, error }] = useUpdateRoomMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateRoomMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoomMutation,
    UpdateRoomMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRoomMutation, UpdateRoomMutationVariables>(
    UpdateRoomDocument,
    options,
  );
}
export type UpdateRoomMutationHookResult = ReturnType<
  typeof useUpdateRoomMutation
>;
export type UpdateRoomMutationResult =
  Apollo.MutationResult<UpdateRoomMutation>;
export type UpdateRoomMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoomMutation,
  UpdateRoomMutationVariables
>;
export const GetRoomsByClinicDocument = gql`
  query GetRoomsByClinic($clinicId: uuid!) {
    rooms(
      where: {
        _or: [
          { clinicId: { _eq: $clinicId } }
          { clinic: { clinics: { id: { _eq: $clinicId } } } }
        ]
      }
    ) {
      ...Room
    }
  }
  ${RoomFragmentDoc}
`;

/**
 * __useGetRoomsByClinicQuery__
 *
 * To run a query within a React component, call `useGetRoomsByClinicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomsByClinicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomsByClinicQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useGetRoomsByClinicQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoomsByClinicQuery,
    GetRoomsByClinicQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoomsByClinicQuery, GetRoomsByClinicQueryVariables>(
    GetRoomsByClinicDocument,
    options,
  );
}
export function useGetRoomsByClinicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoomsByClinicQuery,
    GetRoomsByClinicQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRoomsByClinicQuery,
    GetRoomsByClinicQueryVariables
  >(GetRoomsByClinicDocument, options);
}
export type GetRoomsByClinicQueryHookResult = ReturnType<
  typeof useGetRoomsByClinicQuery
>;
export type GetRoomsByClinicLazyQueryHookResult = ReturnType<
  typeof useGetRoomsByClinicLazyQuery
>;
export type GetRoomsByClinicQueryResult = Apollo.QueryResult<
  GetRoomsByClinicQuery,
  GetRoomsByClinicQueryVariables
>;
export const RoomsByClinicDocument = gql`
  subscription RoomsByClinic($clinicId: uuid!) {
    rooms(
      where: {
        _or: [
          { clinicId: { _eq: $clinicId } }
          { clinic: { clinics: { id: { _eq: $clinicId } } } }
        ]
      }
    ) {
      ...Room
    }
  }
  ${RoomFragmentDoc}
`;

/**
 * __useRoomsByClinicSubscription__
 *
 * To run a query within a React component, call `useRoomsByClinicSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRoomsByClinicSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomsByClinicSubscription({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useRoomsByClinicSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    RoomsByClinicSubscription,
    RoomsByClinicSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    RoomsByClinicSubscription,
    RoomsByClinicSubscriptionVariables
  >(RoomsByClinicDocument, options);
}
export type RoomsByClinicSubscriptionHookResult = ReturnType<
  typeof useRoomsByClinicSubscription
>;
export type RoomsByClinicSubscriptionResult =
  Apollo.SubscriptionResult<RoomsByClinicSubscription>;
export const RoomsByClinicCodeDocument = gql`
  subscription RoomsByClinicCode($clinicCode: String!) {
    rooms(
      where: {
        _or: [
          { clinic: { clinicCode: { _eq: $clinicCode } } }
          { clinic: { clinics: { clinicCode: { _eq: $clinicCode } } } }
        ]
      }
    ) {
      id
      name
      status
      appointment {
        id
        appointment_patients {
          patient {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useRoomsByClinicCodeSubscription__
 *
 * To run a query within a React component, call `useRoomsByClinicCodeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRoomsByClinicCodeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomsByClinicCodeSubscription({
 *   variables: {
 *      clinicCode: // value for 'clinicCode'
 *   },
 * });
 */
export function useRoomsByClinicCodeSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    RoomsByClinicCodeSubscription,
    RoomsByClinicCodeSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    RoomsByClinicCodeSubscription,
    RoomsByClinicCodeSubscriptionVariables
  >(RoomsByClinicCodeDocument, options);
}
export type RoomsByClinicCodeSubscriptionHookResult = ReturnType<
  typeof useRoomsByClinicCodeSubscription
>;
export type RoomsByClinicCodeSubscriptionResult =
  Apollo.SubscriptionResult<RoomsByClinicCodeSubscription>;
export const InsertAppointmentBlockDocument = gql`
  mutation InsertAppointmentBlock($block: appointment_blocks_insert_input!) {
    insert_appointment_blocks_one(object: $block) {
      ...AppointmentBlock
      appointment_blocks_staffs {
        ...AppointmentBlockStaff
      }
    }
  }
  ${AppointmentBlockFragmentDoc}
  ${AppointmentBlockStaffFragmentDoc}
`;
export type InsertAppointmentBlockMutationFn = Apollo.MutationFunction<
  InsertAppointmentBlockMutation,
  InsertAppointmentBlockMutationVariables
>;

/**
 * __useInsertAppointmentBlockMutation__
 *
 * To run a mutation, you first call `useInsertAppointmentBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAppointmentBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAppointmentBlockMutation, { data, loading, error }] = useInsertAppointmentBlockMutation({
 *   variables: {
 *      block: // value for 'block'
 *   },
 * });
 */
export function useInsertAppointmentBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertAppointmentBlockMutation,
    InsertAppointmentBlockMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertAppointmentBlockMutation,
    InsertAppointmentBlockMutationVariables
  >(InsertAppointmentBlockDocument, options);
}
export type InsertAppointmentBlockMutationHookResult = ReturnType<
  typeof useInsertAppointmentBlockMutation
>;
export type InsertAppointmentBlockMutationResult =
  Apollo.MutationResult<InsertAppointmentBlockMutation>;
export type InsertAppointmentBlockMutationOptions = Apollo.BaseMutationOptions<
  InsertAppointmentBlockMutation,
  InsertAppointmentBlockMutationVariables
>;
export const UpsertAppointmentBlockDocument = gql`
  mutation UpsertAppointmentBlock(
    $id: uuid!
    $block: appointment_blocks_set_input!
    $staff: [appointment_blocks_staff_insert_input!]!
  ) {
    delete_appointment_blocks_staff(
      where: { appointmentBlockId: { _eq: $id } }
    ) {
      affected_rows
    }
    insert_appointment_blocks_staff(objects: $staff) {
      affected_rows
    }
    update_appointment_blocks_by_pk(pk_columns: { id: $id }, _set: $block) {
      ...AppointmentBlock
      appointment_blocks_staffs {
        ...AppointmentBlockStaff
      }
    }
  }
  ${AppointmentBlockFragmentDoc}
  ${AppointmentBlockStaffFragmentDoc}
`;
export type UpsertAppointmentBlockMutationFn = Apollo.MutationFunction<
  UpsertAppointmentBlockMutation,
  UpsertAppointmentBlockMutationVariables
>;

/**
 * __useUpsertAppointmentBlockMutation__
 *
 * To run a mutation, you first call `useUpsertAppointmentBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAppointmentBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAppointmentBlockMutation, { data, loading, error }] = useUpsertAppointmentBlockMutation({
 *   variables: {
 *      id: // value for 'id'
 *      block: // value for 'block'
 *      staff: // value for 'staff'
 *   },
 * });
 */
export function useUpsertAppointmentBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertAppointmentBlockMutation,
    UpsertAppointmentBlockMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertAppointmentBlockMutation,
    UpsertAppointmentBlockMutationVariables
  >(UpsertAppointmentBlockDocument, options);
}
export type UpsertAppointmentBlockMutationHookResult = ReturnType<
  typeof useUpsertAppointmentBlockMutation
>;
export type UpsertAppointmentBlockMutationResult =
  Apollo.MutationResult<UpsertAppointmentBlockMutation>;
export type UpsertAppointmentBlockMutationOptions = Apollo.BaseMutationOptions<
  UpsertAppointmentBlockMutation,
  UpsertAppointmentBlockMutationVariables
>;
export const UpdateAppointmentBlockDocument = gql`
  mutation UpdateAppointmentBlock(
    $id: uuid!
    $block: appointment_blocks_set_input!
  ) {
    update_appointment_blocks_by_pk(pk_columns: { id: $id }, _set: $block) {
      ...AppointmentBlock
      appointment_blocks_staffs {
        ...AppointmentBlockStaff
      }
    }
  }
  ${AppointmentBlockFragmentDoc}
  ${AppointmentBlockStaffFragmentDoc}
`;
export type UpdateAppointmentBlockMutationFn = Apollo.MutationFunction<
  UpdateAppointmentBlockMutation,
  UpdateAppointmentBlockMutationVariables
>;

/**
 * __useUpdateAppointmentBlockMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentBlockMutation, { data, loading, error }] = useUpdateAppointmentBlockMutation({
 *   variables: {
 *      id: // value for 'id'
 *      block: // value for 'block'
 *   },
 * });
 */
export function useUpdateAppointmentBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppointmentBlockMutation,
    UpdateAppointmentBlockMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppointmentBlockMutation,
    UpdateAppointmentBlockMutationVariables
  >(UpdateAppointmentBlockDocument, options);
}
export type UpdateAppointmentBlockMutationHookResult = ReturnType<
  typeof useUpdateAppointmentBlockMutation
>;
export type UpdateAppointmentBlockMutationResult =
  Apollo.MutationResult<UpdateAppointmentBlockMutation>;
export type UpdateAppointmentBlockMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppointmentBlockMutation,
  UpdateAppointmentBlockMutationVariables
>;
export const DeleteAppointmentBlockDocument = gql`
  mutation DeleteAppointmentBlock($blockId: uuid!) {
    delete_appointment_blocks_staff(
      where: { appointmentBlockId: { _eq: $blockId } }
    ) {
      affected_rows
    }
    delete_appointment_blocks_by_pk(id: $blockId) {
      id
    }
  }
`;
export type DeleteAppointmentBlockMutationFn = Apollo.MutationFunction<
  DeleteAppointmentBlockMutation,
  DeleteAppointmentBlockMutationVariables
>;

/**
 * __useDeleteAppointmentBlockMutation__
 *
 * To run a mutation, you first call `useDeleteAppointmentBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppointmentBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppointmentBlockMutation, { data, loading, error }] = useDeleteAppointmentBlockMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *   },
 * });
 */
export function useDeleteAppointmentBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAppointmentBlockMutation,
    DeleteAppointmentBlockMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAppointmentBlockMutation,
    DeleteAppointmentBlockMutationVariables
  >(DeleteAppointmentBlockDocument, options);
}
export type DeleteAppointmentBlockMutationHookResult = ReturnType<
  typeof useDeleteAppointmentBlockMutation
>;
export type DeleteAppointmentBlockMutationResult =
  Apollo.MutationResult<DeleteAppointmentBlockMutation>;
export type DeleteAppointmentBlockMutationOptions = Apollo.BaseMutationOptions<
  DeleteAppointmentBlockMutation,
  DeleteAppointmentBlockMutationVariables
>;
export const DeleteAppointmentStaffBlockDocument = gql`
  mutation DeleteAppointmentStaffBlock($appointmentStaffBlockId: bigint!) {
    delete_appointment_blocks_staff_by_pk(id: $appointmentStaffBlockId) {
      id
    }
  }
`;
export type DeleteAppointmentStaffBlockMutationFn = Apollo.MutationFunction<
  DeleteAppointmentStaffBlockMutation,
  DeleteAppointmentStaffBlockMutationVariables
>;

/**
 * __useDeleteAppointmentStaffBlockMutation__
 *
 * To run a mutation, you first call `useDeleteAppointmentStaffBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppointmentStaffBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppointmentStaffBlockMutation, { data, loading, error }] = useDeleteAppointmentStaffBlockMutation({
 *   variables: {
 *      appointmentStaffBlockId: // value for 'appointmentStaffBlockId'
 *   },
 * });
 */
export function useDeleteAppointmentStaffBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAppointmentStaffBlockMutation,
    DeleteAppointmentStaffBlockMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAppointmentStaffBlockMutation,
    DeleteAppointmentStaffBlockMutationVariables
  >(DeleteAppointmentStaffBlockDocument, options);
}
export type DeleteAppointmentStaffBlockMutationHookResult = ReturnType<
  typeof useDeleteAppointmentStaffBlockMutation
>;
export type DeleteAppointmentStaffBlockMutationResult =
  Apollo.MutationResult<DeleteAppointmentStaffBlockMutation>;
export type DeleteAppointmentStaffBlockMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteAppointmentStaffBlockMutation,
    DeleteAppointmentStaffBlockMutationVariables
  >;
export const WatchAppointmentBlocksDocument = gql`
  subscription WatchAppointmentBlocks($where: appointment_blocks_bool_exp) {
    appointment_blocks(where: $where) {
      ...AppointmentBlock
      appointment_blocks_staffs {
        ...AppointmentBlockStaff
      }
    }
  }
  ${AppointmentBlockFragmentDoc}
  ${AppointmentBlockStaffFragmentDoc}
`;

/**
 * __useWatchAppointmentBlocksSubscription__
 *
 * To run a query within a React component, call `useWatchAppointmentBlocksSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchAppointmentBlocksSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchAppointmentBlocksSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useWatchAppointmentBlocksSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    WatchAppointmentBlocksSubscription,
    WatchAppointmentBlocksSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchAppointmentBlocksSubscription,
    WatchAppointmentBlocksSubscriptionVariables
  >(WatchAppointmentBlocksDocument, options);
}
export type WatchAppointmentBlocksSubscriptionHookResult = ReturnType<
  typeof useWatchAppointmentBlocksSubscription
>;
export type WatchAppointmentBlocksSubscriptionResult =
  Apollo.SubscriptionResult<WatchAppointmentBlocksSubscription>;
export const LoadStaffScheduleDocument = gql`
  query LoadStaffSchedule($where: staff_schedule_bool_exp!) {
    staff_schedule(where: $where) {
      ...StaffSchedule
      staff {
        ...Staff
      }
      clinic {
        id
        timeZone
      }
      staff_schedule_services {
        ...StaffScheduleService
      }
    }
  }
  ${StaffScheduleFragmentDoc}
  ${StaffFragmentDoc}
  ${StaffScheduleServiceFragmentDoc}
`;

/**
 * __useLoadStaffScheduleQuery__
 *
 * To run a query within a React component, call `useLoadStaffScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadStaffScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadStaffScheduleQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLoadStaffScheduleQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadStaffScheduleQuery,
    LoadStaffScheduleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadStaffScheduleQuery,
    LoadStaffScheduleQueryVariables
  >(LoadStaffScheduleDocument, options);
}
export function useLoadStaffScheduleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadStaffScheduleQuery,
    LoadStaffScheduleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadStaffScheduleQuery,
    LoadStaffScheduleQueryVariables
  >(LoadStaffScheduleDocument, options);
}
export type LoadStaffScheduleQueryHookResult = ReturnType<
  typeof useLoadStaffScheduleQuery
>;
export type LoadStaffScheduleLazyQueryHookResult = ReturnType<
  typeof useLoadStaffScheduleLazyQuery
>;
export type LoadStaffScheduleQueryResult = Apollo.QueryResult<
  LoadStaffScheduleQuery,
  LoadStaffScheduleQueryVariables
>;
export const VerifyStaffMemberDocument = gql`
  mutation VerifyStaffMember($token: String!) {
    VerifyStaffMember(token: $token) {
      success
    }
  }
`;
export type VerifyStaffMemberMutationFn = Apollo.MutationFunction<
  VerifyStaffMemberMutation,
  VerifyStaffMemberMutationVariables
>;

/**
 * __useVerifyStaffMemberMutation__
 *
 * To run a mutation, you first call `useVerifyStaffMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyStaffMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyStaffMemberMutation, { data, loading, error }] = useVerifyStaffMemberMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyStaffMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyStaffMemberMutation,
    VerifyStaffMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyStaffMemberMutation,
    VerifyStaffMemberMutationVariables
  >(VerifyStaffMemberDocument, options);
}
export type VerifyStaffMemberMutationHookResult = ReturnType<
  typeof useVerifyStaffMemberMutation
>;
export type VerifyStaffMemberMutationResult =
  Apollo.MutationResult<VerifyStaffMemberMutation>;
export type VerifyStaffMemberMutationOptions = Apollo.BaseMutationOptions<
  VerifyStaffMemberMutation,
  VerifyStaffMemberMutationVariables
>;
export const LoadBasicStaffDetailsDocument = gql`
  query LoadBasicStaffDetails($where: staff_bool_exp!) {
    staff(where: $where) {
      id
      firstName
      lastName
      defaultClinicId
      auth0UserId
      avatarUrl
      type
      clinic {
        clinicCode
      }
    }
  }
`;

/**
 * __useLoadBasicStaffDetailsQuery__
 *
 * To run a query within a React component, call `useLoadBasicStaffDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadBasicStaffDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadBasicStaffDetailsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLoadBasicStaffDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadBasicStaffDetailsQuery,
    LoadBasicStaffDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadBasicStaffDetailsQuery,
    LoadBasicStaffDetailsQueryVariables
  >(LoadBasicStaffDetailsDocument, options);
}
export function useLoadBasicStaffDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadBasicStaffDetailsQuery,
    LoadBasicStaffDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadBasicStaffDetailsQuery,
    LoadBasicStaffDetailsQueryVariables
  >(LoadBasicStaffDetailsDocument, options);
}
export type LoadBasicStaffDetailsQueryHookResult = ReturnType<
  typeof useLoadBasicStaffDetailsQuery
>;
export type LoadBasicStaffDetailsLazyQueryHookResult = ReturnType<
  typeof useLoadBasicStaffDetailsLazyQuery
>;
export type LoadBasicStaffDetailsQueryResult = Apollo.QueryResult<
  LoadBasicStaffDetailsQuery,
  LoadBasicStaffDetailsQueryVariables
>;
export const BootstrapClinicsDocument = gql`
  query BootstrapClinics($auth0UserId: String!) {
    staff(where: { auth0UserId: { _eq: $auth0UserId } }) {
      id
      defaultClinicId
      clinic {
        clinicCode
      }
    }
    clinics_regions(
      where: {
        region: {
          staff_regions: { staff: { auth0UserId: { _eq: $auth0UserId } } }
        }
        clinic: { status: { _nin: [HIDDEN, TEMPORARILY_CLOSED] } }
      }
    ) {
      clinicId
      regionId
      clinic {
        clinicCode
      }
    }
  }
`;

/**
 * __useBootstrapClinicsQuery__
 *
 * To run a query within a React component, call `useBootstrapClinicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBootstrapClinicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBootstrapClinicsQuery({
 *   variables: {
 *      auth0UserId: // value for 'auth0UserId'
 *   },
 * });
 */
export function useBootstrapClinicsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BootstrapClinicsQuery,
    BootstrapClinicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BootstrapClinicsQuery, BootstrapClinicsQueryVariables>(
    BootstrapClinicsDocument,
    options,
  );
}
export function useBootstrapClinicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BootstrapClinicsQuery,
    BootstrapClinicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BootstrapClinicsQuery,
    BootstrapClinicsQueryVariables
  >(BootstrapClinicsDocument, options);
}
export type BootstrapClinicsQueryHookResult = ReturnType<
  typeof useBootstrapClinicsQuery
>;
export type BootstrapClinicsLazyQueryHookResult = ReturnType<
  typeof useBootstrapClinicsLazyQuery
>;
export type BootstrapClinicsQueryResult = Apollo.QueryResult<
  BootstrapClinicsQuery,
  BootstrapClinicsQueryVariables
>;
export const LoadEditTaskByIdDocument = gql`
  query LoadEditTaskById($id: uuid!) {
    tasks_by_pk(id: $id) {
      ...Task
      tasks_assignments {
        ...TaskAssignment
      }
      tasks_notifications {
        ...TaskNotification
      }
    }
  }
  ${TaskFragmentDoc}
  ${TaskAssignmentFragmentDoc}
  ${TaskNotificationFragmentDoc}
`;

/**
 * __useLoadEditTaskByIdQuery__
 *
 * To run a query within a React component, call `useLoadEditTaskByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadEditTaskByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadEditTaskByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadEditTaskByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadEditTaskByIdQuery,
    LoadEditTaskByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadEditTaskByIdQuery, LoadEditTaskByIdQueryVariables>(
    LoadEditTaskByIdDocument,
    options,
  );
}
export function useLoadEditTaskByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadEditTaskByIdQuery,
    LoadEditTaskByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadEditTaskByIdQuery,
    LoadEditTaskByIdQueryVariables
  >(LoadEditTaskByIdDocument, options);
}
export type LoadEditTaskByIdQueryHookResult = ReturnType<
  typeof useLoadEditTaskByIdQuery
>;
export type LoadEditTaskByIdLazyQueryHookResult = ReturnType<
  typeof useLoadEditTaskByIdLazyQuery
>;
export type LoadEditTaskByIdQueryResult = Apollo.QueryResult<
  LoadEditTaskByIdQuery,
  LoadEditTaskByIdQueryVariables
>;
export const LoadTaskByIdDocument = gql`
  query LoadTaskById($id: uuid!) {
    tasks_by_pk(id: $id) {
      ...Task
      staff {
        id
        avatarUrl
        firstName
        lastName
      }
      tasks_assignments {
        ...TaskAssignment
        staff {
          id
          avatarUrl
          firstName
          lastName
        }
      }
      tasks_watches {
        ...TaskWatch
      }
      tasks_reads {
        staff {
          id
          avatarUrl
          firstName
          lastName
        }
      }
      tasks_files {
        id
        fileId
      }
      tasks_notifications(where: { active: { _eq: true } }) {
        ...TaskNotification
        account {
          id
          firstName
          lastName
          phoneNumber
        }
        clinic {
          id
          name
        }
        patient {
          id
          firstName
          lastName
          dateOfBirth
          goesByName
          pronunciation
          kareo_patient_id
        }
        staff {
          id
          avatarUrl
          firstName
          lastName
        }
        tasks_group {
          id
          name
          tasks_groups_staffs(where: { staff: { active: { _eq: true } } }) {
            staff {
              id
              firstName
              lastName
              avatarUrl
            }
          }
          tasks_groups_staffs_aggregate(
            where: { staff: { active: { _eq: true } } }
          ) {
            aggregate {
              count(columns: staffId)
            }
          }
        }
        appointment {
          visitType
          visitClassification
          reason
          startTime
          endTime
        }
      }
    }
  }
  ${TaskFragmentDoc}
  ${TaskAssignmentFragmentDoc}
  ${TaskWatchFragmentDoc}
  ${TaskNotificationFragmentDoc}
`;

/**
 * __useLoadTaskByIdQuery__
 *
 * To run a query within a React component, call `useLoadTaskByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTaskByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTaskByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadTaskByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadTaskByIdQuery,
    LoadTaskByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadTaskByIdQuery, LoadTaskByIdQueryVariables>(
    LoadTaskByIdDocument,
    options,
  );
}
export function useLoadTaskByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadTaskByIdQuery,
    LoadTaskByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadTaskByIdQuery, LoadTaskByIdQueryVariables>(
    LoadTaskByIdDocument,
    options,
  );
}
export type LoadTaskByIdQueryHookResult = ReturnType<
  typeof useLoadTaskByIdQuery
>;
export type LoadTaskByIdLazyQueryHookResult = ReturnType<
  typeof useLoadTaskByIdLazyQuery
>;
export type LoadTaskByIdQueryResult = Apollo.QueryResult<
  LoadTaskByIdQuery,
  LoadTaskByIdQueryVariables
>;
export const LoadTaskAssignmentsDocument = gql`
  query LoadTaskAssignments($id: uuid!) {
    tasks_assignments(where: { taskId: { _eq: $id } }) {
      ...TaskAssignment
      staff {
        id
        avatarUrl
        firstName
        lastName
      }
    }
  }
  ${TaskAssignmentFragmentDoc}
`;

/**
 * __useLoadTaskAssignmentsQuery__
 *
 * To run a query within a React component, call `useLoadTaskAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTaskAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTaskAssignmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadTaskAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadTaskAssignmentsQuery,
    LoadTaskAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadTaskAssignmentsQuery,
    LoadTaskAssignmentsQueryVariables
  >(LoadTaskAssignmentsDocument, options);
}
export function useLoadTaskAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadTaskAssignmentsQuery,
    LoadTaskAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadTaskAssignmentsQuery,
    LoadTaskAssignmentsQueryVariables
  >(LoadTaskAssignmentsDocument, options);
}
export type LoadTaskAssignmentsQueryHookResult = ReturnType<
  typeof useLoadTaskAssignmentsQuery
>;
export type LoadTaskAssignmentsLazyQueryHookResult = ReturnType<
  typeof useLoadTaskAssignmentsLazyQuery
>;
export type LoadTaskAssignmentsQueryResult = Apollo.QueryResult<
  LoadTaskAssignmentsQuery,
  LoadTaskAssignmentsQueryVariables
>;
export const LoadTaskActivityDocument = gql`
  query LoadTaskActivity(
    $taskId: uuid!
    $where: audit_logs_bool_exp!
    $order_by: [audit_logs_order_by!]!
  ) {
    tasks_assignments(
      where: { taskId: { _eq: $taskId } }
      order_by: [{ updatedAt: desc }]
    ) {
      createdAt
      updatedAt
      staff {
        id
        avatarUrl
        firstName
        lastName
      }
    }
    tasks_read(
      where: { taskId: { _eq: $taskId } }
      order_by: [{ updatedAt: desc }]
    ) {
      createdAt
      staff {
        id
        avatarUrl
        firstName
        lastName
      }
    }
    audit_logs(where: $where, order_by: $order_by) {
      ...Audit
    }
  }
  ${AuditFragmentDoc}
`;

/**
 * __useLoadTaskActivityQuery__
 *
 * To run a query within a React component, call `useLoadTaskActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTaskActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTaskActivityQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useLoadTaskActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadTaskActivityQuery,
    LoadTaskActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadTaskActivityQuery, LoadTaskActivityQueryVariables>(
    LoadTaskActivityDocument,
    options,
  );
}
export function useLoadTaskActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadTaskActivityQuery,
    LoadTaskActivityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadTaskActivityQuery,
    LoadTaskActivityQueryVariables
  >(LoadTaskActivityDocument, options);
}
export type LoadTaskActivityQueryHookResult = ReturnType<
  typeof useLoadTaskActivityQuery
>;
export type LoadTaskActivityLazyQueryHookResult = ReturnType<
  typeof useLoadTaskActivityLazyQuery
>;
export type LoadTaskActivityQueryResult = Apollo.QueryResult<
  LoadTaskActivityQuery,
  LoadTaskActivityQueryVariables
>;
export const WatchTaskByIdDocument = gql`
  subscription WatchTaskById($id: uuid!, $staffId: uuid!) {
    tasks_by_pk(id: $id) {
      ...Task
      staff {
        id
        avatarUrl
        firstName
        lastName
      }
      tasks_files {
        id
        fileId
      }
      tasks_assignments(where: { active: { _eq: true } }) {
        ...TaskAssignment
        staff {
          id
          avatarUrl
          firstName
          lastName
        }
      }
      tasks_watches {
        ...TaskWatch
      }
      tasks_reads {
        id
        staffId
        staff {
          id
          avatarUrl
          firstName
          lastName
        }
      }
      tasks_notifications(where: { active: { _eq: true } }) {
        id
        createdAt
        updatedAt
        accountId
        active
        appointmentId
        clinicId
        patientId
        staffId
        staffType
        taskId
        taskGroupId
        account {
          id
          firstName
          lastName
          phoneNumber
        }
        clinic {
          id
          name
        }
        patient {
          id
          firstName
          lastName
          dateOfBirth
          goesByName
          pronunciation
          kareo_patient_id
        }
        staff {
          id
          avatarUrl
          firstName
          lastName
        }
        responsible: tasks_group {
          tasks_groups_staffs_aggregate(where: { staffId: { _eq: $staffId } }) {
            aggregate {
              count(columns: staffId)
            }
          }
        }
        tasks_group {
          id
          name
          tasks_groups_staffs(where: { staff: { active: { _eq: true } } }) {
            staffId
            staff {
              id
              firstName
              lastName
              avatarUrl
            }
          }
          tasks_groups_staffs_aggregate(
            where: { staff: { active: { _eq: true } } }
          ) {
            aggregate {
              count(columns: staffId)
            }
          }
        }
        appointment {
          visitType
          visitClassification
          reason
          startTime
          endTime
        }
      }
    }
  }
  ${TaskFragmentDoc}
  ${TaskAssignmentFragmentDoc}
  ${TaskWatchFragmentDoc}
`;

/**
 * __useWatchTaskByIdSubscription__
 *
 * To run a query within a React component, call `useWatchTaskByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchTaskByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchTaskByIdSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useWatchTaskByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    WatchTaskByIdSubscription,
    WatchTaskByIdSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchTaskByIdSubscription,
    WatchTaskByIdSubscriptionVariables
  >(WatchTaskByIdDocument, options);
}
export type WatchTaskByIdSubscriptionHookResult = ReturnType<
  typeof useWatchTaskByIdSubscription
>;
export type WatchTaskByIdSubscriptionResult =
  Apollo.SubscriptionResult<WatchTaskByIdSubscription>;
export const LoadTasksDocument = gql`
  query LoadTasks(
    $where: tasks_bool_exp!
    $order_by: [tasks_order_by!]
    $limit: Int
    $offset: Int
    $staffId: uuid!
  ) {
    tasks(where: $where, order_by: $order_by, offset: $offset, limit: $limit) {
      id
      title
      priority
      dueDateTime
      status
      subType
      type
      completed
      readCount
      responsibleCount
      staff {
        id
        avatarUrl
        firstName
        lastName
      }
      tasks_reads {
        id
        staffId
      }
      tasks_watches {
        id
        staffId
      }
      tasks_assignments(where: { active: { _eq: true } }) {
        id
        assignedTo
        staff {
          id
          avatarUrl
          firstName
          lastName
        }
      }
      tasks_notifications(where: { active: { _eq: true } }) {
        patientId
        appointmentId
        accountId
        staffId
        taskGroupId
        patient {
          id
          firstName
          lastName
        }
        staff {
          id
          avatarUrl
          firstName
          lastName
        }
        responsible: tasks_group {
          tasks_groups_staffs_aggregate(where: { staffId: { _eq: $staffId } }) {
            aggregate {
              count(columns: staffId)
            }
          }
        }
        tasks_group {
          id
          name
          tasks_groups_staffs {
            id
            staffId
          }
          tasks_groups_staffs_aggregate(
            where: { staff: { active: { _eq: true } } }
          ) {
            aggregate {
              count(columns: staffId)
            }
          }
        }
      }
    }
  }
`;

/**
 * __useLoadTasksQuery__
 *
 * To run a query within a React component, call `useLoadTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useLoadTasksQuery(
  baseOptions: Apollo.QueryHookOptions<LoadTasksQuery, LoadTasksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadTasksQuery, LoadTasksQueryVariables>(
    LoadTasksDocument,
    options,
  );
}
export function useLoadTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadTasksQuery,
    LoadTasksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadTasksQuery, LoadTasksQueryVariables>(
    LoadTasksDocument,
    options,
  );
}
export type LoadTasksQueryHookResult = ReturnType<typeof useLoadTasksQuery>;
export type LoadTasksLazyQueryHookResult = ReturnType<
  typeof useLoadTasksLazyQuery
>;
export type LoadTasksQueryResult = Apollo.QueryResult<
  LoadTasksQuery,
  LoadTasksQueryVariables
>;
export const WatchTasksDocument = gql`
  subscription WatchTasks(
    $where: tasks_bool_exp!
    $order_by: [tasks_order_by!]
    $limit: Int
    $offset: Int
    $staffId: uuid!
  ) {
    tasks(where: $where, order_by: $order_by, offset: $offset, limit: $limit) {
      id
      title
      priority
      dueDateTime
      status
      subType
      type
      completed
      readCount
      responsibleCount
      staff {
        id
        avatarUrl
        firstName
        lastName
      }
      tasks_reads {
        id
        staffId
      }
      tasks_watches {
        staffId
      }
      tasks_assignments(where: { active: { _eq: true } }) {
        id
        assignedTo
        staff {
          id
          avatarUrl
          firstName
          lastName
        }
      }
      tasks_notifications(where: { active: { _eq: true } }) {
        patientId
        appointmentId
        accountId
        staffId
        taskGroupId
        patient {
          id
          firstName
          lastName
        }
        staff {
          id
          avatarUrl
          firstName
          lastName
        }
        responsible: tasks_group {
          tasks_groups_staffs_aggregate(where: { staffId: { _eq: $staffId } }) {
            aggregate {
              count(columns: staffId)
            }
          }
        }
        tasks_group {
          id
          name
          tasks_groups_staffs {
            staffId
          }
          tasks_groups_staffs_aggregate(
            where: { staff: { active: { _eq: true } } }
          ) {
            aggregate {
              count(columns: staffId)
            }
          }
        }
      }
    }
  }
`;

/**
 * __useWatchTasksSubscription__
 *
 * To run a query within a React component, call `useWatchTasksSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchTasksSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchTasksSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useWatchTasksSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    WatchTasksSubscription,
    WatchTasksSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchTasksSubscription,
    WatchTasksSubscriptionVariables
  >(WatchTasksDocument, options);
}
export type WatchTasksSubscriptionHookResult = ReturnType<
  typeof useWatchTasksSubscription
>;
export type WatchTasksSubscriptionResult =
  Apollo.SubscriptionResult<WatchTasksSubscription>;
export const LoadTaskIndexDocument = gql`
  query LoadTaskIndex(
    $where: tasks_bool_exp!
    $order_by: [tasks_order_by!]
    $limit: Int
    $offset: Int
    $staffId: uuid!
  ) {
    tasks(where: $where, order_by: $order_by, offset: $offset, limit: $limit) {
      id
      title
      priority
      dueDateTime
      status
      subType
      type
      staff {
        id
        avatarUrl
        firstName
        lastName
      }
      tasks_reads {
        id
        staffId
      }
      tasks_watches {
        staffId
      }
      tasks_assignments(where: { active: { _eq: true } }) {
        id
        assignedTo
        staff {
          id
          avatarUrl
          firstName
          lastName
        }
      }
      tasks_notifications(where: { active: { _eq: true } }) {
        patientId
        appointmentId
        accountId
        staffId
        taskGroupId
        patient {
          id
          firstName
          lastName
        }
        staff {
          id
          avatarUrl
          firstName
          lastName
        }
        responsible: tasks_group {
          tasks_groups_staffs_aggregate(where: { staffId: { _eq: $staffId } }) {
            aggregate {
              count(columns: staffId)
            }
          }
        }
        tasks_group {
          id
          name
          tasks_groups_staffs {
            staffId
          }
          tasks_groups_staffs_aggregate(
            where: { staff: { active: { _eq: true } } }
          ) {
            aggregate {
              count(columns: staffId)
            }
          }
        }
      }
    }
  }
`;

/**
 * __useLoadTaskIndexQuery__
 *
 * To run a query within a React component, call `useLoadTaskIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTaskIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTaskIndexQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      staffId: // value for 'staffId'
 *   },
 * });
 */
export function useLoadTaskIndexQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadTaskIndexQuery,
    LoadTaskIndexQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadTaskIndexQuery, LoadTaskIndexQueryVariables>(
    LoadTaskIndexDocument,
    options,
  );
}
export function useLoadTaskIndexLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadTaskIndexQuery,
    LoadTaskIndexQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadTaskIndexQuery, LoadTaskIndexQueryVariables>(
    LoadTaskIndexDocument,
    options,
  );
}
export type LoadTaskIndexQueryHookResult = ReturnType<
  typeof useLoadTaskIndexQuery
>;
export type LoadTaskIndexLazyQueryHookResult = ReturnType<
  typeof useLoadTaskIndexLazyQuery
>;
export type LoadTaskIndexQueryResult = Apollo.QueryResult<
  LoadTaskIndexQuery,
  LoadTaskIndexQueryVariables
>;
export const WatchTasksCountDocument = gql`
  subscription WatchTasksCount($where: tasks_bool_exp!) {
    tasks_aggregate(where: $where) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;

/**
 * __useWatchTasksCountSubscription__
 *
 * To run a query within a React component, call `useWatchTasksCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchTasksCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchTasksCountSubscription({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useWatchTasksCountSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    WatchTasksCountSubscription,
    WatchTasksCountSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchTasksCountSubscription,
    WatchTasksCountSubscriptionVariables
  >(WatchTasksCountDocument, options);
}
export type WatchTasksCountSubscriptionHookResult = ReturnType<
  typeof useWatchTasksCountSubscription
>;
export type WatchTasksCountSubscriptionResult =
  Apollo.SubscriptionResult<WatchTasksCountSubscription>;
export const LoadTasksCountDocument = gql`
  query LoadTasksCount(
    $start: timestamptz!
    $end: timestamptz!
    $staffAuthId: String!
  ) {
    tasks_aggregate(
      where: {
        _and: [
          {
            active: { _eq: true }
            _not: { tasks_assignments: { active: { _eq: true } } }
          }
          { dueDateTime: { _gte: $start } }
          { dueDateTime: { _lte: $end } }
          {
            _or: [
              {
                tasks_notifications: {
                  staff: { auth0UserId: { _eq: $staffAuthId } }
                }
              }
              {
                tasks_notifications: {
                  tasks_group: {
                    tasks_groups_staffs: {
                      staff: { auth0UserId: { _eq: $staffAuthId } }
                    }
                  }
                }
              }
            ]
          }
          {
            _or: [
              { type: { _eq: ACTION }, status: { _eq: OPEN } }
              {
                type: { _eq: INFORMATION }
                _not: {
                  tasks_reads: { staff: { auth0UserId: { _eq: $staffAuthId } } }
                }
              }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;

/**
 * __useLoadTasksCountQuery__
 *
 * To run a query within a React component, call `useLoadTasksCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTasksCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTasksCountQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      staffAuthId: // value for 'staffAuthId'
 *   },
 * });
 */
export function useLoadTasksCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadTasksCountQuery,
    LoadTasksCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadTasksCountQuery, LoadTasksCountQueryVariables>(
    LoadTasksCountDocument,
    options,
  );
}
export function useLoadTasksCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadTasksCountQuery,
    LoadTasksCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadTasksCountQuery, LoadTasksCountQueryVariables>(
    LoadTasksCountDocument,
    options,
  );
}
export type LoadTasksCountQueryHookResult = ReturnType<
  typeof useLoadTasksCountQuery
>;
export type LoadTasksCountLazyQueryHookResult = ReturnType<
  typeof useLoadTasksCountLazyQuery
>;
export type LoadTasksCountQueryResult = Apollo.QueryResult<
  LoadTasksCountQuery,
  LoadTasksCountQueryVariables
>;
export const InsertTaskDocument = gql`
  mutation InsertTask($task: tasks_insert_input!) {
    insert_tasks_one(object: $task) {
      id
    }
  }
`;
export type InsertTaskMutationFn = Apollo.MutationFunction<
  InsertTaskMutation,
  InsertTaskMutationVariables
>;

/**
 * __useInsertTaskMutation__
 *
 * To run a mutation, you first call `useInsertTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTaskMutation, { data, loading, error }] = useInsertTaskMutation({
 *   variables: {
 *      task: // value for 'task'
 *   },
 * });
 */
export function useInsertTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertTaskMutation,
    InsertTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertTaskMutation, InsertTaskMutationVariables>(
    InsertTaskDocument,
    options,
  );
}
export type InsertTaskMutationHookResult = ReturnType<
  typeof useInsertTaskMutation
>;
export type InsertTaskMutationResult =
  Apollo.MutationResult<InsertTaskMutation>;
export type InsertTaskMutationOptions = Apollo.BaseMutationOptions<
  InsertTaskMutation,
  InsertTaskMutationVariables
>;
export const DeleteTaskDocument = gql`
  mutation DeleteTask($taskId: uuid!) {
    update_tasks_by_pk(pk_columns: { id: $taskId }, _set: { active: false }) {
      id
    }
  }
`;
export type DeleteTaskMutationFn = Apollo.MutationFunction<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDeleteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTaskMutation,
    DeleteTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(
    DeleteTaskDocument,
    options,
  );
}
export type DeleteTaskMutationHookResult = ReturnType<
  typeof useDeleteTaskMutation
>;
export type DeleteTaskMutationResult =
  Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;
export const UpdateTaskDocument = gql`
  mutation UpdateTask($taskId: uuid!, $task: tasks_set_input!) {
    update_tasks_by_pk(pk_columns: { id: $taskId }, _set: $task) {
      id
    }
  }
`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      task: // value for 'task'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskMutation,
    UpdateTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
    UpdateTaskDocument,
    options,
  );
}
export type UpdateTaskMutationHookResult = ReturnType<
  typeof useUpdateTaskMutation
>;
export type UpdateTaskMutationResult =
  Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;
export const UpdateTaskNotificationsDocument = gql`
  mutation UpdateTaskNotifications(
    $where: tasks_notifications_bool_exp!
    $notifications: tasks_notifications_set_input!
  ) {
    update_tasks_notifications(where: $where, _set: $notifications) {
      affected_rows
    }
  }
`;
export type UpdateTaskNotificationsMutationFn = Apollo.MutationFunction<
  UpdateTaskNotificationsMutation,
  UpdateTaskNotificationsMutationVariables
>;

/**
 * __useUpdateTaskNotificationsMutation__
 *
 * To run a mutation, you first call `useUpdateTaskNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskNotificationsMutation, { data, loading, error }] = useUpdateTaskNotificationsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      notifications: // value for 'notifications'
 *   },
 * });
 */
export function useUpdateTaskNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskNotificationsMutation,
    UpdateTaskNotificationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTaskNotificationsMutation,
    UpdateTaskNotificationsMutationVariables
  >(UpdateTaskNotificationsDocument, options);
}
export type UpdateTaskNotificationsMutationHookResult = ReturnType<
  typeof useUpdateTaskNotificationsMutation
>;
export type UpdateTaskNotificationsMutationResult =
  Apollo.MutationResult<UpdateTaskNotificationsMutation>;
export type UpdateTaskNotificationsMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskNotificationsMutation,
  UpdateTaskNotificationsMutationVariables
>;
export const InsertTaskNotificationsDocument = gql`
  mutation InsertTaskNotifications(
    $notifications: [tasks_notifications_insert_input!]!
  ) {
    insert_tasks_notifications(
      objects: $notifications
      on_conflict: {
        constraint: tasks_notifications_pkey
        update_columns: [active]
      }
    ) {
      affected_rows
    }
  }
`;
export type InsertTaskNotificationsMutationFn = Apollo.MutationFunction<
  InsertTaskNotificationsMutation,
  InsertTaskNotificationsMutationVariables
>;

/**
 * __useInsertTaskNotificationsMutation__
 *
 * To run a mutation, you first call `useInsertTaskNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTaskNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTaskNotificationsMutation, { data, loading, error }] = useInsertTaskNotificationsMutation({
 *   variables: {
 *      notifications: // value for 'notifications'
 *   },
 * });
 */
export function useInsertTaskNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertTaskNotificationsMutation,
    InsertTaskNotificationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertTaskNotificationsMutation,
    InsertTaskNotificationsMutationVariables
  >(InsertTaskNotificationsDocument, options);
}
export type InsertTaskNotificationsMutationHookResult = ReturnType<
  typeof useInsertTaskNotificationsMutation
>;
export type InsertTaskNotificationsMutationResult =
  Apollo.MutationResult<InsertTaskNotificationsMutation>;
export type InsertTaskNotificationsMutationOptions = Apollo.BaseMutationOptions<
  InsertTaskNotificationsMutation,
  InsertTaskNotificationsMutationVariables
>;
export const RemoveNotificationDocument = gql`
  mutation RemoveNotification($taskNotificationId: bigint!) {
    update_tasks_notifications_by_pk(
      pk_columns: { id: $taskNotificationId }
      _set: { active: false }
    ) {
      id
    }
  }
`;
export type RemoveNotificationMutationFn = Apollo.MutationFunction<
  RemoveNotificationMutation,
  RemoveNotificationMutationVariables
>;

/**
 * __useRemoveNotificationMutation__
 *
 * To run a mutation, you first call `useRemoveNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNotificationMutation, { data, loading, error }] = useRemoveNotificationMutation({
 *   variables: {
 *      taskNotificationId: // value for 'taskNotificationId'
 *   },
 * });
 */
export function useRemoveNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveNotificationMutation,
    RemoveNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveNotificationMutation,
    RemoveNotificationMutationVariables
  >(RemoveNotificationDocument, options);
}
export type RemoveNotificationMutationHookResult = ReturnType<
  typeof useRemoveNotificationMutation
>;
export type RemoveNotificationMutationResult =
  Apollo.MutationResult<RemoveNotificationMutation>;
export type RemoveNotificationMutationOptions = Apollo.BaseMutationOptions<
  RemoveNotificationMutation,
  RemoveNotificationMutationVariables
>;
export const InsertTaskAssignmentDocument = gql`
  mutation InsertTaskAssignment(
    $taskAssignment: tasks_assignments_insert_input!
  ) {
    insert_tasks_assignments_one(object: $taskAssignment) {
      id
    }
  }
`;
export type InsertTaskAssignmentMutationFn = Apollo.MutationFunction<
  InsertTaskAssignmentMutation,
  InsertTaskAssignmentMutationVariables
>;

/**
 * __useInsertTaskAssignmentMutation__
 *
 * To run a mutation, you first call `useInsertTaskAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTaskAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTaskAssignmentMutation, { data, loading, error }] = useInsertTaskAssignmentMutation({
 *   variables: {
 *      taskAssignment: // value for 'taskAssignment'
 *   },
 * });
 */
export function useInsertTaskAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertTaskAssignmentMutation,
    InsertTaskAssignmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertTaskAssignmentMutation,
    InsertTaskAssignmentMutationVariables
  >(InsertTaskAssignmentDocument, options);
}
export type InsertTaskAssignmentMutationHookResult = ReturnType<
  typeof useInsertTaskAssignmentMutation
>;
export type InsertTaskAssignmentMutationResult =
  Apollo.MutationResult<InsertTaskAssignmentMutation>;
export type InsertTaskAssignmentMutationOptions = Apollo.BaseMutationOptions<
  InsertTaskAssignmentMutation,
  InsertTaskAssignmentMutationVariables
>;
export const UnassignTaskDocument = gql`
  mutation UnassignTask($taskAssignmentId: bigint!) {
    update_tasks_assignments_by_pk(
      pk_columns: { id: $taskAssignmentId }
      _set: { active: false }
    ) {
      id
      active
    }
  }
`;
export type UnassignTaskMutationFn = Apollo.MutationFunction<
  UnassignTaskMutation,
  UnassignTaskMutationVariables
>;

/**
 * __useUnassignTaskMutation__
 *
 * To run a mutation, you first call `useUnassignTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignTaskMutation, { data, loading, error }] = useUnassignTaskMutation({
 *   variables: {
 *      taskAssignmentId: // value for 'taskAssignmentId'
 *   },
 * });
 */
export function useUnassignTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnassignTaskMutation,
    UnassignTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnassignTaskMutation,
    UnassignTaskMutationVariables
  >(UnassignTaskDocument, options);
}
export type UnassignTaskMutationHookResult = ReturnType<
  typeof useUnassignTaskMutation
>;
export type UnassignTaskMutationResult =
  Apollo.MutationResult<UnassignTaskMutation>;
export type UnassignTaskMutationOptions = Apollo.BaseMutationOptions<
  UnassignTaskMutation,
  UnassignTaskMutationVariables
>;
export const MarkAsReadDocument = gql`
  mutation MarkAsRead($taskId: uuid!) {
    insert_tasks_read_one(object: { taskId: $taskId }) {
      id
    }
  }
`;
export type MarkAsReadMutationFn = Apollo.MutationFunction<
  MarkAsReadMutation,
  MarkAsReadMutationVariables
>;

/**
 * __useMarkAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReadMutation, { data, loading, error }] = useMarkAsReadMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useMarkAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkAsReadMutation,
    MarkAsReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkAsReadMutation, MarkAsReadMutationVariables>(
    MarkAsReadDocument,
    options,
  );
}
export type MarkAsReadMutationHookResult = ReturnType<
  typeof useMarkAsReadMutation
>;
export type MarkAsReadMutationResult =
  Apollo.MutationResult<MarkAsReadMutation>;
export type MarkAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkAsReadMutation,
  MarkAsReadMutationVariables
>;
export const DeleteUnreadDocument = gql`
  mutation DeleteUnread($where: tasks_read_bool_exp!) {
    delete_tasks_read(where: $where) {
      affected_rows
    }
  }
`;
export type DeleteUnreadMutationFn = Apollo.MutationFunction<
  DeleteUnreadMutation,
  DeleteUnreadMutationVariables
>;

/**
 * __useDeleteUnreadMutation__
 *
 * To run a mutation, you first call `useDeleteUnreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnreadMutation, { data, loading, error }] = useDeleteUnreadMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteUnreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUnreadMutation,
    DeleteUnreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUnreadMutation,
    DeleteUnreadMutationVariables
  >(DeleteUnreadDocument, options);
}
export type DeleteUnreadMutationHookResult = ReturnType<
  typeof useDeleteUnreadMutation
>;
export type DeleteUnreadMutationResult =
  Apollo.MutationResult<DeleteUnreadMutation>;
export type DeleteUnreadMutationOptions = Apollo.BaseMutationOptions<
  DeleteUnreadMutation,
  DeleteUnreadMutationVariables
>;
export const LoadTaskGroupsDocument = gql`
  query LoadTaskGroups(
    $where: tasks_groups_bool_exp = {}
    $order_by: [tasks_groups_order_by!] = {}
  ) {
    tasks_groups(where: $where, order_by: $order_by) {
      id
      name
    }
  }
`;

/**
 * __useLoadTaskGroupsQuery__
 *
 * To run a query within a React component, call `useLoadTaskGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTaskGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTaskGroupsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useLoadTaskGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadTaskGroupsQuery,
    LoadTaskGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadTaskGroupsQuery, LoadTaskGroupsQueryVariables>(
    LoadTaskGroupsDocument,
    options,
  );
}
export function useLoadTaskGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadTaskGroupsQuery,
    LoadTaskGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadTaskGroupsQuery, LoadTaskGroupsQueryVariables>(
    LoadTaskGroupsDocument,
    options,
  );
}
export type LoadTaskGroupsQueryHookResult = ReturnType<
  typeof useLoadTaskGroupsQuery
>;
export type LoadTaskGroupsLazyQueryHookResult = ReturnType<
  typeof useLoadTaskGroupsLazyQuery
>;
export type LoadTaskGroupsQueryResult = Apollo.QueryResult<
  LoadTaskGroupsQuery,
  LoadTaskGroupsQueryVariables
>;
export const LoadTaskGroupStaffsDocument = gql`
  query LoadTaskGroupStaffs($id: uuid!) {
    tasks_groups_by_pk(id: $id) {
      id
      name
      tasks_groups_staffs(where: { staff: { active: { _eq: true } } }) {
        id
        staffId
        staff {
          id
          lastName
          firstName
          avatarUrl
        }
      }
    }
  }
`;

/**
 * __useLoadTaskGroupStaffsQuery__
 *
 * To run a query within a React component, call `useLoadTaskGroupStaffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTaskGroupStaffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTaskGroupStaffsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadTaskGroupStaffsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadTaskGroupStaffsQuery,
    LoadTaskGroupStaffsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadTaskGroupStaffsQuery,
    LoadTaskGroupStaffsQueryVariables
  >(LoadTaskGroupStaffsDocument, options);
}
export function useLoadTaskGroupStaffsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadTaskGroupStaffsQuery,
    LoadTaskGroupStaffsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadTaskGroupStaffsQuery,
    LoadTaskGroupStaffsQueryVariables
  >(LoadTaskGroupStaffsDocument, options);
}
export type LoadTaskGroupStaffsQueryHookResult = ReturnType<
  typeof useLoadTaskGroupStaffsQuery
>;
export type LoadTaskGroupStaffsLazyQueryHookResult = ReturnType<
  typeof useLoadTaskGroupStaffsLazyQuery
>;
export type LoadTaskGroupStaffsQueryResult = Apollo.QueryResult<
  LoadTaskGroupStaffsQuery,
  LoadTaskGroupStaffsQueryVariables
>;
export const LoadTaskFilesDocument = gql`
  query LoadTaskFiles(
    $where: tasks_files_bool_exp!
    $order_by: [tasks_files_order_by!] = {}
  ) {
    tasks_files(where: $where, order_by: $order_by) {
      file {
        id
        info {
          url
          id
        }
      }
    }
  }
`;

/**
 * __useLoadTaskFilesQuery__
 *
 * To run a query within a React component, call `useLoadTaskFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadTaskFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadTaskFilesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useLoadTaskFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadTaskFilesQuery,
    LoadTaskFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadTaskFilesQuery, LoadTaskFilesQueryVariables>(
    LoadTaskFilesDocument,
    options,
  );
}
export function useLoadTaskFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadTaskFilesQuery,
    LoadTaskFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadTaskFilesQuery, LoadTaskFilesQueryVariables>(
    LoadTaskFilesDocument,
    options,
  );
}
export type LoadTaskFilesQueryHookResult = ReturnType<
  typeof useLoadTaskFilesQuery
>;
export type LoadTaskFilesLazyQueryHookResult = ReturnType<
  typeof useLoadTaskFilesLazyQuery
>;
export type LoadTaskFilesQueryResult = Apollo.QueryResult<
  LoadTaskFilesQuery,
  LoadTaskFilesQueryVariables
>;
export const UnwatchTaskDocument = gql`
  mutation UnwatchTask($taskId: uuid!) {
    delete_tasks_watch(where: { taskId: { _eq: $taskId } }) {
      affected_rows
    }
  }
`;
export type UnwatchTaskMutationFn = Apollo.MutationFunction<
  UnwatchTaskMutation,
  UnwatchTaskMutationVariables
>;

/**
 * __useUnwatchTaskMutation__
 *
 * To run a mutation, you first call `useUnwatchTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnwatchTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unwatchTaskMutation, { data, loading, error }] = useUnwatchTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useUnwatchTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnwatchTaskMutation,
    UnwatchTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnwatchTaskMutation, UnwatchTaskMutationVariables>(
    UnwatchTaskDocument,
    options,
  );
}
export type UnwatchTaskMutationHookResult = ReturnType<
  typeof useUnwatchTaskMutation
>;
export type UnwatchTaskMutationResult =
  Apollo.MutationResult<UnwatchTaskMutation>;
export type UnwatchTaskMutationOptions = Apollo.BaseMutationOptions<
  UnwatchTaskMutation,
  UnwatchTaskMutationVariables
>;
export const WatchTaskDocument = gql`
  mutation WatchTask($taskId: uuid!) {
    insert_tasks_watch_one(object: { taskId: $taskId }) {
      id
      staffId
      taskId
    }
  }
`;
export type WatchTaskMutationFn = Apollo.MutationFunction<
  WatchTaskMutation,
  WatchTaskMutationVariables
>;

/**
 * __useWatchTaskMutation__
 *
 * To run a mutation, you first call `useWatchTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWatchTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [watchTaskMutation, { data, loading, error }] = useWatchTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useWatchTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WatchTaskMutation,
    WatchTaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<WatchTaskMutation, WatchTaskMutationVariables>(
    WatchTaskDocument,
    options,
  );
}
export type WatchTaskMutationHookResult = ReturnType<
  typeof useWatchTaskMutation
>;
export type WatchTaskMutationResult = Apollo.MutationResult<WatchTaskMutation>;
export type WatchTaskMutationOptions = Apollo.BaseMutationOptions<
  WatchTaskMutation,
  WatchTaskMutationVariables
>;
