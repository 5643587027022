/** @jsx jsx */
import {
  AppointmentPartsFragment,
  useOneTimeChargeMutation,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import React, { Fragment, useState } from 'react';
import {
  CardElement,
  Elements,
  injectStripe,
  StripeProvider,
} from 'react-stripe-elements';
import { Button } from '../button';
import { ErrorText, InputWrap, Label, MoneyInput } from '../form';
import { HeaderEdit } from '../layout';

interface InsurancePaymentProps {
  appointment: AppointmentPartsFragment;
  onCancel?: () => void;
  stripe?: any;
}

const getErrorCode = (error: any) => {
  return error?.graphQLErrors?.[0]?.extensions?.internal?.response?.body;
};

const CardElementWrap = styled.div({
  border: `1px solid ${Colors.grayLighter}`,
  borderRadius: 4,
  padding: '16px',
});

export const InsurancePaymentForm: React.FC<InsurancePaymentProps> = ({
  appointment,
  onCancel,
  stripe,
}) => {
  const patient = appointment.appointment_patients[0]?.patient;

  const [amount, setAmount] = useState('');
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const [chargeCard] = useOneTimeChargeMutation();

  const handleSubmit = async () => {
    setLoading(true);
    if (stripe) {
      try {
        let payload = await stripe.createToken();
        if (payload && payload.token) {
          setError('');

          const { token } = payload;
          if (token && token?.id) {
            try {
              await chargeCard({
                variables: {
                  cardToken: token?.id,
                  amount,
                  // TODO: what should the product be?
                  // products: products.map((p) => {
                  //   return {
                  //     ...omit(p, '__typename'),
                  //     price: p.quantity * (parseFloat(p.price) || 0),
                  //   };
                  // }),
                  reason: 'Payment to Brave Care',
                  email: (appointment.email || '')?.trim(),
                  firstName: patient.firstName || '',
                  lastName: patient.lastName || '',
                  dateOfBirth: patient.dateOfBirth || '',
                  appointmentId: appointment.id,
                  patientId: patient.id,
                },
              });
              onCancel?.();
            } catch (error) {
              Sentry.captureException(error);
              const errorMessage = `${
                getErrorCode(error) || 'Error processing card'
              }`;
              setError(errorMessage);
            }
          }
        } else {
          setError(
            payload?.error?.message || 'There was an error adding the card',
          );
          throw payload?.error;
        }
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
      }
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <HeaderEdit
        title={`${DateTime.local().toFormat('D')} Insurance Payment`}
        editing={true}
        onCancel={onCancel}
        css={{ margin: '16px 0' }}
      />
      <InputWrap>
        <Label>Card Number</Label>
        <CardElementWrap>
          <CardElement
            style={{
              base: {
                color: Colors.darkHarbor,
                fontFamily: "'Greycliff', Helvetica, sans-serif",
                fontSize: '16px',
                fontWeight: '400',
                letterSpacing: '-0.2px',
                lineHeight: '1.2',
                textAlign: 'left',

                '::placeholder': {
                  color: Colors.grayLight,
                  lineHeight: '1.2',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            }}
          />
        </CardElementWrap>
        {error && <ErrorText>{error}</ErrorText>}
      </InputWrap>
      <InputWrap>
        <Label>Enter Amount</Label>
        <MoneyInput
          name="amount"
          onChange={(e) => {
            setAmount(e.target.value);
          }}
          value={amount}
        />
      </InputWrap>
      <Button
        css={{ fontWeight: 500, marginLeft: 'auto' }}
        disabled={loading}
        onClick={handleSubmit}
        type="button"
      >
        Add Payment
      </Button>
    </Fragment>
  );
};

const InsurancePaymentWithStripe = injectStripe(InsurancePaymentForm);

export const InsurancePayment: React.FC<
  Omit<InsurancePaymentProps, 'stripe'>
> = (props) => (
  <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''}>
    <Elements fonts={[{ cssSrc: 'https://bravecare.com/global.css' }]}>
      <InsurancePaymentWithStripe {...props} />
    </Elements>
  </StripeProvider>
);
