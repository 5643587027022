import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { FONT_PRIMARY } from '../../styles';

export const Select = styled.select`
  ${FONT_PRIMARY};
  appearance: none;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid ${Colors.grayLight};
  color: ${Colors.gray};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  padding: 16px;
  padding-left: 48px;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }
`;

export const Icon = styled.img`
  height: 24px;
  left: 16px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  z-index: 5;
`;

export const Caret = styled.img`
  height: 18px;
  pointer-events: none;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  z-index: 5;
`;

export const SelectWrap = styled.div`
  display: flex;
  position: relative;
`;
