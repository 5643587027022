/** @jsx jsx */
import { ClinicFragment, RoomFragment } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import { DateTime } from 'luxon';
import { useState } from 'react';
import AppointmentBlock from '../../components/AppointmentBlocker/BlockerDisplay';
import { checkIfDayIsToday } from '../../helpers';
import { ScheduleDisplayItem } from '../../pages/Dashboard.helpers';
import { ViewBlock } from '../AppointmentBlocker/ViewBlock';
import AppointmentDisplay from './AppointmentDisplay';
import CurrentTime from './CurrentTime';
import { generateTimeSlots } from './helper';
import {
  getCurrentTimePosition,
  getDisplayOffset,
  getDisplayOverlaps,
  useClinicTime,
  useClock,
} from './Schedule.helpers';
import { RowSlot, ROW_HEIGHT } from './Schedule.styles';

interface ScheduleProps {
  date: DateTime;
  displayItems: ScheduleDisplayItem[];
  onAppointmentClick: (appointmentId: string) => void;
  onSlotClick: (date: DateTime) => void;
  clinic: ClinicFragment;
  rooms: RoomFragment[];
}

const Schedule = ({
  date,
  onAppointmentClick,
  onSlotClick,
  clinic,
  rooms,
  displayItems,
}: ScheduleProps) => {
  useClock();

  const { clinicOpen, clinicClose } = useClinicTime(
    date,
    clinic.open,
    clinic.close,
  );

  const [selectedBlockId, setSelectedBlockId] = useState<string | undefined>();
  const isToday = checkIfDayIsToday(date);
  const slots = generateTimeSlots(clinicOpen, clinicClose, 60);
  const totalHeight = slots.length * ROW_HEIGHT;
  const { milliseconds } = clinicClose.diff(clinicOpen).toObject();
  const millPerPix = (milliseconds || 0) / totalHeight;
  const displayWithOverlaps = getDisplayOverlaps(displayItems);

  return (
    <div
      css={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {slots.map((slot, index) => {
        return (
          <RowSlot
            key={index}
            isFirst={index === 0}
            isLast={index === slots.length - 1}
            onSlotClick={onSlotClick}
            slot={slot}
          />
        );
      })}

      <div
        css={{
          position: 'absolute',
          left: '70px',
          right: 0,
          bottom: 0,
          top: 0,
        }}
      >
        {isToday && (
          <CurrentTime
            style={getCurrentTimePosition({
              millPerPix,
              startTime: clinicOpen,
            })}
          />
        )}

        {displayWithOverlaps.map((display) => {
          const room = rooms.find((r) => r.appointmentId === display.id);
          const { startTime, endTime } = display;
          const { height, offset } = getDisplayOffset({
            dayStart: clinicOpen,
            display: display,
            millPerPix,
          });

          let width = '100%';
          let left = '0';
          let paddingRight = 0;

          const overlaps = display.overlaps.filter((v: any) => v);

          if (overlaps.length) {
            const displayIndex = overlaps.findIndex(({ id }: any) => {
              return id === display.id;
            });

            const percentage = 100 / overlaps.length;
            width = `calc(${percentage}%)`;
            left = `${percentage * displayIndex}%`;
            paddingRight = 8;
          }

          if (display.type === 'BLOCK') {
            return (
              <div
                key={display.id}
                css={{
                  position: 'absolute',
                  height: `calc(${height}px - 8px)`,
                  top: `${Math.max(offset - height + 4, 0)}px`,
                  left: left,
                  width: width,
                  paddingRight: `${paddingRight}px`,
                  color: '#FFF',
                  wordBreak: 'break-all',
                  padding: '4px',
                }}
              >
                <AppointmentBlock
                  css={{
                    height: `${height}px`,
                  }}
                  startTime={startTime}
                  endTime={endTime}
                  reason={display.reason ?? ''}
                  staff={display.staff}
                  onClick={() => {
                    setSelectedBlockId(display.id);
                  }}
                  height={height}
                />
              </div>
            );
          }

          if (display.type === 'APPOINTMENT') {
            return (
              <AppointmentDisplay
                css={{
                  height: `calc(${height}px - 8px)`,
                  left,
                  paddingRight,
                  top: `${Math.max(offset - height + 4, 0)}px`,
                  width,
                }}
                dateOfBirth={display.dateOfBirth!}
                endTime={endTime}
                firstName={display.firstName}
                globalNotes={display.globalNotes!}
                goesByName={display.goesByName}
                kareoLinked={display.kareoLinked!}
                key={display.id}
                lastName={display.lastName}
                needsExtraPrecaution={
                  display.safetyQuestionnaire?.covid19Symptoms
                }
                onClick={() => onAppointmentClick(display.id)}
                pronunciation={display.pronunciation}
                reason={display.reason}
                room={room}
                staff={display.staff}
                startTime={startTime}
                status={display.status!}
                visitClassification={display.visitClassification!}
                visitType={display.visitType!}
                height={height}
              />
            );
          }
        })}

        {selectedBlockId && (
          <ViewBlock
            blockId={selectedBlockId}
            onDismiss={() => {
              setSelectedBlockId(undefined);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Schedule;
