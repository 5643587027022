/** @jsx jsx */
import { Colors } from '@bc/theme';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import { isEqual, sortBy, uniq } from 'lodash';
import CaretDropdownIcon from '../../assets/caret.svg';
import { useStaffRegions } from '../../lib/staff';
import { IF_TABLET } from '../../styles';
import { Checkbox } from '../form';
import { PrimaryText } from '../text';

interface Props {
  clinicCodes: string[];
  onSelect: (clinicCodes?: string[]) => void;
}

const clinicCodesMatch = (codes: string[], codesComp: string[]) => {
  return isEqual(sortBy(codes), sortBy(codesComp));
};

export const ClinicRegionDropdown = ({ clinicCodes = [], onSelect }: Props) => {
  useStaffRegions();
  const { data: regions } = useStaffRegions();

  const allClinics = regions
    ?.flatMap((region) => {
      return region.clinics_regions?.flatMap(({ clinic }) => {
        return clinic;
      });
    })
    .filter((clinic) => clinic);

  const selectedClinics = allClinics
    .filter((clinic) => {
      return clinicCodes.includes(clinic.clinicCode);
    })
    .map(({ name }) => {
      return name;
    });

  const regionFullySelected = regions.find((region) => {
    const clinics = region.clinics_regions
      .filter(({ clinic }) => clinic)
      .map(({ clinic }) => clinic);

    const regionClinicCodes = clinics.map(({ clinicCode }) => {
      return clinicCode;
    });
    return clinicCodesMatch(regionClinicCodes, clinicCodes);
  });

  const display = regionFullySelected
    ? regionFullySelected.name
    : uniq(selectedClinics)?.join(', ');

  // if not all in the same region. Then unselect all and select just the one in the region

  return (
    <Container>
      <Menu>
        <DropDownTrigger
          css={{
            border: `1px solid ${Colors.grayLight}`,
            borderRadius: '100px',
            minWidth: '240px',
            maxWidth: '240px',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'row',
            padding: '6px 24px',
          }}
        >
          <TriggerText>{display}</TriggerText>
          <Caret src={CaretDropdownIcon} />
        </DropDownTrigger>
        <DropDown
          css={{
            padding: '24px',
          }}
        >
          {regions.map((region) => {
            const clinics = region.clinics_regions
              .filter(({ clinic }) => clinic)
              .map(({ clinic }) => clinic);

            const regionClinicCodes = clinics.map(({ clinicCode }) => {
              return clinicCode;
            });

            return (
              <div key={region.id} css={{ marginBottom: '16px' }}>
                <Item
                  onClick={() => {
                    onSelect(regionClinicCodes);
                  }}
                  css={{ marginBottom: '16px' }}
                >
                  <Checkbox
                    checked={clinicCodesMatch(regionClinicCodes, clinicCodes)}
                  />
                  <ItemText
                    css={{
                      fontWeight: 'bold',
                      fontSize: '18px',
                    }}
                  >
                    {region.name}
                  </ItemText>
                </Item>
                <div>
                  {clinics.map((clinic) => {
                    return (
                      <DropDownItem
                        key={`clinic-${clinic.id}`}
                        onSelect={() => {
                          const regionCurrentlySelected = regions.find(
                            (region) => {
                              const clinics = region.clinics_regions
                                .filter(({ clinic }) => clinic)
                                .map(({ clinic }) => clinic);

                              const regionClinicCodes = clinics.map(
                                ({ clinicCode }) => {
                                  return clinicCode;
                                },
                              );

                              return clinicCodes.find((clinicCode) => {
                                return regionClinicCodes.includes(clinicCode);
                              });
                            },
                          );

                          if (clinicCodes.includes(clinic.clinicCode)) {
                            const nextClinicCodes = clinicCodes.filter(
                              (clinicCode) => {
                                return clinicCode !== clinic.clinicCode;
                              },
                            );

                            if (nextClinicCodes.length !== 0) {
                              onSelect(nextClinicCodes);
                            }
                          } else {
                            if (regionCurrentlySelected) {
                              const clinics =
                                regionCurrentlySelected.clinics_regions
                                  .filter(({ clinic }) => clinic)
                                  .map(({ clinic }) => clinic);

                              const regionClinicCodes = clinics.map(
                                ({ clinicCode }) => {
                                  return clinicCode;
                                },
                              );
                              if (
                                !regionClinicCodes.includes(clinic.clinicCode)
                              ) {
                                onSelect([clinic.clinicCode]);
                              } else {
                                onSelect([...clinicCodes, clinic.clinicCode]);
                              }
                            } else {
                              onSelect([...clinicCodes, clinic.clinicCode]);
                            }
                          }
                        }}
                        css={{
                          marginBottom: '16px',
                        }}
                      >
                        <Item>
                          <Checkbox
                            checked={clinicCodes.includes(clinic.clinicCode)}
                          />
                          <ItemText>{clinic.name}</ItemText>
                        </Item>
                      </DropDownItem>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </DropDown>
      </Menu>
    </Container>
  );
};

const NBSP = '\u00A0';

const Item = styled.div({
  flexDirection: 'row',
  display: 'flex',
  cursor: 'pointer',
});

const commonFontRules = css`
  letter-spacing: 0.1px;
  font-size: 14px;
  ${IF_TABLET} {
    font-size: 16px;
  }
`;

const ShowText = styled(PrimaryText)`
  color: ${Colors.gray};
  ${commonFontRules};
`;

const Caret = styled.img`
  height: 24px;
  margin-left: auto;
  width: 24px;
  z-index: 5;
  transform: rotate(180deg);
`;

const Container = styled.div`
  position: relative;
`;

const DropDownTrigger = styled(MenuButton)`
  align-items: center;
  background-color: ${Colors.white};
  border: none;
  cursor: pointer;
  display: flex;
  padding: 8px;
  &:focus {
    box-shadow: inset 0 0 0 2px ${Colors.grayLighter},
      inset 0 0 0 4px ${Colors.white};
    outline: none;
    border-radius: 8px;
  }
`;

const TriggerText = styled(PrimaryText)`
  font-weight: 600;
  font-size: 14px;
`;

const DropDown = styled(MenuList)`
  background: ${Colors.white};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  border: none;
  min-width: 250px;
  overflow: hidden;
  padding: 8px 0;
  position: relative;
  z-index: 16;
`;

const DropDownItem = styled(MenuItem)<{ selected?: boolean }>(
  (props) => `
  align-items: center;
  background-color: ${props.selected ? '#F3F5F5' : Colors.white};
  display: flex;
  padding: 0;

  &:hover {
    background-color: #F8FAFA;
  }
`,
);

const ItemText = styled(PrimaryText)`
  color: ${Colors.darkHarbor};
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  margin-left: 12px;
`;
