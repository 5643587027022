/** @jsx jsx */
import {
  ProductFragment,
  useAddProductsMutation,
  useUpdateProductsMutation,
} from '@bc/codegen/manager';
import { useGetProductQuery } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Field, FieldProps, Form, Formik } from 'formik';
// import { object, string } from 'yup';
import qs from 'querystring';
import React, { Fragment, useEffect, useState } from 'react';
//@ts-ignore
import { IMaskMixin } from 'react-imask';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Button, ButtonRed } from '../../components/button';
import { Input, Select, SelectWithCaret } from '../../components/form';
import { Header } from '../../components/Header';
import {
  Content,
  InfoWrap,
  InnerWrap,
  Label,
  MainContent,
} from '../../components/layout';
import { Sidebar } from '../../components/Nav/Sidebar';
import ProductSearchInput from '../../components/ProductSearch/input';
import { LargeTitle, PrimaryText } from '../../components/text';

const NumberInput = IMaskMixin(({ inputRef, ...props }: any) => (
  <Input {...props} ref={inputRef} style={{ width: '100%' }} />
));

// const Validation = object().shape({
//   name: string().required('Required'),
//   price: string().required('Required'),
//   type: string().required('Required'),
// });

const ErrorMessageWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  background-color: #d93900;
  padding: 18px;
`;

const SuccessMessageWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  background-color: ${Colors.teal};
  padding: 18px;
`;

const TYPES = ['rx', 'otc', 'service', 'dme', 'vaccine'];

const AddEditProduct: React.FC<{
  product?: Partial<ProductFragment> | null;
  onCancel?: () => void;
  onAdd?: () => void;
}> = ({ product, onCancel, onAdd }) => {
  const context = { role: 'manager' };
  const history = useHistory();
  const [addProduct] = useAddProductsMutation({ context });
  const [updateProduct] = useUpdateProductsMutation({ context });

  const handleSave = async (values: Partial<ProductFragment>) => {
    const price = parseFloat(values.price as any);

    if (isNaN(price)) {
      return;
    }

    let data;

    if (values.id) {
      const updated = await updateProduct({
        variables: {
          id: values.id,
          product: {
            name: values.name,
            price: parseFloat(values.price as any),
            type: values.type,
          },
        },
      });
      data = updated?.data?.update_products?.returning?.[0];
    } else {
      const inserted = await addProduct({
        variables: {
          products: [
            {
              name: values.name,
              price: parseFloat(values.price as any),
              type: values.type,
            },
          ],
        },
      });
      data = inserted?.data?.insert_products?.returning?.[0];
    }
    if (data) {
      history.push({
        pathname: '/products',
        search: qs.stringify({
          price: data.price,
          name: data.name,
        }),
      });
      if (onAdd) {
        onAdd();
      }
    }
  };

  const Spacer = styled.div({
    flex: '1 1 auto',
    padding: '16px 0',
  });

  return (
    <Formik
      initialValues={
        product || {
          name: '',
          type: '',
        }
      }
      onSubmit={handleSave}
    >
      {({ setFieldValue, values }) => {
        return (
          <Form>
            <Spacer>
              <InfoWrap fullWidth>
                <Label>Name</Label>
                <Field name="name">
                  {({ field }: FieldProps<any>) => (
                    <Input
                      {...field}
                      style={{ width: '100%' }}
                      autoComplete="off"
                    />
                  )}
                </Field>
              </InfoWrap>
            </Spacer>
            <Spacer>
              <InfoWrap fullWidth>
                <Label>Price</Label>
                <Field name="price">
                  {({ field }: FieldProps<any>) => (
                    <NumberInput
                      // @ts-ignore
                      mask={Number}
                      scale={2}
                      signed={false}
                      thousandsSeparator=","
                      normalizeZeros={true}
                      radix="."
                      value={`${field.value}`}
                      unmask={true}
                      onAccept={(value: any, mask: any) => {
                        setFieldValue(field.name, value);
                      }}
                    />
                  )}
                </Field>
              </InfoWrap>
            </Spacer>
            <Spacer>
              <InfoWrap fullWidth>
                <Label>Type</Label>
                <Field name="type">
                  {({ field }: FieldProps<any>) => (
                    <SelectWithCaret>
                      <Select {...field} style={{ width: '100%' }}>
                        <option value="">Select Type</option>
                        {TYPES.map((value) => (
                          <option value={value} key={value}>
                            {value}
                          </option>
                        ))}
                      </Select>
                    </SelectWithCaret>
                  )}
                </Field>
              </InfoWrap>
            </Spacer>
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  paddingRight: '16px',
                }}
              >
                <Button type="submit">
                  {!values?.id ? 'Create' : 'Update'}
                </Button>
              </div>
              <ButtonRed type="button" onClick={onCancel}>
                Cancel
              </ButtonRed>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const ProductEditor: React.FC<
  RouteComponentProps<{
    id: string;
  }>
> = ({ match, history, location }) => {
  const [addProduct, setAddProduct] = useState(false);
  const { id } = match.params;
  const { price, name } = qs.parse((location.search || '').slice(1));

  const { data, loading } = useGetProductQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: id,
    },
    skip: !id,
  });

  useEffect(() => {
    if (id && addProduct) {
      setAddProduct(false);
    }
  }, [id, addProduct]);

  const isCopay = data?.products_by_pk?.type === 'cp';

  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap>
          <Sidebar />
          <MainContent
            style={{
              paddingTop: '16px',
            }}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <LargeTitle>Product Editor</LargeTitle>
              <Link to="/products/all">
                <PrimaryText>View All</PrimaryText>
              </Link>
            </div>
            <div
              style={{
                marginTop: '32px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <div
                style={{
                  flex: '1 1 auto',
                  paddingRight: '16px',
                }}
              >
                <ProductSearchInput
                  defaultCopayAmount={0}
                  onChange={(product) => {
                    if (product) {
                      history.push(`/products/${product.id}`);
                    }
                  }}
                  error={false}
                />
              </div>
              <Button
                onClick={() => {
                  setAddProduct(true);
                  history.push('/products');
                }}
                style={{ marginTop: 16 }}
              >
                Add New
              </Button>
            </div>
            {price && name && (
              <SuccessMessageWrapper>
                <PrimaryText
                  style={{
                    color: '#FFF',
                  }}
                >
                  Successfully updated {name} to ${price}
                </PrimaryText>
              </SuccessMessageWrapper>
            )}
            {isCopay && (
              <ErrorMessageWrapper>
                <PrimaryText
                  style={{
                    color: '#FFF',
                  }}
                >
                  Cannot edit co-pay items
                </PrimaryText>
              </ErrorMessageWrapper>
            )}
            {(addProduct || data?.products_by_pk) && !isCopay && !loading && (
              <AddEditProduct
                product={data?.products_by_pk}
                key={id}
                onCancel={() => {
                  history.push('/products');
                  setAddProduct(false);
                }}
                onAdd={() => {
                  setAddProduct(false);
                }}
              />
            )}
          </MainContent>
        </InnerWrap>
      </Content>
    </Fragment>
  );
};

export default ProductEditor;
