/** @jsx jsx */
import {
  AppointmentPartsFragment,
  useFindKareoPatientMutation,
  useUpdatePatientByPkMutation,
  useWatchForKareoActionsSubscription,
} from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import * as Sentry from '@sentry/react';
import React, { Fragment, useEffect, useState } from 'react';
import FindPatient from '../../components/Kareo/FindPatient';
import {
  getPatientDOB,
  getPatientList,
  useKareoTransitions,
} from '../../components/Kareo/hooks';
import KareoActivity from '../../components/Kareo/KareoActivity';
import KareoAppointmentReview from '../../components/Kareo/KareoAppointmentReview';
import KareoAppointmentSection from '../../components/Kareo/KareoAppointmentSection';
import KareoDocumentReview from '../../components/Kareo/KareoDocumentReview';
import KareoDocumentSection from '../../components/Kareo/KareoDocumentSection';
import KareoPatientList from '../../components/Kareo/KareoPatientList';
import KareoPatientUpdateReview from '../../components/Kareo/KareoPatientReview';
import KareoPatientUpdateSection from '../../components/Kareo/KareoPatientUpdateSection';
import KareoLinkedBanner from '../../components/Kareo/LinkedBanner';
import KareoLinkedToast from '../../components/Kareo/LinkToast';
import { SectionTitle } from '../../components/text';
import { fiveMinutesAgo } from '../../helpers';

const Kareo: React.FC<{ data: AppointmentPartsFragment }> = ({ data }) => {
  const patient = data?.appointment_patients?.[0]?.patient;
  const hasKareoAttach = !!(patient.kareo_guid && patient.kareo_patient_id);
  const hasAppointment = !!data?.kareo_appointment_id;
  const [attached, setAttached] = useState(hasKareoAttach);
  const {
    sectionType,
    setSectionType,
    sectionOpen,
    setSectionOpen,
    successTypes,
    setSuccessType,
  } = useKareoTransitions(!hasAppointment ? 'APPOINTMENT' : 'PATIENT');

  const [
    findKareoPatients,
    { data: kareoPatients, loading: findLoading, error: findError },
  ] = useFindKareoPatientMutation();

  const [updatePatient, { error: updateError }] =
    useUpdatePatientByPkMutation();

  const [fiveMinAgo, setFiveMinAgo] = useState(fiveMinutesAgo());
  const { data: kareoActionsData } = useWatchForKareoActionsSubscription({
    variables: {
      query_start: fiveMinAgo,
    },
  });

  // update the five-minutes-ago timestamp for the subscription every minute so the component re-renders
  // in case a recent Kareo Action never updated to status='COMPLETE'
  useEffect(() => {
    const interval = setInterval(() => {
      setFiveMinAgo(fiveMinutesAgo());
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const otherActionsRunning = !!kareoActionsData?.kareo_logs?.[0]?.id;

  const loading = findLoading || otherActionsRunning;

  const isUnlinked =
    !hasKareoAttach && !attached && !kareoPatients?.FindKareoPatient;

  const patients = getPatientList(kareoPatients);

  const handleUnlink = async () => {
    try {
      await updatePatient({
        variables: {
          id: patient.id,
          _set: {
            kareo_guid: null,
            kareo_patient_id: null,
          },
        },
      });
      window.location.reload();
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  return (
    <div
      style={{
        padding: '32px 0',
      }}
    >
      <KareoLinkedToast linked={attached} />
      {loading && <KareoActivity />}

      <div
        css={{
          display: !loading ? 'block' : 'none',
        }}
      >
        <div
          css={{
            marginBottom: 16,
          }}
        >
          <SectionTitle>Kareo Sync</SectionTitle>
          {!!patient.kareo_patient_id && (
            <KareoLinkedBanner
              kareoPatientId={patient.kareo_patient_id}
              onUnlink={() => {
                handleUnlink();
              }}
            />
          )}
        </div>
        {isUnlinked && (
          <FindPatient
            onFindClick={async () => {
              try {
                await findKareoPatients({
                  variables: {
                    name: `${patient.firstName} ${patient.lastName}`,
                    dateOfBirth: getPatientDOB(patient.dateOfBirth),
                  },
                });
              } catch (error) {
                console.log(error);
                Sentry.captureException(error);
              }
            }}
          />
        )}

        {!attached && Array.isArray(kareoPatients?.FindKareoPatient) && (
          <KareoPatientList
            patientId={patient.id}
            onAttach={() => {
              setAttached(true);
            }}
            patients={patients}
          />
        )}

        {attached && (
          <Fragment>
            {!sectionType && (
              <Fragment>
                <div style={{ paddingBottom: '24px' }}>
                  <KareoAppointmentSection
                    appointmentId={data.id}
                    open={sectionOpen === 'APPOINTMENT'}
                    onToggle={() => {
                      const section =
                        sectionOpen === 'APPOINTMENT'
                          ? undefined
                          : 'APPOINTMENT';
                      setSectionOpen(section);
                    }}
                    onSectionSelect={() => {
                      setSectionType('APPOINTMENT');
                    }}
                    success={!!data?.kareo_appointment_id}
                  />
                </div>
                <div style={{ paddingBottom: '24px' }}>
                  <KareoPatientUpdateSection
                    open={sectionOpen === 'PATIENT'}
                    onToggle={() => {
                      const section =
                        sectionOpen === 'PATIENT' ? undefined : 'PATIENT';
                      setSectionOpen(section);
                    }}
                    onSectionSelect={() => {
                      setSectionType('PATIENT');
                    }}
                    success={successTypes.includes('PATIENT')}
                  />
                </div>
                <div style={{ paddingBottom: '24px' }}>
                  <KareoDocumentSection
                    open={sectionOpen === 'DOCUMENT'}
                    onToggle={() => {
                      const section =
                        sectionOpen === 'DOCUMENT' ? undefined : 'DOCUMENT';
                      setSectionOpen(section);
                    }}
                    onSectionSelect={() => {
                      setSectionType('DOCUMENT');
                    }}
                    success={successTypes.includes('DOCUMENT')}
                  />
                </div>
              </Fragment>
            )}

            {sectionType === 'APPOINTMENT' && (
              <KareoAppointmentReview
                appointmentId={data.id}
                appointment={data}
                onCancel={() => {
                  setSectionType(undefined);
                }}
                onSuccess={() => {
                  setSuccessType((state) => [...state, 'APPOINTMENT']);
                  setSectionType(undefined);
                  if (!successTypes.includes('PATIENT')) {
                    setSectionOpen('PATIENT');
                  }
                }}
              />
            )}
            {sectionType === 'PATIENT' && (
              <KareoPatientUpdateReview
                patient={patient}
                patientId={patient.id}
                onCancel={() => {
                  setSectionType(undefined);
                }}
                onSuccess={() => {
                  setSuccessType((state) => [...state, 'PATIENT']);
                  setSectionType(undefined);
                  if (!successTypes.includes('DOCUMENT')) {
                    setSectionOpen('DOCUMENT');
                  }
                }}
              />
            )}
            {sectionType === 'DOCUMENT' && (
              <KareoDocumentReview
                patientId={patient.id}
                onCancel={() => {
                  setSectionType(undefined);
                }}
                onSuccess={() => {
                  setSuccessType((state) => [...state, 'DOCUMENT']);
                  setSectionType(undefined);
                  setSectionOpen(undefined);
                }}
              />
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Kareo;
