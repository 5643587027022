/** @jsx jsx */
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React, { ChangeEvent } from 'react';
import { ReactComponent as ClearIcon } from '../../assets/close.svg';
import Search from '../../assets/search.svg';
import { FONT_PRIMARY, IF_TABLET } from '../../styles';

const Bar = styled.div({
  alignItems: 'center',
  background: Colors.grayLightest,
  borderRadius: 100,
  display: 'flex',
  padding: '0 16px',
  minWidth: '100%',
  [IF_TABLET]: {
    minWidth: 'unset',
    maxWidth: 'max-content',
  },
});

const Input = styled.input`
  ${FONT_PRIMARY};
  background: transparent;
  border: none;
  color: ${Colors.gray};
  font-size: 16px;
  line-height: 24px;
  min-width: 206px;
  outline: none;
  padding: 8px;
`;

const Icon = styled.img({
  height: 18,
  width: 18,
});

const Clear = styled(ClearIcon)`
  width: 10px;
  height: 10px;
  cursor: pointer;
  g {
    fill: ${Colors.gray};
  }
`;

interface SearchBarProps {
  placeholder?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({ onClear, ...props }) => (
  <Bar>
    <Icon src={Search} />
    <Input {...props} autoComplete="off" />
    {props.value && onClear && <Clear onClick={onClear} />}
  </Bar>
);
