/** @jsx jsx */
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { DialogContent } from '@reach/dialog';
import React from 'react';
import { ICONS } from '../assets';
import { IF_DESKTOP, IF_TABLET } from '../styles';
import { TextButton } from './button';
import CopyClip from './copy';
import { PrimaryText, SmallTitle } from './text';

export const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
`;

export const BodyWrap = styled.div`
  flex: 1;
  padding: 0 40px 80px 40px;
  margin-left: 250px;
`;

export const InnerWrap = styled.div`
  width: 100%;
  height: calc(100vh - 72px);
  display: flex;
`;

export const LeftAndRightWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${IF_DESKTOP} {
    flex-wrap: nowrap;
  }
`;

export const LeftWrapper = styled.div`
  flex: 1;
  padding: 0 20px 80px 20px;
  order: 2;

  ${IF_DESKTOP} {
    flex-wrap: nowrap;
    order: 1;
    padding: 0 0 80px 0;
  }
`;

export const MainContent = styled.main`
  flex: 1;
  max-height: calc(100vh - 72px);
  overflow-y: scroll;
  padding: 0 16px 96px;
  ${IF_TABLET} {
    padding: 0 40px 72px 40px;
  }
`;

export const MainModalContent = styled.main`
  flex: 1;
  padding: 24px;
  ${IF_TABLET} {
    max-width: 1152px;
    margin: 64px auto;
    padding: 12px 24px;
  }
`;

export const RightSidebar = styled.div<{
  verticalPadding?: boolean;
}>`
  flex: 0 0 auto;
  margin-left: 40px;
  width: 100%;
  right: 0;
  height: auto;
  order: 1;
  margin: 0 auto;
  height: auto;
  ${({ verticalPadding }) => verticalPadding && `padding: 20px 0;`}
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  ${IF_DESKTOP} {
    display: block;
    width: 312px;
    overflow-y: scroll;
    height: calc(100vh - 72px);
    position: sticky;
    top: 0;
    order: 2;
    ${({ verticalPadding }) => verticalPadding && `padding: 40px 0;`}
  }
`;

export const RightSidebarOnlyFilters = styled(RightSidebar)`
  margin-left: 0;
  justify-content: space-between;
  padding: 20px;
  ${IF_DESKTOP} {
    padding: 0 0 40px 40px;
  }
`;

export const HeaderContainer = styled.div`
  flex: 1;
  flex-direction: column;
  margin: 24px 0;
  ${IF_DESKTOP} {
    margin: 40px 0 24px;
  }
`;

export const TitleRow = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 0 16px 0',
  flexWrap: 'wrap',
  [IF_TABLET]: {
    margin: '0 0 40px 0',
    flexWrap: 'nowrap',
  },
});

export const HeaderEdit: React.FC<{
  title?: React.ReactNode;
  children?: any;
  onEdit?: () => void;
  onCancel?: () => void;
  editing?: boolean;
  editable?: boolean;
  noTopPadding?: boolean;
  className?: string;
}> = ({
  title,
  children,
  onEdit,
  editing,
  editable = true,
  onCancel,
  noTopPadding,
  className,
  ...props
}) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: noTopPadding ? '0 0 16px' : '16px 0',
        width: '100%',
        borderBottom: '1px solid #E5E8E8',
      }}
      className={className}
      {...props}
    >
      {title && <SmallTitle>{title}</SmallTitle>}
      {children}
      {!editing && editable && (
        <TextButton
          type="button"
          onClick={onEdit}
          css={{ alignItems: 'center', padding: 0 }}
        >
          <img src={ICONS.pen} role="presentation" />
          Edit
        </TextButton>
      )}
      {editing && editable && !noTopPadding && (
        <TextButton type="button" onClick={onCancel} css={{ padding: 0 }}>
          Cancel
        </TextButton>
      )}
    </div>
  );
};

export const Label = styled(PrimaryText)`
  flex: 1;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 9px;
  margin-right: 20px;
  font-weight: bold;

  @media (min-width: 420px) {
    flex: 0;
    flex-direction: column;
    margin-right: 0;
  }
`.withComponent('label');

export const SectionContent = styled.div`
  padding: 24px 0;
  ${IF_TABLET} {
    padding: 40px 0;
  }
`;

export const InfoWrap = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  max-width: 100%;
  padding: 5px;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: 420px) {
    flex-direction: column;
    flex-wrap: no-wrap;
    align-items: initial;
    padding: 0;
    ${({ fullWidth }) =>
      fullWidth ? 'max-width: 100%;' : 'max-width: calc(50% - 12px);'}
  }
`;

export const InfoSection: React.FC<{
  htmlFor?: string;
  title?: string;
  className?: string;
  children?: React.ReactNode;
  fullWidth?: boolean;
}> = ({ title, children, className, fullWidth, htmlFor }) => {
  return (
    <InfoWrap fullWidth={fullWidth} className={className}>
      {!!title && <Label htmlFor={htmlFor}>{title}</Label>}
      {children}
    </InfoWrap>
  );
};

const StyledLink = styled.a`
  color: ${Colors.teal};
  text-decoration: none;
`;

export const Link: React.FC<{
  href: string;
  children: React.ReactNode;
}> = ({ href, children }) => (
  <StyledLink href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </StyledLink>
);

export const ContentRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 16px 0;

  @media (min-width: 420px) {
    margin: 24px 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const CondensedRow = styled(ContentRow)`
  margin: 16px 0 0 0;
`;

export const CopyInfoSection: React.FC<{
  label: string;
  text: string;
  fullWidth?: boolean;
}> = ({ label, text, fullWidth }) => {
  return (
    <InfoWrap fullWidth={fullWidth}>
      <Label>{label}</Label>
      <CopyClip text={text}>{text}</CopyClip>
    </InfoWrap>
  );
};

export const SmallModal = styled(DialogContent)`
  width: 400px;
  margin: 30vh auto 0 auto;
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 16px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.13), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
`;
