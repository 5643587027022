/** @jsx jsx */
import {
  AccountPartsFragment,
  useGenerateTemporaryVerificationCodeMutation,
  useUpdateAccountByPkMutation,
  useUpdateAccountPhoneMutation,
} from '@bc/codegen/medical';
import { getPreferredPronoun, PREFERRED_PRONOUN } from '@bc/shared';
import { jsx } from '@emotion/core';
import * as Sentry from '@sentry/react';
import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import parsePhoneNumberFromString from 'libphonenumber-js/min';
import { Fragment } from 'react';
import { DeepPartial } from 'utility-types';
import { object, string } from 'yup';
import { formatPhoneNumber } from '../helpers';
import { PhotoIdDisplay } from './AppointmentDetails/AvatarDisplay';
import { Button, TextButton } from './button';
import {
  CopyToClipField,
  ErrorText,
  InputWrap,
  NormalField,
  PhoneInput,
  SelectSimpleField,
} from './form';
import { ContentRow, HeaderEdit, InfoSection } from './layout';
import { DefinitionList, DefinitionListItem } from './List';
import Loader from './Loader/loader';

const CaregiverValidation = object().shape({
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  goesByName: string(),
  email: string().nullable().email(),
  preferredPronounType: string(),
  preferredPronoun: string(),
});

interface AccountValues {
  id: string;
  firstName: string;
  lastName: string;
  goesByName?: string;
  phoneNumber: string;
  email: string;
  preferredPronounType?: string;
  preferredPronoun?: string;
}

interface CareGiverEditProps {
  account: DeepPartial<AccountPartsFragment>;
}

const getErrorCode = (error: any) => {
  return error?.graphQLErrors?.[0]?.extensions?.internal?.response?.body;
};

export const CareGiverEdit = ({ account }: CareGiverEditProps) => {
  const [updateAccount] = useUpdateAccountByPkMutation();

  const [
    generateTemporaryVerificationCode,
    {
      data: temporaryVerificationCode,
      loading: temporaryVerificationCodeLoading,
    },
  ] = useGenerateTemporaryVerificationCodeMutation();
  const [updatePhoneNumber] = useUpdateAccountPhoneMutation();

  const handleUpdateCaregiver = async (
    { id, ...values }: AccountValues,
    { setStatus, setFieldError }: FormikHelpers<any>,
  ) => {
    try {
      if (id) {
        const parsedNumber = parsePhoneNumberFromString(
          values.phoneNumber,
          'US',
        );

        await updateAccount({
          variables: {
            id: id,
            set: {
              firstName: values.firstName,
              lastName: values.lastName,
              goesByName: values.goesByName,
              email: values.email,
              preferredPronounType: values.preferredPronounType,
              preferredPronoun: values.preferredPronoun,
            },
          },
        });

        if (
          parsedNumber &&
          parsedNumber.isValid() &&
          parsedNumber.number !== account.phoneNumber
        ) {
          await updatePhoneNumber({
            variables: {
              accountId: id,
              phoneNumber: parsedNumber.number.toString(),
            },
          });
        }
      }

      setStatus({ editing: false });
    } catch (error) {
      Sentry.captureException(error);
      if (getErrorCode(error) === 'incorrect_account') {
        setFieldError(
          'phoneNumber',
          "The phone number listed matches another caregiver's information.",
        );
      } else {
        window.alert(
          'There was an error saving. If the problem persists please contact tech support.',
        );
      }
    }
  };

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialStatus={{ editing: false }}
        initialValues={{
          id: account?.id!,
          firstName: account?.firstName || '',
          lastName: account?.lastName || '',
          goesByName: account?.goesByName || '',
          phoneNumber: account?.phoneNumber
            ? formatPhoneNumber(account?.phoneNumber)
            : '',
          email: account?.email || '',
          preferredPronounType: account?.preferredPronounType || '',
          preferredPronoun: account?.preferredPronoun || '',
        }}
        onSubmit={handleUpdateCaregiver}
        validationSchema={CaregiverValidation}
      >
        {({
          status,
          setStatus,
          values,
          resetForm,
          isSubmitting,
          isValid,
          setFieldValue,
        }) => {
          return (
            <Form>
              <HeaderEdit
                title="Basic Information"
                editing={status.editing}
                onEdit={() => {
                  setStatus({ editing: true });
                }}
                onCancel={() => {
                  setStatus({ editing: false });
                  resetForm();
                }}
              />
              {!status.editing && (
                <DefinitionList>
                  {account?.id && (
                    <DefinitionListItem term="Verification pin">
                      {temporaryVerificationCodeLoading ? (
                        <Loader />
                      ) : temporaryVerificationCode
                          ?.GenerateTemporaryVerificationCode?.code ? (
                        temporaryVerificationCode
                          ?.GenerateTemporaryVerificationCode?.code
                      ) : (
                        <TextButton
                          css={{ padding: 0 }}
                          type="button"
                          onClick={() => {
                            if (account?.phoneNumber) {
                              generateTemporaryVerificationCode({
                                variables: {
                                  accountId: account.id!,
                                },
                              });
                            }
                          }}
                        >
                          Show Verification Pin
                        </TextButton>
                      )}
                    </DefinitionListItem>
                  )}
                  <DefinitionListItem term="First name">
                    <CopyToClipField name="firstName" />
                  </DefinitionListItem>
                  <DefinitionListItem term="Last name">
                    <CopyToClipField name="lastName" />
                  </DefinitionListItem>
                  <DefinitionListItem term="Name caregiver goes by">
                    <CopyToClipField name="goesByName" />
                  </DefinitionListItem>
                  <DefinitionListItem term="Phone number">
                    <CopyToClipField
                      name="phoneNumber"
                      transform={formatPhoneNumber}
                    />
                  </DefinitionListItem>
                  <DefinitionListItem term="Email">
                    <CopyToClipField name="email" />
                  </DefinitionListItem>
                  <DefinitionListItem term="Pronouns">
                    {values.preferredPronounType === 'other' ? (
                      <CopyToClipField name="preferredPronoun" />
                    ) : (
                      <CopyToClipField
                        name="preferredPronounType"
                        transform={(value) => getPreferredPronoun(value)}
                      />
                    )}
                  </DefinitionListItem>
                </DefinitionList>
              )}
              {status.editing && (
                <Fragment>
                  <ContentRow>
                    <NormalField title="First name" name="firstName" />
                    <NormalField title="Last name" name="lastName" />
                  </ContentRow>
                  <ContentRow>
                    <NormalField
                      title="Name caregiver goes by"
                      name="goesByName"
                      fullWidth={false}
                    />
                    <InfoSection title="Phone Number" fullWidth={false}>
                      <Field name="phoneNumber">
                        {({ field }: FieldProps<any>) => (
                          <Fragment>
                            <InputWrap>
                              <PhoneInput
                                {...field}
                                onChange={(value) =>
                                  setFieldValue('phoneNumber', value)
                                }
                              />
                            </InputWrap>
                            <ErrorMessage
                              name={field.name}
                              component={ErrorText}
                            />
                          </Fragment>
                        )}
                      </Field>
                    </InfoSection>
                  </ContentRow>
                  <ContentRow>
                    <InfoSection title="Pronouns">
                      <SelectSimpleField name="preferredPronounType">
                        <option value="">Select</option>
                        {PREFERRED_PRONOUN.map(({ label, value }) => {
                          return (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          );
                        })}
                      </SelectSimpleField>
                    </InfoSection>
                    {values.preferredPronounType === 'other' && (
                      <NormalField title="Custom" name="preferredPronoun" />
                    )}
                  </ContentRow>
                  <ContentRow>
                    <div />
                    {isSubmitting ? (
                      <div style={{ margin: '0px auto' }}>
                        <Loader />
                      </div>
                    ) : (
                      <Button type="submit" disabled={!isValid}>
                        Save
                      </Button>
                    )}
                  </ContentRow>
                </Fragment>
              )}
              {account?.id && (
                <ContentRow>
                  <PhotoIdDisplay accountId={account?.id} />
                </ContentRow>
              )}
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};
