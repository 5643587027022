/** @jsx jsx */
import {
  useCreateTaskGroupMutation,
  useLoadStaffQuery,
} from '@bc/codegen/manager';
import { jsx } from '@emotion/core';
import { Form, Formik } from 'formik';
import { RouteComponentProps } from 'react-router';
import { object, string } from 'yup';
import { ICONS } from '../../assets';
import { Button, TransparentButton } from '../../components/button';
import { NormalField } from '../../components/form';
import { ContentRow } from '../../components/layout';
import StaffSearch from '../../components/StaffSearch/StaffSearch';
import { LargeTitle, PrimaryText } from '../../components/text';
import { Close } from '../ViewAppointment.styles';

interface Values {
  name: string;
  staffIds: string[];
}

const GroupValidation = object().shape({
  name: string().required('Required'),
});

export const CreateGroup = ({ history }: RouteComponentProps) => {
  const [createGroup] = useCreateTaskGroupMutation();
  const { data } = useLoadStaffQuery();
  const staffs = data?.staff ?? [];

  const handleSubmit = async (values: Values) => {
    const staffIds = values.staffIds.filter(Boolean).map((staffId) => {
      return {
        staffId,
      };
    });

    await createGroup({
      context: { role: 'manager' },
      variables: {
        group: {
          name: values.name,
          tasks_groups_staffs: {
            data: staffIds,
          },
        },
      },
    });
    history.push('/groups');
  };

  return (
    <div
      css={{
        padding: '40px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <LargeTitle>Create Group</LargeTitle>
        </div>
        <Close onClick={() => history.push('/groups')} />
      </div>
      <Formik
        initialValues={
          {
            name: '',
            staffIds: [],
          } as Values
        }
        validationSchema={GroupValidation}
        onSubmit={handleSubmit}
        initialStatus={{
          editing: true,
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          const staffDisplay = staffs?.filter((staff) => {
            return values.staffIds.includes(staff.id);
          });
          return (
            <Form>
              <ContentRow>
                <NormalField title="Group Name" name="name" fullWidth />
              </ContentRow>
              <div>
                <StaffSearch
                  filterIds={values.staffIds}
                  onChange={(staff) => {
                    if (staff) {
                      setFieldValue('staffIds', [
                        ...values.staffIds,
                        staff?.id,
                      ]);
                    }
                  }}
                />
              </div>
              <div>
                {staffDisplay.map((staff) => {
                  return (
                    <div
                      css={{
                        display: 'flex',
                        padding: '8px 0',
                        alignItems: 'center',
                        gap: '16px',
                      }}
                    >
                      <PrimaryText>
                        {staff?.firstName} {staff?.lastName}
                      </PrimaryText>
                      <TransparentButton
                        type="button"
                        onClick={() => {
                          setFieldValue(
                            'staffIds',
                            values.staffIds.filter((id) => {
                              return id !== staff.id;
                            }),
                          );
                        }}
                      >
                        <img src={ICONS.close} alt="Delete" />
                      </TransparentButton>
                    </div>
                  );
                })}
              </div>
              <ContentRow>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  style={{
                    width: 'auto',
                  }}
                >
                  Save
                </Button>
              </ContentRow>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
