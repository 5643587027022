/** @jsx jsx */
import { GlobalNoteFragment } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import React from 'react';
import { CloseButton } from '../button';
import { ModalTitle } from '../text';
import { Flag } from './Flag';

const SmallModal = styled(DialogContent)`
  max-width: 680px;
  max-height: 80vh;
  overflow-y: scroll;
  margin: 10vh auto 0 auto;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.13), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const ModalClose = styled(CloseButton)`
  border: none;

  &:focus {
    background: white;
    border: none;
  }

  &:hover {
    background: white;
    border: none;
  }
`;

const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ArchivedFlagsModal: React.FC<{
  archivedNotes: GlobalNoteFragment[];
  isOpen: boolean;
  onClose: () => void;
}> = ({ archivedNotes, isOpen, onClose }) => {
  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={onClose}
      style={{
        zIndex: 100,
      }}
    >
      <SmallModal aria-label="Archived Flags">
        <ModalClose
          onClick={onClose}
          css={{ position: 'absolute', top: 24, right: 24 }}
        />
        <ModalTitle alignLeft>Archived Flags</ModalTitle>
        <NoteContainer>
          {archivedNotes.map((note) => (
            <Flag key={note.id} note={note} />
          ))}
        </NoteContainer>
      </SmallModal>
    </DialogOverlay>
  );
};
