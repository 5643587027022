import { Colors } from '@bc/theme';
import React from 'react';

interface ProgressBarProps {
  color?: keyof typeof Colors;
  progress: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  color = Colors.teal,
  progress,
}) => (
  <div
    style={{
      backgroundColor: Colors.grayLight,
      borderRadius: 2.5,
      height: 4,
      width: '100%',
      position: 'relative',
    }}
  >
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: color,
        borderRadius: 2.5,
        height: '100%',
        width: `${progress * 100}%`,
      }}
    />
  </div>
);
