/** @jsx jsx */
import {
  AccountFragment,
  PatientFragment,
  useGetMedicalRecordsReleaseFormQuery,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { Fragment } from 'react';
import { TCell } from '../../pages/account/Profile';
import { ConsentDocumentLink } from '../AppointmentDetails/ConsentDocumentLink';
import { Button } from '../button';
import { HeaderEdit, InfoWrap, Label, Link } from '../layout';
import { Table, TableBody, TableHead, TableHeadCell, TableRow } from '../Table';
import { PrimaryText } from '../text';

const THeadCell = styled(TableHeadCell)({
  border: 'none',
  color: Colors.gray,
});

const TRow = styled(TableRow)({
  ':nth-of-type(odd)': {
    background: 'rgba(239, 241, 241, 0.74)',
  },
});

export const PatientConsents = ({
  patient,
  accompanyingAccount,
  onAgreesToTelehealthTerms,
}: {
  patient: PatientFragment;
  accompanyingAccount?: AccountFragment;
  onAgreesToTelehealthTerms?: () => void;
}) => {
  const { data } = useGetMedicalRecordsReleaseFormQuery({
    variables: { patientId: patient.id },
    fetchPolicy: 'cache-and-network',
    skip: !patient.id,
  });

  const recordsRelease = data?.GetDocument;
  const consentSignedByAccount = patient?.consentSignedByUser?.account;

  return (
    <Fragment>
      <HeaderEdit title="Consent Forms" editable={false} />
      <Table style={{ marginBottom: 16 }}>
        <TableHead>
          <TableRow>
            <THeadCell>&nbsp;</THeadCell>
            <THeadCell>Date Signed</THeadCell>
            <THeadCell>Signed By</THeadCell>
            <THeadCell>&nbsp;</THeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TRow>
            <TCell>In Clinic</TCell>
            <TCell>
              {patient.consentSignedAt
                ? DateTime.fromISO(patient.consentSignedAt).toFormat(
                    'MM/dd/yyyy',
                  )
                : 'Not signed yet'}
            </TCell>
            <TCell>
              {consentSignedByAccount?.firstName}{' '}
              {consentSignedByAccount?.lastName}
            </TCell>
            <TCell>
              {patient?.consentSignedAt && patient.id && (
                <ConsentDocumentLink patientId={patient.id} />
              )}
            </TCell>
          </TRow>
          <TRow>
            <TCell>Records Release</TCell>
            <TCell>
              {recordsRelease?.updatedAt
                ? DateTime.fromISO(recordsRelease?.updatedAt).toFormat(
                    'MM/dd/yyyy',
                  )
                : 'Not signed yet'}
            </TCell>
            <TCell>
              {recordsRelease?.account?.firstName}{' '}
              {recordsRelease?.account?.lastName}
            </TCell>
            <TCell>
              {recordsRelease?.url && (
                <a
                  href={recordsRelease.url}
                  target="_blank"
                  download
                  rel="noopener noreferrer"
                >
                  <PrimaryText>Download Form</PrimaryText>
                </a>
              )}
            </TCell>
          </TRow>
          <TRow>
            <TCell>Telemedicine</TCell>
            <TCell>
              {patient.consentToTelemedicineTermsTimestamp
                ? DateTime.fromISO(
                    patient.consentToTelemedicineTermsTimestamp,
                  ).toFormat('f')
                : 'Not signed yet'}
            </TCell>
            <TCell css={{ maxWidth: 180 }}>
              {patient.consentToTelemedicineTermsByAccount ? (
                <Fragment>
                  {patient.consentToTelemedicineTermsByAccount?.firstName}{' '}
                  {patient.consentToTelemedicineTermsByAccount?.lastName}
                </Fragment>
              ) : (
                <Fragment>
                  {accompanyingAccount && (
                    <InfoWrap fullWidth>
                      <Label css={{ fontWeight: 400 }}>
                        Caregiver has verbally agreed to{' '}
                        <Link href="https://bravecare.com/telemedicine-consent-form">
                          Telemedicine Consent Form
                        </Link>
                      </Label>

                      <Button
                        style={{
                          borderRadius: '100px',
                          fontSize: 14,
                          lineHeight: '18px',
                          padding: '7px 12px',
                        }}
                        onClick={onAgreesToTelehealthTerms}
                      >
                        Yes, {accompanyingAccount?.firstName} agrees
                      </Button>
                    </InfoWrap>
                  )}
                </Fragment>
              )}
            </TCell>
            <TCell></TCell>
          </TRow>
        </TableBody>
      </Table>
    </Fragment>
  );
};
