import {
  ClinicFragment,
  Clinics_Bool_Exp,
  useLoadAllClinicsQuery,
} from '@bc/codegen/medical';
import Downshift from 'downshift';
import { matchSorter } from 'match-sorter';
import React, { useEffect, useState } from 'react';
import { Input, InputWrap } from '../form';
import { ReactComponent as Clear } from './icons/close.svg';
import {
  Container,
  Result,
  ResultDropdownContainer,
  ResultsContainer,
} from './ui';

const fuzzyFilter = (
  input: string,
  clinics: ClinicFragment[],
): ClinicFragment[] => {
  return matchSorter(clinics, input, {
    keys: ['name', 'address'],
  });
};

export const ClinicSearcher = ({
  onChange,
}: {
  onChange: (value: ClinicFragment | null) => void;
}) => {
  return (
    <ClinicSearch
      onChange={(val) => {
        onChange(val);
      }}
      resetOnSelect={true}
    />
  );
};

interface Props {
  value?: string | undefined | null;
  onChange: (value: ClinicFragment | null) => void;
  resetOnSelect?: boolean;
  disabled?: boolean;
  regionIds?: number[];
}

const ClinicSearch = ({
  onChange,
  value,
  resetOnSelect = false,
  disabled = false,
  regionIds,
}: Props) => {
  const [inputValue, setFilterValue] = useState('');
  const regionLimit: Clinics_Bool_Exp = regionIds?.length
    ? {
        clinics_regions: {
          regionId: {
            _in: regionIds,
          },
        },
      }
    : {};
  const { data } = useLoadAllClinicsQuery({
    variables: {
      where: {
        status: {
          _nin: ['PERMANENTLY_CLOSED'],
        },
        ...regionLimit,
      },
    },
  });

  const clinics = data?.clinics ?? [];

  const handleChange = (item: ClinicFragment | null) => {
    onChange(item);
    if (resetOnSelect) {
      setFilterValue('');
    }
  };

  const selectedValue = clinics.find(({ id, clinicCode }) => {
    return id === value || clinicCode === value;
  });

  useEffect(() => {
    if (selectedValue) {
      setFilterValue(selectedValue.name);
    } else {
      setFilterValue('');
    }
  }, [clinics, selectedValue]);

  if (!clinics.length) {
    return null;
  }

  return (
    <Downshift
      inputValue={inputValue}
      itemToString={(item) => (item ? item.name : '')}
      onInputValueChange={(value) => {
        setFilterValue(value);
      }}
      onSelect={handleChange}
    >
      {({
        getRootProps,
        getInputProps,
        getItemProps,
        isOpen,
        openMenu,
        closeMenu,
        highlightedIndex,
        clearSelection,
      }) => {
        return (
          <Container {...getRootProps()}>
            <ResultsContainer>
              <div>
                <InputWrap style={{ marginBottom: 0 }}>
                  <Input
                    {...getInputProps({
                      onFocus: () => {
                        openMenu();
                      },
                      disabled: disabled,
                      type: 'text',
                      placeholder: 'Find Clinic',
                      autoComplete: 'off',
                      onBlur: () => {
                        closeMenu();
                        if (!inputValue) {
                          handleChange(null);
                        }
                      },
                    })}
                    error={false}
                  />
                  {!!value && (
                    <Clear
                      style={{
                        width: '14px',
                        height: '14px',
                        position: 'absolute',
                        right: '20px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        clearSelection();
                        onChange(null);
                        openMenu();
                      }}
                    />
                  )}
                </InputWrap>
              </div>
              {isOpen && (
                <ResultDropdownContainer>
                  <div role="menu">
                    <div>
                      {fuzzyFilter(inputValue, clinics).map(
                        (clinic, index: number) => (
                          <Result
                            href="#"
                            highlighted={highlightedIndex === index}
                            {...getItemProps({
                              item: clinic,
                              index,
                              key: clinic.id,
                              onClick: (e: any) => e.preventDefault(),
                            })}
                          >
                            <span>{clinic.name}</span>
                          </Result>
                        ),
                      )}
                    </div>
                  </div>
                </ResultDropdownContainer>
              )}
            </ResultsContainer>
          </Container>
        );
      }}
    </Downshift>
  );
};

export default ClinicSearch;
