/** @jsx jsx */
import {
  ClinicFragment,
  useInsertAppointmentBlockMutation,
} from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { DialogContent } from '@reach/dialog';
import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useCurrentStaff } from '../../lib/staff';
import { IF_TABLET } from '../../styles';
import { Button, CloseButton, OutlineButton } from '../button';
import { SectionContent } from '../layout';
import { ModalWrap } from '../Routing';
import { MediumTitle, PrimaryText } from '../text';
import BlockerForm from './BlockerForm';

interface Props {
  clinic?: ClinicFragment;
  date: DateTime;
  onCreate: () => void;
  onDismiss: () => void;
}

interface Values {
  reason: string;
  startTime: string;
  endTime: string;
  staffs: string[];
}

export const Content = styled(DialogContent)`
  border-radius: 5px;
  margin: 32px auto;
  max-width: 760px;
  padding: 0;
  width: 100%;
`;

const AppointmentBlocker = ({ clinic, date, onCreate, onDismiss }: Props) => {
  const [insertBlock] = useInsertAppointmentBlockMutation();

  const staff = useCurrentStaff();

  const handleInsert = async (values: Values) => {
    const startTimeParsed = DateTime.fromFormat(values.startTime, 'h:mm a');
    const endTimeParsed = DateTime.fromFormat(values.endTime, 'h:mm a');
    const wrongWay = startTimeParsed.diff(endTimeParsed).milliseconds > 0;

    if (wrongWay) {
      return;
    }

    const appointmentStaff = values.staffs?.map((id) => {
      return {
        staffId: id,
      };
    });

    const startDateTime = date
      .set({
        hour: startTimeParsed.hour,
        minute: startTimeParsed.minute,
      })
      .startOf('minute')
      .toSQL({
        includeZone: false,
        includeOffset: false,
      });
    const endDateTime = date
      .set({
        hour: endTimeParsed.hour,
        minute: endTimeParsed.minute,
      })
      .startOf('minute')
      .toSQL({
        includeZone: false,
        includeOffset: false,
      });

    await insertBlock({
      variables: {
        block: {
          reason: values.reason,
          createdBy: staff?.id,
          startDateTime: startDateTime,
          endDateTime: endDateTime,
          clinicId: clinic?.id,
          appointment_blocks_staffs: {
            data: appointmentStaff,
          },
        },
      },
    });
    onCreate();
  };
  return (
    <ModalWrap onDismiss={onDismiss}>
      <Content>
        <SectionContent
          css={{
            padding: 40,
            position: 'relative',
            [IF_TABLET]: { padding: 40 },
          }}
        >
          <CloseButton
            css={{
              border: 'none',
              position: 'absolute',
              top: 15,
              right: 15,
              ':hover': { border: 'none' },
            }}
            onClick={onDismiss}
          />
          <MediumTitle>Add Time Block</MediumTitle>
          <PrimaryText css={{ display: 'block', margin: '16px 0 24px' }}>
            When you add a time block, people will not be allowed to schedule an
            appointment on the web or app for the duration selected.
          </PrimaryText>
          <Formik
            onSubmit={handleInsert}
            initialValues={{
              reason: '',
              startTime: '',
              endTime: '',
              staffs: [],
            }}
          >
            {() => {
              return (
                <Form>
                  <BlockerForm />
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      marginTop: '24px',
                    }}
                  >
                    <OutlineButton
                      onClick={onDismiss}
                      css={{
                        padding: '16px 40px',
                        marginRight: '16px',
                      }}
                    >
                      Cancel
                    </OutlineButton>
                    <Button type="submit">Save</Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </SectionContent>
      </Content>
    </ModalWrap>
  );
};
export default AppointmentBlocker;
