import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';

const checkImageExists = (path: string): Promise<void> => {
  return new Promise((resolves, rejects) => {
    const image = new Image();
    image.onerror = () => {
      rejects();
    };
    image.onload = () => {
      resolves();
    };
    image.src = path;
  });
};

interface Props {
  value?: string;
  email?: string;
  onUpdate: (link: string) => void;
}

const parseUsername = (email: string) => {
  return (email || '').split('@')[0];
};

const AvatarEmailLookup: React.FC<Props> = ({
  value,
  email = '',
  onUpdate,
}) => {
  const runUpdate = async (username: string) => {
    try {
      const link = `https://bravecare.imgix.net/staff/${username}.png`;
      await checkImageExists(link);
      onUpdate(link);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  useEffect(() => {
    if ((email || '').includes('@')) {
      runUpdate(parseUsername(email));
    }
  }, [email]);

  return (
    <img
      onClick={() => {
        if ((email || '').includes('@')) {
          runUpdate(parseUsername(email));
        }
      }}
      src={value || 'https://bravecare.imgix.net/staff/empty.png'}
      style={{
        width: '40px',
        height: '40px',
        borderRadius: '20px',
      }}
    />
  );
};

export default AvatarEmailLookup;
