/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Menu, MenuItem, MenuList } from '@reach/menu-button';
import { useField } from 'formik';
import { ICONS } from '../../assets';
import { PrimaryText } from '../text';
import { PillMenuButton } from './ui';

const PriorityPill = ({
  background,
  color,
  children,
}: {
  background: string;
  color: string;
  children: React.ReactNode;
}) => {
  return (
    <div css={{ flex: 1, display: 'flex', alignItems: 'flex-start' }}>
      <div
        css={{
          background: background,
          padding: '6px 12px',
          borderRadius: '100px',
        }}
      >
        <PrimaryText
          css={{
            color: color,
            fontWeight: 500,
            fontSize: '14px',
          }}
        >
          {children}
        </PrimaryText>
      </div>
    </div>
  );
};

export const HighPill = (
  <PriorityPill background="#FDE3D7" color="#FF3C00">
    High
  </PriorityPill>
);

export const MediumPill = (
  <PriorityPill background="#FFF4D6" color="#574500">
    Med
  </PriorityPill>
);

export const LowPill = (
  <PriorityPill background="#D2E1F0" color="#0253AB">
    Low
  </PriorityPill>
);

export const PriorityPills = {
  HIGH: HighPill,
  MEDIUM: MediumPill,
  LOW: LowPill,
};

const Display = ({
  value,
  className,
}: {
  value: string;
  className?: string;
}) => {
  if (value === 'HIGH') {
    return (
      <PrimaryText
        css={{ color: '#FF3C00', fontWeight: 500, fontSize: '14px' }}
        className={className}
      >
        High
      </PrimaryText>
    );
  }
  if (value === 'MEDIUM') {
    return (
      <PrimaryText
        css={{ color: '#574500', fontWeight: 500, fontSize: '14px' }}
        className={className}
      >
        Medium
      </PrimaryText>
    );
  }
  if (value === 'LOW') {
    return (
      <PrimaryText
        css={{ color: '#0253AB', fontWeight: 500, fontSize: '14px' }}
        className={className}
      >
        Low
      </PrimaryText>
    );
  }
  return null;
};

export const PriorityDropdown = ({ name }: { name: string }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <Menu>
      <PillMenuButton icon={ICONS.priority}>
        Priority{field.value && `: ${'\u00A0'}`}
        <Display
          value={field.value}
          css={{
            fontSize: '16px',
          }}
        />
      </PillMenuButton>
      <MenuList
        css={{
          border: 'none',
          boxShadow:
            '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
          borderRadius: '16px',
          padding: 0,
          overflow: 'hidden',
          minWidth: '300px',
        }}
      >
        <MenuItem
          onSelect={() => {
            helpers.setValue('HIGH');
          }}
          css={{
            padding: '16px 24px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {HighPill}
          {field.value === 'HIGH' && <img src={ICONS.checkmarkGrey} />}
        </MenuItem>
        <MenuItem
          onSelect={() => {
            helpers.setValue('MEDIUM');
          }}
          css={{
            padding: '16px 24px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {MediumPill}
          {field.value === 'MEDIUM' && <img src={ICONS.checkmarkGrey} />}
        </MenuItem>
        <MenuItem
          onSelect={() => {
            helpers.setValue('LOW');
          }}
          css={{
            padding: '16px 24px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {LowPill}
          {field.value === 'LOW' && <img src={ICONS.checkmarkGrey} />}
        </MenuItem>
        {!!field.value && (
          <MenuItem
            onSelect={() => {
              helpers.setValue(undefined);
            }}
            css={{
              display: 'flex',
              alignItems: 'center',
              padding: '16px 24px',
            }}
          >
            <PrimaryText css={{ color: '#FF3C00' }}>Remove</PrimaryText>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};
