import { TriageJSON } from '@bc/triage';
import { DateTime } from 'luxon';
import React from 'react';
import { friendlyAge } from '../Issues/helpers';
import { Question } from '../Issues/interfaces';
import Issues from '../Issues/issues';
import { ContentRow, HeaderEdit, InfoSection, SectionContent } from '../layout';
import { PrimaryNotBlank } from '../text';

const questions = TriageJSON as any;

interface Props {
  appointment: any;
}

const SymptomCheckerDisplay: React.FC<Props> = ({ appointment }) => {
  const symptomChecker = appointment?.symptom_checker?.session;
  const answer: Question =
    symptomChecker?.metadata &&
    questions.find(({ id }: any) => id === symptomChecker.metadata.exitId);

  if (!symptomChecker) {
    return null;
  }

  return (
    <SectionContent>
      <HeaderEdit title="Symptom Checker" editable={false} />
      <ContentRow>
        <InfoSection title="Name">
          <PrimaryNotBlank>{symptomChecker.answers.name}</PrimaryNotBlank>
        </InfoSection>
        <InfoSection title="Age">
          <PrimaryNotBlank>
            {symptomChecker?.answers?.age &&
              friendlyAge(DateTime.fromISO(symptomChecker.answers.age))}
          </PrimaryNotBlank>
        </InfoSection>

        <InfoSection title="Recommendation">
          <PrimaryNotBlank>{answer?.exit}</PrimaryNotBlank>
        </InfoSection>
      </ContentRow>
      <Issues
        answers={symptomChecker.answers}
        questions={questions}
        color="#D6DBDB"
      />
    </SectionContent>
  );
};

export default SymptomCheckerDisplay;
