/** @jsx jsx */
import {
  useAddStaffToTaskGroupMutation,
  useDeleteStaffFromTaskGroupMutation,
  useLoadStaffQuery,
} from '@bc/codegen/manager';
import { useLoadTaskGroupStaffsQuery } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { RouteComponentProps } from 'react-router';
import { ICONS } from '../../assets';
import { TransparentButton } from '../../components/button';
import { Loader } from '../../components/Loader';
import StaffSearch from '../../components/StaffSearch/StaffSearch';
import { LargeTitle, PrimaryText } from '../../components/text';
import { Close } from '../ViewAppointment.styles';

const context = { role: 'manager' };

export const ViewGroup = ({
  match,
  history,
}: RouteComponentProps<{ id: string }>) => {
  const groupId = match.params.id;
  const [removeStaffFromGroup, { loading: removeLoading }] =
    useDeleteStaffFromTaskGroupMutation();
  const [addStaff, { loading: staffAddLoading }] =
    useAddStaffToTaskGroupMutation();

  const {
    data,
    refetch,
    loading: groupLoading,
  } = useLoadTaskGroupStaffsQuery({
    context,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: groupId,
    },
  });

  const group = data?.tasks_groups_by_pk;
  const staffIds =
    group?.tasks_groups_staffs?.map((staff) => {
      return staff.staffId;
    }) ?? [];

  const { data: staffData } = useLoadStaffQuery();

  const staffs = staffData?.staff ?? [];

  const loading = groupLoading || removeLoading || staffAddLoading;

  return (
    <div
      css={{
        padding: '40px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          alignItems: 'center',
          marginBottom: '40px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <LargeTitle>{group?.name}</LargeTitle>
        </div>
        <Close onClick={() => history.push('/groups')} />
      </div>

      {loading && <Loader />}
      {!loading && (
        <Fragment>
          <div>
            <div
              css={{
                marginBottom: '16px',
              }}
            >
              <PrimaryText
                css={{
                  fontSize: '18px',
                }}
              >
                Add More Staff
              </PrimaryText>
            </div>
            <StaffSearch
              filterIds={staffIds}
              onChange={async (staff) => {
                if (staff) {
                  await addStaff({
                    context,
                    variables: {
                      groupStaff: {
                        taskGroupId: groupId,
                        staffId: staff.id,
                      },
                    },
                  });
                  refetch();
                }
              }}
            />
          </div>
          <div>
            {group?.tasks_groups_staffs?.map((staffGroup) => {
              const staff = staffs.find(({ id }) => {
                return id === staffGroup.staffId;
              });

              return (
                <div
                  css={{
                    display: 'flex',
                    padding: '8px 0',
                    alignItems: 'center',
                    gap: '16px',
                  }}
                >
                  <PrimaryText>
                    {staff?.firstName} {staff?.lastName}
                  </PrimaryText>
                  <TransparentButton
                    type="button"
                    onClick={async () => {
                      await removeStaffFromGroup({
                        context,
                        variables: {
                          id: staffGroup.id,
                        },
                      });
                      refetch();
                    }}
                  >
                    <img src={ICONS.close} alt="Delete" />
                  </TransparentButton>
                </div>
              );
            })}
          </div>
        </Fragment>
      )}
    </div>
  );
};
