/** @jsx jsx */
import { useLoadTasksCountQuery } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { Link, useRouteMatch } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import CollapseIcon from '../../assets/collapse-menu.svg';
import { useWindowSize } from '../../hooks';
import { useCallsWatch } from '../../lib/sound';
import { useAuth0Id } from '../../react-auth0';
import { FONT_PRIMARY, IF_TABLET } from '../../styles';
import AppointmentsIcon from './icons/Appointments';
import HelpIcon from './icons/Help';
import ProductsIcon from './icons/Products';
import RoomsIcon from './icons/Rooms';
import StaffManagementIcon from './icons/StaffManagement';
import StaffScheduleIcon from './icons/StaffSchedule';
import TasksIcon from './icons/Tasks';
const notSelected = Colors.gray;
const selectedColor = Colors.darkHarbor;

const NavLink = styled(Link)<{ selected: boolean }>`
  align-items: center;
  background-color: ${(selected) =>
    selected ? 'rgba(255, 255, 255, 0.5)' : 'none'};
  color: ${Colors.darkHarbor};
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  justify-content: center;
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  padding: 10px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: 0.2s opacity ease;

  &:hover {
    opacity: 1;
  }

  ${IF_TABLET} {
    flex-wrap: nowrap;
    font-weight: bold;
    justify-content: flex-start;
    padding: 16px 20px;
    text-align: left;
    width: 100%;

    svg {
      max-width: 24px;
      min-width: 24px;
    }
  }
`;

const SubNav = styled.div`
  background: rgba(0, 0, 0, 0.08);
  display: flex;
  ${IF_TABLET} {
    display: block;
  }
`;

const Text = styled.span`
  ${FONT_PRIMARY}
  color: inherit;
  font-size: 12px;
  width: 100%;
  ${IF_TABLET} {
    font-size: inherit;
    margin-left: 20px;
    white-space: nowrap;
    width: auto;
  }
`;

const SubNavText = styled(Text)`
  ${IF_TABLET} {
    margin-left: 0px;
  }
`;

const SidebarWrapper = styled.div<{ collapse?: boolean }>`
  background-color: ${Colors.grayLightest};
  bottom: 0;
  display: flex;
  flex-wrap: no-wrap;
  justify-content: flex-start;
  left: 0;
  overflow-x: scroll;
  position: fixed;
  transition: all 0.2s ease-in-out;
  width: 100vw;
  z-index: 16;
  ${IF_TABLET} {
    bottom: inherit;
    display: block;
    height: calc(100vh - 72px);
    justify-content: center;
    left: inherit;
    max-width: ${({ collapse }) => (collapse ? '64px' : '250px')};
    overflow-y: scroll;
    position: inherit;
    position: sticky;
    top: 72px;
    width: 100%;
    z-index: 50;
  }
`;

const UnreadBadge = styled.div`
  ${FONT_PRIMARY}
  align-items: center;
  background-color: ${Colors.orange};
  border-radius: 4px;
  color: ${Colors.white};
  display: flex;
  font-size: 10px;
  height: 20px;
  justify-content: center;
  line-height: 12px;
  margin-left: auto;
  min-width: 20px;
  width: 20px;
`;

const CollapseWrapper = styled.div`
  align-self: center;
  display: flex;
  justify-content: flex-end;
  ${IF_TABLET} {
    display: flex;
    position: relative;
  }
`;

const Button = styled.button<{ collapse: boolean }>`
  background: white;
  border-radius: 100px;
  border: none;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  height: ${({ collapse }) => (collapse ? 30 : 40)}px;
  padding: 0;
  width: ${({ collapse }) => (collapse ? 30 : 40)}px;
  ${IF_TABLET} {
    align-items: center;
    bottom: 40px;
    display: flex;
    height: 40px;
    justify-content: center;
    position: fixed;
    transform: translateX(-${({ collapse }) => (collapse ? 12 : 16)}px);
    width: 40px;
  }
  :focus {
    box-shadow: inset 0 0 0 1px #034a46, 0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    outline: none;
  }
  span {
    display: none;
  }
  :hover {
    align-items: center;
    display: flex;
    width: fit-content;
    ${({ collapse }) =>
      collapse
        ? `
          left: 0;
          padding: 8px 16px 8px 8px;
          transform: translateX(12px);
        `
        : `
          flex-direction: row-reverse;
          padding: 8px 8px 8px 16px;
        `}
    span {
      display: flex;
      white-space: nowrap;
      ${({ collapse }) =>
        collapse
          ? `
            margin-left: 8px;`
          : `
            margin-left: 0;
            margin-right: 8px;
        `}
    }
  }
`;

const Icon = styled.img<{ collapse: boolean }>`
  transform: rotate(${({ collapse }) => (collapse ? 180 : 0)}deg);
`;

const CollapsedChatButton = styled.button<{ selected: boolean }>`
  align-items: center;
  background: ${Colors.grayLightest};
  border: none;
  color: ${({ selected }) => (selected ? selectedColor : notSelected)};
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  justify-content: center;
  padding: 10px;
  position: relative;
  text-align: center;
  text-decoration: none;

  ${IF_TABLET} {
    flex-wrap: nowrap;
    font-weight: bold;
    height: unset;
    justify-content: flex-start;
    padding: 16px 8px 16px 20px;
    text-align: left;
    width: 100%;
  }

  &:hover {
    color: ${selectedColor};
  }

  &:focus {
    border: none;
    outline: none;
  }
`;

const CollapsedChatWrapper = styled.div<{
  topSelected?: boolean;
  collapse: boolean;
  bottomSelected?: boolean;
}>`
  background: ${Colors.white};
  border-radius: 16px;
  bottom: ${({ collapse }) => (collapse ? 56 : 80)}px;
  display: flex;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.2));
  flex-direction: column;
  position: fixed;
  ::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: calc(50% - 8px);
    bottom: -16px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-top: 8px solid
      ${({ bottomSelected }) =>
        bottomSelected ? Colors.grayLightest : Colors.white};
  }
  ::before {
    bottom: -15px;
    content: '';
    height: 15px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  ${IF_TABLET} {
    transform: translate(60px, 60px);
    bottom: unset;
    ::after {
      left: -15px;
      top: 16px;
      border-right: 8px solid
        ${({ topSelected }) =>
          topSelected ? Colors.grayLightest : Colors.white};
      border-top: 8px solid transparent;
    }
    ::before {
      bottom: 0;
      height: 100%;
      left: -30px;
      width: 30px;
    }
  }
`;

const CollapsedNavLink = styled(NavLink)<{ selected: boolean }>`
  flex-wrap: nowrap;
  text-align: left;
  ${IF_TABLET} {
    padding: 12px 24px;
    justify-content: space-between;
  }
  background: ${({ selected }) => selected && Colors.grayLightest};
`;

const CollapsedBadge = styled(UnreadBadge)`
  margin-left: 16px;
`;

const PROVIDER_TYPES = [
  'PHYSICIAN',
  'PHYSICIAN_ASSISTANT',
  'NURSE_PRACTITIONER',
];

export const Sidebar = () => {
  const { innerWidth } = useWindowSize();
  const staffAuthId = useAuth0Id();
  const isMobile = innerWidth < 576;
  const [collapse, setCollapse] = useLocalStorage('collapseSidebar', false);
  const appointmentSelected = useRouteMatch('/dashboard');
  const tasksSelected = useRouteMatch('/tasks');
  const communicationsSelected = useRouteMatch('/communications');
  const helpSelected = useRouteMatch('/help');
  const productsSelected = useRouteMatch('/products');
  const staffManagementSelected = useRouteMatch({
    exact: true,
    path: '/staff',
  });
  const staffScheduleSelected = useRouteMatch('/staff/schedule');
  const roomsSelected = useRouteMatch('/rooms');
  const mergeSelected = useRouteMatch('/patient_merger');
  const start = DateTime.local().minus({ week: 3 }).startOf('day');
  const end = DateTime.local().plus({ week: 3 }).endOf('day');

  const { data: taskOpenData } = useLoadTasksCountQuery({
    variables: {
      start: start.toISO(),
      end: end.toISO(),
      staffAuthId: staffAuthId || '',
    },
    pollInterval: 60 * 1000, // 1 minute
    skip: !staffAuthId,
  });

  const taskOpenCount = taskOpenData?.tasks_aggregate?.aggregate?.count ?? 0;

  const { queuedCallsCount } = useCallsWatch();

  return (
    <SidebarWrapper collapse={collapse}>
      <NavLink
        selected={!!appointmentSelected}
        to="/dashboard"
        title={collapse ? 'Appointments' : undefined}
      >
        <AppointmentsIcon
          color={appointmentSelected ? selectedColor : notSelected}
        />
        {!collapse && <Text>Appointments</Text>}
      </NavLink>
      <NavLink
        selected={!!tasksSelected}
        to="/tasks"
        title={collapse ? 'Tasks' : undefined}
      >
        <div
          css={{
            position: 'relative',
          }}
        >
          {taskOpenCount > 0 && (
            <div
              style={{
                height: 14,
                width: 14,
                borderRadius: 7,
                border: `2px solid ${Colors.grayLightest}`,
                background: Colors.orange,
                position: 'absolute',
                top: -4,
                right: -4,
              }}
            />
          )}
          <TasksIcon color={tasksSelected ? selectedColor : notSelected} />
        </div>
        {!collapse && <Text>Tasks</Text>}
      </NavLink>
      <NavLink
        selected={!!staffScheduleSelected}
        to="/staff/schedule"
        title={collapse ? 'Schedule Management' : undefined}
      >
        <StaffScheduleIcon
          color={staffScheduleSelected ? selectedColor : notSelected}
        />
        {!collapse && <Text>Schedule Management</Text>}
      </NavLink>
      <NavLink
        selected={!!staffManagementSelected}
        to="/staff"
        title={collapse ? 'Staff Management' : undefined}
      >
        <StaffManagementIcon
          color={staffManagementSelected ? selectedColor : notSelected}
        />
        {!collapse && <Text>Staff Management</Text>}
      </NavLink>
      <NavLink
        selected={!!roomsSelected}
        to="/rooms"
        title={collapse ? 'Rooms' : undefined}
      >
        <RoomsIcon color={roomsSelected ? selectedColor : notSelected} />
        {!collapse && <Text>Rooms</Text>}
      </NavLink>
      <NavLink
        selected={!!productsSelected}
        to="/products"
        title={collapse ? 'Products' : undefined}
      >
        <ProductsIcon color={productsSelected ? selectedColor : notSelected} />
        {!collapse && <Text>Products</Text>}
      </NavLink>
      {/* <NavLink selected={!!mergeSelected} to="/patient_merger">
        <RoomsIcon color={mergeSelected ? selectedColor : notSelected} />
        {!collapse && <Text>Patient Merge</Text>}
      </NavLink> */}
      <NavLink
        selected={!!helpSelected}
        to="/help"
        title={collapse ? 'Help' : undefined}
      >
        <HelpIcon color={helpSelected ? selectedColor : notSelected} />
        {!collapse && <Text>Help</Text>}
      </NavLink>
      <CollapseWrapper>
        <Button
          collapse={!!collapse}
          onClick={() => setCollapse(!collapse)}
          onMouseDown={(e) => e.preventDefault()}
          type="button"
        >
          <Icon src={CollapseIcon} collapse={!!collapse} />
          {!isMobile && (
            <Text css={{ color: Colors.darkHarbor }}>
              {!!collapse ? 'Show' : 'Hide'} Side Panel
            </Text>
          )}
        </Button>
      </CollapseWrapper>
    </SidebarWrapper>
  );
};
