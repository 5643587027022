/** @jsx jsx */
import { StaffFragment } from '@bc/codegen/manager';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import Downshift from 'downshift';
import { matchSorter } from 'match-sorter';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ClearIcon } from '../../assets/close.svg';
import { Input, InputWrap } from '../form';
import {
  Container,
  Result,
  ResultDropdownContainer,
  ResultsContainer,
} from './ui';

const Clear = styled(ClearIcon)`
  width: 10px;
  height: 10px;
  cursor: pointer;
  g {
    fill: ${Colors.gray};
  }
`;

interface Props {
  value?: string;
  onChange: (value: StaffFragment | null) => void;
  filterIds?: string[];
  staffItems: StaffFragment[];
}

const fuzzyFilter = (
  input: string,
  staff: StaffFragment[],
  filterIds: string[],
): StaffFragment[] => {
  return matchSorter(staff, input, {
    keys: ['firstName', 'lastName', 'email'],
  }).filter(({ id }) => {
    return !filterIds.includes(id);
  });
};

const StaffScheduleSearch: React.FC<Props> = ({
  staffItems,
  onChange,
  filterIds = [],
  value,
}) => {
  const [inputValue, setFilterValue] = useState('');
  const staffs = staffItems;

  useEffect(() => {
    if (value) {
      const staff = staffs.find(({ id }) => {
        return id === value;
      });
      if (staff) {
        setFilterValue(`${staff.firstName} ${staff.lastName}`);
      }
    }
  }, [staffs]);

  const handleChange = (item: StaffFragment | null) => {
    onChange(item);
    setFilterValue('');
  };

  const selected =
    staffs.find(({ id }) => {
      return id === value;
    }) || null;

  return (
    <Downshift
      selectedItem={selected}
      inputValue={inputValue}
      itemToString={(staff) =>
        staff ? `${staff.firstName} ${staff.lastName}` : ''
      }
      onInputValueChange={(value) => {
        setFilterValue(value);
      }}
      onSelect={handleChange}
    >
      {({
        getRootProps,
        getInputProps,
        getItemProps,
        isOpen,
        openMenu,
        closeMenu,
        clearSelection,
        highlightedIndex,
      }) => {
        return (
          <Container {...getRootProps()}>
            <ResultsContainer>
              <div>
                <InputWrap
                  style={{
                    marginBottom: 0,
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <Input
                    {...getInputProps({
                      onFocus: openMenu,
                      type: 'text',
                      placeholder: 'Find Staff',
                      autoComplete: 'off',
                      onBlur: () => {
                        closeMenu();
                        if (!inputValue) {
                          clearSelection();
                        } else if (selected) {
                          setFilterValue(
                            `${selected?.firstName} ${selected?.lastName}`,
                          );
                        }
                      },
                    })}
                    error={false}
                    css={{
                      flex: 1,
                    }}
                  />
                  {selected && (
                    <Clear
                      onClick={() => clearSelection()}
                      css={{
                        position: 'relative',
                        right: '24px',
                      }}
                    />
                  )}
                </InputWrap>
              </div>
              {isOpen && (
                <ResultDropdownContainer>
                  <div role="menu">
                    <div>
                      {fuzzyFilter(inputValue, staffs, filterIds).map(
                        (member, index: number) => (
                          <Result
                            href="#"
                            highlighted={highlightedIndex === index}
                            {...getItemProps({
                              item: member,
                              index,
                              key: member.id,
                              onClick: (e: any) => e.preventDefault(),
                            })}
                          >
                            <span>
                              {member.firstName} {member.lastName} (
                              {member.type})
                            </span>
                          </Result>
                        ),
                      )}
                    </div>
                  </div>
                </ResultDropdownContainer>
              )}
            </ResultsContainer>
          </Container>
        );
      }}
    </Downshift>
  );
};

export default StaffScheduleSearch;
