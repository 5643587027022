import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string;
}

const Products: React.FC<Props> = ({ color, ...props }) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M20 6C20 7.1 19.1 8 18 8C16.9 8 16 7.1 16 6C16 4.9 16.9 4 18 4C19.1 4 20 4.9 20 6Z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 10C23 10.5 22.7 11.3 22.3 11.7L11.7 22.7C11.3 23.1 10.7 23.1 10.3 22.7L1.3 13.7C0.9 13.3 0.9 12.7 1.3 12.3L12.3 1.7C12.7 1.3 13.5 1 14 1H22C22.5 1 23 1.4 23 2V10Z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Products;
