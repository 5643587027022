import { DialogOverlay } from '@reach/dialog';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {
  children?: React.ReactNode;
  onDismissGoToPath?: string;
}

export const FullScreenModalWrap: React.FC<Props> = ({
  history,
  children,
  onDismissGoToPath = '/dashboard',
}) => (
  <DialogOverlay
    isOpen
    onDismiss={() => history.push(onDismissGoToPath)}
    style={{
      zIndex: 99,
      width: '100%',
      top: 72,
    }}
  >
    {children}
  </DialogOverlay>
);
