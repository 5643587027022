import { useGetPatientDocumentsQuery } from '@bc/codegen/medical';
import styled from '@emotion/styled';
import React from 'react';
import { IF_TABLET } from '../../styles';
import { TextButton } from '../button';

const Button = styled(TextButton)({
  padding: 0,
  fontSize: 14,
  lineHeight: '18px',
  [IF_TABLET]: {
    fontSize: 16,
    lineHeight: '19px',
  },
}).withComponent('a');

export const ConsentDocumentLink = ({
  children,
  patientId,
}: {
  children?: React.ReactNode;
  patientId?: string;
}) => {
  const { data: patientData } = useGetPatientDocumentsQuery({
    fetchPolicy: 'no-cache',
    variables: { patientId: patientId! },
    skip: !patientId,
  });

  const url = patientData?.patients_by_pk?.consentDocument?.url;

  if (!url) {
    return null;
  }

  return (
    <Button href={url} target="_blank" download rel="noopener noreferrer">
      {children || 'Download Form'}
    </Button>
  );
};
