/**
 * Our default spacing scale. Try to keep spacing within these options.
 */
export const SPACING = {
  /**
   * 4px - extra extra small
   */
  xxs: 4,

  /**
   * 8px - extra small
   */
  xs: 8,

  /**
   * 16px - small
   */
  s: 16,

  /**
   * 24px - medium
   */
  m: 24,

  /**
   * 32px - large
   */
  l: 32,

  /**
   * 40px - extra large
   */
  xl: 40,

  /**
   * 64px - extra extra large
   */
  xxl: 64,
};

/**
 * Our default spacing scale but without the yelling.
 */
export const Spacing = SPACING;
