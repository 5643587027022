import {
  useGetAccountAvatarQuery,
  useGetAccountPhotoIdsQuery,
  useGetInsuranceImageQuery,
  useGetPatientAvatarQuery,
  useRequestUploadMutation,
  useUpdateAccountByPkMutation,
  useUpdateFileMutation,
  useUpdateInsuranceMutation,
} from '@bc/codegen/medical';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import React, { Fragment, useState } from 'react';
import { useFileUpload } from 'use-file-upload';
import NotFound from '../../assets/notfound.png';
import { TextButton } from '../button';
import { Loader } from '../Loader';
import { PrimaryText } from '../text';

const GreenTextLink = TextButton.withComponent('a');

const Side = styled.div({
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});

const Image = styled.img<{ size?: number }>(({ size = 72 }) => ({
  height: size,
  width: size,
  borderRadius: '50%',
  backgroundColor: '#8e949d',
}));

Image.defaultProps = {
  src: NotFound,
};

const DisplayImage = styled.img({
  width: '100%',
  height: '212px',
  objectFit: 'contain',
  margin: '8px 0',
});

const Label = styled(PrimaryText)({
  fontSize: 14,
  fontWeight: 'bold',
  lineHeight: '18px',
  marginBottom: 8,
});

const Button = styled(TextButton)({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '18px',
  padding: '8px 0',
});

export const PatientAvatarDisplay: React.FC<
  {
    patientId?: string;
    style?: React.CSSProperties;
    alt?: string;
    title?: string;
    onClick?: () => void;
    size?: number;
  } & React.HTMLAttributes<HTMLImageElement>
> = ({ patientId, ...props }) => {
  const { data: patientData } = useGetPatientAvatarQuery({
    variables: { patientId: patientId! },
    skip: !patientId,
  });

  const url = patientData?.patients_by_pk?.avatar?.url;
  return <Image src={url ?? undefined} {...props} />;
};

export const PatientAvatarLink: React.FC<{
  patientId?: string;
  style?: React.CSSProperties;
  alt?: string;
  title?: string;
  onClick?: () => void;
}> = ({ patientId, ...props }) => {
  const { data: patientData } = useGetPatientAvatarQuery({
    variables: { patientId: patientId! },
    skip: !patientId,
  });

  const url = patientData?.patients_by_pk?.avatar?.url;

  return (
    <GreenTextLink href={url ?? undefined} {...props}>
      View
    </GreenTextLink>
  );
};

export const AccountAvatarDisplay: React.FC<
  {
    accountId?: string;
    style?: React.CSSProperties;
    alt?: string;
    title?: string;
    onClick?: () => void;
    size?: number;
    fallbackUrl?: string;
  } & React.HTMLAttributes<HTMLImageElement>
> = ({ accountId, fallbackUrl, ...props }) => {
  const { data: accountData } = useGetAccountAvatarQuery({
    variables: { accountId: accountId! },
    skip: !accountId,
  });

  const url = accountData?.accounts_by_pk?.avatar?.url ?? fallbackUrl;
  return <Image src={url ?? undefined} {...props} />;
};

export const PhotoIDLink = ({
  accountId,
  display,
}: {
  accountId?: string;
  display: 'front' | 'back';
}) => {
  const { data: accountData, loading } = useGetAccountPhotoIdsQuery({
    variables: { accountId: accountId! },
    skip: !accountId,
  });

  if (!accountData || loading) {
    return null;
  }

  const frontUrl = accountData?.accounts_by_pk?.photoIdFront?.url;
  const backUrl = accountData?.accounts_by_pk?.photoIdBack?.url;

  if (display === 'front') {
    return (
      <GreenTextLink
        href={frontUrl || undefined}
        target="_blank"
        rel="noopener noreferrer"
      >
        View
      </GreenTextLink>
    );
  }

  if (display === 'back') {
    return (
      <GreenTextLink
        href={backUrl || undefined}
        target="_blank"
        rel="noopener noreferrer"
      >
        View
      </GreenTextLink>
    );
  }

  return null;
};

export const PhotoIdDisplay: React.FC<{ accountId?: string }> = ({
  accountId,
}) => {
  const [uploading, setUploading] = useState<'FRONT' | 'BACK'>();

  const {
    data: accountData,
    loading,
    refetch,
  } = useGetAccountPhotoIdsQuery({
    variables: { accountId: accountId! },
    skip: !accountId,
  });

  const [requestUpload] = useRequestUploadMutation();
  const [updateFile] = useUpdateFileMutation();
  const [updateAccount] = useUpdateAccountByPkMutation();
  const [, selectFile] = useFileUpload();

  if (!accountData || loading) {
    return null;
  }

  const frontUrl = accountData?.accounts_by_pk?.photoIdFront?.url;
  const backUrl = accountData?.accounts_by_pk?.photoIdBack?.url;

  const handleUpload = async (type: 'FRONT' | 'BACK') => {
    selectFile(
      { accept: 'image/*', multiple: false },
      async ({ name, file }: any) => {
        setUploading(type);

        const { data: requestData } = await requestUpload({
          variables: {
            contentMD5: '',
            fileName: name,
            accountId,
          },
        });

        const { fileId, url, mimeType } = requestData?.RequestUpload || {};

        if (url && mimeType) {
          const success = await upload({
            file: file,
            uploadTo: url,
            mimeType,
          });
          if (success) {
            let update = {};
            if (type === 'FRONT') {
              update = {
                photoIdFrontId: fileId,
              };
            } else {
              update = {
                photoIdBackId: fileId,
              };
            }
            // Swap front/back
            await Promise.all([
              await updateFile({
                variables: {
                  id: fileId!,
                  file: {
                    status: 'UPLOADED',
                  },
                },
              }),
              await updateAccount({
                variables: {
                  id: accountId!,
                  set: update,
                },
              }),
            ]);

            refetch();
          }
        }

        setUploading(undefined);
      },
    );
  };

  return (
    <Fragment>
      <Side style={{ paddingRight: '10px' }}>
        <Label>Front of ID</Label>
        {uploading === 'FRONT' && <Loader />}
        {uploading !== 'FRONT' && frontUrl && (
          <a href={frontUrl} target="_blank" rel="noopener noreferrer">
            <DisplayImage src={frontUrl} alt="Front of ID" />
          </a>
        )}
        <Button
          css={{ padding: '8px 0' }}
          onClick={() => handleUpload('FRONT')}
        >
          {frontUrl ? 'Replace photo' : 'Upload photo'}
        </Button>
      </Side>
      <Side>
        <Label>Back of ID</Label>
        {uploading === 'BACK' && <Loader />}
        {uploading !== 'BACK' && backUrl && (
          <a href={backUrl} target="_blank" rel="noopener noreferrer">
            <DisplayImage src={backUrl} alt="Back of ID" />
          </a>
        )}
        <Button onClick={() => handleUpload('BACK')}>
          {backUrl ? 'Replace photo' : 'Upload photo'}
        </Button>
      </Side>
    </Fragment>
  );
};

interface UploadOptions {
  uploadTo: string;
  file: File;
  mimeType: string;
}

const upload = async (options: UploadOptions) => {
  try {
    const { file, uploadTo, mimeType } = options;
    await fetch(uploadTo, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': mimeType,
      },
    });
    return true;
  } catch (error) {
    console.log('error with upload', error);
    Sentry.captureException(error);
    return false;
  }
};

export const InsuranceImageDisplay: React.FC<{ insuranceId?: string }> = ({
  insuranceId,
}) => {
  const {
    data: insuranceData,
    refetch,
    loading,
  } = useGetInsuranceImageQuery({
    variables: { insuranceId: insuranceId! },
    skip: !insuranceId,
  });

  const [requestUpload] = useRequestUploadMutation();
  const [updateFile] = useUpdateFileMutation();
  const [updateInsurance] = useUpdateInsuranceMutation();
  const [, selectFile] = useFileUpload();

  const accountId = insuranceData?.insurance_by_pk?.accountId;

  const handleUpload = async (type: 'FRONT' | 'BACK') => {
    selectFile(
      { accept: 'image/*', multiple: false },
      async ({ name, file }: any) => {
        const { data: requestData } = await requestUpload({
          variables: {
            contentMD5: '',
            fileName: name,
            accountId,
          },
        });

        const { fileId, url, mimeType } = requestData?.RequestUpload || {};

        if (url && mimeType) {
          const success = await upload({
            file: file,
            uploadTo: url,
            mimeType,
          });
          if (success) {
            let update = {};
            if (type === 'FRONT') {
              update = {
                frontId: fileId,
              };
            } else {
              update = {
                backId: fileId,
              };
            }
            // Swap front/back
            await Promise.all([
              await updateFile({
                variables: {
                  id: fileId!,
                  file: {
                    status: 'UPLOADED',
                  },
                },
              }),
              await updateInsurance({
                variables: {
                  insuranceId: insuranceId!,
                  set: update,
                },
              }),
            ]);

            refetch();
          }
        }
      },
    );
  };

  if (!insuranceId || loading) {
    return null;
  }

  const frontUrl = insuranceData?.insurance_by_pk?.front?.url;
  const backUrl = insuranceData?.insurance_by_pk?.back?.url;

  return (
    <Fragment>
      <Side
        style={{
          paddingRight: '10px',
        }}
      >
        <Label>Front of Insurance card</Label>
        {frontUrl && (
          <a href={frontUrl} target="_blank" rel="noopener noreferrer">
            <DisplayImage
              src={frontUrl ?? undefined}
              alt="Front Insurance Card"
            />
          </a>
        )}
        <Button
          onClick={() => {
            handleUpload('FRONT');
          }}
        >
          {frontUrl ? 'Replace Front' : 'Upload Front'}
        </Button>
      </Side>
      <Side>
        <Label>Back of Insurance card</Label>
        {backUrl && (
          <a href={backUrl} target="_blank" rel="noopener noreferrer">
            <DisplayImage src={backUrl} alt="Back Insurance Card" />
          </a>
        )}
        <Button
          onClick={() => {
            handleUpload('BACK');
          }}
        >
          {backUrl ? 'Replace Back' : 'Upload Back'}
        </Button>
      </Side>
    </Fragment>
  );
};

export const InsuranceImageLink = ({
  insuranceId,
  display,
}: {
  insuranceId?: string;
  display: 'front' | 'back';
}) => {
  const { data: insuranceData, loading } = useGetInsuranceImageQuery({
    variables: { insuranceId: insuranceId! },
    skip: !insuranceId,
  });

  if (!insuranceId || loading) {
    return null;
  }

  const frontUrl = insuranceData?.insurance_by_pk?.front?.url;
  const backUrl = insuranceData?.insurance_by_pk?.back?.url;

  if (display === 'front') {
    return (
      <GreenTextLink
        href={frontUrl || undefined}
        target="_blank"
        rel="noopener noreferrer"
      >
        View
      </GreenTextLink>
    );
  }

  if (display === 'back') {
    return (
      <GreenTextLink
        href={backUrl || undefined}
        target="_blank"
        rel="noopener noreferrer"
      >
        View
      </GreenTextLink>
    );
  }

  return null;
};
