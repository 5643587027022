/** @jsx jsx */
import { useLoadStaffAndServicesQuery } from '@bc/codegen/manager';
import {
  ChatMessageFileFragment,
  ChatMessageFragment,
  StaffFragment,
  useGetAccountAvatarQuery,
  useGetPatientAvatarQuery,
  useLoadChatFileQuery,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { FONT_PRIMARY } from '../../styles';

export const ChatMessage: React.FC<{
  message: ChatMessageFragment & {
    staff?: StaffFragment | null | undefined;
    chat_messages_files?: ChatMessageFileFragment[];
  };
  showAvatar?: boolean;
  isSender?: boolean;
  color?: string;
  style?: React.CSSProperties;
  onLoad?: () => void;
  onFileClick?: (fileUrl?: string) => void;
}> = ({ message, showAvatar, isSender, color, onLoad, onFileClick, style }) => {
  return (
    <Container isSender={isSender} style={style}>
      <ChatWrap isSender={isSender}>
        {!isSender &&
          (showAvatar ? (
            <AccountAvatar accountId={message.accountId!} />
          ) : (
            <AvatarSpacer />
          ))}
        {message.type === 'FILE' ? (
          message?.chat_messages_files?.map((file) => (
            <ChatFile
              key={file.id}
              isSender={isSender}
              file={file}
              onLoad={onLoad}
              onClick={onFileClick}
            />
          ))
        ) : (
          <ChatBubble isSender={isSender}>{message.message}</ChatBubble>
        )}
        {isSender &&
          (showAvatar ? (
            <Avatar
              src={
                message.staff?.avatarUrl ||
                'https://bravecare.imgix.net/staff/empty.png'
              }
            />
          ) : (
            <AvatarSpacer />
          ))}
      </ChatWrap>
      <Timestamp isSender={isSender}>
        {DateTime.fromISO(message.createdAt).toFormat('h:mm a')}
      </Timestamp>
    </Container>
  );
};

const Container = styled.div<{ isSender?: boolean }>(({ isSender }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 32,
  marginLeft: isSender ? 'auto' : 0,
  marginRight: isSender ? 0 : 'auto',
  maxWidth: '67%',
}));

export const Avatar = styled.img({
  borderRadius: 20,
  height: 40,
  width: 40,
});

export const AccountAvatar: React.FC<{
  accountId: string;
  size?: number;
  style?: React.CSSProperties;
}> = ({ accountId, size = 40, style }) => {
  const { data } = useGetAccountAvatarQuery({
    variables: { accountId },
    skip: !accountId,
  });

  return (
    <Avatar
      src={
        data?.accounts_by_pk?.avatar?.url ||
        'https://bravecare.imgix.net/staff/empty.png'
      }
      style={{
        borderRadius: size / 2,
        height: size,
        width: size,
        ...style,
      }}
    />
  );
};

export const PatientAvatar: React.FC<{
  patientId: string;
  size?: number;
  style?: React.CSSProperties;
}> = ({ patientId, size = 40, style }) => {
  const { data } = useGetPatientAvatarQuery({
    variables: { patientId },
    skip: !patientId,
  });

  return (
    <Avatar
      src={
        data?.patients_by_pk?.avatar?.url ||
        'https://bravecare.imgix.net/staff/empty.png'
      }
      style={{
        borderRadius: size / 2,
        height: size,
        width: size,
        ...style,
      }}
    />
  );
};

export const StaffAvatar: React.FC<{ staffId: any }> = ({ staffId }) => {
  const { data } = useLoadStaffAndServicesQuery({
    variables: {
      where: {
        id: { _eq: staffId },
      },
    },
    skip: !staffId,
  });

  return (
    <Avatar
      src={
        data?.staff?.[0]?.avatarUrl ||
        'https://bravecare.imgix.net/staff/empty.png'
      }
    />
  );
};

const AvatarSpacer = styled.div({
  height: 40,
  width: 40,
});

const ChatBubble = styled.div<{ isSender?: boolean }>`
  ${FONT_PRIMARY};
  border-radius: 32px;
  display: flex;
  flex: 1;
  font-size: 16px;
  line-height: 22px;
  padding: 16px 32px;
  ${({ isSender }) =>
    isSender
      ? `
        background-color: ${Colors.grayLightest};
        border-radius: 32px 32px 4px 32px;
        color: ${Colors.darkHarbor};
        margin-right: 8px;
      `
      : `
        background-color: #0D957B;
        border-radius: 32px 32px 32px 4px;
        color: white;
        margin-left: 8px;
  `};
 }
`;

const ChatImage = styled.img<{ isSender?: boolean }>(({ isSender }) => ({
  borderRadius: 13,
  cursor: 'pointer',
  display: 'flex',
  flex: 1,
  backgroundColor: isSender ? Colors.grayLighter : Colors.butterscotchLightest,
  marginLeft: isSender ? '0px' : '8px',
  marginRight: isSender ? '8px' : '0px',
  minHeight: 170,
  width: 'calc(100% - 40px)',
}));

const ChatVideo = styled(ChatImage)({}).withComponent('video');

const ChatFile: React.FC<{
  isSender?: boolean;
  file: ChatMessageFileFragment;
  onLoad?: () => void;
  onClick?: (fileUrl?: string) => void;
}> = ({ isSender, file, onLoad, onClick }) => {
  // const [contentType, setContentType] = useState('image/jpeg');
  const [isVideo, setIsVideo] = useState(false);

  const { data } = useLoadChatFileQuery({
    variables: { id: file.fileId },
    skip: !file.fileId,
  });

  // useEffect(() => {
  //   if (data?.file?.url) {
  //     fetch(data?.file?.url, {
  //       method: 'HEAD',
  //     }).then((resp) => {
  //       const fetchedContentType = resp.headers.get('Content-Type');
  //       if (fetchedContentType) {
  //         setContentType(fetchedContentType);
  //       }
  //     });
  //   }
  // }, [data?.file?.url]);

  if (!data?.GetFile?.url) return null;

  // const isVideo = contentType.startsWith('video');

  if (isVideo) {
    return (
      <ChatVideo isSender={isSender} controls onLoad={onLoad}>
        <source src={data.GetFile.url} />
      </ChatVideo>
    );
  } else {
    return (
      <ChatImage
        isSender={isSender}
        src={data.GetFile.url}
        onClick={() => onClick?.(data?.GetFile?.url ?? undefined)}
        onError={() => setIsVideo(true)}
        onLoad={onLoad}
      />
    );
  }
};

const ChatWrap = styled.div<{ isSender?: boolean }>(({ isSender }) => ({
  alignItems: 'flex-end',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
}));

const Timestamp = styled.p<{ isSender?: boolean }>`
  ${FONT_PRIMARY};
  align-self: ${({ isSender }) => (isSender ? 'flex-start' : 'flex-end')};
  color: ${Colors.gray};
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
`;
