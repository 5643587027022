import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React, { useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import calendarIcon from '../assets/calendar.svg';
import CaretDown from '../assets/caret-down.svg';
import { FONT_PRIMARY, IF_MOBILE } from '../styles';
import { CalendarSelector } from './CalendarSelector';
import { Caret } from './form';

const InputWrap = styled.div<{
  error?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  position: relative;
  ${FONT_PRIMARY};
  color: ${Colors.darkHarbor};
  padding: 13px 16px 14px;
  align-items: center;
  appearance: none;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.2px;
  text-align: left;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: ${({ error }) => (error ? '#FF0000' : Colors.grayLight)};
  background-color: ${({ disabled }) => (disabled ? '#E3E3E3' : '#FFFFFF')};
  cursor: pointer;
  ::placeholder {
    color: #adb8b7;
  }
  :focus {
    ::placeholder {
      color: transparent;
    }
  }
  min-width: 200px;
  ${IF_MOBILE} {
    font-size: 18px;
    font-weight: 400;
  }
  @media (min-width: 420px) {
    min-width: 160px;
  }
`;

const CalendarWrap = styled.div`
  position: absolute;
  max-width: 328px;
  z-index: 10;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grayLighter};
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.18);
  top: 51px;
  right: 0;

  ${IF_MOBILE} {
    left: -1px;
    right: initial;
  }
`;

const CalendarSelect: React.FC<{
  date: string;
  onChange: (date: Date) => void;
  children: React.ReactNode;
}> = ({ children, date, onChange }) => {
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setTimeout(() => {
      setCalendarOpen(false);
    }, 100);
  });

  return (
    <InputWrap
      onClick={() => {
        if (ref && ref.current) return;
        setCalendarOpen(true);
      }}
    >
      <img
        src={calendarIcon}
        style={{
          cursor: 'pointer',
          marginRight: '16px',
        }}
        alt="Calendar Button"
      />
      {children}
      <Caret src={CaretDown} />
      {calendarOpen && (
        <CalendarWrap ref={ref}>
          <CalendarSelector
            value={DateTime.fromFormat(date, 'yyyy-MM-dd').toJSDate()}
            onChange={(value) => {
              if (Array.isArray(value)) return;
              onChange(value);
              setCalendarOpen(false);
            }}
            style={{ padding: '8px' }}
          />
        </CalendarWrap>
      )}
    </InputWrap>
  );
};

export default CalendarSelect;
