import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { useSelect } from 'downshift';
import React from 'react';
import { FONT_PRIMARY } from '../../styles';
import { PrimaryText } from '../text';
import caret from './caret.svg';

export interface Option {
  value: string;
  title: string;
}

interface Props {
  options: Option[];
  icon?: string;
  value?: string | null | undefined;
  onChange: (item: Option | null | undefined) => void;
  placeHolder?: string;
}

const Wrap = styled.div({
  width: '100%',
  position: 'relative',
});

const Button = styled.button({
  border: '1px solid #D6DBDB',
  boxSizing: 'border-box',
  borderRadius: '4px',
  padding: '16px',
  backgroundColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  outlineColor: Colors.grayLightest,
});

export const ResultDropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 6;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
  overflow: hidden;
  outline-color: ${Colors.grayLightest};
`;

export const Result = styled.a<{ highlighted: boolean }>`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eee;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 10px 20px;
  text-decoration: none;
  color: #314d4a;
  font-size: 18px;
  line-height: 28px;
  ${FONT_PRIMARY}
  color: ${Colors.darkHarbor};
  outline-color: ${Colors.grayLightest};

  background-color: ${({ highlighted }) =>
    highlighted ? '#eee' : 'transparent'};

  :hover {
    background-color: #eee;
    color: ${Colors.darkHarbor};
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

const Dropdown = ({ icon, options, value, onChange, placeHolder }: Props) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    itemToString: (option) => option?.title || '',
    items: options,
    selectedItem: options.find((option) => option.value === value) || null,
    onSelectedItemChange: (changes) => {
      onChange(changes.selectedItem);
    },
  });

  return (
    <Wrap>
      <Button {...getToggleButtonProps()}>
        <img src={icon} />
        <PrimaryText
          style={{
            fontSize: '18px',
            lineHeight: '24px',
            marginLeft: '16px',
            flex: '1 1 auto',
            textAlign: 'left',
          }}
        >
          {selectedItem?.title || placeHolder || ''}
        </PrimaryText>
        <img src={caret} />
      </Button>
      <ResultDropdownContainer {...getMenuProps()} style={{}}>
        {isOpen &&
          options.map((item, index) => (
            <Result
              style={
                highlightedIndex === index ? { backgroundColor: '#CCC' } : {}
              }
              highlighted={selectedItem?.value === item?.value}
              key={`${item?.value}${index}`}
              {...getItemProps({ item, index })}
            >
              {item?.title}
            </Result>
          ))}
      </ResultDropdownContainer>
    </Wrap>
  );
};

export default Dropdown;
