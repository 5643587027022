import { DateTime, Interval } from 'luxon';
import { useEffect, useState } from 'react';
import { ScheduleDisplayItem } from '../../pages/Dashboard.helpers';

export const getCurrentTimePosition = ({
  millPerPix,
  startTime,
}: {
  millPerPix: number;
  startTime: DateTime;
}): React.CSSProperties => {
  const { milliseconds } = DateTime.local().diff(startTime).toObject();

  const top = (milliseconds || 0) / millPerPix;

  return {
    position: 'absolute',
    left: 0,
    top: `${top < 0 ? 0 : top}px`,
    right: 0,
    zIndex: 15,
  };
};

export const useClock = () => {
  const [, setCurrentTime] = useState(DateTime.local());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(DateTime.local());
    }, 10000);
    return () => clearInterval(interval);
  }, []);
};

export const useClinicTime = (date: DateTime, open: string, close: string) => {
  const openTime = DateTime.fromFormat(open, 'hh:mm:ss');
  const closeTime = DateTime.fromFormat(close, 'hh:mm:ss');

  const clinicOpen = date
    .set({
      hour: openTime.hour,
      minute: openTime.minute,
    })
    .startOf('minute');

  const clinicClose = date
    .set({
      hour: closeTime.hour,
      minute: closeTime.minute,
    })
    .startOf('minute')
    .plus({ hour: 1 });

  return { clinicOpen, clinicClose };
};

export const getDisplayOffset = ({
  dayStart,
  millPerPix,
  display,
}: {
  dayStart: DateTime;
  millPerPix: number;
  display: ScheduleDisplayItem;
}) => {
  const displayDiff = display.endTime.diff(display.startTime).toObject();
  const startDiff = display.endTime.diff(dayStart).toObject();

  return {
    height: (displayDiff.milliseconds || 0) / millPerPix,
    offset: (startDiff.milliseconds || 0) / millPerPix,
  };
};

export const getDisplayOverlaps = (displayItems: ScheduleDisplayItem[]) => {
  return displayItems.map((display, displayIndex) => {
    const interval = Interval.fromDateTimes(display.startTime, display.endTime);

    let overlaps: ScheduleDisplayItem[] = [];

    displayItems.forEach((display, index) => {
      if (index === displayIndex) return;

      const isOverlapping = Interval.fromDateTimes(
        display.startTime,
        display.endTime
      ).overlaps(interval);

      if (isOverlapping) {
        overlaps[index] = display;
      }
    });

    if (overlaps.length !== 0) {
      overlaps[displayIndex] = display;
    }

    return {
      ...display,
      overlaps: overlaps,
    };
  });
};
