import { ProductFragment } from '@bc/codegen/medical';
import styled from '@emotion/styled';
import map from 'lodash/map';
import React, { Fragment } from 'react';
import { FONT_PRIMARY, GREY } from '../../styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 16px;
`;

export const ResultsContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const InputContainer = styled.form`
  position: relative;
`;

export const SearchIcon = styled.img`
  filter: brightness(0) saturate(100%) invert(48%) sepia(9%) saturate(556%)
    hue-rotate(125deg) brightness(92%) contrast(91%);
  height: 24px;
  left: 16px;
  position: absolute;
  top: 16px;
  width: 24px;
`;

export const ResultDropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 6; //TODO: see if can drop back down to 5 after cleanup
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
  overflow: hidden;
`;

export const Result = styled.a<{ highlighted: boolean }>`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eee;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 16px;
  text-decoration: none;
  color: #314d4a;
  font-size: 18px;
  line-height: 28px;
  ${FONT_PRIMARY}
  color: ${GREY};

  background-color: ${({ highlighted }) =>
    highlighted ? '#eee' : 'transparent'};

  :hover {
    background-color: #eee;
    color: ${GREY};
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

interface Props {
  highlightedIndex: number | null;
  getItemProps: any;
  products: ProductFragment[];
  inputValue?: string;
  selectedItem?: ProductFragment | null;
}

const ProductResults: React.FC<Props> = ({
  highlightedIndex,
  getItemProps,
  products,
}) => {
  if (products.length === 0) return null;
  return (
    <ResultDropdownContainer>
      <div role="menu">
        <div>
          {map(products, (product, index: number) => (
            <Result
              href="#"
              highlighted={highlightedIndex === index}
              {...getItemProps({
                item: product,
                index,
                key: product.id,
                onClick: (e: any) => e.preventDefault(),
              })}
            >
              <Fragment>
                <span>{product.name}</span>
                <span>
                  {product.price
                    ? `$${product.price.toFixed(2).replace('.00', '')}`
                    : ''}
                </span>
              </Fragment>
            </Result>
          ))}
        </div>
      </div>
    </ResultDropdownContainer>
  );
};

export default ProductResults;
