import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import CaretIcon from '../../assets/caret.svg';
import { LargeTitle, PrimaryText } from '../../components/text';

export const Title = styled(LargeTitle)({
  marginBottom: 32,
}).withComponent('h2');

const SectionTitle = styled(PrimaryText)({
  fontSize: 24,
  fontWeight: 'bold',
  lineHeight: '32px',
});

const Caret = styled.img<{ isExpanded: boolean }>`
  cursor: pointer;
  height: 24px;
  width: 24px;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
  filter: invert(60%) sepia(17%) saturate(140%) hue-rotate(201deg)
    brightness(93%) contrast(91%);
  ${({ isExpanded }) => isExpanded && `transform: rotate(180deg);`}
`;

const Heading = styled.div({
  alignItems: 'center',
  borderBottom: `1px solid ${Colors.grayLighter}`,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '16px 0',
});

const Section = styled.section({
  overflow: 'hidden',
});

export const CollapsibleSection = ({
  children,
  style,
  title,
}: {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  title: string;
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <section style={style}>
      <Heading onClick={() => setIsExpanded(!isExpanded)}>
        <SectionTitle>{title}</SectionTitle>
        <Caret src={CaretIcon} isExpanded={isExpanded} />
      </Heading>
      <Section style={{ height: isExpanded ? 'auto' : 0 }}>{children}</Section>
    </section>
  );
};
