import { ClinicFragment, useLoadAllClinicsQuery } from '@bc/codegen/medical';
import React from 'react';
import { Select, SelectWithCaret } from '../form';

interface Props {
  value?: string | undefined | null;
  onChange: (value: ClinicFragment | undefined) => void;
}

const ClinicSimpleSelect: React.FC<Props> = ({ onChange, value }) => {
  const { data } = useLoadAllClinicsQuery({
    variables: {
      where: {
        status: {
          _nin: ['HIDDEN', 'PERMANENTLY_CLOSED'],
        },
      },
    },
  });

  const clinics = data?.clinics ?? [];

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = clinics.find(({ id, clinicCode }) => {
      return id === e.target.value || clinicCode === e.target.value;
    });

    onChange(selected);
  };

  const selectedValue = clinics.find(({ id, clinicCode }) => {
    return id === value || clinicCode === value;
  });

  if (!clinics.length) {
    return null;
  }

  return (
    <SelectWithCaret>
      <Select value={selectedValue?.id || ''} onChange={handleChange}>
        <option value="">Select Clinic</option>
        {clinics.map((clinic) => {
          return (
            <option key={clinic.id} value={clinic.id}>
              {clinic.name}
            </option>
          );
        })}
      </Select>
    </SelectWithCaret>
  );
};

export default ClinicSimpleSelect;
