/** @jsx jsx */
import {
  AccountFragment,
  AppointmentFragment,
  PatientFragment,
  useSearchAccountsAndPatientsQuery,
} from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import { DateTime } from 'luxon';
import React from 'react';
import { useDebounce } from 'use-debounce';
import { TextButton } from '../button';
import { PatientResult } from './PatientResult';
import {
  AddButton,
  AddIcon,
  CenteredText,
  Wrapper,
} from './SearchForAccountPatient.styles';

interface Props {
  query: string;
  selectedAccountId?: string;
  selectedPatientId?: string;
  onLinkPatient: ({
    appointment,
    patient,
    relationship,
  }: {
    appointment?: AppointmentFragment;
    patient?: PatientFragment;
    relationship?: string;
  }) => void;
  onLinkAccount: ({
    account,
    relationship,
    isMissingName,
  }: {
    account?: AccountFragment;
    relationship?: string;
    isMissingName?: boolean;
  }) => void;
  onCreateNewPatient?: () => void;
  displayAppointmentToLink?: boolean;
  onCreateNewAccount?: () => void;
  displayAddNewAccount?: boolean;
}

export const SearchForPatient: React.FC<Props> = ({
  onLinkAccount,
  onLinkPatient,
  query,
  selectedAccountId,
  selectedPatientId,
  onCreateNewAccount,
  onCreateNewPatient,
  displayAppointmentToLink = false,
  displayAddNewAccount = false,
}) => {
  const [search] = useDebounce(query, 300);
  const shouldSkipSearch = !search || search.length < 3;

  const { data } = useSearchAccountsAndPatientsQuery({
    fetchPolicy: 'no-cache',
    skip: shouldSkipSearch,
    variables: {
      limit: 5,
      search,
    },
  });

  const today = DateTime.local();

  return (
    <Wrapper>
      {data && !data?.search_patients.length && (
        <CenteredText>
          Patient doesn’t exist.{' '}
          {onCreateNewPatient && (
            <TextButton
              type="button"
              onClick={onCreateNewPatient}
              css={{ display: 'inline-flex', padding: 0 }}
            >
              Create new patient.
            </TextButton>
          )}
        </CenteredText>
      )}
      {data?.search_patients
        .filter((patient) =>
          selectedPatientId ? patient.id === selectedPatientId : true,
        )
        .map((patient, index) => {
          if (!patient) return null;

          const todaysAppointment = patient?.appointments?.filter((appt) => {
            return today.hasSame(DateTime.fromISO(appt?.startTime), 'day');
          })[0];
          const appointment = displayAppointmentToLink
            ? todaysAppointment
            : undefined;

          return (
            <PatientResult
              appointment={appointment}
              displayAppointmentToLink
              displayAddNewAccount={displayAddNewAccount}
              isFirst={index === 0}
              selectedPatientId={selectedPatientId}
              selectedAccountId={selectedAccountId}
              key={patient.id}
              onCreateNewAccount={onCreateNewAccount}
              patient={patient}
              onLinkAccount={onLinkAccount}
              onLinkPatient={onLinkPatient}
            />
          );
        })}
      {!selectedPatientId &&
        onCreateNewPatient &&
        !!data?.search_patients?.length && (
          <AddButton onClick={onCreateNewPatient} type="button">
            <AddIcon />
            Create new patient
          </AddButton>
        )}
    </Wrapper>
  );
};
