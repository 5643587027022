/** @jsx jsx */
import {
  AccountFragment,
  useCreateAccountPatientMutation,
  useGetAccountByPkQuery,
  useUpdateCommunicationHistoryMutation,
} from '@bc/codegen/medical';
import { dateOfBirthYupSchema, RELATIONSHIPS } from '@bc/shared';
import { Spacing } from '@bc/theme';
import { jsx } from '@emotion/core';
import * as Sentry from '@sentry/react';
import { Form, Formik } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js/min';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { object, string } from 'yup';
import { Button, CloseButton } from '../components/button';
import { CaregiverInfoSection } from '../components/Caregiver';
import {
  ErrorField,
  ErrorText,
  SearchInput,
  SelectSimpleField,
} from '../components/form';
import {
  ContentRow,
  HeaderEdit,
  InfoSection,
  MainModalContent,
} from '../components/layout';
import { PatientInfoSection } from '../components/Patient';
import { SearchForAccount } from '../components/SearchForAccountPatient';
import { LargeTitle } from '../components/text';
import { formatPhoneNumber } from '../helpers';
import {
  ClosePosition,
  LeftSideContainer,
  ModalWrapper,
  RightSideContainer,
  SideBySide,
  StaticHeader,
} from './ViewAppointment.styles';

const ARIA_LABEL = 'New Patient & Caregiver';

interface AccountPatient {
  accountSearch: string;
  accountId?: string;
  patientId?: string;
  accountFirstName?: string;
  accountLastName?: string;
  phoneNumber: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  relationship?: string;
}

const Validation = object().shape({
  accountFirstName: string().required('Required'),
  accountLastName: string().required('Required'),
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  relationship: string().required('Required'),
  phoneNumber: string()
    .required('Required')
    .test('is-phone', 'Phone number not valid', (value = '') => {
      const phoneNumber = parsePhoneNumberFromString(value!, 'US');
      if (!phoneNumber) return false;

      return phoneNumber.isValid();
    }),
  dateOfBirth: dateOfBirthYupSchema(),
});

const NewAccountPatient: React.FC<
  { returnPath?: string } & RouteComponentProps
> = ({ returnPath, history }) => {
  const locationState = history.location.state ?? ({} as any);
  const accountId = locationState.accountId;
  const communicationHistoryId = locationState.communicationHistoryId;
  const phoneNumber = locationState.phoneNumber?.replace('+1', '') ?? '';

  const [creationError, setError] = useState('');

  const { data: accountData } = useGetAccountByPkQuery({
    variables: {
      accountId,
    },
    skip: !accountId,
  });
  const account = accountData?.accounts_by_pk;

  const [createAccountPatient, { error }] = useCreateAccountPatientMutation({
    fetchPolicy: 'no-cache',
  });
  const [updateCommunicationHistory] = useUpdateCommunicationHistoryMutation({
    fetchPolicy: 'no-cache',
  });

  const handleClose = () =>
    returnPath ? history.push(returnPath) : history.goBack();

  const handleSubmit = async (values: AccountPatient) => {
    try {
      const parsedNumber = parsePhoneNumberFromString(
        values?.phoneNumber,
        'US',
      )?.number.toString();

      const { data: savedData, errors } = await createAccountPatient({
        variables: {
          accountFirstName: values.accountFirstName!,
          accountId: values.accountId,
          accountLastName: values.accountLastName!,
          phoneNumber: parsedNumber!,
          relationship: values.relationship!,
          dateOfBirth: DateTime.fromFormat(
            values.dateOfBirth!,
            'MM/dd/yy',
          ).toFormat('yyyy-MM-dd'),
          patientFirstName: values.firstName!,
          patientId: values.patientId,
          patientLastName: values.lastName!,
        },
      });

      if (errors) {
        Sentry.captureMessage(errors?.[0]?.message, (scope) => {
          scope.setExtras(values as any);
          if (savedData?.CreateAccountPatient) {
            scope.setExtras(savedData?.CreateAccountPatient);
          }
          return scope;
        });
      }

      const accountId = savedData?.CreateAccountPatient?.accountId!;
      const patientId = savedData?.CreateAccountPatient?.patientId!;

      if (communicationHistoryId) {
        await updateCommunicationHistory({
          variables: {
            id: communicationHistoryId,
            set: {
              accountId,
              patientId,
            },
          },
        });
      }

      handleClose();
    } catch (error: any) {
      setError(error.message);
      Sentry.captureMessage(error, (scope) => {
        scope.setExtras(values as any);
        return scope;
      });
    }
  };

  return (
    <ModalWrapper aria-label={ARIA_LABEL}>
      <CloseButton css={ClosePosition} onClick={handleClose} />
      <MainModalContent>
        <LargeTitle css={{ marginBottom: Spacing.m }}>
          New Patient & Caregiver
        </LargeTitle>
        <Formik
          initialValues={{
            accountFirstName: account?.firstName ?? '',
            accountId,
            accountLastName: account?.lastName ?? '',
            accountSearch: phoneNumber,
            dateOfBirth: '',
            firstName: '',
            lastName: '',
            phoneNumber,
            relationship: '',
          }}
          enableReinitialize
          initialStatus={{
            editing: true,
          }}
          isInitialValid={false}
          validationSchema={Validation}
          validateOnChange
          onSubmit={handleSubmit}
        >
          {({ isValid, isSubmitting, setFieldValue, setValues, values }) => {
            const setAccountValues = (account: AccountFragment) => {
              setValues({
                ...values,
                accountFirstName: account?.firstName ?? '',
                accountId: account.id,
                accountLastName: account?.lastName ?? '',
                phoneNumber: formatPhoneNumber(account.phoneNumber),
              });
            };

            return (
              <Form css={SideBySide}>
                <LeftSideContainer>
                  <PatientInfoSection
                    onExistingSelect={(patient) => {
                      setFieldValue('patientId', patient.id);
                    }}
                  />
                  <HeaderEdit
                    title="Caregiver information"
                    editing
                    onEdit={() => {}}
                    editable={false}
                    css={StaticHeader}
                  />
                  <ContentRow css={{ marginBottom: 0 }}>
                    <SearchInput
                      fullWidth
                      name="accountSearch"
                      title="Search for caregiver"
                      disabled={!!values.accountId}
                      placeholder="Enter name or phone number"
                    />
                    <SearchForAccount
                      query={values.accountSearch}
                      selectedAccountId={values.accountId}
                      selectedPatientId={values.patientId}
                      onLinkAccount={({ account, relationship }) => {
                        if (account) {
                          setAccountValues(account);
                          if (!!relationship) {
                            setFieldValue('relationship', relationship);
                          }
                        } else {
                          setValues({
                            ...values,
                            relationship: '',
                            accountSearch: '',
                          });
                        }
                      }}
                    />
                  </ContentRow>
                  <CaregiverInfoSection
                    onExistingSelect={({ account, relationship }) => {
                      setFieldValue('accountId', account.id);
                      setFieldValue('relationship', relationship);
                    }}
                  />
                  <ContentRow>
                    <InfoSection title="Relationship to patient" fullWidth>
                      <SelectSimpleField name="relationship">
                        <option value="">Select</option>
                        {RELATIONSHIPS.map(({ label, value }) => (
                          <option value={value} key={value}>
                            {label}
                          </option>
                        ))}
                      </SelectSimpleField>
                      <ErrorField name="relationship" />
                    </InfoSection>
                  </ContentRow>
                  {(error || creationError) && (
                    <ContentRow>
                      <ErrorText>{error?.message || creationError}</ErrorText>
                    </ContentRow>
                  )}
                </LeftSideContainer>
                <RightSideContainer>
                  <Button
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    css={{
                      boxShadow:
                        '0px 10px 20px rgba(0, 0, 0, 0.12), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);',
                      padding: `${Spacing.s}px ${Spacing.xl}px`,
                      width: '100%',
                    }}
                  >
                    Save
                  </Button>
                </RightSideContainer>
              </Form>
            );
          }}
        </Formik>
      </MainModalContent>
    </ModalWrapper>
  );
};

export default NewAccountPatient;
