import { useGetFullPlaceLazyQuery } from '@bc/codegen/medical';
import { useEffect, useRef, useState } from 'react';
import { Location } from '../../types';

export function usePlaceSuggestion(value: string | undefined) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const [predictions, setPredictions] = useState<Location[]>([]);
  const autocompleteService = useRef<
    google.maps.places.AutocompleteService | undefined
  >(undefined);

  const [getPlace, { data: place }] = useGetFullPlaceLazyQuery();

  const handleGoogleResponse = (
    results: google.maps.places.QueryAutocompletePrediction[] | null,
    status: google.maps.places.PlacesServiceStatus,
  ) => {
    setIsLoading(false);

    if (status !== google.maps.places.PlacesServiceStatus.OK) {
      setError('Error with Google Maps API');
      return;
    }

    const predictions: Location[] =
      results?.map((p) => {
        return {
          place_id: p.place_id,
          formatted_address: p.description,
        };
      }) ?? [];
    setPredictions(predictions);
  };

  // init the lib and store it on the ref
  useEffect(() => {
    if (!google) {
      throw new Error('Google Maps JavaScript API library must be loaded.');
    }

    if (!google.maps.places) {
      throw new Error(
        'Google Maps Places library must be loaded. Please add `libraries=places` to the src URL.',
      );
    }

    autocompleteService.current = new google.maps.places.AutocompleteService();
  }, []);

  // search when value changes
  useEffect(() => {
    if (!autocompleteService.current) {
      throw new Error("Google Maps JavaScript API hasn't been initialized.");
    }

    // don't do a search on empty strings
    if (!value) {
      return;
    }

    setIsLoading(true);
    autocompleteService.current.getPlacePredictions(
      { input: value },
      handleGoogleResponse,
    );
  }, [value]);

  return { predictions, error, isLoading, getPlace, place };
}
