/** @jsx jsx */
import {
  useLoadStaffAndServicesQuery,
  useSearchStaffQuery,
} from '@bc/codegen/manager';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import some from 'lodash/some';
import startCase from 'lodash/startCase';
import xor from 'lodash/xor';
import { transparentize } from 'polished';
import React, { Fragment, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ICONS } from '../../assets';
import Add from '../../assets/add.svg';
import { hasRole } from '../../auth';
import {
  GreenOutlineButton as OutlineButton,
  TextButton,
} from '../../components/button';
import { CheckboxFilter } from '../../components/CheckboxFilter';
import { Header } from '../../components/Header';
import {
  Content,
  HeaderContainer,
  InnerWrap,
  LeftAndRightWrapper,
  LeftWrapper,
  MainContent,
  TitleRow,
} from '../../components/layout';
import { Sidebar } from '../../components/Nav/Sidebar';
import { SearchBar } from '../../components/SearchBar';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '../../components/Table';
import { LargeTitle, PrimaryText } from '../../components/text';
import { useWindowSize } from '../../hooks';
import { useAuth0 } from '../../react-auth0';
import {
  DropdownButtonContainer,
  Filterable,
  FilterableText,
  FilterContainer,
} from '../Dashboard.styles';
import { Icon, StaffAvatar } from './styles';

const StaffManagement: React.FC<RouteComponentProps> = ({ history }) => {
  const [searchText, setSearchText] = useState<string>();
  const { accessToken } = useAuth0();
  const isManager = hasRole('manager', accessToken);
  const context = { role: isManager ? 'manager' : 'assistant' };
  const { innerWidth } = useWindowSize();
  const [showFilters, setShowFilters] = useState(false);

  const { data, refetch } = useLoadStaffAndServicesQuery({
    context,
    variables: {
      order_by: [{ firstName: 'asc' }, { lastName: 'asc' }],
      where: {
        active: {
          _eq: true,
        },
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [history.location, refetch]);

  const { data: searchData } = useSearchStaffQuery({
    context,
    skip: !searchText,
    variables: { search: searchText },
  });
  const services = data?.services ?? [];
  const staffTypes = data?.staff_type ?? [];
  const staffMembers =
    (searchText ? searchData?.search_staff : data?.staff) ?? [];

  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [selectedStaffTypes, setSelectedStaffTypes] = useState<string[]>([]);

  useEffect(() => {
    setSelectedServices(services.map((s) => s.value));
  }, [services]);

  useEffect(() => {
    setSelectedStaffTypes(staffTypes.map((s) => s.value));
  }, [staffTypes]);

  const filteredStaffMembers = staffMembers.filter((s) => {
    return (
      (selectedStaffTypes.length
        ? selectedStaffTypes.includes(s.type)
        : true) &&
      (selectedServices.length === services.length
        ? true
        : selectedServices.length
        ? some(s.staff_services, (s) => selectedServices.includes(s.service))
        : true)
    );
  });

  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap>
          <Sidebar />
          <MainContent>
            <HeaderContainer>
              <TitleRow>
                <LargeTitle>
                  {isManager ? 'Staff Management' : 'Staff Directory'}
                </LargeTitle>
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                  }}
                >
                  <SearchBar
                    placeholder="Search for staff member..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onClear={() => setSearchText('')}
                  />
                  <DropdownButtonContainer css={{ margin: 0 }}>
                    <Filterable
                      onMouseEnter={() => setShowFilters(true)}
                      onMouseLeave={() => setShowFilters(false)}
                      onClick={() => setShowFilters(true)}
                      tabIndex={0}
                      type="button"
                      highlight={!!showFilters}
                    >
                      <Icon src={ICONS.filter} />
                      <FilterableText>Filters</FilterableText>

                      {showFilters && (
                        <div
                          css={[
                            FilterContainer,
                            {
                              display: 'flex',
                              flexWrap: 'wrap',
                              width: '300px',
                              left: 0,
                              right: 'unset',
                            },
                          ]}
                        >
                          <CheckboxFilter
                            title="Staff Type"
                            checkboxOptions={staffTypes.map((s) => ({
                              color: Colors.teal,
                              groupName: 'staffTypes',
                              label: s.description,
                              value: s.value,
                            }))}
                            onChange={(staffType) => {
                              setSelectedStaffTypes(
                                xor(selectedStaffTypes, [staffType]),
                              );
                            }}
                            selectedOptions={selectedStaffTypes}
                            onToggleAll={() =>
                              selectedStaffTypes.length === 0
                                ? setSelectedStaffTypes(
                                    staffTypes.map((s) => s.value),
                                  )
                                : setSelectedStaffTypes([])
                            }
                            style={{ padding: 24 }}
                          />
                          <CheckboxFilter
                            title="Services Provided"
                            checkboxOptions={services.map((s) => ({
                              color: Colors.teal,
                              groupName: 'services',
                              label: s.description,
                              value: s.value,
                            }))}
                            onChange={(service) => {
                              setSelectedServices(
                                xor(selectedServices, [service]),
                              );
                            }}
                            selectedOptions={selectedServices}
                            onToggleAll={() =>
                              selectedServices.length === 0
                                ? setSelectedServices(
                                    services.map((s) => s.value),
                                  )
                                : setSelectedServices([])
                            }
                            style={{ padding: 24 }}
                          />
                        </div>
                      )}
                    </Filterable>
                  </DropdownButtonContainer>
                  {isManager && (
                    <OutlineButton
                      style={{
                        borderRadius: '100px',
                        alignSelf: 'flex-start',
                      }}
                      onClick={() => history.push('/staff/add')}
                    >
                      <Icon src={Add} style={{ marginRight: 3 }} /> Add Staff
                    </OutlineButton>
                  )}
                </div>
              </TitleRow>
            </HeaderContainer>
            <LeftAndRightWrapper>
              <LeftWrapper>
                <Table condense={!!innerWidth && innerWidth < 1200}>
                  <TableHead>
                    <TableRow
                      css={{
                        backgroundColor: transparentize(
                          0.5,
                          Colors.grayLightest,
                        ),
                        borderRadius: '8px 8px 0px 0px',
                      }}
                    >
                      <TableHeadCell>Staff Member</TableHeadCell>
                      <TableHeadCell>Services</TableHeadCell>
                      <TableHeadCell>Phone</TableHeadCell>
                      <TableHeadCell>Email</TableHeadCell>
                      {isManager && <TableHeadCell>Actions</TableHeadCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredStaffMembers.map((staff) => (
                      <TableRow
                        key={staff.id}
                        onClick={() => history.push(`/staff/view/${staff.id}`)}
                        style={{ cursor: 'pointer' }}
                      >
                        <TableCell data-label="Staff Member">
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: 14,
                              minWidth: 200,
                            }}
                          >
                            <StaffAvatar
                              src={
                                staff.avatarUrl ||
                                'https://bravecare.imgix.net/staff/empty.png'
                              }
                              alt={`${staff.firstName} ${staff.lastName}`}
                            />
                            <div>
                              <PrimaryText
                                style={{
                                  display: 'block',
                                  fontWeight: 'bold',
                                  marginBottom: 8,
                                }}
                              >
                                {staff.firstName} {staff.lastName}
                              </PrimaryText>
                              <PrimaryText style={{ display: 'block' }}>
                                {startCase(staff.type.toLowerCase())}
                              </PrimaryText>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell data-label="Services">
                          {staff.staff_services
                            .map((s) => startCase(s.service.toLowerCase()))
                            .join(', ')}
                        </TableCell>
                        <TableCell style={{ minWidth: 120 }} data-label="Phone">
                          {staff.phoneNumber}
                        </TableCell>
                        <TableCell data-label="Email">
                          <PrimaryText>{staff.email}</PrimaryText>
                        </TableCell>
                        {isManager && (
                          <TableCell>
                            <TextButton
                              onClick={(e) => {
                                e.stopPropagation();
                                history.push(`/staff/edit/${staff.id}`);
                              }}
                            >
                              <PrimaryText>Edit</PrimaryText>
                            </TextButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </LeftWrapper>
            </LeftAndRightWrapper>
          </MainContent>
        </InnerWrap>
      </Content>
    </Fragment>
  );
};

export default StaffManagement;
