/** @jsx jsx */
import {
  PatientFragment,
  useWatchTasksSubscription,
} from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { BasicTaskView } from '../../components/Tasks/BasicTaskView';
import { useCurrentStaff } from '../../lib/staff';
import { buildWhere } from '../../lib/task';

const PatientTask = ({
  patient,
  basePath,
}: {
  patient: PatientFragment;
  basePath: string;
}) => {
  const currentStaff = useCurrentStaff();
  const loadFrom = DateTime.local().minus({ month: 1 }).startOf('day');
  const loadTo = DateTime.local().plus({ year: 1 }).endOf('day');

  const where = useMemo(() => {
    return buildWhere({
      start: loadFrom,
      end: loadTo,
      patientIds: [patient.id],
    });
  }, []);

  const { data } = useWatchTasksSubscription({
    skip: !currentStaff?.id,
    variables: {
      where: where,
      order_by: {
        dueDateTime: 'asc',
      },
      staffId: currentStaff?.id!,
    },
  });

  return <BasicTaskView tasks={data?.tasks ?? []} basePath={basePath} />;
};

export default PatientTask;
