/** @jsx jsx */
import { AccountPartsFragment } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { ConsentDocumentLink } from '../../components/AppointmentDetails/ConsentDocumentLink';
import { CareGiverEdit } from '../../components/CareGiverEdit';
import { HeaderEdit, SectionContent } from '../../components/layout';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '../../components/Table';
import { prettifyTimestamp } from '../../helpers';
import { IF_TABLET } from '../../styles';

export const TCell = styled(TableCell)({
  border: 'none',
  color: Colors.darkHarbor,
  fontSize: 14,
  lineHeight: '16px',
  padding: '16px 8px',

  ':first-of-type': {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '18px',
    padding: '8px',
  },

  [IF_TABLET]: {
    fontSize: 16,
    lineHeight: '19px',
    ':first-of-type': {
      padding: '12px 24px',
    },
  },
});

const THeadCell = styled(TableHeadCell)({
  border: 'none',
});

const TRow = styled(TableRow)({
  ':nth-of-type(odd)': {
    background: 'rgba(239, 241, 241, 0.74)',
  },
});

const Profile: React.FC<{
  data: AccountPartsFragment;
}> = ({ data: account }) => {
  const { account_patients, id } = account;
  const consentedToTreatment = account_patients.filter((ap) => {
    return ap.patient.consentSignedByUser?.account?.id === id;
  });
  const consentedToTelemedicine = account_patients.filter((ap) => {
    return ap.patient.consentToTelemedicineTermsByAccount?.id === id;
  });

  return (
    <SectionContent>
      <CareGiverEdit account={account} />
      <HeaderEdit title="Consent Forms" editable={false} />
      <Table style={{ marginBottom: 16 }}>
        <TableHead>
          <TableRow>
            <THeadCell>&nbsp;</THeadCell>
            <THeadCell>Date Signed</THeadCell>
            <THeadCell>On Behalf Of</THeadCell>
            <THeadCell>&nbsp;</THeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TRow>
            <TCell>Terms of Use & Privacy Policy</TCell>
            <TCell>
              {account?.consentToTermsAndPrivacyTimestamp
                ? prettifyTimestamp(account?.consentToTermsAndPrivacyTimestamp)
                : 'Not yet agreed to'}
            </TCell>
            <TCell></TCell>
            <TCell></TCell>
          </TRow>
          <TRow>
            <TCell>Medical Terms</TCell>
            <TCell>
              {account?.consentToMedicalTermsTimestamp
                ? prettifyTimestamp(account?.consentToMedicalTermsTimestamp)
                : 'Not yet agreed to'}
            </TCell>
            <TCell></TCell>
            <TCell></TCell>
          </TRow>
          {consentedToTreatment.map(
            ({ patient: { id, consentSignedAt, firstName, goesByName } }) => (
              <TRow key={`treatment-${id}`}>
                <TCell>In Clinic</TCell>
                <TCell>{prettifyTimestamp(consentSignedAt!)}</TCell>
                <TCell>{goesByName || firstName}</TCell>
                <TCell>
                  {consentSignedAt && id && (
                    <ConsentDocumentLink patientId={id} />
                  )}
                </TCell>
              </TRow>
            ),
          )}
          {consentedToTelemedicine.map(
            ({
              patient: {
                id,
                consentToTelemedicineTermsTimestamp,
                firstName,
                goesByName,
              },
            }) => (
              <TRow key={`telemedicine-${id}`}>
                <TCell>Telemedicine</TCell>
                <TCell>
                  {prettifyTimestamp(consentToTelemedicineTermsTimestamp!)}
                </TCell>
                <TCell>{goesByName || firstName}</TCell>
                <TCell></TCell>
              </TRow>
            ),
          )}
        </TableBody>
      </Table>
    </SectionContent>
  );
};

export default Profile;
