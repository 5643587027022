/** @jsx jsx */
import { jsx } from '@emotion/core';
import { DateTime } from 'luxon';
import { PrimaryText } from '../text';
import {
  checkIfDayIsToday,
  checkIfTomorrow,
  checkIfYesterday,
} from '../../helpers';

export const DueDateTime = ({
  dueDateTime,
  isCompleted,
  className,
}: {
  dueDateTime: string;
  isCompleted: boolean;
  className?: string;
}) => {
  const date = DateTime.fromISO(dueDateTime);
  const today = DateTime.local();

  let color = '#647977';
  let text = date.toRelative();

  if (!isCompleted) {
    if (checkIfDayIsToday(date)) {
      color = '#11BB9A';
      text = 'Today';
    } else if (checkIfTomorrow(date)) {
      text = 'Tomorrow';
    } else if (checkIfYesterday(date)) {
      color = '#FF3C00';
      text = 'Yesterday';
    } else if (date < today) {
      color = '#FF3C00';
    }
  } else {
    text = date.toLocaleString(DateTime.DATE_MED);
  }

  return (
    <PrimaryText
      css={{
        color: color,
      }}
      className={className}
    >
      {text}
    </PrimaryText>
  );
};
