/** @jsx jsx */
import { ClinicFragment, useAddClinicMutation } from '@bc/codegen/manager';
import { useLoadRegionsQuery } from '@bc/codegen/medical';
import { timeZoneMap } from '@bc/shared';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Field, FieldProps, Form, Formik } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js/min';
import { RouteComponentProps } from 'react-router';
import { object, string } from 'yup';
import Pin from '../../assets/pin.svg';
import { Button } from '../../components/button';
import {
  AddressCoordinatesField,
  NormalField,
  PhoneNumberField,
  Select,
  SelectSimpleField,
  SelectWithCaret,
} from '../../components/form';
import { ContentRow, InfoSection } from '../../components/layout';
import { LargeTitle } from '../../components/text';
import { CLINIC_STATUSES, getClinicSlots } from '../../helpers';
import { Row } from '../../styles';
import { Close, Content } from '../ViewAppointment.styles';

const ClinicValidation = object().shape({
  address: string().required('Required'),
  clinicCode: string()
    .required('Required')
    .matches(/^\S*$/, 'No spaces allowed')
    .strict(true)
    .lowercase('Only lower case'),
  open: string().required('Required'),
  close: string().required('Required'),
  googlePlaceId: string().required('Required'),
  locationType: string().required('Required'),
  name: string().required('Required'),
  regionId: string().required('Required'),
  phoneNumber: string()
    .required('Required')
    .test('is-phone', 'Phone number not valid', (value = '') => {
      const phoneNumber = parsePhoneNumberFromString(value!, 'US');
      if (!phoneNumber) return false;

      return phoneNumber.isValid();
    }),
  status: string().required('Required'),
});

const context = { role: 'manager' };
const Icon = styled.img`
  height: 18px;
  width: 18px;
`;

const slots = getClinicSlots();

const CreateClinic = ({ history }: RouteComponentProps<{}>) => {
  const [addClinic] = useAddClinicMutation({ context });
  const { data } = useLoadRegionsQuery({
    context,
  });

  const regions = data?.regions ?? [];

  const handleSubmit = async ({
    regionId,
    ...values
  }: Partial<ClinicFragment & { regionId: number }>) => {
    await addClinic({
      variables: {
        clinic: {
          ...values,
          timeZone: values.timeZone || null,
          active: true,
          location: {
            type: 'Point',
            coordinates: [
              parseFloat(values.longitude!),
              parseFloat(values.latitude!),
            ],
          },
          clinics_regions: {
            data: [
              {
                regionId,
              },
            ],
          },
        },
      },
    });
    history.push('/clinics');
  };
  return (
    <Content aria-label="Create Event" style={{ padding: '40px' }}>
      <Row css={{ position: 'relative' }}>
        <LargeTitle>Create Clinic</LargeTitle>{' '}
        <Close onClick={() => history.push('/clinics')} />
      </Row>
      <Formik
        initialValues={{
          address: '',
          clinicCode: '',
          googlePlaceId: '',
          locationType: 'PHYSICAL',
          name: '',
          phoneNumber: '',
          timeZone: '',
          status: 'HIDDEN',
          open: '10:00:00',
          close: '22:00:00',
        }}
        onSubmit={handleSubmit}
        initialStatus={{
          editing: true,
        }}
        validationSchema={ClinicValidation}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <ContentRow>
                <NormalField title="Clinic Name" name="name" fullWidth />
              </ContentRow>
              <ContentRow>
                <NormalField title="Clinic Code" name="clinicCode" fullWidth />
              </ContentRow>
              <ContentRow>
                <NormalField
                  title="Google Place Id"
                  name="googlePlaceId"
                  fullWidth
                />
              </ContentRow>
              <ContentRow>
                <PhoneNumberField
                  title="Phone Number"
                  name="phoneNumber"
                  placeholder="Enter phone number"
                  css={{ width: '100%' }}
                />
              </ContentRow>
              <ContentRow>
                <InfoSection title="Address" fullWidth>
                  <AddressCoordinatesField name="address" />
                </InfoSection>
              </ContentRow>
              <ContentRow>
                <Field name="locationType">
                  {({ field }: FieldProps<any>) => (
                    <SelectWithCaret
                      style={{
                        width: '100%',
                      }}
                    >
                      <Icon
                        src={Pin}
                        style={{
                          position: 'absolute',
                          top: 16,
                          left: 16,
                        }}
                      />
                      <Select {...field} style={{ paddingLeft: 44 }}>
                        <option value="">Select</option>
                        <option value="PHYSICAL">Physical</option>
                        <option value="MOBILE">Mobile</option>
                        <option value="REMOTE">Remote</option>
                      </Select>
                    </SelectWithCaret>
                  )}
                </Field>
              </ContentRow>
              <ContentRow>
                <InfoSection title="Time Zone" fullWidth>
                  <SelectSimpleField name="timeZone">
                    <option value="">Select</option>
                    {timeZoneMap?.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </SelectSimpleField>
                </InfoSection>
              </ContentRow>
              <ContentRow>
                <InfoSection title="Clinic Status" fullWidth>
                  <SelectSimpleField name="status">
                    <option value="">Select</option>
                    {CLINIC_STATUSES?.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </SelectSimpleField>
                </InfoSection>
              </ContentRow>
              <ContentRow>
                <InfoSection title="Clinic Open" fullWidth>
                  <SelectSimpleField name="open">
                    <option value="">Select</option>
                    {slots.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </SelectSimpleField>
                </InfoSection>
              </ContentRow>
              <ContentRow>
                <InfoSection title="Clinic Close" fullWidth>
                  <SelectSimpleField name="close">
                    <option value="">Select</option>
                    {slots.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </SelectSimpleField>
                </InfoSection>
              </ContentRow>
              <ContentRow>
                <InfoSection title="Region" fullWidth>
                  <SelectSimpleField name="regionId">
                    <option value="">Select</option>
                    {regions?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </SelectSimpleField>
                </InfoSection>
              </ContentRow>
              <ContentRow>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  style={{ width: 'auto' }}
                >
                  Save
                </Button>
              </ContentRow>
            </Form>
          );
        }}
      </Formik>
    </Content>
  );
};

export default CreateClinic;
