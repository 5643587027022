/** @jsx jsx */
import {
  AccountFragment,
  PatientFragment,
  ProductFragment,
  useOneTimeChargeMutation,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import { omit, sumBy } from 'lodash';
import React, { Fragment, useState } from 'react';
import { formatMoney } from '../../helpers';
import { Button, OutlineButton } from '../button';
import EmailSearchInput from '../EmailSearch/search';
import { Input, InputWrap } from '../form';
import { HeaderEdit, Label } from '../layout';
import { Loader } from '../Loader';
import { AddNewPayment } from '../PaymentMethod';
import ProductSearchInput from '../ProductSearch/input';
import { ErrorText, PrimaryText } from '../text';
import { ErrorMessageWrapper } from './Payments.styles';

const ProductSection = styled.div({
  marginBottom: 32,
});

const ProductTable = styled.div({
  backgroundColor: 'rgba(239, 241, 241, 0.5)',
  borderRadius: 8,
  padding: '8px 0',
});

const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const RemoveButton = styled(OutlineButton)({
  border: 'none',
  ':hover': {
    border: 'none',
  },
});

const HeaderText = css({
  color: Colors.gray,
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '14px',
});

const FixedWidthColumn = styled(PrimaryText)({
  display: 'flex',
  fontSize: 16,
  justifyContent: 'flex-end',
  lineHeight: '19px',
  padding: '8px 16px',
  width: 80,
}).withComponent('div');

const PriceColumn = styled(FixedWidthColumn)({
  width: 104,
});

const ExpandedColumn = styled(FixedWidthColumn)({
  flex: 1,
  justifyContent: 'flex-start',
});

interface Props {
  defaultCardId?: string | null;
  defaultEmail?: string | null;
  defaultCopayAmount: number;
  emails: string[];
  appointmentId: string;
  account: AccountFragment;
  patient: PatientFragment;
}

interface ProductQuantity extends ProductFragment {
  quantity: number;
}

const getErrorCode = (error: any) => {
  return error?.graphQLErrors?.[0]?.extensions?.code;
};

const getErrorMessage = (error: any) => {
  return error?.graphQLErrors?.[0]?.message;
};

export const ChargeCard: React.FC<Props> = ({
  defaultCardId,
  defaultEmail,
  defaultCopayAmount,
  emails,
  appointmentId,
  account,
  patient,
}) => {
  const [products, setProducts] = useState<ProductQuantity[]>([]);
  const [notes, setNotes] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [cardSelected, setCard] = useState<
    { id: string; last4?: string; token?: string } | undefined
  >(defaultCardId ? { id: defaultCardId } : undefined);
  const cardIdSelected = cardSelected?.token || cardSelected?.id;
  const [email, setEmail] = useState(defaultEmail || '');
  const [chargeCard, { data: oneTimeChargeData }] = useOneTimeChargeMutation();

  let sumTotal = sumBy(products, ({ quantity = 1, price }) => {
    return quantity * (parseFloat(price as any) || 0);
  });

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (!products.length) {
      setError('Please select at least one product first');
      setIsSubmitting(false);
    }
    if (cardIdSelected) {
      try {
        await chargeCard({
          variables: {
            cardToken: cardIdSelected,
            amount: sumTotal.toFixed(2),
            products: products.map((p) => ({
              ...omit(p, '__typename'),
              price: p.quantity * (parseFloat(p.price as any) || 0),
            })),
            reason: notes
              ? `Payment to Brave Care\n\n${notes}`
              : 'Payment to Brave Care',
            email: (email || '')?.trim(),
            firstName: patient.firstName || '',
            lastName: patient.lastName || '',
            dateOfBirth: patient.dateOfBirth || '',
            appointmentId,
            patientId: patient.id,
          },
        });
        setIsSubmitting(false);
        setProducts([]);
        setCard(undefined);
        setNotes('');
        setError('');
      } catch (error) {
        Sentry.captureException(error);
        const errorMessage = `${
          getErrorMessage(error) || 'Error processing card'
        }`;
        setError(errorMessage);
        setIsSubmitting(false);
      }
    }
  };

  if (!account) {
    return null;
  }

  return (
    <Fragment>
      <ProductSearchInput
        defaultCopayAmount={defaultCopayAmount}
        onChange={(product) => {
          if (product) {
            const hasExisting = products.find((p) => {
              return p.id === product.id && p.type !== 'cp';
            });

            const newProducts = products.map((p) => {
              if (p.id === product.id) {
                return {
                  ...p,
                  quantity: p.quantity + 1,
                };
              }
              return p;
            });

            if (hasExisting) {
              setProducts(newProducts);
              return;
            } else {
              setProducts([
                ...products,
                {
                  ...product,
                  quantity: 1,
                },
              ]);
            }

            // if a service
            // if not copay don't do that
          }
        }}
        error={false}
      />
      <ProductSection>
        {!!products.length && (
          <Row>
            <FixedWidthColumn />
            <ExpandedColumn css={HeaderText}>Product</ExpandedColumn>
            <FixedWidthColumn css={HeaderText}>Qty.</FixedWidthColumn>
            <PriceColumn css={HeaderText}>Price</PriceColumn>
          </Row>
        )}
        {!!products.length && (
          <ProductTable>
            {products.map((product, index) => {
              return (
                <Row key={product.id}>
                  <FixedWidthColumn css={{ justifyContent: 'center' }}>
                    <RemoveButton
                      icon="trash"
                      onClick={() => {
                        let newProducts = [...products];
                        newProducts.splice(index, 1);
                        setProducts(newProducts);
                      }}
                    />
                  </FixedWidthColumn>
                  <ExpandedColumn>{product.name}</ExpandedColumn>
                  <FixedWidthColumn>
                    {product.type !== 'cp' ? (
                      <Input
                        value={`${product.quantity}`}
                        onChange={(e) => {
                          const value = Math.abs(
                            e.target.value ? parseInt(e.target.value, 10) : 0,
                          );
                          let newProducts = [...products];
                          newProducts[index].quantity = value;
                          setProducts(newProducts);
                        }}
                        css={{
                          padding: '8px',
                          textAlign: 'right',
                          width: '100%',
                        }}
                        autoComplete="off"
                      />
                    ) : (
                      '\u00A0'
                    )}
                  </FixedWidthColumn>
                  <PriceColumn>
                    {product.type === 'cp' ? (
                      <Input
                        css={{
                          padding: '8px',
                          textAlign: 'right',
                          width: '100%',
                        }}
                        value={`${product.price || ''}`}
                        onChange={(e) => {
                          let newProducts = [...products];
                          newProducts[index].price = e.target.value as any;
                          setProducts(newProducts);
                        }}
                        autoComplete="off"
                      />
                    ) : (
                      formatMoney(product.price * product.quantity)
                    )}
                  </PriceColumn>
                </Row>
              );
            })}
            <Row css={{ padding: '8px 0' }}>
              <ExpandedColumn />
              <FixedWidthColumn css={{ fontWeight: 700 }}>
                Total
              </FixedWidthColumn>
              <PriceColumn css={{ fontWeight: 700 }}>
                {formatMoney(sumTotal)}
              </PriceColumn>
            </Row>
            <Row
              css={{
                borderTop: `1px solid ${Colors.grayLight}`,
                margin: '16px 16px 0',
                padding: '16px 0 8px',
              }}
            >
              <Input
                css={{ width: '100%' }}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Add note (Visible to Caregivers)"
                value={notes}
              />
            </Row>
          </ProductTable>
        )}
      </ProductSection>
      {!!products.length && (
        <Fragment>
          <AddNewPayment
            key={oneTimeChargeData?.OneTimeCharge?.id}
            value={cardIdSelected}
            onChange={setCard}
            accountId={account.id}
            defaultCardId={defaultCardId}
          />
          <HeaderEdit
            title="Receipt"
            editable={false}
            css={{ marginBottom: 16 }}
          />
          <InputWrap>
            <Label>Send receipt to</Label>
            <EmailSearchInput
              error={false}
              value={email ? email : ''}
              emails={emails}
              onChange={(email) => {
                setEmail(email || '');
              }}
            />
          </InputWrap>
          {isSubmitting ? (
            <Loader />
          ) : (
            <Button
              css={{ fontWeight: 500, marginLeft: 'auto' }}
              type="button"
              disabled={
                !products.length ||
                !cardIdSelected ||
                isSubmitting ||
                sumTotal < 0.5
              }
              onClick={handleSubmit}
            >
              Charge{' '}
              {!!products.length && cardSelected?.last4
                ? `card ending in ${cardSelected?.last4} for`
                : ''}
              &nbsp;
              <span css={{ fontWeight: 700 }}>
                {!!products.length && formatMoney(sumTotal)}
              </span>
            </Button>
          )}
          {error && (
            <ErrorMessageWrapper>
              <ErrorText>{error}</ErrorText>
            </ErrorMessageWrapper>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
