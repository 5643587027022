import { useAddGlobalNoteMutation } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import React, { useState } from 'react';
import { Button, OutlineButton } from '../button';
import { Input, InputWrap, Label } from '../form';

export const AddFlagForm: React.FC<{
  onCancel: () => void;
  accountId?: string;
  appointmentId?: string;
  patientId?: string;
  communicationHistoryId?: string;
  style?: React.CSSProperties;
  typeDisplayName?: string;
}> = ({
  accountId,
  appointmentId,
  communicationHistoryId,
  onCancel,
  patientId,
  style = {},
  typeDisplayName = 'flag',
}) => {
  const [addNote, { loading }] = useAddGlobalNoteMutation();

  const [note, setNote] = useState('');

  const handleAddNote = () => {
    try {
      addNote({
        variables: {
          globalNote: {
            accountId: patientId ? undefined : accountId,
            appointmentId,
            communicationHistoryId,
            patientId,
            note,
          },
        },
      });
      onCancel();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const thingBeingFlagged = appointmentId
    ? 'visit'
    : patientId
    ? 'patient'
    : accountId
    ? 'account'
    : communicationHistoryId
    ? 'communication'
    : '';

  const fullStyle = appointmentId || communicationHistoryId;

  return (
    <Container
      style={
        fullStyle
          ? {
              backgroundColor: '#F3F5F5',
              padding: '16px 24px',
              ...style,
            }
          : { ...style }
      }
    >
      <InputWrap
        style={
          fullStyle
            ? {
                marginBottom: 16,
              }
            : {}
        }
      >
        <Label>
          Add a {typeDisplayName}
          {appointmentId ? ' (Not visible to patient)' : ''}
        </Label>
        <Input
          autoFocus
          placeholder={`Write an internal ${thingBeingFlagged} ${typeDisplayName} here.`}
          onChange={(e) => setNote(e.target.value)}
          style={{
            borderRadius: 8,
            color: Colors.darkHarbor,
            fontSize: 14,
            lineHeight: '16px',
            padding: '15px 16px',
          }}
          value={note}
        />
      </InputWrap>
      <Actions>
        {fullStyle ? (
          <div></div>
        ) : (
          <OutlineButton onClick={onCancel}>Cancel</OutlineButton>
        )}
        <Button
          disabled={!note || loading}
          onClick={handleAddNote}
          style={{ padding: '8px 16px' }}
        >
          Add {typeDisplayName}
        </Button>
      </Actions>
      {fullStyle && <Rule />}
    </Container>
  );
};

const Container = styled.div`
  padding: 16px 0;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Rule = styled.div({
  background: Colors.grayLighter,
  height: 1,
  marginTop: 16,
  width: '100%',
});
