import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import React, { KeyboardEvent } from 'react';
import SearchIcon from '../assets/search.svg';
import { IF_TABLET } from '../styles';
import { PrimaryText } from './text';

const Container = styled.a`
  border: 1px solid ${Colors.grayLight};
  border-radius: 100px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 16px 0 0;
  &:focus {
    border: 1px solid ${Colors.darkHarbor};
    outline: none;
  }
`;

const Icon = styled.img({
  width: '18px',
  height: '18px',
});

const Text = styled(PrimaryText)`
  display: none;
  color: ${Colors.gray};
  margin: 0 16px 0 13px;
  font-size: 14px;
  line-height: 1.5;
  ${IF_TABLET} {
    font-size: 16px;
    display: block;
  }
`;

export const FakeSearchInput: React.FC<{
  onClick: () => void;
  onKeyPress: (event: KeyboardEvent) => void;
}> = ({ onClick, onKeyPress }) => {
  return (
    <Container onClick={onClick} tabIndex={0} onKeyPress={onKeyPress}>
      <Icon src={SearchIcon} />
      <Text>Search...</Text>
    </Container>
  );
};
