/** @jsx jsx */
import { getPreferredPronoun } from '@bc/shared';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { PrimaryText } from '../text';

export const PronounBadge: React.FC<{
  className?: string;
  preferredPronounType: string;
  preferredPronoun?: string;
}> = ({ className, preferredPronounType, preferredPronoun }) => {
  let pronounLabel = getPreferredPronoun(preferredPronounType || '');
  if (
    preferredPronoun &&
    (preferredPronounType === 'other' || preferredPronounType === 'custom')
  ) {
    pronounLabel = preferredPronoun;
  }

  return (
    <Badge className={className} data-testid="pronounBadge">
      <Text>{pronounLabel}</Text>
    </Badge>
  );
};

const Badge = styled.div({
  borderRadius: 4,
  background: Colors.grayLighter,
  color: Colors.darkHarbor,
  padding: '1px 5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'max-content',
});

const Text = styled(PrimaryText)({
  fontSize: 13,
  fontWeight: 500,
  letterSpacing: '1px',
  lineHeight: '18px',
  textTransform: 'uppercase',
});
