import { useTrackActionMutation } from '@bc/codegen/medical';

interface Event {
  type: string;
  data?: any;
}

export const useTrackEvent = () => {
  const [trackAction] = useTrackActionMutation();
  return (event: Event) => {
    const { type, data } = event;
    try {
      trackAction({
        variables: {
          type,
          data,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
};
