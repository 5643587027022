import { Colors } from '@bc/theme';
import React from 'react';
import { ICONS } from '../../assets';
import { Button } from '..//button';
import { PrimaryText } from '../text';

const FindPatient = ({ onFindClick }: { onFindClick: () => void }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '272px',
        background: '#F3F5F5',
        border: `1px solid ${Colors.grayLighter}`,
        borderRadius: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        padding: '24px',
      }}
    >
      <img src={ICONS.kareoLink} />
      <PrimaryText>
        Save time and link the patient from Brave Care directly into Kareo.
      </PrimaryText>
      <Button onClick={onFindClick}>Search for Patient in Kareo</Button>
    </div>
  );
};

export default FindPatient;
