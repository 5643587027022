import styled from '@emotion/styled';
import { FONT_PRIMARY, GREY } from '../../styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ResultsContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const InputContainer = styled.form`
  position: relative;
`;

export const SearchIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

export const ResultDropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 6; //TODO: see if can drop back down to 5 after cleanup
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
  overflow: hidden;
`;

export const Result = styled.a<{ highlighted: boolean }>`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eee;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 20px;
  text-decoration: none;
  color: #314d4a;
  font-size: 18px;
  line-height: 28px;
  ${FONT_PRIMARY}
  color: ${GREY};

  background-color: ${({ highlighted }) =>
    highlighted ? '#eee' : 'transparent'};

  :hover {
    background-color: #eee;
    color: ${GREY};
  }

  &:last-of-type {
    border-bottom: none;
  }
`;
