import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import React from 'react';
import { PrimaryText } from '../text';

export const Month = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  borderTop: `1px solid ${Colors.grayLighter}`,
  borderLeft: `1px solid ${Colors.grayLighter}`,
});

export const Week = styled.div({
  display: 'flex',
});

export const Day = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

export const Weekday = styled(PrimaryText)<{ highlighted?: boolean }>(
  ({ highlighted }) => ({
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: '0.1px',
    color: highlighted ? Colors.teal : Colors.gray,
  }),
);

export const Monthday = styled(Weekday)<{ current?: boolean }>(
  ({ current }) => ({
    alignSelf: 'flex-end',
    color: current ? Colors.darkHarbor : Colors.gray,
  }),
);

export const DayOfMonth = styled(PrimaryText)<{ highlighted?: boolean }>(
  ({ highlighted }) => ({
    alignItems: 'center',
    background: highlighted ? Colors.teal : 'white',
    borderRadius: 20,
    color: highlighted ? 'white' : Colors.darkHarbor,
    display: 'flex',
    fontSize: 18,
    fontWeight: 500,
    height: 40,
    justifyContent: 'center',
    lineHeight: '22px',
    margin: '2px auto 24px',
    textAlign: 'center',
    width: 40,
  }),
);

export const DayItems = styled.div<{ noBorder?: boolean }>`
  border-right: ${({ noBorder }) =>
    noBorder ? 'none;' : `1px solid ${Colors.grayLighter};`};
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Box = styled.div`
  background-color: #fff;
  cursor: pointer;
  height: auto;
  display: flex;
  margin-bottom: 24px;
`;

export const Time = styled(PrimaryText)`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: ${Colors.darkHarbor};
`;

export const Name = styled(Time)`
  font-weight: bold;
`;

export const DayItem: React.FC<{
  kind?: 'week' | 'month';
  name: string;
  time?: string;
  color: string;
  onClick: () => void;
}> = ({ kind = 'bar', name, time, color, onClick }) => (
  <Box
    onClick={onClick}
    style={{ ...(kind === 'month' && { marginBottom: 0 }) }}
  >
    <div
      style={{
        width: '4px',
        backgroundColor: color,
        borderRadius: '100px',
        marginRight: '4px',
        ...(kind === 'month' && { alignSelf: 'center', height: '4px' }),
      }}
    />
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Name style={{ marginBottom: 4 }}>{name}</Name>
      {time && <Time>{time}</Time>}
    </div>
  </Box>
);
