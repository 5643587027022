import { useRoomsByClinicCodeSubscription } from '@bc/codegen/medical';

export interface Room {
  __typename?: 'rooms' | undefined;
  appointment?: {
    __typename?: 'appointments' | undefined;
    id: any;
    appointment_patients?: {
      __typename?: 'appointment_patient' | undefined;
      patient?: {
        _typename?: 'patient' | undefined;
        id: any;
      };
    }[];
  } | null;
  id: any;
  name: string;
  status: string;
}

export const useGetRoomsByClinicCode = (selectedClinic: string) => {
  const { data } = useRoomsByClinicCodeSubscription({
    variables: {
      clinicCode: selectedClinic,
    },
  });

  const rooms = data?.rooms ?? [];

  const sortedRooms: Room[] = rooms.sort((a, b) => {
    return a.name.localeCompare(b.name, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  });

  return sortedRooms;
};
