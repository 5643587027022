import {
  AppointmentPartsFragment,
  useGetAccountByPkQuery,
  useSendTextNotificationMutation,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { parsePhoneNumberFromString } from 'libphonenumber-js/min';
import { DateTime } from 'luxon';
import React, { Fragment, useState } from 'react';
import { Button } from '../button';
import Dropdown from '../Dropdown/dropdown';
import { ContentRow, InfoSection } from '../layout';
import { SuccessMessageWrapper } from '../Payments/Payments.styles';
import { PrimaryNotBlank, PrimaryText } from '../text';
import phone from './phone.svg';

interface Props {
  appointment: AppointmentPartsFragment;
}

const PhoneLink = styled.a({ color: Colors.green, textDecoration: 'none' });

export const getDisplayDate = (date: DateTime) => {
  const today = DateTime.local();
  const hideRelative =
    date.startOf('day').diff(today.startOf('day'), 'days').days >= 2;
  const relative = date.toRelativeCalendar();

  return {
    date: `${date.toFormat('cccc, LLLL d')}`,
    relative: hideRelative ? '' : `${relative || ''}`,
    time: date.toFormat('h:mm a'),
    displayTimeWithOffset: date.toFormat('h:mm a ZZZZ'),
  };
};

export const getArrivingSoon = ({
  address,
  clinicPhoneNumber,
}: {
  address: string;
  clinicPhoneNumber: string;
}) => {
  const fullNumber = parsePhoneNumberFromString(
    clinicPhoneNumber,
    'US' as any,
  )?.formatNational();
  return `Hi, its Brave Care. The mobile clinic will be arriving at ${address} for your visit in 10 minutes. If you need to cancel or reschedule, please call ${fullNumber}. Do not reply to this message.`;
};

export const getArrived = ({
  address,
  clinicPhoneNumber,
}: {
  address: string;
  clinicPhoneNumber: string;
}) => {
  const fullNumber = parsePhoneNumberFromString(
    clinicPhoneNumber,
    'US' as any,
  )?.formatNational();
  const prettyAddress = address?.replace(', USA', '') ?? '';
  return `The Brave Care mobile clinic has arrived at ${prettyAddress}. If you don’t see the mobile clinic, please call ${fullNumber}. Do not reply to this message.`;
};

export const getNoShow = ({
  formattedTime,
  clinicPhoneNumber,
}: {
  formattedTime: string;
  clinicPhoneNumber: string;
}) => {
  const fullNumber = parsePhoneNumberFromString(
    clinicPhoneNumber,
    'US' as any,
  )?.formatNational();
  const timeNow = DateTime.local().toFormat('h:mm a');
  return `You were scheduled for a visit with the Brave Care mobile clinic at ${formattedTime}. It is now ${timeNow} and we are leaving your location. You can reschedule your appointment in the Brave Care mobile app or by calling ${fullNumber}. Do not reply to this message.`;
};

const MobileNotifications: React.FC<Props> = ({ appointment }) => {
  const [value, setValue] = useState('');

  const { data } = useGetAccountByPkQuery({
    variables: {
      accountId: appointment.accountId!,
    },
  });
  const [sendMessage, { data: responseData, error }] =
    useSendTextNotificationMutation();
  const account = data?.accounts_by_pk;

  const sendNoShow = async () => {
    const { time } = getDisplayDate(
      DateTime.fromISO(appointment.startTime, {
        zone: appointment.clinic.timeZone!,
        setZone: true,
      }),
    );

    await sendMessage({
      variables: {
        to: account?.phoneNumber!,
        message: getNoShow({
          formattedTime: time,
          clinicPhoneNumber: appointment.clinic.phoneNumber,
        }),
      },
    });
  };

  const sendArrivingSoon = async () => {
    await sendMessage({
      variables: {
        to: account?.phoneNumber!,
        message: getArrivingSoon({
          address: appointment?.address?.formatted_address,
          clinicPhoneNumber: appointment.clinic.phoneNumber,
        }),
      },
    });
  };

  const sendHasArrived = async () => {
    await sendMessage({
      variables: {
        to: account?.phoneNumber!,
        message: getArrived({
          address: appointment?.address?.formatted_address,
          clinicPhoneNumber: appointment.clinic.phoneNumber,
        }),
      },
    });
  };

  const actions = {
    SOON: sendArrivingSoon,
    ARRIVED: sendHasArrived,
    NO_SHOW: sendNoShow,
  };

  const MESSAGES = [
    {
      value: 'SOON',
      title: "We'll be there soon",
    },
    {
      value: 'ARRIVED',
      title: 'We’ve arrived',
    },
    {
      value: 'NO_SHOW',
      title: 'No show',
    },
  ];

  const formattedNumber =
    account?.phoneNumber &&
    parsePhoneNumberFromString(account.phoneNumber, 'US')?.formatNational();

  return (
    <Fragment>
      <ContentRow>
        <InfoSection title="First Name">
          <PrimaryNotBlank>{account?.firstName}</PrimaryNotBlank>
        </InfoSection>
        <InfoSection title="Last Name">
          <PrimaryNotBlank>{account?.lastName}</PrimaryNotBlank>
        </InfoSection>
      </ContentRow>

      <ContentRow>
        <InfoSection title="Phone number">
          <PrimaryNotBlank>
            <PhoneLink href={`tel:${account?.phoneNumber}`}>
              {formattedNumber}
            </PhoneLink>
          </PrimaryNotBlank>
        </InfoSection>
      </ContentRow>

      <InfoSection title="Caregiver notifications" fullWidth>
        <div
          style={{
            display: 'flex',
          }}
        >
          <Dropdown
            options={MESSAGES}
            value={value}
            icon={phone}
            placeHolder="Select message"
            onChange={(item) => {
              setValue(item?.value ?? '');
            }}
          />

          <div
            style={{
              padding: '0 10px',
            }}
          >
            <Button
              style={{
                minWidth: '161px',
              }}
              disabled={!value}
              onClick={() => {
                const messageToSend = MESSAGES.find(
                  (message) => message.value === value,
                );

                //@ts-ignore
                if (messageToSend && actions[messageToSend.value]) {
                  //@ts-ignore
                  actions[messageToSend?.value]();
                  setValue('');
                }
              }}
            >
              Send Text
            </Button>
          </div>
        </div>
      </InfoSection>
      {!!responseData && !error && (
        <SuccessMessageWrapper>
          <PrimaryText style={{ color: '#FFF' }}>Success!</PrimaryText>
        </SuccessMessageWrapper>
      )}
    </Fragment>
  );
};

export default MobileNotifications;
