import { useBootstrapClinicsLazyQuery } from '@bc/codegen/medical';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { useLocalStorage } from 'react-use';
import { hasRole } from '../auth';
import Loader from '../components/Loader/loader';
import { useAuth0 } from '../react-auth0';
import { FONT_PRIMARY } from '../styles';

const Wrap = styled.div({
  display: 'flex',
  width: '100%',
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
});

const Container = styled.div({
  width: '300px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const VerifyingText = styled.div`
  ${FONT_PRIMARY}
  margin-top: 16px;
`;

const AuthVerify: React.FC<RouteComponentProps> = ({ history }) => {
  const { user, accessToken, isAuthenticated } = useAuth0();
  const [, setSelectedClinics] = useLocalStorage<string[]>('selectedClinics');
  const [, setSelectedClinic] = useLocalStorage('selectedClinic');

  const [bootstrapClinics] = useBootstrapClinicsLazyQuery();

  useEffect(() => {
    const run = async () => {
      if (isAuthenticated === true) {
        const hasAssistantRole = hasRole('assistant', accessToken);

        if (user && accessToken && hasAssistantRole) {
          // Negotiate if we should adjust and update clinics to the default clinic of the user.
          const { data } = await bootstrapClinics({
            variables: {
              auth0UserId: user.sub,
            },
          });
          const staff = data?.staff?.[0];
          const regionClinics = data?.clinics_regions ?? [];

          if (staff?.defaultClinicId && staff?.clinic?.clinicCode) {
            setSelectedClinic(staff.clinic.clinicCode);
            setSelectedClinics([staff.clinic.clinicCode]);
          } else if (regionClinics[0]) {
            // Adjust if no defualt home is set
            const clinic = regionClinics[0];
            if (clinic?.clinic) {
              setSelectedClinic(clinic.clinic.clinicCode);
              setSelectedClinics([clinic.clinic.clinicCode]);
            }
          }

          history.push('/dashboard');
          Sentry.configureScope((scope) => {
            scope.setUser({ id: user?.sub });
          });
        } else if (!hasAssistantRole) {
          history.push('/', {
            needsRole: true,
          });
        }
      }
    };

    run();
  }, [accessToken, user, isAuthenticated, history]);
  return (
    <Wrap>
      <Container>
        <Loader />
        <VerifyingText>Verifying...</VerifyingText>
      </Container>
    </Wrap>
  );
};

export default AuthVerify;
