import { AppointmentPartsFragment } from '@bc/codegen/medical';
import React from 'react';
import { PatientProfile } from '../../components/Patient';

const AppointmentPatientProfile: React.FC<{
  appointment: AppointmentPartsFragment;
}> = ({ appointment }) => {
  const patient = appointment?.appointment_patients[0]?.patient;
  return patient ? <PatientProfile data={patient} /> : null;
};

export default AppointmentPatientProfile;
