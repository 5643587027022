import { Staff_Type_Enum } from '@bc/codegen/manager';
import {
  Tasks_Bool_Exp,
  Tasks_Insert_Input,
  Tasks_Notifications_Insert_Input,
  Tasks_Set_Input,
  Task_Priority_Enum,
  Task_Status_Enum,
  Task_Sub_Type_Enum,
  Task_Type_Enum,
  useDeleteTaskMutation,
  useDeleteUnreadMutation,
  useInsertTaskAssignmentMutation,
  useInsertTaskMutation,
  useInsertTaskNotificationsMutation,
  useLoadTaskGroupsLazyQuery,
  useMarkAsReadMutation,
  useRemoveNotificationMutation,
  useUnassignTaskMutation,
  useUnwatchTaskMutation,
  useUpdateTaskMutation,
  useUpdateTaskNotificationsMutation,
  useWatchTaskMutation,
  WatchTaskByIdSubscription,
  WatchTasksSubscription,
} from '@bc/codegen/medical';
import { DateTime } from 'luxon';

export type Task =
  | WatchTasksSubscription['tasks'][0]
  | WatchTaskByIdSubscription['tasks_by_pk'];

export const getAssociations = (task?: Task) => {
  return (
    task?.tasks_notifications?.filter((association) => {
      return (
        association.patientId ||
        association.appointmentId ||
        association.accountId
      );
    }) ?? []
  );
};
export const getIsTaskWatched = (task?: Task) => {
  return task?.tasks_watches?.length === 1;
};

export const getResponsible = (task?: Task) => {
  return task?.tasks_notifications?.find((association) => {
    return association.staffId || association.taskGroupId;
  });
};

export const getActiveAssignments = (task?: Task) => {
  return task?.tasks_assignments ?? [];
};

export const hasReadTask = (task?: Task, currentStaffId?: string) => {
  return !!task?.tasks_reads?.find(({ staffId }) => {
    return staffId === currentStaffId;
  });
};

export const getTotalReaderCount = (task?: Task) => {
  let totalReadCount = 1;
  const responsible = getResponsible(task);

  if (responsible?.taskGroupId) {
    totalReadCount =
      responsible.tasks_group?.tasks_groups_staffs_aggregate.aggregate?.count ??
      1;
  }

  return totalReadCount;
};

export const getHasAllRead = (task?: Task) => {
  let totalReadCount = getTotalReaderCount(task);
  const readCount = task?.tasks_reads?.length ?? 0;
  return totalReadCount === readCount;
};

export const isResponsible = (task?: Task, staffId?: string) => {
  let responsible = task?.tasks_notifications?.find(
    ({ staffId: taskStaffid }) => {
      return taskStaffid === staffId;
    },
  );

  if (!responsible && task?.tasks_notifications) {
    responsible = task.tasks_notifications.find(({ responsible }) => {
      return responsible?.tasks_groups_staffs_aggregate?.aggregate?.count === 1;
    });
  }

  return responsible;
};

interface GetNotifications {
  unread?: boolean;
  start?: DateTime;
  end?: DateTime;
  assigned?: boolean;
  priority?: Task_Priority_Enum[];
  status?: Task_Status_Enum[];
  subTypes?: Task_Sub_Type_Enum[];
  types?: Task_Type_Enum[];
  staffId?: string;

  staffTypes?: Staff_Type_Enum[];
  clinicIds?: string[];
  appointmentIds?: string[];
  patientIds?: string[];
  accountIds?: string[];
  before?: string;
  filters?: Tasks_Bool_Exp;
  completed?: boolean;
}

interface AddTask {
  task: Tasks_Insert_Input;
  notifications?: Tasks_Notifications_Insert_Input[];
  assignStaffId?: string;
}

export const useTasks = () => {
  const [insertTask] = useInsertTaskMutation();
  const [markTaskAsRead, { loading: taskReadLoading }] =
    useMarkAsReadMutation();
  const [assignTaskToStaff] = useInsertTaskAssignmentMutation();
  const [unassignTaskFromStaff, { loading: unassignPending }] =
    useUnassignTaskMutation();
  const [updateTaskNotification] = useUpdateTaskNotificationsMutation();
  const [upsertTaskNotifications] = useInsertTaskNotificationsMutation();
  const [removeTaskNotification] = useRemoveNotificationMutation();
  const [deleteTaskById] = useDeleteTaskMutation();
  const [updateTaskById] = useUpdateTaskMutation();
  const [deleteUnreadForTask, { loading: unreadTaskLoading }] =
    useDeleteUnreadMutation();
  const [watchTaskById, { loading: watchPending }] = useWatchTaskMutation();
  const [unWatchTaskById, { loading: unwatchPending }] =
    useUnwatchTaskMutation();

  const [loadTaskGroup] = useLoadTaskGroupsLazyQuery();

  const assignTask = ({
    taskId,
    staffId,
  }: {
    taskId: string;
    staffId: string;
  }) => {
    return assignTaskToStaff({
      variables: {
        taskAssignment: {
          taskId: taskId,
          assignedTo: staffId,
        },
      },
    });
  };

  const markTaskRead = (taskId: string) => {
    return markTaskAsRead({
      variables: { taskId: taskId },
    });
  };

  const markAsUnread = (taskId: string) => {
    return deleteUnreadForTask({
      variables: {
        where: {
          taskId: {
            _eq: taskId,
          },
        },
      },
    });
  };

  const watchTask = (taskId: string) => {
    return watchTaskById({
      variables: {
        taskId,
      },
    });
  };
  const unwatchTask = (taskId: string) => {
    return unWatchTaskById({
      variables: {
        taskId,
      },
    });
  };

  const addTask = async ({ task, notifications, assignStaffId }: AddTask) => {
    return insertTask({
      variables: {
        task: {
          ...task,
          tasks_notifications: notifications
            ? {
                data: notifications,
              }
            : undefined,
          // If you create a task auto-watch it
          tasks_watches: {
            data: [{}],
          },
          tasks_assignments:
            //Only assign if it's an action type
            assignStaffId && task.type === 'ACTION'
              ? {
                  data: [
                    {
                      assignedTo: assignStaffId,
                    },
                  ],
                }
              : undefined,
        },
      },
    });
  };

  const deleteTask = (taskId: string) => {
    return deleteTaskById({
      variables: {
        taskId,
      },
    });
  };

  const updateTask = (taskId: string, task: Tasks_Set_Input) => {
    return updateTaskById({
      variables: {
        taskId,
        task,
      },
    });
  };

  const removeNotification = (taskNotificationId: number) => {
    return removeTaskNotification({
      variables: {
        taskNotificationId,
      },
    });
  };

  const unassignTask = (taskAssignmentId: number) => {
    return unassignTaskFromStaff({
      variables: {
        taskAssignmentId: taskAssignmentId,
      },
    });
  };

  const isStaffInGroup = async (taskGroupId: string, staffId: string) => {
    // check if the staff member is in the group
    const { data } = await loadTaskGroup({
      variables: {
        where: {
          id: {
            _eq: taskGroupId,
          },
          tasks_groups_staffs: {
            staffId: {
              _eq: staffId,
            },
          },
        },
      },
    });

    return !!data?.tasks_groups?.[0];
  };

  return {
    addTask,
    updateTask,
    deleteTask,

    watchTask,
    unwatchTask,
    watchingPending: watchPending || unwatchPending,

    markTaskRead,
    markAsUnread,
    unreadLoading: unreadTaskLoading || taskReadLoading,

    assignTask,
    unassignTask,
    unassignTaskPending: unassignPending,

    updateTaskNotification,
    upsertTaskNotifications,
    removeNotification,
    isStaffInGroup,
  };
};

export const buildWhere = ({
  staffId,
  unread,
  assigned,
  priority,
  status,
  types,
  subTypes,
  appointmentIds,
  accountIds,
  patientIds,
  start,
  end,
  filters,
  completed,
}: GetNotifications) => {
  let where: Tasks_Bool_Exp = {
    active: {
      _eq: true,
    },
  };

  let _or: Tasks_Bool_Exp[] = [];
  let _and: Tasks_Bool_Exp[] = [];

  if (unread && staffId) {
    where = {
      _not: {
        tasks_reads: { staffId: { _eq: staffId } },
      },
      ...where,
    };
  }

  if (completed === false) {
    // Not completed actions
    // memos that are not read completely by all
    _and.push({
      _or: [
        {
          type: {
            _eq: 'ACTION',
          },
          status: {
            _neq: 'COMPLETED',
          },
        },
        {
          type: {
            _eq: 'INFORMATION',
          },
          tasks_responsible_count: {
            completed: {
              _eq: false,
            },
          },
        },
      ],
    });
  } else if (completed === true) {
    // Completed actions
    // completed read by all responsible for memos
    _and.push({
      _or: [
        {
          type: {
            _eq: 'ACTION',
          },
          status: {
            _eq: 'COMPLETED',
          },
        },
        {
          type: {
            _eq: 'INFORMATION',
          },
          tasks_responsible_count: {
            completed: {
              _eq: true,
            },
          },
        },
      ],
    });
  }

  // for today supply start of day and end of day.
  // for future just supply start of a day

  if (start && end) {
    _and.push({
      dueDateTime: { _gte: start.toISO() },
    });
    _and.push({
      dueDateTime: { _lte: end.toISO() },
    });
  }

  if (start && !end) {
    _and.push({
      _or: [
        {
          dueDateTime: {
            _gte: start.toISO(),
          },
        },
      ],
    });
  }

  // Get all assigned to specific staff member
  if (assigned === true) {
    where = {
      ...where,
      tasks_assignments: {
        assignedTo: { _eq: staffId },
        active: { _eq: true },
      },
    };
    // Get all not assigned to a staff member
  } else if (assigned === false) {
    where = {
      ...where,
      _not: {
        ...where?._not,
        tasks_assignments: {
          assignedTo: { _eq: staffId },
          active: { _eq: true },
        },
      },
    };
  }

  // Task Type Searches

  if (priority) {
    _or.push({
      priority: {
        _in: priority,
      },
    });
  }
  if (status) {
    _or.push({
      status: {
        _in: status,
      },
    });
  }

  if (types) {
    _or.push({
      type: {
        _in: types,
      },
    });
  }

  if (subTypes) {
    _or.push({
      subType: {
        _in: subTypes,
      },
    });
  }

  // Task Notification Searches

  if (staffId) {
    _or.push({
      tasks_notifications: {
        staffId: {
          _eq: staffId,
        },
      },
    });

    _or.push({
      tasks_watches: {
        staffId: {
          _eq: staffId,
        },
      },
    });
    _or.push({
      tasks_notifications: {
        tasks_group: {
          tasks_groups_staffs: {
            staffId: {
              _eq: staffId,
            },
          },
        },
      },
    });
  }

  if (appointmentIds) {
    _or.push({
      tasks_notifications: {
        appointmentId: {
          _in: appointmentIds,
        },
      },
    });
  }

  if (patientIds) {
    _or.push({
      tasks_notifications: {
        patientId: {
          _in: patientIds,
        },
      },
    });
  }

  if (accountIds) {
    _or.push({
      tasks_notifications: {
        accountId: {
          _in: accountIds,
        },
      },
    });
  }

  if (_or.length) {
    where = {
      ...where,
      _or,
    };
  }

  if (_and.length) {
    where = {
      ...where,
      _and,
    };
  }

  if (filters) {
    where = {
      ...where,
      _and: [...(_and ?? []), filters],
    };
  }
  return where;
};
