/** @jsx jsx */
import { useGetPatientByPkQuery } from '@bc/codegen/medical';
import { getRelationshipDisplay } from '@bc/shared';
import { jsx } from '@emotion/core';
import { Menu, MenuItem } from '@reach/menu-button';
import { ICONS } from '../../assets';
import { formatPhoneNumber } from '../../helpers';
import { AccountAvatarDisplay } from '../AppointmentDetails/AvatarDisplay';
import { PrimaryText } from '../text';
import {
  DropdownMenuList,
  DropdownText,
  PillMenuButton,
  SecondaryDropdownText,
} from './ui';

export const TaskCaregiverDropdown = ({
  patientId,
  onSelect,
}: {
  patientId: string;
  onSelect: (caregiverId: string) => void;
}) => {
  const { data } = useGetPatientByPkQuery({
    variables: { patientId: patientId },
    skip: !patientId,
  });

  const patient = data?.patients_by_pk;
  const accounts = patient?.accounts ?? [];

  return (
    <Menu>
      <PillMenuButton icon={ICONS.caregiverLink}>Link Caregiver</PillMenuButton>
      <DropdownMenuList portal={false}>
        {accounts.map((account) => {
          return (
            <MenuItem
              onSelect={() => {
                onSelect(account.accountId);
              }}
              css={{
                padding: '16px 24px',
                borderBottom: '1px solid #E5E8E8',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <AccountAvatarDisplay accountId={account.accountId} size={40} />
              <div
                css={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0 16px',
                }}
              >
                <DropdownText
                  css={{
                    marginBottom: '4px',
                  }}
                >
                  {account.account.firstName} {account.account.lastName} (
                  {account.relationship &&
                    getRelationshipDisplay(account.relationship)}
                  )
                </DropdownText>
                <SecondaryDropdownText>
                  {formatPhoneNumber(account.account.phoneNumber)}
                </SecondaryDropdownText>
              </div>
              <div
                css={{
                  background: '#E5E8E8',
                  borderRadius: '100px',
                  padding: '3px 12px',
                }}
              >
                <PrimaryText css={{ color: '#647977', fontSize: '12px' }}>
                  Caregiver
                </PrimaryText>
              </div>
            </MenuItem>
          );
        })}
      </DropdownMenuList>
    </Menu>
  );
};
