/** @jsx jsx */
import {
  TaskAssignmentFragment,
  TaskNotificationFragment,
  Task_Priority_Enum,
  Task_Sub_Type_Enum,
  Task_Type_Enum,
  WatchTasksSubscription,
} from '@bc/codegen/medical';
import { DeepExtractType } from 'ts-deep-extract-types';

export type Task = DeepExtractType<WatchTasksSubscription, ['tasks']>[0];

export interface Values {
  title: string;
  note?: string;
  priority?: Task_Priority_Enum;
  type: Task_Type_Enum;
  subType?: Task_Sub_Type_Enum;
  dueDate: Date;
  notifications: Partial<TaskNotificationFragment>[];
  associations: Partial<TaskNotificationFragment>[];
  assignment?: Partial<TaskAssignmentFragment>[];
}

interface SubTypeValue {
  value: Task_Sub_Type_Enum | undefined;
  label: string;
  abbreviation: string;
}

export const SUB_TYPES: SubTypeValue[] = [
  {
    value: undefined,
    label: 'General',
    abbreviation: 'General',
  },
  {
    value: 'MEDICAL_RECORD_REQUEST',
    label: 'Medical Records Request',
    abbreviation: 'MRR',
  },
  {
    value: 'FOLLOW_UP',
    label: 'Follow Up',
    abbreviation: 'FU',
  },
  {
    value: 'APPOINTMENT_PREP',
    label: 'Appointment Prep',
    abbreviation: 'AP',
  },
  {
    value: 'VERIFICATION',
    label: 'Verification',
    abbreviation: 'Verification',
  },
  {
    value: 'SCHEDULE',
    label: 'Schedule',
    abbreviation: 'Schedule',
  },
  {
    value: 'DOCUMENT_MIGRATION',
    label: 'Document Migration',
    abbreviation: 'DM',
  },
  {
    value: 'SIGN_OUT',
    label: 'Sign Out',
    abbreviation: 'Sign Out',
  },
  {
    value: 'ANNOUNCEMENT',
    label: 'Announcement',
    abbreviation: 'Announcement',
  },
  {
    value: 'BEST_PRACTICE',
    label: 'Best Practice',
    abbreviation: 'Best Practice',
  },
  {
    value: 'REFERRAL',
    label: 'Referral',
    abbreviation: 'Referral',
  },
  {
    value: 'PATIENT_REQUEST',
    label: 'Patient Request',
    abbreviation: 'Patient Request',
  },
];

export const getTypeLabel = (value?: string | null) => {
  const type = SUB_TYPES.find((type) => {
    return type.value === (value ?? undefined);
  });

  return type?.label;
};

export const getTypeAbbreviation = (value?: string | null) => {
  const type = SUB_TYPES.find((type) => {
    return type.value === (value ?? undefined);
  });

  return type?.abbreviation;
};
