/** @jsx jsx */
import { useLoadRegionsQuery } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import CaretDropdownIcon from '../../assets/caret.svg';
import { IF_TABLET } from '../../styles';
import { Checkbox } from '../form';
import { PrimaryText } from '../text';

interface Props {
  regionIds: number[];
  onSelect: (regionIds: number[]) => void;
}

export const MultiRegionDropdown = ({ regionIds = [], onSelect }: Props) => {
  const { data } = useLoadRegionsQuery();

  const regions = data?.regions ?? [];
  const selectedRegions = regions
    .filter((region) => {
      return regionIds.includes(region.id);
    })
    .map(({ name }) => {
      return name;
    });

  return (
    <Container>
      <Menu>
        <DropDownTrigger
          css={{
            border: `1px solid ${Colors.grayLight}`,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'row',
            padding: '14px',
            borderRadius: '8px',
          }}
        >
          <TriggerText>
            {selectedRegions.length
              ? selectedRegions?.join(', ')
              : 'Select Regions'}
          </TriggerText>
          <Caret src={CaretDropdownIcon} />
        </DropDownTrigger>
        <DropDown
          css={{
            padding: '24px',
          }}
        >
          {regions.map((region, index) => {
            return (
              <DropDownItem
                key={`region-${region.id}`}
                onSelect={() => {
                  if (regionIds.includes(region.id)) {
                    const nextRegionIds = regionIds.filter((id) => {
                      return id !== region.id;
                    });
                    onSelect(nextRegionIds);
                  } else {
                    onSelect([...regionIds, region.id]);
                  }
                }}
                css={{
                  marginBottom: index !== regions.length - 1 ? '16px' : 0,
                }}
              >
                <Item>
                  <Checkbox checked={regionIds.includes(region.id)} />
                  <ItemText
                    css={{
                      fontWeight: 'bold',
                      fontSize: '18px',
                    }}
                  >
                    {region.name}
                  </ItemText>
                </Item>
              </DropDownItem>
            );
          })}
        </DropDown>
      </Menu>
    </Container>
  );
};

const NBSP = '\u00A0';

const Item = styled.div({
  flexDirection: 'row',
  display: 'flex',
  cursor: 'pointer',
});

const commonFontRules = css`
  letter-spacing: 0.1px;
  font-size: 14px;
  ${IF_TABLET} {
    font-size: 16px;
  }
`;

const ShowText = styled(PrimaryText)`
  color: ${Colors.gray};
  ${commonFontRules};
`;

const Caret = styled.img`
  height: 24px;
  margin-left: auto;
  width: 24px;
  z-index: 5;
  transform: rotate(180deg);
`;

const Container = styled.div`
  position: relative;
`;

const DropDownTrigger = styled(MenuButton)`
  align-items: center;
  background-color: ${Colors.white};
  border: none;
  cursor: pointer;
  display: flex;
  padding: 8px;
  &:focus {
    box-shadow: inset 0 0 0 2px ${Colors.grayLighter},
      inset 0 0 0 4px ${Colors.white};
    outline: none;
    border-radius: 8px;
  }
`;

const TriggerText = styled(PrimaryText)`
  font-weight: 600;
  font-size: 14px;
`;

const DropDown = styled(MenuList)`
  background: ${Colors.white};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  border: none;
  min-width: 250px;
  overflow: hidden;
  padding: 8px 0;
  position: relative;
  z-index: 16;
`;

const DropDownItem = styled(MenuItem)<{ selected?: boolean }>(
  (props) => `
  align-items: center;
  background-color: ${props.selected ? '#F3F5F5' : Colors.white};
  display: flex;
  padding: 0;

  &:hover {
    background-color: #F8FAFA;
  }
`,
);

const ItemText = styled(PrimaryText)`
  color: ${Colors.darkHarbor};
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  margin-left: 12px;
`;
