import { AppointmentPartsFragment } from '@bc/codegen/medical';
import React from 'react';
import { Insurance } from '../../components/Patient';

const AppointmentInsurance: React.FC<{
  appointment: AppointmentPartsFragment;
}> = ({ appointment }) => {
  const patient = appointment?.appointment_patients?.[0]?.patient;
  return patient ? <Insurance data={patient} /> : null;
};

export default AppointmentInsurance;
