/** @jsx jsx */
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import { identity } from 'lodash';
import React, { Fragment, LegacyRef, useEffect, useRef } from 'react';
import MaskedInput from 'react-text-mask';
import TextareaAutosize from 'react-textarea-autosize';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import CaretDown from '../assets/caret-down.svg';
import radioChecked from '../assets/radio-checked.svg';
import SearchIcon from '../assets/search.svg';
import { FONT_PRIMARY, IF_DESKTOP, IF_TABLET } from '../styles';
import { Location } from '../types';
import AddressSearchInput from './AddressSearch/input';
import FullPlaceSearch from './AddressSearch/place';
import { ClearIcon } from './AddressSearch/ui';
import BasicSearchInput, { Value } from './BasicValueSearch/input';
import CopyToClip from './copy';
import { InfoSection } from './layout';
import PlaceSearchInput, { PlaceType } from './PlaceSearch/input';
import { PrimaryText } from './text';

const ClearButton = styled.button({
  border: 'none',
  background: 'none',
  display: 'inline-block',
  cursor: 'pointer',
  position: 'absolute',
  right: 16,
  top: '50%',
  transform: 'translateY(-50%)',
});

const StaticClearIcon = styled(ClearIcon)({
  position: 'unset',
  top: 'unset',
  right: 'unset',
  transform: 'none',
});

export const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy', {
  maxYear: new Date().getFullYear(),
});

export const numberMask = createNumberMask({
  allowDecimal: true,
});

export const DATE_MASK = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const InputWrap = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  margin-bottom: 24px;
  position: relative;
`;

export const CheckboxRowWrap = styled(InputWrap)`
  flex-direction: row;
  flex-wrap: wrap;
  label {
    width: 100%;
    padding-bottom: 8px;
  }
  ${IF_TABLET} {
    label {
      width: 25%;
      padding-bottom: 16px;
    }
  }
`;

export const Label = styled(PrimaryText)`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 8px;
`.withComponent('label');

export const Input = styled.input<{ error?: boolean; disabled?: boolean }>`
  ${FONT_PRIMARY};
  color: ${Colors.darkHarbor};
  padding: 16px;
  appearance: none;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  text-align: left;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  border-color: ${({ error }) => (error ? Colors.errorRed : Colors.grayLight)};
  background-color: ${({ disabled }) =>
    disabled ? Colors.grayLighter : Colors.white};

  ::placeholder {
    color: ${Colors.gray};
  }

  :focus {
    border-color: ${Colors.teal};
  }

  ${IF_DESKTOP} {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const NoBorderInput = styled(Input)({
  border: 'none',
  borderRadius: '0px',
  flex: 1,
  paddingLeft: '0px',
  ':focus::placeholder': {
    color: Colors.gray,
  },
});

export const InputBorder = styled.div<{ error?: boolean }>`
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: ${({ error }) => (error ? Colors.errorRed : Colors.grayLight)};
  display: flex;
  :focus-within {
    border-color: ${Colors.teal};
  }
`;

export const InputWithIcon = React.forwardRef(
  (
    {
      icon,
      error,
      focused,
      onClear,
      ...props
    }: {
      icon: any;
      error?: boolean;
      focused?: boolean;
      onClear?: () => void;
    } & React.InputHTMLAttributes<HTMLInputElement>,
    wrapRef: any,
  ) => {
    const ref = useRef<any>();
    useEffect(() => {
      if (focused) {
        ref?.current?.focus();
      }
    }, [focused]);
    return (
      <InputBorder error={error} ref={wrapRef} css={{ position: 'relative' }}>
        <div
          style={{
            padding: '16px',
          }}
        >
          <img src={icon} alt="" />
        </div>
        <NoBorderInput {...props} ref={ref} autoComplete="off" />
        {!!onClear && !!props.value && (
          <ClearButton
            onClick={() => {
              onClear();
            }}
          >
            <StaticClearIcon />
          </ClearButton>
        )}
      </InputBorder>
    );
  },
);

export const ErrorText = styled.div`
  color: ${Colors.errorRed};
  ${FONT_PRIMARY}
  font-size: 15px;
  line-height: 24px;
  text-align: left;
  margin-top: 8px;
`;

export const ResizingTextarea = styled(Input)`
  resize: none;
`.withComponent(TextareaAutosize);

export const Textarea = styled(Input)`
  height: 112px;
  resize: none;
`.withComponent('textarea');

export const Select = styled.select<{
  tabIndex?: number;
  error?: boolean;
  disabled?: boolean;
}>`
  font-size: 18px;
  line-height: 1.5;
  padding: 13px 30px 13px 16px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ error }) =>
    error ? Colors.errorRed : Colors.grayLighter};
  color: ${Colors.darkHarbor};
  border-radius: 4px;
  appearance: none;
  width: 100%;
  min-width: 200px;
  ${FONT_PRIMARY};
  background-color: ${({ disabled }) =>
    disabled ? Colors.grayLighter : Colors.white};

  &::-ms-expand {
    display: none;
  }
  &:focus {
    outline: none;
    border: 1px solid ${Colors.teal};
  }
  option {
    font-weight: normal;
  }
  @media (min-width: 420px) {
    min-width: 160px;
  }
`;
Select.defaultProps = { tabIndex: 0 };

export const SelectWrap = styled.div`
  display: flex;
  position: relative;
`;

export const Caret = styled.img`
  height: 18px;
  pointer-events: none;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  z-index: 5;
`;

export const SelectWithCaret = ({
  children,
  className,
  style,
}: {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <SelectWrap style={style} className={className}>
      {children}
      <Caret src={CaretDown} />
    </SelectWrap>
  );
};

export const NormalField: React.FC<
  {
    title?: string;
    name: string;
    fullWidth?: boolean;
  } & React.InputHTMLAttributes<HTMLInputElement>
> = ({ title, name, fullWidth, ...props }) => {
  const [field, meta] = useField(name);
  const { status } = useFormikContext();

  const hasError = meta.touched && !!meta.error;
  const isEditing = !status || status.editing;

  return (
    <InfoSection title={title} fullWidth={fullWidth} htmlFor={name}>
      {isEditing ? (
        <Fragment>
          <Input
            {...field}
            id={name}
            autoComplete="off"
            error={hasError}
            {...props}
          />
          <ErrorMessage name={field.name} component={ErrorText} />
        </Fragment>
      ) : (
        <CopyToClip text={field.value}>{field.value}</CopyToClip>
      )}
    </InfoSection>
  );
};

export const SearchInput: React.FC<
  {
    title: string;
    name: string;
    fullWidth?: boolean;
  } & React.InputHTMLAttributes<HTMLInputElement>
> = ({ title, name, fullWidth, ...props }) => {
  const [field] = useField(name);

  return (
    <InfoSection title={title} fullWidth={fullWidth}>
      <InputBorder>
        <div
          css={{
            padding: '16px',
            backgroundColor: props.disabled ? Colors.grayLighter : Colors.white,
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
          }}
        >
          <img src={SearchIcon} alt="" />
        </div>
        <NoBorderInput
          {...field}
          {...props}
          autoComplete="off"
          css={{
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          }}
        />
      </InputBorder>
    </InfoSection>
  );
};

export const ErrorField: React.FC<{ name: string }> = ({ name }) => {
  return <ErrorMessage name={name} component={ErrorText} />;
};

export const NonEmptyErrorField: React.FC<{ name: string }> = ({ name }) => {
  const [field, meta, helpers] = useField(name);
  const { submitCount } = useFormikContext();

  const error = meta.touched || submitCount > 0 ? meta.error : '\u00A0';

  return (
    <ErrorText>
      {error}
      {'\u00A0'}
    </ErrorText>
  );
};

export const PhoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const PhoneInput = ({
  value,
  onChange,
  onBlur,
  disabled = false,
  error = false,
  autoFocus = false,
  className,
  placeholder,
}: {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: boolean;
  autoFocus?: boolean;
  className?: string;
}) => {
  return (
    <MaskedInput
      mask={PhoneNumberMask}
      placeholder={placeholder || 'Enter mobile phone number'}
      value={value}
      autoFocus={autoFocus}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      onBlur={onBlur}
      render={(ref, props) => {
        return (
          <Input
            ref={ref as LegacyRef<HTMLInputElement>}
            {...props}
            className={className}
            disabled={disabled}
            error={error}
            data-testid="phoneNumber"
            id="phoneNumber"
            autoComplete="off"
          />
        );
      }}
    />
  );
};

interface MoneyInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

export const MoneyInput = ({
  value,
  onChange,
  onBlur,
  disabled = false,
  error = false,
  autoFocus = false,
}: MoneyInputProps) => (
  <MaskedInput
    mask={numberMask}
    placeholder="$"
    value={value}
    autoFocus={autoFocus}
    onChange={onChange}
    onBlur={onBlur}
    render={(ref, props) => {
      return (
        <div css={{ position: 'relative', width: '100%' }}>
          <Input
            ref={ref as LegacyRef<HTMLInputElement>}
            {...props}
            disabled={disabled}
            error={error}
            autoComplete="off"
            css={{ width: '100%' }}
          />
          <PrimaryText css={{ position: 'absolute', bottom: 19, right: 17 }}>
            USD
          </PrimaryText>
        </div>
      );
    }}
  />
);

export const MoneyInputField: React.FC<
  {
    name: string;
    title: string;
  } & MoneyInputProps
> = ({ className, name, title, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const hasError = !!meta.error && !!meta.touched;

  return (
    <InfoSection title={title} fullWidth className={className}>
      <MoneyInput
        {...field}
        onChange={(e) => {
          helpers.setValue(e.target.value.replace(/[^0-9\.]/, ''));
        }}
        onBlur={() => {
          helpers.setTouched(true);
        }}
        error={hasError}
        {...props}
      />
      <ErrorMessage name={field.name} component={ErrorText} />
    </InfoSection>
  );
};

const Check = styled.div<{ checked: boolean }>`
  height: 20px;
  width: 20px;
  background: ${({ checked }) => (checked ? Colors.teal : 'transparent')};
  border: ${({ checked }) =>
    checked ? '1px solid transparent' : `1px solid ${Colors.gray}`};
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
`;

const Checked = styled.img`
  height: 20px;
  position: absolute;
  width: 20px;
  left: -1px;
  top: -1px;
`;

const HiddenCheckbox = styled.input`
  position: absolute;
  left: 1px;
  right: 0;
  bottom: 0;
  top: -1px;
  width: 100%;
  margin: 0;
  height: 100%;
  padding: 0;
  opacity: 0;
  cursor: pointer;
`;

export const CheckboxLabel = styled.span`
  margin-left: 10px;
  color: ${Colors.darkHarbor};
  ${FONT_PRIMARY};
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
`;

export const CheckboxWrap = styled.div`
  display: flex;
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const Checkbox: React.FC<{
  checked: boolean;
  name?: string;
  value?: string;
  onChange?: any;
  onBlur?: any;
}> = ({ value, checked, ...props }) => {
  return (
    <Check checked={checked} onClick={props.onChange} onBlur={props.onBlur}>
      <HiddenCheckbox
        type="checkbox"
        {...props}
        checked={checked}
        readOnly={!props.onChange}
        value={value}
      />
      {checked && <Checked src={radioChecked} />}
    </Check>
  );
};

const RadioOutline = styled.div({
  borderColor: Colors.darkHarbor,
  borderRadius: 12,
  borderStyle: 'solid',
  borderWidth: 2,
  cursor: 'pointer',
  height: 24,
  overflow: 'hidden',
  position: 'relative',
  width: 24,
});

const RadioChecked = styled.div({
  backgroundColor: Colors.darkHarbor,
  borderRadius: 7,
  height: 14,
  left: 3,
  position: 'absolute',
  top: 3,
  width: 14,
});

export const Radio: React.FC<{
  checked: boolean;
  name?: string;
  value?: string;
  onChange?: any;
  onBlur?: any;
}> = ({ value, checked, ...props }) => {
  return (
    <RadioOutline
      onClick={props.onChange}
      onBlur={props.onBlur}
      css={{ borderRadius: 12, height: 24, width: 24 }}
    >
      <HiddenCheckbox
        type="radio"
        {...props}
        checked={checked}
        readOnly={!props.onChange}
        value={value}
      />
      {checked && <RadioChecked />}
    </RadioOutline>
  );
};

export const DateOfBirthField: React.FC<
  { name: string } & React.InputHTMLAttributes<HTMLInputElement>
> = ({ name, ...inputProps }) => {
  const [field, meta] = useField(name);
  const hasError = meta.touched && !!meta.error;

  return (
    <Fragment>
      <MaskedInput
        mask={DATE_MASK}
        pipe={autoCorrectedDatePipe}
        placeholder="mm/dd/yyyy"
        keepCharPositions
        guide
        {...field}
        render={(ref: any, props: any) => {
          return (
            <Input
              id={name}
              ref={ref}
              {...props}
              autoComplete="off"
              error={hasError}
              {...inputProps}
            />
          );
        }}
      />
      <ErrorMessage component={ErrorText} name={field.name} />
    </Fragment>
  );
};

export const CopyToClipField: React.FC<{
  name: string;
  transform?: (value: any) => any;
}> = ({ name, transform = identity }) => {
  const [field] = useField(name);
  return (
    <CopyToClip text={transform(field.value)}>
      {transform(field.value)}
    </CopyToClip>
  );
};

export const FullAddressLookupField: React.FC<{
  name: string;
  onChange: (value?: any) => void;
}> = ({ name, onChange }) => {
  const [field, meta] = useField(name);
  return (
    <FullPlaceSearch {...field} error={!!meta.error} onChange={onChange} />
  );
};

export const AddressLookupField: React.FC<{ name: string }> = ({ name }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <AddressSearchInput
      {...field}
      name={name}
      error={!!meta.error}
      onChange={(value?: Location) => {
        helpers.setValue(value);
      }}
    />
  );
};

export const AddressCoordinatesField: React.FC<{ name: string }> = ({
  name,
}) => {
  const [field, meta, helpers] = useField(name);
  const hasError = meta.touched && !!meta.error;
  const { setFieldValue } = useFormikContext();
  return (
    <AddressSearchInput
      {...field}
      error={hasError}
      onChange={(value?: Location) => {
        helpers.setValue(value?.formatted_address);
        setFieldValue('latitude', value?.latitude);
        setFieldValue('longitude', value?.longitude);
      }}
    />
  );
};

export const PlaceSearchField: React.FC<{
  name: string;
  placeTypes: PlaceType[];
  location?: { latitude: string; longitude: string } | undefined;
}> = ({ name, placeTypes, location }) => {
  const [field, meta, helpers] = useField(name);
  const defaultLocation = { latitude: '45.5470119', longitude: '-122.5916175' };

  return (
    <PlaceSearchInput
      {...field}
      error={!!meta.error}
      placeTypes={placeTypes}
      location={location || defaultLocation}
      onChange={(value?: Location) => {
        helpers.setValue(value);
      }}
    />
  );
};

export const TextAreaField: React.FC<{ name: string }> = ({ name }) => {
  const [field, meta] = useField(name);
  return (
    <Textarea id={name} {...field} error={!!meta.error && !!meta.touched} />
  );
};

export const ResizingTextAreaField = ({ name, ...props }: any) => {
  const [field, meta] = useField(name);
  return (
    <ResizingTextarea
      id={name}
      {...field}
      {...props}
      error={!!meta.error && !!meta.touched}
    />
  );
};

export const SelectSimpleField: React.FC<
  {
    name: string;
    children?: React.ReactNode;
  } & React.InputHTMLAttributes<HTMLSelectElement>
> = ({ name, children, ...props }) => {
  const [field, meta] = useField(name);
  const hasError = meta.touched && !!meta.error;

  return (
    <SelectWithCaret>
      <Select {...field} error={hasError} id={name} {...props}>
        {children}
      </Select>
    </SelectWithCaret>
  );
};

export const SelectSimpleSearchField: React.FC<
  {
    name: string;
    icon: string;
    options: Value[];
  } & React.InputHTMLAttributes<HTMLSelectElement>
> = ({ name, options, placeholder, icon }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <BasicSearchInput
      value={field.value}
      onChange={(v) => {
        helpers.setValue(v || '');
      }}
      error={!!meta.error}
      options={options}
      placeholder={placeholder}
      icon={icon}
    />
  );
};

export const PhoneNumberField: React.FC<
  {
    name: string;
  } & React.InputHTMLAttributes<HTMLSelectElement>
> = ({ name, disabled, className, placeholder }) => {
  const [field, meta, helpers] = useField(name);
  const hasError = !!meta.error && !!meta.touched;

  return (
    <Fragment>
      <PhoneInput
        {...field}
        onChange={(value) => {
          helpers.setValue(value);
        }}
        onBlur={() => {
          helpers.setTouched(true);
        }}
        placeholder={placeholder}
        disabled={disabled}
        error={hasError}
        className={className}
      />
      <ErrorMessage component={ErrorText} name={field.name} />
    </Fragment>
  );
};
