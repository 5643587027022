import { useGetPatientChargesQuery } from '@bc/codegen/medical';
import React from 'react';
import { Charge } from '../../components/Billing';
import { ContentRow, SectionContent } from '../../components/layout';
import { PrimaryText, SectionTitle } from '../../components/text';

interface Props {
  patientId: string;
}

const DisplayPatientCharges: React.FC<Props> = ({ patientId }) => {
  const { data } = useGetPatientChargesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        patientId: {
          _eq: patientId,
        },
      },
    },
  });

  const charges = data?.charges ?? [];

  return (
    <SectionContent>
      <SectionTitle>Charges</SectionTitle>
      {charges?.length > 0 ? (
        charges.map((charge) => {
          const staffName = charge.medicalAssistantId?.replace(
            'google-apps|',
            '',
          );
          return (
            <Charge charge={charge} key={charge.id} staffName={staffName} />
          );
        })
      ) : (
        <ContentRow>
          <PrimaryText>No charges</PrimaryText>
        </ContentRow>
      )}
    </SectionContent>
  );
};

export default DisplayPatientCharges;
