/** @jsx jsx */
import { useLoadStaffAndServicesQuery } from '@bc/codegen/manager';
import { Colors } from '@bc/theme';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { hasRole } from '../../auth';
import { CloseButton } from '../../components/button';
import { HeaderEdit } from '../../components/layout';
import Loader from '../../components/Loader/loader';
import { useAuth0 } from '../../react-auth0';
import { FONT_PRIMARY, IF_TABLET } from '../../styles';
import { Content } from '../ViewAppointment.styles';
import { normalizedStaffInfo } from './helpers';

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -20px;
  right: -20px;
`;

const headerStyle = css`
  display: flex;
  align-items: baseline;
  padding: 0 0 24px;
  margin: 0 0 24px;
`;

const Avatar = styled.img`
  display: flex;
  align-self: center;
  height: 40px;
  border-radius: 20px;
  width: auto;
  margin-right: 16px;
`;

const ContentBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Info = styled.div`
  width: 100%;

  ${IF_TABLET} {
    width: 50%;
  }
`;

const textStyles = css`
  ${FONT_PRIMARY}
  color: ${Colors.darkHarbor};
  font-size: 16px;
  line-height: 19px;
`;

const Title = styled.h2`
  ${textStyles}
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  margin-right: auto;
`;

const Label = styled.label`
  ${textStyles}
  display: block;
  font-weight: bold;
  margin: 0 0 8px;
  padding: 0;
`;

const Text = styled.p`
  ${textStyles}
  font-weight: normal;
  margin: 0 0 24px;
  padding: 0;
`;

const StaffView: React.FC<RouteComponentProps<{ staffId: string }>> = ({
  history,
  match,
}) => {
  const { staffId } = match.params;
  const { accessToken } = useAuth0();
  const isManager = hasRole('manager', accessToken);

  const { data } = useLoadStaffAndServicesQuery({
    variables: {
      where: {
        id: {
          _eq: staffId,
        },
      },
    },
  });

  if (!data?.staff) return <Loader />;

  const staff = normalizedStaffInfo(data?.staff[0]);

  const {
    bio,
    email,
    firstName,
    formalFullName,
    fullState,
    informalFullName,
    languagesSpoken,
    lastName,
    phoneNumber,
    services,
    timeZone,
    type,
    avatarUrl,
  } = staff;

  return (
    <Content aria-label="View Staff">
      <div css={{ padding: 40 }}>
        <CloseButtonContainer>
          <CloseButton onClick={() => history.push('/staff/view')} />
        </CloseButtonContainer>
        <HeaderEdit
          css={headerStyle}
          editable={isManager}
          onEdit={() => history.push(`/staff/edit/${staffId}`)}
        >
          <Avatar
            src={avatarUrl || 'https://bravecare.imgix.net/staff/empty.png'}
            alt={`Brave Care Provider: ${formalFullName}`}
          />
          <Title>{informalFullName}</Title>
        </HeaderEdit>
        <ContentBody>
          <Info>
            <Label>First name</Label>
            <Text>{firstName}</Text>
          </Info>
          <Info>
            <Label>Last name</Label>
            <Text>{lastName}</Text>
          </Info>
          <Info>
            <Label>Email address</Label>
            <Text>{email}</Text>
          </Info>
          <Info>
            <Label>Phone number</Label>
            <Text>{phoneNumber}</Text>
          </Info>
          <Info>
            <Label>State of residence</Label>
            <Text>{fullState}</Text>
          </Info>
          <Info>
            <Label>Time zone</Label>
            <Text>{timeZone}</Text>
          </Info>
          <Info>
            <Label>Staff type</Label>
            <Text>{type}</Text>
          </Info>
          <Info>
            <Label>Languages spoken</Label>
            <Text>{languagesSpoken}</Text>
          </Info>
          <Info style={{ width: '100%' }}>
            <Label>Services provided</Label>
            <Text>{services}</Text>
          </Info>
          {!!bio && (
            <Info style={{ width: '100%' }}>
              <Label>Bio</Label>
              <Text style={{ marginBottom: 0 }}>{bio}</Text>
            </Info>
          )}
        </ContentBody>
      </div>
    </Content>
  );
};

export default StaffView;
