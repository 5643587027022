import {
  useGetClinicQuery,
  useLoadStaffScheduleQuery,
} from '@bc/codegen/manager';
import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import startCase from 'lodash/startCase';
import { DateTime } from 'luxon';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { hasRole } from '../../auth';
import { CloseButton, TextButton } from '../../components/button';
import Loader from '../../components/Loader/loader';
import { PrimaryText } from '../../components/text';
import { useAuth0 } from '../../react-auth0';
import { Content } from '../ViewAppointment.styles';
import { PencilIcon } from './styles';

const Avatar = styled.img`
  display: flex;
  align-self: flex-start;
  height: 40px;
  border-radius: 20px;
  width: auto;
  margin-right: 16px;
`;

const ContentBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Info = styled.div`
  width: 100%;
`;

const Title = styled(PrimaryText)`
  color: ${Colors.darkHarbor};
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-right: auto;
`;

const Label = styled(PrimaryText)`
  color: ${Colors.darkHarbor};
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin: 0 0 8px;
  padding: 0;
`;

const Text = styled(Label)`
  font-weight: normal;
  margin: 0 0 24px;
`;

const ScheduleView: React.FC<
  RouteComponentProps<{
    staffScheduleId: string;
  }>
> = ({ history, match }) => {
  const { staffScheduleId } = match.params;
  const { accessToken } = useAuth0();
  const isManager = hasRole('manager', accessToken);
  const context = { role: isManager ? 'manager' : 'assistant' };
  const { data } = useLoadStaffScheduleQuery({
    skip: !staffScheduleId,
    variables: { where: { id: { _eq: staffScheduleId } } },
    context,
  });
  const schedule = data?.staff_schedule[0];
  const staff = schedule?.staff;
  const { data: clinicData } = useGetClinicQuery({
    skip: !schedule?.clinicId,
    variables: { where: { id: { _eq: schedule?.clinicId } } },
    context,
  });
  const clinic = clinicData?.clinics[0];
  const servicesProvided = schedule?.staff_schedule_services
    .map((s) => startCase(s.service.replace(/_/g, ' ').toLowerCase()))
    .join(', ');

  if (!schedule || !clinic) return <Loader />;

  return (
    <Content
      aria-label="View Schedule"
      style={{ maxWidth: 424, padding: 24, marginTop: 120 }}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {isManager && (
          <TextButton
            onClick={() => history.push(`/staff/schedule/edit/${schedule.id}`)}
            style={{ marginRight: 8 }}
          >
            <PencilIcon />
          </TextButton>
        )}
        <CloseButton onClick={() => history.push('/staff/schedule')} />
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <Avatar
          src={
            staff?.avatarUrl || 'https://bravecare.imgix.net/staff/empty.png'
          }
          alt={`${staff?.firstName} ${staff?.lastName}`}
        />
        <div>
          <Title>{`${staff?.firstName} ${staff?.lastName}`}</Title>
          <Text style={{ margin: 0, marginTop: 8 }}>
            {DateTime.fromISO(schedule.startDateTime, {
              zone: clinic.timeZone!,
              setZone: true,
            })
              .setZone(staff?.timeZone || clinic.timeZone!)
              .toFormat('EEEE, MMMM d • h:mm a ZZZZ')
              .replace(':00', '')}{' '}
            -{' '}
            {DateTime.fromISO(schedule.endDateTime, {
              zone: clinic.timeZone!,
              setZone: true,
            })
              .setZone(staff?.timeZone || clinic.timeZone!)
              .toFormat('h:mm a ZZZZ')
              .replace(':00', '')}
          </Text>
          <ContentBody style={{ marginTop: 40 }}>
            <Info>
              <Label>Clinic</Label>
              <Text>{clinic.name}</Text>
            </Info>
            <Info>
              <Label>Services Provided</Label>
              <Text>{servicesProvided}</Text>
            </Info>
          </ContentBody>
        </div>
      </div>
    </Content>
  );
};

export default ScheduleView;
