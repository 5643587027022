/** @jsx jsx */
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React, { Fragment } from 'react';
import Pin from '../../assets/pin.svg';
import { PronounBadge } from '../../components/Badge';
import { AccountAvatar, PatientAvatar } from '../../components/ChatMessage';
import { PrimaryText } from '../../components/text';
import { useGetLatestAppointments } from '../../data/useGetLatestAppointments/useGetLatestAppointments';
import { useGetPatientInfo } from '../../data/useGetPatientInfo/useGetPatientInfo';

const Container = styled.div({
  background: Colors.white,
  border: `1px solid ${Colors.grayLight}`,
  borderRadius: 16,
  marginTop: 40,
  padding: 24,
});

const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
});

const Text = styled(PrimaryText)({
  fontSize: 14,
  lineHeight: 1.285,
});

const Title = styled(PrimaryText)({
  fontSize: 16,
  fontWeight: 'bold',
  lineHeight: 1.375,
}).withComponent('h3');

const Line = styled.div({
  background: Colors.grayLight,
  height: 1,
  margin: '16px 0',
  width: '100%',
});

export const PatientSidebar = ({
  children,
  className,
  patientId,
}: {
  children?: React.ReactNode;
  className?: string;
  patientId?: string;
}) => {
  const {
    accounts,
    address,
    formattedDateofBirth,
    patientTitle,
    preferredPronoun,
    preferredPronounType,
  } = useGetPatientInfo({ patientId });

  const { visits } = useGetLatestAppointments({ patientId });

  if (!patientId) return null;

  return (
    <Container id="patient-sidebar" className={className}>
      <Row>
        <PatientAvatar patientId={patientId} size={64} />
        <div style={{ paddingLeft: 16 }}>
          <Title>{patientTitle}</Title>
          {formattedDateofBirth && (
            <div css={{ marginBottom: 5 }}>
              <Text>{formattedDateofBirth}</Text>
            </div>
          )}
          {preferredPronounType && (
            <PronounBadge
              preferredPronounType={preferredPronounType}
              preferredPronoun={preferredPronoun}
            />
          )}
        </div>
      </Row>
      <Title css={{ marginTop: 24 }}>Address</Title>
      <Line />
      <Row>
        <img src={Pin} alt="" />
        <Text css={{ paddingLeft: 8 }}>{address}</Text>
      </Row>
      <Title css={{ marginTop: 24 }}>Caregivers</Title>
      <Line />
      {accounts.length === 0 ? (
        <Row css={{ marginBottom: 16, marginTop: 8 }}>
          <Text css={{ color: Colors.gray }}>
            No caregivers linked to this patient
          </Text>
        </Row>
      ) : (
        accounts.map((a, index) => (
          <Row
            key={a.accountId}
            css={{ alignItems: 'center', paddingTop: index > 0 ? 16 : 0 }}
          >
            <AccountAvatar accountId={a.accountId} size={24} />
            <Text css={{ paddingLeft: 8 }}>
              {a.name}
              {a.relationshipLabel ? ` (${a.relationshipLabel})` : ''}
            </Text>
          </Row>
        ))
      )}
      <Title css={{ marginTop: 24 }}>Past visits</Title>
      <Line />
      {visits.length === 0 ? (
        <Row css={{ marginBottom: 16, marginTop: 8 }}>
          <Text css={{ color: Colors.gray }}>No previous visits</Text>
        </Row>
      ) : (
        visits.map((v) => (
          <Fragment key={v.id}>
            <Row>
              <Text css={{ fontWeight: 500 }}>{v.formattedDate}</Text>
              <Text>&nbsp;for&nbsp;</Text>
              <Text css={{ fontWeight: 500 }}>{v.title}</Text>
            </Row>
            <Row css={{ marginBottom: 16, marginTop: 8 }}>
              <Text css={{ color: Colors.gray }}>{v.description}</Text>
            </Row>
          </Fragment>
        ))
      )}
      {children}
    </Container>
  );
};
