import React from "react";
import styled from "@emotion/styled";
import map from "lodash/map";
import find from "lodash/find";
import { DateTime } from "luxon";
import numeral from "numeral";
//@ts-ignore
import { compile } from "handlebars/dist/handlebars";
import { QuestionType, TAnswerValue, TAnswers, Question } from "./interfaces";
import { friendlyAge, friendlyDuration } from "./helpers";
import LineSvg from "./line";
import { FONT_PRIMARY } from "../../styles";

const Container = styled.div`
  padding: 0 8px;
  ${FONT_PRIMARY};
`;

const Issue = styled.div`
  margin: 0;
`;

const Circle = styled.div<{ color?: string }>`
  height: 8px;
  width: 8px;
  background-color: ${({ color }) => (color ? color : "#a0e4d7")};
  border-radius: 4px;
  margin: auto 16px auto 0;
`;

const Line = styled(LineSvg)`
  width: 8px;
  margin: 0;
  margin-right: 16px;
  align-self: stretch;
`;

const BlankLine = styled.div`
  width: 8px;
  margin: 0;
  margin-right: 16px;
`;

const Item = styled.div`
  display: flex;
  align-items: stretch;
  margin: 0;
`;

const Label = styled.div`
  font-size: 14px;
  letter-spacing: 0.28px;
  font-weight: bold;
  padding: 0;
  &:first-letter {
    text-transform: capitalize;
  }
`;

const Value = styled.div`
  font-size: 14px;
  letter-spacing: 0.28px;
  font-weight: normal;
  padding: 4px 0;
  padding-bottom: 16px;
`;

interface IIssue {
  label: string;
  value: TAnswerValue;
  type: QuestionType;
}

interface Props {
  answers: TAnswers;
  questions: Question[];
  color?: string;
}

const formatValue = ({ type, value }: IIssue) => {
  switch (type) {
    case QuestionType.duration:
      return friendlyDuration(parseInt(String(value), 10));

    case QuestionType.number:
      return numeral(value).format();

    case QuestionType.dob:
      return friendlyAge(DateTime.fromISO(String(value)));

    case QuestionType.degrees:
      return numeral(value).format("0[.]0") + "℉";

    default:
      return value;
  }
};

const Issues: React.FC<Props> = ({ answers, questions, color }) => {
  let issues = map(answers, (answer, key) => {
    if (key === "name" || key === "age") {
      return null;
    }
    const q = find(questions, i => i.variable === key || i.id === key);
    if (!q) return null;
    return {
      type: q.type,
      label: q.summaryName || "",
      value: answer
    };
  });

  // omit any `none` values because `none of the above` isn't helpful in the summary
  // issues = reject(
  //   issues,
  //   i => String(i ? i.value : "").toLowerCase() === "none"
  // );

  return (
    <Container>
      {map(issues, (issue, index) => {
        if (!issue) return null;

        const template = compile(issue.label);

        return (
          <Issue key={issue.label}>
            <Item>
              <Circle color={color} />
              <Label>{template(answers)}</Label>
            </Item>
            <Item>
              {index < issues.length - 1 ? (
                <Line color={color || "#a0e4d7"} />
              ) : (
                <BlankLine />
              )}
              <Value>{formatValue(issue)}</Value>
            </Item>
          </Issue>
        );
      })}
    </Container>
  );
};

export default Issues;
