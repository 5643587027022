import {
  ClinicFragment,
  useGetClinicQuery,
  useUpdateClinicMutation,
} from '@bc/codegen/manager';
import { Form, Formik } from 'formik';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { object, string } from 'yup';
import { Button } from '../../components/button';
import { AddressCoordinatesField, NormalField } from '../../components/form';
import { ContentRow, InfoSection } from '../../components/layout';
import { LargeTitle } from '../../components/text';
import { Close, Content } from '../ViewAppointment.styles';

const ClinicValidation = object().shape({
  name: string().required('Required'),
  address: string().required('Required'),
});

const context = { role: 'manager' };

const EditEvent: React.FC<
  RouteComponentProps<{
    id: string;
  }>
> = ({ history, match }) => {
  const [updateClinic] = useUpdateClinicMutation({ context });

  const { data: clinicData, loading } = useGetClinicQuery({
    variables: {
      where: {
        id: {
          _eq: match?.params?.id,
        },
      },
    },
  });

  const clinic = clinicData?.clinics?.[0];

  const handleSubmit = async (values: Partial<ClinicFragment>) => {
    await updateClinic({
      variables: {
        id: match.params.id,
        clinic: {
          name: values.name,
          address: values.address,
          latitude: values?.latitude,
          longitude: values?.longitude,
        },
      },
    });
    history.push('/events');
  };
  return (
    <Content
      aria-label="Edit Event"
      style={{
        padding: '40px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <LargeTitle>Edit Event</LargeTitle>
        </div>
        <Close onClick={() => history.push('/events')} />
      </div>
      {!loading && (
        <Formik
          initialValues={{
            name: clinic?.name,
            clinicCode: clinic?.clinicCode,
            address: undefined,
          }}
          onSubmit={handleSubmit}
          initialStatus={{
            editing: true,
          }}
          validationSchema={ClinicValidation}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <ContentRow>
                  <NormalField title="Event Name" name="name" fullWidth />
                </ContentRow>
                <ContentRow>
                  <NormalField
                    title="Event Code"
                    name="clinicCode"
                    fullWidth
                    disabled
                  />
                </ContentRow>
                <ContentRow>
                  <InfoSection title="Address" fullWidth>
                    <AddressCoordinatesField name="address" />
                  </InfoSection>
                </ContentRow>

                <ContentRow>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    style={{
                      width: 'auto',
                    }}
                  >
                    Update
                  </Button>
                </ContentRow>
              </Form>
            );
          }}
        </Formik>
      )}
    </Content>
  );
};

export default EditEvent;
