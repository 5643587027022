/** @jsx jsx */
import { Order_By } from '@bc/codegen/medical';
import { Colors, Spacing } from '@bc/theme';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React from 'react';
import { Link } from 'react-router-dom';
import { ICONS } from '../../assets';
import { Button, TransparentButton } from '../../components/button';
import SortIcon from '../../components/Tasks/icons/sort_arrow.svg';
import { SectionTitle, ThTitle } from '../../components/Tasks/ui';
import { PrimaryText } from '../../components/text';
import {
  checkIfDayIsToday,
  checkIfTomorrow,
  checkIfYesterday,
} from '../../helpers';

export const ButtonLink = Button.withComponent(Link);

export const WrapContainer = styled.div({
  overflow: 'hidden',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
  position: 'relative',
});

export const overflow_form = css({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

export const fill_scroll = css({
  flex: '1',
  overflow: 'auto',
  padding: `${Spacing.xl}px 0`,
});

export const StickyBottomRow = styled.div({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: `${Spacing.s}px ${Spacing.xl}px`,
  borderTop: `1px solid ${Colors.grayLightest}`,
  boxShadow:
    '0px 0px 24px rgba(0, 0, 0, 0.06), 0px 0px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
});

export const pointer = css({
  cursor: 'pointer',
});

export const ShowMoreRow = ({
  onClick,
  toggled,
}: {
  onClick: () => void;
  toggled: boolean;
}) => (
  <tr>
    <td
      colSpan={8}
      css={{
        paddingTop: Spacing.xl / 2,
      }}
    >
      <button
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: '6px',
          background: 'none',
          border: 'none',
          cursor: 'pointer',
        }}
        onClick={onClick}
      >
        <img alt="" src={ICONS.midDots} />
        <PrimaryText
          css={{
            color: Colors.gray,
            fontSize: '14px',
          }}
        >
          {toggled ? 'Show Less' : 'Show More'}
        </PrimaryText>
      </button>
    </td>
  </tr>
);

export const RotateIcon = styled.img<{ up?: boolean }>(
  {
    width: Spacing.xs,
  },
  ({ up }) => ({
    transition: 'transform .25s ease',
    transform: up ? 'rotate(-180deg)' : 'rotate(0deg)',
  }),
);

const SortDirectionIcon = styled.img<{ up?: boolean }>(({ up }) => ({
  transition: 'transform .25s ease',
  transform: up ? 'rotate(-180deg)' : 'rotate(0deg)',
}));

export const NoTasks = ({ children }: { children: string }) => (
  <tr>
    <td
      colSpan={8}
      css={{
        padding: `${Spacing.s}px 0`,
      }}
    >
      <PrimaryText
        css={{
          color: Colors.gray,
        }}
      >
        {children}
      </PrimaryText>
    </td>
  </tr>
);

export const TitleSort = ({
  children,
  direction,
}: {
  children: React.ReactNode;
  direction?: Order_By | null;
}) => (
  <div
    css={{
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    }}
  >
    <ThTitle css={{ flex: 1 }}>{children}</ThTitle>
    {!!direction && (
      <SortDirectionIcon src={SortIcon} up={direction === 'desc'} />
    )}
  </div>
);

export const DueDateTime = ({
  dueDateTime,
  isCompleted,
  className,
}: {
  dueDateTime: string;
  isCompleted: boolean;
  className?: string;
}) => {
  const date = DateTime.fromISO(dueDateTime);
  const today = DateTime.local();

  let color = Colors.gray;
  let text = date.toRelative();

  if (!isCompleted) {
    if (checkIfDayIsToday(date)) {
      color = Colors.teal;
      text = 'Today';
    } else if (checkIfTomorrow(date)) {
      text = 'Tomorrow';
    } else if (checkIfYesterday(date)) {
      color = '#FF3C00';
      text = 'Yesterday';
    } else if (date < today) {
      color = '#FF3C00';
    }
  } else {
    text = date.toLocaleString(DateTime.DATE_MED);
  }

  return (
    <PrimaryText css={{ color }} className={className}>
      {text}
    </PrimaryText>
  );
};

export const SectionRow = ({
  children,
  onClick,
  toggled,
  isEmpty,
}: {
  children: React.ReactNode;
  onClick: () => void;
  toggled: boolean;
  isEmpty: boolean;
}) => (
  <tr>
    <td
      colSpan={8}
      css={{
        paddingTop: Spacing.xl,
        paddingBottom: Spacing.xs,
      }}
    >
      <div
        css={{
          paddingBottom: Spacing.xs,
          borderBottom: isEmpty ? `1px solid ${Colors.grayLighter}` : undefined,
        }}
      >
        <TransparentButton
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={onClick}
        >
          <RotateIcon src={ICONS.caretDownPlain} up={toggled} />
          <SectionTitle
            css={{
              cursor: 'pointer',
              marginLeft: Spacing.m / 2,
            }}
          >
            {children}
          </SectionTitle>
        </TransparentButton>
      </div>
    </td>
  </tr>
);
