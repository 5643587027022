/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from 'react-router-dom';
import { Content, LargeTitle, Close } from './ViewAppointment.styles';
import { SectionContent, ContentRow } from '../components/layout';
import { ListItem, OrderedList } from '../components/List';
import { SmallTitle } from '../components/text';
import appToOpen from '../assets/help/app-to-open.jpg';
import endGuidedAccess from '../assets/help/end-guided-access.jpg';
import enterPasscode from '../assets/help/enter-passcode.jpg';
import press3Times from '../assets/help/press-3-times.jpg';
import { Button } from '../components/button';

const HelpImg = styled.img`
  display: block;
  margin: auto;
  padding: 20px 40px 20px 0;
  width: 50%;
`;

const LinkButton = Button.withComponent('a');

const Help: React.FC<RouteComponentProps> = ({ history }) => {
  return (
    <Content aria-label="Help">
      <div>
        <div style={{ padding: '40px 40px 0 40px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              position: 'relative',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <LargeTitle
                style={{
                  marginBottom: '8px',
                }}
              >
                Help
              </LargeTitle>
            </div>

            <Close onClick={() => history.push('/dashboard')} />
          </div>
        </div>
        <SectionContent style={{ padding: '0 40px', marginTop: '20px' }}>
          <ContentRow style={{ marginTop: 0, flexDirection: 'column' }}>
            <SmallTitle
              // @ts-ignore
              as="h2"
            >
              Tech Support / Feature Requests
            </SmallTitle>
            <div
              css={{
                padding: '20px 0 0 0',
              }}
            >
              <LinkButton href="https://support.bravecare.com" target="_blank">
                Get Help
              </LinkButton>
            </div>
          </ContentRow>
        </SectionContent>
        <SectionContent style={{ paddingLeft: 40 }}>
          <ContentRow style={{ marginTop: 0 }}>
            <SmallTitle
              // @ts-ignore
              as="h2"
            >
              Patient Intake iPad App User Guide
            </SmallTitle>
            <OrderedList>
              <ListItem>
                <strong style={{ fontWeight: 'bold' }}>Always</strong> plug in
                to charge when not in use.
              </ListItem>
              <ListItem>
                In the event of crashing or freezing of the app the following
                protocol should be followed to restore the app to a working
                state:
                <OrderedList>
                  <ListItem>
                    Press the home button 3 times
                    <HelpImg
                      src={press3Times}
                      alt="Press the home button three times"
                    />
                  </ListItem>
                  <ListItem>
                    Enter the passcode to exit guided access mode
                    <HelpImg
                      src={enterPasscode}
                      alt="Enter the passcode to exit guided access mode"
                    />
                  </ListItem>
                  <ListItem>
                    Tap end to end guided access mode
                    <HelpImg
                      src={endGuidedAccess}
                      alt="Tap end to end guided access mode"
                    />
                  </ListItem>
                  <ListItem>
                    Press the home button twice and dismiss the app
                  </ListItem>
                  <ListItem>
                    Reopen the ‘Brave Care’ app
                    <HelpImg
                      src={appToOpen}
                      alt="Reopen the ‘Brave Care’ app"
                    />
                  </ListItem>
                  <ListItem>
                    Press the home button 3 times to re-enable guided access
                    mode
                    <HelpImg
                      src={press3Times}
                      alt="Press the home button three times to re-enable guided access mode"
                    />
                  </ListItem>
                </OrderedList>
              </ListItem>
              <ListItem>
                Should the app exit upon crashing the following protocol will
                restore it to a working state:
                <OrderedList>
                  <ListItem>
                    Reopen the ‘Brave Care’ app
                    <HelpImg
                      src={appToOpen}
                      alt="Reopen the ‘Brave Care’ app"
                    />
                  </ListItem>
                  <ListItem>
                    Press the home button 3 times to re-enable guided access
                    mode
                    <HelpImg
                      src={press3Times}
                      alt="Press the home button three times to re-enable guided access mode"
                    />
                  </ListItem>
                </OrderedList>
              </ListItem>
            </OrderedList>
          </ContentRow>
        </SectionContent>
      </div>
    </Content>
  );
};

export default Help;
