/** @jsx jsx */
import {
  useCreatePaymentMethodMutation,
  useGetPaymentMethodsByAccountQuery,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import React, { Fragment, useEffect, useState } from 'react';
import {
  CardElement,
  Elements,
  injectStripe,
  StripeProvider,
} from 'react-stripe-elements';
import { ICONS } from '../../assets';
import { LOGOS } from '../../assets/payment-method';
import { Button, TextButton } from '../button';
import { Checkbox, ErrorText, InputWrap, Radio } from '../form';
import { HeaderEdit } from '../layout';
import { Loader } from '../Loader';
import { PrimaryText } from '../text';

const PaymentMethod = styled(PrimaryText)<{ selected?: boolean }>(
  ({ selected }) => ({
    alignItems: 'center',
    border: selected
      ? `2px solid ${Colors.tealLighter}`
      : `1px solid ${Colors.grayLighter}`,
    borderRadius: 5,
    display: 'flex',
    padding: 16,
    marginBottom: 16,
  }),
).withComponent('div');

const PreferredBadge = styled(PrimaryText)({
  backgroundColor: Colors.tealLightest,
  borderRadius: 13,
  color: Colors.teal,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '18px',
  height: 24,
  padding: '2px 12px',
});

const CardElementWrap = styled.div({
  border: `1px solid ${Colors.grayLight}`,
  borderRadius: 4,
  padding: '16px',
});

const createOptions = () => ({
  style: {
    base: {
      color: Colors.darkHarbor,
      fontFamily: "'Greycliff', Helvetica, sans-serif",
      fontSize: '16px',
      fontWeight: '400',
      letterSpacing: '-0.2px',
      lineHeight: '1.2',
      textAlign: 'left',

      '::placeholder': {
        color: Colors.grayLight,
        lineHeight: '1.2',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
});

interface Props {
  accountId: string;
  onChange: ({
    id,
    last4,
    token,
  }: {
    id: string;
    last4?: string;
    token?: string;
  }) => void;
  stripe?: any;
  stripeCustomerId?: string;
  value?: string;
  defaultCardId?: string | null;
  paymentMethods: any[];
  refetch?: any;
}

const PaymentMethodSelector: React.FC<Props> = ({
  accountId,
  defaultCardId,
  onChange,
  paymentMethods,
  refetch,
  stripe,
  value,
}) => {
  const [addNew, setAddNew] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [addedCards, setAddedCards] = useState<any[]>([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (value) {
      const cards = paymentMethods;
      const card = cards.find((c) => c?.id === value) ?? undefined;
      if (card) {
        onChange({ id: card.id, last4: card.last4 });
      }
    }
  }, [onChange, paymentMethods, value]);

  const [createPaymentMethod, { loading }] = useCreatePaymentMethodMutation();

  const allPaymentMethods = [...paymentMethods, ...addedCards];

  const handleCreate = async () => {
    if (stripe) {
      try {
        let payload = await stripe.createToken();
        if (payload && payload.token) {
          setError('');
          const { token } = payload;
          if (saveCard) {
            await createPaymentMethod({
              variables: {
                accountId,
                source: token?.id,
              },
            });
          }
          refetch?.();

          if (token && token?.card) {
            if (saveCard) {
              onChange({ id: token.card.id, last4: token.card.last4 });
            } else {
              setAddedCards([
                ...addedCards,
                { ...token.card, token: token.id },
              ]);
              onChange({
                id: token.card.id,
                last4: token.card.last4,
                token: token.id,
              });
            }
          }

          setAddNew(false);
        } else {
          setError(
            payload?.error?.message || 'There was an error adding the card',
          );
          throw payload?.error;
        }
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
      }
    }
  };

  return (
    <div>
      <HeaderEdit
        title="Payment Method"
        editable={false}
        css={{ marginBottom: 16 }}
      >
        <TextButton
          css={{ alignItems: 'center', padding: 0 }}
          onClick={() => setAddNew(!addNew)}
        >
          {!addNew && (
            <img
              src={ICONS.addCard}
              role="presentation"
              css={{ marginRight: 4 }}
            />
          )}
          {addNew ? 'Cancel' : 'Add new card'}
        </TextButton>
      </HeaderEdit>
      {loading && <Loader />}
      {!loading &&
        !addNew &&
        allPaymentMethods?.map(({ id, brand, last4, token }: any) => {
          const isSelected = token ? value === token : value === id;
          return (
            <PaymentMethod key={id} selected={isSelected}>
              {/* @ts-ignore */}
              <img src={LOGOS[brand ?? 'Unknown']} />
              <div css={{ marginLeft: 16 }}>
                {brand} ending in {last4}
              </div>
              <div css={{ display: 'flex', marginLeft: 'auto' }}>
                {defaultCardId === id && (
                  <PreferredBadge css={{ marginRight: 16 }}>
                    Preferred Card
                  </PreferredBadge>
                )}
                <Radio
                  checked={isSelected}
                  name="paymentMethod"
                  value={value || ''}
                  onChange={() => {
                    onChange({ id, last4, token });
                  }}
                />
              </div>
            </PaymentMethod>
          );
        })}
      {addNew && !loading && (
        <Fragment>
          <InputWrap>
            <CardElementWrap>
              <CardElement {...createOptions()} />
            </CardElementWrap>
            {error && <ErrorText>{error}</ErrorText>}
          </InputWrap>
          <InputWrap
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Checkbox
              name="saveCard"
              value={`${saveCard}`}
              checked={saveCard}
              onChange={() => {
                setSaveCard(!saveCard);
              }}
              onBlur={() => {}}
            />
            <PrimaryText
              css={{ marginLeft: '10px', cursor: 'pointer' }}
              onClick={() => {
                setSaveCard(!saveCard);
              }}
            >
              Save card to account for future use?
            </PrimaryText>
          </InputWrap>
          <InputWrap>
            <Button
              css={{ fontWeight: 500 }}
              disabled={loading}
              onClick={handleCreate}
              type="button"
            >
              {saveCard ? 'Save and Use Card' : 'Use Card'}
            </Button>
          </InputWrap>
        </Fragment>
      )}
    </div>
  );
};

const PaymentMethodSelectorWithStripe = injectStripe(PaymentMethodSelector);

export const AddNewPayment: React.FC<Omit<Props, 'paymentMethods'>> = ({
  accountId,
  stripe,
  ...props
}) => {
  const {
    data: paymentMethodData,
    loading,
    refetch,
  } = useGetPaymentMethodsByAccountQuery({
    variables: {
      accountId,
    },
  });
  const paymentMethods = paymentMethodData?.StripePaymentMethods ?? [];

  if (loading) return null;

  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!}>
      <Elements fonts={[{ cssSrc: 'https://bravecare.com/global.css' }]}>
        <PaymentMethodSelectorWithStripe
          {...props}
          accountId={accountId}
          paymentMethods={paymentMethods}
          refetch={refetch}
        />
      </Elements>
    </StripeProvider>
  );
};
