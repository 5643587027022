/** @jsx jsx */
import {
  AppointmentCountsForDaysQuery,
  ClinicFragment,
  LoadStaffScheduleQuery,
} from '@bc/codegen/medical';
import { Colors, Spacing } from '@bc/theme';
import { jsx } from '@emotion/core';
import { sortBy, sumBy } from 'lodash';
import { DateTime } from 'luxon';
import { lighten } from 'polished';
import { Fragment } from 'react';
import { Badge } from '../Badge/Badge';
import { PrimaryText } from '../text';
import { isSameDate, StaffSchedule } from './helper';
import { Day, DayItem, DayItems, DayOfMonth, Week } from './styles';
import { WeekdayLabels } from './WeekdayLabels';

type Schedules = LoadStaffScheduleQuery['staff_schedule'];
type AppointmentCount = AppointmentCountsForDaysQuery['appointment_counts'];

interface WeekViewProps {
  date: Date;
  staffSchedules?: Schedules;
  onSelect?: (staffSchedule: StaffSchedule) => void;
  appointmentCounts: AppointmentCount;
  clinics: ClinicFragment[];
}

export const WeekView = ({
  date,
  staffSchedules,
  onSelect,
  appointmentCounts,
  clinics,
}: WeekViewProps) => {
  const currentDate = DateTime.fromJSDate(date);
  // set start to sunday
  const currentStartOfWeek =
    currentDate.weekday === 7 ? currentDate : currentDate.set({ weekday: 0 });
  const days = Array.from(Array(7).keys());

  return (
    <Fragment>
      <WeekdayLabels highlightedDate={currentDate} />
      <Week>
        {days.map((d) => {
          const day = currentStartOfWeek.plus({ day: d });
          const dayIsSame = isSameDate(currentDate, day);
          return (
            <Day key={`dayoftheweek-${day}`}>
              <DayOfMonth highlighted={dayIsSame}>{day.day}</DayOfMonth>
            </Day>
          );
        })}
      </Week>
      {clinics.map((clinic) => {
        const clinicSchedule = sortBy(
          staffSchedules?.filter(({ clinicId }) => {
            return clinic.id === clinicId;
          }),
          (schedule) => {
            const services = schedule.staff_schedule_services.map(
              ({ service }) => {
                return service;
              },
            );

            const isOnCall =
              services.includes('ONCALL') || services.includes('XRAY_ONCALL');

            return isOnCall;
          },
        );

        // filter appointment counts for each day, but also total
        const dayCounts = appointmentCounts
          ?.filter(({ clinicId }) => {
            return clinicId === clinic.id;
          })
          .map(({ date, count }) => {
            return {
              count,
              date: DateTime.fromISO(date!),
            };
          });

        const weekCount = sumBy(dayCounts, ({ count }) => count ?? 0);

        return (
          <Fragment>
            <div
              style={{
                position: 'relative',
                padding: `${Spacing.m}px 0`,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <div
                style={{
                  borderTopWidth: 1,
                  borderStyle: 'dashed',
                  borderTopColor: Colors.grayLight,
                  position: 'absolute',
                  left: 0,
                  right: 0,
                }}
              />
              <div
                style={{
                  backgroundColor: '#FFF',
                  flexDirection: 'row',
                  display: 'flex',
                  zIndex: 5,
                  padding: `0 ${Spacing.s}px`,
                }}
              >
                <PrimaryText style={{ marginRight: Spacing.s }}>
                  {clinic?.name}
                </PrimaryText>
                <Badge>
                  {weekCount} {weekCount !== 1 ? 'visits' : 'visit'}
                </Badge>
              </div>
            </div>
            <Week>
              {days.map((d) => {
                const day = currentStartOfWeek.plus({ day: d });
                const dayItems = clinicSchedule?.filter((staffSchedule) =>
                  DateTime.fromISO(staffSchedule.startDateTime).hasSame(
                    day,
                    'day',
                  ),
                );

                const countItem = dayCounts?.find(({ date }) => {
                  return date.hasSame(day, 'day');
                });

                const visitCount = countItem?.count ?? 0;

                return (
                  <Day key={`dayoftheweek-${day}`}>
                    <Badge
                      css={{
                        backgroundColor: Colors.tealLighter,
                        color: Colors.teal,
                        marginBottom: Spacing.s,
                      }}
                    >
                      {visitCount} {visitCount !== 1 ? 'visits' : 'visit'}
                    </Badge>
                    <DayItems noBorder={d === 6}>
                      {dayItems?.map((item, index) => {
                        const clinic = item.clinic;

                        const startTime = DateTime.fromISO(item.startDateTime, {
                          zone: clinic?.timeZone!,
                          setZone: true,
                        }).setZone(item.staff?.timeZone || clinic?.timeZone!);
                        const endTime = DateTime.fromISO(item.endDateTime, {
                          zone: clinic?.timeZone!,
                          setZone: true,
                        }).setZone(item.staff?.timeZone || clinic?.timeZone!);

                        const services = item.staff_schedule_services.map(
                          ({ service }) => {
                            return service;
                          },
                        );

                        const isOnCall =
                          services.includes('ONCALL') ||
                          services.includes('XRAY_ONCALL');

                        const displayColor = isOnCall
                          ? Colors.grayLighter
                          : lighten(0.35, item?.staff?.color! ?? Colors.teal);

                        return (
                          <DayItem
                            key={`dayitem-${index}`}
                            color={displayColor ?? Colors.teal}
                            name={`${item.staff.firstName} ${item.staff.lastName}`}
                            time={`${startTime
                              .toFormat('h:mm a ZZZZ')
                              .replace(':00', '')} - ${endTime
                              .toFormat('h:mm a ZZZZ')
                              .replace(':00', '')}`}
                            onClick={() => onSelect?.(item)}
                          />
                        );
                      })}
                    </DayItems>
                  </Day>
                );
              })}
            </Week>
          </Fragment>
        );
      })}
    </Fragment>
  );
};
