/** @jsx jsx */
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { DateTime, Interval } from 'luxon';
import React from 'react';
import AddIcon from '../../assets/add_grey.svg';
import { PrimaryText } from '../text';

export const ROW_HEIGHT = 140;
const NBSP = '\u00A0';

export const RowLayout = styled.div<{ isFirst?: boolean }>(({ isFirst }) => ({
  height: `${ROW_HEIGHT}px`,
  position: 'relative',
  paddingLeft: '20px',
  marginLeft: '70px',
  borderBottom: `1px solid ${Colors.grayLighter}`,
  ...(isFirst && { borderTop: `1px solid ${Colors.grayLighter}` }),
}));

export const HoverSlot0 = styled(PrimaryText)({
  position: 'absolute',
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  height: '50%',
  cursor: 'pointer',
  opacity: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: Colors.gray,
  ':hover': {
    border: `1px dashed ${Colors.grayLight}`,
    opacity: 1,
    backgroundColor: Colors.grayLightest,
  },
});

export const HoverSlot30 = styled(HoverSlot0)({
  top: '50%',
});

export const NewVisit = () => {
  return (
    <PrimaryText
      css={{
        color: Colors.gray,
        fontWeight: 600,
      }}
    >
      New Visit
    </PrimaryText>
  );
};

export const PlusIcon = () => (
  <img
    src={AddIcon}
    alt="+"
    css={{
      marginRight: '8px',
    }}
  />
);

export const EmptySlotDisplay: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <PlusIcon />
      <NewVisit />
      {NBSP}
      {children}
    </div>
  );
};

export const HoverCondensedAddButton = ({
  onClick,
}: {
  onClick: () => void;
}) => (
  <div
    css={{
      left: -44,
      position: 'relative',
      border: `1px dashed ${Colors.grayLight}`,
      backgroundColor: Colors.grayLightest,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 20,
      height: 70,
      opacity: 0,
      cursor: 'pointer',
      [':hover']: {
        opacity: 1,
      },
    }}
    onClick={onClick}
  >
    <img src={AddIcon} alt="+" style={{ width: 16, height: 16 }} />
  </div>
);

export const RowTime = styled(PrimaryText)({
  position: 'absolute',
  right: '100%',
  top: 0,
  transform: 'translateY(-50%)',
  width: '70px',
  color: Colors.gray,
  cursor: 'pointer',
  ':hover': {
    color: Colors.darkHarbor,
  },
});

export const EndRowTime = styled(RowTime)({
  top: '100%',
});

export const RowSlot = ({
  isFirst,
  isLast,
  onSlotClick,
  slot,
}: {
  isFirst: boolean;
  isLast: boolean;
  onSlotClick: (slot: DateTime) => void;
  slot: Interval;
}) => {
  return (
    <div>
      <RowLayout isFirst={isFirst}>
        <RowTime
          onClick={() => {
            onSlotClick(slot.start);
          }}
        >
          {slot.start.toFormat('h a')}
        </RowTime>
        {isLast && <EndRowTime>{slot.end.toFormat('h a')}</EndRowTime>}
        <HoverCondensedAddButton
          onClick={() => {
            onSlotClick(slot.start);
          }}
        />
        <HoverCondensedAddButton
          onClick={() => {
            onSlotClick(slot.start.plus({ minute: 30 }));
          }}
        />
        <HoverSlot0
          onClick={() => {
            onSlotClick(slot.start);
          }}
        >
          <EmptySlotDisplay>{slot.start.toFormat('h:mm a')}</EmptySlotDisplay>
        </HoverSlot0>
        <HoverSlot30
          onClick={() => {
            onSlotClick(slot.start.plus({ minute: 30 }));
          }}
        >
          <EmptySlotDisplay>
            {slot.start.plus({ minute: 30 }).toFormat('h:mm a')}
          </EmptySlotDisplay>
        </HoverSlot30>
      </RowLayout>
    </div>
  );
};
