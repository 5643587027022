/** @jsx jsx */
import {
  StaffFragment,
  StaffServiceFragment,
  useLoadStaffQuery,
} from '@bc/codegen/manager';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import Popover, { positionMatchWidth } from '@reach/popover';
import Downshift from 'downshift';
import { matchSorter } from 'match-sorter';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as ClearIcon } from '../../assets/close.svg';
import { Input, InputWrap } from '../form';
import {
  Container,
  Result,
  ResultDropdownContainer,
  ResultsContainer,
} from './ui';

const Clear = styled(ClearIcon)`
  width: 10px;
  height: 10px;
  cursor: pointer;
  g {
    fill: ${Colors.gray};
  }
`;

interface Props {
  value?: string;
  onChange: (value: StaffsServiceFragment | null) => void;
  filterIds?: string[];
}

export type StaffsServiceFragment = {
  __typename?: 'staff' | undefined;
  staff_services: ({
    __typename?: 'staff_services' | undefined;
  } & StaffServiceFragment)[];
} & StaffFragment;

const fuzzyFilter = (
  input: string,
  staff: StaffsServiceFragment[],
  filterIds: string[],
): StaffsServiceFragment[] => {
  return matchSorter(staff, input, {
    keys: ['firstName', 'lastName', 'email'],
  }).filter(({ id }) => {
    return !filterIds.includes(id);
  });
};

const StaffSearch = ({ onChange, filterIds = [], value }: Props) => {
  const [inputValue, setFilterValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const { data: staffSearch } = useLoadStaffQuery({
    variables: {
      where: {
        active: {
          _eq: true,
        },
      },
    },
  });

  const staffs = staffSearch?.staff ?? [];

  useEffect(() => {
    if (value) {
      const staff = staffs.find(({ id }) => {
        return id === value;
      });
      if (staff) {
        setFilterValue(`${staff.firstName} ${staff.lastName}`);
      }
    }
  }, [staffs]);

  const handleChange = (item: StaffsServiceFragment | null) => {
    onChange(item);
    setFilterValue('');
  };

  const selected =
    staffs.find(({ id }) => {
      return id === value;
    }) || null;

  return (
    <Downshift
      selectedItem={selected}
      inputValue={inputValue}
      itemToString={(staff) =>
        staff ? `${staff.firstName} ${staff.lastName}` : ''
      }
      onInputValueChange={(value) => {
        setFilterValue(value);
      }}
      onSelect={handleChange}
    >
      {({
        getRootProps,
        getInputProps,
        getItemProps,
        isOpen,
        openMenu,
        closeMenu,
        clearSelection,
        highlightedIndex,
      }) => {
        return (
          <Container {...getRootProps()}>
            <ResultsContainer>
              <div>
                <InputWrap
                  style={{
                    marginBottom: 0,
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <Input
                    {...getInputProps({
                      onFocus: openMenu,
                      type: 'text',
                      placeholder: 'Find Staff',
                      autoComplete: 'off',
                      name: 'staff_search',
                      onClick: () => {
                        openMenu();
                      },
                      ref: inputRef,
                      onBlur: () => {
                        closeMenu();
                        if (!inputValue) {
                          clearSelection();
                        } else if (selected) {
                          setFilterValue(
                            `${selected?.firstName} ${selected?.lastName}`,
                          );
                        }
                      },
                    })}
                    error={false}
                    css={{
                      flex: 1,
                    }}
                  />
                  {selected && (
                    <Clear
                      onClick={() => clearSelection()}
                      css={{
                        position: 'relative',
                        right: '24px',
                      }}
                    />
                  )}
                </InputWrap>
              </div>
              <Popover
                hidden={!isOpen}
                targetRef={inputRef}
                position={positionMatchWidth}
                css={{
                  zIndex: 100000,
                }}
              >
                <ResultDropdownContainer>
                  <div role="menu">
                    <div>
                      {fuzzyFilter(inputValue, staffs, filterIds).map(
                        (member, index: number) => (
                          <Result
                            href="#"
                            highlighted={highlightedIndex === index}
                            {...getItemProps({
                              item: member,
                              index,
                              key: member.id,
                              onClick: (e: any) => e.preventDefault(),
                            })}
                          >
                            <span>
                              {member.firstName} {member.lastName} (
                              {member.type})
                            </span>
                          </Result>
                        ),
                      )}
                    </div>
                  </div>
                </ResultDropdownContainer>
              </Popover>
            </ResultsContainer>
          </Container>
        );
      }}
    </Downshift>
  );
};

export default StaffSearch;
