/** @jsx jsx */
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import React from 'react';

export const Undo: React.FC<{
  backgroundColor?: string;
  color?: string;
  hoverColor?: string;
  hoverBackgroundColor?: string;
  size?: number;
}> = ({
  backgroundColor = 'transparent',
  color = Colors.teal,
  hoverBackgroundColor,
  hoverColor,
  size = 24,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 24 24`}
      fill={backgroundColor}
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      css={{
        ':hover': {
          stroke: hoverColor,
          fill: hoverBackgroundColor,
        },
      }}
      {...props}
    >
      <circle r={12} cx={12} cy={12} strokeWidth="0" />
      <g transform="translate(4, 4), scale(0.666)">
        <path
          d="M8 5L5 8l3 3"
          fill="none"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 8h9c3.314 0 6 2.462 6 5.5v0c0 3.038-2.686 5.5-6 5.5H6"
          fill="none"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
