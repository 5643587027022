import {
  AccountFragment,
  AccountPatientFragment,
  InsuranceFragment,
  PatientFragment,
  usePatientInsurancesSubscription,
} from '@bc/codegen/medical';
import React, { Fragment } from 'react';
import { Eligibility, InsuranceForm } from '../Insurance';
import { SectionContent } from '../layout';
import { SectionTitle } from '../text';

const getInsurance = (insurances: InsuranceFragment[] = [], iType: string) => {
  return insurances.find(({ type }) => type === iType);
};

export const Insurance: React.FC<{
  data: PatientFragment & {
    accounts: ({
      __typename?: 'account_patient' | undefined;
      account: { __typename?: 'accounts' | undefined } & AccountFragment;
    } & AccountPatientFragment)[];
  };
}> = ({ data: initialData }) => {
  const patient = initialData;
  const patientId = patient?.id;
  const accounts = patient?.accounts ?? [];

  const { loading, data: insuranceData } = usePatientInsurancesSubscription({
    variables: {
      patientId,
      where: {
        active: {
          _neq: false,
        },
      },
    },
  });

  if (loading) return null;

  const insurances = insuranceData?.patients_by_pk?.insurances ?? [];

  const primaryInsurance = getInsurance(insurances, 'PRIMARY');
  const secondaryInsurance = getInsurance(insurances, 'SECONDARY');

  const simpleAccounts = accounts.map((account) => {
    return {
      accountId: account.accountId,
      firstName: account.account.firstName || '',
      lastName: account.account.lastName || '',
    };
  });

  return (
    <SectionContent>
      <SectionTitle data-testid="insuranceTitle">Insurance</SectionTitle>
      <Fragment>
        <InsuranceForm
          insurance={primaryInsurance}
          title="Primary"
          type="PRIMARY"
          accounts={simpleAccounts}
          patientId={patientId}
        />
        {primaryInsurance && (
          <Eligibility
            insurance={primaryInsurance}
            firstName={primaryInsurance.firstName || ''}
            lastName={primaryInsurance.lastName || ''}
            carrier={primaryInsurance.carrier || ''}
            dateOfBirth={primaryInsurance.dateOfBirth!}
            memberId={primaryInsurance.memberId || ''}
            eligibility={primaryInsurance.eligibility}
          />
        )}
      </Fragment>
      <InsuranceForm
        insurance={secondaryInsurance}
        title="Secondary"
        type="SECONDARY"
        accounts={simpleAccounts}
        patientId={patientId}
      />
    </SectionContent>
  );
};
