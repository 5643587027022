import {
  StaffFragment,
  StaffScheduleFragment,
  StaffScheduleServiceFragment,
} from '@bc/codegen/manager';
import { DateTime, Duration, Interval } from 'luxon';

export interface StaffSchedule extends Partial<StaffScheduleFragment> {
  staff: Partial<StaffFragment>;
  staff_schedule_services?: Partial<StaffScheduleServiceFragment[]>;
}

export interface TimeSlot {
  hour: number;
  minute: number;
  display: string;
}

export const generateTimeSlots = (
  start: DateTime,
  end: DateTime,
  split: number,
) => {
  const interval = Interval.fromDateTimes(start, end);
  const splitDuration = Duration.fromMillis(split * 60 * 1000);
  return interval.splitBy(splitDuration);
};

export const prettifyIntervals = (intervals: Interval[]): TimeSlot[] => {
  return intervals.map(({ start }: Interval) => {
    return {
      hour: start.hour,
      minute: start.minute,
      display: start.toFormat('h:mm a'),
    };
  });
};

export const isSameDate = (date: DateTime, date2: DateTime) => {
  return (
    date2.hasSame(date, 'year') &&
    date2.hasSame(date, 'month') &&
    date2.hasSame(date, 'day')
  );
};
