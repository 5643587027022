/** @jsx jsx */
import {
  Clinic_Location_Types_Enum,
  useUpsertAppointmentStaffMutation,
} from '@bc/codegen/manager';
import {
  AppointmentPartsFragment,
  useUpdateAppointmentTimeMutation,
} from '@bc/codegen/medical';
import {
  APPOINTMENT_TYPES,
  AVAILABLE_CARE_TYPES,
  getCareType,
  TIME_ZONES,
} from '@bc/shared';
import { jsx } from '@emotion/core';
import * as Sentry from '@sentry/react';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import { isEqual, uniq } from 'lodash';
import { DateTime, Interval } from 'luxon';
import React, { Fragment, useState } from 'react';
import { object, string } from 'yup';
import MobileAddressEditor from '../../components/AppointmentDetails/AddressEditor';
import MobileNotifications from '../../components/AppointmentDetails/MobileNotifications';
import SymptomCheckerDisplay from '../../components/AppointmentDetails/SymptomChecker';
import { AppointmentTransitioner } from '../../components/AppointmentStatus';
import { AppointmentAuditLog } from '../../components/Audit';
import { Button } from '../../components/button';
import CalendarSelect from '../../components/CalendarSelect';
import ClinicSimpleSelect from '../../components/ClinicSelector/ClinicSimpleSelect';
import CopyToClip from '../../components/copy';
import { AppointmentFlags } from '../../components/Flags';
import {
  CopyToClipField,
  ErrorField,
  ErrorText,
  NormalField,
  Select,
  SelectSimpleField,
  SelectWithCaret,
} from '../../components/form';
import {
  ContentRow,
  HeaderEdit,
  InfoSection,
  SectionContent,
} from '../../components/layout';
import { DefinitionList, DefinitionListItem } from '../../components/List';
import { AppointmentRoomSelect } from '../../components/RoomSelect';
import { SafetyChecklistInfo } from '../../components/SafetyChecklistInfo';
import StaffScheduleField from '../../components/StaffSearch/StaffScheduleField';
import {
  PrimaryNotBlank,
  SectionTitle,
  SmallPrimaryText,
} from '../../components/text';
import TriangleTooltip from '../../components/TriangleTooltip';
import { getTimeSlots } from '../../helpers';
import { MobileWrapRow, Row } from '../../styles';
import { fullStaffName } from '../staff/helpers';

const CARE_TYPES = AVAILABLE_CARE_TYPES;

interface VisitData {
  startTime: string;
  endTime: string;
  reason: string;
  clinicId: string;
  careType: string;
  physician_providers: string[];
  assistant_providers: string[];
  notes: string;
  address: any;
}

const HoverTitle = ({
  children,
  hoverTitle,
}: {
  children: string;
  hoverTitle: string;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <span
      style={{
        position: 'relative',
      }}
      onMouseOver={() => setOpen(true)}
      onMouseOut={() => setOpen(false)}
    >
      {children}
      {open && (
        <TriangleTooltip
          label={hoverTitle}
          style={{
            width: '300px',
          }}
        />
      )}
    </span>
  );
};

const VisitValidation = object().shape({
  startTime: string().required('Required'),
  endTime: string().required('Required'),
  clinicId: string().required('Required'),
  reason: string(),
});

const Visit: React.FC<{ appointment: AppointmentPartsFragment }> = ({
  appointment,
}) => {
  const [clinicType, setClinicType] = useState<
    Clinic_Location_Types_Enum | undefined
  >(appointment?.clinic?.locationType || 'PHYSICAL');
  const [notify, setNotify] = useState(false);

  const [updateAppointment] = useUpdateAppointmentTimeMutation({
    fetchPolicy: 'no-cache',
  });

  const [upsertAppointmentStaff] = useUpsertAppointmentStaffMutation();

  const handleSubmit = async (
    {
      physician_providers,
      assistant_providers,
      careType,
      ...values
    }: VisitData,
    { setStatus, setFieldError }: any,
  ) => {
    const appointmentId = appointment.id;
    const day = DateTime.fromISO(values.startTime);
    const startTime = day;
    const endTime = DateTime.fromISO(values.endTime).set({
      month: day.month,
      day: day.day,
      year: day.year,
    });

    if (startTime.diff(endTime).milliseconds >= 0) {
      setFieldError('endTime', 'End cannot be less than or equal to start');
      return;
    }

    const visitType = getCareType({ careType }).visitType;
    const visitClassification = getCareType({ careType }).visitClassification;

    try {
      await updateAppointment({
        variables: {
          id: appointmentId,
          clinicId: values.clinicId,
          reason: values?.reason?.trim(),
          notes: values?.notes?.trim(),
          startTime: startTime.toSQL({
            includeZone: false,
            includeOffset: false,
          }),
          endTime: endTime.toSQL({
            includeZone: false,
            includeOffset: false,
          }),
          visitType,
          visitClassification: visitClassification ?? undefined,
          notification: notify,
        },
      });

      setNotify(false);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }

    const staffProviders = uniq([
      ...physician_providers,
      ...assistant_providers,
    ]).map((staffId: string) => {
      return {
        staffId,
        appointmentId,
      };
    });

    const currentStaff = appointment?.appointment_staffs?.map(({ staffId }) => {
      return staffId;
    });

    const newStaff = staffProviders?.map(({ staffId }) => {
      return staffId;
    });

    if (!isEqual(currentStaff, newStaff)) {
      await upsertAppointmentStaff({
        variables: {
          appointmentId,
          staff: staffProviders,
        },
      });
    }

    setStatus({
      editing: false,
    });
  };

  const careType = getCareType({
    visitClassification: appointment?.visitClassification,
    visitType: appointment?.visitType,
  });

  const slots = getTimeSlots({ interval: careType.interval });

  const timeZone = appointment?.timeZone ?? '';
  // @ts-ignore
  const displayTimeZone = TIME_ZONES[timeZone] || '';

  const providers =
    appointment?.appointment_staffs.map(({ staff }) => staff) ?? [];

  const physicians = appointment?.appointment_staffs
    ?.filter((appStaff) => {
      return [
        'PHYSICIAN',
        'PHYSICIAN_ASSISTANT',
        'NURSE_PRACTITIONER',
      ].includes(appStaff?.staff?.type);
    })
    .map(({ staffId }) => {
      return staffId;
    });

  const medicalAssistants = appointment?.appointment_staffs
    ?.filter((appStaff) => {
      return appStaff?.staff?.type === 'MEDICAL_ASSISTANT';
    })
    .map(({ staffId }) => {
      return staffId;
    });

  return (
    <Fragment>
      <Formik<VisitData>
        initialValues={{
          clinicId: appointment?.clinicId ?? '',
          startTime: appointment?.startTime,
          endTime: appointment?.endTime,
          reason: appointment?.reason ?? '',
          notes: appointment?.notes ?? '',
          careType: careType.key,
          physician_providers: physicians ?? [],
          assistant_providers: medicalAssistants ?? [],
          address: appointment?.address,
        }}
        enableReinitialize
        validationSchema={VisitValidation}
        initialStatus={{ editing: false }}
        onSubmit={handleSubmit}
      >
        {({
          status,
          setStatus,
          setFieldValue,
          setFieldTouched,
          values,
          resetForm,
        }) => {
          const renderCareTypes =
            clinicType === 'MOBILE'
              ? Object.keys(CARE_TYPES).filter((key) => {
                  return (
                    key !== 'MEET_AND_GREET' &&
                    key !== 'TELEMEDICINE' &&
                    key !== 'URGENT_CARE'
                  );
                })
              : appointment.visitType !== 'TELEMEDICINE'
              ? Object.keys(CARE_TYPES).filter((key) => {
                  return key !== 'TELEMEDICINE';
                })
              : Object.keys(CARE_TYPES);

          const startOfVisit = DateTime.fromISO(values.startTime);
          const endOfVisit = DateTime.fromISO(values.endTime);
          const visitInterval = Interval.fromDateTimes(
            startOfVisit,
            // don't explode if the form temporarily has an invalid date
            DateTime.max(startOfVisit, endOfVisit),
          );
          const formattedStartDate = visitInterval.start.toFormat('MM/dd/yyyy');
          const formattedStartTime = visitInterval.start.toFormat('h:mm a');
          const formattedEndTime = endOfVisit.toFormat('h:mm a ZZZZ');

          const careType = getCareType({ careType: values.careType });

          const hoverTitle = `Created on ${DateTime.fromJSDate(
            new Date(appointment.createdAt),
          ).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}`;

          return (
            <Form>
              {appointment && (
                <ContentRow>
                  <Row>
                    <SectionTitle
                      css={{ marginRight: 12 }}
                      data-testid="visitTitle"
                    >
                      Visit
                    </SectionTitle>
                    <AppointmentAuditLog appointmentId={appointment.id} />
                  </Row>
                  <MobileWrapRow>
                    <AppointmentRoomSelect appointment={appointment} />
                    <AppointmentTransitioner appointment={appointment} />
                  </MobileWrapRow>
                </ContentRow>
              )}
              <HeaderEdit
                title={
                  <HoverTitle hoverTitle={hoverTitle}>Visit Details</HoverTitle>
                }
                editing={status.editing}
                onEdit={() => {
                  setStatus({
                    editing: true,
                  });
                }}
                onCancel={() => {
                  setStatus({ editing: false });
                  resetForm();
                }}
              />
              {!status.editing && (
                <DefinitionList>
                  <DefinitionListItem term="Clinic">
                    {appointment?.clinic?.name}
                  </DefinitionListItem>
                  <DefinitionListItem term="Date">
                    <CopyToClip text={formattedStartDate}>
                      {formattedStartDate}
                    </CopyToClip>
                  </DefinitionListItem>
                  <DefinitionListItem term="Time">
                    <CopyToClip
                      text={`${formattedStartTime} - ${formattedEndTime}`}
                    >
                      {formattedStartTime} - {formattedEndTime}
                    </CopyToClip>
                  </DefinitionListItem>
                  {appointment.visitType === 'TELEMEDICINE' && (
                    <DefinitionListItem term="Time Zone">
                      <CopyToClip text={displayTimeZone}>
                        {displayTimeZone}
                      </CopyToClip>
                    </DefinitionListItem>
                  )}
                  <DefinitionListItem term="Schedule Type">
                    {appointment.type && APPOINTMENT_TYPES[appointment.type]}
                  </DefinitionListItem>
                  <DefinitionListItem term="Care Type">
                    {careType.title}
                  </DefinitionListItem>
                  <DefinitionListItem term="Language Selection">
                    {appointment.languageSelection === 'es'
                      ? 'Spanish'
                      : 'English'}
                  </DefinitionListItem>
                  <DefinitionListItem term="Provider">
                    {values.physician_providers.map((staffId) => {
                      const staff = providers.find(
                        (staff) => staff.id === staffId,
                      );
                      return fullStaffName(staff, true);
                    })}
                  </DefinitionListItem>
                  <DefinitionListItem term="Medical Assistant">
                    {values.assistant_providers.map((staffId) => {
                      const staff = providers.find(
                        (staff) => staff.id === staffId,
                      );
                      return fullStaffName(staff, true);
                    })}
                  </DefinitionListItem>
                  <DefinitionListItem term="Reason for Visit (Visible to patient)">
                    <CopyToClipField name="reason" />
                  </DefinitionListItem>
                  <DefinitionListItem term="Notes (Not visible to patient)">
                    <CopyToClipField name="notes" />
                  </DefinitionListItem>
                </DefinitionList>
              )}
              {status.editing && (
                <Fragment>
                  <ContentRow>
                    <InfoSection title="Clinic" fullWidth>
                      <ClinicSimpleSelect
                        value={values.clinicId}
                        onChange={(clinic) => {
                          setClinicType(clinic?.locationType);
                          setFieldTouched('clinicId');
                          setFieldValue('clinicId', clinic?.id);
                        }}
                      />
                      <ErrorField name="clinicId" />
                    </InfoSection>
                  </ContentRow>
                  <ContentRow>
                    <InfoSection title="Date">
                      <Field name="startTime">
                        {({ field }: FieldProps<any>) => (
                          <CalendarSelect
                            date={DateTime.fromISO(field.value).toFormat(
                              'yyyy-MM-dd',
                            )}
                            onChange={(value) => {
                              const date = DateTime.fromJSDate(value);
                              const time = DateTime.fromISO(field.value);

                              const dateTime = date
                                .set({
                                  hour: time.hour,
                                  minute: time.minute,
                                })
                                .startOf('minute');

                              setFieldValue('startTime', dateTime.toISO());
                            }}
                          >
                            {DateTime.fromISO(field.value).toFormat(
                              'yyyy-MM-dd',
                            )}
                          </CalendarSelect>
                        )}
                      </Field>
                    </InfoSection>
                    <InfoSection title="Schedule Type">
                      <PrimaryNotBlank>
                        {appointment.type &&
                          APPOINTMENT_TYPES[appointment.type]}
                      </PrimaryNotBlank>
                    </InfoSection>
                  </ContentRow>
                  <ContentRow>
                    <InfoSection title="Start Time">
                      <Field name="startTime">
                        {({ field }: FieldProps<any>) => (
                          <SelectWithCaret>
                            <Select
                              {...field}
                              value={DateTime.fromISO(field.value).toFormat(
                                'h:mm a',
                              )}
                              onChange={(e) => {
                                const time = DateTime.fromFormat(
                                  e.target.value,
                                  'h:mm a',
                                );

                                const dateTime = DateTime.fromISO(field.value)
                                  .set({
                                    hour: time.hour,
                                    minute: time.minute,
                                  })
                                  .startOf('minute');

                                setFieldValue('startTime', dateTime.toISO());
                              }}
                            >
                              <option value="">Select</option>
                              {slots.map(({ display }) => (
                                <option value={display} key={display}>
                                  {display}
                                </option>
                              ))}
                            </Select>
                          </SelectWithCaret>
                        )}
                      </Field>
                    </InfoSection>
                    <InfoSection title="End Time">
                      <Field name="endTime">
                        {({ field }: FieldProps<any>) => (
                          <Fragment>
                            <SelectWithCaret>
                              <Select
                                {...field}
                                value={DateTime.fromISO(field.value).toFormat(
                                  'h:mm a',
                                )}
                                onChange={(e) => {
                                  const time = DateTime.fromFormat(
                                    e.target.value,
                                    'h:mm a',
                                  );

                                  const dateTime = DateTime.fromISO(field.value)
                                    .set({
                                      hour: time.hour,
                                      minute: time.minute,
                                    })
                                    .startOf('minute');

                                  setFieldValue('endTime', dateTime.toISO());
                                }}
                              >
                                <option value="">Select</option>
                                {slots.map(({ display }) => (
                                  <option value={display} key={display}>
                                    {display}
                                  </option>
                                ))}
                              </Select>
                            </SelectWithCaret>
                            <ErrorMessage
                              component={ErrorText}
                              name="endTime"
                            />
                          </Fragment>
                        )}
                      </Field>
                    </InfoSection>
                  </ContentRow>
                  <ContentRow>
                    <InfoSection title="Care Type" fullWidth>
                      <SelectSimpleField name="careType">
                        {renderCareTypes.map((key) => (
                          <option value={key} key={key}>
                            {getCareType({ careType: key }).title}
                          </option>
                        ))}
                      </SelectSimpleField>
                    </InfoSection>
                  </ContentRow>
                  <ContentRow>
                    <InfoSection title="Provider">
                      <StaffScheduleField
                        clinicId={values.clinicId}
                        apptTimeSpan={visitInterval}
                        name="physician_providers"
                        serviceType={careType.visitType}
                        display={[
                          'PHYSICIAN',
                          'PHYSICIAN_ASSISTANT',
                          'NURSE_PRACTITIONER',
                        ]}
                      />
                      <ErrorMessage
                        name="physician_providers"
                        component={ErrorText}
                      />
                    </InfoSection>
                    <InfoSection title="Medical Assistant">
                      <StaffScheduleField
                        clinicId={values.clinicId}
                        apptTimeSpan={visitInterval}
                        name="assistant_providers"
                        display={['MEDICAL_ASSISTANT']}
                      />
                      <ErrorMessage
                        name="assistant_providers"
                        component={ErrorText}
                      />
                    </InfoSection>
                  </ContentRow>
                  <ContentRow>
                    <NormalField
                      name="reason"
                      title="Reason for visit (Visible to patient)"
                      fullWidth
                    />
                  </ContentRow>
                  <ContentRow>
                    <NormalField
                      name="notes"
                      title="Notes (Not visible to patient)"
                      fullWidth
                    />
                  </ContentRow>
                </Fragment>
              )}

              {!!appointment?.safetyQuestionnaire && (
                <SafetyChecklistInfo
                  checklist={appointment.safetyQuestionnaire}
                />
              )}
              {/* TODO: what to do with mobile clinic stuff? */}
              {clinicType === 'MOBILE' && (
                <Fragment>
                  <HeaderEdit
                    title="Location Details"
                    editing={status.editing}
                    onEdit={() => {
                      setStatus({
                        editing: true,
                      });
                    }}
                    noTopPadding
                  />
                  <ContentRow>
                    <MobileAddressEditor />
                  </ContentRow>
                </Fragment>
              )}
              <ContentRow>
                {status.editing && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                      paddingBottom: '16px',
                    }}
                  >
                    <SmallPrimaryText>
                      <label
                        htmlFor="notify"
                        css={{
                          cursor: 'pointer',
                        }}
                      >
                        <input
                          type="checkbox"
                          id="notify"
                          checked={notify}
                          data-testid="notify"
                          onChange={(e) => {
                            setNotify(e.target.checked);
                          }}
                        />
                        Send notification to caregiver?
                      </label>
                    </SmallPrimaryText>
                    <Button type="submit">Save</Button>
                  </div>
                )}
              </ContentRow>
              <AppointmentFlags appointmentId={appointment.id} />
            </Form>
          );
        }}
      </Formik>
      {/* TODO: what to do with mobile clinic stuff? */}
      {appointment?.clinic?.locationType === 'MOBILE' && (
        <SectionContent style={{ paddingTop: 0 }}>
          <HeaderEdit title="Caregiver Details" editable={false} noTopPadding />
          <MobileNotifications appointment={appointment} />
        </SectionContent>
      )}
      {/* TODO: what to do with symptom checker stuff */}
      <SymptomCheckerDisplay appointment={appointment} />
      {/* TODO: what to do with reviews */}
      {appointment.reviews.length > 0 && (
        <SectionContent>
          {appointment.reviews.map((review, index) => (
            <ContentRow key={index}>
              <InfoSection title="Review">
                <PrimaryNotBlank>{review.review}</PrimaryNotBlank>
              </InfoSection>
            </ContentRow>
          ))}
        </SectionContent>
      )}
    </Fragment>
  );
};

export default Visit;
