import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { PrimaryText } from '../text';

export const Title = styled(PrimaryText)({
  alignItems: 'center',
  color: Colors.darkHarbor,
  display: 'flex',
  fontSize: 14,
  fontWeight: 'bold',
  letterSpacing: '0.1px',
  lineHeight: '17px',
  marginBottom: 8,
});

export const ApptTime = styled(PrimaryText)({
  color: '#94A2A1',
  fontSize: 14,
  letterSpacing: '0.1px',
  lineHeight: '17px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const Reason = styled(PrimaryText)({
  color: '#94A2A1',
  fontSize: 14,
  letterSpacing: '0.1px',
  lineHeight: '17px',
  marginTop: 8,
  wordBreak: 'break-all',
});

export const Box = styled.div({
  borderRadius: '8px',
  cursor: 'pointer',
  display: 'flex',
  height: 'auto',
  minHeight: '100%',
  transition: 'box-shadow 500ms',
});

export const Wrap = styled.div({
  position: 'absolute',
  zIndex: 4,
  width: '100%',
});
