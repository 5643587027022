/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import styled from "@emotion/styled";
import React from "react";
import { GREY, PRIMARY } from "../../styles";

interface Props {
  visible?: boolean;
  light?: boolean;
}

interface ColorProps {
  primaryColor: string;
  backgroundColor: string;
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @keyframes bounce {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  animation: bounce 0.2s;
`;

const Spinner = styled.div<ColorProps>`
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  content: "";
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #f6f;
  border-top-color: ${({ primaryColor }) => primaryColor};
  border-left-color: ${({ primaryColor }) => primaryColor};
  border-bottom-color: ${({ backgroundColor }) => backgroundColor};
  border-right-color: ${({ backgroundColor }) => backgroundColor};
  animation: spinner 0.6s linear infinite;
`;

const spinnerLight = css`
  width: 20px;
  height: 20px;
  border-top-color: #fff;
  border-left-color: #fff;
  border-bottom-color: rgba(255, 255, 255, 0.5);
  border-right-color: rgba(255, 255, 255, 0.5);
`;

export const Loader: React.FC<Props> = ({ visible = true, light = false }) => {
  if (!visible) {
    return null;
  }

  let primaryColor = PRIMARY;
  let backgroundColor = GREY;

  if (light) {
    primaryColor = "#FFF";
    backgroundColor = "rgba(255, 255, 255, 0.5)";
  }

  return (
    <Container>
      <Spinner
        css={light && spinnerLight}
        primaryColor={primaryColor}
        backgroundColor={backgroundColor}
      />
    </Container>
  );
};

export const DynamicLoader: React.FC<ColorProps & { className?: string }> = ({
  primaryColor = PRIMARY,
  backgroundColor = GREY,
  className,
}) => {
  return (
    <Container>
      <Spinner
        className={className}
        primaryColor={primaryColor}
        backgroundColor={backgroundColor}
      />
    </Container>
  );
};

export default Loader;
