/** @jsx jsx */
import { PatientFragment, useFindPatientsQuery } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { FormikProps, useFormikContext } from 'formik';
import React, { Fragment, useMemo, useState } from 'react';
import { ICONS } from '../../assets';
import { Appointment } from '../../pages/AddAppointment';
import { TextButton } from '../button';
import { PatientAvatar } from '../ChatMessage';
import { DateOfBirthField, NormalField } from '../form';
import { CondensedRow as ContentRow, HeaderEdit, InfoSection } from '../layout';
import { SelectButton } from '../SearchForAccountPatient/SearchForAccountPatient.styles';
import { PrimaryText } from '../text';

const StaticHeader = css({
  borderBottom: 'none',
  padding: '48px 0 0',
});

export const WarningWrapper = styled(PrimaryText)({
  background: Colors.butterscotchLightest,
  border: `1px solid ${Colors.butterscotch}`,
  borderRadius: 8,
  display: 'flex',
  padding: 16,
  marginTop: 16,
}).withComponent('div');

export const Icon = styled.img({
  height: 24,
  width: 24,
  marginRight: 16,
});

export const Row = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  fontWeight: 500,
});

export const PersonRow = styled.div({
  alignItems: 'center',
  borderBottom: `1px solid ${Colors.butterscotchLight}`,
  display: 'flex',
  fontWeight: 400,
  justifyContent: 'space-between',
  marginRight: 40,
  padding: '16px 0',
  width: '100%',
});

export const DismissText = styled(PrimaryText)({
  fontWeight: 400,
  marginTop: 16,
  width: '100%',
}).withComponent('div');

export const DismissButton = styled(TextButton)({
  display: 'inline-flex',
  padding: '0 0 0 6px',
  textDecoration: 'underline',
});

const Address = styled.span({
  flex: 1,
});

export const PatientInfoSection: React.FC<{
  onExistingSelect: (patient: PatientFragment) => void;
}> = ({ onExistingSelect }) => {
  const [showWarning, setShowWarning] = useState(false);
  const { values }: FormikProps<Appointment> = useFormikContext();
  const { firstName, lastName, dateOfBirth, patientId } = values;
  const validDOB = dateOfBirth && !dateOfBirth.includes('_');

  const { data: patientData } = useFindPatientsQuery({
    variables: {
      where: {
        firstName: {
          _ilike: firstName,
        },
        lastName: {
          _ilike: lastName,
        },
        dateOfBirth: {
          _eq: dateOfBirth,
        },
      },
    },
    skip: !firstName || !lastName || !validDOB || !!patientId,
  });

  const patients = useMemo(() => {
    setShowWarning(true);
    return patientData?.patients ?? [];
  }, [patientData]);
  const patientTerm =
    patients.length > 1 ? 'matching patients' : 'a matching patient';

  return (
    <Fragment>
      <HeaderEdit
        title="Patient information"
        editing={true}
        onEdit={() => {}}
        editable={false}
        css={StaticHeader}
      />
      <ContentRow>
        <NormalField
          title="Patient first name"
          name="firstName"
          disabled={!!values.patientId}
        />
        <NormalField
          title="Patient last name"
          name="lastName"
          disabled={!!values.patientId}
        />
      </ContentRow>
      <ContentRow>
        <InfoSection title="Date of birth">
          <DateOfBirthField name="dateOfBirth" disabled={!!values.patientId} />
        </InfoSection>
      </ContentRow>
      {!!patients.length && showWarning && (
        <WarningWrapper>
          <Icon src={ICONS.info} />
          <Row>
            We found {patientTerm} with the same name and date of birth:
            {patients.map((patient) => (
              <PersonRow key={patient.id}>
                <PatientAvatar
                  size={48}
                  patientId={patient.id}
                  style={{ marginRight: 16 }}
                />
                <Address>
                  {patient.address?.formatted_address.replace(', USA', '')}
                </Address>
                <SelectButton
                  isSelected={false}
                  onClick={(e) => {
                    e.preventDefault();
                    onExistingSelect(patient);
                  }}
                  type="button"
                >
                  Select Patient
                </SelectButton>
              </PersonRow>
            ))}
            <DismissText>
              If you’d like to create a new patient, you can
              <DismissButton
                onClick={(e) => {
                  e.preventDefault();
                  setShowWarning(false);
                }}
                type="button"
              >
                dismiss this alert
              </DismissButton>
              .
            </DismissText>
          </Row>
        </WarningWrapper>
      )}
    </Fragment>
  );
};
