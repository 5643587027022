import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import capitalize from 'lodash/capitalize';
import getIn from 'lodash/get';
import React from 'react';
import { ICONS } from '../../assets';
import { TextButton } from '../button';
import { InsuranceEligibility } from '../CarrierSearch/types';
import CopyToClip from '../copy';
import { DefinitionList, DefinitionListItem } from '../List';
import { ProgressBar } from '../ProgressBar';
import { SmallTitle } from '../text';

interface EligibilityProps {
  insurance: any;
  carrier: string;
  memberId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  eligibility: any;
}

export const Eligibility: React.FC<EligibilityProps> = (props) => {
  const eligibility: InsuranceEligibility = props.eligibility;

  const isInNetwork = props && !/kaiser/.test(props.carrier || '');

  const copayOptions = getIn(eligibility, 'coverage.copay') || [];
  const coinsuranceOptions = getIn(eligibility, 'coverage.coinsurance') || [];

  const dataFilter = (data: any) =>
    (data.service_type_codes || []).includes('UC') &&
    data.in_plan_network === (isInNetwork ? 'yes' : 'no') &&
    data.coverage_level === 'individual';

  const copayData = copayOptions.filter(dataFilter).pop();
  const coinsuranceData = coinsuranceOptions.filter(dataFilter).pop();

  const copayAmount = parseInt(getIn(copayData, 'copayment.amount') || '');
  const coinsurance = getIn(coinsuranceData, 'benefit_percent') ?? 0;
  const currency = getIn(copayData, 'currency');

  const deductible = getIn(
    eligibility,
    `summary.deductible.individual.${
      isInNetwork ? 'in_network' : 'out_of_network'
    }`,
  );
  const deductibleApplied = parseInt(getIn(deductible, 'applied.amount'));
  const deductibleLimit = parseInt(getIn(deductible, 'limit.amount') || '');

  const deductibleProgress =
    deductibleLimit > 0 ? deductibleApplied / deductibleLimit : 1;

  let totalAmount = '';
  let totalDescription = '';
  if (copayAmount > 0 && coinsurance > 0) {
    totalAmount = `$${copayAmount} + ${coinsurance * 100}%`;
    totalDescription = 'Co-pay and coinsurance is due at check-out.';
  } else if (coinsurance > 0) {
    totalAmount = `${coinsurance * 100}%`;
    totalDescription = 'Coinsurance is due at check-out.';
  } else {
    totalAmount = `$${copayAmount}`;
    totalDescription = 'Co-pay is due at check-out.';
  }

  const subscriber = eligibility?.subscriber;

  return (
    <Container>
      <Header>
        <SmallTitle>Benefits Summary</SmallTitle>
        {
          <Button
            disabled
            style={{ borderRadius: '20px', float: 'right', fontSize: '14px' }}
          >
            <Icon src={ICONS.retake} role="presentation" />
            Refresh Eligibility
          </Button>
        }
      </Header>
      <DefinitionList highlight="even">
        <DefinitionListItem term={totalDescription}>
          <CopyToClip text={`${currency === 'USD' && '$'}${totalAmount}`}>
            {currency === 'USD' && '$'}
            {totalAmount}
          </CopyToClip>
        </DefinitionListItem>
        <DefinitionListItem term="Gender">
          <CopyToClip text={capitalize(subscriber?.gender ?? '')}>
            {capitalize(subscriber?.gender ?? '')}
          </CopyToClip>
        </DefinitionListItem>
        <DefinitionListItem term="Deductible Progress">
          <ProgressRow>
            <div style={{ marginBottom: 8 }}>
              {deductibleLimit > 0
                ? `$${deductibleApplied} of $${deductibleLimit} spent`
                : '$0'}
            </div>
            <ProgressBar progress={deductibleProgress} />
          </ProgressRow>
        </DefinitionListItem>
      </DefinitionList>
      <Header>
        <SmallTitle>Insurance Info</SmallTitle>
      </Header>
      <DefinitionList highlight="even">
        <DefinitionListItem term="Street">
          <CopyToClip
            text={(subscriber?.address?.address_lines ?? []).join(' ')}
          >
            {subscriber?.address?.address_lines || '-'}
          </CopyToClip>
        </DefinitionListItem>
        <DefinitionListItem term="City">
          <CopyToClip text={subscriber?.address?.city}>
            {subscriber?.address?.city || '-'}
          </CopyToClip>
        </DefinitionListItem>
        <DefinitionListItem term="State">
          <CopyToClip text={subscriber?.address?.state}>
            {subscriber?.address?.state || '-'}
          </CopyToClip>
        </DefinitionListItem>
        <DefinitionListItem term="Zip">
          <CopyToClip text={subscriber?.address?.zipcode}>
            {subscriber?.address?.zipcode || '-'}
          </CopyToClip>
        </DefinitionListItem>
      </DefinitionList>
    </Container>
  );
};

const Container = styled.div({
  background: '#F3F5F5',
  border: `1px solid ${Colors.grayLighter}`,
  borderRadius: 16,
  marginBottom: 24,
  marginTop: 16,
  padding: '16px 24px',
});

const Header = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 0',
});

const Button = styled(TextButton)({
  alignItems: 'center',
  display: 'flex',
  fontSize: 16,
  lineHeight: '19px',
});

const Icon = styled.img({
  height: 24,
  marginRight: 8,
  width: 24,
});

const ProgressRow = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});
