/** @jsx jsx */
import { useUpdateAppointmentMutation } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import React from 'react';
import { ICONS } from '../../assets';
import { Button, TextButton } from '../button';
import { PrimaryText } from '../text';
import KareoSection from './KareoSection';

const KareoAppointmentSection = ({
  onSectionSelect,
  success,
  open,
  onToggle,
  appointmentId,
}: {
  open: boolean;
  onToggle: () => void;
  onSectionSelect: () => void;
  success: boolean;
  appointmentId: string;
}) => {
  let title: React.ReactNode = 'Create Appointment in Kareo';

  const [updateAppointment, { loading }] = useUpdateAppointmentMutation();

  if (success) {
    title = (
      <div css={{ display: 'flex', alignItems: 'center' }}>
        Create Appointment in Kareo
        <TextButton
          css={{
            color: '#ef3f00',
            marginLeft: '8px',
          }}
          disabled={loading}
          onClick={async () => {
            if (appointmentId) {
              await updateAppointment({
                variables: {
                  id: appointmentId,
                  set: {
                    kareo_appointment_id: null,
                    kareo_appointment_uuid: null,
                  },
                },
              });
            }
          }}
        >
          Unlink Appointment
        </TextButton>
      </div>
    );
  }
  return (
    <KareoSection
      icon={ICONS.kareoAppointment}
      title={title}
      open={open}
      onToggle={onToggle}
      success={success}
    >
      <div
        style={{
          padding: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <PrimaryText
          style={{
            marginBottom: '16px',
          }}
        >
          Sync the patient’s appointment details from Brave Care into Kareo.
        </PrimaryText>
        <Button type="button" onClick={onSectionSelect}>
          Review Appointment Details
        </Button>
      </div>
    </KareoSection>
  );
};

export default KareoAppointmentSection;
