/** @jsx jsx */
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { FONT_PRIMARY } from '../../styles';
import { Checkbox } from './Checkbox';
import { CountBox } from './CountBox';

const StyledFieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
`;

const Title = styled.legend`
  ${FONT_PRIMARY};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.darkHarbor};
  flex-grow: 1;
  text-align: left;
`;

const Row = styled.div<{ areOptionsVisible: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  &:first-of-type {
    margin-top: 0;
  }

  ${({ areOptionsVisible }) =>
    !areOptionsVisible && `opacity: 0; max-height: 0; margin: 0;`}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextButton = styled.span`
  ${FONT_PRIMARY};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${Colors.gray};
  margin-right: 12px;
  cursor: pointer;
`;

const Caret = styled.img<{ areOptionsVisible: boolean }>`
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  transform: rotate(
    ${({ areOptionsVisible }) => (areOptionsVisible ? 180 : 0)}deg
  );
`;

export interface CheckboxOption {
  color: string;
  groupName: string;
  label: string;
  value: string;
  count?: number;
}

export const CheckboxFilter: React.FC<{
  checkboxOptions: CheckboxOption[];
  onChange: (value: string) => void;
  onToggleAll?: () => void;
  selectedOptions: string[];
  title: string;
  count?: number;
  style?: React.CSSProperties;
  className?: string;
}> = ({
  checkboxOptions,
  onChange,
  onToggleAll,
  selectedOptions,
  style,
  className,
  title,
  count,
}) => {
  return (
    <StyledFieldset style={style} className={className}>
      <Wrapper>
        <div css={{ display: 'flex', flexDirection: 'row' }}>
          <Title>{title}</Title>
          {count !== undefined && (
            <CountBox count={count} css={{ marginLeft: '4px' }} />
          )}
        </div>

        {onToggleAll && (
          <TextButton
            onClick={onToggleAll}
            style={{ marginLeft: '10px', color: Colors.tealDark }}
          >
            {selectedOptions.length === 0 ? 'Check all' : 'Clear all'}
          </TextButton>
        )}
      </Wrapper>
      {checkboxOptions.map((checkboxOption) => {
        const { count, ...option } = checkboxOption;
        return (
          <Row key={option.value} areOptionsVisible={true}>
            <Checkbox
              {...option}
              isChecked={selectedOptions.includes(option.value)}
              onChange={() => onChange(option.value)}
            />
            {typeof count === 'number' && <CountBox count={count} />}
          </Row>
        );
      })}
    </StyledFieldset>
  );
};
