import {
  ClinicFragment,
  useAddClinicMutation,
  useGetClinicsQuery,
} from '@bc/codegen/manager';
import { useLoadRegionsQuery } from '@bc/codegen/medical';
import { timeZoneMap } from '@bc/shared';
import styled from '@emotion/styled';
import { Field, FieldProps, Form, Formik } from 'formik';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { object, string } from 'yup';
import Pin from '../../assets/pin.svg';
import { Button } from '../../components/button';
import {
  AddressCoordinatesField,
  NormalField,
  Select,
  SelectSimpleField,
  SelectWithCaret,
} from '../../components/form';
import { ContentRow, InfoSection } from '../../components/layout';
import { LargeTitle } from '../../components/text';
import { Close, Content } from '../ViewAppointment.styles';

const EventValidation = object().shape({
  name: string().required('Required'),
  clinicCode: string()
    .required('Required')
    .matches(/^\S*$/, 'No spaces allowed')
    .strict(true)
    .lowercase('Only lower case'),
  timeZone: string().required('Required'),
  address: string().required('Required'),
  regionId: string().required('Required'),
  parentClinicId: string().nullable(),
});

const context = { role: 'manager' };
const Icon = styled.img`
  height: 18px;
  width: 18px;
`;

const CreateEvent: React.FC<RouteComponentProps<{}>> = ({ history, match }) => {
  const [addClinic] = useAddClinicMutation({ context });
  const { data } = useGetClinicsQuery({
    variables: {
      where: {
        locationType: {
          _neq: 'EVENT',
        },
      },
    },
  });

  const { data: regionData } = useLoadRegionsQuery({
    context,
  });

  const regions = regionData?.regions ?? [];
  const clinics = data?.clinics ?? [];

  const handleSubmit = async ({
    regionId,
    ...values
  }: Partial<ClinicFragment & { regionId: number }>) => {
    await addClinic({
      variables: {
        clinic: {
          ...values,
          parentClinicId: values.parentClinicId
            ? values.parentClinicId
            : undefined,
          locationType: 'EVENT',
          phoneNumber: '(503) 300-4111',
          open: '10:00:00',
          close: '22:00:00',
          active: true,
          location: {
            type: 'Point',
            coordinates: [
              parseFloat(values.longitude!),
              parseFloat(values.latitude!),
            ],
          },
          clinics_regions: {
            data: [{ regionId }],
          },
        },
      },
    });
    history.push('/events');
  };

  return (
    <Content
      aria-label="Create Event"
      style={{
        padding: '40px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <LargeTitle>Create Event</LargeTitle>
        </div>
        <Close onClick={() => history.push('/events')} />
      </div>
      <Formik
        initialValues={{ timeZone: 'America/Los_Angeles' }}
        onSubmit={handleSubmit}
        initialStatus={{
          editing: true,
        }}
        validationSchema={EventValidation}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <ContentRow>
                <NormalField title="Event Name" name="name" fullWidth />
              </ContentRow>
              <ContentRow>
                <NormalField title="Event Code" name="clinicCode" fullWidth />
              </ContentRow>
              <ContentRow>
                <InfoSection title="Address" fullWidth>
                  <AddressCoordinatesField name="address" />
                </InfoSection>
              </ContentRow>
              <ContentRow>
                <Field name="parentClinicId">
                  {({ field }: FieldProps<any>) => (
                    <SelectWithCaret
                      style={{
                        width: '100%',
                      }}
                    >
                      <Icon
                        src={Pin}
                        style={{
                          position: 'absolute',
                          top: 16,
                          left: 16,
                        }}
                      />
                      <Select {...field} style={{ paddingLeft: 44 }}>
                        <option value="">Select</option>
                        {clinics?.map(({ name, id }) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </Select>
                    </SelectWithCaret>
                  )}
                </Field>
              </ContentRow>
              <ContentRow>
                <InfoSection title="Time zone" fullWidth>
                  <SelectSimpleField name="timeZone">
                    <option value="">Select</option>
                    {timeZoneMap?.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </SelectSimpleField>
                </InfoSection>
              </ContentRow>
              <ContentRow>
                <InfoSection title="Region" fullWidth>
                  <SelectSimpleField name="regionId">
                    <option value="">Select</option>
                    {regions?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </SelectSimpleField>
                </InfoSection>
              </ContentRow>
              <ContentRow>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  style={{
                    width: 'auto',
                  }}
                >
                  Save
                </Button>
              </ContentRow>
            </Form>
          );
        }}
      </Formik>
    </Content>
  );
};

export default CreateEvent;
