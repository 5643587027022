import React, { Fragment, useState } from 'react';
import { DateTime } from 'luxon';
import { CalendarSelector } from './CalendarSelector';
import { InputBorder, NoBorderInput } from '../form';
import styled from '@emotion/styled';
import Calendar from '../../assets/calendar.svg';
import Caret from '../../assets/caret-down.svg';

const CalendarWrap = styled.div({
  backgroundColor: 'white',
  padding: 24,
});

const Icon = styled.img`
  height: 18px;
  width: 18px;
  filter: brightness(0) saturate(100%) invert(24%) sepia(11%) saturate(1247%)
    hue-rotate(124deg) brightness(99%) contrast(87%);
  margin: 16px;
`;

interface CalendarPickerProps {
  onChange: (date: Date) => void;
  value: Date;
}

export const CalendarPicker = ({ value, onChange }: CalendarPickerProps) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const close = () => setShowCalendar(false);

  const dateTime = DateTime.fromJSDate(value);
  const formattedDate = dateTime.hasSame(DateTime.local(), 'day')
    ? 'Today'
    : dateTime.toFormat('DDD');

  return (
    <Fragment>
      <InputBorder
        onClick={() => {
          setShowCalendar(!showCalendar);
        }}
        style={{
          cursor: 'pointer',
        }}
      >
        <Icon src={Calendar} />
        <NoBorderInput
          value={formattedDate}
          readOnly
          style={{
            cursor: 'pointer',
          }}
        />
        <Icon src={Caret} />
      </InputBorder>
      {showCalendar && (
        <CalendarWrap>
          <CalendarSelector
            value={value}
            onChange={(value) => {
              onChange(value);
              close();
            }}
          />
        </CalendarWrap>
      )}
    </Fragment>
  );
};
