import { Colors } from '@bc/theme';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import {
  Button,
  GreenOutlineButton,
  OutlineButtonDark,
  TextButton,
} from '../components/button';
import { PrimaryText } from '../components/text';
import { IF_DESKTOP, IF_TABLET } from '../styles';

export const ScheduleWrap = styled.div`
  display: flex;
  border: 1px solid ${Colors.grayLighter};
  border-radius: 12px;
  flex-direction: column;
  flex: 1;
`;
export const StaffScheduleContent = styled.div`
  flex: 1;
  padding: 24px;
  order: 2;
  border-right: none;
  border-top: 1px solid ${Colors.grayLighter};
  display: flex;
  flex-direction: column;
  ${IF_DESKTOP} {
    order: 1;
    border-right: 1px solid ${Colors.grayLighter};
    border-top: none;
    padding: 24px;
  }
`;

export const ScheduleContent = styled(StaffScheduleContent)`
  padding: 0 0 24px;
  border: 1px solid ${Colors.grayLighter};
  border-radius: 16px;
  ${IF_DESKTOP} {
    border: 1px solid ${Colors.grayLighter};
  }
`;

export const DayHeader = styled.div({
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  width: '100%',
  marginBottom: 32,
  flexWrap: 'wrap',
  justifyContent: 'center',
});

export const FormattedDay = styled(PrimaryText)({
  fontWeight: 500,
  [IF_TABLET]: {
    fontSize: '18px',
    lineHeight: '22px',
  },
  color: Colors.darkHarbor,
});

export const TodayButton = styled(OutlineButtonDark)`
  width: 100px;
  font-size: 16px;
  border: 1px solid ${Colors.grayLight};
  background-color: transparent;
  color: ${Colors.darkHarbor};
  border-radius: 100px;
  padding: 8px 24px;
  min-width: 94px;
  min-height: 40px;
  margin-right: 16px;

  &:disabled {
    color: ${Colors.gray};
  }
`;

export const OutlineButton = styled(GreenOutlineButton)({
  fontSize: '16px',
  borderRadius: '100px',
  lineHeight: '24px',
  alignSelf: 'flex-end',
});

export const OutlineButtonLink = OutlineButton.withComponent(Link);

export const TitleContainer = styled.div<{ condense?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: ${({ condense }) => (condense ? 'column' : 'row')};
  align-items: ${({ condense }) => (condense ? 'flex-start' : 'center')};
  justify-content: space-between;
  flex-wrap: wrap;
  padding: ${({ condense }) => (condense ? '16px 0 0 0' : '40px 0')};
`;

export const LeftSide = styled.div`
  display: inline-flex;
  align-items: baseline;
  flex-wrap: wrap;
`;
export const RightSide = styled.div<{ condense?: boolean }>`
  padding: ${({ condense }) => (condense ? '16px 0' : 0)};
  display: ${({ condense }) => (condense ? 'flex' : 'inline-flex')};
  flex-wrap: wrap;
  align-items: center;
`;

export const Filterable = styled.button<{ highlight: boolean }>`
  border: 1px solid ${Colors.grayLight};
  border-radius: 100px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${({ highlight }) =>
    highlight ? Colors.grayLightest : Colors.white};
  &:focus {
    outline: none;
    border: 1px solid ${Colors.darkHarbor};
  }
`;

export const Icon = styled.img({
  width: '18px',
  height: '18px',
});

export const FilterableText = styled(PrimaryText)({
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  color: Colors.darkHarbor,
  marginLeft: 8,
  display: 'flex',
  alignItems: 'center',
});

export const DropdownButtonContainer = styled.div<{ condense?: boolean }>`
  display: inline-flex;
  position: relative;
  margin: 16px 0 0 0;
  flex-grow: 1;
  justify-content: ${({ condense }) => (condense ? 'flex-start' : 'flex-end')};
`;

export const FilterContainer = css({
  [IF_DESKTOP]: {
    left: 'unset',
    right: 0,
  },
  position: 'absolute',
  top: 46,
  left: 0,
  right: 'unset',
  width: 312,
  zIndex: 16,
  background: Colors.white,
  borderRadius: 16,
  boxShadow:
    '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
});

export const CalendarContainer = css({
  padding: '24px 16px',
});

export const TextButtonText = styled(PrimaryText)({
  fontSize: '18px',
});

export const TextDropdownButton = styled(TextButton)({
  padding: '16px 18px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 0,
  ':hover': {
    backgroundColor: Colors.grayLightest,
  },
});
export const TextButtonLink = TextDropdownButton.withComponent(Link);

export const DropContainer = styled.div([
  FilterContainer,
  {
    top: '46px',
    left: 'unset',
    right: '0',
    ':hover': {
      display: 'block',
    },
  },
]);

export const DropContainerWrap = styled.div({
  position: 'relative',
  backgroundColor: 'transparent',
  [`& ${DropContainer}`]: {
    visibility: 'hidden',
    transition: '0.2s .5s',
  },
  ':hover,:active,:focus': {
    [`& ${DropContainer}`]: {
      display: 'block',
      visibility: 'visible',
      transitionDelay: '0s',
    },
  },
});

export const CreateButton = styled(Button)({
  fontSize: '16px',
  borderRadius: '100px',
  lineHeight: '24px',
  alignSelf: 'flex-end',
  padding: '7px 16px',
  ':active,:focus': {
    [`& + ${DropContainer}`]: {
      display: 'block',
      visibility: 'visible',
      transitionDelay: '0s',
    },
  },
});
