/** @jsx jsx */
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { ACCENT_GREEN, FONT_PRIMARY, GREY } from '../../styles';
import { RightButton } from '../button';

const CalendarTitle = styled.span`
  ${FONT_PRIMARY};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${GREY};
`;

const CalendarHead = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 14px;
`;

const CalendarBody = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  min-width: 294px;
`;

export const CalendarControls = styled.div`
  display: flex;
  margin-right: 16px;
`;

export const CalendarControl = styled.div`
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2e2ea;
  box-sizing: border-box;
  border-radius: 8px;
  height: 24px;
  width: 24px;

  :first-of-type {
    margin-right: 8px;
  }
`;

export const Next = styled(RightButton)`
  filter: invert(60%) sepia(17%) saturate(140%) hue-rotate(201deg)
    brightness(93%) contrast(91%);
  height: 9px;
  width: 5px;
`;

export const Prev = styled(Next)`
  transform: rotate(180deg);
`;

const Day = styled.div`
  ${FONT_PRIMARY};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 14.2857142857143%;
  min-width: 42px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: ${GREY};
`;

const Weekday = styled(Day)`
  ${FONT_PRIMARY}
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.857143px;
  text-transform: uppercase;
  color: #94a2a1;
`;

const Today = styled.div<{ selected: boolean }>`
  align-items: center;
  background: ${ACCENT_GREEN};
  border-radius: 100px;
  color: white;
  display: flex;
  font-weight: bold;
  height: 34px;
  justify-content: center;
  width: 34px;
  font-weight: ${({ selected }) => (selected ? 600 : 500)};
`;

const SelectedDay = styled.div`
  align-items: center;
  background: #d9f4ef;
  color: ${ACCENT_GREEN};
  border-radius: 100px;
  display: flex;
  font-weight: bold;
  height: 34px;
  justify-content: center;
  width: 34px;
  font-weight: 600;
`;

interface CalendarSelectorProps {
  onChange: (date: Date) => void;
  value: Date;
}

const isTodayDate = (date: DateTime) => {
  const today = DateTime.local();
  return (
    today.hasSame(date, 'year') &&
    today.hasSame(date, 'month') &&
    today.hasSame(date, 'day')
  );
};

export const CalendarSelector = ({
  className,
  onChange,
  style,
  value,
  ...props
}: CalendarSelectorProps &
  Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>) => {
  const today = DateTime.local();
  const current = DateTime.fromJSDate(value);
  const [date, setDate] = useState(current.startOf('month'));
  const days = Array.from(Array(date.daysInMonth).keys()).map((d) => d + 1);
  const previousMonthDays = Array.from(
    // weekday where 1 is Monday and 7 is Sunday
    Array(date.weekday === 7 ? 0 : date.weekday).keys(),
  )
    .map((offsetDay) => date.minus({ day: offsetDay + 1 }).day)
    .sort((a, b) => a - b);

  const nextMonthDays = Array.from(
    Array(
      6 - (date.endOf('month').weekday === 7 ? 0 : date.endOf('month').weekday),
    ).keys(),
  )
    .map((offsetDay) => date.plus({ day: offsetDay }).day)
    .sort((a, b) => a - b);

  useEffect(() => {
    setDate(current.startOf('month'));
  }, [value]);

  const isCurrent = (dayOfMonth: number) => {
    return (
      current.hasSame(date, 'year') &&
      current.hasSame(date, 'month') &&
      current.day === dayOfMonth
    );
  };

  const isToday = (dayOfMonth: number) => {
    return (
      today.hasSame(date, 'year') &&
      today.hasSame(date, 'month') &&
      today.day === dayOfMonth
    );
  };

  const isTodaySelected = isTodayDate(current);

  return (
    <div style={style} className={className} {...props}>
      <CalendarHead>
        <CalendarTitle>
          {date.monthLong} {date.year}
        </CalendarTitle>
        <CalendarControls>
          <CalendarControl onClick={() => setDate(date.minus({ month: 1 }))}>
            <Prev />
          </CalendarControl>
          <CalendarControl onClick={() => setDate(date.plus({ month: 1 }))}>
            <Next />
          </CalendarControl>
        </CalendarControls>
      </CalendarHead>
      <CalendarBody>
        <Weekday>S</Weekday>
        <Weekday>M</Weekday>
        <Weekday>T</Weekday>
        <Weekday>W</Weekday>
        <Weekday>T</Weekday>
        <Weekday>F</Weekday>
        <Weekday>S</Weekday>
        {previousMonthDays.map((day) => (
          <Day
            key={`previousMonth-${day}`}
            onClick={() =>
              onChange(date.set({ day, month: date.month - 1 }).toJSDate())
            }
            style={{
              cursor: 'pointer',
              color: Colors.gray,
            }}
          >
            {day}
          </Day>
        ))}
        {days.map((day) => (
          <Day
            key={`dateOfMonth-${day}`}
            onClick={() => onChange(date.set({ day }).toJSDate())}
            style={{
              cursor: 'pointer',
              ...(isCurrent(day) && { fontWeight: 'bold' }),
            }}
          >
            {isToday(day) ? (
              <Today selected={isTodaySelected}>{day}</Today>
            ) : isCurrent(day) ? (
              <SelectedDay>{day}</SelectedDay>
            ) : (
              day
            )}
          </Day>
        ))}
        {nextMonthDays.map((day) => (
          <Day
            key={`nextMonth-${day}`}
            onClick={() =>
              onChange(date.set({ day, month: date.month + 1 }).toJSDate())
            }
            style={{
              cursor: 'pointer',
              color: Colors.gray,
            }}
          >
            {day}
          </Day>
        ))}
      </CalendarBody>
    </div>
  );
};
