import {
  useLoadAllClinicsQuery,
  useLoadRegionsQuery,
  useLoadStaffScheduleQuery,
} from '@bc/codegen/medical';
import { useFormikContext } from 'formik';
import { DateTime, Interval } from 'luxon';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0Id } from './react-auth0';

const getSize = () => ({
  innerHeight: window?.innerHeight,
  innerWidth: window?.innerWidth,
  outerHeight: window?.outerHeight,
  outerWidth: window?.outerWidth,
});

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window?.addEventListener('resize', handleResize);
    return () => {
      window?.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}

export const useScrollToErrorWithinModal = ({
  ariaLabel,
}: {
  ariaLabel: string;
}) => {
  const { errors, isSubmitting, isValidating, submitCount } =
    useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const errorKeys = Object.keys(errors);
      const selector = `[aria-label='${ariaLabel}']`;
      const scrollingElement = document.querySelectorAll(selector)?.[0];

      if (!!errorKeys.length && scrollingElement) {
        const topMostError = Object.keys(errors)
          .map((name) => {
            const element =
              document.getElementsByName(name)?.[0] ||
              document.getElementById(name);
            return element?.getBoundingClientRect()?.top - 100 ?? 0;
          })
          .sort((a, b) => a - b)[0];

        scrollingElement.scrollTo({
          top: topMostError,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [ariaLabel, errors, isSubmitting, isValidating, submitCount]);
};

export const useScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    const modal = document.querySelectorAll('[role="dialog"]')?.[0];
    if (modal) {
      modal.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};

export const useScheduledClinic = () => {
  const userId = useAuth0Id();
  const now = DateTime.local();
  const startOfDay = now.startOf('day');
  const endOfDay = now.endOf('day');

  const { data: scheduleData } = useLoadStaffScheduleQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        _and: [
          { staff: { auth0UserId: { _eq: userId } } },
          { startDateTime: { _gt: startOfDay.toSQL() } },
          { endDateTime: { _lt: endOfDay.toSQL() } },
        ],
      },
    },
  });
  const schedule = scheduleData?.staff_schedule ?? [];

  const currentClinic = schedule.find((s) => {
    const start = DateTime.fromISO(s.startDateTime).minus({ hour: 1 });
    const end = DateTime.fromISO(s.endDateTime).plus({ hour: 1 });
    return Interval.fromDateTimes(start, end).contains(now);
  });

  const { data: clinicData } = useLoadAllClinicsQuery();
  const clinics = clinicData?.clinics ?? [];

  return (
    clinics.find((c) => c.id === currentClinic?.clinicId) ??
    clinics.find((c) => c.clinicCode === 'ne-super1')
  );
};

export const useScheduledRegion = () => {
  const clinic = useScheduledClinic();
  const { data } = useLoadRegionsQuery({
    variables: {
      where: {
        clinics_regions: {
          clinicId: { _eq: clinic?.id },
        },
      },
    },
    skip: !clinic?.id,
  });

  const regions = data?.regions ?? [];
  return regions[0];
};
