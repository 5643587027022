import {
  StaffFragment,
  StaffServiceFragment,
  Staff_Type_Enum,
} from '@bc/codegen/manager';
import { STATES, TIME_ZONES } from '@bc/shared';
import { startCase } from 'lodash';

interface Staff extends Omit<StaffFragment, '__typename'> {
  staff_services?: Omit<StaffServiceFragment, '__typename'>[];
}

interface StaffCondensed {
  avatarUrl?: string | null;
  firstName: string;
  lastName: string;
  staff_type: {
    value: string;
  };
}

export const preNominals = (staff: Staff | StaffCondensed) => {
  return staff.staff_type?.value === 'PHYSICIAN' ? 'Dr. ' : '';
};

export const StaffTypeDisplay: Record<Staff_Type_Enum, string> = {
  PHYSICIAN: 'Physician',
  PHYSICIAN_ASSISTANT: 'Physician Assistant',
  MEDICAL_ASSISTANT: 'Medical Assistant',
  NURSE_PRACTITIONER: 'Nurse Practitioner',
  TRIAGE_NURSE: 'Triage Nurse',
  OPERATION: 'Operations',
  UNKNOWN: 'Unknown',
};

export const postNominals = (staff: Staff | StaffCondensed) => {
  const initials: any = {
    PHYSICIAN: 'MD',
    PHYSICIAN_ASSISTANT: 'PA-C',
    MEDICAL_ASSISTANT: 'MA',
    NURSE_PRACTITIONER: 'NP',
    TRIAGE_NURSE: 'RN',
    OPERATION: '',
    UNKNOWN: '',
  }[staff.staff_type?.value as Staff_Type_Enum];

  if (!initials) {
    return '';
  } else {
    return `, ${initials}`;
  }
};

export const fullStaffName = (
  staff?: Staff | StaffCondensed,
  includePreNominals = false,
  includePostNominals = true,
) => {
  if (!staff) return '';
  return `${includePreNominals ? preNominals(staff) : ''}${staff.firstName} ${
    staff.lastName
  }${includePostNominals ? postNominals(staff) : ''}`;
};

interface NormalizedStaffFragment extends Omit<Staff, 'type'> {
  formalFullName: string;
  fullState?: string;
  informalFullName: string;
  services?: string;
  type: string;
}

export const normalizedStaffInfo = (staff: Staff): NormalizedStaffFragment => {
  return {
    ...staff,
    firstName: staff.firstName || '',
    lastName: staff.lastName || '',
    formalFullName: fullStaffName(staff) || '',
    informalFullName: `${staff.firstName} ${staff.lastName}` || '',
    email: staff.email?.toLowerCase() || '',
    services: staff.staff_services
      ?.map((s) => startCase(s.service?.toLowerCase().replace(/_/g, ' ')))
      .join(', '),
    state: startCase(staff.state || ''),
    // @ts-ignore
    fullState: STATES[staff.state?.toLowerCase()],
    // @ts-ignore
    timeZone: TIME_ZONES[staff.timeZone] || '',
    type: startCase(staff.type?.toLowerCase() || ''),
  };
};
