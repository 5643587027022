/** @jsx jsx */
import { AuditFragment, useAuditLogQuery } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import { DialogOverlay } from '@reach/dialog';
import { DateTime } from 'luxon';
import React, { Fragment, useState } from 'react';
import { OutlineButton } from '../button';
import { Entry } from './Entry';
import {
  Avatar,
  EntryText,
  ModalClose,
  ModalContent,
  ModalTitle,
  Row,
  SmallModal,
  TimelineSegment,
  Timestamp,
} from './styles';

const AppointmentStaffItem = ({ audit }: { audit: AuditFragment }) => {
  // This can also be "staff added by an actual caregiver" however this will just be during normal scheduling flow
  const staffName = audit.staff
    ? `${audit.staff?.firstName} ${audit.staff?.lastName}`
    : 'Admin';
  let action = '';

  if (audit.operation === 'INSERT') {
    action = 'added';
  } else if (audit.operation === 'UPDATE') {
    action = 'changed';
  } else if (audit.operation === 'DELETE') {
    action = 'removed';
  }
  const time = DateTime.fromJSDate(new Date(audit.created_at));

  return (
    <Row>
      <Avatar
        src={
          audit.staff?.avatarUrl ??
          'https://bravecare.imgix.net/staff/empty.png'
        }
      />
      <div>
        <EntryText css={{ marginBottom: 1 }}>
          <EntryText css={{ color: '#647977' }}>
            Staff {action} by&nbsp;
          </EntryText>
          <EntryText css={{ fontWeight: 500 }}>{staffName}</EntryText>
        </EntryText>
        <Timestamp>{time.toLocaleString(DateTime.DATETIME_SHORT)}</Timestamp>
      </div>
    </Row>
  );
};

const AppointmentAuditLogModal = ({
  appointmentId,
  isOpen,
  onClose,
}: {
  appointmentId: string;
  isOpen: boolean;
  onClose: (e?: React.SyntheticEvent<Element, Event>) => void;
}) => {
  const { data } = useAuditLogQuery({
    variables: {
      where: {
        table: {
          _in: ['appointments', 'appointment_staff'],
        },
        _or: [
          {
            to: {
              _contains: {
                id: appointmentId,
              },
            },
          },
          {
            to: {
              _contains: {
                appointmentId,
              },
            },
          },
          {
            from: {
              _contains: {
                id: appointmentId,
              },
            },
          },
          {
            from: {
              _contains: {
                appointmentId,
              },
            },
          },
        ],
      },
      order_by: [{ created_at: 'desc' }],
    },
  });

  const audit = data?.audit_logs ?? [];

  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={onClose}
      style={{
        zIndex: 100,
      }}
    >
      <SmallModal aria-label="Activity Log">
        <ModalClose
          onClick={onClose}
          css={{ position: 'absolute', top: 24, right: 24 }}
        />
        <ModalTitle>Activity Log</ModalTitle>
        <ModalContent css={{ paddingBottom: 24 }}>
          {audit?.map((audit, index) => {
            if (audit.table === 'appointments') {
              return (
                <Fragment key={audit.id}>
                  {index > 0 && <TimelineSegment />}
                  <Entry audit={audit} />
                </Fragment>
              );
            } else if (audit.table === 'appointment_staff') {
              return (
                <Fragment key={audit.id}>
                  {index > 0 && <TimelineSegment />}
                  <AppointmentStaffItem audit={audit} />
                </Fragment>
              );
            } else {
              return null;
            }
          })}
        </ModalContent>
      </SmallModal>
    </DialogOverlay>
  );
};

export const AppointmentAuditLog: React.FC<{ appointmentId: string }> = ({
  appointmentId,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <OutlineButton
        css={{
          height: 32,
          padding: '8px 6px',
          width: 32,
        }}
        icon="auditLog"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
      />
      <AppointmentAuditLogModal
        isOpen={isOpen}
        onClose={(e) => {
          e?.preventDefault();
          setIsOpen(false);
        }}
        appointmentId={appointmentId}
      />
    </Fragment>
  );
};
