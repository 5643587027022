/** @jsx jsx */
import {
  DashboardAppointmentFragment,
  useGetClinicByCodeQuery,
  useNewScheduleSubscription,
  useRoomsByClinicSubscription,
  useWatchAppointmentBlocksSubscription,
} from '@bc/codegen/medical';
import { Spacing } from '@bc/theme';
import { jsx } from '@emotion/core';
import useComponentSize from '@rehooks/component-size';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import React, { Fragment, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { ICONS } from '../assets';
import CaretDown from '../assets/caret-down.svg';
import HandWaveIcon from '../assets/hand-wave.svg';
import { hasRole } from '../auth';
import AppointmentBlocker from '../components/AppointmentBlocker/AddAppointmentBlock';
import { Badge } from '../components/Badge/Badge';
import { OutlineButtonDark } from '../components/button';
import {
  CalendarControl,
  CalendarControls,
  CalendarSelector,
  Next,
  Prev,
} from '../components/CalendarSelector';
import { CheckboxFilter } from '../components/CheckboxFilter/CheckboxFilter';
import { SingleClinicRegionDropdown } from '../components/ClinicSelector/SingleClinicRegionDropdown';
import { Checkbox } from '../components/form';
import { Header } from '../components/Header';
import { Content, InnerWrap, MainContent } from '../components/layout';
import { Sidebar } from '../components/Nav/Sidebar';
import Schedule from '../components/Schedule/Schedule';
import { LargeTitle, PrimaryText } from '../components/text';
import { useAuth0 } from '../react-auth0';
import { IF_TABLET, TABLET_WIDTH } from '../styles';
import {
  DEFAULT_STATUSES,
  DEFAULT_VISIT_TYPES,
  getAppointmentDisplay,
  getBlockDisplay,
  getDisplayDate,
} from './Dashboard.helpers';
import {
  CalendarContainer,
  CreateButton,
  DayHeader,
  DropContainer,
  DropContainerWrap,
  DropdownButtonContainer,
  Filterable,
  FilterableText,
  FilterContainer,
  FormattedDay,
  LeftSide,
  OutlineButtonLink,
  RightSide,
  ScheduleContent,
  TextButtonLink,
  TextButtonText,
  TextDropdownButton,
  TitleContainer,
} from './Dashboard.styles';

const Dashboard: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  const [selectedClinic, setSelectedClinic] = useLocalStorage(
    'selectedClinic',
    'ne-super1',
  );

  const ref = useRef(null);
  const { width } = useComponentSize(ref);
  const condense = width < TABLET_WIDTH;

  const [showFilters, setShowFilters] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showBlocks, setShowBlocks] = useState(false);

  const { accessToken } = useAuth0();
  const isManager = hasRole('manager', accessToken);
  const context = { role: isManager ? 'manager' : 'assistant' };

  const { data: clinicData } = useGetClinicByCodeQuery({
    context,
    variables: {
      clinicCode: selectedClinic!,
    },
    skip: !selectedClinic,
  });

  const clinic = clinicData?.clinics?.[0];

  const { data: roomsData } = useRoomsByClinicSubscription({
    variables: {
      clinicId: clinic?.id!,
    },
    skip: !clinic?.id,
  });

  const rooms = roomsData?.rooms ?? [];

  const [displayDate, setDisplayDate] = useState<DateTime>(DateTime.local());
  const [selectedVisitTypes = [], setSelectedVisitTypes] = useLocalStorage<
    string[]
  >(
    'selected_visit_types_v3',
    DEFAULT_VISIT_TYPES.map((s) => s.value),
  );

  const [selectedStatuses = [], setSelectedStatuses] = useLocalStorage<
    string[]
  >(
    'selected_statuses_v3',
    DEFAULT_STATUSES.map((s) => s.value),
  );

  const [showTimeBlocks = true, setShowTimeBlocks] = useLocalStorage<boolean>(
    'show_time_blocks',
    true,
  );

  const handleVisitTypeFilterChange = (visitTypeOption: string) => {
    let updatedCheckboxes: any[] = [];
    if (selectedVisitTypes.includes(visitTypeOption)) {
      updatedCheckboxes = selectedVisitTypes.filter(
        (c) => c !== visitTypeOption,
      );
    } else {
      updatedCheckboxes = [...selectedVisitTypes, visitTypeOption];
    }
    setSelectedVisitTypes(updatedCheckboxes);
  };

  const handleStatusFilterChange = (statusOption: string) => {
    let updatedCheckboxes: any[] = [];
    if (selectedStatuses.includes(statusOption)) {
      updatedCheckboxes = selectedStatuses.filter((c) => c !== statusOption);
    } else {
      updatedCheckboxes = [...selectedStatuses, statusOption];
    }
    setSelectedStatuses(updatedCheckboxes);
  };

  const startOfDayLoad = displayDate
    .startOf('day')
    .toSQL({ includeZone: false, includeOffset: false });
  const endOfDayLoad = displayDate
    .endOf('day')
    .toSQL({ includeZone: false, includeOffset: false });

  const { data: blockData } = useWatchAppointmentBlocksSubscription({
    variables: {
      where: {
        _and: [
          {
            startDateTime: {
              _gte: startOfDayLoad,
            },
          },
          {
            startDateTime: {
              _lte: endOfDayLoad,
            },
          },
        ],
        clinic: {
          clinicCode: {
            _eq: selectedClinic,
          },
        },
      },
    },
  });

  const { data } = useNewScheduleSubscription({
    variables: {
      startTime: startOfDayLoad,
      endTime: endOfDayLoad,
      clinicCode: selectedClinic!,
    },
  });

  const appointments: DashboardAppointmentFragment[] = data?.appointments || [];

  const appointmentCount =
    appointments.filter(({ status }) => {
      return status !== 'CANCELED' && status !== 'NO_SHOW';
    }).length ?? 0;

  const appointmentsByVisitType = () =>
    DEFAULT_VISIT_TYPES.map((option) => {
      option.count = appointments.filter((appt) => {
        if (option.groupName === 'visitClassification') {
          return (
            appt.visitClassification === option.value &&
            appt.status !== 'NO_SHOW' &&
            appt.status !== 'CANCELED'
          );
          // If it's a covid test then don't include in URGENT_CARE types
          // Hack for now
        } else if (
          appt.visitType === 'URGENT_CARE' &&
          appt.visitClassification === 'COVID_19_TEST'
        ) {
          return false;
        } else {
          return (
            appt.visitType === option.value &&
            appt.status !== 'NO_SHOW' &&
            appt.status !== 'CANCELED'
          );
        }
      }).length;
      return option;
    });

  const appointmentsByStatus = () =>
    DEFAULT_STATUSES.map((option) => {
      option.count = appointments.filter(
        (appt) => appt.status === option.value,
      ).length;
      return option;
    });

  const appointmentDisplay = sortBy(
    appointments && getAppointmentDisplay(appointments),
    'status',
  )
    .reverse()
    .filter((appt) => {
      // Handle one edge cae for COVID_19_TEST
      if (appt.visitClassification === 'COVID_19_TEST' && appt.status) {
        const isCovidTest = selectedVisitTypes.includes(
          appt.visitClassification,
        );
        return isCovidTest && selectedStatuses.includes(appt.status);
      }

      return (
        appt.visitType &&
        appt.status &&
        selectedVisitTypes.includes(appt.visitType) &&
        selectedStatuses.includes(appt.status)
      );
    });

  const blocks = blockData?.appointment_blocks ?? [];
  const blockDisplay = getBlockDisplay(blocks);

  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap>
          <Sidebar />
          <MainContent ref={ref}>
            <TitleContainer condense={condense}>
              <LeftSide>
                <LargeTitle css={{ marginRight: 8 }}>Appointments</LargeTitle>
              </LeftSide>
              <RightSide condense={condense}>
                <SingleClinicRegionDropdown
                  clinicCode={selectedClinic!}
                  onSelect={(clinicCode) => {
                    setSelectedClinic(clinicCode);
                  }}
                />
                <OutlineButtonLink
                  to="/greet"
                  css={{
                    margin: '0 16px 8px 8px',
                    [IF_TABLET]: { margin: '0 16px 0 8px' },
                  }}
                >
                  <img
                    src={HandWaveIcon}
                    alt="Greet Patient"
                    css={{
                      width: '18px',
                      height: '18px',
                      marginRight: '8px',
                    }}
                  />
                  <span>Greet Patient</span>
                </OutlineButtonLink>
                <DropContainerWrap>
                  <CreateButton>
                    <img
                      src={ICONS.addWhite}
                      alt="+"
                      css={{
                        width: '18px',
                        height: '18px',
                        marginRight: '8px',
                      }}
                    />
                    <span>Create</span>
                  </CreateButton>
                  <DropContainer css={{ width: 264 }}>
                    <TextButtonLink
                      to="/appointment/add"
                      css={{ ':hover img': { filter: 'none' } }}
                    >
                      <img
                        src={ICONS.newVisit}
                        alt="+"
                        css={{
                          width: '24px',
                          height: '24px',
                          marginRight: '8px',
                        }}
                      />
                      <TextButtonText>New Visit</TextButtonText>
                    </TextButtonLink>
                    <TextDropdownButton
                      css={{ ':hover img': { filter: 'none' } }}
                      onClick={() => {
                        setShowBlocks(true);
                      }}
                    >
                      <img
                        src={ICONS.timeBlock}
                        alt="+"
                        css={{
                          width: '24px',
                          height: '24px',
                          marginRight: '8px',
                        }}
                      />
                      <TextButtonText>Time Block</TextButtonText>
                    </TextDropdownButton>
                  </DropContainer>
                </DropContainerWrap>
              </RightSide>
            </TitleContainer>

            <ScheduleContent>
              <DayHeader>
                <CalendarControls>
                  <CalendarControl
                    onClick={() =>
                      setDisplayDate(displayDate.minus({ day: 1 }))
                    }
                  >
                    <Prev />
                  </CalendarControl>
                  <CalendarControl
                    onClick={() => setDisplayDate(displayDate.plus({ day: 1 }))}
                  >
                    <Next />
                  </CalendarControl>
                </CalendarControls>
                <FormattedDay
                  css={{
                    margin: '0 16px 0 0',
                    [IF_TABLET]: { margin: 0 },
                  }}
                >
                  {width < TABLET_WIDTH
                    ? getDisplayDate(displayDate).shortDate
                    : getDisplayDate(displayDate).date}
                  <Badge css={{ marginLeft: 8 }}>
                    {appointmentCount}{' '}
                    {appointmentCount !== 1 ? 'visits' : 'visit'}
                  </Badge>
                </FormattedDay>

                <DropdownButtonContainer condense={condense}>
                  <OutlineButtonDark
                    css={{
                      borderRadius: '100px',
                      minWidth: '88px',
                    }}
                    onClick={() => {
                      setDisplayDate(DateTime.local());
                    }}
                  >
                    Today
                  </OutlineButtonDark>
                  <Filterable
                    onMouseEnter={() => setShowFilters(true)}
                    onMouseLeave={() => setShowFilters(false)}
                    onClick={() => setShowFilters(true)}
                    tabIndex={0}
                    css={condense ? {} : { margin: '0 0 0 8px' }}
                    type="button"
                    highlight={!!showFilters}
                  >
                    <FilterableText>
                      <span>Filters</span>
                      <img
                        alt=""
                        src={CaretDown}
                        css={{
                          width: '16px',
                          marginLeft: '16px',
                        }}
                      />
                    </FilterableText>
                    {showFilters && (
                      <div css={FilterContainer}>
                        <CheckboxFilter
                          title="Appointments"
                          count={appointmentCount}
                          onChange={(value) =>
                            handleVisitTypeFilterChange(value)
                          }
                          checkboxOptions={appointmentsByVisitType()}
                          selectedOptions={selectedVisitTypes}
                          onToggleAll={() =>
                            selectedVisitTypes.length === 0
                              ? setSelectedVisitTypes(
                                  appointmentsByVisitType().map(
                                    (appt) => appt.value,
                                  ),
                                )
                              : setSelectedVisitTypes([])
                          }
                          style={{ padding: 24 }}
                        />
                        <CheckboxFilter
                          title="Statuses"
                          onChange={(value) => handleStatusFilterChange(value)}
                          checkboxOptions={appointmentsByStatus()}
                          selectedOptions={selectedStatuses}
                          onToggleAll={() =>
                            selectedStatuses.length === 0
                              ? setSelectedStatuses(
                                  appointmentsByStatus().map(
                                    (appt) => appt.value,
                                  ),
                                )
                              : setSelectedStatuses([])
                          }
                          style={{
                            padding: 24,
                          }}
                        />
                        <div
                          css={{
                            padding: 24,
                          }}
                        >
                          <div
                            css={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginBottom: Spacing.s,
                            }}
                            onClick={() => handleStatusFilterChange('CANCELED')}
                          >
                            <PrimaryText css={{ fontWeight: 600 }}>
                              Cancellations
                            </PrimaryText>
                            <Checkbox
                              checked={selectedStatuses.includes('CANCELED')}
                            />
                          </div>
                          <div
                            css={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginBottom: Spacing.s,
                            }}
                            onClick={() => handleStatusFilterChange('NO_SHOW')}
                          >
                            <PrimaryText css={{ fontWeight: 600 }}>
                              No Shows
                            </PrimaryText>
                            <Checkbox
                              checked={selectedStatuses.includes('NO_SHOW')}
                            />
                          </div>
                          <div
                            css={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                            onClick={() => {
                              setShowTimeBlocks(!showTimeBlocks);
                            }}
                          >
                            <PrimaryText css={{ fontWeight: 600 }}>
                              Time Blocks
                            </PrimaryText>
                            <Checkbox checked={showTimeBlocks} />
                          </div>
                        </div>
                      </div>
                    )}
                  </Filterable>
                  <Filterable
                    onMouseEnter={() => setShowCalendar(true)}
                    onMouseLeave={() => setShowCalendar(false)}
                    onClick={() => setShowCalendar(true)}
                    tabIndex={0}
                    css={{ marginLeft: 8 }}
                    type="button"
                    highlight={!!showCalendar}
                  >
                    <FilterableText>
                      <span>Calendar</span>
                      <img
                        alt=""
                        src={CaretDown}
                        css={{
                          width: '16px',
                          marginLeft: '16px',
                        }}
                      />
                    </FilterableText>
                    {showCalendar && (
                      <CalendarSelector
                        value={displayDate.toJSDate()}
                        onChange={(value) => {
                          setDisplayDate(DateTime.fromJSDate(value));
                        }}
                        css={[FilterContainer, CalendarContainer]}
                      />
                    )}
                  </Filterable>
                </DropdownButtonContainer>
              </DayHeader>
              {clinic && (
                <Schedule
                  key={selectedClinic}
                  date={displayDate}
                  displayItems={[
                    ...appointmentDisplay,
                    ...(showTimeBlocks ? blockDisplay : []),
                  ]}
                  clinic={clinic}
                  rooms={rooms}
                  onAppointmentClick={(appointmentId) => {
                    history.push(`appointment/${appointmentId}/visit`);
                  }}
                  onSlotClick={(date) => {
                    history.push('/appointment/add', {
                      dateTime: date.toSQL({
                        includeOffset: false,
                        includeZone: false,
                      }),
                    });
                  }}
                />
              )}
            </ScheduleContent>
          </MainContent>
        </InnerWrap>
      </Content>
      {showBlocks && (
        <AppointmentBlocker
          clinic={clinic}
          date={displayDate}
          onCreate={() => {
            setShowBlocks(false);
          }}
          onDismiss={() => {
            setShowBlocks(false);
          }}
        />
      )}
    </Fragment>
  );
};

export default Dashboard;
