import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { PrimaryText } from '../text';

export const NameWrapper = styled.div({
  display: 'flex',
  width: '100%',
});

export const Name = styled(PrimaryText)({
  alignItems: 'center',
  color: Colors.darkHarbor,
  display: 'flex',
  fontSize: 14,
  fontWeight: 'bold',
  letterSpacing: '0.1px',
  lineHeight: '17px',
  marginBottom: 8,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: 'calc(100% - 24px)',
});

export const DateOfBirth = styled(Name)({
  color: Colors.gray,
  fontSize: 14,
  letterSpacing: '0.1px',
  lineHeight: '17px',
});

export const ApptTime = styled(PrimaryText)({
  color: '#94A2A1',
  fontSize: 14,
  letterSpacing: '0.1px',
  lineHeight: '17px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const ApptTimeInline = styled(ApptTime)({
  marginLeft: '4px',
});

export const Reason = styled(PrimaryText)({
  color: '#94A2A1',
  display: 'none',
  fontSize: 14,
  letterSpacing: '0.1px',
  lineHeight: '17px',
  marginTop: 8,
  wordBreak: 'break-all',
  whiteSpace: 'break-spaces',
});

export const Box = styled.div(
  {
    border: `1px solid ${Colors.grayLighter}`,
    borderRadius: 8,
    cursor: 'pointer',
    display: 'flex',
    height: 'auto',
    minHeight: '100%',
    transition: 'box-shadow 500ms',
  },
  ({ condense }: { condense: boolean }) => {
    return {
      padding: condense ? '4px 8px' : '8px 10px',
    };
  },
);

export const Wrap = styled.div({
  position: 'absolute',
  zIndex: 5,
  ':hover': {
    zIndex: 6,
    [`& ${Box}`]: {
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
    },
    [`& ${Reason}`]: {
      display: 'block',
    },
  },
});
