/** @jsx jsx */
import {
  PatientFragment,
  useFindKareoPatientMutation,
  useUpdatePatientByPkMutation,
  useWatchForKareoActionsSubscription,
} from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import * as Sentry from '@sentry/react';
import React, { Fragment, useEffect, useState } from 'react';
import FindPatient from '../../components/Kareo/FindPatient';
import {
  getPatientDOB,
  getPatientList,
  useKareoTransitions,
} from '../../components/Kareo/hooks';
import KareoActivity from '../../components/Kareo/KareoActivity';
import KareoDocumentReview from '../../components/Kareo/KareoDocumentReview';
import KareoDocumentSection from '../../components/Kareo/KareoDocumentSection';
import KareoPatientList from '../../components/Kareo/KareoPatientList';
import KareoPatientUpdateReview from '../../components/Kareo/KareoPatientReview';
import KareoPatientUpdateSection from '../../components/Kareo/KareoPatientUpdateSection';
import KareoLinkedBanner from '../../components/Kareo/LinkedBanner';
import { SectionTitle } from '../../components/text';
import { fiveMinutesAgo } from '../../helpers';

const Kareo: React.FC<{ patient: PatientFragment }> = ({ patient }) => {
  const hasKareoAttach = !!(patient.kareo_guid && patient.kareo_patient_id);
  const [attached, setAttached] = useState(hasKareoAttach);
  const {
    sectionType,
    setSectionType,
    sectionOpen,
    setSectionOpen,
    successTypes,
    setSuccessType,
  } = useKareoTransitions('PATIENT');

  const [findKareoPatients, { data: kareoPatients, loading: findLoading }] =
    useFindKareoPatientMutation();

  const [updatePatient] = useUpdatePatientByPkMutation();
  const [fiveMinAgo, setFiveMinAgo] = useState(fiveMinutesAgo());
  const { data: kareoActionsData } = useWatchForKareoActionsSubscription({
    variables: {
      query_start: fiveMinAgo,
    },
  });

  // update the five-minutes-ago timestamp for the subscription every minute so the component re-renders
  // in case a recent Kareo Action never updated to status='COMPLETE'
  useEffect(() => {
    const interval = setInterval(() => {
      setFiveMinAgo(fiveMinutesAgo());
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const otherActionsRunning = !!kareoActionsData?.kareo_logs?.[0]?.id;

  const loading = findLoading || otherActionsRunning;

  const isUnlinked =
    !hasKareoAttach && !attached && !kareoPatients?.FindKareoPatient;

  const patients = getPatientList(kareoPatients);

  const handleUnlink = async () => {
    try {
      await updatePatient({
        variables: {
          id: patient.id,
          _set: {
            kareo_guid: null,
            kareo_patient_id: null,
          },
        },
      });
      // force reload for now since we don't subscripe to patient updates.
      window.location.reload();
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  return (
    <div
      style={{
        padding: '32px 0',
      }}
    >
      {loading && <KareoActivity />}

      {!loading && (
        <Fragment>
          <div
            css={{
              marginBottom: 16,
            }}
          >
            <SectionTitle>Kareo Sync</SectionTitle>
            {!!patient.kareo_patient_id && (
              <KareoLinkedBanner
                kareoPatientId={patient.kareo_patient_id}
                onUnlink={handleUnlink}
              />
            )}
          </div>
          {isUnlinked && (
            <FindPatient
              onFindClick={async () => {
                await findKareoPatients({
                  variables: {
                    name: `${patient.firstName} ${patient.lastName}`,
                    dateOfBirth: getPatientDOB(patient.dateOfBirth),
                  },
                });
              }}
            />
          )}

          {!attached && Array.isArray(kareoPatients?.FindKareoPatient) && (
            <KareoPatientList
              patientId={patient.id}
              onAttach={() => {
                setAttached(true);
              }}
              patients={patients}
            />
          )}

          {attached && (
            <Fragment>
              {!sectionType && (
                <Fragment>
                  <div style={{ paddingBottom: '24px' }}>
                    <KareoPatientUpdateSection
                      open={sectionOpen === 'PATIENT'}
                      onToggle={() => {
                        const section =
                          sectionOpen === 'PATIENT' ? undefined : 'PATIENT';
                        setSectionOpen(section);
                      }}
                      onSectionSelect={() => {
                        setSectionType('PATIENT');
                      }}
                      success={successTypes.includes('PATIENT')}
                    />
                  </div>
                  <div style={{ paddingBottom: '24px' }}>
                    <KareoDocumentSection
                      open={sectionOpen === 'DOCUMENT'}
                      onToggle={() => {
                        const section =
                          sectionOpen === 'DOCUMENT' ? undefined : 'DOCUMENT';
                        setSectionOpen(section);
                      }}
                      onSectionSelect={() => {
                        setSectionType('DOCUMENT');
                      }}
                      success={successTypes.includes('DOCUMENT')}
                    />
                  </div>
                </Fragment>
              )}

              {sectionType === 'PATIENT' && (
                <KareoPatientUpdateReview
                  patient={patient}
                  patientId={patient.id}
                  onCancel={() => {
                    setSectionType(undefined);
                  }}
                  onSuccess={() => {
                    setSuccessType((state) => [...state, 'PATIENT']);
                    setSectionType(undefined);
                    if (!successTypes.includes('DOCUMENT')) {
                      setSectionOpen('DOCUMENT');
                    }
                  }}
                />
              )}
              {sectionType === 'DOCUMENT' && (
                <KareoDocumentReview
                  patientId={patient.id}
                  onCancel={() => {
                    setSectionType(undefined);
                  }}
                  onSuccess={() => {
                    setSuccessType((state) => [...state, 'DOCUMENT']);
                    setSectionType(undefined);
                    setSectionOpen(undefined);
                  }}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Kareo;
