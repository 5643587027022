/** @jsx jsx */
import { useGetAccountByPkQuery } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import { Menu, MenuItem } from '@reach/menu-button';
import { ICONS } from '../../assets';
import { formatDateOfBirth, getDateOfBirth } from '../../helpers';
import { PatientAvatarDisplay } from '../AppointmentDetails/AvatarDisplay';
import { PrimaryText } from '../text';
import {
  DropdownMenuList,
  DropdownText,
  PillMenuButton,
  SecondaryDropdownText,
} from './ui';

export const TaskPatientDropdown = ({
  accountId,
  onSelect,
}: {
  accountId: string;
  onSelect: (patientId: string) => void;
}) => {
  const { data: accountData } = useGetAccountByPkQuery({
    variables: {
      accountId: accountId,
    },
    skip: !accountId,
  });
  const account = accountData?.accounts_by_pk;
  const accountPatients = account?.account_patients ?? [];
  return (
    <Menu>
      <PillMenuButton icon={ICONS.profile}>Link Patient</PillMenuButton>
      <DropdownMenuList portal={false}>
        {accountPatients.map((accountPatient) => {
          const patient = accountPatient?.patient;

          const dob =
            patient?.dateOfBirth &&
            `${formatDateOfBirth(patient?.dateOfBirth)} (${getDateOfBirth({
              date: patient?.dateOfBirth,
            })})`;

          return (
            <MenuItem
              onSelect={() => {
                onSelect(accountPatient.patientId);
              }}
              css={{
                padding: '16px 24px',
                borderBottom: '1px solid #E5E8E8',
                display: 'flex',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <PatientAvatarDisplay patientId={patient.id} size={40} />
              <div
                css={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0 16px',
                }}
              >
                <DropdownText
                  css={{
                    marginBottom: '4px',
                  }}
                >
                  {patient.firstName} {patient.lastName}
                </DropdownText>
                <SecondaryDropdownText>{dob}</SecondaryDropdownText>
              </div>
              <div
                css={{
                  background: '#E5E8E8',
                  borderRadius: '100px',
                  padding: '3px 12px',
                }}
              >
                <PrimaryText css={{ color: '#647977', fontSize: '12px' }}>
                  Patient
                </PrimaryText>
              </div>
            </MenuItem>
          );
        })}
      </DropdownMenuList>
    </Menu>
  );
};
