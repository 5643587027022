/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Menu, MenuItem } from '@reach/menu-button';
import { ICONS } from '../../assets';
import { SmallTitle } from '../text';
import { DropdownMenuList, GreenMenuButton } from './ui';

type SelectType = 'VISIT' | 'TASK';

export const TaskCreateDropdown = ({
  onSelect,
  display,
}: {
  display: SelectType[];
  onSelect: (type: SelectType) => void;
}) => (
  <Menu>
    <GreenMenuButton data-testid="create_patient_button">
      <img alt="add" src={ICONS.addWhite} />
      <span css={{ marginLeft: '4px' }}>Create</span>
    </GreenMenuButton>
    <DropdownMenuList css={{ minWidth: '264px' }}>
      {display.includes('VISIT') && (
        <MenuItem
          onSelect={() => {
            onSelect('VISIT');
          }}
          css={{
            padding: '16px 24px',
            borderBottom: '1px solid #E5E8E8',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <img
            alt="new visit"
            src={ICONS.newVisit}
            css={{
              width: '24px',
              height: '24px',
            }}
          />
          <SmallTitle css={{ marginLeft: '16px', fontWeight: 500 }}>
            Visit
          </SmallTitle>
        </MenuItem>
      )}
      {display.includes('TASK') && (
        <MenuItem
          onSelect={() => {
            onSelect('TASK');
          }}
          data-testid="create_task_dropdown_button"
          css={{
            padding: '16px 24px',
            borderBottom: '1px solid #E5E8E8',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <img
            alt="task"
            src={ICONS.task}
            css={{
              width: '24px',
              height: '24px',
            }}
          />
          <SmallTitle css={{ marginLeft: '16px', fontWeight: 500 }}>
            Task
          </SmallTitle>
        </MenuItem>
      )}
    </DropdownMenuList>
  </Menu>
);
