/** @jsx jsx */
import { ProductFragment, useGetProductsQuery } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import Downshift from 'downshift';
import fuzzaldrin from 'fuzzaldrin-plus';
import React, { useState } from 'react';
import { ICONS } from '../../assets';
import { Input, InputWrap } from '../form';
import ProductResults, { Container, ResultsContainer, SearchIcon } from './ui';

const fuzzyFilter = (input: string, products: ProductFragment[]) => {
  return fuzzaldrin.filter(products, input, { key: 'name' });
};

interface Props {
  onChange: (product: ProductFragment | null) => void;
  error: boolean;
  defaultCopayAmount: number;
}

const ProductSearchInput: React.FC<Props> = ({
  onChange,
  error,
  defaultCopayAmount,
}) => {
  const [inputValue, setInputValue] = useState('');
  const { data: productsData } = useGetProductsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const handleChange = (item: ProductFragment | null) => {
    onChange(item);
    //resets the input value after a selection takes place
    setInputValue('');
  };

  const allProducts = productsData?.products ?? [];
  const products = allProducts.map((product) => {
    if (product.type === 'cp') {
      return {
        ...product,
        price: defaultCopayAmount,
      };
    }
    return product;
  });

  return (
    <Downshift
      onChange={handleChange}
      inputValue={inputValue}
      itemToString={(product) => (product ? product.name : '')}
      onInputValueChange={(value) => {
        setInputValue(value);
      }}
      onSelect={handleChange}
      onStateChange={(changes) => {
        const {
          stateChangeTypes: { blurInput },
        } = Downshift;
        // resets the input value on blur
        if (
          changes.type === blurInput &&
          changes.inputValue &&
          changes.inputValue.length > 0
        ) {
          setInputValue('');
        }
      }}
    >
      {({
        getRootProps,
        getInputProps,
        getItemProps,
        isOpen,
        openMenu,
        closeMenu,
        highlightedIndex,
        selectedItem,
      }) => {
        return (
          <Container {...getRootProps()}>
            <ResultsContainer>
              <div>
                <InputWrap style={{ marginBottom: 0 }}>
                  <SearchIcon src={ICONS.search} role="presentation" />
                  <Input
                    {...getInputProps({
                      onFocus: openMenu,
                      type: 'text',
                      placeholder: 'Search for product',
                      autoComplete: 'off',
                      onBlur: () => {
                        closeMenu();
                      },
                    })}
                    css={{
                      paddingLeft: 56,
                      '::placeholder': { color: Colors.gray },
                    }}
                    error={error}
                  />
                </InputWrap>
              </div>
              {isOpen && (
                <ProductResults
                  products={fuzzyFilter(inputValue || '', products).slice(
                    0,
                    10,
                  )}
                  inputValue={inputValue}
                  getItemProps={getItemProps}
                  highlightedIndex={highlightedIndex}
                  selectedItem={selectedItem}
                />
              )}
            </ResultsContainer>
          </Container>
        );
      }}
    </Downshift>
  );
};

export default ProductSearchInput;
