import React from 'react';
import { ICONS } from '../../assets';
import { Button } from '../button';
import { PrimaryText } from '../text';
import KareoSection from './KareoSection';

const KareoDocumentSection = ({
  onSectionSelect,
  open,
  onToggle,
  success,
}: {
  open: boolean;
  onToggle: () => void;
  onSectionSelect: () => void;
  success: boolean;
}) => {
  return (
    <KareoSection
      icon={ICONS.kareoDocumentSync}
      title="Send Documents to Kareo"
      open={open}
      onToggle={onToggle}
      success={success}
    >
      <div
        style={{
          padding: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <PrimaryText
          style={{
            marginBottom: '16px',
          }}
        >
          Send patient documents from Brave Care into Kareo.
        </PrimaryText>
        <Button type="button" onClick={onSectionSelect}>
          Review document updates
        </Button>
      </div>
    </KareoSection>
  );
};

export default KareoDocumentSection;
