import { GlobalNoteFragment } from '@bc/codegen/medical';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React from 'react';
import { ICONS } from '../../assets';
import { formattedTime } from '../../helpers';
import { fullStaffName } from '../../pages/staff/helpers';
import { PrimaryText } from '../text';

export const Flag = ({
  avatarSize,
  children,
  note,
  onDelete,
  style,
}: {
  avatarSize?: number;
  children?: React.ReactNode;
  note: GlobalNoteFragment;
  onDelete?: (note: GlobalNoteFragment) => void;
  style?: React.CSSProperties;
}) => {
  const createdAt = DateTime.fromISO(note.createdAt);
  const staff = note.staff;
  return (
    <Container style={style}>
      <Avatar
        alt={fullStaffName(note.staff ?? undefined) ?? ''}
        size={avatarSize}
        src={staff?.avatarUrl ?? 'https://bravecare.imgix.net/staff/empty.png'}
      />
      <Info>
        <StaffName>{fullStaffName(note.staff ?? undefined)}</StaffName>
        <Timestamp>{formattedTime(createdAt)}</Timestamp>
        <Note>{note.note}</Note>
      </Info>
      {onDelete && (
        <DeleteButton onClick={() => onDelete(note)}>
          <TrashIcon src={ICONS.trash} />
        </DeleteButton>
      )}
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 8px 0;
  position: relative;

  &:hover button {
    display: block;
  }
`;

const Avatar = styled.img<{ size?: number }>(({ size = 32 }) => ({
  height: size,
  width: size,
  borderRadius: size / 2,
  marginRight: 8,
  objectFit: 'contain',
}));

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const StaffName = styled(PrimaryText)`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`;

const Timestamp = styled(PrimaryText)`
  color: #94a2a1;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
  margin-top: 1px;
`;

const Note = styled(PrimaryText)`
  font-size: 14px;
  line-height: 18px;
  word-wrap: anywhere;
`;

const TrashIcon = styled.img`
  height: 24px;
  width: 24px;
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: none;
  position: absolute;
  right: 0;
  top: 8px;
`;
