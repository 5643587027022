import styled from '@emotion/styled';
import { PrimaryText } from '../text';

export const OrderedList = styled.ol`
  list-style: decimal;
  margin: 20px;
  width: 100%;
`;

export const ListItem = styled(PrimaryText)`
  margin: 20px 0;
`.withComponent('li');
