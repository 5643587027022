import React from "react";
import { DateTime } from "luxon";
import { Week, Day, Weekday } from "./styles";

export const WeekdayLabels: React.FC<{
  highlightedDate?: DateTime;
  labelFormat?: "weekdayLong" | "weekdayShort";
}> = ({ highlightedDate, labelFormat = "weekdayShort" }) => {
  const currentStartOfWeek = DateTime.local().startOf("week").minus({ day: 1 });
  const days = Array.from(Array(7).keys());

  return (
    <Week>
      {days.map((d) => {
        const day = currentStartOfWeek.plus({ day: d });
        const matchingDayOfWeek = day?.weekday === highlightedDate?.weekday;
        const weekdayFormat = day[labelFormat];

        return (
          <Day key={`daylabel-${day}`}>
            <Weekday highlighted={matchingDayOfWeek}>{weekdayFormat}</Weekday>
          </Day>
        );
      })}
    </Week>
  );
};
