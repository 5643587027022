import { AppointmentPartsFragment } from '@bc/codegen/medical';
import React from 'react';
import { Caregivers } from '../../components/Patient';

const AppointmentCaregivers: React.FC<{
  appointment: AppointmentPartsFragment;
}> = ({ appointment }) => {
  const patient = appointment?.appointment_patients[0]?.patient;
  return patient ? (
    <Caregivers appointment={appointment} data={patient} />
  ) : null;
};

export default AppointmentCaregivers;
