/** @jsx jsx */
import { useDevicesSubscription } from '@bc/codegen/medical';
import { Spacing } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { startCase } from 'lodash';
import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { ClinicDropdown } from '../components/ClinicSelector';
import { Header } from '../components/Header';
import { Content, InnerWrap, MainContent } from '../components/layout';
import { Sidebar } from '../components/Nav/Sidebar';
import { PrimaryText } from '../components/text';
import { TitleContainer } from './Dashboard.styles';
import { LargeTitle } from './ViewAppointment.styles';

const Devices: React.FC<RouteComponentProps> = () => {
  const [selectedClinic, setSelectedClinic] = useLocalStorage(
    'selectedClinic',
    'ne-super1',
  );

  const { data } = useDevicesSubscription({
    variables: {
      where: { clinicCode: { _eq: selectedClinic } },
      orderBy: [{ name: 'asc' }],
      limit: 100,
    },
    skip: !selectedClinic,
  });

  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap>
          <Sidebar />
          <MainContent style={{ paddingTop: Spacing.xl }}>
            <TitleContainer css={{ justifyContent: 'flex-start' }}>
              <LargeTitle css={{ marginRight: Spacing.xs }}>Devices</LargeTitle>
              <ClinicDropdown
                clinicId={selectedClinic}
                onSelect={(clinic) => {
                  setSelectedClinic(clinic!.clinicCode);
                }}
              />
            </TitleContainer>
            <table style={{ marginTop: Spacing.l, width: '100%' }}>
              <thead>
                <TableRow>
                  <TableHeading style={{ paddingLeft: 0 }}>Name</TableHeading>
                  <TableHeading>OS Version</TableHeading>
                  <TableHeading>App Version</TableHeading>
                  <TableHeading>CodePush Version</TableHeading>
                  <TableHeading>Battery Level</TableHeading>
                  <TableHeading>Battery State</TableHeading>
                  <TableHeading>WIFI Network</TableHeading>
                  <TableHeading>Clinic</TableHeading>
                  <TableHeading>Location</TableHeading>
                </TableRow>
              </thead>
              <tbody>
                {data?.devices.map((d) => (
                  <TableRow key={d.id}>
                    <TableData style={{ paddingLeft: 0, textAlign: 'left' }}>
                      {startCase(d.name ?? '')}
                    </TableData>
                    <TableData>{d.osVersion || ''}</TableData>
                    <TableData>{d.appVersion || ''}</TableData>
                    <TableData>{d.codepushVersion || ''}</TableData>
                    <TableData>
                      {d.batteryLevel && d.batteryLevel > 0
                        ? `${(d.batteryLevel * 100).toFixed()}%`
                        : ''}
                    </TableData>
                    <TableData>{startCase(d.batteryState || '')}</TableData>
                    <TableData>{d.wifiNetwork || ''}</TableData>
                    <TableData>{d.clinic?.name ?? ''}</TableData>
                    <TableData>{startCase(d.location?.name ?? '')}</TableData>
                  </TableRow>
                ))}
              </tbody>
            </table>
          </MainContent>
        </InnerWrap>
      </Content>
    </Fragment>
  );
};

export default Devices;

const TableRow = styled.tr({});

const TableHeading = styled(PrimaryText)({
  fontWeight: 'bold',
  textAlign: 'left',
  padding: Spacing.xs,
}).withComponent('th');

const TableData = styled(PrimaryText)({
  textAlign: 'left',
  padding: Spacing.xs,
}).withComponent('td');
