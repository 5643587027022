import { useUpdateAppointmentStatusMutation } from '@bc/codegen/medical';
import { DialogOverlay } from '@reach/dialog';
import * as Sentry from '@sentry/react';
import React, { useState } from 'react';
import { SmallModal } from '../components/layout';
import { ModalText, ModalTitle } from '../components/text';
import { CancelButton } from '../pages/merge/styles';
import { ButtonRed } from './button';

const CancelAppointmentModal: React.FC<{
  isOpen: boolean;
  appointmentId: string;
  onCancel: () => void;
}> = ({ isOpen, onCancel, appointmentId }) => {
  const [cancelAppointment] = useUpdateAppointmentStatusMutation();
  const [notify, setNotify] = useState(true);
  const handleCancel = async () => {
    try {
      await cancelAppointment({
        variables: {
          id: appointmentId,
          status: 'CANCELED',
          notification: notify,
        },
      });
      onCancel();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={onCancel}
      style={{
        zIndex: 100,
      }}
    >
      <SmallModal aria-label="Cancel Appointment">
        <ModalTitle>
          Are you sure you want to cancel the appointment?
        </ModalTitle>

        <ModalText>
          <label
            htmlFor="notify"
            css={{
              cursor: 'pointer',
            }}
          >
            <input
              type="checkbox"
              id="notify"
              checked={notify}
              data-testid="notify"
              onChange={(e) => {
                setNotify(e.target.checked);
              }}
            />
            Send notification to caregiver?
          </label>
        </ModalText>

        <ButtonRed
          tabIndex={0}
          type="button"
          style={{ display: 'inline-block' }}
          onClick={() => {
            handleCancel();
          }}
        >
          Cancel Appointment
        </ButtonRed>
        <CancelButton style={{ display: 'inline-block' }} onClick={onCancel}>
          Close
        </CancelButton>
      </SmallModal>
    </DialogOverlay>
  );
};

export default CancelAppointmentModal;
