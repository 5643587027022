import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string;
}

const StaffManagement: React.FC<Props> = ({ color, ...props }) => (
  <svg width={25} height={24} viewBox="0 0 25 24" fill="none" {...props}>
    <path
      d="M8 12a4 4 0 100-8 4 4 0 000 8z"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <path
      d="M16 7h.5a3.5 3.5 0 013.5 3.5c0 1.396-.817 2.938-2 3.5"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M15 19v-.544C15 15.033 11.889 12 8 12s-7 3.033-7 6.456V19M18 14c3.046 0 5.163 2.188 5.163 4.625V19"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default StaffManagement;
