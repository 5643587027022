/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Menu, MenuItem } from '@reach/menu-button';
import { useField } from 'formik';
import { ICONS } from '../../assets';
import { PrimaryText } from '../text';
import { getTypeLabel, SUB_TYPES } from './types';
import { BorderMenuButton, DropdownMenuList } from './ui';

export const TaskSubtype = ({ name }: { name: string }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <Menu>
      <BorderMenuButton>
        <PrimaryText css={{ flex: 1, textAlign: 'left' }}>
          {getTypeLabel(field.value)}
        </PrimaryText>
        <img src={ICONS.caretDown} />
      </BorderMenuButton>
      <DropdownMenuList css={{ zIndex: 100000 }}>
        {SUB_TYPES.map(({ value, label }, index) => {
          return (
            <MenuItem
              key={index}
              onSelect={() => {
                helpers.setValue(value);
              }}
              css={{
                padding: '8px 24px',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <PrimaryText css={{ flex: 1 }}>{label}</PrimaryText>
              {field.value === value && <img src={ICONS.checkmarkGrey} />}
            </MenuItem>
          );
        })}
      </DropdownMenuList>
    </Menu>
  );
};
