import React, { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import { ICONS } from '../../assets';
import { PrimaryText } from '../text';
import Toast from '../Toast/toast';

const KareoLinkedToast = ({ linked }: { linked: boolean }) => {
  const prevLinked = usePrevious(linked);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
    }, 3000);
    if (prevLinked === false && linked) {
      setVisible(true);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [linked]);

  if (!visible) {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: '16px',
        width: '50%',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 50,
      }}
    >
      <Toast>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src={ICONS.checkmarkGreen} />
          <PrimaryText style={{ marginLeft: '8px' }}>
            Patient was successfully linked in Kareo.
          </PrimaryText>
        </div>
      </Toast>
    </div>
  );
};

export default KareoLinkedToast;
