/** @jsx jsx */
import {
  AccountFragment,
  useFindAccountQuery,
  useGetAccountByPkQuery,
} from '@bc/codegen/medical';
import { PREFERRED_PRONOUN } from '@bc/shared';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { FormikProps, useFormikContext } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js/min';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { ICONS } from '../../assets';
import { Appointment } from '../../pages/AddAppointment';
import { AccountAvatar } from '../ChatMessage';
import { ErrorText, NormalField, PhoneNumberField } from '../form';
import { CondensedRow as ContentRow, InfoSection } from '../layout';
import {
  DismissButton,
  DismissText,
  Icon,
  PersonRow,
  Row,
  WarningWrapper,
} from '../Patient';
import { SelectButton } from '../SearchForAccountPatient/SearchForAccountPatient.styles';
import { CaregiverInfoModal } from './CaregiverInfoModal';

const AccountRow = styled(PersonRow)({
  cursor: 'inherit',
});

const Account = styled.span({
  flex: 1,
});

export const CaregiverInfoSection: React.FC<{
  onExistingSelect: ({
    account,
    relationship,
    isMissingName,
  }: {
    account: AccountFragment;
    relationship: string;
    isMissingName: boolean;
  }) => void;
}> = ({ onExistingSelect }) => {
  const [showWarning, setShowWarning] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState('');
  const { errors, values, submitCount }: FormikProps<Appointment> =
    useFormikContext();
  const { phoneNumber, accountId, patientId } = values;
  let parsedNumber;
  if (phoneNumber !== undefined) {
    parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');
  }

  const { data: accountData } = useFindAccountQuery({
    variables: {
      where: {
        phoneNumber: {
          _eq: parsedNumber?.number.toString(),
        },
      },
    },
    skip: !parsedNumber?.isValid() || !!accountId,
  });

  const foundAccount = useMemo(() => {
    setShowWarning(true);
    return accountData?.accounts?.[0];
  }, [accountData]);

  const { data } = useGetAccountByPkQuery({
    variables: { accountId: foundAccount?.id! },
    skip: !foundAccount?.id,
  });

  const account = data?.accounts_by_pk;
  const accountPatients = account?.account_patients ?? [];
  const accountPronouns = `${
    account?.preferredPronounType === 'other'
      ? account.preferredPronoun
      : PREFERRED_PRONOUN.find((p) => p.value === account?.preferredPronounType)
          ?.label
  }`;

  const existingRelationship =
    accountPatients.find((ap) => ap.patientId === patientId)?.relationship ??
    '';

  const isMissingName = !account?.firstName || !account.lastName;

  let accountName = account?.goesByName
    ? `${account?.firstName} &quot;${account.goesByName}&quot; ${account?.lastName}`
    : `${account?.firstName} ${account?.lastName}`;

  let helperText = `We found an existing caregiver with this phone number:`;

  if (isMissingName) {
    accountName = phoneNumber;
    helperText = `We found an existing caregiver (without a saved name) with this phone number:`;
  }

  useEffect(() => {
    if (
      submitCount > 0 &&
      errors &&
      Object.keys(errors).includes('accountFirstName')
    ) {
      setError(
        'Please dismiss this alert or enter another caregiver’s phone number.',
      );
    }
  }, [submitCount, errors]);

  return (
    <Fragment>
      <ContentRow css={{ marginBottom: 0 }}>
        <InfoSection title="Mobile phone number" fullWidth>
          <PhoneNumberField name="phoneNumber" />
        </InfoSection>
      </ContentRow>
      {!!account && showWarning && (
        <WarningWrapper>
          <Icon src={ICONS.info} />
          <Row>
            {helperText}
            <AccountRow>
              <AccountAvatar
                accountId={account.id}
                size={48}
                style={{ marginRight: 16 }}
              />
              <Account>{accountName}</Account>
              <SelectButton
                type="button"
                isSelected={false}
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
              >
                View Caregiver
              </SelectButton>
            </AccountRow>
            <DismissText>
              Once you have confirmed this caregiver, you can
              <DismissButton
                onClick={(e) => {
                  e.preventDefault();
                  onExistingSelect({
                    account,
                    relationship: existingRelationship,
                    isMissingName,
                  });
                }}
                type="button"
              >
                dismiss this alert
              </DismissButton>
              .
            </DismissText>
            {isMissingName && (
              <DismissText>
                After dismissing this alert, you’ll be prompted to add the
                caregiver’s name.
              </DismissText>
            )}
          </Row>
        </WarningWrapper>
      )}
      {!!account && showWarning && error && (
        // id is used for the "scroll to error" functionality
        <ErrorText id="accountFirstName">{error}</ErrorText>
      )}
      {!account && (
        <ContentRow>
          <NormalField title="Caregiver first name" name="accountFirstName" />
          <NormalField title="Caregiver last name" name="accountLastName" />
        </ContentRow>
      )}
      {account && (
        <CaregiverInfoModal
          accountId={account.id}
          accountName={accountName}
          accountPatients={accountPatients}
          accountPronouns={accountPronouns}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          preferredPronoun={account.preferredPronoun}
        />
      )}
    </Fragment>
  );
};
