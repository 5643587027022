import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { IF_TABLET } from '../../styles';
import { PrimaryText } from '../text';

export const Row = styled.div({
  alignItems: 'center',
  display: 'flex',
});

export const Column = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const Container = styled(Row)({
  padding: '16px 0',
  borderBottom: `1px solid ${Colors.grayLighter}`,
  cursor: 'pointer',
  justifyContent: 'space-between',
}).withComponent('a');

export const InnerContainer = styled(Row)({
  flex: 1,
  flexWrap: 'wrap',
});

export const Caret = styled.img({
  height: 24,
  marginLeft: 16,
  transform: 'rotate(90deg)',
  width: 24,
});

export const TextContainer = styled(Column)({
  margin: '0 16px',
});

export const IconContainer = styled(Row)({
  flex: 1,
  height: '100%',
  padding: '8px 0 0 20px',
  [IF_TABLET]: {
    padding: 0,
    justifyContent: 'flex-end',
  },
});

export const AvatarContainer = styled(Row)({
  marginRight: 32,
  marginLeft: -20,
  [IF_TABLET]: {
    marginLeft: 0,
    marginRight: 56,
  },
});

export const Title = styled(PrimaryText)({
  lineHeight: '24px',
  marginBottom: 4,
  padding: '8px 0 0 0',
  [IF_TABLET]: {
    padding: 0,
  },
});

export const EmphasizedTitle = styled(Title)({
  fontWeight: 'bold',
});

export const Ended = styled(PrimaryText)({
  color: Colors.gray,
  fontSize: 14,
  lineHeight: '18px',
  marginTop: 4,
  [IF_TABLET]: {
    width: 256,
  },
});

export const StatusIndicator = styled.div<{ active?: boolean }>(
  ({ active }) => ({
    backgroundColor: active ? '#1EA027' : Colors.grayLight,
    border: '2px solid white',
    borderRadius: 6,
    height: 12,
    width: 12,
    position: 'absolute',
    bottom: 2,
    right: 0,
  }),
);
