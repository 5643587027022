import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
  Operation,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

type RequestOperation = (operation: Operation) => void;
type WebsocketConfiguration = {
  connectionParams?: () => any;
};

interface ApolloClientInit extends ApolloClientOptions<NormalizedCacheObject> {
  uri: string;
}

export function createApolloClient(
  options: string | ApolloClientInit,
  request: RequestOperation,
  websocket?: WebsocketConfiguration,
) {
  let uri;
  let additionalOptions;

  if (typeof options === 'object') {
    uri = options.uri;
    additionalOptions = options;
    //@ts-ignore
    delete additionalOptions?.uri;
  } else {
    uri = options;
  }

  const httpLink = new HttpLink({
    uri: uri,
  });

  let link: any = httpLink;

  if (websocket) {
    const wsUrl = new URL(uri);
    wsUrl.protocol = wsUrl.protocol === 'https:' ? 'wss' : 'ws';
    const wsLink = new WebSocketLink({
      uri: wsUrl.toString(),
      options: {
        lazy: true,
        reconnect: true,
        connectionParams: websocket && websocket.connectionParams,
      },
    });

    link = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink,
    );
  }

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle: any;
        Promise.resolve(operation)
          .then((oper) => request(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      }),
  );

  return new ApolloClient({
    connectToDevTools: process.env.NODE_ENV === 'development',
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            );

            // We are seeing a lot of errors in the MATool logs, because requests aren't authenticated.
            // I think this is because the JWT expires but we keep polling. This is a hack to force a refresh.
            if (message.includes(`not found in type: 'query_root'`)) {
              // Only reload max once per 10 minutes so we don't cause an unintentional loop.
              if (
                parseInt(sessionStorage.getItem('lastReloadTime') || '0', 10) <
                Date.now() - 1000 * 60 * 10
              ) {
                sessionStorage.setItem('lastReloadTime', Date.now().toString());
                location.reload();
              }
            }
          });

          // retry once
          return forward(operation);
        }

        if (networkError) {
          console.error(`[Network error]: ${JSON.stringify(networkError)}`);
        }
      }),
      requestLink,
      link,
    ]),
    cache: new InMemoryCache(),
    ...additionalOptions,
  });
}
