export const CARRIERS = {
  '1199_national_benefit_fund': {
    name: '1199 National Benefit Fund',
    payer_id: '13162',
  },
  '8th_district_electrical': {
    name: '8th District Electrical Benefit Fund',
    payer_id: '74234',
  },
  aarp_medicare_complete: {
    name: 'AARP Medicare Complete',
    payer_id: '87726',
  },
  aarp_medicare_supplement: {
    name: 'AARP Medicare Supplement Plan (Fixed or Hospital Indemnity)',
    payer_id: '36273',
  },
  absolute_total_care: {
    name: 'Absolute Total Care',
    payer_id: '68055',
  },
  access_health_solutions: {
    name: 'Access Health Solutions',
    payer_id: '68609',
  },
  access_medicare: {
    name: 'Access Medicare',
    payer_id: '19305',
  },
  administrative_concepts_inc: {
    name: 'Administrative Concepts Inc',
    payer_id: '22384',
  },
  adventist_health_system: {
    name: 'Adventist Health System',
    payer_id: '95340',
  },
  aetna: {
    name: 'Aetna',
    payer_id: '60054',
  },
  aetna_affordable_health_src: {
    name: 'Aetna Affordable Health Choices SM SRC',
    payer_id: '57604',
  },
  aetna_better_health: {
    name: 'Aetna Better Health',
    payer_id: '60054',
  },
  aetna_better_health_il: {
    name: 'Aetna Better Health of Illinois',
    payer_id: '26337',
  },
  aetna_better_health_ky: {
    name: 'Aetna Better Health of Kentucky',
  },
  aetna_better_health_la: {
    name: 'Aetna Better Health of Louisiana',
    payer_id: '128LA',
  },
  aetna_better_health_mi: {
    name: 'Aetna Better Health of Michigan',
  },
  aetna_better_health_ne: {
    name: 'Aetna Better Health of Nebraska',
  },
  aetna_better_health_nj: {
    name: 'Aetna Better Health of New Jersey',
    payer_id: '46320',
  },
  aetna_better_health_ny: {
    name: 'Aetna Better Health of New York',
    payer_id: '34734',
  },
  aetna_better_health_oh: {
    name: 'Aetna Better Health of Ohio',
    payer_id: '50023',
  },
  aetna_better_health_pa: {
    name: 'Aetna Better Health of Pennsylvania',
    payer_id: '23228',
  },
  aetna_better_health_tx: {
    name: 'Aetna Better Health of Texas',
    payer_id: '38692',
  },
  aetna_better_health_va: {
    name: 'Aetna Better Health of Virginia (Formerly Coventry Cares of VA)',
  },
  aetna_better_health_wv: {
    name: 'Aetna Better Health of West Virginia',
  },
  aetna_senior_supplemental: {
    name: 'Aetna Senior Supplemental',
  },
  affiliated_doctors_orange_county: {
    name: 'Affiliated Doctors of Orange County',
    payer_id: 'ADOCS',
  },
  affinity_access: {
    name: 'Affinity Access',
    payer_id: '23334',
  },
  affinity_health_plan: {
    name: 'Affinity Health Plan',
    payer_id: '13334',
  },
  affinity_medicare_advantage: {
    name: 'Affinity Medicare Advantage',
    payer_id: '13333',
  },
  aflac: {
    name: 'Aflac',
  },
  aflac_medicare: {
    name: 'Aflac Medicare Supplement Insurance',
  },
  aftra_health_fund: {
    name: 'Aftra Health Fund',
  },
  agewell_ny: {
    name: 'Agewell New York',
    payer_id: 'AWNY6',
  },
  alameda_alliance_for_health: {
    name: 'Alameda Alliance For Health',
    payer_id: '95327',
  },
  all_savers_insurance: {
    name: 'All Savers Insurance',
    payer_id: '81400',
  },
  allegeant: {
    name: 'Allegeant (LBA Health Plan)',
    payer_id: '52193',
  },
  allegiance: {
    name: 'Allegiance',
  },
  'allegiance_benefit _plan_management_inc': {
    name: 'Allegiance Benefit Plan Management Incorporated',
    payer_id: '81040',
  },
  allied_benefit_systems: {
    name: 'Allied Benefit Systems Inc',
    payer_id: '37308',
  },
  altius_health_plans: {
    name: 'Altius Health Plans',
    payer_id: '25133',
  },
  ambetter: {
    name: 'Ambetter',
    payer_id: '68069',
  },
  ameriben: {
    name: 'AmeriBen',
    payer_id: '75137',
  },
  american_healthcare_alliance: {
    name: 'American Healthcare Alliance',
    payer_id: '01066',
  },
  american_medical_security: {
    name: 'American Medical Security (AMS - UnitedHealthcare Life)',
    payer_id: '81400',
  },
  american_national_insurance: {
    name: 'American National Insurance',
  },
  american_postal_union_health: {
    name: 'American Postal Workers Union (APWU) Health Plan',
    payer_id: '44444',
  },
  american_republic: {
    name: 'American Republic Insurance Company (ARIC)',
    payer_id: '42011',
  },
  american_speciality_health: {
    name: 'American Specialty Health',
    payer_id: 'ASH01',
  },
  amerigroup: {
    name: 'Amerigroup',
    payer_id: '26375',
  },
  amerigroup_community_care: {
    name: 'Amerigroup Community Care',
    payer_id: '27514',
  },
  amerihealth: {
    name: 'Amerihealth',
  },
  amerihealth_administrators: {
    name: 'AmeriHealth Administrators',
    payer_id: '54763',
  },
  amerihealth_caritas_ia: {
    name: 'AmeriHealth Caritas Iowa',
  },
  amerihealth_caritas_la: {
    name: 'AmeriHealth Caritas Louisiana',
    payer_id: '27357',
  },
  amerihealth_caritas_nc: {
    name: 'Amerihealth Caritas of North Carolina',
  },
  amerihealth_caritas_pa: {
    name: 'AmeriHealth Caritas Pennsylvania',
    payer_id: '22248',
  },
  amerihealth_de: {
    name: 'AmeriHealth Delaware',
    payer_id: '93688',
  },
  amerihealth_de_hmo: {
    name: 'AmeriHealth HMO of Delaware',
    payer_id: '95044',
  },
  amerihealth_nj: {
    name: 'AmeriHealth New Jersey',
    payer_id: '60061',
  },
  amerihealth_nj_hmo: {
    name: 'AmeriHealth HMO of New Jersey',
    payer_id: '95044',
  },
  ameritas_ny: {
    name: 'Ameritas of New York',
  },
  anthem_blue_cross_blue_shield_co: {
    name: 'Anthem Blue Cross and Blue Shield of Colorado',
    payer_id: '00050',
  },
  anthem_blue_cross_blue_shield_ct: {
    name: 'Anthem Blue Cross and Blue Shield of Connecticut',
    payer_id: '00060',
  },
  anthem_blue_cross_blue_shield_in: {
    name: 'Anthem Blue Cross and Blue Shield of Indiana',
    payer_id: '00630',
  },
  anthem_blue_cross_blue_shield_ky: {
    name: 'Anthem Blue Cross and Blue Shield of Kentucky',
    payer_id: '00660',
  },
  anthem_blue_cross_blue_shield_me: {
    name: 'Anthem Blue Cross and Blue Shield of Maine',
    payer_id: '00680',
  },
  anthem_blue_cross_blue_shield_mo: {
    name: 'Anthem Blue Cross and Blue Shield of Missouri',
    payer_id: '00241',
  },
  anthem_blue_cross_blue_shield_nh: {
    name: 'Anthem Blue Cross and Blue Shield of New Hampshire',
    payer_id: '00770',
  },
  anthem_blue_cross_blue_shield_nv: {
    name: 'Anthem Blue Cross and Blue Shield of Nevada',
    payer_id: '00265',
  },
  anthem_blue_cross_blue_shield_oh: {
    name: 'Anthem Blue Cross and Blue Shield of Ohio',
    payer_id: '00834',
  },
  anthem_blue_cross_blue_shield_va: {
    name: 'Anthem Blue Cross and Blue Shield of Virginia',
    payer_id: '00423',
  },
  anthem_blue_cross_blue_shield_wi: {
    name: 'Anthem Blue Cross and Blue Shield of Wisconsin',
    payer_id: '00950',
  },
  anthem_blue_cross_ca: {
    name: 'Anthem Blue Cross California',
    payer_id: '47198',
  },
  arbor_health_plan: {
    name: 'Arbor Health Plan',
    payer_id: '52312',
  },
  arise_health_plan: {
    name: 'Arise Health Plan',
  },
  arnett_health_plan: {
    name: 'Arnett Health Plan',
    payer_id: '87726',
  },
  asr_health_benefits: {
    name: 'ASR Health Benefits',
  },
  asuris_northwest_health: {
    name: 'Asuris Northwest Health',
    payer_id: '93221',
  },
  atrio_health_plans: {
    name: 'Atrio Health Plans',
    payer_id: 'ATRIO',
  },
  aultcare: {
    name: 'AultCare',
  },
  automated_benefit_services: {
    name: 'Automated Benefit Services',
    payer_id: '38259',
  },
  avera_health_plan: {
    name: 'Avera Health Plans',
    payer_id: '46045',
  },
  avmed_health_plan: {
    name: 'Avmed Health Plan',
    payer_id: '59274',
  },
  az_physicians_ipa: {
    name: 'Arizona Physicians IPA (United Healthcare Community Plan AZ)',
    payer_id: '03432',
  },
  bankers_life_and_casualty: {
    name: 'Bankers Life and Casualty',
  },
  banner_health_plans: {
    name: 'Banner Health Plans',
    payer_id: '12X42',
  },
  bcbs_medicare_advantage: {
    name: 'Blue Cross Blue Shield Medicare Advantage',
  },
  bcbs_tx_medicaid: {
    name: 'Blue Cross Blue Shield Texas Medicaid',
    payer_id: '66001',
  },
  beacon_health: {
    name: 'Beacon Health (formerly Value Options)',
    payer_id: 'VALOP',
  },
  beech_street: {
    name: 'Beech Street',
    payer_id: '95377',
  },
  benefit_plan_administrators: {
    name: 'Benefit Plan Administrators',
    payer_id: '19753',
  },
  best_choice_health_plan: {
    name: 'Best Choice Health Plan',
  },
  best_life_health: {
    name: 'Best Life and Health',
    payer_id: '95604',
  },
  better_health_plan: {
    name: 'Better Health Plan (Unison)',
    payer_id: '87726',
  },
  bind_benefits: {
    name: 'Bind Benefits',
  },
  blue_cross_blue_shield_al_institutional: {
    name: 'Blue Cross Blue Shield of Alabama (Institutional)',
  },
  blue_cross_blue_shield_al_professional: {
    name: 'Blue Cross Blue Shield of Alabama (Professional)',
    payer_id: 'SB510',
  },
  blue_cross_blue_shield_ar: {
    name: 'Blue Cross Blue Shield of Arkansas',
  },
  blue_cross_blue_shield_az: {
    name: 'Blue Cross and Blue Shield of Arizona',
    payer_id: 'BCBSAZ',
  },
  blue_cross_blue_shield_dc: {
    name: 'CareFirst Blue Cross Blue Shield of DC',
    payer_id: 'SB580',
  },
  blue_cross_blue_shield_federal_employee_program: {
    name: 'Blue Cross Blue Shield Federal Employee Program',
    payer_id: 'SX084',
  },
  blue_cross_blue_shield_fl: {
    name: 'Blue Cross and Blue Shield of Florida',
    payer_id: '00590',
  },
  blue_cross_blue_shield_ga: {
    name: 'Blue Cross and Blue Shield of Georgia',
    payer_id: '00601',
  },
  blue_cross_blue_shield_hi: {
    name: 'Blue Cross Blue Shield of Hawaii (HMSA)',
    payer_id: '990040115',
  },
  blue_cross_blue_shield_il: {
    name: 'Blue Cross and Blue Shield of Illinois',
    payer_id: '00621',
  },
  blue_cross_blue_shield_kc: {
    name: 'Blue Cross and Blue Shield of Kansas City',
    payer_id: '47171',
  },
  blue_cross_blue_shield_ks: {
    name: 'Blue Cross and Blue Shield of Kansas',
    payer_id: 'SB650',
  },
  blue_cross_blue_shield_la: {
    name: 'Blue Cross and Blue Shield of Louisiana',
    payer_id: '53120',
  },
  blue_cross_blue_shield_ma: {
    name: 'Blue Cross and Blue Shield of Massachusetts',
    payer_id: 'SB700',
  },
  blue_cross_blue_shield_md: {
    name: 'CareFirst Blue Cross Blue Shield of Maryland',
    payer_id: 'SB690',
  },
  blue_cross_blue_shield_mi: {
    name: 'Blue Cross Blue Shield of Michigan',
    payer_id: 'SB710',
  },
  blue_cross_blue_shield_mn: {
    name: 'Blue Cross Blue Shield of Minnesota',
    payer_id: '00720',
  },
  blue_cross_blue_shield_ms: {
    name: 'Blue Cross and Blue Shield of Mississippi',
    payer_id: 'SB730',
  },
  blue_cross_blue_shield_mt: {
    name: 'Blue Cross and Blue Shield of Montana',
    payer_id: '00751',
  },
  blue_cross_blue_shield_nc: {
    name: 'Blue Cross and Blue Shield of North Carolina',
    payer_id: '560894904',
  },
  blue_cross_blue_shield_nd: {
    name: 'Blue Cross Blue Shield of North Dakota',
  },
  blue_cross_blue_shield_ne: {
    name: 'Blue Cross Blue Shield Nebraska',
    payer_id: 'SB760',
  },
  blue_cross_blue_shield_nj: {
    name: 'Horizon Blue Cross Blue Shield of New Jersey',
    payer_id: '22099',
  },
  blue_cross_blue_shield_nm: {
    name: 'Blue Cross and Blue Shield of New Mexico',
    payer_id: '00790',
  },
  blue_cross_blue_shield_ok: {
    name: 'Blue Cross and Blue Shield of Oklahoma',
    payer_id: '00840',
  },
  blue_cross_blue_shield_ri: {
    name: 'Blue Cross and Blue Shield of Rhode Island',
  },
  blue_cross_blue_shield_sc: {
    name: 'Blue Cross and Blue Shield of South Carolina',
    payer_id: '401',
  },
  blue_cross_blue_shield_tn: {
    name: 'Blue Cross and Blue Shield of Tennessee (Professional)',
    payer_id: '00390',
  },
  blue_cross_blue_shield_tx: {
    name: 'Blue Cross Blue Shield of Texas',
    payer_id: '84980',
  },
  blue_cross_blue_shield_vt: {
    name: 'Blue Cross and Blue Shield of Vermont',
    payer_id: 'SB915',
  },
  blue_cross_blue_shield_wny: {
    name: 'Blue Cross and Blue Shield of Western New York',
    payer_id: '00246',
  },
  blue_cross_blue_shield_wny_fep: {
    name: 'Blue Cross and Blue Shield of Western New York Federal Employee Plan',
  },
  blue_cross_blue_shield_wy: {
    name: 'Blue Cross Blue Shield of Wyoming',
  },
  blue_cross_idaho: {
    name: 'Blue Cross of Idaho',
  },
  blue_shield_ca: {
    name: 'Blue Shield of California',
    payer_id: 'BS001',
  },
  bluecross_blueshield_utah: {
    name: 'Regence BlueCross BlueShield of Utah',
    payer_id: '00910',
  },
  blueshield_of_northeastern_new_york: {
    name: 'BlueShield of Northeastern New York',
  },
  blueshield_of_northeastern_new_york_fep: {
    name: 'BlueShield of Northeastern New York Federal Employee Plan',
  },
  boon_chapman: {
    name: 'Boon Chapman',
    payer_id: '74238',
  },
  boon_group: {
    name: 'Boon Group',
  },
  boston_medical_center: {
    name: 'Boston Medical Center Health Plan',
    payer_id: '13337',
  },
  bravo_health: {
    name: 'Bravo Health',
  },
  bridgespan: {
    name: 'BridgeSpan Health',
    payer_id: 'BRIDG',
  },
  bridgeway_health_solutions: {
    name: 'Bridgeway Health Solutions',
    payer_id: '68054',
  },
  bright_health: {
    name: 'Bright Health',
    payer_id: 'CB186',
  },
  bright_health_medicare: {
    name: 'Bright Health Medicare',
    payer_id: 'BRT01',
  },
  buckeye_community_health_plan: {
    name: 'Buckeye Community Health Plan',
    payer_id: '32004',
  },
  caidan_enterprises: {
    name: 'Caidan Enterprises',
    payer_id: 'A0245',
  },
  cal_optima_medi_cal: {
    name: 'Medi-Cal',
    payer_id: 'CALOP',
  },
  california_health_wellness: {
    name: 'California Health and Wellness',
    payer_id: '68069',
  },
  capital_blue_cross: {
    name: 'Capital Blue Cross',
  },
  capital_health_plan: {
    name: 'Capital Health Plan',
  },
  caprock: {
    name: 'Caprock',
  },
  care1st: {
    name: 'Care1st Health Plan of California',
    payer_id: 'C1SCA',
  },
  care1st_health_az: {
    name: 'Care1st Health Plan Arizona',
  },
  care_improvement_plus: {
    name: 'Care Improvement Plus',
    payer_id: '87726',
  },
  care_or: {
    name: 'CareOregon',
    payer_id: '93975',
  },
  care_source_health_plan_or: {
    name: 'Caresource Health Plan of Oregon',
    payer_id: 'MRCHP',
  },
  carecentrix: {
    name: 'CareCentrix',
    payer_id: '11345',
  },
  careconnect: {
    name: 'CareConnect',
    payer_id: '46227',
  },
  carefirst_admin_ncas: {
    name: 'CareFirst Administrators (NCAS)',
    payer_id: '75191',
  },
  carelink_coventry: {
    name: 'Carelink (Coventry)',
    payer_id: '25133',
  },
  carelink_medicaid_coventry: {
    name: 'Carelink Medicaid (Coventry)',
    payer_id: '25133',
  },
  caremore_health_plan: {
    name: 'CareMore Health Plan',
    payer_id: 'CARMO',
  },
  careplus_health_plan: {
    name: 'CarePlus Health Plan',
    payer_id: '95092',
  },
  caresource_in: {
    name: 'CareSource of Indiana',
    payer_id: 'INCS1',
  },
  caresource_ky: {
    name: 'CareSource of Kentucky',
    payer_id: 'KYCS1',
  },
  caresource_oh: {
    name: 'CareSource of Ohio',
    payer_id: '31114',
  },
  cariten_healthcare: {
    name: 'Cariten Healthcare',
  },
  carolina_complete: {
    name: 'Carolina Complete Health Care',
  },
  carolina_crescent_health_plan: {
    name: 'Carolina Crescent Health Plan',
    payer_id: '68069',
  },
  carolina_summit_healthcare: {
    name: 'Carolina Summit Healthcare, Inc',
    payer_id: '56195',
  },
  cbhnp: {
    name: 'CBHNP HealthChoices',
    payer_id: '65391',
  },
  cchp: {
    name: 'Chinese Community Health Plan',
    payer_id: '94302',
  },
  cdphp: {
    name: "Capital District Physicians' Health Plan (CDPHP)",
    payer_id: 'SX065',
  },
  cedar_valley_community_health: {
    name: 'Cedar Valley Community Healthcare (CVCH)',
    payer_id: '44827',
  },
  celtic_insurance_co: {
    name: 'Celtic Insurance Company',
    payer_id: '68063',
  },
  celticare_health_plan: {
    name: 'CeltiCare Health Plan',
    payer_id: '68060',
  },
  cencal_health: {
    name: 'CenCal Health',
    payer_id: '95386',
  },
  cenpatico_az: {
    name: 'Cenpatico Behavioral Health Arizona',
    payer_id: '68048',
  },
  cenpatico_fl: {
    name: 'Cenpatico Behavioral Health Florida',
    payer_id: '68058',
  },
  cenpatico_ga: {
    name: 'Cenpatico Behavioral Health Georgia',
    payer_id: '68050',
  },
  cenpatico_il: {
    name: 'Cenpatico Behavioral Health Illinois',
    payer_id: '68065',
  },
  cenpatico_in: {
    name: 'Cenpatico Behavioral Health Indiana',
    payer_id: '68052',
  },
  cenpatico_ks: {
    name: 'Cenpatico Behavioral Health Kansas',
    payer_id: '68047',
  },
  cenpatico_ky: {
    name: 'Cenpatico Behavioral Health Kentucky',
    payer_id: '68068',
  },
  cenpatico_ma: {
    name: 'Cenpatico Behavioral Health Massachusetts',
    payer_id: '68061',
  },
  cenpatico_mo: {
    name: 'Cenpatico Behavioral Health Missouri',
    payer_id: '68068',
  },
  cenpatico_mt: {
    name: 'Cenpatico Behavioral Health Montana',
    payer_id: '68068',
  },
  cenpatico_oh: {
    name: 'Cenpatico Behavioral Health Ohio',
    payer_id: '68051',
  },
  cenpatico_sc: {
    name: 'Cenpatico Behavioral Health South Carolina',
    payer_id: '68059',
  },
  cenpatico_tx: {
    name: 'Cenpatico Behavioral Health Texas',
    payer_id: '68053',
  },
  cenpatico_wi: {
    name: 'Cenpatico Behavioral Health Wisconsin',
    payer_id: '68046',
  },
  centerlight_healthcare: {
    name: 'Centerlight Healthcare',
    payer_id: '13360',
  },
  central_california_health_alliance: {
    name: 'Central California Alliance for Health',
    payer_id: 'SX169',
  },
  central_health: {
    name: 'Central Health',
    payer_id: 'CHCPI',
  },
  century_healthcare: {
    name: 'Century Healthcare',
    payer_id: '75261',
  },
  champva: {
    name: 'ChampVA - Health Administration Center (HAC)',
    payer_id: '84146',
  },
  chc_group_health_plan: {
    name: 'Coventry Health Care Group Health Plan',
  },
  childrens_medical_center_health_plan: {
    name: "Children's Medical Center Health Plan",
    payer_id: 'CMCHP',
  },
  christian_care_ministry: {
    name: 'Christian Care Ministry',
    payer_id: '59355',
  },
  christus_health_plan: {
    name: 'Christus Health Plan Medicaid',
    payer_id: '45210',
  },
  christus_spohn_health_network: {
    name: 'Christus Spohn Health Network',
    payer_id: 'SPOHN',
  },
  cigna: {
    name: 'Cigna',
    payer_id: '62308',
  },
  cigna_behavioral_health: {
    name: 'Cigna Behavioral Health',
    payer_id: 'MCCBV',
  },
  cigna_health_spring: {
    name: 'Cigna-HealthSpring',
    payer_id: '52192',
  },
  cigna_medicare_adv: {
    name: 'Cigna Medicare Advantage (Healthcare for Seniors - Arizona Medicare)',
    payer_id: '86033',
  },
  citrus_health_care: {
    name: 'Citrus Health Care',
    payer_id: '10207',
  },
  citrus_health_plan: {
    name: 'Citrus Health Plan',
    payer_id: '10207',
  },
  clover_health: {
    name: 'Clover Health',
    payer_id: '77023',
  },
  colonial_medical: {
    name: 'Colonial Medical',
    payer_id: '22284',
  },
  columbia_united_providers: {
    name: 'Columbia United Providers',
    payer_id: '91162',
  },
  commercial_travelers_phx: {
    name: 'Commercial Travelers (PHX)',
    payer_id: '88091',
  },
  community_care_of_nc: {
    name: 'Community Care of North Carolina',
    payer_id: 'NCMCD',
  },
  community_care_ok: {
    name: 'Community Care of Oklahoma',
    payer_id: '73143',
  },
  community_first: {
    name: 'Community First Health Plan',
    payer_id: 'COMMF',
  },
  community_health_choice: {
    name: 'Community Health Choice',
    payer_id: '48145',
  },
  community_health_plan_wa: {
    name: 'Community Health Plan of Washington',
    payer_id: 'SB613',
  },
  community_hospital_corp: {
    name: 'Community Hospital Corporation (CHC)',
  },
  complementary_healthcare_plan: {
    name: 'Complementary Healthcare Plan (The CHP Group)',
    payer_id: '93101',
  },
  connecticare: {
    name: 'ConnectiCare',
    payer_id: '06105',
  },
  connecticare_medicare: {
    name: 'ConnectiCare Medicare Advantage',
  },
  connecticut_carpenters_benefit_health: {
    name: 'Connecticut Carpenters Benefit Fund - Health',
    payer_id: '37307',
  },
  consolidated_health_plans: {
    name: 'Consolidated Health Plans',
    payer_id: '87843',
  },
  continental_general_insurance: {
    name: 'Continental General Insurance Company',
    payer_id: '71404',
  },
  cook_childrens_health: {
    name: 'Cook Childrens Health Plan',
    payer_id: 'CCHP1',
  },
  cook_group_health: {
    name: 'Cook Group Health Plan',
    payer_id: '35149',
  },
  cooperative_benefit: {
    name: 'Cooperative Benefit Administrators',
  },
  coordinated_care: {
    name: 'Coordinated Care',
    payer_id: '68069',
  },
  coordinated_medical_specialists: {
    name: 'Coordinated Medical Specialists',
    payer_id: '58204',
  },
  coresource_az_mn: {
    name: 'CoreSource (AZ, MN)',
    payer_id: '35182',
  },
  coresource_il_in_md_nc_pa: {
    name: 'CoreSource (IL, IN, MD, NC, PA)',
    payer_id: '35182',
  },
  coresource_kc: {
    name: 'CoreSource Kansas City',
    payer_id: '48117',
  },
  coresource_little_rock: {
    name: 'CoreSource Little Rock',
    payer_id: '75136',
  },
  coresource_oh: {
    name: 'CoreSource Ohio',
    payer_id: '35183',
  },
  coventry: {
    name: 'Coventry Health And Life',
    payer_id: '25133',
  },
  coventry_advantra: {
    name: 'Coventry Advantra',
  },
  coventry_cares: {
    name: 'Coventry Cares',
  },
  coventry_cares_mi: {
    name: 'CoventryCares of Michigan (OmniCare)',
    payer_id: '25150',
  },
  coventry_cares_va: {
    name: 'CoventryCares of Virginia',
    payer_id: '25133',
  },
  coventry_diamond_plan: {
    name: 'Coventry Diamond Plan (Maryland Medicaid)',
    payer_id: '25133',
  },
  coventry_health_and_life_tennessee: {
    name: 'Coventry Health and Life Tennessee',
  },
  coventry_health_care_carolinas: {
    name: 'Coventry Health Care of the Carolinas (Wellpath)',
  },
  coventry_health_care_de: {
    name: 'Coventry Health Care of Delaware',
  },
  coventry_health_care_fl: {
    name: 'Coventry Health Care of Florida',
  },
  coventry_health_care_ga: {
    name: 'Coventry Health Care of Georgia',
  },
  coventry_health_care_ia: {
    name: 'Coventry Health Care of Iowa',
    payer_id: '25132',
  },
  coventry_health_care_il: {
    name: 'Coventry Health Care of Illinois',
  },
  coventry_health_care_ks: {
    name: 'Coventry Health Care of Kansas',
  },
  coventry_health_care_la: {
    name: 'Coventry Health Care of Louisiana',
  },
  coventry_health_care_mo: {
    name: 'Coventry Health Care of Missouri',
  },
  coventry_health_care_ne: {
    name: 'Coventry Health Care of Nebraska',
    payer_id: '25133',
  },
  coventry_health_care_nv: {
    name: 'Coventry Health Care of Nevada',
  },
  coventry_health_care_ok: {
    name: 'Coventry Health Care of Oklahoma',
  },
  coventry_health_care_tx: {
    name: 'Coventry Health Care of Texas',
  },
  coventry_health_care_va: {
    name: 'Coventry Health Care of Virginia (Southern Health Services)',
  },
  coventry_healthcare_national_network: {
    name: 'Coventry Healthcare National Network',
  },
  coventry_one: {
    name: 'Coventry One',
  },
  cphl: {
    name: 'Centers Plan for Healthy Living (CPHL)',
    payer_id: 'CPHL',
  },
  culinary_health_fund: {
    name: 'Culinary Health Fund',
  },
  cypress_benefits: {
    name: 'Cypress Benefit Administrators',
    payer_id: '391997579',
  },
  dakotacare: {
    name: 'Dakotacare',
    payer_id: 'DAK01',
  },
  dean_health_plan: {
    name: 'Dean Health Plan',
    payer_id: '39113',
  },
  definity_health: {
    name: 'Definity Health (United)',
    payer_id: '87726',
  },
  deseret_mutual: {
    name: 'Deseret Mutual',
    payer_id: 'SX105',
  },
  dga_health: {
    name: 'Directors Guild of America Health Fund',
    payer_id: 'BC001',
  },
  driscoll_childrens_health: {
    name: "Driscoll Children's Health Plan",
    payer_id: '74284',
  },
  easy_choice_health_ny: {
    name: 'Easy Choice Health Plan of New York',
    payer_id: '24770',
  },
  ebms: {
    name: 'Employee Benefit Management Services (EBMS)',
    payer_id: '81039',
  },
  ebso_benefits: {
    name: 'EBSO Benefits',
  },
  educators_mutual: {
    name: 'Educators Mutual',
    payer_id: 'SX110',
  },
  elderplan: {
    name: 'Elderplan',
    payer_id: '31625',
  },
  emblem_health: {
    name: 'Emblem Health',
  },
  empire_blue_cross_blue_shield: {
    name: 'Empire Blue Cross and Blue Shield',
    payer_id: '00803',
  },
  empire_plan: {
    name: 'The Empire Plan (United)',
    payer_id: '87726',
  },
  employee_benefit_systems: {
    name: 'Employee Benefit Systems',
    payer_id: '42149',
  },
  essence_healthcare: {
    name: 'Essence Healthcare',
  },
  excellus_bcbs_ny_central: {
    name: 'Excellus Blue Cross Blue Shield NY - Central Area',
    payer_id: 'SB805',
  },
  excellus_bcbs_ny_rochester: {
    name: 'Excellus Blue Cross Blue Shield NY - Rochester Area',
    payer_id: 'SB804',
  },
  excellus_bcbs_ny_utica: {
    name: 'Excellus Blue Cross Blue Shield NY - Utica Area',
    payer_id: 'SB806',
  },
  excellus_blue_cross_blue_shield: {
    name: 'Excellus Blue Cross and Blue Shield',
  },
  fallon_community_health: {
    name: 'Fallon Community Health Plan',
    payer_id: '22254',
  },
  farm_bureau_health_plans: {
    name: 'Farm Bureau Health Plans',
  },
  federal_employee_plan_wa: {
    name: 'Regence Blue Shield Federal Employee Plan of Washington',
    payer_id: '00932',
  },
  federated_mutual: {
    name: 'Federated Mutual Insurance Company',
    payer_id: '41041',
  },
  fep_plan_sc: {
    name: 'Blue Cross and Blue Shield Federal Employee Plan of South Carolina',
    payer_id: 'SX084',
  },
  fidelis_care_ny: {
    name: 'Fidelis Care of New York',
    payer_id: '11315',
  },
  first_choice_health_network: {
    name: 'First Choice Health Network',
    payer_id: '91131',
  },
  first_health_coventry: {
    name: 'First Health (Coventry)',
    payer_id: '87043',
  },
  firstcare: {
    name: 'Firstcare',
    payer_id: '94999',
  },
  firstcare_star_medicaid: {
    name: 'Firstcare Star Medicaid',
    payer_id: '94998',
  },
  florida_health_care_plan: {
    name: 'Florida Health Care Plan',
    payer_id: '59322',
  },
  freedom_health: {
    name: 'Freedom Health Plan',
    payer_id: '41212',
  },
  friday_health_plan: {
    name: 'Friday Health Plan',
  },
  galveston_indigent_health: {
    name: 'Galveston County Indigent Health Care',
    payer_id: '30005',
  },
  gateway_health_medicare_assured: {
    name: 'Gateway Health Medicare Assured',
    payer_id: '60550',
  },
  gateway_health_pa: {
    name: 'Gateway Health Pennsylvania',
    payer_id: '25169',
  },
  geha: {
    name: 'Government Employees Health Association (GEHA)',
    payer_id: '44054',
  },
  geha_asa: {
    name: 'GEHA-ASA',
    payer_id: '06603',
  },
  geisinger_health: {
    name: 'Geisinger Health Plan',
    payer_id: '75273',
  },
  gilsbar: {
    name: 'Gilsbar',
    payer_id: '07205',
  },
  globe_life: {
    name: 'Globe Life and Accident Insurance Company',
  },
  gold_coast_health_plan: {
    name: 'Gold Coast Health Plan',
  },
  golden_rule_united: {
    name: 'Golden Rule',
    payer_id: '37602',
  },
  granite_state_health_plan: {
    name: 'Granite State Health Plan',
    payer_id: '68069',
  },
  great_west_health_care: {
    name: 'Great West Healthcare',
    payer_id: '62308',
  },
  group_health_cooperative: {
    name: 'Group Health Cooperative',
    payer_id: '91051',
  },
  group_health_cooperative_eau_claire: {
    name: 'Group Health Cooperative of Eau Claire',
    payer_id: '95192',
  },
  group_health_cooperative_south_central_wi: {
    name: 'Group Health Cooperative of South Central Wisconsin',
    payer_id: '39167',
  },
  group_health_incorporated: {
    name: 'Group Health Incorporated (GHI)',
    payer_id: '13551',
  },
  group_health_plan_care_management_resource: {
    name: 'Group Health Plan - CMR',
    payer_id: '25141',
  },
  group_pension_administrators: {
    name: 'Group and Pension Administrators',
    payer_id: '48143',
  },
  harken_health: {
    name: 'Harken Health',
    payer_id: '43313',
  },
  harmony_health_plan_illinois: {
    name: 'Harmony Health Plan of Illinois',
    payer_id: '36406',
  },
  harmony_health_plan_indiana: {
    name: 'Harmony Health Plan of Indiana',
    payer_id: '36405',
  },
  harrington_health: {
    name: 'Harrington Health',
    payer_id: '59143',
  },
  harvard_pilgrim_health_care: {
    name: 'Harvard Pilgrim Health Care',
    payer_id: '04271',
  },
  harvard_pilgrim_passport_connect: {
    name: 'Harvard Pilgrim Passport Connect',
    payer_id: '87726',
  },
  hawaii_mainland_administrators: {
    name: 'Hawaii Mainland Administrators',
    payer_id: '86066',
  },
  hcc_life_insurance: {
    name: 'HCC Life Insurance',
    payer_id: 'HCCMI',
  },
  health_alliance_il: {
    name: 'Health Alliance Medical Plan of Illinois',
    payer_id: '77950',
  },
  health_alliance_mi: {
    name: 'Health Alliance Plan of Michigan',
    payer_id: '38224',
  },
  health_america_coventry: {
    name: 'Health America (Coventry)',
  },
  health_care_la_ipa: {
    name: 'Health Care LA IPA',
    payer_id: 'MPM06',
  },
  health_choice_az: {
    name: 'Health Choice of Arizona',
  },
  health_choice_ok: {
    name: 'Health Choice Oklahoma',
  },
  health_ez: {
    name: 'HealthEZ',
  },
  health_first_health_plans: {
    name: 'Health First Health Plans',
  },
  health_insurance_plan: {
    name: 'Health Insurance Plan (HIP)',
    payer_id: '55247',
  },
  health_net_az: {
    name: 'Health Net of Arizona',
    payer_id: '38309',
  },
  health_net_az_ct_nj_ny_pa: {
    name: 'Health Net (AZ, CT, NJ, NY, PA)',
  },
  health_net_ca: {
    name: 'Health Net of California',
    payer_id: '95567',
  },
  health_net_national: {
    name: 'Health Net National',
  },
  health_net_northeast: {
    name: 'Health Net of the Northeast',
    payer_id: '06108',
  },
  health_new_england: {
    name: 'Health New England',
    payer_id: '04286',
  },
  health_partners_mn: {
    name: 'HealthPartners Minnesota',
    payer_id: '07003',
  },
  health_partners_pa: {
    name: 'Health Partners Plans, Inc',
  },
  health_partners_tn: {
    name: 'HealthPartners Tennessee',
    payer_id: '62157',
  },
  health_plan_nv: {
    name: 'Health Plan of Nevada (United Healthcare Nevada)',
    payer_id: '76342',
  },
  health_plan_san_joaquin: {
    name: 'Health Plan of San Joaquin (HPSJ)',
    payer_id: '68035',
  },
  health_plan_san_mateo: {
    name: 'Health Plan of San Mateo (HPSM)',
    payer_id: 'HPSM1',
  },
  health_tradition_health_plan: {
    name: 'Health Tradition Health Plan (Mayo Health)',
  },
  healthcare_management_administrators: {
    name: 'Healthcare Management Administrators',
    payer_id: '754',
  },
  healthcare_partners_ipa: {
    name: 'Healthcare Partners IPA',
    payer_id: '11328',
  },
  healthcare_usa_coventry: {
    name: 'Healthcare USA (Coventry)',
    payer_id: '25133',
  },
  healthcomp_administrators: {
    name: 'HealthComp',
    payer_id: '85729',
  },
  healthease: {
    name: 'HealthEase of Florida (WellCare)',
    payer_id: '14163',
  },
  healthfirst_ny: {
    name: 'Healthfirst of New York',
    payer_id: '80141',
  },
  healthfirst_tpa: {
    name: 'HealthFirst TPA',
    payer_id: '75234',
  },
  healthgram: {
    name: 'Healthgram',
  },
  healthnow: {
    name: 'HealthNow',
  },
  healthplus_la: {
    name: 'HealthPlus of Louisiana',
    payer_id: '95009',
  },
  healthscope_benefits: {
    name: 'HealthScope Benefits',
    payer_id: '71063',
  },
  healthsmart_benefit: {
    name: 'HealthSmart Benefit Solutions',
    payer_id: '87815',
  },
  healthsun_health_plan: {
    name: 'Healthsun Health Plan',
    payer_id: 'HESUN',
  },
  healthy_blue_nc: {
    name: 'Healthy Blue of North Carolina',
  },
  heartland_national: {
    name: 'Heartland National Life Insurance Company',
  },
  highmark_blue_cross_blue_shield_de: {
    name: 'Highmark Blue Cross Blue Shield Delaware',
    payer_id: '00570',
  },
  highmark_blue_cross_blue_shield_pa: {
    name: 'Highmark Blue Cross Blue Shield Pennsylvania',
    payer_id: '54771',
  },
  highmark_blue_cross_blue_shield_wv: {
    name: 'Highmark Blue Cross Blue Shield West Virginia',
    payer_id: '54828',
  },
  highmark_blue_shield_pa: {
    name: 'Highmark Blue Shield Pennsylvania',
    payer_id: '54771',
  },
  highmark_bs_vision_pa: {
    name: 'Highmark Blue Shield Vision Pennsylvania',
    payer_id: '54771',
  },
  highmark_senior_health_company: {
    name: 'Highmark Senior Health Company',
    payer_id: '522129',
  },
  highmark_senior_solutions_company: {
    name: 'Highmark Senior Solutions Company',
    payer_id: '520237',
  },
  hmaa: {
    name: 'Hawaii Medical Assurance Association',
    payer_id: '48330',
  },
  home_state_health_plan: {
    name: 'Home State Health Plan',
    payer_id: '68069',
  },
  hometown_health: {
    name: 'Hometown Health',
  },
  horizon_nj_health: {
    name: 'Horizon NJ Health',
  },
  hp_administrative: {
    name: 'HP Administrative Services',
    payer_id: '22521',
  },
  hudson_health_plan: {
    name: 'Hudson Health Plan',
  },
  humana: {
    name: 'Humana',
    payer_id: '61101',
  },
  ideal_life_insurance: {
    name: 'Ideal Life Insurance',
  },
  illinicare_health_plan: {
    name: 'Illinicare health Plan',
    payer_id: '68066',
  },
  independence_admin: {
    name: 'Independence Administrators',
    payer_id: 'TA720',
  },
  independence_blue_cross: {
    name: 'Independence Blue Cross (Professional)',
    payer_id: '54704',
  },
  independence_blue_cross_institutional: {
    name: 'Independence Blue Cross (Institutional)',
  },
  independent_health: {
    name: 'Independent Health',
  },
  inland_empire_health: {
    name: 'Inland Empire Health Plan',
    payer_id: 'IEHP1',
  },
  insurance_design_admin: {
    name: 'Insurance Design Administrators',
    payer_id: '13315',
  },
  intotal_health: {
    name: 'INTotal Health',
    payer_id: '35115',
  },
  john_hopkins_health_care: {
    name: 'John Hopkins Healthcare LLC',
    payer_id: '52189',
  },
  kaiser_permanente_co: {
    name: 'Kaiser Permanente of Colorado',
    payer_id: '91617',
  },
  kaiser_permanente_ga: {
    name: 'Kaiser Permanente of Georgia',
    payer_id: 'NG010',
  },
  kaiser_permanente_mid_atlantic: {
    name: 'Kaiser Permanente of the Mid Atlantic',
    payer_id: 'NG008',
  },
  kaiser_permanente_northern_ca: {
    name: 'Kaiser Permanente of Northern CA',
    payer_id: '94135',
  },
  kaiser_permanente_northwest: {
    name: 'Kaiser Permanente of the Northwest',
    payer_id: 'NG009',
  },
  kaiser_permanente_southern_ca: {
    name: 'Kaiser Permanente of Southern CA',
    payer_id: 'KS001',
  },
  kentucky_spirit: {
    name: 'Kentucky Spirit',
    payer_id: '68067',
  },
  key_benefit: {
    name: 'Key Benefit Administrators',
    payer_id: '37217',
  },
  keystone_first: {
    name: 'Keystone First',
    payer_id: '23284',
  },
  keystone_health_plan_east: {
    name: 'Keystone Health Plan East',
    payer_id: '95056',
  },
  la_care_health_plan: {
    name: 'LA Care Health Plan',
    payer_id: 'LACAR',
  },
  la_healthcare_connections: {
    name: 'Louisiana Healthcare Connections',
    payer_id: '68069',
  },
  liberty_health_advantage: {
    name: 'Liberty Health Advantage',
    payer_id: '87071',
  },
  liberty_healthshare: {
    name: 'Liberty HealthShare',
    payer_id: '90753',
  },
  liberty_mutual: {
    name: 'Liberty Mutual',
    payer_id: '33600',
  },
  liberty_national: {
    name: 'Liberty National Life Insurance Company',
  },
  lifetime_benefit_solutions: {
    name: 'Lifetime Benefit Solutions',
  },
  lifewise_or: {
    name: 'LifeWise Health Plan of  Oregon',
    payer_id: '93093',
  },
  lifewise_wa: {
    name: 'LifeWise Health Plan of Washington',
    payer_id: '91049',
  },
  lincoln_financial: {
    name: 'Lincoln Financial Group',
  },
  loma_linda_medical_center_adventist: {
    name: 'Loma Linda University Medical Center Health Plan (Adventist)',
    payer_id: '37267',
  },
  loma_linda_medical_center_managed_care: {
    name: 'Loma Linda University Medical Center Health Plan (Managed Care)',
    payer_id: '33036',
  },
  loomis_company: {
    name: 'Loomis Company',
  },
  magellan_health: {
    name: 'Magellan Health',
    payer_id: '01260',
  },
  magellan_health_aetna: {
    name: 'Magellan Health (Aetna Members Only)',
    payer_id: '60054',
  },
  magnacare: {
    name: 'Magnacare',
    payer_id: '11303',
  },
  magnolia_health_plan: {
    name: 'Magnolia Health Plan',
    payer_id: '68062',
  },
  mahp: {
    name: 'MAHP MAMSI (United)',
    payer_id: '87726',
  },
  mail_handlers: {
    name: 'Mail Handlers Benefit Plan (MHBP)',
    payer_id: '25133',
  },
  managed_care_services: {
    name: 'US Managed Care Services, LLC',
    payer_id: '35162',
  },
  managed_health_networks: {
    name: 'Managed Health Networks (MHN)',
    payer_id: '22771',
  },
  managed_health_services_in: {
    name: 'Managed Health Services Indiana',
    payer_id: '39186',
  },
  managed_health_services_insurance_corp: {
    name: 'Managed Health Services Insurance Corp',
  },
  managed_health_services_wi: {
    name: 'Managed Health Services Wisconsin',
    payer_id: '39187',
  },
  mapfre_life: {
    name: 'MAPFRE Life Puerto Rico',
  },
  maricopa_health_plan_az: {
    name: 'Maricopa Health Plan (Arizona)',
  },
  mayo_management_services: {
    name: 'Mayo Management Services',
    payer_id: '41154',
  },
  medcost: {
    name: 'Medcost Benefit Services',
  },
  medica_health_plans: {
    name: 'Medica Health Plans',
    payer_id: '94265',
  },
  medica_health_plans_ifb_groups: {
    name: 'Medica Health Plans (IFB Groups)',
    payer_id: '12422',
  },
  medica_healthcare_fl: {
    name: 'Medica Healthcare (FL)',
    payer_id: '78857',
  },
  medicaid_ak: {
    name: 'Alaska Medicaid',
  },
  medicaid_al: {
    name: 'Alabama Medicaid',
    payer_id: 'SKAL0',
  },
  medicaid_ar: {
    name: 'Arkansas Medicaid',
    payer_id: 'SKAR0',
  },
  medicaid_az: {
    name: 'Arizona Medicaid (AHCCCS)',
  },
  medicaid_ca: {
    name: 'California Medicaid (Medi-Cal)',
    payer_id: '610442',
  },
  medicaid_co: {
    name: 'Colorado Medicaid',
    payer_id: 'COTXIX',
  },
  medicaid_ct: {
    name: 'Connecticut Medicaid',
  },
  medicaid_dc: {
    name: 'District of Columbia Medicaid',
  },
  medicaid_de: {
    name: 'Delaware Medicaid',
  },
  medicaid_fl: {
    name: 'Florida Medicaid',
  },
  medicaid_ga: {
    name: 'Georgia Medicaid',
    payer_id: '77034',
  },
  medicaid_hi: {
    name: 'Hawaii Medicaid',
  },
  medicaid_ia: {
    name: 'Iowa Medicaid',
  },
  medicaid_id: {
    name: 'Idaho Medicaid',
  },
  medicaid_il: {
    name: 'Illinois Medicaid',
  },
  medicaid_in: {
    name: 'Indiana Medicaid',
  },
  medicaid_ks: {
    name: 'Kansas Medicaid',
    payer_id: 'KSMCD',
  },
  medicaid_ky: {
    name: 'Kentucky Medicaid',
  },
  medicaid_la: {
    name: 'Louisiana Medicaid',
    payer_id: 'SKLA0',
  },
  medicaid_ma: {
    name: 'Massachusetts Medicaid (MassHealth)',
    payer_id: 'SKMA0',
  },
  medicaid_md: {
    name: 'Maryland Medicaid',
  },
  medicaid_me: {
    name: 'Maine Medicaid',
  },
  medicaid_mi: {
    name: 'Michigan Medicaid',
    payer_id: 'MIMCD',
  },
  medicaid_mn: {
    name: 'Minnesota Medicaid',
  },
  medicaid_mo: {
    name: 'Missouri Medicaid',
    payer_id: '70029',
  },
  medicaid_ms: {
    name: 'Mississippi Medicaid',
  },
  medicaid_mt: {
    name: 'Montana Medicaid',
  },
  medicaid_nc: {
    name: 'North Carolina Medicaid',
    payer_id: 'NCTracks',
  },
  medicaid_nd: {
    name: 'North Dakota Medicaid',
  },
  medicaid_ne: {
    name: 'Nebraska Medicaid',
  },
  medicaid_nh: {
    name: 'New Hampshire Medicaid',
  },
  medicaid_nj: {
    name: 'New Jersey Medicaid',
  },
  medicaid_nm: {
    name: 'New Mexico Medicaid',
  },
  medicaid_nv: {
    name: 'Nevada Medicaid',
  },
  medicaid_ny: {
    name: 'New York Medicaid',
    payer_id: 'SKNY0',
  },
  medicaid_oh: {
    name: 'Ohio Medicaid',
    payer_id: '412006324',
  },
  medicaid_ok: {
    name: 'Oklahoma Health Care Authority (Oklahoma Medicaid)',
    payer_id: 'SKOK0',
  },
  medicaid_or: {
    name: 'Oregon Medicaid',
    payer_id: 'AIDOR',
  },
  medicaid_pa: {
    name: 'Pennsylvania Medicaid',
    payer_id: 'PAMCD',
  },
  medicaid_sc: {
    name: 'South Carolina Medicaid',
    payer_id: 'SCXIX',
  },
  medicaid_sd: {
    name: 'South Dakota Medicaid',
  },
  medicaid_tn: {
    name: 'Tennessee Medicaid (TennCare)',
    payer_id: 'SKTN2',
  },
  medicaid_tx_acute_care: {
    name: 'Texas Medicaid (Acute Care)',
    payer_id: '617591011C21P',
  },
  medicaid_tx_long_term_care: {
    name: 'Texas Medicaid (Long Term Care)',
    payer_id: '617591011CMSP',
  },
  medicaid_ut: {
    name: 'Utah Medicaid',
  },
  medicaid_va: {
    name: 'Virginia Medicaid',
    payer_id: 'AIDVA',
  },
  medicaid_vt: {
    name: 'Vermont Medicaid',
    payer_id: 'SKVT0',
  },
  medicaid_wa: {
    name: 'Washington Medicaid',
    payer_id: 'AIDWA',
  },
  medicaid_wi: {
    name: 'Wisconsin Medicaid',
  },
  medicaid_wv: {
    name: 'West Virginia Medicaid',
  },
  medicaid_wy: {
    name: 'Wyoming Medicaid',
  },
  medical_mutual_oh: {
    name: 'Medical Mutual of Ohio',
    payer_id: '29076',
  },
  medicare_b_al: {
    name: 'Alabama Medicare (Part B)',
    payer_id: '10112',
  },
  medicare_b_ar: {
    name: 'Arkansas Medicare (Part B)',
    payer_id: '07102',
  },
  medicare_b_co: {
    name: 'Colorado Medicare (Part B)',
    payer_id: '04112',
  },
  medicare_b_de: {
    name: 'Delaware Medicare (Part B)',
    payer_id: '12102',
  },
  medicare_b_fl: {
    name: 'Florida Medicare (Part B)',
    payer_id: '09102',
  },
  medicare_b_ga: {
    name: 'Georgia Medicare (Part B)',
    payer_id: '10212',
  },
  medicare_b_hi: {
    name: 'Hawaii Medicare (Part B)',
    payer_id: '01212',
  },
  medicare_b_ia: {
    name: 'Iowa Medicare (Part B)',
    payer_id: '05102',
  },
  medicare_b_in: {
    name: 'Indiana Medicare (Part B)',
    payer_id: '08102',
  },
  medicare_b_ks: {
    name: 'Kansas Medicare (Part B)',
    payer_id: '05202',
  },
  medicare_b_ky: {
    name: 'Kentucky Medicare (Part B)',
    payer_id: '15102',
  },
  medicare_b_la: {
    name: 'Louisiana Medicare (Part B)',
    payer_id: '07202',
  },
  medicare_b_md: {
    name: 'Maryland Medicare (Part B)',
    payer_id: '12302',
  },
  medicare_b_mi: {
    name: 'Michigan Medicare (Part B)',
    payer_id: '08202',
  },
  medicare_b_mo: {
    name: 'Missouri Medicare (Part B)',
    payer_id: '05302',
  },
  medicare_b_ms: {
    name: 'Mississippi Medicare (Part B)',
    payer_id: '07302',
  },
  medicare_b_nc: {
    name: 'North Carolina Medicare (Part B)',
    payer_id: '11502',
  },
  medicare_b_ne: {
    name: 'Nebraska Medicare (Part B)',
    payer_id: '05402',
  },
  medicare_b_nj: {
    name: 'New Jersey Medicare (Part B)',
    payer_id: '12402',
  },
  medicare_b_nm: {
    name: 'New Mexico Medicare (Part B)',
    payer_id: '04212',
  },
  medicare_b_ny_queens: {
    name: 'New York Medicare Part B Queens',
    payer_id: '13292',
  },
  medicare_b_oh: {
    name: 'Ohio Medicare (Part B)',
    payer_id: '15202',
  },
  medicare_b_ok: {
    name: 'Oklahoma Medicare (Part B)',
    payer_id: '04312',
  },
  medicare_b_pa: {
    name: 'Pennsylvania Medicare (Part B)',
    payer_id: '12502',
  },
  medicare_b_sc: {
    name: 'South Carolina Medicare (Part B)',
    payer_id: '11202',
  },
  medicare_b_southern_ca: {
    name: 'Southern California Medicare (Part B)',
    payer_id: '01182',
  },
  medicare_b_tn: {
    name: 'Tennessee Medicare (Part B)',
    payer_id: '10312',
  },
  medicare_b_tx: {
    name: 'Texas Medicare (Part B)',
    payer_id: '04412',
  },
  medicare_b_va: {
    name: 'Virginia Medicare (Part B)',
    payer_id: '11302',
  },
  medicare_b_wi: {
    name: 'Wisconsin Medicare (Part B)',
    payer_id: '06302',
  },
  medicare_dme_a: {
    name: 'Medicare DME Jurisdiction A',
    payer_id: '16013',
  },
  medicare_dme_b: {
    name: 'Medicare DME Jurisdiction B',
    payer_id: '17013',
  },
  medicare_dme_c: {
    name: 'Medicare DME Jurisdiction C',
    payer_id: '18003',
  },
  medicare_dme_d: {
    name: 'Medicare DME Jurisdiction D',
    payer_id: '19003',
  },
  medicare_national: {
    name: 'Medicare (all states)',
  },
  medicare_railroad: {
    name: 'Railroad Medicare',
    payer_id: '00882',
  },
  medico_insurance_company: {
    name: 'Medico Insurance Company',
  },
  medpartners: {
    name: 'MedPartners Administrative Services',
    payer_id: '412MP',
  },
  medstar_family_choice: {
    name: 'MedStar Family Choice',
  },
  mega_life_health: {
    name: 'Mega Life and Health Insurance',
  },
  mercy_care_plan: {
    name: 'Mercy Care Plan',
  },
  meritain_health: {
    name: 'Meritain Health',
    payer_id: '41124',
  },
  meritus_health_partners: {
    name: 'Meritus Health Partners',
  },
  metro_plus_health_plan: {
    name: 'Metro Plus Health Plan',
    payer_id: '13265',
  },
  moda_health: {
    name: 'MODA Health Plan',
    payer_id: '13350',
  },
  molina_health_care_ca: {
    name: 'Molina Healthcare of California',
    payer_id: '38333',
  },
  molina_health_care_fl: {
    name: 'Molina Healthcare of Florida',
    payer_id: '51062',
  },
  molina_health_care_il: {
    name: 'Molina Healthcare of Illinois',
    payer_id: '20934',
  },
  molina_health_care_mi: {
    name: 'Molina Healthcare of Michigan',
    payer_id: '38334',
  },
  molina_health_care_nm: {
    name: 'Molina Healthcare of New Mexico',
    payer_id: '09824',
  },
  molina_health_care_nv: {
    name: 'Molina Healthcare of Nevada',
    payer_id: '20676',
  },
  molina_health_care_oh: {
    name: 'Molina Healthcare of Ohio',
    payer_id: '20149',
  },
  molina_health_care_pr: {
    name: 'Molina Healthcare of Puerto Rico',
  },
  molina_health_care_sc: {
    name: 'Molina Healthcare of South Carolina',
    payer_id: '46299',
  },
  molina_health_care_tx: {
    name: 'Molina Healthcare of Texas',
    payer_id: '20554',
  },
  molina_health_care_ut: {
    name: 'Molina Healthcare of Utah',
    payer_id: 'SX109',
  },
  molina_health_care_wa: {
    name: 'Molina Healthcare of Washington',
    payer_id: '38336',
  },
  molina_health_care_wi: {
    name: 'Molina Healthcare of Wisconsin',
    payer_id: 'ABRI1',
  },
  montefiore_contract_management_organization: {
    name: 'Montefiore Contract Management Organization',
    payer_id: '13174',
  },
  multiplan: {
    name: 'Multiplan (PHCS)',
  },
  municipal_health_benefit_fund: {
    name: 'Municipal Health Benefit Fund',
    payer_id: '81883',
  },
  mutual_health_services: {
    name: 'Mutual Health Services',
  },
  mutual_omaha: {
    name: 'Mutual of Omaha',
    payer_id: '71412',
  },
  mvp_health_care: {
    name: 'MVP Health Care',
    payer_id: '14165',
  },
  nalc: {
    name: 'National Association of Letter Carriers (NALC)',
    payer_id: '53011',
  },
  nebraska_total_care: {
    name: 'Nebraska Total Care',
    payer_id: '68069',
  },
  neighborhood_health_partnership: {
    name: 'Neighborhood Health Partnership',
    payer_id: '95123',
  },
  neighborhood_health_plan: {
    name: 'Neighborhood Health Plan',
    payer_id: '04293',
  },
  network_health: {
    name: 'Tufts Health Plan - Network Health',
  },
  network_health_plan: {
    name: 'Network Health Plan',
  },
  new_era_life: {
    name: 'New Era Life Insurance Company',
  },
  new_hampshire_healthy_families: {
    name: 'New Hampshire Healthy Families',
    payer_id: '68069',
  },
  nippon_life_principal: {
    name: 'Nippon Life (Principal Financial Group)',
    payer_id: '81264',
  },
  nova_healthcare_administrators: {
    name: 'Nova Healthcare Administrators',
  },
  novasys_health: {
    name: 'NovaSys Health',
    payer_id: '68069',
  },
  ohana_health_plan: {
    name: 'Ohana Health Plan',
    payer_id: '14163',
  },
  optima_health_plan: {
    name: 'Optima Health Plan',
    payer_id: '54154',
  },
  optum_complex_medical_conditions: {
    name: 'OptumHealth Complex Medical Conditions (CMC)',
    payer_id: '41194',
  },
  optum_health_behavioral: {
    name: 'OptumHealth Behavioral Solutions',
    payer_id: '87726',
  },
  orange_county_advantage_medical_group: {
    name: 'Orange County Advantage Medical Group',
    payer_id: 'A0067',
  },
  oscar_health_plan: {
    name: 'Oscar Health Plan',
    payer_id: 'OSCAR',
  },
  oxford_health_plan_united: {
    name: 'Oxford Health Plan',
    payer_id: '06111',
  },
  pacific_source_health_plans: {
    name: 'PacificSource Health Plans',
    payer_id: '93029',
  },
  pan_american_life: {
    name: 'Pan-American Life Insurance Group',
    payer_id: '04218',
  },
  paramount: {
    name: 'Paramount',
  },
  parkland_community: {
    name: 'Parkland Community Health Plan',
    payer_id: '66917',
  },
  passport_health_plan: {
    name: 'Passport Health Plan',
    payer_id: '61129',
  },
  peach_state_health: {
    name: 'Peach State Health Plan',
    payer_id: '68049',
  },
  pehp_ut: {
    name: 'Public Employee Health Plan (PEHP) Utah',
    payer_id: 'SX106',
  },
  pennsylvania_health_wellness: {
    name: 'Pennsylvania Health and Wellness',
    payer_id: '68069',
  },
  phoenix_health_plan_medicare: {
    name: 'Phoenix Health Plan (Medicare) (formerly ABRAZO Advantage Health Plan)',
    payer_id: '22729',
  },
  physicians_health_plan_northern_in: {
    name: 'Physicians Health Plan of Northern Indiana',
  },
  physicians_health_plan_of_mid_michigan: {
    name: 'Physicians Health Plan of Mid-Michigan',
  },
  physicians_mutual: {
    name: 'Physicians Mutual Insurance Company',
    payer_id: '47027',
  },
  physicians_plus_health_insurance: {
    name: 'Physicians Plus Health Insurance',
  },
  piedmont_wellstar: {
    name: 'Piedmont WellStar Healthplans',
  },
  pinnacle_physician_management: {
    name: 'Pinnacle Physician Management Org',
  },
  planned_admin_inc: {
    name: 'Planned Administrators Inc',
    payer_id: '37287',
  },
  pomco: {
    name: 'POMCO',
    payer_id: '16111',
  },
  prairie_states: {
    name: 'Prairie States TPA',
  },
  preferred_care_partners: {
    name: 'Preferred Care Partners',
    payer_id: '65088',
  },
  preferredone_health_insurance: {
    name: 'PreferredOne Health Insurance',
    payer_id: '41147',
  },
  premera_blue_cross: {
    name: 'Premera Blue Cross',
    payer_id: '00430',
  },
  premera_blue_cross_blue_shield_ak: {
    name: 'Premera Blue Cross Blue Shield Of Alaska',
    payer_id: '00430',
  },
  prestige_health_choice: {
    name: 'Prestige Health Choice',
    payer_id: '77003',
  },
  prevea360_health_plan: {
    name: 'Prevea360 Health Plan',
  },
  principal_financial: {
    name: 'Principal Financial Group',
    payer_id: '61271',
  },
  priority_health: {
    name: 'Priority Health',
    payer_id: '38217',
  },
  promina_aso: {
    name: 'Promina ASO (Healthcare, Inc)',
  },
  providence_health_plan: {
    name: 'Providence Health Plan',
    payer_id: 'PHP01',
  },
  qual_choice_ar: {
    name: 'QualChoice of Arkansas',
    payer_id: '58379',
  },
  qualcare: {
    name: 'QualCare',
    payer_id: '23342',
  },
  regence_blue_shield_wa: {
    name: 'Regence BlueShield of Washington',
    payer_id: '00932',
  },
  regence_bluecross_blueshield_or: {
    name: 'Regence BlueCross BlueShield of Oregon',
    payer_id: '00851',
  },
  regence_blueshield_id: {
    name: 'Regence BlueShield of Idaho',
    payer_id: '00611',
  },
  regence_group_administrators: {
    name: 'Regence Group Administrators',
  },
  reliance_standard_life_insurance: {
    name: 'Reliance Standard Life Insurance',
  },
  reserve_national_insurance_company: {
    name: 'Reserve National Insurance Company',
  },
  rightcare_health_plan: {
    name: 'RightCare Health Plan',
    payer_id: '74205',
  },
  rocky_mountain_health_plan: {
    name: 'Rocky Mountain Health Plan',
    payer_id: 'SX141',
  },
  samba: {
    name: 'SAMBA (Special Agents Mutual Benefit Association)',
    payer_id: '62308',
  },
  san_francisco_health_plan: {
    name: 'San Francisco Health Plan (SFHP)',
  },
  sana_benefits: {
    name: 'Sana Benefits',
  },
  sandhills_center: {
    name: 'SandHills',
  },
  sanford_health_plan: {
    name: 'Sanford Health Plan',
  },
  santa_clara_family_health: {
    name: 'Santa Clara Family Health Plan',
    payer_id: '24077',
  },
  satellite_health_plan: {
    name: 'Satellite Health Plan',
    payer_id: '45552',
  },
  scan_health: {
    name: 'Scan Health Plan',
  },
  scott_and_white_health_plan: {
    name: 'Scott and White Health Plan',
    payer_id: '88030',
  },
  screen_actor_guild: {
    name: 'Screen Actor Guild',
    payer_id: '99289',
  },
  secure_horizons: {
    name: 'SecureHorizons Medicare Complete (United)',
    payer_id: '87726',
  },
  security_health_plan: {
    name: 'Security Health Plan',
  },
  selecthealth: {
    name: 'Selecthealth',
    payer_id: 'SX107',
  },
  senior_whole_health: {
    name: 'Senior Whole Health',
    payer_id: '83035',
  },
  sharp_health_plan: {
    name: 'Sharp Health Plan',
  },
  sierra_health_plan: {
    name: 'Sierra Health Plan',
    payer_id: '76342',
  },
  simply_healthcare: {
    name: 'Simply Healthcare',
    payer_id: '00199',
  },
  st_vincent_medical_center: {
    name: 'St Vincent Medical Center',
    payer_id: 'STVMC',
  },
  standard_life: {
    name: 'Standard Life and Accident Insurance Company',
  },
  state_farm: {
    name: 'State Farm',
    payer_id: '31053',
  },
  staywell: {
    name: 'StayWell',
    payer_id: '14163',
  },
  summacare: {
    name: 'Summacare Health Plan',
  },
  sunflower_state_health_plan: {
    name: 'Sunflower State Health Plan',
    payer_id: '68069',
  },
  sunshine_state_health_plan: {
    name: 'Sunshine State Health Plan',
    payer_id: '68057',
  },
  superior_health_plan: {
    name: 'Superior Health Plan',
    payer_id: '39188',
  },
  tall_tree_administrators: {
    name: 'Tall Tree Administrators',
    payer_id: '88067',
  },
  texas_childrens_health_plan_chip: {
    name: "Texas Children's Health Plan (CHIP)",
    payer_id: '76048',
  },
  texas_childrens_health_plan_star: {
    name: "Texas Children's Health Plan (Medicaid)",
    payer_id: 'TXCSM',
  },
  the_health_plan: {
    name: 'The Health Plan',
  },
  tlc_benefit_solutions: {
    name: 'TLC Benefit Solutions',
    payer_id: 'TLC79',
  },
  todays_options: {
    name: "Today's Options (Pyramid)",
  },
  tricare_east: {
    name: 'TRICARE East',
    payer_id: 'TREST',
  },
  tricare_for_life: {
    name: 'TRICARE For Life',
    payer_id: 'SX176',
  },
  tricare_overseas: {
    name: 'TRICARE Overseas',
  },
  tricare_west: {
    name: 'TRICARE West',
    payer_id: '99726',
  },
  trillium_health_plan: {
    name: 'Trillium Health Plan',
    payer_id: '421406317',
  },
  triple_s: {
    name: 'Triple S',
    payer_id: 'SB980',
  },
  tristar: {
    name: 'Tristar',
    payer_id: 'CB951',
  },
  trustmark: {
    name: 'Trustmark',
    payer_id: '61425',
  },
  tufts: {
    name: 'Tufts Health Plan',
    payer_id: '04298',
  },
  ucare: {
    name: 'UCare of Minnesota',
    payer_id: '52629',
  },
  ucla_medical_group: {
    name: 'UCLA Medical Group',
    payer_id: 'USMBP',
  },
  uhc_community_care_nc: {
    name: 'UHC Community Care North Carolina',
  },
  uhc_west: {
    name: 'UHC West (PacifiCare)',
    payer_id: '87726',
  },
  umr: {
    name: 'UMR',
    payer_id: '39026',
  },
  unicare: {
    name: 'UniCare',
    payer_id: '80314',
  },
  united_american_insurance_company: {
    name: 'United American Insurance Company',
  },
  united_health_care: {
    name: 'United Healthcare',
    payer_id: '87726',
  },
  united_health_care_community_plan: {
    name: 'United Healthcare Community Plan (AmeriChoice)',
    payer_id: '87726',
  },
  united_health_care_community_plan_ia: {
    name: 'United Healthcare Community Plan of Iowa',
    payer_id: '87726',
  },
  united_health_care_community_plan_ks: {
    name: 'United Healthcare Community Plan of Kansas (KanCare)',
    payer_id: '96385',
  },
  united_health_care_community_plan_mi: {
    name: 'United Healthcare Community Plan of Michigan',
    payer_id: '95467',
  },
  united_health_care_community_plan_mo: {
    name: 'United Healthcare Community Plan Missouri',
    payer_id: '86050',
  },
  united_health_care_community_plan_ms: {
    name: 'United Healthcare Community Plan of Mississippi (CHIP)',
    payer_id: '87726',
  },
  united_health_care_community_plan_nj: {
    name: 'United Healthcare Community Plan of New Jersey (AmeriChoice)',
    payer_id: '86047',
  },
  united_health_care_community_plan_pa: {
    name: 'United Healthcare Community Plan PA (AmeriChoice)',
    payer_id: '87726',
  },
  united_health_care_community_plan_tn: {
    name: 'United Healthcare Community Plan of Tennessee (TennCare)',
    payer_id: '95378',
  },
  united_health_care_facets_detroit: {
    name: 'United Healthcare Facets Detroit',
  },
  united_health_care_facets_pittsburgh: {
    name: 'United Healthcare Facets Pittsburgh',
  },
  united_health_care_medicare_solutions: {
    name: 'United Healthcare Medicare Solutions (Evercare)',
    payer_id: '87726',
  },
  united_health_care_river_valley: {
    name: 'United Healthcare of the River Valley',
    payer_id: '87726',
  },
  united_health_care_shared_services: {
    name: 'United Health Care Shared Services',
  },
  united_health_care_student_resources: {
    name: 'United Healthcare Student Resources (Student Insurance)',
    payer_id: '74227',
  },
  united_mine_workers_america: {
    name: 'United Mine Workers of America',
    payer_id: '52180',
  },
  unity_health: {
    name: 'Unity Health Plans Insurance Corporation',
  },
  univera: {
    name: 'Univera Healthcare',
    payer_id: 'HC001',
  },
  university_family_care: {
    name: 'University Family Care',
    payer_id: '09830',
  },
  university_health_alliance: {
    name: 'University Health Alliance',
    payer_id: '990263440',
  },
  university_healthcare_marketplace: {
    name: 'University of Arizona Health Plans (University Healthcare Marketplace)',
  },
  university_mo_coventry: {
    name: 'University of Missouri Health (Coventry)',
  },
  university_of_utah: {
    name: 'University of Utah Health Plans',
  },
  university_physicians_care_advantage_az: {
    name: 'University Physicians Care Advantage (AZ)',
  },
  upmc_health_plan: {
    name: 'UPMC Health Plan',
    payer_id: '23281',
  },
  usaa_life: {
    name: 'USAA Life Insurance',
    payer_id: '74095',
  },
  va_fee_basis: {
    name: 'Veterans Affairs Fee Basis Program',
    payer_id: '12115',
  },
  va_health_administration: {
    name: 'Veterans Affairs Health Administration Center',
  },
  valley_health_plan: {
    name: 'Valley Health Plan',
    payer_id: 'VHP01',
  },
  veterans_affairs_3: {
    name: 'Veterans Affairs Patient Centered Community Care Region 3',
  },
  veterans_affairs_5a: {
    name: 'Veterans Affairs Patient Centered Community Care Region 5a',
  },
  veterans_affairs_5b: {
    name: 'Veterans Affairs Patient Centered Community Care Region 5b',
  },
  veterans_affairs_6: {
    name: 'Veterans Affairs Patient Centered Community Care Region 6',
  },
  village_care_max: {
    name: 'VillageCareMAX',
    payer_id: '26545',
  },
  village_family_practice: {
    name: 'Village Family Practice',
  },
  vista_health_plan: {
    name: 'Vista Health Plan',
  },
  viva_health: {
    name: 'Viva Health',
  },
  vns_choice: {
    name: 'VNS Choice',
    payer_id: '77073',
  },
  wea_trust: {
    name: 'WEA Trust',
  },
  web_tpa: {
    name: 'WebTPA',
    payer_id: '75261',
  },
  wellcare: {
    name: 'Wellcare',
    payer_id: '14163',
  },
  wellmark_blue_cross_blue_shield_ia: {
    name: 'Wellmark Blue Cross and Blue Shield of Iowa',
  },
  wellmark_blue_cross_blue_shield_ia_and_sd: {
    name: 'Wellmark Blue Cross and Blue Shield of Iowa and South Dakota',
    payer_id: 'SB640',
  },
  wellmark_blue_cross_blue_shield_sd: {
    name: 'Wellmark Blue Cross and Blue Shield of South Dakota',
  },
  wellsystems: {
    name: 'Wellsystems',
    payer_id: '35245',
  },
  western_growers_insurance: {
    name: 'Western Growers Insurance',
    payer_id: '24735',
  },
  western_health_advantage: {
    name: 'Western Health Advantage',
  },
  wps_insurance: {
    name: 'WPS Insurance',
  },
  writers_guild: {
    name: 'Writers Guild',
    payer_id: '25133',
  },
};

export type Carriers = typeof CARRIERS;
export type Carrier = Carriers[keyof Carriers];

export const VALID_CARRIER_NAMES = Object.values(CARRIERS).map((c) => c.name);

export const getCarrierId = (byName: string | null): string | undefined => {
  const carrier = Object.entries(CARRIERS).find(
    ([_, { name }]) => name === byName,
  );
  return carrier?.[0];
};

export const getCarrierName = (byId: string): string | undefined => {
  const carrier = Object.entries(CARRIERS).find(([id, _]) => id === byId);
  return carrier && carrier[1] && carrier[1].name;
};
