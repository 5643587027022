import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { TextButton } from '../button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Colors.grayLighter};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const Icon = styled.img`
  height: 24px;
  margin-right: 8px;
  width: 24px;
`;

export const Flags = styled.div`
  padding: 8px 0;
`;

export const AddLink = styled(TextButton)`
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  padding: 0;

  &:hover img {
    filter: brightness(0) saturate(100%) invert(62%) sepia(97%) saturate(590%)
      hue-rotate(115deg) brightness(84%) contrast(101%);
  }
`;

export const ArchiveLink = styled(AddLink)`
  color: ${Colors.gray};

  &:hover {
    color: ${Colors.darkHarbor};
  }
`;
