import jwtDecode from 'jwt-decode';

export interface Token {
  exp: number;
  sub: string;
  'https://hasura.io/jwt/claims': {
    'x-hasura-user-id': string;
    'x-hasura-allowed-roles': string[];
    'x-hasura-default-role': string;
  };
}

const getClaims = (parsed: Token) => {
  return parsed['https://hasura.io/jwt/claims'];
};

export const getParsedToken = (token: string) =>
  jwtDecode(token) as any as Token;

export const hasRole = (role: string, token: string) => {
  try {
    const claims = getClaims(getParsedToken(token));
    return (claims['x-hasura-allowed-roles'] || []).includes(role);
  } catch (error) {
    return false;
  }
};

export const getUserId = (token: string): string | undefined => {
  try {
    const claims = getClaims(getParsedToken(token));
    return claims?.['x-hasura-user-id'];
  } catch (error) {}
};
