import getIn from "lodash/get";

export const getCopayAmount = (carrier: string, eligibility: any): string => {
  const isInNetwork = carrier && !/kaiser/.test(carrier || "");
  const copayOptions = getIn(eligibility, "coverage.copay") || [];

  const dataFilter = (data: any) =>
    (data.service_type_codes || []).includes("UC") &&
    data.in_plan_network === (isInNetwork ? "yes" : "no") &&
    data.coverage_level === "individual";

  const copayData = copayOptions.filter(dataFilter).pop();
  const copayAmount = getIn(copayData, "copayment.amount") || "";

  return copayAmount;
};
