import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { ACCENT_GREEN, FONT_PRIMARY, GREY } from '../../styles';
import { isSameDate } from '../Schedule/helper';
import {
  CalendarControl,
  CalendarControls,
  Next,
  Prev,
} from './CalendarSelector';

const CalendarTitle = styled.span`
  ${FONT_PRIMARY};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${GREY};
`;

const CalendarHead = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 14px;
`;

const CalendarBody = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  min-width: 294px;
`;

const Day = styled.div`
  ${FONT_PRIMARY};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 14.2857142857143%;
  min-width: 42px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: ${GREY};
`;

const Weekday = styled(Day)`
  ${FONT_PRIMARY}
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.857143px;
  text-transform: uppercase;
  color: #94a2a1;
`;

const Selected = styled.div`
  align-items: center;
  background: ${ACCENT_GREEN};
  border-radius: 100px;
  color: white;
  display: flex;
  font-weight: bold;
  height: 34px;
  justify-content: center;
  width: 34px;
  font-weight: 600;
`;

interface CalendarSelectorProps {
  onChange: (date: Date) => void;
  value: Date[];
  month: Date;
  style?: React.CSSProperties;
}

export const CalendarSelectorMulti: React.FC<CalendarSelectorProps> = ({
  onChange,
  month,
  value,
  style,
}) => {
  const current = DateTime.fromJSDate(month);
  const values = value.map((val) => DateTime.fromJSDate(val));
  const [date, setDate] = useState(current.startOf('month'));
  const days = Array.from(Array(date.daysInMonth).keys()).map((d) => {
    return date.set({
      day: d + 1,
    });
  });

  const previousMonthDays = Array.from(
    // weekday where 1 is Monday and 7 is Sunday
    Array(date.weekday === 7 ? 0 : date.weekday).keys(),
  )
    .map((offsetDay) => date.minus({ day: offsetDay + 1, month: 1 }))
    .sort((a, b) => a.day - b.day);

  const nextMonthDays = Array.from(
    Array(
      // weekday where 1 is Monday and 7 is Sunday
      6 - (date.endOf('month').weekday === 7 ? 0 : date.endOf('month').weekday),
    ).keys(),
  )
    .map((offsetDay) => date.plus({ day: offsetDay, month: 1 }))
    .sort((a, b) => a.day - b.day);

  return (
    <div style={style}>
      <CalendarHead>
        <CalendarTitle>
          {date.monthLong} {date.year}
        </CalendarTitle>
        <CalendarControls>
          <CalendarControl onClick={() => setDate(date.minus({ month: 1 }))}>
            <Prev />
          </CalendarControl>
          <CalendarControl onClick={() => setDate(date.plus({ month: 1 }))}>
            <Next />
          </CalendarControl>
        </CalendarControls>
      </CalendarHead>
      <CalendarBody>
        <Weekday>S</Weekday>
        <Weekday>M</Weekday>
        <Weekday>T</Weekday>
        <Weekday>W</Weekday>
        <Weekday>T</Weekday>
        <Weekday>F</Weekday>
        <Weekday>S</Weekday>
        {previousMonthDays.map((day) => {
          const isSelected = !!values.find((value) => isSameDate(value, day));

          return (
            <Day
              key={`previousMonth-${day}`}
              onClick={() => onChange(day.toJSDate())}
              style={{
                cursor: 'pointer',
                color: Colors.gray,
              }}
            >
              {isSelected ? <Selected>{day.day}</Selected> : day.day}
            </Day>
          );
        })}
        {days.map((day) => {
          const isSelected = !!values.find((value) => isSameDate(value, day));

          return (
            <Day
              key={`dateOfMonth-${day.month}-${day.day}`}
              onClick={() => {
                onChange(day.toJSDate());
              }}
              style={{
                cursor: 'pointer',
              }}
            >
              {isSelected ? <Selected>{day.day}</Selected> : day.day}
            </Day>
          );
        })}
        {nextMonthDays.map((day) => {
          const isSelected = !!values.find((value) => isSameDate(value, day));

          return (
            <Day
              key={`nextMonth-${day}`}
              onClick={() => onChange(day.toJSDate())}
              style={{
                cursor: 'pointer',
                color: Colors.gray,
              }}
            >
              {isSelected ? <Selected>{day.day}</Selected> : day.day}
            </Day>
          );
        })}
      </CalendarBody>
    </div>
  );
};
