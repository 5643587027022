/** @jsx jsx */
import {
  ServiceFragment,
  Services_Enum,
  StaffFragment,
  Staff_Type_Enum,
  useAuth0LoadEmployeeMutation,
  useDeleteStaffScheduleAfterDateMutation,
  useInsertStaffMutation,
  useLoadStaffDataQuery,
  useLoadStaffingDataQuery,
  useNormalizeStaffRolesMutation,
  useUpdateStaffMutation,
  useUpsertStaffRegionsMutation,
  useUpsertStaffServicesMutation,
} from '@bc/codegen/manager';
import { timeZoneMap } from '@bc/shared';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import * as Sentry from '@sentry/react';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import capitalize from 'lodash/capitalize';
import last from 'lodash/last';
import sample from 'lodash/sample';
import { DateTime } from 'luxon';
import { Fragment, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { array, boolean, object, string } from 'yup';
import { hasRole } from '../../auth';
import AvatarEmailLookup from '../../components/AvatarEmailLookup';
import { Checkbox } from '../../components/CheckboxFilter';
import ClinicSearch from '../../components/ClinicSelector/ClinicSelector';
import { RegionLeadSearch } from '../../components/LeadSearch/RegionLeadSearch';
import { Loader } from '../../components/Loader';
import { PhoneInput } from '../../components/PhoneInput';
import { MultiRegionDropdown } from '../../components/Regions/MultiRegionDropdown';
import { Button, ButtonRed } from '../../components/button';
import {
  CheckboxRowWrap,
  ErrorText,
  Input,
  NormalField,
  SelectSimpleField,
} from '../../components/form';
import {
  ContentRow,
  InfoSection,
  SectionContent,
} from '../../components/layout';
import { PrimaryText } from '../../components/text';
import { useAuth0 } from '../../react-auth0';
import { GREY_LIGHT } from '../../styles';
import { Close, Content, LargeTitle } from '../ViewAppointment.styles';
import StaffRemovalConfirmation from './StaffRemovalConfirmation';
import { CancelButton } from './styles';

const STAFF_Colors = [
  '#FF3C00',
  '#FFBC00',
  '#FFE499',
  '#0253AB',
  '#9ABADD',
  '#11BB9A',
  '#A0E4D7',
  '#FF5469',
  '#FFBBC3',
  '#AE4398',
  '#DFB4D6',
];

const INITIAL_STAFF = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  services: [],
  languagesSpoken: '',
  timeZone: 'America/Los_Angeles',
  type: undefined,
  bio: '',
  lead: false,
  defaultClinicId: null,
  staffManagerId: null,
  regions: [],
} as Values;

interface Values extends Partial<StaffFragment> {
  services?: Services_Enum[];
  regions: number[];
}

const StaffValidation = object().shape({
  email: string()
    .email('Valid email required')
    .required('Required')
    .test('bravecare', 'Must be @bravecare.com email', (value = '') => {
      return last(value?.split('@')) === 'bravecare.com';
    }),
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  languagesSpoken: string().required('Required'),
  timeZone: string().required('Required'),
  type: string().required('Required'),
  bio: string(),
  lead: boolean(),
  defaultClinicId: string().nullable(),
  regions: array().of(string()).required('Required'),
});

const ALLOWED_BY_TYPE: Record<Staff_Type_Enum, Services_Enum[]> = {
  PHYSICIAN: [
    'PRIMARY_CARE',
    'MEET_AND_GREET',
    'TELEMEDICINE',
    'URGENT_CARE',
    'VACCINATION',
    'XRAY',
    'ONCALL',
    'TESTING',
  ],
  PHYSICIAN_ASSISTANT: [
    'PRIMARY_CARE',
    'MEET_AND_GREET',
    'TELEMEDICINE',
    'URGENT_CARE',
    'VACCINATION',
    'XRAY',
    'ONCALL',
    'TESTING',
  ],
  NURSE_PRACTITIONER: [
    'PRIMARY_CARE',
    'MEET_AND_GREET',
    'TELEMEDICINE',
    'URGENT_CARE',
    'VACCINATION',
    'XRAY',
    'ONCALL',
    'TESTING',
  ],
  MEDICAL_ASSISTANT: ['XRAY', 'VACCINATION', 'XRAY_ONCALL'],
  TRIAGE_NURSE: ['TRIAGE'],
  OPERATION: [],
  UNKNOWN: [],
};

const filterServices = (
  services: ServiceFragment[],
  type?: Staff_Type_Enum,
) => {
  if (!type) return [];
  const allowedServices = ALLOWED_BY_TYPE[type];

  return services.filter(({ value }) => {
    return allowedServices.includes(value as Services_Enum);
  });
};

const filterForSave = (services: Services_Enum[], type: Staff_Type_Enum) => {
  const allowedServices = ALLOWED_BY_TYPE[type];
  let saveServices = (services || []).filter((service) => {
    return allowedServices.includes(service as Services_Enum);
  });
  return saveServices;
};

const context = { role: 'manager' };

const StaffForm = ({
  history,
  match,
}: RouteComponentProps<{ staffId?: string }>) => {
  const { staffId } = match.params;
  const { accessToken } = useAuth0();
  const isManager = hasRole('manager', accessToken);
  const [showRemovalConfirmation, setShowRemovalConfirmation] = useState(false);
  const [insertStaff] = useInsertStaffMutation({ context });
  const [updateStaff] = useUpdateStaffMutation({ context });
  const [deleteStaffScheduleAfterDate] =
    useDeleteStaffScheduleAfterDateMutation({ context });
  const [upsertStaffServices] = useUpsertStaffServicesMutation({ context });
  const [upsertStaffRegions] = useUpsertStaffRegionsMutation({ context });
  const [normalizeStaffRoles] = useNormalizeStaffRolesMutation({ context });
  const [loadEmployeeFromEmail] = useAuth0LoadEmployeeMutation({ context });

  const { data: staffData } = useLoadStaffingDataQuery();
  const { data, refetch, loading } = useLoadStaffDataQuery({
    context,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        id: {
          _eq: staffId,
        },
      },
    },
    skip: !staffId,
  });
  const services = staffData?.services ?? [];
  const staffTypes = staffData?.staff_type ?? [];
  const staff = data?.staff.find((s) => s.id === staffId);

  const initialStaff = staff
    ? {
        ...staff,
        services: staff?.staff_services.map((s) => s.service),
        regions: staff?.staff_regions.map((s) => s.regionId) ?? [],
      }
    : INITIAL_STAFF;
  const isExisting = !!staff?.id;

  const handleRemoveStaff = async () => {
    await updateStaff({ variables: { id: staffId!, set: { active: false } } });
    await deleteStaffScheduleAfterDate({
      variables: {
        staffId: staffId!,
        date: DateTime.local()
          .startOf('day')
          .toSQL({ includeOffset: false, includeZone: false }),
      },
    });
    // Delete the schedule for the staff member from today on
    refetch();
    history.push('/staff/view');
  };

  const handleUpdateStaff = async (values: Values) => {
    const {
      bio,
      email,
      firstName,
      languagesSpoken,
      lastName,
      phoneNumber,
      services,
      timeZone,
      type,
      color,
      avatarUrl,
      lead,
      defaultClinicId,
      regions,
      staffManagerId,
    } = values;

    await updateStaff({
      variables: {
        id: staffId!,
        set: {
          bio,
          email: email?.trim()?.toLowerCase(),
          firstName: firstName?.trim(),
          lastName: lastName?.trim(),
          languagesSpoken,
          phoneNumber,
          timeZone,
          type,
          color,
          avatarUrl,
          lead,
          defaultClinicId,
          staffManagerId,
        },
      },
    });

    // upsert regions?
    // Delete the regions the staff are in, reinsert updatess
    if (values.type) {
      await upsertStaffServices({
        variables: {
          where: {
            staffId: { _eq: staffId },
          },
          services: filterForSave(services || [], values.type)?.map(
            (service) => ({
              service,
              staffId,
            }),
          ),
        },
      });
    }

    // Delete the regions the staff are in, reinsert regions for the staff
    upsertStaffRegions({
      variables: {
        where: {
          staffId: { _eq: staffId },
        },
        regions: regions.map((regionId) => {
          return {
            staffId,
            regionId,
          };
        }),
      },
    });

    refetch();

    history.push('/staff/view');
  };

  const handleInsertStaff = async (
    values: Values,
    { resetForm }: FormikHelpers<Values>,
  ) => {
    const { services = [], staff_type, regions, ...staff } = values;
    const { data: newStaff } = await insertStaff({
      variables: {
        staff: {
          ...staff,
          defaultClinicId: values.defaultClinicId || null,
          color: staff.color || sample(STAFF_Colors),
          email: staff.email?.toLowerCase().trim(),
          firstName: staff.firstName?.trim(),
          lastName: staff.lastName?.trim(),
          staff_regions: {
            data: regions.map((regionId) => {
              return {
                regionId,
              };
            }),
          },
          staff_services: {
            data: filterForSave(services, staff.type!).map((service) => ({
              service,
            })),
          },
        },
      },
    });

    resetForm({ values: INITIAL_STAFF });

    refetch();

    history.push('/staff/view');
  };

  return (
    <Content aria-label={isExisting ? 'Edit Staff Member' : 'Add Staff Member'}>
      {((staffId && !loading) || !staffId) && (
        <Formik
          //@ts-ignore
          initialValues={initialStaff}
          onSubmit={isExisting ? handleUpdateStaff : handleInsertStaff}
          validationSchema={StaffValidation}
          isInitialValid={isExisting}
          enableReinitialize
        >
          {({
            errors,
            isSubmitting,
            setFieldTouched,
            setFieldValue,
            setValues,
            values,
            handleChange,
          }) => {
            const handleSearchAuth0 = async () => {
              if (!values.email) return;
              try {
                const { data: userData } = await loadEmployeeFromEmail({
                  variables: {
                    email: values.email,
                  },
                });

                setValues({
                  ...values,
                  firstName: userData?.Auth0LoadEmployee?.firstName,
                  lastName: userData?.Auth0LoadEmployee?.lastName,
                  auth0UserId: userData?.Auth0LoadEmployee?.userId,
                  color: values.color || sample(STAFF_Colors),
                });
              } catch (error) {
                Sentry.captureException(error);
              }
            };

            return (
              <div>
                <div style={{ padding: '40px 40px 0 40px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                      alignItems: 'center',
                    }}
                  >
                    {isExisting ? (
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <Field name="avatarUrl">
                          {({ field }: FieldProps<any>) => (
                            <AvatarEmailLookup
                              value={field.value}
                              email={values.email || ''}
                              onUpdate={handleChange(field.name)}
                            />
                          )}
                        </Field>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '16px',
                          }}
                        >
                          <LargeTitle
                            css={{
                              marginBottom: '8px',
                            }}
                          >
                            {staff?.firstName} {staff?.lastName}
                          </LargeTitle>
                          <Field name="color">
                            {({ field }: FieldProps<any>) => {
                              if (!field.value) return null;

                              return (
                                <div css={{ display: 'flex' }}>
                                  <PrimaryText
                                    css={{
                                      color: Colors.gray,
                                    }}
                                  >
                                    Display Color:{' '}
                                  </PrimaryText>
                                  <div
                                    style={{
                                      width: '16px',
                                      height: '16px',
                                      borderRadius: '5px',
                                      marginLeft: '8px',
                                      backgroundColor: field.value,
                                    }}
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <Field name="avatarUrl">
                          {({ field }: FieldProps<any>) => (
                            <AvatarEmailLookup
                              value={field.value}
                              email={values.email || ''}
                              onUpdate={handleChange(field.name)}
                            />
                          )}
                        </Field>
                        <div
                          css={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '16px',
                          }}
                        >
                          <LargeTitle
                            css={{
                              marginBottom: '8px',
                            }}
                          >
                            New Staff Member
                          </LargeTitle>
                          <Field name="color">
                            {({ field }: FieldProps<any>) => {
                              if (!field.value) return null;

                              return (
                                <div css={{ display: 'flex' }}>
                                  <PrimaryText
                                    css={{
                                      color: Colors.gray,
                                    }}
                                  >
                                    Display Color:{' '}
                                  </PrimaryText>
                                  <div
                                    style={{
                                      width: '16px',
                                      height: '16px',
                                      borderRadius: '5px',
                                      marginLeft: '8px',
                                      backgroundColor: field.value,
                                    }}
                                  />
                                </div>
                              );
                            }}
                          </Field>
                        </div>
                      </div>
                    )}

                    <Close onClick={() => history.push('/staff/view')} />
                  </div>
                </div>
                <SectionContent
                  style={
                    isExisting
                      ? {
                          borderTop: `1px solid ${GREY_LIGHT}`,
                          padding: '20px 0',
                          margin: 40,
                          marginTop: 20,
                        }
                      : {
                          padding: 40,
                        }
                  }
                >
                  <Form>
                    <ContentRow>
                      <NormalField
                        title="First name"
                        name="firstName"
                        placeholder="First name"
                      />
                      <NormalField
                        title="Last name"
                        name="lastName"
                        placeholder="Last name"
                      />
                    </ContentRow>
                    <ContentRow>
                      <InfoSection title="Brave Care Email">
                        <Field name="email">
                          {({ field, form }: FieldProps<any>) => (
                            <Fragment>
                              <Input
                                {...field}
                                onBlur={(e) => {
                                  field.onBlur(e);
                                  handleSearchAuth0();
                                }}
                                placeholder="first.last@bravecare.com"
                                error={!!errors.email}
                                autoComplete="off"
                              />
                              {form.touched.email && (
                                <ErrorText>{errors.email}</ErrorText>
                              )}
                            </Fragment>
                          )}
                        </Field>
                      </InfoSection>
                      <InfoSection title="Phone number">
                        <Field name="phoneNumber">
                          {({ field }: FieldProps<any>) => (
                            <PhoneInput
                              {...field}
                              onChange={(value) =>
                                setFieldValue(field.name, value)
                              }
                              error={!!errors.phoneNumber}
                            />
                          )}
                        </Field>
                      </InfoSection>
                    </ContentRow>
                    <ContentRow>
                      <InfoSection title="Regions">
                        <Field name="regions">
                          {({ field }) => {
                            return (
                              <MultiRegionDropdown
                                regionIds={field.value ?? []}
                                onSelect={(regions) => {
                                  setFieldValue(field.name, regions);

                                  if (values.defaultClinicId) {
                                    setFieldValue('defaultClinicId', null);
                                  }
                                  if (values.staffManagerId) {
                                    setFieldValue('staffManagerId', null);
                                  }
                                }}
                              />
                            );
                          }}
                        </Field>
                      </InfoSection>
                      <InfoSection title="Home Clinic">
                        <Field name="defaultClinicId">
                          {({ field }) => {
                            return (
                              <ClinicSearch
                                disabled={!values.regions?.length}
                                regionIds={values.regions}
                                value={field.value}
                                onChange={(clinic) => {
                                  setFieldValue(field.name, clinic?.id ?? null);
                                }}
                              />
                            );
                          }}
                        </Field>
                      </InfoSection>
                    </ContentRow>
                    <ContentRow>
                      <InfoSection title="Staff type">
                        <SelectSimpleField name="type">
                          <option value="">Select type</option>
                          {staffTypes?.map(({ description, value }) => (
                            <option key={value} value={value}>
                              {description}
                            </option>
                          ))}
                        </SelectSimpleField>
                      </InfoSection>
                      <InfoSection title="Manager">
                        <Field name="staffManagerId">
                          {({ field }) => {
                            return (
                              <RegionLeadSearch
                                disabled={!values.regions?.length}
                                regionIds={values.regions}
                                value={field.value}
                                onChange={(staff) => {
                                  setFieldValue(field.name, staff?.id ?? null);
                                }}
                              />
                            );
                          }}
                        </Field>
                      </InfoSection>
                    </ContentRow>
                    <ContentRow>
                      <InfoSection title="Time zone">
                        <SelectSimpleField name="timeZone">
                          <option value="">Select</option>
                          {timeZoneMap?.map(({ value, label }) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))}
                        </SelectSimpleField>
                      </InfoSection>
                      <InfoSection>
                        <NormalField
                          title="Languages spoken"
                          name="languagesSpoken"
                          placeholder="List all languages spoken (e.g. Spanish, English)"
                          fullWidth
                        />
                      </InfoSection>
                    </ContentRow>

                    <ContentRow>
                      <InfoSection title="Lead">
                        <CheckboxRowWrap style={{ marginBottom: 0 }}>
                          <Checkbox
                            color={Colors.teal}
                            groupName="lead"
                            label="Lead"
                            onChange={() => {
                              setFieldValue('lead', !values.lead);
                            }}
                            value="lead"
                            isChecked={values.lead ?? false}
                            onBlur={() => setFieldTouched('lead')}
                          />
                        </CheckboxRowWrap>
                      </InfoSection>
                    </ContentRow>
                    <ContentRow style={{ marginBottom: 0 }}>
                      <InfoSection title="Services provided" fullWidth>
                        <CheckboxRowWrap style={{ marginBottom: 0 }}>
                          {filterServices(services, values.type).map(
                            (service) => {
                              const serviceValue =
                                service.value as Services_Enum;
                              const foundIndex =
                                values?.services?.indexOf(serviceValue);
                              const hasValue =
                                values?.services?.includes(serviceValue);
                              const index = hasValue
                                ? foundIndex
                                : values?.services?.length ?? 0;
                              const field = `services[${index}]`;

                              const toggleValue = () => {
                                if (!hasValue) {
                                  setFieldValue(field, serviceValue);
                                } else {
                                  setFieldValue(
                                    'services',
                                    values?.services?.filter(
                                      (val) => val !== serviceValue,
                                    ),
                                  );
                                }
                              };

                              return (
                                <Checkbox
                                  key={serviceValue}
                                  color={Colors.teal}
                                  groupName={field}
                                  label={capitalize(
                                    serviceValue.replace(/_/g, ' '),
                                  )}
                                  value={serviceValue}
                                  onChange={toggleValue}
                                  isChecked={!!hasValue}
                                  onBlur={() => setFieldTouched(field)}
                                />
                              );
                            },
                          )}
                        </CheckboxRowWrap>
                      </InfoSection>
                    </ContentRow>
                    {isExisting &&
                      (values.type === 'PHYSICIAN' ||
                        values.type === 'PHYSICIAN_ASSISTANT' ||
                        values.type === 'NURSE_PRACTITIONER') && (
                        <ContentRow style={{ marginBottom: 40 }}>
                          <NormalField
                            name="bio"
                            title="Bio (optional)"
                            fullWidth
                          />
                        </ContentRow>
                      )}
                    <ContentRow>
                      {isExisting && (
                        <Fragment>
                          {isManager && (
                            <ButtonRed
                              type="button"
                              onClick={() => setShowRemovalConfirmation(true)}
                            >
                              Remove
                            </ButtonRed>
                          )}
                          <CancelButton
                            type="button"
                            style={{ marginLeft: 'auto' }}
                            onClick={() => history.push('/staff/view')}
                          >
                            Cancel
                          </CancelButton>
                        </Fragment>
                      )}
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        style={{
                          marginLeft: isExisting ? 24 : 'auto',
                          width: 'auto',
                        }}
                      >
                        Save
                      </Button>
                    </ContentRow>
                  </Form>
                </SectionContent>
              </div>
            );
          }}
        </Formik>
      )}
      {staffId && loading && <Loader />}

      <StaffRemovalConfirmation
        isOpen={showRemovalConfirmation}
        onCancel={() => setShowRemovalConfirmation(false)}
        onRemove={handleRemoveStaff}
      />
    </Content>
  );
};

export default StaffForm;
