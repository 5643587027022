import { useLoadStaffAndServicesQuery } from '@bc/codegen/manager';
import {
  Clinics_Regions_Bool_Exp,
  LoadBasicStaffDetailsQuery,
  Regions_Bool_Exp,
  useLoadRegionsClinicsQuery,
} from '@bc/codegen/medical';
import { useAuth0 } from '../react-auth0';

export type BasicStaff = LoadBasicStaffDetailsQuery['staff'][0];

export const useCurrentStaff = () => {
  const { user } = useAuth0();

  const { data: staffData } = useLoadStaffAndServicesQuery({
    variables: {
      where: {
        auth0UserId: {
          _eq: user.sub,
        },
      },
    },
    skip: !user.sub,
  });

  return staffData?.staff?.[0];
};

export const useCurrentStaffType = () => {
  const { user } = useAuth0();
  const { data: staffData } = useLoadStaffAndServicesQuery({
    variables: {
      where: {
        auth0UserId: {
          _eq: user.sub,
        },
      },
    },
    skip: !user.sub,
  });

  return staffData?.staff_type?.[0];
};

export const useStaffRegions = () => {
  const staff = useCurrentStaff();

  const type = staff?.type;
  let regionQuery: Regions_Bool_Exp = {};
  let clinicQuery: Clinics_Regions_Bool_Exp = {};

  if (type !== 'OPERATION' && type !== 'TRIAGE_NURSE' && staff) {
    regionQuery = {
      staff_regions: {
        staffId: {
          _eq: staff.id,
        },
      },
    };
    clinicQuery = {
      clinic: {
        status: {
          _nin: ['HIDDEN', 'PERMANENTLY_CLOSED'],
        },
      },
    };
  }

  const { data, loading } = useLoadRegionsClinicsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !staff,
    variables: {
      where: regionQuery,
      clinicWhere: clinicQuery,
    },
  });

  return { data: data?.regions ?? [], loading };
};
