/** @jsx jsx */
import {
  Tasks_Bool_Exp,
  Task_Sub_Type_Enum,
  Task_Type_Enum,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Menu, useMenuButtonContext } from '@reach/menu-button';
import Popover, { positionMatchWidth } from '@reach/popover';
import isEqual from 'lodash/isEqual';
import { DateTime } from 'luxon';
import { Fragment, useEffect, useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { ICONS } from '../../assets';
import { getDisplayDate } from '../../pages/Dashboard.helpers';
import { ACCENT_GREEN } from '../../styles';
import { TransparentButton } from '../button';
import { CalendarSelector } from '../CalendarSelector';
import { Checkbox } from '../CheckboxFilter';
import { InputWrap } from '../form';
import { PrimaryText } from '../text';
import FilterIcon from './icons/filter.svg';
import { SUB_TYPES } from './types';
import { DropdownMenuList, DullText, PillMenuButton } from './ui';

const Title = styled(PrimaryText)({
  fontWeight: 'bold',
  fontSize: '20px',
  lineHeight: '24px',
  color: Colors.darkHarbor,
});
const Subtitle = styled(PrimaryText)({
  fontWeight: 500,
  lineHeight: '24px',
  color: Colors.darkHarbor,
});

const TextDisplay = styled(PrimaryText)({
  fontSize: '14px',
  fontWeight: 500,
  color: Colors.darkHarbor,
});

const HalfWidth = styled.div({
  width: '50%',
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '22px',
});

const ColumnWrap = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
});

const Section = styled.div({
  padding: '16px 32px',
  display: 'flex',
  flexDirection: 'column',
});

const OVERDUE_FILTER: Tasks_Bool_Exp = {
  _or: [
    {
      type: {
        _eq: 'ACTION',
      },
      status: {
        _neq: 'COMPLETED',
      },
      dueDateTime: {
        _lte: 'now()',
      },
    },
    {
      type: {
        _eq: 'INFORMATION',
      },
      dueDateTime: {
        _lte: 'now()',
      },
      tasks_responsible_count: {
        completed: {
          _neq: true,
        },
      },
    },
  ],
};

const GENERAL_FILTER: Tasks_Bool_Exp = { subType: { _is_null: true } };

const buildGeneralFilter = (
  hasGeneral: boolean,
  subTypes: Task_Sub_Type_Enum[],
) => {
  const filters: Tasks_Bool_Exp[] = [];

  if (hasGeneral) {
    filters.push(GENERAL_FILTER);
  }

  if (subTypes.length) {
    filters.push({
      _or: [
        {
          subType: {
            _in: subTypes,
          },
        },
      ],
    });
  }
  return filters.length
    ? {
        _or: filters,
      }
    : undefined;
};

const getFilterCount = (filters: Tasks_Bool_Exp) => {
  const overdueCount = filters?._or ? 1 : 0;
  const highPriority = filters?.priority ? 1 : 0;
  const typeCount = filters?.type?._in?.length ?? 0;
  const _and = filters?._and ?? [];

  const subTypes =
    _and?.[0]?._or?.find((filter) => {
      return !isEqual(filter, GENERAL_FILTER);
    })?._or?.[0]?.subType?._in ?? [];

  const generalCount = !!_and?.[0]?._or?.find((filter) => {
    return isEqual(filter, GENERAL_FILTER);
  })
    ? 1
    : 0;

  const subTypeCount = subTypes.length;

  return subTypeCount + generalCount + overdueCount + highPriority + typeCount;
};

const CalendarDisplay = ({
  label,
  date,
  onChange,
}: {
  label: string;
  date: DateTime;
  onChange: (date: Date) => void;
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef<HTMLDivElement>(null);
  const calendarSelectRef = useRef<HTMLButtonElement>(null);

  const { isExpanded } = useMenuButtonContext();

  useEffect(() => {
    if (!isExpanded) {
      setShowCalendar(false);
    }
  }, [isExpanded]);

  useOnClickOutside(calendarRef, () => {
    setShowCalendar(false);
  });

  return (
    <InputWrap
      ref={calendarRef}
      css={{
        marginBottom: 0,
      }}
    >
      <TextDisplay
        css={{
          marginBottom: '8px',
        }}
      >
        {label}
      </TextDisplay>
      <button
        type="button"
        css={{
          border: `1px solid ${Colors.grayLight}`,
          borderRadius: '4px',
          padding: '13px 13px 13px 0',
          display: 'flex',
          cursor: 'pointer',
          alignItems: 'center',
          background: 'none',
          outlineColor: ACCENT_GREEN,
        }}
        onClick={() => {
          setShowCalendar(true);
        }}
        ref={calendarSelectRef}
      >
        <PrimaryText css={{ textAlign: 'left', flex: 1, paddingLeft: '16px' }}>
          {getDisplayDate(date).date}
        </PrimaryText>
        <img src={ICONS.calendarSimple} />
      </button>
      <Popover
        hidden={!showCalendar}
        targetRef={calendarSelectRef}
        position={positionMatchWidth}
        css={{
          zIndex: 100000,
        }}
      >
        <CalendarSelector
          value={date.toJSDate()}
          onChange={(value) => {
            onChange(value);
            setShowCalendar(false);
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          css={{
            width: '350px',
            padding: '24px 16px',
            zIndex: 16,
            background: Colors.white,
            borderRadius: 16,
            boxShadow:
              '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
          }}
        />
      </Popover>
    </InputWrap>
  );
};

export const TaskFilterDropdown = ({
  value,
  onChange,
  onDateChange,
  onClear,
  dateRange,
}: {
  value?: Tasks_Bool_Exp;
  dateRange: [DateTime, DateTime];
  onDateChange: (range: [DateTime, DateTime]) => void;
  onChange: (filter: Tasks_Bool_Exp) => void;
  onClear: () => void;
  className?: string;
}) => {
  const builtFilter = value ?? {};
  const taskTypes: Task_Type_Enum[] = builtFilter?.type?._in ?? [];
  const filterCount = getFilterCount(builtFilter);

  const [fromDate, toDate] = dateRange;

  return (
    <Menu>
      {({ isExpanded }) => {
        return (
          <Fragment>
            <PillMenuButton
              css={{
                padding: '7px 12px',
              }}
              selected={isExpanded}
              data-testid="task_sort_menu_button"
            >
              <PrimaryText>
                <img
                  src={FilterIcon}
                  css={{
                    width: '14px',
                    marginRight: '4px',
                  }}
                />
                Filters{filterCount ? ` • ${filterCount}` : ''}
              </PrimaryText>
            </PillMenuButton>
            <DropdownMenuList
              data-testid="task_sort_menu_dropdown"
              css={{
                minWidth: '464px',
                maxWidth: '464px',
                minHeight: '200px',
                width: '100%',
              }}
            >
              <div
                css={{
                  padding: '32px 32px 16px 32px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottom: `1px solid ${Colors.grayLight}`,
                }}
              >
                <Title>Filters • {filterCount}</Title>
                <div>
                  <TransparentButton onClick={() => onClear()}>
                    <DullText
                      css={{
                        textDecoration: 'underline',
                        fontSize: '16px',
                        fontWeight: 500,
                      }}
                    >
                      Clear All
                    </DullText>
                  </TransparentButton>
                </div>
              </div>
              <Section
                css={{
                  borderBottom: `1px solid ${Colors.grayLight}`,
                }}
              >
                <div
                  css={{
                    marginBottom: '20px',
                  }}
                >
                  <Subtitle>Quick filters</Subtitle>
                </div>
                <ColumnWrap>
                  <HalfWidth>
                    <Checkbox
                      label={<TextDisplay>Overdue</TextDisplay>}
                      value="Overdue"
                      onChange={() => {
                        if (builtFilter._or) {
                          onChange({
                            ...builtFilter,
                            _or: undefined,
                          });
                        } else {
                          onChange({
                            ...builtFilter,
                            _or: [OVERDUE_FILTER],
                          });
                        }
                      }}
                      isChecked={!!builtFilter._or}
                    />
                  </HalfWidth>
                  <HalfWidth>
                    <Checkbox
                      label={<TextDisplay>High Priority</TextDisplay>}
                      value="High Priority"
                      onChange={() => {
                        if (builtFilter.priority) {
                          onChange({
                            ...builtFilter,
                            priority: undefined,
                          });
                        } else {
                          onChange({
                            ...builtFilter,
                            priority: {
                              _eq: 'HIGH',
                            },
                          });
                        }
                      }}
                      isChecked={builtFilter?.priority?._eq === 'HIGH'}
                    />
                  </HalfWidth>
                  <HalfWidth>
                    <Checkbox
                      label={<TextDisplay>To Do</TextDisplay>}
                      value="To Do"
                      onChange={() => {
                        if (taskTypes.includes('ACTION')) {
                          const nextTaskTypes = taskTypes.filter((type) => {
                            return type !== 'ACTION';
                          });

                          const hasTypes = nextTaskTypes.length
                            ? nextTaskTypes
                            : null;

                          if (hasTypes) {
                            onChange({
                              ...builtFilter,
                              type: {
                                _in: nextTaskTypes,
                              },
                            });
                          } else {
                            onChange({
                              ...builtFilter,
                              type: undefined,
                            });
                          }
                        } else {
                          onChange({
                            ...builtFilter,
                            type: {
                              _in: [...taskTypes, 'ACTION'],
                            },
                          });
                        }
                      }}
                      isChecked={taskTypes.includes('ACTION')}
                    />
                  </HalfWidth>
                  <HalfWidth>
                    <Checkbox
                      label={<TextDisplay>Memo</TextDisplay>}
                      value="Memo"
                      onChange={() => {
                        if (taskTypes.includes('INFORMATION')) {
                          const nextTaskTypes = taskTypes.filter((type) => {
                            return type !== 'INFORMATION';
                          });

                          const hasTypes = nextTaskTypes.length
                            ? nextTaskTypes
                            : null;

                          if (hasTypes) {
                            onChange({
                              ...builtFilter,
                              type: {
                                _in: nextTaskTypes,
                              },
                            });
                          } else {
                            onChange({
                              ...builtFilter,
                              type: {},
                            });
                          }
                        } else {
                          onChange({
                            ...builtFilter,
                            type: {
                              _in: [...taskTypes, 'INFORMATION'],
                            },
                          });
                        }
                      }}
                      isChecked={taskTypes.includes('INFORMATION')}
                    />
                  </HalfWidth>
                </ColumnWrap>
              </Section>
              <Section
                css={{
                  borderBottom: `1px solid ${Colors.grayLight}`,
                }}
              >
                <div
                  css={{
                    marginBottom: '20px',
                  }}
                >
                  <Subtitle>Task Type</Subtitle>
                </div>
                <ColumnWrap>
                  {SUB_TYPES.map(({ value, label }, index) => {
                    const _and = builtFilter?._and ?? [];

                    const subTypes =
                      _and?.[0]?._or?.find((filter) => {
                        return !isEqual(filter, GENERAL_FILTER);
                      })?._or?.[0]?.subType?._in ?? [];

                    const hasGeneral = !!_and?.[0]?._or?.find((filter) => {
                      return isEqual(filter, GENERAL_FILTER);
                    });

                    return (
                      <HalfWidth key={index}>
                        <Checkbox
                          label={<TextDisplay>{label}</TextDisplay>}
                          value={label}
                          onChange={() => {
                            let _and: Tasks_Bool_Exp | undefined = undefined;

                            if (!value) {
                              _and = buildGeneralFilter(!hasGeneral, subTypes);
                            } else {
                              if (subTypes.includes(value)) {
                                _and = buildGeneralFilter(
                                  hasGeneral,
                                  subTypes.filter((subType) => {
                                    return subType !== value;
                                  }),
                                );
                              } else {
                                _and = buildGeneralFilter(hasGeneral, [
                                  ...subTypes,
                                  value,
                                ]);
                              }
                            }

                            onChange({
                              ...builtFilter,
                              _and: _and ? [_and] : undefined,
                            });
                          }}
                          isChecked={
                            !value ? !!hasGeneral : !!subTypes.includes(value)
                          }
                        />
                      </HalfWidth>
                    );
                  })}
                </ColumnWrap>
              </Section>
              <Section>
                <div
                  css={{
                    marginBottom: '20px',
                  }}
                >
                  <Subtitle>Date Range</Subtitle>
                </div>
                <ColumnWrap>
                  <HalfWidth
                    css={{
                      paddingRight: '16px',
                    }}
                  >
                    <CalendarDisplay
                      label="From"
                      date={fromDate}
                      onChange={(value) => {
                        const from = DateTime.fromJSDate(value).startOf('day');
                        if (from > toDate) {
                          onDateChange([
                            from,
                            from.plus({ weeks: 3 }).endOf('day'),
                          ]);
                        } else {
                          onDateChange([from, toDate]);
                        }
                      }}
                    />
                  </HalfWidth>
                  <HalfWidth>
                    <CalendarDisplay
                      label="To"
                      date={toDate}
                      onChange={(value) => {
                        const to = DateTime.fromJSDate(value).endOf('day');
                        if (to < fromDate) {
                          onDateChange([
                            to.minus({ weeks: 3 }).startOf('day'),
                            to,
                          ]);
                        } else {
                          onDateChange([fromDate, to]);
                        }
                      }}
                    />
                  </HalfWidth>
                </ColumnWrap>
              </Section>
            </DropdownMenuList>
          </Fragment>
        );
      }}
    </Menu>
  );
};
