/** @jsx jsx */
import {
  useCancelKareoMutation,
  useLoadKareoLogsQuery,
} from '@bc/codegen/manager';
import { jsx } from '@emotion/core';
import { DateTime } from 'luxon';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import CalendarIcon from '../../assets/calendar-simple.svg';
import { TextButton } from '../../components/button';
import {
  CalendarControl,
  CalendarControls,
  CalendarSelector,
  Next,
  Prev,
} from '../../components/CalendarSelector';
import { Header } from '../../components/Header';
import { Content, InnerWrap, MainContent } from '../../components/layout';
import { Sidebar } from '../../components/Nav/Sidebar';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '../../components/Table';
import { LargeTitle, PrimaryText } from '../../components/text';
import { checkIfDayIsToday } from '../../helpers';
import { getDisplayDate } from '../Dashboard.helpers';
import {
  CalendarContainer,
  DayHeader,
  Filterable,
  FilterableText,
  FilterContainer,
  FormattedDay,
  Icon,
  LeftSide,
  TitleContainer,
} from '../Dashboard.styles';
import { TodayButton } from '../staff/styles';

const KareoLogs = () => {
  const [date, setDate] = useState(DateTime.local());
  const [showCalendar, setShowCalendar] = useState(false);

  const [cancelKareoAction] = useCancelKareoMutation();
  const { data, refetch } = useLoadKareoLogsQuery({
    variables: {
      where: {
        _and: [
          {
            created_at: {
              _gte: date.startOf('day').toISO(),
            },
          },
          {
            created_at: {
              _lte: date.endOf('day').toISO(),
            },
          },
        ],
      },
      order_by: [
        {
          created_at: 'desc',
        },
      ],
    },
  });

  const logs = data?.kareo_logs ?? [];

  const handleCancel = async (id: string) => {
    await cancelKareoAction({
      variables: {
        id,
      },
    });
    refetch();
  };
  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap>
          <Sidebar />
          <MainContent>
            <TitleContainer condense={false}>
              <LeftSide>
                <LargeTitle css={{ marginRight: 8 }}>Kareo Logs</LargeTitle>
              </LeftSide>
            </TitleContainer>
            <DayHeader css={{ width: '100%', justifyContent: 'flex-end' }}>
              <div
                css={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <TodayButton
                  disabled={checkIfDayIsToday(date)}
                  onClick={() => {
                    setDate(DateTime.local());
                  }}
                >
                  Today
                </TodayButton>
                <CalendarControls>
                  <CalendarControl
                    onClick={() => setDate(date.minus({ day: 1 }))}
                  >
                    <Prev />
                  </CalendarControl>
                  <CalendarControl
                    onClick={() => setDate(date.plus({ day: 1 }))}
                  >
                    <Next />
                  </CalendarControl>
                </CalendarControls>
                <FormattedDay>{getDisplayDate(date).shortDate}</FormattedDay>
                <Filterable
                  onMouseEnter={() => setShowCalendar(true)}
                  onMouseLeave={() => setShowCalendar(false)}
                  onClick={() => setShowCalendar(true)}
                  tabIndex={0}
                  css={{ marginLeft: 8, position: 'relative' }}
                  type="button"
                  highlight={!!showCalendar}
                >
                  <Icon src={CalendarIcon} />
                  <FilterableText>Calendar</FilterableText>
                  {showCalendar && (
                    <CalendarSelector
                      value={date.toJSDate()}
                      onChange={(value) => {
                        setDate(DateTime.fromJSDate(value));
                      }}
                      css={[FilterContainer, CalendarContainer]}
                    />
                  )}
                </Filterable>
              </div>
            </DayHeader>

            <Table condense={false}>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Kareo Request</TableHeadCell>
                  <TableHeadCell>Status</TableHeadCell>
                  <TableHeadCell>Patient</TableHeadCell>
                  <TableHeadCell>Created At</TableHeadCell>
                  <TableHeadCell>Staff</TableHeadCell>
                  <TableHeadCell></TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((kareoLog) => (
                  <TableRow key={kareoLog.id} style={{ cursor: 'pointer' }}>
                    <TableCell data-label="Kareo Action">
                      {kareoLog.request_action}
                    </TableCell>
                    <TableCell data-label="Status">{kareoLog.status}</TableCell>
                    <TableCell data-label="Staff">
                      <Link to={`/patient/${kareoLog.patient_id}/profile`}>
                        <PrimaryText>Patient</PrimaryText>
                      </Link>
                    </TableCell>
                    <TableCell data-label="Created At">
                      {DateTime.fromISO(kareoLog.created_at).toLocaleString(
                        DateTime.DATETIME_SHORT,
                      )}
                    </TableCell>
                    <TableCell style={{ minWidth: 120 }} data-label="Staff">
                      {kareoLog.staff}
                    </TableCell>
                    <TableCell>
                      {kareoLog.status === 'RUNNING' && (
                        <TextButton
                          onClick={() => {
                            handleCancel(kareoLog.id);
                          }}
                        >
                          Cancel
                        </TextButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </MainContent>
        </InnerWrap>
      </Content>
    </Fragment>
  );
};

export default KareoLogs;
