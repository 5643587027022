/** @jsx jsx */
import { useVerifyStaffMemberMutation } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React, { Fragment, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Button } from '../components/button';
import { Header } from '../components/Header';
import { Content } from '../components/layout';
import { Loader } from '../components/Loader';
import { PrimaryText } from '../components/text';
import { useAuth0 } from '../react-auth0';
import { FONT_PRIMARY, GREY } from '../styles';

const Title = styled.h1`
  color: ${GREY};
  ${FONT_PRIMARY};
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  text-align: center;
`;

const Subtitle = styled.h3`
  color: ${GREY};
  ${FONT_PRIMARY};
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 16px;
`;

interface HistoryState {
  needsRole?: boolean;
}

const Login: React.FC<RouteComponentProps<{}, {}, HistoryState>> = ({
  history,
}) => {
  const { loginWithRedirect, accessToken } = useAuth0();
  const needsRole = history.location?.state?.needsRole;

  const [verifyStaff, { loading, data }] = useVerifyStaffMemberMutation({
    context: { anonymous: true },
    variables: {
      token: accessToken,
    },
  });

  useEffect(() => {
    const run = async () => {
      if (accessToken && needsRole) {
        const { data } = await verifyStaff({
          variables: {
            token: accessToken,
          },
        });

        if (data?.VerifyStaffMember?.success) {
          loginWithRedirect({
            redirectUi: `${window.location.origin}/auth/verify`,
          });
        }
      }
    };
    run();
  }, [accessToken, needsRole]);

  return (
    <Fragment>
      <Header />
      <Content
        css={{
          marginTop: '40px',
          justifyContent: 'center',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '400px',
            width: '100%',
          }}
        >
          <Title>Welcome Back</Title>
          <Subtitle>Login to your account with your Brave Care email</Subtitle>
          <Button
            type="button"
            onClick={() =>
              loginWithRedirect({
                redirectUi: `${window.location.origin}/auth/verify`,
              })
            }
          >
            Login
          </Button>
          {loading && (
            <div css={{ marginTop: '16px' }}>
              <Loader />
            </div>
          )}

          {needsRole && !loading && !data?.VerifyStaffMember?.success && (
            <PrimaryText css={{ textAlign: 'center', marginTop: '30px' }}>
              You are not tagged as an assistant. Please contact an
              administrator
            </PrimaryText>
          )}
        </div>
      </Content>
    </Fragment>
  );
};

export default Login;
