/** @jsx jsx */
import {
  GlobalNoteFragment,
  useDeleteGlobalNoteMutation,
} from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { DialogOverlay } from '@reach/dialog';
import * as Sentry from '@sentry/react';
import React from 'react';
import { Button, CloseButton, OutlineButton } from '../button';
import { SmallModal } from '../layout';
import { ModalText, ModalTitle } from '../text';

const ModalButtons = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const ModalClose = styled(CloseButton)`
  border: none;

  &:focus {
    background: white;
    border: none;
  }

  &:hover {
    background: white;
    border: none;
  }
`;

export const ArchiveFlagModal: React.FC<{
  isOpen: boolean;
  note?: GlobalNoteFragment;
  onCancel: () => void;
  kind?: 'flag' | 'note';
}> = ({ isOpen, note, onCancel, kind = 'flag' }) => {
  const [deleteNote] = useDeleteGlobalNoteMutation();

  if (!note) return null;

  const handleArchive = async () => {
    if (!note) return;

    try {
      deleteNote({
        variables: {
          id: note.id,
        },
      });
      onCancel();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const staffNames = note.staff ? `${note.staff.firstName}’s` : '';

  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={onCancel}
      style={{
        zIndex: 100,
      }}
    >
      <SmallModal aria-label="Archive Flag">
        <ModalClose
          onClick={onCancel}
          css={{ position: 'absolute', top: 8, right: 16 }}
        />
        <ModalTitle alignLeft>
          Archive {staffNames} {kind}?
        </ModalTitle>
        <ModalText alignLeft>
          This will hide the {kind === 'note' ? 'internal note' : kind}. You
          will be able to view the {kind} under{' '}
          <strong css={{ fontWeight: 'bold' }}>View archive</strong>.
        </ModalText>
        <ModalButtons>
          <OutlineButton onClick={onCancel} css={{ padding: '16px 40px' }}>
            Cancel
          </OutlineButton>
          <Button
            tabIndex={0}
            type="button"
            onClick={() => handleArchive()}
            css={{ marginLeft: 16, padding: '16px 40px' }}
          >
            Yes
          </Button>
        </ModalButtons>
      </SmallModal>
    </DialogOverlay>
  );
};
