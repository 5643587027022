/** @jsx jsx */
import { useAuditLogQuery } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import { DialogOverlay } from '@reach/dialog';
import React, { Fragment, useState } from 'react';
import { OutlineButton } from '../button';
import { Entry } from './Entry';
import {
  ModalClose,
  ModalContent,
  ModalTitle,
  SmallModal,
  TimelineSegment,
} from './styles';

const AccountAuditLogModal = ({
  accountId,
  isOpen,
  onClose,
}: {
  accountId: string;
  isOpen: boolean;
  onClose: (e?: React.SyntheticEvent<Element, Event>) => void;
}) => {
  const { data } = useAuditLogQuery({
    variables: {
      where: {
        table: {
          _in: ['accounts'],
        },
        _or: [
          {
            to: {
              _contains: {
                id: accountId,
              },
            },
          },
          {
            from: {
              _contains: {
                id: accountId,
              },
            },
          },
        ],
      },
      order_by: [{ created_at: 'desc' }],
    },
  });

  const audit = data?.audit_logs ?? [];

  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={onClose}
      style={{
        zIndex: 100,
      }}
    >
      <SmallModal aria-label="Activity Log">
        <ModalClose
          onClick={onClose}
          css={{ position: 'absolute', top: 24, right: 24 }}
        />
        <ModalTitle>Activity Log</ModalTitle>
        <ModalContent css={{ paddingBottom: 24 }}>
          {audit?.map((audit, index) => {
            return (
              <Fragment key={audit.id}>
                {index > 0 && <TimelineSegment />}
                <Entry audit={audit} />
              </Fragment>
            );
          })}
        </ModalContent>
      </SmallModal>
    </DialogOverlay>
  );
};

export const AccountAuditLog: React.FC<{
  accountId: string;
  size?: number;
}> = ({ accountId, size = 32 }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <OutlineButton
        css={{
          height: size,
          padding: size < 32 ? 0 : '8px 6px',
          width: size,

          img: {
            height: size < 32 ? 8 : 'auto',
          },
        }}
        icon="auditLog"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
      />
      <AccountAuditLogModal
        isOpen={isOpen}
        onClose={(e) => {
          e?.preventDefault();
          setIsOpen(false);
        }}
        accountId={accountId}
      />
    </Fragment>
  );
};
