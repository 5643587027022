/** @jsx jsx */
import {
  Appointment_Status_Enum,
  GlobalNoteFragment,
  RoomFragment,
  StaffFragment,
  Visit_Classifications_Enum,
  Visit_Types_Enum,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import useComponentSize from '@rehooks/component-size';
import { DateTime } from 'luxon';
import React, { useRef } from 'react';
import { ICONS } from '../../assets';
import CanceledIcon from '../../assets/canceled.svg';
import CheckedInIcon from '../../assets/checked_in.svg';
import { formatDateOfBirth, getDateOfBirth } from '../../helpers';
import { IF_MOBILE } from '../../styles';
import { StatusBadge } from '../AppointmentStatus';
import Exclamation from '../GlobalNotes/exclamation';
import { PrimaryText } from '../text';
import {
  ApptTime,
  ApptTimeInline,
  Box,
  Name,
  NameWrapper,
  Reason,
  Wrap,
} from './AppointmentDisplay.styles';

const isBeforeNow = (date: DateTime) => DateTime.local() > date;

interface AppointmentDisplayProps {
  className?: string;
  dateOfBirth?: string;
  endTime: DateTime;
  firstName?: string;
  globalNotes?: Partial<GlobalNoteFragment>[];
  goesByName?: string;
  kareoLinked: boolean;
  lastName?: string;
  needsExtraPrecaution?: boolean;
  onClick: () => void;
  pronunciation?: string;
  reason?: string;
  room?: RoomFragment;
  staff?: Partial<StaffFragment>[];
  startTime: DateTime;
  status: Appointment_Status_Enum;
  style?: React.CSSProperties;
  visitClassification?: Visit_Classifications_Enum;
  visitType: Visit_Types_Enum;
}

const StatusMap = {
  CHECKED_IN: {
    icon: CheckedInIcon,
    text: 'Checked In',
  },
  CANCELED: {
    icon: CanceledIcon,
    text: 'Cancelled',
  },
  COMPLETED: {
    icon: null,
    text: 'Completed',
  },
  NO_SHOW: {
    icon: null,
    text: 'No Show',
  },
  READY_FOR_PROVIDER: {
    icon: null,
    text: 'Ready for Provider',
  },
  PROVIDER_FINISHED: {
    icon: null,
    text: 'Provider Finished',
  },
  WITH_PROVIDER: {
    icon: null,
    text: 'With Provider',
  },
};

type ColorMapTypes = Visit_Types_Enum & Visit_Classifications_Enum;

const COLOR_MAP: Record<Partial<ColorMapTypes>, string> = {
  PRIMARY_CARE: Colors.blue,
  TELEMEDICINE: Colors.violet,
  MEET_AND_GREET: Colors.teal,
  URGENT_CARE: Colors.butterscotch,
  VACCINATION: Colors.green,
  COVID_19_TEST: Colors.bubblegum,
};

const StatusDisplay: React.FC<{
  status: Appointment_Status_Enum;
  width?: number;
}> = ({ status, width = 450 }) => {
  if (
    status !== 'CHECKED_IN' &&
    status !== 'CANCELED' &&
    status !== 'COMPLETED' &&
    status !== 'PROVIDER_FINISHED' &&
    status !== 'READY_FOR_PROVIDER' &&
    status !== 'WITH_PROVIDER' &&
    status !== 'NO_SHOW'
  ) {
    return null;
  }

  const { icon, text } = StatusMap[status];

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {icon && <img src={icon} alt={text} />}
      {(!icon || width > 350) && (
        <PrimaryText
          css={{
            marginLeft: '5px',
            fontSize: '12px',
            lineHeight: '14px',
            [IF_MOBILE]: {
              fontSize: '14px',
              lineHeight: '16px',
            },
            color: Colors.gray,
          }}
        >
          {text}
        </PrimaryText>
      )}
    </div>
  );
};

export const StaffImage = styled.img({
  border: `1px solid ${Colors.white}`,
  borderRadius: '16px',
  objectFit: 'contain',
  position: 'absolute',
  top: '50%',
  transform: `translateY(-50%)`,
});

StaffImage.defaultProps = {
  src: 'https://bravecare.imgix.net/staff/empty.png',
};

const AppointmentDisplay = ({
  className,
  dateOfBirth,
  endTime,
  firstName,
  globalNotes,
  goesByName,
  kareoLinked,
  lastName,
  needsExtraPrecaution,
  onClick,
  pronunciation,
  reason,
  room,
  staff,
  startTime,
  status,
  style,
  visitClassification,
  visitType = 'URGENT_CARE',
  height,
}: AppointmentDisplayProps & { height: number }) => {
  const ref = useRef(null);
  const { width } = useComponentSize(ref);
  const isInThePast = isBeforeNow(endTime);
  const displayGrey = status === 'CANCELED' || status === 'COMPLETED';

  let leftBorderColor =
    //@ts-ignore
    COLOR_MAP[visitClassification] ?? COLOR_MAP[visitType] ?? Colors.teal;
  let leftBorderWidth = 4;

  if (status === 'CHECKED_IN') {
    leftBorderColor = Colors.bubblegum;
    leftBorderWidth = 8;
  }

  if (isInThePast) {
    leftBorderColor = Colors.grayLight;
  }

  const dob =
    dateOfBirth &&
    `${formatDateOfBirth(dateOfBirth)} (${getDateOfBirth({
      date: dateOfBirth,
    })})`;

  const assignedStaff = [...(staff ?? [])].reverse();
  const condensedName = `${firstName} ${lastName?.slice(0, 1)}.`;
  const displayPronunciation = pronunciation && ` - ${pronunciation}`;
  const fullName = `${firstName} ${lastName} ${
    goesByName ? `(${goesByName})${displayPronunciation}` : displayPronunciation
  }`;

  const condensed = height <= 35;

  return (
    <Wrap
      className={className}
      style={style}
      onClick={onClick}
      data-testid="date-piece"
      ref={ref}
    >
      <Box
        css={{ backgroundColor: displayGrey ? Colors.grayLightest : '#FFF' }}
        condense={condensed}
      >
        <div
          css={{
            backgroundColor: leftBorderColor,
            borderRadius: '100px',
            marginLeft: leftBorderWidth > 4 ? -2 : 0,
            marginRight: 8,
            transition: 'all 0.5s ease-out',
            width: `${leftBorderWidth}px`,
          }}
        />
        <div
          css={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <NameWrapper>
            <Name>
              {width < 120 ? firstName : width < 180 ? condensedName : fullName}
              {condensed && (
                <ApptTimeInline>
                  {needsExtraPrecaution && '\uD83E\uDDA0'}
                  {startTime.toFormat('h:mm a')}
                </ApptTimeInline>
              )}
            </Name>
            {kareoLinked && (
              <img
                alt="kareoLinked"
                src={ICONS.kareoLinked}
                style={{ marginLeft: '8px' }}
                title="Appointment created in Kareo"
              />
            )}
            {globalNotes && !!globalNotes.length && (
              <Exclamation width={16} height={16} css={{ marginLeft: 8 }} />
            )}
          </NameWrapper>
          {!condensed && (
            <ApptTime>
              {needsExtraPrecaution && '\uD83E\uDDA0'}
              {startTime.toFormat('h:mm a')}
              {width > 300 && <span> - {dob}</span>}
            </ApptTime>
          )}
          <Reason>{reason}</Reason>
          {globalNotes && !!globalNotes.length && (
            <Reason>
              <PrimaryText style={{ fontWeight: 'bold' }}>FLAGS:</PrimaryText>
              {globalNotes?.map(({ id, note }) => (
                <Reason key={id}>{note}</Reason>
              ))}
            </Reason>
          )}
        </div>

        {width > 160 && (
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div css={{ display: 'flex' }}>
              {!!room ? (
                <StatusBadge
                  css={{ marginRight: 0, padding: '3px 12px' }}
                  // @ts-ignore
                  status={room.status ?? status}
                >
                  {width < 300 ? room.name.replace('Room ', '') : room.name}
                </StatusBadge>
              ) : (
                <StatusDisplay status={status} width={width} />
              )}
              {width > 250 && !!assignedStaff.length && (
                <div
                  css={{
                    position: 'relative',
                    width: `${(assignedStaff?.length ?? 0) * 29}px`,
                    marginLeft: '8px',
                  }}
                >
                  {assignedStaff.map((staff, i) => {
                    return (
                      <StaffImage
                        key={staff.id}
                        src={
                          staff.avatarUrl ||
                          'https://bravecare.imgix.net/staff/empty.png'
                        }
                        css={{
                          height: condensed ? '24px' : '32px',
                          width: condensed ? '24px' : '32px',
                          left: `${i * 28}px`,
                          zIndex: assignedStaff.length - i,
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </Box>
    </Wrap>
  );
};

export default AppointmentDisplay;
