import {
  useCreateKareoPatientMutation,
  useUpdatePatientByPkMutation,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import React from 'react';
import { formatDateOfBirth, getDateOfBirth } from '../../helpers';
import { OutlineButton, TextButton } from '..//button';
import { PrimaryText, SmallPrimaryText, SmallTitle } from '../text';

interface KareoPatient {
  name: string;
  dateOfBirth: string;
  genderId: string;
  kareoPatientId: string;
  guid: string;
}

const GENDER_DISPLAYS: Record<string, string> = {
  '1': 'M',
  '2': 'F',
  '3': 'U',
};

const KareoPatientList = ({
  onAttach,
  patients,
  patientId,
}: {
  onAttach: () => void;
  patients: KareoPatient[];
  patientId: string;
}) => {
  const [updatePatient] = useUpdatePatientByPkMutation();
  const [createKareoPatient, { loading: createLoading }] =
    useCreateKareoPatientMutation();

  return (
    <div>
      {patients.length !== 0 && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '18px',
          }}
        >
          <SmallTitle>Select Patient in Kareo</SmallTitle>
        </div>
      )}
      {patients.map(
        ({ name, dateOfBirth, genderId, kareoPatientId, guid }, index) => {
          return (
            <div
              style={{
                borderTop:
                  index === 0 ? `1px solid ${Colors.grayLighter}` : undefined,
                borderBottom: `1px solid ${Colors.grayLighter}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '16px 0',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <PrimaryText
                  style={{ fontWeight: 'bold', marginBottom: '4px' }}
                >
                  {name}
                </PrimaryText>
                <SmallPrimaryText>
                  {getDateOfBirth({ date: dateOfBirth })} •{' '}
                  {formatDateOfBirth(dateOfBirth)} • {GENDER_DISPLAYS[genderId]}
                </SmallPrimaryText>
              </div>
              <OutlineButton
                onClick={async () => {
                  await updatePatient({
                    variables: {
                      id: patientId,
                      _set: {
                        kareo_guid: guid,
                        kareo_patient_id: String(kareoPatientId),
                      },
                    },
                  });
                  onAttach();
                }}
              >
                Link Patient
              </OutlineButton>
            </div>
          );
        },
      )}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '28px',
        }}
      >
        <PrimaryText>Don't see a matching patient?</PrimaryText>
        <TextButton
          disabled={createLoading}
          onClick={async () => {
            if (createLoading) {
              return;
            }
            const { data: createPatient } = await createKareoPatient({
              variables: {
                patientId: patientId,
              },
            });
            if (!!createPatient?.CreateKareoPatient?.success) {
              onAttach();
            }
          }}
        >
          Create a new patient in Kareo
        </TextButton>
      </div>
    </div>
  );
};

export default KareoPatientList;
