import {
  ClinicFragment,
  Clinics_Bool_Exp,
  useLoadAllClinicsQuery,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import React, { useMemo } from 'react';
import CaretDropdownIcon from '../../assets/caret.svg';
import { hasRole } from '../../auth';
import { useAuth0 } from '../../react-auth0';
import { IF_TABLET } from '../../styles';
import { PrimaryText } from '../text';

interface Props {
  clinicId?: string;
  onSelect: (clinic?: ClinicFragment) => void;
}

export const ClinicDropdown: React.FC<Props> = ({ clinicId, onSelect }) => {
  const { accessToken } = useAuth0();
  const isManager = hasRole('manager', accessToken);

  const context = useMemo(() => {
    return { role: isManager ? 'manager' : 'assistant' };
  }, [isManager]);

  const where = useMemo<Clinics_Bool_Exp>(() => {
    if (isManager) {
      return {};
    } else {
      return {
        status: {
          _nin: ['HIDDEN', 'PERMANENTLY_CLOSED'],
        },
      };
    }
  }, [isManager]);

  const { data } = useLoadAllClinicsQuery({
    context,
    variables: {
      where: where,
    },
  });

  const clinics = data?.clinics ?? [];
  const selectedClinic = clinics.find((clinic) => {
    return clinic.id === clinicId || clinic.clinicCode === clinicId;
  });

  return (
    <Container>
      <Menu>
        <DropDownTrigger>
          <ShowText>Show:{NBSP}</ShowText>
          <TriggerText>{selectedClinic?.name ?? 'Please Select'}</TriggerText>
          <Caret src={CaretDropdownIcon} />
        </DropDownTrigger>
        <DropDown>
          {clinics.map((clinic) => {
            return (
              <DropDownItem
                key={`clinic-${clinic.id}`}
                onSelect={() => {
                  onSelect(clinic);
                }}
              >
                <ItemText>{clinic.name}</ItemText>
              </DropDownItem>
            );
          })}
        </DropDown>
      </Menu>
    </Container>
  );
};

const NBSP = '\u00A0';

const commonFontRules = css`
  letter-spacing: 0.1px;
  font-size: 14px;
  ${IF_TABLET} {
    font-size: 16px;
  }
`;

const ShowText = styled(PrimaryText)`
  color: ${Colors.gray};
  ${commonFontRules};
`;

const Caret = styled.img`
  height: 24px;
  margin-left: auto;
  width: 24px;
  z-index: 5;
`;

const Container = styled.div`
  position: relative;
`;

const DropDownTrigger = styled(MenuButton)`
  align-items: center;
  background-color: ${Colors.white};
  border: none;
  cursor: pointer;
  display: flex;
  padding: 8px;
  &:focus {
    box-shadow: inset 0 0 0 2px ${Colors.grayLighter},
      inset 0 0 0 4px ${Colors.white};
    outline: none;
    border-radius: 8px;
  }
`;

const TriggerText = styled(PrimaryText)`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-right: 4px;
`;

const DropDown = styled(MenuList)`
  background: ${Colors.white};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  border: none;
  min-width: 250px;
  overflow: hidden;
  padding: 8px 0;
  position: relative;
  z-index: 16;
`;

const DropDownItem = styled(MenuItem)<{ selected?: boolean }>(
  (props) => `
  align-items: center;
  background-color: ${props.selected ? '#F3F5F5' : Colors.white};
  display: flex;
  padding: 8px 24px;

  &:hover {
    background-color: #F8FAFA;
  }
`,
);

const ItemText = styled(PrimaryText)`
  color: ${Colors.darkHarbor};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;
