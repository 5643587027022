import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { Label } from '../form';

export const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e8e8;
  padding: 24px;
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const ProductRow = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: space-between;
`;

export const CustomInputProductRow = styled(ProductRow)`
  height: 60px;
`;

export const CustomLabel = styled(Label)`
  margin-bottom: 0;
  margin-right: 10px;
`;

export const ErrorMessageWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  background-color: #d93900;
  padding: 18px;
`;

export const SuccessMessageWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  background-color: ${Colors.teal};
  padding: 18px;
  color: #fff;
`;
