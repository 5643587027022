import styled from '@emotion/styled';
import React, { HTMLAttributes } from 'react';
import { PrimaryText } from '../text';
import { IF_TABLET } from '../../styles';

export const DefinitionList = styled.dl<{ highlight?: 'odd' | 'even' }>(
  ({ highlight = 'odd' }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,

    [`> div:nth-of-type(${highlight})`]: {
      backgroundColor: 'rgba(239, 241, 241, 0.74)',
    },
  })
);

export const Row = styled.div({
  alignItems: 'center',
  backgroundColor: 'white',
  display: 'flex',
  padding: '12px 24px',
});

export const Term = styled(PrimaryText)({
  display: 'flex',
  flexWrap: 'wrap',
  fontSize: 14,
  fontWeight: 700,
  lineHeight: '18px',
  marginRight: 16,
  width: '40%',
  [IF_TABLET]: {
    width: 200,
  },
}).withComponent('dt');

export const Definition = styled(PrimaryText)({
  display: 'flex',
  flex: 1,
  fontSize: 16,
  lineHeight: '22px',
}).withComponent('dd');

export const DefinitionListItem: React.FC<
  {
    term: string;
  } & HTMLAttributes<HTMLDivElement>
> = ({ term, children, ...props }) => {
  return (
    <Row {...props}>
      <Term>{term}</Term>
      <Definition data-testid={term}>{children}</Definition>
    </Row>
  );
};
