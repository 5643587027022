/** @jsx jsx */
import { AccountPartsFragment } from '@bc/codegen/medical';
import {
  getPreferredPronoun,
  getRelationshipDisplay,
  getSexDisplay,
} from '@bc/shared';
import { jsx } from '@emotion/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PatientAvatarDisplay } from '../../components/AppointmentDetails/AvatarDisplay';
import CopyToClip from '../../components/copy';
import { SectionContent } from '../../components/layout';
import { DefinitionList, DefinitionListItem } from '../../components/List';
import { SectionTitle } from '../../components/text';
import { getDateOfBirth } from '../../helpers';
import {
  Age,
  Header,
  Name,
  NameWrapper,
  TextWrapper,
  Wrapper,
} from './Patients.styles';

const Patients: React.FC<{
  data: AccountPartsFragment;
}> = ({ data }) => {
  const history = useHistory();
  const { account_patients } = data;

  return (
    <SectionContent>
      <SectionTitle>Patients</SectionTitle>
      {account_patients.map((patient, index) => {
        const {
          patient: {
            additionalInfo,
            dateOfBirth,
            firstName,
            genderAtBirth,
            goesByName,
            id,
            lastName,
            preferredPronoun,
          },
        } = patient;
        return (
          <Wrapper key={patient.id} hideTopBorder={index === 0}>
            <Header
              onClick={() => {
                history.push(`/patient/${id}/profile`);
              }}
            >
              <PatientAvatarDisplay
                patientId={id}
                style={{
                  height: '48px',
                  width: '48px',
                }}
              />
              <TextWrapper>
                <NameWrapper>
                  <Name>
                    {firstName} {lastName} {goesByName ? `(${goesByName})` : ''}
                  </Name>
                </NameWrapper>
                <Age>
                  {getDateOfBirth({
                    date: dateOfBirth,
                    addOld: true,
                  })}
                </Age>
              </TextWrapper>
            </Header>
            <DefinitionList css={{ marginTop: 16 }}>
              <DefinitionListItem term="First name on file with insurance">
                <CopyToClip text={firstName}>{firstName}</CopyToClip>
              </DefinitionListItem>
              <DefinitionListItem term="Last name on file with insurance">
                <CopyToClip text={lastName}>{lastName}</CopyToClip>
              </DefinitionListItem>
              <DefinitionListItem term="Name patient goes by">
                <CopyToClip text={goesByName || firstName}>
                  {goesByName || firstName}
                </CopyToClip>
              </DefinitionListItem>
              <DefinitionListItem term="Birth date">
                <CopyToClip text={dateOfBirth}>{dateOfBirth}</CopyToClip>
              </DefinitionListItem>
              <DefinitionListItem term="Sex assigned at birth">
                <CopyToClip
                  text={getSexDisplay(genderAtBirth || '') || 'Did not provide'}
                >
                  {getSexDisplay(genderAtBirth || '') || 'Did not provide'}
                </CopyToClip>
              </DefinitionListItem>
              <DefinitionListItem term="Pronouns">
                <CopyToClip
                  text={
                    getPreferredPronoun(preferredPronoun || '') ||
                    'Did not provide'
                  }
                >
                  {getPreferredPronoun(preferredPronoun || '') ||
                    'Did not provide'}
                </CopyToClip>
              </DefinitionListItem>
              <DefinitionListItem
                term={`${data.goesByName || data.firstName}’s relationship to ${
                  goesByName || firstName
                }`}
              >
                <CopyToClip
                  text={
                    getRelationshipDisplay(patient.relationship || '') ?? ''
                  }
                >
                  {getRelationshipDisplay(patient.relationship || '') ?? ''}
                </CopyToClip>
              </DefinitionListItem>
              <DefinitionListItem term="Additional info">
                <CopyToClip text={additionalInfo ?? ''}>
                  {additionalInfo || '-'}
                </CopyToClip>
              </DefinitionListItem>
            </DefinitionList>
          </Wrapper>
        );
      })}
    </SectionContent>
  );
};

export default Patients;
