export enum QuestionType {
  text = "TEXT",
  number = "NUMBER",
  choice = "CHOICE",
  dob = "DOB",
  degrees = "DEGREES",
  duration = "DURATION",
  info = "INFO"
}

export enum GranularityType {
  minutes = "MINUTES",
  hours = "HOURS",
  days = "DAYS",
  months = "MONTHS",
  years = "YEARS"
}

export enum ResultOperator {
  lt = "LT",
  lte = "LTE",
  eq = "EQ",
  gt = "GT",
  gte = "GTE",
  is = "IS",
  not = "NOT"
}

export enum QuestionConditionJoin {
  and = "AND",
  or = "OR"
}

export interface QuestionCondition {
  variableId: string;
  operator: ResultOperator;
  value: number | string;
  join?: QuestionConditionJoin;
}

export interface QuestionAction {
  destinationId: string;
  conditions: QuestionCondition[];
}

export interface QuestionInfoLink {
  url: string;
  text: string;
}

export enum QuestionExitType {
  er = "ER",
  urgentCare = "URGENT_CARE",
  homeCare = "HOME_CARE",
  call911 = "CALL_911"
}

export interface Question {
  id: string;
  type: QuestionType;
  text: string;
  variable?: string;
  info?: string;
  summaryName?: string;
  link?: QuestionInfoLink;
  granularity?: string;
  choices?: string[];
  actions?: QuestionAction[];
  exit?: QuestionExitType;
  image?: string;
}

export type TAnswerValue = string | number | undefined | boolean;

export interface TAnswers {
  [key: string]: TAnswerValue;
}
