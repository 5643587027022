/** @jsx jsx */
import {
  AccountPartsFragment,
  useGetAccountChargesQuery,
  useGetPaymentMethodsByAccountQuery,
} from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import React from 'react';
import { Charge } from '../../components/Billing';
import { ContentRow, SectionContent } from '../../components/layout';
import { PrimaryText, SectionTitle } from '../../components/text';

const Payment: React.FC<{
  data: AccountPartsFragment;
}> = ({ data: account }) => {
  const { data: payment } = useGetPaymentMethodsByAccountQuery({
    fetchPolicy: 'no-cache',
    variables: {
      accountId: account.id,
    },
    skip: !account.stripeCustomerId,
  });

  const { data: chargesData } = useGetAccountChargesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        accountId: {
          _eq: account.id,
        },
      },
    },
    skip: !account.id,
  });

  const charges = chargesData?.charges ?? [];

  const getPatientFirstName = (id: string) => {
    const patient = account.account_patients.filter(
      (patient) => patient.patient.id === id,
    )[0].patient;
    return patient.goesByName || patient.firstName;
  };

  return (
    <SectionContent>
      <SectionTitle>Payment info</SectionTitle>
      {payment?.StripePaymentMethods?.length ? (
        <ContentRow>
          {payment.StripePaymentMethods.map((card) => (
            <PrimaryText key={card?.id}>
              {card?.brand} — {card?.last4}
            </PrimaryText>
          ))}
        </ContentRow>
      ) : (
        <ContentRow>
          <PrimaryText>No payment information on file</PrimaryText>
        </ContentRow>
      )}
      <SectionTitle>Charges</SectionTitle>
      {charges?.length > 0 ? (
        charges.map((charge) => {
          const patientName = getPatientFirstName(charge.patientId!);
          return (
            <Charge charge={charge} key={charge.id} patientName={patientName} />
          );
        })
      ) : (
        <ContentRow>
          <PrimaryText>No charges</PrimaryText>
        </ContentRow>
      )}
    </SectionContent>
  );
};

export default Payment;
