import parser from 'parse-address';
import React, { Fragment } from 'react';
import { Location } from '../../types';
import CopyClip from '../copy';
import { DefinitionListItem } from '../List';

export const AddressSection: React.FC<{
  location?: Location | null;
  phoneNumber?: string | null | undefined;
  placeType?: 'Pharmacy' | 'Clinic';
}> = ({ location = {}, phoneNumber, placeType = '' }) => {
  const parsedLocation = parser.parseLocation(
    (location && location.formatted_address) || ''
  );

  const street =
    parsedLocation &&
    `${parsedLocation.number || ''} ${parsedLocation.prefix || ''} ${
      parsedLocation.street || ''
    } ${parsedLocation.type || ''}`;
  const city = parsedLocation?.city;
  const zip = parsedLocation?.zip;
  const state = parsedLocation?.state;

  return (
    <Fragment>
      <DefinitionListItem term="Full address">
        <CopyClip text={location?.formatted_address ?? ''}>
          {location?.formatted_address || '-'}
        </CopyClip>
      </DefinitionListItem>
      {location?.name && (
        <DefinitionListItem term={placeType ? `${placeType} Name` : 'Name'}>
          <CopyClip text={location?.name ?? ''}>
            {location?.name || '-'}
          </CopyClip>
        </DefinitionListItem>
      )}
      {phoneNumber && (
        <DefinitionListItem term="Phone number">
          <CopyClip text={phoneNumber ?? ''}>{phoneNumber || '-'}</CopyClip>
        </DefinitionListItem>
      )}
      <DefinitionListItem term="Street address">
        <CopyClip text={street ?? ''}>{street || '-'}</CopyClip>
      </DefinitionListItem>
      <DefinitionListItem term="Apt, suite. (optional)">
        <CopyClip text={location?.address2 ?? ''}>
          {location?.address2 || '-'}
        </CopyClip>
      </DefinitionListItem>
      <DefinitionListItem term="City">
        <CopyClip text={city ?? ''}>{city || '-'}</CopyClip>
      </DefinitionListItem>
      <DefinitionListItem term="State">
        <CopyClip text={state ?? ''}>{state || '-'}</CopyClip>
      </DefinitionListItem>
      <DefinitionListItem term="Zip">
        <CopyClip text={zip ?? ''}>{zip || '-'}</CopyClip>
      </DefinitionListItem>
    </Fragment>
  );
};
