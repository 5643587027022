/** @jsx jsx */
import {
  AccountFragment,
  PatientFragment,
  useSearchAccountsAndPatientsQuery,
} from '@bc/codegen/medical';
import { getRelationshipDisplay } from '@bc/shared';
import { jsx } from '@emotion/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { Fragment } from 'react';
import { useDebounce } from 'use-debounce';
import { formatFullName, formatPhoneNumber } from '../../helpers';
import { displayDOB } from '../../pages/search/UserSearch';
import {
  AccountAvatarDisplay,
  PatientAvatarDisplay,
} from '../AppointmentDetails/AvatarDisplay';
import {
  CenteredText,
  NormalText,
  PersonText,
  PersonTextWrapper,
  PersonWrapper,
  SecondaryText,
  SelectButton,
  Title,
  Unselect,
  Wrapper,
} from './SearchForAccountPatient.styles';

// output: "1234567890"
export const formatSearchForAccountQuery = (number: string) => {
  try {
    return (
      parsePhoneNumberFromString(number, 'US')?.nationalNumber.toString() ??
      number
    );
  } catch (e) {
    return number;
  }
};

interface Props {
  onLinkAccount: ({
    account,
    relationship,
    isMissingName,
  }: {
    account?: AccountFragment;
    relationship?: string;
    isMissingName?: boolean;
  }) => void;
  onLinkPatient?: ({
    patient,
    relationship,
  }: {
    patient?: PatientFragment;
    relationship?: string;
  }) => void;
  phoneNumber?: boolean;
  query: string;
  selectedAccountId?: string;
  selectedPatientId?: string;
}

export const SearchForAccount: React.FC<Props> = ({
  onLinkAccount,
  onLinkPatient,
  query,
  selectedAccountId,
  selectedPatientId,
}) => {
  let queryText = formatSearchForAccountQuery(query);
  const [search] = useDebounce(queryText, 300);
  const shouldSkipSearch = !search || search.length < 3;

  const { data } = useSearchAccountsAndPatientsQuery({
    fetchPolicy: 'no-cache',
    skip: shouldSkipSearch,
    variables: {
      limit: 5,
      search,
    },
  });
  return (
    <Wrapper>
      {data && !data.search_accounts.length && (
        <CenteredText addBottomMargin>No matching accounts exist</CenteredText>
      )}
      {data &&
        data.search_accounts
          .filter((account) =>
            selectedAccountId ? account.id === selectedAccountId : true,
          )
          .map((account, index) => {
            const isSelected = selectedAccountId === account.id;
            const accountName = formatFullName(account);
            const isMissingName = !account.firstName || !account.lastName;
            const isLinkedToSelectedPatient =
              !isSelected &&
              !!account?.account_patients?.find(
                (ap) => ap.patientId === selectedPatientId,
              );
            const relationship =
              account.account_patients.filter(
                (ap) => ap.patientId === selectedPatientId,
              )?.[0]?.relationship ?? undefined;
            const relationshipDisplay =
              isLinkedToSelectedPatient &&
              getRelationshipDisplay(relationship ?? '');
            return (
              <Fragment key={account.id}>
                <PersonWrapper data-testid={`searchResult-${account.id}`}>
                  <AccountAvatarDisplay
                    accountId={account.id}
                    style={{
                      height: '48px',
                      width: '48px',
                    }}
                  />
                  <PersonTextWrapper>
                    <PersonText>
                      {accountName}
                      {relationshipDisplay && (
                        <NormalText>({relationshipDisplay})</NormalText>
                      )}
                    </PersonText>
                    <SecondaryText>
                      {formatPhoneNumber(account.phoneNumber)}
                    </SecondaryText>
                  </PersonTextWrapper>
                  <SelectButton
                    isSelected={isSelected}
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!isSelected) {
                        onLinkAccount({
                          account,
                          relationship,
                          isMissingName,
                        });
                      } else {
                        onLinkAccount({});
                      }
                    }}
                  >
                    {isSelected ? 'Unselect' : 'Select'} Caregiver
                    {isSelected && <Unselect />}
                  </SelectButton>
                </PersonWrapper>
                {onLinkPatient &&
                  isSelected &&
                  !selectedPatientId &&
                  !!account.account_patients.length && (
                    <Fragment>
                      <Title>
                        Select patient who{' '}
                        {account.goesByName || account.firstName} will be
                        accompanying
                      </Title>
                      {account.account_patients.map((ap, index) => {
                        const patientName = formatFullName(ap.patient);
                        const isPatientSelected =
                          selectedPatientId === ap.patientId;
                        return (
                          <PersonWrapper key={ap.id}>
                            <PatientAvatarDisplay
                              patientId={ap.patientId}
                              style={{
                                height: '48px',
                                width: '48px',
                              }}
                            />
                            <PersonTextWrapper>
                              <PersonText>{patientName}</PersonText>
                              <SecondaryText>
                                {displayDOB(ap.patient.dateOfBirth)}
                              </SecondaryText>
                            </PersonTextWrapper>
                            <SelectButton
                              isSelected={isPatientSelected}
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                if (!isPatientSelected) {
                                  onLinkPatient({
                                    patient: ap.patient,
                                    relationship: ap.relationship ?? undefined,
                                  });
                                } else {
                                  onLinkPatient({});
                                }
                              }}
                            >
                              {isPatientSelected ? 'Unselect' : 'Select'}{' '}
                              Patient
                              {isPatientSelected && <Unselect />}
                            </SelectButton>
                          </PersonWrapper>
                        );
                      })}
                    </Fragment>
                  )}
              </Fragment>
            );
          })}
    </Wrapper>
  );
};
