import { ChatNoteFragment } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React from 'react';
import copyIcon from '../../assets/copy.svg';
import { fullStaffName } from '../../pages/staff/helpers';
import { IF_TABLET } from '../../styles';
import CopyClip from '../copy';
import { PrimaryText } from '../text';

export const ChatSummary: React.FC<{
  chatNote: ChatNoteFragment;
  chatTitle: string;
}> = ({ chatNote, chatTitle }) => {
  return (
    <Container>
      <TitleRow>
        <Title>Chat Summary</Title>
        <CopyClip
          text={`${chatTitle}\n\n${chatNote.note}\n\nhttps://${window.location.host}/chats/${chatNote.chatSessionId}`}
        >
          <Copy src={copyIcon} />
        </CopyClip>
      </TitleRow>
      <PrimaryText>{chatNote.note}</PrimaryText>
      {chatNote.staff && (
        <StaffRow>
          <Avatar src={`${chatNote.staff.avatarUrl}?w=64&h=64`} />
          <div>
            <StaffName>{fullStaffName(chatNote.staff)}</StaffName>
            <Timestamp>
              {DateTime.fromISO(chatNote.updatedAt!).toFormat("D 'at' t")}
            </Timestamp>
          </div>
        </StaffRow>
      )}
    </Container>
  );
};

const Container = styled.div({
  background: Colors.white,
  border: `1px solid ${Colors.grayLight}`,
  borderRadius: 32,
  padding: 16,
  [IF_TABLET]: {
    padding: 32,
  },
});

const TitleRow = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 16,
});

const Title = styled(PrimaryText)({
  fontSize: 18,
  fontWeight: 'bold',
  lineHeight: '24px',
});

const Copy = styled.img({
  cursor: 'pointer',
  height: 24,
  width: 24,
});

const StaffRow = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  marginTop: 18,
});

const Avatar = styled.img({
  borderRadius: 16,
  height: 32,
  width: 32,
});

const StaffName = styled(PrimaryText)({
  display: 'block',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '18px',
  marginLeft: 8,
});

const Timestamp = styled(PrimaryText)({
  color: Colors.gray,
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '16px',
  marginLeft: 8,
});
