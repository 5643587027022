/** @jsx jsx */
import {
  AccountPatientFragment,
  PatientDiscoveredFromFragment,
  PatientFragment,
} from '@bc/codegen/medical';
import { PREFERRED_PRONOUN, RELATIONSHIPS } from '@bc/shared';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { DialogOverlay } from '@reach/dialog';
import React from 'react';
import { getDateOfBirth } from '../../helpers';
import { FONT_PRIMARY } from '../../styles';
import {
  ModalClose,
  ModalContent,
  ModalTitle,
  SmallModal,
} from '../Audit/styles';
import { AccountAvatar, PatientAvatar } from '../ChatMessage';

const ModalText = styled.div`
  ${FONT_PRIMARY}
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.darkHarbor};
  width: 100%;
`;

const Row = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const Column = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const CaregiverInfoModal: React.FC<{
  accountId: string;
  accountName: string;
  accountPatients: ({
    patient: PatientFragment & PatientDiscoveredFromFragment;
  } & AccountPatientFragment)[];
  accountPronouns: string;
  isOpen: boolean;
  onClose: () => void;
  preferredPronoun?: string | null;
}> = ({
  accountId,
  accountName,
  accountPatients,
  accountPronouns,
  isOpen,
  onClose,
  preferredPronoun,
}) => {
  const patients = accountPatients.map((ap) => ap.patient);
  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={onClose}
      style={{
        zIndex: 100,
      }}
    >
      <SmallModal aria-label="Caregiver Info">
        <ModalClose
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
          css={{ position: 'absolute', top: 24, right: 24 }}
        />
        <ModalTitle>About this caregiver</ModalTitle>
        <ModalContent css={{ paddingBottom: 24 }}>
          <Row>
            <AccountAvatar
              accountId={accountId}
              size={64}
              style={{ marginRight: 16 }}
            />
            <Column>
              <ModalText>{accountName}</ModalText>
              {preferredPronoun && <ModalText>{accountPronouns}</ModalText>}
            </Column>
          </Row>
          {patients.length > 0 && (
            <ModalText css={{ fontWeight: 500, margin: '16px 0 8px' }}>
              Linked patients:
            </ModalText>
          )}
          {accountPatients.map((ap, index) => {
            const {
              id,
              dateOfBirth,
              address,
              goesByName,
              firstName,
              lastName,
              preferredPronoun,
              preferredPronounType,
            } = ap.patient;
            const relationship = RELATIONSHIPS.find(
              (r) => r.value === ap.relationship,
            )?.label;

            const name = `${goesByName || firstName} ${lastName}`;
            const pronouns = `${
              preferredPronounType === 'other'
                ? preferredPronoun
                : PREFERRED_PRONOUN.find(
                    (p) => p.value === preferredPronounType,
                  )?.label
            }`;
            const formattedAddress = address?.formatted_address?.replace(
              ', USA',
              '',
            );

            return (
              <Row
                key={id}
                css={
                  index === 0
                    ? { paddingTop: 8 }
                    : {
                        borderTop: `1px solid ${Colors.grayLighter}`,
                        marginTop: 16,
                        paddingTop: 16,
                      }
                }
              >
                <PatientAvatar
                  patientId={id}
                  size={48}
                  style={{ marginRight: 16 }}
                />
                <Column>
                  <ModalText>
                    {relationship && `${relationship} of `}
                    {name} ({getDateOfBirth({ date: dateOfBirth })})
                  </ModalText>
                  {preferredPronoun && (
                    <ModalText css={{ marginTop: 4 }}>{pronouns}</ModalText>
                  )}
                  {formattedAddress && (
                    <ModalText css={{ marginTop: 4 }}>
                      {formattedAddress}
                    </ModalText>
                  )}
                </Column>
              </Row>
            );
          })}
        </ModalContent>
      </SmallModal>
    </DialogOverlay>
  );
};
