/** @jsx jsx */
import {
  useGetClinicsQuery,
  useUpdateClinicMutation,
} from '@bc/codegen/manager';
import { TIME_ZONES } from '@bc/shared';
import { Colors, Spacing } from '@bc/theme';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Fragment, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { hasRole } from '../../auth';
import { Button, ButtonRed, OutlineButton } from '../../components/button';
import { Header } from '../../components/Header';
import { Content, InnerWrap, MainContent } from '../../components/layout';
import { Sidebar } from '../../components/Nav/Sidebar';
import { LargeTitle, PrimaryText, SmallTitle } from '../../components/text';
import { useAuth0 } from '../../react-auth0';
import { Column, IF_TABLET, Row, WrapRow } from '../../styles';

const PageHeader = styled.div`
  align-items: baseline;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding: 14px 12px;
  margin: 20px 0;
  ${IF_TABLET} {
    margin: 40px 0;
    padding: 0;
  }
`;

const Container = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

const SmallButton = css({
  padding: '8px 16px',
  width: 'max-content',
});

const ClinicsIndex = ({ history }: RouteComponentProps<{}>) => {
  const { accessToken } = useAuth0();
  const isManager = hasRole('manager', accessToken);

  const [updateClinic] = useUpdateClinicMutation({
    context: {
      role: 'manager',
    },
  });
  const { data, loading, refetch } = useGetClinicsQuery({
    fetchPolicy: 'cache-and-network',
    context: { role: 'manager' },
    variables: {
      where: {
        locationType: {
          _neq: 'EVENT',
        },
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [history.location.pathname]);

  const clinics = data?.clinics ?? [];
  const sortedClinics = [...clinics].sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  const handleToggleActive = async (clinicId: string) => {
    const clinic = clinics.find(({ id }) => {
      return id === clinicId;
    });

    if (!clinic) return;
    await updateClinic({
      variables: {
        id: clinicId,
        clinic: {
          active: !clinic.active,
        },
      },
    });
    refetch();
  };

  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap>
          <Sidebar />
          <MainContent>
            <PageHeader>
              <LargeTitle>Clinics</LargeTitle>
              {isManager && (
                <OutlineButton
                  style={{
                    borderRadius: '100px',
                    margin: '8px 0 0 auto',
                  }}
                  onClick={() => history.push('/clinics/create')}
                >
                  Create Clinic
                </OutlineButton>
              )}
            </PageHeader>
            <div
              css={{
                border: `1px solid ${Colors.grayLighter}`,
                borderRadius: 12,
                width: '100%',
                minHeight: 'calc(100% - 120px)',
              }}
            >
              {sortedClinics.map(
                ({ id, name, active, address, timeZone }, index) => {
                  const isLast = index === clinics.length - 1;
                  // @ts-ignore
                  const displayTimeZone = TIME_ZONES[timeZone] || '';

                  return (
                    <WrapRow
                      key={id}
                      css={[
                        {
                          borderTop: `1px solid ${Colors.grayLighter}`,
                          justifyContent: 'space-between',
                          padding: '24px',
                        },
                        index === 0 && { borderTop: 'none' },
                        isLast && {
                          borderBottom: `1px solid ${Colors.grayLighter}`,
                        },
                      ]}
                    >
                      <Column css={{ margin: '0 24px 0 0' }}>
                        <SmallTitle>{name}</SmallTitle>
                        <PrimaryText css={{ paddingTop: Spacing.xxs }}>
                          {address}
                        </PrimaryText>
                        {timeZone && (
                          <PrimaryText css={{ paddingTop: Spacing.xxs }}>
                            {displayTimeZone}
                          </PrimaryText>
                        )}
                      </Column>
                      <Row css={{ padding: '8px 0' }}>
                        <OutlineButton
                          style={{
                            borderRadius: '100px',
                            margin: '0 24px 0 auto',
                          }}
                          onClick={() => history.push(`/clinics/edit/${id}`)}
                        >
                          Edit Clinic
                        </OutlineButton>
                        {active && (
                          <ButtonRed
                            css={SmallButton}
                            onClick={() => {
                              handleToggleActive(id);
                            }}
                          >
                            Set Inactive
                          </ButtonRed>
                        )}
                        {!active && (
                          <Button
                            css={SmallButton}
                            onClick={() => {
                              handleToggleActive(id);
                            }}
                          >
                            Set Active
                          </Button>
                        )}
                      </Row>
                    </WrapRow>
                  );
                },
              )}
              {clinics.length === 0 && !loading && (
                <Container>
                  <SmallTitle>No clinics</SmallTitle>
                </Container>
              )}
            </div>
          </MainContent>
        </InnerWrap>
      </Content>
    </Fragment>
  );
};

export default ClinicsIndex;
