/** @jsx jsx */
import { jsx } from '@emotion/core';
import { createBrowserHistory } from 'history';
import { Route, Router, Switch } from 'react-router-dom';
import {
  Content,
  FullScreenModalWrap,
  ModalWrap,
  TaskContent,
} from './components/Routing';
import { PrivateRoute } from './components/Routing/PrivateRoute';
import { useScrollToTop } from './hooks';
import AddAppointment from './pages/AddAppointment';
import AuthVerify from './pages/AuthVerify';
import Chat from './pages/chats/Chat';
import Chats from './pages/chats/Chats';
import MyChats from './pages/chats/MyChats';
import NeedsProvider from './pages/chats/NeedsProvider';
import WaitingRoom from './pages/chats/WaitingRoom';
import ClinicsIndex from './pages/clinics';
import CreateClinic from './pages/clinics/create';
import EditClinic from './pages/clinics/edit';
import Dashboard from './pages/Dashboard';
import Devices from './pages/Devices';
import EventsIndex from './pages/events';
import CreateEvent from './pages/events/create';
import EditEvent from './pages/events/edit';
import GreetPatient from './pages/GreetPatient';
import { GroupIndex } from './pages/groups';
import { CreateGroup } from './pages/groups/create';
import { ViewGroup } from './pages/groups/view_group';
import Help from './pages/Help';
import KareoLogs from './pages/kareo/KareoLogs';
import Login from './pages/Login';
import MergeIndex from './pages/merge/index';
import PatientMerger from './pages/merge/PatientMerger';
import NewAccountPatient from './pages/NewAccountPatient';
import ProductEditor from './pages/products/ProductEditor';
import ViewAllProducts from './pages/products/ViewAll';
import { RegionsIndex } from './pages/regions';
import { CreateRegion } from './pages/regions/create';
import { ViewRegion } from './pages/regions/view_region';
import { Rooms } from './pages/rooms';
import { SearchAll } from './pages/search/Search';
import ScheduleForm from './pages/staff/ScheduleForm';
import ScheduleManagement from './pages/staff/ScheduleManagement';
import ScheduleView from './pages/staff/ScheduleView';
import StaffForm from './pages/staff/StaffForm';
import StaffManagement from './pages/staff/StaffManagement';
import StaffView from './pages/staff/StaffView';
import CreateTask from './pages/tasks/CreateTask';
import EditTask from './pages/tasks/EditTask';
import TaskIndex from './pages/tasks/TaskIndex';
import ViewTask from './pages/tasks/ViewTask';
import ViewAccount from './pages/ViewAccount';
import ViewAppointment from './pages/ViewAppointment';
import ViewPatient from './pages/ViewPatient';

import { BodyWrap } from './styles';

export const history = createBrowserHistory();

// @ts-ignore
window.browserHistory = history;

export const App = () => {
  useScrollToTop();
  return (
    <Router history={history}>
      <BodyWrap>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/auth/verify" component={AuthVerify} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/account" component={Dashboard} />
          <PrivateRoute path="/appointment" component={Dashboard} />
          <PrivateRoute path="/patient" component={Dashboard} />
          <PrivateRoute path="/products/all" component={ViewAllProducts} />
          <PrivateRoute path="/products/:id" component={ProductEditor} />
          <PrivateRoute path="/products" component={ProductEditor} />
          <PrivateRoute path="/devices" component={Devices} />
          <PrivateRoute path="/help" component={Dashboard} />
          <PrivateRoute path="/chats/my-chats" component={MyChats} />
          <PrivateRoute path="/chats/waiting-room" component={WaitingRoom} />
          <PrivateRoute
            path="/chats/needs-provider"
            component={NeedsProvider}
          />
          <PrivateRoute path="/chats/:id" component={Chat} />
          <PrivateRoute path="/chats" component={Chats} />
          <PrivateRoute path="/session" component={Dashboard} />
          <PrivateRoute path="/user" component={Dashboard} />
          <PrivateRoute path="/search" component={Dashboard} />
          <PrivateRoute path="/groups" component={GroupIndex} />
          <PrivateRoute path="/regions" component={RegionsIndex} />
          <PrivateRoute path="/patient_merger/:ids" component={PatientMerger} />
          <PrivateRoute path="/patient_merger" component={MergeIndex} />
          <PrivateRoute path="/clinics" component={ClinicsIndex} />
          <PrivateRoute path="/events" component={EventsIndex} />
          <PrivateRoute path="/staff/schedule" component={ScheduleManagement} />
          <PrivateRoute path="/staff" component={StaffManagement} />
          <PrivateRoute path="/rooms" component={Rooms} />
          <PrivateRoute path="/greet" component={Dashboard} />
          <PrivateRoute path="/tasks/create" component={TaskIndex} />
          <PrivateRoute path="/tasks/:taskId" component={TaskIndex} />
          <PrivateRoute path="/tasks/:taskId/edit" component={TaskIndex} />
          <PrivateRoute path="/tasks" component={TaskIndex} />
          <PrivateRoute path="/kareo/logs" component={KareoLogs} />
          <PrivateRoute path="/new-patient-caregiver" component={Dashboard} />
        </Switch>
        <Switch>
          <PrivateRoute
            exact
            path="/help"
            render={(props: any) => {
              return (
                <ModalWrap {...props}>
                  <Help {...props} />
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            exact
            path="/groups/create"
            render={(props: any) => {
              return (
                <ModalWrap {...props} onDismissGoToPath="/groups">
                  <Content>
                    <CreateGroup {...props} />
                  </Content>
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            exact
            path="/groups/view/:id"
            render={(props: any) => {
              return (
                <ModalWrap {...props} onDismissGoToPath="/groups">
                  <Content>
                    <ViewGroup {...props} />
                  </Content>
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            exact
            path="/regions/create"
            render={(props: any) => {
              return (
                <ModalWrap {...props} onDismissGoToPath="/regions">
                  <Content>
                    <CreateRegion {...props} />
                  </Content>
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            exact
            path="/regions/view/:id"
            render={(props: any) => {
              return (
                <ModalWrap {...props} onDismissGoToPath="/regions">
                  <Content>
                    <ViewRegion {...props} />
                  </Content>
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            exact
            path="/tasks/create"
            render={(props: any) => {
              return (
                <ModalWrap {...props} onDismissGoToPath="/tasks">
                  <TaskContent>
                    <CreateTask
                      {...props}
                      defaultAssociations={[]}
                      dismissPath="/tasks"
                    />
                  </TaskContent>
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            exact
            path="/tasks/:taskId"
            render={(props: any) => {
              return (
                <ModalWrap {...props} onDismissGoToPath="/tasks">
                  <Content>
                    <ViewTask {...props} dismissPath="/tasks" />
                  </Content>
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            exact
            path="/tasks/:taskId/edit"
            render={(props: any) => {
              return (
                <ModalWrap {...props} onDismissGoToPath="/tasks">
                  <TaskContent>
                    <EditTask {...props} dismissPath="/tasks" />
                  </TaskContent>
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            exact
            path="/clinics/create"
            render={(props: any) => {
              return (
                <ModalWrap {...props} onDismissGoToPath="/clinics">
                  <CreateClinic {...props} />
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            exact
            path="/clinics/edit/:id"
            render={(props: any) => {
              return (
                <ModalWrap {...props} onDismissGoToPath="/clinics">
                  <EditClinic {...props} />
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            exact
            path="/events/create"
            render={(props: any) => {
              return (
                <ModalWrap {...props} onDismissGoToPath="/events">
                  <CreateEvent {...props} />
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            exact
            path="/events/edit/:id"
            render={(props: any) => {
              return (
                <ModalWrap {...props} onDismissGoToPath="/events">
                  <EditEvent {...props} />
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            exact
            path="/appointment/add"
            render={(props: any) => {
              const returnPath =
                props.location.state?.returnPath ?? '/dashboard';
              return (
                <FullScreenModalWrap onDismissGoToPath={returnPath} {...props}>
                  <AddAppointment returnPath={returnPath} {...props} />
                </FullScreenModalWrap>
              );
            }}
          />
          <PrivateRoute
            path="/greet"
            render={(props: any) => {
              return (
                <FullScreenModalWrap {...props}>
                  <GreetPatient {...props} />
                </FullScreenModalWrap>
              );
            }}
          />
          <PrivateRoute
            path="/new-patient-caregiver"
            render={(props: any) => {
              return (
                <FullScreenModalWrap {...props}>
                  <NewAccountPatient {...props} />
                </FullScreenModalWrap>
              );
            }}
          />
          <PrivateRoute
            path="/account/:accountId"
            render={(props: any) => {
              return (
                <FullScreenModalWrap {...props}>
                  <ViewAccount {...props} />
                </FullScreenModalWrap>
              );
            }}
          />
          <PrivateRoute
            path="/appointment/:appointmentId"
            render={(props: any) => {
              return (
                <FullScreenModalWrap {...props}>
                  <ViewAppointment {...props} />
                </FullScreenModalWrap>
              );
            }}
          />
          <PrivateRoute
            path="/chats/:id/appointment/:appointmentId"
            render={(props: any) => {
              const returnPath = `/chats/${props.match.params.id}`;
              return (
                <ModalWrap onDismissGoToPath={returnPath} {...props}>
                  <ViewAppointment returnPath={returnPath} {...props} />
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            path="/chats/:id/account/:accountId"
            render={(props: any) => {
              const returnPath = `/chats/${props.match.params.id}`;
              return (
                <ModalWrap onDismissGoToPath={returnPath} {...props}>
                  <ViewAccount returnPath={returnPath} {...props} />
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            path="/chats/:id/patient/:patientId"
            render={(props: any) => {
              const returnPath = `/chats/${props.match.params.id}`;
              return (
                <ModalWrap onDismissGoToPath={returnPath} {...props}>
                  <ViewPatient returnPath={returnPath} {...props} />
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            path="/patient/:patientId"
            render={(props: any) => {
              return (
                <FullScreenModalWrap {...props}>
                  <ViewPatient {...props} />
                </FullScreenModalWrap>
              );
            }}
          />
          <PrivateRoute
            exact
            path="/staff/schedule/add"
            render={(props: any) => {
              return (
                <ModalWrap {...props} onDismissGoToPath="/staff/schedule">
                  <ScheduleForm {...props} />
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            exact
            path="/staff/schedule/edit/:staffScheduleId"
            render={(props: any) => {
              return (
                <ModalWrap {...props} onDismissGoToPath="/staff/schedule">
                  <ScheduleForm {...props} />
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            exact
            path="/staff/schedule/:staffScheduleId"
            render={(props: any) => {
              return (
                <ModalWrap {...props} onDismissGoToPath="/staff/schedule">
                  <ScheduleView {...props} />
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            exact
            path="/staff/add"
            render={(props: any) => {
              return (
                <ModalWrap {...props} onDismissGoToPath="/staff/view">
                  <StaffForm {...props} />
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            path="/staff/edit/:staffId"
            render={(props: any) => {
              return (
                <ModalWrap {...props} onDismissGoToPath="/staff/view">
                  <StaffForm {...props} />
                </ModalWrap>
              );
            }}
          />
          <PrivateRoute
            exact
            path="/staff/view/:staffId"
            render={(props: any) => {
              return (
                <ModalWrap {...props} onDismissGoToPath="/staff/view">
                  <StaffView {...props} />
                </ModalWrap>
              );
            }}
          />
        </Switch>
        <PrivateRoute
          path="/"
          render={(props: any) => {
            const shouldShowModal = !!props?.location?.state?.search;
            if (shouldShowModal) {
              return (
                <ModalWrap
                  {...props}
                  onDismissGoToPath={props?.location?.pathname ?? '/dashboard'}
                >
                  <SearchAll {...props} />
                </ModalWrap>
              );
            }
            return null;
          }}
        />
      </BodyWrap>
    </Router>
  );
};
