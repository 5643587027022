/** @jsx jsx */
import { Task_Status_Enum } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Menu, MenuItem } from '@reach/menu-button';
import { ICONS } from '../../assets';
import { PrimaryText } from '../text';
import { DropdownMenuList, PillMenuButton } from './ui';

export const NameMap: Record<Task_Status_Enum, string> = {
  OPEN: 'Open',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  BLOCKED: 'Blocked',
};

export const ColorMap: Record<Task_Status_Enum, string> = {
  OPEN: '#647977',
  IN_PROGRESS: '#695EED',
  COMPLETED: '#0D957B',
  BLOCKED: '#FF5469',
};

export const getStatusDisplay = (value: Task_Status_Enum) => NameMap[value];
export const getStatusColor = (value: Task_Status_Enum) => ColorMap[value];

const DisplayText = styled(PrimaryText)({
  fontSize: '14px',
  fontWeight: 500,
});

export const StatusDisplay = ({
  value,
  className,
}: {
  value: Task_Status_Enum;
  className?: string;
}) => {
  return (
    <PrimaryText
      css={{
        color: getStatusColor(value),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
      className={className}
    >
      <span>{NameMap[value]}</span>
    </PrimaryText>
  );
};

export const StatusDropdown = ({
  value,
  onChange,
  className,
}: {
  value: Task_Status_Enum;
  onChange: (status: Task_Status_Enum) => void;
  className?: string;
}) => {
  return (
    <Menu>
      <PillMenuButton className={className} data-testid="status_menu_button">
        <PrimaryText
          css={{
            color: ColorMap[value],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <span
            css={{
              fontSize: '14px',
              fontWeight: 500,
            }}
          >
            {NameMap[value]}
          </span>
          <img
            src={ICONS.caretDown}
            css={{
              width: '16px',
              height: '16px',
            }}
          />
        </PrimaryText>
      </PillMenuButton>
      <DropdownMenuList data-testid="status_menu_dropdown">
        <MenuItem
          onSelect={() => {
            onChange('OPEN');
          }}
          css={{
            padding: '16px 24px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            backgroundColor: value === 'OPEN' ? '#EFF1F1' : undefined,
          }}
        >
          <DisplayText
            css={{
              color: ColorMap.OPEN,
            }}
          >
            {NameMap.OPEN}
          </DisplayText>
        </MenuItem>
        <MenuItem
          onSelect={() => {
            onChange('IN_PROGRESS');
          }}
          css={{
            padding: '16px 24px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: value === 'IN_PROGRESS' ? '#EFF1F1' : undefined,
          }}
        >
          <DisplayText
            css={{
              color: ColorMap.IN_PROGRESS,
            }}
          >
            {NameMap.IN_PROGRESS}
          </DisplayText>
        </MenuItem>
        <MenuItem
          onSelect={() => {
            onChange('BLOCKED');
          }}
          css={{
            padding: '16px 24px',
            display: 'flex',
            alignItems: 'center',
            color: ColorMap.BLOCKED,
            backgroundColor: value === 'BLOCKED' ? '#EFF1F1' : undefined,
          }}
        >
          <DisplayText
            css={{
              color: ColorMap.BLOCKED,
            }}
          >
            {NameMap.BLOCKED}
          </DisplayText>
        </MenuItem>
        <MenuItem
          onSelect={() => {
            onChange('COMPLETED');
          }}
          css={{
            padding: '16px 24px',
            display: 'flex',
            alignItems: 'center',
            color: ColorMap.COMPLETED,
            backgroundColor: value === 'COMPLETED' ? '#EFF1F1' : undefined,
          }}
        >
          <DisplayText
            css={{
              color: ColorMap.COMPLETED,
            }}
          >
            {NameMap.COMPLETED}
          </DisplayText>
        </MenuItem>
      </DropdownMenuList>
    </Menu>
  );
};
