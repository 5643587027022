import {
  ClinicFragment,
  useGetClinicQuery,
  useUpdateClinicMutation,
} from '@bc/codegen/manager';
import { Form, Formik } from 'formik';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { object, string } from 'yup';
import { Button } from '../../components/button';
import { NormalField, SelectSimpleField } from '../../components/form';
import { ContentRow, InfoSection } from '../../components/layout';
import { LargeTitle } from '../../components/text';
import { CLINIC_STATUSES, getClinicSlots } from '../../helpers';
import { Column, Row } from '../../styles';
import { Close, Content } from '../ViewAppointment.styles';

const ClinicValidation = object().shape({
  name: string().required('Required'),
  status: string().required('Required'),
});

const context = { role: 'manager' };

const slots = getClinicSlots();

const EditClinic: React.FC<
  RouteComponentProps<{
    id: string;
  }>
> = ({ history, match }) => {
  const [updateClinic] = useUpdateClinicMutation({ context });

  const { data: clinicData, loading } = useGetClinicQuery({
    context,
    variables: {
      where: {
        id: {
          _eq: match?.params?.id,
        },
      },
    },
  });

  const clinic = clinicData?.clinics?.[0];

  const handleSubmit = async (values: Partial<ClinicFragment>) => {
    await updateClinic({
      context,
      variables: {
        id: match.params.id,
        clinic: {
          name: values.name,
          status: values.status,
          open: values.open,
          close: values.close,
        },
      },
    });
    history.push('/clinics');
  };
  return (
    <Content aria-label="Edit Clinic" style={{ padding: '40px' }}>
      <Row style={{ position: 'relative' }}>
        <Column>
          <LargeTitle>Edit Clinic</LargeTitle>
        </Column>
        <Close onClick={() => history.push('/clinics')} />
      </Row>
      {!loading && (
        <Formik
          initialValues={{
            name: clinic?.name,
            clinicCode: clinic?.clinicCode,
            status: clinic?.status,
            open: clinic?.open,
            close: clinic?.close,
          }}
          onSubmit={handleSubmit}
          initialStatus={{
            editing: true,
          }}
          validationSchema={ClinicValidation}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <ContentRow>
                  <NormalField title="Clinic Name" name="name" fullWidth />
                </ContentRow>
                <ContentRow>
                  <NormalField
                    title="Clinic Code"
                    name="clinicCode"
                    fullWidth
                    disabled
                  />
                </ContentRow>
                <ContentRow>
                  <InfoSection title="Clinic Status" fullWidth>
                    <SelectSimpleField name="status">
                      <option value="">Select</option>
                      {CLINIC_STATUSES?.map(({ value, label }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </SelectSimpleField>
                  </InfoSection>
                </ContentRow>
                <ContentRow>
                  <InfoSection title="Clinic Open" fullWidth>
                    <SelectSimpleField name="open">
                      <option value="">Select</option>
                      {slots.map(({ value, label }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </SelectSimpleField>
                  </InfoSection>
                </ContentRow>
                <ContentRow>
                  <InfoSection title="Clinic Close" fullWidth>
                    <SelectSimpleField name="close">
                      <option value="">Select</option>
                      {slots.map(({ value, label }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </SelectSimpleField>
                  </InfoSection>
                </ContentRow>

                <ContentRow>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    style={{ width: 'auto' }}
                  >
                    Update
                  </Button>
                </ContentRow>
              </Form>
            );
          }}
        </Formik>
      )}
    </Content>
  );
};

export default EditClinic;
