/** @jsx jsx */
import { AuditFragment } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import capitalize from 'lodash/capitalize';
import { DateTime } from 'luxon';
import { Fragment } from 'react';
import { Avatar, EntryText, Row, Timestamp } from './styles';

export const Entry: React.FC<{ audit: AuditFragment }> = ({ audit }) => {
  let action = '';
  if (audit.operation === 'INSERT') {
    if (audit.table === 'appointments') {
      action = 'Scheduled';
    } else {
      action = 'Created';
    }
  } else {
    action = 'Edited';
  }

  const accName = audit.account
    ? `${audit.account?.firstName} ${audit.account?.lastName}`
    : '';
  const staffName = audit.staff
    ? `${audit.staff?.firstName} ${audit.staff?.lastName}`
    : '';

  const time = DateTime.fromJSDate(new Date(audit.created_at));
  const responsibleParty =
    accName ||
    staffName ||
    audit.session?.['x-hasura-user-email'] ||
    capitalize(audit.session?.['x-hasura-role']);

  return (
    <Row>
      <Avatar
        src={
          (audit.staff
            ? audit.staff.avatarUrl
            : audit.account
            ? audit.account.avatar?.url
            : 'https://bravecare.imgix.net/staff/empty.png') ??
          'https://bravecare.imgix.net/staff/empty.png'
        }
      />
      <div>
        <EntryText css={{ marginBottom: 1 }}>
          <EntryText css={{ color: '#647977' }}>{action}</EntryText>
          {!!responsibleParty && (
            <Fragment>
              <EntryText css={{ color: '#647977' }}>&nbsp;by&nbsp;</EntryText>
              <EntryText css={{ fontWeight: 500 }}>
                {responsibleParty}
              </EntryText>
            </Fragment>
          )}
        </EntryText>
        <Timestamp>{time.toLocaleString(DateTime.DATETIME_SHORT)}</Timestamp>
      </div>
    </Row>
  );
};
