/** @jsx jsx */
import { Tasks_Order_By } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Menu, MenuItem } from '@reach/menu-button';
import { Fragment } from 'react';
import { PrimaryText } from '../text';
import SortArrows from './icons/sort_both.svg';
import { DropdownMenuList, PillMenuButton } from './ui';
const NBSP = '\u00A0';

type Keys = Pick<
  Tasks_Order_By,
  'title' | 'priority' | 'dueDateTime' | 'status' | 'type'
>;

const TextDisplay = styled(PrimaryText)({
  fontSize: '14px',
  fontWeight: 500,
});

const MenuBit = styled(MenuItem)({
  padding: '16px 24px',
  display: 'flex',
  alignItems: 'center',
});

const KeyToName: Record<keyof Keys, string> = {
  title: 'Title',
  priority: 'Priority',
  dueDateTime: 'Due Date',
  status: 'Status',
  type: 'Type',
};

export const TaskSortDropdown = ({
  value,
  onChange,
  className,
}: {
  value: Tasks_Order_By;
  onChange: (task_order: keyof Tasks_Order_By) => void;
  className?: string;
}) => {
  const displayKey = Object.keys(value)[0] as keyof Keys;

  return (
    <Menu>
      {({ isExpanded }) => {
        return (
          <Fragment>
            <PillMenuButton
              css={{
                padding: '7px 12px',
              }}
              data-testid="task_sort_menu_button"
              selected={isExpanded}
            >
              <PrimaryText>
                <img
                  src={SortArrows}
                  css={{
                    width: '12px',
                    height: '10px',
                    marginRight: '4px',
                    marginTop: '2px',
                  }}
                />
                <span>Sort By:{NBSP}</span>
              </PrimaryText>
              <PrimaryText css={{ color: '#647977' }}>
                {KeyToName[displayKey]}
              </PrimaryText>
            </PillMenuButton>
            <DropdownMenuList
              data-testid="task_sort_menu_dropdown"
              css={{
                minWidth: '168px',
                width: '100%',
              }}
            >
              <MenuBit
                onSelect={() => {
                  onChange('priority');
                }}
                css={{
                  backgroundColor: !!value.priority ? '#EFF1F1' : undefined,
                }}
              >
                <TextDisplay>Priority</TextDisplay>
              </MenuBit>
              <MenuBit
                onSelect={() => {
                  onChange('dueDateTime');
                }}
                css={{
                  backgroundColor: !!value.dueDateTime ? '#EFF1F1' : undefined,
                }}
              >
                <TextDisplay>Due Date</TextDisplay>
              </MenuBit>
              <MenuBit
                onSelect={() => {
                  onChange('status');
                }}
                css={{
                  backgroundColor: !!value.status ? '#EFF1F1' : undefined,
                }}
              >
                <TextDisplay>Status</TextDisplay>
              </MenuBit>
              <MenuBit
                onSelect={() => {
                  onChange('type');
                }}
                css={{
                  backgroundColor: !!value.type ? '#EFF1F1' : undefined,
                }}
              >
                <TextDisplay>Type</TextDisplay>
              </MenuBit>
            </DropdownMenuList>
          </Fragment>
        );
      }}
    </Menu>
  );
};
