/** @jsx jsx */
import {
  useDeleteStaffScheduleMutation,
  useLoadStaffAndServicesQuery,
  useLoadStaffScheduleQuery,
} from '@bc/codegen/manager';
import { useLoadAllClinicsQuery } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import sumBy from 'lodash/sumBy';
import xor from 'lodash/xor';
import { DateTime } from 'luxon';
import { Fragment, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { ICONS } from '../../assets';
import { hasRole } from '../../auth';
import { Badge } from '../../components/Badge/Badge';
import { GreenOutlineButton as OutlineButton } from '../../components/button';
import {
  CalendarControl,
  CalendarControls,
  CalendarSelector,
  Next,
  Prev,
} from '../../components/CalendarSelector';
import { CheckboxFilter } from '../../components/CheckboxFilter';
import { ClinicRegionDropdown } from '../../components/ClinicSelector/ClinicRegionDropdown';
import { Header } from '../../components/Header';
import {
  Content,
  InnerWrap,
  MainContent,
  TitleRow,
} from '../../components/layout';
import { Sidebar } from '../../components/Nav/Sidebar';
import { DayView, MonthView, WeekView } from '../../components/Schedule';
import { LargeTitle, PrimaryText } from '../../components/text';
import { useWindowSize } from '../../hooks';
import { useAppointmentCounts } from '../../lib/appointmentCounts';
import { useAuth0 } from '../../react-auth0';
import { IF_TABLET, MobileWrapRow, Row, TABLET_WIDTH } from '../../styles';
import { getDisplayDate } from '../Dashboard.helpers';
import {
  DropdownButtonContainer,
  Filterable,
  FilterableText,
  FilterContainer,
  ScheduleWrap,
  StaffScheduleContent,
} from '../Dashboard.styles';
import StaffScheduleRemovalConfirmation from './StaffScheduleRemovalConfirmation';
import { Controls, FormattedDay, Icon, ScheduleHeader } from './styles';

const VIEWS = ['day', 'week', 'month'];
type View = typeof VIEWS[number];

const notSelected = `1px solid ${Colors.grayLight}`;
const selectedBorder = `1px solid ${Colors.gray}`;

const SidebySelector = ({
  selected,
  onPress,
}: {
  selected: View;
  onPress: (view: View) => void;
}) => {
  const daySelected = selected === 'day';
  const weekSelected = selected === 'week';
  const monthSelected = selected === 'month';

  return (
    <div
      css={{
        flexDirection: 'row',
        display: 'flex',
      }}
    >
      <div
        css={{
          borderLeft: daySelected ? selectedBorder : notSelected,
          borderTop: daySelected ? selectedBorder : notSelected,
          borderBottom: daySelected ? selectedBorder : notSelected,
          borderRight:
            daySelected || weekSelected ? selectedBorder : notSelected,
          borderRadius: '8px 0px 0px 8px',
          padding: '7px 16px',
          backgroundColor: daySelected ? Colors.grayLightest : 'transparent',
          cursor: 'pointer',
        }}
        onClick={() => onPress('day')}
      >
        <PrimaryText
          css={{ color: daySelected ? Colors.grayDarkest : Colors.gray }}
        >
          Day
        </PrimaryText>
      </div>
      <div
        css={{
          borderTop: weekSelected ? selectedBorder : notSelected,
          borderBottom: weekSelected ? selectedBorder : notSelected,
          padding: '7px 16px',
          backgroundColor: weekSelected ? Colors.grayLightest : 'transparent',
          cursor: 'pointer',
        }}
        onClick={() => onPress('week')}
      >
        <PrimaryText
          css={{ color: weekSelected ? Colors.grayDarkest : Colors.gray }}
        >
          Week
        </PrimaryText>
      </div>
      <div
        css={{
          borderRadius: '0px 8px 8px 0px',
          padding: '7px 16px',
          borderTop: monthSelected ? selectedBorder : notSelected,
          borderBottom: monthSelected ? selectedBorder : notSelected,
          borderRight: monthSelected ? selectedBorder : notSelected,
          borderLeft:
            weekSelected || monthSelected ? selectedBorder : notSelected,
          backgroundColor: monthSelected ? Colors.grayLightest : 'transparent',
          cursor: 'pointer',
        }}
        onClick={() => onPress('month')}
      >
        <PrimaryText
          css={{ color: monthSelected ? Colors.grayDarkest : Colors.gray }}
        >
          Month
        </PrimaryText>
      </div>
    </div>
  );
};

const ScheduleManagement = ({ history }: RouteComponentProps) => {
  // load staff home clinic

  const [selectedClinics, setSelectedClinics] = useLocalStorage<string[]>(
    'selectedClinics',
    ['ne-super1'] as string[],
  );

  const [deleteId, setDeleteId] = useState<string | undefined>();
  const [showCalendar, setShowCalendar] = useState(false);
  const [view, setView] = useState<View>('day');
  const today = DateTime.local().startOf('day');
  const [currentDate, setCurrentDate] = useState(today);
  const startOfView =
    view === 'week'
      ? currentDate.set({
          weekday: currentDate.weekday !== 7 ? 0 : undefined,
        })
      : currentDate.startOf(view as any);
  const endOfView =
    view === 'week'
      ? startOfView.plus({ days: 6 }).set({ hour: 24 })
      : currentDate.endOf(view as any);
  const { accessToken } = useAuth0();
  const isManager = hasRole('manager', accessToken);
  const context = { role: isManager ? 'manager' : 'assistant' };

  const startOfDayLoad = startOfView
    .startOf('day')
    .toSQL({ includeZone: false, includeOffset: false });
  const endOfDayLoad = endOfView
    .endOf('day')
    .toSQL({ includeZone: false, includeOffset: false });

  const { innerWidth } = useWindowSize();
  const condense = innerWidth < TABLET_WIDTH;
  const [showFilters, setShowFilters] = useState(false);

  const appointmentCountDaters = useAppointmentCounts({
    clinicCodes: selectedClinics!,
    start: startOfDayLoad,
    end: endOfDayLoad,
  });

  const { data: clinicData } = useLoadAllClinicsQuery({
    context,
    variables: {
      where: {
        clinicCode: {
          _in: selectedClinics,
        },
      },
    },
  });

  const appointmentCount =
    sumBy(appointmentCountDaters, ({ count }) => count ?? 0) ?? 0;
  const clinics = clinicData?.clinics ?? [];

  const [deleteScheduleStaff] = useDeleteStaffScheduleMutation({
    context,
  });

  const { data: staffData } = useLoadStaffAndServicesQuery({
    context,
    fetchPolicy: 'cache-and-network',
    variables: {
      order_by: [{ firstName: 'asc' }, { lastName: 'asc' }],
      where: {
        active: {
          _eq: true,
        },
      },
    },
  });

  // load if startTime is between 2 dates, and or end time is between them
  const { data, refetch } = useLoadStaffScheduleQuery({
    context,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        staff: {
          active: {
            _eq: true,
          },
        },
        _or: [
          {
            _and: [
              {
                startDateTime: {
                  _gte: startOfView.toSQL(),
                },
              },
              {
                startDateTime: {
                  _lte: endOfView.toSQL(),
                },
              },
            ],
          },
          {
            _and: [
              {
                endDateTime: {
                  _gte: startOfView.toSQL(),
                },
              },
              {
                endDateTime: {
                  _lte: endOfView.toSQL(),
                },
              },
            ],
          },
        ],
        clinic: {
          clinicCode: {
            _in: selectedClinics ?? [],
          },
        },
      },
    },
  });

  const handleScheduleDelete = async (scheduleId: string) => {
    await deleteScheduleStaff({
      variables: {
        staffScheduleId: scheduleId,
      },
    });
    setDeleteId(undefined);
    await refetch();
  };

  const handleScheduleEdit = (scheduleId: string) => {
    history.push(`/staff/schedule/edit/${scheduleId}`);
  };

  useEffect(() => {
    refetch();
  }, [history.location, refetch]);

  const staffTypes = staffData?.staff_type ?? [];
  const [selectedStaffTypes, setSelectedStaffTypes] = useState<string[]>([]);

  useEffect(() => {
    setSelectedStaffTypes(staffTypes.map((s) => s.value));
  }, [staffTypes]);

  const staffMembers = staffData?.staff ?? [];
  const [selectedStaffMembers, setSelectedStaffMembers] = useState<string[]>(
    [],
  );

  useEffect(() => {
    setSelectedStaffMembers(staffMembers.map((s) => s.id));
  }, [staffMembers]);

  const filteredStaffSchedule = data?.staff_schedule.filter((staffSchedule) => {
    return (
      (selectedStaffTypes.length
        ? selectedStaffTypes.includes(staffSchedule.staff.type)
        : true) &&
      (selectedStaffMembers.length
        ? selectedStaffMembers.includes(staffSchedule.staffId)
        : true)
    );
  });

  const single = appointmentCount?.[0]?.count ?? 0;

  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap>
          <Sidebar />
          <MainContent
            css={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <ScheduleHeader>
              <TitleRow>
                <LargeTitle style={{ marginRight: 16 }}>
                  Staff Schedule
                </LargeTitle>
              </TitleRow>
              <MobileWrapRow>
                <ClinicRegionDropdown
                  clinicCodes={selectedClinics as any as string[]}
                  onSelect={(nextClinicCodes) => {
                    setSelectedClinics(nextClinicCodes);
                  }}
                />
                {isManager && (
                  <OutlineButton
                    css={{
                      borderRadius: '100px',
                      [IF_TABLET]: {
                        marginLeft: 'auto',
                      },
                    }}
                    onClick={() =>
                      history.push('/staff/schedule/add', {
                        date: currentDate.toJSDate(),
                      })
                    }
                  >
                    <Icon src={ICONS.add} style={{ marginRight: 3 }} />
                    Schedule Staff
                  </OutlineButton>
                )}
              </MobileWrapRow>
            </ScheduleHeader>
            <ScheduleWrap>
              <StaffScheduleContent>
                <Controls>
                  <Row css={{ padding: '8px 0' }}>
                    <CalendarControls>
                      <CalendarControl
                        onClick={() =>
                          setCurrentDate(currentDate.minus({ [view]: 1 }))
                        }
                      >
                        <Prev />
                      </CalendarControl>
                      <CalendarControl
                        onClick={() =>
                          setCurrentDate(currentDate.plus({ [view]: 1 }))
                        }
                      >
                        <Next />
                      </CalendarControl>
                    </CalendarControls>
                    <FormattedDay css={{ marginLeft: 0, marginRight: 8 }}>
                      {condense
                        ? getDisplayDate(currentDate).shortDate
                        : getDisplayDate(currentDate).date}
                    </FormattedDay>
                    <Badge>
                      {appointmentCount}{' '}
                      {appointmentCount !== 1 ? 'visits' : 'visit'}
                    </Badge>
                  </Row>
                  <Row css={{ marginTop: 16, [IF_TABLET]: { marginTop: 0 } }}>
                    <DropdownButtonContainer
                      css={{
                        gap: '8px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 0,
                      }}
                    >
                      <div>
                        <SidebySelector
                          selected={view}
                          onPress={(view) => {
                            setView(view);
                          }}
                        />
                      </div>

                      <Filterable
                        onMouseEnter={() => setShowFilters(true)}
                        onMouseLeave={() => setShowFilters(false)}
                        onClick={() => setShowFilters(true)}
                        tabIndex={0}
                        type="button"
                        highlight={!!showFilters}
                      >
                        <Icon src={ICONS.filter} />
                        <FilterableText>Filters</FilterableText>

                        {showFilters && (
                          <div
                            css={[
                              FilterContainer,
                              {
                                display: 'flex',
                                flexWrap: 'wrap',
                                left: 0,
                                right: 'unset',
                                width: '300px',
                              },
                            ]}
                          >
                            <CheckboxFilter
                              title="Staff Type"
                              checkboxOptions={staffTypes.map((s) => ({
                                color: Colors.teal,
                                groupName: 'staffTypes',
                                label: s.description,
                                value: s.value,
                              }))}
                              onChange={(staffType) => {
                                setSelectedStaffTypes(
                                  xor(selectedStaffTypes, [staffType]),
                                );
                              }}
                              selectedOptions={selectedStaffTypes}
                              onToggleAll={() =>
                                selectedStaffTypes.length === 0
                                  ? setSelectedStaffTypes(
                                      staffTypes.map((s) => s.value),
                                    )
                                  : setSelectedStaffTypes([])
                              }
                              style={{ padding: 24 }}
                            />
                            <CheckboxFilter
                              style={{ padding: 24 }}
                              title="Staff Member"
                              checkboxOptions={staffMembers.map((s) => {
                                return {
                                  color: s.color || Colors.teal,
                                  groupName: 'staffMembers',
                                  label: `${s.firstName} ${s.lastName}`,
                                  value: s.id,
                                };
                              })}
                              onChange={(staffMember) => {
                                setSelectedStaffMembers(
                                  xor(selectedStaffMembers, [staffMember]),
                                );
                              }}
                              selectedOptions={selectedStaffMembers}
                              onToggleAll={() =>
                                selectedStaffMembers.length === 0
                                  ? setSelectedStaffMembers(
                                      staffMembers.map((s) => s.id),
                                    )
                                  : setSelectedStaffMembers([])
                              }
                            />
                          </div>
                        )}
                      </Filterable>
                      <Filterable
                        onMouseEnter={() => setShowCalendar(true)}
                        onMouseLeave={() => setShowCalendar(false)}
                        onClick={() => setShowCalendar(true)}
                        tabIndex={0}
                        type="button"
                        highlight={!!showCalendar}
                      >
                        <FilterableText>Calendar</FilterableText>
                        {showCalendar && (
                          <CalendarSelector
                            value={currentDate.toJSDate()}
                            onChange={(value) => {
                              setCurrentDate(DateTime.fromJSDate(value));
                            }}
                            css={[
                              FilterContainer,
                              {
                                padding: '24px 16px',
                              },
                            ]}
                          />
                        )}
                      </Filterable>
                    </DropdownButtonContainer>
                  </Row>
                </Controls>
                {view === 'day' && (
                  <DayView
                    schedules={filteredStaffSchedule ?? []}
                    handleScheduleDelete={handleScheduleDelete}
                    handleScheduleEdit={handleScheduleEdit}
                    isManager={true}
                    appointmentCounts={appointmentCountDaters ?? []}
                    clinics={clinics}
                    date={currentDate}
                  />
                )}
                {view === 'week' && (
                  <WeekView
                    date={currentDate.toJSDate()}
                    staffSchedules={filteredStaffSchedule}
                    appointmentCounts={appointmentCountDaters ?? []}
                    onSelect={(schedule) =>
                      history.push(`/staff/schedule/${schedule.id}`)
                    }
                    clinics={clinics}
                  />
                )}
                {view === 'month' && (
                  <MonthView
                    date={currentDate.toJSDate()}
                    staffSchedules={filteredStaffSchedule}
                    appointmentCounts={appointmentCountDaters ?? []}
                    onSelect={(schedule) =>
                      history.push(`/staff/schedule/${schedule.id}`)
                    }
                    clinics={clinics}
                  />
                )}
              </StaffScheduleContent>
            </ScheduleWrap>
          </MainContent>
        </InnerWrap>
      </Content>
      <StaffScheduleRemovalConfirmation
        isOpen={!!deleteId}
        onCancel={() => {
          setDeleteId(undefined);
        }}
        onRemove={() => {
          if (deleteId) {
            handleScheduleDelete(deleteId);
          }
        }}
      />
    </Fragment>
  );
};

export default ScheduleManagement;
