import { useLoadStaffAndServicesQuery } from '@bc/codegen/manager';
import {
  Chat_Notes_Types_Enum,
  useChatMessagesSubscription,
  useChatSessionSubscription,
  useCreateChatNoteMutation,
  useInsertChatSessionStaffMutation,
  useLeaveChatMutation,
  useRejoinChatMutation,
  useSendChatMessageMutation,
  useUpdateChatSessionStatusMutation,
  useUpdateReadReceiptMutation,
} from '@bc/codegen/medical';
import { useAuth0 } from '../react-auth0';

export const useAsyncChat = (chatSessionId: string) => {
  const { user } = useAuth0();
  const { data: staffData } = useLoadStaffAndServicesQuery({
    variables: {
      where: {
        auth0UserId: {
          _eq: user.sub,
        },
      },
    },
    skip: !user.sub,
  });
  const staff = staffData?.staff?.[0];
  const staffId = staff?.id;
  const staffType = staffData?.staff_type?.[0];

  const [createChatNote] = useCreateChatNoteMutation();
  const [doLeaveChat] = useLeaveChatMutation();
  const [doRejoinChat] = useRejoinChatMutation();
  const [insertChatMessage] = useSendChatMessageMutation();
  const [insertReadReceipt] = useUpdateReadReceiptMutation();
  const [insertStaff] = useInsertChatSessionStaffMutation();
  const [updateChatStatus] = useUpdateChatSessionStatusMutation();

  const { data: chatSessionData } = useChatSessionSubscription({
    variables: {
      id: chatSessionId,
    },
    skip: !chatSessionId,
  });

  const { data } = useChatMessagesSubscription({
    variables: {
      where: {
        chatSessionId: {
          _eq: chatSessionId,
        },
      },
    },
    skip: !chatSessionId,
  });

  const messages = data?.chat_messages ?? [];
  const chatSession = chatSessionData?.chat_sessions_by_pk;
  const chatSummary = chatSession?.chat_notes?.filter(
    (n) => n.type === 'SUMMARY',
  )?.[0];

  const closeChat = async () => {
    await updateChatStatus({
      variables: {
        id: chatSessionId,
        status: 'CLOSED',
      },
    });

    return insertChatMessage({
      variables: {
        message: {
          chatSessionId,
          data: { action: 'STAFF_ENDED' },
          message: `ended the chat.`,
          staffId,
          type: 'SYSTEM',
        },
      },
    });
  };

  const requestProvider = async () => {
    await updateChatStatus({
      variables: {
        id: chatSessionId,
        status: 'READY_FOR_PROVIDER',
      },
    });

    return insertChatMessage({
      variables: {
        message: {
          chatSessionId,
          data: { action: 'STAFF_REQUESTED_PROVIDER' },
          message: `requested a provider to join the chat.`,
          staffId,
          type: 'SYSTEM',
        },
      },
    });
  };

  const joinChat = async () => {
    const didPreviouslyJoin = !!chatSession?.chat_sessions_staffs.find(
      (s) => s.staffId === staffId,
    );

    if (didPreviouslyJoin) {
      await doRejoinChat({ variables: { chatSessionId, staffId: staffId! } });
    } else {
      await insertStaff({
        variables: {
          chatSessionStaff: {
            chatSessionId,
            staffId,
          },
        },
      });
    }

    return insertChatMessage({
      variables: {
        message: {
          chatSessionId,
          data: { action: 'STAFF_JOINED' },
          message: `joined the chat`,
          staffId,
          type: 'SYSTEM',
        },
      },
    });
  };

  const leaveChat = async () => {
    await doLeaveChat({ variables: { chatSessionId, staffId: staffId! } });

    return insertChatMessage({
      variables: {
        message: {
          chatSessionId,
          data: { action: 'STAFF_LEFT' },
          message: `left the chat`,
          staffId,
          type: 'SYSTEM',
        },
      },
    });
  };

  const sendChatMessage = async (message: string) => {
    return insertChatMessage({
      variables: {
        message: {
          message,
          type: 'MESSAGE',
          chatSessionId,
          staffId,
        },
      },
    });
  };

  type ChatActionType =
    | 'REQUEST_FILE'
    | 'SCHEDULE_APPOINTMENT'
    | 'SCHEDULE_PRIMARY'
    | 'SCHEDULE_URGENT';
  const requestAction = async (action: ChatActionType) => {
    let message = '';
    if (action === 'REQUEST_FILE') {
      message += 'requested a photo or video.';
    }
    if (action === 'SCHEDULE_APPOINTMENT') {
      message += 'recommended scheduling a visit (general).';
    }
    if (action === 'SCHEDULE_PRIMARY') {
      message += 'recommended scheduling a well check visit.';
    }
    if (action === 'SCHEDULE_URGENT') {
      message += 'recommended scheduling an urgent care visit.';
    }

    return insertChatMessage({
      variables: {
        message: {
          accountId: chatSession?.chat_sessions_accounts[0].accountId,
          chatSessionId,
          message,
          request: { type: action },
          staffId,
          type: 'ACTION',
        },
      },
    });
  };

  const updateReadReceipt = async () => {
    if (!staffId) {
      return;
    }
    return insertReadReceipt({
      variables: {
        read: {
          chatSessionId,
          lastViewed: 'now()',
          staffId,
        },
      },
    });
  };

  const writeChatNote = async (
    note: string,
    type: Chat_Notes_Types_Enum = 'INTERNAL',
  ) => {
    if (!staffId) {
      return;
    }
    return createChatNote({
      variables: {
        chatNote: {
          chatSessionId,
          staffId,
          note,
          type,
        },
      },
    });
  };

  return {
    chatSession,
    chatSummary,
    closeChat,
    currentStaffData: {
      staff,
      staffId,
      staffType,
    },
    joinChat,
    leaveChat,
    messages,
    requestAction,
    requestProvider,
    sendChatMessage,
    updateReadReceipt,
    writeChatNote,
  };
};
