/** @jsx jsx */
import {
  ChargeFragment,
  useGetPaymentMethodByChargeIdQuery,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import { DialogOverlay } from '@reach/dialog';
import { DateTime } from 'luxon';
import React, { Fragment, useState } from 'react';
import { ICONS } from '../../assets';
import { REASONS } from '../Billing';
import { OutlineButton } from '../button';
import { Check, Undo } from '../Icons';
import { ChargeEntry } from './ChargeEntry';
import {
  ModalClose,
  ModalContent,
  ModalTitle,
  SmallModal,
  TimelineSegment,
} from './styles';

const formatMoney = (amount: number) => {
  return new Intl.NumberFormat('EN-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
};

const ChargeLogModal = ({
  charge,
  isOpen,
  onClose,
}: {
  charge: ChargeFragment;
  isOpen: boolean;
  onClose: (e?: React.SyntheticEvent<Element, Event>) => void;
}) => {
  const { data } = useGetPaymentMethodByChargeIdQuery({
    variables: { stripeChargeId: charge.stripeChargeId },
    fetchPolicy: 'cache-and-network',
    skip: !charge.stripeChargeId,
  });

  const stripeCharge = data?.StripePaymentMethodByChargeId;
  const refunds = stripeCharge?.refunds?.data ?? [];

  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={onClose}
      style={{
        zIndex: 100,
      }}
    >
      <SmallModal aria-label="Activity Log">
        <ModalClose
          onClick={onClose}
          css={{ position: 'absolute', top: 24, right: 24 }}
        />
        <ModalTitle>Activity Log</ModalTitle>
        <ModalContent css={{ position: 'relative' }}>
          <TimelineSegment
            css={{
              position: 'absolute',
              top: 20,
              left: 15,
              height: 'calc(100% - 40px)',
              margin: 0,
            }}
          />
          {refunds.map((r) => (
            <ChargeEntry
              reason={REASONS.find((sr) => sr.value === r.reason)?.label ?? ''}
              note={r.metadata?.note ?? undefined}
              key={r.created}
              title={`${formatMoney(r.amount / 100)} refunded`}
              timestamp={DateTime.fromSeconds(r.created).toFormat('ff')}
            >
              <Undo
                color={Colors.orange}
                backgroundColor={Colors.orangeLighter}
                css={{ border: '4px solid white' }}
                size={32}
              />
            </ChargeEntry>
          ))}
          <ChargeEntry
            title="Payment succeeded"
            timestamp={DateTime.fromISO(charge.created_at).toFormat('ff')}
          >
            <Check
              color={Colors.green}
              backgroundColor={Colors.greenLighter}
              css={{ border: '4px solid white' }}
              size={32}
            />
          </ChargeEntry>
          <ChargeEntry
            title="Payment started"
            timestamp={(stripeCharge?.created
              ? DateTime.fromSeconds(stripeCharge?.created)
              : DateTime.fromISO(charge.created_at)
            ).toFormat('ff')}
          >
            <img
              src={ICONS.dollar}
              css={{
                border: '4px solid white',
                height: 32,
                width: 32,
                background: 'white',
              }}
            />
          </ChargeEntry>
        </ModalContent>
      </SmallModal>
    </DialogOverlay>
  );
};

export const ChargeAuditLog: React.FC<{
  charge: ChargeFragment;
  size?: number;
}> = ({ charge, size = 32 }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <OutlineButton
        css={{
          height: size,
          padding: size < 32 ? 0 : '8px 6px',
          width: size,

          img: {
            height: size < 32 ? 8 : 'auto',
          },
        }}
        icon="auditLog"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
      />
      <ChargeLogModal
        isOpen={isOpen}
        onClose={(e) => {
          e?.preventDefault();
          setIsOpen(false);
        }}
        charge={charge}
      />
    </Fragment>
  );
};
