/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Avatar, AccountAvatar } from '../ChatMessage';
import {
  AvatarContainer,
  Caret,
  Container,
  EmphasizedTitle,
  Ended,
  IconContainer,
  TextContainer,
  Title,
  InnerContainer,
  Row,
} from './ChatRow.styles';
import { PrimaryText } from '../text';
import { DateTime } from 'luxon';
import CaretIcon from '../../assets/caret.svg';
import truncate from 'lodash/truncate';

export const ClosedChatRow: React.FC<{
  chatSession: any;
  onClick?: () => void;
}> = ({ chatSession, onClick }) => {
  const {
    chat_notes,
    chat_sessions_accounts,
    chat_sessions_patients,
    chat_sessions_staffs,
    updatedAt,
  } = chatSession;
  const account = chat_sessions_accounts?.[0]?.account;
  const patient = chat_sessions_patients?.[0]?.patient;
  const accountName = `${account?.goesByName || account?.firstName} ${
    account?.lastName
  }`;
  const patientName = `${patient?.goesByName || patient?.firstName} ${
    patient?.lastName
  }`;
  const staffs = chat_sessions_staffs.filter((s: any) => s.active);

  const chatSummary = chat_notes.filter((n: any) => n.type === 'SUMMARY')[0];
  const subtitleText = truncate(chatSummary?.note ?? '', {
    length: 60,
  });

  const endedAt = DateTime.fromISO(updatedAt);
  const formattedEndedAt = endedAt.hasSame(DateTime.local(), 'day')
    ? endedAt.toFormat('h:mm a')
    : endedAt.hasSame(DateTime.local().minus({ day: 1 }), 'day')
    ? endedAt.toFormat("'Yesterday at' h:mm a")
    : endedAt.toFormat("D 'at' h:mm a");

  return (
    <Container onClick={onClick}>
      <InnerContainer>
        <Row css={{ flex: 1 }}>
          <AccountAvatar accountId={account?.id} size={48} />
          <TextContainer>
            <Title>
              <EmphasizedTitle>{accountName}</EmphasizedTitle>
              {!!patient && ' about '}
              {!!patient && <EmphasizedTitle>{patientName}</EmphasizedTitle>}
            </Title>
            <PrimaryText>{subtitleText}</PrimaryText>
          </TextContainer>
        </Row>
        <Row>
          <IconContainer>
            {staffs?.length > 0 && (
              <AvatarContainer>
                {staffs?.map((s: any, i: any) => {
                  const staff = s.staff;
                  return (
                    <Avatar
                      key={staff?.id}
                      title={`${staff?.firstName} ${staff?.lastName}`}
                      src={
                        staff?.avatarUrl
                          ? `${staff?.avatarUrl}?w=96&h=96`
                          : 'https://bravecare.imgix.net/staff/empty.png?w=96&h=96'
                      }
                      css={{
                        height: 48,
                        width: 48,
                        border: '1px solid white',
                        borderRadius: 24,
                        marginRight: i === staffs?.length - 1 ? 0 : '-8px',
                        zIndex: staffs?.length - 1 - i,
                      }}
                    />
                  );
                })}
              </AvatarContainer>
            )}
            <Ended>Chat ended {formattedEndedAt}</Ended>
          </IconContainer>
        </Row>
      </InnerContainer>
      <Caret src={CaretIcon} />
    </Container>
  );
};
