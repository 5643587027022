import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { DialogContent } from '@reach/dialog';
import { IF_TABLET } from '../../styles';
import { CloseButton } from '../button';
import { PrimaryText } from '../text';

export const SmallModal = styled(DialogContent)({
  borderRadius: 16,
  boxShadow:
    '0px 16px 24px rgba(0, 0, 0, 0.13), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  margin: '10vh auto 0 auto',
  maxHeight: '80vh',
  overflowY: 'scroll',
  padding: 24,
  position: 'relative',
  width: '90%',
  [IF_TABLET]: {
    padding: 40,
    maxWidth: 454,
    width: '100%',
  },
});

export const ModalTitle = styled(PrimaryText)({
  fontWeight: 'bold',
  fontSize: 28,
  lineHeight: '34px',
  marginBottom: 24,
}).withComponent('h3');

export const ModalClose = styled(CloseButton)({
  border: 'none',
  ':focus': {
    background: 'white',
    border: 'none',
  },
  ':hover': {
    background: 'white',
    border: 'none',
  },
});

export const ModalContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const Row = styled.div({
  display: 'flex',
});

export const Avatar = styled.img({
  height: 32,
  width: 32,
  borderRadius: 16,
  marginRight: 8,
  objectFit: 'contain',
});

export const EntryText = styled(PrimaryText)({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
});

export const Timestamp = styled(PrimaryText)({
  color: '#94a2a1',
  display: 'block',
  fontSize: 12,
  lineHeight: '14px',
});

export const TimelineSegment = styled.div({
  backgroundColor: Colors.grayLighter,
  borderRadius: 100,
  height: 24,
  margin: '8px 13.5px',
  width: 3,
});
