import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { FONT_PRIMARY } from '../../styles';

export const Table = styled.table<{ condense?: boolean }>`
  border-collapse: collapse;
  ${FONT_PRIMARY};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1;
  text-align: left;
  vertical-align: middle;
  width: 100%;
  ${({ condense }) =>
    condense &&
    `
  thead, tbody, tr {
    display: block;
  }
  
  th {
    display: none;
  }

  tr {
    border-bottom: 1px solid ${Colors.grayLighter};
  }

  td {
    padding: 16px 8px 16px 40% !important;
    display: block;
    border: none;
    position: relative;
    ::before {
      content: attr(data-label);
      position: absolute;
      top: auto;
      left: 8px;
      padding: 0 0 0 8px;
      color: ${Colors.gray};
      max-width: calc(40% - 24px);
      overflow-wrap: break-word;
    }
  }
  `}
`;

export const TableHead = styled.thead``;
export const TableBody = styled.tbody``;
export const TableRow = styled.tr``;
export const TableCell = styled.td<{
  condense?: boolean;
  alignRight?: boolean;
}>`
  border-bottom: 1px solid ${Colors.grayLighter};
  color: ${Colors.darkHarbor};
  line-height: 17px;
  padding: ${({ condense }) => (condense ? '16px 8px' : '24px 8px')};
  vertical-align: middle;
  ${({ alignRight }) => alignRight && 'text-align: right;'}

  :last-of-type {
    padding-right: 8px;
  }
`;
export const TableHeadCell = styled.th<{ alignRight?: boolean }>`
  border-bottom: 1px solid ${Colors.grayLighter};
  border-top: 1px solid ${Colors.grayLighter};
  font-weight: 500;
  letter-spacing: 0.1px;
  color: ${Colors.darkHarbor};
  padding: 8px;
  white-space: nowrap;
  ${({ alignRight }) => alignRight && 'text-align: right;'}
`;
