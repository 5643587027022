import { StaffFragment, useLoadStaffQuery } from '@bc/codegen/manager';
import Downshift from 'downshift';
import { matchSorter } from 'match-sorter';
import React, { useEffect, useState } from 'react';
import { Input, InputWrap } from '../form';
import { ReactComponent as Clear } from './icons/close.svg';
import {
  Container,
  Result,
  ResultDropdownContainer,
  ResultsContainer,
} from './ui';

const fuzzyFilter = (
  input: string,
  clinics: StaffFragment[],
): StaffFragment[] => {
  return matchSorter(clinics, input, {
    keys: ['firstName', 'lastName', 'email'],
  });
};

interface Props {
  value?: string | undefined | null;
  onChange: (value: StaffFragment | null) => void;
  resetOnSelect?: boolean;
  disabled?: boolean;
  regionIds?: number[];
}

export const RegionLeadSearch = ({
  onChange,
  value,
  disabled = false,
  regionIds,
}: Props) => {
  const [inputValue, setFilterValue] = useState('');
  const { data } = useLoadStaffQuery({
    skip: disabled,
    variables: {
      where: {
        lead: {
          _eq: true,
        },
        active: {
          _eq: true,
        },
        staff_regions: {
          regionId: {
            _in: regionIds,
          },
        },
      },
    },
  });

  const staffs = data?.staff ?? [];

  const handleChange = (item: StaffFragment | null) => {
    onChange(item);
  };

  const selectedValue = staffs.find(({ id }) => {
    return id === value;
  });

  useEffect(() => {
    if (selectedValue) {
      setFilterValue(`${selectedValue.firstName} ${selectedValue.lastName}`);
    } else {
      setFilterValue('');
    }
  }, [staffs, selectedValue]);

  return (
    <Downshift
      inputValue={inputValue}
      itemToString={(item) =>
        item ? `${item.firstName} ${item.lastName}` : ''
      }
      onInputValueChange={(value) => {
        setFilterValue(value);
      }}
      onSelect={handleChange}
    >
      {({
        getRootProps,
        getInputProps,
        getItemProps,
        isOpen,
        openMenu,
        closeMenu,
        highlightedIndex,
        clearSelection,
      }) => {
        return (
          <Container {...getRootProps()}>
            <ResultsContainer>
              <div>
                <InputWrap style={{ marginBottom: 0 }}>
                  <Input
                    {...getInputProps({
                      onFocus: () => {
                        openMenu();
                      },
                      disabled: disabled,
                      type: 'text',
                      placeholder: 'Find Manager',
                      autoComplete: 'off',
                      onBlur: () => {
                        closeMenu();
                        if (!inputValue) {
                          handleChange(null);
                        }
                      },
                    })}
                    error={false}
                  />
                  {!!value && (
                    <Clear
                      style={{
                        width: '14px',
                        height: '14px',
                        position: 'absolute',
                        right: '20px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        clearSelection();
                        onChange(null);
                        openMenu();
                      }}
                    />
                  )}
                </InputWrap>
              </div>
              {isOpen && (
                <ResultDropdownContainer>
                  <div role="menu">
                    <div>
                      {fuzzyFilter(inputValue, staffs).map(
                        (staffMember, index: number) => (
                          <Result
                            href="#"
                            highlighted={highlightedIndex === index}
                            {...getItemProps({
                              item: staffMember,
                              index,
                              key: staffMember.id,
                              onClick: (e: any) => e.preventDefault(),
                            })}
                          >
                            <span>{`${staffMember.firstName} ${staffMember.lastName}`}</span>
                          </Result>
                        ),
                      )}
                    </div>
                  </div>
                </ResultDropdownContainer>
              )}
            </ResultsContainer>
          </Container>
        );
      }}
    </Downshift>
  );
};
