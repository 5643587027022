import Downshift from 'downshift';
import React, { Fragment, useEffect, useState } from 'react';
import { Location } from '../../types';
import { ErrorField, Input, InputWrap } from '../form';
import LocationResults, { ClearIcon, Container, ResultsContainer } from './ui';
import { useAddressSuggestions } from './useAddressSuggestions';

interface Props {
  onChange: (item?: Location) => void;
  value?: Location;
  error: boolean;
  name?: string;
}

const SearchInput: React.FC<Props> = ({ onChange, value, error, name }) => {
  const [inputValue, setInputValue] = useState(value?.formatted_address ?? '');

  const { predictions, getPlace, place } = useAddressSuggestions(
    inputValue || '',
  );

  const itemToString = (item: Location | null) => {
    if (!item) return '';
    return item.formatted_address ?? '';
  };

  const placeId = place?.FindPlace?.place_id;
  const formattedAddress = place?.FindPlace?.formatted_address;
  useEffect(() => {
    if (placeId && formattedAddress) {
      onChange({
        place_id: placeId,
        formatted_address: formattedAddress,
        latitude: String(place?.FindPlace?.geometry?.location?.lat),
        longitude: String(place?.FindPlace?.geometry?.location?.lng),
        address_components: place?.FindPlace.address_components ?? undefined,
      });
      setInputValue(formattedAddress);
    }
  }, [placeId, formattedAddress]);

  const handleLocationChange = async (location: Location | null) => {
    if (location?.place_id) {
      await getPlace({
        variables: {
          placeId: location.place_id,
        },
      });
    }
  };

  return (
    <Fragment>
      <Downshift
        onChange={handleLocationChange}
        itemToString={itemToString}
        inputValue={inputValue}
        onInputValueChange={(value) => {
          onChange({
            formatted_address: value,
          });
          setInputValue(value);
        }}
      >
        {({
          getRootProps,
          getInputProps,
          getItemProps,
          highlightedIndex,
          isOpen,
          openMenu,
          closeMenu,
        }) => {
          return (
            <Container {...getRootProps()}>
              <ResultsContainer>
                <div>
                  <InputWrap style={{ marginBottom: 0 }}>
                    <Input
                      {...getInputProps({
                        onFocus: openMenu,
                        type: 'text',
                        placeholder: 'Enter address',
                        autocomplete: 'off',
                        name: name,
                        onBlur: () => {
                          closeMenu();
                        },
                      })}
                      error={error}
                    />
                    {!!value && (
                      <ClearIcon
                        onClick={() => {
                          setInputValue('');
                          onChange(undefined);
                        }}
                      />
                    )}
                  </InputWrap>
                </div>
                {isOpen && (
                  <LocationResults
                    inputValue={inputValue}
                    highlightedIndex={highlightedIndex}
                    getItemProps={getItemProps}
                    predictions={predictions}
                  />
                )}
              </ResultsContainer>
            </Container>
          );
        }}
      </Downshift>
      {error && name && <ErrorField name={name} />}
    </Fragment>
  );
};

export default SearchInput;
