/** @jsx jsx */
import { StaffFragment } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { Column, IF_TABLET, Row } from '../../styles';
import { LargeTitle } from '../text';

const Image = styled.img({
  backgroundColor: '#8e949d',
  border: `2px solid ${Colors.grayLighter}`,
  borderRadius: '50%',
  height: 64,
  width: 64,
  [IF_TABLET]: {
    height: 120,
    width: 120,
  },
});

export const StaffHeader = ({
  children,
  className,
  staff,
}: {
  children?: React.ReactNode;
  className?: string;
  staff?: StaffFragment;
}) => {
  if (!staff) return null;

  const fullName = `${staff.firstName} ${staff.lastName}`;

  return (
    <Row className={className}>
      <Image src={staff.avatarUrl ?? ''} />
      <Column css={{ marginLeft: 16 }}>
        <LargeTitle css={{ margin: '0 8px 8px 0' }}>{fullName}</LargeTitle>
        {children}
      </Column>
    </Row>
  );
};
