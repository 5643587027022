/** @jsx jsx */
import { useAppointmentDataSubscription } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React from 'react';
import ExpandingTextarea from 'react-expanding-textarea';
import { Textarea } from '../../components/form';
import { PrimaryText } from '../../components/text';
import { IF_TABLET } from '../../styles';

export const ChatInputBar = styled.div({
  alignItems: 'center',
  background: 'white',
  display: 'flex',
  justifyContent: 'center',
  paddingLeft: 24,
  paddingRight: 24,
  position: 'relative',
  marginBottom: 64,
  [IF_TABLET]: {
    marginBottom: 0,
    paddingLeft: 40,
    paddingRight: 40,
  },
});

export const ChatTextarea = styled(Textarea)({
  borderRadius: 32,
  display: 'flex',
  flex: 1,
  height: 56,
  margin: '16px 0',
  padding: '16px 56px',
}).withComponent(ExpandingTextarea);

export const ChatStatusWarning = styled(PrimaryText)({
  alignItems: 'center',
  backgroundColor: Colors.grayLightest,
  borderRadius: 32,
  display: 'flex',
  flex: 1,
  fontWeight: 'bold',
  fontSize: 14,
  lineHeight: '18px',
  margin: '16px 0',
  padding: '16px 16px 16px 32px',
  [IF_TABLET]: {
    height: 56,
    fontSize: 16,
    lineHeight: '19px,',
  },
});

export const ChatActionsBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

export const ChatActions = styled.button({
  alignItems: 'center',
  appearance: 'none',
  background: Colors.white,
  border: 'none',
  borderRadius: 20,
  bottom: 24,
  cursor: 'pointer',
  display: 'flex',
  height: 40,
  justifyContent: 'center',
  outline: 'none',
  overflow: 'visible',
  position: 'absolute',
  width: 40,
});

export const ChatSendButton = styled(ChatActions)({
  background: Colors.teal,
  ':disabled': {
    background: Colors.grayLight,
  },
});

export const RequestActionsTray = styled.div`
  background: white;
  border-radius: 13px;
  border: 1px solid ${Colors.grayLightest};
  bottom: 60px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
  padding: 24px;
  position: absolute;
  transform: translateX(40px);
  z-index: 999;
`;

export const RequestActionHeading = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
});

export const RequestActionTitle = styled(PrimaryText)({
  fontWeight: 'bold',
  marginLeft: 16,
});

export const RequestAction = styled(PrimaryText)({
  appearance: 'none',
  background: Colors.white,
  border: 'none',
  cursor: 'pointer',
  display: 'block',
  fontSize: 16,
  lineHeight: '22px',
  marginLeft: 32,
  padding: 8,
}).withComponent('button');

export const SystemMessage = styled.div({
  border: `1px solid ${Colors.grayLighter}`,
  alignItems: 'center',
  alignSelf: 'center',
  borderRadius: 100,
  display: 'flex',
  marginBottom: 32,
  padding: '8px 16px',
});

export const SystemMessageIcon = styled.img({
  height: 16,
  width: 16,
});

export const AppointmentConfirmation: React.FC<{
  appointmentId: string;
  onClick: () => void;
  caregiverFirstName: string;
}> = ({ appointmentId, onClick, caregiverFirstName }) => {
  const { data } = useAppointmentDataSubscription({
    variables: {
      id: appointmentId,
    },
    skip: !appointmentId,
  });

  if (!data?.appointments_by_pk) {
    return null;
  }

  const startTime = DateTime.fromISO(data?.appointments_by_pk?.startTime);
  const date = startTime.toLocaleString(DateTime.DATE_HUGE);
  const time = startTime.toLocaleString(DateTime.TIME_SIMPLE);

  const isCanceled = data?.appointments_by_pk?.status === 'CANCELED';
  const verbPhrase = isCanceled ? 'canceled their' : 'scheduled an';

  return (
    <SystemMessage
      onClick={onClick}
      css={{
        cursor: 'pointer',
        [':hover']: {
          textDecoration: 'underline',
        },
      }}
    >
      <PrimaryText>
        {caregiverFirstName} {verbPhrase} appointment on {date} at {time}.
      </PrimaryText>
    </SystemMessage>
  );
};
