/** @jsx jsx */
import { useLoadStaffQuery } from '@bc/codegen/manager';
import {
  AppointmentPartsFragment,
  useCreateKareoAppointmentMutation,
} from '@bc/codegen/medical';
import {
  APPOINTMENT_TYPES,
  VISIT_CLASSIFICATIONS,
  VISIT_TYPES,
} from '@bc/shared';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import * as Sentry from '@sentry/react';
import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { object, string } from 'yup';
import { ICONS } from '../../assets';
import { Button, TextButton } from '../button';
import { Label, NonEmptyErrorField, SelectSimpleSearchField } from '../form';
import { DefinitionList, DefinitionListItem } from '../List';
import { ErrorMessageWrapper } from '../Payments/Payments.styles';
import { PrimaryText, SmallTitle } from '../text';

const VisitValidation = object().shape({
  kareoClinic: string().required('Required'),
  kareoType: string().required('Required'),
  kareoProvider: string().required('Required'),
});

interface KareoAppointmentCreate {
  kareoClinic: string;
  kareoType: string;
  kareoProvider: string;
}

const ClinicMap: Record<string, string> = {
  'NE Portland': 'NEPO - Brave Care',
  Sellwood: 'Sellwood Clinic - Brave Care',
  'COVID-19 Vaccine at Sellwood': 'Sellwood Clinic - Brave Care',
  'COVID-19 Testing at Sellwood': 'Sellwood Clinic - Brave Care',
  'Cedar Hills': 'Cedar Hills - Brave Care',
  default: 'NEPO - Brave Care',
};

const KAREO_CLINICS = [
  { value: 'NEPO - Brave Care', label: 'NEPO - Brave Care' },
  {
    value: 'Sellwood Clinic - Brave Care',
    label: 'Sellwood Clinic - Brave Care',
  },
  {
    value: 'Cross Creek - Brave Care',
    label: 'Cross Creek - Brave Care',
  },
  {
    value: 'Circle C - Brave Care',
    label: 'Circle C - Brave Care',
  },
  {
    value: 'Cedar Hills - Brave Care',
    label: 'Cedar Hills - Brave Care',
  },
];

const KAREO_APPOINTMENT_TYPES = [
  { value: 'Telehealth Visit', label: 'Telehealth Visit' },
  { value: 'Urgent Care - WALK IN', label: 'Urgent Care - WALK IN' },
  { value: 'Urgent Care - SCHEDULED', label: 'Urgent Care - SCHEDULED' },
  { value: 'PCP Visit', label: 'PCP Visit' },
  { value: 'Office Visit', label: 'Office Visit' },
  { value: 'Circumcision', label: 'Circumcision' },
  { value: 'Pap', label: 'Pap' },
  { value: 'F/u visit', label: 'F/u visit' },
  { value: 'Vaccination', label: 'Vaccination' },
  { value: 'Well-Exam', label: 'Well-Exam' },
  { value: 'Other', label: 'Other' },
  { value: 'Results review', label: 'Results review' },
  { value: 'Travel visit', label: 'Travel visit' },
  { value: 'f/u HM', label: 'f/u HM' },
  { value: 'Paperwork only', label: 'Paperwork only' },
  { value: 'Testing', label: 'Testing' },
  { value: 'Counseling', label: 'Counseling' },
  { value: 'Paperwork exam', label: 'Paperwork exam' },
  { value: 'Sports Physical', label: 'Sports Physical' },
];

const getKareoVisitType = (appointment: AppointmentPartsFragment) => {
  if (appointment.visitClassification === 'COVID_19_TEST') {
    return 'Office Visit';
  }

  if (appointment.visitType === 'TELEMEDICINE') {
    return 'Telehealth Visit';
  }

  if (appointment.visitType === 'PRIMARY_CARE') {
    return 'PCP Visit';
  }

  if (appointment.visitClassification === 'SPORTS_PHYSICAL') {
    return 'Sports Physical';
  }

  if (appointment.visitType === 'VACCINATION') {
    return 'Vaccination';
  }

  if (
    appointment.visitType === 'URGENT_CARE' &&
    appointment.type === 'WALKIN'
  ) {
    return 'Urgent Care - WALK IN';
  }

  if (
    appointment.visitType === 'URGENT_CARE' &&
    appointment.type !== 'WALKIN'
  ) {
    return 'Urgent Care - SCHEDULED';
  }

  return '';
};

const KareoAppointmentReview = ({
  appointmentId,
  appointment,
  onCancel,
  onSuccess,
}: {
  appointmentId: string;
  appointment: AppointmentPartsFragment;
  onCancel: () => void;
  onSuccess: () => void;
}) => {
  const [createKareoAppointment, { loading: appointmentLoading, error }] =
    useCreateKareoAppointmentMutation();

  const { data, loading } = useLoadStaffQuery({
    variables: {
      where: {
        active: {
          _eq: true,
        },
        type: {
          _in: ['PHYSICIAN', 'PHYSICIAN_ASSISTANT', 'NURSE_PRACTITIONER'],
        },
      },
      order_by: {
        firstName: 'asc',
      },
    },
  });

  const staffNames =
    data?.staff?.map(({ firstName, lastName }) => {
      const name = `${firstName} ${lastName}`;
      return { value: name, label: name };
    }) ?? [];

  const handleCreateKareoAppointment = async (
    values: KareoAppointmentCreate,
  ) => {
    try {
      await createKareoAppointment({
        variables: {
          appointmentId: appointmentId,
          kareoClinic: values.kareoClinic,
          kareoType: values.kareoType,
          kareoProvider: values.kareoProvider,
        },
      });
      onSuccess();
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  const physicians = appointment?.appointment_staffs
    ?.filter((appStaff) => {
      return [
        'PHYSICIAN',
        'PHYSICIAN_ASSISTANT',
        'NURSE_PRACTITIONER',
      ].includes(appStaff?.staff?.type);
    })
    .map(({ staff }) => {
      return staff;
    });

  const startOfVisit = DateTime.fromISO(appointment.startTime);
  const formattedStartDate = startOfVisit.toFormat('MM/dd/yyyy');
  const formattedStartTime = startOfVisit.toFormat('h:mm a');
  const endOfVisit = DateTime.fromISO(appointment.endTime);
  const formattedEndTime = endOfVisit.toFormat('h:mm a ZZZZ');
  const careTypeTitle = appointment?.visitClassification
    ? VISIT_CLASSIFICATIONS[appointment.visitClassification]
    : appointment?.visitType
    ? VISIT_TYPES[appointment.visitType]
    : '';
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${Colors.grayLighter}`,
          paddingBottom: '18px',
        }}
      >
        <SmallTitle>Review Appointment Details</SmallTitle>
        <TextButton type="button" onClick={onCancel}>
          Cancel
        </TextButton>
      </div>
      <div
        style={{
          padding: '24px 0',
        }}
      >
        <PrimaryText>
          Kareo requires a provider. Please confirm a provider is assigned for
          this appointment.
        </PrimaryText>
      </div>
      {!!error && (
        <div
          css={{
            marginBottom: '16px',
          }}
        >
          <ErrorMessageWrapper>
            <PrimaryText
              style={{
                color: '#FFF',
              }}
            >
              An error occurred while creating the appointment. Please try
              again.
            </PrimaryText>
          </ErrorMessageWrapper>
        </div>
      )}
      {!loading && (
        <Formik
          onSubmit={handleCreateKareoAppointment}
          initialValues={{
            kareoClinic:
              ClinicMap[appointment?.clinic?.name] || ClinicMap.default,
            kareoType: getKareoVisitType(appointment),
            kareoProvider: physicians[0]
              ? `${physicians[0]?.firstName} ${physicians[0]?.lastName}`
              : '',
          }}
          validationSchema={VisitValidation}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <div>
                  <div>
                    <div style={{ width: '100%' }}>
                      <Label
                        style={{ marginBottom: '8px', display: 'inline-block' }}
                      >
                        Assign or Confirm Provider
                      </Label>
                      <SelectSimpleSearchField
                        name="kareoProvider"
                        icon={ICONS.person}
                        options={staffNames}
                        placeholder="Select Provider"
                      />
                      <NonEmptyErrorField name="kareoProvider" />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ paddingRight: '16px', width: '50%' }}>
                        <Label
                          style={{
                            marginBottom: '8px',
                            display: 'inline-block',
                          }}
                        >
                          Assign or Confirm Location
                        </Label>

                        <SelectSimpleSearchField
                          name="kareoClinic"
                          icon={ICONS.pin}
                          options={KAREO_CLINICS}
                          placeholder="Select Kareo Clinic"
                        />

                        <NonEmptyErrorField name="kareoClinic" />
                      </div>
                      <div style={{ width: '50%' }}>
                        <Label
                          style={{
                            marginBottom: '8px',
                            display: 'inline-block',
                          }}
                        >
                          Assign or Confirm Visit Type
                        </Label>

                        <SelectSimpleSearchField
                          name="kareoType"
                          icon={ICONS.stethoscope}
                          options={KAREO_APPOINTMENT_TYPES}
                          placeholder="Select Kareo Appointment Type"
                        />

                        <NonEmptyErrorField name="kareoType" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <SmallTitle
                      style={{ marginBottom: '16px', display: 'inline-block' }}
                    >
                      Appointment Details
                    </SmallTitle>
                    <DefinitionList>
                      <DefinitionListItem term="Clinic">
                        {appointment?.clinic?.name}
                      </DefinitionListItem>

                      <DefinitionListItem term="Date">
                        {formattedStartDate}
                      </DefinitionListItem>
                      <DefinitionListItem term="Time">
                        {formattedStartTime} - {formattedEndTime}
                      </DefinitionListItem>
                      <DefinitionListItem term="Schedule Type">
                        {appointment.type &&
                          APPOINTMENT_TYPES[appointment.type]}
                      </DefinitionListItem>
                      <DefinitionListItem term="Care Type">
                        {careTypeTitle}
                      </DefinitionListItem>
                      <DefinitionListItem term="Reason for Visit">
                        {appointment.reason}
                      </DefinitionListItem>
                    </DefinitionList>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      type="submit"
                      disabled={isSubmitting || appointmentLoading}
                    >
                      Create Kareo Appointment
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default KareoAppointmentReview;
