/** @jsx jsx */
import { Colors } from '@bc/theme';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { DialogContent } from '@reach/dialog';
import React, { useState } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { ICONS } from '../assets';
import NotFound from '../assets/notfound.png';
import { CloseButton } from '../components/button';
import { PrimaryText } from '../components/text';
import { useWindowSize } from '../hooks';
import { IF_DESKTOP, IF_TABLET, TABLET_WIDTH } from '../styles';

export const Content = styled(DialogContent)`
  width: 90%;
  margin: 64px auto;
  padding: 0;
  border-radius: 5px;
  max-height: calc(100% - 128px);
  overflow-y: scroll;
  ${IF_TABLET} {
    width: 100%;
    margin: 128px auto;
    max-width: 760px;
    max-height: calc(100% - 64px);
  }
`;

export const LinkPadded = styled(NavLink)({
  display: 'flex',
  padding: '8px 16px',
  alignItems: 'center',
  justifyContent: 'left',
  textDecoration: 'none',
  color: Colors.gray,
  lineHeight: '1.5',
  fontWeight: 500,
  borderTop: `3px solid transparent`,
  marginRight: 0,
  [IF_TABLET]: {
    padding: '16px',
    flex: '1',
    borderLeft: `3px solid transparent`,
    borderTop: 'none',
    marginRight: 24,
  },
});

export const LinkText = styled(PrimaryText)`
  font-size: 14px;
  line-height: 18px;
  color: inherit;
  ${IF_TABLET} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const mobileActiveStyled = {
  color: Colors.darkHarbor,
  borderTop: `3px solid ${Colors.teal}`,
  fontWeight: 700,
};

const activeStyled = {
  borderLeft: `3px solid ${Colors.teal}`,
};

export const PaddedLink: React.FC<
  {
    icon?: keyof typeof ICONS;
  } & NavLinkProps
> = ({ children, icon, ...props }) => {
  const [isActive, setIsActive] = useState(false);
  const { innerWidth } = useWindowSize();
  return (
    <LinkPadded
      activeStyle={
        innerWidth < TABLET_WIDTH ? mobileActiveStyled : activeStyled
      }
      isActive={(match) => {
        setIsActive(!!match);
        return !!match;
      }}
      {...props}
    >
      {icon && (
        <img
          src={ICONS[icon]}
          alt={icon}
          css={
            isActive
              ? {
                  filter:
                    'brightness(0) saturate(100%) invert(15%) sepia(84%) saturate(1537%) hue-rotate(152deg) brightness(100%) contrast(98%)',
                }
              : {
                  filter:
                    'brightness(0) saturate(100%) invert(50%) sepia(7%) saturate(775%) hue-rotate(125deg) brightness(90%) contrast(91%)',
                }
          }
        />
      )}
      <LinkText css={{ marginLeft: 16 }}>{children}</LinkText>
    </LinkPadded>
  );
};

export const LargeTitle = styled(PrimaryText)`
  color: #314d4a;
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
`;
export const Image = styled.img`
  height: 72px;
  width: 72px;
  border-radius: 120px;
  background-color: #8e949d;
`;

Image.defaultProps = {
  src: NotFound,
};

export const Close = styled(CloseButton)`
  position: absolute;
  right: 0;
  top: 0;
`;

export const ClosePosition = css({
  position: 'fixed',
  right: 40,
  top: 95,
  zIndex: 1,
});

export const ModalWrapper = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  padding: 0,
  margin: 0,
  position: 'absolute',
  overflowY: 'scroll',
  [IF_DESKTOP]: {
    flexDirection: 'row',
  },
});

export const NavigationWrapper = styled.div({
  background: Colors.white,
  bottom: 0,
  display: 'flex',
  filter: 'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.2))',
  left: 0,
  overflowX: 'scroll',
  position: 'fixed',
  width: '100vw',
  zIndex: 10,
  [IF_TABLET]: {
    borderRight: `1px solid ${Colors.grayLightest}`,
    bottom: 'unset',
    display: 'block',
    filter: 'none',
    paddingTop: 144,
    maxWidth: 180,
    overflowX: 'unset',
    top: 0,
    width: '100%',
    zIndex: 'auto',
  },
  [IF_DESKTOP]: {
    paddingTop: 72,
    maxWidth: 343,
    position: 'sticky',
    overflow: 'auto',
  },
});

export const MainContent = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: 'max-content',
  padding: '56px 24px 24px',
  width: '100%',
  [IF_TABLET]: {
    marginLeft: 180,
    width: 'calc(100% - 180px)',
  },
  [IF_DESKTOP]: {
    marginLeft: 'unset',
    minWidth: 566,
    padding: '72px 32px 72px 56px',
  },
});

export const InfoPanel = styled.div({
  width: '100%',
  padding: '0 24px 96px',
  [IF_TABLET]: {
    marginLeft: 180,
    width: 'calc(100% - 180px)',
  },
  [IF_DESKTOP]: {
    marginLeft: 'unset',
    padding: '72px 0 0 16px',
    maxWidth: 336,
    marginRight: 32,
    position: 'sticky',
    top: 0,
  },
});

export const SideBySide = css({
  display: 'flex',
  flexDirection: 'column',
  [IF_TABLET]: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
});

export const LeftSideContainer = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  maxWidth: 686,

  paddingBottom: 24,
  [IF_TABLET]: {
    paddingBottom: 96,
    marginRight: 48,
  },
});

export const RightSideContainer = styled.div({
  width: '100%',
  [IF_TABLET]: {
    width: 336,
  },
});

export const StaticHeader = css({
  borderBottom: 'none',
  padding: '48px 0 0',
});
