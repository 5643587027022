/** @jsx jsx */
import { useGetRoomsByClinicQuery } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import sortBy from 'lodash/sortBy';
import React from 'react';
import CaretDown from '../../assets/caret-down.svg';
import RoomIcon from '../../assets/exit-door-login.svg';
import { Caret, Icon, Select, SelectWrap } from './shared';

export const RoomSelect: React.FC<{
  clinicId: string;
  onSelectRoom: (value: string) => void;
  roomId?: string;
}> = ({ clinicId, onSelectRoom, roomId }) => {
  const { data: roomsData, refetch } = useGetRoomsByClinicQuery({
    fetchPolicy: 'no-cache',
    variables: {
      clinicId,
    },
  });

  return (
    <SelectWrap>
      <Icon src={RoomIcon} />
      <Select
        onChange={(e) => {
          onSelectRoom(e.target.value);
          refetch();
        }}
        css={{
          color:
            roomId !== 'undefined' && roomId !== 'unselected'
              ? Colors.darkHarbor
              : Colors.gray,
          width: '100%',
        }}
        value={roomId || 'unselected'}
      >
        <option value="unselected">Select exam room</option>
        {sortBy(roomsData?.rooms, 'name').map((room) => (
          <option
            value={room.id}
            key={room.id}
            disabled={room.status !== 'EMPTY' && room.id !== roomId}
          >
            {room.name}
          </option>
        ))}
      </Select>
      <Caret src={CaretDown} />
    </SelectWrap>
  );
};
