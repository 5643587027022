import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { ReactComponent as Pencil } from '../../assets/pencil.svg';
import { ReactComponent as Trash } from '../../assets/trash.svg';
import { Button, OutlineButton } from '../../components/button';
import { PrimaryText } from '../../components/text';
import { IF_TABLET } from '../../styles';

export const Icon = styled.img`
  height: 18px;
  width: 18px;
`;

export const PencilIcon = styled(Pencil)`
  height: 18px;
  width: 18px;
  :hover path {
    fill: ${Colors.darkHarbor};
  }
`;

export const TrashIcon = styled(Trash)`
  height: 18px;
  width: 18px;
  :hover path {
    fill: ${Colors.darkHarbor};
  }
`;

export const StaffAvatar = styled.img({
  height: 40,
  width: 40,
  borderRadius: 20,
  marginRight: 16,
  objectFit: 'contain',
});

export const CancelButton = styled(OutlineButton)`
  border-color: ${Colors.grayLight};
  border-radius: 100px;
  color: ${Colors.darkHarbor};
  padding: 15px 30px;
  &:active,
  &:visited,
  &:hover {
    border: 1px solid ${Colors.grayLight};
    background-color: ${Colors.grayLight};
    color: #fff;
  }
`;

export const ScheduleHeader = styled.div`
  align-items: baseline;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  padding: 14px 12px;
  margin: 20px 0;
  ${IF_TABLET} {
    margin: 40px 0;
    padding: 0;
  }
`;

export const ScheduleBody = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  border: '1px solid #E5E8E8',
  borderRadius: '12px 12px 0 0',
});

export const ScheduleFilters = styled.div({
  borderLeft: '1px solid #E5E8E8',
  padding: '32px 0 0',
  width: '355px',
});

export const Controls = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: relative
  width: 100%;
  margin-bottom: 32px;
  flex-wrap: wrap;
  button {
    margin-left: 0;
  }
  @media(min-width: 1100px) {
    flex-wrap: nowrap;
    button {
      margin-left: auto;
    }
  }
`;

export const FormattedDay = styled(PrimaryText)({
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '22px',
  marginLeft: '16px',
});

export const Main = styled.div({
  flex: 1,
  flexDirection: 'column',
  padding: '24px 40px',
});

export const TodayButton = styled(Button)`
  width: 94px;
  font-size: 16px;
  margin-left: auto;
  margin-right: 16px;
  padding: 10px 24px;

  &:disabled {
    color: ${Colors.gray};
  }
`;
export const Caret = styled.img<{ isOpen?: boolean }>`
  width: 16px;
  height: 16px;
  margin-left: 4px;
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
  ${({ isOpen }) => isOpen && `transform: rotate(0deg);`}
`;

export const DayButton = styled(Button)<{ isSelected?: boolean }>`
  align-items: center;
  background: transparent;
  border-radius: 100px;
  border: 1px solid ${Colors.grayLight};
  box-sizing: border-box;
  color: ${Colors.darkHarbor};
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  letter-spacing: 0.1px;
  line-height: 24px;

  ${({ isSelected }) =>
    isSelected &&
    `background: ${Colors.teal}; border: 1px solid ${Colors.teal}; color: white;`}

  margin-right: 16px;
  padding: 8px 20px;
  max-width: 55px;
  margin-bottom: 8px;
  &:last-of-type {
    margin-right: 0;
    margin-bottom: 0;
  }

  ${IF_TABLET} {
    margin-right: 16px;
    padding: 8px 24px 6px;
    max-width: initial;
    margin-bottom: 0;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const WeekdayWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${IF_TABLET} {
    flex-wrap: nowrap;
    padding: 5px 0;
  }
`;
