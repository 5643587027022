import addCard from './add-card.svg';
import add from './add.svg';
import additionalInfo from './additional_info.svg';
import addCalendar from './add_calendar.svg';
import addPerson from './add_person.svg';
import addWhite from './add_white.svg';
import auditLog from './audit_log.svg';
import billing from './billing.svg';
import calendarSimple from './calendar-simple.svg';
import callIncoming from './call-incoming.svg';
import callOnhold from './call-onhold.svg';
import callOutgoing from './call-outgoing.svg';
import call from './call.svg';
import caregiverLink from './caregiver-link.svg';
import caregiver from './caregiver.svg';
import caretDown from './caret-down.svg';
import caretRight from './caret-dropdown.svg';
import caret from './caret.svg';
import caretDownPlain from './caret_down_plain.svg';
import chatBubble from './chat-bubble.svg';
import chat from './chat.svg';
import checkmarkCircle from './checkmark-circle.svg';
import checkmarkGreen from './checkmark_green.svg';
import checkmarkGrey from './checkmark_grey.svg';
import circleCheck from './circle_check.svg';
import circleCheckGreen from './circle_check_green.svg';
import clear from './clear.svg';
import close from './close.svg';
import copy from './copy.svg';
import disable from './disable.svg';
import dollar from './dollar.svg';
import error from './error.svg';
import filter from './filter.svg';
import flag from './flag.svg';
import highPriority from './high_priority.svg';
import history from './history.svg';
import inbound from './inbound.svg';
import incomingCall from './incoming-call.svg';
import info from './info.svg';
import insurancePayment from './insurance-payment.svg';
import insurance from './insurance.svg';
import kareo from './kareo.svg';
import kareoAppointment from './kareo_appointment.svg';
import kareoDocumentSync from './kareo_document_sync.svg';
import kareoLink from './kareo_link.svg';
import kareoLinked from './kareo_linked.svg';
import kareoPatientUpdate from './kareo_patient_update.svg';
import arrowLeft from './left-arrow.svg';
import lowPriority from './low_priority.svg';
import mail from './mail.svg';
import mediumPriority from './medium_priority.svg';
import megaphone from './megaphone.svg';
import midDots from './mid-dots.svg';
import muted from './muted.svg';
import newVisit from './new_visit.svg';
import outboundCall from './outbound-call.svg';
import outbound from './outbound.svg';
import pause from './pause.svg';
import paused from './paused.svg';
import pen from './pen.svg';
import person from './person.svg';
import phone from './phone.svg';
import pin from './pin.svg';
import priority from './priority.svg';
import profile from './profile.svg';
import question from './question.svg';
import retake from './retake.svg';
import arrowRight from './right-arrow.svg';
import search from './search.svg';
import sms from './sms.svg';
import stethoscope from './stethoscope.svg';
import successCheckmark from './success_checkmark.svg';
import task from './task.svg';
import taskClinic from './task_clinic.svg';
import taskGroup from './task_group.svg';
import timeBlock from './time_block.svg';
import trash from './trash.svg';
import undo from './undo-arrow.svg';
import unmuted from './unmuted.svg';
import visits from './visits.svg';

export const ICONS = {
  add,
  addCalendar,
  addCard,
  additionalInfo,
  addPerson,
  addWhite,
  arrowLeft,
  arrowRight,
  auditLog,
  billing,
  calendarSimple,
  call,
  callIncoming,
  callOnhold,
  callOutgoing,
  caregiver,
  caregiverLink,
  caret,
  caretDown,
  caretDownPlain,
  caretRight,
  checkmarkCircle,
  checkmarkGreen,
  checkmarkGrey,
  circleCheck,
  circleCheckGreen,
  chat,
  chatBubble,
  clear,
  close,
  copy,
  disable,
  dollar,
  error,
  filter,
  flag,
  highPriority,
  history,
  inbound,
  incomingCall,
  info,
  insurance,
  insurancePayment,
  kareo,
  kareoAppointment,
  kareoDocumentSync,
  kareoLink,
  kareoLinked,
  kareoPatientUpdate,
  lowPriority,
  mail,
  mediumPriority,
  megaphone,
  midDots,
  muted,
  newVisit,
  outbound,
  outboundCall,
  pause,
  paused,
  pen,
  person,
  phone,
  pin,
  priority,
  profile,
  question,
  retake,
  search,
  sms,
  stethoscope,
  successCheckmark,
  task,
  taskClinic,
  taskGroup,
  timeBlock,
  trash,
  undo,
  unmuted,
  visits,
};
