/** @jsx jsx */
import {
  TaskNotificationFragment,
  useGetAccountByPkQuery,
  useGetPatientByPkQuery,
  useLoadAppointmentDataQuery,
} from '@bc/codegen/medical';
import { VISIT_CLASSIFICATIONS, VISIT_TYPES } from '@bc/shared';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { useField } from 'formik';
import { DateTime } from 'luxon';
import { ICONS } from '../../assets';
import {
  formatDateOfBirth,
  formatPhoneNumber,
  getDateOfBirth,
} from '../../helpers';
import {
  AccountAvatarDisplay,
  PatientAvatarDisplay,
} from '../AppointmentDetails/AvatarDisplay';
import { PrimaryText } from '../text';

const LinkWrapButton = styled.button({
  padding: '8px',
  border: '1px solid #034A46',
  borderRadius: '100px',
  background: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
});

const SmallText = styled(PrimaryText)({
  color: '#647977',
  fontSize: '14px',
  fontWeight: 400,
});

const AssociationDisplay = styled(PrimaryText)({
  display: 'inline-block',
  marginBottom: '4px',
  fontWeight: 500,
  fontSize: '14px',
});

const NewVisitIcon = () => {
  return (
    <div
      css={{
        display: 'flex',
        width: '40px',
        height: '40px',
        borderRadius: '20px',
        backgroundColor: '#E5E8E8',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={ICONS.newVisit} css={{ width: '24px', height: '24px' }} />
    </div>
  );
};

export const TaskAssociationDisplay = ({
  associations,
  onRemove,
  disabled,
}: {
  associations: Partial<TaskNotificationFragment>[];
  disabled?: boolean;
  onRemove: (
    association: Partial<TaskNotificationFragment> | undefined,
  ) => void;
}) => {
  const patientAssociation = associations.find((association) => {
    return association.patientId;
  });
  const accountAssociation = associations.find((association) => {
    return association.accountId;
  });
  const appointmentAssociation = associations.find((association) => {
    return association.appointmentId;
  });

  const { data } = useGetPatientByPkQuery({
    variables: { patientId: patientAssociation?.patientId! },
    skip: !patientAssociation?.patientId,
  });

  const { data: accountData } = useGetAccountByPkQuery({
    variables: {
      accountId: accountAssociation?.accountId!,
    },
    skip: !accountAssociation?.accountId,
  });

  const { data: appointmentData } = useLoadAppointmentDataQuery({
    variables: {
      id: appointmentAssociation?.appointmentId!,
    },
    skip: !appointmentAssociation?.appointmentId,
  });

  const patient = data?.patients_by_pk;
  const account = accountData?.accounts_by_pk;
  const appointment = appointmentData?.appointments_by_pk;

  const date = DateTime.fromISO(appointment?.startTime!);
  const careTypeTitle = appointment?.visitClassification
    ? VISIT_CLASSIFICATIONS[appointment.visitClassification]
    : appointment?.visitType
    ? VISIT_TYPES[appointment.visitType]
    : '';

  const dob =
    patient?.dateOfBirth &&
    `${formatDateOfBirth(patient?.dateOfBirth)} (${getDateOfBirth({
      date: patient?.dateOfBirth,
    })})`;

  return (
    <div>
      {account && (
        <div
          css={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            borderBottom:
              patient || appointment ? '1px solid #D6DBDB' : undefined,
            padding: '16px 0',
          }}
        >
          <AccountAvatarDisplay accountId={account.id} size={40} />
          <div
            css={{
              flex: 1,
              flexDirection: 'column',
              display: 'flex',
              paddingLeft: '16px',
            }}
          >
            <AssociationDisplay>
              {account.firstName} {account.lastName}
            </AssociationDisplay>
            <SmallText>{formatPhoneNumber(account.phoneNumber)}</SmallText>
          </div>
          {!disabled && (
            <LinkWrapButton
              type="button"
              onClick={() => {
                onRemove(accountAssociation);
              }}
            >
              <PrimaryText css={{ padding: '0 8px' }}>Unlink</PrimaryText>
              <img src={ICONS.clear} />
            </LinkWrapButton>
          )}
        </div>
      )}
      {patient && (
        <div
          css={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            borderBottom: appointment ? '1px solid #D6DBDB' : undefined,
            padding: '16px 0',
          }}
        >
          <PatientAvatarDisplay patientId={patient?.id} size={40} />
          <div
            css={{
              flex: 1,
              flexDirection: 'column',
              display: 'flex',
              paddingLeft: '16px',
            }}
          >
            <AssociationDisplay>
              {patient.firstName} {patient.lastName}
            </AssociationDisplay>
            <SmallText>{dob}</SmallText>
          </div>
          {!disabled && (
            <LinkWrapButton
              type="button"
              onClick={() => {
                onRemove(patientAssociation);
              }}
            >
              <PrimaryText css={{ padding: '0 8px' }}>Unlink</PrimaryText>
              <img src={ICONS.clear} />
            </LinkWrapButton>
          )}
        </div>
      )}
      {appointment && (
        <div
          css={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            padding: '16px 0',
          }}
        >
          <NewVisitIcon />
          <div
            css={{
              flex: 1,
              flexDirection: 'column',
              display: 'flex',
              paddingLeft: '16px',
            }}
          >
            <AssociationDisplay>
              {date.toFormat('MM/dd/yyyy')} for {careTypeTitle}
            </AssociationDisplay>
            <SmallText>{appointment.reason}</SmallText>
          </div>
          {!disabled && (
            <LinkWrapButton
              type="button"
              onClick={() => {
                onRemove(appointmentAssociation);
              }}
            >
              <PrimaryText css={{ padding: '0 8px' }}>Unlink</PrimaryText>
              <img src={ICONS.clear} />
            </LinkWrapButton>
          )}
        </div>
      )}
    </div>
  );
};

export const TaskAssociationField = ({
  name,
  disabled,
}: {
  name: string;
  disabled?: boolean;
}) => {
  const [field, meta, helpers] = useField(name);

  const displayAssociations = field.value?.filter(
    (association: Partial<TaskNotificationFragment>) => {
      return (
        association.accountId ||
        association.appointmentId ||
        association.patientId
      );
    },
  );

  return (
    <TaskAssociationDisplay
      associations={displayAssociations}
      disabled={disabled}
      onRemove={(association) => {
        if (association?.appointmentId) {
          helpers.setValue(
            field.value.filter((assoc: Partial<TaskNotificationFragment>) => {
              return assoc.appointmentId !== association.appointmentId;
            }),
          );
        }

        if (association?.patientId) {
          helpers.setValue(
            field.value.filter((assoc: Partial<TaskNotificationFragment>) => {
              return assoc.patientId !== association.patientId;
            }),
          );
        }

        if (association?.accountId) {
          helpers.setValue(
            field.value.filter((assoc: Partial<TaskNotificationFragment>) => {
              return assoc.accountId !== association.accountId;
            }),
          );
        }
      }}
    />
  );
};
