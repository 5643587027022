/** @jsx jsx */
import { useAppointmentDataSubscription } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { CloseButton } from '../components/button';
import { FlagsSidebar } from '../components/Flags';
import Loader from '../components/Loader/loader';
import { PatientHeader } from '../components/ModalHeader';
import { Content, ModalWrap, TaskContent } from '../components/Routing';
import AdditionalInformation from './appointment/AdditionalInformation';
import Caregivers from './appointment/CareGivers';
import Insurance from './appointment/Insurance';
import Kareo from './appointment/Kareo';
import Payments from './appointment/Payments';
import Profile from './appointment/Profile';
import AppointmentTasks from './appointment/Tasks';
import Visit from './appointment/Visit';
import VisitHistory from './appointment/VisitHistory';
import CreateTask from './tasks/CreateTask';
import EditTask from './tasks/EditTask';
import ViewTask from './tasks/ViewTask';
import {
  ClosePosition,
  InfoPanel,
  MainContent,
  ModalWrapper,
  NavigationWrapper,
  PaddedLink,
} from './ViewAppointment.styles';

const ViewAppointment: React.FC<
  { returnPath?: string } & RouteComponentProps<{
    appointmentId: string;
  }>
> = ({ returnPath, match, history }) => {
  const { appointmentId } = match.params;

  const { data, loading } = useAppointmentDataSubscription({
    variables: {
      id: appointmentId,
    },
  });

  const appointment = data?.appointments_by_pk;
  const patient = appointment?.appointment_patients?.[0]?.patient;

  return !data ? (
    <Loader />
  ) : (
    <ModalWrapper aria-label="Appointment">
      <CloseButton
        css={ClosePosition}
        data-testid="closeModal"
        onClick={() => history.push(returnPath || '/dashboard')}
      />
      {patient && (
        <NavigationWrapper>
          <PaddedLink
            data-testid="visit"
            icon="visits"
            to={`${match.url}/visit`}
          >
            Visit
          </PaddedLink>
          <PaddedLink
            data-testid="patientProfile"
            icon="profile"
            to={`${match.url}/profile`}
          >
            Patient Profile
          </PaddedLink>
          <PaddedLink
            data-testid="additionalInfo"
            icon="additionalInfo"
            to={`${match.url}/additional-info`}
          >
            Additional Information
          </PaddedLink>
          <PaddedLink
            data-testid="caregivers"
            icon="caregiver"
            to={`${match.url}/caregivers`}
          >
            Caregivers
          </PaddedLink>
          <PaddedLink
            data-testid="insurance"
            icon="insurance"
            to={`${match.url}/insurance`}
          >
            Insurance
          </PaddedLink>
          <PaddedLink
            data-testid="billing"
            icon="billing"
            to={`${match.url}/payments`}
          >
            Billing
          </PaddedLink>
          <PaddedLink
            data-testid="otherVisits"
            icon="history"
            to={`${match.url}/visits`}
          >
            Other Visits
          </PaddedLink>
          <PaddedLink data-testid="tasks" icon="task" to={`${match.url}/tasks`}>
            Tasks
          </PaddedLink>
          <PaddedLink icon="kareo" to={`${match.url}/kareo`}>
            Kareo Sync
          </PaddedLink>
        </NavigationWrapper>
      )}
      <MainContent>
        <PatientHeader
          patientId={patient?.id}
          accountId={appointment?.accountId!}
          appointmentId={appointmentId}
        />
        {!data && loading && <Loader />}
        {data && appointment && (
          <Switch>
            <Route
              path={`${match.url}/visit`}
              render={() => <Visit appointment={appointment} />}
            />
            <Route
              path={`${match.url}/profile`}
              render={() => <Profile appointment={appointment} />}
            />
            <Route
              path={`${match.url}/additional-info`}
              render={() => <AdditionalInformation appointment={appointment} />}
            />
            <Route
              path={`${match.url}/caregivers`}
              render={() => <Caregivers appointment={appointment} />}
            />
            <Route
              path={`${match.url}/insurance`}
              render={() => <Insurance appointment={appointment} />}
            />
            <Route
              path={`${match.url}/payments`}
              render={() => <Payments appointment={appointment} />}
            />
            <Route
              path={`${match.url}/visits`}
              render={() => <VisitHistory isOther patientId={patient?.id} />}
            />
            <Route
              path={`${match.url}/kareo`}
              render={() => <Kareo data={appointment} />}
            />
            <Route
              path={`${match.url}/tasks`}
              render={() => (
                <AppointmentTasks
                  appointment={appointment}
                  basePath={match.url}
                />
              )}
            />
          </Switch>
        )}
        {data && appointment && patient && (
          <Switch>
            <Route
              path={`${match.url}/tasks/:taskId/edit`}
              // @ts-ignore
              render={(props: RouteComponentProps<{ taskId: string }>) => {
                return (
                  <ModalWrap
                    {...props}
                    onDismissGoToPath={`${match.url}/tasks`}
                  >
                    <TaskContent>
                      <EditTask {...props} dismissPath={`${match.url}/tasks`} />
                    </TaskContent>
                  </ModalWrap>
                );
              }}
            />
            <Route
              path={`${match.url}/tasks/create`}
              // @ts-ignore
              render={(props: RouteComponentProps<{ taskId: string }>) => {
                return (
                  <ModalWrap
                    {...props}
                    onDismissGoToPath={`${match.url}/tasks`}
                  >
                    <TaskContent>
                      <CreateTask
                        {...props}
                        defaultAssociations={[
                          {
                            appointmentId: appointment.id,
                          },
                          {
                            patientId: patient.id,
                          },
                        ]}
                        dismissPath={`${match.url}/tasks`}
                      />
                    </TaskContent>
                  </ModalWrap>
                );
              }}
            />
            <Route
              path={`${match.url}/tasks/:taskId`}
              // @ts-ignore
              render={(props: RouteComponentProps<{ taskId: string }>) => {
                return (
                  <ModalWrap
                    {...props}
                    onDismissGoToPath={`${match.url}/tasks`}
                  >
                    <Content>
                      <ViewTask {...props} dismissPath={`${match.url}/tasks`} />
                    </Content>
                  </ModalWrap>
                );
              }}
            />
          </Switch>
        )}
      </MainContent>
      <InfoPanel>
        <FlagsSidebar
          patientId={patient?.id}
          accountId={appointment?.accountId!}
        />
      </InfoPanel>
    </ModalWrapper>
  );
};

export default ViewAppointment;
