/** @jsx jsx */
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { ICONS } from '../../assets/index';
import { OutlineButtonDark, TransparentButton } from '../../components/button';
import RoundClear from '../../components/Tasks/icons/round_clear.svg';
import { TaskCheckmark } from '../../components/Tasks/icons/task_check';
import Unwatched from '../../components/Tasks/icons/unwatched.svg';
import Watched from '../../components/Tasks/icons/watched.svg';
import { PriorityPills } from '../../components/Tasks/TaskPriorityDropdown';
import {
  StatusDisplay,
  StatusDropdown,
} from '../../components/Tasks/TaskStatus';
import { getTypeAbbreviation, Task } from '../../components/Tasks/types';
import {
  DullText,
  ellipsis,
  rightSideText,
  StaffImage,
  TdOther,
  TdTask,
} from '../../components/Tasks/ui';
import { useTrackEvent } from '../../lib/analytics';
import { getPatientName } from '../../lib/patient';
import { BasicStaff } from '../../lib/staff';
import {
  getActiveAssignments,
  getAssociations,
  getHasAllRead,
  getIsTaskWatched,
  getResponsible,
  getTotalReaderCount,
  hasReadTask,
  isResponsible,
  useTasks,
} from '../../lib/task';
import { DueDateTime } from './styles';

export const TaskRow = ({
  task,
  isLast,
  currentStaff,
  showWatch = false,
}: {
  isLast: boolean;
  task: Task;
  currentStaff?: BasicStaff;
  showWatch?: boolean;
}) => {
  const {
    updateTask,
    assignTask,
    unassignTask,
    unassignTaskPending,
    unwatchTask,
    watchTask,
    watchingPending,
  } = useTasks();
  const isCompleted = task?.status === 'COMPLETED';
  const assignemnts = getActiveAssignments(task);
  const assignment = assignemnts?.[0];
  const assignedStaff = assignemnts?.[0]?.staff;
  const claimedForSelf =
    assignedStaff?.id && assignedStaff.id === currentStaff?.id;
  const assignedToMe = currentStaff && assignedStaff?.id === currentStaff?.id;
  const readCount = task?.tasks_reads?.length ?? 0;
  const isAction = task.type === 'ACTION';
  const isMemo = task.type === 'INFORMATION';
  const responsible = isResponsible(task, currentStaff?.id);
  const responsibleParty = getResponsible(task);
  const associations = getAssociations(task);
  const patientAssociation = associations?.filter(({ patientId }) => {
    return patientId;
  })?.[0];
  const allResponsibleRead = getHasAllRead(task);
  const isTaskWatched = getIsTaskWatched(task);

  const hasRead = hasReadTask(task) && isMemo;

  const canClaim = !!responsible;

  let responsibleDisplay: string | undefined = '';
  let totalReadCount = getTotalReaderCount(task);

  if (responsibleParty?.taskGroupId) {
    responsibleDisplay = responsibleParty.tasks_group?.name;
  } else if (responsibleParty?.staffId) {
    responsibleDisplay = `${responsibleParty.staff?.firstName} ${responsibleParty.staff?.lastName}`;
  }

  const trackEvent = useTrackEvent();

  return (
    <tr
      css={{
        opacity: isCompleted || hasRead || allResponsibleRead ? '0.4' : '1',
        ':hover': {
          backgroundColor: '#FBFAFA',
        },
      }}
      data-testid={task.title?.replace(/ /g, '_')?.toLowerCase()}
    >
      <TdTask
        isLast={isLast}
        css={{
          maxWidth: '300px',
          ':hover': {
            backgroundColor: Colors.grayLightest,
          },
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
          }}
        >
          {showWatch && (
            <TransparentButton
              css={{
                marginRight: '8px',
              }}
              disabled={watchingPending}
              title={isTaskWatched ? 'Unfollow' : 'Follow'}
              onClick={() => {
                try {
                  if (isTaskWatched) {
                    unwatchTask(task.id);
                  } else {
                    watchTask(task.id);
                  }
                } catch (error) {}
              }}
            >
              <img src={isTaskWatched ? Watched : Unwatched} />
            </TransparentButton>
          )}
          {(assignedToMe || isCompleted) && isAction && (
            <TransparentButton
              data-testid="complete_checkmark"
              onClick={() => {
                if (!isCompleted) {
                  updateTask(task.id, {
                    status: 'COMPLETED',
                  });
                  trackEvent({
                    type: 'TASK_COMPLETED',
                    data: {
                      taskId: task.id,
                      currentStaffId: currentStaff?.id,
                    },
                  });
                } else {
                  updateTask(task.id, {
                    status: 'OPEN',
                  });
                  trackEvent({
                    type: 'TASK_OPEN',
                    data: {
                      taskId: task.id,
                      currentStaffId: currentStaff?.id,
                    },
                  });
                }
              }}
              css={{
                marginRight: '11px',
                [`:hover path, :hover circle`]: {
                  stroke: '#11BB9A',
                },
              }}
            >
              {!isCompleted && <TaskCheckmark />}
              {isCompleted && <img src={ICONS.circleCheckGreen} />}
            </TransparentButton>
          )}
          <Link
            to={`/tasks/${task.id}`}
            css={[
              {
                flex: 1,
                textDecoration: 'none',
              },
              ellipsis,
            ]}
          >
            <DullText>{task.title}</DullText>
          </Link>
        </div>
      </TdTask>
      <TdOther isLast={isLast}>
        {task.priority && PriorityPills[task.priority]}
      </TdOther>
      <TdOther isLast={isLast}>
        <DueDateTime
          dueDateTime={task.dueDateTime!}
          isCompleted={isCompleted || hasRead}
          css={rightSideText}
        />
      </TdOther>
      <TdOther isLast={isLast}>
        <DullText css={[ellipsis]}>{responsibleDisplay}</DullText>
      </TdOther>
      <TdOther isLast={isLast}>
        {isAction && (
          <Fragment>
            {!isCompleted && !assignedStaff && canClaim && (
              <OutlineButtonDark
                data-testid="claim_task"
                onClick={() => {
                  if (currentStaff) {
                    assignTask({
                      taskId: task.id,
                      staffId: currentStaff.id,
                    });
                  }
                  trackEvent({
                    type: 'CLAIM_TASK',
                    data: {
                      taskId: task.id,
                      currentStaffId: currentStaff?.id,
                    },
                  });
                }}
                css={{
                  borderRadius: '100px',
                  fontSize: '14px',
                  fontWeight: 500,
                  padding: '5px 8px',
                  minHeight: '32px',
                }}
              >
                Claim Task
              </OutlineButtonDark>
            )}
            {assignedStaff && (
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  css={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <StaffImage src={assignedStaff?.avatarUrl ?? undefined} />
                  <DullText
                    css={[
                      {
                        marginLeft: '4px',
                      },
                      ellipsis,
                    ]}
                  >
                    {assignedStaff?.firstName} {assignedStaff?.lastName}
                  </DullText>
                </div>
                {canClaim && task.status === 'OPEN' && isAction && assignment && (
                  <TransparentButton
                    data-testid="unclaim_task"
                    onClick={async () => {
                      if (currentStaff) {
                        await unassignTask(assignment.id);
                        trackEvent({
                          type: 'UNCLAIM_TASK',
                          data: {
                            taskId: task.id,
                            currentStaffId: currentStaff?.id,
                          },
                        });
                      }
                    }}
                    disabled={!!unassignTaskPending}
                    css={{
                      marginLeft: '8px',
                    }}
                  >
                    <img src={RoundClear} />
                  </TransparentButton>
                )}
              </div>
            )}
          </Fragment>
        )}
      </TdOther>
      <TdOther
        isLast={isLast}
        css={{
          ':hover': {
            backgroundColor:
              isAction && claimedForSelf ? Colors.grayLightest : undefined,
          },
        }}
      >
        {isMemo && (
          <DullText>
            Read by {readCount} of {totalReadCount}
          </DullText>
        )}
        {isAction && (
          <Fragment>
            {!claimedForSelf && (
              <StatusDisplay value={task.status} css={rightSideText} />
            )}
            {claimedForSelf && (
              <StatusDropdown
                value={task.status}
                onChange={(status) => {
                  updateTask(task.id, {
                    status: status,
                  });
                  trackEvent({
                    type: 'UPDATE_TASK_STATUS',
                    data: {
                      taskId: task.id,
                      currentStaffId: currentStaff?.id,
                      status,
                    },
                  });
                }}
                css={{
                  border: 'none',
                  width: '100%',
                  padding: 0,
                }}
              />
            )}
          </Fragment>
        )}
      </TdOther>
      <TdOther
        css={{
          ':hover': {
            backgroundColor: patientAssociation
              ? Colors.grayLightest
              : undefined,
          },
        }}
      >
        {patientAssociation && patientAssociation.patient && (
          <Link
            to={`/patient/${patientAssociation.patientId}/profile`}
            css={{
              textDecorationColor: Colors.gray,
            }}
          >
            <DullText>{getPatientName(patientAssociation.patient)}</DullText>
          </Link>
        )}
      </TdOther>
      <TdOther
        isLast={isLast}
        css={{
          borderRight: 'none',
        }}
      >
        <DullText>{getTypeAbbreviation(task.subType)}</DullText>
      </TdOther>
    </tr>
  );
};
