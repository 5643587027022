/** @jsx jsx */
import { useLoadTaskActivityQuery } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import { DateTime } from 'luxon';
import React from 'react';
import { EntryText, Row, Timestamp } from '../Audit/styles';
import { LargeStaffImage } from './ui';

const Entry = ({
  children,
  time,
  avatarUrl,
  className,
}: {
  children: React.ReactNode;
  time: DateTime;
  avatarUrl?: string | null;
  className?: string;
}) => {
  return (
    <Row
      css={{
        alignItems: 'center',
        marginBottom: '16px',
        paddingBottom: '16px',
        borderBottom: '1px solid #D6DBDB',
      }}
      className={className}
    >
      <LargeStaffImage src={avatarUrl ?? undefined} />
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '8px',
        }}
      >
        <EntryText
          css={{
            marginBottom: '2px',
          }}
        >
          {children}
        </EntryText>
        <Timestamp>{time.toLocaleString(DateTime.DATETIME_SHORT)}</Timestamp>
      </div>
    </Row>
  );
};

export const TaskViewActivity = ({ taskId }: { taskId: string }) => {
  const { data } = useLoadTaskActivityQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      taskId: taskId,
      where: {
        _or: [
          {
            table: {
              _eq: 'tasks',
            },
            to: { _contains: { id: taskId } },
          },
          {
            table: {
              _eq: 'tasks_assignemnts',
            },
            to: { _contains: { taskId: taskId } },
          },
          {
            table: {
              _eq: 'tasks_notifications',
            },
            to: { _contains: { taskId: taskId } },
          },
        ],
      },
      order_by: {
        created_at: 'desc',
      },
    },
  });

  const logs = (data?.audit_logs ?? []).map((data) => {
    return {
      type: 'LOG',
      data,
    };
  });
  const assignments = (data?.tasks_assignments ?? []).map((data) => {
    return {
      type: 'ASSIGNMENTS',
      data,
    };
  });
  const reads = (data?.tasks_read ?? []).map((data) => {
    return {
      type: 'READ',
      data,
    };
  });

  const activityItems = [...logs, ...assignments, ...reads].sort(
    //@ts-ignore
    (a: any, b: any) => {
      let aDate: DateTime = DateTime.fromISO(
        a?.data?.createdAt ?? a?.data?.created_at,
      );
      let bDate: DateTime = DateTime.fromISO(
        b?.data?.createdAt ?? b?.data?.created_at,
      );

      //@ts-ignore
      return bDate - aDate;
    },
  );

  return (
    <div>
      {activityItems?.map((item) => {
        const time = DateTime.fromISO(
          //@ts-ignore
          item?.data?.createdAt || item?.data?.created_at,
        );
        if (item.type === 'ASSIGNMENTS') {
          return (
            <Entry time={time} avatarUrl={item.data.staff?.avatarUrl!}>
              {item.data.staff?.firstName} {item.data.staff?.lastName} claimed
              this task
            </Entry>
          );
        } else if (item.type === 'READ') {
          return (
            <Entry time={time} avatarUrl={item.data.staff?.avatarUrl!}>
              {item.data.staff?.firstName} {item.data.staff?.lastName} marked
              this task as read
            </Entry>
          );
        } else if (item.type === 'LOG') {
          const log = item.data;
          //@ts-ignore
          if (log.table === 'tasks' && log.operation === 'UPDATE') {
            return (
              log.staff && (
                <Entry time={time} avatarUrl={log.staff?.avatarUrl}>
                  {log.staff?.firstName} {log.staff?.lastName} updated this task
                </Entry>
              )
            );
          }
        }
      })}
      {/* Always put created first */}
      {activityItems?.map((item) => {
        const log = item.data;

        //@ts-ignore
        if (log.table === 'tasks' && log.operation === 'INSERT') {
          const time = DateTime.fromISO(
            //@ts-ignore
            item?.data?.createdAt || item?.data?.created_at,
          );
          return (
            <Entry
              time={time}
              avatarUrl={log?.staff?.avatarUrl}
              css={{
                borderBottom: 'none',
              }}
            >
              {log?.staff
                ? `${log.staff?.firstName} ${log.staff?.lastName} created this task`
                : 'Task created automatically'}
            </Entry>
          );
        }
        return null;
      })}
    </div>
  );
};
