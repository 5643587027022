/** @jsx jsx */
import {
  useAppointmentScheduleQuery,
  useGetAccountByPkWithRelatedQuery,
  useLoadChatSessionsQuery,
} from '@bc/codegen/medical';
import { PREFERRED_PRONOUN, RELATIONSHIPS } from '@bc/shared';
import { Colors, Spacing } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { ErrorBoundary } from '@sentry/react';
import capitalize from 'lodash/capitalize';
import uniqBy from 'lodash/uniqBy';
import { DateTime } from 'luxon';
import React, {
  Fragment,
  KeyboardEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
// import Draggable from 'react-draggable';
import ExpandingTextarea from 'react-expanding-textarea';
import { useHistory } from 'react-router-dom';
import caret from '../../assets/caret.svg';
import {
  AccountAvatar,
  PatientAvatar,
  StaffAvatar,
} from '../../components/ChatMessage';
import { PrimaryText, TinyTitle } from '../../components/text';
import {
  formatDateOfBirth,
  formatPhoneNumber,
  getDateOfBirth,
} from '../../helpers';
import { useWindowSize } from '../../hooks';
import { useAsyncChat } from '../../lib/chat';
import { fullStaffName } from '../../pages/staff/helpers';
import { IF_DESKTOP, IF_TABLET } from '../../styles';
import { Textarea } from '../form';
// import rect from './icons/draggableRect.svg';

export const ChatSidebar: React.FC<{
  chatSessionId: string;
}> = ({ chatSessionId }) => {
  const [value, setValue] = useState('');
  const [deltaY, setDeltaY] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);
  const [inputHeight, setInputHeight] = useState(64);
  const notesRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);

  const { innerWidth } = useWindowSize();

  const history = useHistory();
  const { chatSession, currentStaffData, writeChatNote } =
    useAsyncChat(chatSessionId);

  const account = chatSession?.chat_sessions_accounts?.[0]?.account;
  const patient = chatSession?.chat_sessions_patients?.[0]?.patient;

  useLayoutEffect(() => {
    if (notesRef?.current) {
      setInitialHeight(notesRef.current?.scrollHeight);
    }
  }, [chatSession?.chat_notes?.length]);

  const handleSelectChat = (id: string) => history.push(`/chats/${id}`);

  const handleSelectVisit = (id: string) =>
    history.push(`/chats/${chatSessionId}/appointment/${id}/visit`);

  const handleSelectAccount = (id: string) =>
    history.push(`/chats/${chatSessionId}/account/${id}/profile`);

  const handleSelectPatient = (id: string) =>
    history.push(`/chats/${chatSessionId}/patient/${id}/profile`);

  const currentStaffId = currentStaffData.staffId;

  const isMobile = innerWidth < 767;

  return (
    <ErrorBoundary
      fallback={() => (
        <Sidebar
          css={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            maxHeight: `calc(100% - ${inputHeight}px)`,
            padding: Spacing.m,
          }}
        >
          <SidebarSectionTitle>Oops! Something went wrong.</SidebarSectionTitle>
          <SidebarText css={{ marginTop: Spacing.m, textAlign: 'center' }}>
            An error report as been automatically sent to our tech staff but if
            you want to provide more context or would like the escalate the
            priority of this issue{' '}
            <SidebarLink href="https://support.bravecare.com">
              file a ticket.
            </SidebarLink>
          </SidebarText>
        </Sidebar>
      )}
    >
      <Sidebar css={{ maxHeight: `calc(100% - ${inputHeight}px)` }}>
        <SidebarContent
          css={
            isMobile
              ? {
                  height: `calc(100% - ${initialHeight}px + ${deltaY}px - ${inputHeight}px - 68px)`,
                }
              : {
                  height: `calc(100% - ${initialHeight}px + ${deltaY}px - ${inputHeight}px)`,
                }
          }
        >
          <SidebarFamilySection
            accountId={account?.id!}
            patientId={patient?.id!}
            onSelectAccount={handleSelectAccount}
            onSelectPatient={handleSelectPatient}
          />
          <SidebarVisitSection
            accountId={account?.id!}
            patientId={patient?.id!}
            onSelectVisit={handleSelectVisit}
          />
          <SidebarChatSection
            currentChatId={chatSession?.id}
            accountId={account?.id!}
            patientId={patient?.id!}
            onSelectChat={handleSelectChat}
          />
        </SidebarContent>
        {/* <Draggable
          axis="y"
          onDrag={(e, data) => {
            setDeltaY(data.y);
          }}
          bounds={
            isMobile
              ? { bottom: initialHeight, top: -300 }
              : {
                  bottom: initialHeight,
                  top: -500,
                }
          }
        >
          <DraggableContainer css={{ transform: 'translate(0, 0) !important' }}>
            <Rectangle src={rect} onMouseDown={(e) => e.preventDefault()} />
          </DraggableContainer>
        </Draggable> */}
        <SidebarSectionTitle
          css={{
            textAlign: 'left',
            paddingLeft: 24,
            paddingBottom: 16,
            zIndex: 11,
          }}
        >
          Internal Notes
        </SidebarSectionTitle>
        <InternalNotesContent
          ref={notesRef}
          style={{
            height: `calc(${initialHeight}px - ${deltaY}px)`,
            bottom: `${inputHeight}px`,
          }}
        >
          {chatSession?.chat_notes.map((note, i) => (
            <NoteRow
              key={note.id}
              css={{
                ...(i === 0 ? { paddingTop: 0 } : {}),
                ...(i === chatSession?.chat_notes?.length - 1
                  ? {}
                  : { borderBottom: `1px solid ${Colors.grayLighter}` }),
              }}
            >
              <StaffAvatar staffId={note.staff?.id} />
              <NoteContainer>
                <Title>
                  <Text>
                    {note?.staff
                      ? fullStaffName(note?.staff)
                      : `${note.staff?.firstName} ${note.staff?.lastName}`}
                  </Text>
                  <Timestamp>
                    {DateTime.fromISO(note.updatedAt!).toFormat("D 'at' t")}
                  </Timestamp>
                </Title>
                <NoteText>{note.note}</NoteText>
              </NoteContainer>
            </NoteRow>
          ))}
        </InternalNotesContent>
        <NoteInputRow ref={inputRef}>
          <StaffAvatar staffId={currentStaffId} />
          <NoteTextarea
            value={value}
            onChange={(event: any) => {
              setValue(event.target.value);
              setInputHeight(inputRef?.current?.clientHeight || 65);
            }}
            onKeyPress={(e: KeyboardEvent) => {
              if (['Enter'].includes(e.key)) {
                e.preventDefault();
                if (value.length) {
                  writeChatNote(value, 'INTERNAL');
                  setValue('');
                  setInputHeight(65);
                }
              }
            }}
            placeholder="Add note..."
          />
        </NoteInputRow>
      </Sidebar>
    </ErrorBoundary>
  );
};

const Sidebar = styled.div({
  display: 'block',
  minWidth: 352,
  width: 352,
  paddingTop: 0,
  backgroundColor: Colors.grayLightest,
});

const SidebarContent = styled.div({
  overflowY: 'scroll',
  position: 'sticky',
  top: 0,
  padding: 24,
});

const InternalNotesContent = styled.div({
  position: 'sticky',
  bottom: 0,
  backgroundColor: Colors.grayLightest,
  overflowY: 'scroll',
  zIndex: 11,
});

const DraggableContainer = styled.div({
  zIndex: 11,
  position: 'sticky',
  borderTop: `1px solid ${Colors.grayLighter}`,
  backgroundColor: Colors.grayLightest,
  textAlign: 'center',
  height: 7,
  paddingBottom: 24,
  ':hover': {
    cursor: 'row-resize',
  },
});

const Rectangle = styled.img({
  textAlign: 'center',
  padding: '8px 0',
});

const NoteContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 8,
});

const Title = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
});

const NoteRow = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  padding: '16px 0',
  margin: ' 0 24px',
});

const Text = styled(PrimaryText)({
  fontWeight: 'bold',
  fontSize: 14,
  lineHeight: '18px',
  marginRight: 8,
});

const NoteText = styled(Text)({
  fontWeight: 'normal',
  marginRight: 0,
});

const Timestamp = styled(PrimaryText)({
  fontSize: 12,
  lineHeight: '16px',
});

const NoteInputRow = styled(NoteRow)({
  padding: '16px 24px 8px',
  backgroundColor: Colors.grayLightest,
  borderTop: `1px solid ${Colors.grayLighter}`,
  position: 'relative',
  bottom: 0,
  margin: 0,
  zIndex: 11,
  [IF_TABLET]: {
    position: 'fixed',
  },
});

const NoteTextarea = styled(Textarea)({
  marginLeft: 16,
  width: 'calc(352px - 48px - 56px)',
  height: 34,
  padding: 8,
  [IF_DESKTOP]: {
    fontSize: 14,
    lineHeight: 1.5,
  },
}).withComponent(ExpandingTextarea);

const SidebarSection = styled.section({
  marginTop: 24,
  marginLeft: 16,
  marginRight: 16,
});

const SidebarSectionHeader = styled.div({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 12,
});

const SidebarSectionTitle = styled(TinyTitle)({
  color: Colors.darkHarbor,
}).withComponent('h3');

const Caret = styled.img<{ isSectionVisible: boolean }>`
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
  filter: invert(60%) sepia(17%) saturate(140%) hue-rotate(201deg)
    brightness(93%) contrast(91%);
  ${({ isSectionVisible }) => isSectionVisible && `transform: rotate(180deg);`}
`;

const SidebarText = styled(PrimaryText)({
  lineHeight: '26px',
}).withComponent('p');

const SidebarLink = styled(SidebarText)({
  cursor: 'pointer',
  display: 'block',
}).withComponent('a');

const PillWrapper = styled.div({
  overflow: 'hidden',
  transition: 'height 1.2s ease-in-out',
});

const Pill = styled(PrimaryText)({
  backgroundColor: Colors.white,
  border: `1px solid ${Colors.grayLighter}`,
  borderRadius: 100,
  cursor: 'pointer',
  display: 'inline-block',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '18px',
  padding: '4px 12px',
  margin: '0 8px 4px 0',
}).withComponent('a');

const SidebarCard = styled.div({
  background: Colors.white,
  border: '1px solid #EFF1F1',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
  borderRadius: 5,
  marginTop: 24,
});

const CardInfo = styled.div({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
});

const CardInfoTitle = styled(TinyTitle)({
  color: Colors.darkHarbor,
});

const CardInfoText = styled(PrimaryText)({
  fontSize: 14,
  lineHeight: '18px',
});

const CardInfoLink = styled(CardInfoText)({
  color: Colors.teal,
  textDecoration: 'underline',
}).withComponent('a');

const CardDetails = styled.div({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  padding: '0 16px',
  transition: 'height 1.2s ease-in-out',
});

const CardDetailTitle = styled(PrimaryText)({
  color: Colors.gray,
  fontWeight: 'bold',
  fontSize: 12,
  lineHeight: '16px',
});

const stateFromZip = async (zipcode: string) => {
  const baseUrl = `https://maps.googleapis.com/maps/api/geocode/json`;
  const params = `address=${zipcode}&key=${process.env.REACT_APP_GOOGLE_KEY}`;
  const resp = await fetch(`${baseUrl}?${params}`);
  const { results } = await resp.json();
  const state = results?.[0]?.address_components?.find((ac: any) =>
    ac.types.includes('administrative_area_level_1'),
  )?.long_name;

  return state;
};

const SidebarFamilySection: React.FC<{
  accountId: string;
  patientId: string;
  onSelectAccount: (id: string) => void;
  onSelectPatient: (id: string) => void;
}> = ({ accountId, patientId, onSelectAccount, onSelectPatient }) => {
  const [isPatientExpanded, setIsPatientExpanded] = useState(true);
  const [isAccountExpanded, setIsAccountExpanded] = useState(true);
  const [accountStateOfResidence, setAccountStateOfResidence] =
    useState<string>();

  const { data } = useGetAccountByPkWithRelatedQuery({
    variables: { accountId },
    skip: !accountId,
  });

  const account = data?.accounts_by_pk;
  const accountPatients = account?.account_patients ?? [];
  const patients = accountPatients.map((ap) => ap.patient);
  const patient = patients.find((p) => p.id === patientId);
  const relationToPatient = patient?.accounts.find(
    (a) => a.accountId === accountId,
  )?.relationship;
  const relatedAccounts = uniqBy(
    patients
      .filter((p) => (patientId ? p.id === patientId : true))
      .map((p) => p.accounts)
      .flat(),
    'accountId',
  );
  const moreChildren = patients.filter((patient) => patient.id !== patientId);

  useEffect(() => {
    async function fetchAccountStateOfResidence() {
      if (account?.postalCode) {
        const state = await stateFromZip(account?.postalCode);
        setAccountStateOfResidence(state);
      }
    }

    fetchAccountStateOfResidence();
  }, [account?.postalCode]);

  return (
    <Fragment>
      <SidebarCard css={{ marginTop: 0 }}>
        <div style={{ padding: 16 }}>
          <SidebarSectionHeader
            onClick={() => setIsPatientExpanded(!isPatientExpanded)}
          >
            <SidebarSectionTitle>About this Patient</SidebarSectionTitle>
            <Caret src={caret} isSectionVisible={isPatientExpanded} />
          </SidebarSectionHeader>
          <CardInfo onClick={() => onSelectPatient(patientId)}>
            <PatientAvatar patientId={patientId} size={64} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 16,
              }}
            >
              <CardInfoTitle style={{ marginBottom: 4 }}>
                {patient?.firstName}
                {patient?.goesByName ? ` ‘${patient.goesByName}’ ` : ' '}
                {patient?.lastName}
              </CardInfoTitle>
              {patient?.dateOfBirth && (
                <CardInfoText style={{ marginBottom: 4 }}>
                  {formatDateOfBirth(patient?.dateOfBirth)} (
                  {getDateOfBirth({ date: patient?.dateOfBirth })})
                </CardInfoText>
              )}
              <CardInfoText>
                {patient?.preferredPronounType === 'other'
                  ? patient.preferredPronoun
                  : PREFERRED_PRONOUN.find(
                      (p) => p.value === patient?.preferredPronounType,
                    )?.label}
              </CardInfoText>
            </div>
          </CardInfo>
        </div>
        <CardDetails
          style={{
            height: isPatientExpanded ? 'auto' : 0,
            padding: isPatientExpanded ? 16 : 0,
            borderTop: isPatientExpanded
              ? `1px solid ${Colors.grayLighter}`
              : 'none',
          }}
        >
          <CardDetailTitle style={{ marginBottom: 4 }}>
            Location
          </CardDetailTitle>
          <CardInfoText style={{ marginBottom: 16 }}>
            {patient?.address?.formatted_address ??
              accountStateOfResidence ??
              'Unknown'}
          </CardInfoText>
          <CardDetailTitle style={{ marginBottom: 4 }}>
            Caregivers
          </CardDetailTitle>
          {relatedAccounts.map((account, i) => {
            return (
              <SidebarLink
                key={account.accountId}
                onClick={() => onSelectAccount(account.accountId)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: i === relatedAccounts.length - 1 ? 0 : 8,
                }}
              >
                <AccountAvatar accountId={accountId} size={24} />
                <CardInfoText style={{ marginLeft: 8 }}>
                  {account?.account_limited?.goesByName ||
                    account?.account_limited?.firstName}{' '}
                  {account?.account_limited?.lastName}{' '}
                  {patientId && `(${capitalize(account.relationship || '')})`}
                </CardInfoText>
              </SidebarLink>
            );
          })}
        </CardDetails>
      </SidebarCard>
      <SidebarCard>
        <div style={{ padding: 16 }}>
          <SidebarSectionHeader
            onClick={() => setIsAccountExpanded(!isAccountExpanded)}
          >
            <SidebarSectionTitle>About this Caregiver</SidebarSectionTitle>
            <Caret src={caret} isSectionVisible={isAccountExpanded} />
          </SidebarSectionHeader>
          <CardInfo onClick={() => onSelectAccount(accountId)}>
            <AccountAvatar accountId={accountId} size={64} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 16,
              }}
            >
              <CardInfoTitle style={{ marginBottom: 4 }}>
                {account?.firstName}
                {account?.goesByName ? ` ‘${account.goesByName}’ ` : ' '}
                {account?.lastName}
              </CardInfoTitle>
              <CardInfoText style={{ marginBottom: 4 }}>
                {
                  RELATIONSHIPS.find((r) => r.value === relationToPatient)
                    ?.label
                }
              </CardInfoText>
              <CardInfoText>
                {account?.preferredPronounType === 'other'
                  ? account.preferredPronoun
                  : PREFERRED_PRONOUN.find(
                      (p) => p.value === account?.preferredPronounType,
                    )?.label}
              </CardInfoText>
            </div>
          </CardInfo>
        </div>
        <CardDetails
          style={{
            height: isAccountExpanded ? 'auto' : 0,
            padding: isAccountExpanded ? 16 : 0,
            borderTop: isAccountExpanded
              ? `1px solid ${Colors.grayLighter}`
              : 'none',
          }}
        >
          <CardDetailTitle style={{ marginBottom: 4 }}>Email</CardDetailTitle>
          <CardInfoLink
            style={{ marginBottom: 16 }}
            href={`mailto:${account?.email}`}
          >
            {account?.email}
          </CardInfoLink>
          <CardDetailTitle style={{ marginBottom: 4 }}>
            Phone number
          </CardDetailTitle>
          <CardInfoText style={{ marginBottom: 16 }}>
            {formatPhoneNumber(account?.phoneNumber)}
          </CardInfoText>
          {moreChildren.length > 0 && (
            <CardDetailTitle style={{ marginBottom: 4 }}>
              More children
            </CardDetailTitle>
          )}
          {moreChildren.map((patient) => {
            const { id, dateOfBirth, goesByName, firstName, lastName } =
              patient;
            return (
              <SidebarLink key={id} onClick={() => onSelectPatient(id)}>
                {goesByName || firstName} {lastName} (
                {dateOfBirth && getDateOfBirth({ date: dateOfBirth })})
              </SidebarLink>
            );
          })}
        </CardDetails>
      </SidebarCard>
    </Fragment>
  );
};

const SidebarChatSection: React.FC<{
  currentChatId?: string;
  accountId: string;
  patientId: string;
  onSelectChat: (id: string) => void;
}> = ({ accountId, currentChatId, patientId, onSelectChat }) => {
  const [isSectionVisible, setIsSectionVisible] = useState(true);

  const { data } = useLoadChatSessionsQuery({
    variables: {
      where: {
        ...(!patientId
          ? { chat_sessions_accounts: { accountId: { _eq: accountId } } }
          : {}),
        ...(patientId
          ? {
              chat_sessions_patients: { patientId: { _eq: patientId } },
            }
          : {}),
      },
    },
    skip: !(accountId || patientId),
  });

  const chats =
    data?.chat_sessions.filter((session) => session.id !== currentChatId) ?? [];

  const sortedChats = chats.reverse();

  return (
    <SidebarSection>
      <SidebarSectionHeader
        onClick={() => setIsSectionVisible(!isSectionVisible)}
      >
        <SidebarSectionTitle>Recent Chats</SidebarSectionTitle>
        <Caret src={caret} isSectionVisible={isSectionVisible} />
      </SidebarSectionHeader>
      <PillWrapper style={{ height: isSectionVisible ? 'auto' : 0 }}>
        {sortedChats.length === 0 ? (
          <SidebarText>None</SidebarText>
        ) : (
          sortedChats.map((session) => {
            const formattedDate = DateTime.fromISO(session.createdAt).toFormat(
              'D',
            );
            return (
              <Pill key={session.id} onClick={() => onSelectChat(session.id)}>
                {formattedDate}
              </Pill>
            );
          })
        )}
      </PillWrapper>
    </SidebarSection>
  );
};

const SidebarVisitSection: React.FC<{
  accountId: string;
  patientId: string;
  onSelectVisit: (id: string) => void;
}> = ({ accountId, patientId, onSelectVisit }) => {
  const [isSectionVisible, setIsSectionVisible] = useState(true);

  const { data } = useAppointmentScheduleQuery({
    variables: {
      where: {
        ...(!patientId ? { accountId: { _eq: accountId } } : {}),
        ...(patientId
          ? { appointment_patients: { patient_id: { _eq: patientId } } }
          : {}),
      },
    },
    skip: !(accountId || patientId),
  });

  const visits = data?.appointments ?? [];
  const sortedVisits = [...visits].sort(
    (a, b) =>
      DateTime.fromISO(b.startTime).toSeconds() -
      DateTime.fromISO(a.startTime).toSeconds(),
  );

  return (
    <SidebarSection>
      <SidebarSectionHeader
        onClick={() => setIsSectionVisible(!isSectionVisible)}
      >
        <SidebarSectionTitle>Recent Visits</SidebarSectionTitle>
        <Caret src={caret} isSectionVisible={isSectionVisible} />
      </SidebarSectionHeader>
      <PillWrapper style={{ height: isSectionVisible ? 'auto' : 0 }}>
        {sortedVisits.length === 0 ? (
          <SidebarText>None</SidebarText>
        ) : (
          sortedVisits.map((visit) => {
            const formattedDate = DateTime.fromISO(visit.startTime).toFormat(
              'D',
            );
            return (
              <Pill key={visit.id} onClick={() => onSelectVisit(visit.id)}>
                {formattedDate}
              </Pill>
            );
          })
        )}
      </PillWrapper>
    </SidebarSection>
  );
};
