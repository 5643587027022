import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { DialogOverlay } from '@reach/dialog';
import React from 'react';
import { ButtonRed, OutlineButton } from './button';
import { SmallModal } from './layout';
import { ModalText, ModalTitle } from './text';

export const CancelButton = styled(OutlineButton)`
  border-color: ${Colors.grayLight};
  border-radius: 100px;
  color: ${Colors.darkHarbor};
  padding: 15px 30px;
  &:active,
  &:visited,
  &:hover {
    border: 1px solid ${Colors.grayLight};
    background-color: ${Colors.grayLightest};
  }
`;

const RemoveCaregiverConfirmation: React.FC<{
  isOpen: boolean;
  onCancel: any;
  onRemove: () => void;
  patientName: string;
  accountName: string;
}> = ({ isOpen, onCancel, onRemove, patientName, accountName }) => {
  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={onCancel}
      style={{
        zIndex: 100,
      }}
    >
      <SmallModal aria-label="Remove Caregiver">
        <ModalTitle>Are you sure?</ModalTitle>

        <ModalText>
          Once you remove {accountName} they will no longer have access to{' '}
          {patientName}.
        </ModalText>

        <ButtonRed
          tabIndex={0}
          style={{ display: 'inline-block' }}
          onClick={onRemove}
        >
          Remove
        </ButtonRed>
        <CancelButton style={{ display: 'inline-block' }} onClick={onCancel}>
          Cancel
        </CancelButton>
      </SmallModal>
    </DialogOverlay>
  );
};

export default RemoveCaregiverConfirmation;
