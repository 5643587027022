/** @jsx jsx */
import { useLoadStaffAndServicesQuery } from '@bc/codegen/manager';
import { Colors, Spacing } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React, { Fragment, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useOnClickOutside from 'use-onclickoutside';
import LogoSrc from '../assets/bravecare_logo.svg';
import CaretDropdownIcon from '../assets/caret.svg';
import { useAuth0 } from '../react-auth0';
import { FONT_PRIMARY, IF_DESKTOP, IF_MOBILE, IF_TABLET } from '../styles';
import { FakeSearchInput } from './FakeSearchInput';

const Banner = styled.div`
  ${FONT_PRIMARY};
  align-items: center;
  background: ${Colors.errorRed};
  color: ${Colors.white};
  display: flex;
  height: 72px;
  justify-content: center;
  padding: 10px 12px;
  z-index: 10001;
  ${IF_TABLET} {
    padding: 16px 24px;
  }
`;

const HeaderWrap = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
  background: ${Colors.white};
  z-index: 10001;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 72px;
  flex-direction: row;
  padding: 10px 12px;
  ${IF_TABLET} {
    padding: 16px 24px;
  }
`;

const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  // margin: 0 0 0 auto;
  margin: 0;
`;

const Logo = styled.img`
  width: 50px;
  height: 33px;
  object-fit: none;
  object-position: 0 0;
  ${IF_MOBILE} {
    width: auto;
    height: auto;
  }
`;

const AvatarDisplay: React.FC<{ userId: string }> = ({ userId }) => {
  const { data } = useLoadStaffAndServicesQuery({
    variables: {
      where: {
        auth0UserId: {
          _eq: userId,
        },
      },
    },
  });

  const staff = data?.staff?.[0];

  return (
    <Avatar
      src={staff?.avatarUrl || 'https://bravecare.imgix.net/staff/empty.png'}
    />
  );
};

export const Header: React.FC<{ loggedIn?: boolean }> = ({ loggedIn }) => {
  const { user, logout } = useAuth0();
  const history = useHistory();
  const location = useLocation();

  return (
    <Fragment>
      <HeaderWrap>
        <div css={{ alignItems: 'center', display: 'flex' }}>
          <Link to="/dashboard" css={{ paddingRight: Spacing.m }}>
            <Logo src={LogoSrc} alt="Brave Care Logo" />
          </Link>
        </div>
        {loggedIn && (
          <HeaderInner>
            <FakeSearchInput
              onClick={() => {
                history.push({
                  pathname: location.pathname,
                  state: { search: true },
                });
              }}
              onKeyPress={(e) => {
                if ([' ', 'Enter'].includes(e.key)) {
                  e.preventDefault();
                  history.push({
                    pathname: location.pathname,
                    state: { search: true },
                  });
                }
              }}
            />
            <DropdownMenu
              user={user}
              name={user?.name || 'Logout'}
              logout={logout}
            />
          </HeaderInner>
        )}
      </HeaderWrap>
    </Fragment>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
  background: ${Colors.white};
  justify-content: flex-end;
  position: relative;
`;

const Name = styled.p`
  ${FONT_PRIMARY}
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0.1px;
  color: ${Colors.gray};
  margin: 0 16px;
  cursor: pointer;
  display: none;
  ${IF_DESKTOP} {
    display: block;
  }
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 20px;
  object-fit: contain;
`;

const Caret = styled.img<{ isMenuVisible: boolean }>`
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
  filter: invert(60%) sepia(17%) saturate(140%) hue-rotate(201deg)
    brightness(93%) contrast(91%);
  ${({ isMenuVisible }) => isMenuVisible && `transform: rotate(180deg);`}
`;

const DropdownWrapper = styled.div<{ isMenuVisible: boolean }>`
  position: absolute;
  background: ${Colors.white};
  border: 1px solid ${Colors.grayLighter};
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  top: 100%;
  width: 100%;
  ${({ isMenuVisible }) => !isMenuVisible && `display: none;`}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const DropdownItem = styled.p`
  ${FONT_PRIMARY};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  cursor: pointer;
  color: ${Colors.darkHarbor};
  padding: 16px 24px;
`;

export const MenuItem = styled(DropdownItem)`
  margin-right: 16px;
  padding: 0;
`;

export const DropdownMenu: React.FC<{
  name: string;
  logout: any;
  user: any;
}> = ({ user, logout, name }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setIsMenuVisible(false);
  });

  const handleClick = () => {
    setIsMenuVisible(!isMenuVisible);
  };
  return (
    <Fragment>
      <Wrapper onClick={handleClick} ref={ref}>
        <Name>{name}</Name>
        {user && <AvatarDisplay userId={user.sub} />}
        <Caret src={CaretDropdownIcon} isMenuVisible={isMenuVisible} />
        <DropdownWrapper isMenuVisible={isMenuVisible}>
          <StyledLink
            to="/"
            onClick={() => {
              if (!isMenuVisible) return;
              sessionStorage.removeItem('auth_token');
              logout({
                returnTo: window.location.origin,
              });
            }}
          >
            <DropdownItem>Log Out</DropdownItem>
          </StyledLink>
        </DropdownWrapper>
      </Wrapper>
    </Fragment>
  );
};

export const MenuButton: React.FC<{
  to: string;
  text: string;
}> = ({ to, text }) => (
  <StyledLink to={to}>
    <MenuItem>{text}</MenuItem>
  </StyledLink>
);
