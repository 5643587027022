/** @jsx jsx */
import { StaffFragment } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import { DateTime } from 'luxon';
import React from 'react';
import { StaffImage } from '../Schedule/AppointmentDisplay';
import { ApptTime, Box, Reason, Title, Wrap } from './BlockerDisplay.styles';

const isBeforeNow = (date: DateTime) => DateTime.local() > date;

interface Props {
  startTime: DateTime;
  endTime: DateTime;
  className?: string;
  style?: React.CSSProperties;
  reason?: string;
  staff?: Partial<StaffFragment>[];
  onClick: () => void;
  height: number;
}

const AppointmentBlock = ({
  startTime,
  endTime,
  className,
  style,
  reason,
  staff: staffs,
  onClick,
  height,
}: Props) => {
  const isInThePast = isBeforeNow(endTime);
  const condensed = height <= 35;

  let leftBorderColor = '#FF5469';
  let leftBorderWidth = 4;

  if (isInThePast) {
    leftBorderColor = Colors.grayLight;
  }

  return (
    <Wrap
      className={className}
      style={style}
      data-testid="block-piece"
      onClick={onClick}
      css={{
        ':hover': {
          [`& ${Reason}`]: {
            display: 'block',
          },
        },
      }}
    >
      <Box
        css={{
          backgroundColor: '#FFF',
          backgroundImage:
            'linear-gradient(135deg, #ffeaed 37.50%, #ffffff 37.50%, #ffffff 50%, #ffeaed 50%, #ffeaed 87.50%, #ffffff 87.50%, #ffffff 100%)',
          backgroundSize: '11.31px 11.31px',
          padding: condensed ? '4px 8px' : '8px 10px',
        }}
      >
        <div
          css={{
            backgroundColor: leftBorderColor,
            borderRadius: '100px',
            marginLeft: leftBorderWidth > 4 ? -2 : 0,
            marginRight: 8,
            transition: 'all 0.5s ease-out',
            width: `${leftBorderWidth}px`,
          }}
        />
        <div
          css={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Title>Time Block</Title>
              {condensed && (
                <ApptTime css={{ marginLeft: '4px' }}>
                  {startTime.toFormat('h:mm a')}
                </ApptTime>
              )}
            </div>

            {!!staffs?.length && (
              <div
                css={{
                  position: 'relative',
                  width: `${(staffs?.length ?? 0) * 24}px`,
                  height: condensed ? '24px' : '32px',
                }}
              >
                {staffs?.map((staff, i) => {
                  return (
                    <StaffImage
                      key={staff.id}
                      src={staff.avatarUrl ?? undefined}
                      title={`${staff.firstName} ${staff.lastName}`}
                      css={{
                        left: `${i * 28}px`,
                        zIndex: staffs.length - i,
                        width: condensed ? '24px' : '32px',
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
          {!condensed && <ApptTime>{startTime.toFormat('h:mm a')}</ApptTime>}
          <Reason
            css={{
              display: condensed ? 'none' : 'block',
            }}
          >
            {reason}
          </Reason>
        </div>
      </Box>
    </Wrap>
  );
};

export default AppointmentBlock;
