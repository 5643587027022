/** @jsx jsx */
import { useSearchAccountsAndPatientsQuery } from '@bc/codegen/medical';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { parsePhoneNumberFromString } from 'libphonenumber-js/min';
import { Fragment, useState } from 'react';
import { useDebounce } from 'use-debounce';
import SearchIcon from '../../assets/search.svg';
import {
  AccountAvatarDisplay,
  PatientAvatarDisplay,
} from '../../components/AppointmentDetails/AvatarDisplay';
import { InputWithIcon } from '../../components/form';
import { PrimaryText, SmallTitle } from '../../components/text';
import { formatDateOfBirth, getDateOfBirth } from '../../helpers';
import {
  ACCENT_BLUE,
  ACCENT_BLUE_EXTRA_LIGHT,
  ACCENT_GREEN,
  ACCENT_GREEN_EXTRA_LIGHT,
  GREY_LIGHT,
  IF_TABLET,
} from '../../styles';

const ImageSize = css({
  width: 48,
  height: 48,
  [IF_TABLET]: {
    width: 72,
    height: 72,
  },
});

const Row = styled.div({
  borderBottom: `1px solid ${GREY_LIGHT}`,
  display: 'flex',
  flexDirection: 'row',
  padding: '20px 0',
  cursor: 'pointer',
  flexWrap: 'wrap',
  alignItems: 'center',
});

const PersonTypePill = ({ isPatient = false }) => (
  <div
    css={{
      backgroundColor: isPatient
        ? ACCENT_GREEN_EXTRA_LIGHT
        : ACCENT_BLUE_EXTRA_LIGHT,
      borderRadius: 13,
      padding: '3px 20px',
      marginTop: 8,
      [IF_TABLET]: {
        marginTop: 0,
      },
    }}
  >
    <PrimaryText css={{ color: isPatient ? ACCENT_GREEN : ACCENT_BLUE }}>
      {isPatient ? 'Patient' : 'Caregiver'}
    </PrimaryText>
  </div>
);

const TextWrapper = styled.div({
  flex: 1,
  marginLeft: 16,
  minWidth: 150,
  [IF_TABLET]: {
    marginLeft: 24,
  },
});

export const displayDOB = (dateOfBirth: string) => {
  return `${formatDateOfBirth(dateOfBirth)} (${getDateOfBirth({
    date: dateOfBirth,
  })})`;
};

export interface PersonResponse {
  type: 'PATIENT' | 'ACCOUNT';
  id: string;
}

const UserSearch = ({
  onSelect,
}: {
  onSelect: (type: PersonResponse) => void;
}) => {
  const [query, setQuery] = useState('');
  const [search] = useDebounce(query, 300);
  const { data, loading } = useSearchAccountsAndPatientsQuery({
    fetchPolicy: 'no-cache',
    variables: { search, limit: 5 },
    skip: !search || search.length < 3,
  });

  return (
    <Fragment>
      <div
        css={{
          position: 'relative',
        }}
      >
        <InputWithIcon
          icon={SearchIcon}
          value={query}
          placeholder="Search for caregivers or patients by name or phone number"
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          name="search_users"
        />
        {!loading && !!query && (
          <div
            css={{
              position: 'absolute',
              top: '100%',
              left: '0',
              right: '0',
              backgroundColor: '#FFF',
              zIndex: 100,
              boxShadow:
                '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
              borderRadius: '16px',
              padding: '0 10px',
            }}
          >
            {data?.search_patients.map((person) => (
              <Row
                key={person.id}
                onClick={() => {
                  onSelect({ type: 'PATIENT', id: person.id });
                  setQuery('');
                }}
              >
                <PatientAvatarDisplay patientId={person.id} css={ImageSize} />
                <TextWrapper>
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <SmallTitle css={{ display: 'block', marginRight: 9 }}>
                      {person.firstName} {person.lastName}
                    </SmallTitle>
                  </div>
                  <PrimaryText css={{ color: '#ADB8B7', display: 'block' }}>
                    {displayDOB(person.dateOfBirth)}
                  </PrimaryText>
                </TextWrapper>
                <PersonTypePill isPatient />
              </Row>
            ))}

            {data?.search_accounts.map((person) => (
              <Row
                key={person.id}
                onClick={() => {
                  onSelect({ type: 'ACCOUNT', id: person.id });
                  setQuery('');
                }}
              >
                <AccountAvatarDisplay accountId={person.id} css={ImageSize} />
                <TextWrapper>
                  <SmallTitle css={{ display: 'block' }}>
                    {person.firstName} {person.lastName}
                  </SmallTitle>
                  <PrimaryText css={{ color: '#ADB8B7', display: 'block' }}>
                    {parsePhoneNumberFromString(
                      person.phoneNumber,
                    )?.formatNational()}
                  </PrimaryText>
                  {person.account_patients?.map((accountPatient) => (
                    <PatientAvatarDisplay
                      key={accountPatient.patient?.id}
                      patientId={accountPatient.patient?.id}
                      alt={`${accountPatient.patient?.firstName} ${accountPatient.patient?.lastName}`}
                      title={`${accountPatient.patient?.firstName} ${accountPatient.patient?.lastName}`}
                      style={{
                        height: 32,
                        width: 32,
                        margin: '5px 9px 0 0',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        onSelect({
                          type: 'PATIENT',
                          id: accountPatient.patient.id,
                        });
                        setQuery('');
                      }}
                    />
                  ))}
                </TextWrapper>
                <PersonTypePill />
              </Row>
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default UserSearch;
