/** @jsx jsx */
import {
  useDeleteAppointmentBlockMutation,
  useLoadAppointmentBlockByPkQuery,
  useUpsertAppointmentBlockMutation,
} from '@bc/codegen/medical';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { DialogContent } from '@reach/dialog';
import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useCurrentStaff } from '../../lib/staff';
import { Button, ButtonRed, CloseButton } from '../button';
import { HeaderEdit, SectionContent } from '../layout';
import { DefinitionList, DefinitionListItem } from '../List';
import { ModalWrap } from '../Routing';
import { MediumTitle, PrimaryText } from '../text';
import BlockerForm from './BlockerForm';

interface Values {
  reason: string;
  startTime: string;
  endTime: string;
  staffs: string[];
}

const Content = styled(DialogContent)`
  width: 100%;
  max-width: 760px;
  margin: 32px auto;
  padding: 0;
  border-radius: 5px;
`;

const headerStyle = css`
  display: flex;
  align-items: baseline;
  padding: 0 0 16px 0;
`;

export const ViewBlock = ({
  blockId,
  onDismiss,
}: {
  blockId: string;
  onDismiss: () => void;
}) => {
  const [editing, setEditing] = useState(false);
  const { data } = useLoadAppointmentBlockByPkQuery({
    variables: {
      id: blockId,
    },
  });

  const [upsertBlock] = useUpsertAppointmentBlockMutation();
  const [deleteBlock] = useDeleteAppointmentBlockMutation();
  const staff = useCurrentStaff();
  const block = data?.appointment_blocks_by_pk;

  if (!block) {
    return null;
  }

  const date = DateTime.fromISO(block?.startDateTime);

  const handleDelete = async () => {
    await deleteBlock({
      variables: {
        blockId,
      },
    });
    onDismiss();
  };
  const handleInsert = async (values: Values) => {
    const startTimeParsed = DateTime.fromFormat(values.startTime, 'h:mm a');
    const endTimeParsed = DateTime.fromFormat(values.endTime, 'h:mm a');
    const wrongWay = startTimeParsed.diff(endTimeParsed).milliseconds > 0;

    if (wrongWay) {
      return;
    }

    const startDateTime = date
      .set({
        hour: startTimeParsed.hour,
        minute: startTimeParsed.minute,
      })
      .startOf('minute')
      .toSQL({
        includeZone: false,
        includeOffset: false,
      });
    const endDateTime = date
      .set({
        hour: endTimeParsed.hour,
        minute: endTimeParsed.minute,
      })
      .startOf('minute')
      .toSQL({
        includeZone: false,
        includeOffset: false,
      });

    await upsertBlock({
      variables: {
        id: blockId,
        block: {
          reason: values.reason,
          createdBy: staff?.id,
          startDateTime: startDateTime,
          endDateTime: endDateTime,
        },
        staff: values.staffs?.map((staffId) => {
          return {
            staffId,
            appointmentBlockId: blockId,
          };
        }),
      },
    });
    onDismiss();
  };

  const startOfVisit = DateTime.fromISO(block?.startDateTime);
  const formattedStartTime = startOfVisit.toFormat('h:mm a');

  const endOfVisit = DateTime.fromISO(block?.endDateTime);
  const formattedEndTime = endOfVisit.toFormat('h:mm a');

  const staffNames = block?.appointment_blocks_staffs?.map((appBlock) => {
    return `${appBlock?.staff?.firstName} ${appBlock?.staff?.lastName}`;
  });

  return (
    <ModalWrap onDismiss={onDismiss} aria-label="Time Block Form">
      <Content>
        <SectionContent css={{ padding: 40, position: 'relative' }}>
          <CloseButton
            css={{
              border: 'none',
              position: 'absolute',
              top: 15,
              right: 15,
              ':hover': { border: 'none' },
            }}
            onClick={onDismiss}
          />
          <HeaderEdit
            css={[headerStyle, editing && { marginBottom: 16 }]}
            editable
            editing={editing}
            onCancel={() => {
              setEditing(false);
            }}
            onEdit={() => {
              setEditing(true);
            }}
          >
            <MediumTitle>
              {editing ? 'Edit Time Block' : 'Time Block'}
            </MediumTitle>
          </HeaderEdit>
          {!editing && (
            <div>
              <DefinitionList>
                <DefinitionListItem term="Time">
                  {formattedStartTime} - {formattedEndTime}
                </DefinitionListItem>
                <DefinitionListItem term="Description">
                  {block?.reason}
                </DefinitionListItem>
                <DefinitionListItem term="Created by">
                  {block?.staff?.firstName} {block?.staff?.lastName}
                </DefinitionListItem>
                <DefinitionListItem term="Blocked For">
                  <PrimaryText>{staffNames.join(', ')}</PrimaryText>
                </DefinitionListItem>
              </DefinitionList>
            </div>
          )}
          {editing && (
            <Formik
              onSubmit={handleInsert}
              initialValues={{
                reason: block.reason ?? '',
                startTime: formattedStartTime,
                endTime: formattedEndTime,
                staffs: block?.appointment_blocks_staffs?.map((apptStaff) => {
                  return apptStaff.staff.id;
                }),
              }}
            >
              {() => {
                return (
                  <Form>
                    <BlockerForm />
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '24px',
                      }}
                    >
                      <ButtonRed onClick={handleDelete} type="button">
                        Delete
                      </ButtonRed>
                      <Button type="submit">Save</Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </SectionContent>
      </Content>
    </ModalWrap>
  );
};
