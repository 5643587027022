/** @jsx jsx */
import {
  GlobalNoteFragment,
  Global_Notes_Bool_Exp,
  useWatchGlobalNotesSubscription,
} from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React, { Fragment, useState } from 'react';
import { ICONS } from '../../assets';
import { PrimaryText, SmallPrimaryText, SmallTitle } from '../text';
import { AddFlagForm } from './AddFlagForm';
import { ArchivedFlagsModal } from './ArchivedFlagsModal';
import { ArchiveFlagModal } from './ArchiveFlagModal';
import { Flag } from './Flag';
import {
  AddLink,
  ArchiveLink,
  Container,
  Flags,
  Header,
  Icon,
  TitleContainer,
} from './styles';

const Title = styled(PrimaryText)({
  fontSize: 16,
  fontWeight: 'bold',
  lineHeight: 1.375,
}).withComponent('h3');

export const FlagsSidebar: React.FC<{
  accountId?: string;
  patientId?: string;
  condensed?: boolean;
}> = ({ accountId, patientId, condensed }) => {
  const [archiveNote, setArchiveNote] = useState<GlobalNoteFragment>();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [showArchiveNotes, setShowArchiveNotes] = useState(false);

  let query: Global_Notes_Bool_Exp = {
    _or: [],
  };

  if (accountId) {
    query._or?.push({
      accountId: { _eq: accountId },
    });
  }

  if (patientId) {
    query._or?.push({
      patientId: { _eq: patientId },
    });
  }

  const { data: globalNoteData } = useWatchGlobalNotesSubscription({
    variables: {
      where: query,
      orderBy: [{ createdAt: 'desc' }],
    },
    skip: !accountId && !patientId,
  });

  if (!accountId && !patientId) {
    return null;
  }

  const title =
    !accountId && patientId && !condensed
      ? 'Patient Flags'
      : accountId && !patientId
      ? 'Caregiver Flags'
      : 'Flags';

  const globalNotes = globalNoteData?.global_notes ?? [];
  const activeNotes = globalNotes.filter((note) => !note.deleted);
  const archivedNotes = globalNotes.filter((note) => !!note.deleted);

  return (
    <Fragment>
      <Container>
        <Header>
          <TitleContainer>
            {!condensed && <Icon src={ICONS.flag} />}
            {condensed ? (
              <Title>{title}</Title>
            ) : (
              <SmallTitle>{title}</SmallTitle>
            )}
          </TitleContainer>
          {!isFormVisible && (
            <AddLink onClick={() => setIsFormVisible(true)}>
              <Icon src={ICONS.add} css={{ marginRight: 4 }} />
              Add flag
            </AddLink>
          )}
        </Header>
        {isFormVisible && (
          <AddFlagForm
            accountId={accountId}
            patientId={patientId}
            onCancel={() => setIsFormVisible(false)}
          />
        )}
        <Flags>
          {activeNotes.length === 0 && (
            <SmallPrimaryText css={{ display: 'block', paddingTop: 8 }}>
              No flags added.
            </SmallPrimaryText>
          )}
          {activeNotes.map((note) => (
            <Flag
              key={note.id}
              note={note}
              onDelete={(note) => setArchiveNote(note)}
            />
          ))}
        </Flags>
        {!!archivedNotes.length && (
          <ArchiveLink onClick={() => setShowArchiveNotes(true)}>
            View archive
          </ArchiveLink>
        )}
      </Container>
      <ArchiveFlagModal
        isOpen={!!archiveNote}
        note={archiveNote}
        onCancel={() => setArchiveNote(undefined)}
      />
      {showArchiveNotes && (
        <ArchivedFlagsModal
          isOpen={showArchiveNotes}
          archivedNotes={archivedNotes}
          onClose={() => setShowArchiveNotes(false)}
        />
      )}
    </Fragment>
  );
};
