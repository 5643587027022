import {
  useFindAccountQuery,
  useGenerateTemporaryVerificationCodeMutation,
} from '@bc/codegen/medical';
import * as Sentry from '@sentry/react';
import { parsePhoneNumberFromString } from 'libphonenumber-js/min';
import React, { Fragment, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import SearchIcon from '../../assets/search.svg';
import { Button } from '../../components/button';
import { InputWrap } from '../../components/form';
import Loader from '../../components/Loader/loader';
import { PhoneInput } from '../../components/PhoneInput';
import { PrimaryText, SmallTitle } from '../../components/text';

const VerificationPinLookup: React.FC<
  { selected: boolean } & RouteComponentProps
> = ({ history, selected }) => {
  const [generateTemporaryVerificationCode, { data, loading }] =
    useGenerateTemporaryVerificationCodeMutation();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');
  const isValidPhone = parsedNumber ? parsedNumber.isValid() : false;

  const { data: accountData } = useFindAccountQuery({
    variables: {
      where: {
        phoneNumber: {
          _eq: parsedNumber?.number.toString(),
        },
      },
    },
    skip: !isValidPhone,
    fetchPolicy: 'no-cache', // don't cache in case we're looking up an account that didn't exist and the clinic creates a new one
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValidPhone || !parsedNumber || !accountData?.accounts[0]?.id) {
      setPhoneError(true);
      return;
    }

    try {
      setPhoneError(false);

      await generateTemporaryVerificationCode({
        variables: {
          accountId: accountData?.accounts[0]?.id!,
        },
      });
    } catch (error) {
      Sentry.captureException(error);
      setPhoneError(true);
    }
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <InputWrap>
          <PhoneInput
            value={phoneNumber}
            error={phoneError}
            onChange={(value) => {
              setPhoneNumber(value);
            }}
            focused={selected}
            placeholder="Search for pin by caregiver phone number"
            icon={SearchIcon}
          />
        </InputWrap>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            disabled={!isValidPhone}
            data-testid="submit"
            style={{
              width: '150px',
            }}
          >
            Search
          </Button>
        </div>
      </form>
      {loading && <Loader />}

      {data?.GenerateTemporaryVerificationCode?.code && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid #E5E8E8',
            padding: '24px',
            borderRadius: '6px',
            marginTop: '24px',
          }}
        >
          <SmallTitle data-testid="verification-code">
            Code: {data?.GenerateTemporaryVerificationCode?.code}
          </SmallTitle>
          <PrimaryText>Provide code to caregiver</PrimaryText>
        </div>
      )}
    </Fragment>
  );
};

export default VerificationPinLookup;
