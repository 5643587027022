import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string;
}

const TasksIcon: React.FC<Props> = ({ color, ...props }) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      clipRule="evenodd"
      d="M18.111 23H5.89A4.903 4.903 0 011 18.111V5.89C1 3.2 3.2 1 5.889 1H18.11C20.8 1 23 3.2 23 5.889V18.11C23 20.8 20.8 23 18.111 23z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 10.2L13.2 15l-3.5-3.5M8.4 15L6 12.6M14.4 9l-1.2 1.2"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TasksIcon;
