import {
  useChatSessionsSubscription,
  useLoadChatSessionsQuery,
} from '@bc/codegen/medical';
import { DateTime } from 'luxon';
import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ActiveChatRow, ClosedChatRow } from '../../components/ChatRow';
import { Header } from '../../components/Header';
import { Content, InnerWrap, MainContent } from '../../components/layout';
import { Sidebar } from '../../components/Nav/Sidebar';
import { useMessageWatch } from '../../lib/sound';
import { CollapsibleSection, Title } from './Chats.styles';
import { getChatLabel } from './helpers';

const Chats: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  const { data: queryData } = useLoadChatSessionsQuery();
  const { data: subscriptionData } = useChatSessionsSubscription();
  const data = subscriptionData ?? queryData;
  useMessageWatch();

  const inProgressChats = data?.chat_sessions
    ?.filter(
      (chat) =>
        ['CREATED', 'READY', 'READY_FOR_PROVIDER'].includes(chat.status) ||
        (chat.status === 'CLOSED' &&
          chat.chat_notes.filter((n) => n.type === 'SUMMARY').length === 0),
    )
    .map((chat) => getChatLabel(chat))
    .sort((a, b) => {
      const chatLabelA = a?.chatLabel;
      const chatLabelB = b?.chatLabel;
      const createdAtA = DateTime.fromISO(a?.createdAt).toSeconds();
      const createdAtB = DateTime.fromISO(b?.createdAt).toSeconds();
      // if chats have the same category, sort by descending chat session createdAt
      if (chatLabelA === chatLabelB) {
        return createdAtB - createdAtA;
      }
      return chatLabelA - chatLabelB;
    });

  const finishedChats = data?.chat_sessions
    ?.filter(
      (chat) =>
        chat.status === 'CLOSED' &&
        chat.chat_notes.filter((n) => n.type === 'SUMMARY')[0],
    )
    .map((chat) => getChatLabel(chat))
    .sort((a, b) => {
      const createdAtA = DateTime.fromISO(a?.createdAt).toSeconds();
      const createdAtB = DateTime.fromISO(b?.createdAt).toSeconds();
      // sort by descending chat session createdAt
      return createdAtB - createdAtA;
    });

  const handleChatSelection = (id: string) => {
    history.push(`/chats/${id}`);
  };

  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap>
          <Sidebar />
          <MainContent style={{ paddingTop: '40px' }}>
            <Title>All Chats</Title>
            <CollapsibleSection title="Active" style={{ marginBottom: 32 }}>
              {inProgressChats?.map((chat) => (
                <ActiveChatRow
                  key={chat.id}
                  chatSession={chat}
                  onClick={() => handleChatSelection(chat.id)}
                />
              ))}
            </CollapsibleSection>
            <CollapsibleSection title="Closed">
              {finishedChats?.map((chat) => (
                <ClosedChatRow
                  key={chat.id}
                  chatSession={chat}
                  onClick={() => handleChatSelection(chat.id)}
                />
              ))}
            </CollapsibleSection>
          </MainContent>
        </InnerWrap>
      </Content>
    </Fragment>
  );
};

export default Chats;
