import { Colors, Spacing } from '@bc/theme';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { ICONS } from '../../assets';
import { getParsedToken } from '../../auth';
import { useAuth0 } from '../../react-auth0';
import { PrimaryText } from '../text';

export const NetworkConnectivityAlertBanner = () => {
  const { accessToken, user } = useAuth0();
  const [disconnected, setDisconnected] = useState(false);

  useEffect(() => {
    async function checkConnection() {
      try {
        // check if token is expired
        const { exp } = getParsedToken(accessToken);
        const expiresAt = new Date(exp * 1000);
        if (expiresAt < new Date()) {
          setDisconnected(true);
          return;
        }

        // check internet connection
        await fetch(`${process.env.REACT_APP_ACTION_SERVER}/healthz`, {
          cache: 'no-cache',
          mode: 'no-cors',
        });

        setDisconnected(false);
      } catch {
        setDisconnected(true);
      }
    }

    const poll = setInterval(checkConnection, disconnected ? 5000 : 6000);

    return () => clearInterval(poll);
  }, [accessToken, disconnected, user]);

  return (
    <AlertBanner isConnected={disconnected}>
      It looks like you’re offline. Please check your network connection.
    </AlertBanner>
  );
};

export const AlertBanner = ({
  children,
  isConnected,
}: {
  children: React.ReactNode;
  isConnected: boolean;
}) => (
  <Container isConnected={isConnected}>
    <Icon src={ICONS.error} role="presentation" />
    <Text>{children}</Text>
  </Container>
);

const Container = styled.div<{ isConnected: boolean }>(({ isConnected }) => ({
  alignItems: 'center',
  background: Colors.errorRed,
  display: 'flex',
  justifyContent: 'center',
  height: isConnected ? Spacing.xxl : 0,
  transition: 'all 0.3s ease',
  width: '100%',
}));

const Icon = styled.img({
  filter: 'brightness(0) saturate(100%) invert(100%)',
  height: Spacing.m,
  marginRight: Spacing.s,
  width: Spacing.m,
});

const Text = styled(PrimaryText)({
  color: Colors.white,
});
