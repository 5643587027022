/** @jsx jsx */
import { StaffFragment } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import Downshift from 'downshift';
import { matchSorter } from 'match-sorter';
import { useState } from 'react';
import { FONT_PRIMARY } from '../../styles';
import { InputWrap } from '../form';
import SearchIcon from './icons/search.svg';
import {
  Container,
  Result,
  ResultDropdownContainer,
  ResultsContainer,
} from './ui.staff';

const Input = styled.input(`
  ${FONT_PRIMARY}
  width: 100%;
  padding: 4px 8px 4px 4px;
  border: none;
  border-radius: 100px;
`);

interface Props {
  options: Partial<StaffFragment>[];
  onChange: (value: Partial<StaffFragment> | null) => void;
}

const fuzzyFilter = (input: string, staff: Partial<StaffFragment>[]): any[] => {
  return matchSorter(staff, input, {
    keys: ['firstName', 'lastName', 'email'],
  });
};

const TaskStaffSearch = ({ onChange, options }: Props) => {
  const [inputValue, setFilterValue] = useState('');

  const staffs = options;

  const handleChange = (item: any | null) => {
    onChange(item);
    setFilterValue('');
  };

  return (
    <Downshift
      inputValue={inputValue}
      itemToString={(staff) =>
        staff ? `${staff.firstName} ${staff.lastName}` : ''
      }
      onInputValueChange={(value) => {
        setFilterValue(value);
      }}
      onSelect={handleChange}
    >
      {({
        getRootProps,
        getInputProps,
        getItemProps,
        isOpen,
        openMenu,
        closeMenu,
        clearSelection,
        highlightedIndex,
      }) => {
        return (
          <Container {...getRootProps()}>
            <ResultsContainer>
              <div css={{}}>
                <InputWrap
                  style={{
                    marginBottom: 0,
                    alignItems: 'center',
                    flexDirection: 'row',
                    border: `1px solid ${Colors.grayLight}`,
                    borderRadius: '100px',
                    padding: '2px 0',
                  }}
                >
                  <img src={SearchIcon} />
                  <Input
                    {...getInputProps({
                      onFocus: openMenu,
                      type: 'text',
                      placeholder: 'Search Staff...',
                      autoComplete: 'off',
                      onClick: () => {
                        openMenu();
                      },
                      onBlur: () => {
                        closeMenu();
                        if (!inputValue) {
                          clearSelection();
                        }
                      },
                    })}
                  />
                </InputWrap>
              </div>
              {isOpen && (
                <ResultDropdownContainer>
                  <div role="menu">
                    <div>
                      {fuzzyFilter(inputValue, staffs).map(
                        (member, index: number) => (
                          <Result
                            href="#"
                            highlighted={highlightedIndex === index}
                            {...getItemProps({
                              item: member,
                              index,
                              key: member.id,
                              onClick: (e: any) => e.preventDefault(),
                            })}
                          >
                            <span>
                              {member.firstName} {member.lastName}
                            </span>
                          </Result>
                        ),
                      )}
                    </div>
                  </div>
                </ResultDropdownContainer>
              )}
            </ResultsContainer>
          </Container>
        );
      }}
    </Downshift>
  );
};

export default TaskStaffSearch;
