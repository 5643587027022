/** @jsx jsx */
import { useSearchAccountsAndPatientsQuery } from '@bc/codegen/medical';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { parsePhoneNumberFromString } from 'libphonenumber-js/min';
import { DateTime } from 'luxon';
import React, { Fragment, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import SearchIcon from '../../assets/search.svg';
import {
  AccountAvatarDisplay,
  PatientAvatarDisplay,
} from '../../components/AppointmentDetails/AvatarDisplay';
import { InputWithIcon, InputWrap } from '../../components/form';
import Loader from '../../components/Loader/loader';
import { PrimaryText, SmallTitle } from '../../components/text';
import { formatDateOfBirth, getDateOfBirth } from '../../helpers';
import { getPatientName } from '../../lib/patient';
import {
  ACCENT_BLUE,
  ACCENT_BLUE_EXTRA_LIGHT,
  ACCENT_GREEN,
  ACCENT_GREEN_EXTRA_LIGHT,
  GREY_LIGHT,
  IF_TABLET,
} from '../../styles';

const ImageSize = css({
  width: 48,
  height: 48,
  [IF_TABLET]: {
    width: 72,
    height: 72,
  },
});

const Row = styled.div({
  borderBottom: `1px solid ${GREY_LIGHT}`,
  display: 'flex',
  flexDirection: 'row',
  padding: '20px 0',
  cursor: 'pointer',
  flexWrap: 'wrap',
});

const PersonTypePill = ({ isPatient = false }) => (
  <div
    css={{
      backgroundColor: isPatient
        ? ACCENT_GREEN_EXTRA_LIGHT
        : ACCENT_BLUE_EXTRA_LIGHT,
      borderRadius: 13,
      height: 24,
      padding: '3px 20px',
      marginTop: 8,
      [IF_TABLET]: {
        marginTop: 0,
      },
    }}
  >
    <PrimaryText css={{ color: isPatient ? ACCENT_GREEN : ACCENT_BLUE }}>
      {isPatient ? 'Patient' : 'Caregiver'}
    </PrimaryText>
  </div>
);

const AppointmentLink = styled(PrimaryText)({
  backgroundColor: GREY_LIGHT,
  borderRadius: 3,
  color: '#7C7C7C',
  display: 'inline-block',
  marginRight: 9,
  marginTop: 5,
  padding: '2px 9px',
  textDecoration: 'none',
}).withComponent('a');

const TextWrapper = styled.div({
  flex: 1,
  marginLeft: 16,
  minWidth: 150,
  [IF_TABLET]: {
    marginLeft: 24,
  },
});

export const displayDOB = (dateOfBirth: string) => {
  return `${formatDateOfBirth(dateOfBirth)} (${getDateOfBirth({
    date: dateOfBirth,
  })})`;
};

const usPhoneRegExp = /(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})/;

const UserSearch: React.FC<{ selected: boolean } & RouteComponentProps> = ({
  history,
  selected,
}) => {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 300);
  const search = usPhoneRegExp.test(debouncedQuery)
    ? debouncedQuery.replace(/[^\d+]/g, '')
    : debouncedQuery;
  const { data, loading } = useSearchAccountsAndPatientsQuery({
    fetchPolicy: 'no-cache',
    variables: { search, limit: 5 },
    skip: !search || search.length < 3,
  });

  return (
    <Fragment>
      <InputWrap>
        <InputWithIcon
          icon={SearchIcon}
          value={query}
          focused={selected}
          placeholder="Search for caregivers or patients by name or phone number "
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
      </InputWrap>

      {loading && <Loader />}

      {!loading &&
        data?.search_patients.map((person) => (
          <Row
            key={person.id}
            onClick={() => history.push(`/patient/${person.id}/profile`)}
          >
            <PatientAvatarDisplay patientId={person.id} css={ImageSize} />
            <TextWrapper>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <SmallTitle css={{ display: 'block', marginRight: 9 }}>
                  {getPatientName(person)}
                </SmallTitle>
              </div>
              <PrimaryText css={{ color: '#ADB8B7', display: 'block' }}>
                {displayDOB(person.dateOfBirth)}
              </PrimaryText>
              {person.appointments?.map((appointment) => (
                <AppointmentLink
                  key={appointment.id}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                    history.push(`/appointment/${appointment.id}/visit`);
                  }}
                >
                  {DateTime.fromISO(appointment.startTime).toFormat('MM/dd/yy')}
                </AppointmentLink>
              ))}
            </TextWrapper>
            <PersonTypePill isPatient />
          </Row>
        ))}
      {!loading &&
        data?.search_accounts.map((person) => (
          <Row
            key={person.id}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push(`/account/${person.id}/profile`);
            }}
          >
            <AccountAvatarDisplay accountId={person.id} css={ImageSize} />
            <TextWrapper>
              <SmallTitle css={{ display: 'block' }}>
                {person.firstName} {person.lastName}
              </SmallTitle>
              <PrimaryText css={{ color: '#ADB8B7', display: 'block' }}>
                {parsePhoneNumberFromString(
                  person.phoneNumber,
                )?.formatNational()}
              </PrimaryText>
              {person.account_patients?.map((accountPatient) => (
                <PatientAvatarDisplay
                  key={accountPatient.patient?.id}
                  patientId={accountPatient.patient?.id}
                  alt={`${accountPatient.patient?.firstName} ${accountPatient.patient?.lastName}`}
                  title={`${accountPatient.patient?.firstName} ${accountPatient.patient?.lastName}`}
                  style={{
                    height: 32,
                    width: 32,
                    margin: '5px 9px 0 0',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    history.push(
                      `/patient/${accountPatient.patient?.id}/profile`,
                    )
                  }
                />
              ))}
            </TextWrapper>
            <PersonTypePill />
          </Row>
        ))}
    </Fragment>
  );
};

export default UserSearch;
