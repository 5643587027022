/** @jsx jsx */
import {
  useAddGlobalNoteMutation,
  useWatchGlobalNotesSubscription,
} from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import { Fragment, useState } from 'react';
import { formattedTime } from '../../helpers';
import { useCurrentStaff } from '../../lib/staff';
import { fullStaffName } from '../../pages/staff/helpers';
import { Button } from '../button';
import { ResizingTextarea } from '../form';
import { PrimaryText } from '../text';
import { LargeStaffImage, TextDisplay } from './ui';

const Timestamp = styled(PrimaryText)`
  color: #94a2a1;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
  margin-top: 1px;
`;

export const TaskViewComments = ({ taskId }: { taskId: string }) => {
  const staff = useCurrentStaff();
  const [addNote] = useAddGlobalNoteMutation();
  const [note, setNote] = useState('');
  const [focus, setFocus] = useState(false);

  const handleAddNote = async () => {
    if (!note) {
      return;
    }

    try {
      setNote('');
      await addNote({
        variables: {
          globalNote: {
            taskId,
            note,
          },
        },
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  const { data } = useWatchGlobalNotesSubscription({
    variables: {
      where: {
        taskId: {
          _eq: taskId,
        },
      },
      orderBy: [{ createdAt: 'desc' }],
    },
  });

  const globalNotes = data?.global_notes ?? [];
  return (
    <div>
      <div css={{ display: 'flex', gap: '8px', marginBottom: '24px' }}>
        <LargeStaffImage src={staff?.avatarUrl ?? undefined} />
        <div
          css={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <ResizingTextarea
            value={note}
            placeholder="Write a comment..."
            css={{
              width: '100%',
              paddingBottom: focus || note ? '30px' : undefined,
            }}
            onChange={(e: any) => {
              setNote(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
          {(focus || note) && (
            <Button
              type="button"
              disabled={!note}
              onClick={handleAddNote}
              css={{
                position: 'absolute',
                bottom: '8px',
                right: '8px',
                fontSize: '14px',
                padding: '5px 10px',
              }}
            >
              Comment
            </Button>
          )}
        </div>
      </div>
      {globalNotes.map((note, index) => {
        const createdAt = DateTime.fromISO(note.createdAt);

        return (
          <Fragment>
            <div css={{ display: 'flex', gap: '8px' }}>
              <LargeStaffImage src={note?.staff?.avatarUrl ?? undefined} />
              <div css={{ display: 'flex', flexDirection: 'column' }}>
                <PrimaryText>
                  {fullStaffName(note.staff ?? undefined)}
                </PrimaryText>
                <Timestamp>{formattedTime(createdAt)}</Timestamp>
                <div>
                  <TextDisplay>{note.note}</TextDisplay>
                </div>
              </div>
            </div>
            {index !== globalNotes.length - 1 && (
              <div
                css={{
                  margin: '16px 0',
                  backgroundColor: '#D6DBDB',
                  height: '1px',
                  width: '100%',
                }}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
