/** @jsx jsx */
import {
  Tasks_Bool_Exp,
  Tasks_Order_By,
  useLoadTaskIndexQuery,
  useWatchTasksSubscription,
  WatchTasksSubscription,
} from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import { DateTime } from 'luxon';
import { Fragment, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { ICONS } from '../../assets/index';
import { Header } from '../../components/Header';
import { Content, InnerWrap, MainContent } from '../../components/layout';
import { Sidebar } from '../../components/Nav/Sidebar';
import { TaskFilterDropdown } from '../../components/Tasks/TaskFilterDropdown';
import { TaskSortDropdown } from '../../components/Tasks/TaskSortDropdown';
import { ThOther, ThTask, ThTitle } from '../../components/Tasks/ui';
import { LargeTitle } from '../../components/text';
import { useCurrentStaff } from '../../lib/staff';
import {
  buildWhere,
  getActiveAssignments,
  getHasAllRead,
  hasReadTask,
  isResponsible,
} from '../../lib/task';
import {
  ButtonLink,
  NoTasks,
  pointer,
  SectionRow,
  ShowMoreRow,
  TitleSort,
} from './styles';
import { TaskRow } from './TaskRow';

type ShowMoreType =
  | 'MY_TASKS'
  | 'OPEN_TASKS'
  | 'TASKS_WATCHED'
  | 'COMPLETED_TASKS';

const useSortOrder = () => {
  const [orderBy, setOrderBy] = useLocalStorage<Tasks_Order_By>(
    'task_index_sort',
    {
      dueDateTime: 'asc',
    },
  );

  const createSortToggle = (property: keyof Tasks_Order_By) => () => {
    if (orderBy?.[property] === 'asc') {
      setOrderBy({
        [property]: 'desc',
      });
    } else {
      setOrderBy({
        [property]: 'asc',
      });
    }
  };

  return {
    orderBy: orderBy!,
    createSortToggle,
  };
};

type TasksList = WatchTasksSubscription['tasks'];

const useTaskIndex = (tasks: TasksList, staffId: string) => {
  const myTasks = tasks?.filter((task) => {
    const assignemnts = getActiveAssignments(task);
    const assignedSelf = assignemnts?.[0]?.assignedTo === staffId;
    const hasRead = hasReadTask(task, staffId);
    const responsible = isResponsible(task, staffId);

    return (
      assignedSelf &&
      responsible &&
      ((task.type === 'ACTION' && task.status !== 'COMPLETED') ||
        (task.type === 'INFORMATION' && !hasRead))
    );
  });

  const openTasks = tasks?.filter((task) => {
    if (!staffId) {
      return (
        (task.type === 'ACTION' && task.status === 'OPEN') ||
        task.type === 'INFORMATION'
      );
    }

    const responsible = isResponsible(task, staffId);

    if (task.type === 'ACTION') {
      const assignemnts = getActiveAssignments(task);
      return (
        task.status === 'OPEN' && assignemnts.length === 0 && !!responsible
      );
    } else if (task.type === 'INFORMATION') {
      return !hasReadTask(task, staffId) && responsible;
    }
    return false;
  });

  const tasksWatched =
    tasks?.filter((task) => {
      return task.tasks_watches?.length === 1;
    }) ?? [];

  const completedTasks = tasks?.filter((task) => {
    if (task.type === 'ACTION') {
      return task.status === 'COMPLETED';
    } else if (task.type === 'INFORMATION') {
      return hasReadTask(task, staffId) || getHasAllRead(task);
    }
  });

  return {
    myTasks,
    openTasks,
    tasksWatched,
    completedTasks,
  };
};

const useTaskToggles = () => {
  const [showMore, setShowMore] = useState<ShowMoreType[]>([]);
  const [hidden, setHidden] = useState<ShowMoreType[]>([]);

  const toggleHidden = (type: ShowMoreType) => {
    setHidden((hidden) => {
      if (hidden.includes(type)) {
        return hidden.filter((item) => {
          return item !== type;
        });
      } else {
        return [...hidden, type];
      }
    });
  };

  const toggleShowMore = (type: ShowMoreType) => {
    setShowMore((hidden) => {
      if (hidden.includes(type)) {
        return hidden.filter((item) => {
          return item !== type;
        });
      } else {
        return [...hidden, type];
      }
    });
  };

  return {
    showMore,
    hidden,
    toggleHidden,
    toggleShowMore,
  };
};

const TaskIndex = () => {
  const staff = useCurrentStaff();
  const [additionalFilter, setAdditionalFilter] = useState<
    Tasks_Bool_Exp | undefined
  >();

  const { showMore, hidden, toggleHidden, toggleShowMore } = useTaskToggles();
  const { orderBy, createSortToggle } = useSortOrder();

  const [loadFrom, setLoadFrom] = useState<DateTime>(
    DateTime.local().minus({ week: 2 }).startOf('day'),
  );
  const [loadTo, setLoadTo] = useState<DateTime>(
    DateTime.local().plus({ week: 3 }).endOf('day'),
  );

  const where = useMemo(() => {
    return buildWhere({
      start: loadFrom,
      end: loadTo,
      staffId: staff?.id,
      filters: additionalFilter,
      completed: false,
    });
  }, [staff?.id, additionalFilter, loadFrom, loadTo]);

  const completedWhere = useMemo(() => {
    return buildWhere({
      start: loadFrom,
      end: loadTo,
      staffId: staff?.id,
      filters: additionalFilter,
      completed: true,
    });
  }, [staff?.id, additionalFilter, loadFrom, loadTo]);

  const { data } = useWatchTasksSubscription({
    skip: !staff?.id,
    variables: {
      where: where,
      order_by: orderBy,
      staffId: staff?.id!,
    },
  });

  const { data: completedTaskData } = useLoadTaskIndexQuery({
    skip: !staff?.id,
    fetchPolicy: 'no-cache',
    variables: {
      where: completedWhere,
      order_by: orderBy,
      staffId: staff?.id!,
    },
  });

  const tasks = data?.tasks ?? [];

  let { myTasks, openTasks, tasksWatched } = useTaskIndex(tasks, staff?.id!);
  let { completedTasks } = useTaskIndex(
    completedTaskData?.tasks ?? [],
    staff?.id!,
  );

  if (!showMore.includes('MY_TASKS')) {
    myTasks = myTasks.slice(0, 5);
  }

  if (!showMore.includes('OPEN_TASKS')) {
    openTasks = openTasks.slice(0, 5);
  }

  if (!showMore.includes('TASKS_WATCHED')) {
    tasksWatched = tasksWatched.slice(0, 5);
  }

  if (!showMore.includes('COMPLETED_TASKS')) {
    completedTasks = completedTasks.slice(0, 5);
  }

  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap>
          <Sidebar />
          <MainContent
            style={{
              paddingTop: '16px',
            }}
          >
            <div
              css={{
                padding: '21px 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div css={{ display: 'flex' }}>
                <LargeTitle>Tasks</LargeTitle>
                <ButtonLink
                  to="/tasks/create"
                  css={{
                    padding: '4px 14px',
                    fontWeight: 500,
                    marginLeft: '16px',
                  }}
                >
                  <img
                    src={ICONS.addWhite}
                    css={{
                      width: '18px',
                      height: '18px',
                    }}
                  />
                  <span css={{ marginLeft: '4px' }}>New Task</span>
                </ButtonLink>
              </div>

              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  css={{
                    marginRight: '8px',
                  }}
                >
                  <TaskFilterDropdown
                    value={additionalFilter}
                    dateRange={[loadFrom, loadTo]}
                    onDateChange={(range) => {
                      const [from, to] = range;
                      setLoadFrom(from);
                      setLoadTo(to);
                    }}
                    onChange={(filter) => {
                      setAdditionalFilter(filter);
                    }}
                    onClear={() => {
                      setLoadFrom(
                        DateTime.local().minus({ week: 2 }).startOf('day'),
                      );
                      setLoadTo(
                        DateTime.local().plus({ week: 3 }).endOf('day'),
                      );
                      setAdditionalFilter(undefined);
                    }}
                  />
                </div>
                <TaskSortDropdown
                  value={orderBy}
                  onChange={(sortKey) => {
                    createSortToggle(sortKey)();
                  }}
                />
              </div>
            </div>

            <table css={{ width: '100%' }}>
              <thead>
                <tr>
                  <ThTask onClick={createSortToggle('title')} css={pointer}>
                    <TitleSort direction={orderBy.title}>Task</TitleSort>
                  </ThTask>
                  <ThOther onClick={createSortToggle('priority')} css={pointer}>
                    <TitleSort direction={orderBy.priority}>Priority</TitleSort>
                  </ThOther>
                  <ThOther
                    onClick={createSortToggle('dueDateTime')}
                    css={pointer}
                  >
                    <TitleSort direction={orderBy.dueDateTime}>
                      Due Date
                    </TitleSort>
                  </ThOther>
                  <ThOther>
                    <ThTitle>Responsible</ThTitle>
                  </ThOther>
                  <ThOther>
                    <ThTitle>Claimed By</ThTitle>
                  </ThOther>
                  <ThOther onClick={createSortToggle('status')} css={pointer}>
                    <TitleSort direction={orderBy.status}>Status</TitleSort>
                  </ThOther>
                  <ThOther>
                    <ThTitle>Patient Association</ThTitle>
                  </ThOther>
                  <ThOther
                    css={[
                      {
                        borderRight: 'none',
                      },
                      pointer,
                    ]}
                    onClick={createSortToggle('subType')}
                  >
                    <TitleSort direction={orderBy.subType}>Type</TitleSort>
                  </ThOther>
                </tr>
              </thead>
              <tbody>
                <Fragment>
                  <SectionRow
                    onClick={() => {
                      toggleHidden('MY_TASKS');
                    }}
                    toggled={hidden.includes('MY_TASKS')}
                    isEmpty={myTasks.length === 0}
                  >
                    My Tasks
                  </SectionRow>
                  {!hidden.includes('MY_TASKS') && (
                    <Fragment>
                      {myTasks.length === 0 && (
                        <NoTasks>No tasks for you</NoTasks>
                      )}
                      {myTasks.map((task, index) => {
                        const isLast = myTasks?.length - 1 === index;
                        return (
                          <TaskRow
                            task={task}
                            isLast={isLast}
                            currentStaff={staff}
                          />
                        );
                      })}
                      {myTasks.length >= 5 && (
                        <ShowMoreRow
                          toggled={showMore.includes('MY_TASKS')}
                          onClick={() => {
                            toggleShowMore('MY_TASKS');
                          }}
                        />
                      )}
                    </Fragment>
                  )}
                </Fragment>

                <SectionRow
                  onClick={() => {
                    toggleHidden('OPEN_TASKS');
                  }}
                  toggled={hidden.includes('OPEN_TASKS')}
                  isEmpty={openTasks.length === 0}
                >
                  Open Tasks
                </SectionRow>
                {!hidden.includes('OPEN_TASKS') && (
                  <Fragment>
                    {openTasks.length === 0 && <NoTasks>No open tasks</NoTasks>}
                    {openTasks.map((task, index) => {
                      const isLast = myTasks?.length - 1 === index;
                      return (
                        <TaskRow
                          task={task}
                          isLast={isLast}
                          currentStaff={staff}
                        />
                      );
                    })}
                    {openTasks.length >= 5 && (
                      <ShowMoreRow
                        toggled={showMore.includes('OPEN_TASKS')}
                        onClick={() => {
                          toggleShowMore('OPEN_TASKS');
                        }}
                      />
                    )}
                  </Fragment>
                )}
                <SectionRow
                  onClick={() => {
                    toggleHidden('TASKS_WATCHED');
                  }}
                  toggled={hidden.includes('TASKS_WATCHED')}
                  isEmpty={tasksWatched.length === 0}
                >
                  Followed Tasks
                </SectionRow>
                {!hidden.includes('TASKS_WATCHED') && (
                  <Fragment>
                    {tasksWatched.length === 0 && (
                      <NoTasks>No followed tasks</NoTasks>
                    )}
                    {tasksWatched.map((task, index) => {
                      const isLast = myTasks?.length - 1 === index;
                      return (
                        <TaskRow
                          task={task}
                          isLast={isLast}
                          currentStaff={staff}
                          showWatch={true}
                        />
                      );
                    })}
                    {tasksWatched.length >= 5 && (
                      <ShowMoreRow
                        toggled={showMore.includes('TASKS_WATCHED')}
                        onClick={() => {
                          toggleShowMore('TASKS_WATCHED');
                        }}
                      />
                    )}
                  </Fragment>
                )}
                <SectionRow
                  onClick={() => {
                    toggleHidden('COMPLETED_TASKS');
                  }}
                  toggled={hidden.includes('COMPLETED_TASKS')}
                  isEmpty={completedTasks.length === 0}
                >
                  Completed
                </SectionRow>
                {!hidden.includes('COMPLETED_TASKS') && (
                  <Fragment>
                    {completedTasks.length === 0 && (
                      <NoTasks>No completed tasks</NoTasks>
                    )}
                    {completedTasks.map((task, index) => {
                      const isLast = myTasks?.length - 1 === index;
                      return (
                        <TaskRow
                          task={task}
                          isLast={isLast}
                          currentStaff={staff}
                        />
                      );
                    })}
                    {completedTasks.length >= 5 && (
                      <ShowMoreRow
                        toggled={showMore.includes('COMPLETED_TASKS')}
                        onClick={() => {
                          toggleShowMore('COMPLETED_TASKS');
                        }}
                      />
                    )}
                  </Fragment>
                )}
              </tbody>
            </table>
          </MainContent>
        </InnerWrap>
      </Content>
    </Fragment>
  );
};

export default TaskIndex;
