/** @jsx jsx */
import { jsx } from '@emotion/core';
import { TextButton } from '../button';
import { PrimaryText } from '../text';
import RedTrash from './icons/red_trash.svg';

export const DeletedTask = ({ onUndo }: { onUndo: () => void }) => {
  return (
    <div
      css={{
        background: '#FDE3D7',
        borderRadius: '8px',
        display: 'flex',
        padding: '8px 16px',
        alignItems: 'center',
      }}
    >
      <img src={RedTrash} />
      <PrimaryText
        css={{
          color: '#FF3C00',
          margin: '0 8px',
        }}
      >
        This task is deleted.
      </PrimaryText>
      <TextButton
        onClick={onUndo}
        type="button"
        css={{
          textDecoration: 'underline',
          color: '#034A46',
          padding: 0,
          margin: 0,
          ':hover': {
            color: '#355E5C',
          },
        }}
      >
        Undo
      </TextButton>
    </div>
  );
};
