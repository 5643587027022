import { Colors } from '@bc/theme';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { FullAddressLookupField, Input, InputWrap } from '../form';
import { InfoSection } from '../layout';
import { PrimaryNotBlank } from '../text';

const MobileAddressEditor = () => {
  const [field, , helpers] = useField('address');
  const form = useFormikContext();

  const formatted = field?.value?.formatted_address;
  const parkingInfo = field?.value?.parkingInstructions;

  return (
    <div style={{ width: '100%' }}>
      <InfoSection title="Address" fullWidth>
        <InputWrap>
          {!form.status.editing ? (
            <PrimaryNotBlank>
              <a
                style={{
                  color: Colors.teal,
                }}
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  formatted,
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatted}
              </a>
            </PrimaryNotBlank>
          ) : (
            <FullAddressLookupField
              name="address"
              onChange={(value?: any) => {
                if (!value) {
                  helpers.setValue({
                    parkingInstructions: field?.value?.parkingInstructions,
                  });
                } else {
                  helpers.setValue({
                    ...field.value,
                    ...value,
                  });
                }
              }}
            />
          )}
        </InputWrap>
      </InfoSection>
      <InfoSection title="Parking Instructions" fullWidth>
        <InputWrap>
          {!form.status.editing ? (
            <PrimaryNotBlank>{parkingInfo}</PrimaryNotBlank>
          ) : (
            <Input
              value={parkingInfo || ''}
              onChange={(e) => {
                helpers.setValue({
                  ...field.value,
                  parkingInstructions: e.target.value,
                });
              }}
              autoComplete="off"
            />
          )}
        </InputWrap>
      </InfoSection>
    </div>
  );
};

export default MobileAddressEditor;
