import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import React from 'react';
import { ButtonRed } from '../../components/button';
import { CancelButton } from '../../pages/staff/styles';
import { FONT_PRIMARY } from '../../styles';

const SmallModal = styled(DialogContent)`
  width: 328px;
  margin: 30vh auto 0 auto;
  padding: 24px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ModalTitle = styled.h3`
  ${FONT_PRIMARY}
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: ${Colors.darkHarbor};
  width: 100%;
  margin-bottom: 8px;
`;

const ModalText = styled.p`
  ${FONT_PRIMARY}
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${Colors.darkHarbor};
  width: 100%;
  margin-bottom: 24px;
`;

const InsuranceDeleteConfirmation: React.FC<{
  isOpen: boolean;
  onCancel: any;
  onRemove: () => void;
}> = ({ isOpen, onCancel, onRemove }) => {
  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={onCancel}
      style={{
        zIndex: 100,
      }}
    >
      <SmallModal aria-label="Delete Insurance">
        <ModalTitle>Are you sure?</ModalTitle>

        <ModalText>Once you remove an insurance you can't undo it.</ModalText>

        <ButtonRed
          tabIndex={0}
          style={{ display: 'inline-block' }}
          onClick={onRemove}
        >
          Remove
        </ButtonRed>
        <CancelButton style={{ display: 'inline-block' }} onClick={onCancel}>
          Cancel
        </CancelButton>
      </SmallModal>
    </DialogOverlay>
  );
};

export default InsuranceDeleteConfirmation;
