/** @jsx jsx */
import { jsx } from '@emotion/core';
import { EntryText, Row, Timestamp } from './styles';

export const ChargeEntry = ({
  children,
  note,
  reason,
  timestamp,
  title,
}: {
  children?: React.ReactNode;
  note?: string;
  reason?: string;
  timestamp: string;
  title: string;
}) => (
  <Row css={{ padding: '12px 0', zIndex: 1 }}>
    {children}
    <div css={{ marginLeft: 16 }}>
      <EntryText
        css={{
          fontSize: 16,
          fontWeight: 500,
          lineHeight: '22px',
          marginBottom: 4,
        }}
      >
        {title}
      </EntryText>
      {reason && (
        <EntryText
          css={{
            display: 'block',
            fontSize: 14,
            lineHeight: '18px',
            marginBottom: 4,
          }}
        >
          {reason}
        </EntryText>
      )}
      {note && (
        <EntryText
          css={{
            display: 'block',
            fontSize: 14,
            lineHeight: '18px',
            marginBottom: 4,
          }}
        >
          {note}
        </EntryText>
      )}
      <Timestamp>{timestamp}</Timestamp>
    </div>
  </Row>
);
