/** @jsx jsx */
import { useInsertRegionMutation } from '@bc/codegen/manager';
import { useLoadAllClinicsQuery } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import { Form, Formik } from 'formik';
import uniq from 'lodash/uniq';
import { RouteComponentProps } from 'react-router';
import { object, string } from 'yup';
import { ICONS } from '../../assets';
import { Button, TransparentButton } from '../../components/button';
import { ClinicSearcher } from '../../components/ClinicSelector/ClinicSelector';
import { NormalField } from '../../components/form';
import { ContentRow } from '../../components/layout';
import { LargeTitle, PrimaryText } from '../../components/text';
import { Close } from '../ViewAppointment.styles';

interface Values {
  name: string;
  regionCode: string;
  latitude: string;
  longitude: string;
  virtualZoomRegionEmail: string;
  clinicIds: string[];
  virtualZoomRegionUserId: string;
}

const RegionValidation = object().shape({
  name: string().required('Required'),
  regionCode: string().required('Required'),
  latitude: string().required('Required'),
  longitude: string().required('Required'),
  virtualZoomRegionEmail: string().email(''),
  virtualZoomRegionUserId: string().required('Required'),
});

const context = { role: 'manager' };

export const CreateRegion = ({ history }: RouteComponentProps) => {
  const { data } = useLoadAllClinicsQuery();
  const [insertRegion] = useInsertRegionMutation({
    context,
  });

  const clinics = data?.clinics ?? [];

  const handleSubmit = async (values: Values) => {
    const clinicIds = values.clinicIds.filter(Boolean).map((id) => {
      return {
        clinicId: id,
      };
    });

    await insertRegion({
      variables: {
        region: {
          name: values.name,
          regionCode: values.regionCode,
          location: {
            type: 'Point',
            coordinates: [
              parseFloat(values.longitude),
              parseFloat(values.latitude),
            ],
          },
          virtualZoomRegionEmail: values.virtualZoomRegionEmail,
          virtualZoomRegionUserId: values.virtualZoomRegionUserId,
          clinics_regions: {
            data: clinicIds,
          },
        },
      },
    });

    history.push('/regions');
  };

  return (
    <div
      css={{
        padding: '40px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <LargeTitle>Create Regions</LargeTitle>
        </div>
        <Close onClick={() => history.push('/regions')} />
      </div>
      <Formik
        initialValues={
          {
            name: '',
            regionCode: '',
            latitude: '',
            longitude: '',
            virtualZoomRegionEmail: '',
            virtualZoomRegionUserId: '',
            clinicIds: [],
          } as Values
        }
        validationSchema={RegionValidation}
        onSubmit={handleSubmit}
        initialStatus={{
          editing: true,
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          const clinicDisplay = clinics?.filter((clinic) => {
            return values.clinicIds.includes(clinic.id);
          });
          return (
            <Form>
              <ContentRow>
                <NormalField title="Region Name" name="name" fullWidth />
              </ContentRow>
              <ContentRow>
                <NormalField title="Region Code" name="regionCode" fullWidth />
              </ContentRow>
              <ContentRow>
                <NormalField title="Latitude" name="latitude" fullWidth />
              </ContentRow>
              <ContentRow>
                <NormalField title="Longitude" name="longitude" fullWidth />
              </ContentRow>
              <ContentRow>
                <NormalField
                  title="Virtual Care Email"
                  name="virtualZoomRegionEmail"
                  fullWidth
                />
              </ContentRow>
              <ContentRow>
                <NormalField
                  title="Virtual Zoom User ID"
                  name="virtualZoomRegionUserId"
                  fullWidth
                />
              </ContentRow>
              <div>
                <PrimaryText>Clinic Search</PrimaryText>
                <div>
                  <ClinicSearcher
                    onChange={(clinic) => {
                      if (clinic) {
                        setFieldValue(
                          'clinicIds',
                          uniq([...values.clinicIds, clinic.id]),
                        );
                      }
                    }}
                  />
                </div>
              </div>
              <div>
                {clinicDisplay.map((clinic) => {
                  return (
                    <div
                      css={{
                        display: 'flex',
                        padding: '8px 0',
                        alignItems: 'center',
                        gap: '16px',
                      }}
                    >
                      <PrimaryText>{clinic.name}</PrimaryText>
                      <TransparentButton
                        type="button"
                        onClick={() => {
                          setFieldValue(
                            'clinicIds',
                            values.clinicIds.filter((id) => {
                              return id !== clinic.id;
                            }),
                          );
                        }}
                      >
                        <img src={ICONS.close} alt="Delete" />
                      </TransparentButton>
                    </div>
                  );
                })}
              </div>
              <ContentRow>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  style={{
                    width: 'auto',
                  }}
                >
                  Save
                </Button>
              </ContentRow>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
