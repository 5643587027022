import React from 'react';
import styled from '@emotion/styled';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { ButtonRed } from '../../components/button';
import { CancelButton } from './styles';
import { ModalTitle, ModalText } from '../../components/text';

const SmallModal = styled(DialogContent)`
  width: 328px;
  margin: 30vh auto 0 auto;
  padding: 24px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StaffRemovalConfirmation: React.FC<{
  isOpen: boolean;
  onCancel: any;
  onRemove: () => void;
}> = ({ isOpen, onCancel, onRemove }) => {
  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={onCancel}
      style={{
        zIndex: 100,
      }}
    >
      <SmallModal aria-label="Remove Staff">
        <ModalTitle>Are you sure?</ModalTitle>

        <ModalText>
          Once you remove a staff member, you can't undo it.
        </ModalText>

        <ButtonRed
          tabIndex={0}
          style={{ display: 'inline-block' }}
          onClick={onRemove}
        >
          Remove
        </ButtonRed>
        <CancelButton style={{ display: 'inline-block' }} onClick={onCancel}>
          Cancel
        </CancelButton>
      </SmallModal>
    </DialogOverlay>
  );
};

export default StaffRemovalConfirmation;
