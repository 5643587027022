/** @jsx jsx */
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { PrimaryText } from '../text';

export const PaymentStatusBadge = ({
  children,
  className,
  status,
}: {
  children?: React.ReactNode;
  className?: string;
  status?: string;
}) => (
  <Badge className={className} status={status}>
    {children}
  </Badge>
);

const Badge = styled(PrimaryText)<{ status?: string }>(({ status }) => ({
  fontSize: 13,
  fontWeight: 500,
  letterSpacing: '1px',
  lineHeight: '18px',
  textTransform: 'uppercase',
  borderRadius: 4,
  background: status === 'succeeded' ? '#D8DFCA' : Colors.grayLight,
  color: Colors.darkHarbor,
  padding: '1px 5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'max-content',
})).withComponent('div');
