/** @jsx jsx */
import { Colors } from '@bc/theme';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { MenuButton, MenuList } from '@reach/menu-button';
import React from 'react';
import { FONT_DISPLAY, IF_TABLET } from '../../styles';
import { Button } from '../button';
import { PrimaryText } from '../text';

export const rightSideText = css({
  fontSize: '14px',
  fontWeight: 500,
});

export const TaskTitle = styled(PrimaryText)`
  ${FONT_DISPLAY};
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  ${IF_TABLET} {
    font-size: 28px;
  }
`.withComponent('h1');

const baseButtonStyle = css({
  color: Colors.darkHarbor,
  border: 'none',
  boxShadow: 'none',
  fontSize: '16px',
  padding: '4px 10px',
  background: 'none',
  cursor: 'pointer',
  display: 'inline-block',
  fontFamily: "'Greycliff', Helvetica, sans-serif;",
});

const BaseButton = styled.button(baseButtonStyle);

export const ellipsis = css({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const NonSelectedButton = styled(BaseButton)({
  color: Colors.gray,
  outlineColor: Colors.teal,
});

export const GreenTextButton = styled(BaseButton)({
  color: Colors.teal,
  backgroundColor: Colors.tealLightest,
  borderRadius: '100px',
  outlineColor: Colors.teal,
});

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  selected: boolean;
}

export const ToggleButton = ({ selected, ...props }: ButtonProps) => {
  if (selected) {
    return <GreenTextButton {...props} type="button" />;
  }

  return <NonSelectedButton {...props} type="button" />;
};

export const PillButton = ({
  children,
  icon,
  ...props
}: {
  children: React.ReactNode;
  icon: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <BaseButton
      {...props}
      css={{
        border: `1px solid ${Colors.grayLight}`,
        borderRadius: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      type="button"
    >
      <img src={icon} css={{ paddingRight: '8px' }} />
      <PrimaryText>{children}</PrimaryText>
    </BaseButton>
  );
};

export const PillMenuButton = ({
  children,
  icon,
  selected,
  ...props
}: {
  children: React.ReactNode;
  icon?: string;
  selected?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <MenuButton
      {...props}
      css={[
        baseButtonStyle,
        {
          border: `1px solid ${Colors.grayLight}`,
          borderRadius: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          outlineColor: Colors.teal,
        },
        selected && {
          backgroundColor: '#E5E8E8',
          border: `1px solid ${Colors.darkHarbor}`,
        },
      ]}
      type="button"
    >
      {icon && <img src={icon} css={{ paddingRight: '8px' }} />}
      {typeof children === 'string' && <PrimaryText>{children}</PrimaryText>}
      {typeof children !== 'string' && children}
    </MenuButton>
  );
};

export const DropdownMenuList = styled(MenuList)({
  border: 'none',
  boxShadow:
    '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  borderRadius: '16px',
  padding: 0,
  overflow: 'hidden',
  maxWidth: 'calc(760px - 80px)',
});

export const GreenMenuButton = styled(Button)({
  padding: '8px 16px',
  fontWeight: 500,
}).withComponent(MenuButton);

export const BorderMenuButton = styled(MenuButton)({
  background: 'none',
  border: `1px solid ${Colors.grayLight}`,
  borderRadius: '4px',
  padding: '13px 13px 13px 16px',
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  outlineColor: Colors.teal,
});

export const centeredToolip = (triggerRect: any, tooltipRect: any) => {
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.bottom + 8 + window.scrollY,
  };
};

export const ThTitle = styled(PrimaryText)({
  fontSize: '12px',
  lineHeight: '16px',
});

export const Td = styled.td<{ isLast?: boolean }>(
  {
    borderTop: `1px solid ${Colors.grayLighter}`,
    padding: '13px 8px',
    borderRight: `1px solid ${Colors.grayLighter}`,
    verticalAlign: 'middle',
  },
  ({ isLast }) => {
    return {
      borderBottom: `1px solid ${Colors.grayLighter}`,
    };
  },
);

export const TdTask = styled(Td)({
  width: 'calc(100% / 3)',
});

export const ThTask = styled(TdTask)({
  padding: '8px 0',
  borderTop: `1px solid ${Colors.grayLighter}`,
  borderRight: `1px solid ${Colors.grayLighter}`,
  borderBottom: `1px solid ${Colors.grayLighter}`,
  textAlign: 'left',
  fontWeight: 500,
  paddingLeft: '42px',
}).withComponent('th');

export const TdOther = styled(Td)({
  width: 'calc((100% - 100% / 3) / 7)',
});

export const ThOther = styled(ThTask)({
  width: 'calc((100% - 100% / 3) / 7)',
  paddingLeft: '7px',
});

export const SectionTitle = styled(PrimaryText)({
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '24px',
});

export const StaffImage = styled.img({
  borderRadius: '12px',
  height: '24px',
  objectFit: 'contain',
  width: '24px',
});

StaffImage.defaultProps = {
  src: 'https://bravecare.imgix.net/staff/empty.png',
};

export const LargeStaffImage = styled.img({
  borderRadius: '16px',
  height: '32px',
  objectFit: 'contain',
  width: '32px',
});

LargeStaffImage.defaultProps = {
  src: 'https://bravecare.imgix.net/staff/empty.png',
};

export const TextDisplay = styled(PrimaryText)({
  fontSize: '14px',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  '& a': {
    color: Colors.teal,
  },
}).withComponent('pre');

export const DullText = styled(PrimaryText)({
  color: Colors.gray,
  fontSize: '14px',
  fontWeight: 500,
});

export const SmallText = styled(PrimaryText)({
  color: Colors.gray,
  fontSize: '14px',
  fontWeight: 400,
});

export const DropdownText = styled(PrimaryText)`
  font-weight: 500;
`;

export const SecondaryDropdownText = styled(PrimaryText)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '14px',
  fontWeight: 400,
});
