/** @jsx jsx */
import { Colors } from '@bc/theme';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useTabsContext,
} from '@reach/tabs';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PrimaryText } from '../../components/text';
import { IF_TABLET } from '../../styles';
import { Close, Content, LargeTitle } from '../ViewAppointment.styles';
import UserSearch from './UserSearch';
import VerificationPinLookup from './VerificationPinLookup';

const UnderlineTab = ({
  children,
  index,
}: {
  children?: React.ReactNode;
  index: number;
}) => {
  const { selectedIndex } = useTabsContext();
  const isSelected = selectedIndex === index;

  return (
    <Tab
      css={{
        flex: 1,
        appearance: 'none',
        border: 'none',
        borderBottomWidth: isSelected ? '3px' : '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: isSelected ? Colors.teal : Colors.grayLighter,
        backgroundColor: Colors.white,
        padding: '8px 0',
        cursor: 'pointer',
        outline: 'none',
        ':focus': {
          borderBottomStyle: 'double',
        },
      }}
    >
      <PrimaryText
        css={{
          fontWeight: 'bold',
          fontSize: '14px',
          lineHeight: '18px',
          color: !isSelected ? Colors.gray : undefined,
        }}
      >
        {children}
      </PrimaryText>
    </Tab>
  );
};

const HeaderWrap = styled.div({
  padding: '24px 24px 0 24px',
  [IF_TABLET]: {
    padding: '40px 40px 0 40px',
  },
});

const Panel = css({
  outline: 'none',
  padding: '24px',
  [IF_TABLET]: {
    padding: '40px',
  },
});

const Header = styled.div({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  alignItems: 'center',
});

export const SearchAll: React.FC<RouteComponentProps> = (props) => {
  const { history } = props;

  const [selected, setSelectedTab] = useState(0);

  return (
    <Content aria-label="Search">
      <HeaderWrap>
        <Header>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <LargeTitle
              css={{
                marginBottom: '8px',
              }}
            >
              Search
            </LargeTitle>
          </div>
          <Close onClick={() => history.goBack()} />
        </Header>
      </HeaderWrap>

      <Tabs
        index={selected}
        onChange={(index) => {
          setSelectedTab(index);
        }}
      >
        <TabList
          css={{
            display: 'flex',
            color: Colors.teal,
          }}
        >
          <UnderlineTab index={0}>Users</UnderlineTab>
          <UnderlineTab index={1}>Verification Pin</UnderlineTab>
        </TabList>
        <TabPanels>
          <TabPanel css={Panel} tabIndex={-1}>
            <UserSearch {...props} selected={selected === 0} />
          </TabPanel>
          <TabPanel css={Panel} tabIndex={-1}>
            <VerificationPinLookup {...props} selected={selected === 1} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Content>
  );
};
