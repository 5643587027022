import i18n from 'i18next';
import { DateTime } from 'luxon';
import { date } from 'yup';
import en from './locales/en/translation.json';

export const dateOfBirthYupSchema = (
  {
    isMeetAndGreet,
  }: {
    isMeetAndGreet?: boolean;
  } = {
    isMeetAndGreet: false,
  },
) => {
  const validText = isMeetAndGreet
    ? i18n?.t('shared.validators.missingDueDate') ??
      en.shared.validators.missingDueDate
    : i18n?.t('shared.validators.invalidBirthDate') ??
      en.shared.validators.invalidBirthDate;

  let validation = date()
    .transform((_, originalValue: Date | string) => {
      const parsedDate =
        originalValue instanceof Date
          ? DateTime.fromJSDate(originalValue)
          : DateTime.fromFormat(originalValue, 'MM/dd/yy');
      return parsedDate.isValid &&
        parsedDate > DateTime.local().minus({ years: 100 })
        ? parsedDate.toJSDate()
        : new Date('');
    })
    .typeError(validText)
    .required(validText);

  if (isMeetAndGreet) {
    validation = validation.min(
      new Date(),
      i18n?.t('shared.validators.pastDueDate') ??
        en.shared.validators.pastDueDate,
    );
  } else {
    validation = validation.max(
      new Date(),
      i18n?.t('shared.validators.futureBirthDate') ??
        en.shared.validators.futureBirthDate,
    );
  }
  return validation;
};
