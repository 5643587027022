import { DateTime } from "luxon";
import pluralize from "pluralize";

export const MS_IN_DAY = 1000 * 60 * 60 * 24;

export const friendlyAge = (date: DateTime) => {
  const fromNow = date.diffNow(["years", "months", "days"]);
  const months = Math.floor(Math.abs(fromNow.months));
  const years = Math.floor(Math.abs(fromNow.years));
  const days = Math.floor(Math.abs(fromNow.days));

  let output = "";

  if (years > 0) {
    output += pluralize("year", years, true) + " ";
  }
  if (months > 0) {
    output += pluralize("month", months, true) + " ";
  }
  if (months < 3 && days > 0 && !years) {
    output += pluralize("day", days, true);
  }

  return output.trim();
};

export const friendlyDuration = (ms: number) => {
  const days = Math.round(ms / MS_IN_DAY);

  if (days >= 30) {
    return "more than 4 weeks";
  }
  if (days >= 21) {
    return "3 - 4 weeks";
  }
  if (days >= 15) {
    return "2 - 3 weeks";
  }

  return pluralize("day", days, true);
};
