import React from 'react';

export const TaskCheckmark = ({ color }: { color?: string }) => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <circle
        cx={10}
        cy={10}
        r={9}
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.444 10.34l2.167 2.167-.014-.014 4.89-4.89"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

TaskCheckmark.defaultProps = {
  color: '#D6DBDB',
};
