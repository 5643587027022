/** @jsx jsx */
import { useLoadTaskFilesQuery } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import { PrimaryText } from '../text';

export const TaskViewFiles = ({ taskId }: { taskId: string }) => {
  const { data } = useLoadTaskFilesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        taskId: {
          _eq: taskId,
        },
      },
      order_by: {
        createdAt: 'asc',
      },
    },
  });

  const files = data?.tasks_files ?? [];
  return (
    <div>
      {files.length === 0 && (
        <div>
          <PrimaryText>No files</PrimaryText>
        </div>
      )}
      {files.map((file) => {
        if (!file?.file?.info?.url) {
          return null;
        }
        return (
          <div
            css={{
              marginBottom: '16px',
            }}
          >
            <a
              href={file?.file?.info?.url}
              target="_blank"
              rel="noreferrer noopener"
            >
              {file?.file?.info?.url}
            </a>
          </div>
        );
      })}
    </div>
  );
};
