/** @jsx jsx */
import {
  Clinic_Location_Types_Enum,
  useUpsertAppointmentStaffMutation,
} from '@bc/codegen/manager';
import {
  AccountFragment,
  Appointment_Types_Enum,
  PatientFragment,
  useAddGlobalNoteMutation,
  useCreateAccountPatientMutation,
  useGetAccountByPkQuery,
  useGetClinicByCodeQuery,
  useGetPatientByPkQuery,
  useLoadCommunicationHistoryQuery,
  useScheduleAppointmentMutation,
  useUpdateAccountMutation,
  useUpdateCommunicationHistoryMutation,
  Visit_Classifications_Enum,
  Visit_Types_Enum,
} from '@bc/codegen/medical';
import {
  AVAILABLE_CARE_TYPES,
  dateOfBirthYupSchema,
  getCareType,
  RELATIONSHIPS,
  THIRTY_MINUTES,
  timeZoneMap,
  VISIT_CLASSIFICATIONS,
  VISIT_TYPES,
} from '@bc/shared';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import * as Sentry from '@sentry/react';
import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  useFormikContext,
} from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js/min';
import { DateTime, Interval } from 'luxon';
import React, { Fragment, useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { useLocalStorage, usePrevious } from 'react-use';
import { object, string } from 'yup';
import MobileAddressEditor from '../components/AppointmentDetails/AddressEditor';
import { Button, CloseButton } from '../components/button';
import CalendarSelect from '../components/CalendarSelect';
import { CaregiverInfoSection } from '../components/Caregiver';
import ClinicSimpleSelect from '../components/ClinicSelector/ClinicSimpleSelect';
import {
  ErrorField,
  ErrorText,
  NormalField,
  SearchInput,
  SelectSimpleField,
} from '../components/form';
import {
  CondensedRow as ContentRow,
  HeaderEdit,
  InfoSection,
  MainModalContent,
} from '../components/layout';
import { UnknownHeader } from '../components/ModalHeader';
import { PatientInfoSection } from '../components/Patient';
import { PatientSidebar } from '../components/PatientSidebar';
import { TimeSlot } from '../components/Schedule/helper';
import {
  SearchForAccount,
  SearchForPatient,
} from '../components/SearchForAccountPatient/';
import StaffScheduleField from '../components/StaffSearch/StaffScheduleField';
import { LargeTitle } from '../components/text';
import {
  DEFAULT_TIME_ZONE,
  formatDateOfBirth,
  formatPhoneNumber,
  getInitialDateTime,
  getTimeSlots,
} from '../helpers';
import { useScrollToErrorWithinModal } from '../hooks';
import {
  ClosePosition,
  LeftSideContainer,
  ModalWrapper,
  RightSideContainer,
  SideBySide,
  StaticHeader,
} from './ViewAppointment.styles';

const ARIA_LABEL = 'New Visit';
const CARE_TYPES = AVAILABLE_CARE_TYPES;

const AppointmentValidation = object().shape({
  accountFirstName: string().required('Required'),
  accountLastName: string().required('Required'),
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  clinicId: string().required('Required'),
  relationship: string().required('Required'),
  phoneNumber: string()
    .required('Required')
    .test('is-phone', 'Phone number not valid', (value = '') => {
      const phoneNumber = parsePhoneNumberFromString(value!, 'US');
      if (!phoneNumber) return false;

      return phoneNumber.isValid();
    }),
  dateOfBirth: dateOfBirthYupSchema(),
  date: string().required('Required'),
  time: string().required('Required'),
  reason: string().required('Required'),
  notes: string(),
  type: string().required('Required'),
  careType: string().required('Required'),
  timeZone: string().when('careType', {
    is: (careType: Care_Type_Enum) => careType === 'TELEMEDICINE',
    then: string().required('Required'),
  }),
  email: string()
    .email()
    .when('careType', {
      is: (careType: Care_Type_Enum) => careType === 'TELEMEDICINE',
      then: string().email('Required').required('Required'),
    }),
  patientSearch: string(),
  accountSearch: string(),
});

type Care_Type_Enum = Visit_Types_Enum | Visit_Classifications_Enum;

export interface Appointment {
  accountId?: string;
  patientId?: string;
  accountFirstName?: string;
  address?: any;
  accountLastName?: string;
  phoneNumber: string;
  clinicId?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  relationship?: string;
  date: string;
  time: string;
  reason: string;
  notes: string;
  type: Appointment_Types_Enum;
  careType: Care_Type_Enum;
  timeZone?: string;
  physician_providers: string[];
  assistant_providers: string[];
}

const initialAccountValues = {
  accountFirstName: '',
  accountId: undefined,
  accountLastName: '',
  email: '',
  phoneNumber: '',
};

const initialPatientValues = {
  dateOfBirth: '',
  firstName: '',
  lastName: '',
  patientId: undefined,
};

const useUpdateAccountPatient = () => {
  const { setFieldValue } = useFormikContext<any>();
  const location = useLocation<{
    dateTime: string;
    accountId?: string;
    patientId?: string;
    phoneNumber?: string;
    communicationHistoryId?: string;
  }>();

  // load patient
  const { data: patientData } = useGetPatientByPkQuery({
    variables: {
      patientId: location?.state?.patientId!,
    },
    skip: !location?.state?.patientId,
  });

  // load account with relationship
  const { data: accountData } = useGetAccountByPkQuery({
    variables: {
      accountId: location.state?.accountId!,
    },
    skip: !location.state?.accountId,
  });

  // load communicationHistory this came from
  const { data: communicationData } = useLoadCommunicationHistoryQuery({
    variables: {
      id: location.state?.communicationHistoryId!,
    },
    skip: !!accountData || !location.state?.communicationHistoryId,
  });

  const account = accountData?.accounts_by_pk;
  const communication = communicationData?.communication_history_by_pk;
  const patient = patientData?.patients_by_pk;
  const phoneNumber = location.state?.phoneNumber;

  useEffect(() => {
    if (account) {
      setFieldValue(
        'accountSearch',
        `${account.firstName} ${account.lastName}`,
      );
      setFieldValue('accountId', account.id);
      setFieldValue('accountFirstName', account.firstName ?? undefined);
      setFieldValue('accountLastName', account.lastName ?? undefined);
      setFieldValue('email', account.email ?? undefined);
      setFieldValue('phoneNumber', account.phoneNumber ?? undefined);
    } else if (communication) {
      const isInbound = communication?.direction === 'INBOUND';
      const initialPhoneNumber = isInbound
        ? communication?.from
        : communication?.to;
      const phoneNumber = initialPhoneNumber?.replace('+1', '');
      setFieldValue('phoneNumber', phoneNumber);
    } else if (phoneNumber) {
      const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');
      const formattedNumber = parsedNumber?.formatNational();
      setFieldValue('phoneNumber', formattedNumber);
    }

    if (patient) {
      setFieldValue(
        'patientSearch',
        `${patient.firstName} ${patient.lastName}`,
      );
      setFieldValue('patientId', patient?.id);
      setFieldValue(
        'dateOfBirth',
        (patient && formatDateOfBirth(patient.dateOfBirth)) ?? '',
      );
      setFieldValue('firstName', patient?.firstName ?? '');
      setFieldValue('lastName', patient?.lastName ?? '');

      if (account) {
        const accountPatient = account?.account_patients?.find((patient) => {
          return patient.patientId === location?.state?.patientId;
        });
        setFieldValue('relationship', accountPatient?.relationship ?? '');
      }
    }
  }, [account, communication, patient, phoneNumber]);

  return {
    account,
    communication,
    patient,
  };
};

const AddAppointmentForm = ({
  clinicType,
  creationError,
  error,
  hasExistingRelationship,
  onSetHasExistingRelationship,
  setClinicType,
}: {
  clinicType: Clinic_Location_Types_Enum;
  creationError: any;
  error: any;
  hasExistingRelationship: boolean;
  onSetHasExistingRelationship: (value: boolean) => void;
  setClinicType: (value: Clinic_Location_Types_Enum) => void;
}) => {
  const { communication } = useUpdateAccountPatient();

  const { isSubmitting, setFieldTouched, setFieldValue, setValues, values } =
    useFormikContext<any>();

  const [showPatientFields, setShowPatientFields] = useState(true);
  const [showAccountFields, setShowAccountFields] = useState(true);
  const [isMissingAccountName, setIsMissingAccountName] = useState(false);
  const previousTelemedicine = usePrevious(values.careType === 'TELEMEDICINE');

  const clinicCode = values.clinicId;
  const { data: clinicData, refetch } = useGetClinicByCodeQuery({
    variables: { clinicCode },
    skip: !clinicCode,
  });
  const clinic = clinicData?.clinics?.[0];
  const careType = getCareType({ visitType: values.careType });
  const interval = careType?.interval ?? 30;
  const slots: TimeSlot[] = getTimeSlots({
    interval,
    clinic,
    timeZone: values.timeZone ?? DEFAULT_TIME_ZONE,
  });

  useEffect(() => {
    if (values.clinicId || values.careType) {
      refetch();
    }

    if (values.careType === 'COVID_19_TEST' && !values.reason) {
      setFieldValue('reason', 'COVID-19 Test');
    }
  }, [refetch, values.careType, values.clinicId]);

  useEffect(() => {
    const clinic = clinicData?.clinics?.[0];
    if (clinic?.timeZone && !previousTelemedicine) {
      setFieldValue('timeZone', clinic.timeZone);
    }
  }, [
    clinicData,
    clinicType,
    previousTelemedicine,
    setFieldValue,
    values.timeZone,
  ]);

  const renderCareTypes =
    clinicType === 'MOBILE'
      ? Object.keys(CARE_TYPES).filter(
          (key) =>
            key !== 'MEET_AND_GREET' &&
            key !== 'TELEMEDICINE' &&
            key !== 'URGENT_CARE',
        )
      : CARE_TYPES;

  const time = DateTime.fromFormat(values.time || '10:00 AM', 'h:mm a');
  const startDate = DateTime.fromFormat(values.date, 'yyyy-MM-dd')
    .set({ hour: time.hour, minute: time.minute })
    .startOf('minute');
  const apptDuration = careType?.duration ?? THIRTY_MINUTES;
  const endDate = startDate.plus({ milliseconds: apptDuration });
  const visitInterval = Interval.fromDateTimes(startDate, endDate);

  useScrollToErrorWithinModal({ ariaLabel: ARIA_LABEL });

  const setAccountValues = (account: AccountFragment) => {
    setValues({
      ...values,
      accountFirstName: account?.firstName ?? '',
      accountId: account.id,
      accountLastName: account?.lastName ?? '',
      email: account?.email ?? '',
      phoneNumber: formatPhoneNumber(account.phoneNumber),
    });
  };

  const setPatientValues = (patient: PatientFragment) => {
    setValues({
      ...values,
      patientId: patient.id,
      dateOfBirth: formatDateOfBirth(patient.dateOfBirth) ?? '',
      firstName: patient?.firstName ?? '',
      lastName: patient?.lastName ?? '',
    });
  };

  const shouldShowCaregiverNameFields =
    !!values.accountId && isMissingAccountName;

  const shouldShowCaregiverFields =
    !values.accountId || !hasExistingRelationship;
  const visitType = careType?.visitType ?? 'URGENT_CARE';

  return (
    <Form css={SideBySide}>
      <LeftSideContainer>
        <ContentRow css={{ marginTop: 0 }}>
          <SearchInput
            fullWidth
            name="patientSearch"
            title="Search for patient"
            disabled={!!values.patientId}
            placeholder="Enter name or date of birth"
            autoFocus
          />
          <SearchForPatient
            query={values.patientSearch}
            selectedPatientId={values.patientId}
            selectedAccountId={values.accountId}
            onLinkPatient={({ patient, relationship }) => {
              onSetHasExistingRelationship(!!relationship);
              if (patient) {
                setShowPatientFields(false);
                setPatientValues(patient);
              } else {
                setShowPatientFields(true);
                setValues({
                  ...values,
                  ...initialAccountValues,
                  ...initialPatientValues,
                  relationship: '',
                });
              }
            }}
            onLinkAccount={({ account, relationship, isMissingName }) => {
              onSetHasExistingRelationship(!!relationship);
              setIsMissingAccountName(!!isMissingName);
              if (account) {
                setAccountValues(account);
                if (!!relationship) {
                  setFieldValue('relationship', relationship);
                }
                if (!!relationship && !isMissingName) {
                  setShowAccountFields(false);
                } else {
                  setShowAccountFields(true);
                }
              } else {
                setShowAccountFields(true);
                setValues({
                  ...values,
                  ...initialAccountValues,
                  relationship: '',
                });
              }
            }}
            onCreateNewAccount={() => setShowAccountFields(true)}
            displayAddNewAccount={!showAccountFields}
          />
        </ContentRow>
        {showPatientFields && (
          <PatientInfoSection
            onExistingSelect={(patient) => {
              setFieldValue('patientId', patient.id);
              setFieldValue(
                'patientSearch',
                `${patient.firstName} ${patient.lastName}`,
              );
              setShowPatientFields(false);
            }}
          />
        )}
        {showAccountFields && (
          <Fragment>
            <HeaderEdit
              title="Caregiver Information"
              editing={true}
              onEdit={() => {}}
              editable={false}
              css={StaticHeader}
            />
            <ContentRow css={{ marginBottom: 0 }}>
              <SearchInput
                fullWidth
                name="accountSearch"
                title="Search for caregiver"
                disabled={!!values.accountId}
                placeholder="Enter name or phone number"
              />
              <SearchForAccount
                query={values.accountSearch}
                selectedAccountId={values.accountId}
                selectedPatientId={values.patientId}
                onLinkAccount={({ account, relationship, isMissingName }) => {
                  onSetHasExistingRelationship(!!relationship);
                  setIsMissingAccountName(!!isMissingName);
                  if (account) {
                    setAccountValues(account);
                    if (!!relationship) {
                      setFieldValue('relationship', relationship);
                    }
                    if (!!relationship && !isMissingName) {
                      setShowAccountFields(false);
                    } else {
                      setShowAccountFields(true);
                    }
                  } else {
                    setShowAccountFields(true);
                    setValues({
                      ...values,
                      ...initialAccountValues,
                      ...initialPatientValues,
                      relationship: '',
                      accountSearch: '',
                    });
                  }
                }}
                onLinkPatient={({ patient, relationship }) => {
                  onSetHasExistingRelationship(!!relationship);
                  if (patient) {
                    setPatientValues(patient);
                    setFieldValue('accountSearch', '');
                    if (!!relationship) {
                      setFieldValue('relationship', relationship);
                    }
                  } else {
                    setValues({
                      ...values,
                      ...initialPatientValues,
                      relationship: '',
                      accountSearch: '',
                    });
                  }
                }}
              />
            </ContentRow>
            {!values.accountId && (
              <CaregiverInfoSection
                onExistingSelect={({
                  account,
                  relationship,
                  isMissingName,
                }) => {
                  onSetHasExistingRelationship(!!relationship);
                  setIsMissingAccountName(isMissingName);
                  setAccountValues(account);
                  if (isMissingName) {
                    setFieldValue('accountSearch', account.phoneNumber);
                  } else {
                    setFieldValue(
                      'accountSearch',
                      `${account.firstName} ${account.lastName}`,
                    );
                  }
                  if (!!relationship) {
                    setFieldValue('relationship', relationship);
                    if (!!isMissingName) {
                      setShowAccountFields(false);
                    }
                  }
                }}
              />
            )}
            {shouldShowCaregiverNameFields && (
              <ContentRow>
                <NormalField
                  title="Caregiver first name"
                  name="accountFirstName"
                />
                <NormalField
                  title="Caregiver last name"
                  name="accountLastName"
                />
              </ContentRow>
            )}
            {shouldShowCaregiverFields && (
              <ContentRow>
                <NormalField title="Caregiver Email" name="email" />
                <InfoSection
                  title={
                    values.firstName
                      ? `Relationship to ${values.firstName}`
                      : 'Caregiver relationship'
                  }
                  htmlFor="relationship"
                >
                  <SelectSimpleField name="relationship">
                    <option value="">Select</option>
                    {RELATIONSHIPS.map(({ label, value }) => (
                      <option value={value} key={value}>
                        {label}
                      </option>
                    ))}
                  </SelectSimpleField>
                  <ErrorField name="relationship" />
                </InfoSection>
              </ContentRow>
            )}
          </Fragment>
        )}
        <HeaderEdit
          title="Visit Details"
          editing={true}
          editable={false}
          onEdit={() => {}}
          css={StaticHeader}
        />
        <ContentRow>
          <InfoSection title="Clinic">
            <ClinicSimpleSelect
              value={values.clinicId}
              onChange={(clinic) => {
                if (clinic?.locationType) {
                  setClinicType(clinic.locationType);
                }
                if (clinic?.timeZone) {
                  setFieldValue('timeZone', clinic.timeZone);
                }
                setFieldTouched('clinicId');
                setFieldValue('clinicId', clinic?.clinicCode);
              }}
            />
            <ErrorField name="clinicId" />
          </InfoSection>
          <InfoSection title="Time zone">
            <SelectSimpleField
              name="timeZone"
              disabled={values.careType !== 'TELEMEDICINE'}
            >
              <option value="">Select</option>
              {timeZoneMap.map(({ value, label }) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </SelectSimpleField>
            <ErrorMessage name="timeZone" component={ErrorText} />
          </InfoSection>
        </ContentRow>
        <ContentRow>
          <InfoSection title="Date">
            <Field name="date">
              {({ field }: FieldProps<any>) => (
                <CalendarSelect
                  date={field.value}
                  onChange={(value) => {
                    setFieldValue(
                      'date',
                      DateTime.fromJSDate(value).toFormat('yyyy-MM-dd'),
                    );
                  }}
                >
                  {field.value}
                </CalendarSelect>
              )}
            </Field>
          </InfoSection>
          <InfoSection title="Start time">
            <SelectSimpleField name="time">
              <option value="">Select time</option>
              {slots.map(({ display }) => (
                <option key={display} value={display}>
                  {display}
                </option>
              ))}
            </SelectSimpleField>
            <ErrorMessage name="time" component={ErrorText} />
          </InfoSection>
        </ContentRow>
        <ContentRow>
          <InfoSection title="Visit type">
            <SelectSimpleField name="careType">
              <option value="">Select</option>
              {Object.keys(renderCareTypes).map((key) => {
                //@ts-ignore
                const title = VISIT_TYPES[key] || VISIT_CLASSIFICATIONS[key];
                return (
                  <option value={key} key={key}>
                    {title}
                  </option>
                );
              })}
            </SelectSimpleField>
            <ErrorMessage name="careType" component={ErrorText} />
          </InfoSection>
          <InfoSection title="Type">
            <SelectSimpleField name="type">
              <option value="">Select</option>
              <option value="CALLIN">Call In</option>
              <option value="WALKIN">Walk In</option>
            </SelectSimpleField>
            <ErrorMessage name="type" component={ErrorText} />
          </InfoSection>
        </ContentRow>
        {clinicType === 'MOBILE' && (
          <Fragment>
            <HeaderEdit
              title="Location Details"
              editing
              noTopPadding
              editable={false}
              css={StaticHeader}
            />
            <ContentRow>
              <MobileAddressEditor />
            </ContentRow>
          </Fragment>
        )}
        <ContentRow>
          <InfoSection title="Provider">
            <StaffScheduleField
              clinicCode={clinicCode}
              apptTimeSpan={visitInterval}
              name="physician_providers"
              serviceType={visitType}
              display={[
                'PHYSICIAN',
                'PHYSICIAN_ASSISTANT',
                'NURSE_PRACTITIONER',
              ]}
            />
          </InfoSection>
          <InfoSection title="Medical Assistant">
            <StaffScheduleField
              clinicCode={clinicCode}
              apptTimeSpan={visitInterval}
              name="assistant_providers"
              display={['MEDICAL_ASSISTANT']}
            />
          </InfoSection>
        </ContentRow>
        <ContentRow>
          <NormalField
            title="Reason for Visit (Visible to patient)"
            fullWidth
            name="reason"
          />
        </ContentRow>
        <HeaderEdit
          title="Visit Flags"
          editing={true}
          editable={false}
          onEdit={() => {}}
          css={StaticHeader}
        />
        <ContentRow>
          <NormalField
            title="Flag for visit (Not visible to patient)"
            fullWidth
            name="notes"
          />
        </ContentRow>
        {(error || creationError) && (
          <ContentRow>
            <ErrorText>{error?.message || creationError}</ErrorText>
          </ContentRow>
        )}
      </LeftSideContainer>
      <RightSideContainer>
        <Button
          type="submit"
          disabled={isSubmitting}
          css={{
            boxShadow:
              '0px 10px 20px rgba(0, 0, 0, 0.12), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
            width: '100%',
            padding: '16px 40px',
            position: 'sticky',
            top: '16px',
          }}
        >
          Save Visit
        </Button>
        <PatientSidebar patientId={values.patientId} />
        {communication && (
          <UnknownHeader
            communication={communication}
            condensed
            css={{
              borderRadius: 16,
              border: `1px solid ${Colors.grayLight}`,
              marginTop: 40,
              padding: 24,
            }}
          />
        )}
      </RightSideContainer>
    </Form>
  );
};

const AddAppointment: React.FC<
  { returnPath?: string } & RouteComponentProps<
    {},
    {},
    {
      dateTime: string;
      accountId?: string;
      patientId?: string;
      communicationHistoryId?: string;
    }
  >
> = ({ returnPath, history, location }) => {
  const [creationError, setError] = useState('');
  const [scheduleAppointment, { error }] = useScheduleAppointmentMutation({
    fetchPolicy: 'no-cache',
  });
  const [upsertAppointmentStaff] = useUpsertAppointmentStaffMutation({
    fetchPolicy: 'no-cache',
  });
  const [createAccountPatient] = useCreateAccountPatientMutation({
    fetchPolicy: 'no-cache',
  });
  const [updateAccount] = useUpdateAccountMutation({
    fetchPolicy: 'no-cache',
  });
  const [updateCommunicationHistory] = useUpdateCommunicationHistoryMutation({
    fetchPolicy: 'no-cache',
  });
  const [addNote] = useAddGlobalNoteMutation({ fetchPolicy: 'no-cache' });

  const [clinicType, setClinicType] =
    useState<Clinic_Location_Types_Enum>('PHYSICAL');
  const [selectedClinic] = useLocalStorage('selectedClinic', 'ne-super1');
  const [clinicTimeZone, setClinicTimeZone] = useState(DEFAULT_TIME_ZONE);

  const { data: clinicData } = useGetClinicByCodeQuery({
    variables: {
      clinicCode: selectedClinic!,
    },
    skip: !selectedClinic,
  });

  useEffect(() => {
    const locationType = clinicData?.clinics?.[0]?.locationType;
    const timeZone = clinicData?.clinics?.[0]?.timeZone;
    if (locationType) {
      setClinicType(locationType);
    }
    if (timeZone) {
      setClinicTimeZone(timeZone);
    }
  }, [clinicData]);

  const [hasExistingRelationship, setHasExistingRelationship] = useState(true);

  const handleAddAppointment = async (
    values: Appointment,
    { setFieldError }: FormikHelpers<Appointment>,
  ) => {
    try {
      const time = DateTime.fromFormat(values.time, 'h:mm a');
      const startTime = DateTime.fromFormat(values.date, 'yyyy-MM-dd')
        .set({
          hour: time.hour,
          minute: time.minute,
        })
        .startOf('minute');

      const parsedNumber = parsePhoneNumberFromString(
        values?.phoneNumber,
        'US',
      )?.number.toString();
      let successfulAppointment;
      let appointmentId: string | undefined;

      let accountId = values.accountId;
      let patientId = values.patientId;

      if (!accountId || !patientId || !hasExistingRelationship) {
        const { data: savedData, errors } = await createAccountPatient({
          variables: {
            accountFirstName: values.accountFirstName!,
            accountId,
            accountLastName: values.accountLastName!,
            phoneNumber: parsedNumber!,
            relationship: values.relationship!,
            dateOfBirth: DateTime.fromFormat(
              values.dateOfBirth!,
              'MM/dd/yy',
            ).toFormat('yyyy-MM-dd'),
            patientFirstName: values.firstName!,
            patientId,
            patientLastName: values.lastName!,
            email: values.email,
          },
        });

        if (errors) {
          Sentry.captureMessage(errors?.[0]?.message, (scope) => {
            scope.setExtras(values as any);
            if (savedData?.CreateAccountPatient) {
              scope.setExtras(savedData?.CreateAccountPatient);
            }
            return scope;
          });
        }

        accountId = savedData?.CreateAccountPatient?.accountId!;
        patientId = savedData?.CreateAccountPatient?.patientId!;
      }

      if (
        accountId &&
        (values.email || values.accountFirstName || values.accountLastName)
      ) {
        await updateAccount({
          variables: {
            set: {
              ...(values.email && { email: values.email }),
              ...(values.accountFirstName && {
                firstName: values.accountFirstName,
              }),
              ...(values.accountLastName && {
                lastName: values.accountLastName,
              }),
            },
            where: {
              id: {
                _eq: accountId,
              },
            },
          },
        });
      }

      if (!accountId || !patientId) {
        if (!accountId) {
          setError('Cannot find account');
        } else if (!patientId) {
          setError('Cannot create patient');
        }
        return;
      }

      if (location.state?.communicationHistoryId) {
        await updateCommunicationHistory({
          variables: {
            id: location.state?.communicationHistoryId,
            set: {
              accountId,
              patientId,
            },
          },
        });
      }

      const sqlTime = startTime.toSQL({
        includeOffset: false,
        includeZone: false,
      });

      if (!sqlTime) {
        throw new Error('No valid time');
      }

      if (clinicType === 'MOBILE' && !values.address) {
        setFieldError('address', 'Address field is required');
        return;
      }

      const visitType =
        CARE_TYPES[values?.careType]?.visitType || 'URGENT_CARE';
      const visitClassification =
        CARE_TYPES[values?.careType]?.visitClassification;

      const { data, errors } = await scheduleAppointment({
        variables: {
          accountId: accountId,
          patientId: patientId,
          clinicId: values.clinicId!,
          communicationHistoryId: location.state?.communicationHistoryId,
          reason: values.reason.trim(),
          sqlTime: sqlTime,
          address: values.address
            ? btoa(JSON.stringify(values?.address))
            : undefined,
          type: values.type,
          visitType,
          visitClassification,
          email: values.email,
          timeZone: values.timeZone,
        },
      });
      successfulAppointment = !!data?.ScheduleAppointment?.id;
      appointmentId = data?.ScheduleAppointment?.id;

      if (errors) {
        throw new Error(errors?.[0]?.message);
      }

      setError('');

      const combinedProviders = [
        ...values.physician_providers,
        ...values.assistant_providers,
      ];

      if (combinedProviders.length && appointmentId) {
        const providers = combinedProviders.map((staffId: string) => {
          return {
            staffId,
            appointmentId,
          };
        });

        await upsertAppointmentStaff({
          variables: {
            appointmentId,
            staff: providers,
          },
        });
      }

      if (!!values.notes.length && appointmentId) {
        addNote({
          variables: {
            globalNote: {
              appointmentId,
              note: values.notes.trim(),
            },
          },
        });
      }

      if (successfulAppointment) {
        history.push('/dashboard');
      }
    } catch (error: any) {
      setError(error.message);
      Sentry.captureMessage(error, (scope) => {
        scope.setExtras(values as any);
        return scope;
      });
    }
  };

  return (
    <ModalWrapper aria-label={ARIA_LABEL}>
      <MainModalContent>
        <LargeTitle css={{ marginBottom: 24 }}>New Visit</LargeTitle>
        <CloseButton
          css={ClosePosition}
          onClick={() => history.push(returnPath ? returnPath : '/dashboard')}
        />
        <Formik
          initialValues={{
            ...initialAccountValues,
            ...initialPatientValues,
            clinicId: selectedClinic,
            relationship: '',
            patientSearch: '',
            accountSearch: '',
            reason: '',
            notes: '',
            type: 'CALLIN',
            careType: 'URGENT_CARE',
            timeZone: clinicTimeZone,
            physician_providers: [],
            assistant_providers: [],
            ...getInitialDateTime(location?.state?.dateTime),
          }}
          enableReinitialize
          initialStatus={{
            editing: true,
          }}
          validationSchema={AppointmentValidation}
          onSubmit={handleAddAppointment}
        >
          {() => (
            <AddAppointmentForm
              clinicType={clinicType}
              creationError={creationError}
              error={error}
              hasExistingRelationship={hasExistingRelationship}
              onSetHasExistingRelationship={setHasExistingRelationship}
              setClinicType={setClinicType}
            />
          )}
        </Formik>
      </MainModalContent>
    </ModalWrapper>
  );
};

export default AddAppointment;
