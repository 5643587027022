import createAuth0Client, { Auth0ClientOptions } from '@auth0/auth0-spa-js';
import jwtDecode from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getUserId, hasRole, Token } from './auth';

export const Auth0Context = React.createContext<any>({});
export const useAuth0 = () => {
  return useContext(Auth0Context);
};

export const useAuth0Id = () => {
  const { accessToken } = useAuth0();
  const userId = getUserId(accessToken);
  return userId;
};

export const useIsManager = () => {
  const { accessToken } = useAuth0();
  const isManager = hasRole('manager', accessToken);
  if (process.env.REACT_APP_CYPRESS) {
    return true;
  }
  return isManager;
};

export const createClient = async (options: Auth0ClientOptions) => {
  return await createAuth0Client(options);
};

interface Props {
  children?: React.ReactNode;
  client: any;
  redirect_uri: string;
}

const Auth0Provider: React.FC<RouteComponentProps & Props> = ({
  children,
  client,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<any>();
  const [accessToken, setAccessToken] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth0 = async () => {
      if (window.location.search.includes('code=')) {
        try {
          await client.handleRedirectCallback();
        } catch (error) {
          console.log(error);
        }
      }

      let isAuthenticated = await client.isAuthenticated();

      const sessionAuthToken = window.sessionStorage.getItem('auth_token');
      const localAuthToken = window.localStorage.getItem('auth_token');
      const authToken = sessionAuthToken ?? localAuthToken;
      const hasAuthToken = !!authToken;

      if (hasAuthToken) {
        isAuthenticated = true;
        setAccessToken(authToken!);
        const user = jwtDecode<Token>(authToken!);
        setUser(user);
        // @ts-ignore
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({ accessToken: authToken }),
        );
      }

      if (isAuthenticated && !hasAuthToken) {
        const user = await client.getUser();
        const claims = await client.getIdTokenClaims();

        // @ts-ignore
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({ accessToken: claims.__raw }),
        );
        setAccessToken(claims.__raw);
        setUser(user);
      }
      setIsAuthenticated(!!isAuthenticated);
      setLoading(false);
    };
    initAuth0();
  }, [client]);

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        accessToken,
        getIdTokenClaims: (...p: any) => client.getIdTokenClaims(...p),
        loginWithRedirect: (...p: any) => client.loginWithRedirect(...p),
        getTokenSilently: (...p: any) => client.getTokenSilently(...p),
        getTokenWithPopup: (...p: any) => client.getTokenWithPopup(...p),
        logout: (opts: any) => client.logout({ federated: true, ...opts }),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export default withRouter(Auth0Provider);
