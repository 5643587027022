/** @jsx jsx */
import {
  useAddAccountMutation,
  useGenerateTemporaryVerificationCodeMutation,
  useRemoveAccountFromPatientMutation,
  useUpdateAccountByPkMutation,
  useUpdateAccountMutation,
  useUpdateAccountPatientRelationshipMutation,
  useUpdateAccountPhoneMutation,
} from '@bc/codegen/medical';
import {
  getPreferredPronoun,
  getRelationshipDisplay,
  PREFERRED_PRONOUN,
  RELATIONSHIPS,
} from '@bc/shared';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js/min';
import { DateTime } from 'luxon';
import React, { Fragment, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { object, string } from 'yup';
import { AccountAuditLog } from '../components/Audit';
import {
  Button,
  ButtonRed,
  OutlineButton,
  TextButton,
} from '../components/button';
import {
  CopyToClipField,
  ErrorText,
  NormalField,
  PhoneInput,
  SelectSimpleField,
} from '../components/form';
import {
  ContentRow,
  HeaderEdit,
  InfoSection,
  InfoWrap,
  Label,
  Link,
} from '../components/layout';
import { DefinitionList, DefinitionListItem } from '../components/List';
import Loader from '../components/Loader/loader';
import { PrimaryText } from '../components/text';
import { formatPhoneNumber } from '../helpers';
import { PhotoIdDisplay } from './AppointmentDetails/AvatarDisplay';
import RemoveCaregiverConfirmation from './RemoveCaregiverConfirmation';

const CaregiverValidation = object().shape({
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  phoneNumber: string()
    .required('Required')
    .test('is-phone', 'Phone number not valid', (value = '') => {
      const phoneNumber = parsePhoneNumberFromString(value!, 'US');
      if (!phoneNumber) return false;

      return phoneNumber.isValid();
    }),
  email: string().email(),
  preferredPronounType: string(),
  preferredPronoun: string(),
  relationship: string().required('Required'),
});

interface AccountValues {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  relationship: string;
  preferredPronounType?: string;
  preferredPronoun?: string;
}

interface CaregiverFormProps {
  patient: any;
  account?: any;
  accounts: any[];
  index?: number;
  isNew?: boolean;
  onCancel?: () => void;
  isAccompanying?: boolean;
}

const CaregiverLink: React.FC<{
  accountId: string;
  children: React.ReactNode;
}> = ({ accountId, children }) => (
  <RouterLink
    css={{
      color: Colors.darkHarbor,
      textDecoration: 'none',
    }}
    to={`/account/${accountId}/profile`}
  >
    {children}
  </RouterLink>
);

const getHasuraErrorCode = (error: any) => {
  return error?.graphQLErrors?.[0]?.extensions?.code;
};

const ButtonRedSmall = styled(ButtonRed)({
  display: 'inline-block',
  padding: '5px 20px',
  marginLeft: '5px',
});

export const CaregiverForm: React.FC<CaregiverFormProps> = ({
  patient,
  account,
  index,
  isNew,
  accounts,
  onCancel = () => {},
  isAccompanying,
}) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [removeAccountFromPatient] = useRemoveAccountFromPatientMutation();
  const [addAccount] = useAddAccountMutation();
  const [updateAccount] = useUpdateAccountMutation();
  const [updateAccountByPk] = useUpdateAccountByPkMutation();
  const [updateAccountPatientRelationship] =
    useUpdateAccountPatientRelationshipMutation();
  const [updatePhoneNumber] = useUpdateAccountPhoneMutation();

  const [
    generateTemporaryVerificationCode,
    {
      data: temporaryVerificationCode,
      loading: temporaryVerificationCodeLoading,
    },
  ] = useGenerateTemporaryVerificationCodeMutation();

  const handleAgreesToMedicalTerms = async () => {
    const accountId = account?.accountId;
    if (!accountId) {
      throw new Error('Cannot save account');
    }

    try {
      await updateAccountByPk({
        variables: {
          id: accountId,
          set: {
            consentToMedicalTermsTimestamp: 'now()',
          },
        },
      });
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  const handleAgreesToTermsAndPrivacy = async () => {
    const accountId = account?.accountId;
    if (!accountId) {
      throw new Error('Cannot save account');
    }

    try {
      await updateAccountByPk({
        variables: {
          id: accountId,
          set: {
            consentToTermsAndPrivacyTimestamp: 'now()',
          },
        },
      });
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  const handleUpdateCaregiver = async (
    { relationship, id, ...values }: AccountValues,
    { setStatus, setFieldError }: FormikHelpers<AccountValues>,
  ) => {
    try {
      const patientId = patient.id;

      if (id) {
        await updateAccount({
          variables: {
            set: {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              preferredPronounType: values.preferredPronounType,
              preferredPronoun: values.preferredPronoun,
            },
            where: {
              id: {
                _eq: id,
              },
            },
          },
        });

        const parsedNumber = parsePhoneNumberFromString(
          values.phoneNumber,
          'US',
        );

        if (
          id &&
          parsedNumber &&
          parsedNumber.isValid() &&
          parsedNumber.number !== account.account.phoneNumber
        ) {
          await updatePhoneNumber({
            variables: {
              accountId: id,
              phoneNumber: parsedNumber.number.toString(),
            },
          });
        }

        if (patientId) {
          await updateAccountPatientRelationship({
            variables: {
              accountId: id,
              patientId: patientId,
              relationship: relationship,
            },
          });
        }
      } else {
        await addAccount({
          variables: { ...values, patientId, relationship },
        });
      }

      setStatus({
        editing: false,
      });

      if (!id) {
        onCancel(); // close the new form
      }
    } catch (error) {
      Sentry.captureException(error);
      if (getHasuraErrorCode(error) === 'wrong_last_name') {
        setFieldError('lastName', 'Last name does not match account on file');
      } else if (getHasuraErrorCode(error) === 'incorrect_account') {
        setFieldError(
          'phoneNumber',
          "The phone number listed matches another caregiver's information.",
        );
      } else {
        window.alert(
          'There was an error saving. If the problem persists please contact tech support.',
        );
      }
    }
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          id: account?.accountId,
          firstName: account?.account?.firstName || '',
          lastName: account?.account?.lastName || '',
          phoneNumber: account?.account?.phoneNumber
            ? formatPhoneNumber(account?.account?.phoneNumber)
            : '',
          email: account?.account?.email || '',
          relationship: account?.relationship || '',
          preferredPronounType: account?.account?.preferredPronounType || '',
          preferredPronoun: account?.account?.preferredPronoun || '',
        }}
        enableReinitialize
        validationSchema={CaregiverValidation}
        initialStatus={{ editing: isNew }}
        onSubmit={handleUpdateCaregiver}
      >
        {({
          status,
          setFieldValue,
          setStatus,
          values,
          resetForm,
          isSubmitting,
          isValid,
        }) => {
          return (
            <Form>
              <HeaderEdit
                title={
                  index !== undefined ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div css={{ marginRight: 8 }}>
                        <CaregiverLink accountId={values.id}>
                          {`Caregiver ${index + 1}${
                            isAccompanying ? ' (accompanying)' : ''
                          }`}
                        </CaregiverLink>
                      </div>
                      {account?.account?.id && (
                        <AccountAuditLog
                          accountId={account?.account?.id}
                          size={24}
                        />
                      )}
                    </div>
                  ) : (
                    'New Caregiver'
                  )
                }
                editing={status.editing}
                onEdit={() => {
                  setStatus({ editing: true });
                }}
                onCancel={() => {
                  setStatus({
                    editing: false,
                  });
                  resetForm();
                  onCancel();
                }}
              />
              {!status.editing && (
                <DefinitionList>
                  {account?.account?.id && (
                    <DefinitionListItem term="Verification Pin">
                      {temporaryVerificationCodeLoading ? (
                        <Loader />
                      ) : temporaryVerificationCode
                          ?.GenerateTemporaryVerificationCode?.code ? (
                        temporaryVerificationCode
                          ?.GenerateTemporaryVerificationCode?.code
                      ) : (
                        <TextButton
                          css={{ padding: 0 }}
                          type="button"
                          onClick={() => {
                            generateTemporaryVerificationCode({
                              variables: {
                                accountId: account.account.id!,
                              },
                            });
                          }}
                        >
                          Show Verification Pin
                        </TextButton>
                      )}
                    </DefinitionListItem>
                  )}
                  <DefinitionListItem term="First Name">
                    <CopyToClipField name="firstName" />
                  </DefinitionListItem>
                  <DefinitionListItem term="Last Name">
                    <CopyToClipField name="lastName" />
                  </DefinitionListItem>
                  <DefinitionListItem term="Phone Number">
                    <CopyToClipField
                      name="phoneNumber"
                      transform={(value) => {
                        const parsedNumber = parsePhoneNumberFromString(
                          value,
                          'US',
                        );

                        return parsedNumber?.format('NATIONAL') ?? '';
                      }}
                    />
                  </DefinitionListItem>
                  <DefinitionListItem term="Email">
                    <CopyToClipField name="email" />
                  </DefinitionListItem>
                  <DefinitionListItem term="Pronouns">
                    {values.preferredPronounType === 'other' ? (
                      <CopyToClipField name="preferredPronoun" />
                    ) : (
                      <CopyToClipField
                        name="preferredPronounType"
                        transform={(value) => getPreferredPronoun(value)}
                      />
                    )}
                  </DefinitionListItem>
                  <DefinitionListItem
                    term={`Relationship to ${
                      patient.goesByName || patient.firstName
                    }`}
                  >
                    <CopyToClipField
                      name="relationship"
                      transform={(value) => getRelationshipDisplay(value)}
                    />
                  </DefinitionListItem>
                  <DefinitionListItem term="Agreed to Terms of Use and Privacy Policy">
                    {account?.account?.consentToTermsAndPrivacyTimestamp
                      ? DateTime.fromISO(
                          account?.account?.consentToTermsAndPrivacyTimestamp,
                        ).toFormat('f')
                      : 'Has not yet agreed'}
                  </DefinitionListItem>
                  <DefinitionListItem term="Agreed to Medical Terms">
                    {account?.account?.consentToMedicalTermsTimestamp
                      ? DateTime.fromISO(
                          account?.account?.consentToMedicalTermsTimestamp,
                        ).toFormat('f')
                      : 'Has not yet agreed'}
                  </DefinitionListItem>
                </DefinitionList>
              )}
              {status.editing && (
                <Fragment>
                  <ContentRow>
                    <NormalField title="First name" name="firstName" />
                    <NormalField title="Last name" name="lastName" />
                  </ContentRow>
                  <ContentRow>
                    <InfoSection title="Phone Number">
                      <Field name="phoneNumber">
                        {({ field }: FieldProps<any>) => (
                          <Fragment>
                            <PhoneInput
                              {...field}
                              onChange={(value) =>
                                setFieldValue('phoneNumber', value)
                              }
                            />
                            <ErrorMessage
                              name={field.name}
                              component={ErrorText}
                            />
                          </Fragment>
                        )}
                      </Field>
                    </InfoSection>
                    <NormalField title="Email" name="email" />
                  </ContentRow>
                  <ContentRow>
                    <InfoSection title="Pronouns">
                      <SelectSimpleField name="preferredPronounType">
                        <option value="">Select</option>
                        {PREFERRED_PRONOUN.map(({ label, value }) => {
                          return <option value={value}>{label}</option>;
                        })}
                      </SelectSimpleField>
                    </InfoSection>
                    {values.preferredPronounType === 'other' && (
                      <NormalField title="Custom" name="preferredPronoun" />
                    )}
                    <InfoSection
                      title={`Relationship to ${
                        patient.goesByName || patient.firstName
                      }`}
                    >
                      <SelectSimpleField name="relationship">
                        <option value="">Select</option>
                        {RELATIONSHIPS.map(({ label, value }) => (
                          <option value={value} key={value}>
                            {label}
                          </option>
                        ))}
                      </SelectSimpleField>
                    </InfoSection>
                  </ContentRow>
                  {isAccompanying && (
                    <ContentRow>
                      <InfoWrap>
                        <Label>
                          Agreed to{' '}
                          <Link href="https://bravecare.com/terms">
                            Terms of Use
                          </Link>{' '}
                          and{' '}
                          <Link href="https://www.bravecare.com/privacy">
                            Privacy Policy
                          </Link>
                        </Label>
                        {!account?.account
                          ?.consentToTermsAndPrivacyTimestamp ? (
                          <OutlineButton
                            type="button"
                            style={{
                              borderRadius: '100px',
                              padding: '16px 40px',
                              width: 'max-content',
                            }}
                            onClick={handleAgreesToTermsAndPrivacy}
                          >
                            Yes, {values.firstName} agrees
                          </OutlineButton>
                        ) : (
                          <PrimaryText>
                            {account?.account?.consentToTermsAndPrivacyTimestamp
                              ? DateTime.fromISO(
                                  account?.account
                                    ?.consentToTermsAndPrivacyTimestamp,
                                ).toFormat('f')
                              : 'Has not yet agreed'}
                          </PrimaryText>
                        )}
                      </InfoWrap>
                      <InfoWrap>
                        <Label>
                          Agreed to{' '}
                          <Link href="https://bravecare.com/medical-terms-of-service">
                            Medical Terms
                          </Link>{' '}
                        </Label>
                        {!account?.account?.consentToMedicalTermsTimestamp ? (
                          <OutlineButton
                            type="button"
                            style={{
                              borderRadius: '100px',
                              padding: '16px 40px',
                              width: 'max-content',
                            }}
                            onClick={handleAgreesToMedicalTerms}
                          >
                            Yes, {values.firstName} agrees
                          </OutlineButton>
                        ) : (
                          <PrimaryText>
                            {account?.account?.consentToMedicalTermsTimestamp
                              ? DateTime.fromISO(
                                  account?.account
                                    ?.consentToMedicalTermsTimestamp,
                                ).toFormat('f')
                              : 'Has not yet agreed'}
                          </PrimaryText>
                        )}
                      </InfoWrap>
                    </ContentRow>
                  )}
                  <ContentRow>
                    {account?.account?.id && !isNew && accounts.length > 1 && (
                      <ButtonRedSmall
                        onClick={() => {
                          setDeleteOpen(true);
                        }}
                      >
                        Remove
                      </ButtonRedSmall>
                    )}
                    {isSubmitting ? (
                      <div style={{ margin: '0px auto' }}>
                        <Loader />
                      </div>
                    ) : (
                      <Button type="submit" disabled={!isValid}>
                        Save
                      </Button>
                    )}
                  </ContentRow>
                </Fragment>
              )}
              <ContentRow css={{ paddingTop: 16 }}>
                <PhotoIdDisplay accountId={account?.accountId} />
              </ContentRow>
            </Form>
          );
        }}
      </Formik>
      <RemoveCaregiverConfirmation
        isOpen={deleteOpen}
        accountName={`${account?.account?.firstName} ${account?.account?.lastName}`}
        patientName={`${patient.firstName} ${patient.lastName}`}
        onCancel={() => setDeleteOpen(false)}
        onRemove={async () => {
          if (account && patient) {
            await removeAccountFromPatient({
              variables: {
                accountId: account?.account?.id,
                patientId: patient?.id,
              },
            });
          }
          setDeleteOpen(false);
        }}
      />
    </Fragment>
  );
};
