import React from 'react';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { IF_TABLET } from '../../styles';

export const ModalWrap = ({
  children,
  onDismissGoToPath = '/dashboard',
  onDismiss,
}: {
  history?: any;
  onDismissGoToPath?: string;
  onDismiss?: () => void;
  children: React.ReactNode;
}) => {
  const history = useHistory();

  return (
    <DialogOverlay
      isOpen
      onDismiss={() => {
        if (onDismiss) {
          onDismiss();
        } else if (onDismissGoToPath) {
          history.push(onDismissGoToPath);
        }
      }}
      style={{
        zIndex: 99999,
      }}
    >
      {children}
    </DialogOverlay>
  );
};

export const Content = styled(DialogContent)`
  width: 90%;
  margin: 64px auto;
  padding: 0;
  border-radius: 16px;
  max-height: calc(100% - 128px);
  overflow-y: scroll;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 4%), 0px 2px 6px rgb(0 0 0 / 4%),
    0px 0px 1px rgb(0 0 0 / 4%);
  ${IF_TABLET} {
    width: 100%;
    margin: 32px auto;
    max-width: 760px;
    max-height: calc(100% - 64px);
  }
`;

export const TaskContent = styled(Content)`
  display: flex;
  flex-direction: column;
`;
