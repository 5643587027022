import { Colors } from '@bc/theme';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const PRIMARY = Colors.orange;
export const GREY = Colors.darkHarbor;
export const GREY_DARK = '#ADB8B7';
export const GREY_LIGHT = Colors.grayLight;
export const GREY_EXTRA_LIGHT = '#FAFAFA';

export const ACCENT_YELLOW = Colors.butterscotch;
export const ACCENT_YELLOW_LIGHT = Colors.butterscotchLight;

export const ACCENT_PINK = Colors.bubblegum;
export const ACCENT_PINK_HOVER = '#FF2843';
export const ACCENT_PINK_LIGHT = '#FFDDE1';

export const ACCENT_PURPLE = Colors.purple;
export const ACCENT_PURPLE_LIGHT = Colors.purpleLight;

export const ACCENT_RED = '#FF0000';
export const ACCENT_RED_LIGHT = '#FF6666';

export const ACCENT_BLUE = Colors.blue;
export const ACCENT_BLUE_LIGHT = Colors.blueLight;
export const ACCENT_BLUE_EXTRA_LIGHT = '#E0EAF4';

export const ACCENT_GREEN = Colors.teal;
export const ACCENT_GREEN_HOVER = '#00CBA4';
export const ACCENT_GREEN_LIGHT = Colors.tealLight;
export const ACCENT_GREEN_EXTRA_LIGHT = '#DAF4EF';
export const ACCENT_GREEN_ACTIVE = '#0E947A';

export const TABLET_WIDTH = 768;
export const DESKTOP_WIDTH = 992;

export const IF_MOBILE_SMALL = `@media (max-width: 340px)`;
export const IF_MOBILE = `@media (min-width: 576px)`;
export const IF_TABLET = `@media (min-width: ${TABLET_WIDTH}px)`;
export const IF_DESKTOP = `@media (min-width: ${DESKTOP_WIDTH}px)`;

export const FONT_DISPLAY = `font-family: 'Tiempos', serif;`;
export const FONT_PRIMARY = `font-family: 'Greycliff', Helvetica, sans-serif;`;

export const BUTTON = css`
  ${FONT_PRIMARY}
  border-radius: 100px;
  border: none;
  color: ${Colors.white};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  text-decoration: none;
  transition: background 500ms;

  &:disabled,
  &[disabled] {
    background-color: ${Colors.grayLight};
    color: ${Colors.gray};
    &:hover {
      background-color: ${Colors.grayLight};
      color: ${Colors.gray};
      cursor: not-allowed;
    }
  }

  &:active {
    color: ${Colors.white};
    outline: none;
    border: none;
  }
  &:hover {
    color: ${Colors.white};
  }
`;

export const BUTTON_DARK_HARBOR = css`
  ${BUTTON}
  background: ${Colors.darkHarbor};
  &:hover {
    background: ${Colors.tealDark};
  }
  &:active {
    background: ${Colors.darkHarborLight};
  }
`;

export const BUTTON_GREEN = css`
  ${BUTTON}
  background: ${ACCENT_GREEN};
  &:hover {
    background: ${ACCENT_GREEN_HOVER};
  }
  &:active {
    background: ${ACCENT_GREEN_ACTIVE};
  }
`;

export const BUTTON_RED = css`
  ${BUTTON}
  background: ${Colors.errorRed};
  &:hover {
    // errorRed lightened by 0.1
    background: #ef3f00;
  }
  &:active {
    background: ${Colors.errorRed};
  }
`;

export const BUTTON_TRANSPARENT = css`
  ${BUTTON}
  background: transparent;
  color: ${ACCENT_GREEN};
  &:active,
  &:hover {
    color: ${ACCENT_GREEN_HOVER};
  }
`;

export const BUTTON_OUTLINE = css`
  ${BUTTON_TRANSPARENT}
  border: 1px solid ${ACCENT_GREEN};
  color: ${ACCENT_GREEN};
  &:active,
  &:hover {
    border: 1px solid ${ACCENT_GREEN};
    background-color: ${Colors.teal};
    color: #fff;
  }
`;

export const BUTTON_OUTLINE_DARK = css`
  ${BUTTON_TRANSPARENT}
  border: 1px solid ${Colors.grayLight};
  color: ${Colors.darkHarbor};
  &:active,
  &:hover {
    border: 1px solid ${Colors.grayLight};
    background-color: ${Colors.grayLightest};
    color: ${Colors.darkHarbor};
  }
`;

export const BodyWrap = styled.div`
  flex-direction: column;
  display: flex;
  min-height: 100vh;
`;

export const Row = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const WrapRow = styled(Row)({
  flexWrap: 'wrap',
});

export const MobileWrapRow = styled(WrapRow)({
  [IF_TABLET]: {
    flexWrap: 'nowrap',
  },
});

export const Column = styled.div({
  display: 'flex',
  flexDirection: 'column',
});
