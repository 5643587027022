import styled from '@emotion/styled';
import map from 'lodash/map';
import React from 'react';
import { FONT_PRIMARY, GREY } from '../../styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ResultsContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const InputContainer = styled.form`
  position: relative;
`;

export const SearchIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

export const ResultDropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 5;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
  overflow: hidden;
`;

export const Result = styled.a<{ highlighted: boolean }>`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eee;
  align-items: center;
  overflow: hidden;
  padding: 0 20px;
  text-decoration: none;
  color: #314d4a;
  font-size: 18px;
  line-height: 28px;
  ${FONT_PRIMARY}
  color: ${GREY};

  background-color: ${({ highlighted }) =>
    highlighted ? '#eee' : 'transparent'};

  :hover {
    background-color: #eee;
    color: ${GREY};
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

interface Value {
  label: string;
  value: string;
}

interface Props {
  inputValue: string | undefined;
  highlightedIndex: number | null;
  getItemProps: (options: any) => any;
  options?: Value[];
}

const Results: React.FC<Props> = ({
  highlightedIndex,
  getItemProps,
  options = [],
}) => {
  if (options.length === 0) return null;

  return (
    <ResultDropdownContainer>
      <div role="menu">
        <div>
          {map(options, (option, index: number) => (
            <Result
              href="#"
              highlighted={highlightedIndex === index}
              {...getItemProps({
                item: option.value,
                index,
                key: option.value,
                onClick: (e: any) => e.preventDefault(),
              })}
            >
              <div>
                <p>{option.label}</p>
              </div>
            </Result>
          ))}
        </div>
      </div>
    </ResultDropdownContainer>
  );
};

export default Results;
