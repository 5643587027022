/** @jsx jsx */
import {
  StripeRefundReason,
  useCreateRefundMutation,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { DialogOverlay } from '@reach/dialog';
import * as Sentry from '@sentry/react';
import { Form, Formik } from 'formik';
import React, { Fragment, useState } from 'react';
import { object, string } from 'yup';
import { ICONS } from '../../assets';
import { ACCENT_GREEN_HOVER } from '../../styles';
import { ModalClose, ModalContent, SmallModal } from '../Audit/styles';
import { Button, OutlineButton, TextButton } from '../button';
import {
  ErrorField,
  MoneyInputField,
  NormalField,
  SelectSimpleField,
} from '../form';
import { Undo } from '../Icons';
import { InfoSection } from '../layout';
import { PrimaryText, SmallTitle } from '../text';

const SmallIcon = styled.img({
  height: 16,
  marginRight: 16,
  width: 16,
});

const Reminder = styled.div({
  background: Colors.grayLightest,
  borderRadius: 8,
  display: 'flex',
  marginTop: 16,
  padding: 16,
});

const ButtonWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginTop: 24,
});

const Text = styled(PrimaryText)({}).withComponent('div');

const Validation = object().shape({
  amount: string().required('An amount to refund is required'),
  reason: string()
    .oneOf(['duplicate', 'fraudulent', 'requested_by_customer'])
    .required('A reason is required'),
  note: string(),
});

interface RefundValue {
  amount: string;
  reason: StripeRefundReason | '';
  note?: string;
}

export const REASONS = [
  {
    label: 'Duplicate',
    value: 'duplicate',
  },
  {
    label: 'Fradulent',
    value: 'fraudulent',
  },
  {
    label: 'Requested by customer',
    value: 'requested_by_customer',
  },
];

const getErrorCode = (error: any) => {
  return error?.graphQLErrors[0].message;
};

const ErrorMessage = styled(PrimaryText)({
  color: Colors.errorRed,
  marginTop: 16,
}).withComponent('p');

const RefundModal = ({
  stripeChargeId,
  isOpen,
  onClose,
}: {
  stripeChargeId: string;
  isOpen: boolean;
  onClose: (e?: React.SyntheticEvent<Element, Event>) => void;
}) => {
  const [createRefund] = useCreateRefundMutation();
  const [error, setError] = useState('');

  const handleSubmit = async (values: RefundValue) => {
    try {
      const { amount, note, reason } = values;
      const parsedAmount = parseFloat(amount.replace(/[^0-9\.]/, '')) || 0;
      if (reason === '') {
        setError('A reason is required');
        Sentry.captureException('A reason is required');
        return;
      }
      const stripeReason = reason as StripeRefundReason;
      await createRefund({
        variables: {
          reason: stripeReason,
          refundAmount: parsedAmount,
          stripeChargeId,
          ...(note && { note }),
        },
      });
      setError('');
      onClose();
    } catch (e) {
      console.log(getErrorCode(e));
      Sentry.captureException(e);
      setError(getErrorCode(e));
    }
  };

  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={() => {
        setError('');
        onClose();
      }}
      style={{
        zIndex: 100,
      }}
    >
      <SmallModal aria-label="Refund Payment">
        <ModalClose
          onClick={() => {
            setError('');
            onClose();
          }}
          css={{ position: 'absolute', top: 24, right: 24 }}
        />
        <SmallTitle>Refund Payment</SmallTitle>
        <ModalContent css={{ paddingBottom: 24 }}>
          <Reminder>
            <SmallIcon src={ICONS.info} role="presentation" />
            <Text>
              Refunds take 5-10 days to appear on a customer’s statement.
            </Text>
          </Reminder>
          <Formik
            initialValues={{
              amount: '',
              note: '',
              reason: 'duplicate',
            }}
            validationSchema={Validation}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <MoneyInputField
                  name="amount"
                  title="Refund"
                  css={{ marginTop: 16 }}
                />
                <InfoSection
                  title="Reason"
                  fullWidth
                  css={{ margin: '16px 0' }}
                >
                  <SelectSimpleField name="reason">
                    <option value="">Select a reason</option>
                    {REASONS.map(({ label, value }) => (
                      <option value={value} key={value}>
                        {label}
                      </option>
                    ))}
                  </SelectSimpleField>
                  <ErrorField name="reason" />
                </InfoSection>
                <NormalField
                  name="note"
                  title="Note"
                  placeholder="Add a note for this refund"
                  fullWidth
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <ButtonWrapper>
                  <OutlineButton
                    css={{ padding: '16px 32px', marginRight: 16 }}
                    onClick={(e) => {
                      e.preventDefault();
                      setError('');
                      onClose();
                    }}
                  >
                    Cancel
                  </OutlineButton>
                  <Button type="submit" disabled={isSubmitting}>
                    Refund
                  </Button>
                </ButtonWrapper>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </SmallModal>
    </DialogOverlay>
  );
};

export const Refund: React.FC<{
  isMore?: boolean;
  onClose: () => void;
  stripeChargeId: string;
}> = ({ isMore, onClose, stripeChargeId }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <TextButton
        css={{ alignItems: 'center', padding: 0, position: 'relative' }}
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
      >
        <Undo
          hoverColor={ACCENT_GREEN_HOVER}
          css={{ right: '100%', position: 'absolute' }}
          size={32}
        />
        Refund{isMore ? ' More' : ''}
      </TextButton>
      <RefundModal
        isOpen={isOpen}
        onClose={(e) => {
          e?.preventDefault();
          onClose();
          setIsOpen(false);
        }}
        stripeChargeId={stripeChargeId}
      />
    </Fragment>
  );
};
