/** @jsx jsx */
import { useLoadAllProductsQuery } from '@bc/codegen/manager';
import { Products_Order_By, Products_Select_Column } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Header } from '../../components/Header';
import { Content, InnerWrap, MainContent } from '../../components/layout';
import { Sidebar } from '../../components/Nav/Sidebar';
import { TdOther, TdTask, ThOther, ThTask } from '../../components/Tasks/ui';
import { LargeTitle, PrimaryText } from '../../components/text';
import { numberToCurrency } from '../../helpers';
import { TitleSort } from '../tasks/styles';

const context = { role: 'manager' };

const TaskIndex = () => {
  const [orderBy, setOrderBy] = useState<Products_Order_By>({
    name: 'asc',
  });

  const { data } = useLoadAllProductsQuery({
    context,
    variables: {
      where: {},
      order_by: orderBy,
    },
  });
  const createSortToggle = (property: Products_Select_Column) => () => {
    if (orderBy[property] === 'asc') {
      setOrderBy({
        [property]: 'desc',
      });
    } else {
      setOrderBy({
        [property]: 'asc',
      });
    }
  };

  const products = (data?.products ?? []).filter((product) => {
    return product.type !== 'cp';
  });

  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap>
          <Sidebar />
          <MainContent
            style={{
              paddingTop: '16px',
            }}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '32px',
              }}
            >
              <LargeTitle>Product List</LargeTitle>
              <Link to="/products">
                <PrimaryText>Product Search</PrimaryText>
              </Link>
            </div>
            <table css={{ width: '100%' }}>
              <thead>
                <tr>
                  <ThTask
                    onClick={createSortToggle('name')}
                    css={{ cursor: 'pointer' }}
                  >
                    <TitleSort direction={orderBy.name}>Name</TitleSort>
                  </ThTask>
                  <ThOther
                    onClick={createSortToggle('price')}
                    css={{ cursor: 'pointer' }}
                  >
                    <TitleSort direction={orderBy.price}>Price</TitleSort>
                  </ThOther>
                  <ThOther
                    onClick={createSortToggle('type')}
                    css={{ cursor: 'pointer' }}
                  >
                    <TitleSort direction={orderBy.type}>Type</TitleSort>
                  </ThOther>
                  <ThOther
                    css={[
                      {
                        borderRight: 'none',
                      },
                    ]}
                  ></ThOther>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr>
                    <TdTask>
                      <PrimaryText>{product.name}</PrimaryText>
                    </TdTask>
                    <TdOther>
                      <PrimaryText>
                        {numberToCurrency(product.price)}
                      </PrimaryText>
                    </TdOther>
                    <TdOther>
                      <PrimaryText>{product.type}</PrimaryText>
                    </TdOther>
                    <TdOther
                      css={{
                        borderRight: 'none',
                      }}
                    >
                      <Link
                        to={`/products/${product.id}`}
                        css={{
                          textDecoration: 'none',
                        }}
                      >
                        <PrimaryText>Edit</PrimaryText>
                      </Link>
                    </TdOther>
                  </tr>
                ))}
              </tbody>
            </table>
          </MainContent>
        </InnerWrap>
      </Content>
    </Fragment>
  );
};

export default TaskIndex;

{
  /* <tr css={{ opacity: isCompleted || hasRead ? '0.4' : '1' }}>
      <TdTask isLast={isLast}>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '11px',
            flex: 1,
          }}
        >
          {(assignedToMe || isCompleted) && isAction && (
            <TransparentButton
              onClick={() => {
                if (!isCompleted) {
                  updateTask(task.id, {
                    status: 'COMPLETED',
                  });
                }
              }}
            >
              <img
                src={
                  isCompleted ? ICONS.circle_check_green : ICONS.circle_check
                }
              />
            </TransparentButton>
          )}
          <Link
            to={`/tasks/${task.id}`}
            css={{
              flex: 1,
              textDecoration: 'none',
            }}
          >
            <PrimaryText>{task.title}</PrimaryText>
          </Link>
        </div>
      </TdTask>
      <TdOther isLast={isLast}>
        {task.priority && PriorityPills[task.priority]}
      </TdOther>
      <TdOther isLast={isLast}>
        <DueDateTime
          dueDateTime={task.dueDateTime}
          isCompleted={isCompleted || hasRead}
        />
      </TdOther>
      <TdOther isLast={isLast}>
        <PrimaryText
          css={{
            color: '#647977',
          }}
        >
          {responsibleDisplay}
        </PrimaryText>
      </TdOther>
      <TdOther isLast={isLast}>
        {isAction && (
          <Fragment>
            {!isCompleted && !assignedStaff && canClaim && (
              <OutlineButtonDark
                onClick={() => {
                  if (currentStaff) {
                    assignTask({
                      taskId: task.id,
                      staffId: currentStaff.id,
                    });
                  }
                }}
                css={{
                  borderRadius: '100px',
                }}
              >
                Claim Task
              </OutlineButtonDark>
            )}
            {assignedStaff && (
              <div css={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <StaffImage src={assignedStaff?.avatarUrl ?? undefined} />
                <PrimaryText
                  css={{
                    color: '#647977',
                  }}
                >
                  {assignedStaff?.firstName} {assignedStaff?.lastName}
                </PrimaryText>
              </div>
            )}
          </Fragment>
        )}
      </TdOther>
      <TdOther isLast={isLast}>
        {isMemo && (
          <PrimaryText
            css={{
              color: '#647977',
            }}
          >
            Read by {readCount} of {totalReadCount}
          </PrimaryText>
        )}
        {isAction && (
          <Fragment>
            {!claimedForSelf && <StatusDisplay value={task.status} />}
            {claimedForSelf && (
              <StatusDropdown
                value={task.status}
                onChange={(status) => {
                  updateTask(task.id, {
                    status: status,
                  });
                }}
                css={{
                  border: 'none',
                  width: '100%',
                  padding: 0,
                }}
              />
            )}
          </Fragment>
        )}
      </TdOther>
      <TdOther
        isLast={isLast}
        css={{
          borderRight: 'none',
        }}
      >
        <PrimaryText>{getTypeAbbreviation(task.subType)}</PrimaryText>
      </TdOther>
    </tr> */
}
