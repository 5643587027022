import React from "react";
import styled from "@emotion/styled";
import fuzzaldrin from "fuzzaldrin-plus";
import { FONT_PRIMARY, GREY } from "../../styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ResultsContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const InputContainer = styled.form`
  position: relative;
`;

export const SearchIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

export const ResultDropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 5;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
  overflow: hidden;
`;

export const Result = styled.a<{ highlighted: boolean }>`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eee;
  align-items: center;
  overflow: hidden;
  padding: 0 20px;
  text-decoration: none;
  font-size: 18px;
  line-height: 28px;
  color: ${GREY};
  ${FONT_PRIMARY};

  background-color: ${({ highlighted }) =>
    highlighted ? "#eee" : "transparent"};

  :hover {
    background-color: #eee;
  }

  &:last-of-type {
    border-bottom: none;
  }
  strong {
    font-weight: bold;
  }
`;

interface Props {
  highlightedIndex: number | null;
  getItemProps: any;
  items: string[];
  inputValue?: string;
  selectedItem?: string | null;
}

export const SearchResults: React.FC<Props> = ({
  highlightedIndex,
  getItemProps,
  items,
  inputValue = "",
  selectedItem
}) => {
  if (items.length === 0) return null;

  return (
    <ResultDropdownContainer>
      <div role="menu">
        <div>
          {items.map((item: string, index: number) => {
            return (
              <Result
                href="#"
                highlighted={highlightedIndex === index}
                {...getItemProps({
                  item: item,
                  index,
                  key: item,
                  onClick: (e: any) => e.preventDefault(),
                  style: {
                    backgroundColor:
                      highlightedIndex === index ? "lightgray" : "white",
                    fontWeight: selectedItem === item ? "bold" : "normal"
                  }
                })}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: fuzzaldrin.wrap(item, inputValue || "")
                  }}
                />
              </Result>
            );
          })}
        </div>
      </div>
    </ResultDropdownContainer>
  );
};
