import { ApolloProvider, Operation } from '@apollo/client';
import createAuth0Client from '@auth0/auth0-spa-js';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import { render } from 'react-dom';
import 'react-image-lightbox/style.css';
import { BrowserRouter } from 'react-router-dom';
import GlobalCss from './components/GlobalCss';
import { createApolloClient } from './lib/apollo';
import { CommunicationProvider } from './lib/sound';
import Auth0Provider from './react-auth0';
import { App, history } from './routes';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 0.1,
});

const run = async () => {
  const authConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    redirect_uri: `${window.location.origin}/auth/verify`,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  };

  const auth0Client = await createAuth0Client(authConfig);

  const getToken = async (): Promise<string | undefined | null> => {
    let token;
    const sessionAuthToken = window.sessionStorage.getItem('auth_token');
    const localAuthToken = window.localStorage.getItem('auth_token');
    const authToken = sessionAuthToken ?? localAuthToken;
    if (!authToken) {
      const claims = await auth0Client.getIdTokenClaims();
      token = claims?.__raw;
    } else {
      token = authToken;
    }

    return token;
  };

  const handleRequest = async (operation: Operation) => {
    if (typeof window !== 'undefined') {
      const context = operation.getContext() || {};
      const token = await getToken();

      if (token && !context.anonymous) {
        let role = 'assistant';

        if (context.role) {
          role = context.role;
        }

        operation.setContext({
          headers: {
            authorization: `Bearer ${token}`,
            'x-hasura-role': role,
          },
        });
      }
    }
  };

  const connectionParams = async () => {
    const token = await getToken();

    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-hasura-role': 'assistant',
        },
      };
    }
  };

  const client = createApolloClient(
    process.env.REACT_APP_CLIENT_SERVER!,
    handleRequest,
    {
      connectionParams,
    },
  );

  const routes = (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <Auth0Provider
          client={auth0Client}
          redirect_uri={authConfig.redirect_uri}
        >
          <CommunicationProvider>
            <GlobalCss />
            <App />
          </CommunicationProvider>
        </Auth0Provider>
      </ApolloProvider>
    </BrowserRouter>
  );

  render(routes, document.getElementById('root'));
};

run();
