import React, { useState } from 'react';
import Downshift from 'downshift';
import { Input, InputWrap } from '../form';
import Results, { Container, ResultsContainer } from './ui';
import { TextButton } from '../button';
import { matchSorter } from 'match-sorter';
import { ICONS } from '../../assets';

export interface Value {
  label: string;
  value: string;
}

interface Props {
  onChange: (item?: string) => void;
  value?: string;
  error: boolean;
  options: Value[];
  placeholder?: string;
  icon?: string;
}

const fuzzyFilter = (input: string, values: Value[]): Value[] => {
  return matchSorter(values, input, {
    keys: ['text', 'value'],
  });
};

const BasicSearchInput: React.FC<Props> = ({
  onChange,
  value,
  error,
  options,
  placeholder,
  icon,
}) => {
  const displayValue = options.find((option) => {
    return option.value === value;
  });

  const [inputValue, setInputValue] = useState(displayValue?.label);

  const itemToString = (val: any) => {
    const displayValue = options.find((option) => {
      return option.value === val;
    });

    return displayValue?.label || '';
  };

  const handleLocationChange = (val: string | null) => {
    onChange(val || undefined);
  };

  const results = fuzzyFilter(inputValue || '', options);

  return (
    <Downshift
      onChange={handleLocationChange}
      itemToString={itemToString}
      inputValue={inputValue}
      onInputValueChange={(value) => setInputValue(value)}
    >
      {({
        getRootProps,
        getInputProps,
        getItemProps,
        highlightedIndex,
        isOpen,
        openMenu,
        closeMenu,
      }) => {
        return (
          <Container {...getRootProps()}>
            <ResultsContainer>
              <div style={{ position: 'relative' }}>
                <img
                  src={icon}
                  style={{
                    position: 'absolute',
                    left: '12px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 2,
                  }}
                />
                <InputWrap style={{ marginBottom: 0 }}>
                  <Input
                    {...getInputProps({
                      onFocus: openMenu,
                      type: 'text',
                      placeholder: placeholder,
                      autoComplete: 'off',
                      onBlur: () => {
                        closeMenu();
                      },
                      style: {
                        paddingLeft: '48px',
                      },
                    })}
                    error={error}
                  />
                </InputWrap>
                {value && (
                  <TextButton
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                    type="button"
                    onClick={() => {
                      setInputValue('');
                      onChange(undefined);
                    }}
                  >
                    <img src={ICONS.clear} />
                  </TextButton>
                )}
              </div>

              {isOpen && (
                <Results
                  inputValue={inputValue}
                  highlightedIndex={highlightedIndex}
                  getItemProps={getItemProps}
                  options={results}
                />
              )}
            </ResultsContainer>
          </Container>
        );
      }}
    </Downshift>
  );
};

export default BasicSearchInput;
