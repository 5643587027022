import { StaffFragment } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import React, { useState } from 'react';
import { fullStaffName } from '../../pages/staff/helpers';
import { Button, CloseButton, OutlineButtonDark } from '../button';
import { Textarea } from '../form';
import { LargeTitle, PrimaryText } from '../text';

interface ChatSummaryPromptProps {
  isOpen: boolean;
  onDismiss: () => void;
  onSubmit?: (note: string) => void;
  placeholder?: string;
  staff?: StaffFragment;
  title: string;
}

export const ChatNotePrompt: React.FC<ChatSummaryPromptProps> = ({
  isOpen,
  onDismiss,
  onSubmit,
  placeholder = 'Add summary...',
  staff,
  title,
}) => {
  const [note, setNote] = useState();

  return (
    <Overlay isOpen={isOpen} onDismiss={onDismiss}>
      <Content>
        <CloseButton
          onClick={onDismiss}
          style={{ display: 'block', marginLeft: 'auto' }}
        />
        <Title>{title}</Title>
        <Textarea
          placeholder={placeholder}
          onChange={(e: any) => setNote(e.target.value)}
          value={note}
          style={{ width: '100%' }}
        />
        {staff && (
          <StaffInfo>
            <Avatar src={`${staff.avatarUrl}?w=64&h=64`} />
            <StaffName>{fullStaffName(staff)}</StaffName>
          </StaffInfo>
        )}
        <Actions>
          <CancelButton onClick={onDismiss}>Not Now</CancelButton>
          <Button
            disabled={!note}
            onClick={() => {
              if (note && onSubmit) {
                onSubmit(note!);
              }
              onDismiss();
            }}
          >
            Submit
          </Button>
        </Actions>
      </Content>
    </Overlay>
  );
};

const Overlay = styled(DialogOverlay)({
  zIndex: 999,
});

const Content = styled(DialogContent)({
  background: Colors.white,
  boxShadow: '0px 10px 37px rgba(0, 0, 0, 0.15)',
  borderRadius: 5,
});

const Title = styled(LargeTitle)({
  display: 'block',
  lineHeight: '40px',
  marginBottom: 32,
});

const StaffInfo = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  marginTop: 8,
});

const Avatar = styled.img({
  borderRadius: 16,
  height: 32,
  width: 32,
});

const StaffName = styled(PrimaryText)({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '18px',
  marginLeft: 8,
});

const Actions = styled.div({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: 40,
});

const CancelButton = styled(OutlineButtonDark)({
  borderRadius: 100,
  height: 56,
  padding: '16px 32px',
});
