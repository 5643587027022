import { useAppointmentCountsForDaysQuery } from '@bc/codegen/medical';

export const useAppointmentCounts = ({
  clinicCodes,
  start,
  end,
}: {
  clinicCodes: string[];
  start: string;
  end: string;
}) => {
  const { data: appointmentData } = useAppointmentCountsForDaysQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        clinic: { clinicCode: { _in: clinicCodes } },
        date: {
          _gte: start,
          _lte: end,
        },
      },
    },
  });

  return appointmentData?.appointment_counts;
};
