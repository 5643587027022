/** @jsx jsx */
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import CaretDown from '../../assets/caret-down.svg';
import { PatientAvatarDisplay } from '../../components/AppointmentDetails/AvatarDisplay';
import { LargeTitle, SmallTitle } from '../../components/text';
import { Room } from '../../data/useGetRoomsByClinicCode/useGetRoomsByClinicCode';
import { FONT_PRIMARY, IF_TABLET } from '../../styles';

const STATUSES_MAP = {
  EMPTY: 'Empty',
  READY_FOR_PATIENT: 'Ready for patient',
  READY_FOR_MA: 'Ready for medical assistant',
  WITH_MA: 'With medical assistant',
  READY_FOR_PROVIDER: 'Ready for provider',
  WITH_PROVIDER: 'With provider',
  PROVIDER_FINISHED: 'Provider finished',
  DIRTY: 'Dirty',
  RESERVED: 'Reserved',
  BLOCKED: 'Blocked',
};

export const RoomRow: React.FC<{
  room: Room;
  number: number;
  onSelect: ({ status, roomId }: { status: string; roomId: string }) => void;
  onPatientClick: (appointmentId: string) => void;
}> = ({ room, number, onSelect, onPatientClick }) => {
  const appointmentId = room?.appointment?.id;
  const patientId = room?.appointment?.appointment_patients?.[0]?.patient?.id;

  return (
    <Container>
      <Group css={{ marginRight: 16 }}>
        <Number
          css={{
            background:
              room?.status === 'READY_FOR_PROVIDER'
                ? Colors.butterscotchLightest
                : Colors.white,
          }}
        >
          {number}
        </Number>
        <SmallTitle css={{ margin: '0 auto 0 24px' }}>{room.name}</SmallTitle>
      </Group>
      <Group>
        {appointmentId && patientId && (
          <PatientAvatarDisplay
            patientId={patientId}
            onClick={() => {
              onPatientClick(appointmentId);
            }}
            style={{ height: 42, width: 42, marginRight: 16 }}
          />
        )}
        <SelectWrap>
          <Select
            onChange={(e) =>
              onSelect({
                status: e.target.value,
                roomId: room.id,
              })
            }
            key={room.status}
            defaultValue={room.status}
          >
            {Object.keys(STATUSES_MAP)
              .filter((status) =>
                appointmentId
                  ? true
                  : ['EMPTY', 'DIRTY', 'RESERVED', 'BLOCKED'].includes(status),
              )
              .map((status) => (
                <option value={status} key={status}>
                  {
                    //@ts-ignore
                    STATUSES_MAP[status]
                  }
                </option>
              ))}
          </Select>
          <Caret src={CaretDown} />
        </SelectWrap>
      </Group>
    </Container>
  );
};

export const Container = styled.div({
  display: 'flex',
  width: 'calc(100% - 32px)',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '8px 16px 8px 16px ',
  flexWrap: 'wrap',
  borderBottom: '1px solid #E5E8E8',
  paddingBottom: 16,
  [IF_TABLET]: {
    paddingBottom: 24,
    margin: '0 40px',
    width: 'calc(100% - 80px)',
  },
});

const Group = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 0 0',
  [IF_TABLET]: {
    padding: '24px 0 0',
  },
});

const Number = styled(LargeTitle)({
  borderRadius: '100px',
  border: `1px solid ${Colors.grayLight}`,
  width: 42,
  minWidth: 42,
  height: 42,
  lineHeight: '36px',
  textAlign: 'center',
  [IF_TABLET]: {
    lineHeight: '36px',
  },
});

export const Select = styled.select`
  appearance: none;
  background-color: #fff;
  color: ${Colors.darkHarbor};
  ${FONT_PRIMARY};
  font-weight: bold;
  border: 1px solid transparent;
  padding: 10px 30px 10px 8px;
  font-size: 14px;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
    border: 1px solid ${Colors.grayLight};
    border-radius: 4px;
  }
  ${IF_TABLET} {
    font-size: 16px;
  }
`;

export const Caret = styled.img`
  height: 18px;
  pointer-events: none;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  z-index: 5;
`;

export const SelectWrap = styled.div`
  display: flex;
  position: relative;
`;
