import { Colors } from '@bc/theme';

const PROVIDER_TYPES = [
  'PHYSICIAN',
  'PHYSICIAN_ASSISTANT',
  'NURSE_PRACTITIONER',
];

export const isProvider = (staffTypeValue = '') =>
  PROVIDER_TYPES.includes(staffTypeValue);

// order determines how chats are organized
export enum ChatLabel {
  WAITING_FOR_STAFF_TO_JOIN,
  NEEDS_PROVIDER,
  NEEDS_STAFF_RESPONSE,
  WAITING_FOR_CAREGIVER_RESPONSE,
  NEEDS_SUMMARY,
}

export const getChatLabel = (chat: any) => {
  const isClosed = chat?.status === 'CLOSED';
  const lastMessage = chat?.chat_messages[0];
  const staffs = chat?.chat_sessions_staffs.filter((s: any) => s.active);
  const noStaffJoined = staffs?.length === 0;
  const hasActiveProvider = !!staffs.find(
    (s: any) => isProvider(s.staff.staff_type.value) && s.active,
  );
  const readyForProvider =
    chat?.status === 'READY_FOR_PROVIDER' && !hasActiveProvider;

  const chatSummary = chat?.chat_notes.filter(
    (n: any) => n.type === 'SUMMARY',
  )[0];
  const needsSummary = isClosed && !chatSummary;

  const isLastMessageActionRequest =
    lastMessage?.type === 'ACTION' &&
    lastMessage?.request &&
    !lastMessage?.response;

  const isLastMessageFromStaff =
    lastMessage?.type === 'MESSAGE' && !lastMessage?.accountId;

  const waitingForCaregiverResponse =
    isLastMessageActionRequest || isLastMessageFromStaff;

  const chatLabel = readyForProvider
    ? ChatLabel.NEEDS_PROVIDER
    : needsSummary
    ? ChatLabel.NEEDS_SUMMARY
    : noStaffJoined
    ? ChatLabel.WAITING_FOR_STAFF_TO_JOIN
    : !waitingForCaregiverResponse
    ? ChatLabel.NEEDS_STAFF_RESPONSE
    : ChatLabel.WAITING_FOR_CAREGIVER_RESPONSE;
  return {
    chatLabel,
    ...chat,
  };
};

export const getLabelInfo = (label: ChatLabel) => {
  switch (label) {
    case ChatLabel.NEEDS_PROVIDER:
      return {
        color: '#6A35FF',
        text: 'Needs a provider',
        showIndicator: true,
      };
    case ChatLabel.NEEDS_SUMMARY:
      return {
        color: Colors.blue,
        text: 'Needs summary',
        showIndicator: true,
      };
    case ChatLabel.WAITING_FOR_STAFF_TO_JOIN:
      return {
        color: Colors.orange,
        text: 'Waiting for staff to join',
        showIndicator: true,
      };
    case ChatLabel.NEEDS_STAFF_RESPONSE:
      return {
        color: Colors.purple,
        text: 'Needs a response',
        showIndicator: true,
      };
    case ChatLabel.WAITING_FOR_CAREGIVER_RESPONSE:
    default:
      return {
        color: Colors.darkHarbor,
        text: 'Waiting for caregiver response',
        showIndicator: false,
      };
  }
};
