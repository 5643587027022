/** @jsx jsx */
import { useLoadAppointmentsQuery } from '@bc/codegen/medical';
import { VISIT_CLASSIFICATIONS, VISIT_TYPES } from '@bc/shared';
import { jsx } from '@emotion/core';
import { Menu, MenuItem } from '@reach/menu-button';
import { DateTime } from 'luxon';
import { ICONS } from '../../assets';
import {
  DropdownMenuList,
  DropdownText,
  PillMenuButton,
  SecondaryDropdownText,
} from './ui';

export const TaskAppointmentDropdown = ({
  patientId,
  onSelect,
}: {
  patientId: string;
  onSelect: (appointmentId: string) => void;
}) => {
  const { data } = useLoadAppointmentsQuery({
    variables: {
      where: {
        appointment_patients: {
          patient_id: {
            _eq: patientId,
          },
        },
      },
    },
  });

  const appointments = data?.appointments ?? [];

  return (
    <Menu>
      <PillMenuButton icon={ICONS.newVisit}>Link Appointment</PillMenuButton>
      <DropdownMenuList portal={false}>
        {appointments.map((appointment) => {
          const date = DateTime.fromISO(appointment.startTime);
          const careTypeTitle = appointment?.visitClassification
            ? VISIT_CLASSIFICATIONS[appointment.visitClassification]
            : appointment?.visitType
            ? VISIT_TYPES[appointment.visitType]
            : '';

          return (
            <MenuItem
              onSelect={() => {
                onSelect(appointment.id);
              }}
              css={{
                padding: '16px 24px',
                borderBottom: '1px solid #E5E8E8',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <DropdownText
                css={{
                  marginBottom: '4px',
                }}
              >
                {date.toFormat('MM/dd/yyyy')} for {careTypeTitle}
              </DropdownText>
              <SecondaryDropdownText>
                {appointment.reason}
              </SecondaryDropdownText>
            </MenuItem>
          );
        })}
      </DropdownMenuList>
    </Menu>
  );
};
