/** @jsx jsx */
import { Clinic_Location_Types_Enum } from '@bc/codegen/manager';
import {
  AccountFragment,
  AppointmentFragment,
  AppointmentPartsFragment,
  Appointment_Types_Enum,
  PatientFragment,
  useAddGlobalNoteMutation,
  useCreateAccountPatientMutation,
  useGetClinicByCodeQuery,
  useLoadAppointmentsQuery,
  useScheduleAppointmentMutation,
  useUpdateAccountMutation,
  useUpdateAppointmentMutation,
  useUpdateRoomMutation,
  Visit_Classifications_Enum,
  Visit_Types_Enum,
} from '@bc/codegen/medical';
import {
  AVAILABLE_CARE_TYPES,
  dateOfBirthYupSchema,
  RELATIONSHIPS,
  VISIT_CLASSIFICATIONS as CLASSIFICATIONS,
  VISIT_TYPES as TYPES,
} from '@bc/shared';
import { jsx } from '@emotion/core';
import * as Sentry from '@sentry/react';
import {
  ErrorMessage,
  Form,
  Formik,
  FormikHelpers,
  useFormikContext,
} from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js/min';
import { DateTime } from 'luxon';
import React, { Fragment, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { mixed, object, string } from 'yup';
import { Button, CloseButton } from '../components/button';
import { CaregiverInfoSection } from '../components/Caregiver';
import {
  ErrorField,
  ErrorText,
  NormalField,
  SearchInput,
  SelectSimpleField,
} from '../components/form';
import {
  CondensedRow as ContentRow,
  HeaderEdit,
  InfoSection,
  MainModalContent,
} from '../components/layout';
import { PatientInfoSection } from '../components/Patient';
import { PatientSidebar } from '../components/PatientSidebar';
import { RoomSelect } from '../components/RoomSelect';
import {
  PatientResult,
  SearchForAccount,
  SearchForPatient,
} from '../components/SearchForAccountPatient/';
import { LargeTitle } from '../components/text';
import {
  formatDateOfBirth,
  formatPhoneNumber,
  getInitialDateTime,
} from '../helpers';
import { useScrollToErrorWithinModal } from '../hooks';
import { getDateTimeToDisplay } from './Dashboard.helpers';
import {
  ClosePosition,
  LeftSideContainer,
  ModalWrapper,
  RightSideContainer,
  SideBySide,
  StaticHeader,
} from './ViewAppointment.styles';

const ARIA_LABEL = 'Greet Patient';
const CARE_TYPES = AVAILABLE_CARE_TYPES;

const AppointmentValidation = object().shape({
  accountId: string(),
  accountFirstName: string().required('Required'),
  accountLastName: string().required('Required'),
  appointment: mixed(),
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  clinicId: string().required('Required'),
  relationship: string().required('Required'),
  phoneNumber: string()
    .required('Required')
    .test('is-phone', 'Phone number not valid', (value = '') => {
      const phoneNumber = parsePhoneNumberFromString(value!, 'US');
      if (!phoneNumber) return false;

      return phoneNumber.isValid();
    }),
  dateOfBirth: dateOfBirthYupSchema(),
  date: string().required('Required'),
  time: string().required('Required'),
  reason: string().required('Required'),
  notes: string(),
  type: string().required('Required'),
  careType: string().required('Required'),
  timeZone: string().when('careType', {
    is: (careType: Care_Type_Enum) => careType === 'TELEMEDICINE',
    then: string().required('Required'),
  }),
  email: string()
    .email()
    .when('careType', {
      is: (careType: Care_Type_Enum) => careType === 'TELEMEDICINE',
      then: string().email('Required').required('Required'),
    }),
  patientSearch: string(),
  accountSearch: string(),
  roomId: string(),
});

type Care_Type_Enum = Visit_Types_Enum | Visit_Classifications_Enum;

interface Appointment {
  accountId?: string;
  appointment?: AppointmentFragment;
  patientId?: string;
  accountFirstName?: string;
  accountLastName?: string;
  phoneNumber: string;
  clinicId?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  relationship?: string;
  date: string;
  time: string;
  reason: string;
  notes: string;
  type: Appointment_Types_Enum;
  careType: Care_Type_Enum;
  timeZone?: string;
  patientSearch: string;
  accountSearch: string;
  roomId: string;
}

const initialAccountValues = {
  accountFirstName: '',
  accountId: undefined,
  accountLastName: '',
  email: '',
  phoneNumber: '',
  timeZone: 'America/Los_Angeles',
};

const initialPatientValues = {
  dateOfBirth: '',
  firstName: '',
  lastName: '',
  patientId: undefined,
};

const initialAppointmentValues = {
  appointment: undefined,
  clinicId: '',
  careType: 'URGENT_CARE',
  reason: '',
};

const GreetPatientForm = ({
  creationError,
  error,
  hasExistingRelationship,
  onSetHasExistingRelationship,
  renderCareTypes,
  upcomingAppointments,
}: {
  creationError: any;
  error: any;
  hasExistingRelationship: boolean;
  onSetHasExistingRelationship: (value: boolean) => void;
  renderCareTypes: any;
  upcomingAppointments: AppointmentPartsFragment[];
}) => {
  const {
    errors,
    submitCount,
    isSubmitting,
    setFieldValue,
    setValues,
    values,
  } = useFormikContext<any>();

  const [showPatientFields, setShowPatientFields] = useState(false);
  const [showAccountFields, setShowAccountFields] = useState(false);
  const [showVisitFields, setShowVisitFields] = useState(false);
  const [isMissingAccountName, setIsMissingAccountName] = useState(false);

  useScrollToErrorWithinModal({ ariaLabel: ARIA_LABEL });

  useEffect(() => {
    if (
      submitCount > 0 &&
      errors &&
      Object.keys(errors).includes('phoneNumber')
    ) {
      setShowAccountFields(true);
    }
  }, [submitCount, errors]);

  const setAccountValues = (account: AccountFragment) => {
    setValues({
      ...values,
      accountFirstName: account?.firstName ?? '',
      accountId: account.id,
      accountLastName: account?.lastName ?? '',
      email: account?.email ?? '',
      phoneNumber: formatPhoneNumber(account.phoneNumber),
    });
  };

  const setPatientValues = (patient: PatientFragment) => {
    setValues({
      ...values,
      patientId: patient.id,
      dateOfBirth: formatDateOfBirth(patient.dateOfBirth) ?? '',
      firstName: patient?.firstName ?? '',
      lastName: patient?.lastName ?? '',
    });
  };

  const shouldShowCaregiverNameFields =
    !!values.accountId && isMissingAccountName;

  const shouldShowCaregiverFields =
    !values.accountId || !hasExistingRelationship;

  const onLinkPatient = ({
    appointment,
    patient,
    relationship,
  }: {
    appointment?: AppointmentFragment;
    patient?: PatientFragment;
    relationship?: string;
  }) => {
    onSetHasExistingRelationship(!!relationship);
    if (patient) {
      setShowPatientFields(false);
      setShowVisitFields(true);
      if (appointment) {
        setValues({
          ...values,
          appointment,
          clinicId: appointment?.clinicId || '',
          careType:
            appointment?.visitClassification ||
            appointment?.visitType ||
            'URGENT_CARE',
          patientId: patient.id,
          dateOfBirth: formatDateOfBirth(patient.dateOfBirth) ?? '',
          firstName: patient?.firstName ?? '',
          lastName: patient?.lastName ?? '',
          reason: appointment.reason ?? '',
          patientSearch: `${patient.firstName} ${patient.lastName}`,
        });
      } else {
        setPatientValues(patient);
        setFieldValue(
          'patientSearch',
          `${patient.firstName} ${patient.lastName}`,
        );
      }
    } else {
      setShowVisitFields(false);
      setShowAccountFields(false);
      setValues({
        ...values,
        ...initialAccountValues,
        ...initialAppointmentValues,
        ...initialPatientValues,
        relationship: '',
        patientSearch: '',
      });
    }
  };

  const onLinkAccount = ({
    account,
    relationship,
    isMissingName,
  }: {
    account?: AccountFragment;
    relationship?: string;
    isMissingName?: boolean;
  }) => {
    onSetHasExistingRelationship(!!relationship);
    setIsMissingAccountName(!!isMissingName);
    if (account) {
      setAccountValues(account);
      if (!!relationship) {
        setFieldValue('relationship', relationship);
      }
      if (!!relationship && !isMissingName) {
        setShowAccountFields(false);
      } else {
        setShowAccountFields(true);
      }
    } else {
      setValues({
        ...values,
        ...initialAccountValues,
        relationship: '',
      });
    }
  };

  return (
    <Form css={SideBySide}>
      <LeftSideContainer>
        <ContentRow css={{ marginTop: 0 }}>
          <SearchInput
            fullWidth
            name="patientSearch"
            title="Search for patient"
            disabled={!!values.patientId}
            placeholder="Enter name or date of birth"
            autoFocus
          />
          <SearchForPatient
            query={values.patientSearch}
            selectedPatientId={values.patientId}
            selectedAccountId={values.accountId}
            onLinkPatient={onLinkPatient}
            onLinkAccount={onLinkAccount}
            displayAppointmentToLink
            onCreateNewAccount={() => setShowAccountFields(true)}
            displayAddNewAccount={!showAccountFields}
            onCreateNewPatient={() => {
              setFieldValue('patientSearch', '');
              setShowPatientFields(true);
              setShowAccountFields(true);
              setShowVisitFields(true);
            }}
          />
        </ContentRow>
        {!values.patientSearch &&
          !values.patientId &&
          !!upcomingAppointments.length &&
          upcomingAppointments.map((appointment, index) => {
            const patient = appointment.appointment_patients?.[0]?.patient;
            if (!patient) return null;
            return (
              <PatientResult
                appointment={appointment}
                displayAppointmentToLink
                displayAddNewAccount={!showAccountFields}
                isFirst={index === 0}
                selectedPatientId={values.patientId}
                selectedAccountId={values.accountId}
                key={appointment.id}
                onCreateNewAccount={() => setShowAccountFields(true)}
                patient={patient}
                onLinkAccount={onLinkAccount}
                onLinkPatient={onLinkPatient}
              />
            );
          })}
        {showPatientFields && (
          <PatientInfoSection
            onExistingSelect={(patient) => {
              setFieldValue('patientId', patient.id);
              setFieldValue(
                'patientSearch',
                `${patient.firstName} ${patient.lastName}`,
              );
              setShowPatientFields(false);
            }}
          />
        )}
        {showAccountFields && (
          <Fragment>
            <HeaderEdit
              title="Caregiver Information"
              editing={true}
              onEdit={() => {}}
              editable={false}
              css={StaticHeader}
            />
            <ContentRow css={{ marginBottom: 0 }}>
              <SearchInput
                fullWidth
                name="accountSearch"
                title="Search for caregiver"
                disabled={!!values.accountId}
                placeholder="Enter name or phone number"
              />
              <SearchForAccount
                query={values.accountSearch}
                selectedAccountId={values.accountId}
                selectedPatientId={values.patientId}
                onLinkAccount={({ account, relationship, isMissingName }) => {
                  onSetHasExistingRelationship(!!relationship);
                  if (account) {
                    setIsMissingAccountName(!!isMissingName);
                    setAccountValues(account);
                    if (!!relationship) {
                      setFieldValue('relationship', relationship);
                    }
                    if (!!relationship && !isMissingName) {
                      setShowAccountFields(false);
                    } else {
                      setShowAccountFields(true);
                    }
                  } else {
                    setShowAccountFields(true);
                    setValues({
                      ...values,
                      ...initialAccountValues,
                      relationship: '',
                      accountSearch: '',
                    });
                  }
                }}
                onLinkPatient={({ patient, relationship }) => {
                  onSetHasExistingRelationship(!!relationship);
                  if (patient) {
                    setPatientValues(patient);
                    setFieldValue('accountSearch', '');
                    if (!!relationship) {
                      setFieldValue('relationship', relationship);
                    }
                  } else {
                    setValues({
                      ...values,
                      ...initialPatientValues,
                      relationship: '',
                      accountSearch: '',
                    });
                  }
                }}
              />
            </ContentRow>
            {!values.accountId && (
              <CaregiverInfoSection
                onExistingSelect={({
                  account,
                  relationship,
                  isMissingName,
                }) => {
                  onSetHasExistingRelationship(!!relationship);
                  setIsMissingAccountName(!!isMissingName);
                  setAccountValues(account);
                  if (isMissingName) {
                    setFieldValue('accountSearch', account.phoneNumber);
                  } else {
                    setFieldValue(
                      'accountSearch',
                      `${account.firstName} ${account.lastName}`,
                    );
                  }
                  if (!!relationship) {
                    setFieldValue('relationship', relationship);
                  }
                  if (!!relationship && !isMissingName) {
                    setShowAccountFields(false);
                  } else {
                    setShowAccountFields(true);
                  }
                }}
              />
            )}
            {shouldShowCaregiverNameFields && (
              <ContentRow>
                <NormalField
                  title="Caregiver first name"
                  name="accountFirstName"
                />
                <NormalField
                  title="Caregiver last name"
                  name="accountLastName"
                />
              </ContentRow>
            )}
            {shouldShowCaregiverFields && (
              <ContentRow>
                <InfoSection title="Relationship to patient" fullWidth>
                  <SelectSimpleField name="relationship">
                    <option value="">Select</option>
                    {RELATIONSHIPS.map(({ label, value }) => (
                      <option value={value} key={value}>
                        {label}
                      </option>
                    ))}
                  </SelectSimpleField>
                  <ErrorField name="relationship" />
                </InfoSection>
              </ContentRow>
            )}
          </Fragment>
        )}
        {showVisitFields && (
          <Fragment>
            <HeaderEdit
              title="Visit Details"
              editing={true}
              editable={false}
              onEdit={() => {}}
              css={StaticHeader}
            />
            <ContentRow>
              <InfoSection title="Visit type" fullWidth>
                <SelectSimpleField name="careType">
                  <option value="">Select</option>
                  {Object.keys(renderCareTypes).map((key) => {
                    //@ts-ignore
                    const title = TYPES[key] || CLASSIFICATIONS[key];

                    return (
                      <option value={key} key={key}>
                        {title}
                      </option>
                    );
                  })}
                </SelectSimpleField>
                <ErrorMessage name="careType" component={ErrorText} />
              </InfoSection>
            </ContentRow>
            <ContentRow>
              <NormalField
                title="Reason for visit (Visible to patient)"
                fullWidth
                name="reason"
              />
            </ContentRow>
            <HeaderEdit
              title="Visit Flags"
              editing={true}
              editable={false}
              onEdit={() => {}}
              css={StaticHeader}
            />
            <ContentRow>
              <NormalField
                title="Flags for visit (Not visible to patient)"
                fullWidth
                name="notes"
              />
            </ContentRow>
          </Fragment>
        )}
        {(error || creationError) && (
          <ContentRow>
            <ErrorText>{error?.message || creationError}</ErrorText>
          </ContentRow>
        )}
      </LeftSideContainer>
      <RightSideContainer>
        {!!(values.patientId || showPatientFields) && (
          <Fragment>
            <div
              css={{
                borderRadius: 16,
                boxShadow:
                  '0px 10px 20px rgba(0, 0, 0, 0.12), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
                padding: 24,
              }}
            >
              <RoomSelect
                clinicId={values.clinicId}
                onSelectRoom={(value) => setFieldValue('roomId', value)}
                roomId={values.roomId}
              />
              <Button
                type="submit"
                disabled={isSubmitting}
                css={{
                  width: '100%',
                  padding: '16px 40px',
                  marginTop: 24,
                }}
              >
                Confirm Patient Arrival
              </Button>
            </div>
            <PatientSidebar patientId={values.patientId} />
          </Fragment>
        )}
      </RightSideContainer>
    </Form>
  );
};

const GreetPatient: React.FC<{ returnPath?: string } & RouteComponentProps> = ({
  returnPath,
  match,
  history,
}) => {
  const [creationError, setError] = useState('');
  const [scheduleAppointment, { error }] = useScheduleAppointmentMutation({
    fetchPolicy: 'no-cache',
  });
  const [updateAppointment, { error: updateError }] =
    useUpdateAppointmentMutation({
      fetchPolicy: 'no-cache',
    });
  const [createAccountPatient] = useCreateAccountPatientMutation({
    fetchPolicy: 'no-cache',
  });
  const [updateAccount] = useUpdateAccountMutation({
    fetchPolicy: 'no-cache',
  });
  const [updateRoom] = useUpdateRoomMutation();
  const [addNote] = useAddGlobalNoteMutation({ fetchPolicy: 'no-cache' });
  const [clinicType, setClinicType] =
    useState<Clinic_Location_Types_Enum>('PHYSICAL');
  const [selectedClinic] = useLocalStorage('selectedClinic', 'ne-super1');

  const { data: clinicData } = useGetClinicByCodeQuery({
    variables: {
      clinicCode: selectedClinic!,
    },
    skip: !selectedClinic,
  });

  useEffect(() => {
    const locationType = clinicData?.clinics?.[0]?.locationType;
    if (locationType) {
      setClinicType(locationType);
    }
  }, [clinicData]);

  const startOfDayLoad = getDateTimeToDisplay({
    displayDate: DateTime.local(),
    hour: 0,
    minute: 1,
  });

  const endOfDayLoad = getDateTimeToDisplay({
    displayDate: DateTime.local(),
    hour: 23,
    minute: 59,
  });

  const { data: apponitmentsData } = useLoadAppointmentsQuery({
    variables: {
      where: {
        _and: [
          {
            startTime: {
              _gte: startOfDayLoad,
            },
          },
          {
            startTime: {
              _lte: endOfDayLoad,
            },
          },
          {
            status: { _in: ['CREATED', 'CHECKED_IN'] },
          },
        ],
        clinic: { clinicCode: { _eq: selectedClinic } },
      },
      order_by: [
        {
          startTime: 'asc',
        },
      ],
      limit: 5,
    },
    skip: !selectedClinic,
  });

  const upcomingAppointments = apponitmentsData?.appointments ?? [];

  const [hasExistingRelationship, setHasExistingRelationship] = useState(true);

  const handleAddAppointment = async (
    values: Appointment,
    { setFieldError }: FormikHelpers<Appointment>,
  ) => {
    try {
      const time = DateTime.fromFormat(values.time, 'h:mm a');
      const startTime = DateTime.fromFormat(values.date, 'yyyy-MM-dd')
        .set({
          hour: time.hour,
          minute: time.minute,
        })
        .startOf('minute');

      const parsedNumber = parsePhoneNumberFromString(
        values?.phoneNumber,
        'US',
      )?.number.toString();
      let successfulAppointment;
      let appointmentId = values.appointment?.id;

      let accountId = values.accountId;
      let patientId = values.patientId;

      if (!accountId || !patientId || !hasExistingRelationship) {
        const { data: savedData, errors } = await createAccountPatient({
          variables: {
            accountFirstName: values.accountFirstName!,
            accountId,
            accountLastName: values.accountLastName!,
            phoneNumber: parsedNumber!,
            relationship: values.relationship!,
            dateOfBirth: DateTime.fromFormat(
              values.dateOfBirth!,
              'MM/dd/yy',
            ).toFormat('yyyy-MM-dd'),
            patientFirstName: values.firstName!,
            patientId,
            patientLastName: values.lastName!,
            email: values.email,
          },
        });

        if (errors) {
          Sentry.captureMessage(errors?.[0]?.message, (scope) => {
            scope.setExtras(values as any);
            if (savedData?.CreateAccountPatient) {
              scope.setExtras(savedData?.CreateAccountPatient);
            }
            return scope;
          });
        }

        accountId = savedData?.CreateAccountPatient?.accountId!;
        patientId = savedData?.CreateAccountPatient?.patientId!;
      }

      if (
        accountId &&
        (values.email || values.accountFirstName || values.accountLastName)
      ) {
        await updateAccount({
          variables: {
            set: {
              ...(values.email && { email: values.email }),
              ...(values.accountFirstName && {
                firstName: values.accountFirstName,
              }),
              ...(values.accountLastName && {
                lastName: values.accountLastName,
              }),
            },
            where: {
              id: {
                _eq: accountId,
              },
            },
          },
        });
      }

      if (!accountId || !patientId) {
        if (!accountId) {
          setError('Cannot find account');
        } else if (!patientId) {
          setError('Cannot create patient');
        }
        return;
      }

      const sqlTime = startTime.toSQL({
        includeOffset: false,
        includeZone: false,
      });

      if (!sqlTime) {
        throw new Error('No valid time');
      }

      const visitType =
        CARE_TYPES[values?.careType]?.visitType || 'URGENT_CARE';
      const visitClassification =
        CARE_TYPES[values?.careType]?.visitClassification;

      if (appointmentId) {
        const { data, errors } = await updateAppointment({
          variables: {
            id: appointmentId,
            set: {
              accountId,
              clinicId: values.clinicId!,
              reason: values.reason.trim(),
              visitType,
              visitClassification,
              email: values.email,
              timeZone: values.timeZone,
            },
          },
        });

        successfulAppointment = !!data?.update_appointments?.returning?.[0]?.id;

        if (errors) {
          throw new Error(errors?.[0]?.message);
        }
      } else {
        const { data, errors } = await scheduleAppointment({
          variables: {
            accountId: accountId,
            patientId: patientId,
            clinicId: values.clinicId!,
            reason: values.reason.trim(),
            sqlTime: sqlTime,
            type: values.type,
            visitType,
            visitClassification,
            email: values.email,
            timeZone: values.timeZone,
          },
        });
        successfulAppointment = !!data?.ScheduleAppointment?.id;
        appointmentId = data?.ScheduleAppointment?.id;

        if (errors) {
          throw new Error(errors?.[0]?.message);
        }
      }

      setError('');

      if (values.roomId && values.roomId !== 'unselected') {
        await updateRoom({
          variables: {
            id: values.roomId,
            set: { appointmentId, status: 'READY_FOR_PATIENT' },
          },
        });
      }

      if (!!values.notes.length && appointmentId) {
        addNote({
          variables: {
            globalNote: {
              appointmentId,
              note: values.notes.trim(),
            },
          },
        });
      }

      if (successfulAppointment) {
        history.push('/dashboard');
      }
    } catch (error: any) {
      setError(error.message);
      Sentry.captureMessage(error, (scope) => {
        scope.setExtras(values as any);
        return scope;
      });
    }
  };

  const renderCareTypes =
    clinicType === 'MOBILE'
      ? Object.keys(CARE_TYPES).filter((key) => {
          return (
            key !== 'MEET_AND_GREET' &&
            key !== 'TELEMEDICINE' &&
            key !== 'URGENT_CARE'
          );
        })
      : CARE_TYPES;

  const { date, time: currentTime } = getInitialDateTime(
    DateTime.local().toSQL(),
  );
  const currentDateTime = DateTime.fromFormat(currentTime, 'h:mm a');
  const time =
    currentDateTime.minute > 30
      ? currentDateTime.set({ hour: currentDateTime.hour + 1, minute: 0 })
      : currentDateTime.set({ minute: 30 });

  return (
    <ModalWrapper aria-label={ARIA_LABEL}>
      <CloseButton
        css={ClosePosition}
        onClick={() => history.push(returnPath ? returnPath : '/dashboard')}
      />
      <MainModalContent>
        <LargeTitle css={{ marginBottom: 24 }}>
          Greet Patient at Arrival
        </LargeTitle>
        <Formik
          initialValues={{
            ...initialAccountValues,
            ...initialPatientValues,
            appointment: undefined,
            clinicId: clinicData?.clinics?.[0]?.id,
            relationship: '',
            patientSearch: '',
            accountSearch: '',
            reason: '',
            roomId: '',
            notes: '',
            type: 'WALKIN',
            careType: 'URGENT_CARE',
            timeZone: 'America/Los_Angeles',
            date,
            time: time.toFormat('h:mm a'),
          }}
          enableReinitialize
          initialStatus={{
            editing: true,
          }}
          validationSchema={AppointmentValidation}
          validateOnChange
          onSubmit={handleAddAppointment}
        >
          {() => (
            <GreetPatientForm
              creationError={creationError}
              error={error || updateError}
              hasExistingRelationship={hasExistingRelationship}
              onSetHasExistingRelationship={setHasExistingRelationship}
              renderCareTypes={renderCareTypes}
              upcomingAppointments={upcomingAppointments}
            />
          )}
        </Formik>
      </MainModalContent>
    </ModalWrapper>
  );
};

export default GreetPatient;
