import { useNewUserChatMessagesSubscription } from '@bc/codegen/medical';
import React, { useEffect, useRef } from 'react';
import { useLocalStorage } from 'react-use';
import { useDebouncedCallback } from 'use-debounce';
import useSound from 'use-sound';
import { useGetActivePhoneCalls } from '../data/useGetActivePhoneCalls/useGetActivePhoneCalls';

export const usePhoneRinging = () => {
  return useSound('/ring.mp3', {
    volume: 1,
    loop: true,
  });
};

export const useNotification = () => {
  return useSound('/notification.mp3', { volume: 1 });
};

export const useMessageWatch = (chatSessionId?: string) => {
  const chatSession = chatSessionId ? { _eq: chatSessionId } : undefined;
  const { data } = useNewUserChatMessagesSubscription({
    variables: {
      chatSession,
    },
  });
  const firstLoad = useRef(true);
  const chatMessage = data?.chat_messages?.[0];
  const [playSound, { stop }] = useNotification();

  useEffect(() => {
    if (chatMessage?.id && !firstLoad.current) {
      stop();
      playSound();
    }
    // ignore first load message
    if (chatMessage?.id) {
      firstLoad.current = false;
    }
  }, [chatMessage?.id]);
};

export const CommunicationContext = React.createContext<{
  hasInProgressCall?: boolean;
  longestHeldCallId?: string;
  longestQueuedCallId?: string;
  longHeldCallCount: number;
  queuedCallsCount: number;
  isAvailableForCalls?: boolean;
  setIsAvailableForCalls: (isAvailableForCalls: boolean) => void;
  startRinging: () => void;
  stopRinging: () => void;
}>({
  hasInProgressCall: false,
  longestHeldCallId: undefined,
  longestQueuedCallId: undefined,
  longHeldCallCount: 0,
  queuedCallsCount: 0,
  isAvailableForCalls: false,
  setIsAvailableForCalls: () => {},
  startRinging: () => {},
  stopRinging: () => {},
});

export const CommunicationProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [isAvailableForCalls, setIsAvailableForCalls] =
    useLocalStorage<boolean>('isAvailableForCalls');
  const [startRinging, { stop: stopRinging }] = usePhoneRinging();
  const debouncedStartRinging = useDebouncedCallback(startRinging, 1000);

  const {
    hasInProgressCall,
    longestHeldCallId,
    longestQueuedCallId,
    longHeldCallCount,
    queuedCallsCount,
  } = useGetActivePhoneCalls();

  return (
    <CommunicationContext.Provider
      value={{
        hasInProgressCall,
        longestHeldCallId,
        longestQueuedCallId,
        longHeldCallCount,
        queuedCallsCount,
        isAvailableForCalls,
        setIsAvailableForCalls,
        startRinging: debouncedStartRinging,
        stopRinging,
      }}
    >
      {children}
    </CommunicationContext.Provider>
  );
};

export const useCommunicationContext = () =>
  React.useContext(CommunicationContext);

export const useCallsWatch = () => {
  const {
    hasInProgressCall,
    longestHeldCallId,
    longestQueuedCallId,
    longHeldCallCount,
    queuedCallsCount,
    isAvailableForCalls,
    setIsAvailableForCalls,
    startRinging,
    stopRinging,
  } = useCommunicationContext();
  const hasQueuedCalls = queuedCallsCount > 0;

  useEffect(() => {
    if (isAvailableForCalls && hasQueuedCalls && !hasInProgressCall) {
      startRinging();
    } else {
      stopRinging();
    }

    return stopRinging;
  }, [
    isAvailableForCalls,
    hasInProgressCall,
    hasQueuedCalls,
    startRinging,
    stopRinging,
  ]);

  return {
    isAvailableForCalls,
    setIsAvailableForCalls,
    longestHeldCallId,
    longestQueuedCallId,
    longHeldCallCount,
    queuedCallsCount,
  };
};
