/** @jsx jsx */
import { ChatMessageFragment } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import truncate from 'lodash/truncate';
import { DateTime } from 'luxon';
import React from 'react';
import CaretIcon from '../../assets/caret.svg';
import { getLabelInfo } from '../../pages/chats/helpers';
import { AccountAvatar, Avatar } from '../ChatMessage';
import { PrimaryText } from '../text';
import {
  AvatarContainer,
  Caret,
  Column,
  Container,
  EmphasizedTitle,
  Ended,
  IconContainer,
  InnerContainer,
  Row,
  StatusIndicator,
  TextContainer,
  Title,
} from './ChatRow.styles';

const requestMap = {
  SCHEDULE_APPOINTMENT: 'to schedule visit (general)',
  SCHEDULE_PRIMARY: 'to schedule Primary Care visit',
  SCHEDULE_URGENT: 'to schedule Urgent Care visit',
  REQUEST_FILE: 'to send photo or video',
};

const getMessageInfo = ({
  chatMessage,
  author,
  accountFirstName,
}: {
  chatMessage: ChatMessageFragment;
  author: string;
  accountFirstName: string;
}) => {
  let message = author;
  if (!chatMessage) {
    return `${accountFirstName} started a chat`;
  }
  if (chatMessage?.type === 'ACTION') {
    if (chatMessage?.response) {
      message += chatMessage.response?.appointmentId
        ? ' scheduled an appointment'
        : ` selected “${chatMessage.response?.selection}” ${
            // @ts-ignore
            requestMap[chatMessage.request?.type]
          }`;
    } else if (chatMessage?.request) {
      message += ` ${chatMessage?.message}`;
    }
  } else {
    message = chatMessage?.message;
  }
  return message;
};

export const ActiveChatRow: React.FC<{
  chatSession: any;
  onClick?: () => void;
}> = ({ chatSession, onClick }) => {
  const {
    chatLabel,
    chat_messages,
    chat_sessions_accounts,
    chat_sessions_patients,
    chat_sessions_staffs,
    status: chatSessionStatus,
    updatedAt,
  } = chatSession;
  const isClosed = chatSessionStatus === 'CLOSED';
  const chatSessionAccount = chat_sessions_accounts?.[0];
  const account = chatSessionAccount?.account;
  const patient = chat_sessions_patients?.[0]?.patient;
  const accountName = `${account?.goesByName || account?.firstName} ${
    account?.lastName
  }`;
  const patientName = `${patient?.goesByName || patient?.firstName} ${
    patient?.lastName
  }`;
  const lastMessage = chat_messages[0];

  let lastMessageAuthor =
    (lastMessage?.type === 'ACTION' &&
      lastMessage?.request &&
      lastMessage?.response) ||
    (lastMessage?.type === 'MESSAGE' && !!lastMessage.accountId)
      ? account
      : chat_sessions_staffs.find(
          (s: any) => s?.staffId === lastMessage?.staffId,
        )?.staff;

  const lastMessageAuthorName =
    lastMessageAuthor?.goesByName || lastMessageAuthor?.firstName;
  const lastMessageText = getMessageInfo({
    chatMessage: lastMessage,
    author: lastMessageAuthorName,
    accountFirstName: `${account?.goesByName || account?.firstName}`,
  });
  const subtitleText = truncate(lastMessageText, { length: 60 });

  const staffs = chat_sessions_staffs.filter((s: any) => s.active);
  const status = getLabelInfo(chatLabel);

  const lastUpdatedAt = DateTime.fromISO(
    lastMessage?.updatedAt || lastMessage?.createdAt || updatedAt,
  );
  const endedAt = DateTime.fromISO(updatedAt);

  return (
    <Container onClick={onClick}>
      <InnerContainer>
        <Row css={{ flex: 1 }}>
          <div style={{ position: 'relative' }}>
            {account && <AccountAvatar accountId={account.id} size={48} />}
            <StatusIndicator active={!!chatSessionAccount?.active} />
          </div>
          <TextContainer>
            <Title>
              <EmphasizedTitle>{accountName}</EmphasizedTitle>
              {!!patient && ' about '}
              {!!patient && <EmphasizedTitle>{patientName}</EmphasizedTitle>}
            </Title>
            <PrimaryText>{subtitleText}</PrimaryText>
          </TextContainer>
        </Row>
        <Row>
          <IconContainer>
            {staffs?.length > 0 && (
              <AvatarContainer>
                {staffs?.map((s: any, i: any) => {
                  const staff = s.staff;
                  return (
                    <Avatar
                      key={staff?.id}
                      title={`${staff?.firstName} ${staff?.lastName}`}
                      src={
                        staff?.avatarUrl
                          ? `${staff?.avatarUrl}?w=96&h=96`
                          : 'https://bravecare.imgix.net/staff/empty.png?w=96&h=96'
                      }
                      css={{
                        height: 48,
                        width: 48,
                        border: '1px solid white',
                        borderRadius: 24,
                        marginRight: i === staffs?.length - 1 ? 0 : '-8px',
                        zIndex: staffs?.length - 1 - i,
                      }}
                    />
                  );
                })}
              </AvatarContainer>
            )}
            <Column>
              <PrimaryText
                style={{ color: status.color, position: 'relative' }}
              >
                {status.showIndicator && (
                  <StatusIndicator
                    style={{
                      backgroundColor: status.color,
                      position: 'absolute',
                      left: -20,
                    }}
                  />
                )}
                {status.text}
              </PrimaryText>
              {isClosed ? (
                <Ended>Chat ended {endedAt.toRelative()}</Ended>
              ) : lastMessage ? (
                <Ended>
                  Last message from {lastMessageAuthorName}{' '}
                  {lastUpdatedAt.toRelative()}
                </Ended>
              ) : (
                <Ended>Chat started {lastUpdatedAt.toRelative()}</Ended>
              )}
            </Column>
          </IconContainer>
        </Row>
      </InnerContainer>
      <Caret src={CaretIcon} />
    </Container>
  );
};
