/** @jsx jsx */
import { useGetPatientByPkQuery } from '@bc/codegen/medical';
import { Colors, Spacing } from '@bc/theme';
import { jsx } from '@emotion/core';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { formatDateOfBirth, getDateOfBirth } from '../../helpers';
import { Column, IF_TABLET, Row } from '../../styles';
import { PatientAvatarDisplay } from '../AppointmentDetails/AvatarDisplay';
import { PronounBadge } from '../Badge';
import { TaskCreateDropdown } from '../Tasks/TaskCreateDropdown';
import { LargeTitle, PrimaryText } from '../text';
import { TextWrapper } from './header.styles';

export const PatientHeader: React.FC<{
  accountId?: string;
  patientId?: string;
  appointmentId?: string;
}> = ({ accountId, patientId, appointmentId }) => {
  const history = useHistory();
  const { data: patientData } = useGetPatientByPkQuery({
    variables: { patientId: patientId! },
    skip: !patientId,
  });
  const patient = patientData?.patients_by_pk;

  if (!patient?.id) return null;

  return (
    <Row>
      <Link
        to={`/patient/${patient.id}/profile`}
        css={{ textDecoration: 'none' }}
      >
        <PatientAvatarDisplay
          patientId={patient.id}
          css={{
            border: `2px solid ${Colors.grayLighter}`,
            width: 64,
            height: 64,
            [IF_TABLET]: {
              width: 120,
              height: 120,
            },
          }}
        />
      </Link>
      <Column css={{ marginLeft: Spacing.s }}>
        <Row>
          <Link
            to={`/patient/${patient.id}/profile`}
            css={{ textDecoration: 'none' }}
          >
            <LargeTitle css={{ margin: `0 ${Spacing.xs}px ${Spacing.xs}px 0` }}>
              {patient?.firstName} {patient?.lastName}{' '}
              {patient?.goesByName ? `(${patient.goesByName})` : ''}
              {patient.pronunciation ? ` - ${patient.pronunciation}` : ''}
            </LargeTitle>
          </Link>
        </Row>
        <TextWrapper>
          <PrimaryText
            css={{
              margin: `0 ${Spacing.xs}px ${Spacing.xs}px 0`,
              [IF_TABLET]: { margin: `0 ${Spacing.xs}px 0 0` },
            }}
          >
            {getDateOfBirth({
              date: patient?.dateOfBirth,
              addOld: true,
            })}
            {formatDateOfBirth(patient?.dateOfBirth)
              ? ` • ${formatDateOfBirth(patient?.dateOfBirth)}`
              : ''}
          </PrimaryText>
          {patient?.preferredPronounType && (
            <PronounBadge
              preferredPronounType={patient?.preferredPronounType}
              preferredPronoun={patient?.preferredPronoun ?? ''}
            />
          )}
        </TextWrapper>
        <Row>
          <TaskCreateDropdown
            display={['VISIT', 'TASK']}
            onSelect={(type) => {
              if (type === 'VISIT') {
                history.push('/appointment/add', {
                  accountId,
                  patientId,
                });
              } else if (type === 'TASK') {
                if (appointmentId) {
                  history.push(`/appointment/${appointmentId}/tasks/create`);
                } else if (patientId) {
                  history.push(`/patient/${patientId}/tasks/create`);
                } else if (accountId) {
                  history.push(`/account/${accountId}/tasks/create`);
                }
              }
            }}
          />
        </Row>
      </Column>
    </Row>
  );
};
