/** @jsx jsx */
import {
  PatientFragment,
  useUpdateKareoPatientMutation,
} from '@bc/codegen/medical';
import { getSexDisplay } from '@bc/shared';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import { AddressSection } from '../Address';
import { Button, TextButton } from '../button';
import { DefinitionList, DefinitionListItem } from '../List';
import { ErrorMessageWrapper } from '../Payments/Payments.styles';
import { PrimaryText, SmallTitle } from '../text';

const SectionTitle = styled(PrimaryText)({
  fontSize: '14px',
  fontWeight: 'bold',
  display: 'block',
});

export const getPatientDisplayName = (patient: Partial<PatientFragment>) => {
  let firstName = patient.firstName;

  if (patient.goesByName) {
    firstName = `${firstName} "${patient.goesByName}"`;
  }

  if (patient.preferredPronounType) {
    if (patient.preferredPronounType === 'other') {
      firstName = `${firstName} (${patient.preferredPronoun})`;
    } else {
      firstName = `${firstName} (${patient.preferredPronounType.toUpperCase()})`;
    }
  }
  return firstName;
};

const KareoPatientUpdateReview = ({
  patient,
  patientId,
  onCancel,
  onSuccess,
}: {
  patient: PatientFragment;
  patientId: string;
  onCancel: () => void;
  onSuccess: () => void;
}) => {
  const [updateKareoPatient, { loading: kareoPatientLoading, error }] =
    useUpdateKareoPatientMutation();

  const handleUpdatePatient = async () => {
    try {
      await updateKareoPatient({
        variables: {
          patientId: patientId,
        },
      });
      onSuccess();
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${Colors.grayLighter}`,
          paddingBottom: '18px',
          marginBottom: '24px',
        }}
      >
        <SmallTitle>Review Patient Chart</SmallTitle>
        <TextButton type="button" onClick={onCancel}>
          Cancel
        </TextButton>
      </div>

      {!!error && (
        <div
          css={{
            marginBottom: '16px',
          }}
        >
          <ErrorMessageWrapper>
            <PrimaryText
              style={{
                color: '#FFF',
              }}
            >
              An error occurred while updating the patient. Please try again.
            </PrimaryText>
          </ErrorMessageWrapper>
        </div>
      )}

      <SectionTitle
        css={{
          marginBottom: '24px',
        }}
      >
        Basic Details
      </SectionTitle>

      <DefinitionList>
        <DefinitionListItem term="Name">
          {getPatientDisplayName(patient)} {patient.lastName}
        </DefinitionListItem>
        <DefinitionListItem term="Gender">
          {patient.genderAtBirth && getSexDisplay(patient.genderAtBirth)}
        </DefinitionListItem>
        <DefinitionListItem term="Date of Birth">
          {DateTime.fromFormat(patient.dateOfBirth, 'yyyy-MM-dd').toFormat(
            'MM/dd/yyyy',
          )}
        </DefinitionListItem>
      </DefinitionList>
      <DefinitionList>
        <AddressSection location={patient?.address} />
      </DefinitionList>
      <SectionTitle
        css={{
          margin: '24px 0',
        }}
      >
        Preferred Pharmacy
      </SectionTitle>
      <DefinitionList>
        <AddressSection location={patient?.preferredPharmacy} />
      </DefinitionList>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          type="button"
          disabled={kareoPatientLoading}
          onClick={handleUpdatePatient}
        >
          Update Patient Chart in Kareo
        </Button>
      </div>
    </div>
  );
};

export default KareoPatientUpdateReview;
