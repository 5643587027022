import {
  AccountFragment,
  ClinicFragment,
  Clinic_Status_Enum,
  PatientFragment,
} from '@bc/codegen/medical';
import { parsePhoneNumberFromString } from 'libphonenumber-js/min';
import { DateTime } from 'luxon';
import {
  generateTimeSlots,
  prettifyIntervals,
} from './components/Schedule/helper';

export const DEFAULT_TIME_ZONE = 'America/Los_Angeles';

export const checkIfDayIsToday = (date: DateTime) => {
  const today = DateTime.local();
  return (
    date.hasSame(today, 'year') &&
    date.hasSame(today, 'month') &&
    date.hasSame(today, 'day')
  );
};
export const checkIfYesterday = (date: DateTime) => {
  const yesterday = DateTime.local().minus({ days: 1 });
  return (
    date.hasSame(yesterday, 'year') &&
    date.hasSame(yesterday, 'month') &&
    date.hasSame(yesterday, 'day')
  );
};

export const checkIfTomorrow = (date: DateTime) => {
  const tomorrow = DateTime.local().plus({ days: 1 });
  return (
    date.hasSame(tomorrow, 'year') &&
    date.hasSame(tomorrow, 'month') &&
    date.hasSame(tomorrow, 'day')
  );
};

export const formattedTime = (dateTime: DateTime) => {
  return dateTime.hasSame(DateTime.local(), 'day')
    ? dateTime.toFormat('h:mm a')
    : dateTime.hasSame(DateTime.local().minus({ day: 1 }), 'day')
    ? dateTime.toFormat("'Yesterday at' h:mm a")
    : dateTime.toFormat("D 'at' h:mm a");
};

export const formatDateOfBirth = (date: string) => {
  if (!date || DateTime.fromFormat(date, 'yyyy-MM-dd') >= DateTime.local())
    return '';
  return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
};

export const isPhoneNumber = (number = '') => {
  return parsePhoneNumberFromString(number, 'US')?.isValid;
};
export const formatPhoneNumber = (number = '') =>
  parsePhoneNumberFromString(number, 'US')?.format('NATIONAL').toString() ?? '';

export const getDateOfBirth = ({
  date,
  addOld = false,
}: {
  date: string;
  addOld?: boolean;
}) => {
  if (!date) return '';
  if (DateTime.fromFormat(date, 'yyyy-MM-dd') >= DateTime.local()) {
    return `Expected: ${date}`;
  }
  const niceDateOfBirth = DateTime.fromFormat(date, 'yyyy-MM-dd')
    .diffNow(['years', 'months', 'days'])
    .toObject();

  const years = Math.abs(niceDateOfBirth.years || 0);
  const months = Math.abs(niceDateOfBirth.months || 0);
  const days = Math.floor(Math.abs(niceDateOfBirth.days || 0));

  const yearString =
    years === 0 ? '' : `${years} ${years === 1 ? 'yr' : 'yrs'}`;
  const monthString = months === 0 ? '' : `${months} mo`;
  const dayString = days === 0 ? '' : `${days} ${days === 1 ? 'day' : 'days'}`;

  return `${yearString} ${monthString} ${years >= 3 ? '' : dayString} ${
    addOld ? ' old' : ''
  }`.trim();
};
export const getInitialDateTime = (sqlDateTime?: string) => {
  const dateFormat = 'yyyy-MM-dd';
  const timeFormat = 'h:mm a';

  const date = sqlDateTime
    ? DateTime.fromSQL(sqlDateTime).toFormat(dateFormat)
    : DateTime.local().toFormat(dateFormat);
  const time = sqlDateTime
    ? DateTime.fromSQL(sqlDateTime).toFormat(timeFormat)
    : '';

  return { date, time };
};

interface GetTimeSlots {
  interval?: number;
  clinic?: Partial<ClinicFragment>;
  timeZone?: string;
  open?: { hour: number; minute: number };
  close?: { hour: number; minute: number };
}

export const getTimeSlots = (
  {
    interval = 30,
    clinic = undefined,
    timeZone = undefined,
    open = { hour: 10, minute: 0 },
    close = { hour: 22, minute: 0 },
  }: GetTimeSlots = {
    interval: 30,
    open: { hour: 10, minute: 0 },
    close: { hour: 22, minute: 0 },
  },
) => {
  const date = DateTime.local();
  let offset = 0;
  if (clinic) {
    if (clinic?.open) {
      open.hour = DateTime.fromFormat(clinic.open, 'hh:mm:ss').hour;
      open.minute = DateTime.fromFormat(clinic.open, 'hh:mm:ss').minute;
    }

    if (clinic?.close) {
      close.hour = DateTime.fromFormat(clinic.close, 'hh:mm:ss').hour;
      close.minute = DateTime.fromFormat(clinic.close, 'hh:mm:ss').minute;
    }
  }

  if (timeZone) {
    const timezoneOffset =
      date.setZone(timeZone ?? DEFAULT_TIME_ZONE).offset / 60;
    const clinicTimezoneOffset =
      date.setZone(clinic?.timeZone ?? DEFAULT_TIME_ZONE).offset / 60;

    offset = clinicTimezoneOffset - timezoneOffset;
  }

  const startTime = date.set({
    hour: open.hour - offset,
    minute: open.minute,
    second: 0,
  });

  const endTime = date.set({
    hour: close.hour - offset,
    minute: close.minute + interval,
    second: 0,
  });

  const slots = generateTimeSlots(startTime, endTime, interval);
  return prettifyIntervals(slots);
};

export const prettifyTimestamp = (date: string, format = 'f') =>
  DateTime.fromISO(date).toFormat(format);

export const prettifyDate = (date: string) =>
  DateTime.fromISO(date).toFormat('D');

export const numberToCurrency = (amount: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);

export const isTouchDevice = () =>
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  // @ts-ignore
  navigator.msMaxTouchPoints > 0;

export const formatMoney = (num: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(num);
};

export function formatCommunicationType(typeEnum: string): string {
  switch (typeEnum) {
    case 'PHONE_CALL':
      return 'Phone Call';
    case 'SMS':
      return 'Text Message';
    case 'EMAIL':
      return 'Email';
    default:
      return '';
  }
}

export const formatFullName = (
  entity: Partial<AccountFragment | PatientFragment>,
) => {
  return `${entity?.firstName || ''}${
    entity?.goesByName ? ` ‘${entity.goesByName}’ ` : ' '
  }${entity?.lastName || ''}`;
};

export interface Status {
  value: Clinic_Status_Enum;
  label: string;
}

export const CLINIC_STATUSES: Status[] = [
  {
    value: 'HIDDEN',
    label: 'Hidden',
  },
  {
    value: 'PENDING_OPEN',
    label: 'Pending Open',
  },
  {
    value: 'OPEN',
    label: 'Open',
  },
  {
    value: 'TEMPORARILY_CLOSED',
    label: 'Temporarily Closed',
  },
  {
    value: 'PERMANENTLY_CLOSED',
    label: 'Permanently Closed',
  },
];

export const getClinicSlots = () => {
  const date = DateTime.local();
  const startTime = date.set({
    hour: 1,
    minute: 0,
    second: 0,
  });

  const endTime = date.set({
    hour: 23,
    minute: 0,
    second: 0,
  });

  const slots = generateTimeSlots(startTime, endTime, 60);
  return slots.map((item) => {
    return {
      value: item.start.toFormat('HH:mm:ss'),
      label: item.start.toFormat('h:mm a'),
    };
  });
};

/**
 * Returns a SQL DateTime equal to five minutes before the current time.
 */
export const fiveMinutesAgo = () => {
  return DateTime.local()
    .minus({ minute: 5 })
    .toUTC()
    .toSQL({ includeOffset: false, includeZone: false });
};
