import { useLoadStaffAndServicesQuery } from '@bc/codegen/manager';
import {
  useChatSessionsSubscription,
  useLoadChatSessionsQuery,
} from '@bc/codegen/medical';
import { DateTime } from 'luxon';
import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ActiveChatRow, ClosedChatRow } from '../../components/ChatRow';
import { Header } from '../../components/Header';
import { Content, InnerWrap, MainContent } from '../../components/layout';
import { Sidebar } from '../../components/Nav/Sidebar';
import { useMessageWatch } from '../../lib/sound';
import { useAuth0 } from '../../react-auth0';
import { CollapsibleSection, Title } from './Chats.styles';
import { getChatLabel } from './helpers';

const MyChats: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  const { data: queryData } = useLoadChatSessionsQuery();
  const { data: subscriptionData } = useChatSessionsSubscription();
  const data = subscriptionData ?? queryData;
  useMessageWatch();

  const { user } = useAuth0();
  const { data: staffData } = useLoadStaffAndServicesQuery({
    variables: {
      where: {
        auth0UserId: {
          _eq: user.sub,
        },
      },
    },
    skip: !user.sub,
  });
  const staff = staffData?.staff?.[0];
  const currentStaffId = staff?.id;

  const myChats = data?.chat_sessions
    ?.filter((chat) =>
      chat.chat_sessions_staffs.find(
        (s) => s.active && s.staffId === currentStaffId,
      ),
    )
    .map((chat: any) => getChatLabel(chat))
    .sort((a, b) => {
      const chatLabelA = a?.chatLabel;
      const chatLabelB = b?.chatLabel;
      const createdAtA = DateTime.fromISO(a?.createdAt).toSeconds();
      const createdAtB = DateTime.fromISO(b?.createdAt).toSeconds();
      // if chats have the same category, sort by descending chat session createdAt
      if (chatLabelA === chatLabelB) {
        return createdAtB - createdAtA;
      }
      return chatLabelA - chatLabelB;
    });

  const inProgressChats = myChats?.filter((chat) => chat.status !== 'CLOSED');

  const finishedChats = myChats?.filter((chat) => chat.status === 'CLOSED');

  const handleChatSelection = (id: string) => {
    history.push(`/chats/${id}`);
  };

  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap>
          <Sidebar />
          <MainContent
            style={{
              paddingTop: '40px',
            }}
          >
            <Title>My Chats</Title>
            <CollapsibleSection title="Active" style={{ marginBottom: 32 }}>
              {inProgressChats?.map((chat) => (
                <ActiveChatRow
                  key={chat.id}
                  chatSession={chat}
                  onClick={() => handleChatSelection(chat.id)}
                />
              ))}
            </CollapsibleSection>
            <CollapsibleSection title="Closed">
              {finishedChats?.map((chat) => (
                <ClosedChatRow
                  key={chat.id}
                  chatSession={chat}
                  onClick={() => handleChatSelection(chat.id)}
                />
              ))}
            </CollapsibleSection>
          </MainContent>
        </InnerWrap>
      </Content>
    </Fragment>
  );
};

export default MyChats;
