/** @jsx jsx */
import {
  AccountFragment,
  AccountPatientFragment,
  AppointmentFragment,
  PatientFragment,
} from '@bc/codegen/medical';
import {
  getRelationshipDisplay,
  VISIT_CLASSIFICATIONS,
  VISIT_TYPES,
} from '@bc/shared';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import { DateTime } from 'luxon';
import React, { Fragment } from 'react';
import { formatFullName, formatPhoneNumber } from '../../helpers';
import { displayDOB } from '../../pages/search/UserSearch';
import {
  AccountAvatarDisplay,
  PatientAvatarDisplay,
} from '../AppointmentDetails/AvatarDisplay';
import {
  AccountWrapper,
  AddButton,
  AddIcon,
  Divider,
  NormalText,
  PersonText,
  PersonTextWrapper,
  PersonWrapper,
  SecondaryText,
  SelectButton,
  Title,
  Unselect,
} from './SearchForAccountPatient.styles';

type Patient = {
  accounts: ({
    account: AccountFragment;
  } & AccountPatientFragment)[];
} & PatientFragment;

export const PatientResult: React.FC<{
  appointment?: AppointmentFragment;
  displayAddNewAccount?: boolean;
  displayAppointmentToLink?: boolean;
  isFirst?: boolean;
  onLinkPatient: ({
    appointment,
    patient,
    relationship,
  }: {
    appointment?: AppointmentFragment;
    patient?: PatientFragment;
    relationship?: string;
  }) => void;
  onLinkAccount: ({
    account,
    relationship,
    isMissingName,
  }: {
    account?: AccountFragment;
    relationship?: string;
    isMissingName?: boolean;
  }) => void;
  patient: Patient;
  selectedAccountId?: string;
  selectedPatientId?: string;
  onCreateNewAccount?: () => void;
}> = ({
  appointment,
  displayAddNewAccount,
  displayAppointmentToLink,
  isFirst,
  onCreateNewAccount,
  onLinkAccount,
  onLinkPatient,
  patient,
  selectedAccountId,
  selectedPatientId,
}) => {
  const isSelected = selectedPatientId === patient.id;
  const isLinkedToSelectedAccount =
    !!patient.accounts.find((ap) => ap.accountId === selectedAccountId) &&
    !isSelected;
  const relationship =
    patient.accounts.filter((ap) => ap.accountId === selectedAccountId)?.[0]
      ?.relationship ?? undefined;
  const patientName = formatFullName(patient);

  const appointmentTime = appointment
    ? DateTime.fromISO(appointment?.startTime).toFormat('t')
    : '';
  const visitType = VISIT_TYPES[appointment?.visitType || 'URGENT_CARE'];
  const careType =
    appointment?.visitClassification &&
    VISIT_CLASSIFICATIONS[appointment.visitClassification];

  return (
    <Fragment key={patient.id}>
      <PersonWrapper>
        <PatientAvatarDisplay
          patientId={patient.id}
          style={{
            height: '48px',
            width: '48px',
          }}
        />
        <PersonTextWrapper>
          <PersonText>
            {patientName}
            {isLinkedToSelectedAccount && (
              <NormalText>(Existing relationship)</NormalText>
            )}
          </PersonText>
          <SecondaryText>{displayDOB(patient.dateOfBirth)}</SecondaryText>
          {displayAppointmentToLink && appointment && (
            <SecondaryText css={{ color: Colors.gray, fontWeight: 500 }}>
              {appointmentTime} <span css={{ fontWeight: 400 }}>for</span>{' '}
              {careType || visitType}
            </SecondaryText>
          )}
        </PersonTextWrapper>
        <SelectButton
          isSelected={isSelected}
          type="button"
          onClick={(e) => {
            e.preventDefault();
            if (!isSelected) {
              onLinkPatient({
                appointment,
                patient,
                relationship,
              });
            } else {
              onLinkAccount({});
              onLinkPatient({});
            }
          }}
        >
          {isSelected ? 'Unselect' : 'Select'} Patient
          {isSelected && <Unselect />}
        </SelectButton>
      </PersonWrapper>
      {isSelected && patient.accounts.length > 0 && (
        <AccountWrapper>
          <Title>
            Select accompanying caregiver for{' '}
            {patient.goesByName || patient.firstName}
          </Title>
          {patient.accounts.map((ap) => {
            const accountName = formatFullName(ap.account);
            const isAccountSelected = selectedAccountId === ap.accountId;
            const relationship =
              ap.relationship && getRelationshipDisplay(ap.relationship);
            return (
              <PersonWrapper key={ap.id}>
                <AccountAvatarDisplay
                  accountId={ap.accountId}
                  style={{
                    height: '48px',
                    width: '48px',
                  }}
                />
                <PersonTextWrapper>
                  <PersonText>
                    {accountName}
                    {relationship && <NormalText>({relationship})</NormalText>}
                  </PersonText>
                  <SecondaryText>
                    {formatPhoneNumber(ap.account.phoneNumber)}
                  </SecondaryText>
                </PersonTextWrapper>
                <SelectButton
                  isSelected={isAccountSelected}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!isAccountSelected) {
                      onLinkAccount({
                        account: ap?.account,
                        relationship: ap.relationship ?? undefined,
                        isMissingName:
                          !ap?.account?.firstName || !ap?.account?.lastName,
                      });
                    } else {
                      onLinkAccount({});
                    }
                  }}
                >
                  {isAccountSelected ? 'Unselect' : 'Select'} Caregiver
                  {isAccountSelected && <Unselect />}
                </SelectButton>
              </PersonWrapper>
            );
          })}
        </AccountWrapper>
      )}
      {isSelected && !selectedAccountId && displayAddNewAccount && (
        <Fragment>
          {' '}
          <Divider />
          <AddButton onClick={onCreateNewAccount} type="button">
            <AddIcon />
            Add a caregiver
          </AddButton>
        </Fragment>
      )}
    </Fragment>
  );
};
