/** @jsx jsx */
import { TaskNotificationFragment } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import * as Sentry from '@sentry/react';
import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { Link, useHistory } from 'react-router-dom';
import { object, string } from 'yup';
import { Button, OutlineButton } from '../../components/button';
import CloseIcon from '../../components/Tasks/icons/close.svg';
import { TasksForm } from '../../components/Tasks/TasksForm';
import { Values } from '../../components/Tasks/types';
import { useTrackEvent } from '../../lib/analytics';
import { useTasks } from '../../lib/task';
import {
  fill_scroll,
  overflow_form,
  StickyBottomRow,
  WrapContainer,
} from './styles';

const CreateTaskValidation = object().shape({
  title: string().required('Required'),
  type: string().required('Required'),
  description: string(),
});

const CreateTask = ({
  dismissPath,
  defaultAssociations,
}: {
  dismissPath: string;
  defaultAssociations: Partial<TaskNotificationFragment>[];
}) => {
  const { addTask } = useTasks();
  const history = useHistory();
  const trackEvent = useTrackEvent();

  const handleCreate = async (values: Values) => {
    const {
      associations,
      dueDate,
      note,
      notifications,
      priority,
      subType,
      title,
      type,
      assignment,
    } = values;
    try {
      let assigned;
      // If we are an action and we hold a staff responsible then assign it directly.
      if (type === 'ACTION') {
        const staffNotification = notifications.find((notification) => {
          return notification.staffId;
        });
        assigned = staffNotification?.staffId;
      }
      if (!assigned && assignment) {
        assigned = assignment?.[0]?.assignedTo;
      }

      const dueDateTime = DateTime.fromJSDate(dueDate)
        .set({ hour: 10 })
        .startOf('hour');

      const assignStaffId = assigned;
      const combinedNotifications = [...notifications, ...associations];

      const response = await addTask({
        task: {
          title,
          note,
          status: 'OPEN',
          priority,
          type,
          subType: subType ?? null,
          dueDateTime: dueDateTime.toSQL(),
        },
        assignStaffId,
        notifications: combinedNotifications,
      });

      const taskId = response.data?.insert_tasks_one?.id;

      trackEvent({
        type: 'CREATE_NEW_TASK',
        data: {
          taskId,
          assignStaffId,
          status: 'OPEN',
          priority,
          type: type,
          subType: subType ?? null,
          dueDateTime,
          associations,
          notifications,
        },
      });

      history.push(dismissPath);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <WrapContainer data-testid="create_task">
      <div
        css={{
          position: 'absolute',
          top: '17px',
          right: '18px',
        }}
      >
        <Link to={dismissPath}>
          <img alt="" src={CloseIcon} />
        </Link>
      </div>
      <Formik
        onSubmit={handleCreate}
        validationSchema={CreateTaskValidation}
        initialValues={{
          title: '',
          note: '',
          type: 'ACTION',
          subType: undefined,
          dueDate: new Date(),
          priority: undefined,
          associations: defaultAssociations,
          notifications: [],
        }}
      >
        {() => (
          <Form css={overflow_form}>
            <div css={fill_scroll}>
              <TasksForm />
            </div>
            <StickyBottomRow>
              <OutlineButton
                type="button"
                onClick={() => {
                  history.push(dismissPath);
                }}
                css={{
                  padding: '16px 40px',
                  marginRight: '16px',
                }}
              >
                Cancel
              </OutlineButton>
              <Button type="submit">Create Task</Button>
            </StickyBottomRow>
          </Form>
        )}
      </Formik>
    </WrapContainer>
  );
};

export default CreateTask;
