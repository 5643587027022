import { useLoadAppointmentsQuery } from '@bc/codegen/medical';
import { getCareType } from '@bc/shared';
import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { ICONS } from '../../assets';
import { HeaderEdit, SectionContent } from '../layout';
import { DefinitionList, DefinitionListItem } from '../List';
import { PrimaryText, SectionTitle } from '../text';

export const VisitHistory: React.FC<{
  patientId?: string;
  isOther?: boolean;
}> = ({ isOther, patientId }) => {
  const history = useHistory();

  const now = DateTime.local().startOf('minute').set({ millisecond: 0 }).toSQL({
    includeOffset: false,
    includeZone: false,
  });

  const { data: upcomingData } = useLoadAppointmentsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        _and: [
          { appointment_patients: { patient_id: { _eq: patientId } } },
          { status: { _eq: 'CREATED' } },
          { endTime: { _gt: now } },
        ],
      },
      order_by: [{ endTime: 'desc' }],
    },
  });

  const upcoming = upcomingData?.appointments ?? [];

  const { data: pastData } = useLoadAppointmentsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        _and: [
          { appointment_patients: { patient_id: { _eq: patientId } } },
          { status: { _neq: 'CANCELED' } },
          { endTime: { _lte: now } },
        ],
      },
      order_by: [{ endTime: 'desc' }],
    },
  });

  const past = pastData?.appointments ?? [];

  return (
    <SectionContent>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
        <SectionTitle data-testid="otherVisitsTitle">
          {isOther ? 'Other Visits' : 'Visits'}
        </SectionTitle>
      </div>
      {upcoming.length > 0 && (
        <Fragment>
          <HeaderEdit
            title={`Upcoming (${upcoming.length})`}
            editable={false}
          />
          <DefinitionList>
            {upcoming.map((a) => {
              const careType = getCareType({
                visitType: a.visitType,
                visitClassification: a.visitClassification,
              });
              return (
                <ListItem
                  key={a.id}
                  term={DateTime.fromISO(a.startTime).toFormat('D')}
                  onClick={() => history.push(`/appointment/${a.id}/visit`)}
                >
                  <Details>
                    <div>
                      <CareType>{careType.title}</CareType>
                      <Reason>{a.reason}</Reason>
                    </div>
                    <Caret />
                  </Details>
                </ListItem>
              );
            })}
          </DefinitionList>
        </Fragment>
      )}
      {past.length > 0 && (
        <Fragment>
          <HeaderEdit title={`Past (${past.length})`} editable={false} />
          <DefinitionList>
            {past.map((a) => {
              const careType = getCareType({
                visitType: a.visitType,
                visitClassification: a.visitClassification,
              });
              return (
                <ListItem
                  key={a.id}
                  term={DateTime.fromISO(a.startTime).toFormat('D')}
                  onClick={() => history.push(`/appointment/${a.id}/visit`)}
                >
                  <Details>
                    <div>
                      <CareType>{careType.title}</CareType>
                      <Reason>{a.reason}</Reason>
                    </div>
                    <Caret />
                  </Details>
                </ListItem>
              );
            })}
          </DefinitionList>
        </Fragment>
      )}
    </SectionContent>
  );
};

const Details = styled.div({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
});

const CareType = styled(PrimaryText)({
  fontSize: 16,
  lineHeight: '22px',
  marginBottom: 8,
}).withComponent('p');

const Reason = styled(PrimaryText)({
  color: Colors.gray,
  fontSize: 14,
  lineHeight: '18px',
}).withComponent('p');

const Caret = styled.img({
  height: 8,
  marginLeft: 26,
  width: 4,
});

Caret.defaultProps = {
  src: ICONS.caretRight,
};

const ListItem = styled(DefinitionListItem)({
  cursor: 'pointer',
});
