import {
  useChatSessionsSubscription,
  useLoadChatSessionsQuery,
} from '@bc/codegen/medical';
import { DateTime } from 'luxon';
import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ActiveChatRow } from '../../components/ChatRow';
import { Header } from '../../components/Header';
import { Content, InnerWrap, MainContent } from '../../components/layout';
import { Sidebar } from '../../components/Nav/Sidebar';
import { useMessageWatch } from '../../lib/sound';
import { Title } from './Chats.styles';
import { getChatLabel } from './helpers';

const PROVIDER_TYPES = [
  'PHYSICIAN',
  'PHYSICIAN_ASSISTANT',
  'NURSE_PRACTITIONER',
];
const isProvider = (staffTypeValue = '') =>
  PROVIDER_TYPES.includes(staffTypeValue);

const NeedsProvider: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  const { data: queryData } = useLoadChatSessionsQuery();
  const { data: subscriptionData } = useChatSessionsSubscription();
  const data = subscriptionData ?? queryData;
  useMessageWatch();

  const needsProviderChats = data?.chat_sessions
    ?.filter(
      (chat) =>
        chat.status === 'READY_FOR_PROVIDER' &&
        chat.chat_sessions_staffs.filter(
          (s) => isProvider(s.staff.staff_type?.value) && s.active,
        ).length === 0,
    )
    .map((chat: any) => getChatLabel(chat))
    .sort((a, b) => {
      const createdAtA = DateTime.fromISO(a?.createdAt).toSeconds();
      const createdAtB = DateTime.fromISO(b?.createdAt).toSeconds();
      // sort by descending chat session createdAt
      return createdAtB - createdAtA;
    });

  const handleChatSelection = (id: string) => {
    history.push(`/chats/${id}`);
  };

  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap>
          <Sidebar />
          <MainContent
            style={{
              paddingTop: '40px',
            }}
          >
            <Title>Needs Provider</Title>
            {needsProviderChats?.map((chat) => (
              <ActiveChatRow
                key={chat.id}
                chatSession={chat}
                onClick={() => handleChatSelection(chat.id)}
              />
            ))}
          </MainContent>
        </InnerWrap>
      </Content>
    </Fragment>
  );
};

export default NeedsProvider;
