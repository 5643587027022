import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string;
}

const Rooms: React.FC<Props> = ({ color, ...props }) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M1 2.959v15.127c0 .382.266.765.533.861l7.107 2.968c.622.287 1.243-.191 1.243-.862V6.023c0-.383-.266-.766-.533-.862L2.244 2.097C1.622 1.81 1 2.29 1 2.96zM15.5 3v14.679c0 .574-.383.957-.957.957H11"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 2h12.438c.574 0 .957.383.957.957V17.5"
      stroke={color}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={7.5} cy={13.5} r={0.5} fill={color} />
  </svg>
);

export default Rooms;
