/** @jsx jsx */
import { useUpdateRoomMutation } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import * as Sentry from '@sentry/react';
import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { SingleClinicRegionDropdown } from '../../components/ClinicSelector/SingleClinicRegionDropdown';
import { Header } from '../../components/Header';
import { Content, InnerWrap, MainContent } from '../../components/layout';
import { Sidebar } from '../../components/Nav/Sidebar';
import { LargeTitle, SmallTitle } from '../../components/text';
import { useGetRoomsByClinicCode } from '../../data/useGetRoomsByClinicCode/useGetRoomsByClinicCode';
import { IF_TABLET } from '../../styles';
import { Container, RoomRow } from './RoomRow';

export const Rooms: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  const [selectedClinic, setSelectedClinic] = useLocalStorage(
    'selectedClinic',
    'ne-super1',
  );

  const rooms = useGetRoomsByClinicCode(selectedClinic!);

  const [updateRoom] = useUpdateRoomMutation();

  const handleSelect = async ({
    status,
    roomId,
  }: {
    status: string;
    roomId: string;
  }) => {
    /**
     * NOTE: Appointment and room status are kept in sync in actions/pages/api/rooms.
     * As such only room status OR appointment status should be updated at a time.
     * Updating both when associated may result in a status update loop.
     *
     * More info: actions/pages/api/roomsReadme.md
     */
    try {
      await updateRoom({
        variables: {
          id: roomId,
          set: { status },
        },
      });
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  const handlePatientClick = (appointmentId: string) => {
    history.push(`/appointment/${appointmentId}/visit`);
  };

  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap>
          <Sidebar />
          <MainContent>
            <div
              css={{
                display: 'flex',
                alignItems: 'baseline',
                padding: '40px 0 24px',
                [IF_TABLET]: {
                  padding: '40px 0',
                },
              }}
            >
              <LargeTitle css={{ marginRight: 8 }}>Rooms</LargeTitle>
              <SingleClinicRegionDropdown
                clinicCode={selectedClinic!}
                onSelect={(clinicCode) => {
                  setSelectedClinic(clinicCode);
                }}
              />
            </div>
            <div
              css={{
                border: `1px solid #E5E8E8`,
                borderRadius: 12,
                height: 'calc(100% - 120px)',
                overflow: 'scroll',
                width: '100%',
              }}
            >
              {rooms.length > 0 ? (
                rooms.map((room, i) => (
                  <RoomRow
                    room={room}
                    number={i + 1}
                    key={room.id}
                    onSelect={handleSelect}
                    onPatientClick={handlePatientClick}
                  />
                ))
              ) : (
                <Container>
                  <SmallTitle>No rooms</SmallTitle>
                </Container>
              )}
            </div>
          </MainContent>
        </InnerWrap>
      </Content>
    </Fragment>
  );
};
