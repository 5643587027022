import {
  PatientFragment,
  PatientPartsFragment,
  useUpdatePatientMutation,
} from '@bc/codegen/medical';
import {
  dateOfBirthYupSchema,
  getPreferredPronoun,
  getSexDisplay,
  PREFERRED_PRONOUN,
} from '@bc/shared';
import * as Sentry from '@sentry/react';
import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import React, { Fragment } from 'react';
import { object, string } from 'yup';
import { PatientAuditLog } from '../../components/Audit';
import { Button } from '../../components/button';
import {
  AddressLookupField,
  CopyToClipField,
  DateOfBirthField,
  NormalField,
  SelectSimpleField,
} from '../../components/form';
import {
  ContentRow,
  HeaderEdit,
  InfoSection,
  SectionContent,
} from '../../components/layout';
import { DefinitionList, DefinitionListItem } from '../../components/List';
import { SectionTitle } from '../../components/text';
import { Patient } from '../../types';
import { AddressSection } from '../Address';

const GeneralValidation = object().shape({
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  pronunciation: string(),
  goesByName: string(),
  genderAtBirth: string(),
  dateOfBirth: dateOfBirthYupSchema(),
  preferredPronounType: string(),
  preferredPronoun: string(),
});

const AddressValidation = object().shape({
  address: object().shape({
    place_id: string(),
    formatted_address: string(),
  }),
  address2: string(),
});

export const PatientProfile: React.FC<{ data: PatientPartsFragment }> = ({
  data: patient,
}) => {
  const [updatePatient] = useUpdatePatientMutation();

  const handlePatientSubmit = async (
    values: Partial<PatientFragment>,
    { setStatus }: any,
  ) => {
    const patientId = patient?.id;

    if (!patientId) {
      throw new Error('Cannot save patient');
    }

    try {
      const dateOfBirth = DateTime.fromFormat(
        values.dateOfBirth || '',
        'MM/dd/yy',
      ).toFormat('yyyy-MM-dd');

      await updatePatient({
        variables: {
          set: {
            firstName: values.firstName,
            lastName: values.lastName,
            goesByName: values.goesByName,
            pronunciation: values.pronunciation,
            genderAtBirth: values.genderAtBirth,
            preferredPronounType: values.preferredPronounType,
            preferredPronoun: values.preferredPronoun,
            dateOfBirth: dateOfBirth,
            additionalInfo: values?.additionalInfo ?? '',
          },
          where: {
            id: {
              _eq: patientId,
            },
          },
        },
      });
      setStatus({
        editing: false,
      });
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  const handleAddressSubmit = async (
    values: Partial<Patient>,
    { setStatus }: any,
  ) => {
    const patientId = patient?.id;

    if (!patientId) {
      throw new Error('Cannot save patient');
    }

    try {
      await updatePatient({
        variables: {
          set: {
            address: values.address
              ? {
                  ...values.address,
                  address2: values.address2,
                }
              : null,
          },
          where: {
            id: {
              _eq: patientId,
            },
          },
        },
      });
      setStatus({
        editing: false,
      });
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  return (
    <SectionContent>
      <Formik
        initialValues={{
          firstName: patient?.firstName ?? '',
          lastName: patient?.lastName ?? '',
          goesByName: patient?.goesByName ?? '',
          pronunciation: patient?.pronunciation ?? '',
          dateOfBirth: patient?.dateOfBirth
            ? DateTime.fromFormat(patient.dateOfBirth, 'yyyy-MM-dd').toFormat(
                'MM/dd/yyyy',
              )
            : '',
          genderAtBirth: patient?.genderAtBirth ?? '',
          preferredPronounType: patient?.preferredPronounType ?? '',
          preferredPronoun: patient?.preferredPronoun ?? '',
          additionalInfo: patient?.additionalInfo ?? '',
        }}
        enableReinitialize
        validationSchema={GeneralValidation}
        initialStatus={{ editing: false }}
        onSubmit={handlePatientSubmit}
      >
        {({ status, setStatus, values, resetForm }) => {
          return (
            <Form>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SectionTitle
                  data-testid="patientProfileTitle"
                  style={{ flex: 'unset', marginRight: 12 }}
                >
                  Patient Profile
                </SectionTitle>
                {patient?.id && <PatientAuditLog patientId={patient.id} />}
              </div>
              <HeaderEdit
                title="Basic Details"
                editing={status.editing}
                onEdit={() => {
                  setStatus({ editing: true });
                }}
                onCancel={() => {
                  setStatus({
                    editing: false,
                  });
                  resetForm();
                }}
              />
              {!status.editing && (
                <DefinitionList>
                  <DefinitionListItem term="First name on file with insurance">
                    <CopyToClipField name="firstName" />
                  </DefinitionListItem>
                  <DefinitionListItem term="Last name on file with insurance">
                    <CopyToClipField name="lastName" />
                  </DefinitionListItem>
                  <DefinitionListItem term="Name patient goes by">
                    <CopyToClipField name="goesByName" />
                  </DefinitionListItem>
                  <DefinitionListItem term="Name pronunciation">
                    <CopyToClipField name="pronunciation" />
                  </DefinitionListItem>
                  <DefinitionListItem term="Birth date">
                    <CopyToClipField name="dateOfBirth" />
                  </DefinitionListItem>
                  <DefinitionListItem term="Sex assigned at birth">
                    <CopyToClipField
                      name="genderAtBirth"
                      transform={(value) => getSexDisplay(value)}
                    />
                  </DefinitionListItem>
                  <DefinitionListItem term="Pronouns">
                    {values.preferredPronounType === 'other' ? (
                      <CopyToClipField name="preferredPronoun" />
                    ) : (
                      <CopyToClipField
                        name="preferredPronounType"
                        transform={(value) => getPreferredPronoun(value)}
                      />
                    )}
                  </DefinitionListItem>
                  <DefinitionListItem term="Additional info">
                    <CopyToClipField name="additionalInfo" />
                  </DefinitionListItem>
                </DefinitionList>
              )}
              {status.editing && (
                <Fragment>
                  <ContentRow>
                    <NormalField
                      title="First name on file with insurance"
                      name="firstName"
                    />
                    <NormalField
                      title="Last name on file with insurance"
                      name="lastName"
                    />
                  </ContentRow>
                  <ContentRow>
                    <NormalField
                      title="Name patient goes by"
                      name="goesByName"
                    />
                    <NormalField
                      title="Name pronunciation"
                      name="pronunciation"
                    />
                  </ContentRow>
                  <ContentRow>
                    <InfoSection title="Birth date">
                      <DateOfBirthField name="dateOfBirth" />
                    </InfoSection>
                    <InfoSection title="Sex assigned at birth">
                      <SelectSimpleField name="genderAtBirth">
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </SelectSimpleField>
                    </InfoSection>
                  </ContentRow>
                  <ContentRow>
                    <InfoSection title="Pronouns">
                      <SelectSimpleField name="preferredPronounType">
                        <option value="">Select</option>
                        {PREFERRED_PRONOUN.map(({ label, value }) => (
                          <option value={value}>{label}</option>
                        ))}
                      </SelectSimpleField>
                    </InfoSection>
                    {values.preferredPronounType === 'other' && (
                      <NormalField title="Pronouns" name="preferredPronoun" />
                    )}
                  </ContentRow>
                  <ContentRow>
                    <NormalField
                      title="Additional Info"
                      name="additionalInfo"
                      fullWidth
                    />
                  </ContentRow>
                  <ContentRow>
                    <div />
                    <Button type="submit">Save</Button>
                  </ContentRow>
                </Fragment>
              )}
            </Form>
          );
        }}
      </Formik>
      <Formik
        initialValues={{
          address: patient?.address,
          address2: patient?.address?.address2 ?? '',
        }}
        enableReinitialize
        validationSchema={AddressValidation}
        initialStatus={{ editing: false }}
        onSubmit={handleAddressSubmit}
      >
        {({ status, setStatus, resetForm }) => {
          return (
            <Form>
              <HeaderEdit
                title="Address"
                editing={status.editing}
                onEdit={() => {
                  setStatus({ editing: true });
                }}
                onCancel={() => {
                  setStatus({
                    editing: false,
                  });
                  resetForm();
                }}
              />
              {!status.editing && (
                <DefinitionList>
                  <AddressSection location={patient?.address} />
                </DefinitionList>
              )}
              {status.editing && (
                <Fragment>
                  <ContentRow>
                    <InfoSection title="Address" fullWidth>
                      <AddressLookupField name="address" />
                    </InfoSection>
                  </ContentRow>
                  <ContentRow>
                    <NormalField
                      title="Apt, suite, etc (optional)"
                      name="address2"
                      fullWidth
                    />
                  </ContentRow>
                  <ContentRow>
                    <div />
                    <Button type="submit">Save</Button>
                  </ContentRow>
                </Fragment>
              )}
            </Form>
          );
        }}
      </Formik>
    </SectionContent>
  );
};
