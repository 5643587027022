/** @jsx jsx */
import {
  AppointmentCountsForDaysQuery,
  ClinicFragment,
  LoadStaffScheduleQuery,
} from '@bc/codegen/medical';
import { Colors, Spacing } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { sortBy, sumBy } from 'lodash';
import { DateTime } from 'luxon';
import { lighten } from 'polished';
import { Fragment } from 'react';
import { Badge } from '../Badge/Badge';
import { PrimaryText } from '../text';
import { StaffSchedule } from './helper';
import { Day, DayItem, DayItems, Month, Monthday, Week } from './styles';
import { WeekdayLabels } from './WeekdayLabels';

const MonthStyledDay = styled(Day)`
  border-right: 1px solid ${Colors.grayLighter};
  border-bottom: 1px solid ${Colors.grayLighter};
  min-height: 140px;
  padding: 4px 8px;
`;

type Schedules = LoadStaffScheduleQuery['staff_schedule'];
type AppointmentCount = AppointmentCountsForDaysQuery['appointment_counts'];

interface MonthViewProps {
  date: Date;
  staffSchedules?: Schedules;
  onSelect?: (taffSchedule: StaffSchedule) => void;
  appointmentCounts: AppointmentCount;
  clinics: ClinicFragment[];
}

export const MonthView = ({
  date,
  staffSchedules,
  onSelect,
  appointmentCounts,
  clinics,
}: MonthViewProps) => {
  // const today = DateTime.local();
  const currentDate = DateTime.fromJSDate(date);
  const startOfMonth = currentDate.startOf('month');
  const endOfMonth = currentDate.endOf('month');
  // NOTE: days of the week are offset with `.minus({ day: 1 })` because the
  // ISO format uses a Monday week-start and we want to show a Sunday week-start.
  // Not offsetting count here because we want num of days not the day of the
  // week's index on a Monday-start week structure.
  const firstSunday = startOfMonth.startOf('week').minus({ day: 1 });
  let lastDayOfTheMonth = endOfMonth.endOf('week').minus({ day: 1 });
  // NOTE: if the last day of the month is a Sunday, we need to show the entire
  //  last week of the month, so this skips the offset counting
  if (endOfMonth.weekday === 7) {
    lastDayOfTheMonth = endOfMonth.endOf('week');
  }
  const diffDays = lastDayOfTheMonth.diff(firstSunday, 'day').days;
  const diffWeeks = Math.abs(Math.ceil(diffDays / 7));
  const weeks = Array.from(Array(diffWeeks).keys());
  const days = Array.from(Array(7).keys());

  return (
    <Fragment>
      {clinics.map((clinic) => {
        const clinicSchedule = sortBy(
          staffSchedules?.filter(({ clinicId }) => {
            return clinic.id === clinicId;
          }),
          (schedule) => {
            const services = schedule.staff_schedule_services.map(
              ({ service }) => {
                return service;
              },
            );

            const isOnCall =
              services.includes('ONCALL') || services.includes('XRAY_ONCALL');

            return isOnCall;
          },
        );

        const counts = appointmentCounts
          ?.filter(({ clinicId }) => {
            return clinicId === clinic.id;
          })
          .map(({ date, count }) => {
            return {
              count,
              date: DateTime.fromISO(date!),
            };
          });

        const monthCount = sumBy(counts, ({ count }) => count ?? 0);

        return (
          <div>
            <div
              style={{
                position: 'relative',
                padding: `${Spacing.m}px 0`,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <div
                style={{
                  borderTopWidth: 1,
                  borderStyle: 'dashed',
                  borderTopColor: Colors.grayLight,
                  position: 'absolute',
                  left: 0,
                  right: 0,
                }}
              />
              <div
                style={{
                  backgroundColor: '#FFF',
                  flexDirection: 'row',
                  display: 'flex',
                  zIndex: 5,
                  padding: `0 ${Spacing.s}px`,
                }}
              >
                <PrimaryText style={{ marginRight: Spacing.s }}>
                  {clinic?.name}
                </PrimaryText>
                <Badge>
                  {monthCount} {monthCount !== 1 ? 'visits' : 'visit'}
                </Badge>
              </div>
            </div>
            <WeekdayLabels />
            <Month style={{ marginTop: 8 }}>
              {weeks.map((w) => {
                const weekStart = firstSunday.plus({ week: w });
                return (
                  <Week key={w}>
                    {days.map((d) => {
                      const day = weekStart.plus({ day: d });
                      // const isToday = day.hasSame(today, "day");
                      const isThisMonth = day.hasSame(currentDate, 'month');
                      const dayItems = clinicSchedule?.filter((staffSchedule) =>
                        DateTime.fromISO(staffSchedule.startDateTime).hasSame(
                          day,
                          'day',
                        ),
                      );

                      const dayCounts = appointmentCounts
                        ?.filter(({ clinicId }) => {
                          return clinicId === clinic.id;
                        })
                        .map(({ date, count }) => {
                          return {
                            count,
                            date: DateTime.fromISO(date!),
                          };
                        });
                      const countItem = dayCounts?.find(({ date }) => {
                        return date.hasSame(day, 'day');
                      });
                      const visitCount = countItem?.count ?? 0;

                      return (
                        <MonthStyledDay key={day.day}>
                          <Monthday current={isThisMonth}>
                            {day.day === 1 ? day.toFormat('MMM d') : day.day}
                          </Monthday>
                          <Badge
                            css={{
                              backgroundColor: Colors.tealLighter,
                              color: Colors.teal,
                              marginBottom: Spacing.s,
                            }}
                          >
                            {visitCount} {visitCount !== 1 ? 'visits' : 'visit'}
                          </Badge>
                          <DayItems noBorder>
                            {dayItems?.map((item, index) => {
                              const services = item.staff_schedule_services.map(
                                ({ service }) => {
                                  return service;
                                },
                              );

                              const isOnCall =
                                services.includes('ONCALL') ||
                                services.includes('XRAY_ONCALL');

                              const displayColor = isOnCall
                                ? Colors.grayLighter
                                : lighten(
                                    0.35,
                                    item?.staff?.color! ?? Colors.teal,
                                  );

                              return (
                                <DayItem
                                  key={item.id}
                                  kind="month"
                                  color={displayColor ?? Colors.teal}
                                  name={`${
                                    item.staff.firstName
                                  } ${item.staff.lastName?.slice(0, 1)}.`}
                                  time=""
                                  onClick={() => onSelect?.(item)}
                                />
                              );
                            })}
                          </DayItems>
                        </MonthStyledDay>
                      );
                    })}
                  </Week>
                );
              })}
            </Month>
          </div>
        );
      })}
    </Fragment>
  );
};
