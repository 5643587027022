/** @jsx jsx */
import { useLoadStaffQuery } from '@bc/codegen/manager';
import {
  TaskNotificationFragment,
  useLoadTaskGroupsQuery,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import Popover, { positionMatchWidth } from '@reach/popover';
import { useField } from 'formik';
import { matchSorter } from 'match-sorter';
import { useRef, useState } from 'react';
import { ICONS } from '../../assets';
import SearchIcon from '../../assets/search.svg';
import { StaffTypeDisplay } from '../../pages/staff/helpers';
import { InputWithIcon } from '../form';
import { SmallPrimaryText } from '../text';

interface Value {
  id: string;
  display: string;
  subLabel?: string;
  type: 'GROUP' | 'STAFF';
  label: string;
  value: any;
  meta?: Record<string, any>;
}

const fuzzyFilter = (input: string, values: Value[]): Value[] => {
  return matchSorter(values, input, {
    keys: ['label'],
  });
};

const RowImage = styled.img({
  width: '40px',
  height: '40px',
  borderRadius: '20px',
});

const Row = styled.div({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  ':hover': {
    background: Colors.grayLightest,
  },
  padding: '24px',
});

const RowContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  paddingLeft: '16px',
});

const RowTitle = styled(SmallPrimaryText)({
  fontWeight: 500,
  marginBottom: '4px',
});

const RowSubtitle = styled.span({
  fontFamily: 'Greycliff CF',
  fontSize: '14px',
  fontWeight: 400,
  color: '#647977',
});

const DropdownContainer = styled.div({
  background: '#FFFFFF',
  border: '1px solid #EFF1F1',
  borderRadius: '16px',
  maxHeight: '460px',
  overflow: 'auto',
});

export const TaskResponsibility = ({
  value,
  onChange,
  onClear,
}: {
  value: Partial<TaskNotificationFragment>[];
  onChange: (value: Value) => void;
  onClear: () => void;
}) => {
  const [focused, setFocused] = useState(false);
  const [query, setQuery] = useState('');
  const inputRef = useRef<HTMLDivElement>(null);
  const { data } = useLoadTaskGroupsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      order_by: {
        name: 'asc',
      },
    },
  });
  const { data: staffData } = useLoadStaffQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        active: {
          _eq: true,
        },
      },
      order_by: [
        {
          firstName: 'asc',
        },
        {
          lastName: 'asc',
        },
      ],
    },
  });

  const taskGroups = data?.tasks_groups ?? [];
  const staffs = staffData?.staff ?? [];

  const filterSorter: Value[] = [];

  taskGroups.forEach(({ id, name }) => {
    filterSorter.push({
      id,
      display: ICONS.taskGroup,
      label: name,
      subLabel: 'Group',
      type: 'GROUP',
      value: id,
      meta: {},
    });
  });
  staffs.forEach(({ id, firstName, lastName, avatarUrl, type }) => {
    filterSorter.push({
      id,
      display: avatarUrl || 'https://bravecare.imgix.net/staff/empty.png',
      label: `${firstName} ${lastName}`,
      subLabel: StaffTypeDisplay[type],
      type: 'STAFF',
      value: id,
    });
  });

  let filteredValues = query ? fuzzyFilter(query, filterSorter) : filterSorter;

  let display: string | undefined = '';

  if (value?.[0]?.taskGroupId) {
    const group = taskGroups.find((group) => {
      return group.id === value[0].taskGroupId;
    });
    display = group?.name;
  } else if (value?.[0]?.staffId) {
    const staff = staffs?.find((staff) => {
      return staff.id === value[0].staffId;
    });

    display = `${staff?.firstName} ${staff?.lastName}`;
  }

  console.log({ filteredValues });

  return (
    <div>
      <InputWithIcon
        icon={SearchIcon}
        value={value.length ? display : query}
        ref={inputRef}
        name="responsible_staff"
        placeholder="Search by staff name, group, or clinic"
        onChange={(e) => {
          if (!value.length) {
            setQuery(e.target.value);
          }
        }}
        onFocus={() => {
          if (!value.length) {
            setFocused(true);
          }
        }}
        onBlur={() => {
          setTimeout(() => {
            setFocused(false);
          }, 250);
        }}
        onClear={() => {
          onClear();
          setQuery('');
        }}
      />
      <Popover
        hidden={!focused}
        targetRef={inputRef}
        position={positionMatchWidth}
        css={{
          zIndex: 100000,
        }}
      >
        <DropdownContainer>
          {filteredValues?.map((rowValue, index) => {
            const { display, label, subLabel } = rowValue;
            return (
              <Row
                key={index}
                onClick={() => {
                  onChange(rowValue);
                  setFocused(false);
                  setQuery('');
                }}
              >
                <RowImage src={display} />
                <RowContent>
                  <RowTitle>{label}</RowTitle>
                  <RowSubtitle>{subLabel}</RowSubtitle>
                </RowContent>
              </Row>
            );
          })}
        </DropdownContainer>
      </Popover>
    </div>
  );
};

const filterNotifications = (
  notifcations: Partial<TaskNotificationFragment>[],
) => {
  return (
    notifcations?.filter((notification) => {
      return notification.staffId || notification.taskGroupId;
    }) ?? []
  );
};

export const TaskResponsibilityField = ({
  name,
  onClear,
}: {
  name: string;
  onClear: () => void;
}) => {
  const [field, _, helpers] = useField(name);
  const value = filterNotifications(field.value ?? []);

  return (
    <TaskResponsibility
      value={value}
      onClear={() => {
        helpers.setValue([]);
        onClear();
      }}
      onChange={(value: Value) => {
        if (value.type === 'STAFF') {
          helpers.setValue([
            {
              staffId: value.id,
            },
          ]);
        } else if (value.type === 'GROUP') {
          helpers.setValue([
            {
              taskGroupId: value.id,
            },
          ]);
        }
      }}
    />
  );
};
