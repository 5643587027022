import view_claimed from './view_claimed.svg';
import view_due from './view_due.svg';
import view_priority from './view_priority.svg';
import view_responsible from './view_responsible.svg';
import view_status from './view_status.svg';
import view_type from './view_type.svg';
import view_person from './person.svg';

export const VIEW_ICONS = {
  view_claimed,
  view_due,
  view_priority,
  view_responsible,
  view_status,
  view_type,
  view_person,
};
