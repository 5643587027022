/** @jsx jsx */
import {
  useDeleteClinicFromRegionMutation,
  useInsertClinicRegionMutation,
  useLoadRegionByPkQuery,
} from '@bc/codegen/manager';
import { useLoadAllClinicsQuery } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { RouteComponentProps } from 'react-router';
import { ICONS } from '../../assets';
import { TransparentButton } from '../../components/button';
import { ClinicSearcher } from '../../components/ClinicSelector/ClinicSelector';
import { Loader } from '../../components/Loader';
import { LargeTitle, PrimaryText } from '../../components/text';
import { Close } from '../ViewAppointment.styles';

const context = { role: 'manager' };

export const ViewRegion = ({
  match,
  history,
}: RouteComponentProps<{ id: string }>) => {
  const regionId = parseInt(match.params.id);

  const [removeClinicFromRegion, { loading: removeLoading }] =
    useDeleteClinicFromRegionMutation();
  const [addClinicToRegion, { loading: clinicAddLoading }] =
    useInsertClinicRegionMutation();

  const { data: clinicData } = useLoadAllClinicsQuery();
  const {
    data,
    refetch,
    loading: regionLoading,
  } = useLoadRegionByPkQuery({
    context,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: regionId,
    },
  });

  const region = data?.regions_by_pk;
  const clinics = clinicData?.clinics ?? [];
  const loading = regionLoading || removeLoading || clinicAddLoading;

  return (
    <div
      css={{
        padding: '40px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          alignItems: 'center',
          marginBottom: '40px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <LargeTitle>{region?.name}</LargeTitle>
        </div>
        <Close onClick={() => history.push('/regions')} />
      </div>

      {loading && <Loader />}
      {!loading && (
        <Fragment>
          <div>
            <div
              css={{
                marginBottom: '16px',
              }}
            >
              <PrimaryText
                css={{
                  fontSize: '18px',
                }}
              >
                Add More Clinics
              </PrimaryText>
            </div>
            <div>
              <PrimaryText>Clinic Search</PrimaryText>
              <div>
                <ClinicSearcher
                  onChange={async (clinic) => {
                    if (clinic && regionId) {
                      try {
                        await addClinicToRegion({
                          context: { role: 'manager' },
                          variables: {
                            regions: [
                              {
                                regionId,
                                clinicId: clinic.id,
                              },
                            ],
                          },
                        });
                      } catch (e) {
                        console.log(e);
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            {region?.clinics_regions?.map((clinicRegion) => {
              const clinic = clinics.find(({ id }) => {
                return id === clinicRegion.clinicId;
              });

              return (
                <div
                  css={{
                    display: 'flex',
                    padding: '8px 0',
                    alignItems: 'center',
                    gap: '16px',
                  }}
                >
                  <PrimaryText>{clinic?.name}</PrimaryText>
                  <TransparentButton
                    type="button"
                    onClick={async () => {
                      await removeClinicFromRegion({
                        context,
                        variables: {
                          where: {
                            regionId: {
                              _eq: regionId,
                            },
                            clinicId: {
                              _eq: clinicRegion.clinicId,
                            },
                          },
                        },
                      });
                      refetch();
                    }}
                  >
                    <img src={ICONS.close} alt="Delete" />
                  </TransparentButton>
                </div>
              );
            })}
          </div>
        </Fragment>
      )}
    </div>
  );
};
