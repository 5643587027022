/** @jsx jsx */
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { numberToCurrency } from '../../helpers';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '../Table';
import { ChargeProductProps } from './Charge';

const THeadCell = styled(TableHeadCell)({
  borderTop: 'none',
  color: Colors.gray,
  padding: '16px 0',
});

const TCell = styled(TableCell)({
  fontSize: 16,
  lineHeight: '19px',
  padding: '16px 0',
});

export const ProductsTable: React.FC<{
  products: ChargeProductProps[];
}> = ({ products }) => {
  return (
    <span css={{ padding: '0 24px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <THeadCell css={{ width: '60%' }}>Name</THeadCell>
            <THeadCell>Type</THeadCell>
            <THeadCell alignRight>Qty.</THeadCell>
            <THeadCell alignRight>Amount</THeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow key={product.id}>
              <TCell data-label="Name" css={{ width: '60%' }}>
                {product.product.name}
              </TCell>
              <TCell
                data-label="Type"
                css={
                  ['service', 'rx'].includes(product.product.type)
                    ? { textTransform: 'capitalize' }
                    : { textTransform: 'uppercase' }
                }
              >
                {product.product.type}
              </TCell>
              <TCell data-label="Qty." css={{ textAlign: 'right' }}>
                {product.quantity}
              </TCell>
              <TCell
                data-label="Amount"
                css={{ paddingRight: '0 !important', textAlign: 'right' }}
              >
                {numberToCurrency(product.amount)}
              </TCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </span>
  );
};
