/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { HeaderEdit } from '../layout';
import { DefinitionList, DefinitionListItem } from '../List';

interface SafetyQuestionnaireProps {
  covid19Test?: boolean;
  covid19Exposure?: boolean;
  covid19Symptoms?: boolean;
  covid19TestResult?: string;
}

export const SafetyChecklistInfo: React.FC<{
  checklist: SafetyQuestionnaireProps;
}> = ({ checklist }) => {
  return (
    <Fragment>
      <HeaderEdit title="COVID-19 Screening Checklist" editable={false} />
      <DefinitionList>
        <DefinitionListItem term="Symptoms">
          {checklist?.covid19Symptoms ? 'Yes' : 'No'}
        </DefinitionListItem>
        <DefinitionListItem term="Known exposure">
          {checklist?.covid19Exposure ? 'Yes' : 'No'}
        </DefinitionListItem>
        <DefinitionListItem term="COVID-19 test">
          {checklist?.covid19Test
            ? `Yes — ${checklist?.covid19TestResult} result`
            : 'No'}
        </DefinitionListItem>
      </DefinitionList>
    </Fragment>
  );
};
