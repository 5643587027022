import { getCarrierId, VALID_CARRIER_NAMES } from '@bc/shared';
import Downshift from 'downshift';
import fuzzaldrin from 'fuzzaldrin-plus';
import React, { useState } from 'react';
import { Input, InputWrap } from '../form';
import { Container, ResultsContainer, SearchResults } from './ui';

const fuzzyFilter = (input: string) =>
  fuzzaldrin.filter(VALID_CARRIER_NAMES, input);

interface SearchInputProps {
  error: boolean;
  onChange: (text?: string) => void;
  value?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  onChange,
  value,
  error,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleLocationChange = (item: string | null) => {
    const carrierId = getCarrierId(item);
    onChange(carrierId);
  };

  return (
    <Downshift
      onChange={handleLocationChange}
      inputValue={inputValue}
      onInputValueChange={setInputValue}
    >
      {({
        getRootProps,
        getInputProps,
        getItemProps,
        isOpen,
        openMenu,
        closeMenu,
        highlightedIndex,
        selectedItem,
      }) => (
        <Container {...getRootProps()}>
          <ResultsContainer>
            <div>
              <InputWrap style={{ marginBottom: 0 }}>
                <Input
                  {...getInputProps({
                    autoComplete: 'off',
                    onBlur: closeMenu,
                    onFocus: openMenu,
                    placeholder: 'Find Carrier',
                    type: 'text',
                  })}
                  error={error}
                />
              </InputWrap>
            </div>
            {isOpen && (
              <SearchResults
                items={fuzzyFilter(inputValue || '').slice(0, 10)}
                inputValue={inputValue}
                getItemProps={getItemProps}
                highlightedIndex={highlightedIndex}
                selectedItem={selectedItem}
              />
            )}
          </ResultsContainer>
        </Container>
      )}
    </Downshift>
  );
};

export default SearchInput;
