import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import React from 'react';
import { FONT_DISPLAY, FONT_PRIMARY, IF_TABLET } from '../styles';

export const PrimaryText = styled.span`
  ${FONT_PRIMARY};
  color: ${Colors.darkHarbor};
  font-size: 16px;
  line-height: 19px;
`;

export const SmallPrimaryText = styled(PrimaryText)({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '18px',
});

export const LargeTitle = styled(PrimaryText)`
  ${FONT_DISPLAY};
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  ${IF_TABLET} {
    font-size: 32px;
    line-height: 42px;
  }
`.withComponent('h1');

export const SectionTitle = styled(PrimaryText)`
  flex: 1;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  ${IF_TABLET} {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const MediumTitle = styled(SectionTitle)`
  ${FONT_DISPLAY};
`;

export const SmallTitle = styled(PrimaryText)`
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  ${IF_TABLET} {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const ModalTitle = styled(PrimaryText)<{ alignLeft?: boolean }>`
  color: ${Colors.darkHarbor};
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 16px;
  text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
  width: 100%;
  ${IF_TABLET} {
    font-size: 18px;
    line-height: 24px;
  }
`.withComponent('h3');

export const ModalText = styled(PrimaryText)<{ alignLeft?: boolean }>`
  color: ${Colors.darkHarbor};
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  margin-bottom: 24px;
  text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
  width: 100%;
  ${IF_TABLET} {
    font-size: 16px;
    line-height: 22px;
  }
`.withComponent('p');

export const TinyTitle = styled(PrimaryText)`
  color: ${Colors.gray};
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
`;

export const ErrorText = styled(PrimaryText)`
  color: ${Colors.white};
  font-size: 19px;
  line-height: 24px;
`;

export const PrimaryNotBlank = ({
  children,
  ...props
}: {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return <PrimaryText {...props}>{children ? children : '\u00A0'}</PrimaryText>;
};

export const LinkText = styled(PrimaryText)({
  textDecoration: 'underline',
  cursor: 'pointer',
  color: Colors.teal,
}).withComponent('a');
