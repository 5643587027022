import React from 'react';

const Toast = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        backgroundColor: '#BFCBB2',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '15px',
      }}
    >
      {children}
    </div>
  );
};

export default Toast;
