import { useFindLocationsQuery } from '@bc/codegen/medical';
import Downshift from 'downshift';
import React, { useState } from 'react';
import { Input, InputWrap } from '../form';
import LocationResults, { ClearIcon, Container, ResultsContainer } from './ui';

export type PlaceType = 'doctor' | 'hospital' | 'pharmacy';

interface Location {
  name: string;
  place_id: string;
  formatted_address: string;
}

interface Props {
  onChange: (item?: Location) => void;
  value?: Location;
  error: boolean;
  placeTypes: PlaceType[];
  location: {
    latitude: string;
    longitude: string;
  };
}

const SearchInput: React.FC<Props> = ({
  onChange,
  value,
  error,
  placeTypes,
  location,
}) => {
  const [inputValue, setInputValue] = useState(
    value && `${value.name}, ${value.formatted_address}`,
  );
  const { data } = useFindLocationsQuery({
    variables: {
      value: inputValue || '',
      placeTypes,
      location: `${location.latitude},${location.longitude}`,
    },
  });

  const itemToString = (item: any) => {
    if (!item) return '';
    return `${item.name}, ${item.formatted_address}`;
  };
  const handleLocationChange = (location: Location | null) => {
    onChange(location || undefined);
  };

  return (
    <Downshift
      onChange={handleLocationChange}
      itemToString={itemToString}
      inputValue={inputValue}
      onInputValueChange={(value) => setInputValue(value)}
    >
      {({
        getRootProps,
        getInputProps,
        getItemProps,
        highlightedIndex,
        isOpen,
        openMenu,
        closeMenu,
      }) => {
        return (
          <Container {...getRootProps()}>
            <ResultsContainer>
              <div>
                <InputWrap style={{ marginBottom: 0 }}>
                  <Input
                    {...getInputProps({
                      onFocus: openMenu,
                      type: 'text',
                      placeholder: 'Enter address',
                      autoComplete: 'off',
                      onBlur: () => {
                        closeMenu();
                      },
                    })}
                    error={error}
                  />
                  {!!value && (
                    <ClearIcon
                      onClick={() => {
                        setInputValue('');
                        onChange(undefined);
                      }}
                    />
                  )}
                </InputWrap>
              </div>
              {isOpen && data?.SearchLocations && (
                <LocationResults
                  inputValue={inputValue}
                  highlightedIndex={highlightedIndex}
                  getItemProps={getItemProps}
                  predictions={data?.SearchLocations}
                />
              )}
            </ResultsContainer>
          </Container>
        );
      }}
    </Downshift>
  );
};

export default SearchInput;
