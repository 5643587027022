/** @jsx jsx */
import { useLoadEditTaskByIdQuery } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import * as Sentry from '@sentry/react';
import { Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { Fragment } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Button, ButtonRed, OutlineButton } from '../../components/button';
import CloseIcon from '../../components/Tasks/icons/close.svg';
import { TasksForm } from '../../components/Tasks/TasksForm';
import { Values } from '../../components/Tasks/types';
import { useTrackEvent } from '../../lib/analytics';
import { useTasks } from '../../lib/task';
import {
  fill_scroll,
  overflow_form,
  StickyBottomRow,
  WrapContainer,
} from './styles';

const EditTask = ({
  match,
  dismissPath,
}: RouteComponentProps<{ taskId: string }> & { dismissPath: string }) => {
  const {
    updateTask,
    upsertTaskNotifications,
    updateTaskNotification,
    unassignTask,
    assignTask,
    isStaffInGroup,
  } = useTasks();
  const history = useHistory();

  const trackEvent = useTrackEvent();

  const taskId = match.params.taskId;

  const { data, loading } = useLoadEditTaskByIdQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: taskId,
    },
  });

  const task = data?.tasks_by_pk;

  // TODO: if no group then don't assign
  // verify we clear the task assignment if there is one then re-assign to the one we may or may not have

  const handleUpdate = async (values: Values) => {
    if (!task) return;

    const { dueDate, note, notifications, priority, subType, title, type } =
      values;

    try {
      const currentStaffResponsible = task?.tasks_notifications?.filter(
        (association) => {
          return association.staffId || association.taskGroupId;
        },
      )?.[0];

      const newStaffResponsible = notifications?.filter((association) => {
        return association.staffId || association.taskGroupId;
      })?.[0];

      const dueDateTime = DateTime.fromJSDate(dueDate)
        .set({ hour: 10 })
        .startOf('hour');

      await updateTask(taskId, {
        title,
        note,
        priority,
        subType: subType ?? null,
        dueDateTime: dueDateTime.toSQL(),
      });

      if (!currentStaffResponsible && newStaffResponsible) {
        await upsertTaskNotifications({
          variables: {
            notifications: [{ ...newStaffResponsible, taskId: task.id }],
          },
        });
      } else if (currentStaffResponsible && !newStaffResponsible) {
        await updateTaskNotification({
          variables: {
            where: {
              id: {
                _eq: currentStaffResponsible.id,
              },
            },
            notifications: {
              active: false,
            },
          },
        });
      } else if (currentStaffResponsible && newStaffResponsible) {
        const ifStaffChanged =
          currentStaffResponsible.staffId &&
          newStaffResponsible.staffId &&
          currentStaffResponsible.staffId !== newStaffResponsible.staffId;
        const ifGroupChanged =
          currentStaffResponsible.taskGroupId &&
          newStaffResponsible.taskGroupId &&
          currentStaffResponsible.taskGroupId !==
            newStaffResponsible.taskGroupId;
        const ifStaffToGroup =
          currentStaffResponsible.staffId && newStaffResponsible.taskGroupId;
        const ifGroupToStaff =
          currentStaffResponsible.taskGroupId && newStaffResponsible.staffId;

        const assignment = task.tasks_assignments?.filter(({ active }) => {
          return active;
        })?.[0];

        // Anytime we're transitiong to a group
        if ((ifGroupChanged || ifStaffToGroup) && assignment) {
          // Check if the current assigned staff is int he new group
          const isInGroup = await isStaffInGroup(
            newStaffResponsible.taskGroupId!,
            assignment.assignedTo,
          );
          if (!isInGroup) {
            await unassignTask(assignment.id);
          }
          // Antime we're transitioning to a staff
        } else if ((ifStaffChanged || ifGroupToStaff) && assignment) {
          await unassignTask(assignment.id);
        }

        if (
          ifStaffChanged ||
          ifGroupChanged ||
          ifStaffToGroup ||
          ifGroupToStaff
        ) {
          await updateTaskNotification({
            variables: {
              where: {
                id: {
                  _eq: currentStaffResponsible.id,
                },
              },
              notifications: {
                active: false,
              },
            },
          });
          await upsertTaskNotifications({
            variables: {
              notifications: [{ ...newStaffResponsible, taskId: task.id }],
            },
          });
        }
      }

      // Handle assignment swapping
      const newAssignment = values.assignment?.[0]?.assignedTo;
      const assignment = task.tasks_assignments?.filter(({ active }) => {
        return active;
      })?.[0];

      // If we have a new assignment in our form then re-assign task
      if (newAssignment && newAssignment !== assignment?.assignedTo) {
        if (assignment && assignment.id) {
          await unassignTask(assignment.id);
        }
        await assignTask({
          taskId: task.id,
          staffId: newAssignment,
        });
        // If we were assigned but now we aren't then unassign it.
      } else if (!newAssignment && assignment?.assignedTo) {
        await unassignTask(assignment.id);
      }

      const previousNotifications = [
        {
          ...(currentStaffResponsible?.taskGroupId && {
            taskGroupId: currentStaffResponsible.taskGroupId,
          }),
          ...(currentStaffResponsible?.staffId && {
            staffId: currentStaffResponsible.staffId,
          }),
        },
      ];

      const updatedNotifications = [
        {
          ...(newStaffResponsible?.taskGroupId && {
            taskGroupId: newStaffResponsible.taskGroupId,
          }),
          ...(newStaffResponsible?.staffId && {
            staffId: newStaffResponsible.staffId,
          }),
        },
      ];

      trackEvent({
        type: 'EDIT_TASK',
        data: {
          taskId,
          priority,
          subType: subType ?? null,
          type,
          dueDateTime,
          associations: patientAssociations,
          ...(!isEqual(previousNotifications, updatedNotifications) && {
            'notifications-previous': previousNotifications,
          }),
          notifications: updatedNotifications,
        },
      });

      history.push(dismissPath);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  const staffResponsibile = task?.tasks_notifications?.filter((association) => {
    return association.staffId || association.taskGroupId;
  });

  const patientAssociations = task?.tasks_notifications?.filter(
    (association) => {
      return (
        association.accountId ||
        association.patientId ||
        association.appointmentId
      );
    },
  );

  const assigment = task?.tasks_assignments?.filter(({ active }) => {
    return active;
  });

  return (
    <WrapContainer data-testid="edit_task">
      <div
        css={{
          position: 'absolute',
          top: '17px',
          right: '18px',
        }}
      >
        <Link to={dismissPath}>
          <img src={CloseIcon} />
        </Link>
      </div>
      {!loading && !!task && (
        <Fragment>
          <Formik
            onSubmit={handleUpdate}
            initialValues={{
              title: task?.title || '',
              note: task?.note || '',
              type: task.type,
              subType: task.subType ?? undefined,
              dueDate: DateTime.fromISO(task.dueDateTime!).toJSDate(),
              priority: task.priority ?? undefined,
              notifications: staffResponsibile ?? [],
              associations: patientAssociations ?? [],
              assignment: assigment,
            }}
          >
            {() => (
              <Form css={overflow_form}>
                <div css={fill_scroll}>
                  <TasksForm disableAssociations={true} />
                </div>
                <StickyBottomRow
                  css={{
                    justifyContent: 'space-between',
                  }}
                >
                  {task.active && (
                    <ButtonRed
                      type="button"
                      onClick={async () => {
                        await updateTask(task.id, {
                          active: false,
                        });
                        history.push(dismissPath);
                      }}
                    >
                      Delete
                    </ButtonRed>
                  )}
                  <div
                    css={{
                      flex: 1,
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <OutlineButton
                      type="button"
                      onClick={() => {
                        history.push(`${dismissPath}/${task.id}`);
                      }}
                      css={{
                        padding: '16px 40px',
                        marginRight: '16px',
                      }}
                    >
                      Cancel
                    </OutlineButton>
                    <Button type="submit">Save Task</Button>
                  </div>
                </StickyBottomRow>
              </Form>
            )}
          </Formik>
        </Fragment>
      )}
    </WrapContainer>
  );
};

export default EditTask;
