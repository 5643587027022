import { FullStaffNameFragment, Maybe, Staff_Type_Enum } from '@bc/codegen';
import gql from 'graphql-tag';
import { getPreferredPronoun } from './field_options';

export const formatPronoun = (entity: {
  preferredPronoun?: string | null;
  preferredPronounType?: string | null;
}) => {
  if (!entity.preferredPronounType && !entity.preferredPronoun) return null;
  const type = entity.preferredPronounType;
  const preferred = entity.preferredPronoun;
  const pronoun = getPreferredPronoun(type || '');
  if (preferred && (type === 'other' || type === 'custom')) {
    return preferred;
  }
  return pronoun;
};

export const formatFullName = (
  entity?: {
    __typename?: string | null;
    firstName?: string | null;
    goesByName?: string | null;
    lastName?: string | null;
  } | null,
) => {
  if (
    entity?.__typename === 'patients' &&
    !entity?.firstName &&
    !entity?.lastName
  ) {
    return 'Child not yet born or adopted';
  }
  const goesByName = entity?.goesByName ? `‘${entity.goesByName}’` : '';
  return [entity?.firstName, goesByName, entity?.lastName]
    .filter(Boolean)
    .join(' ');
};

export const parseFormattedAddress = (formattedAddress: string | undefined) => {
  const splitAddress = formattedAddress?.replace(', USA', '').split(',');
  return {
    street: splitAddress?.[0],
    cityState: splitAddress?.slice(1).join(',').trim(),
  };
};

export const TITLES: { [K in Staff_Type_Enum]: string } = {
  PHYSICIAN: 'MD',
  PHYSICIAN_ASSISTANT: 'PA-C',
  MEDICAL_ASSISTANT: 'MA',
  NURSE_PRACTITIONER: 'NP',
  TRIAGE_NURSE: 'RN',
  OPERATION: '',
  UNKNOWN: '',
};

// TODO: finish moving other references to shared
export const fullStaffName = ({
  staff,
  includePreNominals = false,
  includePostNominals = true,
}: {
  staff: { __typename?: 'staff' | undefined } & FullStaffNameFragment;
  includePreNominals?: boolean;
  includePostNominals?: boolean;
}) => {
  if (staff?.firstName && staff.lastName) {
    return `${includePreNominals ? preNominals(staff) : ''}${
      staff?.firstName
    } ${staff?.lastName}${includePostNominals ? postNominals(staff) : ''}`;
  } else {
    return '';
  }
};

fullStaffName.fragment = gql`
  fragment FullStaffName on staff {
    id
    firstName
    lastName
    staff_type {
      value
    }
  }
`;

export const preNominals = (staff: FullStaffNameFragment) => {
  return staff?.staff_type.value === 'PHYSICIAN' ? 'Dr. ' : '';
};

export const postNominals = (staff: FullStaffNameFragment) => {
  const initials = TITLES[staff?.staff_type.value as Staff_Type_Enum];
  return initials ? `, ${initials}` : '';
};

const initial = (name: string) => name.slice(0, 1).toUpperCase();

export const inititals = (entity: {
  firstName: Maybe<string>;
  lastName: Maybe<string>;
}) =>
  entity?.firstName && entity?.lastName
    ? `${initial(entity.firstName)}${initial(entity.lastName)}`
    : '';

export const toSentence = (arr: string[]) => {
  const length = arr.length;
  let sentence: string;
  if (length <= 1) {
    sentence = arr.toString();
  } else if (length === 2) {
    sentence = arr.join(' and ');
  } else {
    sentence = `${arr.slice(0, length - 1).join(', ')}, and ${arr.slice(-1)}`;
  }
  return sentence;
};
