import { Colors } from '@bc/theme';
import styled from '@emotion/styled';
import React from 'react';
import { PrimaryText } from '../text';

const Container = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const Value = styled(PrimaryText)({
  fontSize: 16,
  lineHeight: '22px',
});

const Label = styled(Value)({
  color: Colors.gray,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '18px',
  marginBottom: 4,
});

export const PaymentInfo = ({
  children,
  label,
}: {
  children?: React.ReactNode;
  label: string;
}) => (
  <Container>
    <Label>{label}</Label>
    <Value>{children}</Value>
  </Container>
);
