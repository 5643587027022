/** @jsx jsx */
import {
  AppointmentPartsFragment,
  Appointment_Status_Enum,
  useGetRoomsByClinicQuery,
  useUpdateRoomMutation,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import * as Sentry from '@sentry/react';
import sortBy from 'lodash/sortBy';
import React from 'react';
import CaretDown from '../../assets/caret-down.svg';
import RoomIcon from '../../assets/exit-door-login.svg';
import { IF_TABLET } from '../../styles';
import { Caret, Icon, Select, SelectWrap } from './shared';

// when the room is assigned, set the room status
const APPOINTMENT_TO_ROOM_STATUS_MAP: Partial<
  Record<Appointment_Status_Enum, string>
> = {
  CREATED: 'READY_FOR_PATIENT',
  CHECKED_IN: 'READY_FOR_MA',
  READY_FOR_PROVIDER: 'READY_FOR_PROVIDER',
  PROVIDER_FINISHED: 'PROVIDER_FINISHED',
  COMPLETED: 'DIRTY',
};

export const AppointmentRoomSelect: React.FC<{
  appointment: AppointmentPartsFragment;
}> = ({ appointment }) => {
  const [updateRoom] = useUpdateRoomMutation();

  const { data: roomsData, refetch } = useGetRoomsByClinicQuery({
    variables: {
      clinicId: appointment?.clinicId,
    },
    fetchPolicy: 'cache-and-network',
    skip:
      !appointment?.clinicId ||
      appointment.status === 'CANCELED' ||
      appointment.status === 'NO_SHOW' ||
      appointment.status === 'COMPLETED',
  });

  const rooms = roomsData?.rooms;
  const sortedRooms = sortBy(rooms, 'name');

  const room = rooms?.filter(
    (room) => room?.appointmentId === appointment.id,
  )?.[0];
  const roomId = room?.id;

  const handleSelectRoom = async (value: string) => {
    let appointmentId: string | null = appointment.id;
    let newRoomId = value;
    let status = APPOINTMENT_TO_ROOM_STATUS_MAP[appointment?.status!];

    if (value === 'unselected') {
      appointmentId = null;
      newRoomId = roomId!;
      status = 'EMPTY';
    }

    try {
      /**
       * NOTE: Appointment and room status are kept in sync in actions/pages/api/rooms.
       * As such only room status OR appointment status should be updated at a time.
       * Updating both when associated may result in a status update loop.
       *
       * More info: actions/pages/api/roomsReadme.md
       */
      await updateRoom({
        variables: {
          id: newRoomId,
          set: { appointmentId, status },
        },
      });
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }

    refetch();
  };

  return (
    <SelectWrap css={{ margin: '16px 0', [IF_TABLET]: { margin: '0 8px 0' } }}>
      <Icon src={RoomIcon} />
      <Select
        onChange={(e) => handleSelectRoom(e.target.value)}
        css={{
          border: `1.5px solid ${Colors.grayLight}`,
          borderRadius: 8,
          color: Colors.darkHarbor,
          cursor: 'pointer',
          fontSize: 16,
          fontWeight: 500,
          lineHeight: '22px',
          padding: '8px 40px',
          width: '100%',
        }}
        value={roomId || 'unselected'}
      >
        <option value="unselected">Select room</option>
        {sortedRooms.map((room) => (
          <option
            value={room.id}
            key={room.id}
            disabled={
              room.status !== 'EMPTY' && room.appointmentId !== appointment.id
            }
          >
            {room.name}
          </option>
        ))}
      </Select>
      <Caret src={CaretDown} css={{ height: 24, width: 24 }} />
    </SelectWrap>
  );
};
