/** @jsx jsx */
import {
  GetPatientByPkAndNotesQuery,
  LoadAppointmentsQuery,
  useGetPatientByPkAndNotesQuery,
  useInsertNotDuplicateMutation,
  useLoadAppointmentsQuery,
  useMergePatientsMutation,
} from '@bc/codegen/medical';
import { getRelationshipDisplay } from '@bc/shared';
import { jsx } from '@emotion/core';
import * as Sentry from '@sentry/react';
import humanize from 'humanize-string';
import { difference, isEqual, isObject, map, reduce, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { Fragment, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  AccountAvatarDisplay,
  PatientAvatarDisplay,
} from '../../components/AppointmentDetails/AvatarDisplay';
import { TextButton } from '../../components/button';
import { Header } from '../../components/Header';
import { Content, InnerWrap, MainContent } from '../../components/layout';
import { Sidebar } from '../../components/Nav/Sidebar';
import {
  LargeTitle,
  PrimaryNotBlank,
  PrimaryText,
} from '../../components/text';
import { formatDateOfBirth, formatPhoneNumber } from '../../helpers';
import {
  CancelButton,
  CGItem,
  FirstTD,
  getBorderStyle,
  Label,
  PurpleResolve,
  Resolve,
  Td,
  Tr,
} from './styles';

const DIFF_COLOR = '#AE4398';

const diff = function (obj1: any, obj2: any) {
  return reduce(
    obj1,
    function (result, value, key) {
      if (!isEqual(value, obj2?.[key])) {
        //@ts-ignore
        result[key] = value;
      }
      return result;
    },
    {},
  );
};

const getDisplayDate = (time: string) => {
  return DateTime.fromISO(time).toLocaleString(DateTime.DATE_MED);
};

interface Selected {
  from: string;
  to: string;
}

const usePatient = (patientId: string) => {
  const { data } = useGetPatientByPkAndNotesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      patientId: patientId,
    },
  });

  const { data: appointmentData } = useLoadAppointmentsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        appointment_patients: {
          patient_id: {
            _eq: patientId,
          },
        },
      },
      limit: 1,
      order_by: [
        {
          startTime: 'desc',
        },
      ],
    },
  });

  return {
    data,
    appointmentData,
  };
};

const CaregiverColumn = ({
  data,
  selectedId,
  compare,
}: {
  data: GetPatientByPkAndNotesQuery;
  compare: GetPatientByPkAndNotesQuery;
  selectedId?: string;
}) => {
  const patient = data?.patients_by_pk;
  if (!patient) {
    return null;
  }

  const accounts = patient?.accounts ?? [];
  const compareTo = compare?.patients_by_pk?.accounts ?? [];
  const selected = selectedId === patient.id;

  const diffAccounts = difference(
    map(compareTo, ({ accountId }) => accountId),
    map(accounts, ({ accountId }) => accountId),
  );

  const selectedAccounts = compareTo.filter(({ accountId }) => {
    return diffAccounts.includes(accountId);
  });

  return (
    <Td
      css={{
        borderRadius: selected ? '2px' : undefined,
        borderLeft: getBorderStyle(selected),
        borderRight: getBorderStyle(selected),
        borderBottom: selected
          ? getBorderStyle(selectedId === patient.id)
          : undefined,
      }}
    >
      {accounts?.map((account) => {
        return (
          <div key={account.id} style={{ display: 'flex' }}>
            <AccountAvatarDisplay
              accountId={account.accountId}
              style={{
                width: '24px',
                height: '24px',
              }}
            />
            <div
              css={{
                paddingLeft: '8px',
              }}
            >
              <CGItem>
                <PrimaryNotBlank>
                  {account.account.firstName} {account.account.lastName}
                </PrimaryNotBlank>
              </CGItem>
              <CGItem>
                <PrimaryNotBlank>
                  {formatPhoneNumber(account.account.phoneNumber)}
                </PrimaryNotBlank>
              </CGItem>
              {account.relationship && (
                <CGItem>
                  <PrimaryNotBlank>
                    {getRelationshipDisplay(account.relationship)}
                  </PrimaryNotBlank>
                </CGItem>
              )}
            </div>
          </div>
        );
      })}

      {selected &&
        selectedAccounts?.map((account) => {
          return (
            <div key={account.id} style={{ display: 'flex' }}>
              <AccountAvatarDisplay
                accountId={account.accountId}
                style={{
                  width: '24px',
                  height: '24px',
                }}
              />
              <div
                css={{
                  paddingLeft: '8px',
                }}
              >
                <CGItem>
                  <PrimaryNotBlank
                    css={{
                      color: DIFF_COLOR,
                    }}
                  >
                    {account.account.firstName} {account.account.lastName}
                  </PrimaryNotBlank>
                </CGItem>
                <CGItem>
                  <PrimaryNotBlank
                    css={{
                      color: DIFF_COLOR,
                    }}
                  >
                    {formatPhoneNumber(account.account.phoneNumber)}
                  </PrimaryNotBlank>
                </CGItem>
                {account.relationship && (
                  <CGItem>
                    <PrimaryNotBlank
                      css={{
                        color: DIFF_COLOR,
                      }}
                    >
                      {getRelationshipDisplay(account.relationship)}
                    </PrimaryNotBlank>
                  </CGItem>
                )}
              </div>
            </div>
          );
        })}
    </Td>
  );
};

interface PatientProps {
  patient1: GetPatientByPkAndNotesQuery;
  patient2: GetPatientByPkAndNotesQuery;
  selectedId?: string;
  appointment1?: LoadAppointmentsQuery;
  appointment2?: LoadAppointmentsQuery;
  onFieldSelect: (field: string) => void;
  copyFields: string[];
}
// If address display formatted address as a comp
// Do a diff from caregivers and show them as merged

// primaryClinicAddress, preferredPharmacy, address

const AddressDisplay = ({ value }: any) => {
  return value?.formatted_address ?? null;
};

const Value = ({ value }: any) => {
  return (
    <Fragment>
      {value === null ? (
        ''
      ) : isObject(value) ? (
        <AddressDisplay value={value} />
      ) : (
        `${value}`
      )}
    </Fragment>
  );
};

const Diff = ({
  patient1,
  patient2,
  selectedId,
  onFieldSelect,
  copyFields,
}: PatientProps) => {
  const diff1 = diff(
    patient1.patients_by_pk || {},
    patient2.patients_by_pk || {},
  );
  const diff2 = diff(
    patient2.patients_by_pk || {},
    patient1.patients_by_pk || {},
  );

  const diff1keys = Object.keys(diff1);
  const diff2keys = Object.keys(diff2);
  const diffKeys = uniq([...diff1keys, ...diff2keys]).filter((key) => {
    return key !== 'accounts' && key !== 'id';
  });

  const patient1Selected = patient1?.patients_by_pk?.id === selectedId;
  const patient2Selected = patient2?.patients_by_pk?.id === selectedId;

  return (
    <Fragment>
      {diffKeys.map((key) => {
        //@ts-ignore
        const value1 = diff1?.[key];
        //@ts-ignore
        const value2 = diff2?.[key];

        const override = copyFields.includes(key);
        const overrideValue = override ? value1 || value2 : '';

        const p1ValueSelectable = !value1 && value2 && patient1Selected;
        const p2ValueSelectable = !value2 && value1 && patient2Selected;

        return (
          <Tr>
            <FirstTD>
              <Label>{humanize(key)}</Label>
            </FirstTD>
            <Td
              selected={patient1Selected}
              onClick={() => {
                if (p1ValueSelectable) {
                  onFieldSelect(key);
                }
              }}
              css={{
                ':hover': {
                  cursor: p1ValueSelectable ? 'pointer' : undefined,
                  backgroundColor: p1ValueSelectable
                    ? '#F5F5F5'
                    : 'transparent',
                },
              }}
            >
              <PrimaryNotBlank>
                <span
                  style={{
                    color: overrideValue && !value1 ? DIFF_COLOR : undefined,
                  }}
                >
                  <Value value={value1 || overrideValue} />
                </span>
              </PrimaryNotBlank>
            </Td>
            <Td
              selected={patient2Selected}
              onClick={() => {
                if (p2ValueSelectable) {
                  onFieldSelect(key);
                }
              }}
              css={{
                ':hover': {
                  cursor: p2ValueSelectable ? 'pointer' : undefined,
                  backgroundColor: p2ValueSelectable
                    ? '#F5F5F5'
                    : 'transparent',
                },
              }}
            >
              <PrimaryNotBlank>
                <span
                  style={{
                    color: overrideValue && !value2 ? DIFF_COLOR : undefined,
                  }}
                >
                  <Value value={value2 || overrideValue} />
                </span>
              </PrimaryNotBlank>
            </Td>
          </Tr>
        );
      })}
    </Fragment>
  );
};

const TopRows = ({
  patient1,
  patient2,
  appointment1,
  appointment2,
  selectedId,
}: Partial<PatientProps>) => {
  const startTime1 = appointment1?.appointments?.[0]?.startTime;
  const startTime2 = appointment2?.appointments?.[0]?.startTime;

  const patient1Id = patient1?.patients_by_pk?.id;
  const patient2Id = patient2?.patients_by_pk?.id;

  return (
    <Fragment>
      <Tr>
        <FirstTD />
        <Td
          css={{
            borderRadius: selectedId === patient1Id ? '2px' : undefined,
            borderLeft: getBorderStyle(selectedId === patient1Id),
            borderRight: getBorderStyle(selectedId === patient1Id),
            borderTop: getBorderStyle(selectedId === patient1Id),
          }}
        >
          <PatientAvatarDisplay patientId={patient1?.patients_by_pk?.id} />
        </Td>
        <Td
          css={{
            borderRadius: selectedId === patient2Id ? '2px' : undefined,
            borderLeft: getBorderStyle(selectedId === patient2Id),
            borderRight: getBorderStyle(selectedId === patient2Id),
            borderTop: getBorderStyle(selectedId === patient2Id),
          }}
        >
          <PatientAvatarDisplay patientId={patient2?.patients_by_pk?.id} />
        </Td>
      </Tr>
      <Tr>
        <FirstTD>
          <Label>Last Seen</Label>
        </FirstTD>
        <Td
          css={{
            borderLeft: getBorderStyle(selectedId === patient1Id),
            borderRight: getBorderStyle(selectedId === patient1Id),
          }}
        >
          <PrimaryNotBlank>
            {startTime1 ? getDisplayDate(startTime1) : 'Never'}
          </PrimaryNotBlank>
        </Td>
        <Td
          css={{
            borderLeft: getBorderStyle(selectedId === patient2Id),
            borderRight: getBorderStyle(selectedId === patient2Id),
          }}
        >
          <PrimaryNotBlank>
            {startTime2 ? getDisplayDate(startTime2) : 'Never'}
          </PrimaryNotBlank>
        </Td>
      </Tr>
    </Fragment>
  );
};

const PatientMigrator = () => {
  const { ids } = useParams<{ ids: string }>();
  const history = useHistory();
  const [patient1Id, patient2Id] = ids.split(',');
  const [selectedPatient, setSelectedPatient] = useState<
    Selected | undefined
  >();
  const [copyFields, setCopyFields] = useState<string[]>([]);
  const { data: patient1Data, appointmentData: appointment1Data } =
    usePatient(patient1Id);
  const { data: patient2Data, appointmentData: appointment2Data } =
    usePatient(patient2Id);

  const [merging, setMerging] = useState(false);
  const [mergePatient] = useMergePatientsMutation();
  const [markNotDuplicate] = useInsertNotDuplicateMutation();
  const { data } = useGetPatientByPkAndNotesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      patientId: patient1Id,
    },
  });
  const patient = data?.patients_by_pk;

  const handleNotDuplicate = async () => {
    setMerging(true);
    await markNotDuplicate({
      variables: {
        patient1_id: patient1Id,
        patient2_id: patient2Id,
      },
    });
    setMerging(false);
    history.push('/patient_merger');
  };

  const submitMerge = async ({ from, to }: { from: string; to: string }) => {
    if (!patient1Id || !patient2Id) return;

    try {
      setMerging(true);
      await mergePatient({
        variables: {
          fromPatientId: from,
          toPatientId: to,
          copyFields,
        },
      });
      setMerging(false);
      history.push('/patient_merger');
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap>
          <Sidebar />
          <MainContent
            style={{
              paddingTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              css={{
                maxWidth: '750px',
              }}
            >
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottom: '1px solid #E5E8E8',
                  paddingBottom: '17px',
                  marginBottom: '30px',
                }}
              >
                <LargeTitle>
                  {patient?.firstName} {patient?.lastName} -{' '}
                  {formatDateOfBirth(patient?.dateOfBirth!)}
                </LargeTitle>
                {!selectedPatient && (
                  <TextButton onClick={handleNotDuplicate}>
                    Not a duplicate
                  </TextButton>
                )}
              </div>

              <table
                css={{
                  width: '100%',
                  borderCollapse: 'separate',
                }}
              >
                {patient1Data && patient2Data && (
                  <Fragment>
                    <TopRows
                      patient1={patient1Data}
                      patient2={patient2Data}
                      appointment1={appointment1Data}
                      appointment2={appointment2Data}
                      selectedId={selectedPatient?.to}
                    />
                    <Diff
                      patient1={patient1Data}
                      patient2={patient2Data}
                      selectedId={selectedPatient?.to}
                      onFieldSelect={(field) => {
                        setCopyFields((fields) => {
                          return uniq([...fields, field]);
                        });
                      }}
                      copyFields={copyFields}
                    />
                    <Tr>
                      <FirstTD>
                        <Label>Notes</Label>
                      </FirstTD>
                      <Td>
                        {patient1Data?.global_notes?.map(({ note }) => {
                          return (
                            <div css={{ marginBottom: '8px' }}>
                              <PrimaryText>{note}</PrimaryText>
                            </div>
                          );
                        })}
                      </Td>
                      <Td>
                        {patient2Data?.global_notes?.map(({ note }) => {
                          return (
                            <div css={{ marginBottom: '8px' }}>
                              <PrimaryText>{note}</PrimaryText>
                            </div>
                          );
                        })}
                      </Td>
                    </Tr>
                    <Tr>
                      <FirstTD>
                        <Label>Caregivers</Label>
                      </FirstTD>
                      <CaregiverColumn
                        data={patient1Data}
                        compare={patient2Data}
                        selectedId={selectedPatient?.to}
                      />
                      <CaregiverColumn
                        data={patient2Data}
                        compare={patient1Data}
                        selectedId={selectedPatient?.to}
                      />
                    </Tr>

                    <Tr>
                      <FirstTD css={{ borderBottom: 'none' }}></FirstTD>
                      <Td css={{ borderBottom: 'none' }}>
                        {!selectedPatient && (
                          <Resolve
                            type="button"
                            onClick={() => {
                              if (!patient1Id || !patient2Id) return;
                              setSelectedPatient({
                                from: patient2Id,
                                to: patient1Id,
                              });
                            }}
                          >
                            Use This One
                          </Resolve>
                        )}
                        {selectedPatient?.to === patient1Id && (
                          <div css={{ display: 'flex' }}>
                            <PurpleResolve
                              disabled={merging}
                              onClick={() => {
                                submitMerge(selectedPatient);
                              }}
                            >
                              Confirm
                            </PurpleResolve>
                            <CancelButton
                              disabled={merging}
                              css={{ marginLeft: '4px' }}
                              onClick={() => {
                                setSelectedPatient(undefined);
                                setCopyFields([]);
                              }}
                            >
                              Cancel
                            </CancelButton>
                          </div>
                        )}
                      </Td>
                      <Td css={{ borderBottom: 'none' }}>
                        {!selectedPatient && (
                          <Resolve
                            type="button"
                            onClick={() => {
                              if (!patient1Id || !patient2Id) return;

                              setSelectedPatient({
                                from: patient1Id,
                                to: patient2Id,
                              });
                            }}
                          >
                            Use This One
                          </Resolve>
                        )}
                        {selectedPatient?.to === patient2Id && (
                          <div css={{ display: 'flex' }}>
                            <PurpleResolve
                              disabled={merging}
                              onClick={() => {
                                submitMerge(selectedPatient);
                              }}
                            >
                              Confirm
                            </PurpleResolve>
                            <CancelButton
                              disabled={merging}
                              css={{ marginLeft: '4px' }}
                              onClick={() => {
                                setSelectedPatient(undefined);
                                setCopyFields([]);
                              }}
                            >
                              Cancel
                            </CancelButton>
                          </div>
                        )}
                      </Td>
                    </Tr>
                  </Fragment>
                )}
                <Tr>
                  <FirstTD css={{ borderBottom: 'none' }} />
                  <Td
                    css={{ borderBottom: 'none' }}
                    colSpan={selectedPatient?.to === patient1Id ? 2 : 1}
                  >
                    {selectedPatient?.to === patient1Id && (
                      <div>
                        <PrimaryText>
                          This will consolodate the duplicate patient into the
                          selected one. Any caregivers on the duplicate will be
                          added to the selected patient. Appoinments and chats
                          will be moved to the selected patient. This cannot be
                          undone so please make sure this is correct.
                        </PrimaryText>
                      </div>
                    )}
                  </Td>
                  {selectedPatient?.to === patient2Id && (
                    <Td css={{ borderBottom: 'none' }}>
                      <div>
                        <PrimaryText>
                          This will consolodate the duplicate patient into the
                          selected one. Any caregivers on the duplicate will be
                          added to the selected patient. Appoinments and chats
                          will be moved to the selected patient. This cannot be
                          undone so please make sure this is correct.
                        </PrimaryText>
                      </div>
                    </Td>
                  )}
                </Tr>
              </table>
            </div>
          </MainContent>
        </InnerWrap>
      </Content>
    </Fragment>
  );
};

export default PatientMigrator;
