/** @jsx jsx */
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import React from 'react';

export const Check: React.FC<{
  backgroundColor?: string;
  color?: string;
  hoverColor?: string;
  hoverBackgroundColor?: string;
  size?: number;
}> = ({
  backgroundColor = 'transparent',
  color = Colors.teal,
  hoverBackgroundColor,
  hoverColor,
  size = 24,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 16 16`}
      fill={backgroundColor}
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      css={{
        ':hover': {
          stroke: hoverColor,
          fill: hoverBackgroundColor,
        },
      }}
      {...props}
    >
      <circle r={8} cx={8} cy={8} strokeWidth="0" />
      <g transform="translate(3, 3), scale(0.666)">
        <path
          d="M13 5L6.125 11.6667L3 8.63636"
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
