import { FindKareoPatientMutation } from '@bc/codegen/medical';
import { DateTime } from 'luxon';
import { useState } from 'react';

export type SectionTypes = 'APPOINTMENT' | 'PATIENT' | 'DOCUMENT';

export const useKareoTransitions = (start: SectionTypes) => {
  const [sectionType, setSectionType] = useState<SectionTypes>();
  const [sectionOpen, setSectionOpen] = useState<SectionTypes | undefined>(
    start,
  );
  const [successTypes, setSuccessType] = useState<SectionTypes[]>([]);

  return {
    sectionType,
    setSectionType,
    sectionOpen,
    setSectionOpen,
    successTypes,
    setSuccessType,
  };
};

export const getPatientList = (
  kareoPatients?: FindKareoPatientMutation | null | undefined,
) => {
  const patients =
    kareoPatients?.FindKareoPatient?.map((kareoPatient) => {
      const DOB =
        (kareoPatient?.dateOfBirth &&
          DateTime.fromMillis(kareoPatient?.dateOfBirth, {
            zone: 'UTC',
          }).toFormat('yyyy-MM-dd')) ??
        '';

      return {
        name: `${kareoPatient?.firstName} ${kareoPatient?.lastName}`,
        dateOfBirth: String(DOB),
        guid: kareoPatient!.guid,
        kareoPatientId: String(kareoPatient!.patientId),
        genderId: String(kareoPatient?.genderId || '3'),
      };
    }) ?? [];

  return patients;
};

export const getPatientDOB = (dateOfBirth: string) => {
  return DateTime.fromFormat(dateOfBirth, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
};
