import type {
  Appointments,
  Care_Types_Enum,
  Visit_Classifications_Enum,
  Visit_Types_Enum,
} from '@bc/codegen';
import omit from 'lodash/omit';
import en from './locales/en/translation.json';
import es from './locales/es/translation.json';
export { en, es };

export const NAME_FOR_KID_NOT_BORN_OR_ADOPTED = 'Child not yet born or adopted';

export const RELATIONSHIPS = [
  {
    label: 'Parent',
    labelTx: 'shared.relationships.parent',
    value: 'parent',
  },
  {
    label: 'Guardian',
    labelTx: 'shared.relationships.guardian',
    value: 'guardian',
  },
  {
    label: 'Relative',
    labelTx: 'shared.relationships.relative',
    value: 'relative',
  },
  {
    label: 'Family Friend',
    labelTx: 'shared.relationships.familyFriend',
    value: 'family_friend',
  },
  {
    label: 'Self',
    labelTx: 'shared.relationships.self',
    value: 'self',
  },
  {
    label: 'Other',
    labelTx: 'shared.relationships.other',
    value: 'other',
  },
];

export const SEX_AT_BIRTH = [
  {
    label: 'Male',
    labelTx: 'shared.sexAtBirth.male',
    value: 'male',
  },
  {
    label: 'Female',
    labelTx: 'shared.sexAtBirth.female',
    value: 'female',
  },
];

export const PREFERRED_PRONOUN = [
  {
    label: 'He/him',
    labelTx: 'shared.pronouns.heHim',
    value: 'he/him',
  },
  {
    label: 'She/her',
    labelTx: 'shared.pronouns.sheHer',
    value: 'she/her',
  },
  {
    label: 'They/them',
    labelTx: 'shared.pronouns.theyThem',
    value: 'they/them',
  },
  {
    label: 'Other',
    labelTx: 'shared.pronouns.other',
    value: 'other',
  },
];

export const PREFERRED_LANGUAGE = [
  { label: 'Acehnese', value: 'acehnese' },
  { label: 'Achi', value: 'achi' },
  { label: 'Acholi', value: 'acholi' },
  { label: 'Afrikaans', value: 'afrikaans' },
  { label: 'Akan', value: 'akan' },
  { label: 'Akateko (Akatek)', value: 'akateko' },
  { label: 'Albanian', value: 'albanian' },
  { label: 'Amharic', value: 'amharic' },
  { label: 'Anuak', value: 'anuak' },
  { label: 'Arabic', value: 'arabic' },
  { label: 'Armenian', value: 'armenian' },
  { label: 'Ashanti', value: 'ashanti' },
  { label: 'ASL', value: 'asl' },
  { label: 'Assyrian', value: 'assyrian' },
  { label: 'Awakateko', value: 'awakateko' },
  { label: 'Azeri', value: 'azeri' },
  { label: 'Bahasa (Malaysian)', value: 'bahasa' },
  { label: 'Bambara', value: 'bambara' },
  { label: 'Bantu', value: 'bantu' },
  { label: 'Bashkir', value: 'bashkir' },
  { label: 'Basque', value: 'basque' },
  { label: 'Bassa', value: 'bassa' },
  { label: 'Belarusian', value: 'belarusian' },
  { label: 'Bengali', value: 'bengali' },
  { label: 'Bosnian', value: 'bosnian' },
  { label: 'Bulgarian', value: 'bulgarian' },
  { label: 'Burmese', value: 'burmese' },
  { label: 'Cambodian', value: 'cambodian' },
  { label: 'Cantonese', value: 'cantonese' },
  { label: 'Catalan', value: 'catalan' },
  { label: 'Cebuano', value: 'cebuano' },
  { label: 'Chadian Arabic', value: 'chadianArabic' },
  { label: 'Chaldean', value: 'chaldean' },
  { label: 'Chamorro', value: 'chamorro' },
  { label: 'Chin', value: 'chin' },
  { label: 'Choujo', value: 'choujo' },
  { label: 'Chuj', value: 'chuj' },
  { label: 'Chuukese', value: 'chuukese' },
  { label: 'Cotocoli (Tem)', value: 'cotocoli' },
  { label: 'Creole (Cape Verde)', value: 'creole' },
  { label: 'Croatian', value: 'croatian' },
  { label: 'Czech', value: 'czech' },
  { label: 'Dari', value: 'dari' },
  { label: 'Danish', value: 'danish' },
  { label: 'Dinka', value: 'dinka' },
  { label: 'Dioula', value: 'dioula' },
  { label: 'Dutch', value: 'dutch' },
  { label: 'Edo', value: 'edo' },
  { label: 'Egyptian Arabic', value: 'egyptianArabic' },
  { label: 'English', value: 'english' },
  { label: 'Estonian', value: 'estonian' },
  { label: 'Ewe', value: 'ewe' },
  { label: 'Farsi', value: 'farsi' },
  { label: 'Fijian Hindi', value: 'fijianHindi' },
  { label: 'Finnish', value: 'finnish' },
  { label: 'Flemish', value: 'flemish' },
  { label: 'Foochow (Fuzhou)', value: 'foochow' },
  { label: 'French', value: 'french' },
  { label: 'Fukienese', value: 'fukienese' },
  { label: 'Fulani', value: 'fulani' },
  { label: 'Fulde', value: 'fulde' },
  { label: 'Ga', value: 'ga' },
  { label: 'Garre', value: 'garre' },
  { label: 'Georgian', value: 'georgian' },
  { label: 'German', value: 'german' },
  { label: 'Greek', value: 'greek' },
  { label: 'Guarani', value: 'guarani' },
  { label: 'Gujarati', value: 'gujarati' },
  { label: 'Hainanese', value: 'hainanese' },
  { label: 'Haitian Creole', value: 'haitianCreole' },
  { label: 'Hakka (China)', value: 'hakka' },
  { label: 'Harar', value: 'harar' },
  { label: 'Hassaniya', value: 'hassaniya' },
  { label: 'Hausa', value: 'hausa' },
  { label: 'Hebrew', value: 'hebrew' },
  { label: 'Hindi', value: 'hindi' },
  { label: 'Hmong', value: 'hmong' },
  { label: 'Hokkien', value: 'hokkien' },
  { label: 'Hungarian', value: 'hungarian' },
  { label: 'Icelandic', value: 'icelandic' },
  { label: 'Ido', value: 'ido' },
  { label: 'Igbo', value: 'igbo' },
  { label: 'Ilocano', value: 'ilocano' },
  { label: 'Ilonggo', value: 'ilonggo' },
  { label: 'Indonesian', value: 'indonesian' },
  { label: 'Italian', value: 'italian' },
  { label: 'Jakalteko', value: 'jakalteko' },
  { label: 'Japanese', value: 'japanese' },
  { label: 'Jarai', value: 'jarai' },
  { label: 'Jiangsu', value: 'jiangsu' },
  { label: 'Jingpho (Kachin)', value: 'jingpho' },
  { label: "K'iche'", value: "k'Iche'" },
  { label: 'Kanjobal', value: 'kanjobal' },
  { label: 'Kannada', value: 'kannada' },
  { label: 'Karen', value: 'karen' },
  { label: 'Karenni', value: 'karenni' },
  { label: 'Kazakh', value: 'kazakh' },
  { label: 'Khmer', value: 'khmer' },
  { label: 'Kikongo', value: 'kikongo' },
  { label: 'Kikuyu', value: 'kikuyu' },
  { label: 'Kinyamulenge', value: 'kinyamulenge' },
  { label: 'Kinyarwanda', value: 'kinyarwanda' },
  { label: 'Kirundi', value: 'kirundi' },
  { label: 'Kituba', value: 'kituba' },
  { label: 'Kizigua', value: 'kizigua' },
  { label: 'Korean', value: 'korean' },
  { label: 'Kosraean', value: 'kosraean' },
  { label: 'Krahn', value: 'krahn' },
  { label: 'Krio', value: 'krio' },
  { label: 'Kunama', value: 'kunama' },
  { label: 'Kurdish', value: 'kurdish' },
  { label: 'Kyrgyz', value: 'kyrgyz' },
  { label: 'Laotian/Lao', value: 'laotian/Lao' },
  { label: 'Latvian', value: 'latvian' },
  { label: 'Lautu', value: 'lautu' },
  { label: 'Lingala', value: 'lingala' },
  { label: 'Lithuanian', value: 'lithuanian' },
  { label: 'Lorma', value: 'lorma' },
  { label: 'Luganda', value: 'luganda' },
  { label: 'Luo', value: 'luo' },
  { label: 'Macedonian', value: 'macedonian' },
  { label: 'Malayalam', value: 'malayalam' },
  { label: 'Malaysian', value: 'malaysian' },
  { label: 'Mam', value: 'mam' },
  { label: 'Mandarin', value: 'mandarin' },
  { label: 'Mandinka', value: 'mandinka' },
  { label: 'Marathi', value: 'marathi' },
  { label: 'Marshallese', value: 'marshallese' },
  { label: 'Matu', value: 'matu' },
  { label: 'May May', value: 'mayMay' },
  { label: 'Maya Family', value: 'mayaFamily' },
  { label: 'Mbay', value: 'mbay' },
  { label: 'Mende', value: 'mende' },
  { label: 'Mien', value: 'mien' },
  { label: 'Mina', value: 'mina' },
  { label: 'Mixteco', value: 'mixteco' },
  { label: 'Moldovan', value: 'moldovan' },
  { label: 'Mongolian', value: 'mongolian' },
  { label: 'Montenegrin', value: 'montenegrin' },
  { label: 'More', value: 'more' },
  { label: 'Mushunguli', value: 'mushunguli' },
  { label: 'Navajo', value: 'navajo' },
  { label: 'Nepali', value: 'nepali' },
  { label: 'Newari', value: 'newari' },
  { label: 'Norwegian', value: 'norwegian' },
  { label: 'Nuer', value: 'nuer' },
  { label: 'Oromo/Oromifa', value: 'oromo/Oromifa' },
  { label: "Pa'O (Taungthu)", value: "pa'O" },
  { label: 'Palauan', value: 'palauan' },
  { label: 'Pashto', value: 'pashto' },
  { label: 'Patois', value: 'patois' },
  { label: 'Pidgin (Camaroon)', value: 'pidginCamaroon' },
  { label: 'Pidgin (Nigeria)', value: 'pidginNigeria' },
  { label: 'Pohnpeian', value: 'pohnpeian' },
  { label: 'Polish', value: 'polish' },
  { label: 'Portuguese', value: 'portuguese' },
  { label: 'Pulaar', value: 'pulaar' },
  { label: 'Punjabi', value: 'punjabi' },
  { label: 'Purepecha', value: 'purepecha' },
  { label: "Q'anjob'al", value: "q'Anjob'Al" },
  { label: "Q'eqchi", value: "q'Eqchi" },
  { label: 'Rohingya', value: 'rohingya' },
  { label: 'Romanian', value: 'romanian' },
  { label: 'Russian', value: 'russian' },
  { label: 'Samoan', value: 'samoan' },
  { label: 'Sangho', value: 'sangho' },
  { label: 'Sangu', value: 'sangu' },
  { label: 'Senthang', value: 'senthang' },
  { label: 'Serbian', value: 'serbian' },
  { label: 'Shanghainese', value: 'shanghainese' },
  { label: 'Shona', value: 'shona' },
  { label: 'Sichuan', value: 'sichuan' },
  { label: 'Sicilian', value: 'sicilian' },
  { label: 'Sinhalese', value: 'sinhalese' },
  { label: 'Siyin', value: 'siyin' },
  { label: 'Slovak', value: 'slovak' },
  { label: 'Slovene', value: 'slovene' },
  { label: 'Somali', value: 'somali' },
  { label: 'Soninke', value: 'soninke' },
  { label: 'Sorani', value: 'sorani' },
  { label: 'Sousou', value: 'sousou' },
  { label: 'Spanish', value: 'spanish' },
  { label: 'Swahili', value: 'swahili' },
  { label: 'Swedish', value: 'swedish' },
  { label: 'Sylheti', value: 'sylheti' },
  { label: 'Syrian', value: 'syrian' },
  { label: 'Tagalog', value: 'tagalog' },
  { label: 'Taiwanese', value: 'taiwanese' },
  { label: 'Tajik', value: 'tajik' },
  { label: 'Tamil', value: 'tamil' },
  { label: 'Telugu', value: 'telugu' },
  { label: 'Temne', value: 'temne' },
  { label: 'Teochew', value: 'teochew' },
  { label: 'Thai', value: 'thai' },
  { label: 'Tibetan', value: 'tibetan' },
  { label: 'Tigrinya', value: 'tigrinya' },
  { label: 'Toishanese', value: 'toishanese' },
  { label: 'Tongan', value: 'tongan' },
  { label: 'Tosk', value: 'tosk' },
  {
    label: 'Trilingual ASL/Spoken Spanish',
    value: 'trilingualASLSpokenSpanish',
  },
  { label: 'Tulu', value: 'tulu' },
  { label: 'Turkish', value: 'turkish' },
  { label: 'Twi', value: 'twi' },
  { label: 'Ukrainian', value: 'ukrainian' },
  { label: 'Urdu', value: 'urdu' },
  { label: 'Uzbek', value: 'uzbek' },
  { label: 'Vietnamese', value: 'vietnamese' },
  { label: 'Vinsayan', value: 'vinsayan' },
  { label: 'Wolof', value: 'wolof' },
  { label: 'Xhosa', value: 'xhosa' },
  { label: 'Yapese', value: 'yapese' },
  { label: 'Yiddish', value: 'yiddish' },
  { label: 'Yoruba', value: 'yoruba' },
  { label: "Yup'ik", value: "yup'Ik" },
  { label: 'Zapoteco', value: 'zapoteco' },
  { label: 'Zomi', value: 'zomi' },
];

export const PARENT_TYPE_OPTIONS = [
  {
    label: 'Parent of child',
    value: 'POC',
  },
  {
    label: 'Mother of child',
    value: 'MOC',
  },
  { label: 'Father of child', value: 'FOC' },
];

export const PARENT_RELATIONSHIP = [
  {
    label: 'Adoptive',
    labelTx: 'shared.relationships.adoptive',
    value: 'adoptive',
  },
  {
    label: 'Biological',
    labelTx: 'shared.relationships.biological',
    value: 'biological',
  },
  {
    label: 'Foster',
    labelTx: 'shared.relationships.foster',
    value: 'foster',
  },
];

export const getSexDisplay = (value: string) => {
  const sex = SEX_AT_BIRTH.find((relationship) => relationship.value === value);
  return sex?.label;
};

export const getSexDisplayTx = (value: string) => {
  const sex = SEX_AT_BIRTH.find((relationship) => relationship.value === value);
  return sex?.labelTx;
};

export const getPreferredPronoun = (value: string) => {
  const pref = PREFERRED_PRONOUN.find((pref) => pref.value === value);
  return pref?.label;
};

export const getPreferredPronounTx = (value: string) => {
  const pref = PREFERRED_PRONOUN.find((pref) => pref.value === value);
  return pref?.labelTx;
};

export const getRelationshipDisplay = (value: string) => {
  const relationship = RELATIONSHIPS.find(
    (relationship) => relationship.value === value,
  );
  return relationship?.label;
};

export const getRelationshipDisplayTx = (value: string) => {
  const relationship = RELATIONSHIPS.find(
    (relationship) => relationship.value === value,
  );
  return relationship?.labelTx;
};

export const STATES = {
  al: 'Alabama',
  ak: 'Alaska',
  az: 'Arizona',
  ar: 'Arkansas',
  ca: 'California',
  co: 'Colorado',
  ct: 'Connecticut',
  de: 'Delaware',
  fl: 'Florida',
  ga: 'Georgia',
  hi: 'Hawaii',
  id: 'Idaho',
  il: 'Illinois',
  in: 'Indiana',
  ia: 'Iowa',
  ks: 'Kansas',
  ky: 'Kentucky',
  la: 'Louisiana',
  me: 'Maine',
  md: 'Maryland',
  ma: 'Massachusetts',
  mi: 'Michigan',
  mn: 'Minnesota',
  ms: 'Mississippi',
  mo: 'Missouri',
  mt: 'Montana',
  ne: 'Nebraska',
  nv: 'Nevada',
  nh: 'New Hampshire',
  nj: 'New Jersey',
  nm: 'New Mexico',
  ny: 'New York',
  nc: 'North Carolina',
  nd: 'North Dakota',
  oh: 'Ohio',
  ok: 'Oklahoma',
  or: 'Oregon',
  pa: 'Pennsylvania',
  ri: 'Rhode Island',
  sc: 'South Carolina',
  sd: 'South Dakota',
  tn: 'Tennessee',
  tx: 'Texas',
  ut: 'Utah',
  vt: 'Vermont',
  va: 'Virginia',
  wa: 'Washington',
  wv: 'West Virginia',
  wi: 'Wisconsin',
  wy: 'Wyoming',
};

export const stateAbbreviations = Object.keys(STATES).map((key) =>
  key.toUpperCase(),
);

export const statesMap = Object.entries(STATES).map(
  ([key, value]: [string, string]) => {
    return {
      id: key.toUpperCase(),
      value,
    };
  },
);

export const TIME_ZONES = {
  'Pacific/Honolulu': 'Hawaii Time',
  'America/Anchorage': 'Alaska Time',
  'America/Los_Angeles': 'Pacific Time',
  'America/Denver': 'Mountain Time',
  'America/Chicago': 'Central Time',
  'America/New_York': 'Eastern Time',
};

export const timeZoneMap = Object.entries(TIME_ZONES).map(([key, value]) => {
  return {
    value: key,
    label: value,
  };
});

export const APPOINTMENT_TYPES = {
  MOBILE: 'Brave Care App',
  CALLIN: 'Call In',
  WALKIN: 'Walk in',
  WEB: 'Website',
};

export const appointmentTypeMap = Object.entries(APPOINTMENT_TYPES).map(
  ([key, value]) => {
    return {
      id: key,
      value,
    };
  },
);

export const APPOINTMENT_STATUSES = {
  CREATED: 'Booked',
  CANCELED: 'Cancelled',
  CHECKED_IN: 'Checked in',
  COMPLETED: 'Completed',
  NO_SHOW: 'No Show',
  READY_FOR_PROVIDER: 'Ready for Provider',
  PROVIDER_FINISHED: 'Provider is Finished',
};

export const appointmentStatusMap = Object.entries(APPOINTMENT_STATUSES).map(
  ([key, value]) => {
    return {
      id: key,
      value,
    };
  },
);

export const VISIT_TYPES = {
  MEET_AND_GREET: 'Meet and greet',
  PRIMARY_CARE: 'Primary care',
  TELEMEDICINE: 'Telemedicine',
  URGENT_CARE: 'Urgent care',
  VACCINATION: 'Vaccination',
  TESTING: 'Testing',
};

export const VISIT_CLASSIFICATIONS: Record<Visit_Classifications_Enum, string> =
  {
    COVID_19_TEST: 'COVID-19 Test',
    MENTAL_HEALTH: 'Mental Health',
    SPORTS_PHYSICAL: 'Sports Physical',
    COVID_19_VACCINE: 'COVID-19 Vaccine',
    FLU_VACCINE: 'Flu Vaccine',
  };

export const visitTypeMap = Object.entries(VISIT_TYPES).map(([id, value]) => ({
  id,
  value,
}));

export const getDisplayLabel = (values: any[], value: string) => {
  const bit = values.find((bit) => bit.value === value);
  return bit?.label;
};

export const getDisplayLabelTx = (values: any[], value: string) => {
  const bit = values.find((bit) => bit.value === value);
  return bit?.labelTx;
};

export const VACCINE_SELECTION = [
  {
    label: 'Now',
    labelTx: 'shared.vaccineSelection.now',
    value: 'NOW',
  },
  {
    label: 'In the next 3 months',
    labelTx: 'shared.vaccineSelection.inTheNext3Months',
    value: 'NEXT_3_MONTHS',
  },
  {
    label: 'More than 3 months',
    labelTx: 'shared.vaccineSelection.moreThan3Months',
    value: 'MORE_THAN_3_MONTHS',
  },
  {
    label: 'I’m not sure',
    labelTx: 'shared.vaccineSelection.imNotSure',
    value: 'NOT_SURE',
  },
  {
    label: 'Prefer not to say',
    labelTx: 'shared.vaccineSelection.preferNotToSay',
    value: 'PREFER_NOT_TO_SAY',
  },
];

export const PCP_SELECTION = [
  {
    label: 'Yes',
    labelTx: 'shared.pcpSelection.yes',
    value: 'YES',
  },
  {
    label: 'No',
    labelTx: 'shared.pcpSelection.no',
    value: 'NO',
  },
];

export const LAST_WELLNESS_VISIT = [
  {
    label: 'Within the past 6 months',
    labelTx: 'shared.lastWellnessVisit.withinThePast6Months',
    value: 'PAST_6_MONTHS',
  },
  {
    label: '6-12 months ago',
    labelTx: 'shared.lastWellnessVisit.612MonthsAgo',
    value: '6_TO_12_MONTHS_AGO',
  },
  {
    label: 'More than 1 year ago',
    labelTx: 'shared.lastWellnessVisit.moreThan1YearAgo',
    value: 'OVER_1_YEAR_AGO',
  },
  {
    label: 'I’m not sure',
    labelTx: 'shared.lastWellnessVisit.imNotSure',
    value: 'NOT_SURE',
  },
];

export const HEARD_ABOUT_US_ITEMS = [
  {
    id: 'SEARCH_ENGINE',
    title: 'Google search',
    titleTx: 'shared.heardAboutUsItems.searchEngine',
  },
  {
    id: 'SCHOOL',
    title: 'School/daycare',
    titleTx: 'shared.heardAboutUsItems.schoolDaycare',
  },
  {
    id: 'SOCIAL_MEDIA',
    title: 'Social media (Facebook, Instagram)',
    titleTx: 'shared.heardAboutUsItems.socialMedia',
  },
  {
    id: 'FRIEND',
    title: 'Friend or co-worker',
    titleTx: 'shared.heardAboutUsItems.friendCoworker',
  },
  {
    id: 'CLINIC',
    title: 'Saw clinic in real life',
    titleTx: 'shared.heardAboutUsItems.clinic',
  },
  {
    id: 'COMMUNITY_EVENT',
    title: 'Community event',
    titleTx: 'shared.heardAboutUsItems.communityEvent',
  },
  {
    id: 'BRAVE_CARE_EVENT',
    title: 'Brave Care hosted event',
    titleTx: 'shared.heardAboutUsItems.bcEvent',
  },
  {
    id: 'MEDICAL_PROVIDER',
    title: 'Medical provider (OBGYN, PCP, etc.)',
    titleTx: 'shared.heardAboutUsItems.medicalProvider',
  },
  {
    id: 'PUBLICATION',
    title: 'Publication',
    titleTx: 'shared.heardAboutUsItems.localPublication',
  },
  {
    id: 'OTHER',
    title: 'Other',
    titleTx: 'shared.heardAboutUsItems.other',
  },
];

export interface CareTypes {
  [key: string]: {
    careTypeCode: string;
    duration: number;
    icon: string;
    interval: number;
    key: Visit_Types_Enum | Visit_Classifications_Enum | Care_Types_Enum;
    mobileEnabled: boolean;
    subtitle: string;
    subtitleTx: string;
    title: string;
    titleTx: string;
    updatedIcon?: string;
    visitClassification?: Visit_Classifications_Enum;
    visitType: Visit_Types_Enum;
    careType: Care_Types_Enum;
  };
}

export const ONE_MINUTE = 60 * 1000;
export const FIFTEEN_MINUTES = ONE_MINUTE * 15;
export const THIRTY_MINUTES = ONE_MINUTE * 30;
export const ONE_HOUR = ONE_MINUTE * 60;
export const NINETY_MINUTES = ONE_MINUTE * 90;

export const CARE_TYPES: CareTypes = {
  COVID_19_TEST: {
    careTypeCode: 'covid19Test',
    duration: THIRTY_MINUTES,
    icon: 'covid19',
    interval: 30,
    key: 'COVID_19_TEST',
    mobileEnabled: true,
    subtitle:
      'Rapid NAAT, rapid antigen & send out PCR, Children under 21 only (no adult testing)',
    subtitleTx: 'shared.careTypes.covid19TestSubtitle',
    title: 'COVID-19 Test',
    titleTx: 'shared.careTypes.covid19TestTitle',
    visitClassification: 'COVID_19_TEST',
    visitType: 'TESTING',
    careType: 'COVID_19_TEST',
  },
  TESTING: {
    careTypeCode: 'covid19Test',
    duration: THIRTY_MINUTES,
    icon: 'covid19',
    interval: 30,
    key: 'COVID_19_TEST',
    mobileEnabled: true,
    subtitle:
      'Rapid NAAT, rapid antigen & send out PCR, Children under 21 only (no adult testing)',
    subtitleTx: 'shared.careTypes.covid19TestSubtitle',
    title: 'COVID-19 Test',
    titleTx: 'shared.careTypes.covid19TestTitle',
    visitClassification: 'COVID_19_TEST',
    visitType: 'TESTING',
    careType: 'COVID_19_TEST',
  },
  URGENT_CARE: {
    careTypeCode: 'urgent_care',
    duration: THIRTY_MINUTES,
    icon: 'urgentCare',
    interval: 30,
    key: 'URGENT_CARE',
    mobileEnabled: false,
    subtitle: 'Sickness or urgent injuries',
    subtitleTx: 'shared.careTypes.urgentCareSubtitle',
    title: 'Urgent Care, Illness or Injury',
    titleTx: 'shared.careTypes.urgentCareTitle',
    updatedIcon: 'sick',
    visitType: 'URGENT_CARE',
    careType: 'URGENT_CARE',
  },
  CHECK_UP: {
    careTypeCode: 'primary_care',
    duration: ONE_HOUR,
    icon: 'checkUp',
    interval: 30,
    key: 'CHECK_UP',
    mobileEnabled: true,
    subtitle: 'Well Child Checks and annual checkups',
    subtitleTx: 'shared.careTypes.checkUpSubtitle',
    title: 'Check-Up',
    titleTx: 'shared.careTypes.checkUpTitle',
    updatedIcon: 'well',
    visitType: 'PRIMARY_CARE',
    careType: 'CHECK_UP',
  },
  OFFICE_VISIT: {
    careTypeCode: 'primary_care',
    duration: ONE_HOUR,
    icon: 'officeVisit',
    interval: 30,
    key: 'OFFICE_VISIT',
    mobileEnabled: true,
    subtitle:
      'For current Primary Care patients only, chronic disease & medication management, mental & behavioral health concerns',
    subtitleTx: 'shared.careTypes.officeVisitSubtitle',
    title: 'Office Visit (Primary Care Patients Only)',
    titleTx: 'shared.careTypes.officeVisitTitle',
    updatedIcon: 'well',
    visitType: 'PRIMARY_CARE',
    careType: 'OFFICE_VISIT',
  },
  PRIMARY_CARE: {
    careTypeCode: 'primary_care',
    duration: ONE_HOUR,
    icon: 'primaryCare',
    interval: 30,
    key: 'CHECK_UP',
    mobileEnabled: true,
    subtitle: 'Annual checkups and school physicals',
    subtitleTx: 'shared.careTypes.primaryCareSubtitle',
    title: 'Well Visit',
    titleTx: 'shared.careTypes.primaryCareTitle',
    updatedIcon: 'well',
    visitType: 'PRIMARY_CARE',
    careType: 'CHECK_UP',
  },
  VACCINATION: {
    careTypeCode: 'vaccination',
    duration: FIFTEEN_MINUTES,
    icon: 'vaccine',
    interval: 15,
    key: 'VACCINATION',
    mobileEnabled: true,
    subtitle:
      'COVID-19 vaccine (Pfizer only) and flu shots for children under 21 only',
    subtitleTx: 'shared.careTypes.vaccinationSubtitle',
    title: 'Covid or Flu Vaccinations',
    titleTx: 'shared.careTypes.vaccinationTitle',
    updatedIcon: 'vaccines',
    visitType: 'VACCINATION',
    careType: 'VACCINATION',
  },
  TELEMEDICINE: {
    careTypeCode: 'telemedicine',
    duration: THIRTY_MINUTES,
    icon: 'telehealth',
    interval: 30,
    key: 'TELEMEDICINE',
    mobileEnabled: false,
    subtitle: 'Virtual Care visit to address illness or minor injuries',
    subtitleTx: 'shared.careTypes.virtualVisitSubtitle', // TODO: translate this
    title: 'Telehealth',
    titleTx: 'shared.careTypes.virtualVisitTitle', // TODO: translate this
    updatedIcon: 'telemedicine',
    visitType: 'TELEMEDICINE',
    careType: 'TELEMEDICINE',
  },
  MEET_AND_GREET: {
    careTypeCode: 'meet_and_greet',
    duration: THIRTY_MINUTES,
    icon: 'meetAndGreet',
    interval: 30,
    key: 'MEET_AND_GREET',
    mobileEnabled: false,
    subtitle: 'Free meeting to get to know our Primary Care provider',
    subtitleTx: 'shared.careTypes.meetAndGreetSubtitle',
    title: 'Meet & Greet',
    titleTx: 'shared.careTypes.meetAndGreetTitle',
    visitType: 'MEET_AND_GREET',
    careType: 'MEET_AND_GREET',
  },
  SPORTS_PHYSICAL: {
    careTypeCode: '_sp',
    duration: THIRTY_MINUTES,
    icon: 'sportsPhysical',
    interval: 30,
    key: 'SPORTS_PHYSICAL',
    mobileEnabled: true,
    subtitle: 'Sports or camp physical exam',
    subtitleTx: 'shared.careTypes.sportsPhysicalSubtitle',
    title: 'Sports Physical',
    titleTx: 'shared.careTypes.sportsPhysicalTitle',
    visitClassification: 'SPORTS_PHYSICAL',
    updatedIcon: 'physicals',
    visitType: 'URGENT_CARE',
    careType: 'SPORTS_PHYSICAL',
  },
  MENTAL_HEALTH: {
    careTypeCode: '_mb',
    duration: THIRTY_MINUTES,
    icon: 'mental',
    interval: 30,
    key: 'MENTAL_HEALTH',
    mobileEnabled: true,
    subtitle: 'Discuss mental or behavioral health concerns',
    subtitleTx: 'shared.careTypes.mentalHealthSubtitle',
    title: 'Mental / Behavioral Health',
    titleTx: 'shared.careTypes.mentalHealthTitle',
    visitClassification: 'MENTAL_HEALTH',
    visitType: 'URGENT_CARE',
    careType: 'URGENT_CARE',
  },
};

export const AVAILABLE_CARE_TYPES = omit(CARE_TYPES, [
  'CHECK_UP',
  'OFFICE_VISIT',
]);

export const getCareType = ({
  careType: givenCareType,
  visitClassification,
  visitType,
}: {
  careType?: string | null;
  visitClassification?: Visit_Classifications_Enum | null;
  visitType?: Visit_Types_Enum | null;
} = {}) => {
  const validCareType = Object.keys(CARE_TYPES);

  let careType = CARE_TYPES[givenCareType ?? visitType ?? 'URGENT_CARE'];
  if (visitClassification && validCareType.includes(visitClassification)) {
    careType = CARE_TYPES[visitClassification];
  }

  return careType;
};

export const getKareoVisitType = (
  appointment: Pick<Appointments, 'careType' | 'type'>,
) => {
  if (appointment.careType === 'COVID_19_TEST') {
    return 'COVID-19 Test';
  }

  if (appointment.careType === 'TELEMEDICINE') {
    return 'Telehealth';
  }

  if (appointment.careType === 'CHECK_UP') {
    return 'Check-up (WCC)';
  }

  if (appointment.careType === 'OFFICE_VISIT') {
    return 'Office Visit PCP';
  }

  if (appointment.careType === 'SPORTS_PHYSICAL') {
    return 'Sports Physical';
  }

  if (appointment.careType === 'VACCINATION') {
    return 'Vaccination-COVID-19/ Flu';
  }

  if (appointment.careType === 'URGENT_CARE' && appointment.type === 'CALLIN') {
    return 'Urgent Care - SCHEDULED';
  }

  if (appointment.careType === 'URGENT_CARE' && appointment.type === 'WALKIN') {
    return 'Urgent Care - WALK IN';
  }

  return '';
};

export const KAREO_APPOINTMENT_TYPES = [
  { value: 'Telehealth Visit', label: 'Telehealth Visit' },
  { value: 'Urgent Care - WALK IN', label: 'Urgent Care - WALK IN' },
  { value: 'Urgent Care - SCHEDULED', label: 'Urgent Care - SCHEDULED' },
  { value: 'Check-up (WCC)', label: 'Check-up (WCC)' },
  { value: 'COVID-19 Test', label: 'COVID-19 Test' },
  { value: 'PCP Visit', label: 'PCP Visit' },
  { value: 'Office Visit', label: 'Office Visit PCP' },
  { value: 'Vaccination', label: 'Vaccination-COVID-19/ Flu' },
  { value: 'Sports Physical', label: 'Sports Physical' },
];

export const KAREO_CLINICS = [
  { value: 'NEPO - Brave Care', label: 'NEPO - Brave Care' },
  {
    value: 'Sellwood Clinic - Brave Care',
    label: 'Sellwood Clinic - Brave Care',
  },
  {
    value: 'Circle C - Brave Care',
    label: 'Circle C - Brave Care',
  },
  {
    value: 'Cedar Hills - Brave Care',
    label: 'Cedar Hills - Brave Care',
  },
];
