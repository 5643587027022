/** @jsx jsx */
import {
  AppointmentPartsFragment,
  Appointment_Status_Enum,
  useGetRoomsByClinicQuery,
  useUpdateAppointmentMutation,
  useUpdateRoomMutation,
} from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import * as Sentry from '@sentry/react';
import React, { Fragment, useEffect, useState } from 'react';
import CaretDown from '../../assets/caret-down.svg';
import Cancel from '../../assets/close.svg';
import NoShow from '../../assets/no-show.svg';
import CancelAppointmentModal from '../CancelAppointment';
import CheckInAppointmentModal from '../CheckInAppointment';
import { PrimaryText } from '../text';

interface AppointmentTransitionerProps {
  appointment: AppointmentPartsFragment;
}

const VALID_STATUSES = {
  CREATED: {
    background: Colors.grayLighter,
    color: Colors.darkHarbor,
    title: 'Booked',
  },
  READY_FOR_PATIENT: {
    background:
      'linear-gradient(90deg, #FF0000 -3.73%, #FFD600 44.35%, #60CFFF 100%)',
    color: Colors.white,
    title: 'Ready for Patient',
  },
  CHECKED_IN: {
    background: Colors.butterscotch,
    color: Colors.darkHarbor,
    title: 'Ready for MA',
  },
  WITH_MA: {
    background: Colors.butterscotchLight,
    color: Colors.darkHarbor,
    title: 'With MA',
  },
  READY_FOR_PROVIDER: {
    background: '#EA65FF',
    color: Colors.white,
    title: 'Ready for Provider',
  },
  WITH_PROVIDER: {
    background: 'rgb(057,019,063)',
    color: Colors.white,
    title: 'With Provider',
  },
  PROVIDER_FINISHED: {
    background: 'rgb(045,078,023)',
    color: Colors.white,
    title: 'Provider Finished',
  },
  COMPLETED: {
    background: '#0D957B',
    color: Colors.white,
    title: 'Completed',
  },
};

const INVALID_STATUSES = {
  CANCELED: {
    background: Colors.grayLighter,
    color: Colors.darkHarbor,
    title: 'Canceled',
  },
  NO_SHOW: {
    background: Colors.grayLighter,
    color: Colors.darkHarbor,
    title: 'No Show',
  },
  READY_FOR_MA: {
    background: 'rgb(210,081,000)',
    color: Colors.white,
    title: 'Ready for MA',
  },
  EMPTY: {
    background: 'rgb(032,023,015)',
    color: Colors.white,
    title: 'Empty',
  },
  DIRTY: {
    background: 'rgb(072,007,007)',
    color: Colors.white,
    title: 'Dirty',
  },
  RESERVED: {
    background: 'rgb(007,007,072)',
    color: Colors.white,
    title: 'Reserved',
  },
  BLOCKED: {
    background: 'rgb(007,007,072)',
    color: Colors.white,
    title: 'Blocked',
  },
};

export const STATUSES = { ...VALID_STATUSES, ...INVALID_STATUSES };

type ValidStatus = keyof typeof VALID_STATUSES;
// type InvalidStatus = keyof typeof INVALID_STATUSES;
type Status = keyof typeof STATUSES;

// when the room is assigned, set the room status
const APPOINTMENT_TO_ROOM_STATUS_MAP: Partial<
  Record<Appointment_Status_Enum, string>
> = {
  CREATED: 'READY_FOR_PATIENT',
  CHECKED_IN: 'READY_FOR_MA',
  READY_FOR_PROVIDER: 'READY_FOR_PROVIDER',
  PROVIDER_FINISHED: 'PROVIDER_FINISHED',
  COMPLETED: 'DIRTY',
};

export const AppointmentTransitioner: React.FC<
  AppointmentTransitionerProps
> = ({ appointment }) => {
  const [updateAppointment] = useUpdateAppointmentMutation({
    fetchPolicy: 'no-cache',
  });
  const [updateRoom] = useUpdateRoomMutation({
    fetchPolicy: 'no-cache',
  });

  const { data: roomsData } = useGetRoomsByClinicQuery({
    variables: {
      clinicId: appointment?.clinicId,
    },
    fetchPolicy: 'cache-and-network',
    skip:
      !appointment?.clinicId ||
      appointment.status === 'CANCELED' ||
      appointment.status === 'NO_SHOW' ||
      appointment.status === 'COMPLETED',
  });

  const room = roomsData?.rooms?.filter(
    (room) => room?.appointmentId === appointment.id,
  )?.[0];

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [checkInModalOpen, setCheckInModalOpen] = useState(false);
  const [status, setStatus] = useState<Status | undefined | null>();

  useEffect(() => {
    // @ts-ignore
    setStatus(room?.status ?? appointment.status);
  }, [appointment.status, room?.status]);

  const handleTransition = async (status: Status) => {
    /**
     * NOTE: Appointment and room status are kept in sync in actions/pages/api/rooms.
     * As such only room status OR appointment status should be updated at a time.
     * Updating both when associated may result in a status update loop.
     *
     * More info: actions/pages/api/roomsReadme.md
     */
    try {
      const shouldClearRoom = status === 'CANCELED' || status === 'NO_SHOW';

      if (room && shouldClearRoom) {
        await updateRoom({
          variables: {
            id: room.id,
            set: {
              appointmentId: null,
              status: 'EMPTY',
            },
          },
        });
      }

      if (
        status === 'CANCELED' ||
        status === 'CHECKED_IN' ||
        status === 'COMPLETED' ||
        status === 'CREATED' ||
        status === 'NO_SHOW' ||
        status === 'PROVIDER_FINISHED' ||
        status === 'READY_FOR_PROVIDER' ||
        status === 'WITH_PROVIDER'
      ) {
        await updateAppointment({
          variables: {
            id: appointment.id,
            set: {
              status,
            },
          },
        });
      } else if (room) {
        await updateRoom({
          variables: {
            id: room.id,
            set: {
              appointmentId: appointment.id,
              status,
            },
          },
        });
      }

      setStatus(status);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <Fragment>
      <Container>
        <Menu>
          <DropDownTrigger>
            <TriggerText>Status:&nbsp;</TriggerText>
            {status && (
              <StatusBadge status={status}>
                {STATUSES[status]?.title ?? status}
              </StatusBadge>
            )}
            <Caret src={CaretDown} />
          </DropDownTrigger>
          <DropDown portal={false}>
            {(Object.keys(VALID_STATUSES) as ValidStatus[])
              .filter((validStatuses) =>
                // hide other status options if the appointment has no room yet
                room ? true : ['CREATED'].includes(validStatuses),
              )
              .map((s) => (
                <DropDownItem
                  selected={s === status}
                  key={s}
                  onSelect={() => {
                    if (s === 'CHECKED_IN') {
                      setCheckInModalOpen(true);
                    } else {
                      handleTransition(s);
                    }
                  }}
                >
                  <StatusBadge status={s}>
                    {VALID_STATUSES[s].title}
                  </StatusBadge>
                </DropDownItem>
              ))}
            <Divider />
            <DropDownItem onSelect={() => handleTransition('NO_SHOW')}>
              <ItemIcon src={NoShow} role="presentation" />
              <ItemText css={{ marginLeft: 16 }}>Mark as No Show</ItemText>
            </DropDownItem>
            <DropDownItem onSelect={() => setCancelModalOpen(true)}>
              <ItemIcon
                src={Cancel}
                role="presentation"
                css={{ height: 12, width: 12, margin: 3 }}
              />
              <ItemText css={{ marginLeft: 16 }}>Cancel Appointment</ItemText>
            </DropDownItem>
          </DropDown>
        </Menu>
      </Container>
      {appointment && (
        <Fragment>
          <CancelAppointmentModal
            isOpen={cancelModalOpen}
            appointmentId={appointment?.id}
            onCancel={() => {
              setCancelModalOpen(false);
            }}
          />
          <CheckInAppointmentModal
            isOpen={checkInModalOpen}
            appointmentId={appointment?.id}
            onCancel={() => {
              setCheckInModalOpen(false);
            }}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

const Caret = styled.img`
  height: 24px;
  margin-left: auto;
  width: 24px;
  z-index: 5;
`;

const Container = styled.div`
  position: relative;
`;

const DropDownTrigger = styled(MenuButton)`
  align-items: center;
  background-color: ${Colors.white};
  border: 1.5px solid ${Colors.grayLight};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  min-width: 250px;
  padding: 8px 12px;
`;

const TriggerText = styled(PrimaryText)`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-right: 8px;
`;

const DropDown = styled(MenuList)`
  background: ${Colors.white};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  border: none;
  min-width: 250px;
  overflow: hidden;
  padding: 8px 0;
  position: relative;
  z-index: 1;
`;

const DropDownItem = styled(MenuItem)<{ selected?: boolean }>(
  (props) => `
  align-items: center;
  background-color: ${props.selected ? '#F3F5F5' : Colors.white};
  display: flex;
  padding: 8px 24px;

  &:hover {
    background-color: #F8FAFA;
  }
`,
);

const ItemIcon = styled.img`
  height: 18px;
  width: 18px;
`;

const ItemText = styled(PrimaryText)`
  color: ${Colors.darkHarbor};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

export const StatusBadge = styled(PrimaryText)<{
  status: Status;
}>(({ status }) => {
  // @ts-ignore
  const options = VALID_STATUSES[status] ?? INVALID_STATUSES[status];
  const background = options?.background ?? Colors.grayLighter;
  const color = options?.color ?? Colors.darkHarbor;
  return `
      align-items: flex-start;
      background: ${background};
      border-radius: 100px;
      color: ${color};
      display: flex;
      flex-direction: row;
      font-weight: 500;
      padding: 2px 12px;
      font-size: 14px;
      line-height: 18px;
      margin-right: 8px;
    `;
});

const Divider = styled.div`
  background-color: ${Colors.grayLight};
  height: 1px;
  margin: 8px 0;
  width: 100%;
`;
