/** @jsx jsx */
import { useGetPatientByPkQuery } from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { CloseButton } from '../components/button';
import { FlagsSidebar } from '../components/Flags';
import Loader from '../components/Loader/loader';
import { PatientHeader } from '../components/ModalHeader';
import { Content, ModalWrap, TaskContent } from '../components/Routing';
import AdditionalInformation from './patient/AdditionalInformation';
import Caregivers from './patient/Caregivers';
import DisplayPatientCharges from './patient/Charges';
import Insurance from './patient/Insurance';
import Kareo from './patient/Kareo';
import Profile from './patient/Profile';
import PatientTasks from './patient/Tasks';
import VisitHistory from './patient/VisitHistory';
import CreateTask from './tasks/CreateTask';
import EditTask from './tasks/EditTask';
import ViewTask from './tasks/ViewTask';
import {
  ClosePosition,
  InfoPanel,
  MainContent,
  ModalWrapper,
  NavigationWrapper,
  PaddedLink,
} from './ViewAppointment.styles';

const ViewPatient: React.FC<
  { returnPath?: string } & RouteComponentProps<{
    patientId: string;
  }>
> = ({ returnPath, match, history }) => {
  const { patientId } = match.params;

  const { data, loading } = useGetPatientByPkQuery({
    variables: {
      patientId,
    },
  });

  const patient = data?.patients_by_pk;

  return loading ? (
    <Loader />
  ) : (
    <ModalWrapper aria-label="Patient">
      <CloseButton
        onClick={() => history.push(returnPath || '/dashboard')}
        css={ClosePosition}
      />
      {patient && (
        <NavigationWrapper>
          <PaddedLink icon="profile" to={`${match.url}/profile`}>
            Profile
          </PaddedLink>
          <PaddedLink icon="additionalInfo" to={`${match.url}/additional-info`}>
            Additional Information
          </PaddedLink>
          <PaddedLink icon="caregiver" to={`${match.url}/caregivers`}>
            Caregivers
          </PaddedLink>
          <PaddedLink icon="insurance" to={`${match.url}/insurance`}>
            Insurance
          </PaddedLink>
          <PaddedLink icon="billing" to={`${match.url}/charges`}>
            Charges
          </PaddedLink>
          <PaddedLink icon="history" to={`${match.url}/visits`}>
            Visits
          </PaddedLink>
          <PaddedLink data-testid="tasks" icon="task" to={`${match.url}/tasks`}>
            Tasks
          </PaddedLink>
          <PaddedLink icon="kareo" to={`${match.url}/kareo`}>
            Kareo Sync
          </PaddedLink>
        </NavigationWrapper>
      )}
      <MainContent>
        <PatientHeader patientId={patientId} />
        {!data && loading && <Loader />}
        {data && patient && (
          <Switch>
            <Route
              path={`${match.url}/profile`}
              render={() => <Profile data={patient} />}
            />
            <Route
              path={`${match.url}/additional-info`}
              render={() => <AdditionalInformation data={patient} />}
            />
            <Route
              path={`${match.url}/caregivers`}
              render={() => <Caregivers data={patient} />}
            />
            <Route
              path={`${match.url}/insurance`}
              render={() => <Insurance data={patient} />}
            />
            <Route
              path={`${match.url}/charges`}
              render={() => <DisplayPatientCharges patientId={patient.id} />}
            />
            <Route
              path={`${match.url}/visits`}
              render={() => <VisitHistory patientId={patientId} />}
            />
            <Route
              path={`${match.url}/kareo`}
              render={() => <Kareo patient={patient} />}
            />
            <Route
              path={`${match.url}/tasks`}
              render={() => (
                <PatientTasks patient={patient} basePath={match.url} />
              )}
            />
          </Switch>
        )}

        {data && patient && (
          <Switch>
            <Route
              path={`${match.url}/tasks/:taskId/edit`}
              // @ts-ignore
              render={(props: RouteComponentProps<{ taskId: string }>) => {
                return (
                  <ModalWrap
                    {...props}
                    onDismissGoToPath={`${match.url}/tasks`}
                  >
                    <TaskContent>
                      <EditTask {...props} dismissPath={`${match.url}/tasks`} />
                    </TaskContent>
                  </ModalWrap>
                );
              }}
            />
            <Route
              path={`${match.url}/tasks/create`}
              // @ts-ignore
              render={(props: RouteComponentProps<{ taskId: string }>) => {
                return (
                  <ModalWrap
                    {...props}
                    onDismissGoToPath={`${match.url}/tasks`}
                  >
                    <TaskContent>
                      <CreateTask
                        {...props}
                        defaultAssociations={[
                          {
                            patientId: patient.id,
                          },
                        ]}
                        dismissPath={`${match.url}/tasks`}
                      />
                    </TaskContent>
                  </ModalWrap>
                );
              }}
            />
            <Route
              path={`${match.url}/tasks/:taskId`}
              // @ts-ignore
              render={(props: RouteComponentProps<{ taskId: string }>) => {
                return (
                  <ModalWrap
                    {...props}
                    onDismissGoToPath={`${match.url}/tasks`}
                  >
                    <Content>
                      <ViewTask {...props} dismissPath={`${match.url}/tasks`} />
                    </Content>
                  </ModalWrap>
                );
              }}
            />
          </Switch>
        )}
      </MainContent>
      <InfoPanel>
        <FlagsSidebar patientId={patient?.id} />
      </InfoPanel>
    </ModalWrapper>
  );
};

export default ViewPatient;
