import React, { useRef, useEffect } from 'react';
import MaskedInput from 'react-text-mask';

import { Input, InputBorder, NoBorderInput } from './form';

export const PhoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const PhoneInput = ({
  value,
  onChange,
  onBlur,
  disabled = false,
  error = false,
  autoFocus = false,
  placeholder = '(***) ***-****',
  icon,
  focused,
}: {
  value: string;
  onChange: (value: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  icon?: string;
  focused?: boolean;
}) => {
  const inputRef = useRef<any>();

  useEffect(() => {
    if (focused) {
      inputRef?.current?.inputElement?.focus();
    }
  }, [focused]);

  return icon ? (
    <InputBorder error={error}>
      <div
        style={{
          padding: '16px',
        }}
      >
        <img src={icon} alt="" />
      </div>
      <MaskedInput
        ref={inputRef}
        disabled={disabled}
        mask={PhoneNumberMask}
        placeholder={placeholder}
        value={value}
        autoFocus={autoFocus}
        onChange={(e: any) => {
          onChange(e.target.value);
        }}
        onBlur={onBlur}
        render={(ref: any, props: any) => {
          return (
            <NoBorderInput
              ref={ref}
              {...props}
              focused={focused}
              error={error}
              data-testid="phoneNumber"
              autoComplete="off"
            />
          );
        }}
        style={disabled ? { backgroundColor: '#E3E3E3' } : {}}
      />
    </InputBorder>
  ) : (
    <MaskedInput
      disabled={disabled}
      mask={PhoneNumberMask}
      placeholder={placeholder}
      value={value}
      autoFocus={autoFocus}
      onChange={(e: any) => {
        onChange(e.target.value);
      }}
      onBlur={onBlur}
      render={(ref: any, props: any) => {
        return (
          <Input
            ref={ref}
            {...props}
            autoComplete="off"
            error={error}
            data-testid="phoneNumber"
          />
        );
      }}
      style={disabled ? { backgroundColor: '#E3E3E3' } : {}}
    />
  );
};
