import {
  AppointmentPartsFragment,
  PatientFragment,
  PatientPartsFragment,
  useAddPatientTelehealthConsentMutation,
  useUpdatePatientMutation,
} from '@bc/codegen/medical';
import {
  getDisplayLabel,
  LAST_WELLNESS_VISIT,
  PCP_SELECTION,
  VACCINE_SELECTION,
} from '@bc/shared';
import * as Sentry from '@sentry/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import React, { Fragment } from 'react';
import { lazy, object, string } from 'yup';
import { useScheduledClinic } from '../../hooks';
import { AddressSection } from '../Address';
import { PatientAuditLog } from '../Audit';
import { Button } from '../button';
import {
  CopyToClipField,
  NormalField,
  PlaceSearchField,
  Select,
  SelectWithCaret,
} from '../form';
import { HeardAboutUs } from '../HeardAboutUs';
import { ContentRow, HeaderEdit, InfoSection, SectionContent } from '../layout';
import { DefinitionList, DefinitionListItem } from '../List';
import { SectionTitle } from '../text';
import { PatientConsents } from './PatientConsents';

const PharmacyValidation = object().shape({
  preferredPharmacy: lazy((value) => {
    if (typeof value === 'object') {
      return object().shape({ formatted_address: string() });
    } else {
      return string();
    }
  }),
});

const PhysicianValidation = object().shape({
  address: string(),
  address2: string(),
});

interface Pharmacy {
  preferredPharmacy: any;
}

export const AdditionalInformation: React.FC<{
  appointment?: AppointmentPartsFragment;
  data: PatientPartsFragment;
}> = ({ appointment, data }) => {
  const scheduledClinic = useScheduledClinic();
  const [addPatientTelehealthConsent] =
    useAddPatientTelehealthConsentMutation();
  const [updatePatient] = useUpdatePatientMutation();

  const patient = data;
  const accompanyingAccountId = appointment?.accountId;
  const accompanyingAccount = patient?.accounts?.filter(
    (a) => a.accountId === accompanyingAccountId,
  )?.[0]?.account;

  const handleAgreesToTelehealthTreatment = async () => {
    const patientId = patient?.id;

    if (!patientId || !accompanyingAccountId) {
      throw new Error('Cannot save patient');
    }

    try {
      await addPatientTelehealthConsent({
        variables: {
          accompanyingAccountId,
          patientId,
          appointmentId: appointment?.id,
        },
      });
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handlePharmacyUpdate = async (values: Pharmacy, { setStatus }: any) => {
    const patientId = patient?.id;

    if (!patientId) {
      throw new Error('Cannot save patient');
    }

    try {
      await updatePatient({
        variables: {
          set: {
            preferredPharmacy: values.preferredPharmacy || null,
          },
          where: {
            id: {
              _eq: patientId,
            },
          },
        },
      });
      setStatus({
        editing: false,
      });
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handlePrimarySubmit = async (
    values: Partial<PatientFragment>,
    { setStatus }: any,
  ) => {
    const patientId = patient?.id;

    if (!patientId) {
      throw new Error('Cannot save patient');
    }
    try {
      await updatePatient({
        variables: {
          set: {
            physicianName: values.physicianName || '',
            primaryClinicAddress: values.primaryClinicAddress || null,
            hasPCP: values?.hasPCP ?? null,
            lastWellVisitStatus: values?.lastWellVisitStatus ?? null,
          },
          where: {
            id: {
              _eq: patientId,
            },
          },
        },
      });
      setStatus({
        editing: false,
      });
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const clinic = {
    latitude: scheduledClinic?.latitude ?? '45.5470119',
    longitude: scheduledClinic?.longitude ?? '-122.5916175',
  };
  const preferredPharmacy = patient?.preferredPharmacy ?? '';

  return (
    <Fragment>
      <Formik
        initialValues={{
          preferredPharmacy,
        }}
        enableReinitialize
        validationSchema={PharmacyValidation}
        initialStatus={{ editing: false }}
        onSubmit={handlePharmacyUpdate}
      >
        {({ status, setStatus, resetForm }) => {
          return (
            <Form>
              <SectionContent>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <SectionTitle
                    data-testid="additionalInfoTitle"
                    style={{ flex: 'unset', marginRight: 12 }}
                  >
                    Additional Information
                  </SectionTitle>
                  {patient?.id && <PatientAuditLog patientId={patient.id} />}
                </div>
                <HeaderEdit
                  title="Preferred Pharmacy"
                  editing={status.editing}
                  onEdit={() => {
                    setStatus({ editing: true });
                  }}
                  onCancel={() => {
                    setStatus({
                      editing: false,
                    });
                    resetForm();
                  }}
                />
                {!status.editing && (
                  <DefinitionList>
                    <AddressSection
                      location={preferredPharmacy}
                      placeType="Pharmacy"
                    />
                  </DefinitionList>
                )}
                {status.editing && (
                  <Fragment>
                    <ContentRow>
                      <InfoSection title="Pharmacy" fullWidth>
                        <PlaceSearchField
                          name="preferredPharmacy"
                          location={clinic}
                          placeTypes={['pharmacy']}
                        />
                      </InfoSection>
                    </ContentRow>
                    <ContentRow>
                      <div />
                      <Button type="submit">Save</Button>
                    </ContentRow>
                  </Fragment>
                )}
              </SectionContent>
            </Form>
          );
        }}
      </Formik>
      <Formik
        initialValues={{
          hasPCP: patient?.hasPCP ?? 'YES',
          primaryClinicAddress: patient?.primaryClinicAddress,
          physicianName: patient?.physicianName ?? '',
          lastWellVisitStatus: patient?.lastWellVisitStatus ?? '',
          vaccinationStatus: patient?.vaccinationStatus ?? '',
        }}
        enableReinitialize
        validationSchema={PhysicianValidation}
        initialStatus={{ editing: false }}
        onSubmit={handlePrimarySubmit}
      >
        {({ status, setStatus, setFieldValue, resetForm, values }) => {
          return (
            <Form>
              <HeaderEdit
                title="Intake"
                editing={status.editing}
                onEdit={() => {
                  setStatus({ editing: true });
                }}
                onCancel={() => {
                  setStatus({
                    editing: false,
                  });
                  resetForm();
                }}
              />
              {!status.editing && (
                <DefinitionList>
                  <DefinitionListItem term="Last Well Visit Status">
                    <CopyToClipField
                      name="lastWellVisitStatus"
                      transform={(value) =>
                        getDisplayLabel(LAST_WELLNESS_VISIT, value)
                      }
                    />
                  </DefinitionListItem>
                  <DefinitionListItem term="Vaccination status">
                    <CopyToClipField
                      name="vaccinationStatus"
                      transform={(value) =>
                        getDisplayLabel(VACCINE_SELECTION, value)
                      }
                    />
                  </DefinitionListItem>
                  <DefinitionListItem term="PCP Declaration">
                    <CopyToClipField
                      name="hasPCP"
                      transform={(value) =>
                        value === 'YES'
                          ? getDisplayLabel(PCP_SELECTION, value)
                          : 'No primary care provider'
                      }
                    />
                  </DefinitionListItem>
                  {patient.physicianName && (
                    <DefinitionListItem term="PCP Declaration">
                      <CopyToClipField name="physicianName" />
                    </DefinitionListItem>
                  )}
                  {patient?.primaryClinicAddress && (
                    <AddressSection
                      location={patient?.primaryClinicAddress}
                      placeType="Clinic"
                    />
                  )}
                </DefinitionList>
              )}
              {status.editing && (
                <Fragment>
                  <ContentRow>
                    <InfoSection title="Last Well Visit Status">
                      <Field name="lastWellVisitStatus">
                        {({ field }: FieldProps<any>) => (
                          <SelectWithCaret>
                            <Select {...field}>
                              <option value="">Select</option>
                              {LAST_WELLNESS_VISIT.map((option) => {
                                return (
                                  <option
                                    value={option.value}
                                    key={option.value}
                                  >
                                    {option.label}
                                  </option>
                                );
                              })}
                            </Select>
                          </SelectWithCaret>
                        )}
                      </Field>
                    </InfoSection>
                    <InfoSection title="Vaccination Status">
                      <Field name="vaccinationStatus">
                        {({ field }: FieldProps<any>) => (
                          <SelectWithCaret>
                            <Select {...field}>
                              <option value="">Select</option>
                              {VACCINE_SELECTION.map((option) => {
                                return (
                                  <option
                                    value={option.value}
                                    key={option.value}
                                  >
                                    {option.label}
                                  </option>
                                );
                              })}
                            </Select>
                          </SelectWithCaret>
                        )}
                      </Field>
                    </InfoSection>
                  </ContentRow>
                  <ContentRow>
                    <InfoSection title="PCP Declaration" fullWidth>
                      <Field name="hasPCP">
                        {({ field }: FieldProps<any>) => (
                          <SelectWithCaret>
                            <Select {...field}>
                              <option value="">Select</option>
                              {PCP_SELECTION.map((option) => {
                                return (
                                  <option
                                    value={option.value}
                                    key={option.value}
                                  >
                                    {option.label}
                                  </option>
                                );
                              })}
                            </Select>
                          </SelectWithCaret>
                        )}
                      </Field>
                    </InfoSection>
                  </ContentRow>
                  {values.hasPCP === 'YES' && (
                    <Fragment>
                      <ContentRow>
                        <NormalField
                          title="Provider Name"
                          name="physicianName"
                          fullWidth
                        />
                      </ContentRow>
                      <ContentRow>
                        <InfoSection title="Primary Care Clinic" fullWidth>
                          <PlaceSearchField
                            name="primaryClinicAddress"
                            placeTypes={['doctor', 'hospital']}
                            location={clinic}
                          />
                        </InfoSection>
                      </ContentRow>
                    </Fragment>
                  )}
                  <ContentRow>
                    <div />
                    <Button type="submit">Save</Button>
                  </ContentRow>
                </Fragment>
              )}
            </Form>
          );
        }}
      </Formik>
      {patient && (
        <PatientConsents
          patient={patient}
          accompanyingAccount={accompanyingAccount}
          onAgreesToTelehealthTerms={handleAgreesToTelehealthTreatment}
        />
      )}
      {patient && <HeardAboutUs patient={patient} />}
    </Fragment>
  );
};
