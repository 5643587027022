/** @jsx jsx */
import {
  GlobalNoteFragment,
  useWatchGlobalNotesSubscription,
} from '@bc/codegen/medical';
import { jsx } from '@emotion/core';
import React, { Fragment, useState } from 'react';
import { ICONS } from '../../assets';
import { SmallPrimaryText, SmallTitle } from '../text';
import { AddFlagForm } from './AddFlagForm';
import { ArchivedFlagsModal } from './ArchivedFlagsModal';
import { ArchiveFlagModal } from './ArchiveFlagModal';
import { Flag } from './Flag';
import { AddLink, ArchiveLink, Container, Header, Icon } from './styles';

export const AppointmentFlags: React.FC<{
  appointmentId?: string;
}> = ({ appointmentId }) => {
  const [archiveNote, setArchiveNote] = useState<GlobalNoteFragment>();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [showArchiveNotes, setShowArchiveNotes] = useState(false);

  const { data: globalNoteData } = useWatchGlobalNotesSubscription({
    variables: {
      where: { appointmentId: { _eq: appointmentId } },
      orderBy: [{ createdAt: 'desc' }],
    },
    skip: !appointmentId,
  });

  if (!appointmentId) {
    return null;
  }

  const globalNotes = globalNoteData?.global_notes ?? [];
  const activeNotes = globalNotes.filter((note) => !note.deleted);
  const archivedNotes = globalNotes.filter((note) => !!note.deleted);

  return (
    <Fragment>
      <Container css={{ paddingBottom: 16 }}>
        <Header>
          <SmallTitle>Visit Flags</SmallTitle>
          {isFormVisible ? (
            <AddLink onClick={() => setIsFormVisible(false)}>Cancel</AddLink>
          ) : (
            <AddLink onClick={() => setIsFormVisible(true)}>
              <Icon src={ICONS.add} css={{ marginRight: 4 }} />
              Add flag
            </AddLink>
          )}
        </Header>
        {isFormVisible && (
          <AddFlagForm
            appointmentId={appointmentId}
            onCancel={() => setIsFormVisible(false)}
          />
        )}
        <div>
          {activeNotes.length === 0 && (
            <SmallPrimaryText css={{ display: 'block', paddingTop: 16 }}>
              No visit flags added.
            </SmallPrimaryText>
          )}
          {activeNotes.map((note) => (
            <div
              key={note.id}
              css={{
                padding: '8px 24px',
                ':nth-of-type(odd)': { background: '#F3F5F5' },
              }}
            >
              <Flag note={note} onDelete={(note) => setArchiveNote(note)} />
            </div>
          ))}
        </div>
        {!!archivedNotes.length && (
          <ArchiveLink
            onClick={() => setShowArchiveNotes(true)}
            css={{ paddingTop: 8 }}
          >
            View archive
          </ArchiveLink>
        )}
      </Container>
      <ArchiveFlagModal
        isOpen={!!archiveNote}
        note={archiveNote}
        onCancel={() => setArchiveNote(undefined)}
      />
      {showArchiveNotes && (
        <ArchivedFlagsModal
          isOpen={showArchiveNotes}
          archivedNotes={archivedNotes}
          onClose={() => setShowArchiveNotes(false)}
        />
      )}
    </Fragment>
  );
};
