/** @jsx jsx */
import { useGetAccountByPkQuery } from '@bc/codegen/medical';
import { Colors } from '@bc/theme';
import { jsx } from '@emotion/core';
import { DateTime } from 'luxon';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { RouteComponentProps } from 'react-router-dom';
import calendarIcon from '../../assets/calendar.svg';
import cameraIcon from '../../assets/camera.svg';
import moreIcon from '../../assets/more.svg';
import sendIcon from '../../assets/send.svg';
import { Button } from '../../components/button';
import { ChatHeader } from '../../components/ChatHeader';
import { ChatMessage, ChatSystemMessage } from '../../components/ChatMessage';
import { ChatNotePrompt, ChatSummary } from '../../components/ChatNote';
import { ChatSidebar } from '../../components/ChatSidebar';
import { Header } from '../../components/Header';
import { Content, InnerWrap, MainContent } from '../../components/layout';
import { Sidebar } from '../../components/Nav/Sidebar';
import { PrimaryText } from '../../components/text';
import { useAsyncChat } from '../../lib/chat';
import { useMessageWatch } from '../../lib/sound';
import { IF_TABLET } from '../../styles';
import {
  AppointmentConfirmation,
  ChatActions,
  ChatActionsBackdrop,
  ChatInputBar,
  ChatSendButton,
  ChatStatusWarning,
  ChatTextarea,
  RequestAction,
  RequestActionHeading,
  RequestActionsTray,
  RequestActionTitle,
  SystemMessage,
  SystemMessageIcon,
} from './Chat.styles';

const Chat: React.FC<
  RouteComponentProps<{
    id: string;
  }>
> = ({ history, match }) => {
  const scrollEnd = useRef<HTMLDivElement>(null);
  const [lightboxSrc, setLightboxSrc] = useState<string>();
  const [showActions, setShowActions] = useState(false);
  const [showSummaryPrompt, setShowSummaryPrompt] = useState(false);
  const [value, setValue] = useState('');
  const { id } = match.params;
  const {
    chatSession,
    chatSummary,
    currentStaffData,
    messages,
    requestAction,
    sendChatMessage,
    updateReadReceipt,
    writeChatNote,
  } = useAsyncChat(id);

  useMessageWatch(id);

  const latestMessageUpdatedAt = messages[messages.length - 1]?.updatedAt;
  const chatHeight = scrollEnd.current?.clientHeight;

  useEffect(() => {
    setTimeout(
      () => {
        scrollEnd.current?.scrollIntoView({ behavior: 'smooth' });
        updateReadReceipt();
      },
      chatHeight ? 10 : 1000,
    );
  }, [latestMessageUpdatedAt, chatHeight]);

  const staffs = chatSession?.chat_sessions_staffs ?? [];
  const { data } = useGetAccountByPkQuery({
    variables: {
      accountId: chatSession?.createdByAccountId!,
    },
    skip:
      !!chatSession?.chat_sessions_accounts[0]?.account ||
      !chatSession?.createdByAccountId,
  });

  const accountByCreatedByAccount = data?.accounts_by_pk;

  const hasJoinedChat = !!staffs.find(
    (s) => s.active && s.staffId === currentStaffData.staffId,
  );

  const account =
    chatSession?.chat_sessions_accounts[0]?.account ??
    accountByCreatedByAccount;
  const patient = chatSession?.chat_sessions_patients?.[0]?.patient;
  const accountName = `${account?.firstName}${
    account?.goesByName ? ` ‘${account?.goesByName}’ ` : ' '
  }${account?.lastName}`;
  const patientName = `${patient?.firstName}${
    patient?.goesByName ? ` ‘${patient?.goesByName}’ ` : ' '
  }${patient?.lastName}`;

  return (
    <Fragment>
      <Header loggedIn />
      <Content>
        <InnerWrap
          css={{
            backgroundColor: Colors.grayLightest,
            overflowX: 'scroll',
            [IF_TABLET]: { overflowY: 'unset', background: 'auto' },
          }}
        >
          <Sidebar />
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <ChatHeader chatSessionId={id} />
            <MainContent
              css={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                paddingTop: 16,
                background: Colors.white,
                [IF_TABLET]: {
                  padding: '16px 40px 0',
                },
              }}
            >
              {messages
                .sort(
                  (a, b) =>
                    DateTime.fromISO(a.createdAt).toSeconds() -
                    DateTime.fromISO(b.createdAt).toSeconds(),
                )
                .map((message, index) => {
                  if (message.type === 'SYSTEM') {
                    return (
                      <ChatSystemMessage
                        message={message}
                        currentStaffId={currentStaffData.staffId!}
                        key={index}
                      />
                    );
                  }
                  if (message.type === 'ACTION') {
                    const staffName =
                      currentStaffData.staffId === message.staffId
                        ? 'You'
                        : `${message.staff?.firstName} ${message.staff?.lastName}`;
                    return (
                      <Fragment key={message.id}>
                        <SystemMessage css={{ justifyContent: 'center' }}>
                          <SystemMessageIcon
                            src={
                              message.request.type === 'REQUEST_FILE'
                                ? cameraIcon
                                : calendarIcon
                            }
                            style={{ height: 16, width: 16 }}
                          />
                          <PrimaryText style={{ padding: '0 10px' }}>
                            {staffName} {message.message}
                          </PrimaryText>
                        </SystemMessage>
                        {!message?.response?.selection ? null : (
                          <SystemMessage key={message.createdAt}>
                            <PrimaryText>
                              {account?.goesByName || account?.firstName}’s
                              response: “{message.response?.selection}”
                            </PrimaryText>
                          </SystemMessage>
                        )}
                        {!message?.response?.appointmentId ? null : (
                          <AppointmentConfirmation
                            appointmentId={message?.response?.appointmentId}
                            onClick={() =>
                              history.push(
                                `/chats/${id}/appointment/${message?.response?.appointmentId}/visit`,
                              )
                            }
                            caregiverFirstName={`${
                              account?.goesByName || account?.firstName
                            }`}
                          />
                        )}
                      </Fragment>
                    );
                  }
                  const isNextDifferentUser =
                    messages[index + 1]?.accountId !== message.accountId ||
                    messages[index + 1]?.staffId !== message.staffId;
                  const isSender = !message.accountId;

                  return (
                    <ChatMessage
                      key={message.id}
                      isSender={isSender}
                      message={message}
                      showAvatar={isNextDifferentUser}
                      onFileClick={setLightboxSrc}
                      onLoad={() => {
                        scrollEnd.current?.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }}
                    />
                  );
                })}
              {chatSummary && (
                <ChatSummary
                  chatTitle={`Chat with ${accountName} about ${patientName}`}
                  chatNote={chatSummary}
                />
              )}
              <div ref={scrollEnd} />
            </MainContent>
            <ChatInputBar>
              {chatSession?.status === 'CLOSED' && !chatSummary ? (
                <ChatStatusWarning>
                  This chat has ended and needs a summary.
                  <Button
                    style={{ marginLeft: 'auto', padding: '6px 12px' }}
                    onClick={() => setShowSummaryPrompt(true)}
                  >
                    Add Summary
                  </Button>
                </ChatStatusWarning>
              ) : !hasJoinedChat ? (
                <ChatStatusWarning>
                  To begin messaging, you must join the chat.
                </ChatStatusWarning>
              ) : chatSession?.status !== 'CLOSED' ? (
                <Fragment>
                  <ChatActions
                    disabled={!hasJoinedChat}
                    onClick={() => setShowActions(!showActions)}
                    style={{
                      background: showActions
                        ? Colors.grayLightest
                        : Colors.white,
                      left: 48,
                    }}
                  >
                    <img src={moreIcon} />
                  </ChatActions>
                  <ChatTextarea
                    value={value}
                    onChange={(event: any) => setValue(event.target.value)}
                  />
                  <ChatSendButton
                    disabled={!hasJoinedChat || value.length === 0}
                    onClick={async () => {
                      const val = value;
                      setValue('');
                      await sendChatMessage(val);
                    }}
                    style={{ right: 48 }}
                  >
                    <img src={sendIcon} />
                  </ChatSendButton>
                </Fragment>
              ) : (
                <ChatStatusWarning>
                  This chat has ended and you can not send anymore messages.
                </ChatStatusWarning>
              )}
            </ChatInputBar>
            {showActions && (
              <Fragment>
                <ChatActionsBackdrop onClick={() => setShowActions(false)} />
                <RequestActionsTray>
                  <RequestActionHeading>
                    <img src={calendarIcon} />
                    <RequestActionTitle>Schedule a Visit</RequestActionTitle>
                  </RequestActionHeading>
                  <RequestAction
                    style={{ marginTop: 8 }}
                    onClick={() => {
                      requestAction('SCHEDULE_APPOINTMENT');
                      setShowActions(false);
                    }}
                  >
                    Request to schedule visit (general)
                  </RequestAction>
                  <RequestAction
                    style={{ marginTop: 8 }}
                    onClick={() => {
                      requestAction('SCHEDULE_PRIMARY');
                      setShowActions(false);
                    }}
                  >
                    Request to schedule Primary Care visit
                  </RequestAction>
                  <RequestAction
                    style={{ marginTop: 8 }}
                    onClick={() => {
                      requestAction('SCHEDULE_URGENT');
                      setShowActions(false);
                    }}
                  >
                    Request to schedule Urgent Care visit
                  </RequestAction>
                  <RequestActionHeading style={{ marginTop: 16 }}>
                    <img src={cameraIcon} />
                    <RequestActionTitle>Upload Media</RequestActionTitle>
                  </RequestActionHeading>
                  <RequestAction
                    onClick={() => {
                      requestAction('REQUEST_FILE');
                      setShowActions(false);
                    }}
                  >
                    Request to send photo or video
                  </RequestAction>
                </RequestActionsTray>
              </Fragment>
            )}
          </div>
          <ChatSidebar chatSessionId={id} />
        </InnerWrap>
      </Content>
      <ChatNotePrompt
        title="Write a summary"
        isOpen={showSummaryPrompt}
        onDismiss={() => setShowSummaryPrompt(false)}
        onSubmit={(note) => writeChatNote(note, 'SUMMARY')}
        staff={currentStaffData.staff}
      />
      {lightboxSrc && (
        <Lightbox
          mainSrc={lightboxSrc}
          onCloseRequest={() => setLightboxSrc(undefined)}
        />
      )}
    </Fragment>
  );
};

export default Chat;
