import React from "react";

interface Props {
  color: string;
}
const Line: React.FC<Props> = ({ color, ...props }) => (
  <svg width="1px" height="37px" viewBox="0 0 1 37" {...props}>
    <path
      transform="translate(-160 -760) translate(156 692)"
      stroke={color}
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
      strokeDasharray="1,3"
      strokeLinecap="round"
      d="M4.5 68L4.5 108"
    />
  </svg>
);

export default Line;
